import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import Container from '../../../../../../Shared/Container/Container';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import Select, { ISelectOption } from '../../../../../../Shared/Select/Select';
import { Fieldset } from '../../../../../../Shared/Form/Fieldset/Fieldset';
import ChangeLevel from '../../../../../../Shared/ChangeLevel/ChangeLevel';
import IconButton from '../../../../../../Shared/IconButton/IconButton';
import Button from '../../../../../../Shared/Button/Button';
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';

import {
  useGetSurvey,
  ISurveyQuestion,
  surveyQuestionTypes,
  SurveyQuestionType,
  useUpsertSurvey,
  IChoiceQuestion,
  ResponderType,
  responderTypes,
  MailInterval,
  mailIntervals,
  IUpsertSurveyValidationErrors,
  SurveyType,
} from '../Surveys.api';
import { useParams, useHistory } from 'react-router-dom';
import ChoiceQuestion from './ChoiceQuestion/ChoiceQuestion';
import { isErrorResponse } from '../../../../../../Shared/Api/response/IErrorRespose';
import useInputState from '../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { useStyles } from './EditSurvey.styles';
import useFormValidation from '../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import CustomerSelect from '../../../../Customers/Components/CustomerSelect/CustomerSelect';
import { SupplierSelect } from '../../Suppliers/SupplierSelect/SupplierSelect';
import CompanyUserSelect from '../../Users/UserSelect/CompanyUserSelect';
import Datepicker from '../../../../../../Shared/InputFields/DatePicker/Datepicker';
import { getCurrentDay } from '../../../../../Shared/utils';
import PreviewTextQuestion from '../../../../../../AnonymousApp/Features/Survey/SurveyForm/TextQuestion/TextQuestion';
import PreviewSatisfactionQuestion from '../../../../../../AnonymousApp/Features/Survey/SurveyForm/SatisfactionQuestion/SatisfactionQuestion';
import PreviewChoiceQuestion from '../../../../../../AnonymousApp/Features/Survey/SurveyForm/ChoiceQuestion/ChoiceQuestion';
import { IChoiceQuestion as PreviewIChoiceQuestion } from '../../../../../../AnonymousApp/Features/Survey/Survey.api';

const questionTypeOptions: ISelectOption[] = surveyQuestionTypes.map((x) => ({
  label: x.name,
  value: (x.type as number).toString(),
}));

interface ISurveyRecipientSelectOption extends ISelectOption {
  type: ResponderType;
}

const recipientTypeOptions: ISurveyRecipientSelectOption[] = responderTypes.map((x) => ({
  label: x.name,
  value: x.type.toString(),
  type: x.type,
}));

interface IMailIntervalSelectOption extends ISelectOption {
  interval: MailInterval | undefined;
}

const mailIntervalOptions: IMailIntervalSelectOption[] = mailIntervals.map((x) => ({
  label: x.name,
  value: x.interval?.toString() ?? '',
  interval: x.interval,
}));

const EditSurvey = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { isFormValid, formRef } = useFormValidation();
  const { isLoading, getSurvey } = useGetSurvey();
  const { isLoading: isUpdateLoading, upsertSurvey } = useUpsertSurvey();
  const [name, changeName, setName] = useInputState('');
  const [mailInterval, setMailInterval] = useState<MailInterval>();
  const [startDate, setStartDate] = useState<Date | null | undefined>();
  const [selectedRecipientType, setSelectedRecipientType] = useState<ResponderType>();
  const [selectedIds, setSelectedIds] = useState<string[]>();
  const [allSelected, setAllSelected] = useState<boolean>();
  const [questions, setQuestions] = useState<ISurveyQuestion[]>([]);
  const [newQuestionType, setNewQuestionType] = useState<SurveyQuestionType>();
  const [mailMessage, changeMailMessage, setMailMessage] = useInputState('');
  const [errors, setErrors] = useState<IUpsertSurveyValidationErrors>();
  const [surveyType, setSurveyType] = useState<SurveyType>();

  useEffect(() => {
    async function loadSurvey() {
      const survey = await getSurvey(id);
      setName(survey.name);
      setMailMessage(survey.mailMessage);
      setMailInterval(survey.mailInterval);
      setStartDate(survey.nextSendDate ? new Date(survey.nextSendDate) : undefined);
      setQuestions(survey.questions.sort((a, b) => a.sortOrder - b.sortOrder));

      setSurveyType(survey.type);
      setSelectedRecipientType(survey.recipientType);
      if (survey.isAllSelect) setSelectedIds(['0']);
      else setSelectedIds(survey.recipientIds?.map((x) => x.toString()));
      setAllSelected(survey.isAllSelect);
    }

    if (id) {
      loadSurvey();
    }
  }, [id, getSurvey, setMailMessage, setName]);

  async function update(emailRecipient: boolean) {
    if ((selectedRecipientType !== undefined && (selectedIds || allSelected !== undefined)) || surveyType) {
      updateSortOrder(questions);
      const response = await upsertSurvey(
        {
          mailMessage: mailMessage,
          mailInterval: mailInterval,
          recipientType: selectedRecipientType,
          recipients: selectedIds?.map((x) => parseInt(x)).filter((x) => x !== 0) ?? [],
          isAllSelect: allSelected ?? false,
          nextSendDate: startDate,
          surveyName: name,
          choiceQuestions: questions.filter((x) => x.type === SurveyQuestionType.Choice),
          satisfactionQuestions: questions.filter((x) => x.type === SurveyQuestionType.Satisfaction),
          textQuestions: questions.filter((x) => x.type === SurveyQuestionType.Text),
          emailRecipient,
        },
        id,
      );

      if (response && isErrorResponse(response)) {
        setErrors(response);
      } else {
        goToList();
      }
    }
  }

  function goToList() {
    history.push(`/admin/surveys`);
  }

  function updateSortOrder(list: ISurveyQuestion[]) {
    for (let index = 0; index < list.length; index++) {
      list[index].sortOrder = index + 1;
    }
  }

  function removeQuestion(question: ISurveyQuestion) {
    const newList = [...questions];

    const index = newList.indexOf(question);
    newList.splice(index, 1);

    setQuestions(newList);
  }

  function moveUp(index: number) {
    const newList = [...questions];

    const question = newList[index];
    newList[index] = newList[index - 1];
    newList[index - 1] = question;

    setQuestions(newList);
  }

  function moveDown(index: number) {
    const newList = [...questions];

    const question = newList[index];
    newList[index] = newList[index + 1];
    newList[index + 1] = question;

    setQuestions(newList);
  }

  function addQuestion() {
    if (newQuestionType) {
      const newList = [...questions];

      newList.push({ type: newQuestionType, question: '', sortOrder: nextSortOrder() });

      setQuestions(newList);
      setNewQuestionType(undefined);
    }
  }

  function updateQuestion(index: number, update: any) {
    const newList = [...questions];

    newList[index] = { ...newList[index], ...update };

    setQuestions(newList);
  }

  function nextSortOrder() {
    return Math.max(...questions.map((x) => x.sortOrder), 0) + 1;
  }

  function valid(): boolean {
    let valid = true;
    questions.forEach((question) => {
      if (!question.question) valid = false;

      if (question.type === SurveyQuestionType.Choice) {
        if (
          !(question as IChoiceQuestion).choices ||
          (question as IChoiceQuestion).choices?.some((x) => !x.choiceOption)
        ) {
          valid = false;
        }
      }
    });
    return valid;
  }

  function updateSelectedIds(option?: ISelectOption | ISelectOption[], allOptionsSelected?: boolean) {
    if (option && Array.isArray(option)) {
      setAllSelected(allOptionsSelected ?? false);
      setSelectedIds(option.map((x) => x.value));
    }
  }

  const form = useMemo(() => {
    switch (selectedRecipientType) {
      case ResponderType.Customer:
        return (
          <CustomerSelect
            isClearable
            fullwidth
            isMulti
            addSelectAllOption
            selectedCustomerId={selectedIds}
            onChange={(_1, _2, isAllSelected, option) => updateSelectedIds(option, isAllSelected)}
          />
        );
      case ResponderType.Supplier:
        return (
          <SupplierSelect
            isClearable
            fullwidth
            isMulti
            addSelectAllOption
            selectedSupplierId={selectedIds}
            onChange={(_1, _2, option, isAllSelected) => updateSelectedIds(option, isAllSelected)}
          />
        );
      case ResponderType.CompanyUser:
        return (
          <CompanyUserSelect
            label={'Välj användare'}
            isClearable
            fullwidth
            isMulti
            addSelectAllOption
            selectedCompanyUserId={selectedIds}
            onChange={(_1, _2, option, isAllSelected) => updateSelectedIds(option, isAllSelected)}
          />
        );
      default:
        setSelectedRecipientType(undefined);
        return undefined;
    }
  }, [selectedRecipientType, selectedIds]);

  function QuestionComponent(question: ISurveyQuestion | PreviewIChoiceQuestion) {
    switch (question.type) {
      case SurveyQuestionType.Text:
        return (
          <PreviewTextQuestion
            question={{id: 1, ...question}}
            answer={"A text answer ..."}
            onAnswerChange={() => false}
          />
        );
      case SurveyQuestionType.Satisfaction:
        return (
          <PreviewSatisfactionQuestion
            question={{id: 2, ...question}}
            answer={'Nöjd'}
            onAnswerChange={() => false}
          />
        );
      case SurveyQuestionType.Choice:
        const choices = (question as PreviewIChoiceQuestion).choices ?? [];
        return (
          <PreviewChoiceQuestion
            preview
            question={question as PreviewIChoiceQuestion}
            answer={choices.length > 0 ? choices[0].choiceOption : ""}
            onAnswerChange={(answer) => false}
        />
        );
    }
  }

  return (
    <>
      <Container
        label={name}
        loading={isLoading}
        form
        ref={formRef}
        actions={
          <>
            <Button variant="outlined" disabled={isUpdateLoading} onClick={goToList}>
              Avbryt
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isLoading || !valid() || !isFormValid}
              loading={isUpdateLoading}
              onClick={() => update(false)}
            >
              {id ? 'Spara' : 'Skapa'}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isLoading || !valid() || !isFormValid || surveyType === undefined}
              loading={isUpdateLoading}
              onClick={() => update(true)}
            >
              {'Skicka'}
            </Button>
          </>
        }
      >
        <Fieldset legend="Inställningar">
          <InputField
            label="Utskick namn"
            required
            multiline
            fullwidth
            value={name}
            onChange={changeName}
            errorText={errors?.surveyName}
            viewOnly={!!id && surveyType !== SurveyType.Custom}
          />
          <Datepicker
            isClearable
            customWidth={4}
            label="Utskicksdatum"
            selected={startDate}
            onChange={setStartDate}
            minDate={getCurrentDay()}
          />
          <Select
            inputFieldProps={{ label: 'Utskickintervall', placeholder: 'Välj intervall', customWidth: 4 }}
            selectProps={{
              options: mailIntervalOptions,
              value: mailIntervalOptions.find((x) => x.interval === mailInterval),
              onChange: (option) => {
                setMailInterval((option as IMailIntervalSelectOption)?.interval);
              },
            }}
          />
          {(!id || surveyType === SurveyType.Custom) && (
            <>
              <Select
                inputFieldProps={{ label: 'Välj mottagare typ', placeholder: 'Välj mottagare typ', fullwidth: true }}
                selectProps={{
                  options: recipientTypeOptions,
                  value: recipientTypeOptions.find((x) => x.type === selectedRecipientType),
                  onChange: (option) => {
                    setSelectedRecipientType((option as ISurveyRecipientSelectOption).type);
                    setSelectedIds(undefined);
                  },
                }}
              />
              {form}
            </>
          )}
          <InputField
            label="E-post meddelande"
            required
            multiline
            fullwidth
            rows={3}
            value={mailMessage}
            onChange={changeMailMessage}
            errorText={errors?.mailMessage}
          />
        </Fieldset>
        {questions.map((question, index) => (
          <Fieldset
            key={question.id + '-' + index}
            legend={'Fråga ' + (index + 1) + ' - ' + surveyQuestionTypes.find((x) => x.type === question.type)?.name}
            actions={
              <>
                <ChangeLevel
                  up={{ onClick: () => moveUp(index), disabled: index === 0 }}
                  down={{ onClick: () => moveDown(index), disabled: index === questions.length - 1 }}
                />
                <IconButton
                  color="error"
                  size="small"
                  aria-label="Ta bort fråga"
                  className={classes.remove}
                  onClick={() => removeQuestion(question)}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            }
          >
            <InputField
              label="Fråga"
              multiline
              fullwidth
              value={question.question}
              onChange={(e) => updateQuestion(index, { question: e.target.value })}
            />
            {question.type === SurveyQuestionType.Choice && (
              <ChoiceQuestion question={question} onChange={(update) => updateQuestion(index, update)} />
            )}
          </Fieldset>
        ))}
        <Fieldset legend="Lägg till fråga">
          <Grid item container alignItems="center">
            <Grid xs item>
              <Select
                inputFieldProps={{
                  label: 'Frågetyp',
                  fullwidth: true,
                }}
                selectProps={{
                  value: questionTypeOptions.filter((x) => Number(x.value) === newQuestionType),
                  onChange: (selectedValue) => {
                    setNewQuestionType(Number((selectedValue as ISelectOption).value));
                  },
                  options: questionTypeOptions,
                  isClearable: false,
                }}
              />
            </Grid>
            <Grid item>
              <IconButton
                color="primary"
                size="small"
                aria-label="Lägg till fråga"
                disabled={!newQuestionType || isLoading}
                onClick={addQuestion}
                className={classes.addIconBtn}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Fieldset>
      </Container>

      <Container label={name + ' (förhandsvisning - går ej att svara eller skicka)'} actions={
          <Button color="primary" variant="contained" onClick={() => false}>
          Skicka
        </Button>
        } form ref={formRef} loading={isLoading}>
        {questions
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .map((question) => (
            <Fragment key={question.id}>{QuestionComponent(question)}</Fragment>
          ))}
      </Container>
    </>
  );
};

export default EditSurvey;
