import React, { useState, useEffect, useCallback } from 'react';
import { useGetAudits, ListType } from '../../Audit.api';
import AuditListComponent from '../AuditListComponent/AuditListComponent';
import { useRouteMatch } from 'react-router-dom';
import IPaginatedRequest from '../../../../../Shared/Api/request/IPaginatedRequest';

const NoContact = () => {
  const { isLoading, audits, getAudits } = useGetAudits();
  const [emptyMessage, setEmptyMessage] = useState('');
  const [request, setRequest] = useState<IPaginatedRequest>();
  const { path } = useRouteMatch();

  const getList = useCallback(async () => {
    if (request) {
      const response = await getAudits({
        ...request,
        listType: ListType.NoContact,
      });

      if (response?.totalCount <= 0) {
        if (request.query) {
          setEmptyMessage('Filtreringen gav inga resultat');
        } else {
          setEmptyMessage('Finns inga system med kunder som ej önskar kontakt');
        }
      }
    } else {
      setEmptyMessage('Inga system är aktuella för revision');
    }
  }, [request, getAudits]);

  useEffect(() => {
    getList();
  }, [getList]);

  return (
    <AuditListComponent
      label="Ej kontakt"
      emptyMessage={emptyMessage}
      onTableChange={setRequest}
      audits={audits}
      isLoading={isLoading}
      editPath={path}
      isNoContactList={true}
      loadList={getList}
    />
  );
};

export default NoContact;
