import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  mobileRoot: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  calendar: {
    marginBottom: '0',
  },
  date: {
    marginBottom: '0.5rem',
  },
  dateAndName: {
    fontSize: '1.125rem',
  },
}));
