import React, { useEffect } from 'react';
import { InputBaseProps, FormControl, InputBase, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/SearchRounded';
import useInputState from '../../Hooks/UseInputState/UseInputState';
import useDebounce from '../../Hooks/UseDebounce/useDebounce';
import { useStyles } from './SearchInput.styles';

interface IProps extends Omit<InputBaseProps, 'onChange'> {
  onChange: (value: string) => void;
  inline?: boolean;
  defaultValue?: string | null;
}

function SearchInput(props: IProps) {
  const classes = useStyles();
  const [query, setQuery] = useInputState(props.defaultValue ? props.defaultValue : '');
  const debouncedQuery = useDebounce(query, 500);

  const { onChange, inline, ...rest } = props;

  useEffect(() => {
    onChange(debouncedQuery);
  }, [onChange, debouncedQuery]);

  return (
    <FormControl className={`${classes.root} ${inline ? classes.inline : ''} `}>
      <InputBase
        {...rest}
        value={query}
        onChange={setQuery}
        inputProps={{ 'aria-label': props.placeholder }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </FormControl>
  );
}

SearchInput.defaultProps = {
  placeholder: 'Sök',
};

export default SearchInput;
