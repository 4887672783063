import React, { createContext, useEffect, useState, useContext, ReactNode, useCallback } from 'react';
// Todo: move closer
import {
  useGetCurrentUser,
  ICompanyUserModuleAccess,
  UserType,
  ICurrentUser,
  HomeComponentDto,
  Role,
} from '../../AuthApp/Modules/Admin/Components/Users/Users.api';
import { AccessRights } from './ModuleAccess';

export interface IUser {
  id: number;
  username: string;
  userType: UserType | null;
  sessions: ISession[];
  currentUserModuleAccess: ICompanyUserModuleAccess[];
  companyName?: string;
  bannerText?: string;
  companyLogoBase64?: string;
  companyAdditionalImageBase64?: string;
  impersonatedCompany: ICompany | null;
  selectedUserId: number | null;
  homeComponents: HomeComponentDto[] | null;
  roles: Role[] | null;
}

interface ICompany {
  id: number;
  name: string;
  logo: string;
  additionalImage?: string;
  companyUser: ICompanyUser;
}

export interface ICompanyUser {
  id: number;
  fullName: string;
  moduleAccessRights: ICompanyUserModuleAccess[];
}

export interface ISession {
  userName?: string;
  userType: UserType;
  userId?: number;
  companyName?: string;
}

export interface IUserContext {
  user: IUser;
  logout: () => void;
  setSession: (session: ISession | undefined) => void;
  hasAccess: (moduleIdentifier: string, accessRights: AccessRights) => boolean;
  impersonateCompany: (userId: number | null) => void;
  cancelImpersonation: () => void;
  refetch: () => Promise<void>;
}

export const hasUserAccessToModule = (user: IUser, moduleIdentifier: string | string[], accessRights: AccessRights) => {
  if (!user) return false;

  const currentUserModuleAccess =
    user.userType === UserType.SuperAdmin && user.impersonatedCompany
      ? user.impersonatedCompany?.companyUser.moduleAccessRights
      : user.currentUserModuleAccess;

  let permission: ICompanyUserModuleAccess | undefined = undefined;

  if (Array.isArray(moduleIdentifier))
    permission = currentUserModuleAccess?.find((x) => moduleIdentifier.some((y) => y === x.moduleIdentifier));
  else permission = currentUserModuleAccess?.find((x) => x.moduleIdentifier === moduleIdentifier);

  if (!permission) return false;

  return permission.accessRights >= accessRights;
};

export const hasUserAccessToAnyModule = (user: IUser) => {
  if (!user) return false;

  const currentUserModuleAccess =
    user.userType === UserType.SuperAdmin && user.impersonatedCompany
      ? user.impersonatedCompany?.companyUser.moduleAccessRights
      : user.currentUserModuleAccess;

  const permission = currentUserModuleAccess?.some((x) => x.accessRights >= 2);

  if (!permission) return false;

  return permission;
};

export const userHasAccessThroughRole = (user: IUser, role: Role): boolean => {
  return user.roles?.includes(role) ?? false;
};

export const UserContext = createContext<IUserContext>({} as IUserContext);

interface IProps {
  logout: () => void;
  children: ReactNode;
}

function getAllSessions(apiUser: ICurrentUser) {
  const sessionList: ISession[] = [];

  if (apiUser.companies)
    apiUser.companies.map((x) => {
      return sessionList.push({ userType: UserType.CompanyUser, userId: x.userId, companyName: x.companyName });
    });

  if (apiUser.customers)
    apiUser.customers.map((x) => {
      return sessionList.push({ userType: UserType.Customer, userId: x.userId, companyName: x.companyName });
    });

  if (apiUser.isPortalAdministrator) {
    sessionList.push({ userType: UserType.SuperAdmin });
    apiUser.impersonatedCompanyUsers.map((x) => {
      return sessionList.push({
        userType: UserType.SuperAdmin,
        companyName: x.companyName,
        userId: x.userId,
        userName: x.userName,
      });
    });
  }

  return sessionList;
}

const userType = localStorage.getItem('USER_TYPE')
  ? (JSON.parse(localStorage.getItem('USER_TYPE')!) as UserType)
  : undefined;
const userId = localStorage.getItem('USER_ID') ?? null;

export function UserContextProvider(props: IProps) {
  const [session, setSession] = useState<ISession | undefined>(
    userType ? { userType: userType, userId: userId ? parseInt(userId) : undefined, companyName: '' } : undefined,
  );
  const [state, setState] = useState<IUserContext | null>(null);
  const { getCurrentUser } = useGetCurrentUser();

  const { logout } = props;

  const handleLogout = useCallback(() => {
    localStorage.removeItem('USER_TYPE');
    logout();
  }, [logout]);

  useEffect(() => {
    function impersonateCompany(impersonatedCompanyUserId: number | null) {
      localStorage.setItem('USER_ID', impersonatedCompanyUserId?.toString() ?? '');
      setSession((prev) => {
        if (!prev) {
          handleLogout();
          return prev;
        }
        return {
          ...prev,
          userId: impersonatedCompanyUserId ?? undefined,
        };
      });
    }

    const loadUser = async () => {
      try {
        const apiUser = await getCurrentUser(session?.userType, session?.userId);
        if (!apiUser) {
          handleLogout();
          return;
        }

        const user: IUser = {
          id: apiUser.id,
          username: apiUser.firstName + ' ' + apiUser.lastName,
          userType: apiUser.selectedUserType,
          sessions: getAllSessions(apiUser),
          currentUserModuleAccess: apiUser.moduleAccessRights,
          companyName: apiUser.selectedCompany?.companyName,
          bannerText: apiUser.selectedCompany?.bannerText,
          companyLogoBase64: apiUser.selectedCompany?.companyLogoBase64,
          companyAdditionalImageBase64: apiUser.selectedCompany?.companyAdditionalImageBase64,
          impersonatedCompany:
            apiUser.selectedCompany && apiUser.impersonatedCompanyUser
              ? {
                  id: apiUser.selectedCompany?.companyId,
                  logo: apiUser.selectedCompany?.companyLogoBase64,
                  additionalImage: apiUser.selectedCompany?.companyAdditionalImageBase64,
                  name: apiUser.selectedCompany?.companyName,
                  companyUser: apiUser.impersonatedCompanyUser,
                }
              : null,
          selectedUserId: apiUser.selectedUserId ?? null,
          homeComponents: apiUser.homeComponents,
          roles: apiUser.roles,
        };

        const hasAccess = (moduleIdentifier: string, accessRights: AccessRights) => {
          return !!hasUserAccessToModule(user, moduleIdentifier, accessRights);
        };

        setState({
          user: user,
          logout: handleLogout,
          setSession,
          hasAccess,
          impersonateCompany: impersonateCompany,
          cancelImpersonation: () => impersonateCompany(null),
          refetch: loadUser,
        });
      } catch (error) {
        handleLogout();
      }
    };

    loadUser();
  }, [session, handleLogout, getCurrentUser]);

  return <>{state && <UserContext.Provider value={state}>{props.children}</UserContext.Provider>}</>;
}

const useUserContext = () => useContext(UserContext);

export default useUserContext;
