import React, { useContext } from 'react';
import { FormControl, FormGroup, Grid, GridSize } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { ContainerContext } from '../../Container/Container';
import InputLabel from '../InputLabel/InputLabel';
import { useStyles } from './CheckboxField.styles';

interface ICheckboxFieldProps {
  label: string;
  required?: boolean;
  inline?: boolean;
  viewOnly?: boolean;
  fullwidth?: boolean;
  customWidth?: GridSize;
}

const CheckboxField: React.FunctionComponent<ICheckboxFieldProps> = (props) => {
  const classes = useStyles();
  const containerContext = useContext(ContainerContext);

  let skeletonRows = [];

  for (var i = 0; i < React.Children.count(props.children); i++) {
    skeletonRows.push(
      <div className={classes.skeletonRow} key={`radio-skeleton-${i}`}>
        <Skeleton variant="circle" animation="wave" className={classes.skeletonRadioButton} />
        <Skeleton variant="text" animation="wave" className={classes.skeletonInput} />
      </div>,
    );
  }
  return (
    <Grid
      item
      md={props.fullwidth ? 12 : props.customWidth ?? 6}
      sm={props.fullwidth ? 12 : props.customWidth ?? 6}
      xs={12}
      style={{overflow: 'hidden'}}
    >
      <FormControl
        className={`${classes.formControl} ${!props.inline && !props.viewOnly ? classes.baseHeight : ''} ${
          !props.inline && props.viewOnly ? classes.viewOnlyBaseHeight : ''
        } `}
      >
        {containerContext?.loading ? (
          <>
            <Skeleton variant="text" animation="wave" className={classes.skeletonLabel} />
            {skeletonRows}
          </>
        ) : (
          <>
            <InputLabel label={props.label} required={props.required} />
            <FormGroup>{props.children}</FormGroup>
          </>
        )}
      </FormControl>
    </Grid>
  );
};

export default CheckboxField;
