import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SelectTable, { ISelectItem } from '../../../../../Shared/Table/SelectTable/SelectTable';
import { useGetCompanies } from './Companies.api';

interface ISelectIitemWithId extends ISelectItem {
  id: number;
}
const CompanySelect = () => {
  const history = useHistory();
  const { isLoading, companies, getCompanies } = useGetCompanies();
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>();
  const [companyItems, setCompanyItems] = useState<ISelectIitemWithId[]>([]);
  const [customerItems, setCustomerItems] = useState<ISelectIitemWithId[]>([]);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  useEffect(() => {
    if (companies) {
      setCompanyItems(companies.map((x) => ({ label: x.name, id: x.id, value: x.id.toString() })));
      if (companies.length === 1) {
        setSelectedCompanyId(companies[0].id);
      }
    }
  }, [companies]);

  const goToCustomer = useCallback(
    (id: number) => {
      history.push(`${history.location.pathname}${selectedCompanyId}/${id}`);
    },
    [history, selectedCompanyId],
  );

  useEffect(() => {
    if (companies && selectedCompanyId) {
      const company = companies.find((x) => x.id === selectedCompanyId);
      if (company) {
        setCustomerItems(company.customers.map((x) => ({ label: x.name, id: x.id, value: x.id.toString() })));
        if (company.customers.length === 1) {
          goToCustomer(company.customers[0].id);
        }
      }
    }
  }, [companies, selectedCompanyId, goToCustomer]);

  return selectedCompanyId ? (
    <SelectTable<ISelectIitemWithId>
      items={customerItems}
      onItemSelect={(x) => goToCustomer(x.id)}
      header="Kundprofil"
      loading={{ loading: isLoading }}
    />
  ) : (
    <SelectTable<ISelectIitemWithId>
      items={companyItems}
      onItemSelect={(x) => setSelectedCompanyId(x.id)}
      header="Företag"
      loading={{ loading: isLoading }}
    />
  );
};

export default CompanySelect;
