import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '5% 0',
    '& > div': {
      padding: '0 0.75rem',
    },
  },
}));

export default useStyles;
