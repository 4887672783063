import React, { useState, useEffect, useCallback } from 'react';
import { TableCell, TableHead, TableRow, TableBody, Typography, MenuItem, ListItemIcon, useMediaQuery } from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import { Table } from '../../../../../../Shared/Table/Table';
import { DropdownCell } from '../../../../../../Shared/Table/DropdownCell/DropdownCell';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import Button from '../../../../../../Shared/Button/Button';
import { useHistory } from 'react-router-dom';
import AccessGuard from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { ModuleIdentifiers, AccessRights } from '../../../../../../Core/Authentication/ModuleAccess';
import {
  ICustomerContact,
  useDeleteContact,
  useDeleteFacilityContact,
  useGetContactList,
  useSendContactInvite,
  useUpdateExistingContact,
} from '../../../Customers.api';
import Link from '../../../../../../Shared/Link/Link';
import useStyles from './ContactList.styles';
import { MobileTable } from '../../../../../../Shared/Table/MobileTable/MobileTable';
import { MobileTableRow } from '../../../../../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import Snackbar from '../../../../../../Shared/Hooks/UseErrorMessage/Snackbar/Snackbar';
import { ErrorCode, isErrorResponse } from '../../../../../../Shared/Api/response/IErrorRespose';
import useErrorMessage from '../../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import CustomerContactSelector from '../CustomerContactSelector/CustomerContactSelector';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import useInputState from '../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { ConfirmDeleteOrArchiveRow } from '../../../../../../Shared/Table/ConfirmDeleteOrArchiveRow/ConfirmDeleteOrArchiveRow';
import theme from '../../../../../../Styles/Theme';

interface IContactList {
  showFacility?: boolean;
  customerId: string;
  facilityId?: string;
  mobileView?: boolean;
}

const defaultVisibleItems = 7;

const ContactList = (props: IContactList) => {
  const classes = useStyles();
  const history = useHistory();
  const { showFacility, customerId, facilityId } = props;
  const { contacts, getContactList, isLoading: loading } = useGetContactList(customerId, facilityId);
  const { updateContact } = useUpdateExistingContact(customerId);
  const { sendContactInvite } = useSendContactInvite(customerId);
  const { isLoading: isDeleteContactLoading, deleteContact } = useDeleteContact(customerId);
  const { isLoading: isDeleteFacilityContactLoading, deleteFacilityContact } = useDeleteFacilityContact(customerId);
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const [isToggleOpen, setIsToggleOpen] = useState(false);
  const [confirmDeleteId, setConfirmDeleteId] = useState<number>();
  const [openConfirmText, setOpenConfirmText] = useState(false);
  const [position, changePosition, setPosition] = useInputState('');
  const [selectedContactId, setSelectedContactId] = useState<number | undefined>(0);
  const { setErrorMessage } = useErrorMessage();

  const isTogglable = contacts && contacts.length > defaultVisibleItems;
  const maxDisplay = contacts && isToggleOpen ? contacts.length : defaultVisibleItems;

  useEffect(() => setConfirmDeleteId(undefined), [contacts]);

  const handleGetContact = useCallback(async () => {
    await getContactList();
  }, [getContactList]);

  useEffect(() => {
    handleGetContact();
  }, [handleGetContact]);

  const handleTableClick = (contact: ICustomerContact) => {
    history.push(`/customers/${customerId}/contact/${contact.id}/update`);
  };

  async function handleSendInvitation(contactId: number) {
    await sendContactInvite(contactId.toString());
    setOpenConfirmText(true);
  }

  async function handleUpdateExistingContact(): Promise<void> {
    if (selectedContactId === 0 || facilityId === undefined || selectedContactId === undefined) {
      return;
    }

    await updateContact(
      {
        facilityId: Number(facilityId),
        position: position,
      },
      selectedContactId.toString(),
    );
    await handleGetContact();
    setSelectedContactId(undefined);
    setPosition('');
  }

  async function handleDeleteContact(contactId: number) {
    let result;
    if (facilityId) {
      result = await deleteFacilityContact(contactId, facilityId);
    } else {
      result = await deleteContact(contactId);
    }

    if (isErrorResponse(result) && result.errorCode === ErrorCode.EntityInUse) {
      setErrorMessage({ message: 'Gick inte att ta bort kontakten då den används' });
    } else {
      getContactList();
    }
  }

  return (
    <>
      <div className={classes.contacts}>
        <Table
          loading={{
            loading: !!loading,
            skeletonRows: contacts?.length,
          }}
          customSize={{
            fullsize: true,
          }}
          empty={{
            empty: !!(contacts && contacts.length === 0),
            message: 'Inga kontakter tillagda',
          }}
          actions={
            <AccessGuard module={ModuleIdentifiers.Customer} accessRights={AccessRights.Write}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push(`${history.location.pathname}/contacts/create`)}
              >
                Lägg till kontakt
              </Button>
            </AccessGuard>
          }
          mobile={mobileView}
        >
          {mobileView ? (
            <>
              {contacts &&
                contacts?.map(
                  (contact, index) =>
                    index < maxDisplay && (
                      <MobileTable
                        key={contact.id}
                        label={contact.firstname + ' ' + contact.lastname}
                        customLink={
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Link
                              to={`/customers/${customerId}/contact/${contact.id}/update`}
                              module={ModuleIdentifiers.Customer}
                              accessRights={AccessRights.Write}
                            >
                              {contact.firstname + ' ' + contact.lastname}&nbsp;
                            </Link>
                            <>
                              {contact.mobilePhoneNumber ? (
                                <a href={`tel:${contact.mobilePhoneNumber}`}>{`(${contact.mobilePhoneNumber})`}</a>
                              ) : (
                                contact.phoneNumber && (
                                  <a href={`tel:${contact.phoneNumber}`}>{`(${contact.phoneNumber})`}</a>
                                )
                              )}
                            </>
                            {!contact.inviteAccepted && (
                              <span style={{ color: 'red', display: 'flex', alignItems: 'center', marginLeft: '6px' }}>
                                <Cancel fontSize="small" />
                              </span>
                            )}
                          </div>
                        }
                        action={
                          <DropdownCell mobile>
                            <MenuItem onClick={() => setConfirmDeleteId(contact.id)}>
                              <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                              <Typography variant="inherit">Ta bort</Typography>
                            </MenuItem>
                            {!contact.inviteAccepted && (
                              <MenuItem onClick={() => handleSendInvitation(contact.id)}>
                                <Typography variant="inherit">Skicka ny inbjudan länk</Typography>
                              </MenuItem>
                            )}
                          </DropdownCell>
                        }
                      >
                        <MobileTableRow
                          label="Namn"
                          value={
                            <Link
                              to={`/customers/${customerId}/contact/${contact.id}/update`}
                              module={ModuleIdentifiers.Customer}
                              accessRights={AccessRights.Write}
                            >
                              {contact.firstname + ' ' + contact.lastname}{' '}
                            </Link>
                          }
                        />
                        <MobileTableRow
                          label="Telefon"
                          value={<a href={`tel:${contact.phoneNumber}`}>{contact.phoneNumber}</a>}
                        />
                        <MobileTableRow
                          label="Mobil"
                          value={
                            contact.mobilePhoneNumber && (
                              <a href={`tel:${contact.mobilePhoneNumber}`}>{contact.mobilePhoneNumber}</a>
                            )
                          }
                        />
                        <MobileTableRow
                          label="E-post"
                          value={<a href={`mailto:${contact.email}`}>{contact.email}</a>}
                        />
                        <MobileTableRow label="Plats" value={contact.facilityNames.join(', ')} />
                        {!contact.inviteAccepted && <MobileTableRow label="Status" value="Användare ej verifierad" />}
                      </MobileTable>
                    ),
                )}

              {isTogglable && contacts && (
                <div onClick={() => setIsToggleOpen((x) => !x)} className={classes.mobileTableEndRow}>
                  {contacts.length - maxDisplay ? `+ ${contacts.length - maxDisplay} till` : 'Visa färre'}
                </div>
              )}
            </>
          ) : (
            <>
              <TableHead>
                <TableRow>
                  <AccessGuard module={ModuleIdentifiers.Customer} accessRights={AccessRights.Write}>
                    <TableCell>
                      <span className={'sr-only'}>Knappar</span>
                    </TableCell>
                  </AccessGuard>
                  <TableCell>Namn</TableCell>
                  <TableCell>Befattning</TableCell>
                  <TableCell>Telefon</TableCell>
                  <TableCell>Mobil</TableCell>
                  <TableCell>E-post</TableCell>
                  {showFacility && <TableCell>Plats</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {contacts &&
                  contacts?.map(
                    (contact, index) =>
                      index < maxDisplay && (
                        <TableRow className={classes.pointerCursor} hover tabIndex={-1} key={contact.id}>
                          {confirmDeleteId !== contact.id ? (
                            <>
                              <AccessGuard module={ModuleIdentifiers.Customer} accessRights={AccessRights.Write}>
                                <DropdownCell>
                                  <MenuItem onClick={() => setConfirmDeleteId(contact.id)}>
                                    <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                                    <Typography variant="inherit">Ta bort</Typography>
                                  </MenuItem>
                                  {!contact.inviteAccepted && (
                                    <MenuItem onClick={() => handleSendInvitation(contact.id)}>
                                      <Typography variant="inherit">Skicka ny inbjudan länk</Typography>
                                    </MenuItem>
                                  )}
                                </DropdownCell>
                              </AccessGuard>
                              <TableCell onClick={() => handleTableClick(contact)} className={classes.font}>
                                <AccessGuard
                                  module={ModuleIdentifiers.Customer}
                                  accessRights={AccessRights.Write}
                                  fallback={contact.firstname + ' ' + contact.lastname}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      color: !contact.inviteAccepted ? 'red' : 'inherit',
                                    }}
                                  >
                                    {contact.firstname + ' ' + contact.lastname}
                                  </div>
                                </AccessGuard>
                              </TableCell>
                              <TableCell onClick={() => handleTableClick(contact)}>{contact.position ?? ''}</TableCell>
                              <TableCell>
                                <a href={`tel:${contact.phoneNumber}`}>{contact.phoneNumber}</a>
                              </TableCell>
                              <TableCell>
                                {contact.mobilePhoneNumber ? (
                                  <a href={`tel:${contact.mobilePhoneNumber}`}>{contact.mobilePhoneNumber}</a>
                                ) : (
                                  <div style={{ minWidth: '50px' }}></div>
                                )}
                              </TableCell>
                              <TableCell>
                                <a href={`mailto:${contact.email}`}>{contact.email}</a>
                              </TableCell>
                              {showFacility && (
                                <TableCell onClick={() => handleTableClick(contact)}>
                                  {contact.facilityNames.join(', ')}
                                </TableCell>
                              )}
                            </>
                          ) : (
                            <ConfirmDeleteOrArchiveRow
                              onConfirm={() => handleDeleteContact(confirmDeleteId)}
                              onCancel={() => setConfirmDeleteId(undefined)}
                              colspan={4}
                              label={contact.firstname + ' ' + contact.lastname}
                              loading={isDeleteContactLoading || isDeleteFacilityContactLoading}
                            />
                          )}
                        </TableRow>
                      ),
                  )}

                {isTogglable && contacts && (
                  <TableRow>
                    <TableCell
                      onClick={() => setIsToggleOpen((x) => !x)}
                      className={classes.roundedHighlightColumn}
                      colSpan={7}
                    >
                      {contacts.length - maxDisplay ? `+ ${contacts.length - maxDisplay} till` : 'Visa färre'}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </>
          )}
        </Table>
      </div>
      {props.facilityId && (
        <div className={!mobileView ? classes.addContacts : ''}>
          <CustomerContactSelector
            customerId={props.customerId}
            chosenContacts={contacts}
            fullwidth={false}
            onChange={(id) => {
              setSelectedContactId(id ? Number(id) : undefined);
            }}
            customWidth={3}
            label="Befintliga kontaktpersoner"
          />
          <InputField
            fullwidth={mobileView}
            customWidth={3}
            label="Befattning"
            value={position}
            onChange={changePosition}
          />
          <AccessGuard module={ModuleIdentifiers.Customer} accessRights={AccessRights.Write}>
            <Button variant="contained" color="primary" onClick={handleUpdateExistingContact} style={mobileView ? {marginTop: '0.5rem'} : {}}>
              Lägg till befintlig kontaktperson
            </Button>
          </AccessGuard>
        </div>
      )}
      <Snackbar
        message={'Ny inbjudan har skickats.'}
        open={openConfirmText}
        severity={'success'}
        onClose={() => setOpenConfirmText(false)}
      />
    </>
  );
};

export default ContactList;
