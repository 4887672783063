import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  calendar: {
    marginBottom: '1rem',
  },
  date: {
    marginBottom: '0.5rem',
  },
  dateAndName: {
    fontSize: '1.125rem',
  },
  saveButton: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
  },
  tabs: {
    marginTop: '10px',
  },
  draftBtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
