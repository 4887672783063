import { useApiPost } from '../../../../../../Core/Api/Api';
import { TimeReportType } from '../../../../Errands/Components/Sale.api';

export function useGetProductionStatsList() {
  const { isLoading, response, post } = useApiPost<TimeReportSummaryDto[], ITimeReportSummaryQuery>(
    `/api/statistics/production`,
  );

  return {
    isLoading,
    productionStats: response,
    getProductionStatsList: post,
  };
}

export interface ITimeReportSummaryQuery {
  fromDate: Date;
  toDate: Date;
}

export interface TimeReportSummaryDto {
  companyUserId: number;
  name: string;
  entries: TimeReportSummaryEntry[];
}

export interface TimeReportSummaryEntry {
  type: TimeReportType;
  hours: number;
}

export interface IUserTimeReportQuery {
  fromDate: Date;
  toDate: Date;
}

export function useGetUserProductionStats(userId: string | undefined) {
  const { isLoading, response, post } = useApiPost<ICompanyUserTimeReportDto, IUserTimeReportQuery>(
    `/api/statistics/production/${userId}`,
  );

  return {
    isLoading,
    productionStats: response,
    getProductionStats: post,
  };
}

export interface ICompanyUserTimeReportDto {
  companyUserId: number;
  name: string;
  entries: IUserTimeReportEntryDto[];
}

export interface IUserTimeReportEntryDto {
  id: number;
  companyId: number;
  company: string;
  companyUserId: number;
  date: Date;
  hours: number;
  trips: number;
  parking: number;
}
