import React, { useContext } from 'react';
import ConfigurationContext from '../Core/Configurations/ConfigurationContext';
import { AuthContextProvider } from '../Core/Authentication/AuthContextProvider';
import AzureAD, { IAzureADFunctionProps, AuthenticationState } from 'react-aad-msal';
import { UserContextProvider } from '../Core/Authentication/UserContext';
import App from './App';
import Button from '../Shared/Button/Button';

const AuthApp = () => {
  const config = useContext(ConfigurationContext);

  return (
    <AuthContextProvider config={config}>
      {(msalAuthProvider) =>
        msalAuthProvider && (
          <AzureAD provider={msalAuthProvider} forceLogin={true}>
            {({ login, authenticationState, logout }: IAzureADFunctionProps) => {
              switch (authenticationState) {
                case AuthenticationState.Authenticated:
                  return (
                    <UserContextProvider logout={logout}>
                      <App />
                    </UserContextProvider>
                  );
                case AuthenticationState.InProgress:
                  return <p>Authenticating...</p>;
                case AuthenticationState.Unauthenticated:
                  return <Button onClick={login}>Login</Button>;
                default:
                  return <Button onClick={login}>Login</Button>;
              }
            }}
          </AzureAD>
        )
      }
    </AuthContextProvider>
  );
};

export default AuthApp;
