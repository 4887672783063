import React from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import CircleIcon from '@material-ui/icons/FiberManualRecordRounded';
import { useStyles } from './MenuItem.styles';

interface IMenuItemProps {
  ellipsis?: boolean;
  icon: React.ReactNode;
  label?: string;
  to: string;
  exact: boolean;
  onClick?: () => void;
  hiddenFromFocus?: boolean;
  hideActiveSymbol?: boolean;
}

function MenuItem(props: IMenuItemProps) {
  const { ellipsis, label } = props;
  const classes = useStyles();

  return (
    <li className={classes.listItem}>
      <NavLink
        exact={props.exact}
        to={props.to}
        activeClassName={classes.active}
        className={classes.root}
        onClick={props.onClick}
        tabIndex={props.hiddenFromFocus ? -1 : 0}
      >
        <div className={classes.icon} data-testid="icon">
          {props.icon}
        </div>

        {label && (
          <Tooltip title={label} disableHoverListener={!ellipsis}>
            <Typography variant="caption" className={ellipsis ? classes.bigLabel : classes.label}>
              {label}
            </Typography>
          </Tooltip>
        )}

        {!props.hideActiveSymbol && (
          <div className={classes.activeSymbol}>
            <CircleIcon aria-label="Detta menyalternativet är aktivt" className={classes.circleIcon} />
          </div>
        )}
      </NavLink>
    </li>
  );
}

MenuItem.defaultProps = {
  exact: false,
} as Partial<IMenuItemProps>;

export default MenuItem;
