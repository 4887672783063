import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: '1rem',
  },
  nav: {
    display: 'flex',
    padding: '0 0.25rem 0 1.5rem',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: theme.shape.borderRadius,
    flexGrow: 1,
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      minHeight: '3.75rem',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 0 0 0.75rem',
    },
  },
  mobile: {
    padding: '0',
    border: 'none',
  },
  list: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    position: 'relative',
  },
  listItem: {
    marginRight: '1.5rem',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.grey[600],
    '& > *': {
      borderBottom: '2px solid transparent',
      borderTop: '2px solid transparent',
      padding: '1rem 0',
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
        padding: '0.5rem 0 0.25rem',
        marginBottom: '0.25rem',
      },
    },
  },
  active: {
    color: theme.palette.common.black,
    '& > *': {
      borderBottomColor: theme.palette.primary.main,
    },
  },
  iIcon: {
    padding: '0.5rem',
  },
  userDropDownMenu: {
    alignItems: 'center',
    width: '33.3%',
    [theme.breakpoints.down('md')]: {
      width: '20%',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      flexGrow: 1,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    paddingRight: 10,
    display: 'flex',
    justifyContent: 'flex-end',
  },

  textContainer: {
    flexGrow: 1,
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    '& > *': {
      [theme.breakpoints.down('md')]: {
        fontSize: '1.4rem',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  logotypeLinkNameDay: {
    width: '33.3%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      flexGrow: 1,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  dateAndName: {
    textTransform: 'capitalize',
    padding: '0.5rem',
    fontSize: '1.125rem',
    lineHeight: 0,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  navigationIcon: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5rem',
    color: theme.palette.common.black,
    textDecoration: 'none',
    [theme.breakpoints.up('sm')]: {
      outlineColor: theme.palette.primary.contrastText,
      '&:focus': {
        '& > div': {
          backgroundColor: theme.palette.grey[300],
        },
      },
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      padding: '1rem',
      '&:focus': {
        borderColor: theme.palette.common.white,
        outline: 0,
      },
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.white,
    [theme.breakpoints.up('sm')]: {
      color: theme.palette.grey[800],
      padding: '1rem',
      marginBottom: '0.25rem',
      borderRadius: theme.shape.borderRadius,
      border: `2px solid ${theme.palette.grey[200]}`,
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: '1rem',
    },
  },
}));
