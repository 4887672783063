import { useCallback } from 'react';
import { useGetSurveyList } from '../../../Surveys/Surveys.api';
import { AsyncSelect } from '../../../../../../../Shared/Select';
import React from 'react';

export interface IProps {
  onChange?: (surveyId: string | undefined) => void;
  required?: boolean;
  selectedSurveyId?: number | undefined;
  disabled?: boolean;
  fullwidth?: boolean;
}

const SurveySelect = (props: IProps) => {
  const { isLoading, getSurveys } = useGetSurveyList();

  const loadSurveys = useCallback(
    async (query?: string) => {
      const response = await getSurveys({
        query: query,
        usedAsSelectList: true,
      });
      var list = response?.items.map((x) => ({ value: x.id.toString(), label: x.name })) ?? [];
      return list;
    },
    [getSurveys],
  );

  return (
    <AsyncSelect
      label="Välj utskick"
      fullwidth={props.fullwidth}
      required={props.required}
      isDisabled={props.disabled}
      isLoading={isLoading}
      selectedOptionId={props.selectedSurveyId?.toString()}
      loadOptions={loadSurveys}
      onChange={(value) => {
        props.onChange && props.onChange(value?.value);
      }}
    />
  );
};
export default SurveySelect;
