import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import './RichTextEditor.css';
import { PreselectionPreviewDummyData } from '../../AuthApp/Modules/Admin/Components/Preselection/PreselectionDummydata';

const RichTextEditor = (props: {
  height?: number;
  menuBar?: boolean;
  className?: string;
  footer?: string;
  termsAndCondtions?: string;
  header?: string;
  value: string;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  type?: string;
  viewOnly?: boolean;
  overrideConfig?: Record<string, any>;
}) => {
  var preProssesInnerHtml: any;
  const config = {
    height: props.height ?? 400,
    width: '100%',
    branding: true,
    themes: 'modern',
    mobile: {
      theme: 'silver',
      plugins: ['autosave', 'lists', 'autolink'],
    },
    selector: 'textarea#image-tools',
    plugins: [
      'advlist autolink lists link charmap print anchor image code',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime table paste code',
      'hr pagebreak nonbreaking anchor',
      'preview imagetools',
      'table',
      'code'
    ],
    table_default_styles: {
        width: '100%'
    },
    pagebreak_separator: '<!-- my page break -->',
    pagebreak_split_block: true,
    menubar: props.menuBar ? 'file edit view insert format tools table tc help' : false,
    toolbar: props.viewOnly
      ? ''
      : 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  save print | insertfile  link image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment | preview | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | code',
    browser_spellcheck: true,
    automatic_uploads: true,
    file_picker_types: 'file image media',
    image_caption: true,
    paste_data_images: true,
    content_css: './Preview.css',
    content_style:
      'body img.md\\:float-right{ float: right; } .headerStyle{ height: 80px; white-space: nowrap; overflow: hidden;  }',
    image_class_list: [
      { title: 'Left', value: 'w-full md:float-left' },
      { title: 'Right', value: 'w-full md:float-right' },
    ],

    toolbar_mode: 'sliding',
    a11y_advanced_options: true,
    language: 'sv_EN',
    urlconverter_callback: function (url: string) {
      if (!url.startsWith('http') && !url.includes('blob')) {
        if (url.indexOf('.') > -1) {
          return `http://${url}`;
        }
      }
      return url;
    },
    images_dataimg_filter: function (img: any) {
      return img.hasAttribute('internal-blob');
    },
    init_instance_callback: function (editor: any) {
      editor.on('BeforeExecCommand', function (e: any) {
        if (e.command === 'mcePreview') {
          preProssesInnerHtml = editor.getContent();

          switch (props.type) {
            case 'Agreement':
              return editor.setContent(
                PreselectionPreviewDummyData(props.header, props.footer, undefined, preProssesInnerHtml),
              );
            case 'Header':
              return editor.setContent(
                PreselectionPreviewDummyData(preProssesInnerHtml, props.footer, undefined, props.termsAndCondtions),
              );
            case 'Footer':
              return editor.setContent(
                PreselectionPreviewDummyData(props.header, preProssesInnerHtml, undefined, props.termsAndCondtions),
              );
            case 'customDocument':
              return editor.setContent(
                PreselectionPreviewDummyData(props.header, props.footer, preProssesInnerHtml, props.termsAndCondtions),
              );
            default:
              return;
          }
        }
      });
      editor.on('ExecCommand', function (e: any) {
        if (e.command === 'mcePreview') {
          editor.setContent(preProssesInnerHtml);
        }
      });
    },
  } as Record<string, any>;

  const init = props.overrideConfig ? { ...config, ...props.overrideConfig } : config;

  return (
    <Editor
      disabled={props.viewOnly}
      init={init}
      apiKey="iikkoud02kovuwq5h1wsft3evjw9o4lgdrto0irkohu62zio" // Temporary API key.
      value={props.value}
      onEditorChange={props.onChange}
      onFocus={() => props.onFocus && props.onFocus()}
      onBlur={() => props.onBlur && props.onBlur()}
    />
  );
};

export default RichTextEditor;
