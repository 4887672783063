import Button from '../../../../../../Shared/Button/Button';
import React, { useState } from 'react';
import DialogForm from '../../../../../../Shared/DialogForm/DialogForm';
import CustomerSelect from '../../CustomerSelect/CustomerSelect';
import { HandleAgreementChoice, useUpdateFacilityCustomer } from '../Facilities.api';
import { isErrorResponse } from '../../../../../../Shared/Api/response/IErrorRespose';
import { FormControlLabel, Radio } from '@material-ui/core';
import PopperMenu from '../../../../../../Shared/Popper/Popper';
import RadioField from '../../../../../../Shared/InputFields/RadioField/RadioField';
import useErrorMessage from '../../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import { useStyles } from './FacilityChangeCustomer.styles';

interface IFacilityChangeCustomerProps {
  onUpdateCustomer: () => void;
  facilityId: string;
  currentCustomerId: string;
  onCancel: () => void;
}

const FacilityChangeCustomer = (props: IFacilityChangeCustomerProps) => {
  const { handleUpdateFaclityCustomer, isUpdateLoading } = useUpdateFacilityCustomer(props.currentCustomerId);
  const { setErrorMessage } = useErrorMessage();

  const [selectedCustomerId, setSelectedCustomerId] = useState<string>();
  const [selectedCustomerName, setSelectedCustomerName] = useState<string>();
  const [selectedHandleAgreement, setSelectedHandleAgreement] = useState<HandleAgreementChoice>(
    HandleAgreementChoice.KeepAgreement,
  );
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  async function changeFacilityCustomer() {
    const result = await handleUpdateFaclityCustomer(
      {
        updatedCustomerId: Number(selectedCustomerId),
        handleSystemAgreement: selectedHandleAgreement,
      },
      props.facilityId,
    );

    if (isErrorResponse(result)) {
      setErrorMessage({ message: result.message });
    } else {
      setOpen(false);
      props.onUpdateCustomer();
    }
  }

  const PopupConfirmation = () => (
    <DialogForm
      actions={
        <>
          <Button disabled={isUpdateLoading} color="default" variant="outlined" onClick={() => setOpen(false)}>
            Nej
          </Button>
          <Button
            disabled={isUpdateLoading}
            className={classes.cautionButton}
            variant="outlined"
            onClick={changeFacilityCustomer}
          >
            Ja
          </Button>
        </>
      }
    >
      <div className={classes.padding}>
        Är du säker på att du vill flytta platsen till kund {selectedCustomerName}?
        <ul>
          <li>Åtgärds- och användarlista av alla system kommer nollställas.</li>
          <li>Detta är en oåterkallelig åtgärd!</li>
        </ul>
      </div>
    </DialogForm>
  );

  const Action = () => (
    <>
      <Button disabled={isUpdateLoading} variant="outlined" onClick={props.onCancel}>
        Avbryt
      </Button>
      <PopperMenu
        setOpen={setOpen}
        open={open}
        className={classes.cautionButton}
        variant="outlined"
        customContent
        alwaysDialog
        disabled={!selectedCustomerId || isUpdateLoading}
        title={'Är du säker?'}
        content={<PopupConfirmation />}
      >
        Bekräfta ändringen
      </PopperMenu>
    </>
  );

  return (
    <DialogForm actions={<Action />}>
      <CustomerSelect
        required
        fullwidth
        selectedCustomerId={selectedCustomerId}
        onChange={(selectedCustomerId, selectedCustomerName) => {
          setSelectedCustomerId(selectedCustomerId);
          setSelectedCustomerName(selectedCustomerName);
        }}
      />
      <div className={classes.padding}>
        <RadioField label="Avtalhantering">
          <FormControlLabel
            label={'Flytta även avtal'}
            control={<Radio color="primary" />}
            checked={selectedHandleAgreement === HandleAgreementChoice.MoveAgreement}
            onChange={() => setSelectedHandleAgreement(HandleAgreementChoice.MoveAgreement)}
            disabled={!selectedCustomerId}
          />
          <FormControlLabel
            label={'Säg upp avtal'}
            control={<Radio color="primary" />}
            checked={selectedHandleAgreement === HandleAgreementChoice.TerminateAgreement}
            onChange={() => setSelectedHandleAgreement(HandleAgreementChoice.TerminateAgreement)}
            disabled={!selectedCustomerId}
          />
          <FormControlLabel
            label={'Behålla avtal'}
            control={<Radio color="primary" />}
            checked={selectedHandleAgreement === HandleAgreementChoice.KeepAgreement}
            onChange={() => setSelectedHandleAgreement(HandleAgreementChoice.KeepAgreement)}
            disabled={!selectedCustomerId}
          />
        </RadioField>
      </div>
    </DialogForm>
  );
};

export default FacilityChangeCustomer;
