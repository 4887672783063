import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { TableHead, TableRow, TableCell, TableBody, useMediaQuery, Tooltip, Grid } from '@material-ui/core';
import { AccessRights, ModuleIdentifiers } from '../../../../../../Core/Authentication/ModuleAccess';
import AccessGuard from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { Table } from '../../../../../../Shared/Table/Table';
import useTable from '../../../../../../Shared/Table/useTable';
import TablePaginationWithDatasource from '../../../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import SearchInput from '../../../../../../Shared/InputFields/SearchInput/SearchInput';
import SortButton from '../../../../../../Shared/Table/SortButton/SortButton';
import Select, { ISelectOption } from '../../../../../../Shared/Select/Select';
import Datepicker from '../../../../../../Shared/InputFields/DatePicker/Datepicker';
import { SaleType, SaleTypeLabel, SaleTypes } from '../../../../../Shared/SaleType';
import { MobileTable } from '../../../../../../Shared/Table/MobileTable/MobileTable';
import { MobileTableRow } from '../../../../../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import formatPrice from '../../../../../../Shared/Formatting/formatPrice';
import { SortDirection } from '../../../../../../Shared/Api/request/IPaginatedRequest';
import CompanyUserSelect from '../../../../Admin/Components/Users/UserSelect/CompanyUserSelect';
import { statusList, SaleStatus, SaleStatusLabel, WorkflowStatus, useCancelSale, ISaleListItem } from '../../Sale.api';
import { useGetOrders } from '../Order.api';
import theme from '../../../../../../Styles/Theme';
import useStyles from './OrderList.styles';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import IconButton from '../../../../../../Shared/IconButton/IconButton';
import formatDate from '../../../../../../Shared/Formatting/formatDate';
import { ConfirmDeleteOrArchiveRow } from '../../../../../../Shared/Table/ConfirmDeleteOrArchiveRow/ConfirmDeleteOrArchiveRow';
import { ViewField } from '../../../../../../Shared/InputFields/InputField/ViewField';

export interface IOrderListProps {
  workflowStatus?: WorkflowStatus;
  onlyMine?: boolean;
}

export interface ISaleTypeOption extends ISelectOption {
  type: SaleType;
}

export interface IWarningMessage {
  sale?: ISaleListItem;
  warnings?: {
    status?: SaleStatus;
    hasSupplierOrder?: boolean;
    hasErrorReport?: boolean;
  };
}

const saleTypeOptions: ISaleTypeOption[] = SaleTypes.map((x) => ({
  label: x.name,
  value: x.type.toString(),
  type: x.type,
})).sort((a, b) => (a.label > b.label ? 1 : -1));

const OrderList = (props: IOrderListProps) => {
  const { workflowStatus } = props;
  const history = useHistory();
  const customerParams = new URLSearchParams(window.location.search).get('query');
  const { isLoading, orders, getOrders } = useGetOrders();
  const { isLoading: isCancelLoading, cancelSale } = useCancelSale();
  const {
    toggleSort,
    sortProperty,
    sortDirection,
    pageSize,
    setPageSize,
    setPageNumber,
    query,
    setQuery,
    paginatedRequest,
  } = useTable(100, '', SortDirection.Ascending, customerParams ?? '');
  const [emptyMessage, setEmptyMessage] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<ISelectOption>();
  const [selectedUser, setSelectedUser] = useState<ISelectOption>();
  const [createdFrom, setCreatedFrom] = useState<Date | null>(null);
  const [createdTo, setCreatedTo] = useState<Date | null>(null);
  const [type, setType] = useState<SaleType>();
  const [confirmCancelOrderId, setConfirmCancelOrderId] = useState<number | undefined>();
  const [warningMessage, setWarningMessage] = useState<IWarningMessage>();
  const [totalSum, setTotalSum] = useState<number>(0);
  const classes = useStyles();

  const mobileView = useMediaQuery(theme.breakpoints.down(1080 / 16));
  const activeWorkFlow = workflowStatus ?? WorkflowStatus.Order;

  const statusOptions: ISelectOption[] = statusList
    .filter((x) => x.workflowStatus === activeWorkFlow)
    .map((x) => ({ label: x.name, value: x.stringValue }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  const getList = useCallback(async () => {
    const response = await getOrders({
      ...paginatedRequest,
      status: selectedStatus ? [(selectedStatus.value as unknown) as SaleStatus] : undefined,
      projectLeaderId: selectedUser ? Number(selectedUser.value) : undefined,
      createdFrom,
      createdTo,
      workflowStatus: activeWorkFlow,
      type: type,
    });

    var sum = 0;
    response?.items.forEach((item, index) => {
      sum += item.totalPrice;
      if (index === response.items.length - 1) setTotalSum(sum);
    });

    if (response?.totalCount <= 0) {
      if (paginatedRequest.query) {
        setEmptyMessage('Filtreringen gav inga resultat');
      } else {
        setEmptyMessage('Inga order tillagda');
      }
    }
  }, [paginatedRequest, getOrders, selectedStatus, selectedUser, createdFrom, createdTo, activeWorkFlow, type]);

  useEffect(() => {
    getList();
  }, [getList]);

  async function handleCancelOrder() {
    if (!confirmCancelOrderId) return;

    await cancelSale(confirmCancelOrderId.toString());
    setConfirmCancelOrderId(undefined);
    setWarningMessage(undefined);
    await getList();
  }

  const handleTableClick = (order: ISaleListItem) => {
    history.push({
      pathname: history.location.pathname,
      search: query ? `?query=${query}` : '',
    });
    history.push(`${history.location.pathname}/${order.id}`);
  };

  const saleStatus = (order: ISaleListItem) => {
    return SaleStatusLabel[order.status] +
      (order.status === SaleStatus.approved
        ? ' av ' + order.approvedBy + ' ' + formatDate(order.approvedDate)
        : order.status === SaleStatus.invoicing ? ' ' + formatDate(order.approvedDate) : '')
  }

  return (
    <>
      <Table
        label={
          (workflowStatus === WorkflowStatus.Archive ? 'Arkiverade ordrar' : 'Pågående') +
          ` (${orders?.totalCount ?? 0})`
        }
        minWidth
        customSize={{
          fullsize: true,
        }}
        filters={
          <>
            <SearchInput
              defaultValue={query}
              type="search"
              placeholder="Sök"
              value={query}
              onChange={(search) => setQuery(search)}
            />
            <CompanyUserSelect
              isClearable
              placeholder="Välj projektledare"
              selectedCompanyUser={selectedUser}
              onChange={(_1, _2, option) => setSelectedUser(option as ISelectOption)}
            />
            <Select
              inputFieldProps={{ 'aria-label': 'Typ', placeholder: 'Typ' }}
              selectProps={{
                options: saleTypeOptions,
                isClearable: true,
                onChange: (x) => (x ? setType((x as ISaleTypeOption).type) : setType(undefined)),
              }}
            />
            {statusOptions.length > 1 && (
              <Select
                inputFieldProps={{ 'aria-label': 'Status', placeholder: 'Status' }}
                selectProps={{
                  isClearable: true,
                  value: selectedStatus,
                  onChange: (selectedValues) => {
                    setSelectedStatus(selectedValues as ISelectOption);
                  },
                  options: statusOptions,
                }}
              />
            )}
            <Datepicker
              isClearable
              inFilter
              placeholderText="Skapad fr.o.m"
              selected={createdFrom}
              onChange={setCreatedFrom}
            />
            <Datepicker
              isClearable
              inFilter
              placeholderText="Skapad t.o.m"
              selected={createdTo}
              onChange={setCreatedTo}
            />
            <Grid item sm={12} md={6} style={{display: "flex", alignItems: "center", justifyContent: "flex-end", marginLeft: "auto"}}>
              <span>{workflowStatus === WorkflowStatus.Invoicing && `Fakturavärde: ${totalSum} SEK`}</span>
            </Grid>
          </>
        }
        iconButtons={
          <AccessGuard module={ModuleIdentifiers.Orders} accessRights={AccessRights.Write}>
            <IconButton
              size="small"
              aria-label="Lägg till order"
              onClick={() => {
                history.push(`${history.location.pathname}/create`);
              }}
            >
              <AddIcon />
            </IconButton>
          </AccessGuard>
        }
        pagination={
          orders && (
            <TablePaginationWithDatasource
              datasource={orders}
              onChangePage={setPageNumber}
              onChangeRowsPerPage={setPageSize}
            />
          )
        }
        empty={{
          empty: orders && orders.totalCount <= 0 ? true : false,
          message: emptyMessage,
        }}
        loading={{
          loading: isLoading,
          skeletonRows: pageSize,
        }}
        mobile={mobileView}
      >
        {mobileView ? (
          <>
            {orders?.items.map((order) => (
              <MobileTable
                key={order.id}
                link={`${history.location.pathname}/${order.id}`}
                label={`${order.saleIdentifier} - ${order.customerName}`}
              >
                <MobileTableRow label="Kund" value={order.customerName} />
                <MobileTableRow label="Order nr" value={order.saleIdentifier} />
                <MobileTableRow label="Projektledare" value={order.projectLeader} />
                <MobileTableRow label="Typ" value={SaleTypeLabel[order.type]} />
                <MobileTableRow label="Kommentar" value={order.description} />
                <MobileTableRow
                  label="Status"
                  value={
                    SaleStatusLabel[order.status] +
                    (order.status === SaleStatus.approved
                      ? ' av ' + order.approvedBy + ' ' + formatDate(order.approvedDate)
                      : '')
                  }
                />
                <MobileTableRow label="Summa" value={formatPrice(order.totalPrice)} />
              </MobileTable>
            ))}
          </>
        ) : (
          <>
            <TableHead>
              <TableRow>
                <TableCell>
                  Kund
                  <SortButton
                    property="customerName"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>
                <TableCell className={classes.mediumColumn}>
                  Order nr
                  <SortButton
                    property="saleIdentifier"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>
                <TableCell>
                  Projektledare
                  <SortButton
                    property="projectLeader"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>
                <TableCell>
                  Typ
                  <SortButton
                    property="type"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>
                <TableCell>Rubrik</TableCell>
                <TableCell>Beskrivning</TableCell>
                <TableCell>
                  Status
                  <SortButton
                    property="status"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>
                <TableCell>
                  Summa
                  <SortButton
                    property="totalPrice"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders?.items.map((order) => (
                <TableRow className={classes.pointerCursor} hover tabIndex={-1} key={order.id}>
                  {confirmCancelOrderId !== order.id || warningMessage?.warnings ? (
                    <>
                      <TableCell onClick={() => handleTableClick(order)}> {order.customerName}</TableCell>
                      <TableCell onClick={() => handleTableClick(order)} className={classes.font+classes.mediumColumn}>
                        {order.saleIdentifier}
                      </TableCell>
                      <TableCell onClick={() => handleTableClick(order)}>{order.projectLeader}</TableCell>
                      <TableCell onClick={() => handleTableClick(order)}>{order.type === SaleType.system ? 'Installation' : SaleTypeLabel[order.type]}{order.coreSystemType && " - " + order.coreSystemType}</TableCell>
                      <TableCell onClick={() => handleTableClick(order)}>{order.description ?? (order.type === SaleType.system ? 'Ny installation' : order.type === SaleType.service ? 'Komplettera' : '')}</TableCell>
                      <Tooltip title={order.jobDescription ?? ''}>
                        <TableCell onClick={() => handleTableClick(order)}>
                          {order.jobDescription?.substring(0, 15) +
                            (order.jobDescription && order.jobDescription?.length > 14 ? '..' : '')}
                        </TableCell>
                      </Tooltip>
                      <TableCell onClick={() => handleTableClick(order)}>
                        {saleStatus(order)}
                      </TableCell>
                      <TableCell onClick={() => handleTableClick(order)}>{formatPrice(order.totalPrice)}</TableCell>
                    </>
                  ) : (
                    <ConfirmDeleteOrArchiveRow
                      onConfirm={handleCancelOrder}
                      onCancel={() => {
                        setConfirmCancelOrderId(undefined);
                      }}
                      colspan={8}
                      label={`ordern med order nr ${order.saleIdentifier.toString()}`}
                      loading={isCancelLoading}
                    />
                  )}
                </TableRow>
              ))}
            </TableBody>
          </>
        )}
      </Table>
    </>
  );
};

export default OrderList;
