import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './NotFound.styles';

const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <Typography variant="h2" component="h1">
          Länken är inte längre aktiv
        </Typography>
      </header>
      <Typography>
          Länken har utgått eller är redan använd. Var vänlig kontakta avsändaren för att få hjälp.
      </Typography>
    </div>
  );
};

export default NotFound;
