import React, { useEffect, useCallback } from 'react';
import { useGetSystemTypes } from '../SystemTypes.api';
import { AsyncSelect, ISelectOption } from '../../../../../../Shared/Select';
import { GridSize } from '@material-ui/core';

export interface IProps {
  onChange?: (systemTypeId: string | undefined, systemTypeName: string | undefined) => void;
  required?: boolean;
  isDisabled?: boolean;
  selectedSystemType?: ISelectOption;
  selectedSystemTypeId?: string;
  customWidth?: GridSize;
}

const SystemTypeSelect = (props: IProps) => {
  const { response: systemTypes, getSystemTypes, isLoading } = useGetSystemTypes();
  const { selectedSystemType, selectedSystemTypeId } = props;
  const pageSize = 100;

  const loadSystemTypes = useCallback(
    async (query?: string) => {
      const response = await getSystemTypes({
        pageNumber: 1,
        pageSize: pageSize,
        query: query,
        usedAsSelectList: true,
      });
      return response.items.map((x) => ({ value: x.id.toString(), label: x.name }));
    },
    [pageSize, getSystemTypes],
  );

  useEffect(() => {
    if (!systemTypes)
      getSystemTypes({
        pageNumber: 1,
        pageSize: pageSize,
      });
  }, [pageSize, systemTypes, getSystemTypes]);

  return (
    <AsyncSelect
      label="Välj systemtyp"
      required={props.required}
      isDisabled={props.isDisabled}
      isLoading={isLoading}
      selectedOption={selectedSystemType}
      selectedOptionId={selectedSystemTypeId}
      loadOptions={loadSystemTypes}
      onChange={(value) => {
        props.onChange && props.onChange(value?.value, value?.label);
      }}
      customWidth={props.customWidth}
    />
  );
};
export default SystemTypeSelect;
