import React, { useMemo } from 'react';
import {
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@material-ui/core';
import { ValueType } from 'react-select';
import {
  AccessRights,
  accessRights,
  accessRightsGroups,
  AccessRightsGroup,
  getGroupAccessRights,
  moduleHasAdminRight,
  ModuleIdentifiers,
  modules,
} from '../../../../../../../Core/Authentication/ModuleAccess';
import { Fieldset } from '../../../../../../../Shared/Form/Fieldset/Fieldset';
import InlineButton from '../../../../../../../Shared/Button/InlineButton';
import { MobileTable } from '../../../../../../../Shared/Table/MobileTable/MobileTable';
import Select, { ISelectOption } from '../../../../../../../Shared/Select/Select';
import { Table } from '../../../../../../../Shared/Table/Table';
import { getSelectedOption, getSelectedValue } from '../../../../../../Shared/utils';
import { ICompanyUserModuleAccess, Role, roleLists } from '../../Users.api';
import { useStyles } from '../EditUser.styles';
import theme from '../../../../../../../Styles/Theme';
import IconButton from '../../../../../../../Shared/IconButton/IconButton';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutlineRounded';

function getAccessRightsOptions(module: ModuleIdentifiers): ISelectOption[] {
  var accessRightsOptions = accessRights.map((x) => ({
    value: (x.accessRight as number).toString(),
    label: x.name,
  }));
  if (!moduleHasAdminRight(module)) accessRightsOptions.pop();
  return accessRightsOptions;
}

const accessRightsGroupOptions: ISelectOption[] = accessRightsGroups.map((x) => ({
  value: (x.type as number).toString(),
  label: x.name,
}));

const roleOptions: ISelectOption[] = roleLists.map((x) => ({
  value: (x.role as number).toString(),
  label: x.name,
}));

interface ICommonProps {
  userModuleAccess: ICompanyUserModuleAccess[];
  setUserModuleAccess: React.Dispatch<React.SetStateAction<ICompanyUserModuleAccess[]>>;
  userRoles: Role[];
  setUserRole: React.Dispatch<React.SetStateAction<Role[]>>;
}
interface ICompanyUserAccessRightsProps extends ICommonProps {
  accessRightsGroup: AccessRightsGroup;
  setAccessRightsGroup: React.Dispatch<React.SetStateAction<AccessRightsGroup>>;
}

interface IAccessRightsTableProps extends ICommonProps {
  disabled: boolean;
}

interface IAccessRightsSelectProps extends IAccessRightsTableProps {
  moduleIdentifier: ModuleIdentifiers;
  mobileView: boolean;
}

const AccessRightsSelect = (props: IAccessRightsSelectProps) => {
  const { moduleIdentifier, userModuleAccess, setUserModuleAccess } = props;

  const options = useMemo(() => getAccessRightsOptions(moduleIdentifier), [moduleIdentifier]);
  const selected = useMemo(() => {
    var moduleAccess = userModuleAccess.find((x) => x.moduleIdentifier === moduleIdentifier);
    if (moduleAccess === undefined) return options[0];
    return options.find((x) => x.value === (moduleAccess!.accessRights as number).toString());
  }, [moduleIdentifier, options, userModuleAccess]);

  function handleModuleAccessChange(selected: ValueType<ISelectOption>) {
    const newUserModuleAccess = [...userModuleAccess];
    let current = newUserModuleAccess.find((x) => x.moduleIdentifier === moduleIdentifier);
    const accessRights = selected ? (parseInt((selected as ISelectOption).value) as AccessRights) : AccessRights.Denied;
    if (current) current.accessRights = accessRights;
    else newUserModuleAccess.push({ moduleIdentifier, accessRights });

    setUserModuleAccess(newUserModuleAccess);
  }

  return (
    <Select
      inputFieldProps={props.mobileView ? { label: 'Behörighet' } : { placeholder: 'Välj rättighet', fullwidth: true }}
      selectProps={{
        value: selected,
        isDisabled: props.disabled,
        options: options,
        onChange: handleModuleAccessChange,
      }}
    />
  );
};

const AccessRightsTable = (props: IAccessRightsTableProps) => {
  const classes = useStyles();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Table mobile={mobileView} customSize={{ fullsize: true }}>
      {mobileView ? (
        modules.map((module) => (
          <MobileTable key={module.name} label={module.name}>
            <div className={classes.mobilePermissions}>
              <div>
                <AccessRightsSelect {...props} moduleIdentifier={module.identifier} mobileView={mobileView} />
              </div>
            </div>
          </MobileTable>
        ))
      ) : (
        <>
          <TableHead>
            <TableRow>
              <TableCell>Modul</TableCell>
              <TableCell>Behörighet</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {modules.map((module) => (
              <TableRow key={module.identifier}>
                <TableCell>{module.name}</TableCell>
                <TableCell>
                  <AccessRightsSelect {...props} moduleIdentifier={module.identifier} mobileView={mobileView} />
                </TableCell>
              </TableRow>
            ))}
            <TableRow key={"tidrapport"}>
                <TableCell>Tidrapporter</TableCell>
                <TableCell>
                  Räkna ej timmar <Checkbox checked={props.userRoles.find(r => r === Role.Contractor) ? true : false} onClick={(checked) => props.setUserRole(checked ? [...props.userRoles, Role.Contractor] : props.userRoles.filter(r => r !== Role.Contractor))} />
                </TableCell>
              </TableRow>
            <Grid xs={12} item style={{padding: "1em"}}>
              <Select
                inputFieldProps={ mobileView ? { label: 'Behörighet' } : { placeholder: 'Välj rättighet', fullwidth: true } }
                selectProps={{
                  options: roleOptions.filter((x) => !props.userRoles.some((y) => y.toString() === x.value)),
                  isDisabled: roleOptions.filter((x) => !props.userRoles.some((y) => y.toString() === x.value)).length === 0,
                  onChange: (selected) =>
                    selected && props.setUserRole((x) => [...x, parseInt((selected as ISelectOption).value) as Role]),
                }}
              />
              <List>
                {props.userRoles.filter(r => r !== Role.Contractor).map((role) => (
                  <ListItem key={role} disableGutters>
                    <ListItemText primary={roleLists.find((y) => y.role === role)?.name} />
                    <ListItemSecondaryAction>
                      <IconButton
                        aria-label="Ta bort roll"
                        onClick={() => props.setUserRole((x) => x.filter((y) => y !== role))}
                        color="error"
                      >
                        <RemoveIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </TableBody>
        </>
      )}
    </Table>
  );
};

export const CompanyUserAccessRights = (props: ICompanyUserAccessRightsProps) => {
  const { accessRightsGroup, setUserModuleAccess, setAccessRightsGroup, setUserRole } = props;

  function handleAccessRightsGroupChange(selected: ValueType<ISelectOption>) {
    var selectedAccessRightsGroup = getSelectedValue(selected);
    setAccessRightsGroup(selectedAccessRightsGroup ?? AccessRightsGroup.Custom);
    if (selectedAccessRightsGroup) {
      var [roles, moduleAccess] = getGroupAccessRights(selectedAccessRightsGroup);
      setUserModuleAccess(moduleAccess);
      setUserRole(roles);
    }
  }

  return (
    <>
      <Fieldset legend="Behörigheter">
        <Select
          inputFieldProps={{ label: 'Behörighetsgrupp', placeholder: 'Välj behörighetsgrupp', required: true }}
          selectProps={{
            value:
              getSelectedOption(accessRightsGroupOptions, accessRightsGroup) ??
              accessRightsGroupOptions.find((x) => Number(x.value) === AccessRightsGroup.Custom),
            options: accessRightsGroupOptions,
            onChange: handleAccessRightsGroupChange,
          }}
        />

        <InlineButton
          color="secondary"
          variant="contained"
          justify="flex-start"
          onClick={() => setAccessRightsGroup(AccessRightsGroup.Custom)}
          disabled={accessRightsGroup === AccessRightsGroup.Custom}
        >
          Modifiera förvalda behörigheter
        </InlineButton>

        <AccessRightsTable {...props} disabled={accessRightsGroup !== AccessRightsGroup.Custom} />
      </Fieldset>
    </>
  );
};
