import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../../../../Shared/Button/Button';
import Container from '../../../../../Shared/Container/Container';
import { Fieldset } from '../../../../../Shared/Form/Fieldset/Fieldset';
import { InputField } from '../../../../../Shared/InputFields/InputField/InputField';
import { ErrorCode, isErrorResponse } from '../../../../../Shared/Api/response/IErrorRespose';
import { isResultError } from '../../../../../Shared/Api/response/ICreateResult';
import formatDate from '../../../../../Shared/Formatting/formatDate';
import useErrorMessage from '../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import useFormValidation from '../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import useInputState from '../../../../../Shared/Hooks/UseInputState/UseInputState';
import CompanyUserSelect from '../../../Admin/Components/Users/UserSelect/CompanyUserSelect';
import BMSNodeSelect from './BMSNodeSelect';
import {
  IBMSSnapshotDto,
  IUpsertBMSNodeCommand,
  useDeleteBMSNode,
  useGetBMSNode,
  useUpsertBMSNode,
} from '../../BMS.api';
import useUserContext from '../../../../../Core/Authentication/UserContext';
import RichTextEditor from '../../../../../Shared/RichTextEditor/RichTextEditor';
import SelectOldNode from './SelectOldNode/SelectOldNode';
import { useStyles } from './EditNode.styles';
import ViewOldVersionModal from './ViewOldVersionModal/ViewOldVersionModal';
import AccessGuard from '../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { AccessRights, ModuleIdentifiers } from '../../../../../Core/Authentication/ModuleAccess';

const EditBMSNode = (props: { triggerRefetch: () => void }) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { user: currentCompanyUser } = useUserContext();
  const { isLoading: isGetLoading, node, getNode } = useGetBMSNode();
  const { isLoading: isUpsertLoading, upsert } = useUpsertBMSNode();
  const { isLoading: isDeleteLoading, deleteNode } = useDeleteBMSNode();
  const { isFormValid, formRef } = useFormValidation();
  const { setValidationErrorMessage, setErrorMessage } = useErrorMessage();
  const redirectPath = id
    ? history.location.pathname.replace(/\/update$/, '')
    : history.location.pathname.replace('/create', '');

  const [sectionNumber, changeSectionNumber, setSectionNumber] = useInputState('');
  const [name, changeName, setName] = useInputState('');
  const [topic, changeTopic, setTopic] = useInputState('');
  const [isoVersion, changeISOVersion, setISOVersion] = useInputState('');
  const [issuedBy, setIssuedBy] = useState<number>();
  const [determinedBy, setDeterminedBy] = useState<number>();
  const [processOwner, setProcessOwner] = useState<number>();
  const [oldVersion, setOldVersion] = useState<IBMSSnapshotDto | undefined>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [parentNode, setParentNode] = useState<number>(0);
  const [content, setContent] = useState('');

  const classes = useStyles();
  const isValid = !!isFormValid && !!issuedBy && !!determinedBy && !!processOwner;

  useEffect(() => {
    async function loadNode() {
      var response = await getNode(id);
      setSectionNumber(response.sectionNumber);
      setName(response.name);
      setTopic(response.topic);
      setISOVersion(response.isoVersion);
      setIssuedBy(response.issuedByCompanyUserId);
      setDeterminedBy(response.determinedByCompanyUserId);
      setProcessOwner(response.processOwnerCompanyUserId);
      setParentNode(response.parentNodeId || 0);
      setContent(response.content);
    }
    if (id) loadNode();
    else {
      setSectionNumber('');
      setName('');
      setTopic('');
      setISOVersion('');
      setIssuedBy(currentCompanyUser.selectedUserId ?? undefined);
      setDeterminedBy(currentCompanyUser.selectedUserId ?? undefined);
      setProcessOwner(currentCompanyUser.selectedUserId ?? undefined);
    }
  }, [
    id,
    currentCompanyUser.id,
    currentCompanyUser.selectedUserId,
    getNode,
    setSectionNumber,
    setName,
    setTopic,
    setISOVersion,
    setContent,
  ]);

  async function upsertBMSNode(newVersion: boolean = false) {
    if (!name || !issuedBy || !determinedBy || !processOwner) return;
    const upsertCommand: IUpsertBMSNodeCommand = {
      parentNodeId: parentNode === 0 ? null : parentNode,
      sectionNumber: sectionNumber,
      name: name,
      topic: topic,
      isoVersion: isoVersion,
      issuedByCompanyUserId: issuedBy!,
      determinedByCompanyUserId: determinedBy!,
      processOwnerCompanyUserId: processOwner!,
      content: content,
      isNewVersion: newVersion,
    };
    const result = await upsert(upsertCommand, id);
    if (isResultError(result)) {
      setValidationErrorMessage(result);
    } else {
      history.push(`/bms/${result}`);
      props.triggerRefetch();
    }
  }

  async function handleDeleteBMSNode() {
    const result = await deleteNode(id!);
    if (isErrorResponse(result) && result.errorCode === ErrorCode.EntityInUse) {
      setErrorMessage({
        message:
          'Gick inte att ta bort verksamhetsledningssystem då det är inte möjligt att radera ett dokument som har underdokument',
      });
    } else {
      history.push(history.location.pathname.replace(/\/\d+\/update$/, ''));
      props.triggerRefetch();
    }
  }

  function Actions() {
    const cancel = () => history.push(redirectPath);
    return (
      <>
        <Button variant="outlined" onClick={cancel}>
          Avbryt
        </Button>
        {node && !node.hasChildren && (
          <AccessGuard module={ModuleIdentifiers.BusinessManagementSystem} accessRights={AccessRights.Full}>
            <Button color="primary" variant="contained" onClick={handleDeleteBMSNode} loading={isDeleteLoading}>
              Radera
            </Button>
          </AccessGuard>
        )}
        <Button
          color="primary"
          variant="contained"
          onClick={() => upsertBMSNode()}
          loading={isUpsertLoading}
          disabled={!isValid}
        >
          Spara
        </Button>
        {node && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => upsertBMSNode(true)}
            loading={isUpsertLoading}
            disabled={!isValid}
          >
            Spara som ny version
          </Button>
        )}
      </>
    );
  }

  return (
    <>
      <Container
        customSize={{ fullsize: true }}
        form
        loading={isGetLoading}
        label={id ? 'Redigera avsnitt' : 'Skapa avsnitt'}
        actions={<Actions />}
        ref={formRef}
      >
        <Fieldset legend="Uppgifter">
          {node && <InputField label="Skapad" type="text" value={formatDate(node.created)} viewOnly />}
          {node && <InputField label="Ändrad" type="text" value={formatDate(node.lastModified)} viewOnly />}
          <InputField label="Avsnitt nr" value={sectionNumber} onChange={changeSectionNumber} type="text" />
          <InputField required label="Dokumentnamn" value={name} onChange={changeName} type="text" />
          <InputField label="Rubrik" value={topic} onChange={changeTopic} type="text" />
          <InputField label="ISO Version" value={isoVersion} onChange={changeISOVersion} type="text" />
          <CompanyUserSelect
            required
            label="Utfärdad av"
            inline={false}
            selectedCompanyUserId={issuedBy?.toString()}
            onChange={(id) => setIssuedBy(id ? Number(id) : undefined)}
          />
          <CompanyUserSelect
            required
            label="Fastställd av"
            inline={false}
            selectedCompanyUserId={determinedBy?.toString()}
            onChange={(id) => setDeterminedBy(id ? Number(id) : undefined)}
          />
          <CompanyUserSelect
            required
            label="Processägare"
            inline={false}
            selectedCompanyUserId={processOwner?.toString()}
            onChange={(id) => setProcessOwner(id ? Number(id) : undefined)}
          />
          <div className={classes.versionContainer}>
            <InputField fullWidth label="Version" type="text" value={node?.version || '1'} viewOnly />
            {node && node.olderVersions.length > 0 && (
              <SelectOldNode
                id={id}
                setOldVersion={setOldVersion}
                setOpenModal={setOpenModal}
                oldVersions={node ? node.olderVersions : []}
              />
            )}
          </div>
        </Fieldset>

        <Fieldset legend="Plats och innehåll">
          <BMSNodeSelect
            label="Förälder"
            fullwidth
            required
            nodeId={id ? Number(id) : undefined}
            selectedNodeId={parentNode ?? undefined}
            onChange={(id) => setParentNode(id ? Number(id) : 0)}
          />
        </Fieldset>
        <RichTextEditor menuBar={false} value={content} onChange={setContent} />
      </Container>
      <ViewOldVersionModal snapshot={oldVersion} open={openModal && !!oldVersion} setOpen={setOpenModal} />
    </>
  );
};

export default EditBMSNode;
