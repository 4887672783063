import React, { useState } from 'react';
import AccessGuard from '../../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { ModuleIdentifiers, AccessRights } from '../../../../../../../Core/Authentication/ModuleAccess';
import PopperMenu from '../../../../../../../Shared/Popper/Popper';
import TodoForm from '../../../../../Todos/Components/TodoForm/TodoForm';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';

export interface IAddToDoProps {
  onAction: () => void;
}

export default function AddToDo(props: IAddToDoProps) {
  const [open, setOpen] = useState(false);

  function action() {
    setOpen(false);
    props.onAction();
  }

  return (
    <AccessGuard module={ModuleIdentifiers.Todo} accessRights={AccessRights.Write}>
      <PopperMenu
        setOpen={setOpen}
        open={open}
        buttonType='icon'
        alwaysDialog
        customContent
        title={'Lägg till uppgift'}
        content={<TodoForm onAction={action} isModal />}
      >
        <AddIcon />
      </PopperMenu>
    </AccessGuard>
  );
}
