import React, { useState, useEffect } from 'react';
import Button from '../../../../../Shared/Button/Button';
import InlineButton from '../../../../../Shared/Button/InlineButton';
import { useHistory, useParams } from 'react-router-dom';
import { Fieldset } from '../../../../../Shared/Form/Fieldset/Fieldset';
import { InputField } from '../../../../../Shared/InputFields/InputField/InputField';
import useInputState from '../../../../../Shared/Hooks/UseInputState/UseInputState';
import {
  AutoCreditCheckInterval,
  autoCreditCheckIntervals,
  IGetProspectLegalDataRequest,
  IUpsertCustomerCommand,
  IUpsertCustomerValidationErrors,
  useGetCustomer,
  useGetProspectLegalData,
  useUpsertCustomer,
} from '../../Customers.api';
import useFormValidation from '../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import Container from '../../../../../Shared/Container/Container';
import { isResultError } from '../../../../../Shared/Api/response/ICreateResult';
import CustomerTypeSelect from '../CustomerTypeSelect/CustomerTypeSelect';
import { CustomerType, getCustomerTypeName } from '../../../../../Shared/Misc/CustomerType';
import IdentificationNumberField from '../../../../../Shared/InputFields/IdentificationNumberField/IdentificationNumberField';
import { Checkbox, FormControlLabel, useMediaQuery } from '@material-ui/core';
import CheckboxField from '../../../../../Shared/InputFields/CheckboxField/CheckboxField';
import PostalCodeField from '../../../../../Shared/InputFields/PostalCodeField/PostalCodeField';
import EmailField from '../../../../../Shared/InputFields/EmailField/EmailField';
import theme from '../../../../../Styles/Theme';
import { ISelectOption, Select } from '../../../../../Shared/Select';
import PhoneNumberField from '../../../../../Shared/InputFields/PhoneNumberField/PhoneNumberField';

const autoCreditCheckIntervalOptions: ISelectOption[] = autoCreditCheckIntervals.map((x) => ({
  label: x.text,
  value: (x.option as number).toString(),
}));

const CustomerForm: React.FunctionComponent = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string | undefined }>();
  const { isLoading: isGetLoading, getCustomer } = useGetCustomer();
  const { isLoading: isGetNameLoading, getProspectLegalData } = useGetProspectLegalData();
  const { isLoading: isUpsertLoading, upsert } = useUpsertCustomer();
  const { isFormValid, formRef } = useFormValidation();
  const [isIdentificationExisting, setIsIdentificationExisting] = useState<boolean>();
  const [originalName, setOriginalName] = useState('');
  const [name, changeName, setName] = useInputState('');
  const [identificationNumber, changeIdentificationNumber, setIdentificationNumber] = useInputState('');
  const [customerEmail, changeCustomerEmail, setCustomerEmail] = useInputState('');
  const [customerAddress, changeCustomerAddress, setCustomerAddress] = useInputState('');
  const [customerPostalCode, changeCustomerPostalCode, setCustomerPostalCode] = useInputState('');
  const [customerCity, changeCustomerCity, setCustomerCity] = useInputState('');
  const [customerPhoneNumber, changeCustomerPhoneNumber, setCustomerPhoneNumber] = useInputState('');
  const [invoiceEmail, changeInvoiceEmail, setInvoiceEmail] = useInputState('');
  const [invoiceReference, changeInvoiceReference, setInvoiceReference] = useInputState('');
  const [invoiceAddress, changeInvoiceAddress, setInvoiceAddress] = useInputState('');
  const [invoicePostalCode, changeInvoicePostalCode, setInvoicePostalCode] = useInputState('');
  const [invoiceCity, changeInvoiceCity, setInvoiceCity] = useInputState('');
  const [selectedType, setSelectedType] = useState<CustomerType>(CustomerType.Company);
  const [noContact, setNoContact] = useState<boolean>(false);
  const [customerNumber, setCustomerNumber] = useState('');
  const [doCreditCheck, setDoCreditCheck] = useState<boolean>(false);
  const [errors, setErrors] = useState<IUpsertCustomerValidationErrors>();
  const [selectedAutoCreditCheckIntervalOption, setSelectedAutoCreditCheckIntervalOption] = useState<
    AutoCreditCheckInterval | undefined
  >();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    async function loadCustomer() {
      const customer = await getCustomer(`${id}/${false}`);
      setOriginalName(customer.name);
      setSelectedType(customer.type);
      setName(customer.name);
      setIdentificationNumber(customer.identificationNumber);
      setCustomerPhoneNumber(customer.customerPhoneNumber);
      setCustomerEmail(customer.customerEmail);
      setCustomerAddress(customer.customerAddress);
      setCustomerPostalCode(customer.customerPostalCode);
      setCustomerCity(customer.customerCity);
      setInvoiceEmail(customer.invoiceEmail);
      setInvoiceAddress(customer.invoiceAddress);
      setInvoicePostalCode(customer.invoicePostalCode);
      setInvoiceCity(customer.invoiceCity);
      setNoContact(customer.noContact);
      setInvoiceReference(customer.invoiceReference);
      setCustomerNumber(customer.customerNumber.toString());
      setSelectedAutoCreditCheckIntervalOption(customer.autoCreditCheckInterval);
    }

    if (id) {
      loadCustomer();
    }
  }, [
    getCustomer,
    id,
    setCustomerPhoneNumber,
    setCustomerEmail,
    setCustomerAddress,
    setCustomerPostalCode,
    setCustomerCity,
    setInvoiceCity,
    setInvoiceAddress,
    setInvoiceEmail,
    setIdentificationNumber,
    setName,
    setInvoicePostalCode,
    setInvoiceReference,
  ]);

  async function fetchProspectLegalData() {
    const getProspectLegalDataRequest: IGetProspectLegalDataRequest = {
      type: selectedType,
      identificationNumber: identificationNumber,
    };
    try {
      let response = await getProspectLegalData(getProspectLegalDataRequest);
      setName(response.name);
      setCustomerEmail(response.invoiceEmail);
      setCustomerAddress(response.invoiceAddress);
      setCustomerPostalCode(response.invoicePostalCode);
      setCustomerCity(response.invoiceCity);
      setErrors({ ...errors, identificationNumber: undefined });
    } catch (e) {
      setErrors({ ...errors, identificationNumber: ['Inget kundnamn hittades registrerat med detta nummer'] });
    }
  }

  async function upsertCustomer() {
    if (!selectedType) return;
    const upsertCommand: IUpsertCustomerCommand = {
      name: name,
      type: selectedType,
      identificationNumber: identificationNumber,
      customerPhoneNumber: customerPhoneNumber,
      customerEmail: customerEmail,
      customerAddress: customerAddress,
      customerPostalCode: customerPostalCode,
      customerCity: customerCity,
      invoiceReference: invoiceReference,
      invoiceEmail: invoiceEmail,
      invoiceAddress: invoiceAddress,
      invoicePostalCode: invoicePostalCode,
      invoiceCity: invoiceCity,
      noContact: noContact,
      doCreditCheck: doCreditCheck,
      autoCreditCheckInterval: selectedAutoCreditCheckIntervalOption,
    };

    const result = await upsert(upsertCommand, id);

    isResultError(result) ? setErrors(result) : history.push('/customers/' + result);
  }

  function Actions() {
    const cancel = () => (id ? history.push(`/customers/${id}`) : history.push('/customers'));

    return (
      <>
        <Button color="default" variant="outlined" onClick={cancel}>
          Avbryt
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={upsertCustomer}
          loading={isUpsertLoading}
          disabled={!isFormValid || isIdentificationExisting}
        >
          {id ? 'Spara' : 'Skapa'}
        </Button>
      </>
    );
  }

  return (
    <Container
      form
      label={id ? `Redigera ${originalName}` : 'Skapa kund'}
      actions={<Actions />}
      ref={formRef}
      loading={isGetLoading}
    >
      <Fieldset legend="Kundens uppgifter">
        {id && <InputField fullwidth={mobileView} customWidth={4} label="Kundnummer" value={customerNumber} viewOnly />}
        {id ? (
          <InputField
            fullwidth={mobileView}
            customWidth={4}
            label="Typ"
            value={getCustomerTypeName(selectedType)}
            viewOnly
          />
        ) : (
          <CustomerTypeSelect
            required={true}
            customerWidth={4}
            fullwidth={mobileView}
            selected={selectedType}
            onChange={(type) => setSelectedType(type)}
          />
        )}
        <IdentificationNumberField
          setIsIdentificationExisting={setIsIdentificationExisting}
          fullwidth={mobileView}
          customWidth={4}
          currentCustomerId={id ? Number(id) : undefined}
          customerType={selectedType}
          required
          value={identificationNumber}
          onChange={changeIdentificationNumber}
          errorText={errors?.identificationNumber}
        />
        {!id && (
          <InlineButton
            customWidth={4}
            color="primary"
            variant="contained"
            disableTouchRipple
            disabled={!identificationNumber || !selectedType}
            loading={isGetNameLoading}
            onClick={fetchProspectLegalData}
          >
            Hämta kundinfo
          </InlineButton>
        )}
        <InputField
          fullwidth={mobileView}
          customWidth={4}
          label="Namn"
          type="text"
          required
          value={name}
          onChange={changeName}
          errorText={errors?.name}
        />
        <PhoneNumberField
          fullwidth={mobileView}
          customWidth={4}
          label={selectedType === CustomerType.Company ? 'Telefonnummer till företaget' : 'Telefon'}
          value={customerPhoneNumber}
          onChange={changeCustomerPhoneNumber}
          errorText={errors?.customerPhoneNumber}
        />
        <EmailField
          fullwidth={mobileView}
          customWidth={4}
          label={selectedType === CustomerType.Company ? 'E-post till företaget' : 'E-post '}
          required
          value={customerEmail}
          onChange={changeCustomerEmail}
          errorText={errors?.customerEmail}
        />
        <InputField
          fullwidth={mobileView}
          customWidth={4}
          label="Gatuadress"
          required
          value={customerAddress}
          onChange={changeCustomerAddress}
          errorText={errors?.customerAddress}
        />
        <PostalCodeField
          fullwidth={mobileView}
          customWidth={4}
          label="Postnummer"
          type="text"
          required
          value={customerPostalCode}
          onChange={changeCustomerPostalCode}
          errorText={errors?.customerPostalCode}
        />
        <InputField
          fullwidth={mobileView}
          customWidth={4}
          label="Ort"
          required
          type="text"
          value={customerCity}
          onChange={changeCustomerCity}
          errorText={errors?.customerCity}
        />
        <CheckboxField label="Ej kontakt">
          <FormControlLabel
            control={
              <Checkbox
                name="noContact"
                color="primary"
                checked={noContact}
                onChange={(_, checked) => setNoContact(checked)}
              />
            }
            label="Kund vill ej bli kontaktad"
          />
        </CheckboxField>
      </Fieldset>
      <Fieldset legend="Fakturauppgifter">
        <EmailField
          fullwidth={mobileView}
          customWidth={4}
          label="E-post"
          required
          value={invoiceEmail}
          onChange={changeInvoiceEmail}
          errorText={errors?.invoiceEmail}
        />
        <InputField
          fullwidth={mobileView}
          customWidth={4}
          label="Fakturaadress"
          type="text"
          required
          value={invoiceAddress}
          onChange={changeInvoiceAddress}
          errorText={errors?.invoiceAddress}
        />
        <PostalCodeField
          fullwidth={mobileView}
          customWidth={4}
          label="Postnummer"
          type="text"
          required
          value={invoicePostalCode}
          onChange={changeInvoicePostalCode}
          errorText={errors?.invoicePostalCode}
        />
        <InputField
          fullwidth={mobileView}
          customWidth={4}
          label="Ort"
          type="text"
          required
          value={invoiceCity}
          onChange={changeInvoiceCity}
          errorText={errors?.invoiceCity}
        />
        <InputField
          fullwidth={mobileView}
          customWidth={4}
          label="Faktureringsreferens"
          value={invoiceReference}
          onChange={changeInvoiceReference}
          errorText={errors?.invoiceReference}
        />
      </Fieldset>
      <Fieldset legend="Upplysning">
        {!id && (
          <CheckboxField label="">
            <FormControlLabel
              control={
                <Checkbox
                  name="doCreditCheck"
                  color="primary"
                  checked={doCreditCheck}
                  onChange={(_, checked) => setDoCreditCheck(checked)}
                />
              }
              label="Utför en ny upplysning på kund"
            />
          </CheckboxField>
        )}
        <Select
          inputFieldProps={{ label: 'Automatisk kontroll' }}
          selectProps={{
            isClearable: true,
            value: autoCreditCheckIntervalOptions.filter(
              (x) => Number(x.value) === selectedAutoCreditCheckIntervalOption,
            ),
            options: autoCreditCheckIntervalOptions,
            onChange: (selectedValue) => {
              setSelectedAutoCreditCheckIntervalOption(
                selectedValue ? Number((selectedValue as ISelectOption).value) : undefined,
              );
            },
          }}
        />
      </Fieldset>
    </Container>
  );
};

export default CustomerForm;
