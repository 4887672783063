import { Button, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { UserType } from '../../Modules/Admin/Components/Users/Users.api';
import React from 'react';
import Label from '../../../Shared/Container/Label/Label';
import { useStyles } from './UserTypeContainer.styles';

interface IAccessContainer {
  isMobile: boolean;
  userType: UserType;
  handleSession: (userType: UserType) => void;
}

const UserTypeContainer = (props: IAccessContainer) => {
  const classes = useStyles();
  const { isMobile, userType, handleSession } = props;
  return (
    <>
      {isMobile ? (
        <div className={classes.card}>
          <Button onClick={() => handleSession(userType)} className={classes.btn}>
            <Typography variant="h3" component="h2" className={classes.mobileTitle}>
              {userType === UserType.SuperAdmin
                ? 'Administratör'
                : userType === UserType.CompanyUser
                ? 'Företagsanvändare'
                : 'Kund'}
            </Typography>
            <img className={classes.logo} alt="" src={require(`../Icons/${userType}.png`)} />
          </Button>
        </div>
      ) : (
        <Grid md={4}>
          <div className={classes.card}>
            <div className={classes.title}>
              <Label
                label={
                  userType === UserType.SuperAdmin
                    ? 'Administratör'
                    : userType === UserType.CompanyUser
                    ? 'Företagsanvändare'
                    : 'Kund'
                }
              />
            </div>
            <Button data-testid={'button-' + userType} onClick={() => handleSession(userType)} className={classes.btn}>
              <img className={classes.logo} alt="" src={require(`../Icons/${userType}.png`)} />
            </Button>
          </div>
        </Grid>
      )}
    </>
  );
};

export default UserTypeContainer;
