import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  pointerCursor: {
    cursor: 'pointer',
  },
  back: {
    paddingBottom: '5px',
  },
}));
