import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Grid,
  InputAdornment,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutlineRounded';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import Button from '../../../../../../Shared/Button/Button';
import Container from '../../../../../../Shared/Container/Container';

import { AccessRights, ModuleIdentifiers } from '../../../../../../Core/Authentication/ModuleAccess';
import AccessGuard from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { SaleStatus, SaleStatusLabel, useCancelSale, WarningStatus } from '../../Sale.api';
import useInputState from '../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { Fieldset } from '../../../../../../Shared/Form/Fieldset/Fieldset';
import useFormValidation from '../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import { isResultError } from '../../../../../../Shared/Api/response/ICreateResult';
import { Table } from '../../../../../../Shared/Table/Table';
import useUserContext from '../../../../../../Core/Authentication/UserContext';
import { MobileTableRow } from '../../../../../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import { MobileTable } from '../../../../../../Shared/Table/MobileTable/MobileTable';
import theme from '../../../../../../Styles/Theme';
import formatPrice from '../../../../../../Shared/Formatting/formatPrice';
import { SaleType } from '../../../../../Shared/SaleType';
import PopperMenu from '../../../../../../Shared/Popper/Popper';
import DialogForm from '../../../../../../Shared/DialogForm/DialogForm';
import IconButton from '../../../../../../Shared/IconButton/IconButton';
import Snackbar from '../../../../../../Shared/Hooks/UseErrorMessage/Snackbar/Snackbar';
import ErrandsWarningPopUp from '../../../ErrandsWaringPopUp/ErrandsWarningPopUp';
import SystemLogList from '../../../../Admin/Components/SystemLog/SystemLogList/SystemLogList';
import { Action, SystemLogType } from '../../../../Admin/Components/SystemLog/SystemLog.api';
import OfferSummary from './OfferSummary/OfferSummary';
import {
  useGetOffer,
  useApproveOffer,
  IApproveOfferCommand,
  IApproveOfferErrors,
  useSendOfferToRecipients,
  useGetOfferPdf,
  IPriceItem,
  PriceLabels,
  useRevertOfferConfirmation,
  useNotifyCustomer,
} from '../Offer.api';
import { openFile } from '../../../../../../Shared/Api/response/IFileResult';
import { Color } from '@material-ui/lab/Alert';
import { ViewField } from '../../../../../../Shared/InputFields/InputField/ViewField';

export interface ISummaryProduct {
  id: number;
  quantity: number;
  name: string;
  description: string;
  regulation: string;
  certificate: string;
  placement: string;
}

interface IConfirmText {
  message: string;
  severity: Color;
}

const installationPriceList: IPriceItem[] = [
  'materialCost',
  'installationTime',
  'installationRate',
  'installationCost',
  'otherCost',
  'totalPrice',
];

const rentPriceList: IPriceItem[] = ['firstInstallationCost', 'rentPrice'];

const ViewOffer = () => {
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { user } = useUserContext();
  const { isFormValid, formRef } = useFormValidation();
  const { isLoading, offer, getOffer } = useGetOffer();
  const { isLoading: approveIsLoading, approveOffer } = useApproveOffer();
  const { isLoading: emailOfferIsLoading, sendOfferToRecipients } = useSendOfferToRecipients();
  const { isLoading: offerPdfIsLoading, getOfferPdf } = useGetOfferPdf();
  const { isLoading: isCancelLoading, cancelSale } = useCancelSale();
  const { isLoading: isEditOfferConfirmationLoading, editOfferConfirmation } = useRevertOfferConfirmation();
  const { notifyCustomer } = useNotifyCustomer();

  const canBeApproved = offer?.status === SaleStatus.new || offer?.status === SaleStatus.pending_approval;
  const anyLoading = isLoading || approveIsLoading || offerPdfIsLoading || emailOfferIsLoading;
  const [products, setProducts] = useState<ISummaryProduct[]>([]);
  const [approveErrors, setApproveErrors] = useState<IApproveOfferErrors>();
  const [invoiceReference, changeInvoiceReference, setInvoiceReference] = useInputState('');
  const [openPopup, setOpenPopup] = useState(false);
  const [newRecipient, changeNewRecipient, setNewRecipient] = useInputState('');
  const [recipientLists, setRecipientLists] = useState<string[]>([]);
  const [openConfirmText, setOpenConfirmText] = useState<IConfirmText>();
  const [emailMessageText, changeEmailMessageText] = useInputState('');
  const [key, setKey] = useState(0);

  const [editPopup, setEditPopup] = useState(false);

  useEffect(() => {
    async function loadOffer() {
      const response = await getOffer(id);
      if (response) {
        const newList: ISummaryProduct[] = [];
        response.saleProducts.forEach((product) => {
          newList.push({
            id: product.id,
            quantity: 1,
            name: product.name,
            description: product.description,
            certificate: product.certifiedTo,
            regulation: product.regulation,
            placement: product.facilityPlacement,
          });
        });
        setProducts(newList);
        setInvoiceReference(response.customer?.invoiceReference);
      }
    }
    loadOffer();
  }, [id, getOffer, setInvoiceReference]);

  async function approve() {
    let command: IApproveOfferCommand = {
      invoiceReference,
    };

    if (id) {
      const response = await approveOffer(id, command);
      if (isResultError(response)) {
        setApproveErrors(response);
      } else {
        setTimeout(async () => {
          await notifyCustomer({ saleId: parseInt(id), currentAction: Action.ApproveSale });
          setOpenConfirmText({ message: 'Offert har godkänts och kund meddelats.', severity: 'success' });
          setKey((x) => x + 1);
        }, 200);
        history.push(`/errands/orders/${id}`);
      }
    }
  }

  async function handleCancelOffer() {
    if (!offer?.id) return;
    await cancelSale(offer.id.toString());
    history.push('/errands/offers');
  }

  function handleOpenPopup(open: boolean) {
    setOpenPopup(open);
    setRecipientLists(offer?.contactPerson ? [offer.contactPerson?.email] : []);
  }

  function handleAddSendEmail() {
    if (RegExp(`^\\S+@\\S+\\.\\S+$`).test(newRecipient as string)) {
      setRecipientLists([...recipientLists, newRecipient]);
      setNewRecipient('');
    }
  }

  function handleRemoveSendEmail(sendPerson: string) {
    const newList = recipientLists.filter((x) => x !== sendPerson);
    setRecipientLists(newList);
  }

  async function handleSendOfferToEmails() {
    setOpenConfirmText({ message: 'Offerten skickas.', severity: 'info' });
    setOpenPopup(false);
    await sendOfferToRecipients(id, { recipients: recipientLists, message: emailMessageText !== '' ? emailMessageText : `Bifogat finns offerten enligt överenskommelse. <br />Bästa hälsningar, ${offer && offer.projectLeader.name} ${offer && offer.projectLeader.phoneNumber}` });
    setOpenConfirmText({ message: 'Offert har skickats.', severity: 'success' });
    setKey((x) => x + 1);
  }

  return (
    <>
      <Container
        form
        ref={formRef}
        loading={isLoading}
        label={offer && 'Offert ' + offer.saleIdentifier + ' - ' + SaleStatusLabel[offer.status]}
        actions={
          <>
            <Button
              variant="outlined"
              color="primary"
              loading={offerPdfIsLoading}
              onClick={async () => { 
                openFile(await getOfferPdf(id as string), mobileView);
                setKey((x) => x + 1);
              }}
            >
              Visa offert
            </Button>
            <AccessGuard module={ModuleIdentifiers.Offers} accessRights={AccessRights.Write}>
              {offer && (
                <ErrandsWarningPopUp
                  id={offer.id}
                  title={'Radera'}
                  warningStatus={WarningStatus.offer}
                  action={handleCancelOffer}
                  isLoading={isCancelLoading}
                />
              )}
              {offer?.status === SaleStatus.new && (
                <Button
                  disabled={anyLoading}
                  color="default"
                  variant="outlined"
                  onClick={() => history.push(`${history.location.pathname}/update`)}
                >
                  Redigera
                </Button>
              )}
              {offer?.status === SaleStatus.pending_approval && (
                <PopperMenu
                  color="primary"
                  variant="outlined"
                  alwaysDialog
                  customContent
                  open={editPopup}
                  setOpen={setEditPopup}
                  title={`Redigera offert?`}
                  content={
                    <>
                      <DialogForm
                        actions={
                          <>
                            <Button color="default" variant="outlined" onClick={() => setEditPopup(false)}>
                              Avbryt
                            </Button>
                            <Button
                              color="primary"
                              variant="outlined"
                              loading={isEditOfferConfirmationLoading}
                              onClick={async () => {
                                await editOfferConfirmation(id);
                                history.push(`${history.location.pathname}/update`);
                              }}
                            >
                              Bekräfta
                            </Button>
                          </>
                        }
                        children={
                          <span>
                            Offerter har skickats till kund för godkännande. Om man redigerar offerten nu kommer alla
                            länkar som skickats till kund att invalideras. Är du säker?
                          </span>
                        }
                      />
                    </>
                  }
                >
                  Redigera
                </PopperMenu>
              )}
              {(offer?.status === SaleStatus.new || offer?.status === SaleStatus.pending_approval) && (
                <>
                  <PopperMenu
                    color="primary"
                    variant="outlined"
                    alwaysDialog
                    customContent
                    open={openPopup}
                    setOpen={handleOpenPopup}
                    title={`Skicka offert`}
                    content={
                      <>
                        <DialogForm
                          actions={
                            <>
                              <Button color="default" variant="outlined" onClick={() => handleOpenPopup(false)}>
                                Avbryt
                              </Button>
                              <Button
                                color="primary"
                                variant="outlined"
                                loading={emailOfferIsLoading}
                                onClick={handleSendOfferToEmails}
                              >
                                Skicka
                              </Button>
                            </>
                          }
                          children={
                            <>
                              <InputField
                                placeholder={`Bifogat finns offerten enligt överenskommelse. <br />Bästa hälsningar, ${offer.projectLeader.name} ${offer.projectLeader.phoneNumber}`}
                                label="Text i epostmeddelande"
                                fullwidth
                                multiline
                                rows="5"
                                type="text"
                                value={emailMessageText}
                                onChange={changeEmailMessageText}
                              />
                              {recipientLists.length > 0 && (
                                <Grid xs={12} item>
                                  <List>
                                    {recipientLists.map((person) => (
                                      <ListItem key={person} disableGutters>
                                        <ListItemText primary={person} />
                                        <ListItemSecondaryAction>
                                          <IconButton
                                            aria-label="Ta bort e-post"
                                            onClick={() => handleRemoveSendEmail(person)}
                                            color="error"
                                          >
                                            <RemoveIcon />
                                          </IconButton>
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                    ))}
                                  </List>
                                </Grid>
                              )}
                              <InputField
                                placeholder="Lägg till person"
                                type="text"
                                fullwidth
                                value={newRecipient}
                                onChange={changeNewRecipient}
                                onEnter={(e) => {
                                  e.preventDefault();
                                  handleAddSendEmail();
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="Lägg till e-post"
                                      disabled={!RegExp(`^\\S+@\\S+\\.\\S+$`).test(newRecipient as string)}
                                      onClick={handleAddSendEmail}
                                      color="primary"
                                    >
                                      <AddIcon />
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                              <ViewField fullwidth>{offer.canBeApprovedByCustomer && 'Notera att länk till godkännande av offert medföljer.'}</ViewField>
                            </>
                          }
                        />
                      </>
                    }
                  >
                    Skicka offert
                  </PopperMenu>
                </>
              )}
              {canBeApproved && (
                <Button
                  loading={approveIsLoading}
                  disabled={!isFormValid || anyLoading}
                  color="default"
                  variant="outlined"
                  onClick={() => approve()}
                >
                  Godkänn
                </Button>
              )}
            </AccessGuard>
          </>
        }
      >
        {offer && (
          <OfferSummary
            {...offer}
            addToExistingSystem={
              !offer.saleProducts.some((x) => x.productId === offer.coreSystem?.mainProductId) &&
              offer.coreSystem != null
            }
          />
        )}
        <AccessGuard module={ModuleIdentifiers.Offers} accessRights={AccessRights.Write}>
          {canBeApproved && (
            <>
              <Fieldset legend={'Faktureringsreferens'}>
                <InputField
                  fullwidth
                  label="Faktureringsreferens"
                  value={invoiceReference}
                  onChange={changeInvoiceReference}
                  errorText={approveErrors?.invoiceReference}
                  viewOnly={!!offer?.customer?.invoiceReference}
                />
              </Fieldset>
            </>
          )}
        </AccessGuard>
      </Container>
      {offer && offer?.type !== SaleType.deal && (
        <Grid item direction="row" xl={6} md={6} sm={12} xs={12}>
          <Grid item xl={12} md={12} sm={12} xs={12}>
            <Table customSize={{ fullsize: true }} label="Summering" mobile={mobileView}>
              {mobileView ? (
                <>
                  <MobileTable key={user.id} label={'Pris'}>
                    {installationPriceList.map((key) => (
                      <MobileTableRow
                        label={PriceLabels[key]}
                        value={
                          `${offer?.prices?.[key]} ${key === 'installationTime' ? 'h' : 'SEK'}` +
                            !offer?.pricesDisplay?.[key] ?? '(Visas ej)'
                        }
                      />
                    ))}
                  </MobileTable>
                  <MobileTable key={user.id} label={'Hyra'}>
                    {rentPriceList.map((key) => (
                      <MobileTableRow
                        label={PriceLabels[key]}
                        value={`${offer?.prices?.[key]} SEK` + (!offer?.pricesDisplay?.[key] ?? '(Visas ej)')}
                      />
                    ))}
                    {!!offer?.saleAgreement && (
                      <MobileTableRow label="Avtal månadsavgift" value={formatPrice(offer.saleAgreement.monthlyCost)} />
                    )}
                    <MobileTableRow
                      label="Total månadsavgift"
                      value={formatPrice((offer?.prices?.rentPrice ?? 0) + (offer?.saleAgreement?.monthlyCost ?? 0))}
                    />
                  </MobileTable>
                </>
              ) : (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell>Pristyp</TableCell>
                      <TableCell>Kostnad</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {installationPriceList.map((key) => (
                      <TableRow>
                        <TableCell>
                          <Typography>{PriceLabels[key]}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {offer?.prices?.[key]
                              ? `${offer?.prices?.[key]} ${key === 'installationTime' ? ' h' : ' SEK'}` +
                                (!offer?.pricesDisplay?.[key] ? ' (Visas ej)' : '')
                              : '-'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>

                  <TableHead>
                    <TableRow>
                      <TableCell>Hyra</TableCell>
                      <TableCell>Kostnad</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {rentPriceList.map((key) => (
                      <TableRow>
                        <TableCell>
                          <Typography>{PriceLabels[key]}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {offer?.prices?.[key]
                              ? `${offer?.prices?.[key]} SEK` + (!offer?.pricesDisplay?.[key] ? ' (Visas ej)' : '')
                              : '-'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                    {!!offer?.saleAgreement && (
                      <TableRow>
                        <TableCell>
                          <Typography>Avtal månadsavgift</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{formatPrice(offer.saleAgreement.monthlyCost)}</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell>
                        <Typography variant={'h3'}>Total månadsavgift</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {formatPrice((offer?.prices?.rentPrice ?? 0) + (offer?.saleAgreement?.monthlyCost ?? 0))}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </>
              )}
            </Table>
          </Grid>
          <Grid style={{ padding: '1rem 0' }} item xl={12} md={12} sm={12} xs={12}>
            <Table customSize={{ fullsize: true }} label="Produkter" mobile={mobileView}>
              {mobileView ? (
                <>
                  {products.map((product) => (
                    <MobileTable key={user.id} label={product.name}>
                      <MobileTableRow label="Namn" value={product.name}></MobileTableRow>
                      <MobileTableRow label="Beskrivning" value={product.description}></MobileTableRow>
                      <MobileTableRow label="Certifikat" value={product.certificate}></MobileTableRow>
                      <MobileTableRow label="Placering" value={product.placement}></MobileTableRow>
                    </MobileTable>
                  ))}
                </>
              ) : (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell>Namn</TableCell>
                      <TableCell>Regelverk</TableCell>
                      <TableCell>Beskrivning</TableCell>
                      <TableCell>Placering</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map((product) => (
                      <TableRow key={product.id}>
                        <TableCell>
                          <Typography>{product.name}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{product.regulation}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{product.description}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{product.placement}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </>
              )}
            </Table>
          </Grid>
        </Grid>
      )}
      <SystemLogList
        key={`log_${key}`}
        customSize={{ lg: 5, md: 6, sm: 12, xs: 12 }}
        defaultEntityId={id ? parseInt(id) : undefined}
        defaultLogType={SystemLogType.Sale}
        lockCustomerId
        lockLogType
        disableFromAndToDate
      />
      {openConfirmText && (
        <Snackbar
          message={openConfirmText.message}
          open={!!openConfirmText}
          severity={openConfirmText.severity}
          onClose={() => setOpenConfirmText(undefined)}
        />
      )}
    </>
  );
};

export default ViewOffer;
