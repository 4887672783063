import React, { useCallback, useEffect, useState } from 'react';
import { TableHead, TableRow, TableCell, TableBody, Grid } from '@material-ui/core';
import { InputField } from '../../../../../Shared/InputFields/InputField/InputField';
import Select, { ISelectOption } from '../../../../../Shared/Select/Select';
import { Fieldset } from '../../../../../Shared/Form/Fieldset/Fieldset';
import { Table } from '../../../../../Shared/Table/Table';
import Button from '../../../../../Shared/Button/Button';
import {
  useMoveAudit,
  useMoveAuditOnePeriod,
  useMessageCustomer,
  useGetAuditForm,
  useSetNoContact,
} from '../../Audit.api';
import { useHistory, useParams } from 'react-router-dom';
import formatDate from '../../../../../Shared/Formatting/formatDate';
import useInputState from '../../../../../Shared/Hooks/UseInputState/UseInputState';
import moment from 'moment';
import { services, useAddCustomLog } from '../../../Errands/Components/Sale.api';
import { ViewAddress } from '../../../../../Shared/InputFields/Address/ViewAddress';
import Link from '../../../../../Shared/Link/Link';
import Container from '../../../../../Shared/Container/Container';
import { AccessRights, ModuleIdentifiers } from '../../../../../Core/Authentication/ModuleAccess';
import EditActivity from '../../../../Shared/Calendars/Components/EditActivity/EditActivity';
import { isErrorResponse } from '../../../../../Shared/Api/response/IErrorRespose';
import { IActivity } from '../../../../Shared/Calendars/Components/ActivityForm/ActivityForm';
import { CalendarEntryType, useUpsertCalendarEntry } from '../../../Booking/Booking.api';
import useUserContext from '../../../../../Core/Authentication/UserContext';
import Snackbar from '../../../../../Shared/Hooks/UseErrorMessage/Snackbar/Snackbar';
import { SystemLogType } from '../../../Admin/Components/SystemLog/SystemLog.api';
export interface IAuditFormProps {
  cancelPath: string;
}

const options = [
  { value: '1', label: 'Flytta 1 år' },
  { value: '2', label: 'Flytta en period' },
  { value: '3', label: 'Maila kund' },
  { value: '4', label: 'Ej kontakt' },
  { value: '5', label: 'Meddelande logg' },
  { value: '6', label: 'Flytta till bokning' },
];

const AuditForm = (props: IAuditFormProps) => {
  const history = useHistory();
  const { customerId, id } = useParams<{ customerId: string; id: string }>();
  const { cancelPath } = props;
  const { isLoading, auditForm, getAuditForm } = useGetAuditForm(id);
  const { isLoading: isAddingLog, addCustomLog } = useAddCustomLog();
  const { isLoading: isMoveAuditLoading, moveAudit } = useMoveAudit(customerId, id);
  const { isLoading: isMoveAuditOnePeriodLoading, moveAuditOnePeriod } = useMoveAuditOnePeriod(customerId, id);
  const { isLoading: isMessageCustomerLoading, messageCustomer } = useMessageCustomer(customerId, id);
  const { isLoading: isSetNoContactLoading, put } = useSetNoContact(customerId, id);
  const { upsert } = useUpsertCalendarEntry();
  const [addActivity, setAddActivity] = useState<{ start: Date; end: Date }>();
  const { user } = useUserContext();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [customerMessage, changeCustomerMessage, setCustomerMessage] = useInputState('');
  const [logMessage, changeLogMessage] = useInputState('');
  const [selectedAction, setSelectedAction] = useState<string | undefined>();

  const isActionLoading =
    isMoveAuditLoading || isMoveAuditOnePeriodLoading || isMessageCustomerLoading || isSetNoContactLoading || isAddingLog;

  const loadAuditForm = useCallback(async () => {
    const response = await getAuditForm();

    if (!response) {
      history.push(cancelPath);
    }
  }, [cancelPath, getAuditForm, history]);

  useEffect(() => {
    loadAuditForm();
  }, [loadAuditForm]);

  async function performAction() {
    if (auditForm && selectedAction) {
      switch (selectedAction) {
        case '1':
          await moveAudit(moment(auditForm.nextAudit).add('year', 1).toDate());
          break;
        case '2':
          await moveAuditOnePeriod();
          break;
        case '3':
          await messageCustomer({ message: customerMessage });
          setCustomerMessage('');
          break;
        case '4':
          await put({ noContact: true });
          break;
        case '5':
          await addCustomLog({ customLogContent: logMessage, logType: SystemLogType.Facility }, auditForm.facilityId.toString());
          break;
        case '6':
          await bookVisit();
          break;
        default:
          break;
      }
      setSelectedAction('1');
      loadAuditForm();
    }
  }

  async function handleSave(activity: IActivity) {
    if (activity.companyUserIds) {
      const result = await upsert({ ...activity, companyUserIds: activity.companyUserIds }, activity.id?.toString());
      if (isErrorResponse(result)) {
        setErrorMessage("Något gick fel, försök igen.");
      } else {
        setErrorMessage(undefined);
        history.push(`/booking/me#${result.toString()}`);
      }
    }
  }

  async function bookVisit() {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    setAddActivity({start: date, end: date});
  }

  const serviceIntervalText = () => {
    var intervalText = '-';
    if (auditForm?.agreement && auditForm.agreement.auditInterval && services.find((x) => x.type === auditForm?.agreement?.service)) {
      intervalText = 'Revision: ' + (auditForm.agreement.auditInterval ?? '-') + ', ';
      intervalText += 'Service: ' + (services.find((x) => x.type === auditForm?.agreement?.service) ? services.find((x) => x.type === auditForm?.agreement?.service)?.name : '-');
    }
    return intervalText;
  }

  return (
    <Container
      label="Revision"
      loading={isLoading}
      form
      actions={
        <>
        <Grid container item md={12} xs={12} direction="row" justify="center" style={{ padding: '1.5rem' }}>
          <Grid item md={8}>
            <Select
              inputFieldProps={{}}
              selectProps={{
                menuPlacement: 'top',
                options: options,
                onChange: (selected) => {
                  setSelectedAction(selected ? (selected as ISelectOption).value : undefined);
                },
                value: options.find((x) => x.value === selectedAction),
              }} 
            />
          </Grid>
          <Grid item md={4} style={{display: 'flex', justifyContent: 'space-evenly'}}> 
            <Button variant="outlined" onClick={() => history.push(cancelPath)}>
              Avbryt
            </Button>
            <Button
              variant="outlined"
              loading={isActionLoading}
              disabled={isLoading || !selectedAction || (selectedAction === '4' && !customerMessage) || (selectedAction === '5' && !logMessage)}
              onClick={performAction}
            >
              Spara
            </Button>  
          </Grid>
        </Grid>
        </>
      }
    >
      <InputField
        viewOnly
        label="Namn"
        value={
          <Link
            to={`/customers/${auditForm?.customerId}/facility/${auditForm?.facilityId}`}
            module={ModuleIdentifiers.Customer}
            accessRights={AccessRights.Read}
          >
            {(auditForm?.customerName === auditForm?.facilityName ? auditForm?.customerName : auditForm?.customerName + ' - ' + auditForm?.facilityName)}
          </Link>
        }
      />
      <InputField viewOnly label="Kundnummer" value={auditForm?.facilityId} />
      <InputField label="Personnummer" value={auditForm?.identificationNumber} viewOnly />
      <ViewAddress address={auditForm?.address} postalCode={auditForm?.postalCode} city={auditForm?.city} />
      <InputField label="Telefon" viewOnly />
      <Fieldset legend="Avtalsinformation">
        <InputField label="AvtalsNummer" value={auditForm?.agreement?.agreementNumber} viewOnly />
        <InputField label="Revision & service" value={serviceIntervalText()} viewOnly />
        <InputField label="Från" value={formatDate(auditForm?.agreement?.startdate)} viewOnly />
        <InputField label="Till" value={formatDate(auditForm?.agreement?.enddate)} viewOnly />
        <InputField label="Larmcentral" value={auditForm?.agreement?.emergencyCallCenterSupplier} viewOnly />
        <InputField label="Vaktbolag" value={auditForm?.agreement?.securityCompanySupplier} viewOnly />
      </Fieldset>
      <Fieldset legend="System">
        <InputField label="Installerat" value={formatDate(auditForm?.systemDate)} viewOnly />{' '}
        <Grid item xs={12}>
          <Table customSize={{fullsize: true}}>
            <TableHead>
              <TableRow>
                <TableCell>Datum</TableCell>
                <TableCell>Namn</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{formatDate(auditForm?.systemDate)}</TableCell>
                <TableCell>{auditForm?.description}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Fieldset>
      <Fieldset legend="Revision">
        <InputField label="Förra revisionen" value={auditForm?.lastAudit ? formatDate(auditForm?.lastAudit) : '-'} viewOnly />{' '}
        <InputField label="Nästa revision" value={formatDate(auditForm?.nextAudit)} viewOnly />{' '}
        {selectedAction === '4' && (
          <InputField
            label="Kommentar"
            helperText="Sparas i kundhistorik."
            multiline
            fullwidth
            rows={5}
            value={customerMessage}
            onChange={changeCustomerMessage}
          />
        )}
        {selectedAction === '5' && (
          <InputField
            label="Meddelande logg"
            helperText="Sparas i kundhistorik."
            multiline
            fullwidth
            rows={5}
            value={logMessage}
            onChange={changeLogMessage}
          />
        )}
      </Fieldset>
      <EditActivity defaultDate={new Date()} 
        icon={true}
        companyUserId={user.selectedUserId ?? undefined}
        onSave={handleSave}
        open={!!addActivity}
        onClose={() => setAddActivity(undefined)}
        isCompanyCalendar={true}
        showInCompanyCalendarByDefault={false}
        start={addActivity?.start}
        end={addActivity?.end}
        customerId={customerId}
        facilityId={auditForm?.facilityId ? auditForm?.facilityId.toString() : undefined}
        activityType={CalendarEntryType.Sale}
        label='REVISION'
        description="Revision"
        revisionId={auditForm?.id.toString()}
        firstContact
       />
      <Snackbar
        message={errorMessage}
        open={errorMessage !== undefined}
        severity={'warning' }
        onClose={() => setErrorMessage(undefined)}
      />
    </Container>
  );
};

export default AuditForm;
