import { makeStyles, Theme } from '@material-ui/core';
import { calendarColors } from '../../../../../Styles/Theme';

export const useStyles = makeStyles((theme: Theme) => ({
  blue: {
    '& .rbc-event': {
      backgroundColor: calendarColors.blue.background,
      borderColor: calendarColors.blue.background,
      color: calendarColors.blue.text,
    },
  },
  green: {
    '& .rbc-event': {
      backgroundColor: calendarColors.green.background,
      borderColor: calendarColors.green.background,
      color: calendarColors.green.text,
    },
  },
  purple: {
    '& .rbc-event': {
      backgroundColor: calendarColors.purple.background,
      borderColor: calendarColors.purple.background,
      color: calendarColors.purple.text,
    },
  },
  yellow: {
    '& .rbc-event': {
      backgroundColor: calendarColors.yellow.background,
      borderColor: calendarColors.yellow.background,
      color: calendarColors.yellow.text,
    },
  },
  red: {
    '& .rbc-event': {
      backgroundColor: calendarColors.red.background,
      borderColor: calendarColors.red.background,
      color: calendarColors.red.text,
    },
  }, 
  gray: {
    '& .rbc-event': {
      backgroundColor: calendarColors.gray.background,
      borderColor: calendarColors.gray.background,
      color: calendarColors.gray.text,
    },
  },
}));
