import { Checkbox, Grid, List, ListItem, ListItemText } from '@material-ui/core';
import React, { useState } from 'react';
import useUserContext from '../../../../Core/Authentication/UserContext';
import Button from '../../../../Shared/Button/Button';
import ChangeLevel from '../../../../Shared/ChangeLevel/ChangeLevel';
import Container from '../../../../Shared/Container/Container';
import Snackbar from '../../../../Shared/Hooks/UseErrorMessage/Snackbar/Snackbar';
import { HomeComponentDto, homeComponentName, useUpdateHomeComponent } from '../../Admin/Components/Users/Users.api';

const HomeComponentSetting = () => {
  const { user, refetch } = useUserContext();
  const { isLoading, updateHomeComponent } = useUpdateHomeComponent();
  const [homeComponents, setHomeComponents] = useState<HomeComponentDto[] | null>(user.homeComponents);
  const [openConfirmText, setOpenConfirmText] = useState(false);

  function handleChangePriorityUp(homeComponent: HomeComponentDto) {
    changeHomeComponentPriority(homeComponent, -1);
  }

  function handleChangePriorityDown(homeComponent: HomeComponentDto) {
    changeHomeComponentPriority(homeComponent, 1);
  }

  function changeHomeComponentPriority(homeComponent: HomeComponentDto, step: number) {
    const newList = homeComponents ? [...homeComponents] : [];
    const index = newList.indexOf(homeComponent);
    newList[index] = newList[index + step];
    newList[index + step] = homeComponent;
    updatePrioritiesByOrder(newList);
    setHomeComponents(newList);
  }

  function updatePrioritiesByOrder(list: HomeComponentDto[]) {
    for (let index = 0; index < list.length; index++) {
      list[index].priority = index + 1;
    }
  }

  const handleUpdateHomeComponent = async () => {
    if (homeComponents) {
      await updateHomeComponent({ homeComponents: homeComponents });
      refetch();
      setOpenConfirmText(true);
    }
  };

  function changeHomeComponentIsHidden(homeComponent: HomeComponentDto) {
    let newList = homeComponents ? [...homeComponents] : [];
    const index = newList.indexOf(homeComponent);
    newList[index].isHidden = !newList[index].isHidden;
    setHomeComponents(newList);
  }

  function Actions() {
    return (
      <Button color="primary" variant="contained" loading={isLoading} onClick={handleUpdateHomeComponent}>
        Spara
      </Button>
    );
  }

  return (
    <>
      <Container form label={'Startsidan inställning för mobilvy'} actions={<Actions />}>
        <Grid xs={12} item>
          <List disablePadding>
            {homeComponents &&
              homeComponents.map((component, index) => (
                <ListItem key={component.id} disableGutters>
                  <ChangeLevel
                    up={{
                      onClick: () => {
                        handleChangePriorityUp(component);
                      },
                      disabled: index === 0,
                    }}
                    down={{
                      onClick: () => {
                        handleChangePriorityDown(component);
                      },
                      disabled: index === user.homeComponents!.length - 1,
                    }}
                  />
                  <ListItemText primary={homeComponentName.find((x) => x.type === component.component)?.name} />
                  <Checkbox
                    color="primary"
                    edge="start"
                    onClick={() => changeHomeComponentIsHidden(component)}
                    checked={component.isHidden}
                  />
                  Dölj
                </ListItem>
              ))}
          </List>
        </Grid>
      </Container>
      <Snackbar
        message={'Inställning uppdaterat.'}
        open={openConfirmText}
        severity={'success'}
        onClose={() => setOpenConfirmText(false)}
      />
    </>
  );
};

export default HomeComponentSetting;
