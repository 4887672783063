import { useState, useRef, useEffect } from 'react';

export default function useFormValidation() {
  const formRef = useRef<HTMLFormElement>(null);
  const [isFormValid, setIsFormValid] = useState(false);

  // This is safe to do for set* functions in useState
  // https://github.com/facebook/react/issues/15627#issuecomment-491616781
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (formRef?.current) {
      setIsFormValid(formRef.current.checkValidity());
    }
  });

  return { isFormValid, formRef };
}
