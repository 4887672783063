import React, { useEffect, useState } from 'react';
import { useRouteMatch, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import useHeaderNavigationContext from '../../../Shared/Navigation/Header/HeaderNavigationContext/HeaderNavigationContext';
import { INavigationItem } from '../../../Shared/Navigation/Header/Header';
import CustomerForm from './Components/CustomerForm/CustomerForm';
import ViewCustomer from './Components/ViewCustomer/ViewCustomer';
import CustomerList from './Components/CustomerList/CustomerList';
import AuthRoute from '../../../Core/Authentication/AuthRoute/AuthRoute';
import { ModuleIdentifiers, AccessRights } from '../../../Core/Authentication/ModuleAccess';
import FacilityForm from './Components/Facilities/FacilityForm/FacilityForm';
import ViewFacility from './Components/Facilities/ViewFacility/ViewFacility';
import CoreSystemForm from './Components/Facilities/CoreSystems/CoreSystemForm/CoreSystemForm';
import ViewCoreSystem from './Components/Facilities/CoreSystems/ViewCoreSystem/ViewCoreSystem';
import EditContactForm from './Components/Contacts/EditContactForm/EditContactForm';
import EditCommunication from './Components/Facilities/CoreSystems/ViewCoreSystem/Components/Communication/EditCommunication';
import DocumentForm from './Components/ViewCustomer/Documents/Components/DocumentForm/DocumentForm';
import ProductForm from './Components/Facilities/CoreSystems/ViewCoreSystem/Components/Product/ProductForm';
import AgreementForm from './Components/ViewCustomer/AgreementForm/AgreementForm';
import { EntityType, useGetBreadCrumb } from '../../../Shared/Navigation/Breadcrumbs/Breadcrumbs.api';

function CustomersRoute() {
  const { path } = useRouteMatch();
  const location = useLocation();
  const id = '[\\d]+';
  const { setHeaderNavigationItems, setHeaderNavigationText } = useHeaderNavigationContext();
  const { getBreadCrumb } = useGetBreadCrumb();
  const [customerName, setCustomerName] = useState<string>();
  const [facilityName, setFacilityName] = useState<string>();
  const [systemId, setSystemId] = useState<string>();

  useEffect(() => {
    async function handleBreadCrumb() {
      const customerId = location.pathname.match(new RegExp(id, 'g'));

      if (customerId) {
        setCustomerName(
          await (await getBreadCrumb({ entityId: Number(customerId[0]), type: EntityType.Customer }))?.breadCrumbName,
        );

        customerId[1] &&
          setFacilityName(
            await (await getBreadCrumb({ entityId: Number(customerId[1]), type: EntityType.Facility }))?.breadCrumbName,
          );

        customerId[2] &&
          setSystemId(
            await (await getBreadCrumb({ entityId: Number(customerId[2]), type: EntityType.System }))?.breadCrumbName,
          );
      }
    }
    handleBreadCrumb();
  }, [location.pathname, getBreadCrumb]);

  useEffect(() => {
    const navigationItems: INavigationItem[] = [
      {
        title: 'Kunder',
        to: `${path}`,
        exact: false,
      },
    ];

    const HeaderNavigation: INavigationItem[] = [
      {
        title: 'Skapa kund',
        to: `${path}/create`,
        exact: false,
      },
      {
        title: 'Redigera kund',
        to: `${path}/${id}/update`,
        exact: false,
      },
      {
        title: 'Skapa plats',
        to: `${path}/${id}/facility/create`,
        exact: false,
      },
      {
        title: 'Kontakt',
        to: `${path}/${id}/contact`,
        exact: false,
      },
      {
        title: 'Skapa kontakt',
        to: `${path}/${id}/contact/create`,
        exact: false,
      },
      {
        title: 'Redigera kommunikation',
        to: `${path}/${id}/facility/${id}/system/${id}/communication/update`,
        exact: false,
      },
      {
        title: 'Lägg till produkt',
        to: `${path}/${id}/facility/${id}/system/${id}/addproduct`,
        exact: false,
      },
      {
        title: 'Redigera system',
        to: `${path}/${id}/facility/${id}/system/${id}/update`,
        exact: false,
      },
      {
        title: 'System',
        to: `${path}/${id}/facility/${id}/system/${id}`,
        exact: false,
      },
      {
        title: 'Plats',
        to: `${path}/${id}/facility/${id}`,
        exact: false,
      },
      {
        title: 'Skapa kontakt',
        to: `${path}/${id}/facility/${id}/createcontact`,
        exact: false,
      },
      {
        title: 'Nytt dokument',
        to: `${path}/${id}/docs/create`,
        exact: false,
      },
      {
        title: 'Avtal',
        to: `${path}/${id}/agreements`,
        exact: false,
      },
      {
        title: 'Redigera avtal',
        to: `${path}/${id}/agreements/${id}/update`,
        exact: false,
      },
      {
        title: 'Nytt avtal',
        to: `${path}/${id}/agreements/create`,
        exact: false,
      },
      {
        title: 'Redigera avtal',
        to: `${path}/${id}/facility${id}/agreements/${id}/update`,
        exact: false,
      },
      {
        title: 'Nytt avtal',
        to: `${path}/${id}/facility${id}/agreements/create`,
        exact: false,
      },
      {
        title: 'Avtal',
        to: `${path}/${id}/facility/${id}/agreements/${id}`,
        exact: false,
      },
      {
        title: 'Kund',
        to: `${path}/${id}`,
        exact: false,
      },
    ];

    setHeaderNavigationText(HeaderNavigation.concat(navigationItems));
    setHeaderNavigationItems(navigationItems);

    return () => {
      setHeaderNavigationItems([]);
    };
  }, [path, setHeaderNavigationText, setHeaderNavigationItems]);

  return (
    <Switch>
      <AuthRoute exact path={path} module={ModuleIdentifiers.Customer}>
        <h1 className={'sr-only'}>Lista över kunder</h1>
        <CustomerList />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/create`}
        module={ModuleIdentifiers.Customer}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: 'Skapa kund',
          },
        ]}
      >
        <h1 className={'sr-only'}>Formulär för att skapa kund</h1>
        <CustomerForm />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/:id`}
        module={ModuleIdentifiers.Customer}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: customerName ?? 'Kund',
          },
        ]}
      >
        <h1 className={'sr-only'}>Visa kund</h1>
        <ViewCustomer />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/:customerId/docs/create`}
        module={ModuleIdentifiers.Customer}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: customerName || 'Kund',
            to: `${path}/:customerId`,
          },
          {
            title: 'Nytt dokument',
          },
        ]}
      >
        <h1 className={'sr-only'}>Dokument</h1>
        <DocumentForm />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/:id/update`}
        module={ModuleIdentifiers.Customer}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: customerName || 'Kund',
            to: `${path}/:id`,
          },
          {
            title: 'Redigera',
          },
        ]}
      >
        <h1 className={'sr-only'}>Formulär för att uppdatera kund</h1>
        <CustomerForm />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/:customerId/contacts/create`}
        module={ModuleIdentifiers.Customer}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: customerName || 'Kund',
            to: `${path}/:customerId`,
          },
          {
            title: 'Skapa kontakt',
          },
        ]}
      >
        <h1 className={'sr-only'}>Formulär för att lägga till kontakt</h1>
        <EditContactForm />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/:customerId/contact/:id/update`}
        module={ModuleIdentifiers.Customer}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: customerName || 'Kund',
            to: `${path}/:customerId`,
          },
          {
            title: 'Redigera kontakt',
          },
        ]}
      >
        <h1 className={'sr-only'}>Formulär för att redigera kontakt</h1>
        <EditContactForm />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/:customerId/contact/:id`}
        module={ModuleIdentifiers.Customer}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: customerName || 'Kund',
            to: `${path}/:customerId`,
          },
          {
            title: 'Redigera kontakt',
          },
        ]}
      >
        <h1 className={'sr-only'}>Formulär för att redigera kontakt</h1>
        <EditContactForm viewOnly={true} />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/:customerId/facility/create`}
        module={ModuleIdentifiers.Customer}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: customerName || 'Kund',
            to: `${path}/:customerId`,
          },
          {
            title: 'Skapa plats',
          },
        ]}
      >
        <h1 className={'sr-only'}>Formulär för att lägga till plats</h1>
        <FacilityForm />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/:customerId/facility/:id`}
        module={ModuleIdentifiers.Customer}
        breadcrumbs={[
          { title: 'Kunder', to: `${path}` },
          { title: customerName || 'Kund', to: `${path}/:customerId` },
          {
            title: facilityName || 'Plats',
          },
        ]}
      >
        <h1 className={'sr-only'}>Information om plats</h1>
        <ViewFacility />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/:customerId/facility/:id/update`}
        module={ModuleIdentifiers.Customer}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: customerName || 'Kund',
            to: `${path}/:customerId`,
          },
          {
            title: facilityName || 'Plats',
            to: `${path}/:customerId/facility/:id`,
          },
          {
            title: 'Redigera',
          },
        ]}
      >
        <h1 className={'sr-only'}>Formulär för att redigera plats</h1>
        <FacilityForm />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/:customerId/facility/:facilityId/contacts/create`}
        module={ModuleIdentifiers.Customer}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: customerName || 'Kund',
            to: `${path}/:customerId`,
          },
          {
            title: facilityName || 'Plats',
            to: `${path}/:customerId/facility/:facilityId`,
          },
          {
            title: 'Skapa kontakt',
          },
        ]}
      >
        <h1 className={'sr-only'}>Formulär för att lägga till kontakt</h1>
        <EditContactForm />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/:customerId/facility/:facilityId/agreements/create`}
        module={ModuleIdentifiers.Customer}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: customerName || 'Kund',
            to: `${path}/:customerId`,
          },
          {
            title: facilityName || 'Plats',
            to: `${path}/:customerId/facility/:facilityId`,
          },
          {
            title: 'Skapa avtal',
          },
        ]}
      >
        <h1 className={'sr-only'}>Formulär för att skapa nytt avtal</h1>
        <AgreementForm />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/:customerId/facility/:facilityId/agreements/:agreementId/update`}
        module={ModuleIdentifiers.Customer}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: customerName || 'Kund',
            to: `${path}/:customerId`,
          },
          {
            title: facilityName || 'Plats',
            to: `${path}/:customerId/facility/:facilityId`,
          },
          {
            title: 'Redigera avtal',
          },
        ]}
      >
        <h1 className={'sr-only'}>Formulär för att redigera avtal</h1>
        <AgreementForm />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/:customerId/facility/:facilityId/agreements`}
        module={ModuleIdentifiers.Customer}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: customerName || 'Kund',
            to: `${path}/:customerId`,
          },
          {
            title: facilityName || 'Plats',
            to: `${path}/:customerId/facility/:facilityId`,
          },
          {
            title: 'Avtal',
          },
        ]}
      >
        <h1 className={'sr-only'}>Information om avtal</h1>
        <AgreementForm viewOnly />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/:customerId/facility/:facilityId/docs/create`}
        module={ModuleIdentifiers.Customer}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: customerName || 'Kund',
            to: `${path}/:customerId`,
          },
          {
            title: facilityName || 'Plats',
            to: `${path}/:customerId/facility/:facilityId`,
          },
          {
            title: 'Skapa dokument',
          },
        ]}
      >
        <h1 className={'sr-only'}>Formulär för att lägga till dokument</h1>
        <DocumentForm />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/:customerId/facility/:facilityId/agreements/:agreementId`}
        module={ModuleIdentifiers.Customer}
        accessRights={AccessRights.Read}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: customerName || 'Kund',
            to: `${path}/:customerId`,
          },
          {
            title: 'Avtal',
          },
        ]}
      >
        <h1 className={'sr-only'}>Information om avtal</h1>
        <AgreementForm viewOnly />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/:customerId/facility/:facilityId/system/create`}
        module={ModuleIdentifiers.Customer}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: customerName || 'Kund',
            to: `${path}/:customerId`,
          },
          { title: facilityName || 'Plats', to: `${path}/:customerId/facility/:facilityId` },
          {
            title: 'Skapa system',
          },
        ]}
      >
        <h1 className={'sr-only'}>Formulär för att lägga till system</h1>
        <CoreSystemForm />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/:customerId/facility/:facilityId/system/:id`}
        module={ModuleIdentifiers.Customer}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: customerName || 'Kund',
            to: `${path}/:customerId`,
          },
          { title: facilityName || 'Plats', to: `${path}/:customerId/facility/:facilityId` },
          {
            title: systemId || 'System',
          },
        ]}
      >
        <h1 className={'sr-only'}>Information om system</h1>
        <ViewCoreSystem />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/:customerId/facility/:facilityId/system/:id/update`}
        module={ModuleIdentifiers.Customer}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: customerName || 'Kund',
            to: `${path}/:customerId`,
          },
          { title: facilityName || 'Plats', to: `${path}/:customerId/facility/:facilityId` },
          {
            title: systemId || 'System',
            to: `${path}/:customerId/facility/:facilityId/system/:id`,
          },
          {
            title: 'Redigera',
          },
        ]}
      >
        <h1 className={'sr-only'}>Formulär för att redigera system</h1>
        <CoreSystemForm />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/:customerId/facility/:facilityId/system/:id/communication/update`}
        module={ModuleIdentifiers.Customer}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: customerName || 'Kund',
            to: `${path}/:customerId`,
          },
          { title: facilityName || 'Plats', to: `${path}/:customerId/facility/:facilityId` },
          {
            title: systemId || 'System',
            to: `${path}/:customerId/facility/:facilityId/system/:id`,
          },
          {
            title: 'Redigera kommunikation',
          },
        ]}
      >
        <h1 className={'sr-only'}>Redigera kommunikation</h1>
        <EditCommunication />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/:customerId/facility/:facilityId/system/:id/addproduct`}
        module={ModuleIdentifiers.Customer}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: customerName || 'Kund',
            to: `${path}/:customerId`,
          },
          { title: facilityName || 'Plats', to: `${path}/:customerId/facility/:facilityId` },
          {
            title: systemId || 'System',
            to: `${path}/:customerId/facility/:facilityId/system/:id`,
          },
          {
            title: 'Redigera',
          },
        ]}
      >
        <h1 className={'sr-only'}>Formulär för att lägg till en produkt på systemet</h1>
        <ProductForm />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/:customerId/agreements/create`}
        module={ModuleIdentifiers.Customer}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: customerName || 'Kund',
            to: `${path}/:customerId`,
          },
          {
            title: 'Skapa avtal',
          },
        ]}
      >
        <h1 className={'sr-only'}>Formulär för att skapa nytt avtal</h1>
        <AgreementForm />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/:customerId/agreements/:agreementId`}
        module={ModuleIdentifiers.Customer}
        accessRights={AccessRights.Read}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: customerName || 'Kund',
            to: `${path}/:customerId`,
          },
          {
            title: 'Avtal',
          },
        ]}
      >
        <h1 className={'sr-only'}>Information om avtal</h1>
        <AgreementForm viewOnly />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/:customerId/agreements/:agreementId/update`}
        module={ModuleIdentifiers.Customer}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: customerName || 'Kund',
            to: `${path}/:customerId`,
          },
          {
            title: 'Redigera avtal',
          },
        ]}
      >
        <h1 className={'sr-only'}>Formulär för att redigera ett avtal</h1>
        <AgreementForm />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/:customerId/agreements/:agreementId`}
        module={ModuleIdentifiers.Customer}
        accessRights={AccessRights.Read}
        breadcrumbs={[
          {
            title: 'Kunder',
            to: path,
          },
          {
            title: customerName || 'Kund',
            to: `${path}/:customerId`,
          },
          {
            title: 'Avtal',
          },
        ]}
      >
        <h1 className={'sr-only'}>Information om avtal</h1>
        <AgreementForm viewOnly />
      </AuthRoute>

      <Route path={`${path}/*`}>
        <Redirect to={`${path}`} />
      </Route>
    </Switch>
  );
}

export default CustomersRoute;
