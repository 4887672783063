import React, { useState, useEffect, useCallback } from 'react';
import { useGetAudits, ListType } from '../../Audit.api';
import AuditListComponent from '../AuditListComponent/AuditListComponent';
import { useRouteMatch } from 'react-router-dom';
import IPaginatedRequest from '../../../../../Shared/Api/request/IPaginatedRequest';

interface IProps {
  onlyMine?: boolean;
}

const AuditList = (props: IProps) => {
  const { isLoading, audits, getAudits } = useGetAudits();
  const [emptyMessage, setEmptyMessage] = useState('');
  const [request, setRequest] = useState<IPaginatedRequest>();  
  const [status, setStatus] = useState<string>();
  const [auditFromDate, setAuditFromDate] = useState<Date | undefined>(undefined);
  const [auditToDate, setAuditToDate] = useState<Date | undefined>(undefined);
  const { path } = useRouteMatch();

  const getList = useCallback(async () => {
    if (request) {
      const response = await getAudits({
        ...request,
        listType: ListType.Audit,
        onlyMine: props.onlyMine,
        auditFromDate,
        auditToDate,
        status
      });

      if (response?.totalCount <= 0) {
        if (request.query) {
          setEmptyMessage('Filtreringen gav inga resultat');
        } else {
          setEmptyMessage('Inga system är aktuella för revision');
        }
      }
    } else {
      setEmptyMessage('Inga system är aktuella för revision');
    }
  }, [request, getAudits, props.onlyMine, auditFromDate, auditToDate, status]);

  useEffect(() => {
    getList();
  }, [getList]);

  return (
    <AuditListComponent
      label="Revisionslista"
      emptyMessage={emptyMessage}
      onTableChange={(request, dateFrom, dateTo, status) => {
        setAuditFromDate(dateFrom ?? undefined);
        setAuditToDate(dateTo ?? undefined);
        setStatus(status);
        setRequest(request); 
      }}
      audits={audits}
      isLoading={isLoading}
      editPath={path}
    />
  );
};

export default AuditList;
