import { makeStyles } from '@material-ui/core';
import theme from '../../Styles/Theme';

export const Loading = {
  height: '1rem',
  width: '1rem',
  padding: '0.25rem 0',
};

const useStyles = makeStyles(() => ({
  loading: {
    ...Loading,
  },
  buttonRow: {
    height: '50%',
    display: 'flex',
    padding: '1.8rem 0',
    marginTop: '1.4rem',
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '0',
      marginTop: '0 !important',
    },
  },
}));

export default useStyles;
