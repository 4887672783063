import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Container from '../../../../../../Shared/Container/Container';
import { IUpdateProfileValidationErrors, useGetProfile, useUpdateProfile } from '../Customer.api';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import Button from '../../../../../../Shared/Button/Button';
import useInputState from '../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { isResultError } from '../../../../../../Shared/Api/response/ICreateResult';
import useFormValidation from '../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import PostalCodeField from '../../../../../../Shared/InputFields/PostalCodeField/PostalCodeField';
import EmailField from '../../../../../../Shared/InputFields/EmailField/EmailField';

const CustomerInfoForm = () => {
  const history = useHistory();
  const { customerId, companyId } = useParams<{ customerId: string; companyId: string }>();
  const { isLoading, profile } = useGetProfile(customerId);
  const { isLoading: isUpdateLoading, updateProfile } = useUpdateProfile(customerId);
  const { isFormValid, formRef } = useFormValidation();
  const [name, changeName, setName] = useInputState('');
  const [invoiceEmail, changeInvoiceEmail, setInvoiceEmail] = useInputState('');
  const [invoiceAddress, changeInvoiceAddress, setInvoiceAddress] = useInputState('');
  const [invoicePostalCode, changeInvoicePostalCode, setInvoicePostalCode] = useInputState('');
  const [invoiceCity, changeInvoiceCity, setInvoiceCity] = useInputState('');

  const [errors, setErrors] = useState<IUpdateProfileValidationErrors>();

  useEffect(() => {
    if (profile) {
      setName(profile.name);
      setInvoiceEmail(profile.invoiceEmail);
      setInvoiceAddress(profile.invoiceAddress);
      setInvoicePostalCode(profile.invoicePostalCode);
      setInvoiceCity(profile.invoiceCity);
    }
  }, [profile, setName, setInvoiceEmail, setInvoiceAddress, setInvoicePostalCode, setInvoiceCity]);

  function goToView() {
    history.push(`/${companyId}/${customerId}/profile`);
  }

  async function save() {
    const result = await updateProfile({
      name,
      invoiceAddress,
      invoiceCity,
      invoicePostalCode,
      invoiceEmail,
    });

    isResultError(result) ? setErrors(result) : goToView();
  }

  return (
    <Container
      form
      ref={formRef}
      loading={isLoading}
      actions={
        <>
          <Button color="default" variant="outlined" disabled={isUpdateLoading} onClick={goToView}>
            Avbryt
          </Button>
          <Button color="primary" variant="contained" loading={isUpdateLoading} disabled={!isFormValid} onClick={save}>
            Spara
          </Button>
        </>
      }
      label="Kunduppgifter"
    >
      <InputField label="Namn" type="text" required value={name} onChange={changeName} errorText={errors?.name} />
      <InputField label="Org-/Personnummer" value={profile?.identificationNumber} viewOnly />
      <InputField
        label="Gatuadress"
        type="text"
        required
        value={invoiceAddress}
        onChange={changeInvoiceAddress}
        errorText={errors?.invoiceAddress}
      />
      <EmailField
        label="E-post"
        required
        value={invoiceEmail}
        onChange={changeInvoiceEmail}
        errorText={errors?.invoiceEmail}
      />
      <PostalCodeField
        label="Postnummer"
        type="text"
        required
        value={invoicePostalCode}
        onChange={changeInvoicePostalCode}
        errorText={errors?.invoicePostalCode}
      />
      <InputField
        label="Ort"
        type="text"
        required
        value={invoiceCity}
        onChange={changeInvoiceCity}
        errorText={errors?.invoiceCity}
      />
    </Container>
  );
};

export default CustomerInfoForm;
