import moment from 'moment';

export function addTimeToDate(d: Date, time: string): Date {
  const startTimeParts = time.split(':');
  let dateWithTime = moment(d).startOf('day');

  dateWithTime = dateWithTime.hour(parseInt(startTimeParts[0]));
  dateWithTime = dateWithTime.minutes(parseInt(startTimeParts[1]));
  return dateWithTime.toDate();
}

export function timeFromDate(d: Date): string {
  return moment(d).format('HH:mm');
}

export function validTime(timeString: string | undefined) {
  return timeString && timeString.length === 5 && timeString[2] === ':';
}
