import React, { useEffect, useState } from 'react';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import { Button, Tooltip } from '@material-ui/core';
import Snackbar from '../Hooks/UseErrorMessage/Snackbar/Snackbar';

interface IUrlParameter {
  param?: string;
  value?: string;
}

interface ICopyLink {
  root: string;
  parameters?: IUrlParameter[];
  color?: 'white' | 'black';
}

const CopyLink = (props: ICopyLink) => {
  const { root, parameters } = props;
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [link, setLink] = useState<string>('');

  useEffect(() => {
    let tempLink = parameters ? (root.endsWith('/') ? root.slice(0, -1) + '?' : root + '?') : root;

    parameters?.forEach((parameter, index, array) => {
      if (parameter.value && parameter.param) {
        tempLink += parameter.param.trim() + '=' + parameter.value.trim();
        if (index !== array.length - 1 && array[index + 1].value !== undefined) {
          tempLink += '&';
        }
      }
    });

    setLink(window.location.host + tempLink);
  }, [root, parameters, setLink]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    setOpenSnackBar(true);
  };

  return (
    <>
      <Tooltip title={'Kopiera länk till urval'}>
        <Button data-testid="copyLink" onClick={() => handleCopyLink()} style={{color: props.color}}>
          <InsertLinkIcon />
        </Button>
      </Tooltip>
      <Snackbar
        message={'Länk kopierad!'}
        open={openSnackBar}
        severity={'success'}
        onClose={() => setOpenSnackBar(false)}
      />
    </>
  );
};

export default CopyLink;
