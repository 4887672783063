import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  InputAdornment,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import AddIcon from '@material-ui/icons/AddCircleOutlineRounded';
import Button from '../../../../../../Shared/Button/Button';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import useInputState from '../../../../../../Shared/Hooks/UseInputState/UseInputState';
import useFormValidation from '../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import Container from '../../../../../../Shared/Container/Container';
import { isResultError } from '../../../../../../Shared/Api/response/ICreateResult';
import ChangeLevel from '../../../../../../Shared/ChangeLevel/ChangeLevel';
import IconButton from '../../../../../../Shared/IconButton/IconButton';
import { Fieldset } from '../../../../../../Shared/Form/Fieldset/Fieldset';
import Select, { ISelectOption } from '../../../../../../Shared/Select/Select';
import { termsValidPeriodTexts } from '../../Preselection/Preselection.api';
import {
  useGetSystemType,
  ICreateSystemTypeValidationErrors,
  useUpsertSystemType,
  IUpsertSystemTypeCommand,
  IRegulation,
} from '../SystemTypes.api';
import useStyles from './SystemTypeForm.styles';
import CheckboxField from '../../../../../../Shared/InputFields/CheckboxField/CheckboxField';

const termsValidPeriodOptions: ISelectOption[] = termsValidPeriodTexts.map((x) => ({ label: x, value: x }));

function checkingNoRegulationOption(regulationList: IRegulation[]) {
  if (regulationList.length < 1) {
    const newList = [{ name: 'Inget', priority: 1 }];
    return newList;
  } else {
    return regulationList;
  }
}

const SystemTypeForm: React.FunctionComponent = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { isLoading: isGetLoading, getSystemType } = useGetSystemType();
  const { isLoading: isUpsertLoading, upsert } = useUpsertSystemType();
  const { isFormValid, formRef } = useFormValidation();

  const [originalName, setOriginalName] = useState('');
  const [name, changeName, setName] = useInputState('');
  const [newRegulationName, changeNewRegulationName, setNewRegulationName] = useInputState('');
  const [regulations, setRegulations] = useState<IRegulation[]>([]);
  const [saleDescription, changeSaleDescription, setSalesDescription] = useInputState('');
  const [termsIncluding, changeTermsIncluding, setTermsIncluding] = useInputState('');
  const [termsExcluding, changeTermsExcluding, setTermsExcluding] = useInputState('');
  const [termsRegulation, changeTermsRegulation, setTermsRegulation] = useInputState('');
  const [termsWarranty, changeTermsWarranty, setTermsWarranty] = useInputState('');
  const [termsDelivery, changeTermsDelivery, setTermsDelivery] = useInputState('');
  const [termsValidPeriod, setTermsValidPeriod] = useState('');
  const [isCustom, setIsCustom] = useState<boolean>(false);

  const [errors, setErrors] = useState<ICreateSystemTypeValidationErrors>();

  useEffect(() => {
    async function loadSystemType() {
      const systemType = await getSystemType(id);
      setOriginalName(systemType.name);
      setRegulations(systemType.regulations.sort((a, b) => a.priority - b.priority));
      setName(systemType.name);
      setSalesDescription(systemType.termsOffer);
      setTermsIncluding(systemType.termsIncluding);
      setTermsExcluding(systemType.termsExcluding);
      setTermsRegulation(systemType.termsRegulation);
      setTermsWarranty(systemType.termsWarranty);
      setTermsDelivery(systemType.termsDelivery);
      setTermsValidPeriod(systemType.termsValidPeriod);
      setIsCustom(systemType.isCustom);
    }

    if (id) {
      loadSystemType();
    }
  }, [
    getSystemType,
    id,
    setName,
    setSalesDescription,
    setTermsDelivery,
    setTermsExcluding,
    setTermsIncluding,
    setTermsRegulation,
    setTermsValidPeriod,
    setTermsWarranty,
  ]);

  async function upsertSystemType() {
    const upsertCommand: IUpsertSystemTypeCommand = {
      name: name,
      termsOffer: saleDescription,
      termsIncluding: termsIncluding,
      termsExcluding: termsExcluding,
      termsRegulation: termsRegulation,
      termsWarranty: termsWarranty,
      termsDelivery: termsDelivery,
      termsValidPeriod: termsValidPeriod,
      regulations: regulations,
      isCustom: isCustom,
    };

    const result = await upsert(upsertCommand, id);
    isResultError(result) ? setErrors(result) : history.push('/admin/systemtype');
  }

  function handleRemoveRegulation(regulation: IRegulation) {
    const newList = regulations.filter((x) => x !== regulation);
    updatePrioritiesByOrder(newList);
    setRegulations(newList);
  }

  function handleAddRegulation() {
    if (newRegulationName && newRegulationName.trim()) {
      setRegulations([...regulations, { name: newRegulationName, priority: getNextPriority() }]);
      setNewRegulationName('');
    }
  }

  function handleMoveRegulationUp(regulation: IRegulation) {
    moveRegulation(regulation, -1);
  }

  function handleMoveRegulationDown(regulation: IRegulation) {
    moveRegulation(regulation, 1);
  }

  function moveRegulation(regulation: IRegulation, step: number) {
    const newList = [...regulations];
    const index = newList.indexOf(regulation);
    newList[index] = newList[index + step];
    newList[index + step] = regulation;
    updatePrioritiesByOrder(newList);
    setRegulations(newList);
  }

  function updatePrioritiesByOrder(list: IRegulation[]) {
    for (let index = 0; index < list.length; index++) {
      list[index].priority = index + 1;
    }
  }

  function getNextPriority(): number {
    if (regulations.length === 0) return 1;
    return (
      Math.max.apply(
        Math,
        regulations.map((x) => x.priority),
      ) + 1
    );
  }

  function Actions() {
    return (
      <>
        <Button color="default" variant="outlined" onClick={() => history.push('/admin/systemtype')}>
          Avbryt
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={upsertSystemType}
          loading={isUpsertLoading}
          disabled={!isFormValid}
        >
          {id ? 'Spara' : 'Skapa'}
        </Button>
      </>
    );
  }

  const classes = useStyles();

  useEffect(() => {
    if (!id) {
      setRegulations(checkingNoRegulationOption([]));
    }
  }, [id]);

  return (
    <Container
      form
      label={id ? `Redigera ${originalName}` : 'Skapa systemtyp'}
      actions={<Actions />}
      ref={formRef}
      loading={isGetLoading}
    >
      <InputField
        label="Namn"
        type="text"
        fullwidth
        required
        value={name}
        onChange={changeName}
        errorText={errors?.name}
      />

      <InputField
        label="Regelverk"
        placeholder="Lägg till regelverk"
        type="text"
        fullwidth
        value={newRegulationName}
        onChange={changeNewRegulationName}
        onEnter={() => {
          handleAddRegulation();
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="Lägg till regelverk"
              disabled={!newRegulationName || !newRegulationName.trim()}
              onClick={handleAddRegulation}
              color="primary"
            >
              <AddIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      {regulations.length > 0 && (
        <Grid xs={12} item>
          <List disablePadding className={classes.list}>
            {regulations.map((regulation, index) => (
              <ListItem key={regulation.id + regulation.name} disableGutters>
                <ChangeLevel
                  up={{
                    onClick: () => {
                      handleMoveRegulationUp(regulation);
                    },
                    disabled: index === 0,
                  }}
                  down={{
                    onClick: () => {
                      handleMoveRegulationDown(regulation);
                    },
                    disabled: index === regulations.length - 1,
                  }}
                />
                <ListItemText primary={regulation.name} />
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="Ta bort regelverk"
                    onClick={() => handleRemoveRegulation(regulation)}
                    color="error"
                    disabled={regulations.length < 2}
                  >
                    <RemoveIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
      <Fieldset legend="Standardvärden på offert">
        <InputField
          label="Förklarande text"
          type="text"
          multiline
          rows="5"
          value={saleDescription}
          onChange={changeSaleDescription}
          fullwidth
        />
        <InputField
          label="Inklusive"
          type="text"
          multiline
          fullwidth
          rows="5"
          value={termsIncluding}
          onChange={changeTermsIncluding}
        />
        <InputField
          label="Enligt regelverk"
          multiline
          rows={5}
          type="text"
          fullwidth
          value={termsRegulation}
          onChange={changeTermsRegulation}
        />
        <InputField
          label="Exklusive"
          multiline
          rows={5}
          fullwidth
          type="text"
          value={termsExcluding}
          onChange={changeTermsExcluding}
        />
        <Select
          inputFieldProps={{ label: 'Giltighetstid', fullwidth: true }}
          selectProps={{
            isClearable: true,
            options: termsValidPeriodOptions,
            value: termsValidPeriodOptions.find((x) => x.value === termsValidPeriod),
            onChange: (option) => {
              setTermsValidPeriod(option ? (option as ISelectOption).value : '');
            },
          }}
        />
        <InputField
          label="Leverans"
          type="text"
          multiline
          rows={5}
          value={termsDelivery}
          fullwidth
          onChange={changeTermsDelivery}
        />
        <InputField
          label="Garanti"
          type="text"
          fullwidth
          multiline
          rows={5}
          value={termsWarranty}
          onChange={changeTermsWarranty}
        />
      </Fieldset>

      <CheckboxField label="" customWidth={6} inline>
        <FormControlLabel
          control={
            <Checkbox
              name="customSystem"
              color="primary"
              checked={isCustom}
              onChange={(_, checked) => setIsCustom(checked)}
            />
          }
          label="Special systemtyp"
        />
      </CheckboxField>
    </Container>
  );
};

export default SystemTypeForm;
