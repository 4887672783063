import { makeStyles } from '@material-ui/core';
import theme from '../../Styles/Theme';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap-reverse',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    '& > *': {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      padding: '0.5rem',
    },
  },
  regular: {
    justifyContent: 'flex-end',
  },
  reverse: {
    justifyContent: 'flex-start',
  },
}));

export default useStyles;
