import React from 'react';
import { TableSortLabel } from '@material-ui/core';
import { SortDirection } from '../../Api/request/IPaginatedRequest';

interface IProps {
  property: string;
  sortProperty: string;
  sortDirection: SortDirection;
  onSort: (propert: string) => void;
}

const SortButton = (props: IProps) => {
  return (
    <TableSortLabel
      active={props.property === props.sortProperty}
      direction={props.sortDirection === SortDirection.Descending ? 'desc' : 'asc'}
      onClick={() => {
        props.onSort(props.property);
      }}
    />
  );
};

export default SortButton;
