import React, { useCallback, useMemo } from 'react';
import { AsyncSelect, MultiAsyncSelect } from '../../../../../../../Shared/Select';
import { useGetCompanyBranches } from '../../../Settings/CompanyBranch/CompanyBranch.api';

export interface IProps {
  selectedCompanyBranchId?: number | number[];
  fullWidth?: boolean;
  label?: string;
  placeholder?: string;
  isMulti?: boolean;
  hasCompanyUsers?: boolean;
  onChange?: (companyBranchId: number | number[] | undefined) => void;
}

const CompanyBranchSelect = (props: IProps) => {
  const { selectedCompanyBranchId, fullWidth, isMulti, label, placeholder, onChange, hasCompanyUsers } = props;
  const { isLoading, getCompanyBranches } = useGetCompanyBranches();

  const selectedOptionId = useMemo(() => {
    if (!selectedCompanyBranchId) return undefined;
    if (isMulti) {
      return Array.isArray(selectedCompanyBranchId)
        ? selectedCompanyBranchId.map((x) => x.toString())
        : [selectedCompanyBranchId.toString()];
    } else {
      return Array.isArray(selectedCompanyBranchId)
        ? selectedCompanyBranchId[0].toString()
        : selectedCompanyBranchId.toString();
    }
  }, [isMulti, selectedCompanyBranchId]);

  const loadCompanyBranches = useCallback(
    async (query?: string) => {
      const response = await getCompanyBranches({
        pageNumber: 1,
        pageSize: 25,
        query: query,
      });
      if (hasCompanyUsers) {
        return response?.items.filter((x) => x.hasCompanyUsers).map((x) => ({ value: x.id.toString(), label: x.name }));
      }
      return response?.items.map((x) => ({ value: x.id.toString(), label: x.name }));
    },
    [getCompanyBranches, hasCompanyUsers],
  );

  if (isMulti) {
    return (
      <MultiAsyncSelect
        fullwidth={fullWidth}
        placeholder={placeholder ?? 'Välj...'}
        label={label}
        isClearable={true}
        isLoading={isLoading}
        selectedOptionIds={selectedOptionId as string[] | undefined}
        loadOptions={loadCompanyBranches}
        onChange={(value) => onChange && onChange(value ? value.map((x) => Number(x.value)) : [])}
      />
    );
  }

  return (
    <AsyncSelect
      fullwidth={fullWidth}
      placeholder={placeholder ?? 'Välj...'}
      label={label}
      isClearable={true}
      isLoading={isLoading}
      selectedOptionId={selectedOptionId as string | undefined}
      loadOptions={loadCompanyBranches}
      onChange={(value) => onChange && onChange(value ? Number(value?.value) : undefined)}
    />
  );
};

export default CompanyBranchSelect;
