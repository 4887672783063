import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontWeight: 600,
    textDecoration: 'none',
    paddingLeft: '1rem',
    overflow: 'hidden',
  },
  root: {
    position: 'relative',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    zIndex: 1,
    position: 'absolute',
    // Default for material ui
    height: '24px',
    padding: '12px 0',
    maxWidth: '90%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: 0,
    padding: 0,
  },
  asLink: {
    color: theme.palette.primary.main,
  },
  withAction: {
    paddingLeft: '0.5rem',
    flexGrow: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  expansionPanel: {
    '&:before': {
      height: 0,
    },
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}));
