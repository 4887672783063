import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.contrastText,
      cursor: 'pointer',
    }
  },
  button: {
    border: 'none',
    backgroundColor: 'transparent',
    maxWidth: '100%',
    flexGrow: 1,
    '&:focus': {
      outline: 0,
      '& > div': {
        outline: 'auto',
      },
    },    
  },
  content: {
    [theme.breakpoints.up('md')]: {
      padding: '1rem',
    },
    display: 'flex',
    zIndex: 2,
    flexDirection: 'column',
    '& > *': {
      marginBottom: '1rem',
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  time: {
    width: '6rem',
  },
  datepicker: {
    marginRight: '1rem',
  },
}));
