import React from 'react';
import { CircularProgress, MenuItem } from '@material-ui/core';
import useStyles from './MultiButtonItem.styles';

export interface IMultiButtonItem {
  loading?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

const MultiButtonItem: React.FC<IMultiButtonItem> = (props) => {
  const classes = useStyles();

  return (
    <MenuItem className={classes.root} disabled={props.disabled} button onClick={props.onClick}>
      <span className={props.loading ? classes.activeLoading : ''}>{props.children}</span>
      {props.loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress aria-label="Laddar" disableShrink size="1rem" />
        </div>
      )}
    </MenuItem>
  );
};

export default MultiButtonItem;
