import { useApiPost } from '../../../../../Core/Api/Api';
import IPaginatedRequest from '../../../../../Shared/Api/request/IPaginatedRequest';
import IPaginatedResponse from '../../../../../Shared/Api/response/IPaginatedResponse';

export function useGetSystemLog() {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<ISystemLogEntryDto>, ISystemLogRequest>(
    '/api/logs/list',
  );

  return {
    isLoading,
    systemLog: response,
    getSystemLog: post,
  };
}

export function useGetCurrentCompanyUserLog() {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<ISystemLogEntryDto>, ILogRequest>(
    '/api/logs/user',
    undefined,
  );

  return {
    isLoading,
    currentCompanyUserLog: response,
    getCurrentCompanyUserLog: post,
  };
}

export interface ILogRequest extends IPaginatedRequest {
  fromDate?: Date;
  toDate?: Date;
}

export interface ISystemLogRequest extends ILogRequest {
  type?: SystemLogType;
  entityId?: number;
  userId?: number;
}

export interface ISystemLogEntryDto {
  id: number;
  dateTime: Date;
  user: string;
  entityName: string;
  entityId: number | null;
  eventType: EventType;
  eventData?: IApplicationEventData;
  customerName?: string;
  saleNumber?: string;
  impersonator?: string;
  oldValues?: string;
  newValues?: string;
  
}

export interface ICustomerUpdateDto {
  type: CustomerUpdateType;
  updatedEntityId: number | null;
  entityDescription: string;
  action: Action;
  changeDescription: string;
}

export interface IApplicationEventData {
  entityDescription: string;
  updatedEntityId: number | null;
  updatedEntityDescription: string;
  action: Action;
  changeDescription: string;
}

export enum SystemLogType {
  Customer = 1,
  Sale = 2,
  Todo = 3,
  Facility = 4,
}

export enum EventType {
  // Entity events
  EntityAdded = 0,
  EntityModified = 1,
  EntityDeleted = 2,

  // Email events
  EmailSent = 10,
  CustomerUpdate = 20,
  SaleEvent = 30,
  TodoEvent = 40,
}

export enum CustomerUpdateType {
  Sale,
  CoreSystem,
  Misc,
  ErrorReport,
  Facility,
}

export enum Action {
  Misc,
  EmailCustomer,
  CustomerDeclineOffer,
  CustomerApproveOffer,
  CustomerViewOffer,
  EmailProjectLeader,
  SecurityCompanyConfirmChanges,
  SecurityCompanyGetChangesPDF,
  SupplierConfirmOrder,
  RespondToSurvey,
  Login,
  CustomerUpdateProfile,
  UpdateEventActions,
  InstallationBooked,
  AddContact,
  DeleteContact,
  CreateContact,
  UpdateContact,
  ViewCustomer,
  CreateErrorReport,
  UpdateErrorReport,
  ErrorReportCreateService,
  CompleteErrorReport,
  CreateFacility,
  UpdateFacility,
  SaleAddProduct,
  AddTimeReport,
  AddCost,
  UpdateCost,
  RemoveCost,
  ApproveSale,
  CancelSale,
  CompleteSale,
  SendOfferToCustomer,
  SendOfferToEmail,
  CreateServiceSale,
  CreateSupplierOrder,
  SaleInstallationComplete,
  SaleMarkForInvoice,
  SaleUpdateInvoice,
  UpdateChecklist,
  UpdateOffer,
  UpdateSaleDescriptions,
  UpdateSale,
  ViewOrder,
  ViewOffer,
  AddAuditTodo,
  AuditPerformed,
  CreateCoreSystem,
  UpdateSystem,
  UpdateSystemUsers,
  CreateOffer,
  CreateDocument,
  CoreSystemRemoveProduct,
  CoreSystemUpdateProducts,
  CoreSystemAddProduct,
  TerminateAgreement,
  RenewedAgreement,
  ChangeFacilityCustomer,
  CreateAgreement,
  UpdateAgreement,
  DeleteSupplierOrder,
  CustomLog,
  CreateTodo,
  UpdateTodo,
  AnswerTodo,
  ArchiveTodo,
  DeleteTodo,
  DeleteDocument,
  UpdateTotalPrice,
  RemoveTimeReport,
  RemoveSaleProduct,
  ViewDraft,
  ReviewOfferPdf,
  InstallerBooked,
  ViewFacility,
  SendOrderConfirmationToCustomer,
  SendSupplierOrderToSupplier,
  EmailSalesPerson
}

export function getEntryDescription(entry: ISystemLogEntryDto) {
  let eventEntry = entry.eventData;
  
  if (eventEntry === null && entry.newValues != null) {
    switch (entry.eventType) {
      case EventType.EntityAdded: 
        return `${entry.entityName} med ID ${entry.entityId} skapades`
      case EventType.EntityModified: 
        return `${entry.entityName} med ID ${entry.entityId} ändrades. Gamla värden: ${entry.oldValues && JSON.parse(entry.oldValues)}. Nya värden: ${entry.newValues && JSON.parse(entry.newValues)}`
    }
  }

  switch (eventEntry?.action) {
    case Action.Misc:
      return eventEntry.updatedEntityDescription;
    case Action.EmailCustomer:
      return 'Mail skickat till kund: ' + eventEntry.changeDescription ?? eventEntry.updatedEntityDescription;
    case Action.CustomerDeclineOffer:
      return `Kund nekade offert ${eventEntry.updatedEntityDescription}: ` + eventEntry.changeDescription;
    case Action.CustomerApproveOffer:
      return `Kund godkände offert ${eventEntry.updatedEntityDescription}. (${eventEntry.changeDescription})`;
    case Action.CustomerViewOffer:
      return `Kund (${eventEntry.updatedEntityDescription}) visade offert ${eventEntry.entityDescription}`;
    case Action.EmailProjectLeader:
      return `Mail skickat till projektledare ang. offert/order ${eventEntry.updatedEntityDescription}`;
    case Action.SecurityCompanyConfirmChanges:
      return `Kund neka offert ${eventEntry.updatedEntityDescription}`;
    case Action.SecurityCompanyGetChangesPDF:
      return `Larmcentral visa PDF för ändringar av system ${eventEntry.updatedEntityDescription}`;
    case Action.SupplierConfirmOrder:
      return `Leverantör godkände beställning för order ${eventEntry.updatedEntityDescription}`;
    case Action.RespondToSurvey:
      return `Kund svarade på utskick ${eventEntry.updatedEntityDescription}`;
    case Action.Login:
      return `Kund loggade in ${eventEntry.updatedEntityDescription}`;
    case Action.CustomerUpdateProfile:
      return `Kund godkände uppdaterade profil`;
    case Action.UpdateEventActions:
      return `Åtgärdslista uppdatertad för ${eventEntry.updatedEntityDescription}`;
    case Action.InstallationBooked:
      return `Installation bokad på order ${eventEntry.updatedEntityDescription}`;
    case Action.AddContact:
      return `Kontakt tillagd: ${eventEntry.changeDescription}`;
    case Action.DeleteContact:
      return `Kontakt borttagen: ${eventEntry.changeDescription}`;
    case Action.CreateContact:
      return `Kontakt skapad: ${eventEntry.changeDescription}`;
    case Action.UpdateContact:
      return `Kontakt uppdaterad: ${eventEntry.changeDescription}`;
    case Action.ViewCustomer:
      return `Visa kund ${eventEntry.updatedEntityDescription}`;
    case Action.CreateErrorReport:
      return `Felrapport skapad för order ${eventEntry.updatedEntityDescription}`;
    case Action.UpdateErrorReport:
      return `Felrapport ${eventEntry.updatedEntityId} uppdaterad`;
    case Action.ErrorReportCreateService:
      return `Serviceorder skapad för felrapport ${eventEntry.updatedEntityId}`;
    case Action.CompleteErrorReport:
      return `Felrapport ${eventEntry.updatedEntityId} slutförd`;
    case Action.CreateFacility:
      return `Plats ${eventEntry.changeDescription} skapad`;
    case Action.UpdateFacility:
      return `Plats ${eventEntry.updatedEntityDescription} uppdaterad`;
    case Action.SaleAddProduct:
      return `Produkt ${eventEntry.changeDescription} tillagd på order ${eventEntry.updatedEntityDescription}`;
    case Action.AddTimeReport:
      return `Tidrapport tillagd på order ${eventEntry.updatedEntityDescription}`;
    case Action.ApproveSale:
      return `Offert godkändes av administratör (${eventEntry.changeDescription})`;
    case Action.CancelSale:
      return `Offert ${eventEntry.updatedEntityDescription} avbröts`;
    case Action.CompleteSale:
      return `Order ${eventEntry.updatedEntityDescription} slutförd`;
    case Action.SendOfferToCustomer:
      return `Offert ${eventEntry.entityDescription} skickad till kund (${eventEntry.changeDescription ?? eventEntry.updatedEntityDescription})`;
    case Action.SendOfferToEmail:
      return `Offert ${eventEntry.updatedEntityDescription} skickad till ${eventEntry.changeDescription ?? eventEntry.updatedEntityDescription}`;
    case Action.CreateServiceSale:
      return `ServiceOrder ${eventEntry.updatedEntityDescription} skapad`;
    case Action.CreateSupplierOrder:
      return `Beställning skapad för order ${eventEntry.updatedEntityDescription}`;
    case Action.SaleInstallationComplete:
      return `Installation slutförd för order ${eventEntry.updatedEntityDescription}`;
    case Action.SaleMarkForInvoice:
      return `Order ${eventEntry.entityDescription} skickad för fakturering`;
    case Action.SaleUpdateInvoice:
      return `Fakturainformation uppdaterat för order ${eventEntry.entityDescription}`;
    case Action.UpdateChecklist:
      return `Checklista uppdaterad för order ${eventEntry.updatedEntityDescription}`;
    case Action.UpdateOffer:
      return `Offert ${eventEntry.updatedEntityDescription} uppdaterad`;
    case Action.UpdateSaleDescriptions:
      return `Beskrivning uppdaterad för order ${eventEntry.updatedEntityDescription}`;
    case Action.UpdateSale:
      return `Order ${eventEntry.updatedEntityDescription} uppdaterad`;
    case Action.ViewOrder:
      return `Order ${eventEntry.updatedEntityDescription} visad`;
    case Action.ViewOffer:
      return `Offert ${eventEntry.updatedEntityDescription} visad`;
    case Action.AddAuditTodo:
      return `Revision för ${eventEntry.updatedEntityDescription} har lagts i uppgifter`;
    case Action.AuditPerformed:
      return `Revision för ${eventEntry.updatedEntityDescription} utförd`;
    case Action.CreateCoreSystem:
      return `System ${eventEntry.updatedEntityDescription} skapat`;
    case Action.UpdateSystem:
      return `System ${eventEntry.updatedEntityDescription} uppdaterat`;
    case Action.UpdateSystemUsers:
      return `Användare för ${eventEntry.updatedEntityDescription} uppdaterade`;
    case Action.CreateOffer:
      return `Offert ${eventEntry.updatedEntityDescription} skapad`;
    case Action.CreateDocument:
      return `Dokument ${eventEntry.updatedEntityDescription} skapat`;
    case Action.CoreSystemRemoveProduct:
      return `System produkt ${eventEntry.updatedEntityDescription} har tagit bort`;
    case Action.CoreSystemUpdateProducts:
      return `System produkter uppdaterade`;
    case Action.CoreSystemAddProduct:
      return `System produkt ${eventEntry.updatedEntityDescription} lagts till`;
    case Action.TerminateAgreement:
      return `Avtal avslutades: ${eventEntry.updatedEntityDescription}`;
    case Action.RenewedAgreement:
      return `Fornya avtal: ${eventEntry.updatedEntityDescription} `;
    case Action.ChangeFacilityCustomer:
      return `Flytta plats ${eventEntry.updatedEntityId} från kund ${eventEntry.updatedEntityDescription} till kund ${eventEntry.changeDescription}`;
    case Action.CreateAgreement:
      return `Skapa avtal: ${eventEntry.updatedEntityDescription} `;
    case Action.UpdateAgreement:
      return `Uppdatera avtal: ${eventEntry.updatedEntityDescription} `;
    case Action.DeleteSupplierOrder:
      return `Bäställning borttagen: ${eventEntry.updatedEntityDescription}`;
    case Action.CustomLog:
      return `${eventEntry?.changeDescription ?? eventEntry.updatedEntityDescription}`;
    case Action.CreateTodo:
      return `Uppgift skapad: ${eventEntry.entityDescription}`;
    case Action.UpdateTodo:
      return `Uppgift uppdaterad: ${eventEntry.entityDescription}`;
    case Action.AnswerTodo:
      return `Uppgift besvarad: ${eventEntry.entityDescription}`;
    case Action.ArchiveTodo:
      return `Uppgift arkiverad: ${eventEntry.entityDescription}`;
    case Action.DeleteTodo:
      return `Uppgift borttagen: ${eventEntry.entityDescription}`;
    case Action.DeleteDocument:
      return `Dokument ${eventEntry.updatedEntityDescription} med id ${eventEntry.updatedEntityId} raderat`;
    case Action.UpdateTotalPrice:
      return `Totalpris uppdaterat`;
    case Action.RemoveTimeReport:
      return `Tidrapport med id ${eventEntry.changeDescription} har raderats`;
    case Action.RemoveSaleProduct:
      return `Product med id ${eventEntry.changeDescription} har tagits bort från ordern`;
    case Action.ViewDraft:
      return `Utkast ${eventEntry.updatedEntityDescription} visad`;
    case Action.ReviewOfferPdf:
      return `Offert ${eventEntry.updatedEntityDescription} granskad`;
    case Action.InstallerBooked: 
      return `Installation bokad på ${eventEntry.changeDescription}`;
    case Action.ViewFacility:
      return `Plats ${eventEntry.updatedEntityDescription} visad`;  
    case Action.SendOrderConfirmationToCustomer:
      return `Ordergodkännande för ${eventEntry.entityDescription} skickad till kund (${eventEntry.changeDescription ?? eventEntry.updatedEntityDescription})`;
    case Action.SendSupplierOrderToSupplier:
      return `Beställning ${eventEntry.entityDescription} skickad till leverantör (${eventEntry.changeDescription ?? eventEntry.updatedEntityDescription})`;
    case Action.EmailSalesPerson:
      return `Mail skickat till säljare ang. offert/order ${eventEntry.updatedEntityDescription}`;
    default:
      return `${eventEntry?.updatedEntityDescription}`;
  }
}
