import { useApiPost } from '../../../../../Core/Api/Api';

export function useGetErrorReportStats() {
  const { isLoading, response, post } = useApiPost<IErrorReportStatsDto, IErrorReportStatsQuery>(
    `/api/statistics/error-reports`,
  );

  return {
    isLoading,
    errorReportStats: response,
    getErrorReportStats: post,
  };
}

export interface IErrorReportStatsQuery {
  fromDate: Date;
  toDate: Date;
}

export interface IErrorReportStatsDto {
  errorReportCount: number;
  hours: number;
  hoursPerReport: number;
  trips: number;
  tripsPerReport: number;
  typeCount: ITypeCountDto[];
  companyUserCount: ICompanyUserCountDto[];
  supplierCount: ISupplierCountDto[];
}

export interface ITypeCountDto {
  errorCategoryId: number;
  errorCategoryName: string;
  count: number;
}

export interface ICompanyUserCountDto {
  companyUserId: number;
  name: string;
  count: number;
}

export interface ISupplierCountDto {
  supplierId: number;
  name: string;
  count: number;
}
