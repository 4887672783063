import React, { useState } from 'react';
import { Fieldset } from '../../../../../../../../../Shared/Form/Fieldset/Fieldset';
import useFormValidation from '../../../../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import { InputField } from '../../../../../../../../../Shared/InputFields/InputField/InputField';
import useInputState from '../../../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { isResultError } from '../../../../../../../../../Shared/Api/response/ICreateResult';
import Button from '../../.././../../../../../../Shared/Button/Button';
import { ICreateServiceSaleCommandValidationErrors, useCreateServiceSale } from '../../../../Reports.api';
import DialogForm from '../../../../../../../../../Shared/DialogForm/DialogForm';
import PhoneNumberField from '../../../../../../../../../Shared/InputFields/PhoneNumberField/PhoneNumberField';

export interface IServiceOrderFormProps {
  onCreate: (id: number) => void;
  onCancel?: () => void;
  useContainer?: boolean;
  errorReportId: Number;
}

const ServiceOrderForm: React.FunctionComponent<IServiceOrderFormProps> = (props: IServiceOrderFormProps) => {
  const { onCreate, onCancel } = props;
  const { isFormValid, formRef } = useFormValidation();
  const [description, changeDescription] = useInputState('');
  const [contactName, changeContactName] = useInputState('');
  const [contactNumber, changeContactNumber] = useInputState('');
  const { createServiceSale, isLoading } = useCreateServiceSale(props.errorReportId.toString(), true);
  const [errors, setErrors] = useState<ICreateServiceSaleCommandValidationErrors>();

  async function handleSave() {
    const result = await createServiceSale({
      contactName: contactName,
      contactPhone: contactNumber,
      jobDescription: description,
      saleDescription: description,
    });
    if (isResultError(result)) {
      setErrors(result);
    } else {
      onCreate(Number(result));
    }
  }

  return (
    <DialogForm
      ref={formRef}
      actions={
        <>
          <Button color="default" variant="outlined" onClick={onCancel}>
            Avbryt
          </Button>
          <Button color="primary" variant="contained" onClick={handleSave} loading={isLoading} disabled={!isFormValid}>
            Skapa
          </Button>
        </>
      }
    >
      <InputField
        label="Beskrivning"
        type="text"
        value={description}
        onChange={changeDescription}
        multiline
        rows={5}
        required
        errorText={errors?.jobDescription}
      />
      <Fieldset legend="Kontakt">
        <InputField
          label="Namn"
          type="text"
          required
          value={contactName}
          onChange={changeContactName}
          errorText={errors?.contactName}
        />   
        <PhoneNumberField
          label={'Telefon'}
          required
          value={contactNumber}
          onChange={changeContactNumber}
          errorText={errors?.contactPhone}
        />
      </Fieldset>
    </DialogForm>
  );
};

export default ServiceOrderForm;
