import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { AsyncSelect, MultiAsyncSelect, ISelectOption } from '../../../../../../Shared/Select';
import {
  areAllSelected,
  emptyList,
  selectAllList,
  selectAllOption,
} from '../../../../../../Shared/Select/SelectMultiChecker';
import { useGetCompanyUsers } from '../Users.api';
import useUserContext from '../../../../../../Core/Authentication/UserContext';
import { GridSize } from '@material-ui/core';

export interface IProps {
  onChange?: (
    companyUserId: string | undefined,
    companyUserName: string | undefined,
    option: ISelectOption | ISelectOption[] | undefined,
    allOptionsSelected: boolean | undefined,
  ) => void;
  companyId?: string;
  required?: boolean;
  selectedCompanyUser?: ISelectOption | ISelectOption[];
  selectedCompanyUserId?: string | string[];
  label?: string;
  placeholder?: string;
  isClearable?: boolean;
  isMulti?: boolean;
  addSelectAllOption?: boolean;
  inline?: boolean;
  fullwidth?: boolean;
  companyUserIds?: number[];
  pageSize?: number;
  isDisabled?: boolean;
  autoFocus?: boolean;
  isUserActived?: boolean;
  defaultAll?: boolean;
  omitSelf?: boolean;
  customWidth?: GridSize;
}

//There is still small problem with UserSelect when passing props inside. Should check on another branch
const CompanyUserSelect = (props: IProps) => {
  const {
    companyId,
    selectedCompanyUser,
    selectedCompanyUserId,
    isMulti,
    addSelectAllOption,
    companyUserIds,
    isUserActived: isCompanyUserActived,
    defaultAll,
    omitSelf,
    customWidth
  } = props;
  const { getCompanyUsers, isLoading } = useGetCompanyUsers();
  const [defaultOptions, setDefaultOptions] = useState<ISelectOption[]>();
  const user = useUserContext();

  const selectedOption = useMemo(() => {
    if (!selectedCompanyUser) return undefined;
    if (isMulti) {
      return Array.isArray(selectedCompanyUser) ? selectedCompanyUser : [selectedCompanyUser];
    } else {
      return Array.isArray(selectedCompanyUser) ? selectedCompanyUser[0] : selectedCompanyUser;
    }
  }, [isMulti, selectedCompanyUser]);

  const selectedOptionId = useMemo(() => {
    if (!selectedCompanyUserId) return undefined;
    if (isMulti) {
      return Array.isArray(selectedCompanyUserId) ? selectedCompanyUserId : [selectedCompanyUserId];
    } else {
      return Array.isArray(selectedCompanyUserId) ? selectedCompanyUserId[0] : selectedCompanyUserId;
    }
  }, [isMulti, selectedCompanyUserId]);

  const isAllSelected =
    isMulti &&
    addSelectAllOption &&
    areAllSelected(defaultOptions?.length, selectedCompanyUser as ISelectOption[], selectedCompanyUserId as string[]);
  const pageSize = props.pageSize ?? 25;

  const loadUsers = useCallback(
    async (query?: string) => {
      const response = await getCompanyUsers({
        companyId: companyId ?? undefined,
        pageNumber: 1,
        pageSize: pageSize,
        query: query,
        companyUserIds: query ? undefined : companyUserIds,
        usedAsSelectList: true,
        includeDeleted: false,
      });
      var companyUsers = isCompanyUserActived ? response?.items.filter((x) => x.inviteAccepted) : response?.items;
      if (omitSelf && user.user !== undefined)
        companyUsers = companyUsers.filter((x) => x.id !== user.user.selectedUserId);
      var newOptions =
        companyUsers?.map((x) => ({
          value: x.id.toString(),
          label: x.firstName + ' ' + x.lastName,
        })) ?? [];

      if (addSelectAllOption && newOptions.length > 0) {
        newOptions?.unshift(selectAllOption);
      }

      return newOptions;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyId, companyUserIds, pageSize, addSelectAllOption, isCompanyUserActived, getCompanyUsers],
  );

  useEffect(() => {
    //Load with no query
    loadUsers().then((x) => {
      setDefaultOptions(x);
      if (defaultAll) props.onChange && props.onChange(undefined, undefined, selectAllList, true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadUsers]);

  if (isMulti) {
    return (
      <MultiAsyncSelect
        {...props}
        placeholder={props.placeholder ?? 'Välj användare'}
        autoFocus={props.autoFocus ?? true}
        isLoading={isLoading}
        selectedOptions={isAllSelected ? [selectAllOption] : (selectedOption as ISelectOption[] | undefined)}
        selectedOptionIds={isAllSelected ? ['0'] : (selectedOptionId as string[] | undefined)}
        defaultOptions={isAllSelected ? emptyList : defaultOptions}
        loadOptions={loadUsers}
        onChange={(value) => {
          props.onChange && props.onChange(undefined, undefined, value, false);
        }}
        customWidth={customWidth}
      />
    );
  }

  return (
    <AsyncSelect
      {...props}
      placeholder={props.placeholder ?? 'Välj användare'}
      autoFocus={props.autoFocus ?? true}
      isLoading={isLoading}
      selectedOption={selectedOption as ISelectOption | undefined}
      selectedOptionId={selectedOptionId as string | undefined}
      defaultOptions={defaultOptions}
      loadOptions={loadUsers}
      onChange={(value) => props.onChange && props.onChange(value?.value, value?.label, value ?? undefined, false)}
      customWidth={customWidth}
    />
  );
};

export default CompanyUserSelect;
