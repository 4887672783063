import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Checkbox, FormControlLabel, Tab } from '@material-ui/core';
import {
  ICoreSystemProduct,
  ICoreSystemEventAction,
  ICoreSystemUser,
  useGetCoreSystem,
  useRemoveProductFromCoreSystem,
  useUpdateCoreSystemProducts,
  useUpdateCoreSystemEventActions,
  useUpdateCoreSystemUsers,
} from '../CoreSystem.api';
import Container from '../../../../../../../Shared/Container/Container';
import AccessGuard from '../../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import Tabs from '../../../../../../../Shared/Tabs/Tabs';
import Button from '../../../../../../../Shared/Button/Button';
import EventActionList from '../../../../../../Shared/EventActionList/EventActionList';
import SystemUserList from '../../../../../../Shared/SystemUserList/SystemUserList';
import Communication from './Components/Communication/Communication';
import ProductList from './Components/Product/ProductList';
import { ModuleIdentifiers, AccessRights } from '../../../../../../../Core/Authentication/ModuleAccess';
import { InputField } from '../../../../../../../Shared/InputFields/InputField/InputField';
import { isErrorResponse } from '../../../../../../../Shared/Api/response/IErrorRespose';
import formatDate from '../../../../../../../Shared/Formatting/formatDate';
import useErrorMessage from '../../../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import AgreementList from '../../../ViewCustomer/AgreementList/AgreementList';
import { useStyles } from './ViewCoreSystem.styles';

export default function ViewCoreSystem() {
  const history = useHistory();
  const { hash } = useLocation();
  const classes = useStyles();
  const { customerId, facilityId, id } = useParams<{ customerId: string; facilityId: string; id: string }>();
  const { isLoading, coreSystem, getCoreSystem } = useGetCoreSystem(customerId, facilityId);
  const { isLoading: isRemoveProductLoading, remove: removeProduct } = useRemoveProductFromCoreSystem(
    customerId,
    facilityId,
    id,
  );
  const { isUpdatingSystemProductsLoading, updateSystemProducts } = useUpdateCoreSystemProducts(id);
  const { isUpdatingEventActionsLoading, updateEventActions } = useUpdateCoreSystemEventActions(id);
  const { isUpdatingSystemUsersLoading, updateSystemUsers } = useUpdateCoreSystemUsers(id);
  const { setErrorMessage } = useErrorMessage();
  const [label, setLabel] = useState<string>();
  const tabList = [
    "systeminformation",
    "systemet",
    "kommunikation",
    "atgardslista",
    "anvandarlista",
    "avatal"
  ]

  const loadCoreSystem = useCallback(async () => {
    const response = await getCoreSystem(id);
    if (!response) {
      history.push('/customers/' + customerId);
    } else {
      const mainProduct = response.products.find((x) => x.productId === response.mainProductId) as ICoreSystemProduct;
      setLabel(`${response.coreSystemIdentifier} - ${mainProduct.productName}`);
    }
  }, [id, customerId, history, getCoreSystem]);

  useEffect(() => {
    loadCoreSystem();
  }, [loadCoreSystem]);

  async function deleteProduct(productId: number) {
    const result = await removeProduct(productId.toString());
    if (isErrorResponse(result)) {
      setErrorMessage({ message: result.message });
    } else {
      loadCoreSystem();
    }
  }

  async function saveSystemProducts(products: ICoreSystemProduct[]) {
    await updateSystemProducts(products);
    loadCoreSystem();
  }

  async function saveEventActions(eventActions: ICoreSystemEventAction[]) {
    await updateEventActions(eventActions);
    loadCoreSystem();
  }

  async function saveSystemUsers(users: ICoreSystemUser[]) {
    await updateSystemUsers(users);
    loadCoreSystem(); 
  }

  return (
    <div className={classes.wrapper}>
      <Tabs
        customSize={{
          fullsize: true
        }}
        startIndex={hash && hash.length > 0  ? tabList.findIndex((x) => x === hash.slice(1)) : 0}
        onChange={(index) => { window.location.hash = tabList[index]}}
      >
        <Tab label="Systeminformation">
          <Container
            customSize={{fullsize: true}}
            loading={isLoading}
            form
            actions={
              <AccessGuard module={ModuleIdentifiers.Customer} accessRights={AccessRights.Write}>
                <Button
                  data-testid="info-edit-button"
                  color="default"
                  variant="outlined"
                  onClick={() => history.push(`${history.location.pathname}/update`)}
                >
                  Redigera
                </Button>
              </AccessGuard>
            }
          >
            <InputField label="System-ID" value={label} viewOnly />
            <InputField label="Systemtyp" value={coreSystem?.systemTypeName} viewOnly />
            <InputField
              label="Regelverk"
              value={`${coreSystem?.regulationName} - Larmklass ${coreSystem?.alarmClass}`}
              viewOnly
            />
            <InputField label="Säljare" value={coreSystem?.projectLeaderName} viewOnly />
            <InputField
              label="Montör"
              value={`${coreSystem?.mainInstallerName} (${
                coreSystem?.installDate ? formatDate(coreSystem.installDate) : ''
              })`}
              viewOnly
            />
            <InputField
              label="Beställare/projektledare"
              // TODO: Fix so that this shows the beställare and the beställningsdatum
              value={`${
                coreSystem?.client
                  ? `${coreSystem.client} ${coreSystem?.clientPhoneNumber ?? `(${coreSystem.clientPhoneNumber})`} /`
                  : `Godkänd av admin (${formatDate(coreSystem?.installDate)}) /`
              }  ${coreSystem?.projectLeaderName} `}
              viewOnly
            />
            <InputField label="Placering" value={coreSystem?.systemPlacement} viewOnly />
            <FormControlLabel
              control={
                <Checkbox name="customerDocsCheckbox" color="primary" checked={coreSystem?.customerDocuments} disabled />
              }
              label="Kundpärm finns"
            />
          </Container>
        </Tab>
        <Tab label="Systemet">
          <ProductList
            mainProductId={coreSystem?.mainProductId}
            products={coreSystem?.products ?? []}
            isLoading={isLoading}
            isUpdateLoading={isUpdatingSystemProductsLoading}
            updateProducts={saveSystemProducts}
            isRemoveLoading={isRemoveProductLoading}
            removeProduct={deleteProduct}
          />
        </Tab>
        <Tab label="Kommunikation">
          <Communication />
        </Tab>
        <Tab label="Åtgärdslista">
          <EventActionList
            eventActions={coreSystem?.eventActions ?? []}
            isLoading={isLoading}
            isUpdateLoading={isUpdatingEventActionsLoading}
            save={saveEventActions}
          />
        </Tab>
        <Tab label="Användarlista">
          <SystemUserList
            users={coreSystem?.systemUsers ?? []}
            isLoading={isLoading}
            isUpdateLoading={isUpdatingSystemUsersLoading}
            save={saveSystemUsers}
            disableEdit={coreSystem?.onPendingConfirm}
          />
        </Tab>
        <Tab label="Avtal">
          <AgreementList customerId={customerId} coreSystemId={id} />
        </Tab>
      </Tabs>
    </div>
  );
}
