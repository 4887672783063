import { makeStyles, Theme } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '1rem',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '1rem 2rem',
    },
  },
}));
