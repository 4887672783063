import React, { useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import PopperMenu from '../../Popper/Popper';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useStyles } from './UserDropdownMenu.styles';
import useUserContext, { IUser } from '../../../Core/Authentication/UserContext';
import { useHistory } from 'react-router-dom';
import AccessGuard from '../../../Core/Authentication/AccessGuard/AccessGuard';
import { ModuleIdentifiers } from '../../../Core/Authentication/ModuleAccess';

interface IUserDropdownMenuProps {
  user: IUser;
  logout: () => void;
  white?: boolean;
  alwaysMobile?: boolean;
}

export const UserDropdownMenu = (props: IUserDropdownMenuProps) => {
  const classes = useStyles();
  const { user } = props;
  const { setSession } = useUserContext();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const handleChangeSession = () => {
    // Waiting for the backend to be finished to implement this further.
    setSession(undefined);
  };

  const MobileIcon: React.ReactChild = (
    <span className={classes.MobileIcon}>{user.username.charAt(0) + '' + user.username.split(" ")[1].charAt(0)}</span>
  )

  return (
    <div>
      <PopperMenu
        variant="outlined"
        buttonType={props.alwaysMobile || mdDown ? 'icon' : undefined}
        className={`${props.white ? classes.white : ''}`}
        content={
          <>
            {user.sessions.length > 1 && <MenuItem onClick={handleChangeSession}>Byt ingång</MenuItem>}
            <MenuItem onClick={() => history.push('/user/log')}>Visa loggar</MenuItem>
            <MenuItem onClick={() => history.push('/user/homepage')}>Startsidan inställningar</MenuItem>
            <AccessGuard module={ModuleIdentifiers.Admin}>
              <MenuItem onClick={() => history.push('/admin')}>Admin inställningar</MenuItem>
            </AccessGuard>
            <MenuItem onClick={props.logout}>Logga ut</MenuItem>
          </>
        }
        setOpen={setOpen}
        open={open}
      >
        {props.alwaysMobile || mdDown ? MobileIcon : user.username}
      </PopperMenu>
    </div>
  );
};
