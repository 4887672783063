import React, { useState, useEffect, useMemo } from 'react';
import Select, { ISelectOption } from './Select';
import useDebounce from '../Hooks/UseDebounce/useDebounce';
import { GridSize } from '@material-ui/core';

interface IBaseAsyncSelectProps {
  label?: string;
  placeholder?: string;
  required?: boolean;
  errorText?: string | string[];
  fullwidth?: boolean;
  autoFocus?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  defaultOptions?: ISelectOption[];
  cacheOptions?: any;
  loadOptions: (query?: string) => Promise<ISelectOption[]>; //Needs to be a callback
}

export interface IAsyncSelectProps extends IBaseAsyncSelectProps {
  viewOnly?: boolean;
  selectedOption?: ISelectOption;
  selectedOptionId?: string;
  customWidth?: GridSize;
  onChange?: (value: ISelectOption | undefined) => void;
}

export interface IAsyncMultiSelectProps extends IBaseAsyncSelectProps {
  selectedOptions?: ISelectOption[];
  selectedOptionIds?: string[];
  customWidth?: GridSize;
  onChange: (value: ISelectOption[] | undefined) => void;
}
interface IProps extends IAsyncMultiSelectProps {
  isMulti: boolean;
  viewOnly?: boolean;
  customWidth?: GridSize;
}

const BaseAsyncSelect = (props: IProps) => {
  const { selectedOptions, selectedOptionIds, defaultOptions, loadOptions } = props;
  const [options, setOptions] = useState<ISelectOption[] | undefined>(defaultOptions);
  const [asyncLoad, setAsyncLoad] = useState<{ value: string; callback: (arg: any) => void }>();
  const asyncLoadDebounced = useDebounce(asyncLoad, 750);

  const selectedValues = useMemo(() => {
    if (selectedOptions !== undefined) return selectedOptions;
    if (selectedOptionIds === undefined || selectedOptionIds.length === 0) return undefined;
    return options?.filter((x) => {
      return selectedOptionIds.some((y) => y === x.value);
    });
  }, [selectedOptionIds, selectedOptions, options]);

  useEffect(() => {
    if (defaultOptions) setOptions(defaultOptions);
  }, [defaultOptions]);

  useEffect(() => {
    async function getList() {
      const newOptions = await loadOptions(asyncLoadDebounced?.value);
      setOptions(newOptions);
      asyncLoadDebounced?.callback(newOptions);
    }
    if (asyncLoadDebounced) getList();
  }, [asyncLoadDebounced, loadOptions]);

  return (
    <Select
      autoComplete
      viewOnlyValue={props.viewOnly && selectedValues && selectedValues.length ? selectedValues[0].label : undefined}
      inputFieldProps={{
        label: props.label,
        placeholder: props.placeholder,
        viewOnly: props.viewOnly,
        required: props.required,
        disabled: props.isDisabled,
        errorText: props.errorText,
        fullwidth: props.fullwidth,
        customWidth: props.customWidth,
      }}
      selectProps={{
        autoFocus: props.autoFocus,
        isMulti: props.isMulti,
        isClearable: props.isClearable,
        isLoading: props.isLoading,
        isDisabled: props.isDisabled,
        value: selectedValues,
        defaultOptions: defaultOptions ?? true,
        cacheOptions: props.cacheOptions,
        loadOptions: (searchValue, searchCallback) => {
          setAsyncLoad({ value: searchValue, callback: searchCallback });
        },
        onChange: (value) => {
          var newValue: ISelectOption[] | undefined = undefined;
          if (value) {
            if (props.isMulti) newValue = value as ISelectOption[];
            else newValue = [value as ISelectOption];
          }
          props.onChange(newValue);
          setOptions(defaultOptions);
        },
      }}
    ></Select>
  );
};

export const MultiAsyncSelect = (props: IAsyncMultiSelectProps) => {
  return <BaseAsyncSelect {...props} isMulti={true} />;
};

const AsyncSelect = (props: IAsyncSelectProps) => {
  const { selectedOption, selectedOptionId, onChange } = props;

  var selectedOptions = useMemo(() => (selectedOption ? [selectedOption] : undefined), [selectedOption]);
  var selectedOptionIds = useMemo(() => (selectedOptionId ? [selectedOptionId] : undefined), [selectedOptionId]);

  return (
    <BaseAsyncSelect
      {...props}
      isMulti={false}
      selectedOptions={selectedOptions}
      selectedOptionIds={selectedOptionIds}
      onChange={(value) => onChange && onChange(value ? value[0] : undefined)}
    />
  );
};

export default AsyncSelect;
