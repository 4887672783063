import { useCallback } from 'react';
import { useApiDelete, useApiGet, useApiPost } from '../../../../../Core/Api/Api';
import { IFileResult } from '../../../../../Shared/Api/response/IFileResult';
import IPaginatedResponse from '../../../../../Shared/Api/response/IPaginatedResponse';

export interface IAgreementListItems {
  id: number;
  agreementNumber: number;
  customerId: number;
  customerNumber: number;
  customerName: string;
  customerAddress: string;
  facilityId: number;
  coreSystemId: number;
  systemType: string;
  startDate: Date;
  endDate: Date;
  isActive: boolean;
  isTerminated: boolean;
}

export function useGetAgreementsList() {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<IAgreementListItems>>('/api/agreement/list');

  return {
    isLoading,
    agreementList: response,
    getAgreementsList: post,
  };
}

export function useGetAgreementPdf() {
  const { isLoading, response, get } = useApiGet<IFileResult>(`/api/agreement/`, false);
   
  const getAgreementPdf = useCallback(
    (agreementId: string) => {
      return get(`${agreementId}/pdf`);
    },
    [get],
  );
  
  return {
    isLoading,
    agreementPdf: response,
    getAgreementPdf,
  };
}

export function useSoftDeleteAgreement() {
  const { isLoading, delete: deleteRequest } = useApiDelete(`/api/agreement/`);

  const deleteCallback = useCallback(
    async (agreementId: string) => {
      return await deleteRequest(undefined, agreementId);
    },
    [deleteRequest],
  );

  return {
    isLoading,
    softDeleteAgreement: deleteCallback,
  };
}