import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  messageListContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: '1rem',
    borderRadius: theme.shape.borderRadius,
  },
  messageListContainerError: {
    display: 'flex',
    alignItems: 'flex-start',
    border: `1px solid ${theme.palette.error.main}`,
    padding: '1rem',
    borderRadius: theme.shape.borderRadius,
  },
  greyContainer: {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  blueContainer: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  redContainer: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  greenContainer: {
    border: `1px solid ${theme.palette.success.main}`,
  },
  warningColor: {
    color: theme.palette.error.main,
  },
  normalColor: {
    color: 'black',
  },
  message: {
    flexGrow: 1,
    cursor: 'pointer',
    position: 'relative',
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      right: '0px',
      top: '-1px'
    },
    '& > p': {
      paddingRight: '26px'
    }
  },
  bold: {
    marginTop: '0.5rem',
    fontWeight: 600,
  },
  list: {
    padding: 0,
    margin: 0,
  },
  todoList: {
    marginBottom: '1rem',
  },
  listItem: {
    marginBottom: '0.75rem',
    '&:last-child': {
      marginBottom: 0,
    },
    listStyleType: 'none',
  },
  eventListItem: {
    marginBottom: '0.75rem',
    listStyleType: 'none',
  },
  dash: {
    margin: '0 0.25rem',
  },
  eventListInformation: {
    display: 'block; display: -webkit-box;',
    maxHeight: '3.6rem',
    lineHeight: '1.2rem',
    lineClamp: 3,
    boxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));
