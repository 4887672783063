import React, { useEffect, useState } from 'react';
import { Table } from '../../../../../../../../Shared/Table/Table';
import {
  InputBase,
  ListItemIcon,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import IconButton from '../../../../../../../../Shared/IconButton/IconButton';
import { InputField } from '../../../../../../../../Shared/InputFields/InputField/InputField';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import useStyles from './ProductTable.styles';
import { MobileTableRow } from '../../../../../../../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import { MobileTable } from '../../../../../../../../Shared/Table/MobileTable/MobileTable';
import { DropdownCell } from '../../../../../../../../Shared/Table/DropdownCell/DropdownCell';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';

enum tableColumn {
  placement = 1,
  comment = 2,
  quantity = 3,
}

export interface IProductList {
  id: number;
  name: string;
  comment?: string | undefined;
  quantity?: number | undefined;
  placement?: string | undefined;
}

export interface IProductTable {
  isLoading?: boolean;
  products: IProductList[];
  onChange: (products: IProductList[]) => void;
}

const ProductTable = (props: IProductTable) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { isLoading, products, onChange } = props;
  const [productsList, setProductList] = useState<IProductList[]>();

  useEffect(() => {
    if (products) {
      setProductList([...products]);
    }
  }, [products]);

  const updateProduct = (value: string, index: number, column: tableColumn) => {
    if (!productsList) return;

    const updatedList = [...productsList];

    if (column === tableColumn.placement) {
      updatedList[index].placement = value;
    } else if (column === tableColumn.comment) {
      updatedList[index].comment = value;
    } else if (column === tableColumn.quantity) {
      updatedList[index].quantity = value ? parseInt(value) : undefined;
    }

    onChange(updatedList);
  };

  const onRemove = (index: number) => {
    if (productsList) {
      const updatedList = productsList.filter((_, i) => i !== index);

      onChange(updatedList);
    }
  };

  return (
    <>
      {!mobileView ? (
        <Table
          loading={{
            loading: isLoading !== undefined && isLoading,
            skeletonRows: productsList?.length,
          }}
          label="Valda produkter"
          empty={
            products && {
              empty: products.length < 1,
            }
          }
        >
          <TableHead>
            <TableRow>
              <TableCell>Produkt</TableCell>
              <TableCell>Placering</TableCell>
              <TableCell>Kommentar</TableCell>
              <TableCell className={classes.th}>Antal</TableCell>
              <TableCell>
                <span className={'sr-only'}>Knappar</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productsList &&
              productsList.map((x, index) => (
                <TableRow key={index}>
                  <TableCell>{x.name}</TableCell>
                  <TableCell>
                    <InputField
                      fullwidth
                      placeholder="Placering"
                      value={x.placement}
                      onChange={(event) => updateProduct(event.target.value, index, tableColumn.placement)}
                    />
                  </TableCell>
                  <TableCell>
                    <InputField
                      fullwidth
                      placeholder="Kommentar"
                      value={x.comment}
                      onChange={(event) => updateProduct(event.target.value, index, tableColumn.comment)}
                    />
                  </TableCell>
                  <TableCell>
                    <InputBase
                      type="number"
                      value={x.quantity}
                      onChange={(event) => updateProduct(event.target.value, index, tableColumn.quantity)}
                      className={classes.input}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton color="error" onClick={() => onRemove(index)} aria-label="Ta bort rad">
                      <RemoveIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      ) : (
        <div className={classes.mobileTable}>
          {productsList &&
            productsList.map((x, index) => (
              <MobileTable
                label={x.name + '(' + x.quantity + ')'}
                key={index}
                action={
                  <>
                    <DropdownCell mobile={mobileView}>
                      <MenuItem
                        onClick={() => {
                          onRemove(index);
                        }}
                      >
                        <ListItemIcon>
                          <DeleteIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">Ta bort</Typography>
                      </MenuItem>
                    </DropdownCell>
                  </>
                }
              >
                <>
                  <MobileTableRow label={'Produkt'} value={x.name}></MobileTableRow>
                  <MobileTableRow label={'Placering'} value={x.placement} />
                  <MobileTableRow label={'Kommentar'} value={x.comment} />
                </>
              </MobileTable>
            ))}
        </div>
      )}
    </>
  );
};

export default ProductTable;
