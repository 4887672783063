import { useCallback } from 'react';
import { useApiDelete, useApiGet, useApiPost, useApiPut } from '../../../../../Core/Api/Api';
import ISearchRequest from '../../../../../Shared/Api/request/ISearchRequest';
import IPaginatedResponse from '../../../../../Shared/Api/response/IPaginatedResponse';
import ValidationErrorResultType from '../../../../../Shared/Api/response/ValidationErrorResultType';

export enum SurveyType {
  AfterInvoice = 1,
  SupplierQualification = 2,
  Custom = 3,
}

export enum SurveyQuestionType {
  Satisfaction = 1,
  Text = 2,
  Choice = 3,
}

export interface ISurveyQuestionType {
  name: string;
  type: SurveyQuestionType;
}
export const surveyQuestionTypes: ISurveyQuestionType[] = [
  { name: 'Nöjdhet', type: SurveyQuestionType.Satisfaction },
  { name: 'Text', type: SurveyQuestionType.Text },
  { name: 'Flerval', type: SurveyQuestionType.Choice },
];

export interface ISurveyListItemDto {
  id: number;
  name: string;
  isActive: boolean;
  type: SurveyType;
  mailSubject: string;
  interval: number;
}

export function useGetSurveyList() {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<ISurveyListItemDto>, ISearchRequest>(
    '/api/surveys/list',
    false,
  );

  return {
    isLoading,
    surveys: response,
    getSurveys: post,
  };
}

export interface ISurvey {
  type: SurveyType;
  name: string;
  mailMessage: string;
  mailInterval: MailInterval;
  recipientIds?: number[];
  recipientType: ResponderType;
  isAllSelect: boolean;
  nextSendDate: Date;
  questions: ISurveyQuestion[];
}

export interface ISurveyQuestion {
  id?: number;
  sortOrder: number;
  question: string;
  type: SurveyQuestionType;
}

export interface IChoiceQuestion extends ISurveyQuestion {
  choices?: IChoiceQuestionAnswer[];
}

export interface IChoiceQuestionAnswer {
  id?: number;
  choiceOption: string;
  sortOrder: number;
}

export function useGetSurvey() {
  const { isLoading, response, get } = useApiGet<ISurvey>('/api/surveys/', false);

  return {
    isLoading,
    survey: response,
    getSurvey: get,
  };
}

export interface IUpsertSurveyValidationErrors extends ValidationErrorResultType<IUpsertSurveyCommand> {}
export interface IUpsertSurveyCommand {
  surveyName: string;
  mailMessage: string;
  mailInterval?: MailInterval;
  recipientType?: ResponderType;
  recipients: number[];
  isAllSelect: boolean;
  nextSendDate?: Date | null;
  textQuestions: ISurveyQuestion[];
  satisfactionQuestions: ISurveyQuestion[];
  choiceQuestions: ISurveyQuestion[];
  emailRecipient: boolean;
}

export function useUpsertSurvey() {
  const { isLoading: isCreateLoading, post } = useApiPost<IUpsertSurveyValidationErrors, IUpsertSurveyCommand>(
    '/api/surveys/',
  );

  const { isLoading: isUpdateLoading, put } = useApiPut<IUpsertSurveyValidationErrors, IUpsertSurveyCommand>(
    '/api/surveys/',
  );

  const upsertSurvey = useCallback(
    async (command: IUpsertSurveyCommand, id?: string) => {
      if (id && id !== '0') {
        return await put(command, id);
      }

      return await post(command);
    },
    [put, post],
  );

  return {
    isLoading: isCreateLoading || isUpdateLoading,
    upsertSurvey,
  };
}

export function useDeleteSurvey() {
  const { isLoading, delete: deleteRequest } = useApiDelete(`/api/surveys/`);

  const deleteSurvey = useCallback(
    async (surveyId: string) => {
      return await deleteRequest(undefined, surveyId);
    },
    [deleteRequest],
  );

  return {
    isLoading,
    deleteSurvey,
  };
}

export function useGetSurveyResponses() {
  const { isLoading, response, post } = useApiPost<ISurveyResponseDto[], IListResponsesQuery>('/api/surveys/');

  const getSurveyResponses = useCallback(
    (id: string, data: IListResponsesQuery) => {
      return post(data, `${id}/responses`);
    },
    [post],
  );

  return {
    isLoading,
    responses: response,
    getSurveyResponses: getSurveyResponses,
  };
}

export interface IListResponsesQuery {
  fromDate: Date;
  toDate: Date;
}

export interface ISurveyResponseDto {
  id: number;
  created: Date;
  responderId: number;
  responderName: string;
  responderNumber: string;
  responderType: ResponderType;
  answers: ISurveyQuestionAnswer[];
}

export interface ISurveyQuestionAnswer {
  question: string;
  questionType: SurveyQuestionType;
  answer: string;
}

export enum ResponderType {
  Customer,
  Supplier,
  CompanyUser,
}

export const responderTypes = [
  { name: 'Kund', type: ResponderType.Customer },
  { name: 'Leverantör', type: ResponderType.Supplier },
  { name: 'Användare', type: ResponderType.CompanyUser },
];

export enum MailInterval {
  OneWeek,
  OneMonth,
  ThreeMonth,
  SixMonth,
  OneYear,
}

export const mailIntervals = [
  { name: 'Engångs', interval: undefined },
  { name: 'Varje vecka', interval: MailInterval.OneWeek },
  { name: 'Varje månad', interval: MailInterval.OneMonth },
  { name: 'Var tredje månad', interval: MailInterval.ThreeMonth },
  { name: 'Var sjätte månad', interval: MailInterval.SixMonth },
  { name: 'Varje år', interval: MailInterval.OneYear },
];

export function useDeactiveSurvey() {
  const { isLoading, put } = useApiPut('/api/surveys/');

  const deactiveSurvey = useCallback(
    async (id: string) => {
      return await put(undefined, `${id}/deactivate`);
    },
    [put],
  );

  return {
    isLoading,
    deactiveSurvey,
  };
}
