import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  navigation: {
    marginBottom: '10px',
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      display: 'flex',
      flexDirection: 'column',
    },
    flex: '1 0 auto',
    margin: '13px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gridGap: '20px',
  },
  listItem: {
    marginBottom: '0.75rem',
    width: '100%',
    '&:last-child': {
      marginBottom: 0,
    },
    listStyleType: 'none',
  },
  listItemText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    '& > span': {
      display: 'unset',
    },
  },
  iconButton: {
    padding: '0.5rem',
  },
  forms: {
    [theme.breakpoints.down('xs')]: {
      width: '95%',
      marginLeft: '0px',
      marginTop: '10px',
      gap: '0px',
    },
    marginLeft: '10px',
    display: 'flex',
    width: '50%',
  },
  //FileNode
  ParentNode: {
    marginTop: '3px',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
  },
  ChildrenNode: {
    backgroundColor: theme.palette.grey[100],
    '&:first-child': {
      borderTop: `1px solid ${theme.palette.grey[400]}`,
    },
    '&:last-child': {
      borderBottomRightRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
  },
  empty: {
    minHeight: '15rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyMessage: {
    marginBottom: '1rem',
    marginLeft: '1.25rem',
  },

  focusNode: {
    backgroundColor: theme.palette.grey[200],
  },
  notFocusNode: {
    backgroundColor: theme.palette.background.paper,
  },
  pdfViewerElement: {
    width: '100%',
    height: '1000px'
  }
}));
