import { Checkbox, FormControlLabel, InputAdornment, useMediaQuery } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import Container from '../../../../../../Shared/Container/Container';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import { Fieldset } from '../../../../../../Shared/Form/Fieldset/Fieldset';
import Select, { ISelectOption } from '../../../../../../Shared/Select/Select';
import {
  measures,
  services,
  auditIntervals,
  rentTypes,
  transferTypes,
  transfersIntervals,
  Measure,
  Service,
  AuditInterval,
  RentType,
  TransferType,
  TransferInterval,
  mapAgreementOptions,
  invoiceIntervals,
  InvoiceInterval,
} from '../../../../Errands/Components/Sale.api';
import Datepicker from '../../../../../../Shared/InputFields/DatePicker/Datepicker';
import CheckboxField from '../../../../../../Shared/InputFields/CheckboxField/CheckboxField';
import useFormValidation from '../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import useNullableNumericInputState from '../../../../../../Shared/Hooks/UseNullableNumericInputState/UseNullableNumericInputState';
import { SupplierType } from '../../../../Admin/Components/Suppliers/Suppliers.api';
import { useHistory, useParams } from 'react-router';
import { SupplierSelect } from '../../../../Admin/Components/Suppliers/SupplierSelect/SupplierSelect';
import FacilitySelect from '../../Facilities/FacilitySelect/FacilitySelect';
import { useGetFacility } from '../../Facilities/Facilities.api';
import useInputState from '../../../../../../Shared/Hooks/UseInputState/UseInputState';
import {
  IUpsertAgreementValidationErrors,
  useGetAgreement,
  useGetContactList,
  useGetCustomer,
  useUpsertAgreement,
} from '../../../Customers.api';
import EmailField from '../../../../../../Shared/InputFields/EmailField/EmailField';
import { isResultError } from '../../../../../../Shared/Api/response/ICreateResult';
import Button from '../../../../../../Shared/Button/Button';
import useErrorMessage from '../../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import theme from '../../../../../../Styles/Theme';
import CustomerInfoDisplay from '../CustomerInfo/CustomerInfoDisplay';
import { getCurrentDay, getSelectedOption, getSelectedValue } from '../../../../../Shared/utils';
import PhoneNumberField from '../../../../../../Shared/InputFields/PhoneNumberField/PhoneNumberField';
import { useGetPreselectedAgreementTerms } from '../../../../Admin/Components/Preselection/Preselection.api';
import FacilityInfoDisplay from '../FacilityInfo/FacilityInfoDisplay';
import moment from 'moment';
import MultiButton from '../../../../../../Shared/MultiButton/MultiButton';
import { useGetAgreementPdf } from '../../../../Errands/Components/ErrandAgreementList/Agreements.api';
import { openFile } from '../../../../../../Shared/Api/response/IFileResult';

const measureOptions: ISelectOption[] = mapAgreementOptions(measures);
const serviceOptions: ISelectOption[] = mapAgreementOptions(services);
const auditOptions: ISelectOption[] = mapAgreementOptions(auditIntervals);
const rentOptions: ISelectOption[] = mapAgreementOptions(rentTypes);
const transferOptions: ISelectOption[] = mapAgreementOptions(transferTypes);
const transferIntervalOptions: ISelectOption[] = mapAgreementOptions(transfersIntervals);
const invoiceOptions: ISelectOption[] = mapAgreementOptions(invoiceIntervals);

interface IAgreementFormProps {
  viewOnly?: boolean;
}

const getEndDateFromYears = (years: number = 0) => {
  let tempEndDate = new Date();
  tempEndDate.setFullYear(tempEndDate?.getFullYear() + years);
  return tempEndDate;
};

const validateMonthlyCost = (monthlyCost: number | null) => {
  return monthlyCost != null && monthlyCost >= 0;
};

const AgreementForm = (props: IAgreementFormProps) => {
  const { customerId, facilityId, agreementId } = useParams<{
    customerId: string;
    facilityId?: string;
    agreementId?: string;
  }>();
  const history = useHistory();
  const { isFormValid, formRef } = useFormValidation();
  const { isLoading: isFacilityLoading, getFacility } = useGetFacility(customerId);
  const { isLoading: isContactsLoading, contacts, getContactList } = useGetContactList(customerId, facilityId);
  const { isLoading: isUpsertLoading, upsert } = useUpsertAgreement(customerId);
  const { isLoading: isAgreementLoading, get } = useGetAgreement(customerId);
  const { getPreselectedAgreementTerms } = useGetPreselectedAgreementTerms();
  const { customer, getCustomer } = useGetCustomer();
  const { getAgreementPdf } = useGetAgreementPdf();
  const { setErrorMessage } = useErrorMessage();

  const [selectedFacilityId, setSelectedFacilityId] = useState<string | undefined>(facilityId);
  const [coreSystemOptions, setCoreSystemOptions] = useState<ISelectOption[]>([]);
  const [selectedCoreSystemId, setSelectedCoreSystemId] = useState<string>();
  const [agreementNumber, setAgreementNumber] = useState<Number>();

  const [selectedContactId, setSelectedContactId] = useState<string>();
  const [contactOptions, setContactOptions] = useState<ISelectOption[]>();
  const [contactFirstName, changeContactFirstName, setContactFirstName] = useInputState('');
  const [contactLastName, changeContactLastName, setContactLastName] = useInputState('');
  const [contactPosition, changeContactPosition, setContactPosition] = useInputState('');
  const [contactPhoneNumber, changeContactPhoneNumber, setContactPhoneNumber] = useInputState('');
  const [contactEmail, changeContactEmail, setContactEmail] = useInputState('');

  const [monthlyCost, changeMonthlyCost, setMonthlyCost] = useNullableNumericInputState(0);
  const [startDate, setStartDate] = useState<Date>(getCurrentDay(1));
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [checkedEndDate, setCheckedEndDate] = useState(false);

  const [selectedSecurityCompanyId, setSelectedSecurityCompanyId] = useState<string>();
  const [selectedEmergencyCallCenterId, setSelectedEmergencyCallCenterId] = useState<string>();

  const [includeInnerControl, setIncludeInnerControl] = useState<boolean>(false);
  const [measure, setMeasure] = useState<Measure | undefined>(undefined);
  const [service, setService] = useState<Service | undefined>();
  const [invoiceInterval, setInvoiceInterval] = useState<InvoiceInterval | undefined>();
  const [auditInterval, setAuditInterval] = useState<AuditInterval | undefined>();
  const [rentType, setRentType] = useState<RentType | undefined>();
  const [transferType, setTransferType] = useState<TransferType | undefined>();
  const [transferInterval, setTransferInterval] = useState<TransferInterval | undefined>();
  const [error, setError] = useState<IUpsertAgreementValidationErrors>();
  const [
    externalAgreementInvoiceNumber,
    updateExternalAgreementInvoiceNumber,
    setExternalAgreementInvoiceNumber,
  ] = useInputState<string | undefined>('');
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isValid =
    isFormValid && customerId && startDate && (checkedEndDate || endDate) && validateMonthlyCost(monthlyCost);

  useEffect(() => {
    async function getAgreement() {
      const response = await get(agreementId);
      setSelectedFacilityId(response.facilityId?.toString());
      setSelectedCoreSystemId(response.coreSystemId?.toString());
      setAgreementNumber(response.agreementNumber);
      setSelectedContactId(response.contactPersonId?.toString());
      setMonthlyCost(response.monthlyCost);
      setStartDate(new Date(response.startDate));
      if (response.endDate) {
        setEndDate(new Date(response.endDate));
        setCheckedEndDate(false);
      } else {
        setEndDate(null);
        setCheckedEndDate(true);
      }
      setSelectedEmergencyCallCenterId(response.emergencyCallCenterId?.toString());
      setSelectedSecurityCompanyId(response.securityCompanyId?.toString());
      setMeasure(response.measure);
      setService(response.service);
      setInvoiceInterval(response.invoiceInterval);
      setAuditInterval(response.auditInterval);
      setRentType(response.rentType);
      setTransferType(response.transferType);
      setTransferInterval(response.transferInterval);
      setExternalAgreementInvoiceNumber(response.externalAgreementInvoiceNumber);
    }
    if (agreementId) getAgreement();
  }, [agreementId, setExternalAgreementInvoiceNumber, get, setMonthlyCost]);

  useEffect(() => {
    async function getCurrentCustomer() {
      await getCustomer(customerId + '/false');
    }

    if (!facilityId && props.viewOnly && customerId) {
      getCurrentCustomer();
    }
  }, [facilityId, customerId, props.viewOnly, getCustomer]);

  useEffect(() => {
    const getValues = async () => {
      const results = await getPreselectedAgreementTerms();
      let agreementPeriod = 3;
      if (results) {
        setInvoiceInterval(results.invoiceInterval);
        setAuditInterval(results.auditInterval);
        switch (results.auditInterval) {
          case AuditInterval.OneYear:
            agreementPeriod = 1;
            break;
          case AuditInterval.TwoYears:
            agreementPeriod = 2;
            break;
          case AuditInterval.ThreeYears:
            agreementPeriod = 3;
            break;
        }
        setIncludeInnerControl(results.includeInnerControl ? results.includeInnerControl : includeInnerControl);
      }

      if (!endDate && !props.viewOnly) {
        let tempEndDate = getCurrentDay(1);
        tempEndDate.setFullYear(tempEndDate?.getFullYear() + agreementPeriod);
        setEndDate(tempEndDate);
        setCheckedEndDate(false);
      }
    };
    if (!invoiceInterval && !auditInterval && !includeInnerControl && !endDate) getValues();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditInterval, includeInnerControl, invoiceInterval, endDate, getPreselectedAgreementTerms]);

  useEffect(() => {
    async function loadFacility() {
      var response = await getFacility(selectedFacilityId);
      setCoreSystemOptions(response.coreSystems.map((x) => ({ label: x.description, value: x.id.toString() })));
      if (response.coreSystems.length === 1) {
        setSelectedCoreSystemId(response.coreSystems[0].id.toString());
      }
    }
    if (selectedFacilityId) {
      loadFacility();
    }
  }, [selectedFacilityId, getFacility]);

  useEffect(() => {
    async function loadContactList() {
      var response = await getContactList();
      setContactOptions([
        { value: '0', label: 'Ny kontakt' },
        ...response.map((x) => ({ value: x.id.toString(), label: x.firstname + ' ' + x.lastname + ' ('+ x.position +')' })),
      ]);
    }
    loadContactList();
  }, [getContactList]);

  useEffect(() => {
    // If selectedContactId is defined and not new contact option (Id: 0)
    if (selectedContactId && selectedContactId !== '0') {
      const selectedContact = contacts?.find((x) => x.id.toString() === selectedContactId);
      setContactFirstName(selectedContact?.firstname ?? '');
      setContactLastName(selectedContact?.lastname ?? '');
      setContactPosition(selectedContact?.position ?? '');
      setContactPhoneNumber(selectedContact?.phoneNumber ?? '');
      setContactEmail(selectedContact?.email ?? '');
    } else {
      setContactFirstName('');
      setContactLastName('');
      setContactPosition('');
      setContactPhoneNumber('');
      setContactEmail('');
    }
  }, [
    contacts,
    selectedContactId,
    setContactFirstName,
    setContactLastName,
    setContactPosition,
    setContactPhoneNumber,
    setContactEmail,
  ]);

  const handleUpsertAgreement = useCallback(
    (createAndApprove: boolean, isSaleAgreement: boolean) => {
      const upsertAgreement = async () => {
        if (isValid) {
          const response = await upsert(
            {
              facilityId: selectedFacilityId ? Number(selectedFacilityId) : undefined,
              coreSystemId: selectedCoreSystemId ? Number(selectedCoreSystemId) : undefined,
              customerContactId: selectedContactId && selectedContactId !== '0' ? Number(selectedContactId) : undefined,
              externalAgreementInvoiceNumber: externalAgreementInvoiceNumber ?? '',
              customerContactFirstName: contactFirstName,
              customerContactLastName: contactLastName,
              customerContactPosition: contactPosition,
              customerContactPhoneNumber: contactPhoneNumber,
              customerContactEmail: contactEmail,
              monthlyCost: monthlyCost as number,
              startDate: startDate!,
              endDate: endDate,
              emergencyCallCenterId: Number(selectedEmergencyCallCenterId),
              securityCompanyId: Number(selectedSecurityCompanyId),
              measure: measure,
              service: service,
              auditInterval: auditInterval,
              invoiceInterval: invoiceInterval,
              rentType: rentType,
              transferType: transferType,
              transferInterval: transferInterval,
              includeInnerControl: includeInnerControl,
              createAndApprove: createAndApprove,
              isSaleAgreement: isSaleAgreement,
            },
            agreementId,
          );

          if (isResultError(response)) {
            setErrorMessage({ message: 'Ett eller flera valideringsfel har inträffat.' });
            setError(response);
          } else {
            const url =
              agreementId || !isSaleAgreement
                ? `/customers/${customerId}${facilityId ? '/facility/' + facilityId : ''}/agreements/${response}`
                : createAndApprove
                ? `/errands/orders/${response}`
                : `/errands/offers/${response}/draft`;

            if (!isSaleAgreement) {
              var agreementPdf = await getAgreementPdf(response.toString());
              if (agreementPdf) openFile(agreementPdf);
            }
            history.push(url);
          }
        }
      };
      return upsertAgreement;
    },
    [
      isValid,
      selectedFacilityId,
      selectedCoreSystemId,
      externalAgreementInvoiceNumber,
      selectedContactId,
      contactFirstName,
      contactLastName,
      contactPosition,
      contactPhoneNumber,
      contactEmail,
      monthlyCost,
      startDate,
      endDate,
      selectedEmergencyCallCenterId,
      selectedSecurityCompanyId,
      measure,
      service,
      auditInterval,
      invoiceInterval,
      rentType,
      transferType,
      transferInterval,
      includeInnerControl,
      agreementId,
      customerId,
      history,
      facilityId,
      upsert,
      setErrorMessage,
      setError,
      getAgreementPdf,
    ],
  );

  const Action = () => (
    <>
      <Button color="default" variant="outlined" onClick={() => history.push(`/customers/${customerId}`)}>
        Avbryt
      </Button>
      {agreementId ? (
        <>
          <Button
            disabled={!isValid}
            loading={isUpsertLoading}
            color="primary"
            variant="contained"
            onClick={handleUpsertAgreement(false, false)}
          >
            {'Spara'}
          </Button>
        </>
      ) : (
        <>
          <MultiButton
            disabled={!isValid}
            color="primary"
            variant="contained"
            menuItems={
              <>
                <Button
                  loading={isUpsertLoading}
                  color="primary"
                  variant="outlined"
                  onClick={handleUpsertAgreement(false, true)}
                >
                  {'Skapa'}
                </Button>
                {!agreementId && (
                  <Button
                    loading={isUpsertLoading}
                    color="primary"
                    variant="outlined"
                    onClick={handleUpsertAgreement(true, true)}
                  >
                    {'Skapa och godkänn'}
                  </Button>
                )}
                {!agreementId && (
                  <Button
                    loading={isUpsertLoading}
                    color="primary"
                    variant="outlined"
                    onClick={handleUpsertAgreement(true, false)}
                  >
                    {'Skapa utan order'}
                  </Button>
                )}
              </>
            }
          >
            Hantera
          </MultiButton>
        </>
      )}
    </>
  );

  return (
    <>
      {smDown && <CustomerInfoDisplay customerId={customerId} />}
      {smDown && selectedFacilityId && <FacilityInfoDisplay facilityId={selectedFacilityId} customerId={customerId} />}
      <Container
        form
        ref={formRef}
        loading={isAgreementLoading || (props.viewOnly && isFacilityLoading)}
        label={
          props.viewOnly
            ? `Avtal ${agreementNumber ?? ''}`
            : agreementId
            ? `Redigera avtal ${agreementNumber ?? ''}`
            : 'Nytt avtal'
        }
        actions={!props.viewOnly && <Action />}
      >
        {!facilityId && props.viewOnly && customer ? (
          <InputField label="Kund" type="text" viewOnly disabled value={customer.name} />
        ) : (
          <>
            <FacilitySelect
              label={props.viewOnly ? 'Plats' : 'Välj plats'}
              isDisabled={!!agreementId}
              isClearable
              placeholder={agreementId ? 'Ingen plats' : 'Välj eller lämna tomt'}
              customerId={customerId}
              onChange={setSelectedFacilityId}
              selectedFacilityId={selectedFacilityId}
            />
            <Select
              viewOnlyValue={coreSystemOptions.find((x) => x.value === selectedCoreSystemId)?.value}
              inputFieldProps={{ label: props.viewOnly ? 'System' : 'Välj system', viewOnly: props.viewOnly }}
              selectProps={{
                placeholder: agreementId ? 'Inget system' : 'Välj eller lämna tomt',
                value: coreSystemOptions.find((x) => x.value === selectedCoreSystemId) || null,
                isDisabled: !!(coreSystemOptions.length === 0) || !!agreementId || isFacilityLoading,
                onChange: (selected) => {
                  setSelectedCoreSystemId(selected ? (selected as ISelectOption).value : undefined);
                },
                isClearable: true,
                options: coreSystemOptions,
                isLoading: isFacilityLoading,
              }}
            />
          </>
        )}

        <Fieldset legend={'Kontaktperson'}>
          <Select
            viewOnlyValue={contactOptions?.find((x) => x.value === selectedContactId)?.value}
            inputFieldProps={{ label: props.viewOnly ? 'Kontakt' : 'Välj kontakt', viewOnly: props.viewOnly }}
            selectProps={{
              value: contactOptions?.find((x) => x.value === selectedContactId) || null,
              onChange: (selected) => {
                setSelectedContactId(selected ? (selected as ISelectOption).value : undefined);
              },
              options: contactOptions,
              isLoading: isContactsLoading,
            }}
          />
          <InputField
            label="Förnamn"
            type="text"
            viewOnly={props.viewOnly}
            required
            value={contactFirstName}
            onChange={changeContactFirstName}
            errorText={error?.customerContactFirstName}
          />
          <InputField
            label="Efternamn"
            type="text"
            viewOnly={props.viewOnly}
            required
            value={contactLastName}
            onChange={changeContactLastName}
            errorText={error?.customerContactLastName}
          />
          <InputField
            label="Befattning/Avdelning"
            type="text"
            viewOnly={props.viewOnly}
            value={contactPosition}
            required
            onChange={changeContactPosition}
            errorText={error?.customerContactPosition}
          />
          <PhoneNumberField
            label="Telefon"
            viewOnly={props.viewOnly}
            required
            value={contactPhoneNumber}
            onChange={changeContactPhoneNumber}
            errorText={error?.customerContactPhoneNumber}
          />
          <EmailField
            label="E-post"
            type="text"
            viewOnly={props.viewOnly}
            required
            value={contactEmail}
            onChange={changeContactEmail}
            errorText={error?.customerContactEmail}
          />
        </Fieldset>
        <Fieldset>
          <InputField
            label="Månadsavgift"
            type="text"
            viewOnly={props.viewOnly}
            endAdornment={<InputAdornment position="end">SEK</InputAdornment>}
            required
            value={monthlyCost}
            onChange={changeMonthlyCost}
            errorText={error?.monthlyCost}
          />
          <InputField
            label="Externt avtalsfakturanummer"
            type="text"
            viewOnly={props.viewOnly}
            value={externalAgreementInvoiceNumber}
            onChange={updateExternalAgreementInvoiceNumber}
            errorText={error?.externalAgreementInvoiceNumber}
          />
        </Fieldset>
        <Fieldset legend="DATUM">
          <Datepicker
            label="Från"
            viewOnly={props.viewOnly}
            onChange={(x: Date | null) => {
              if (x) setStartDate(x);
            }}
            selected={startDate}
          />
          <Datepicker
            label="Till"
            minDate={startDate as Date}
            inFilter={true}
            viewOnly={props.viewOnly}
            onChange={(x: Date | null) => {
              if (x) setEndDate(x);
            }}
            selected={endDate}
            disabled={moment().diff(endDate, 'days') / -365 === 3}
            radioBoxProps={{
              radioBoxLabel: 'Test',
              radioBoxCheckedValue: moment().diff(endDate, 'days') / -365,
              alternatives: [
                {
                  radioBoxAlternativeLabel: '1 år',
                  radioBoxAlternativeValue: 1,
                },
                {
                  radioBoxAlternativeLabel: '2 år',
                  radioBoxAlternativeValue: 2,
                },
                {
                  radioBoxAlternativeLabel: 'Tills vidare (3 år)',
                  radioBoxAlternativeValue: 3,
                },
              ],
              onRadioBoxChange: (value) => {
                if (value === 3 && auditInterval) {
                  setEndDate(getEndDateFromYears(auditInterval ?? 3));
                } else {
                  setEndDate(getEndDateFromYears(value));
                }
              },
            }}
          />
        </Fieldset>
        <Fieldset legend="Larm">
          <SupplierSelect
            viewOnly={props.viewOnly}
            isClearable
            isDisabled={props.viewOnly}
            label={props.viewOnly ? 'Larmcentral' : 'Välj larmcentral'}
            onChange={setSelectedEmergencyCallCenterId}
            selectedSupplierId={selectedEmergencyCallCenterId}
            supplierType={SupplierType.EmergencyCallCenter}
          />
          <SupplierSelect
            viewOnly={props.viewOnly}
            isDisabled={props.viewOnly}
            isClearable
            label={props.viewOnly ? 'Vaktbolag' : 'Välj vaktbolag'}
            onChange={setSelectedSecurityCompanyId}
            selectedSupplierId={selectedSecurityCompanyId}
            supplierType={SupplierType.SecurityCompany}
          />
          <Select
            viewOnlyValue={getSelectedOption(measureOptions, measure)?.label}
            inputFieldProps={{
              disabled: props.viewOnly,
              label: 'Åtgärd',
              viewOnly: props.viewOnly,
              errorText: error?.measure,
            }}
            selectProps={{
              isClearable: true,
              options: measureOptions,
              value: getSelectedOption(measureOptions, measure),
              onChange: (option) => {
                setMeasure(getSelectedValue(option));
              },
            }}
          />
          <Select
            viewOnlyValue={getSelectedOption(serviceOptions, service)?.label}
            inputFieldProps={{
              disabled: props.viewOnly,
              label: 'Service',
              viewOnly: props.viewOnly,
              errorText: error?.service,
            }}
            selectProps={{
              isClearable: true,
              options: serviceOptions,
              value: getSelectedOption(serviceOptions, service),
              onChange: (option) => {
                setService(getSelectedValue(option));
              },
            }}
          />
          <Select
            viewOnlyValue={getSelectedOption(auditOptions, auditInterval)?.label}
            inputFieldProps={{
              disabled: props.viewOnly,
              label: 'Revision',
              viewOnly: props.viewOnly,
              errorText: error?.auditInterval,
            }}
            selectProps={{
              isClearable: true,
              options: auditOptions,
              value: getSelectedOption(auditOptions, auditInterval),
              onChange: (option) => {
                setAuditInterval(getSelectedValue(option));
              },
            }}
          />
          <Select
            viewOnlyValue={getSelectedOption(invoiceOptions, invoiceInterval)?.label}
            inputFieldProps={{
              disabled: props.viewOnly,
              label: 'Fakturaintervall',
              viewOnly: props.viewOnly,
              errorText: error?.invoiceInterval,
            }}
            selectProps={{
              isClearable: true,
              options: invoiceOptions,
              value: getSelectedOption(invoiceOptions, invoiceInterval),
              onChange: (option) => {
                setInvoiceInterval(getSelectedValue(option));
              },
            }}
          />
          <Select
            viewOnlyValue={getSelectedOption(rentOptions, rentType)?.label}
            inputFieldProps={{
              disabled: props.viewOnly,
              label: 'Hyra',
              viewOnly: props.viewOnly,
              errorText: error?.rentType,
            }}
            selectProps={{
              isClearable: true,
              options: rentOptions,
              value: getSelectedOption(rentOptions, rentType),
              onChange: (option) => {
                setRentType(getSelectedValue(option));
              },
            }}
          />

          <Select
            viewOnlyValue={getSelectedOption(transferOptions, transferType)?.label}
            inputFieldProps={{
              disabled: props.viewOnly,
              label: 'Överföring',
              viewOnly: props.viewOnly,
              errorText: error?.transferType,
            }}
            selectProps={{
              isClearable: true,
              options: transferOptions,
              value: getSelectedOption(transferOptions, transferType),
              onChange: (option) => {
                setTransferType(getSelectedValue(option));
              },
            }}
          />
          <Select
            viewOnlyValue={getSelectedOption(transferIntervalOptions, transferInterval)?.label}
            inputFieldProps={{
              disabled: props.viewOnly,
              label: 'Överföringsperiod',
              viewOnly: props.viewOnly,
              errorText: error?.transferInterval,
            }}
            selectProps={{
              isClearable: true,
              options: transferIntervalOptions,
              value: getSelectedOption(transferIntervalOptions, transferInterval),
              onChange: (option) => {
                setTransferInterval(getSelectedValue(option));
              },
            }}
          />

          <CheckboxField label="Nyckelförvaring">
            <FormControlLabel
              disabled={props.viewOnly}
              control={<Checkbox name="checkedB" color="primary" />}
              label="Inklusive inre kontroll"
              checked={includeInnerControl}
              onChange={(_, checked) => setIncludeInnerControl(checked)}
            />
          </CheckboxField>
        </Fieldset>
      </Container>
      <Container>
        {!smDown && <CustomerInfoDisplay customerId={customerId} />}
        <br />
        {!smDown && selectedFacilityId && (
          <FacilityInfoDisplay facilityId={selectedFacilityId} customerId={customerId} />
        )}
      </Container>
    </>
  );
};

export default AgreementForm;
