import React from 'react';
import { useStyles } from '../DataList.styles';

const Dt: React.FC = (props) => {
  const classes = useStyles();

  return <dt className={`${classes.item} ${classes.dt}`}>{props.children}</dt>;
};

export default Dt;
