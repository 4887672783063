import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  ICoreSystemEventAction,
  useGetCoreSystem,
  useUpdateCoreSystemEventActions,
  useUpdateCoreSystemUsers,
} from '../CoreSystems.api';
import EventActionList from '../../../../../../../Shared/EventActionList/EventActionList';
import { Grid } from '@material-ui/core';
import SystemUserList, { ICoreSystemUser } from '../../../../../../../Shared/SystemUserList/SystemUserList';

const ViewCoreSystem = () => {
  const { customerId, facilityId, coreSystemId } = useParams<{
    facilityId: string;
    customerId: string;
    coreSystemId: string;
  }>();
  const { getCoreSystem, isLoading, coreSystem } = useGetCoreSystem(customerId, facilityId);
  const { isUpdatingEventActionsLoading, updateEventActions } = useUpdateCoreSystemEventActions(
    customerId,
    facilityId,
    coreSystemId,
  );
  const { isUpdatingSystemUsersLoading, updateSystemUsers } = useUpdateCoreSystemUsers(
    customerId,
    facilityId,
    coreSystemId,
  );

  const loadCoreSystem = useCallback(() => {
    if (coreSystemId) {
      getCoreSystem(coreSystemId);
    }
  }, [getCoreSystem, coreSystemId]);

  useEffect(() => {
    loadCoreSystem();
  }, [loadCoreSystem]);

  async function saveEventActions(eventActions: ICoreSystemEventAction[]) {
    await updateEventActions(eventActions);
    loadCoreSystem();
  }

  async function saveSystemUsers(users: ICoreSystemUser[]) {
    await updateSystemUsers(users);
    loadCoreSystem();
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={6}>
        <EventActionList
          label="Åtgärdslista"
          save={saveEventActions}
          eventActions={coreSystem?.eventActions ?? []}
          isLoading={isLoading}
          isUpdateLoading={isUpdatingEventActionsLoading}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <SystemUserList
          label="Användarlista"
          users={coreSystem?.users ?? []}
          isLoading={isLoading}
          isUpdateLoading={isUpdatingSystemUsersLoading}
          save={saveSystemUsers}
          disableEdit={coreSystem?.onPendingConfirm}
        />
      </Grid>
    </Grid>
  );
};

export default ViewCoreSystem;
