import IPaginatedResponse from '../../../../Shared/Api/response/IPaginatedResponse';
import IPaginatedRequest from '../../../../Shared/Api/request/IPaginatedRequest';
import { ICreditCheckDto } from '../../Customers/Customers.api';
import { IFileResult } from '../../../../Shared/Api/response/IFileResult';
import { ISelectOption } from '../../../../Shared/Select/Select';
import { ISupplierListItem } from './SupplierOrder/SupplierOrder.api';
import { SaleType } from '../../../Shared/SaleType';
import { useCallback } from 'react';
import { useApiPost, useApiGet, useApiDelete } from '../../../../Core/Api/Api';
import { SystemLogType } from '../../Admin/Components/SystemLog/SystemLog.api';
import { Role } from '../../Admin/Components/Users/Users.api';

/** Enums and constants**/
export enum WorkflowStatus {
  Offer,
  Order,
  Invoicing,
  Archive,
}

export enum SaleStatus {
  draft = 0,
  new = 1,
  pending_approval,
  archived,
  approved,
  installationBooked,
  installed,
  invoicing,
  completed,
  canceled,
}

export const SaleStatusLabel = {
  [SaleStatus.draft]: 'Utkast',
  [SaleStatus.new]: 'Skapad',
  [SaleStatus.pending_approval]: 'Skickad till kund',
  [SaleStatus.archived]: 'Arkiverad',
  [SaleStatus.approved]: 'Godkänd',
  [SaleStatus.installationBooked]: 'Installation bokad',
  [SaleStatus.installed]: 'Installation slutförd',
  [SaleStatus.invoicing]: 'Skickad till fakturering',
  [SaleStatus.completed]: 'Slutförd',
  [SaleStatus.canceled]: 'Avbruten',
};

export interface ISaleStatus {
  name: string;
  status: SaleStatus;
  stringValue: string;
  workflowStatus: WorkflowStatus;
}

export const statusList: ISaleStatus[] = [
  {
    status: SaleStatus.new,
    name: SaleStatusLabel[SaleStatus.new],
    stringValue: (SaleStatus.new as Number).toString(),
    workflowStatus: WorkflowStatus.Offer,
  },
  {
    status: SaleStatus.pending_approval,
    name: SaleStatusLabel[SaleStatus.pending_approval],
    stringValue: (SaleStatus.pending_approval as Number).toString(),
    workflowStatus: WorkflowStatus.Offer,
  },
  {
    status: SaleStatus.draft,
    name: SaleStatusLabel[SaleStatus.draft],
    stringValue: (SaleStatus.draft as Number).toString(),
    workflowStatus: WorkflowStatus.Offer,
  },
  {
    status: SaleStatus.approved,
    name: SaleStatusLabel[SaleStatus.approved],
    stringValue: (SaleStatus.approved as Number).toString(),
    workflowStatus: WorkflowStatus.Order,
  },
  {
    status: SaleStatus.installationBooked,
    name: SaleStatusLabel[SaleStatus.installationBooked],
    stringValue: (SaleStatus.installationBooked as Number).toString(),
    workflowStatus: WorkflowStatus.Order,
  },
  {
    status: SaleStatus.installed,
    name: SaleStatusLabel[SaleStatus.installed],
    stringValue: (SaleStatus.installed as Number).toString(),
    workflowStatus: WorkflowStatus.Order,
  },
  {
    status: SaleStatus.invoicing,
    name: SaleStatusLabel[SaleStatus.invoicing],
    stringValue: (SaleStatus.invoicing as Number).toString(),
    workflowStatus: WorkflowStatus.Invoicing,
  },
  {
    status: SaleStatus.completed,
    name: SaleStatusLabel[SaleStatus.completed],
    stringValue: (SaleStatus.completed as Number).toString(),
    workflowStatus: WorkflowStatus.Archive,
  },
  {
    status: SaleStatus.canceled,
    name: SaleStatusLabel[SaleStatus.canceled],
    stringValue: (SaleStatus.canceled as Number).toString(),
    workflowStatus: WorkflowStatus.Archive,
  },
];

export enum WarningStatus {
  offer = 1,
  order = 2,
  errorReport = 3,
  supplierOrder = 4,
}

export const WarningLabel = {
  [WarningStatus.offer]: 'offert',
  [WarningStatus.order]: 'order',
  [WarningStatus.errorReport]: 'felrapport',
  [WarningStatus.supplierOrder]: 'beställning',
};

export enum SaleProductStatus {
  added,
  orderPlaced,
  orderConfirmed,
  delivered,
  installed,
}

export const saleProductStatusLabel = {
  [SaleProductStatus.added]: 'Ingen beställning',
  [SaleProductStatus.orderPlaced]: 'Beställning lagd',
  [SaleProductStatus.orderConfirmed]: 'Beställning bekräftad',
  [SaleProductStatus.delivered]: 'Beställning mottagen',
  [SaleProductStatus.installed]: 'Produkt installerad',
};

export interface IAgreementOption<T> {
  type: T;
  name: string;
}

export enum AuditInterval {
  OneYear = 1,
  TwoYears = 2,
  ThreeYears = 3,
}

export const auditIntervals: IAgreementOption<AuditInterval>[] = [
  { type: AuditInterval.OneYear, name: '1 år' },
  { type: AuditInterval.TwoYears, name: '2 år' },
  { type: AuditInterval.ThreeYears, name: '3 år' },
];

export enum Measure {
  FreeAlarmExpressions = 1,
  ContactList = 2,
  EmergencyPreparedness = 3,
}

export const measures: IAgreementOption<Measure>[] = [
  { type: Measure.FreeAlarmExpressions, name: 'Fri larmutryckningar' },
  { type: Measure.ContactList, name: 'Kontaktlista' },
  { type: Measure.EmergencyPreparedness, name: 'Utryckningsberedskap' },
];

export enum Service {
  BasicService = 1,
  FreeService = 2,
}

export const services: IAgreementOption<Service>[] = [
  { type: Service.BasicService, name: 'Grundservice' },
  { type: Service.FreeService, name: 'Fri service' },
];

export enum RentType {
  RentOfSystem = 1,
  RentOfMobileSystem = 2,
}

export const rentTypes: IAgreementOption<RentType>[] = [
  { type: RentType.RentOfSystem, name: 'Hyra av system' },
  { type: RentType.RentOfMobileSystem, name: 'Hyra av mobilsystem' },
];

export enum TransferType {
  Dualtech = 1,
  CallUp = 2,
  Frontel = 3,
  Safetel = 4,
  Multicom = 5,
  TCP_IP = 6,
  GPRS = 7,
  GSM = 8,
}

export const transferTypes: IAgreementOption<TransferType>[] = [
  { type: TransferType.Dualtech, name: 'Dualtech' },
  { type: TransferType.CallUp, name: 'Uppringning' },
  { type: TransferType.Frontel, name: 'Frontel' },
  { type: TransferType.Safetel, name: 'Safetel' },
  { type: TransferType.Multicom, name: 'Multicom' },
  { type: TransferType.TCP_IP, name: 'TCP/IP' },
  { type: TransferType.GPRS, name: 'GPRS' },
  { type: TransferType.GSM, name: 'GSM' },
];

export enum TransferInterval {
  Minutes_3 = 1,
  Minutes_10 = 2,
  Hours_24 = 3,
}

export const transfersIntervals: IAgreementOption<TransferInterval>[] = [
  { type: TransferInterval.Minutes_3, name: '3 min' },
  { type: TransferInterval.Minutes_10, name: '10 min' },
  { type: TransferInterval.Hours_24, name: '24 timmar' },
];

export enum InvoiceInterval {
  OneMonth = 1,
  ThreeMonths = 2,
  SixMonths = 3,
  OneYear = 4,
}

export const invoiceIntervals: IAgreementOption<InvoiceInterval>[] = [
  { type: InvoiceInterval.OneMonth, name: '1 mån' },
  { type: InvoiceInterval.ThreeMonths, name: '3 mån' },
  { type: InvoiceInterval.SixMonths, name: '6 mån' },
  { type: InvoiceInterval.OneYear, name: '12 mån' },
];

export function mapAgreementOptions(options: IAgreementOption<any>[]): ISelectOption[] {
  return options.map((x) => ({ label: x.name, value: (x.type as number).toString() }));
}

export enum TimeReportType {
  Service = 1,
  Installation = 2,
  Completion = 3,
  Revision = 4,
  OngoingInstallation = 5,
  Documentation = 6,
  Warranty = 7,
  TravelTime = 8,
}

export interface ITimeReportType {
  type: TimeReportType;
  name: string;
}

export const timeReportTypes: ITimeReportType[] = [
  { type: TimeReportType.Service, name: 'Service' },
  { type: TimeReportType.Installation, name: 'Installation' },
  { type: TimeReportType.Completion, name: 'Komplettering' },
  { type: TimeReportType.Revision, name: 'Revision' },
  { type: TimeReportType.OngoingInstallation, name: 'Löpande installation' },
  { type: TimeReportType.Documentation, name: 'Dokumentation' },
  { type: TimeReportType.Warranty, name: 'Garanti' },
  { type: TimeReportType.TravelTime, name: 'Restid' },
];

export enum SaleChecklistEntryType {
  CentralEquipment = 1,
  PowerSupply = 2,
  Actuators = 3,
  Detectors = 4,
  Sounders = 5,
  Transfer = 6,
  TFConnectionControl = 7,
  SaleProduct = 15
}

/** Call methods **/
export interface ISaleListItem {
  id: number;
  saleIdentifier: string;
  customerName: string;
  customerId: number;
  facilityId?: number;
  projectLeader: string;
  mainInstaller?: string;
  booked?: string;
  type: SaleType;
  status: SaleStatus;
  totalPrice: number;
  description: string;
  jobDescription?: string;
  materialDescription?: string;
  createdDate: Date;
  sentDate?: Date;
  approvedDate?: Date;
  approvedBy?: string;
  bookingDate?: Date;
  markedForInvoiceDate?: Date;
  coreSystemType?: string;
}

export interface ISaleListRequest extends IPaginatedRequest {
  status?: SaleStatus[];
  projectLeaderId?: number;
  salesPersonId?: number;
  createdFrom?: Date | null;
  createdTo?: Date | null;
  workflowStatus?: WorkflowStatus;
  customerId?: number;
  type?: SaleType;
}

export function useGetSales() {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<ISaleListItem>, ISaleListRequest>(
    '/api/sales/list',
  );

  return {
    isLoading,
    sales: response,
    getSales: post,
  };
}

export interface ISaleListFromQueryRequest {
  saleIds: number[];
}

export function useGetSalesFromQuery() {
  const { isLoading, response, post } = useApiPost<ISaleListItem[], ISaleListFromQueryRequest>(
    '/api/sales/listsalesfromsearch',
  );

  return {
    isLoading,
    sales: response,
    getSalesFromSearch: post,
  };
}

export interface IExistingSaleCustomer {
  id: number;
  name: string;
  type: number;
  identificationNumber: string;
  email: string;
  phoneNumber: string;
  customerNumber: string;
  invoiceAddress: string;
  invoicePostalCode: string;
  invoiceCity: string;
  invoiceReference: string;
  invoiceAdditionalMessage: string;
  creditCheck: ICreditCheckDto;
}
export interface IFacility {
  id: number;
  facilityNumber: number;
  name: string;
  address: string;
  postalCode: string;
  city: string;
  internalNote?: string;
}

export interface ICoreSystemDto {
  id: number;
  description: string;
  systemTypeName: string;
  regulationName: string;
  mainProductId: number;
  transmitter: string;
}

export interface ISaleProductDto {
  id: number;
  productId: number;
  isMainProduct: boolean;
  name: string;
  description: string;
  regulation: string;
  certifiedTo: string;
  price: number;
  installationTime: number;
  facilityPlacement: string;
  additionOnOrder: boolean;
  deletedOnOrder: boolean;
  comment?: string;
  status: SaleProductStatus;
  supplierOrder?: ISupplierListItem;
}
export interface ISaleCostDto {
  id: number;
  date: Date;
  costIn: number;
  costOut: number;
  description: string;
  supplierId: number;
  supplierName: string;
}

export interface ISaleUser {
  id: number;
  name: string;
  phoneNumber?: string;
  roles: Array<{
    companyUserId: number;
    role: Role;
  }>;
}

export interface ISaleAgreement {
  agreementNumber: number;
  externalAgreementInvoiceNumber: string;
  nextAgreementNumber: number;
  referenceNumber: string;
  monthlyCost: number;
  startDate: Date;
  endDate: Date | null;
  emergencyCallCenterSupplierId: number;
  emergencyCallCenterSupplierName: string;
  securityCompanySupplierId: number;
  securityCompanySupplierName: string;
  measure?: Measure;
  service?: Service;
  auditInterval?: AuditInterval;
  invoiceInterval?: InvoiceInterval;
  transferType?: TransferType;
  transferInterval?: TransferInterval;
  rentType?: RentType;
  includeInnerControl?: boolean;
}

export interface ISale {
  id: number;
  saleIdentifier: string;
  status: SaleStatus;
  canBeApprovedByCustomer: boolean;
  type: SaleType;
  description: string;
  saleDescription: string;
  jobDescription: string;
  materialDescription: string;
  extraMaterial: string;
  workDescription: string;
  externalInvoiceNumber: string;
  invoiceMessage: string;
  projectLeader: ISaleUser;
  salesPerson: ISaleUser;
  projector: ISaleUser;
  approvedBy: string | null;
  approveDate: Date | null;

  customer: IExistingSaleCustomer;
  contactPerson?: {
    id: number;
    name: string;
    email: string;
    phoneNumber: string;
    position: string;
  };

  facility: IFacility;
  coreSystem: ICoreSystemDto | undefined;
  systemTypeName: string;
  systemTypeRegulationName: string;
  saleProducts: ISaleProductDto[];

  prices?: {
    materialCost?: number;
    installationTime?: number;
    installationRate?: number;
    installationCost?: number;
    otherCost?: number;
    totalPrice?: number;
    firstInstallationCost?: number;
    rentPrice?: number;
    invoicedTotalPrice?: number;
  };
  pricesDisplay?: {
    materialCost?: boolean;
    installationTime?: boolean;
    installationRate?: boolean;
    installationCost?: boolean;
    otherCost?: boolean;
    totalPrice?: boolean;
    firstInstallationCost?: boolean;
    rentPrice?: boolean;
    material?: boolean;
    firstpage?: boolean;
    agreement?: boolean;
    deliveryAndWarrantyConditions?: boolean;
  };

  saleAgreement?: ISaleAgreement;
  terms?: ISaleTerms;
  mainInstaller?: ISaleUser;
  installDate?: Date;
  documentId?: number;
  userAccess?: UserAccessType;
}

export interface UserAccessType {
  projectLeader?: boolean;
  salesPerson?: boolean;
  installer?: boolean;
  admin?: boolean;
}

export interface ISaleTerms {
  including: string | undefined;
  excluding: string | undefined;
  regulation: string | undefined;
  warranty: string | undefined;
  delivery: string | undefined;
  validPeriod: string | undefined;
}

export function useGetSale() {
  const { isLoading, response, get } = useApiGet<ISale>('/api/sales/', false);

  return {
    isLoading,
    sale: response,
    getSale: get,
  };
}

export interface ISaleTimeReport {
  id: number;
  date: Date;
  hours: number;
  parking: number;
  trips: number;
  type: TimeReportType;
  companyUser: ISaleUser;
}

export interface IDateDetails {
  startDate: Date;
  endDate: Date;
}

export interface IBookedSaleUser extends ISaleUser {
  dateDetails: IDateDetails[];
}

export interface ISaleChecklistEntryDto {
  type: SaleChecklistEntryType;
  installed: boolean;
  tested: boolean;
  done: boolean;
  saleProductId: number | undefined;
  comment: string | undefined;
}

export interface ISaleMisc {
  status: SaleStatus;
  saleProducts: ISaleProductDto[];
  timeReports: ISaleTimeReport[];
  bookedUsers: IBookedSaleUser[];
  checklistEntries: ISaleChecklistEntryDto[];
  saleCosts: ISaleCostDto[];
}

export function useGetSaleMisc() {
  const { isLoading, response, get } = useApiGet<ISaleMisc>('/api/sales/', false);

  const getSaleMisc = useCallback(
    (saleId: string) => {
      return get(`${saleId}/misc`);
    },
    [get],
  );

  return {
    isLoading,
    saleMisc: response,
    getSaleMisc,
  };
}

export function useGetSaleCost() {
  const { isLoading, response, get } = useApiGet<ISaleCostDto>('/api/sales/', false);

  const getSaleCost = useCallback(
    (saleId: string, saleCostId: string) => {
      return get(`${saleId}/costs/${saleCostId}`);
    },
    [get],
  );

  return {
    isLoading,
    saleCost: response,
    getSaleCost,
  };
}

export function useDeleteSaleProduct() {
  const { isLoading, delete: deleteRequest } = useApiDelete('/api/sales/');

  const deleteSaleProduct = useCallback(
    async (saleId: string, saleProductId: number) => {
      return await deleteRequest(undefined, `${saleId}/products/${saleProductId}`);
    },
    [deleteRequest],
  );

  return {
    isLoading,
    deleteSaleProduct,
  };
}
export function useDeleteTimeReport() {
  const { isLoading, delete: deleteRequest } = useApiDelete('/api/sales/');

  const deleteTimeReport = useCallback(
    async (saleId: string, timeReportId: number) => {
      return await deleteRequest(undefined, `${saleId}/timereports/${timeReportId}`);
    },
    [deleteRequest],
  );

  return {
    isLoading,
    deleteTimeReport,
  };
}
export interface ISaleDeleteInfo {
  salesNumber: number;
  status: SaleStatus;
  supplierOrderCount: number;
  errorReportCount: number;
}

export function useGetSaleDeleteInfo() {
  const { isLoading, response, get } = useApiGet<ISaleDeleteInfo>('/api/sales/', false);

  const getSaleDeleteInfo = (saleId: string) => {
    return get(`${saleId}/deleteinfo`);
  };

  return {
    isLoading,
    saleDeleteInfo: response,
    getSaleDeleteInfo: getSaleDeleteInfo,
  };
}

export interface ISaleStatsDto {
  user: string;
  saleAmount: number;
}

export function useGetTopSales() {
  const { isLoading, response, get } = useApiGet<ISaleStatsDto[]>('/api/sales/topsales', false);

  return {
    isLoading,
    topSales: response,
    getTopSales: get,
  };
}

export function useCancelSale() {
  const { isLoading, post } = useApiPost(`/api/sales/`);

  const cancelSale = (saleId: string) => {
    return post(undefined, `${saleId}/cancel`);
  };

  return {
    isLoading,
    cancelSale,
  };
}

export interface ICustomLogOnSale {
  customLogContent: string;
  logType?: SystemLogType;
}

export function useAddCustomLog() {
  const { isLoading, post } = useApiPost<ICustomLogOnSale>(`/api/logs/`);

  const addCustomLog = useCallback(
    (command: ICustomLogOnSale, entityId: string) => {
      return post(command, `${entityId}/log`);
    },
    [post],
  );

  return {
    isLoading,
    addCustomLog,
  };
}

export function useGetPreviewSalePdf(saleId: string) {
  const { isLoading, response, get } = useApiGet<IFileResult>(`/api/sales/${saleId}/preview`, false);

  return {
    isLoading,
    offerPdf: response,
    getPreviewSalePdf: get,
  };
}

export function useGetWorkOrderPdf(saleId: string) {
  const { isLoading, response, get } = useApiGet<IFileResult>(`/api/sales/${saleId}/workorder`, false);

  return {
    isLoading,
    offerPdf: response,
    getWorkOrderPdf: get,
  };
}

export interface ISalePostCalculation {
  materialCost: number;
  saleCost: number;
  installationCost: number;
  profit: number;
  installationHours: number;
  installationHoursContractor: number;
}

export function useGetSalePostCalculation() {
  const { isLoading, response, get } = useApiGet<ISalePostCalculation>('/api/sales/', false);

  const getSalePostCalculation = useCallback(
    (saleId: string) => {
      return get(`${saleId}/postcalculation`);
    },
    [get],
  );

  return {
    isLoading,
    salePostCalculation: response,
    getSalePostCalculation,
  };
}
