import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  reportList: {
    maxWidth: '30rem',
    marginBottom: '2rem',
  },
  week: {
    fontWeight: 600,
  },
  label: {
    marginBottom: '0.5rem',
    fontWeight: 600,
  },
}));

export default useStyles;
