import React, { useEffect } from 'react';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import useHeaderNavigationContext from '../../../Shared/Navigation/Header/HeaderNavigationContext/HeaderNavigationContext';
import { INavigationItem } from '../../../Shared/Navigation/Header/Header';
import AuthRoute from '../../../Core/Authentication/AuthRoute/AuthRoute';
import { ModuleIdentifiers, AccessRights } from '../../../Core/Authentication/ModuleAccess';
import ReportForm from '../Errands/Components/Reports/Components/ReportForm/ReportForm';
import ReportList from '../Errands/Components/Reports/Components/ReportList/ReportList';
import AuditList from '../Audit/Components/AuditList/AuditList';
import AuditForm from '../Audit/Components/AuditForm/AuditForm';
import TodoList from './Components/TodoList/TodoList';
import TodoForm from './Components/TodoForm/TodoForm';
import useUserContext from '../../../Core/Authentication/UserContext';
import ViewOrder from '../Errands/Components/Order/ViewOrder/ViewOrder';
import ProductForm from '../Errands/Components/Order/ViewOrder/ProductForm/ProductForm';
import CreateSupplierOrdersForm from '../Errands/Components/Order/ViewOrder/CreateSupplierOrders/CreateSupplierOrdersForm';
import MeetingNotes from './Components/MeetingNotes/MeetingNotes';
import MeetingNotesForm from './Components/MeetingNotes/MeetingNotesForm/MeetingNotesForm';
import MeetingNotesCommentForm from './Components/MeetingNotes/MeetingNotesComment/MeetingNotesCommentForm';
import MeetingsArchive from './Components/MeetingNotes/MeetingsArchive';
import OrderForm from '../Errands/Components/Order/OrderForm/OrderForm';
import TodoOrderList from '../Errands/Components/Order/TodoOrderList/TodoOrderList';

function TodosRoute() {
  const { path } = useRouteMatch();
  const id = '[\\d]+';
  const { setHeaderNavigationItems, setHeaderNavigationText } = useHeaderNavigationContext();
  const { hasAccess } = useUserContext();

  useEffect(() => {
    const navigationItems: INavigationItem[] = [
      {
        title: 'Uppgifter',
        to: `${path}/list`,
        exact: false,
      },
      {
        title: 'Mitt arkiv',
        to: `${path}/archivelist`,
        exact: false,
      },
      {
        title: 'Möte',
        to: `${path}/meetingnotes`,
        exact: false,
      },
      {
        title: 'Mötesarkiv',
        to: `${path}/meetings/archive`,
        exact: false,
      },
    ];

    if (hasAccess(ModuleIdentifiers.Orders, AccessRights.Read))
      navigationItems.push({
        title: 'Mina ordrar',
        to: `${path}/orders`,
        exact: false,
      });

    if (hasAccess(ModuleIdentifiers.ErrorReport, AccessRights.Read))
      navigationItems.push({
        title: 'Felrapporter',
        to: `${path}/error-reports`,
        exact: false,
      });

    if (hasAccess(ModuleIdentifiers.Customer, AccessRights.Read))
      navigationItems.push({
        title: 'Revisioner',
        to: `${path}/audit/list`,
        exact: false,
      });

    const HeaderNavigation: INavigationItem[] = [
      {
        title: 'Redigera uppgift',
        to: `${path}/list/${id}/update`,
        exact: false,
      },
      {
        title: 'Skapa uppgift',
        to: `${path}/list/create`,
        exact: false,
      },
      {
        title: 'Mitt arkiv',
        to: `${path}/archivelist/${id}`,
        exact: false,
      },
      // {
      //   title: 'Skapa möte',
      //   to: `${path}/meetings/create`,
      //   exact: false,
      // },
      {
        title: 'Skapa beställning',
        to: `${path}/orders/${id}/supplierorders`,
        exact: false,
      },
      {
        title: 'Lägg till produkt',
        to: `${path}/orders/${id}/product/add`,
        exact: false,
      },
      {
        title: 'Redigera felrapport',
        to: `${path}/error-reports/${id}/update`,
        exact: false,
      },
    ];

    setHeaderNavigationText(HeaderNavigation.concat(navigationItems));
    setHeaderNavigationItems(navigationItems);

    return () => {
      setHeaderNavigationItems([]);
    };
  }, [path, setHeaderNavigationItems, setHeaderNavigationText, hasAccess]);

  return (
    <Switch>
      {/* TODOS */}
      <AuthRoute exact path={`${path}/list`} module={ModuleIdentifiers.Todo} accessRights={AccessRights.Read}>
        <h1 className={'sr-only'}>Lista över uppgifter</h1>
        <TodoList />
      </AuthRoute>
      <AuthRoute exact path={`${path}/archivelist`} module={ModuleIdentifiers.Todo} accessRights={AccessRights.Read}>
        <h1 className={'sr-only'}>Lista över arkiverade uppgifter</h1>
        <TodoList inArchive />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/list/create`}
        module={ModuleIdentifiers.Todo}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Uppgifter',
            to: `${path}/list`,
          },
          {
            title: 'Skapa ny',
          },
        ]}
      >
        <h1 className={'sr-only'}>Skapa uppgift</h1>
        <TodoForm />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/list/:id/update`}
        module={ModuleIdentifiers.Todo}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Uppgift',
            to: `${path}/list`,
          },
          {
            title: 'Redigera',
          },
        ]}
      >
        <h1 className={'sr-only'}>Redigera uppgift</h1>
        <TodoForm />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/archivelist/:id`}
        module={ModuleIdentifiers.Todo}
        accessRights={AccessRights.Read}
        breadcrumbs={[
          {
            title: 'Mitt arkiv',
            to: `${path}/archivelist`,
          },
          {
            title: 'Arkiv',
          },
        ]}
      >
        <h1 className={'sr-only'}>Mitt arkiv</h1>
        <TodoForm isArchive />
      </AuthRoute>

      {/* ORDERS */}
      <AuthRoute exact path={`${path}/orders`} module={ModuleIdentifiers.Orders} accessRights={AccessRights.Read}>
        <h1 className={'sr-only'}>Lista över ordrar</h1>
        <TodoOrderList />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/orders/create`}
        module={ModuleIdentifiers.Orders}
        accessRights={AccessRights.Read}
      >
        <h1 className={'sr-only'}>Skapa ordrar</h1>
        <OrderForm location={`${path}/orders`} />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/orders/:id`}
        module={ModuleIdentifiers.Orders}
        accessRights={AccessRights.Read}
        breadcrumbs={[{ title: 'Ordrar', to: `${path}/orders` }, { title: 'Order' }]}
      >
        <h1 className={'sr-only'}>Visa order</h1>
        <ViewOrder />
      </AuthRoute>
      <AuthRoute
        exact
        path={[`${path}/orders/:id/product/add`]}
        module={ModuleIdentifiers.Orders}
        accessRights={AccessRights.Write}
      >
        <h1 className={'sr-only'}>Lägg till produkt</h1>
        <ProductForm />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/orders/:id/supplierorders`}
        module={ModuleIdentifiers.SupplierOrders}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Ordrar', to: `${path}/orders` },
          { title: 'Order', to: `${path}/orders/:id` },
          { title: 'Skapa beställning' },
        ]}
      >
        <h1 className={'sr-only'}>Skapa beställning</h1>
        <CreateSupplierOrdersForm />
      </AuthRoute>

      {/* MEETING-NOTES */}
      <AuthRoute exact path={`${path}/meetingnotes`} module={ModuleIdentifiers.Todo} accessRights={AccessRights.Read}>
        <h1 className={'sr-only'}>Lista över mötesfrågor</h1>
        <MeetingNotes />
      </AuthRoute>
      <AuthRoute
        path={`${path}/meetingnotes/create`}
        module={ModuleIdentifiers.Todo}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Möte',
            to: `${path}/meetingnotes`,
          },
          {
            title: 'Skapa mötesfråga',
          },
        ]}
      >
        <h1 className={'sr-only'}>Skapa mötesfråga</h1>
        <MeetingNotesForm />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/meetingnotes/:id`}
        module={ModuleIdentifiers.Todo}
        accessRights={AccessRights.Read}
      >
        <h1 className={'sr-only'}>Visualisera mötesfrågor</h1>
        <MeetingNotes />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/meetingnotes/:id/update`}
        module={ModuleIdentifiers.Todo}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Möte',
            to: `${path}/meetingnotes`,
          },
          {
            title: 'Mötesfrågor',
            to: `${path}/meetingnotes/${id}`,
          },
          {
            title: 'Redigera mötesfråga',
          },
        ]}
      >
        <h1 className={'sr-only'}>Redigera mötesfråga</h1>
        <MeetingNotesForm />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/meetingnotes/:id/comment/create`}
        module={ModuleIdentifiers.Todo}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Möte',
            to: `${path}/meetingnotes`,
          },
          {
            title: 'Mötesfrågor',
            to: `${path}/meetingnotes/${id}`,
          },
          {
            title: 'Skapa kommentar',
          },
        ]}
      >
        <h1 className={'sr-only'}>Skapa kommentar</h1>
        <MeetingNotesCommentForm />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/meetingnotes/:id/comment/:commentId/update`}
        module={ModuleIdentifiers.Todo}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Möte',
            to: `${path}/meetingnotes`,
          },
          {
            title: 'Mötesfrågor',
            to: `${path}/meetingnotes/${id}`,
          },
          {
            title: 'Redigera kommentar',
          },
        ]}
      >
        <h1 className={'sr-only'}>Redigera kommentar</h1>
        <MeetingNotesCommentForm />
      </AuthRoute>

      {/* MEETINGS ARCHIVE */}
      <AuthRoute
        exact
        path={`${path}/meetings/archive`}
        module={ModuleIdentifiers.Todo}
        accessRights={AccessRights.Read}
      >
        <h1 className={'sr-only'}>Mötesarkiv</h1>
        <MeetingsArchive />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/meetings/archive/:id`}
        module={ModuleIdentifiers.Todo}
        accessRights={AccessRights.Read}
      >
        <h1 className={'sr-only'}>Visualisera möte</h1>
        <MeetingsArchive />
      </AuthRoute>

      {/* ERROR-REPORTS */}
      <AuthRoute
        exact
        path={`${path}/error-reports`}
        module={ModuleIdentifiers.ErrorReport}
        accessRights={AccessRights.Read}
      >
        <h1 className={'sr-only'}>Lista över felrapporter</h1>
        <ReportList />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/error-reports/:id/update`}
        module={ModuleIdentifiers.ErrorReport}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Felrapporter',
            to: `${path}/error-reports`,
          },
          {
            title: 'Redigera felrapport',
          },
        ]}
      >
        <h1 className={'sr-only'}>Redigera felrapport</h1>
        <ReportForm />
      </AuthRoute>

      {/* AUDITS */}
      <AuthRoute exact path={`${path}/audit/list`} module={ModuleIdentifiers.Customer} accessRights={AccessRights.Read}>
        <h1 className={'sr-only'}>Lista över revisioner</h1>
        <AuditList onlyMine />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/audit/list/:customerId/:id`}
        module={ModuleIdentifiers.Customer}
        breadcrumbs={[{ title: 'Revisioner', to: `${path}/audit/list` }, { title: 'Avtal' }]}
      >
        <h1 className={'sr-only'}>Redigera avtal</h1>
        <AuditForm cancelPath={`${path}/audit/list`} />
      </AuthRoute>

      <Route path={[path, `${path}/*`]}>
        <Redirect to={`${path}/list`} />
      </Route>
    </Switch>
  );
}

export default TodosRoute;
