import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '3rem',
  },
  container: {
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    alignItems: 'center',
    left: '6rem',
    width: 'calc(100% - 6rem)',
    position: 'fixed',
    zIndex: 3,
    top: 0,
    [theme.breakpoints.down('xs')]: {
      top: '3.5rem',
      width: 'calc(100% - 2rem)',
      padding: '0 1rem',
      left: 0,
      justifyContent: 'space-between',
    },
  },
  message: {
    color: theme.palette.common.white,
    padding: '0.5rem 1rem 0.5rem 2rem',
    [theme.breakpoints.down('xs')]: {
      padding: '0.5rem 1rem 0.5rem 0',
    },
  },
  company: {
    fontWeight: 700,
  },
  button: {
    backgroundColor: theme.palette.common.white,
    margin: '0.5rem 0',
    [theme.breakpoints.down('xs')]: {
      margin: '0.5rem 0 0.5rem 1rem',
    },
  },
}));
