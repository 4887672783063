import React from 'react';
import { IInputFieldProps, InputField } from '../InputField/InputField';

export interface IEmailFieldProps extends IInputFieldProps {
  removeHelperText?: boolean;
}

const EmailField = (props: IEmailFieldProps) => {
  const { ...rest } = props;
  let pattern = undefined;
  let label = props.label;
  let helperText = undefined;
  // eslint-disable-next-line no-useless-escape
  const expresssion = new RegExp(/^[^\s@]+@[^\s@]+\.[a-z]{2,}$/i);
  pattern = expresssion.source;
  if (!props.removeHelperText) helperText = 'email@example.com';
  let inputProps = { ...props.inputProps, pattern };

  return <InputField type="email" {...rest} inputProps={inputProps} label={label} helperText={helperText}/>;
};

export default EmailField;
