import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  remove: {
    display: 'flex',
    borderRadius: '12rem',
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.error.main,
  },
  addIconBtn: {
    borderRadius: '12rem',
    backgroundColor: theme.palette.grey[100],
    marginLeft: '1rem',
    marginTop: '1.5rem',
  },
}));
