import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, FormControlLabel, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import RichTextEditor from '../../../../../../../Shared/RichTextEditor/RichTextEditor';
import CheckboxField from '../../../../../../../Shared/InputFields/CheckboxField/CheckboxField';
import Select, { ISelectOption } from '../../../../../../../Shared/Select/Select';
import {
  AuditInterval,
  InvoiceInterval,
  invoiceIntervals,
  mapAgreementOptions,
  auditIntervals,
} from '../../../../../Errands/Components/Sale.api';
import {
  useUpsertPreselectedAgreementTerms,
  useGetPreselectedAgreementTerms,
  IUpsertPreselectedAgreementTermsCommandValidationErrors,
  useGetPreselectionFileContent,
  IPreselectionFileInformationDto,
  useGetPreselectedDocumentFormat,
} from '../../Preselection.api';
import { Fieldset } from '../../../../../../../Shared/Form/Fieldset/Fieldset';
import { IPreselectionProps } from '../../Preselection';
import { isResultError } from '../../../../../../../Shared/Api/response/ICreateResult';
import { Table } from '../../../../../../../Shared/Table/Table';
import formatDate from '../../../../../../../Shared/Formatting/formatDate';
import Button from '../../../../../../../Shared/Button/Button';
import { getSelectedOption, getSelectedValue } from '../../../../../../Shared/utils';
import useStyles from '../../Preselection.styles';

const auditOptions: ISelectOption[] = mapAgreementOptions(auditIntervals);
const invoiceOptions: ISelectOption[] = mapAgreementOptions(invoiceIntervals);

const AgreementTerms = (props: IPreselectionProps) => {
  const classes = useStyles();
  const {
    preselectionDefaultContent,
    saveCallback,
    setPreselectionId,
    setIsLoading,
    setPreselectionDefaultContent,
    setChildrenComponent,
  } = props;
  const { isLoading: isGetLoading, getPreselectedAgreementTerms } = useGetPreselectedAgreementTerms();
  const { isLoading: isUpdateLoading, upsert } = useUpsertPreselectedAgreementTerms();
  const { isLoading: isGettingFileLoading, getPreselectionFileContent } = useGetPreselectionFileContent();

  const { getPreselectedDocumentFormat } = useGetPreselectedDocumentFormat();

  const [invoiceInterval, setInvoiceInterval] = useState<InvoiceInterval | undefined>();
  const [auditInterval, setAuditInterval] = useState<AuditInterval | undefined>();
  const [termsAndConditions, setTermsAndConditions] = useState<string | undefined>();
  const [includeInnerControl, setIncludeInnerControl] = useState<boolean>(false);
  const [agreementFiles, setAgreementFiles] = useState<IPreselectionFileInformationDto[]>([]);
  const [errors, setErrors] = useState<IUpsertPreselectedAgreementTermsCommandValidationErrors>();
  const [header, setHeader] = useState<string>();
  const [footer, setFooter] = useState<string>();

  useEffect(() => {
    setIsLoading(isGetLoading || isUpdateLoading || isGettingFileLoading);
  }, [isGetLoading, isUpdateLoading, isGettingFileLoading, setIsLoading]);

  useEffect(() => {
    if (preselectionDefaultContent) {
      setTermsAndConditions(preselectionDefaultContent);
      setPreselectionDefaultContent(undefined);
    }
  }, [preselectionDefaultContent, setPreselectionDefaultContent]);

  useEffect(() => {
    const save: any = async (id: string) => {
      var result = await upsert(
        {
          auditInterval: auditInterval,
          invoiceInterval: invoiceInterval,
          includeInnerControl: includeInnerControl,
          termsAndConditions: termsAndConditions,
        },
        id,
      );
      if (isResultError(result)) {
        setErrors(result);
        return false;
      }
      return true;
    };
    saveCallback(() => save);
  }, [auditInterval, invoiceInterval, includeInnerControl, termsAndConditions, saveCallback, upsert]);

  useEffect(() => {
    async function getValues() {
      const result = await getPreselectedAgreementTerms();
      if (result) {
        setPreselectionId(result.id?.toString());
        setInvoiceInterval(result.invoiceInterval);
        setAuditInterval(result.auditInterval);
        setIncludeInnerControl(result.includeInnerControl);
        setTermsAndConditions(result.termsAndConditions);
        setAgreementFiles(result.termsAndConditionsFileNames);
      } else {
        setErrors(result);
        return false;
      }
    }
    getValues();
  }, [getPreselectedAgreementTerms, setPreselectionId]);

  useEffect(() => {
    agreementFiles && setChildrenComponent(<AgreementFileTable />);
  }, [agreementFiles, setChildrenComponent]);

  const setHeaderFooter = useCallback(async () => {
    const results = await getPreselectedDocumentFormat();
    if (results) {
      setFooter(results.customFooter ?? '');
      setHeader(results.customHeader ?? '');
    }
  }, [getPreselectedDocumentFormat]);

  useEffect(() => {
    setHeaderFooter();
  }, [setHeaderFooter]);

  const textEditor = useMemo(() => {
    if (header && footer) {
      return (
        <RichTextEditor
          menuBar={false}
          header={header}
          footer={footer}
          termsAndCondtions={termsAndConditions}
          value={termsAndConditions ?? ''}
          onChange={setTermsAndConditions}
          onFocus={() => true}
          onBlur={() => false}
          type={'Agreement'}
        />
      );
    }
  }, [header, footer, termsAndConditions]);

  const AgreementFileTable = () => (
    <Table customSize={{ md: 5, lg: 5 }}>
      <TableHead>
        <TableRow>
          <TableCell>Version</TableCell>
          <TableCell>Skapad</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {agreementFiles.map((file) => (
          <TableRow>
            <TableCell>
              <Button
                color="primary"
                variant="text"
                className={classes.button}
                disableTouchRipple
                onClick={() => handleGetPreselectionFile(file.id.toString())}
              >
                {file.name}
              </Button>
            </TableCell>
            <TableCell>{formatDate(new Date(file.created))}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  const handleGetPreselectionFile = async (id: string) => {
    setTermsAndConditions((await getPreselectionFileContent(id.toString())).content);
  };

  return (
    <>
      <Fieldset>
        <Select
          inputFieldProps={{ label: 'Förval för fakturaintervall', placeholder: 'Välj faktureringsintervall' }}
          selectProps={{
            isClearable: true,
            options: invoiceOptions,
            errorText: errors?.invoiceInterval,
            value: getSelectedOption(invoiceOptions, invoiceInterval),
            onChange: (option) => {
              setInvoiceInterval(getSelectedValue(option));
            },
          }}
        />
        <Select
          inputFieldProps={{ label: 'Förval för revision', placeholder: 'Välj revision' }}
          selectProps={{
            isClearable: true,
            options: auditOptions,
            errorText: errors?.auditInterval,
            value: getSelectedOption(auditOptions, auditInterval),
            onChange: (option) => {
              setAuditInterval(getSelectedValue(option));
            },
          }}
        />
        <CheckboxField label="Förval för nyckelförvaring">
          <FormControlLabel
            onClick={() => setIncludeInnerControl(!includeInnerControl)}
            checked={includeInnerControl}
            control={<Checkbox color="primary" />}
            label="Inklusive inre kontroll"
          />
        </CheckboxField>
      </Fieldset>

      <Fieldset legend="Avtalsvillkor">
        <div className={classes.wysiwygFields}>{textEditor}</div>
      </Fieldset>
    </>
  );
};

export default AgreementTerms;
