import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  skeleton: {
    [theme.breakpoints.up('md')]: {
      minWidth: '18rem',
      maxWidth: '37rem',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
  button: {
    padding: 0,
    top: '-0.25rem',
    border: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  skeletonLabel: {
    height: `${19 / 16}rem`,
    marginBottom: '0.25rem',
  },
  skeletonInput: {
    height: `${45 / 16}rem`,
  },
}));

export default useStyles;
