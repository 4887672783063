import React, { useCallback, useMemo } from 'react';
import { AsyncSelect, MultiAsyncSelect } from '../../../../../../Shared/Select';
import { ISelectOption } from '../../../../../../Shared/Select/Select';
import { useGetFacilities } from '../Facilities.api';
import { GridSize } from '@material-ui/core';

export interface IProps {
  onChange?: (
    facilityId: string | undefined,
    facilityName: string | undefined,
    option: ISelectOption | ISelectOption[] | undefined,
  ) => void;
  customerId: string;
  required?: boolean;
  selectedFacility?: ISelectOption | ISelectOption[];
  selectedFacilityId?: string | string[];
  label?: string;
  placeholder?: string;
  isClearable?: boolean;
  isMulti?: boolean;
  inline?: boolean;
  fullwidth?: boolean;
  customWidth?: GridSize;
  facilityIds?: number[];
  isDisabled?: boolean;
  viewOnly?: boolean;
  hasNewOption?: boolean;
  hasSystem?: boolean;
}

const newOption = { label: 'Ny plats', value: '0' };

const FacilitySelect = (props: IProps) => {
  const { facilityIds, hasNewOption, hasSystem, isMulti, selectedFacility, selectedFacilityId } = props;
  const { isLoading, getFacilities } = useGetFacilities(props.customerId);

  const selectedOption = useMemo(() => {
    if (!selectedFacility) return undefined;
    if (isMulti) {
      return Array.isArray(selectedFacility) ? selectedFacility : [selectedFacility];
    } else {
      return Array.isArray(selectedFacility) ? selectedFacility[0] : selectedFacility;
    }
  }, [isMulti, selectedFacility]);

  const selectedOptionId = useMemo(() => {
    if (!selectedFacilityId) return undefined;
    if (isMulti) {
      return Array.isArray(selectedFacilityId) ? selectedFacilityId : [selectedFacilityId];
    } else {
      return Array.isArray(selectedFacilityId) ? selectedFacilityId?.[0] : selectedFacilityId;
    }
  }, [isMulti, selectedFacilityId]);

  const loadFacilities = useCallback(
    async (query?: string) => {
      const response = await getFacilities({ query, pageNumber: 1, pageSize: 1000, hasSystem });
      var newOptions =
        response?.items.map((x) => ({
          value: x.id.toString(),
          label: x.name,
        })) ?? [];

      if (facilityIds) {
        newOptions = newOptions.filter((option) => facilityIds.find((id) => option.value === id.toString()));
      }

      if (hasNewOption) {
        newOptions?.unshift(newOption);
      }

      return newOptions;
    },
    [facilityIds, hasNewOption, hasSystem, getFacilities],
  );

  if (isMulti) {
    return (
      <MultiAsyncSelect
        {...props}
        placeholder={props.placeholder ?? 'Välj plats'}
        isLoading={isLoading}
        selectedOptions={selectedOption as ISelectOption[] | undefined}
        selectedOptionIds={selectedOptionId as string[] | undefined}
        loadOptions={loadFacilities}
        onChange={(value) => props.onChange && props.onChange(undefined, undefined, value)}
        customWidth={props.customWidth}
      />
    );
  }

  return (
    <AsyncSelect
      {...props}
      placeholder={props.placeholder ?? 'Välj plats'}
      isLoading={isLoading}
      selectedOption={selectedOption as ISelectOption | undefined}
      selectedOptionId={selectedOptionId as string | undefined}
      loadOptions={loadFacilities}
      onChange={(value) => props.onChange && props.onChange(value?.value, value?.label, value)}
      customWidth={props.customWidth}
    />
  );
};

export default FacilitySelect;
