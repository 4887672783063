import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import { Checkbox, FormControlLabel, Grid, IconButton, Radio } from '@material-ui/core';
import { Fieldset } from '../../../../../../../Shared/Form/Fieldset/Fieldset';
import Container from '../../../../../../../Shared/Container/Container';
import WizardFormActions from '../../../../../../../Shared/Form/WizardFormActions/WizardFormActions';
import useFormValidation from '../../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import { InputField } from '../../../../../../../Shared/InputFields/InputField/InputField';
import RadioField from '../../../../../../../Shared/InputFields/RadioField/RadioField';
import useInputState from '../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { CustomerType } from '../../../../../../../Shared/Misc/CustomerType';
import IdentificationNumberField from '../../../../../../../Shared/InputFields/IdentificationNumberField/IdentificationNumberField';
import EmailField from '../../../../../../../Shared/InputFields/EmailField/EmailField';
import PostalCodeField from '../../../../../../../Shared/InputFields/PostalCodeField/PostalCodeField';
import PhoneNumberField from '../../../../../../../Shared/InputFields/PhoneNumberField/PhoneNumberField';
import Select, { ISelectOption } from '../../../../../../../Shared/Select/Select';
import InlineButton from '../../../../../../../Shared/Button/InlineButton';
import EmptyGridSpace from '../../../../../../../Shared/EmptyGridSpace/EmptyGridSpace';
import CheckboxField from '../../../../../../../Shared/InputFields/CheckboxField/CheckboxField';
import { isResultError } from '../../../../../../../Shared/Api/response/ICreateResult';
import { SaleType, SaleTypeLabel } from '../../../../../../Shared/SaleType';
import CustomerSelect from '../../../../../Customers/Components/CustomerSelect/CustomerSelect';
import CustomerTypeSelect from '../../../../../Customers/Components/CustomerTypeSelect/CustomerTypeSelect';
import { useCheckIdentificationExisting, useGetProspectLegalData } from '../../../../../Customers/Customers.api';
import OfferFormContext from '../OfferFormContext';
import {
  useUpdateOfferDraft,
  useCreateOfferDraft,
  IUpdateOfferDraftCommand,
  ICreateOfferDraftCommand,
  IOfferCustomerContactData,
  ICustomerContactDto,
  getNextPage,
  useGetOfferDraft,
  useGetOfferCustomer,
} from '../../Offer.api';
import useStyles from './SelectCustomerAndSaleType.style';
import CompanyUserSelect from '../../../../../Admin/Components/Users/UserSelect/CompanyUserSelect';
import WarningModal from '../../../../../../../Shared/WarningModal/WarningModal';
import useUserContext from '../../../../../../../Core/Authentication/UserContext';

const SelectCustomerAndSaleType: React.FunctionComponent = () => {
  const { user } = useUserContext();
  const classes = useStyles();
  const { id: offerId } = useParams<{ id: string }>();
  const { offerStep, setOfferStep, offerType, setOfferType, customerId, setCustomerId, identifier } = useContext(
    OfferFormContext,
  );
  const history = useHistory();
  const { isFormValid, formRef } = useFormValidation();
  const { isLoading: isCheckLoading, check } = useCheckIdentificationExisting();

  const { get: getOfferDraft, isLoading: isGetOfferDraftLoading } = useGetOfferDraft(offerId);
  const { get: getOfferCustomer, isLoading: isGetOfferCustomerLoading } = useGetOfferCustomer();
  const { getProspectLegalData, isLoading: isGetProspectDataLoading } = useGetProspectLegalData();
  const { post: createOfferDraft, isLoading: isCreateOfferDraftLoading } = useCreateOfferDraft();
  const { put: updateOfferCustomer, isLoading: isUpdateOfferCustomerLoading } = useUpdateOfferDraft(offerId);

  const [description, changeDescription, setDescription] = useInputState('');

  const [identificationNumber, changeIdentificationNumber, setIdentificationNumber] = useInputState('');
  const [customerType, setCustomerType] = useState<CustomerType>(CustomerType.Private);
  const [name, changeName, setName] = useInputState('');
  const [address, changeAddress, setAddress] = useInputState('');
  const [postalCode, changePostalCode, setPostalCode] = useInputState('');
  const [city, changeCity, setCity] = useInputState('');
  const [email, changeEmail, setEmail] = useInputState('');
  const [phoneNumber, changePhoneNumber, setPhoneNumber] = useInputState('');
  const [isProspect, setIsProspect] = useState(true);
  const [doCreditCheck, setDoCreditCheck] = useState<boolean>(true);
  const [customerDataCanBeFetched, setCustomerDataCanBeFetched] = useState(false);
  const [customerDataCanBeCleared, setCustomerDataCanBeCleared] = useState(false);

  const [addContactPerson, setAddContactPerson] = useState(false);
  const [customerContacts, setCustomerContacts] = useState<ICustomerContactDto[]>();
  const [salesPersonId, setSalesPersonId] = useState<string>(user.selectedUserId?.toString() ?? '0');
  const [selectedContactId, setSelectedContactId] = useState<string>('0');
  const [contactFirstName, changeContactFirstName, setContactFirstName] = useInputState('');
  const [contactLastName, changeContactLastName, setContactLastName] = useInputState('');
  const [contactEmail, changeContactEmail, setContactEmail] = useInputState('');
  const [contactPhoneNumber, changeContactPhoneNumber, setContactPhoneNumber] = useInputState('');
  const [contactPosition, changeContactPosition, setContactPosition] = useInputState('');
  const [isNewContact, setIsNewContact] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [modalLink, setModalLink] = useState<string>('');

  const hasContact =
    !!contactFirstName || !!contactLastName || !!contactPhoneNumber || !!contactEmail || !!contactPosition;
  const isLoading = isGetOfferDraftLoading || isGetOfferCustomerLoading;
  const isProcessing = isCreateOfferDraftLoading || isUpdateOfferCustomerLoading;

  /* Return selected customer id as a list of one, or as an empty list */
  const selectedCustomerIds = useMemo(() => {
    return !!customerId ? [customerId] : [];
  }, [customerId]);

  /* Set up and return customer contacts as select options */
  const customerContactOptions = useMemo(() => {
    let options: ISelectOption[] = [{ value: '0', label: 'Ny kontakt' }];
    if (customerContacts) {
      options = [
        ...options,
        ...customerContacts.map((x) => ({ value: x.id.toString(), label: x.firstName + ' ' + x.lastName })),
      ];
    }
    return options;
  }, [customerContacts]);

  /* Set offer draft data for existing offer */
  useEffect(() => {
    async function loadOfferDraft() {
      if (!!offerId) {
        const response = await getOfferDraft();
        if (response) {
          setDescription(response.description);
          setSelectedContactId(response.customerContactId ? response.customerContactId!.toString() : '0');
          setSalesPersonId(response.salesPersonId?.toString() ?? user.selectedUserId?.toString() ?? '0');
        }
      } else {
        setDescription('');
        setSelectedContactId('0');
      }
    }
    loadOfferDraft();
  }, [offerId, getOfferDraft, setDescription, user.selectedUserId]);

  /* Set offer customer data and customer contacts list */
  useEffect(() => {
    async function loadOfferCustomer() {
      if (!!customerId) {
        const response = await getOfferCustomer(customerId.toString());

        if (response) {
          setIdentificationNumber(response.identificationNumber);
          setCustomerType(response.type);
          setName(response.name);
          setEmail(response.email);
          setAddress(response.address);
          setPhoneNumber(response.phoneNumber);
          setPostalCode(response.postalCode);
          setCity(response.city);
          setIsProspect(response.isProspect);
          setDoCreditCheck(response.type === CustomerType.Company);
          setCustomerDataCanBeFetched(true);

          let contacts = response.contacts?.map((x) => ({
            id: x.id,
            firstName: x.firstName,
            lastName: x.lastName,
            position: x.position ?? 'Beställare',
            email: x.email,
            phoneNumber: x.phoneNumber,
            isProspect: x.isProspect,
          }));
          setCustomerContacts(contacts);
          setSelectedContactId((x) => {
            let contactId = !!contacts?.find((c) => c.id.toString() === x) ? x : '0';
            setAddContactPerson(response.type === CustomerType.Company || contactId !== '0');
            return contactId;
          });
          
          setSelectedContactId(contacts ? contacts?.sort((x, y) => y.id - x.id)[0].id.toString() : '0');
          
          
        }
      } else if (customerId === 0) {
        setIdentificationNumber('');
        setCustomerType(CustomerType.Private);
        setName('');
        setEmail('');
        setAddress('');
        setPhoneNumber('');
        setPostalCode('');
        setCity('');
        setIsProspect(true);
        setDoCreditCheck(true);
        setCustomerDataCanBeFetched(false);
        setAddContactPerson(false);
        setCustomerContacts(undefined);
        setSelectedContactId('0');
      }
    }
    loadOfferCustomer();
  }, [
    customerId,
    getOfferCustomer,
    setAddress,
    setCity,
    setEmail,
    setIdentificationNumber,
    setName,
    setPhoneNumber,
    setPostalCode,
  ]);

  /* Set customer contact info for existing offer draft if a contact has previously been selected */
  useEffect(() => {
    let selectedContact = undefined;

    if (selectedContactId !== '0' && customerContacts !== undefined) {
      selectedContact = customerContacts.find((x) => x.id.toString() === selectedContactId);
    }

    setContactFirstName(selectedContact?.firstName ?? '');
    setContactLastName(selectedContact?.lastName ?? '');
    setContactPosition(selectedContact?.position ?? '');
    setContactPhoneNumber(selectedContact?.phoneNumber ?? '');
    setContactEmail(selectedContact?.email ?? '');
    setIsNewContact(selectedContact?.isProspect ?? true);
  }, [
    selectedContactId,
    customerContacts,
    setContactFirstName,
    setContactLastName,
    setContactPosition,
    setContactPhoneNumber,
    setContactEmail,
  ]);

  /* Set customer data fetched from external API */
  const getCustomerData = async () => {
    if (identificationNumber && customerType) {
      const result = await getProspectLegalData({ type: customerType, identificationNumber: identificationNumber });
      setName(result.name);
      setEmail(result.invoiceEmail);
      setAddress(result.invoiceAddress);
      setPostalCode(result.invoicePostalCode);
      setCity(result.invoiceCity);
      setCustomerDataCanBeFetched(false);
      setCustomerDataCanBeCleared(true);
    }
  };

  const clearCustomerData = () => {
    setName('');
    setEmail('');
    setAddress('');
    setPostalCode('');
    setCity('');
    setCustomerDataCanBeCleared(false);
    setCustomerDataCanBeFetched(true);
  };

  const clearContactData = () => {
    setContactFirstName('');
    setContactLastName('');
    setContactEmail('');
    setContactPhoneNumber('');
    setContactPosition('');
    setIsNewContact(true);
  };

  const handleIdentificationNrChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value && e.target.value === identificationNumber) setCustomerDataCanBeFetched(true);
    changeIdentificationNumber(e);
    if (e.target.value.length === 11 && !isCheckLoading) {
      checkExistingId(e.target.value as string);
    }
  };

  const checkExistingId = async (id: string) => {
    const customerId = await check({
      identificationNumber: id
    });
    if (customerId !== undefined) {
      setCustomerId(Number(customerId));
      setSelectedContactId('0');
    }
  }

  /* Create offer draft and/or update it with customer and contact data */
  const upsertOfferDraft = async () => {
    if (offerType === undefined) {
      return;
    }

    let result: any = undefined;

    const updatedCustomer = {
      type: customerType,
      name: name,
      identificationNumber: identificationNumber,
      email: email,
      phoneNumber: phoneNumber,
      address: address,
      postalCode: postalCode,
      city: city,
      isProspect: isProspect,
    };

    const selectedContatPersonId = selectedContactId === '0' ? undefined : Number(selectedContactId);

    const updatedOfferContactPerson: IOfferCustomerContactData | undefined = hasContact
      ? {
          firstName: contactFirstName,
          lastName: contactLastName,
          email: contactEmail,
          phoneNumber: contactPhoneNumber,
          position: contactPosition,
        }
      : undefined;

    if (offerId === undefined) {
      const createOfferDraftCommand: ICreateOfferDraftCommand = {
        saleType: offerType,
        description: description,
        customerId: customerId === 0 ? undefined : customerId,
        customer: updatedCustomer,
        performCreditCheck: doCreditCheck,
        customerContactId: selectedContatPersonId,
        contactPerson: updatedOfferContactPerson,
        salesPersonId: Number(salesPersonId)
      };

      result = await createOfferDraft(createOfferDraftCommand);
    } else {
      const updateOfferCustomerCommand: IUpdateOfferDraftCommand = {
        description: description,
        customer: updatedCustomer,
        customerContactId: selectedContatPersonId,
        contactPerson: updatedOfferContactPerson,
        salesPersonId: Number(salesPersonId)
      };

      result = await updateOfferCustomer(updateOfferCustomerCommand);
    }

    if (!isResultError(result)) {
      if (offerId === undefined) {
        history.push(`${result}/draft`);
      } else setOfferStep(getNextPage(offerType, offerStep));
    } else {
      history.push(`/errands/offers`);
    }
  };

  return (
    <>
      <Container
        form
        loading={isLoading}
        ref={formRef}
        label={
          (offerId ? 'Redigera ' + identifier : 'Skapa ny offert') + (offerType === SaleType.deal ? ' (Avtal)' : '')
        }
        actions={
          <WizardFormActions
            cancel={() => history.push('/errands')}
            goNext={upsertOfferDraft}
            isFormValid={isFormValid && offerType !== undefined}
            isProcessing={isProcessing}
          />
        }
        customSize={{ md: 12, lg: 12 }}
      >
        <RadioField label="Offerttyp">
          <FormControlLabel
            label={SaleTypeLabel[SaleType.system]}
            control={<Radio color="primary" />}
            checked={offerType === SaleType.system}
            onChange={() => setOfferType(SaleType.system)}
            disabled={!!offerId}
          />
          <FormControlLabel
            label={SaleTypeLabel[SaleType.deal]}
            control={<Radio color="primary" />}
            checked={offerType === SaleType.deal}
            onChange={() => setOfferType(SaleType.deal)}
            disabled={!!offerId}
          />
          <FormControlLabel
            label={SaleTypeLabel[SaleType.custom]}
            control={<Radio color="primary" />}
            checked={offerType === SaleType.custom}
            onChange={() => setOfferType(SaleType.custom)}
            disabled={!!offerId}
          />
        </RadioField>
        <InputField label="Rubrik offert" type="text" value={description} onChange={changeDescription} customWidth={3}/>
        <CompanyUserSelect
          isClearable
          label={'Säljare'}
          selectedCompanyUserId={salesPersonId}
          onChange={(_1, _2, option) => {
            setSalesPersonId((option as ISelectOption).value)}
          }
          customWidth={3}
        />
        <Fieldset legend="Kunduppgifter">
          <IdentificationNumberField
            disabled={(customerId && customerId !== 0) || !!offerId}
            withoutLastFourDigits={false}
            customerType={customerType}
            required
            value={identificationNumber}
            onChange={handleIdentificationNrChange}
            customWidth={isProspect && customerDataCanBeFetched ? 3 : 6}
          />

          {isProspect && customerDataCanBeFetched && (
            <InlineButton
              color="primary"
              variant="contained"
              justify="flex-start"
              onClick={getCustomerData}
              loading={isGetProspectDataLoading}
              customWidth={3}
              disabled={!(customerType && identificationNumber)}
            >
              Hämta kundinfo
            </InlineButton>
          )}

          {isProspect && customerDataCanBeCleared && (
            <InlineButton color="secondary" variant="contained" justify="flex-start" onClick={clearCustomerData}>
              Rensa kundinfo
            </InlineButton>
          )}

          {!isProspect && <EmptyGridSpace width="half" />}

          <CustomerSelect
            addProspectOption
            includeProspect
            required
            customerIds={selectedCustomerIds}
            selectedCustomerId={customerId?.toString()}
            onChange={(customerId, _1) => {
              setCustomerId(Number(customerId));
              setSelectedContactId('0');
            }}
            isDisabled={!!offerId}
          />
          <CustomerTypeSelect
            disabled={(customerId && customerId !== 0) || !!offerId}
            required
            selected={customerType}
            onChange={(type) => {
              setCustomerType(type);
              setDoCreditCheck(type === CustomerType.Company);
              setAddContactPerson(type === CustomerType.Company);
            }}
          />
          <InputField label="Namn" type="text" required value={name} onChange={changeName} disabled={!isProspect} />
          <InputField
            label="Gatuadress"
            type="text"
            required
            value={address}
            onChange={changeAddress}
            disabled={!isProspect}
          />
          <PostalCodeField
            required
            label="Postnummer"
            type="text"
            value={postalCode}
            onChange={changePostalCode}
            disabled={!isProspect}
          />
          <InputField label="Ort" type="text" required value={city} onChange={changeCity} disabled={!isProspect} />
          <EmailField
            label={customerType === CustomerType.Company ? 'E-post till företaget' : 'E-post'}
            required={!hasContact}
            value={email}
            onChange={changeEmail}
            disabled={!isProspect}
          />
          <PhoneNumberField
            label={customerType === CustomerType.Company ? 'Telefonnummer till företaget' : 'Telefon'}
            value={phoneNumber}
            onChange={changePhoneNumber}
            disabled={!isProspect}
          />
          {!offerId && (
            <CheckboxField label="">
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!!offerId}
                    name="doCreditCheck"
                    color="primary"
                    checked={doCreditCheck}
                    onChange={(_, checked) => customerType !== CustomerType.Company && setDoCreditCheck(checked)}
                  />
                }
                label="Utför en upplysning"
              />
            </CheckboxField>
          )}
          {!isProspect && customerId && (
            <>
              {offerId && <EmptyGridSpace width="half" />}
              <InlineButton
                color="primary"
                variant="text"
                startIcon={<EditIcon />}
                onClick={async () => {
                  console.log(customerId);
                  console.log(customerId ? `/customers/${customerId}/update` : '#');
                  if (isFormValid && offerType !== undefined) {
                    await upsertOfferDraft();
                    history.push(customerId ? `/customers/${customerId}/update` : '#');
                  } else {
                    setModalLink(customerId ? `/customers/${customerId}/update` : '#');
                    setOpenModal(true);
                  }
                }}
                disabled={!customerId}
                justify="flex-end"
                slim
              >
                Redigera kundinfo
              </InlineButton>
            </>
          )}
          {customerType === CustomerType.Private && !addContactPerson && (
            <div className={classes.iconButtonContainer}>
              <IconButton
                onClick={() => setAddContactPerson(true)}
                classes={{ root: classes.iconButton, label: classes.iconButtonLabel }}
              >
                <AddIcon color="primary" />
                <div aria-label="Lägg till offertmottagare">Lägg till offertmottagare</div>
              </IconButton>
            </div>
          )}
        </Fieldset>
        {addContactPerson && (
          <Fieldset legend="Offertmottagare" style={{ marginTop: '1rem' }}>
            {(customerId !== 0 || offerId) && (
              <Select
                inputFieldProps={{ label: 'Välj kontakt' }}
                selectProps={{
                  value: customerContactOptions?.find((x) => x.value === selectedContactId) || null,
                  isDisabled: !!(customerContactOptions?.length === 0),
                  isLoading: isLoading,
                  onChange: (selected) => {
                    setSelectedContactId(selected ? (selected as ISelectOption).value : '0');
                  },
                  options: customerContactOptions,
                }}
              />
            )}
            <InputField
              label="Förnamn"
              type="text"
              required={hasContact}
              value={contactFirstName}
              onChange={changeContactFirstName}
              disabled={!isNewContact}
            />
            <InputField
              label="Efternamn"
              type="text"
              required={hasContact}
              value={contactLastName}
              onChange={changeContactLastName}
              disabled={!isNewContact}
            />
            <EmailField
              label="E-post"
              type="text"
              required={hasContact}
              value={contactEmail}
              onChange={changeContactEmail}
              disabled={!isNewContact}
            />
            <PhoneNumberField
              label="Telefon"
              required={hasContact}
              value={contactPhoneNumber}
              onChange={changeContactPhoneNumber}
              disabled={!isNewContact}
            />
            {customerType === CustomerType.Company && (
              <InputField
                label="Befattning/Avdelning"
                type="text"
                value={contactPosition}
                onChange={changeContactPosition}
                disabled={!isNewContact}
              />
            )}
            {customerType === CustomerType.Private && (
              <div className={classes.iconButtonContainer}>
                <IconButton
                  onClick={() => {
                    setAddContactPerson(false);
                    clearContactData();
                  }}
                  classes={{ root: classes.iconButton, label: classes.iconButtonLabel }}
                >
                  <RemoveIcon color="primary" />
                  <div aria-label="Ta bort offertmottagare">Ta bort offertmottagare</div>
                </IconButton>
              </div>
            )}
            {!isNewContact ? (
              <>
                <EmptyGridSpace width="half" />
                <InlineButton
                  color="primary"
                  variant="text"
                  startIcon={<EditIcon />}
                  onClick={async () => {
                    if (isFormValid && offerType !== undefined) {
                      await upsertOfferDraft();
                      history.push(customerId ? `/customers/${customerId}/contact/${selectedContactId}/update` : '#');
                    } else {
                      setModalLink(customerId ? `/customers/${customerId}/contact/${selectedContactId}/update` : '#');
                      setOpenModal(true);
                    }
                  }}
                  disabled={!customerId || !selectedContactId}
                  justify="flex-end"
                >
                  Redigera kontakt
                </InlineButton>
              </>
            ) : (
              <EmptyGridSpace />
            )}
          </Fieldset>
        )}
        <WarningModal
          open={openModal}
          action={() => history.push(modalLink)}
          setOpen={setOpenModal}
          title={'Det går inte att spara automatiskt. Vill du lämna ändå?'}
          isLoading={isLoading}
        >
          <Grid container item>
            För att kunna spara automatiskt behöver alla tvingande fält vara ifyllda. Vill du ändå lämna formuläret?
          </Grid>
        </WarningModal>
      </Container>
    </>
  );
};
export default SelectCustomerAndSaleType;
