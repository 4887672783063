import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  terminateBtn: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  checkbox: {
    paddingLeft: '0.5rem',
  },
  center: {
    
  }
}));

export default useStyles;
