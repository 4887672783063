import { makeStyles } from '@material-ui/core';
import theme from '../../../Styles/Theme';

const useStyles = makeStyles(() => ({
  root: {
    fontWeight: 700,
    textDecoration: 'none',
    color: theme.palette.primary.main,
    padding: 0,
    fontSize: '0.9rem',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      outline: '5px auto rgba(0, 103, 244, 0.247)',
    },
  },
}));

export default useStyles;
