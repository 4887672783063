import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select, { ISelectOption } from '../../../../../../Shared/Select/Select';
import { IBMSSnapshotDto, useGetBMSNodeOldVersion } from '../../../BMS.api';

interface ISelectNode {
  id: string;
  setOpenModal: (open: boolean) => void;
  setOldVersion: (verions: IBMSSnapshotDto) => void;
  oldVersions: number[];
}

const SelectOldNode = (props: ISelectNode) => {
  const { id, oldVersions, setOpenModal, setOldVersion } = props;
  const { getSnapshot } = useGetBMSNodeOldVersion(id);
  const [version, setVersion] = useState<number | undefined>(oldVersions[0]);

  const versionOptions: ISelectOption[] = useMemo(
    () =>
      oldVersions.map((x) => {
        return { label: x.toString(), value: x.toString() };
      }),
    [oldVersions],
  );

  const oldVersion = useCallback(async () => {
    if (version) {
      const result = await getSnapshot(version.toString());
      if (result) {
        setOldVersion(result);
      }
    }
  }, [version, getSnapshot, setOldVersion]);

  useEffect(() => {
    oldVersion();
  }, [oldVersion]);

  return (
    <>
      <Select
        inputFieldProps={{ placeholder: 'Välj tidigare versioner...', label: 'Tidigare versioner' }}
        selectProps={{
          onChange: (selected) => {
            setVersion(selected ? Number((selected as ISelectOption).value) : undefined);
            setOpenModal && setOpenModal(true);
          },
          options: versionOptions,
        }}
      />
    </>
  );
};

export default SelectOldNode;
