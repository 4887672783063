import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { isResultError } from '../../../../../../../../../Shared/Api/response/ICreateResult';
import Button from '../../../../../../../../../Shared/Button/Button';
import Container from '../../../../../../../../../Shared/Container/Container';
import useErrorMessage from '../../../../../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import useFormValidation from '../../../../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import useInputState from '../../../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { InputField } from '../../../../../../../../../Shared/InputFields/InputField/InputField';
import { SupplierType } from '../../../../../../../Admin/Components/Suppliers/Suppliers.api';
import { SupplierSelect } from '../../../../../../../Admin/Components/Suppliers/SupplierSelect/SupplierSelect';
import FormatSelect from '../../../../../../../../../AuthApp/Modules/Admin/Components/Format/FormatSelect/FormatSelect';

import {
  IUpdateCoreSystemCommunicationCommand,
  useGetCoreSystemCommunication,
  useUpdateCoreSystemCommunication,
} from '../../../CoreSystem.api';

const EditCommunication = () => {
  const history = useHistory();
  const { customerId, facilityId, id } = useParams<{ customerId: string; facilityId: string; id: string }>();
  const systemsUrl = `/customers/${customerId}/facility/${facilityId}`;
  const [selectedAlarmCenterId, setSelectedAlarmCenterId] = useState<string>();
  const [formatId, setFormatId] = useState<string | undefined>();
  const [transmitter, changeTransmitter, setTransmitter] = useInputState('');
  const [GSMorSNR, changeGSMorSNR, setGSMorSNR] = useInputState<string | null>(null);
  const [multicomNr, changeMulticomNr, setMulticomNr] = useInputState<string | null>(null);
  const [TCPorIP, changeTCPorIP, setTCPorIP] = useInputState<string | null>(null);
  const [selectedSecurityCompanyId, setSelectedSecurityCompanyId] = useState<string>();
  const [keyNr, changeKeyNr, setKeyNr] = useInputState<string | null>(null);
  const [otherInfo, changeOtherInfo, setOtherInfo] = useInputState<string | null>(null);
  const [selectedTelematicSupplierId, setSelectedTelematicSupplierId] = useState<string>();
  const [alarmCenterInformation, changeAlarmCenterInformation, setAlarmCenterInformation] = useInputState<
    string | null
  >(null);
  const [securityCompanyInformation, changeSecurityCompanyInformation, setSecurityCompanyInformation] = useInputState<
    string | null
  >(null);
  const [telematicInformation, changeTelematicInformation, setTelematicInformation] = useInputState<string | null>(
    null,
  );

  const { isLoading: isGetLoading, getCommunication } = useGetCoreSystemCommunication(id);
  const { isLoading: isUpdateLoading, updateCommunication } = useUpdateCoreSystemCommunication(id);
  const { isFormValid, formRef } = useFormValidation();
  const { setValidationErrorMessage } = useErrorMessage();

  useEffect(() => {
    async function loadCommunication() {
      const response = await getCommunication();
      setSelectedAlarmCenterId(response.alarmCenterId?.toString());
      setFormatId(response.format?.id.toString());
      setTransmitter(response.transmitter);
      setGSMorSNR(response.gsMorSNR);
      setMulticomNr(response.multicomNumber);
      setTCPorIP(response.tcPorIP);
      setSelectedSecurityCompanyId(response.securityCompanyId?.toString());
      setKeyNr(response.keyNumber);
      setOtherInfo(response.otherInformation);
      setSelectedTelematicSupplierId(response.telematicSupplierId?.toString());
      setAlarmCenterInformation(response.alarmCenterInformation);
      setSecurityCompanyInformation(response.securityCompanyInformation);
      setTelematicInformation(response.telematicInformation);
    }
    if (id) loadCommunication();
  }, [
    id,
    getCommunication,
    setSelectedAlarmCenterId,
    setTransmitter,
    setGSMorSNR,
    setMulticomNr,
    setTCPorIP,
    setSelectedSecurityCompanyId,
    setKeyNr,
    setOtherInfo,
    setSelectedTelematicSupplierId,
    setAlarmCenterInformation,
    setSecurityCompanyInformation,
    setTelematicInformation,
  ]);

  async function updateCoreSystemCommunication() {
    if (!selectedAlarmCenterId || !formatId || !selectedSecurityCompanyId) {
      return;
    }

    const updateCommand: IUpdateCoreSystemCommunicationCommand = {
      alarmCenterId: Number(selectedAlarmCenterId),
      formatId: Number(formatId),
      transmitter: transmitter ?? '',
      gsMorSNR: GSMorSNR ? GSMorSNR : null,
      multicomNumber: multicomNr,
      tcPorIP: TCPorIP ? TCPorIP : null,
      securityCompanyId: Number(selectedSecurityCompanyId),
      keyNumber: keyNr,
      otherInformation: otherInfo ? otherInfo : null,
      telematicSupplierId: Number(selectedTelematicSupplierId),
      alarmCenterInformation: alarmCenterInformation ? alarmCenterInformation : null,
      securityCompanyInformation: securityCompanyInformation ? securityCompanyInformation : null,
      telematicInformation: telematicInformation ? telematicInformation : null,
    };

    const result = await updateCommunication(updateCommand);

    isResultError(result) ? setValidationErrorMessage(result) : history.push(`${systemsUrl}/system/${id}`);
  }

  function Actions() {
    const cancel = () => (id ? history.push(`${systemsUrl}/system/${id}`) : history.push(systemsUrl));

    return (
      <>
        <Button variant="outlined" onClick={cancel}>
          Avbryt
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={updateCoreSystemCommunication}
          loading={isUpdateLoading}
          disabled={!isFormValid || !selectedAlarmCenterId || !formatId || !selectedSecurityCompanyId}
        >
          Spara
        </Button>
      </>
    );
  }

  return (
    <Container
      form
      loading={isGetLoading}
      label="Redigera kommunikation "
      actions={<Actions />}
      ref={formRef}
      customSize={{ xs: 12, md: 6 }}
    >
      <SupplierSelect
        required
        label={'Larmcentral*'}
        selectedSupplierId={selectedAlarmCenterId}
        supplierType={SupplierType.EmergencyCallCenter}
        onChange={setSelectedAlarmCenterId}
      />
      <FormatSelect
        required
        label={'Format'}
        selectedFormatId={formatId ? Number(formatId) : undefined}
        onChange={(formatId) => {
          setFormatId(formatId ? formatId.toString() : undefined);
        }}
      />
      <InputField customWidth={4} label="TCP/IP" value={TCPorIP} onChange={changeTCPorIP} type="text" />
      <InputField customWidth={4} label="MulticomNr" value={multicomNr} onChange={changeMulticomNr} type="text" />
      <InputField customWidth={4} label="Sändar-ID" value={transmitter} onChange={changeTransmitter} type="text" />
      <InputField
        label="Info till Larmcentral"
        value={alarmCenterInformation}
        type="text"
        onChange={changeAlarmCenterInformation}
        multiline
        rows={5}
        fullwidth
      />
      <SupplierSelect
        required
        label={'Vaktbolag*'}
        selectedSupplierId={selectedSecurityCompanyId}
        supplierType={SupplierType.SecurityCompany}
        onChange={setSelectedSecurityCompanyId}
      />
      <InputField label="Nyckel ID" value={keyNr} onChange={changeKeyNr} type="text" />
      <InputField
        label="Info till Vaktbolag"
        value={securityCompanyInformation}
        onChange={changeSecurityCompanyInformation}
        multiline
        rows={5}
        fullwidth
      />
      <SupplierSelect
        required
        label={'Telematik-leverantör'}
        selectedSupplierId={selectedTelematicSupplierId}
        supplierType={SupplierType.Telematic}
        onChange={setSelectedTelematicSupplierId}
      />
      <InputField label="Telematik-ID" value={GSMorSNR} onChange={changeGSMorSNR} type="text" />
      <InputField
        label="Info telematik"
        value={telematicInformation}
        onChange={changeTelematicInformation}
        multiline
        rows={5}
        fullwidth
      />
      <InputField
        label="Interna anteckningar"
        value={otherInfo}
        onChange={changeOtherInfo}
        multiline
        rows={5}
        fullwidth
      />
    </Container>
  );
};

export default EditCommunication;
