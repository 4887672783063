import { useApiGet, useApiPost } from '../../../Core/Api/Api';
import ValidationErrorResultType from '../../../Shared/Api/response/ValidationErrorResultType';

export interface ISupplierOrderDto {
  supplierName: string;
  preferredDeliveryDate: Date;
  message: string;
  contactInfo: IContactInfoDto;
  products: IProduct[];
  deliveryMarking: string;
}
export interface IContactInfoDto {
  emailCustomerService?: string;
  phoneNumber?: string;
  companyLogoBase64?: string;
}
export interface IProduct {
  name: string;
  productNumber: string;
  quantity: number;
  id: number;
}

export function useGetSupplierOrder() {
  const { isLoading, response, get } = useApiGet<ISupplierOrderDto>('/api/anonymous/supplierorders/', false);

  return {
    isLoading,
    supplierOrder: response,
    get: get,
  };
}

export interface IConfirmSupplierOrderCommand {
  deliveryDate: Date;
  supplierOrderNumber: string;
  messageToPurchaser: string;
  products: IProductRequest[] | undefined;
}

export interface IProductRequest {
  quantity: number;
  id: number;
}

export interface IConfirmSupplierOrderValidationErrors
  extends ValidationErrorResultType<IConfirmSupplierOrderCommand> {}

export function useConfirmSupplierOrder(token: string | undefined) {
  const { isLoading, post } = useApiPost<IConfirmSupplierOrderValidationErrors, IConfirmSupplierOrderCommand>(
    `/api/anonymous/supplierorders/${token}/confirm`,
  );

  return {
    isConfirmLoading: isLoading,
    confirm: post,
  };
}
