import React from 'react';
import { useParams } from 'react-router-dom';
import MeetingNotesList from '../../Todos/Components/MeetingNotes/MeetingNotesList/MeetingNotesList';
import MeetingNotesView from '../../Todos/Components/MeetingNotes/MeetingNotesView/MeetingNotesView';

const Documents = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <MeetingNotesList label="Mötesdokumentation" mode="BMS" />
      {id && <MeetingNotesView noEdit />}
    </>
  );
};

export default Documents;
