import React, { useContext } from 'react';
import InputMask from 'react-input-mask';
import { IInputFieldProps, InputField } from '../InputField/InputField';
import { ContainerContext } from '../../Container/Container';

const charFormat = { '9': '[0-9]', a: '[A-Za-z]', '*': '[A-Za-z0-9]', '+': '[+0-9]' };

const PhoneNumberField = (props: IInputFieldProps) => {
  const { label, inputProps, onChange, ...otherProps } = props;
  const containerContext = useContext(ContainerContext);
  const pattern = `^(\\+?\\d+)[- ]\\d*$`;
  if (props.viewOnly || containerContext?.loading) {
    return <InputField type="text" {...otherProps} inputProps={{ ...inputProps, pattern }} label={label} />;
  }

  const onChangeEvent = onChange
    ? (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let newEvent = event;
        if (event.target) {
          newEvent = { ...event, target: { ...event.target, value: event.target.value.replace(/[-\s]/g, '') } };
        }
        onChange!(newEvent);
      }
    : undefined;

  return (
    <InputMask
      onChange={onChangeEvent}
      alwaysShowMask={false}
      mask="+99 9999999999999"
      value={props.value as string}
      maskChar={null}
      formatChars={charFormat}
      disabled={otherProps.disabled}
    >
      {(innerProps: any) => {
        const inputProps = { ...innerProps.inputProps, pattern };
        return <InputField type="text" inputProps={inputProps} {...otherProps} label={label} />;
      }}
    </InputMask>
  );
};

export default PhoneNumberField;
