import React from 'react';
import IconButton from '../IconButton/IconButton';
import UpIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import DownIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { IconButtonProps } from '@material-ui/core';
import useStyles from './ChangeLevel.styles';

interface IChangeLevelProps {
  up?: IconButtonProps;
  down?: IconButtonProps;
}

const ChangeLevel = (props: IChangeLevelProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <IconButton color="primary" size="small" aria-label="Flytta till en högre nivå" {...props.up}>
        <UpIcon />
      </IconButton>
      <IconButton color="primary" size="small" aria-label="Flytta till en lägre nivå" {...props.down}>
        <DownIcon />
      </IconButton>
    </div>
  );
};

export default ChangeLevel;
