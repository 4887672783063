import { makeStyles, Theme } from '@material-ui/core';
import { red } from '../../../../Styles/Theme';
import { bookingCalStyling, companyCalStyling, sharedCalendarStyling } from '../Components/SharedCalendar.styles';

export const useStyles = makeStyles((theme: Theme) => ({
  calendar: {
    display: 'flex',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    minHeight: '80px',
    '& ul': {
      width: '100%',
      textAlign: 'center',
      paddingInlineStart: '0',
      '& table': {
        width: '100%',
        '& tr': {
          height: '30px'
        },
        '& tr > td:nth-of-type(1)': {
          width: '90px'
        },
        '& tr > td:nth-of-type(2)': {
          textAlign: 'left',
          width: '120px',
          overflow: 'hidden',
        },
      }
    }
  },
  user: {
    minWidth: '15rem',
    display: 'flex',
    paddingTop: '1rem',
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '1rem',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  event: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
  label: {
    textTransform: 'capitalize',
  },
  companyCal: {
    '& .rbc-time-header-content': {
      borderLeftColor: 'transparent',
    },
  },
  bookingCal: {
    '& .rbc-time-view': {
      width: 'calc(100% - 15rem)',
      marginLeft: '15rem',
    },
  },
  main: {
    '& .rbc-time-content, .rbc-label': {
      display: 'none',
    },
    '& .rbc-row-content': {
      '& .rbc-row:empty': {
        display: 'none',
      },
    },
    '& .rbc-toolbar': {
      display: 'none',
    },
    '& .rbc-tooltip': {
      display: 'none',
    },
    '& .rbc-today ': {
      backgroundColor: theme.palette.common.white,
      '& span': {
        fontWeight: 600,
      },
    },
    '& .rbc-time-view': {
      border: 0,
    },
    '& .rbc-time-header-cell': {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& .rbc-event-allday': {
      outline: 'none',
    },
    '& .rbc-header': {
      borderBottom: 0,
      height: '3rem',
      fontWeight: 300,
      zIndex: 1,
      fontSize: theme.typography.body1.fontSize,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .rbc-agenda-view': {
      display: 'none',
    },
    '& .rbc-allday-cell': {
      height: '50%',
      position: 'relative',
    },
    '& .rbc-event': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      borderColor: 'rgba(255, 255, 255, 0.5)',
      color: red.main,
      position: 'relative'
    },
    '& .rbc-event-content': {
      textAlign: 'center',
    },
  },
  child: {
    width: '100%',
    '& .rbc-toolbar': {
      display: 'none',
    },
    '& .rbc-event-content': {
      fontWeight: 'bold',
      width: '100%',
    },
    '& .rbc-time-header-cell': {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      borderLeft: 0,
    },
    '& .rbc-agenda-empty': {
      textAlign: 'center',
    },
    '& .rbc-event-label': {
      display: 'none',
    },
    '& .rbc-allday-cell': {
      marginLeft: '0.5rem',
      marginRight: '0.5rem',
      marginTop: '0.5rem',
      width: 'auto',
      '& .rbc-row-content': {
        '& .rbc-row:empty': {
          display: 'none',
        },
      },
    },
    ...sharedCalendarStyling,
    ...bookingCalStyling,
    '& .rbc-time-gutter': {
      width: '6rem',
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      '& .rbc-time-column': {
        '&:nth-child(2)': {
          '& .rbc-timeslot-group': {
            borderLeft: 0,
          },
        },
      },
    },
  },
  empty: {
    '& .rbc-timeslot-group': {
      minHeight: '1rem',
    },
  },
  notEmpty: {
    '& .rbc-timeslot-group': {
      minHeight: '1.8rem',
    },
  },
  companyCalendarChild: {
    ...sharedCalendarStyling,
    ...companyCalStyling,
    width: '100%',
    minHeight: '80px',
    overflow: 'hidden',
    '& .rbc-time-content': {
      overflow: 'hidden',
      borderTop: 0,
    },
    '& .rbc-toolbar': {
      display: 'none',
    },
    '& .rbc-addons-dnd-resizable': {
      width: '100%',
    },
    '& .rbc-event-content': {
      fontWeight: 'bold',
      width: '100%',
    },
    '& .rbc-event': {
      height: 'fit-content !important',
      position: 'static',
      minWidth: '100%!important',
    },
    '& .rbc-event-label': {
      display: 'none',
    },
    '& table.rbc-agenda-table': {
      border: 'none',
    },
    '& .rbc-agenda-empty': {
      textAlign: 'center',
      paddingTop: '0.25rem',
      paddingBottom: '0.25rem',
    },
    '& .rbc-agenda-view table.rbc-agenda-table tbody > tr > td': {
      padding: '0.25rem 0.5rem',
      fontSize: theme.typography.body1.fontSize,
      verticalAlign: 'middle',
    },
    '& .rbc-agenda-view table.rbc-agenda-table thead > tr > th': {
      padding: '0.25rem 0.5rem',
    },

    '& .rbc-time-header-cell': {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      borderLeft: 0,
    },
    '& .rbc-events-container': {
      overflowY: 'auto',
      padding: "5px",
      marginRight: 0,
      [theme.breakpoints.down('sm')]: {
        marginRight: '0px',
        padding: '1rem'
      },
    },
    '& .rbc-event-continues-earlier .continuesDateStart': {
      display: 'none'
    },
    '& .rbc-event-continues-later .continuesDateEnd': {
      display: 'none'
    }
  },
  userName: {
    marginLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
  longUserName: {
    marginLeft: '0.5rem',
    paddingRight: '0.5rem',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  userMaxWidth: {
    maxWidth: 0,
    minWidth: '15rem',
    display: 'flex',
    paddingTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '1rem',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
  },

  companyBranchContainer: {
    backgroundColor: '#e4e4e4',
    borderColor: 'black',
    borderWidth: 10,
  },
  companyBranch: {
    marginLeft: 3,
    padding: 3,
  },
  weekDesktop: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: '3rem',
    marginLeft: '1rem',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  weekMobile: {
    position: 'relative',
    float: 'right'
  },
  companyBranchSelect: {
    [theme.breakpoints.up('md')]: {
      minWidth: '20rem',
      display: 'flow-root',
    },
    minWidth: '12rem',
    display: 'flow-root',
    flexDirection: 'column',
  },
}));
