import { useApiPost, useApiGet, useApiPut, useApiDelete } from '../../../../../Core/Api/Api';
import ValidationErrorResultType from '../../../../../Shared/Api/response/ValidationErrorResultType';
import IPaginatedRequest from '../../../../../Shared/Api/request/IPaginatedRequest';
import IPaginatedResponse from '../../../../../Shared/Api/response/IPaginatedResponse';
import { ICreateResult } from '../../../../../Shared/Api/response/ICreateResult';

export interface ICreateSystemTypeBody {
  name: string;
}

export function useUpsertSystemType() {
  const { isLoading: isCreateLoading, post } = useApiPost<
    ICreateResult<ICreateSystemTypeValidationErrors>,
    IUpsertSystemTypeCommand
  >('/api/systemtypes');

  const { isLoading: isUpdateLoading, put } = useApiPut<ICreateSystemTypeValidationErrors, IUpsertSystemTypeCommand>(
    '/api/systemtypes/',
  );

  async function upsert(command: IUpsertSystemTypeCommand, id?: string) {
    if (id) {
      const result = await put(command, id);

      return result ? result : id;
    }

    return post(command);
  }

  return {
    isLoading: isCreateLoading || isUpdateLoading,
    upsert: upsert,
  };
}

export function useGetSystemType() {
  const { isLoading, response, get } = useApiGet<ISystemType>('/api/systemtypes/', false);

  return {
    isLoading,
    response,
    getSystemType: get,
  };
}

export function useDeleteSystemType() {
  const { isLoading, delete: deleteRequest } = useApiDelete('/api/systemtypes/');

  function deleteSystemType(id: number) {
    return deleteRequest(undefined, id.toString());
  }

  return {
    isLoading,
    deleteSystemType,
  };
}

export interface ICreateSystemTypeValidationErrors extends ValidationErrorResultType<ICreateSystemTypeBody> {}

export interface ISystemType {
  id: number;
  name: string;
  termsOffer: string;
  termsIncluding: string;
  termsExcluding: string;
  termsRegulation: string;
  termsWarranty: string;
  termsDelivery: string;
  termsValidPeriod: string;
  regulations: IRegulation[];
  isCustom: boolean;
}

export interface IRegulation {
  id?: number;
  name: string;
  priority: number;
}

export interface IUpsertSystemTypeCommand {
  name: string;
  termsOffer: string;
  termsIncluding: string;
  termsExcluding: string;
  termsRegulation: string;
  termsWarranty: string;
  termsDelivery: string;
  termsValidPeriod: string;
  regulations: IRegulation[];
  isCustom: boolean;
}

export function useGetSystemTypes() {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<ISystemType>, IPaginatedRequest>(
    '/api/systemtypes/list',
  );

  return {
    isLoading,
    response,
    getSystemTypes: post,
  };
}
