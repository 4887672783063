import { useApiGet, useApiPut } from '../../../../../Core/Api/Api';
import ValidationErrorResultType from '../../../../../Shared/Api/response/ValidationErrorResultType';

export interface IFormat {
  id?: number | null;
  name: string;
  description: string;
  isVisible: boolean;
}

interface IUpdateIFormatCommand {
  Formats: IFormat[];
}
export interface IUpdateFormatValidationErrors extends ValidationErrorResultType<IFormat> {}

export function useUpdateFormats() {
  const { isLoading, put } = useApiPut<IUpdateFormatValidationErrors, IUpdateIFormatCommand>(`/api/formats/update`);

  function update(Formats: IFormat[]) {
    const command: IUpdateIFormatCommand = {
      Formats: Formats,
    };
    return put(command);
  }

  return {
    isLoading,
    updateFormat: update,
  };
}

export function useGetFormats() {
  const { isLoading, response, get } = useApiGet<IFormat[]>('/api/formats/admin/list/');

  return {
    isLoading,
    response,
    getFormats: get,
  };
}
