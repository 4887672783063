import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '6rem',
    marginTop: '1rem',
    padding: 0,
    alignItems: 'center',
    color: theme.palette.common.black,
    backgroundColor: 'transparent',
    textDecoration: 'none',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      '& > span > div > div': {
        backgroundColor: theme.palette.grey[300],
      },
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: '1rem',
    marginBottom: '0.5rem',
    borderRadius: theme.shape.borderRadius,
    border: `2px solid ${theme.palette.grey[200]}`,
  },
  link: {
    color: theme.palette.common.black,
    textDecoration: 'none',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
