import { useApiGet, useApiPut } from '../../../../../Core/Api/Api';
import ValidationErrorResultType from '../../../../../Shared/Api/response/ValidationErrorResultType';

export function useGetProfile(customerId: string | undefined) {
  const { isLoading, response, get } = useApiGet<ICustomerProfileDto>(`/api/customer-portal/profiles/${customerId}`);

  return {
    isLoading,
    profile: response,
    getProfile: get,
  };
}

export interface ICustomerProfileDto {
  id: number;
  name: string;
  customerNumber: number;
  identificationNumber: string;
  invoiceEmail: string;
  invoiceAddress: string;
  invoicePostalCode: string;
  invoiceCity: string;
}

export interface IUpdateProfileCommand {
  name: string;
  invoiceEmail: string;
  invoiceAddress: string;
  invoicePostalCode: string;
  invoiceCity: string;
}

export interface IUpdateProfileValidationErrors extends ValidationErrorResultType<IUpdateProfileCommand> {}

export function useUpdateProfile(customerId: string | undefined) {
  const { isLoading, put } = useApiPut<IUpdateProfileValidationErrors, IUpdateProfileCommand>(
    `/api/customer-portal/profiles/${customerId}`,
  );

  return {
    isLoading: isLoading,
    updateProfile: put,
  };
}
