import React, { useEffect, useMemo } from 'react';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import useHeaderNavigationContext from '../../../Shared/Navigation/Header/HeaderNavigationContext/HeaderNavigationContext';
import { INavigationItem } from '../../../Shared/Navigation/Header/Header';
import { ModuleIdentifiers, AccessRights } from '../../../Core/Authentication/ModuleAccess';
import AuthRoute from '../../../Core/Authentication/AuthRoute/AuthRoute';
import MyCalendar from './MyCalendar/MyCalendar';
import BookingCalendar from './BookingCalendar/BookingCalendar';
import useUserContext from '../../../Core/Authentication/UserContext';
import ActivityWindow from './Activity/ActivityWindow';

export default function BookingRoutes() {
  const { path } = useRouteMatch();
  const { setHeaderNavigationItems, setHeaderNavigationText } = useHeaderNavigationContext();
  const { hasAccess } = useUserContext();

  const hasMeAccess = useMemo(() => hasAccess(ModuleIdentifiers.Booking, AccessRights.Read), [hasAccess]);
  const hasAdminAccess = useMemo(() => hasAccess(ModuleIdentifiers.Booking, AccessRights.Admin), [hasAccess]);

  useEffect(() => {
    const navigationItems: INavigationItem[] = [];

    if (hasAdminAccess) {
      navigationItems.push({
        title: 'Bokning',
        to: `${path}/admin`,
        exact: true,
      });
    }

    if (hasMeAccess) {
      navigationItems.push({
        title: 'Min kalender',
        to: `${path}/me`,
        exact: true,
      });
    }

    setHeaderNavigationText(navigationItems);
    setHeaderNavigationItems(navigationItems);

    return () => {
      setHeaderNavigationItems([]);
    };
  }, [hasAdminAccess, hasMeAccess, path, setHeaderNavigationItems, setHeaderNavigationText]);

  const redirectPath = hasAdminAccess ? 'admin' : 'me';

  return (
    <Switch>
      <AuthRoute exact path={`${path}/me`} module={ModuleIdentifiers.Booking}>
        <h1 className={'sr-only'}>Min kalender</h1>
        <MyCalendar />
      </AuthRoute>
      <AuthRoute exact path={`${path}/admin`} module={ModuleIdentifiers.Booking}>
        <h1 className={'sr-only'}>Bokning</h1>
        <BookingCalendar />
      </AuthRoute>
      <AuthRoute exact path={`${path}/activitywindow/:id/:activityId`} module={ModuleIdentifiers.Booking}>
        <h1 className={'sr-only'}>Bokning</h1>
        <ActivityWindow />
      </AuthRoute>
      <Route path={[path, `${path}/*`]}>
        <Redirect to={`${path}/${redirectPath}`} />
      </Route>
    </Switch>
  );
}
