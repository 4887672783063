import React from 'react';
import Done from '@material-ui/icons/Done';
import { Typography, Link } from '@material-ui/core';
import { useStyles } from './Success.styles';

interface IProps {
  title: string;
  content: string;
  email?: string;
  phonenumber?: string;
  companyLogoBase64?: string;
}

const Success = (props: IProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <Done fontSize="large" className={classes.icon} aria-hidden="true" />
        <Typography variant="h2" component="h1">
          {props.title}
        </Typography>
      </header>
      <Typography>{props.content}</Typography>
      <footer className={classes.footer}>
        {props.companyLogoBase64 ? <img className={classes.logo} src={props.companyLogoBase64} alt="logo" /> : ''}
        <div className={classes.contact}>
          <Link href={'mailto:' + props.email}>{props.email}</Link>
          <Typography>{props.phonenumber}</Typography>
        </div>
      </footer>
    </div>
  );
};

export default Success;
