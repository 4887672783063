import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    height: '100%',
  },
  children: {
    padding: '1rem',
  },
  actions: {
    '&:not(:empty)': {
      display: 'flex',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
      padding: '0.25rem 0 0.25rem 0.5rem',
    },
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    padding: '1rem 1rem ',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    height: '1.5rem',
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.contrastText,
      cursor: 'pointer',
    }
  },
  label: {
    color: theme.palette.primary.contrastText,
  },
  subheader: {
    textTransform: 'capitalize',
  },
}));
