import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../Styles/Theme';

export const useStyles = makeStyles({
  root: {
    // border: `2px solid ${theme.palette.grey[500]}`,
    '& > td': {
      fontWeight: 700,
    },
  },
});
