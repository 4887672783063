import React, { useMemo } from 'react';
import { Checkbox, FormControlLabel, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Fieldset } from '../../../../../../../Shared/Form/Fieldset/Fieldset';
import { IPriceItem, IPricesDto, PriceLabels } from '../../Offer.api';
import { calculateInstallationCost, calculateRent, calculateTotalPrice } from './priceCalculations';

export interface IPriceChange {
  key: IPriceItem;
  label: string;
  value: number;
  originalValue: number;
}

const PriceChanges: React.FunctionComponent<{
  originaInstallationRate: number;
  originalInstallationTime: number;
  originalMaterialCost: number;
  prices: IPricesDto;
  confirmChanges: boolean;
  setConfirmChanges: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props) => {
  const { originaInstallationRate, originalInstallationTime, originalMaterialCost, prices } = props;

  const changes = useMemo<IPriceChange[]>(() => {
    const originalInstallationCost = calculateInstallationCost(originalInstallationTime, originaInstallationRate);
    const originalTotalCost = calculateTotalPrice(originalMaterialCost, originalInstallationCost, prices.otherCost);
    const originalRent = calculateRent(originalTotalCost, prices.firstInstallationCost);
    return [
      {
        key: 'installationRate',
        label: PriceLabels['installationRate'],
        value: prices.installationRate,
        originalValue: originaInstallationRate,
      },
      {
        key: 'installationTime',
        label: `${PriceLabels['installationTime']} (Ber 2)`,
        value: prices.installationTime,
        originalValue: originalInstallationTime,
      },
      {
        key: 'materialCost',
        label: PriceLabels['materialCost'],
        value: prices.materialCost,
        originalValue: originalMaterialCost,
      },
      {
        key: 'installationCost',
        label: PriceLabels['installationCost'],
        value: prices.installationCost,
        originalValue: originalInstallationCost,
      },
      {
        key: 'totalPrice',
        label: PriceLabels['totalPrice'],
        value: prices.totalPrice,
        originalValue: originalTotalCost,
      },
      { key: 'rentPrice', label: PriceLabels['rentPrice'], value: prices.rentPrice, originalValue: originalRent },
    ];
  }, [originaInstallationRate, originalInstallationTime, originalMaterialCost, prices]);

  return (
    <>
    {changes.some((x) => x.value !== x.originalValue) && (
      <>
        <Fieldset legend="Ändringar">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Fält</TableCell>
                <TableCell>Uträknat värde</TableCell>
                <TableCell>Nytt värde</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {changes.map(
                (x) =>
                  x.value !== x.originalValue && (
                    <TableRow key={x.key}>
                      <TableCell>{x.label}</TableCell>
                      <TableCell>
                        {x.originalValue} {x.key === 'installationTime' ? 'h' : 'SEK'}
                      </TableCell>
                      <TableCell>
                        {x.value} {x.key === 'installationTime' ? 'h' : 'SEK'}
                      </TableCell>
                    </TableRow>
                  ),
              )}
            </TableBody>
          </Table>
        </Fieldset>
        <FormControlLabel
          control={
            <Checkbox
              name="checkedB"
              checked={props.confirmChanges}
              onChange={() => props.setConfirmChanges((x) => !x)}
              color="primary"
            />
          }
          label="Jag godkänner ändringarna"
        />
      </>)} 
    </>
  );
};

export default PriceChanges;
