import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  white: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
  },
  MobileIcon: {
    border: "2px solid #fff",
    borderRadius: "50%",
    padding: '7px',
    marginLeft: '-12px',
    fontSize: '1.25rem!important',
  }
}));
