import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  useUpsertProduct,
  IUpsertProductValidationErrors,
  IUpsertProductCommand,
  useGetProductForm,
  useGetProductBySupplier,
  useDeleteProductBySupplier,
  IProductDto,
} from '../Products.api';
import useFormValidation from '../../../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import useInputState from '../../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { isResultError } from '../../../../../../../../Shared/Api/response/ICreateResult';
import Button from '../../../../../../../../Shared/Button/Button';
import Container from '../../../../../../../../Shared/Container/Container';
import { Fieldset } from '../../../../../../../../Shared/Form/Fieldset/Fieldset';
import { InputField } from '../../../../../../../../Shared/InputFields/InputField/InputField';
import Select, { ISelectOption } from '../../../../../../../../Shared/Select/Select';
import UploadInput, { IUploadFile } from '../../../../../../../../Shared/InputFields/UploadInput/UploadInput';
import { Radio, InputAdornment, FormControlLabel, Dialog, DialogContent, Checkbox } from '@material-ui/core';
import Datepicker from '../../../../../../../../Shared/InputFields/DatePicker/Datepicker';
import RadioField from '../../../../../../../../Shared/InputFields/RadioField/RadioField';
import { round } from '../../../../../../../../Shared/Misc/MathHelper';
import { DialogContentHeader } from '../../../../../../../../Shared/DialogContentHeader/DialogContentHeader';
import DialogForm from '../../../../../../../../Shared/DialogForm/DialogForm';
import { ErrorCode, isErrorResponse } from '../../../../../../../../Shared/Api/response/IErrorRespose';
import useErrorMessage from '../../../../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';

interface ISystemTypeSelectOption extends ISelectOption {
  regulations: ISelectOption[];
}

function calculateNetPrice(purchasePrice: number, netPriceInPercent: number) {
  return round(purchasePrice * (netPriceInPercent / 100));
}

function calculateNetPriceInPercent(purchasePrice: number, netPrice: number) {
  return round((netPrice / purchasePrice) * 100);
}

const ProductForm: React.FunctionComponent = () => {
  const history = useHistory();
  const { supplierId, id } = useParams<{ supplierId: string; id: string }>();
  const { isGetProductFormLoading, getProductForm } = useGetProductForm(supplierId);
  const { isGetProductLoading, getProduct } = useGetProductBySupplier(supplierId);
  const { isUpsertLoading, upsert } = useUpsertProduct(supplierId);
  const { isFormValid, formRef } = useFormValidation();

  const [systemTypes, setSystemTypes] = useState<ISystemTypeSelectOption[]>([]);
  const [productCategories, setProductCategories] = useState<ISelectOption[]>([]);
  const [mainProducts, setMainProducts] = useState<ISelectOption[]>([]);
  const [childProducts, setChildProducts] = useState<ISelectOption[]>([]);

  const [originalName, setOriginalName] = useState('');
  const [selectedSystemType, setSelectedSystemType] = useState<ISelectOption | undefined | null>(null);
  const [selectedRegulation, setSelectedRegulation] = useState<ISelectOption | undefined | null>(null);
  const [selectedProductCategory, setSelectedProductCategory] = useState<ISelectOption | undefined | null>(null);

  const [isMainProduct, setIsMainProduct] = useState(false);
  const [selectedMainProducts, setSelectedMainProducts] = useState<ISelectOption[]>([]);
  const [selectedChildProducts, setSelectedChildProducts] = useState<ISelectOption[]>([]);
  const [name, changeName, setName] = useInputState('');
  const [description, changeDescription, setDescription] = useInputState('');
  const [productNumber, changeProductNumber, setProductNumber] = useInputState('');
  const [purchasePrice, changePurchasePrice, setPurchasePrice] = useInputState('0');
  const [netPrice, changeNetPrice, setNetPrice] = useInputState('0');
  const [netPriceInPercent, changeNetPriceInPercent, setNetPriceInPercent] = useInputState('0');
  const [price, changePrice, setPrice] = useInputState(0);
  const [resellerPrice, changeResellerPrice, setResellerPrice] = useInputState(0);

  const [numberOfSections, changeNumberOfSections, setNumberOfSections] = useInputState<number | undefined>(undefined);
  const [numberOfBuses, changeNumberOfBuses, setNumberOfBuses] = useInputState<number | undefined>(undefined);
  const [numberOfInputs, changeNumberOfInputs, setNumberOfInputs] = useInputState<number | undefined>(undefined);
  const [numberOfoutputs, changeNumberOfOutputs, setNumberOfOutputs] = useInputState<number | undefined>(undefined);
  const [certifiedTo, setCertifiedTo] = useState<Date | null>(null);
  const [idlePowerConsumption, changeIdlePowerConsumption, setIdlePowerConsumption] = useInputState('0');
  const [activePowerConsumption, changeActivePowerConsumption, setActivePowerConsumption] = useInputState('0');
  const [installationTime, changeInstallationTime, setInstallationTime] = useInputState<number | undefined>(undefined);
  const [installationCost, changeInstallationCost, setInstallationCost] = useInputState<number | undefined>(undefined);
  const [installationPrice, changeInstallationPrice, setInstallationPrice] = useInputState<number | undefined>(
    undefined,
  );
  const [productImage, setProductImage] = useState<IUploadFile>();
  const [productPdf, setProductPfd] = useState<IUploadFile>();
  const [supplierName, setSupplierName] = useState<string>();
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const [errors, setErrors] = useState<IUpsertProductValidationErrors>();
  const [confirmDeleteProduct, setConfirmDeleteProduct] = useState<boolean>(false);
  const { isLoading: isDeleteLoading, deleteProduct } = useDeleteProductBySupplier(id);
  const { setErrorMessage } = useErrorMessage();

  useEffect(() => {
    async function setData(
      product: IProductDto,
      mappedSystemTypes: ISystemTypeSelectOption[],
      mappedProductCategories: ISelectOption[],
      mappedMainProducts: ISelectOption[],
      mappedChildProducts: ISelectOption[],
    ) {
      setOriginalName(product.name);

      const systemType = mappedSystemTypes.find((x) => x.value === product.systemTypeId.toString());
      setSelectedSystemType(systemType);
      setSelectedRegulation(systemType?.regulations.find((x) => x.value === product.regulationId.toString()));
      setSelectedProductCategory(mappedProductCategories.find((x) => x.value === product.productCategoryId.toString()));

      setIsMainProduct(product.isMainProduct);

      if (product.mainProductIds) {
        const localSelectedMainProducts: ISelectOption[] = [];

        product.mainProductIds.forEach((x) => {
          const mainProduct = mappedMainProducts.find((m) => m.value === x.toString());
          if (mainProduct) localSelectedMainProducts.push(mainProduct);
        });

        setSelectedMainProducts(localSelectedMainProducts);
      }
      if (product.childProductIds) {
        const localSelectedChildProducts: ISelectOption[] = [];

        product.childProductIds.forEach((x) => {
          const childProduct = mappedChildProducts.find((m) => m.value === x.toString());
          if (childProduct) localSelectedChildProducts.push(childProduct);
        });

        setSelectedChildProducts(localSelectedChildProducts);
      }

      setName(product.name);
      setDescription(product.description);
      setProductNumber(product.productNumber);
      setPurchasePrice(product.purchasePrice.toString());
      setNetPrice(product.netPrice.toString());
      setPrice(product.price);
      setResellerPrice(product.resellerPrice);
      setNetPriceInPercent(calculateNetPriceInPercent(product.purchasePrice, product.netPrice).toString());

      setNumberOfSections(product.numberOfSections);
      setNumberOfBuses(product.numberOfBuses);
      setNumberOfInputs(product.numberOfInputs);
      setNumberOfOutputs(product.numberOfOutputs);

      setCertifiedTo(product.certifiedTo ? new Date(product.certifiedTo) : null);

      setIdlePowerConsumption(product.idlePowerConsumption?.toString() ?? '');
      setActivePowerConsumption(product.activePowerConsumption?.toString() ?? '');
      setInstallationTime(product.installationTime);
      setInstallationCost(product.installationCost);
      setInstallationPrice(product.installationPrice);
      setProductImage(product.productImage);
      setProductPfd(product.productPdf);
      setSupplierName(product.supplierName);
      setIsVisible(product.visible);
    }

    async function getForm() {
      if (!supplierId) {
        history.push('admin/supplier/');
        return;
      }

      const formResultPromise = getProductForm();
      let productPromise = id ? getProduct(id) : null;

      const formResult = await formResultPromise;

      const mappedSystemTypes = formResult?.systemTypes.map((x) => ({
        value: x.id.toString(),
        label: x.name,
        regulations: x.regulations.map((r) => ({ value: r.id.toString(), label: r.name })),
      }));
      setSystemTypes(mappedSystemTypes);

      const mappedProductCategories = formResult?.productCategories.map((x) => ({
        value: x.id.toString(),
        label: x.name,
      }));
      const otherCategory = mappedProductCategories.find((x) => x.value.toLocaleLowerCase().includes('övrigt'));
      if (otherCategory === null) {
        mappedProductCategories.push({ value: '0', label: 'Övrigt' });
      }

      setProductCategories(mappedProductCategories);

      const mappedMainProducts = formResult?.mainProducts
        .map((x) => ({ value: x.id.toString(), label: x.name }))
        .filter((x) => x.value !== id);
      setMainProducts(mappedMainProducts);

      const mappedChildProducts = formResult?.childProducts
        .map((x) => ({ value: x.id.toString(), label: x.name }))
        .filter((x) => x.value !== id);
      setChildProducts(mappedChildProducts);

      if (productPromise) {
        const product = await productPromise;
        setData(product, mappedSystemTypes, mappedProductCategories, mappedMainProducts, mappedChildProducts);
      }
    }

    getForm();
  }, [
    getProduct,
    getProductForm,
    history,
    id,
    setActivePowerConsumption,
    setDescription,
    setIdlePowerConsumption,
    setInstallationCost,
    setInstallationPrice,
    setInstallationTime,
    setName,
    setNetPrice,
    setNetPriceInPercent,
    setNumberOfBuses,
    setNumberOfInputs,
    setNumberOfOutputs,
    setNumberOfSections,
    setPrice,
    setProductNumber,
    setPurchasePrice,
    setResellerPrice,
    setProductImage,
    setProductPfd,
    setIsVisible,
    supplierId,
  ]);

  async function upsertProduct() {
    if (!selectedSystemType || !selectedRegulation || !selectedProductCategory) {
      return;
    }

    const command: IUpsertProductCommand = {
      systemTypeId: parseInt(selectedSystemType.value),
      regulationId: parseInt(selectedRegulation.value),
      productCategoryId: parseInt(selectedProductCategory.value),
      isMainProduct: isMainProduct,
      isVisible: isVisible,
      mainProductIds: selectedMainProducts?.map((x) => parseInt(x.value)),
      childProductIds: selectedChildProducts?.map((x) => parseInt(x.value)),
      name: name,
      description: description,
      productNumber: productNumber,

      purchasePrice: Number(purchasePrice),
      netPrice: Number(netPrice),
      price: price,
      resellerPrice: resellerPrice,

      numberOfSections: numberOfSections,
      numberOfBuses: numberOfBuses,
      numberOfInputs: numberOfInputs,
      numberOfOutputs: numberOfoutputs,

      certifiedTo: certifiedTo,

      idlePowerConsumption: Number(idlePowerConsumption),
      activePowerConsumption: Number(activePowerConsumption),

      installationTime: installationTime,
      installationCost: installationCost,
      installationPrice: installationPrice,

      productImage: productImage,
      productPdf: productPdf,
    };

    const result = await upsert(command, id);

    isResultError(result) ? setErrors(result) : history.goBack();
  }

  async function handleDelete() {
    const result = await deleteProduct(id);
    if (isErrorResponse(result) && result.errorCode === ErrorCode.EntityInUse) {
      setErrorMessage({ message: 'Gick inte att ta bort produkten då den används' });
    } else {
      history.goBack();
    }
  }

  function onPurchasePriceChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const value = changePurchasePrice(e);

    if (netPriceInPercent && value) {
      setNetPrice(calculateNetPrice(Number(value), Number(netPriceInPercent)).toString());
    }
  }

  function onNetPriceChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const value = changeNetPrice(e);
    if (purchasePrice && value) {
      setNetPriceInPercent(calculateNetPriceInPercent(Number(purchasePrice), value).toString());
    }
  }

  function onNetPriceInPercentChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const value = changeNetPriceInPercent(e);
    const purchasePriceNum = Number(purchasePrice);

    if (purchasePrice && value) {
      setNetPrice(round((value / 100) * purchasePriceNum).toString());
    }
  }

  function Actions() {
    return (
      <>
        <Button
          color="default"
          variant="outlined"
          onClick={() => {
            setConfirmDeleteProduct(true);
          }}
        >
          Ta bort
        </Button>
        <Button color="default" variant="outlined" onClick={() => history.goBack()}>
          Avbryt
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={upsertProduct}
          loading={isUpsertLoading}
          disabled={
            !isFormValid ||
            !selectedSystemType ||
            !selectedRegulation ||
            !selectedProductCategory ||
            isGetProductFormLoading ||
            isGetProductLoading
          }
        >
          {id ? 'Spara' : 'Skapa'}
        </Button>
      </>
    );
  }

  return (
    <Container
      form
      label={id ? `Redigera ${originalName} ${!isVisible ? '(arkiverad)' : ''}` : 'Skapa produkt'}
      actions={<Actions />}
      ref={formRef}
      loading={isGetProductFormLoading || isGetProductLoading}
    >
      <InputField label="Leverantör" value={supplierName} viewOnly customWidth={3} />
      <Fieldset legend="Produktgruppering">
        <Select
          inputFieldProps={{ label: 'Systemtyp', required: true, errorText: errors?.systemTypeId }}
          selectProps={{
            value: selectedSystemType,
            onChange: (selectedValue) => {
              setSelectedSystemType(selectedValue as ISelectOption);
              setSelectedRegulation(
                systemTypes.find((x) => x.value === (selectedValue as ISelectOption).value)?.regulations[0],
              );
            },
            options: systemTypes,
            isClearable: true,
          }}
        />
        <Select
          inputFieldProps={{ label: 'Regelverk', required: true, errorText: errors?.regulationId }}
          selectProps={{
            isClearable: true,
            value: selectedRegulation,
            options: systemTypes.find((x) => x.value === selectedSystemType?.value)?.regulations,
            onChange: (selectedValue) => {
              setSelectedRegulation(selectedValue as ISelectOption);
            },
          }}
        />

        <RadioField label="Produkttyp">
          <FormControlLabel
            label="Huvudprodukt"
            control={<Radio color="primary" />}
            checked={isMainProduct}
            onChange={() => {
              setIsMainProduct(true);
              setSelectedMainProducts([]);
            }}
          />
          <FormControlLabel
            label="Produkt"
            control={<Radio color="primary" />}
            checked={!isMainProduct}
            onChange={() => setIsMainProduct(false)}
          />
        </RadioField>
        {isMainProduct && (
          <Select
            inputFieldProps={{ label: 'Tillgängliga underprodukter', errorText: errors?.childProductIds }}
            selectProps={{
              isClearable: true,
              isMulti: true,
              value: selectedChildProducts,
              options: childProducts,
              onChange: (selectedValues) => setSelectedChildProducts(selectedValues as ISelectOption[]),
            }}
          />
        )}
        <RadioField label="Arkivera">
          <FormControlLabel
            label="Arkiverad"
            control={<Checkbox color="primary" />}
            checked={!isVisible}
            onChange={() => setIsVisible(!isVisible)}
          />
        </RadioField>
        {!isMainProduct && (
          <Select
            inputFieldProps={{ label: 'Tillhör huvudprodukt', errorText: errors?.mainProductIds }}
            selectProps={{
              isClearable: true,
              isMulti: true,
              value: selectedMainProducts,
              options: mainProducts,
              onChange: (selectedValues) => setSelectedMainProducts(selectedValues as ISelectOption[]),
            }}
          />
        )}
      </Fieldset>
      <Fieldset legend="Produktinformation">
        <InputField
          label="Benämning"
          type="text"
          required
          value={name}
          onChange={changeName}
          errorText={errors?.name}
        />
        <InputField
          label="Produktnummer"
          type="text"
          required
          value={productNumber}
          onChange={changeProductNumber}
          errorText={errors?.productNumber}
        />
        <InputField
          label="Beskrivning"
          type="text"
          required
          value={description}
          onChange={changeDescription}
          errorText={errors?.description}
        />
        <Select
          inputFieldProps={{ label: 'Produktkategori', required: true, errorText: errors?.productCategoryId }}
          selectProps={{
            value: selectedProductCategory,
            onChange: (selectedValue) => {
              setSelectedProductCategory(selectedValue as ISelectOption);
            },
            options: productCategories,
            isClearable: true,
          }}
        />
      </Fieldset>
      <Fieldset legend="Productspecifikation">
        <InputField
          label="Antal sektioner"
          type="number"
          value={numberOfSections}
          onChange={changeNumberOfSections}
          errorText={errors?.numberOfSections}
        />
        <InputField
          label="Antal bussar"
          type="number"
          value={numberOfBuses}
          onChange={changeNumberOfBuses}
          errorText={errors?.numberOfBuses}
        />
        <InputField
          label="Antal ingångar"
          type="number"
          value={numberOfInputs}
          onChange={changeNumberOfInputs}
          errorText={errors?.numberOfInputs}
        />
        <InputField
          label="Antal utgångar"
          type="number"
          value={numberOfoutputs}
          onChange={changeNumberOfOutputs}
          errorText={errors?.numberOfOutputs}
        />
        <Datepicker label="Certifierad till" selected={certifiedTo} onChange={setCertifiedTo} />
      </Fieldset>
      <Fieldset legend="Strömförsörjning">
        <InputField
          label="Vila"
          type="number"
          value={idlePowerConsumption}
          onChange={changeIdlePowerConsumption}
          errorText={errors?.idlePowerConsumption}
          endAdornment={<InputAdornment position="end">mA</InputAdornment>}
          inputProps={{step: 0.1, inputMode: "decimal"}}
        />
        <InputField
          label="Larm"
          type="number"
          value={activePowerConsumption}
          inputProps={{step: 0.1, inputMode: "decimal"}}
          onChange={changeActivePowerConsumption}
          errorText={errors?.activePowerConsumption}
          endAdornment={<InputAdornment position="end">mA</InputAdornment>}
        />
      </Fieldset>
      <Fieldset legend="Priser">
        <InputField
          label="Grundnettopris"
          type="number"
          inputProps={{ step: 0.01, inputMode: 'decimal' }}
          required
          value={purchasePrice}
          onChange={onPurchasePriceChanged}
          errorText={errors?.purchasePrice}
          endAdornment={<InputAdornment position="end">SEK</InputAdornment>}
        />
        <InputField
          label="Kundpris"
          type="number"
          inputProps={{ step: 0.01, inputMode: 'decimal' }}
          required
          value={price}
          onChange={changePrice}
          errorText={errors?.price}
          endAdornment={<InputAdornment position="end">SEK</InputAdornment>}
        />
        <InputField
          label="Inköpspris"
          type="number"
          inputProps={{ step: 0.01, inputMode: 'decimal' }}
          required
          value={netPrice}
          onChange={onNetPriceChanged}
          errorText={errors?.netPrice}
          endAdornment={<InputAdornment position="end">SEK</InputAdornment>}
        />
        <InputField
          label="Inköpspris (%)"
          type="number"
          inputProps={{ step: 0.01, inputMode: 'decimal' }}
          value={netPriceInPercent}
          onChange={onNetPriceInPercentChanged}
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
        />
        <InputField
          label="Återförsäljarpris"
          type="number"
          required
          value={resellerPrice}
          onChange={changeResellerPrice}
          errorText={errors?.resellerPrice}
          endAdornment={<InputAdornment position="end">SEK</InputAdornment>}
        />
      </Fieldset>
      <Fieldset legend="Installation">
        <InputField
          label="Monteringstid"
          type="number"
          value={installationTime}
          onChange={changeInstallationTime}
          errorText={errors?.installationTime}
          endAdornment={<InputAdornment position="end">h</InputAdornment>}
        />
        <InputField
          label="Installations kostnad"
          type="number"
          value={installationCost}
          onChange={changeInstallationCost}
          errorText={errors?.installationCost}
          endAdornment={<InputAdornment position="end">SEK</InputAdornment>}
        />
        <InputField
          label="Installations pris"
          type="number"
          value={installationPrice}
          onChange={changeInstallationPrice}
          errorText={errors?.installationPrice}
          endAdornment={<InputAdornment position="end">SEK</InputAdornment>}
        />
      </Fieldset>
      <Fieldset legend="Filer">
        <UploadInput type="image" label="Bild" value={productImage?.name} onFileChange={setProductImage} />
        <UploadInput type="pdf" label="Pdf" value={productPdf?.name} onFileChange={setProductPfd} />
      </Fieldset>
      <Dialog open={confirmDeleteProduct}>
        <DialogContent>
          <DialogContentHeader
            onClose={() => setConfirmDeleteProduct(false)}
            title={'Är du säker på att du vill ta bort denna produkt?'}
            titleId=""
          />
          <DialogForm
            CustomWidth={100}
            actions={
              <>
                <Button variant="outlined" onClick={() => setConfirmDeleteProduct(false)}>
                  Avbryt
                </Button>
                <Button color="primary" variant="contained" onClick={handleDelete} loading={isDeleteLoading}>
                  Radera
                </Button>
              </>
            }
          >
            {}
          </DialogForm>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default ProductForm;
