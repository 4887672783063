import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import useUserContext from '../../../../../Core/Authentication/UserContext';
import Select, { ISelectOption } from '../../../../../Shared/Select/Select';
import { InputField } from '../../../../../Shared/InputFields/InputField/InputField';
import Datepicker from '../../../../../Shared/InputFields/DatePicker/Datepicker';
import Button from '../../../../../Shared/Button/Button';
import { DialogContentHeader } from '../../../../../Shared/DialogContentHeader/DialogContentHeader';
import DialogForm from '../../../../../Shared/DialogForm/DialogForm';
import useInputState from '../../../../../Shared/Hooks/UseInputState/UseInputState';
import { addTimeToDate, validTime } from '../../../../Shared/Calendars/Components/dateFunctions';
import { isErrorResponse } from '../../../../../Shared/Api/response/IErrorRespose';
import useErrorMessage from '../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import CompanyUserSelect from '../../../Admin/Components/Users/UserSelect/CompanyUserSelect';
import { getErrorMessage } from '../../../Booking/Booking.api';
import { useBookInstallationOnService } from '../../Forum.api';
import { useStyles } from './BookOnService.styles';
import { useGetFacility } from '../../../Customers/Components/Facilities/Facilities.api';
import { IFacility } from '../../../Errands/Components/Sale.api';
import Link from '../../../../../Shared/Link/Link';
import { ModuleIdentifiers, AccessRights } from '../../../../../Core/Authentication/ModuleAccess';
import { ViewField } from '../../../../../Shared/InputFields/InputField/ViewField';

const defaultDate = new Date();

export interface IBookOnOrderProps {
  title: string;
  serviceId: number;
  customerId: number;
  customerName?: string;
  facilityId: number;
  description?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSave?: () => void;
}

const BookOnService = (props: IBookOnOrderProps) => {
  const classes = useStyles();
  const { title, serviceId, open, setOpen, onSave } = props;
  const { user } = useUserContext();

  const { getFacility, isLoading: isGetFacilityLoading } = useGetFacility(props.customerId.toString());
  const { post } = useBookInstallationOnService(serviceId);
  const { setErrorMessage } = useErrorMessage();

  const [facility, setFacility] = useState<IFacility>();
  const [description, changeDescription] = useInputState<string>('Serviceärende');
  const [jobDescription, changeJobDescription] = useInputState<string>(props.description ?? '');
  const [mainInstaller, setMainInstaller] = useState<ISelectOption>();
  const [coreSystemOptions, setCoreSystemOptions] = useState<ISelectOption[]>([]);
  const [coreSystemId, setCoreSystemId] = useState<number>();
  const [totalPrice, changeTotalPrice] = useInputState(0);
  const [date, setDate] = useState<Date>(defaultDate);
  const [startTime, changeStartTime] = useInputState<string>('00:00');
  const [endTime, changeEndTime] = useInputState<string>('00:00');
  const titleWithoutSpaces = title.replace(/\s/g, '');

  useEffect(() => {
    if (user && !mainInstaller) {
      const currentUser = { value: user.selectedUserId?.toString() ?? '', label: user.username };
      setMainInstaller(currentUser);
    }
  }, [mainInstaller, user]);

  useEffect(() => {
    async function loadCoreSystems() {
      const response = await getFacility(props.facilityId.toString());
      if (response) {
        setFacility(response);
        var options = response.coreSystems.map((x) => ({ value: x.id.toString(), label: x.description }));
        setCoreSystemOptions(options);
        if (options.length === 1) {
          setCoreSystemId(parseInt(options[0].value));
        }
      }
    }
    open && loadCoreSystems();
  }, [open, props.facilityId, getFacility]);

  async function handleSave() {
    if (!mainInstaller) return;
    const command = {
      description: description,
      jobDescription: jobDescription,
      coreSystemId: coreSystemId,
      totalPrice: totalPrice,
      companyUserId: Number(mainInstaller.value),
      startDate: addTimeToDate(date, startTime),
      endDate: addTimeToDate(date, endTime),
    };
    const result = await post(command);
    if (isErrorResponse(result)) {
      setErrorMessage({ message: getErrorMessage(result, true) });
    } else {
      onSave && onSave();
    }
    setOpen(false);
  }

  function changeDate(d: Date | null) {
    d && setDate(d);
  }

  function valid() {
    return mainInstaller && jobDescription && bookTimeValid();
  }

  function bookTimeValid() {
    return (
      startTime &&
      endTime &&
      date &&
      validTime(startTime) &&
      validTime(endTime) &&
      addTimeToDate(date, endTime) >= addTimeToDate(date, startTime)
    );
  }

  const Actions = () => (
    <>
      <Button color="default" variant="outlined" onClick={() => setOpen(false)}>
        Avbryt
      </Button>
      <Button className={classes.save} color="primary" variant="contained" disabled={!valid()} onClick={handleSave}>
        Spara
      </Button>
    </>
  );

  return (
    <Dialog open={open} onClose={props.setOpen} aria-labelledby={titleWithoutSpaces}>
      <DialogContent>
        <DialogContentHeader onClose={() => setOpen(false)} title={title} titleId={titleWithoutSpaces} />

        <DialogForm actions={<Actions />}>
          <ViewField fullwidth>
            <Link
              module={ModuleIdentifiers.Service}
              accessRights={AccessRights.Write}
              to={`/customers/${props.customerId}/facility/${facility?.id}`}
            >
              {props.customerName + ' - ' + facility?.name}
            </Link>
          </ViewField>         
          <Select
            inputFieldProps={{ label: 'System', required: false, fullWidth: true}}            
            selectProps={{
              isClearable: true,
              value: coreSystemOptions.find((x) => x.value === coreSystemId?.toString()) || null,
              isLoading: isGetFacilityLoading,
              isDisabled: !coreSystemOptions.length,
              options: coreSystemOptions,
              onChange: (selected) => setCoreSystemId(selected ? Number((selected as ISelectOption).value) : undefined),
            }}
          />
          <InputField label="Totalt pris" type="number" value={totalPrice} onChange={changeTotalPrice} />
          <CompanyUserSelect
            label="Installatör"
            isClearable
            required
            placeholder="Installatör"
            selectedCompanyUser={mainInstaller}
            onChange={(_1, _2, option) => setMainInstaller(option ? (option as ISelectOption) : undefined)}
          />
          <Datepicker required label="Datum" selected={date} onChange={changeDate} />
          <Grid container justify="center">
            <Grid item className={classes.time}>
              <InputField required type="time" label="Från" value={startTime} onChange={changeStartTime} />
            </Grid>
            <Grid item className={classes.time}>
              <InputField required type="time" label="Till" value={endTime} onChange={changeEndTime} />
            </Grid>
          </Grid>
          <InputField
            label="Rubrik"
            fullwidth
            type="text"
            value={description}
            onChange={changeDescription}
          />        
          <InputField
            required
            label="Uppdragsbeskrivning"
            fullwidth
            type="text"
            multiline
            rows="5"
            value={jobDescription}
            onChange={changeJobDescription}
          />
        </DialogForm>
      </DialogContent>
    </Dialog>
  );
};

export default BookOnService;
