import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './MyPagesLogin.styles';
import { useHistory } from 'react-router-dom';
import Container from '../../../../Shared/Container/Container';
import Button from '../../../../Shared/Button/Button';
import useInputState from '../../../../Shared/Hooks/UseInputState/UseInputState';
import { useCreateCustomerCode } from '../MyPages.api';
import { ErrorCode, isErrorResponse } from '../../../../Shared/Api/response/IErrorRespose';
import EmailField from '../../../../Shared/InputFields/EmailField/EmailField';

const MyPagesLogin = () => {
  const classes = useStyles();
  const history = useHistory();
  const [codeCreated, changeCreateCode] = useState<boolean>(false);
  const [email, changeEmail] = useInputState('');

  const { isLoading, createCode } = useCreateCustomerCode();
  const company = { name: '' }; //  We need to somehow read a querystring in the url or something to get company brandlogin, to be able to customize the looks on this component
  const regexString = new RegExp(/^[^\s@]+@[^\s@]+\.[a-z]{2,}$/i);
  async function createCustomerCode() {
    const result = await createCode({ email });

    if (isErrorResponse(result) && result.errorCode === ErrorCode.AnonymousTokenNotFound) {
      history.push(`${history.location.pathname}/404`);
    }
    changeCreateCode(true);
  }

  function checkMail(): boolean {
    // eslint-disable-next-line no-useless-escape
    return regexString.test(email);
  }

  function Actions() {
    return (
      <>
        {checkMail() && (
          <Button color="primary" variant="contained" onClick={createCustomerCode} loading={isLoading}>
            Skicka kod
          </Button>
        )}
      </>
    );
  }
  return (
    <>
      <Grid container spacing={4} justify={'center'} alignContent={'center'} alignItems={'center'}>
        <Grid item sm={12} md={4} className={classes.loginContainer}>
          <h2>
            Välkommen till <span>{company.name}</span> kundservice
          </h2>
          <p>
            Här kan du:
            <br />
            - se och hantera dina kunduppgifter
            <br />
            - se och redigera dina användare
            <br />
            - se och redigera larmhändelser
            <br />
          </p>
          <h2>Logga in</h2>
          <p>Steg 1: skriv in din mejladress</p>
          <p>Steg 2: skriv in den kod du fick skickat till din mejl</p>
          <Container label={'Login'} actions={<Actions />} customSize={{ fullsize: true }}>
            <EmailField label={'Email'} fullwidth required value={email} onChange={changeEmail} />
            {codeCreated && <p>Ett mail har skickats till det angivna mailet.</p>}
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default MyPagesLogin;
