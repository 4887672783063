import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.black,
    textDecoration: 'none',
    [theme.breakpoints.up('sm')]: {
      outlineColor: theme.palette.primary.contrastText,
      '&:first-child': {
        marginTop: '1rem',
      },
      '&:focus': {
        '& > div': {
          backgroundColor: theme.palette.grey[300],
        },
      },
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      padding: '1rem',
      '&:focus': {
        borderColor: theme.palette.common.white,
        outline: 0,
      },
    },
  },
  listItem: {
    [theme.breakpoints.up('sm')]: {
      width: '6rem',
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.white,
    [theme.breakpoints.up('sm')]: {
      color: theme.palette.grey[800],
      padding: '1rem',
      marginBottom: '0.25rem',
      borderRadius: theme.shape.borderRadius,
      border: `2px solid ${theme.palette.grey[200]}`,
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: '1rem',
    },
  },
  label: {
    [theme.breakpoints.down('xs')]: {
      color: theme.palette.common.white,
    },
  },

  bigLabel: {
    [theme.breakpoints.down('xs')]: {
      color: theme.palette.common.white,
    },
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 1,
    wordBreak: 'break-all',
    display: '-webkit-box',
    marginLeft: 5,
  },

  activeSymbol: {
    display: 'none',
  },
  active: {
    '& > *': {
      [theme.breakpoints.up('sm')]: {
        borderColor: `${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
      },
    },
    '& div:last-child': {
      display: 'none',
      margin: 0,
      [theme.breakpoints.down('xs')]: {
        flexGrow: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        display: 'flex',
      },
    },
  },
  circleIcon: {
    fontSize: '1rem',
    color: theme.palette.primary.light,
  },
}));
