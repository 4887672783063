import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AsyncSelect, MultiAsyncSelect, ISelectOption } from '../../../../../Shared/Select';
import {
  areAllSelected,
  emptyList,
  selectAllList,
  selectAllOption,
} from '../../../../../Shared/Select/SelectMultiChecker';
import { useSearchCustomers } from '../../Customers.api';

export interface IProps {
  isClearable?: boolean;
  required?: boolean;
  isDisabled?: boolean;
  includeProspect?: boolean;

  customerIds?: number[];
  fullwidth?: boolean;
  onChange?: (
    customerId?: string,
    customerNames?: string,
    selectAllOrProspect?: boolean,
    option?: ISelectOption | ISelectOption[],
  ) => void;
}

export interface IPropsMulti extends IProps {
  isMulti: true;
  addSelectAllOption?: boolean;
  selectedCustomer?: ISelectOption;
  selectedCustomerId?: string[];
}

export interface IPropsSingle extends IProps {
  isMulti?: false | undefined;
  addProspectOption?: boolean;
  selectedCustomer?: ISelectOption[];
  selectedCustomerId?: string;
}

type IPropsSelect = IPropsSingle | IPropsMulti;

const selectProspectOption = { label: 'Ny kund', value: '0' };

const CustomerSelect = (props: IPropsSelect) => {
  const { includeProspect, selectedCustomer, selectedCustomerId, customerIds } = props;
  const { searchCustomers, isLoading } = useSearchCustomers();
  const addProspectOption = !props.isMulti && props.addProspectOption;
  const addAllSelectOption = props.isMulti && props.addSelectAllOption;
  const [defaultOptions, setDefaultOptions] = useState<ISelectOption[]>();
  const isAllSelected =
    props.isMulti &&
    addAllSelectOption &&
    areAllSelected(defaultOptions?.length, selectedCustomer as ISelectOption[], selectedCustomerId as string[]);

  const selectedOption = useMemo(() => {
    if (!selectedCustomer) return undefined;
    if (props.isMulti) {
      return Array.isArray(selectedCustomer) ? selectedCustomer : [selectedCustomer];
    } else {
      return Array.isArray(selectedCustomer) ? selectedCustomer[0] : selectedCustomer;
    }
  }, [props.isMulti, selectedCustomer]);

  const selectedOptionId = useMemo(() => {
    if (!selectedCustomerId) return undefined;
    if (props.isMulti) {
      return Array.isArray(selectedCustomerId) ? selectedCustomerId : [selectedCustomerId];
    } else {
      return Array.isArray(selectedCustomerId) ? selectedCustomerId[0] : selectedCustomerId;
    }
  }, [props.isMulti, selectedCustomerId]);

  const loadCustomers = useCallback(
    async (query?: string) => {
      const response = await searchCustomers({
        query: query,
        includeProspect,
        customerIds: query ? undefined : customerIds,
      });
      var list = response?.map((x) => ({ value: x.id.toString(), label: x.name })) ?? [];
      if (addProspectOption) list = [selectProspectOption, ...list];
      if (addAllSelectOption && list.length > 0) list = [selectAllOption, ...list];
      return list;
    },
    [includeProspect, customerIds, addProspectOption, addAllSelectOption, searchCustomers],
  );

  useEffect(() => {
    //Load with no query
    loadCustomers().then((x) => {
      setDefaultOptions(x);
    });
  }, [loadCustomers]);

  if (props.isMulti) {
    return (
      <MultiAsyncSelect
        {...props}
        label="Välj kund"
        isLoading={isLoading}
        selectedOptions={isAllSelected ? [selectAllOption] : (selectedOption as ISelectOption[] | undefined)}
        selectedOptionIds={isAllSelected ? ['0'] : (selectedOptionId as string[] | undefined)}
        defaultOptions={isAllSelected ? emptyList : defaultOptions}
        loadOptions={loadCustomers}
        onChange={(value) => {
          let areAll = defaultOptions && areAllSelected(defaultOptions.length, value as ISelectOption[] | undefined);
          props.onChange && props.onChange(undefined, undefined, areAll, areAll ? selectAllList : value ?? []);
        }}
      />
    );
  }

  return (
    <AsyncSelect
      {...props}
      label="Välj kund"
      isLoading={isLoading}
      selectedOption={selectedOption as ISelectOption | undefined}
      selectedOptionId={selectedOptionId as string | undefined}
      defaultOptions={defaultOptions}
      loadOptions={loadCustomers}
      onChange={(value) => props.onChange && props.onChange(value?.value, value?.label, false, value ?? undefined)}
    />
  );
};
export default CustomerSelect;
