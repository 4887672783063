import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetSystemTypes, IRegulation, useDeleteSystemType, ISystemType } from '../SystemTypes.api';
import { Table } from '../../../../../../Shared/Table/Table';
import TablePaginationWithDatasource from '../../../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import { TableHead, TableRow, TableCell, TableBody, MenuItem, ListItemIcon, Typography } from '@material-ui/core';
import { DropdownCell } from '../../../../../../Shared/Table/DropdownCell/DropdownCell';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import SortButton from '../../../../../../Shared/Table/SortButton/SortButton';
import useTable from '../../../../../../Shared/Table/useTable';
import SearchInput from '../../../../../../Shared/InputFields/SearchInput/SearchInput';
import AccessGuard from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { ModuleIdentifiers, AccessRights } from '../../../../../../Core/Authentication/ModuleAccess';
import { useStyles } from '../../../../../../Shared/Table/TableRowHeader/TableRowHeader.styles';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import IconButton from '../../../../../../Shared/IconButton/IconButton';
import { ConfirmDeleteOrArchiveRow } from '../../../../../../Shared/Table/ConfirmDeleteOrArchiveRow/ConfirmDeleteOrArchiveRow';

const SystemTypeList = () => {
  const { isLoading, response: systemTypes, getSystemTypes } = useGetSystemTypes();
  const { isLoading: isDeleteLoading, deleteSystemType } = useDeleteSystemType();
  const {
    toggleSort,
    sortProperty,
    sortDirection,
    pageSize,
    setPageSize,
    setPageNumber,
    query,
    setQuery,
    paginatedRequest,
  } = useTable();
  const [emptyMessage, setEmptyMessage] = useState('');
  const [confirmDeleteSystemTypeId, setConfirmDeleteSystemTypeId] = useState(-1);

  const history = useHistory();
  const classes = useStyles();

  const getList = useCallback(async () => {
    const response = await getSystemTypes(paginatedRequest);

    if (response?.totalCount <= 0) {
      if (query) {
        setEmptyMessage('Filtreringen gav inga resultat');
      } else {
        setEmptyMessage('Inga systemtyper tillagda');
      }
    }
  }, [paginatedRequest, query, getSystemTypes]);

  useEffect(() => {
    getList();
  }, [getList]);

  async function handleDeleteSystemType() {
    await deleteSystemType(confirmDeleteSystemTypeId);
    getList();
    setConfirmDeleteSystemTypeId(-1);
  }

  function concatRegulations(regulations: IRegulation[]) {
    return regulations
      .sort((a, b) => a.priority - b.priority)
      .map((x) => x.name)
      .join(', ');
  }

  const handleTableClick = (systemType: ISystemType) => {
    history.push(`${history.location.pathname}/${systemType.id}`);
  };

  return (
    <Table
      label={`Systemtyper (${systemTypes?.totalCount ?? 0})`}
      minWidth
      filters={<SearchInput type="search" placeholder="Sök" onChange={setQuery} />}
      iconButtons={
        <AccessGuard module={ModuleIdentifiers.Admin} accessRights={AccessRights.Write}>
          <IconButton
            size="small"
            aria-label="Lägg till systemtyp"
            onClick={() => {
              history.push(`${history.location.pathname}/create`);
            }}
          >
            <AddIcon />
          </IconButton>
        </AccessGuard>
      }
      pagination={
        systemTypes && (
          <TablePaginationWithDatasource
            datasource={systemTypes}
            onChangePage={setPageNumber}
            onChangeRowsPerPage={setPageSize}
          />
        )
      }
      empty={{
        empty: systemTypes && systemTypes.totalCount <= 0 ? true : false,
        message: emptyMessage,
      }}
      loading={{
        loading: isLoading,
        skeletonRows: pageSize,
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>
            <span className={'sr-only'}>Knappar</span>
          </TableCell>
          <TableCell>
            Namn
            <SortButton property="name" sortProperty={sortProperty} sortDirection={sortDirection} onSort={toggleSort} />
          </TableCell>
          <TableCell>Regelverk</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {systemTypes?.items.map((systemType) => (
          <TableRow className={classes.pointerCursor} hover tabIndex={-1} key={systemType.id}>
            {confirmDeleteSystemTypeId !== systemType.id ? (
              <>
                <AccessGuard
                  module={ModuleIdentifiers.Admin}
                  accessRights={AccessRights.Full}
                  fallback={<TableCell className={classes.narrowColumn}></TableCell>}
                >
                  <DropdownCell>
                    <MenuItem
                      onClick={() => {
                        setConfirmDeleteSystemTypeId(systemType.id);
                      }}
                    >
                      <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                      <Typography variant="inherit">Ta bort</Typography>
                    </MenuItem>
                  </DropdownCell>
                </AccessGuard>

                <TableCell onClick={() => handleTableClick(systemType)} className={classes.linkFont}>
                  <AccessGuard
                    module={ModuleIdentifiers.Admin}
                    accessRights={AccessRights.Write}
                    fallback={systemType.name}
                  >
                    {systemType.name}
                  </AccessGuard>
                </TableCell>
                <TableCell onClick={() => handleTableClick(systemType)}>
                  {concatRegulations(systemType.regulations)}
                </TableCell>
              </>
            ) : (
              <ConfirmDeleteOrArchiveRow
                onConfirm={() => handleDeleteSystemType()}
                onCancel={() => {
                  setConfirmDeleteSystemTypeId(-1);
                }}
                colspan={3}
                label={systemType.name}
                loading={isDeleteLoading}
              />
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default SystemTypeList;
