import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  useGetCompanyBranch,
  IUpsertCompanyBranchValidationErrors,
  useUpsertCompanyBranch,
  IUpsertCompanyBranchCommand,
} from '../CompanyBranch.api';
import useFormValidation from '../../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import useInputState from '../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { isResultError } from '../../../../../../../Shared/Api/response/ICreateResult';
import Button from '../../../../../../../Shared/Button/Button';
import Container from '../../../../../../../Shared/Container/Container';
import { InputField } from '../../../../../../../Shared/InputFields/InputField/InputField';

const CompanyBranchForm = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const { isLoading, getCompanyBranch } = useGetCompanyBranch();
  const { isLoading: isUpsertLoading, upsert } = useUpsertCompanyBranch();

  const { isFormValid, formRef } = useFormValidation();

  const [originalName, setOriginalName] = useState('');
  const [name, changeName, setName] = useInputState('');

  const [errors, setErrors] = useState<IUpsertCompanyBranchValidationErrors>();

  useEffect(() => {
    async function loadCompanyBranch() {
      const companyBranch = await getCompanyBranch(id);

      setOriginalName(companyBranch.name);
      setName(companyBranch.name);
    }

    if (id) {
      loadCompanyBranch();
    }
  }, [getCompanyBranch, id, setName]);

  async function upsertCompanyBranch() {
    const command: IUpsertCompanyBranchCommand = {
      name: name,
    };

    const result = await upsert(command, id);

    isResultError(result) ? setErrors(result) : history.push('/admin/settings/branch');
  }

  function Actions() {
    return (
      <>
        <Button color="default" variant="outlined" onClick={() => history.push('/admin/settings/branch')}>
          Avbryt
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={upsertCompanyBranch}
          loading={isUpsertLoading}
          disabled={!isFormValid}
        >
          {id ? 'Spara' : 'Skapa'}
        </Button>
      </>
    );
  }

  return (
    <Container
      form
      label={id ? `Redigera ${originalName}` : 'Skapa filial'}
      actions={<Actions />}
      ref={formRef}
      loading={isLoading}
    >
      <InputField label="Namn" type="text" required value={name} onChange={changeName} errorText={errors?.name} />
    </Container>
  );
};

export default CompanyBranchForm;
