import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ListItemIcon,
  MenuItem,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import Button from '../../../../../../../../../Shared/Button/Button';
import { Table } from '../../../../../../../../../Shared/Table/Table';
import { DropdownCell } from '../../../../../../../../../Shared/Table/DropdownCell/DropdownCell';
import { MobileTable } from '../../../../../../../../../Shared/Table/MobileTable/MobileTable';
import { MobileTableRow } from '../../../../../../../../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import { InputField } from '../../../../../../../../../Shared/InputFields/InputField/InputField';
import { ICoreSystemProduct } from '../../../CoreSystem.api';
import formatDate from '../../../../../../../../../Shared/Formatting/formatDate';
import Datepicker from '../../../../../../../../../Shared/InputFields/DatePicker/Datepicker';
import Container from '../../../../../../../../../Shared/Container/Container';
import IconButton from '../../../../../../../../../Shared/IconButton/IconButton';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import { ConfirmDeleteOrArchiveRow } from '../../../../../../../../../Shared/Table/ConfirmDeleteOrArchiveRow/ConfirmDeleteOrArchiveRow';

interface ISystemProductListProps {
  mainProductId: number | undefined;
  products: ICoreSystemProduct[];
  isLoading: boolean;
  isUpdateLoading: boolean;
  updateProducts: (products: ICoreSystemProduct[]) => void;
  isRemoveLoading: boolean;
  removeProduct: (id: number) => Promise<void>;
}

interface ICategoryProductKeyable {
  [key: string]: ICoreSystemProduct[];
}

export default function SystemProductList(props: ISystemProductListProps) {
  const { mainProductId, products, removeProduct } = props;
  const history = useHistory();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down(1080 / 16));

  const [sortedProducts, setSortedProducts] = useState<ICoreSystemProduct[]>([]);
  const [categoryProductsObject, setCategoryProductsObject] = useState<ICategoryProductKeyable[]>();
  const [categorySort, setCategorySort] = useState<{ [key: string]: number }>();
  const [confirmRemoveProductId, setConfirmRemoveProductId] = useState<number>();

  const orderProducts = useCallback(() => {
    setConfirmRemoveProductId(undefined);
    const mainProduct = products.find((x) => x.productId === mainProductId) as ICoreSystemProduct;
    var sorting: { [key: string]: number } = {};
    setCategoryProductsObject(
      [mainProduct, ...products.filter((x) => x.productId !== mainProductId)].reduce((r, a) => {
        r[a.productCategoryName] = r[a.productCategoryName] || [];
        r[a.productCategoryName].push(a);
        sorting[a.productCategoryName] = a.productCategorySortOrder || 0;
        return r;
      }, Object.create(null)),
    );
    setCategorySort(sorting);
    setSortedProducts([mainProduct, ...products.filter((x) => x.productId !== mainProductId)]);
  }, [mainProductId, products]);

  useEffect(() => {
    if (mainProductId) {
      orderProducts();
    }
  }, [mainProductId, orderProducts]);

  const deleteProduct = async () => {
    if (confirmRemoveProductId) {
      await removeProduct(confirmRemoveProductId);
    }
  };

  async function save() {
    await props.updateProducts(sortedProducts);
  }

  function updateProductRow(
    category: string,
    index: number,
    address: string | null,
    placement: string | null,
    installDate: Date | null,
  ) {
    const newList =
      categoryProductsObject && Object.values(categoryProductsObject[category as keyof typeof categoryProductsObject]);
    var rest: ICoreSystemProduct[] = []; //categoryProductsObject && Object.values(categoryProductsObject).reduce((res,key) => { return res.value.concat(categoryProductsObject[key])});
    // eslint-disable-next-line array-callback-return
    Object.keys(categoryProductsObject !== undefined && categoryProductsObject).map((_category: string) => {
      if (_category !== category && categoryProductsObject) {
        rest = [...rest, ...Object.values(categoryProductsObject[_category as keyof typeof categoryProductsObject])];
      }
    });

    if (newList && categoryProductsObject) {
      newList[index] = {
        ...Object.values(categoryProductsObject[category as keyof typeof categoryProductsObject])[index],
        productAddress: address,
        facilityPlacement: placement,
      };
      if (installDate) newList[index].installDate = installDate;
      setSortedProducts([...newList, ...rest]);
      setCategoryProductsObject(
        [...newList, ...rest].reduce((r, a) => {
          r[a.productCategoryName] = r[a.productCategoryName] || [];
          r[a.productCategoryName].push(a);
          return r;
        }, Object.create(null)),
      );
    }
  }

  return (
    <Container
      customSize={{ fullsize: true }}
      form
      actions={
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(`${history.location.pathname}/addproduct`)}
          >
            Lägg till produkt
          </Button>
          <Button variant="contained" color="primary" disabled={props.isUpdateLoading} onClick={save}>
            Spara
          </Button>
        </>
      }
    >
      <Table
        loading={{ loading: props.isLoading, skeletonRows: sortedProducts?.length ?? 5 }}
        mobile={mobileView}
        customSize={{
          fullsize: true,
        }}
      >
        {mobileView ? (
          <>
            {sortedProducts.map((product) => (
              <>
                <MobileTable
                  key={product.productName}
                  label={product.productName}
                  confirmDeleteRow={{
                    show: confirmRemoveProductId === product.id,
                    onConfirm: deleteProduct,
                    onCancel: () => setConfirmRemoveProductId(undefined),
                    loading: props.isRemoveLoading,
                  }}
                  action={
                    <DropdownCell mobile={mobileView}>
                      <MenuItem
                        disabled={mainProductId === product.productId}
                        onClick={() => setConfirmRemoveProductId(product.id)}
                      >
                        <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                        <Typography variant="inherit">Ta bort</Typography>
                      </MenuItem>
                    </DropdownCell>
                  }
                >
                  <MobileTableRow label="Produkt" value={product.productName} />
                  <MobileTableRow label="Artikelnummer" value={product.productNumber} />
                  <MobileTableRow label="Adress (SEKT.)" value={product.productAddress} />
                  <MobileTableRow label="Placering" value={product.facilityPlacement} />
                  <MobileTableRow label="Installationsdatum" value={formatDate(product.installDate)} />
                </MobileTable>
              </>
            ))}
          </>
        ) : (
          <>
            <TableHead>
              <TableRow>
                <TableCell>Produkt</TableCell>
                <TableCell>Artikelnummer</TableCell>
                <TableCell>Adress(SEKT.)</TableCell>
                <TableCell>Placering</TableCell>
                <TableCell>Installationsdatum</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(categoryProductsObject !== undefined && categoryProductsObject)
                .sort((a: any, b: any) => {
                  return categorySort ? categorySort[a] - categorySort[b] : 0;
                })
                .map((category: string) => {
                  return (
                    <>
                      <TableRow style={{ background: '#e0e0e0' }}>
                        <TableCell></TableCell>
                        <TableCell>{category}</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      {categoryProductsObject &&
                        Object.values(categoryProductsObject[category as keyof typeof categoryProductsObject]).map(
                          (product, index) => {
                            return (
                              <SystemProductRow
                                product={product}
                                isMain={product.productId === mainProductId}
                                delete={() => removeProduct(product.id)}
                                setConfirmDelete={setConfirmRemoveProductId}
                                confirmDeleteId={confirmRemoveProductId}
                                update={(address: string | null, placement: string | null, installDate: Date | null) =>
                                  updateProductRow(category, index, address, placement, installDate)
                                }
                              />
                            );
                          },
                        )}
                    </>
                  );
                })}
            </TableBody>
          </>
        )}
      </Table>
    </Container>
  );
}

interface ISystemProductRowProps {
  product: ICoreSystemProduct;
  update: (address: string | null, placement: string | null, installDate: Date | null) => void;
  confirmDeleteId?: number;
  isMain?: boolean;
  setConfirmDelete: (id: number | undefined) => void;
  delete: (id: number) => void;
}

const SystemProductRow = (props: ISystemProductRowProps) => {
  function updateAddress(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    props.update(e.target.value, props.product.facilityPlacement, props.product.installDate);
  }

  function updatePlacement(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    props.update(props.product.productAddress, e.target.value, props.product.installDate);
  }
  function updateInstallDate(newDate: Date | null) {
    newDate && props.update(props.product.productAddress, props.product.facilityPlacement, newDate);
  }

  return (
    <TableRow key={props.product.id}>
      {props.confirmDeleteId !== props.product.id ? (
        <>
          <TableCell>{props.product.productName}</TableCell>
          <TableCell>{props.product.productNumber}</TableCell>
          <TableCell>
            <InputField placeholder="Adress" fullwidth value={props.product.productAddress} onChange={updateAddress} />
          </TableCell>
          <TableCell>
            <InputField
              placeholder="Placering"
              fullwidth
              value={props.product.facilityPlacement}
              onChange={updatePlacement}
            />
          </TableCell>
          <TableCell>
            <Datepicker
              customWidth={12}
              selected={props.product.installDate ? new Date(props.product.installDate) : null}
              onChange={(newDate: Date | null) => updateInstallDate(newDate)}
            />
          </TableCell>
          <TableCell>
            <IconButton
              disabled={props.isMain}
              color="error"
              onClick={() => props.setConfirmDelete(props.product.id)}
              aria-label="Ta bort rad"
            >
              <RemoveIcon />
            </IconButton>
          </TableCell>
        </>
      ) : (
        <ConfirmDeleteOrArchiveRow
          onConfirm={() => props.delete(props.product.id)}
          onCancel={() => props.setConfirmDelete(undefined)}
          colspan={5}
          label={props.product.productName}
        />
      )}
    </TableRow>
  );
};
