import React, { useCallback, useEffect, useState } from 'react';
import { List, ListSubheader, Typography, useMediaQuery } from '@material-ui/core';
import AccessGuard from '../../../../Core/Authentication/AccessGuard/AccessGuard';
import { AccessRights, ModuleIdentifiers } from '../../../../Core/Authentication/ModuleAccess';
import Container from '../../../../Shared/Container/Container';
import { uniqueValues } from '../../../Shared/utils';
import FileNode from './FileNode';
import EditNodeForm from './Forms/EditNodeForm';
import UploadFileForm from './Forms/UploadFileForm';
import EmptyListIcon from '../../../../Styles/Icons/EmptyListIcon';
import { IFileNode, IFileFormType, useGetFileSystemNodes, useDownloadFile } from '../FileSystem.api';
import { useStyles } from './FileSystem.styles';
import Label from '../../../../Shared/Container/Label/Label';
import theme from '../../../../Styles/Theme';
import IconButton from '../../../../Shared/IconButton/IconButton';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';

const FileSystem = () => {
  const classes = useStyles();
  const { isLoading, nodes, getNodes } = useGetFileSystemNodes();
  const [openNodes, setOpenNodes] = useState<number[]>([]);
  const [selectedNode, setSelectedNode] = useState<IFileNode>();
  const [fileForm, setFileForm] = useState<IFileFormType>();
  const [focusNode, setFocusNode] = useState<number>(0);
  const [viewFile, setViewFile] = useState<string>();

  const { downloadFile } = useDownloadFile();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchNodes = useCallback(async () => {
    var newNodes = await getNodes({ openNodes: openNodes });
    var openNodeIds = newNodes.filter((x) => x.parentNodeId != null).map((x) => x.parentNodeId!);
    setOpenNodes(uniqueValues(openNodeIds));
  }, [openNodes, getNodes]);

  useEffect(() => {
    if (nodes === undefined) fetchNodes();
  }, [nodes, fetchNodes]);

  const onToggleNode = async (nodeId: number, close?: boolean) => {
    setFocusNode(nodeId);
    if (close) {
      //Close node: remove from list
      setOpenNodes((state) => state.filter((x) => x !== nodeId));
    } else {
      //Open node: add to list and fetch children;
      let newState = [...openNodes, nodeId];
      await getNodes({ openNodes: newState });
      setOpenNodes(newState);
    }
  };

  const onOpenForm = (node: IFileNode, formType: IFileFormType) => {
    setSelectedNode(node);
    setFileForm(formType);
  };
  const onCloseForm = (refetch?: boolean) => {
    setSelectedNode(undefined);
    setFileForm(undefined);
    if (refetch) fetchNodes();
  };

  const onCloseFile = () => {
    setViewFile(undefined);
    onCloseForm(true);
  };

  return (
    <>
      <Container
        loading={isLoading}
        customSize={{ sm: 12, md: 4, lg: 3 }}
        customHeader={
          <Label
            label={'Välj mapp eller fil'}
            invert
            action={
              <AccessGuard module={ModuleIdentifiers.FileSystem} accessRights={AccessRights.Write}>
                <IconButton
                  color="primary"
                  onClick={() => setFileForm('createFolder')}
                  aria-label="Skapa ny fil"
                >
                  <AddIcon />
                </IconButton>
              </AccessGuard>
            }
          />
        }
      >
        <List component="nav" className={classes.root} subheader={<ListSubheader component="div"></ListSubheader>}>
          <div className={classes.listItem}>
            {!!nodes ? (
              nodes
                .filter((x) => x.parentNodeId === null)
                .map((node) => (
                  <FileNode
                    key={node.id}
                    node={node}
                    allNodes={nodes}
                    openNodeIds={openNodes}
                    onToggleNode={onToggleNode}
                    onOpenForm={onOpenForm}
                    focusNode={focusNode}
                    onViewFile={(_node, nodeId) => {
                      if (mobileView) {
                        downloadFile(nodeId);
                      } else {
                        setViewFile(_node);
                      }
                    }}
                  />
                ))
            ) : (
              <div className={classes.empty}>
                <span className={classes.emptyMessage}>
                  <EmptyListIcon />
                </span>
                <Typography>{'Inga mapp tillagda'}</Typography>
              </div>
            )}
          </div>
        </List>
      </Container>
      {(selectedNode || fileForm === 'createFolder') && (
        <>
          {fileForm === 'uploadFile' && (
            <UploadFileForm
              parentNode={selectedNode!}
              closeForm={onCloseForm}
              toggleNode={onToggleNode}
              onViewFile={(_node, nodeId) => {
                if (mobileView) {
                  downloadFile(nodeId);
                } else {
                  setViewFile(_node);
                }
              }}
            />
          )}
          {fileForm === 'createFolder' && (
            <EditNodeForm
              key={'createFolder_' + selectedNode?.id}
              parentNode={selectedNode}
              closeForm={onCloseForm}
              closeFile={onCloseFile}
            />
          )}
          {fileForm === 'editNode' && (
            <EditNodeForm
              key={'editNodeForm_' + selectedNode!.id}
              node={selectedNode}
              closeForm={onCloseForm}
              closeFile={onCloseFile}
            />
          )}
        </>
      )}
      {!mobileView && viewFile && (
        <Container
          customSize={{ sm: 12, md: 8, lg: 9 }}
          alignment={'flex-end'}
          loading={isLoading}
          customHeader={<Label label={'Fil'} />}
        >
          <object aria-labelledby="file" className={classes.pdfViewerElement} data={viewFile} type="application/pdf" />
        </Container>
      )}
    </>
  );
};

export default FileSystem;
