import { useApiGet } from '../../../../../Core/Api/Api';

export function useGetCompanies() {
  const { isLoading, response, get } = useApiGet<CompanyListItemDto[]>('/api/customer-portal/companies/list', false);

  return {
    isLoading,
    companies: response,
    getCompanies: get,
  };
}

export interface CompanyListItemDto {
  id: number;
  name: string;
  identificationNumber: string;
  customers: CustomerListItemDto[];
}

export interface CustomerListItemDto {
  id: number;
  name: string;
  customerNumber: number;
  identificationNumber: string;
}
