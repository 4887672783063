import { makeStyles, Theme } from '@material-ui/core/styles';
import { pdfSize } from '../../Offer.api';

const useStyles = makeStyles((theme: Theme) => ({
  forPdf: {
    [theme.breakpoints.up('md')]: {
      width: `${pdfSize}cm`,
      maxWidth: '100%',
    },
    '& > *': {
      maxWidth: '100%',
      // To match the pdf
      '& pre': {
        fontFamily: 'sans-serif',
        fontSize: '12px',
      },
    },
  },
}));

export default useStyles;
