import { makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/styles';

export const selectContainer = {
  flexGrow: 0,
  maxWidth: '100%',
  flexBasis: '100%',
  padding: '0 1.5rem 0.5rem 0.5rem',

  '@media (min-width: 496px)': {
    flexGrow: 0,
    maxWidth: '50%',
    flexBasis: 'calc(50% - 1.5rem)',
    padding: '0 0 0.5rem 0.5rem',
  },
};

export const button = {
  padding: 0,
  top: '-0.25rem',
  border: 0,
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
};

export const pdfInInput = `calc(${21 - 3.4}cm + 1.8rem)`;

export const forPdf = {
  '& textarea': {
    height: '37rem',
  },
} as CSSProperties;

export const fields = {
  padding: '0.5rem',
  maxWidth: '100%',
  width: '100%',

  '& textarea': {
    fontFamily: 'sans-serif',
    fontSize: '12px',
    lineHeight: '15px',
    overflowY: 'scroll',
  },

  '@media (min-width: 855px)': {
    maxWidth: pdfInInput,
  },
} as CSSProperties;

export const containedFields = {
  marginRight: '-1rem',
  ...fields,
} as CSSProperties;

const useStyles = makeStyles((theme: Theme) => ({
  selectContainer: {
    ...selectContainer,
  },
  selectContainerUnpadded: {
    ...selectContainer,
    '@media (min-width: 496px)': {
      flexBasis: 'calc(50% - 1rem)',
    },
  },
  button: {
    ...button,
  },
  forPdf: {
    ...forPdf,
  },
  wysiwygFields: {
    ...fields,
  },
  containedWysiwygFields: {
    ...containedFields,
  },
}));

export default useStyles;
