import React from 'react';
import Button from '../../Button/Button';

export interface IFormStep<T> {
  goNext?: () => void;
  goBack?: () => void;
  cancel?: () => void;
  isFormValid?: boolean;
  isProcessing?: boolean;
}

export default function WizardFormActions(props: IFormStep<any>) {
  const { goNext, cancel, goBack, isFormValid, isProcessing } = props;

  return (
    <>
      {cancel && (
        <Button color="default" variant="outlined" disabled={isProcessing} onClick={cancel}>
          Avbryt
        </Button>
      )}
      {goBack && (
        <Button
          color="default"
          variant="outlined"
          onClick={() => {
            goBack && goBack();
            window.scrollTo({ top: 0 });
          }}
          disabled={isProcessing}
        >
          Tillbaka
        </Button>
      )}
      {goNext && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            goNext && goNext();
            window.scrollTo({ top: 0 });
          }}
          loading={isProcessing}
          disabled={!isFormValid || isProcessing}
        >
          Spara
        </Button>
      )}
    </>
  );
}
