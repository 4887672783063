import theme from '../../Styles/Theme';

export const InputFieldBaseStyles: any = {
  borderRadius: 5,
  position: 'relative',
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.grey[400]}`,
  width: '100%',
  padding: '0.75rem 0.5rem',
  '&:focus': {
    borderColor: theme.palette.primary.main,
  },
};
