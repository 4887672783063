import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    marginLeft: '1rem',
  },
  confirm: {
    marginRight: '0.5rem',
  },
  label: {
    fontWeight: 700,
  },
  mobile: {
    minHeight: '3rem',
    display: 'flex',
    alignItems: 'center',
    padding: '0 1rem',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}));
