import React, { useCallback, useEffect, useState } from 'react';
import { isResultError } from '../../../../../../../../../Shared/Api/response/ICreateResult';
import useInputState from '../../../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { InputField } from '../../../../../../../../../Shared/InputFields/InputField/InputField';
import Select, { ISelectOption } from '../../../../../../../../../Shared/Select/Select';
import { useGetFacility } from '../../../../../Facilities/Facilities.api';
import FacilitySelect from '../../../../../Facilities/FacilitySelect/FacilitySelect';
import { useGetCoreSystem } from '../../../../../Facilities/CoreSystems/CoreSystem.api';
import { useCreateOrderSecurityCompanyDocument, useGetOrderSecurityCompanyDocumentForm } from '../../Documents.api';
import { IDocumentFormProps } from '../../IDocumentFormProps';

const OrderSecurityCompany = (props: IDocumentFormProps) => {
  const { customerId, onLoadingChange, saveCallback, documentName, onDocumentCreated, validateFormState } = props;
  const { getOrderSecurityCompanyDocumentForm, isLoading } = useGetOrderSecurityCompanyDocumentForm(customerId);
  const { isLoading: isFacilityLoading, getFacility } = useGetFacility(customerId);
  const { isLoading: isCreateLoading, createOrderSecurityCompanyDocument } = useCreateOrderSecurityCompanyDocument(
    customerId,
  );
  const [receiver, changeReceiver, setReceiver] = useInputState('');
  const [facilityId, setFacilityId] = useState<number | undefined>(
    props.facilityId ? parseInt(props.facilityId) : undefined,
  );
  const { isLoading: isCoreSystemLoading, getCoreSystem } = useGetCoreSystem(customerId, facilityId?.toString());
  const [coreSystemOptions, setCoreSystemOptions] = useState<ISelectOption[]>([]);
  const [coreSystemId, setCoreSystemId] = useState<string>();
  const [agreementOptions, setAgreementOptions] = useState<ISelectOption[]>([]);
  const [agreementId, setAgreementId] = useState<string>();

  useEffect(() => {
    validateFormState && validateFormState(!!facilityId && !!coreSystemId && !!agreementId);
  }, [validateFormState, facilityId, coreSystemId, agreementId]);

  useEffect(() => {
    async function loadForm() {
      const form = await getOrderSecurityCompanyDocumentForm();
      setReceiver(form.receiver);
      setFacilityId(form.facilityId);
      setCoreSystemId(form.coreSystemId.toString());
      setAgreementId(form.agreementId.toString());
    }
    loadForm();
  }, [getOrderSecurityCompanyDocumentForm, setReceiver]);

  useEffect(() => {
    onLoadingChange(isLoading || isCreateLoading);
  }, [onLoadingChange, isLoading, isCreateLoading]);

  useEffect(() => {
    async function loadFacility() {
      var response = await getFacility(facilityId?.toString());
      setCoreSystemOptions(response.coreSystems.map((x) => ({ label: x.mainProduct, value: x.id.toString() })));
      if (response.coreSystems.length === 1) {
        setCoreSystemId(response.coreSystems[0].id.toString());
      }
    }
    if (facilityId !== undefined) {
      loadFacility();
    }
  }, [facilityId, getFacility]);

  useEffect(() => {
    async function loadCoreSystem() {
      var response = await getCoreSystem(coreSystemId);
      var agreements = response.agreements.filter((x) => x.isActive);
      setAgreementOptions(agreements.map((x) => ({ label: x.securityCompanySupplier, value: x.id.toString() })));
      if (agreements.length === 1) {
        setAgreementId(response.agreements[0].id.toString());
      }
    }
    if (coreSystemId !== undefined) {
      loadCoreSystem();
    }
  }, [coreSystemId, getCoreSystem]);

  const save = useCallback(() => {
    async function innerSave() {
      const result = await createOrderSecurityCompanyDocument({
        documentName: documentName,
        receiver: receiver ?? null,
        facilityId: facilityId!,
        coreSystemId: Number(coreSystemId!),
        agreementId: Number(agreementId!),
      });
      if (!isResultError(result)) onDocumentCreated(Number(result as string));
    }
    innerSave();
  }, [
    createOrderSecurityCompanyDocument,
    documentName,
    receiver,
    facilityId,
    coreSystemId,
    agreementId,
    onDocumentCreated,
  ]);

  useEffect(() => {
    saveCallback(save);
  }, [saveCallback, save]);

  return (
    <>
      <FacilitySelect
        customerId={customerId}
        isDisabled={!!props.facilityId}
        label="Välj plats"
        required
        selectedFacilityId={facilityId?.toString()}
        onChange={(selected) => {
          setFacilityId(selected ? Number(selected) : undefined);
          setCoreSystemId(undefined);
          setAgreementId(undefined);
        }}
      />
      <Select
        inputFieldProps={{ label: 'Välj system', required: true }}
        selectProps={{
          value: coreSystemOptions.find((x) => x.value === coreSystemId) || null,
          isDisabled: !!(coreSystemOptions.length === 0),
          onChange: (selected) => {
            setCoreSystemId(selected ? (selected as ISelectOption).value : undefined);
            setAgreementId(undefined);
          },
          options: coreSystemOptions,
          isLoading: isFacilityLoading,
        }}
      ></Select>
      <Select
        inputFieldProps={{ label: 'Välj avtal', required: true }}
        selectProps={{
          value: agreementOptions.find((x) => x.value === agreementId) || null,
          isDisabled: !!(agreementOptions.length === 0),
          onChange: (selected) => {
            setAgreementId(selected ? (selected as ISelectOption).value : undefined);
          },
          options: agreementOptions,
          isLoading: isCoreSystemLoading,
        }}
      ></Select>
      <InputField label="Mottagare" type="text" value={receiver} onChange={changeReceiver} />
    </>
  );
};

export default OrderSecurityCompany;
