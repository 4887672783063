import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../Styles/Theme';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  text: {
    marginLeft: '1rem',
  },
  linkFont: {
    fontWeight: 700,
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  pointerCursor: {
    cursor: 'pointer',
  },
  mobileButtonList: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    flexDirection: 'column',
    display: 'block',
  },
  mobileButtonBorder: {
    marginBottom: 10,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  mobileButton: {
    display: 'grid',

    '& > Button': {
      margin: '2px',
      borderRadius: theme.shape.borderRadius,
      '&:first-child': {
        marginTop: 10,
      },
    },
  },
  emptyColumn: {
    border: '0',
    display: 'table-column',
    width: '6px',
  },
  narrowColumn: {
    width: '15px',
  },
  roundedHighlightColumn: {
    cursor: 'pointer',
    background: '#f7f7f7',
    borderRadius: '0 0 10px 10px',
    textAlign: 'center',
    padding: '12px 0',
  },
  borderBottom: {
    borderBottom: '1pt solid black',
  },
  red: {
    color: theme.palette.error.main,
  },
  yellow: {
    color: theme.palette.warning.dark,
  },
  green: {
    color: theme.palette.success.dark,
  },
  fakeTableHead: {
    background: '#f5f5f5',
    '& td': {
      fontWeight: 'bold'
    }
  }
});
