import { makeStyles, Theme } from '@material-ui/core/styles';
import { selectBorderRadius } from '../../../../../../Styles/Theme';
import { forPdf } from '../../../../Errands/Components/Offer/OfferForm/SetSaleTerms/SetSaleTerms.styles';

const useStyles = makeStyles((theme: Theme) => ({
  regulations: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '36rem',
  },
  list: {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: selectBorderRadius,
    marginTop: '1rem',
    marginBottom: '1rem',
    '& > li': {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
  forPdf: {
    ...forPdf,
  },
}));

export default useStyles;
