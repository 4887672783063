import { makeStyles, Theme } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme: Theme) => ({
  root: {
        '& .MuiTabs-root': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0
        },
        '& .PrivateTabIndicator-root-83': {
            backgroundColor: theme.palette.primary.contrastText
        },
        '& .MuiTabs-root .Mui-selected': {
            color: theme.palette.primary.contrastText,
            textDecoration: 'underline'
        }

    },
}));
