import { useApiPost, useApiDelete, useApiGet, useApiPut } from '../../../../../Core/Api/Api';
import IPaginatedResponse from '../../../../../Shared/Api/response/IPaginatedResponse';
import IPaginatedRequest from '../../../../../Shared/Api/request/IPaginatedRequest';
import { AccessRights } from '../../../../../Core/Authentication/ModuleAccess';
import { ISelectOption } from '../../../../../Shared/Select/Select';
import { useCallback } from 'react';
import { ICompanyListItem } from '../../../Company/Companies.api';
import { ICompanyUser } from '../../../../../Core/Authentication/UserContext';

export interface IProcessOwnership {
  sectionNumber: string;
  name: string;
}

export enum Role {
  Installer = 1,
  Contractor = 2,
}

export const roleLists: { role: Role; name: string }[] = [{ role: Role.Installer, name: 'Montör' },{ role: Role.Contractor, name: 'Underentreprenör' }];

export interface IUser {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  identificationNumber: string;
  phoneNumber: string;
  privateEmail: string;
  privatePhoneNumber: string;
  address: string;
  postalCode: string;
  city: string;
  employmentDate?: Date;
  controlDate1: Date | null;
  controlDate2: Date | null;
  controlDate3: Date | null;
  salesBudget: number;
  purchaseLimit: number | null;
  offerBudget: number;
  offerAmount: number;
  companyBranchId?: number;
  companyBranchName?: string | null;
  organizationDepartment: string;
  organizationJobDescription: string;
  processOwnership?: IProcessOwnership[];
  education: IUserEducation[];
  moduleAccessRights: ICompanyUserModuleAccess[];
  roles: Role[];
  isPortalAdministrator: boolean;
  inviteAccepted: boolean;
  companyInfo?: ICompanyListItem;
}

export interface ICurrentUser {
  id: number;
  firstName: string;
  lastName: string;
  isPortalAdministrator: boolean;
  companies: { userId: number; companyName: string }[];
  customers: { userId: number; companyName: string }[];
  impersonatedCompanyUsers: { userId: number; companyName: string; userName: string }[];
  selectedUserType: UserType | null;
  moduleAccessRights: ICompanyUserModuleAccess[];
  selectedCompany?: {
    applicationUserId: number;
    companyId: number;
    companyName: string;
    companyLogoBase64: string;
    companyAdditionalImageBase64?: string;
    bannerText: string;
  };
  selectedUserId?: number;
  impersonatedCompanyUser?: ICompanyUser;
  homeComponents: HomeComponentDto[];
  roles: Role[];
}

export enum HomeComponent {
  Calendar = 1,
  Todo = 2,
  UserMessage = 3,
  Service = 4,
}

export const homeComponentName = [
  {
    name: 'Kalendar',
    type: HomeComponent.Calendar,
  },
  {
    name: 'Att göra',
    type: HomeComponent.Todo,
  },
  {
    name: 'Forum',
    type: HomeComponent.UserMessage,
  },
  {
    name: 'Service',
    type: HomeComponent.Service,
  },
];

export interface HomeComponentDto {
  id: number;
  component: HomeComponent;
  priority: number;
  isHidden: boolean;
}

export enum UserType {
  SuperAdmin = 1,
  CompanyUser = 2,
  Customer = 3,
}

export function useGetCurrentUser() {
  const { isLoading, response, get } = useApiGet<ICurrentUser>('/api/users/current', false);

  const getCurrentUser = useCallback(
    async (userType?: UserType, entityId?: number) => {
      const currentUserULR = '?userType=' + userType + '&entityId=' + entityId;

      return get(currentUserULR);
    },
    [get],
  );

  return {
    isLoading,
    response,
    getCurrentUser: getCurrentUser,
  };
}

export function useDeleteUser() {
  const { isLoading, delete: deleteRequest } = useApiDelete('api/users/');

  function deleteUser(id: number) {
    return deleteRequest(undefined, id.toString());
  }

  return {
    isLoading,
    deleteUser,
  };
}

export interface IGetCompanyUserRequest {
  includeCompany?: boolean;
}

export function useGetCompanyUser() {
  const { isLoading, response, post } = useApiPost<IUser, IGetCompanyUserRequest>('/api/users/companyuser/', false);

  const getCompanyUser = useCallback(
    (id: string, command?: IGetCompanyUserRequest) => {
      return post(command ?? { includeCompany: false }, id);
    },
    [post],
  );

  return {
    isLoading,
    response,
    getCompanyUser: getCompanyUser,
  };
}

export function useGetCompanyUsers() {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<IUser>, ICompanyUserRequest>(
    '/api/users/companyuser/list',
  );

  return {
    isLoading,
    response,
    getCompanyUsers: post,
  };
}

export function useUpdateCompanyUser() {
  const { isLoading, put } = useApiPut<IEditCompanyUserResult>('/api/users/companyuser/');

  const updateCompanyUser = (id: string, createUserCommand: IEditCompanyUserBody) => {
    return put(createUserCommand, id);
  };

  return {
    isLoading,
    updateCompanyUser,
  };
}

export function useSoftDeleteCompanyUser() {
  const { isLoading, put } = useApiPut<IEditCompanyUserResult>('/api/users/softdeletecompanyuser/');

  const softDeleteCompanyUser = (id: string) => {
    return put(undefined, id);
  };

  return {
    isLoading,
    softDeleteCompanyUser: softDeleteCompanyUser,
  };
}

export function useDeleteCompanyUser() {
  const { isLoading, delete: deleteRequest } = useApiDelete('/api/users/companyuser/');

  function deleteCompanyUser(id: number) {
    return deleteRequest(undefined, id.toString());
  }

  return {
    isLoading,
    deleteCompanyUser,
  };
}

export interface ICompanyUserModuleAccess {
  moduleIdentifier: string;
  accessRights: AccessRights;
}

export interface IUserEducation {
  id: number | null;
  education: string;
  educationStatus?: Number;
  startDate: Date;
  endDate: Date;
}

export interface IEditCompanyUserResult {
  identificationNumber?: string[];
  firstname?: string[];
  lastname?: string[];
  address?: string[];
  postalCode?: string[];
  city?: string[];
  phoneNumber?: string[];
  email?: string[];
  privatePhoneNumber?: string[];
  privateEmail?: string[];
  roleId?: number;
  moduleAccessRights: string[];
}

export interface IEditCompanyUserBody {
  identificationNumber: string;
  firstname: string;
  lastname: string;
  address: string;
  postalCode: string;
  city: string;
  phoneNumber: string;
  email: string;
  privateEmail: string | null;
  privatePhoneNumber: string | null;
  employmentDate: Date | null;
  controlDate1: Date | null;
  controlDate2: Date | null;
  controlDate3: Date | null;
  salesBudget: number | null;
  purchaseLimit: number | null;
  offerBudget: number | null;
  offerAmount: number;
  companyBranchId?: number | number[];
  organizationDepartment: string | null;
  organizationJobDescription: string | null;
  companyId?: string;
  educationList: IUserEducation[];
  moduleAccessRights: ICompanyUserModuleAccess[];
  roles: Role[];
}

export interface ICompanyUserRequest extends IPaginatedRequest {
  companyId?: string;
  companyUserIds?: number[];
  usedAsSelectList?: boolean;
  includeDeleted: boolean;
}

export function useCreateCompanyUser() {
  const { isLoading, post } = useApiPost<IEditCompanyUserResult, IEditCompanyUserBody>('/api/users/companyuser');

  return {
    isLoading,
    createCompanyUser: post,
  };
}

export function useSendInvite() {
  const { isLoading, post } = useApiPost('/api/users/companyuser/');

  const sendInvite = (id: string) => {
    return post(undefined, `${id}/invite`);
  };

  return {
    isLoading,
    sendInvite,
  };
}

export interface IEducationOptions extends ISelectOption {
  type: EducationType;
}

export enum EducationType {
  Requested,
  Planned,
  Accomplished,
}

export const educationOptions = [
  { type: EducationType.Requested, name: 'Önskad' },
  { type: EducationType.Planned, name: 'Planerad' },
  { type: EducationType.Accomplished, name: 'Genomförd' },
];

export const educationStatusOptions: IEducationOptions[] = educationOptions.map((x) => ({
  label: x.name,
  value: x.type.toString(),
  type: x.type,
}));

interface IUpdateHomeComponentRequest {
  homeComponents: HomeComponentDto[];
}

export function useUpdateHomeComponent() {
  const { isLoading, put } = useApiPut<any, IUpdateHomeComponentRequest>('/api/users/homecomponent/');

  return {
    isLoading,
    updateHomeComponent: put,
  };
}
