import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import useUserContext from '../../../../Core/Authentication/UserContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: 'auto',
    height: '4.5rem',
    maxWidth: '19rem',
    marginRight: '0.5rem',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '50%',
    },
  },
  logo: {
    width: '100%',
    objectFit: 'contain',
  },
}));

export const Logotype = () => {
  const { user } = useUserContext();
  const classes = useStyles();
  return user && (user.companyLogoBase64 || user.impersonatedCompany?.logo) ? (
    <Link to="/" className={classes.root}>
      <img className={classes.logo} src={user.companyLogoBase64 ?? user.impersonatedCompany?.logo} alt="logotype" />
    </Link>
  ) : null;
};
