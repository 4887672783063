import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { ILoadingTable, Table } from '../Table';
import Button from '../../Button/Button';

export interface ISelectTableProps<T extends ISelectItem> {
  items: T[];
  onItemSelect: (item: T) => void;
  header: string;
  loading?: ILoadingTable;
}

export interface ISelectItem {
  label: string;
  value: string;
}
function SelectTable<T extends ISelectItem = ISelectItem>(props: ISelectTableProps<T>) {
  const { items, onItemSelect, header, loading } = props;
  return (
    <Table loading={loading}>
      <TableHead>
        <TableRow>
          <TableCell>{header}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item) => (
          <TableRow key={item.value}>
            <TableCell>
              <Button onClick={() => onItemSelect(item)}>{item.label}</Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
export default SelectTable;
