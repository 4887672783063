import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Dt from '../../../../../Shared/DataList/Dt/Dt';
import Dd from '../../../../../Shared/DataList/Dd/Dd';
import DataList from '../../../../../Shared/DataList/DataList';
import { Fieldset } from '../../../../../Shared/Form/Fieldset/Fieldset';
import Container from '../../../../../Shared/Container/Container';
import Datepicker from '../../../../../Shared/InputFields/DatePicker/Datepicker';
import { useGetErrorReportStats } from './ErrorReportStats.api';
import useStyles from './ErrorReport.styles';

const defaultFromDate = moment().startOf('month').toDate();
const defaultToDate = moment().startOf('day').toDate();

const ErrorReport = () => {
  const [fromDate, setFromDate] = useState<Date>(defaultFromDate);
  const [toDate, setToDate] = useState<Date>(defaultToDate);
  const classes = useStyles();
  const { isLoading, errorReportStats, getErrorReportStats } = useGetErrorReportStats();

  useEffect(() => {
    getErrorReportStats({ fromDate, toDate });
  }, [getErrorReportStats, fromDate, toDate]);

  return (
    <>
      <Container
        loading={isLoading}
        customSize={{ fullsize: true }}
        customStatisticFilter
        filters={
          <>
            <Datepicker
              required
              label="Visa från"
              inFilter
              selected={fromDate}
              onChange={(d: Date | null) => setFromDate(d as Date)}
            />

            <Datepicker
              required
              label="Visa till"
              inFilter
              selected={toDate}
              onChange={(d: Date | null) => setToDate(d as Date)}
            />
          </>
        }
      >
        <div className={classes.lists}>
          <DataList>
            <Dt>Antal felrapporter</Dt>
            <Dd>{errorReportStats?.errorReportCount}</Dd>
            <Dt>Antal timmar</Dt>
            <Dd>{errorReportStats?.hours}</Dd>
            <Dt>Snitt antal timmar/rapport</Dt>
            <Dd>{errorReportStats?.hoursPerReport.toPrecision(2)}</Dd>
            <Dt>Antal garantiresor</Dt>
            <Dd>{errorReportStats?.trips}</Dd>
            <Dt>Snitt antal resor/rapport</Dt>
            <Dd>{errorReportStats?.tripsPerReport.toPrecision(2)}</Dd>
          </DataList>
          {errorReportStats?.typeCount && errorReportStats.typeCount.length > 0 && (
            <Fieldset legend="Typ av fel">
              <DataList>
                {errorReportStats?.typeCount.map((x) => (
                  <React.Fragment key={x.errorCategoryId}>
                    <Dt>{x.errorCategoryName}</Dt>
                    <Dd>{x.count}</Dd>
                  </React.Fragment>
                ))}
              </DataList>
            </Fieldset>
          )}
          {errorReportStats?.supplierCount && errorReportStats?.supplierCount.length > 0 && (
            <Fieldset legend="Leverantör">
              <DataList>
                {errorReportStats?.supplierCount.map((x) => (
                  <React.Fragment key={x.supplierId}>
                    <Dt>{x.name}</Dt>
                    <Dd>{x.count}</Dd>
                  </React.Fragment>
                ))}
              </DataList>
            </Fieldset>
          )}
          {errorReportStats?.companyUserCount && errorReportStats?.companyUserCount.length > 0 && (
            <Fieldset legend="Personer">
              <DataList>
                {errorReportStats?.companyUserCount.map((x) => (
                  <React.Fragment key={x.companyUserId}>
                    <Dt>{x.name}</Dt>
                    <Dd>{x.count}</Dd>
                  </React.Fragment>
                ))}
              </DataList>
            </Fieldset>
          )}
        </div>
      </Container>
    </>
  );
};
export default ErrorReport;
