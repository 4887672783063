import React, { useEffect, useState, useCallback } from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import Calendar, { IUserCalendar } from '../../../Shared/Calendars/Calendar/Calendar';
import useErrorMessage from '../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import { isErrorResponse } from '../../../../Shared/Api/response/IErrorRespose';
import { IActivity } from '../../../Shared/Calendars/Components/ActivityForm/ActivityForm';
import {
  useGetCalendars,
  useUpsertCalendarEntry,
  getErrorMessage,
  ICalendarEntry,
  useDeleteCalendarEntry,
  getCalendarEntryTypeName,
} from '../Booking.api';
import moment from 'moment';

export interface ICalendarEntryWithUser extends ICalendarEntry {
  companyUserId: number;
}

const BookingCalendar = () => {
  const now = new Date();
  const [date, setDate] = useState(new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())));
  const { calendars: bookingCalendar, getCalendars } = useGetCalendars();
  const [userCalendars, setUserCalendars] = useState<IUserCalendar<ICalendarEntryWithUser>[]>();
  const { upsert } = useUpsertCalendarEntry();
  const { setErrorMessage } = useErrorMessage();
  const { deleteCalendarEntry } = useDeleteCalendarEntry();
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState<number | number[] | undefined>();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const loadData = useCallback(async () => {
    const startDate = moment(date)
      .startOf(mobile ? 'day' : 'isoWeek')
      .toDate();
    const endDate = moment(date)
      .endOf(mobile ? 'day' : 'isoWeek')
      .toDate();

    setLoading(true);
    await getCalendars({ query, startDate, endDate });
    setLoading(false);
  }, [getCalendars, date, query, mobile]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  async function handleSave(activity: IActivity) {
    if (activity.companyUserIds) {
      const result = await upsert({ ...activity, companyUserIds: activity.companyUserIds }, activity.id?.toString());
      if (isErrorResponse(result)) {
        setErrorMessage({ message: getErrorMessage(result, !activity.id) });
      } else {
        loadData();
      }
    }
  }

  const handleRemove = async (id: number) => {
    const result = await deleteCalendarEntry(id.toString());

    if (isErrorResponse(result)) {
      setErrorMessage({ message: getErrorMessage(result, false) });
    } else {
      loadData();
    }
  };

  const addSecondToDate = (date: Date) => {
    // A hack to prevent no duration event to be counted as allDay event
    var newDate = new Date(date);
    newDate.setSeconds(1);
    return newDate;
  };

  useEffect(() => {
    if (bookingCalendar) {
      setUserCalendars(
        bookingCalendar.map((calendar) => ({
          companyBranchName: calendar.companyBranchName,
          companyBranchId: calendar.companyBranchId,
          userCalendar: calendar.companyUsers.map((calendar) => ({
            companyUserId: calendar.companyUserId,
            companyUserName: calendar.companyUserName,
            companyUserPhoneNumber: calendar.companyUserPhoneNumber,
            companyUserEmail: calendar.companyUserEmail,
            events: calendar.entries.map((x) => ({
              start: new Date(x.startDate),
              end: addSecondToDate(x.endDate),
              title: x.description ? x.description : getCalendarEntryTypeName(x.type),
              type: x.type,
              resource: { ...x, companyUserId: calendar.companyUserId },
            })),
          })),
        })),
      );
    } else {
      setUserCalendars([]);
    }
  }, [bookingCalendar]);

  return (
    <>
      <Grid item container xs={12}>
        <Calendar<ICalendarEntryWithUser>
          viewDate={date}
          calendars={userCalendars}
          onDateChanged={(d) => setDate(d)}
          onSave={handleSave}
          onRemove={handleRemove}
          onReload={loadData}
          loading={loading}
          query={setQuery}
        />
      </Grid>
    </>
  );
};

export default BookingCalendar;
