import React from 'react';
import { useParams } from 'react-router-dom';
import FacilityList from './FacilityList/FacilityList';

const CustomerProfile = () => {
  const { customerId } = useParams<{ companyId: string; customerId: string }>();

  return <FacilityList customerId={customerId} />;
};

export default CustomerProfile;
