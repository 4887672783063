import React, { useCallback, useEffect, useState } from 'react';
import { Table } from '../../../../../Shared/Table/Table';
import { TableRow, TableCell, TableBody } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { isErrorResponse, ErrorCode } from '../../../../../Shared/Api/response/IErrorRespose';
import { ICustomersDto, useGetCustomers } from '../../MyPages.api';
import { useStyles } from './MyCustomers.styles';
import Button from '../../../../../Shared/Button/Button';
import CustomerInformation from '../Customer-Information/CustomerInformation';

interface IProps {
  companyId: number;
}

export default function MyCustomers(props: IProps) {
  const history = useHistory();
  const [customersArray, setCustomers] = useState<ICustomersDto[]>([]);
  const { token } = useParams<{ token: string | undefined }>();
  const { getCustomers } = useGetCustomers(token, props.companyId);
  const [customerId, setCustomerId] = useState<string | undefined>();

  const classes = useStyles();

  const loadCustomers = useCallback(async () => {
    if (token === undefined) {
      history.push(`${history.location.pathname}/404`);
    }

    const result = await getCustomers();
    if (!result || (isErrorResponse(result) && result.errorCode === ErrorCode.AnonymousTokenNotFound)) {
      history.push(`${history.location.pathname}/404`);
    } else {
      if (result.length === 1) {
        setCustomerId(result[0].id.toString());
        return;
      }

      setCustomers(result);
      setCustomerId(undefined);
    }
  }, [getCustomers, history, token]);

  useEffect(() => {
    loadCustomers();
  }, [loadCustomers]);

  const dom = (
    <>
      {customerId ? (
        <div>
          {customersArray.length > 1 && (
            <div className={classes.back}>
              <Button
                onClick={() => {
                  setCustomerId('');
                }}
              >
                Tillbaka
              </Button>
            </div>
          )}
          <CustomerInformation customerId={customerId} companyId={props.companyId}></CustomerInformation>
        </div>
      ) : (
        <Table customSize={{ fullsize: true }} label="Företag">
          <TableBody>
            {customersArray.map((customer) => (
              <TableRow key={customer.id} className={classes.pointerCursor} hover tabIndex={-1}>
                <>
                  <TableCell
                    onClick={() => {
                      setCustomerId(customer.id.toString());
                    }}
                  >
                    {customer.name}
                  </TableCell>
                </>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );

  return dom;
}
