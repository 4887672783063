import { Dialog, DialogContent, Grid } from '@material-ui/core';
import React from 'react';
import DialogForm from '../../../../../../Shared/DialogForm/DialogForm';
import { useStyles } from './RemoveOrArchiveFacilityModal.style';
import { DialogContentHeader } from '../../../../../../Shared/DialogContentHeader/DialogContentHeader';
import Button from '../../../../../../Shared/Button/Button';
import { isErrorResponse } from '../../../../../../Shared/Api/response/IErrorRespose';
import useErrorMessage from '../../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import { useArchiveFacility, useDeleteFacility } from '../Facilities.api';

export interface IRemoveFacilityModalProps {
  customerId: number;
  facilityId: number | undefined;
  agreementCount: number;
  open: boolean;
  setOpen: (open: boolean) => void;
  loadList?: () => void;
  isArchive?: boolean;
}

const RemoveOrArchiveFacilityModal = (props: IRemoveFacilityModalProps) => {
  const classes = useStyles();
  const { customerId, facilityId, open, setOpen, loadList, isArchive } = props;
  const { isLoading, deleteFacility } = useDeleteFacility(customerId);
  const { isLoading: archiveIsLoading, archiveFacility } = useArchiveFacility(customerId);
  const { setErrorMessage } = useErrorMessage();
  const handleDeleteOrArchiveFacility = async () => {
    if (facilityId) {
      if (!isArchive) {
        const result = await deleteFacility(facilityId);
        if (!isErrorResponse(result)) {
          setOpen(false);
          loadList && loadList();
        } else {
          setErrorMessage({ message: 'Kunde inte avsluta avtal kopplade till systemet/platsen.' });
        }
      } else {
        const result = await archiveFacility(facilityId);
        if (!isErrorResponse(result)) {
          setOpen(false);
          loadList && loadList();
        } else {
          setErrorMessage({ message: 'Kunde inte avsluta avtal kopplade till systemet/platsen.' });
        }
      }
    }
  };

  const Action = () => (
    <>
      <Button color="default" variant="outlined" onClick={() => setOpen(false)}>
        Nej
      </Button>
      <Button
        loading={isLoading || archiveIsLoading}
        className={classes.cautionButton}
        variant="outlined"
        onClick={handleDeleteOrArchiveFacility}
      >
        Ja
      </Button>
    </>
  );

  return (
    <Dialog open={open} onClose={setOpen}>
      <DialogContent>
        <DialogContentHeader
          onClose={() => setOpen(false)}
          title={`${isArchive ? 'Arkivera' : 'Ta bort'} plats`}
          titleId={facilityId?.toString() ?? ''}
        />
        <DialogForm actions={<Action />}>
          <Grid container item>
            Det finns {props.agreementCount}st avtal kopplade till systemet/platsen. Vill du avsluta dessa? Avtalen
            kommer att sägas upp med omedelbar verkan.
          </Grid>
        </DialogForm>
      </DialogContent>
    </Dialog>
  );
};

export default RemoveOrArchiveFacilityModal;
