import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import AccessGuard from '../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { AccessRights, ModuleIdentifiers } from '../../../../../Core/Authentication/ModuleAccess';
import EmptyListIcon from '../../../../../Styles/Icons/EmptyListIcon';
import Container from '../../../../../Shared/Container/Container';
import SearchInput from '../../../../../Shared/InputFields/SearchInput/SearchInput';
import { uniqueValues } from '../../../../Shared/utils';
import { IBMSNode, useSearchBMSTreeNodes } from '../../BMS.api';
import useStyles from './NavigationMenu.syles';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';

const emptyNumberArray: number[] = [];
export function getNodeMenuName(node: IBMSNode) {
  return `${node.sectionNumber} ${node.name}`;
}

function getOpenNodesFromApiReturn(newNodes: IBMSNode[]) {
  var parentIds = newNodes.filter((x) => !!x.parentNodeId).map((x) => x.parentNodeId!);
  if (!parentIds.length) return emptyNumberArray;
  return uniqueValues(parentIds);
}

const BMSNavigationMenu = (props: { rootNodeId?: number | null; setRootNodeId: (id: number | null) => void }) => {
  const classes = useStyles();
  const history = useHistory();
  const { rootNodeId, setRootNodeId } = props;
  const { id } = useParams<{ id?: string }>();
  const { isLoading, nodes, searchNodes } = useSearchBMSTreeNodes();
  const [openNodes, setOpenNodes] = useState<number[]>([]);
  const [openNodesWithQuery, setOpenNodesWithQuery] = useState<number[]>();
  const [query, setQuery] = useState<string>('');
  const [focusNode, setFocusNode] = useState<number>(0);
  const isQueryActive = useRef(false);
  const basePath = '/bms';
  const nodeId = id === undefined ? undefined : Number(id);
  const idNodeNotShown = !!nodeId && nodes?.find((x) => x.id === nodeId) === undefined;

  useEffect(() => {
    if (nodeId !== undefined && isNaN(nodeId)) history.push(basePath);
  }, [nodeId, history]);

  const fetchNodes = useCallback(
    async (query?: string) => {
      if (!nodeId && !query) {
        return await searchNodes({});
      }
      if (!query) {
        var newNodes = await searchNodes({ openNodes: openNodes, selectedNode: nodeId });
        setOpenNodes(getOpenNodesFromApiReturn(newNodes));
        return newNodes;
      } else {
        var newQueryNodes = await searchNodes({ query: query });
        setOpenNodesWithQuery(getOpenNodesFromApiReturn(newQueryNodes));
        return newQueryNodes;
      }
    },
    [nodeId, openNodes, searchNodes],
  );

  useEffect(() => {
    const refetch = async () => {
      var newNodes = await fetchNodes();
      let rootNodes = newNodes?.filter((x) => x.parentNodeId === null);
      let root = rootNodes && rootNodes.length ? rootNodes[0].id : null;
      setRootNodeId(root);
      //Open first root
      if (root !== null) {
        setFocusNode(root);
        setOpenNodes([root as number]);
      }
    };

    if (rootNodeId === undefined) refetch();
    else {
      if (query) {
        isQueryActive.current = true;
        fetchNodes(query);
      } else {
        setOpenNodesWithQuery(undefined);
        if (idNodeNotShown || isQueryActive.current) {
          fetchNodes();
        }
        isQueryActive.current = false;
      }
    }
  }, [idNodeNotShown, query, rootNodeId, fetchNodes, setRootNodeId]);

  const onToggleNode = async (nodeId: number, isNodeOpen: boolean) => {
    setFocusNode(nodeId);
    if (isNodeOpen) {
      //Close node: remove from list
      query
        ? setOpenNodesWithQuery((state) => state && state.filter((x) => x !== nodeId))
        : setOpenNodes((state) => state.filter((x) => x !== nodeId));
    } else {
      //Open node: add to list and fetch children;
      if (query) {
        setOpenNodesWithQuery((x) => {
          if (!x) return [nodeId];
          return [...x, nodeId];
        });
      } else {
        let newState = [...openNodes, nodeId];
        await searchNodes({ openNodes: newState });
        setOpenNodes(newState);
      }
    }
  };

  const BMSNode = (node: IBMSNode) => {
    var isNodeOpen = openNodesWithQuery ? openNodesWithQuery.includes(node.id) : openNodes.includes(node.id);

    return (
      <div key={node.id}>
        <div
          className={
            focusNode === node.id || focusNode === node.parentNodeId ? classes.focusNode : classes.notFocusNode
          }
        >
          <ListItem
            button
            style={
              node.treeLevel < 2
                ? {
                    paddingLeft: `${!node.hasChildren ? node.treeLevel * 2 : 0}rem`,
                  }
                : node.treeLevel <= 7
                ? {
                    paddingLeft: `${!node.hasChildren ? node.treeLevel * 2 : (node.treeLevel - 1) * 2}rem`,
                    paddingTop: '3px',
                    paddingBottom: '3px',
                  }
                : { paddingLeft: `14rem` }
            }
            onClick={() => {
              history.push(`${basePath}/${node.id}`);
              onToggleNode(node.id, isNodeOpen);
            }}
          >
            {node.hasChildren && (
              <ListItemIcon>
                <IconButton
                  className={classes.nodeIcon}
                  data-testid={`expand_node_${node.id}`}
                  onClick={(event) => {
                    onToggleNode(node.id, isNodeOpen);
                    event.stopPropagation();
                  }}
                >
                  {isNodeOpen ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </ListItemIcon>
            )}
            <ListItemText className={classes.nodeName} primary={getNodeMenuName(node)} />
          </ListItem>
        </div>
        {node.hasChildren && (
          <Collapse in={isNodeOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {nodes!.filter((x) => x.parentNodeId === node.id).map((childNode) => BMSNode(childNode))}
            </List>
          </Collapse>
        )}
      </div>
    );
  };

  return (
    <Container
      loading={isLoading}
      label={'VLS Verksamhetsledningssystem'}
      filters={<SearchInput type="search" fullWidth placeholder="Sök" onChange={setQuery} />}
      customSize={{ fullsize: true }}
      customActionHeader={
        <AccessGuard module={ModuleIdentifiers.BusinessManagementSystem} accessRights={AccessRights.Write}>
          <IconButton
            size="small"
            aria-label="Lägg till nod"
            onClick={() =>{ history.push(`${basePath}/create`); }}
          >
            <AddIcon />
          </IconButton>
        </AccessGuard>
      }
    >
      <List component="nav" className={classes.root} subheader={<ListSubheader component="div"></ListSubheader>}>
        <div className={classes.listItem}>
          {!!nodes && nodes.length > 0 ? (
            nodes.filter((x) => x.parentNodeId === null).map((node) => BMSNode(node))
          ) : (
            <div className={classes.empty}>
              <span className={classes.emptyMessage}>
                <EmptyListIcon />
              </span>
              <Typography>
                {query ? 'Filtreringen gav inga resultat' : 'Inga versamhetsledningssystem tillagda'}
              </Typography>
            </div>
          )}
        </div>
      </List>
    </Container>
  );
};

export default BMSNavigationMenu;
