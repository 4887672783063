import React, { useEffect, useState } from 'react';
import { IErrorReportStepProps } from '../ReportForm';
import Container from '../../../../../../../../Shared/Container/Container';
import { Fieldset } from '../../../../../../../../Shared/Form/Fieldset/Fieldset';
import { InputField } from '../../../../../../../../Shared/InputFields/InputField/InputField';
import useFormValidation from '../../../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import useInputState from '../../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import {
  ErrorCategoryType,
  useSetMeasureAnalysisOther,
  ISetMeasureAnalysisOtherCommandValidationErrors,
  useSetMeasureAnalysisPersonFault,
  ISetMeasureAnalysisPersonFaultCommandValidationErrors,
  useGetProductFaultForm,
  IAddFaultyProductDto,
  useSetMeasureAnalysisProductFault,
  ISetMeasureAnalysisProductFaultCommandValidationErrors,
  ComplaintAction,
  ErrorReportState,
} from '../../../Reports.api';
import { isResultError } from '../../../../../../../../Shared/Api/response/ICreateResult';
import { Table } from '../../../../../../../../Shared/Table/Table';
import { TableHead, TableRow, TableCell, TableBody, Grid } from '@material-ui/core';
import Select, { ISelectOption } from '../../../../../../../../Shared/Select/Select';
import MultiButton from '../../../../../../../../Shared/MultiButton/MultiButton';
import MultiButtonItem from '../../../../../../../../Shared/MultiButton/MultiButtonItem/MultiButtonItem';
import InputLabel from '../../../../../../../../Shared/InputFields/InputLabel/InputLabel';

const complaintActionOptions: ISelectOption[] = [
  {
    label: 'Kredit',
    value: Number(ComplaintAction.Credit).toString(),
  },
  {
    label: 'Ersättningsprodukter',
    value: Number(ComplaintAction.NewProducts).toString(),
  },
];

const AddAnalyze: React.FunctionComponent<IErrorReportStepProps> = (props: IErrorReportStepProps) => {
  const { errorReport, onSave, errorReportIsLoading, children } = props;
  const { isFormValid, formRef } = useFormValidation();
  const stringId = errorReport?.id.toString();
  const { isLoading: setMeasureAnalysisOtherIsLoading, setMeasureAnalysisOther } = useSetMeasureAnalysisOther(stringId);
  const {
    isLoading: setMeasureAnalysisPersonFaultIsLoading,
    setMeasureAnalysisPersonFault,
  } = useSetMeasureAnalysisPersonFault(stringId);

  const {
    isLoading: setMeasureAnalysisProductFaultIsLoading,
    setMeasureAnalysisProductFault,
  } = useSetMeasureAnalysisProductFault(stringId);
  const { productFaultForm, isLoading: isProductFaultFormLoading, getProductFaultForm } = useGetProductFaultForm(
    stringId,
  );
  const [causeAnalysis, changeCauseAnalysis, setCauseAnalysis] = useInputState('');
  const [correctiveAction, changeCorrectiveAction, setCorrectiveAction] = useInputState('');

  const [analysisStatus, changeAnalysisStatus, setAnalysisStatus] = useInputState('');

  const [complaintMessage, changeComplaintMessage, setComplaintMessage] = useInputState('');
  const [complaintAction, setComplaintAction] = useState<ComplaintAction>(ComplaintAction.Credit);
  const [faultyProducts, setFaultyProducts] = useState<IAddFaultyProductDto[]>([]);

  const [analysisOtherErrors, setAnalysisOtherErrors] = useState<ISetMeasureAnalysisOtherCommandValidationErrors>();
  const [analysisPersonFaultErrors, setAnalysisPersonFaultErrors] = useState<
    ISetMeasureAnalysisPersonFaultCommandValidationErrors
  >();
  const [measureAnalysisProductFaultErrors, setMeasureAnalysisProductFaultErrors] = useState<
    ISetMeasureAnalysisProductFaultCommandValidationErrors
  >();
  const [savingWithGoNextStep, setSavingWithGoNextStep] = useState<boolean>();

  const isSaveLoading =
    setMeasureAnalysisOtherIsLoading ||
    setMeasureAnalysisProductFaultIsLoading ||
    setMeasureAnalysisPersonFaultIsLoading;

  const isPersonFault =
    errorReport?.errorCategoryType === ErrorCategoryType.Installation ||
    errorReport?.errorCategoryType === ErrorCategoryType.Sales;

  const isValid =
    isFormValid && (errorReport?.errorCategoryType !== ErrorCategoryType.ProductFault || faultyProducts.length > 0);

  useEffect(() => {
    if (errorReport) {
      errorReport.causeAnalysis && setCauseAnalysis(errorReport.causeAnalysis);
      errorReport.correctiveAction && setCorrectiveAction(errorReport.correctiveAction);

      errorReport.analysisStatus && setAnalysisStatus(errorReport.analysisStatus);

      errorReport.complaintMessage && setComplaintMessage(errorReport.complaintMessage);
      errorReport.complaintAction && setComplaintAction(errorReport.complaintAction);
      setFaultyProducts(errorReport.faultyProducts);

      if (errorReport.errorCategoryType === ErrorCategoryType.ProductFault) {
        getProductFaultForm();
      }
    }
  }, [errorReport, setCauseAnalysis, setCorrectiveAction, setAnalysisStatus, getProductFaultForm, setComplaintMessage]);

  async function handleSave(goToNextStep: boolean) {
    setSavingWithGoNextStep(goToNextStep);
    if (errorReport) {
      let result;
      if (errorReport.errorCategoryType === ErrorCategoryType.Other) {
        result = await setMeasureAnalysisOther({ causeAnalysis, correctiveAction });
        setAnalysisOtherErrors(result);
      } else if (isPersonFault) {
        result = await setMeasureAnalysisPersonFault({ analysisStatus });
        setAnalysisPersonFaultErrors(result);
      } else if (errorReport.errorCategoryType === ErrorCategoryType.ProductFault) {
        result = await setMeasureAnalysisProductFault({
          complaintMessage: complaintMessage,
          complaintAction: complaintAction as ComplaintAction,
          products: faultyProducts,
        });
        setMeasureAnalysisProductFaultErrors(result);
      }

      if (!result || !isResultError(result)) {
        onSave(goToNextStep);
      }
    }
  }

  function changeFaultyProductQty(productId: number, qty: number) {
    const newList = faultyProducts.filter((x) => x.productId !== productId);
    var saleProduct = productFaultForm?.products.find((x) => x.productId === productId);
    if (saleProduct) {
      qty = Math.min(saleProduct.quantity, qty);
      if (qty > 0) newList.push({ productId, quantity: qty });

      setFaultyProducts(newList);
    }
  }

  function Actions() {
    return (
      <>
        {props.actions}
        {errorReport?.errorReportState !== ErrorReportState.Completed && (
          <MultiButton
            color="primary"
            variant="contained"
            disabled={!isValid || isSaveLoading}
            loading={isSaveLoading && savingWithGoNextStep}
            onClick={() => handleSave(true)}
            menuItems={
              <MultiButtonItem
                disabled={!isValid || isSaveLoading}
                loading={isSaveLoading && !savingWithGoNextStep}
                onClick={() => handleSave(false)}
              >
                Spara och stäng
              </MultiButtonItem>
            }
          >
            Spara och gå till nästa steg
          </MultiButton>
        )}
      </>
    );
  }

  return (
    <Container
      form
      ref={formRef}
      loading={errorReportIsLoading || isProductFaultFormLoading}
      label="Redigera felrapport"
      actions={<Actions />}
    >
      {children}
      <Fieldset legend="Steg 3 - Analys">
        {errorReport?.errorCategoryType === ErrorCategoryType.Other && (
          <>
            <InputField
              fullwidth
              required
              label="Analys av orsak"
              multiline
              rows={5}
              type="text"
              value={causeAnalysis}
              onChange={changeCauseAnalysis}
              errorText={analysisOtherErrors?.causeAnalysis}
            />
            <InputField
              fullwidth
              required
              label="Korrigerande åtgärd"
              multiline
              rows={5}
              type="text"
              value={correctiveAction}
              onChange={changeCorrectiveAction}
              errorText={analysisOtherErrors?.correctiveAction}
            />
          </>
        )}
        {isPersonFault && (
          <>
            <InputField fullwidth viewOnly label="Ansvarig" type="text" value={errorReport?.responsiblePerson ?? 'Ansvarig saknas'} />
            <InputField
              fullwidth
              required
              label="Status"
              multiline
              rows={5}
              type="text"
              value={analysisStatus}
              onChange={changeAnalysisStatus}
              errorText={analysisPersonFaultErrors?.analysisStatus}
            />
          </>
        )}
        {errorReport?.errorCategoryType === ErrorCategoryType.ProductFault && (
          <>
            <Grid item container xs={12} direction="column">
              <InputLabel label="Produkter" />
              <Table
                loading={{
                  loading: isProductFaultFormLoading,
                  skeletonRows: 3,
                }}
                customSize={{
                  fullsize: true,
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Leverantör</TableCell>
                    <TableCell>Artikelnummer</TableCell>
                    <TableCell>Namn</TableCell>
                    <TableCell>Antal på order</TableCell>
                    <TableCell>Antal på reklamation</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productFaultForm?.products.map((p) => (
                    <TableRow key={p.productId}>
                      <TableCell>{p.supplierName}</TableCell>
                      <TableCell>{p.productNumber}</TableCell>
                      <TableCell>{p.productName}</TableCell>
                      <TableCell>{p.quantity}</TableCell>
                      <TableCell>
                        <InputField
                          type="number"
                          value={faultyProducts.find((x) => x.productId === p.productId)?.quantity}
                          onChange={(e) => changeFaultyProductQty(p.productId, Number(e.target.value))}
                        ></InputField>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <InputField
              fullwidth
              required
              label="Reklamation"
              multiline
              rows={5}
              type="text"
              value={complaintMessage}
              onChange={changeComplaintMessage}
              errorText={measureAnalysisProductFaultErrors?.complaintMessage}
            />
            <Select
              inputFieldProps={{
                label: 'Önskad åtgärd',
                required: true,
                errorText: measureAnalysisProductFaultErrors?.complaintAction,
              }}
              selectProps={{
                value: complaintActionOptions.find((x) => x.value === Number(complaintAction)?.toString()),
                onChange: (selectedValue) => {
                  setComplaintAction(Number((selectedValue as ISelectOption).value));
                },
                options: complaintActionOptions,
                isClearable: false,
              }}
            />
          </>
        )}
      </Fieldset>
    </Container>
  );
};

export default AddAnalyze;
