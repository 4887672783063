import React, { useEffect, useCallback, useState } from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import {
  IGetCompanyUserMessagesResult,
  useGetCompanyUserMessages,
  IUpsertCompanyUserMessageRequestBody,
  useUpsertCompanyUserMessage,
  useDeleteCompanyMessage,
  useCreateTodoFromCompanyUserMessage,
} from '../Forum.api';
import EditCompanyUserMessage from './EditCompanyUserMessage/EditCompanyUserMessage';
import MessageMenu from './MessageMenu/MessageMenu';
import { isErrorResponse } from '../../../../Shared/Api/response/IErrorRespose';
import useErrorMessage from '../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import WidgetContainer from '../../../../Shared/WidgetContainer/WidgetContainer';
import { useStyles } from './CompanyUserMessage.styles';
import { ICurrentUser, useGetCurrentUser } from '../../Admin/Components/Users/Users.api';
import { formatDateWithTime } from '../../../../Shared/Formatting/formatDate';
import PopperMenu from '../../../../Shared/Popper/Popper';
import CompanyUserMessageForm from '../CompanyUserMessage/CompanyUserMessageForm/CompanyUserMessageForm';
import AccessGuard from '../../../../Core/Authentication/AccessGuard/AccessGuard';
import { AccessRights, ModuleIdentifiers } from '../../../../Core/Authentication/ModuleAccess';
import moment from 'moment';

function isPostedByCurrentUser(message: IGetCompanyUserMessagesResult, user: ICurrentUser) {
  return message.senderCompanyUserId === (user.impersonatedCompanyUser?.id ?? user.selectedUserId);
}

interface ICompanyUserMessage {
  setKey?: React.Dispatch<React.SetStateAction<number>>;
}

export default function UserMessage(props: ICompanyUserMessage) {
  const classes = useStyles();
  const { response: currentUser, getCurrentUser } = useGetCurrentUser();
  const { companyUserMessages, getCompanyUserMessages } = useGetCompanyUserMessages();
  const { upsert } = useUpsertCompanyUserMessage();
  const { deleteCompanyMessage: deleteCompanyUserMessage } = useDeleteCompanyMessage();
  const { post } = useCreateTodoFromCompanyUserMessage();
  const { setErrorMessage } = useErrorMessage();

  const [open, setOpen] = useState(false);
  const [editCompanyUserMessage, setEditCompanyUserMessage] = useState<IGetCompanyUserMessagesResult | undefined>(
    undefined,
  );

  const loadData = useCallback(async () => {
    getCurrentUser();
    getCompanyUserMessages();
  }, [getCompanyUserMessages, getCurrentUser]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  async function save(body: IUpsertCompanyUserMessageRequestBody, id?: string) {
    const result = await upsert(body, id);
    if (isErrorResponse(result)) {
      setErrorMessage({ message: result.message });
    } else {
      setOpen(false);
      loadData();
    }
  }

  async function handleDelete(messageId: string) {
    const result = await deleteCompanyUserMessage(messageId);
    if (isErrorResponse(result)) {
      setErrorMessage({ message: result.message });
    } else {
      loadData();
    }
  }

  async function handleCreateTodoFromUserMessage(userMessageId: string) {
    const result = await post(userMessageId);
    if (isErrorResponse(result)) {
      setErrorMessage({ message: result.message });
    } else {
      props.setKey && props.setKey((prev) => prev + 1);
    }
  }

  function handleEditCompanyUserMessageModal(message: IGetCompanyUserMessagesResult) {
    if (currentUser && isPostedByCurrentUser(message, currentUser)) {
      setEditCompanyUserMessage(message);
      setOpen(true);
    }    
  }

  return (
    <>
      <WidgetContainer label={`Forum (${companyUserMessages?.length ?? 0})`} actions={<EditCompanyUserMessage onSave={save}></EditCompanyUserMessage>}>
        {(currentUser && companyUserMessages && companyUserMessages.length > 0) && (
          <ul className={classes.list} aria-label="Meddelanden från användare">
            {companyUserMessages.sort((a,b) => moment(b.created).diff(a.created)).map((message) => (
              <li key={message.id} className={classes.listItem}>
                <div
                  className={
                    isPostedByCurrentUser(message, currentUser) &&
                    !message.recipientCompanyUserIds?.includes(currentUser.id)
                      ? classes.messageContainerPersonal
                      : classes.messageContainer
                  }
                >
                  <div className={classes.message} onClick={() => handleEditCompanyUserMessageModal(message)}>
                    <div style={{ display: 'grid', justifyItems: 'right', gridTemplateColumns: 'auto auto auto', justifyContent: 'space-between' }}>
                      <Typography className={classes.dateTime}>{formatDateWithTime(message.created)}</Typography>
                      <Typography className={classes.author}>
                        <span className={'sr-only'}>Avsändare:</span>
                        Från: {message.senderName}
                      </Typography>
                      <Tooltip title={message.allCompanyUsersSelected ? 'Alla'
                            : message.recipientNames && message.recipientNames.join(', ')}>
                        <Typography className={classes.author}>
                          <span className={'sr-only'}>Mottagare:</span>
                          Till:{' '}
                          {message.allCompanyUsersSelected
                            ? 'Alla'
                            : message.recipientNames && message.recipientNames?.length < 2
                            ? message.recipientNames.join(', ')
                            : message.recipientNames.slice(0,1).join(', ') + ', +' + (message.recipientNames.length - 1).toString()
                          }
                        </Typography>
                      </Tooltip>
                    </div>
                    <Typography>{message.description}</Typography>
                  </div>
                  <div className={classes.dropdown}>
                    <AccessGuard module={ModuleIdentifiers.Forum} accessRights={AccessRights.Write}>
                      <MessageMenu
                        isPostedByCurrentUser={isPostedByCurrentUser(message, currentUser)}
                        userMessage={message}
                        handleEditCompanyUserMessage={() => handleEditCompanyUserMessageModal(message)}
                        handleCreateTodo={() => handleCreateTodoFromUserMessage(message.id.toString())}
                        onDelete={() => handleDelete(message.id.toString())}
                      />
                    </AccessGuard>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )
      }
      </WidgetContainer>
      <AccessGuard
        module={ModuleIdentifiers.Forum}
        accessRights={AccessRights.Write}
        fallback={
          <PopperMenu
            setOpen={setOpen}
            open={open}
            onClose={() => {
              setOpen(false);
              setEditCompanyUserMessage(undefined);
            }}
            disabled
            customContent
            alwaysDialog
            title={'Meddelande'}
            content={
              <CompanyUserMessageForm
                onCancel={() => {
                  setOpen(false);
                  setEditCompanyUserMessage(undefined);
                }}
                onSave={save}
                message={editCompanyUserMessage}
              />
            }
          />
        }
      >
        <PopperMenu
          setOpen={setOpen}
          open={open}
          onClose={() => {
            setOpen(false);
            setEditCompanyUserMessage(undefined);
          }}
          disabled
          customContent
          alwaysDialog
          title={'Lägg till Forum'}
          content={
            <CompanyUserMessageForm
              onCancel={() => {
                setOpen(false);
                setEditCompanyUserMessage(undefined);
              }}
              onSave={save}
              message={editCompanyUserMessage}
            />
          }
        />
      </AccessGuard>
    </>
  );
}
