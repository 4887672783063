import React, { ReactNode, useState, FC, useEffect } from 'react';
import {
  Calendar as BigCalendar,
  EventProps,
  EventWrapperProps,
  momentLocalizer,
  Event as CalendarEvent,
} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useUserContext from '../../../../Core/Authentication/UserContext';
import Container from '../../../../Shared/Container/Container';
import Toolbar, { Stepping } from '../Components/Toolbar/Toolbar';
import EventWrapper from '../Components/EventWrapper/EventWrapper';
import { ScrollingText } from '../Components/ScrollingText/ScrollingText';
import EditActivity from '../Components/EditActivity/EditActivity';
import Event from '../Components/Event/Event';
import { IActivity } from '../Components/ActivityForm/ActivityForm';
import { Messages } from '../Messages';
import { ICalendarEvent } from '../UserCalendar/UserCalendar';
import { HeaderCalendarContent } from '../Calendar/HeaderCalendarContent';
import { useGetHoliday } from '../../../Modules/Holiday/Holiday.api';
import { useSharedCalendarStyles } from '../Components/SharedCalendar.styles';
import { useStyles } from '../Calendar/Calendar.styles';
import '../Calendar/Calendar.css';
import moment from 'moment';
import { Role } from '../../../Modules/Admin/Components/Users/Users.api';
import MobileEvent from '../Components/MobileEvent/MobileEvent';
import { ICalendarEntry, calendarEntryTypes } from '../../../Modules/Booking/Booking.api';

export interface ICalendarProps {
  events?: ICalendarEvent<ICalendarEntry>[];
  viewDate: Date;
  onDateChanged: (date: Date) => void;
  onSave?: (activity: IActivity) => void;
  onRemove?: (id: number) => void;
  toolbarActions?: ReactNode;
}
export interface ExtendedEventProps extends EventProps {
  userId: number;
}
const dateFormat = { dayFormat: 'ddd DD MMMM' };
export default function CompanyCalendar<TResource extends object = object>(props: ICalendarProps) {
  const classes = useStyles();
  const sharedClasses = useSharedCalendarStyles();
  const { onDateChanged, viewDate, onSave, onRemove, toolbarActions, ...rest } = props;
  const events = props.events ?? [];
  const localizer = momentLocalizer(moment);
  const [addActivity, setAddActivity] = useState<{ start: Date; end: Date }>();
  const [editActivity, setEditActivity] = useState<IActivity>();
  const { user } = useUserContext();
  const { getCheck } = useGetHoliday();
  const [holidayEvent, setHolidayEvent] = useState<CalendarEvent[]>([]);

  useEffect(() => {
    async function getList() {
      const result = await getCheck();
      setHolidayEvent(
        result
          ? result.map((x) => ({
              start: new Date(x.date),
              end: new Date(x.date),
              allDay: true,
              title: x.name,
            }))
          : [],
      );
    }
    getList();
  }, [getCheck]);

  // Custom component for Event (adding custom color based on resource number, maybe there's a better way)
  const CustomEventWrapper: FC<EventWrapperProps<ICalendarEvent<ICalendarEntry>>> = (props) => {
    return (
      <EventWrapper
        onSave={onSave}
        onRemove={onRemove}
        activity={props.event.resource}
        type={props.event.type}
        isCompanyCalendar={true}
        showInCompanyCalendarByDefault={true}
      >
        {props.children}
      </EventWrapper>
    );
  };

  // Custom component for event
  function CustomEvent(props: EventProps<ICalendarEntry & CalendarEvent>) {
    const { event } = props;
    const customTitle = event.resource?.otherActivity ?? event.resource?.customerName;
    return (
      <Event
        isCompanyCalendar
        users={event.resource.companyUserNames}
        label={event.resource.type}
        start={event.start}
        end={event.end}
        isBlueBooking={
          moment(props.event.start).format('HH:mm') === '00:00' && moment(props.event.end).format('HH:mm') === '00:00'
        }
        customTitle={customTitle}
      />
    );
  }

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <Container customHeader={mobile ? null : <ScrollingText />} customSize={{ md: 12 }}>
        <div className={sharedClasses.calendarContainer}>
          <div className={sharedClasses.innerContainer}>
            <Toolbar
              editProps={{
                companyUserId: user.selectedUserId ?? undefined,
                onSave: onSave,
                open: !!addActivity,
                start: addActivity?.start,
                end: addActivity?.end,
                onClose: () => setAddActivity(undefined),
                isCompanyCalendar: true,
                showInCompanyCalendarByDefault: true,
              }}
              stepping={mobile ? Stepping.Day : Stepping.Week}
              date={viewDate}
              onDateChanged={onDateChanged}
              actions={toolbarActions}
            />
            <BigCalendar
              tooltipAccessor={(e) => ''}
              formats={dateFormat}
              localizer={localizer}
              events={holidayEvent}
              date={viewDate}
              onNavigate={() => {}}
              startAccessor="start"
              endAccessor="end"
              view={mobile ? 'day' : 'week'}
              views={['day', 'week']}
              onView={() => {}}
              timeslots={24}
              messages={Messages}
              min={new Date(0, 0, 0, 6, 0, 0)}
              max={new Date(0, 0, 0, 20, 0, 0)}
              className={`${classes.main} ${classes.companyCal}`}
              toolbar={false}
              components={{
                week: {
                  header: HeaderCalendarContent,
                },
              }}
            />
          </div>
          <div className={classes.calendar}>
            {mobile ? 
              <ul>
                {events.map((event) => {
                  const customTitle = event.resource?.otherActivity ?? event.resource?.customerName;
                  return <EventWrapper
                    onSave={onSave}
                    onRemove={onRemove}
                    activity={event.resource}
                    type={event.type}
                    isCompanyCalendar={true}
                    showInCompanyCalendarByDefault={true}
                  >
                    <MobileEvent
                      isCompanyCalendar
                      users={event.resource.companyUserNames}
                      label={event.resource.type}
                      start={event.start}
                      end={event.end}
                      isBlueBooking={
                        moment(event.start).format('HH:mm') === '00:00' && moment(event.end).format('HH:mm') === '00:00'
                      }
                      customTitle={customTitle}
                    />
                  </EventWrapper>
                })}
                {events.length === 0 && <span>Inga bokningar idag</span>}
              </ul>
              :
            <BigCalendar<ICalendarEvent<ICalendarEntry>, TResource>
              {...rest}
              tooltipAccessor={(e) => ''}
              showMultiDayTimes
              localizer={localizer}
              messages={Messages}
              events={events}
              view={mobile ? 'day' : 'week'}
              defaultView={mobile ? 'day' : 'week'}
              views={['day', 'week']}
              onView={() => {}}
              timeslots={12}
              step={60}
              date={viewDate}
              onNavigate={(date) => {
                if (date !== viewDate) {
                  onDateChanged(date);
                }
              }}
              components={{
                eventWrapper: CustomEventWrapper,
                event: CustomEvent as (event: CalendarEvent) => JSX.Element,
              }}
              className={classes.companyCalendarChild}
              selectable
              onSelectEvent={(x) => {
                setEditActivity(x.resource);
              }}
              onSelectSlot={(info) => {
                var startDate = info.start as Date;
                var endDate = info.end as Date;
                startDate.setHours(0, 0);
                endDate.setHours(0, 0);
                setAddActivity({ start: startDate, end: endDate });
              }}
              dayLayoutAlgorithm="no-overlap"
            />}
          </div>
        </div>
      </Container>
      {
        <EditActivity
          onSave={onSave}
          onRemove={onRemove}
          onClose={() => setEditActivity(undefined)}
          editActivityId={editActivity?.id}
          open={!!editActivity}
          showInCompanyCalendarByDefault={false}
          isCompanyCalendar={false}
          inCalendar
          activityLabel={(calendarEntryTypes.find((c) => c.type === editActivity?.type)?.name ?? 'Aktivitet') + (editActivity?.facilityName ? ': ' + editActivity?.facilityName : editActivity?.otherActivity ? (': ' + editActivity?.otherActivity) : '')}
          installerCalendar={user.roles?.some((x) => x === Role.Installer)}
        />
      }
    </>
  );
}
