import React, { useState } from 'react';
import Container from '../../../../Shared/Container/Container';
import Button from '../../../../Shared/Button/Button';
import { useHistory } from 'react-router-dom';
import useFormValidation from '../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import { isResultError } from '../../../../Shared/Api/response/ICreateResult';
import useInputState from '../../../../Shared/Hooks/UseInputState/UseInputState';
import { Fieldset } from '../../../../Shared/Form/Fieldset/Fieldset';
import { InputField } from '../../../../Shared/InputFields/InputField/InputField';
import EmailField from '../../../../Shared/InputFields/EmailField/EmailField';
import { IAdministratorValidationError, ICreateAdministrator, useCreateAdministrator } from '../Administration.api';

const AdministrationForm = () => {
  const history = useHistory();
  const [errors, setErrors] = useState<IAdministratorValidationError>();
  const { isLoading, createAdministrator } = useCreateAdministrator();
  const { isFormValid, formRef } = useFormValidation();
  const [firstName, changeFirstName] = useInputState('');
  const [lastName, changeLastName] = useInputState('');
  const [email, changeEmail] = useInputState('');

  async function createAdmin() {
    const Command: ICreateAdministrator = {
      firstName: firstName,
      lastName: lastName,
      email: email,
    };

    const result = await createAdministrator(Command);

    isResultError(result) ? setErrors(result) : history.push('/administration');
  }

  function Actions() {
    return (
      <>
        <Button color="default" variant="outlined" onClick={() => history.push('/administration')}>
          Avbryt
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => createAdmin()}
          disabled={!isFormValid}
          loading={isLoading}
        >
          Skapa
        </Button>
      </>
    );
  }

  return (
    <Container form label={'Skapa Administratör'} actions={<Actions />} ref={formRef}>
      <Fieldset legend="Allmänt">
        <InputField
          required
          label="Förnamn"
          type="text"
          value={firstName}
          onChange={changeFirstName}
          errorText={errors?.firstName}
        />

        <InputField
          required
          label="Efternamn"
          type="text"
          value={lastName}
          onChange={changeLastName}
          errorText={errors?.lastName}
        />

        <EmailField errorText={errors?.email} label="E-post" required value={email} onChange={changeEmail} />
      </Fieldset>
    </Container>
  );
};

export default AdministrationForm;
