import { Collapse, TableCell, TableRow } from '@material-ui/core';
import React, { useState } from 'react';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useStyles } from './SelectCollapseCell.styles';

export interface ISelectCellListProps<T extends ISelectCellItem> {
  items: T[];
  onItemSelect: (item: T) => void;
  header: string;
}

export interface ISelectCellItem {
  label: string;
  value: string;
}

function SelectCollapseCell<T extends ISelectCellItem = ISelectCellItem>(props: ISelectCellListProps<T>) {
  const { items, onItemSelect, header } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        className={classes.cursorPointer}
        hover
        tabIndex={-1}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <TableCell className={classes.headerCell}>
          <div>{header}</div>
          <div style={{ textAlign: 'right' }}>{open ? <ExpandMore /> : <ExpandLess />}</div>
        </TableCell>
      </TableRow>
      <Collapse className={classes.innerTableWrap} in={open} timeout="auto" unmountOnExit>
        {items.map((item) => (
          <TableRow key={item.value} hover tabIndex={-1}>
            <TableCell style={{ paddingLeft: '3rem' }} onClick={() => onItemSelect(item)}>
              {item.label}
            </TableCell>
          </TableRow>
        ))}
      </Collapse>
    </>
  );
}
export default SelectCollapseCell;
