import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      display: 'block',
      marginTop: '2vh',

      border: `none`,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: 1,
    margin: 10,
    justifyContent: 'center',
    minWidth: '20%',
    maxWidth: '30%',
    [theme.breakpoints.up('md')]: {
      width: '30%',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      maxWidth: '100%',
      width: '95%',
      flexGrow: 1,
      flexDirection: 'column',
      margin: '5xp',
      marginTop: '2vh',
    },
    '& .MuiGrid-grid-md-4': {
      margin: '0.1rem',
      minWidth: '55%',
      maxWidth: '55%',
    },
    '& .makeStyles-container-4': {
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        '& .makeStyles-container-33': {
          width: '33%',
        },
      },
    },
  },

  text: {
    width: '100%',
    marginBottom: '2rem',
  },
  table: {
    margin: 10,
    width: '36rem',

    [theme.breakpoints.down('sm')]: {
      width: '34rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '95%',
    },
  },
  pointerCursor: {
    cursor: 'pointer',
  },
}));
