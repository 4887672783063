import React from 'react';
import { TableRow, TableCell, Checkbox } from '@material-ui/core';
import { IFormat } from '../Format.api';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import IconButton from '../../../../../../Shared/IconButton/IconButton';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

interface IFormatRowProps {
  isEditing: boolean;
  format: IFormat;
  delete: () => void;
  update: (name: string, description: string, isVisible: boolean) => void;
}

const EditFormatRow = (props: IFormatRowProps) => {
  const { format, isEditing, update } = props;

  function updateFormat(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    update(e.target.value, format.description, format.isVisible);
  }

  function updateDescription(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    update(format.name, e.target.value, format.isVisible);
  }

  function updateIsVisible(e: React.ChangeEvent<HTMLInputElement>) {
    update(format.name, format.description, e.target.checked);
  }

  return (
    <TableRow>
      <TableCell>
        {isEditing ? (
          <InputField fullwidth placeholder="Format" value={format.name} onChange={updateFormat} />
        ) : (
          format.name
        )}
      </TableCell>

      <TableCell>
        {isEditing ? (
          <InputField fullwidth placeholder="Beskrivning" value={format.description} onChange={updateDescription} />
        ) : (
          format.description
        )}
      </TableCell>
      <TableCell>
        {isEditing ? (
          <Checkbox role="checkbox" color="primary" checked={format.isVisible} onChange={updateIsVisible} />
        ) : format.isVisible ? (
          <CheckBoxIcon color="primary" aria-label="icon enabled" />
        ) : (
          <CheckBoxOutlineBlankIcon color="disabled" aria-label="icon disabled" />
        )}
      </TableCell>

      {isEditing && (
        <TableCell>
          <IconButton color="error" onClick={props.delete} aria-label="Ta bort rad">
            <RemoveIcon />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
};

export default EditFormatRow;
