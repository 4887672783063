import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import {
  TableHead,
  TableRow,
  TableCell,
  ListItemIcon,
  TableBody,
  MenuItem,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import AccessGuard from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { ModuleIdentifiers, AccessRights } from '../../../../../../Core/Authentication/ModuleAccess';
import { Table } from '../../../../../../Shared/Table/Table';
import useTable from '../../../../../../Shared/Table/useTable';
import TablePaginationWithDatasource from '../../../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import SearchInput from '../../../../../../Shared/InputFields/SearchInput/SearchInput';
import SortButton from '../../../../../../Shared/Table/SortButton/SortButton';
import { DropdownCell } from '../../../../../../Shared/Table/DropdownCell/DropdownCell';
import Select, { ISelectOption } from '../../../../../../Shared/Select/Select';
import Datepicker from '../../../../../../Shared/InputFields/DatePicker/Datepicker';
import { SaleType, SaleTypeLabel, SaleTypes } from '../../../../../Shared/SaleType';
import { MobileTable } from '../../../../../../Shared/Table/MobileTable/MobileTable';
import { MobileTableRow } from '../../../../../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import { useStyles } from '../../../../../../Shared/Table/TableRowHeader/TableRowHeader.styles';
import { SortDirection } from '../../../../../../Shared/Api/request/IPaginatedRequest';
import formatDate from '../../../../../../Shared/Formatting/formatDate';
import CompanyUserSelect from '../../../../Admin/Components/Users/UserSelect/CompanyUserSelect';
import { ISaleTypeOption } from '../../Order/OrderList/OrderList';
import { statusList, SaleStatus, SaleStatusLabel, WorkflowStatus, useCancelSale, ISaleListItem } from '../../Sale.api';
import { useGetOffers } from '../Offer.api';
import theme from '../../../../../../Styles/Theme';
import IconButton from '../../../../../../Shared/IconButton/IconButton';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { ConfirmDeleteOrArchiveRow } from '../../../../../../Shared/Table/ConfirmDeleteOrArchiveRow/ConfirmDeleteOrArchiveRow';

const statusOptions: ISelectOption[] = statusList
  .filter((x) => x.workflowStatus === WorkflowStatus.Offer)
  .map((x) => ({ label: x.name, value: x.stringValue }));

const saleTypeOptions: ISaleTypeOption[] = SaleTypes.map((x) => ({
  label: x.name,
  value: x.type.toString(),
  type: x.type,
})).sort((a, b) => (a.label > b.label ? 1 : -1));

const OfferList = () => {
  const history = useHistory();
  const customerParams = new URLSearchParams(window.location.search).get('query');
  const {
    toggleSort,
    sortProperty,
    sortDirection,
    pageSize,
    setPageSize,
    setPageNumber,
    query,
    setQuery,
    paginatedRequest,
  } = useTable(100, '', SortDirection.Ascending, customerParams ?? '');
  const classes = useStyles();
  const mobileView = useMediaQuery(theme.breakpoints.down(1080 / 16));
  const columnCount = 5;

  const { isLoading, offers, getOffers } = useGetOffers();
  const { isLoading: isDeleteLoading, cancelSale } = useCancelSale();

  const [emptyMessage, setEmptyMessage] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<ISelectOption>();
  const [selectedUser, setSelectedUser] = useState<ISelectOption>();
  const [createdFrom, setCreatedFrom] = useState<Date | null>(null);
  const [createdTo, setCreatedTo] = useState<Date | null>(null);
  const [type, setType] = useState<SaleType>();
  const [confirmDeleteOfferId, setConfirmDeleteOfferId] = useState<number>();

  const getList = useCallback(async () => {
    const response = await getOffers({
      ...paginatedRequest,
      status: selectedStatus ? [(selectedStatus.value as unknown) as SaleStatus] : undefined,
      projectLeaderId: selectedUser ? Number(selectedUser.value) : undefined,
      createdFrom,
      createdTo,
      type: type,
    });

    if (response?.totalCount <= 0) {
      if (paginatedRequest.query) {
        setEmptyMessage('Filtreringen gav inga resultat');
      } else {
        setEmptyMessage('Inga offerter tillagda');
      }
    }
  }, [paginatedRequest, selectedStatus, selectedUser, type, createdFrom, createdTo, getOffers]);

  useEffect(() => {
    getList();
  }, [getList]);

  const onDatePickerChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setCreatedFrom(start);
    setCreatedTo(end);
  };

  async function handleCancelOffer(id: number) {
    await cancelSale(id.toString());
    setConfirmDeleteOfferId(undefined);
    getList();
  }

  const handleTableClick = (offer: ISaleListItem) => {
    history.push({
      pathname: history.location.pathname,
      search: query ? `?query=${query}` : '',
    });

    history.push(
      offer.status !== SaleStatus.draft
        ? `${history.location.pathname}/${offer.id}`
        : `${history.location.pathname}/${offer.id}/draft`,
    );
  };

  return (
    <Table
      label={`Offerter (${offers?.totalCount ?? 0})`}
      customSize={{
        fullsize: true,
      }}
      filters={
        <>
          <SearchInput
            defaultValue={query}
            type="search"
            placeholder="Sök"
            value={query}
            onChange={(search) => setQuery(search)}
          />
          <CompanyUserSelect
            isClearable
            selectedCompanyUser={selectedUser}
            onChange={(_1, _2, option) => setSelectedUser(option as ISelectOption)}
          />
          <Select
            inputFieldProps={{ 'aria-label': 'Typ', placeholder: 'Typ' }}
            selectProps={{
              options: saleTypeOptions,
              isClearable: true,
              onChange: (x) => (x ? setType((x as ISaleTypeOption).type) : setType(undefined)),
            }}
          />
          <Select
            inputFieldProps={{ 'aria-label': 'Status', placeholder: 'Status' }}
            selectProps={{
              isClearable: true,
              value: selectedStatus,
              onChange: (selectedValues) => {
                setSelectedStatus(selectedValues as ISelectOption);
              },
              options: statusOptions,
            }}
          />
          <Datepicker
            selectsRange
            isClearable
            inFilter
            dateFormat={'yy-MM-dd'}
            startDate={createdFrom}
            endDate={createdTo}
            placeholderText="Skapad fr.o.m - t.o.m"
            onChange={onDatePickerChange}
          />
        </>
      }
      iconButtons={
        <AccessGuard module={ModuleIdentifiers.Offers} accessRights={AccessRights.Write}>
          <IconButton
            size="small"
            aria-label="Lägg till offert"
            onClick={() => {
              history.push(`${history.location.pathname}/create`);
            }}
          >
            <AddIcon />
          </IconButton>
        </AccessGuard>
      }
      pagination={
        offers && (
          <TablePaginationWithDatasource
            datasource={offers}
            onChangePage={setPageNumber}
            onChangeRowsPerPage={setPageSize}
          />
        )
      }
      empty={{
        empty: !!(offers && offers.totalCount === 0),
        message: emptyMessage,
      }}
      loading={{
        loading: isLoading,
        skeletonRows: pageSize,
      }}
      mobile={mobileView}
    >
      {mobileView ? (
        <>
          {offers?.items.map((offer) => (
            <MobileTable
              key={offer.id}
              label={`${offer.saleIdentifier} - ${offer.customerName}`}
              link={
                offer.status !== SaleStatus.draft
                  ? `${history.location.pathname}/${offer.id}`
                  : `${history.location.pathname}/${offer.id}/draft`
              }
              action={
                confirmDeleteOfferId !== offer.id ? (
                  <AccessGuard module={ModuleIdentifiers.Offers} accessRights={AccessRights.Full}>
                    <DropdownCell mobile={mobileView}>
                      <MenuItem
                        onClick={() => {
                          setConfirmDeleteOfferId(offer.id);
                        }}
                      >
                        <ListItemIcon>
                          <DeleteIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">Ta bort</Typography>
                      </MenuItem>
                    </DropdownCell>
                  </AccessGuard>
                ) : (
                  <ConfirmDeleteOrArchiveRow
                    onConfirm={() => handleCancelOffer(offer.id)}
                    onCancel={() => {
                      setConfirmDeleteOfferId(undefined);
                    }}
                    colspan={columnCount}
                    label={' offert ' + offer.customerName}
                    loading={isDeleteLoading}
                  />
                )
              }
            >
              <MobileTableRow label="Offert nr" value={offer.saleIdentifier} />
              <MobileTableRow label="Kund" value={offer.customerName} />
              <MobileTableRow label="Projektledare" value={`${formatDate(offer.createdDate)} ${offer.projectLeader}`} />
              <MobileTableRow label="Typ" value={SaleTypeLabel[offer.type]} />
              <MobileTableRow
                label="Händelse"
                value={
                  offer.sentDate && offer.status === SaleStatus.pending_approval
                    ? `${formatDate(offer.sentDate)} ${SaleStatusLabel[offer.status]}`
                    : SaleStatusLabel[offer.status]
                }
              />
              <MobileTableRow label="Offertpris" value={offer.totalPrice} />
            </MobileTable>
          ))}
        </>
      ) : (
        <>
          <TableHead>
            <TableRow>
              <AccessGuard
                module={ModuleIdentifiers.Offers}
                accessRights={AccessRights.Full}
                fallback={<TableCell className={classes.narrowColumn}></TableCell>}
              >
                <TableCell>
                  <span className={'sr-only'}>Knappar</span>
                </TableCell>
              </AccessGuard>

              <TableCell>
                Offert nr
                <SortButton
                  property="salesNumber"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Kund
                <SortButton
                  property="customerName"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Projektledare
                <SortButton
                  property="projectLeader"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Typ
                <SortButton
                  property="type"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Rubrik
                <SortButton
                  property="description"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Händelse
                <SortButton
                  property="status"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Offertpris
                <SortButton
                  property="totalPrice"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {offers?.items.map((offer) => (
              <TableRow className={classes.pointerCursor} hover tabIndex={-1} key={offer.id}>
                {confirmDeleteOfferId !== offer.id ? (
                  <>
                    <AccessGuard
                      module={ModuleIdentifiers.Offers}
                      accessRights={AccessRights.Full}
                      fallback={<TableCell className={classes.narrowColumn}></TableCell>}
                    >
                      <DropdownCell>
                        <MenuItem
                          onClick={() => {
                            setConfirmDeleteOfferId(offer.id);
                          }}
                        >
                          <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                          <Typography variant="inherit">Ta bort</Typography>
                        </MenuItem>
                      </DropdownCell>
                    </AccessGuard>

                    <TableCell onClick={() => handleTableClick(offer)} className={classes.linkFont}>
                      {offer.saleIdentifier}
                    </TableCell>
                    <TableCell onClick={() => handleTableClick(offer)}>{offer.customerName}</TableCell>
                    <TableCell onClick={() => handleTableClick(offer)}>
                      {formatDate(offer.createdDate)} {offer.projectLeader}
                    </TableCell>
                    <TableCell onClick={() => handleTableClick(offer)}>{SaleTypeLabel[offer.type]}</TableCell>
                    <TableCell onClick={() => handleTableClick(offer)}>{offer.description}</TableCell>
                    <TableCell onClick={() => handleTableClick(offer)}>
                      {offer.sentDate &&
                        offer.status === SaleStatus.pending_approval &&
                        `${formatDate(offer.sentDate)} `}
                      {SaleStatusLabel[offer.status]}
                    </TableCell>
                    <TableCell onClick={() => handleTableClick(offer)}>{offer.totalPrice} SEK</TableCell>
                  </>
                ) : (
                  <ConfirmDeleteOrArchiveRow
                    onConfirm={() => handleCancelOffer(offer.id)}
                    onCancel={() => {
                      setConfirmDeleteOfferId(undefined);
                    }}
                    colspan={columnCount}
                    label={' offert ' + offer.customerName}
                    loading={isDeleteLoading}
                  />
                )}
              </TableRow>
            ))}
          </TableBody>
        </>
      )}
    </Table>
  );
};

export default OfferList;
