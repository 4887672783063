import { IOfferPriceProduct } from '../../Offer.api';

const rentMonths = 30;

export function calculateInstallationTime(products: IOfferPriceProduct[]): number {
  return products.reduce(
    (sum: number, product: IOfferPriceProduct) => sum + product.installationTime * product.quantity,
    0,
  );
}

export function calculateMaterialCost(products: IOfferPriceProduct[]): number {
  return products.reduce((sum: number, product: IOfferPriceProduct) => sum + product.price * product.quantity, 0);
}

export function calculateInstallationCost(installationTime: number, installationRate: number): number {
  return installationTime * installationRate;
}

export function calculateTotalPrice(materialCost: number, installationCost: number, otherCost: number): number {
  return materialCost + installationCost + otherCost;
}

export function calculateRent(totalPrice: number, firstInstallationCost: number): number {
  return Math.max(Math.round((totalPrice - firstInstallationCost) / rentMonths), 0);
}
