import { ListItemIcon, MenuItem, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { formatDateWithTime } from '../../../../../../../../Shared/Formatting/formatDate';
import { Table } from '../../../../../../../../Shared/Table/Table';
import TablePaginationWithDatasource from '../../../../../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import useTable from '../../../../../../../../Shared/Table/useTable';
import AccessGuard from '../../../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { AccessRights, ModuleIdentifiers } from '../../../../../../../../Core/Authentication/ModuleAccess';
import Button from '../../../../../../../../Shared/Button/Button';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/CheckRounded';
import {
  IDocumentListItemDto,
  getDocumentTypeName,
  useDeleteDocument,
  useGetCustomerDocuments,
  useOpenDocument,
  useUpdateDocument,
} from '../Documents.api';
import SortButton from '../../../../../../../../Shared/Table/SortButton/SortButton';
import { DropdownCell } from '../../../../../../../../Shared/Table/DropdownCell/DropdownCell';
import MailIcon from '@material-ui/icons/MailRounded';
import EmailDocumentModal from '../../../../../../../../Shared/Modal/EmailModal/EmailDocumentModal';
import { IEmailDocument, IEmailDocumentCommand, useEmailDocument } from '../../../../../Customers.api';
import Snackbar from '../../../../../../../../Shared/Hooks/UseErrorMessage/Snackbar/Snackbar';
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';
import RemoveDocumentModal from '../../RemoveDocumentModal/RemoveDocumentModal';
import { InputField } from '../../../../../../../../Shared/InputFields/InputField/InputField';
import useStyles from './DocumentList.style';
export interface IDocumentListProps {
  customerId: string;
  customerName?: string;
  facilityId?: string;
}

const initialEmailData = { recipient: '', message: '', subject: '' };

const DocumentList = (props: IDocumentListProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId, facilityId } = props;
  const { isLoading, documents, getDocuments } = useGetCustomerDocuments(customerId, facilityId);
  const { deleteDocument } = useDeleteDocument(props.customerId);
  const { isLoading: isOpenLoading, openDocument } = useOpenDocument(props.customerId);
  const { isLoadingEmailDocument, emailDocument } = useEmailDocument();
  const { updateDocument } = useUpdateDocument();
  const { toggleSort, sortProperty, sortDirection, pageSize, setPageSize, setPageNumber, paginatedRequest } = useTable(
    10,
  );
  const [emptyMessage] = useState('Inga dokument tillagda');
  const [fileNameChange, setFileNameChange] = useState('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedDocument, SetSelectedDocument] = useState<IDocumentListItemDto>();
  const [documentEmailData, setDocumentEmailData] = useState<IEmailDocument>(initialEmailData);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [confirmRemoveDocumentId, setConfirmRemoveDocumentId] = useState<number | undefined>();

  useEffect(() => {
    getDocuments(paginatedRequest);
  }, [paginatedRequest, getDocuments]);

  const ToggleModalCallBack = useCallback(() => {
    setOpenModal(!openModal);
    setDocumentEmailData(initialEmailData);
  }, [openModal, setOpenModal]);

  const handleSelectDocument = (document: IDocumentListItemDto) => {
    SetSelectedDocument(document);
    ToggleModalCallBack();
  };

  const handleSendDocument = useCallback(async () => {
    if (selectedDocument && documentEmailData.recipient && documentEmailData.subject && documentEmailData.message) {
      const data: IEmailDocumentCommand = {
        ...documentEmailData,
        documentId: selectedDocument.id,
        customerId: props.customerId ? parseInt(props.customerId) : undefined,
      };

      await emailDocument(data).then(() => {
        setOpenSnackBar(true);
      });
    }
    ToggleModalCallBack();
  }, [selectedDocument, documentEmailData, props.customerId, ToggleModalCallBack, emailDocument]);

  const handleDeleteDocument = async () => {
    if (confirmRemoveDocumentId) {
      await deleteDocument(confirmRemoveDocumentId?.toString());
      setOpenModal(false);
      setConfirmRemoveDocumentId(undefined);
    }
  };

  const HandleRemove = (id: number) => {
    setOpenModal(true);
    setConfirmRemoveDocumentId(id);
  };

  async function handleEdit(id: number) {
    resetState();

    const foundDocument = documents?.items.find((x) => x.id === id);
    if (foundDocument) {
      foundDocument.edit = true;
      setFileNameChange(foundDocument.fileName);
    }
  }

  function changeFileName(name: string) {
    setFileNameChange(name);
  }

  async function cancelEdit() {
    resetState();
    setFileNameChange('');
  }

  async function resetState() {
    await documents?.items?.forEach((document) => {
      document.edit = false;
    });
  }

  async function updateDocumentHandle(documentId: number) {
    await updateDocument(Number(customerId), documentId, fileNameChange);

    const foundDocument = documents?.items.find((x) => x.id === documentId);
    if (foundDocument) {
      foundDocument.fileName = fileNameChange;
      foundDocument.edit = false;
      setFileNameChange('');
    }
  }

  return (
    <>
      <Table
        customSize={{
          fullsize: true,
        }}
        actions={
          <AccessGuard module={ModuleIdentifiers.Customer} accessRights={AccessRights.Write}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => history.push(`${history.location.pathname}/docs/create`)}
            >
              Nytt
            </Button>
          </AccessGuard>
        }
        pagination={
          documents && (
            <TablePaginationWithDatasource
              datasource={documents}
              onChangePage={setPageNumber}
              onChangeRowsPerPage={setPageSize}
              fixed
            />
          )
        }
        empty={{
          empty: documents && documents.totalCount <= 0 ? true : false,
          message: emptyMessage,
        }}
        loading={{
          loading: isLoading || isLoadingEmailDocument,
          skeletonRows: pageSize,
        }}
      >
        <TableHead>
          <TableRow>
            <AccessGuard module={ModuleIdentifiers.Customer} accessRights={AccessRights.Delete}>
              <TableCell>
                <span className={'sr-only'}>Knappar</span>
              </TableCell>
            </AccessGuard>
            <TableCell>
              Datum
              <SortButton
                property="created"
                sortProperty={sortProperty}
                sortDirection={sortDirection}
                onSort={toggleSort}
              />
            </TableCell>
            <TableCell>Dokument</TableCell>
            <TableCell>Skapad av</TableCell>
            <TableCell>Typ</TableCell>
            {!facilityId && <TableCell>Plats</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {documents?.items.map((doc) => (
            <TableRow key={doc.id}>
              <DropdownCell>
                <AccessGuard module={ModuleIdentifiers.Customer} accessRights={AccessRights.Write}>
                  <>
                    <MenuItem onClick={() => handleSelectDocument(doc)}>
                      <ListItemIcon>{<MailIcon />}</ListItemIcon>
                      <Typography variant="inherit">Skicka</Typography>
                    </MenuItem>
                  </>
                </AccessGuard>
                <AccessGuard module={ModuleIdentifiers.Customer} accessRights={AccessRights.Write}>
                  <MenuItem
                    onClick={() => {
                      handleEdit(doc.id);
                    }}
                  >
                    <ListItemIcon>{<EditIcon />}</ListItemIcon>
                    <Typography variant="inherit">Uppdatera</Typography>
                  </MenuItem>
                </AccessGuard>
                <AccessGuard module={ModuleIdentifiers.Customer} accessRights={AccessRights.Delete}>
                  <MenuItem onClick={() => HandleRemove(doc.id)}>
                    <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                    <Typography variant="inherit">Ta bort</Typography>
                  </MenuItem>
                </AccessGuard>
              </DropdownCell>
              <TableCell>{formatDateWithTime(doc.created)}</TableCell>
              <TableCell>
                {(doc.edit === false || doc.edit === undefined) && (
                  <Button
                    variant="text"
                    color="primary"
                    disabled={isOpenLoading}
                    onClick={() => {
                      if (doc.saleId) {
                        history.push(`/errands/archive/${doc.saleId}`);
                      } else if (doc.type === 9) {
                        openDocument(doc.id, true);
                      } else {
                        openDocument(doc.id);
                      }
                    }}
                  >
                    {doc.fileName}
                  </Button>
                )}
                {doc.edit === true && (
                  <div className={classes.updateDiv}>
                    <InputField
                      required
                      type="text"
                      value={fileNameChange}
                      onChange={(e) => {
                        changeFileName(e.target.value);
                      }}
                      customWidth={6}
                    />
                    <div className={classes.iconDiv}>
                      <DeleteIcon onClick={cancelEdit} className={classes.noColor} />
                      <CheckIcon
                        onClick={async () => {
                          await updateDocumentHandle(doc.id);
                        }}
                        className={classes.yesColor}
                      />
                    </div>
                  </div>
                )}
              </TableCell>
              <TableCell>{doc.createdBy}</TableCell>
              <TableCell>{getDocumentTypeName(doc.type)}</TableCell>
              {!facilityId && <TableCell>{doc.facilityName}</TableCell>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {selectedDocument && (
        <EmailDocumentModal
          key={selectedDocument?.id}
          title={`Skicka ${selectedDocument.fileName} ${props.customerName ?? ''}`}
          value={documentEmailData}
          open={openModal}
          ToggleModal={ToggleModalCallBack}
          onChange={setDocumentEmailData}
          type={getDocumentTypeName(selectedDocument.type) ?? 'Dokument'}
          onSend={handleSendDocument}
          isLoading={isLoadingEmailDocument}
        />
      )}
      <Snackbar
        message={'Document har skickats.'}
        open={openSnackBar}
        severity={'success'}
        onClose={() => setOpenSnackBar(false)}
      />
      {confirmRemoveDocumentId && (
        <RemoveDocumentModal
          documentId={confirmRemoveDocumentId}
          open={openModal}
          setOpen={setOpenModal}
          isLoading={isLoading}
          handleDelete={handleDeleteDocument}
        />
      )}
    </>
  );
};

export default DocumentList;
