import React, { useEffect, useState } from 'react';
import { TableBody, TableCell, TableHead, TableRow, useMediaQuery } from '@material-ui/core';
import theme from '../../../../../Styles/Theme';
import { Table } from '../../../../../Shared/Table/Table';
import Link from '../../../../../Shared/Link/Link';
import TablePaginationWithDatasource from '../../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import useTable from '../../../../../Shared/Table/useTable';
import { MobileTable } from '../../../../../Shared/Table/MobileTable/MobileTable';
import { MobileTableRow } from '../../../../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import formatDate from '../../../../../Shared/Formatting/formatDate';
import { useGetBMSNodeList } from '../../BMS.api';
import { AccessRights, ModuleIdentifiers } from '../../../../../Core/Authentication/ModuleAccess';

const NodeList = () => {
  const { isLoading, nodes, getNodes } = useGetBMSNodeList();
  const { pageSize, paginatedRequest, setPageSize, setPageNumber } = useTable(5);
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const [emptyMessage, setEmptyMessage] = useState('');

  useEffect(() => {
    async function getList() {
      const response = await getNodes(paginatedRequest);

      if (response?.totalCount <= 0) {
        if (paginatedRequest.query) {
          setEmptyMessage('Filtreringen gav inga resultat');
        } else {
          setEmptyMessage('Inga senaste ändringarna'); //TODO: check if this is wanted
        }
      }
    }

    getList();
  }, [paginatedRequest, getNodes]);

  return (
    <Table
      customSize={{ fullsize: true }}
      label={`Senaste ändringarna (${nodes?.totalCount ?? 0})`}
      pagination={
        nodes && (
          <TablePaginationWithDatasource
            addPageSize={[5]}
            datasource={nodes}
            onChangePage={setPageNumber}
            onChangeRowsPerPage={setPageSize}
          />
        )
      }
      empty={{
        empty: nodes && nodes.totalCount <= 0 ? true : false,
        message: emptyMessage,
      }}
      loading={{
        loading: isLoading,
        skeletonRows: pageSize,
      }}
      minWidth
      mobile={mobileView}
    >
      {mobileView ? (
        <>
          {nodes?.items.map((node) => (
            <MobileTable key={node.id} label={node.name} link={`/bms/${node.id}`}>
              <MobileTableRow label="Nr" value={node.sectionNumber} />
              <MobileTableRow label="Rubrik" value={node.topic} />
              <MobileTableRow label="Ändrat datum" value={formatDate(node.lastModified)} />
              <MobileTableRow label="Ändrat av" value={node.lastModifiedByCompanyUserName} />
            </MobileTable>
          ))}
        </>
      ) : (
        <>
          <TableHead>
            <TableRow>
              <TableCell>Nr</TableCell>
              <TableCell>Rubrik</TableCell>
              <TableCell>Ändrat datum</TableCell>
              <TableCell>Ändrat av</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {nodes?.items.map((node) => (
              <TableRow key={node.id}>
                <>
                  <TableCell>
                    <Link
                      to={`/bms/${node.id}`}
                      module={ModuleIdentifiers.BusinessManagementSystem}
                      accessRights={AccessRights.Read}
                    >
                      {node.sectionNumber}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      to={`/bms/${node.id}`}
                      module={ModuleIdentifiers.BusinessManagementSystem}
                      accessRights={AccessRights.Read}
                    >
                      {node.topic}
                    </Link>
                  </TableCell>
                  <TableCell>{formatDate(node.lastModified)}</TableCell>
                  <TableCell>{node.lastModifiedByCompanyUserName}</TableCell>
                </>
              </TableRow>
            ))}
          </TableBody>
        </>
      )}
    </Table>
  );
};

export default NodeList;
