import React from 'react';
import { ICoreSystemListItemDto } from '../../Facilities.api';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Table } from '../../../../../../../../Shared/Table/Table';
import { useHistory } from 'react-router-dom';
import { useStyles } from '../../../../../../../../Shared/Table/TableRowHeader/TableRowHeader.styles';

export interface ISystemListProps {
  coreSystems?: ICoreSystemListItemDto[];
  isLoading: boolean;
}

const SystemList = (props: ISystemListProps) => {
  const { isLoading, coreSystems } = props;
  const history = useHistory();
  const classes = useStyles();

  return (
    <Table label="System" loading={{ loading: isLoading, skeletonRows: 5 }} minWidth>
      <TableHead>
        <TableRow>
          <TableCell>System</TableCell>
          <TableCell>Typ</TableCell>
          <TableCell>Larmcentral</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {coreSystems?.map((coreSystem) => (
          <TableRow
            className={classes.pointerCursor}
            hover
            tabIndex={-1}
            onClick={() => history.push(`${history.location.pathname}/system/${coreSystem.id}`)}
            key={coreSystem.id}
          >
            <TableCell>{coreSystem.description}</TableCell>
            <TableCell>{coreSystem.systemType}</TableCell>
            <TableCell>{coreSystem.emergencyCallCentre}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default SystemList;
