import React, { useContext, useEffect, useState } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import IconButton from '../../IconButton/IconButton';
import ArrowForward from '@material-ui/icons/KeyboardArrowRightRounded';
import ArrowBack from '@material-ui/icons/KeyboardArrowLeftRounded';
import { Typography, Grid, GridSize } from '@material-ui/core';
import sv from 'date-fns/locale/sv';
import DateInput, { ICheckBoxProps, IRadioBoxProps } from './DateInput/DateInput';
import { useStyles } from './Datepicker.styles';
import { useGetHoliday } from './../../../AuthApp/Modules/Holiday/Holiday.api';
import { InputFieldLoading } from '../InputField/InputFieldLoading/InputFieldLoading';
import { ContainerContext } from '../../Container/Container';
import formatDate from '../../Formatting/formatDate';
import { InputField } from '../InputField/InputField';

interface IDatepickerProps<WithRange extends boolean = false> extends ReactDatePickerProps<never, WithRange> {
  label?: string;
  inFilter?: boolean;
  errorText?: string | string[];
  viewOnly?: boolean;
  minDate?: Date;
  fullwidth?: boolean;
  customWidth?: GridSize;
  checkBoxProps?: ICheckBoxProps;
  radioBoxProps?: IRadioBoxProps;
  viewOnlyDate?: boolean;
}
interface IDatepickerSingleProps extends IDatepickerProps {
  onChange: (date: Date | null, event: React.SyntheticEvent<any> | undefined) => void;
}

interface IDatepickerRangeProps extends IDatepickerProps<true> {
  selectsRange: true;
  onChange: (date: [Date | null, Date | null], event: React.SyntheticEvent<any> | undefined) => void;
}

const Datepicker = (props: IDatepickerSingleProps | IDatepickerRangeProps) => {
  const classes = useStyles();
  const { label, errorText, viewOnly, customInput, customInputRef, minDate, onChange, selectsRange, ...rest } = props;
  const { getCheck } = useGetHoliday();
  const containerContext = useContext(ContainerContext);
  const [holidayDates, setHolidayDates] = useState<Date[]>([]);

  const getMonthByNumber = (month: number) => {
    return months[month];
  };

  const myCustomDay = (dayOfMonth: number, date?: Date) => {
    const day = date?.getDay();

    return (
      <>
        <div className={day !== undefined && (day === 6 || day === 0) ? classes.weekend : undefined}>
          <span>{dayOfMonth}</span>
        </div>
      </>
    );
  };

  useEffect(() => {
    async function getList() {
      const result = await getCheck();
      setHolidayDates(result ? result.map((x) => new Date(x.date)) : []);
    }
    getList();
  }, [getCheck]);

  const months = [
    'Januari',
    'Februari',
    'Mars',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'Augusti',
    'September',
    'Oktober',
    'November',
    'December',
  ];
  const ref = React.createRef<string>();

  return (
    <Grid
      md={props.fullwidth ? 12 : props.customWidth ?? 6}
      sm={props.fullwidth ? 12 : props.customWidth ?? 6}
      xs={12}
      item
      className={classes.datepicker}
    >
      {containerContext?.loading ? (
        // Unsure why InputField of DateInput doesn't find containerContext.loading. Has to use this solution so for DatePicker
        <InputFieldLoading />
      ) : (
        <>
          {!viewOnly ? (
            <ReactDatePicker
              renderDayContents={myCustomDay}
              highlightDates={holidayDates}
              disabledKeyboardNavigation //Make CSS look weird when changing month
              calendarClassName={`${classes.calendar} ${rest.showMonthYearPicker ? classes.yearPicker : ''} `}
              popperClassName={classes.popper}
              showWeekNumbers
              selectsRange={props.selectsRange}
              locale={sv}
              minDate={minDate}
              dateFormat={props.dateFormat ?? 'yyyy-MM-dd'}
              popperModifiers={[
                {
                  name: 'preventOverflow',
                  options: {
                    altBoundary: true,
                  },
                },
              ]}
              customInput={
                customInput ?? (
                  <DateInput
                    ref={ref}
                    value={props.selected}
                    label={label}
                    placeholder={rest.placeholderText}
                    errorText={errorText}
                    checkBoxProps={props.checkBoxProps}
                    radioBoxProps={props.radioBoxProps}
                  />
                )
              }
              customInputRef={customInputRef ?? (ref.current || undefined)}
              renderCustomHeader={({
                date,
                decreaseMonth,
                increaseMonth,
                increaseYear,
                decreaseYear,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div className={classes.header}>
                  {!rest.showMonthYearPicker ? (
                    <>
                      <IconButton
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                        aria-label="Gå till nästa månad"
                        size="small"
                      >
                        <ArrowBack />
                      </IconButton>
                      <Typography variant="h6" component="span">
                        {getMonthByNumber(date.getMonth())} {date.getFullYear()}
                      </Typography>
                      <IconButton
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                        aria-label="Gå till föregående månad"
                        size="small"
                      >
                        <ArrowForward />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton
                        onClick={decreaseYear}
                        disabled={prevMonthButtonDisabled}
                        aria-label="Gå till nästa år"
                        size="small"
                      >
                        <ArrowBack />
                      </IconButton>
                      <Typography variant="h6" component="span">
                        {date.getFullYear()}
                      </Typography>
                      <IconButton
                        onClick={increaseYear}
                        disabled={nextMonthButtonDisabled}
                        aria-label="Gå till föregående år"
                        size="small"
                      >
                        <ArrowForward />
                      </IconButton>
                    </>
                  )}
                </div>
              )}
              onChange={(date, event) => {
                if (date) {
                  if (Array.isArray(date)) {
                    date[0]?.setHours(0, 0, 0, 0);
                    date[1]?.setHours(0, 0, 0, 0);
                  } else {
                    date.setHours(0, 0, 0, 0);
                  }
                }
                onChange(date as (Date | null) & [Date | null, Date | null], event);
              }}
              {...rest}
            />
          ) : (
            <InputField
              label={props.label}
              type="text"
              value={props.selected ? formatDate(props.selected) : ''}
              viewOnly
            />
          )}
        </>
      )}
    </Grid>
  );
};

export default Datepicker;
