import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import useHeaderNavigationContext from '../../../Shared/Navigation/Header/HeaderNavigationContext/HeaderNavigationContext';
import { useEffect } from 'react';
import { INavigationItem } from '../../../Shared/Navigation/Header/Header';
import React from 'react';
import AuthRoute from '../../../Core/Authentication/AuthRoute/AuthRoute';
import { UserType } from '../Admin/Components/Users/Users.api';
import AdministrationList from './AdministrationList/AdministrationList';
import AdministrationForm from './AdministrationForm/AdministrationForm';

function AdministrationRoutes() {
  const { path } = useRouteMatch();
  const { setHeaderNavigationItems, setHeaderNavigationText } = useHeaderNavigationContext();

  useEffect(() => {
    const navigationItems: INavigationItem[] = [
      {
        title: 'Administratörer',
        to: `${path}`,
        exact: false,
      },
    ];
    setHeaderNavigationText(navigationItems);
    setHeaderNavigationItems(navigationItems);

    return () => {
      setHeaderNavigationItems([]);
    };
  }, [path, setHeaderNavigationText, setHeaderNavigationItems]);

  return (
    <Switch>
      <AuthRoute exact path={`${path}`} userType={UserType.SuperAdmin}>
        <h1 className={'sr-only'}>Lista över Administratör</h1>
        <AdministrationList />
      </AuthRoute>

      <AuthRoute exact path={`${path}/create`} userType={UserType.SuperAdmin}>
        <h1 className={'sr-only'}>Skapa Administratör</h1>
        <AdministrationForm />
      </AuthRoute>

      <Route path={`${path}/*`}>
        <Redirect to={`${path}`} />
      </Route>
    </Switch>
  );
}

export default AdministrationRoutes;
