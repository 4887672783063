import React, { useMemo } from 'react';
import MuiSnackbar, { SnackbarProps, SnackbarCloseReason } from '@material-ui/core/Snackbar/Snackbar';
import MuiAlert, { Color } from '@material-ui/lab/Alert';
import IconButton from '../../../IconButton/IconButton';
import DeleteIcon from '@material-ui/icons/ClearRounded';
import useStyles from './Snackbar.styles';

interface ISnackbarProps extends Omit<SnackbarProps, 'onClose'> {
  onClose: () => void;
  severity: Color;
}

const Snackbar = (props: ISnackbarProps) => {
  const classes = useStyles();
  const { message, action, onClose, severity, ...rest } = props;
  const backgroundColor = useMemo(() => {
    switch (severity) {
      case 'success':
        return classes.success;
      case 'error':
        return classes.error;
      case 'info':
        return classes.info;
    }
  }, [severity, classes]);

  function close(event: React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) {
    if (reason === 'clickaway') return;

    onClose();
  }

  return (
    <MuiSnackbar
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      onClose={close}
      {...rest}
    >
      <MuiAlert
        className={backgroundColor}
        severity={severity}
        elevation={6}
        variant="filled"
        action={
          <>
            {action}
            <IconButton onClick={onClose} size="small" aria-label="Stäng" className={classes.close}>
              <DeleteIcon />
            </IconButton>
          </>
        }
      >
        {message}
      </MuiAlert>
    </MuiSnackbar>
  );
};

export default Snackbar;
