import React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/ExpandMoreRounded';
import { Link } from 'react-router-dom';
import { useStyles } from './MobileTable.styles';
import { ConfirmDeleteOrArchiveRow } from '../ConfirmDeleteOrArchiveRow/ConfirmDeleteOrArchiveRow';

interface IMobileTableProps {
  label: string | number;
  icon?: React.ReactNode;
  action?: React.ReactNode;
  children?: React.ReactNode;
  link?: string;
  customLink?: React.ReactNode;
  confirmDeleteRow?: IConfirmRemove;
}

interface IConfirmRemove {
  show?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  loading: boolean;
}

export const MobileTable = (props: IMobileTableProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.confirmDeleteRow?.show ? (
        <ConfirmDeleteOrArchiveRow
          label={props.label}
          mobile
          onCancel={props.confirmDeleteRow.onCancel}
          onConfirm={props.confirmDeleteRow.onConfirm}
          loading={props.confirmDeleteRow.loading}
        />
      ) : (
        <>
          <div className={classes.headerContainer}>
            {props.action && <div>{props.action}</div>}
            {props.customLink ? (
              props.customLink
            ) : (
              <Typography
                component={props.link ? Link : 'span'}
                to={props.link}
                className={`${classes.label} ${props.link ? classes.asLink : ''} ${
                  props.action ? classes.withAction : ''
                }`}
              >
                {props.icon} {props.label}
              </Typography>
            )}
          </div>
          <Accordion square elevation={0} className={classes.expansionPanel}>
            <AccordionSummary expandIcon={<ArrowIcon />}></AccordionSummary>
            <AccordionDetails>
              <ul className={classes.container}>{props.children}</ul>
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </div>
  );
};
