import React, { useCallback } from 'react';
import { AsyncSelect } from '../../../../../../Shared/Select';
import { useGetFormats } from '../../../../Customers/Components/Facilities/CoreSystems/CoreSystem.api';

export interface IProps {
  label?: string;
  onChange?: (formatId: string | undefined, formatName: string | undefined) => void;
  required?: boolean;
  isClearable?: boolean;
  selectedFormatId?: number | undefined;
}

const FormatSelect = (props: IProps) => {
  const { isLoading, getFormats } = useGetFormats();

  const loadFormats = useCallback(async () => {
    const response = (await getFormats())?.map((x) => ({
      label: x.name,
      value: (x.id as number).toString(),
    }));
    return response;
  }, [getFormats]);

  return (
    <AsyncSelect
      isClearable={props.isClearable}
      label={props.label ? props.label : 'Format (larmcentral)'}
      required={props.required}
      isLoading={isLoading}
      selectedOptionId={props.selectedFormatId?.toString()}
      loadOptions={loadFormats}
      onChange={(value) => {
        props.onChange && props.onChange(value?.value, value?.label);
      }}
    />
  );
};
export default FormatSelect;
