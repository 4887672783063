import React from 'react';
import { useHistory } from 'react-router-dom';
import AccessGuard from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { ModuleIdentifiers, AccessRights } from '../../../../../../Core/Authentication/ModuleAccess';
import { ErrorCode, isErrorResponse } from '../../../../../../Shared/Api/response/IErrorRespose';
import useErrorMessage from '../../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import formatDate from '../../../../../../Shared/Formatting/formatDate';
import { IMeetingNotesComment, useDeleteMeetingNotesComment } from '../MeetingNotes.api';
import { useStyles } from './MeetingNotesComment.styles';
import { Delete, Edit } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import IconButton from '../../../../../../Shared/IconButton/IconButton';

export function getCommentHeader(comment: IMeetingNotesComment) {
  return `${formatDate(comment.date)} av ${comment.createdBy}`;
}

const MeetingNotesCommentView = (props: { comment: IMeetingNotesComment; refetch: () => void; noEdit?: boolean }) => {
  const { comment, refetch } = props;
  const classes = useStyles();
  const history = useHistory();
  const { isLoading: isDeleteLoading, deleteComment } = useDeleteMeetingNotesComment(comment.meetingNotesId);
  const { setErrorMessage } = useErrorMessage();

  async function handleDelete() {
    const result = await deleteComment(comment.id);
    if (isErrorResponse(result) && result.errorCode === ErrorCode.EntityInUse) {
      setErrorMessage({
        message: 'Gick inte att ta bort kommentar',
      });
    } else {
      refetch();
    }
  }

  return (
    <li className={classes.root}>
      <div className={classes.message}>
        <div className={classes.text}>
          <Typography className={classes.title}>{comment.topic}</Typography>
          <Typography>{comment.content}</Typography>
        </div>
        {!props.noEdit && (
          <div className={classes.actions}>
            <AccessGuard module={ModuleIdentifiers.Todo} accessRights={AccessRights.Write}>
              <IconButton
                data-testid={`edit-comment_${comment.id}-button`}
                onClick={() => history.push(`${history.location.pathname}/comment/${comment.id}/update`)}
                aria-label="Redigera"
                loading={isDeleteLoading}
              >
                <Edit />
              </IconButton>
              <IconButton
                data-testid={`delete-comment_${comment.id}-button`}
                onClick={handleDelete}
                aria-label="Ta bort"
              >
                <Delete />
              </IconButton>
            </AccessGuard>
          </div>
        )}
      </div>
      <Typography className={classes.by}>{getCommentHeader(comment)}</Typography>
    </li>
  );
};

export default MeetingNotesCommentView;
