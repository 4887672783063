import React from 'react';
import AnonymousAppRoutes from './AnonymousApp.routes';
import { useStyles } from './AnonymousApp.styles';

const AnonymousApp = () => {
  const classes = useStyles();

  return (
    <main className={classes.content}>
      <AnonymousAppRoutes />
    </main>
  );
};

export default AnonymousApp;
