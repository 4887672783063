import React, { useState } from 'react';
import CompanyUserMessageForm from '../CompanyUserMessageForm/CompanyUserMessageForm';
import { IUpsertCompanyUserMessageRequestBody, IGetCompanyUserMessagesResult } from '../../Forum.api';
import PopperMenu from '../../../../../Shared/Popper/Popper';
import AccessGuard from '../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { AccessRights, ModuleIdentifiers } from '../../../../../Core/Authentication/ModuleAccess';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';

export interface IEditCompanyUserMessageProps {
  message?: IGetCompanyUserMessagesResult;
  onClose?: () => void;
  onSave: (body: IUpsertCompanyUserMessageRequestBody, id?: string) => void;
  open?: boolean;
  fromMenuItem?: boolean;
}

export default function EditCompanyUserMessage(props: IEditCompanyUserMessageProps) {
  const [open, setOpen] = useState(false);

  function save(body: IUpsertCompanyUserMessageRequestBody, id?: string) {
    setClose();
    setOpen(false);
    props.onSave(body, id);
  }

  function setClose() {
    setOpen(false);
    props.onClose && props.onClose();
  }

  return (
    <AccessGuard module={ModuleIdentifiers.Forum} accessRights={AccessRights.Write}>
      <PopperMenu
        setOpen={setOpen}
        open={open}
        onClose={() => setClose()}
        buttonType='icon'
        customContent
        alwaysDialog
        title={'Lägg till Forum'}
        content={<CompanyUserMessageForm onCancel={() => setOpen(false)} onSave={save} message={props.message} />}
      >
        <AddIcon />
      </PopperMenu>
    </AccessGuard>
  );
}
