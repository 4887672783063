import React, { useCallback, useEffect, useState } from 'react';
import { Table } from '../../../../Shared/Table/Table';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
  ListItemIcon,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import EditIcon from '@material-ui/icons/Edit';
import SearchInput from '../../../../Shared/InputFields/SearchInput/SearchInput';
import { useHistory } from 'react-router-dom';
import useTable from '../../../../Shared/Table/useTable';
import SortButton from '../../../../Shared/Table/SortButton/SortButton';
import { DropdownCell } from '../../../../Shared/Table/DropdownCell/DropdownCell';
import {
  useGetAdministrators,
  useDeleteAdministrator,
  useUpdateAdministrator,
  useSendAdministratorInvitation,
  IAdministratorValidationError,
  IAdministrator,
  IUpdateAdministrator,
} from '../Administration.api';
import TablePaginationWithDatasource from '../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import { EditRows } from '../../../../Shared/Table/EditRows/EditRows';
import { ErrorCode, isErrorResponse } from '../../../../Shared/Api/response/IErrorRespose';
import useErrorMessage from '../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import useInputState from '../../../../Shared/Hooks/UseInputState/UseInputState';
import { useStyles } from './AdministrationList.Styles';
import { InputField } from '../../../../Shared/InputFields/InputField/InputField';
import { ICustomSize } from '../../../../Shared/Container/Container';
import theme from '../../../../Styles/Theme';
import { Mail } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import IconButton from '../../../../Shared/IconButton/IconButton';
import { ConfirmDeleteOrArchiveRow } from '../../../../Shared/Table/ConfirmDeleteOrArchiveRow/ConfirmDeleteOrArchiveRow';

const AdministrationList = () => {
  const { deleteAdministrator } = useDeleteAdministrator();
  const { updateAdministrator } = useUpdateAdministrator();
  const { isLoading: isSendInviteLoading, sendAdminInvite } = useSendAdministratorInvitation();
  const { isLoading, response: administrators, getAdministrators } = useGetAdministrators();
  const {
    toggleSort,
    sortProperty,
    sortDirection,
    pageSize,
    setPageSize,
    setPageNumber,
    setQuery,
    paginatedRequest,
  } = useTable();
  const { setErrorMessage } = useErrorMessage();
  const [confirmDeleteId, setConfirmDeleteId] = useState<number | undefined>();
  const [editAdmin, setEditAdmin] = useState<number | undefined>();
  const [errors, setErrors] = useState<IAdministratorValidationError>();
  const [firstName, changeFirstName, setFirstName] = useInputState<string | undefined>(undefined);
  const [lastName, changeLastName, setLastName] = useInputState<string | undefined>(undefined);
  const [emptyMessage, setEmptyMessage] = useState('');
  const history = useHistory();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const customSize: ICustomSize = {
    fullsize: !mobileView,
  };

  const getList = useCallback(async () => {
    const response = await getAdministrators(paginatedRequest);

    if (response?.totalCount <= 0) {
      if (paginatedRequest.query) {
        setEmptyMessage('Filtreringen gav inga resultat');
      } else {
        setEmptyMessage('Inga företag tillagda');
      }
    }
  }, [paginatedRequest, getAdministrators]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleDelete = async (id: number) => {
    const result = await deleteAdministrator(id);
    if (isErrorResponse(result) && result.errorCode === ErrorCode.EntityInUse) {
      setErrorMessage({ message: 'Gick inte att ta bort Administratör' });
      setConfirmDeleteId(undefined);
    } else {
      await getList();
    }
    setConfirmDeleteId(undefined);
  };
  const handleSetAdmin = (admin: IAdministrator) => {
    setEditAdmin(admin.id);
    setFirstName(admin.firstName);
    setLastName(admin.lastName);
  };
  const handleEdit = async (id: number) => {
    if (firstName && lastName && firstName.replace(/\s/g, '').length && lastName.replace(/\s/g, '').length) {
      const updateAdmin: IUpdateAdministrator = {
        firstName: firstName,
        lastName: lastName,
      };

      const result = await updateAdministrator(updateAdmin, id.toString());
      isErrorResponse(result) ? setErrors(result) : history.push('/administration');

      await getList();
      setEditAdmin(undefined);
    } else {
      setErrorMessage({ message: 'Gick inte redigera namn' });
    }
  };

  return (
    <Table
      customSize={customSize}
      label={`Administratörer (${administrators?.totalCount ?? 0})`}
      filters={<SearchInput type="search" placeholder="Sök" onChange={setQuery} />}
      iconButtons={
        <IconButton
          size="small"
          aria-label="Lägg till administratör"
          onClick={() => {
            history.push(`${history.location.pathname}/create`);
          }}
        >
          <AddIcon />
        </IconButton>
      }
      pagination={
        administrators && (
          <TablePaginationWithDatasource
            datasource={administrators}
            onChangePage={setPageNumber}
            onChangeRowsPerPage={setPageSize}
          />
        )
      }
      empty={{
        empty: administrators && administrators.totalCount <= 0 ? true : false,
        message: emptyMessage,
      }}
      loading={{
        loading: isLoading,
        skeletonRows: pageSize,
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>
            <span className={'sr-only'}>Knappar</span>
          </TableCell>
          <TableCell>
            Namn
            <SortButton
              property="firstname"
              sortProperty={sortProperty}
              sortDirection={sortDirection}
              onSort={toggleSort}
            />
          </TableCell>
          <TableCell>
            Email
            <SortButton
              property="email"
              sortProperty={sortProperty}
              sortDirection={sortDirection}
              onSort={toggleSort}
            />
          </TableCell>
        </TableRow>
      </TableHead>
      {administrators?.items.map((administrator) => (
        <TableBody key={administrator.id}>
          {confirmDeleteId === administrator.id || editAdmin === administrator.id ? (
            <>
              {confirmDeleteId && (
                <ConfirmDeleteOrArchiveRow
                  onConfirm={() => handleDelete(administrator.id)}
                  onCancel={() => setConfirmDeleteId(undefined)}
                  colspan={3}
                />
              )}
              {editAdmin && (
                <>
                  <EditRows
                    mobile={mobileView}
                    onConfirm={() => handleEdit(administrator.id)}
                    onCancel={() => setEditAdmin(undefined)}
                    colspan={1}
                  >
                    <InputField
                      placeholder={'Förnamn'}
                      customWidth={'auto'}
                      type="text"
                      errorText={errors?.firstName}
                      value={firstName}
                      onChange={changeFirstName}
                    />
                    <div style={{ marginLeft: 5, marginRight: 5 }} />
                    <InputField
                      placeholder={'Efternamn'}
                      customWidth={'auto'}
                      type="text"
                      errorText={errors?.lastName}
                      value={lastName}
                      onChange={changeLastName}
                    />
                  </EditRows>
                  <TableCell>{administrator.email}</TableCell>
                </>
              )}
            </>
          ) : (
            <TableRow key={1}>
              <>
                <DropdownCell mobile={mobileView}>
                  <MenuItem onClick={() => handleSetAdmin(administrator)}>
                    <ListItemIcon>{<EditIcon />}</ListItemIcon>
                    <Typography variant="inherit">Redigera Administratör</Typography>
                  </MenuItem>
                  {!administrator.isActive && (
                    <MenuItem
                      disabled={isSendInviteLoading}
                      onClick={async () => await sendAdminInvite(administrator.id.toString())}
                    >
                      <ListItemIcon>{<Mail />}</ListItemIcon>
                      <Typography variant="inherit">Skicka ny inbjudan</Typography>
                    </MenuItem>
                  )}
                  <MenuItem onClick={() => setConfirmDeleteId(administrator.id)}>
                    <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                    <Typography variant="inherit">Ta bort</Typography>
                  </MenuItem>
                </DropdownCell>
                <TableCell className={classes.tableText}>
                  <span style={{ color: administrator.isActive ? 'red' : 'inherit' }}>{administrator.fullName}</span>
                </TableCell>
                <TableCell className={classes.tableText}>{administrator.email}</TableCell>
              </>
            </TableRow>
          )}
        </TableBody>
      ))}
    </Table>
  );
};

export default AdministrationList;
