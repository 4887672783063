import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useGetCompany } from '../../Companies.api';
import Container from '../../../../../Shared/Container/Container';
import { Fieldset } from '../../../../../Shared/Form/Fieldset/Fieldset';
import { InputField } from '../../../../../Shared/InputFields/InputField/InputField';
import Button from '../../../../../Shared/Button/Button';
import UserList from '../../../Admin/Components/Users/UserList/UserList';
import { ViewAddress } from '../../../../../Shared/InputFields/Address/ViewAddress';
import useStyles from './ViewCompany.styles';
import { useMediaQuery } from '@material-ui/core';
import theme from '../../../../../Styles/Theme';

const ViewCompany = () => {
  const history = useHistory();
  const { companyId } = useParams<{ companyId: string }>();
  const classes = useStyles();
  const { isLoading, response, getCompany } = useGetCompany();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    async function get() {
      const response = await getCompany(companyId);

      if (!response) {
        history.push('/company');
      }
    }

    get();
  }, [getCompany, history, companyId]);

  return (
    <>
      <div className={classes.root}>
        {!mobileView && (
          <div className={classes.userList}>
            <UserList
              isSuperAdmin
              onUserClick={(user) => history.push(`${history.location.pathname}/user/${user.id}`)}
              onCreateNew={() => history.push(`${history.location.pathname}/user/create`)}
            />
          </div>
        )}
        <div className={classes.companyContainer}>
          <Container
            loading={isLoading}
            label={response?.name}
            actions={
              <Button
                color="default"
                variant="outlined"
                onClick={() => history.push(`${history.location.pathname}/update`)}
              >
                Redigera
              </Button>
            }
          >
            <Fieldset legend="Allmänt">
              <InputField label="Företagsnamn" value={response?.name} viewOnly />
              <InputField label="Organisationsnummer" value={response?.identificationNumber} viewOnly />
              <ViewAddress address={response?.address} postalCode={response?.postalCode} city={response?.city} />
              <InputField label="E-post" value={response?.email} viewOnly />
              <InputField label="Telefon" value={response?.phoneNumber} viewOnly />
            </Fieldset>
          </Container>
        </div>
        {mobileView && (
          <div className={classes.userList}>
            <UserList
              isSuperAdmin
              onUserClick={(user) => history.push(`${history.location.pathname}/user/${user.id}`)}
              onCreateNew={() => history.push(`${history.location.pathname}/user/create`)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ViewCompany;
