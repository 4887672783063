import React, { useState, useRef, useEffect } from 'react';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import AuditIcon from '@material-ui/icons/PlaylistAddCheckRounded';
import DescriptionIcon from '@material-ui/icons/Description';
import EventIcon from '@material-ui/icons/EventRounded';
import MenuIcon from '@material-ui/icons/Menu';
import ReceiptIcon from '@material-ui/icons/ReceiptRounded';
import StartIcon from '@material-ui/icons/HomeRounded';
import ArrowIcon from '@material-ui/icons/ArrowBack';
import StoreIcon from '@material-ui/icons/StoreRounded';
import UsersIcon from '@material-ui/icons/SupervisedUserCircleRounded';
import { IconButton, Collapse, Toolbar, AppBar } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { ModuleIdentifiers } from '../../../Core/Authentication/ModuleAccess';
import AccessGuard from '../../../Core/Authentication/AccessGuard/AccessGuard';
import MenuItem from './MenuItem/MenuItem';
import ShowAll from './ShowAll/ShowAll';
import { Nameday } from '../Header/Nameday/Nameday';
import { Logotype } from '../Header/Logotype/Logotype';
import { UserType } from '../../../AuthApp/Modules/Admin/Components/Users/Users.api';
import { useStyles } from './Sidebar.styles';
import useUserContext from '../../../Core/Authentication/UserContext';
import { useHistory } from 'react-router';

interface ISidebarProps {
  userDropDownMenu: React.ReactNode;
  isImpersonated?: string | null;
}

export const Sidebar = (props: ISidebarProps) => {
  const { user } = useUserContext();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const [openCollapse, setOpenCollapse] = useState(false);
  const [hiddenItems, setHiddenItems] = useState<HTMLLIElement[]>([]);

  // Ref for list with children
  const list = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const showHiddenMenuItems = () => {
      if (!xsDown) {
        if (list !== null && list.current !== null) {
          const listWithHiddenItems: HTMLLIElement[] = [];
          // Check if the item has moved due to flex: wrap;
          // If so, add the item to the array
          Array.from(list.current.children).forEach((item: any) => {
            if (item.offsetLeft > 0 && list !== null && list.current !== null) {
              listWithHiddenItems.push(item);
            }
          });

          // Add the array to state
          setHiddenItems(listWithHiddenItems);
        }
      }
    };

    // Run function on load
    showHiddenMenuItems();

    // Also run function when window is resized (preferably only when resize vertically)
    window.addEventListener('resize', showHiddenMenuItems);
  }, [xsDown, props.isImpersonated]);

  // Check if a item is hidden, in that case the item should not be able to reach trough 'tabbing'.
  // Currently comparing label with innerText, maybe there is a better way?
  const checkIfHidden = (x: string) => {
    return hiddenItems.some((a) => x === a.innerText);
  };

  const toggleMenu = () => {
    setOpenCollapse(!openCollapse);
  };

  const closeMenu = () => {
    setOpenCollapse(false);
  };

  const homePage = () => {
    history.push('/');
  };

  const Bar = () => (
    <div className={classes.appBar}>
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <div>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="Växla mellan att öppna och stänga meny"
              aria-pressed={openCollapse}
              onClick={toggleMenu}
            >
              {openCollapse ? <ArrowIcon fontSize='large' aria-hidden="true" /> : <MenuIcon fontSize='large' aria-hidden="true" />}
            </IconButton>
          </div>
          <span onClick={homePage}>{user.companyName}</span>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {props.userDropDownMenu}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
  const List = () => (
    <nav>
      <ul className={classes.list} ref={list}>
        {(user.userType !== UserType.SuperAdmin || user.impersonatedCompany) && (
          <MenuItem
            exact
            to="/"
            label="Start"
            hiddenFromFocus={checkIfHidden('Start')}
            onClick={closeMenu}
            icon={<StartIcon aria-hidden="true" />}
          />
        )}
        <AccessGuard module={ModuleIdentifiers.Todo}>
          <MenuItem
            to="/todos"
            label="Uppgifter"
            hiddenFromFocus={checkIfHidden('Uppgifter')}
            onClick={closeMenu}
            icon={<AssignmentOutlinedIcon aria-hidden="true" />}
          />
        </AccessGuard>
        <AccessGuard module={ModuleIdentifiers.Booking}>
          <MenuItem
            to="/booking"
            label="Bokningar"
            hiddenFromFocus={checkIfHidden('Bokningar')}
            onClick={closeMenu}
            icon={<EventIcon aria-hidden="true" />}
          />
        </AccessGuard>
        {/* TODO: add orders, agreements and supplier orders too */}
        <AccessGuard module={[ModuleIdentifiers.Offers, ModuleIdentifiers.Orders, ModuleIdentifiers.Agreements]}>
          <MenuItem
            to="/errands"
            label="Ärenden"
            hiddenFromFocus={checkIfHidden('Ärenden')}
            onClick={closeMenu}
            icon={<ReceiptIcon aria-hidden="true" />}
          />
        </AccessGuard>
        <AccessGuard module={ModuleIdentifiers.Customer}>
          <MenuItem
            to="/customers"
            label="Kunder"
            hiddenFromFocus={checkIfHidden('Kunder')}
            onClick={closeMenu}
            icon={<UsersIcon aria-hidden="true" />}
          />
        </AccessGuard>
        <AccessGuard module={ModuleIdentifiers.Orders}>
          <MenuItem
            to="/audit"
            hiddenFromFocus={checkIfHidden('Revision')}
            label="Revision"
            onClick={closeMenu}
            icon={<AuditIcon aria-hidden="true" />}
          />
        </AccessGuard>
        <AccessGuard module={ModuleIdentifiers.BusinessManagementSystem}>
          <MenuItem
            to="/bms"
            label="ISO"
            hiddenFromFocus={checkIfHidden('ISO')}
            onClick={closeMenu}
            icon={<DescriptionIcon aria-hidden="true" />}
          />
        </AccessGuard>
        <AccessGuard module={ModuleIdentifiers.FileSystem}>
          <MenuItem
            to="/files"
            label="Filer"
            hiddenFromFocus={checkIfHidden('Filer')}
            onClick={closeMenu}
            icon={<DescriptionIcon aria-hidden="true" />}
          />
        </AccessGuard>
        <AccessGuard userType={UserType.SuperAdmin}>
          <MenuItem
            to="/company"
            label="Företag"
            hiddenFromFocus={checkIfHidden('Företag')}
            onClick={closeMenu}
            icon={<StoreIcon aria-hidden="true" />}
          />
        </AccessGuard>
        <AccessGuard userType={UserType.SuperAdmin}>
          <MenuItem
            to="/administration"
            label="Administratörer"
            hiddenFromFocus={checkIfHidden('Administratör')}
            onClick={closeMenu}
            icon={<UsersIcon aria-hidden="true" />}
            ellipsis
          />
        </AccessGuard>
      </ul>
      {hiddenItems.length > 0 && <ShowAll items={hiddenItems} />}
    </nav>
  );

  if (xsDown)
    return (
      <>
        <Bar />
        <Collapse className={classes.collapse} in={openCollapse}>
          <div className={classes.header}>
            <Logotype />
            <Nameday align="right" />
          </div>
          <List />
        </Collapse>
      </>
    );

  return (
    <div className={classes.root}>
      <List />
    </div>
  );
};
