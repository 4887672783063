import { makeStyles, Theme } from '@material-ui/core/styles';
//Might not need anymore
const useStyles = makeStyles((theme: Theme) => ({
  iconButton: {
    borderRadius: '3rem',
    padding: '6px 12px',
  },
  iconButtonLabel: {
    fontSize: '1rem',
    fontWeight: 700,
    color: theme.palette.primary.main,
    display: 'flex',
    gap: '.5rem',
    flexDirection: 'row',

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  iconButtonContainer: {
    marginTop: '1rem',
    width: '100%',
  },
  borderRight: {
    borderRight: `1px solid ${theme.palette.grey[200]}`,
  },
  padding: {
    paddingRight: `0.5rem`,
  },
  buttonRow: {
    justifyContent: 'center',
    height: '50%',
    display: 'flex',
    padding: '1.8rem 0',
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '0',
    },
  },
}));

export default useStyles;
