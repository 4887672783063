export const customerListSettingCache = 'CUSTOMERLIST_SETTING_CACHE';

export default interface ICustomerListSetting {
    setting: string;
    check: boolean;
}

export function saveCustomerListSetting(setting: string, check: boolean) {
    let storedCustomerlistSettings = localStorage.getItem(customerListSettingCache);
    let customerListSettings: ICustomerListSetting[] = storedCustomerlistSettings ? JSON.parse(storedCustomerlistSettings) : [];
    customerListSettings = customerListSettings.filter((x: ICustomerListSetting) => x.setting !== setting);
  
    const currentActivePage: ICustomerListSetting = {
      setting: setting,
      check: check,
    };
  
    customerListSettings.unshift(currentActivePage);
  
    localStorage.setItem(customerListSettingCache, JSON.stringify(customerListSettings));
  }


export function getCustomerListSetting(setting: string) {
    let storedCustomerlistSettings = localStorage.getItem(customerListSettingCache);
    let customerListSettings: ICustomerListSetting[] = storedCustomerlistSettings ? JSON.parse(storedCustomerlistSettings) : undefined
  
    if (!customerListSettings) {
        saveCustomerListSetting(setting, false);
        return false;
    }

    return customerListSettings.find((x) => x.setting === setting)?.check ?? false;
}