import React, { useEffect, useMemo, useState } from 'react';
import { useRouteMatch, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import useHeaderNavigationContext from '../../../Shared/Navigation/Header/HeaderNavigationContext/HeaderNavigationContext';
import { INavigationItem } from '../../../Shared/Navigation/Header/Header';
import OfferList from './Components/Offer/OfferList/OfferList';
import OfferForm from './Components/Offer/OfferForm/OfferForm';
import ViewOffer from './Components/Offer/ViewOffer/ViewOffer';
import OrderList from './Components/Order/OrderList/OrderList';
import ViewOrder from './Components/Order/ViewOrder/ViewOrder';
import { WorkflowStatus } from './Components/Sale.api';
import AuthRoute from '../../../Core/Authentication/AuthRoute/AuthRoute';
import { ModuleIdentifiers, AccessRights } from '../../../Core/Authentication/ModuleAccess';
import ViewSupplierOrder from './Components/SupplierOrder/ViewSupplierOrder/ViewSupplierOrder';
import SupplierOrderList from './Components/SupplierOrder/SupplierOrderList/SupplierOrderList';
import CreateSupplierOrdersForm from './Components/Order/ViewOrder/CreateSupplierOrders/CreateSupplierOrdersForm';
import ProductForm from './Components/Order/ViewOrder/ProductForm/ProductForm';
import ReportList from '../Errands/Components/Reports/Components/ReportList/ReportList';
import ReportForm from '../Errands/Components/Reports/Components/ReportForm/ReportForm';
import ErrandAgreementList from './Components/ErrandAgreementList/ErrandAgreementList/ErrandAgreementList';
import { EntityType, useGetBreadCrumb } from '../../../Shared/Navigation/Breadcrumbs/Breadcrumbs.api';
import OrderForm from './Components/Order/OrderForm/OrderForm';
import AddCostForm from './Components/Order/ViewOrder/AddCostForm/AddCostForm';
import useUserContext, { hasUserAccessToModule } from '../../../Core/Authentication/UserContext';
import UpdateCostForm from './Components/Order/ViewOrder/UpdateCostForm/UpdateCostForm';

function ErrandsRoute() {
  const { path } = useRouteMatch();
  const location = useLocation();
  const { user } = useUserContext();
  const id = '[\\d]+';
  const [costId, setCostId] = useState<string>();
  const { setHeaderNavigationItems, setHeaderNavigationText } = useHeaderNavigationContext();
  const { name: breadCrumbName, getBreadCrumb } = useGetBreadCrumb();
  const redirectUrl = useMemo(() => {
    if (hasUserAccessToModule(user, ModuleIdentifiers.Offers, AccessRights.Read)) return `${path}/offers`;
    if (hasUserAccessToModule(user, ModuleIdentifiers.Orders, AccessRights.Read)) return `${path}/orders`;
    if (hasUserAccessToModule(user, ModuleIdentifiers.Agreements, AccessRights.Read)) return `${path}/agreements`;
    else return path;
  }, [user, path]);

  useEffect(() => {
    async function handleBreadCrumb() {
      const params = location.pathname.match(new RegExp(id, 'g'));

      if (params) {
        await (await getBreadCrumb({ entityId: Number(params[0]), type: EntityType.Sale }))?.breadCrumbName;
        if (params.length > 1) {
          setCostId(params[1]);
        }
      }
    }
    handleBreadCrumb();
  }, [location.pathname, getBreadCrumb]);

  useEffect(() => {

    const navigationItems: INavigationItem[] = [
      {
        title: 'Offert',
        to: `${path}/offers`,
        exact: false,
      },
      {
        title: 'Pågående',
        to: `${path}/orders`,
        exact: false,
      },
      {
        title: 'Fakturering',
        to: `${path}/invoice`,
        exact: false,
      },
      {
        title: 'Avtal',
        to: `${path}/agreements`,
        exact: false,
      },
      {
        title: 'Beställning ',
        to: `${path}/supplierorders`,
        exact: false,
      },
      {
        title: 'Felrapport',
        to: `${path}/reports`,
        exact: false,
      },
      {
        title: 'Arkiv',
        to: `${path}/archive`,
        exact: false,
      },
    ];
    const HeaderNavigation: INavigationItem[] = [
      {
        title: 'Offert',
        to: `${path}/offers/${id}`,
        exact: false,
      },
      {
        title: 'Fakturaunderlag',
        to: `${path}/invoice/${id}`,
        exact: false,
      },
      {
        title: 'Skapa offert',
        to: `${path}/offers/create`,
        exact: false,
      },
      {
        title: 'Skapa kostnad',
        to: `${path}/invoice/${id}/cost/create`,
        exact: false,
      },
      {
        title: 'Uppdate kostnad',
        to: `${path}/invoice/${id}/cost/${costId}/update`,
        exact: false,
      },
      {
        title: 'Order',
        to: `${path}/orders/${id}`,
        exact: false,
      },
      {
        title: 'Arkiverad order',
        to: `${path}/archive${id}/`,
        exact: false,
      },
      {
        title: 'Avtal',
        to: `${path}/agreements`,
        exact: false,
      },
      {
        title: 'Beställning',
        to: `${path}/supplierorders/${id}`,
        exact: false,
      },
      {
        title: 'Redigera felrapport',
        to: `${path}/reports/${id}/update`,
        exact: false,
      },
      {
        title: 'Skapa felrapport',
        to: `${path}/reports/create`,
        exact: false,
      },
    ];

    setHeaderNavigationText(HeaderNavigation.concat(navigationItems));
    setHeaderNavigationItems(navigationItems);

    return () => {
      setHeaderNavigationItems([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, setHeaderNavigationText, setHeaderNavigationItems]);

  return (
    <Switch>
      <AuthRoute
        exact
        path={`${path}/offers`}
        module={ModuleIdentifiers.Offers}
        accessRights={AccessRights.Read}
        breadcrumbs={[{ title: 'Ärenden' }, { title: 'Offerter' }]}
      >
        <h1 className={'sr-only'}>Lista över offerter</h1>
        <OfferList />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/offers/create`}
        module={ModuleIdentifiers.Offers}
        accessRights={AccessRights.Write}
        breadcrumbs={[{ title: 'Ärenden' }, { title: 'Offerter', to: `${path}/offers` }, { title: 'Skapa offert' }]}
      >
        <h1 className={'sr-only'}>Formulär för att skapa ny offert</h1>
        <OfferForm />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/offers/:id`}
        module={ModuleIdentifiers.Offers}
        accessRights={AccessRights.Read}
        breadcrumbs={[{ title: 'Ärenden' }, { title: 'Offerter', to: `${path}/offers` }, { title: 'Offert' }]}
      >
        <h1 className={'sr-only'}>Information om offert</h1>
        <ViewOffer />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/offers/:id/update`}
        module={ModuleIdentifiers.Offers}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Ärenden' },
          { title: 'Offerter', to: `${path}/offers` },
          { title: 'Offert', to: `${path}/offers/:id` },
          { title: 'Redigera' },
        ]}
      >
        <h1 className={'sr-only'}>Uppdatera offert</h1>
        <OfferForm />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/offers/:id/draft`}
        module={ModuleIdentifiers.Offers}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Ärenden' },
          { title: 'Offerter', to: `${path}/offers` },
          { title: breadCrumbName?.breadCrumbName || '' },
          { title: 'Utkast' },
        ]}
      >
        <h1 className={'sr-only'}>Utkast</h1>
        <OfferForm />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/orders`}
        module={ModuleIdentifiers.Orders}
        accessRights={AccessRights.Read}
        breadcrumbs={[{ title: 'Ärenden' }, { title: 'Pågående' }]}
      >
        <h1 className={'sr-only'}>Lista över ordrar</h1>
        <OrderList />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/orders/create`}
        module={ModuleIdentifiers.Orders}
        accessRights={AccessRights.Write}
        breadcrumbs={[{ title: 'Ärenden' }, { title: 'Ordrar', to: `${path}/orders` }, { title: 'Skapa order' }]}
      >
        <h1 className={'sr-only'}>Skapa order</h1>
        <OrderForm location={`${path}/orders`} />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/orders/:id`}
        module={ModuleIdentifiers.Orders}
        accessRights={AccessRights.Read}
        breadcrumbs={[
          { title: 'Ärenden' },
          { title: 'Ordrar', to: `${path}/orders` },
          { title: breadCrumbName?.breadCrumbName || '' },
          { title: 'Order' },
        ]}
      >
        <h1 className={'sr-only'}>Visa order</h1>
        <ViewOrder />
      </AuthRoute>
      <AuthRoute
        exact
        path={[`${path}/invoice/:id/cost/create`, `${path}/orders/:id/cost/create`, `${path}/archive/:id/cost/create`]}
        module={ModuleIdentifiers.Orders}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Ärenden' },
          { title: 'Ordrar', to: `${path}/invoice` },
          { title: 'Order', to: `${path}/invoice/:id` },
          { title: 'Skapa kostnad' },
        ]}
      >
        <h1 className={'sr-only'}>Skapa kostnad</h1>
        <AddCostForm />
      </AuthRoute>      
      <AuthRoute
        exact
        path={[`${path}/invoice/:id/cost/:costId/update`, `${path}/orders/:id/cost/:costId/update`, `${path}/archive/:id/cost/:costId/update`]}
        module={ModuleIdentifiers.Orders}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Ärenden' },
          { title: 'Ordrar', to: `${path}/invoice` },
          { title: 'Order', to: `${path}/invoice/:id` },
          { title: 'Uppdatera kostnad' },
        ]}
      >
        <h1 className={'sr-only'}>Uppdatera kostnad</h1>
        <UpdateCostForm />
      </AuthRoute>
      <AuthRoute
        exact
        path={[`${path}/orders/:id/product/add`]}
        module={ModuleIdentifiers.Orders}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Ärenden' },
          { title: 'Ordrar', to: `${path}/orders` },
          { title: 'Order', to: `${path}/orders/:id` },
          { title: 'Lägg till produkt' },
        ]}
      >
        <h1 className={'sr-only'}>Formulär för att lägga till produkt</h1>
        <ProductForm />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/agreements`}
        module={ModuleIdentifiers.Agreements}
        accessRights={AccessRights.Read}
        breadcrumbs={[{ title: 'Ärenden' }, { title: 'Avtal', to: `${path}/agreements` }]}
      >
        <h1 className={'sr-only'}>Lista över avtal</h1>
        <ErrandAgreementList />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/orders/:id/supplierorders`}
        module={ModuleIdentifiers.SupplierOrders}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Ärenden' },
          { title: 'Ordrar', to: `${path}/orders` },
          { title: 'Order', to: `${path}/orders/:id` },
          { title: 'Skapa beställning' },
        ]}
      >
        <h1 className={'sr-only'}>Formulär för att skapa beställning</h1>
        <CreateSupplierOrdersForm />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/invoice`}
        module={ModuleIdentifiers.Orders}
        accessRights={AccessRights.Read}
        breadcrumbs={[{ title: 'Ärenden' }, { title: 'Fakturaunderlag' }]}
      >
        <h1 className={'sr-only'}>Lista över ordrar med status fakturering</h1>
        <OrderList workflowStatus={WorkflowStatus.Invoicing} />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/invoice/:id`}
        module={ModuleIdentifiers.Orders}
        accessRights={AccessRights.Read}
        breadcrumbs={[
          { title: 'Ärenden' },
          { title: 'Fakturaunderlag', to: `${path}/invoice` },
          { title: breadCrumbName?.breadCrumbName || '' },
          { title: 'Fakturaunderlag' },
        ]}
      >
        <h1 className={'sr-only'}>Information om ordern</h1>
        <ViewOrder />
      </AuthRoute>

      {/* TODO: add offers too */}
      <AuthRoute
        exact
        path={`${path}/archive`}
        module={ModuleIdentifiers.Orders}
        accessRights={AccessRights.Read}
        breadcrumbs={[{ title: 'Ärenden' }, { title: 'Arkiverade ordrar' }]}
      >
        <h1 className={'sr-only'}>Lista över ordrar med status arkiv</h1>
        <OrderList workflowStatus={WorkflowStatus.Archive} />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/archive/:id`}
        module={ModuleIdentifiers.Orders}
        accessRights={AccessRights.Read}
        breadcrumbs={[
          { title: 'Ärenden' },
          { title: 'Arkiverade ordrar', to: `${path}/archive` },
          { title: breadCrumbName?.breadCrumbName || '' },
          { title: 'Order' },
        ]}
      >
        <h1 className={'sr-only'}>Information om ordern</h1>
        <ViewOrder />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/supplierorders`}
        module={ModuleIdentifiers.SupplierOrders}
        accessRights={AccessRights.Read}
        breadcrumbs={[{ title: 'Ärenden' }, { title: 'Beställningar' }]}
      >
        <h1 className={'sr-only'}>Lista över beställningar</h1>
        <SupplierOrderList />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/supplierorders/:id`}
        module={ModuleIdentifiers.SupplierOrders}
        accessRights={AccessRights.Read}
        breadcrumbs={[
          { title: 'Ärenden' },
          { title: 'Beställningar', to: `${path}/supplierorders` },
          { title: breadCrumbName?.breadCrumbName || '' },
          { title: 'Beställning' },
        ]}
      >
        <h1 className={'sr-only'}>Visa beställning</h1>
        <ViewSupplierOrder />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/reports`}
        module={ModuleIdentifiers.ErrorReport}
        accessRights={AccessRights.Read}
        breadcrumbs={[{ title: 'Ärenden' }, { title: 'Felrapporter' }]}
      >
        <h1 className={'sr-only'}>Lista över felrapporter</h1>
        <ReportList />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/reports/create`}
        module={ModuleIdentifiers.ErrorReport}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Ärenden' },
          { title: 'Felrapporter', to: `${path}/reports` },
          { title: 'Skapa felrapport' },
        ]}
      >
        <h1 className={'sr-only'}>Formulär för att skapa en felrapport</h1>
        <ReportForm />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/reports/:id/update`}
        module={ModuleIdentifiers.ErrorReport}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Ärenden' },
          { title: 'Felrapporter', to: `${path}/reports` },
          { title: 'Redigera felrapport' },
        ]}
      >
        <h1 className={'sr-only'}>Formulär för att redigera en felrapport</h1>
        <ReportForm />
      </AuthRoute>
      <Route path={[path, `${path}/*`]}>
        <Redirect to={redirectUrl} />
      </Route>
    </Switch>
  );
}

export default ErrandsRoute;
