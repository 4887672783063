import {
  ListItemIcon,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import EditIcon from '@material-ui/icons/EditRounded';
import FileOpenIcon from '@material-ui/icons/FileCopyRounded';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AccessGuard from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { AccessRights, ModuleIdentifiers } from '../../../../../../Core/Authentication/ModuleAccess';
import formatDate from '../../../../../../Shared/Formatting/formatDate';
import { DropdownCell } from '../../../../../../Shared/Table/DropdownCell/DropdownCell';
import { Table } from '../../../../../../Shared/Table/Table';
import TablePaginationWithDatasource from '../../../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import useTable from '../../../../../../Shared/Table/useTable';
import {
  IAgreementListItemDto,
  useGetCustomerAgreements,
  useUpdateAgreementExternalNumber,
} from '../../../Customers.api';
import RenewAgreementPopup from '../../Facilities/CoreSystems/ViewCoreSystem/Components/RenewAgreementPopup/RenewAgreementPopup';
import TerminateAgreementForm from '../TerminateAgreementForm/TerminateAgreementForm';
import { useStyles } from '../../../../../../Shared/Table/TableRowHeader/TableRowHeader.styles';
import SortButton from '../../../../../../Shared/Table/SortButton/SortButton';
import { getCurrentDay } from '../../../../../Shared/utils';
import { useGetAgreementPdf } from '../../../../Errands/Components/ErrandAgreementList/Agreements.api';
import { openFile } from '../../../../../../Shared/Api/response/IFileResult';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import IconButton from '../../../../../../Shared/IconButton/IconButton';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import useInputState from '../../../../../../Shared/Hooks/UseInputState/UseInputState';
import Button from '../../../../../../Shared/Button/Button';
import SoftDeleteAgreementForm from '../SoftDeleteAgreementForm/SoftDeleteAgreementForm';
interface IAgreementListProps {
  customerId: string;
  facilityId?: string;
  coreSystemId?: string;
}

const emptyMessage = 'Inga avtal tillagda';

const AgreementList = (props: IAgreementListProps) => {
  const { customerId, facilityId, coreSystemId } = props;
  const { isLoading, agreements, getCustomerAgreements } = useGetCustomerAgreements(customerId, facilityId);
  const { isLoading: updateExternalNumberLoading, updateExternalNumber } = useUpdateAgreementExternalNumber();
  const { getAgreementPdf } = useGetAgreementPdf();
  const { toggleSort, sortProperty, sortDirection, pageSize, setPageSize, setPageNumber, paginatedRequest } = useTable(
    10,
  );
  const [agreementItems, setagreementItems] = useState<IAgreementListItemDto[] | undefined>([]);

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down(1080 / 16));

  const [openRenewForm, setOpenRenewForm] = useState(false);
  const [openTerminateForm, setOpenTerminateForm] = useState(false);
  const [openSoftDeleteForm, setOpenSoftDeleteFrom] = useState(false);
  const [editmode, setEditMode] = useState(true);
  const [editExternalNumber, setEditExternalNumber, changeEditExternalNumber] = useInputState<string | undefined>('');
  const [selectedAgreement, setSelectedAgreement] = useState<IAgreementListItemDto>();

  const history = useHistory();
  const classes = useStyles();

  const handleGetAgreement = useCallback(() => {
    getCustomerAgreements({ ...paginatedRequest, coreSystemId: coreSystemId });
  }, [coreSystemId, getCustomerAgreements, paginatedRequest]);

  useEffect(() => {
    handleGetAgreement();
  }, [handleGetAgreement]);

  useEffect(() => {
    if (editmode) {
      agreements?.items.forEach((x) => (x.editMode = false));
      setagreementItems(agreements?.items);
    }
  }, [agreements, editmode]);

  const checkEditable = (d: Date | undefined) => {
    if (d) return new Date(d) > getCurrentDay();
    else return true;
  };

  const handleTableClick = async (agreementId: number) => {
    var agreementPdf = await getAgreementPdf(agreementId.toString());
    if (agreementPdf) openFile(agreementPdf, mobileView);
  };

  const handleExternalClick = async (agreementId: number | undefined) => {
    if (agreementId === undefined) {
      setEditMode(false);
    }

    agreementItems?.forEach((x) => (x.editMode = false));
    const item = agreementItems?.find((x) => x.id === agreementId);
    if (item) {
      item.editMode = !item?.editMode;
      changeEditExternalNumber(item.externalAgreementInvoiceNumber);
    }
    if (agreementItems) {
      setagreementItems([...agreementItems]);
      setEditMode(!editmode);
    }
  };

  const getAgreementRoute = () =>
    facilityId ? `/customers/${props.customerId}/facility/${facilityId}` : `/customers/${props.customerId}`;

  const handleAgreementOperation = async (agreementId: number, editAgreement?: boolean) => {
    if (editAgreement) {
      history.push(`${getAgreementRoute()}/agreements/${agreementId}/update`);
      return;
    }
    await handleTableClick(agreementId);
    history.push(`${getAgreementRoute()}/agreements/${agreementId}`);
  };

  //Couldn't find a way to put modal inside DropdownCell/Popper. OnKeyDown event of Popper prevent InputField of the modal so we have to put the modal outside the loop

  return (
    <Table
      customSize={{
        fullsize: true,
      }}
      iconButtons={
        !props.coreSystemId && (
          <AccessGuard module={ModuleIdentifiers.Customer} accessRights={AccessRights.Write}>
            <IconButton
              size="small"
              aria-label="Lägg till avtal"
              onClick={() => history.push(`${history.location.pathname}/agreements/create`)}
            >
              <AddIcon />
            </IconButton>
          </AccessGuard>
        )
      }
      pagination={
        agreements && (
          <TablePaginationWithDatasource
            datasource={agreements}
            onChangePage={setPageNumber}
            onChangeRowsPerPage={setPageSize}
            addPageSize={[10, 15, 20]}
            fixed
          />
        )
      }
      empty={{
        empty: agreements && agreements.totalCount <= 0 ? true : false,
        message: emptyMessage,
      }}
      loading={{
        loading: isLoading,
        skeletonRows: pageSize,
      }}
    >
      {selectedAgreement && (
        <>
          <RenewAgreementPopup
            customerId={props.customerId}
            agreementId={selectedAgreement.id.toString()}
            startDate={selectedAgreement.endDate ?? getCurrentDay()}
            onRenew={handleGetAgreement}
            open={openRenewForm}
            facilityId={selectedAgreement.facilityId}
            isSaleAgreement={selectedAgreement.isSaleAgreement}
            setOpen={setOpenRenewForm}
          />
          <TerminateAgreementForm
            customerId={props.customerId}
            agreementId={selectedAgreement.id.toString()}
            startDate={selectedAgreement.startDate}
            otherLoading={isLoading}
            onTerminate={handleGetAgreement}
            open={openTerminateForm}
            setOpen={setOpenTerminateForm}
          />
          <SoftDeleteAgreementForm
            agreementId={selectedAgreement.id.toString()}
            onTerminate={handleGetAgreement}
            open={openSoftDeleteForm}
            setOpen={setOpenSoftDeleteFrom}
          />
        </>
      )}
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>
            Avtalsnummer
            <SortButton
              property="agreementIdentifier"
              sortProperty={sortProperty}
              sortDirection={sortDirection}
              onSort={toggleSort}
            />
          </TableCell>
          <TableCell>
            Externt avtalsfakturanr
            <SortButton
              property="externalAgreementInvoiceNumber"
              sortProperty={sortProperty}
              sortDirection={sortDirection}
              onSort={toggleSort}
            />
          </TableCell>
          {!props.coreSystemId && (
            <>
              {!facilityId && <TableCell>Plats</TableCell>}
              <TableCell>SID</TableCell>
            </>
          )}
          <TableCell>
            Från
            <SortButton
              property="startDate"
              sortProperty={sortProperty}
              sortDirection={sortDirection}
              onSort={toggleSort}
            />
          </TableCell>
          <TableCell>
            Till
            <SortButton
              property="endDate"
              sortProperty={sortProperty}
              sortDirection={sortDirection}
              onSort={toggleSort}
            />
          </TableCell>
          <TableCell>
            Månadskostnad
            <SortButton
              property="monthlyCost"
              sortProperty={sortProperty}
              sortDirection={sortDirection}
              onSort={toggleSort}
            />
          </TableCell>
          {!props.coreSystemId && <TableCell>Status</TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {agreementItems?.map((agreement) => (
          <>
            <TableRow className={classes.pointerCursor} hover tabIndex={-1} key={agreement.id.toString()}>
              <DropdownCell>
                <AccessGuard module={ModuleIdentifiers.Customer} accessRights={AccessRights.Write}>
                  <>
                    <MenuItem onClick={() => handleAgreementOperation(agreement.id)}>
                      <ListItemIcon>{<FileOpenIcon />}</ListItemIcon>
                      <Typography variant="inherit">Visa</Typography>
                    </MenuItem>
                    {checkEditable(agreement.endDate) && !agreement.isTerminated && (
                      <MenuItem onClick={() => handleAgreementOperation(agreement.id, true)}>
                        <ListItemIcon>{<EditIcon />}</ListItemIcon>
                        <Typography variant="inherit">Ändra</Typography>
                      </MenuItem>
                    )}
                    {agreement.endDate && (
                      <MenuItem
                        onClick={() => {
                          setSelectedAgreement(agreement);
                          setOpenRenewForm(true);
                        }}
                      >
                        <ListItemIcon>{<AutorenewIcon />}</ListItemIcon>
                        <Typography variant="inherit">Förnya</Typography>
                      </MenuItem>
                    )}
                    {!agreement.isTerminated && (
                      <MenuItem
                        onClick={() => {
                          setSelectedAgreement(agreement);
                          setOpenTerminateForm(true);
                        }}
                      >
                        <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                        <Typography variant="inherit">Säg upp</Typography>
                      </MenuItem>
                    )}
                  </>
                </AccessGuard>
                <AccessGuard module={ModuleIdentifiers.Agreements} accessRights={AccessRights.Full}>
                  <MenuItem
                    onClick={() => {
                      setSelectedAgreement(agreement);
                      setOpenSoftDeleteFrom(true);
                    }}
                  >
                    <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                    <Typography variant="inherit">Ta Bort</Typography>
                  </MenuItem>
                </AccessGuard>
              </DropdownCell>

              <TableCell className={classes.linkFont} onClick={() => handleTableClick(agreement.id)}>
                {agreement.agreementIdentifier}
              </TableCell>
              <TableCell
                onClick={() => {
                  handleExternalClick(agreement.id);
                }}
              >
                {!agreement.editMode && <>{agreement.externalAgreementInvoiceNumber}</>}
                {agreement.editMode && (
                  <>
                    <InputField
                      fullwidth={true}
                      customWidth={4}
                      type="text"
                      value={editExternalNumber}
                      onChange={setEditExternalNumber}
                      autoFocus
                    />
                    <br />
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={async () => {
                        await updateExternalNumber(
                          { externalAgreementInvoiceNumber: editExternalNumber ?? '' },
                          agreement.id.toString(),
                        );

                        handleExternalClick(undefined);
                        await handleGetAgreement();
                      }}
                      loading={updateExternalNumberLoading}
                    >
                      Uppdatera
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={async () => {
                        handleExternalClick(undefined);
                      }}
                      loading={updateExternalNumberLoading}
                    >
                      Avbryt
                    </Button>
                  </>
                )}
              </TableCell>
              {!props.coreSystemId && (
                <>
                  {!facilityId && (
                    <TableCell onClick={() => handleTableClick(agreement.id)}>
                      {agreement.facilityName && agreement.facilityName}
                    </TableCell>
                  )}
                  <TableCell
                    className={agreement.coreSystemId ? classes.linkFont : ''}
                    onClick={() =>
                      agreement.coreSystemId
                        ? history.push(
                            `${history.location.pathname}/facility/${agreement.facilityId}/system/${agreement.coreSystemId}`,
                          )
                        : undefined
                    }
                  >
                    {agreement.coreSystemIdentifier}
                  </TableCell>
                </>
              )}
              <TableCell onClick={() => handleTableClick(agreement.id)}>{formatDate(agreement.startDate)}</TableCell>
              <TableCell onClick={() => handleTableClick(agreement.id)}>
                {agreement.endDate ? formatDate(agreement.endDate) : 'Tillsvidare'}
              </TableCell>
              <TableCell onClick={() => handleTableClick(agreement.id)}>{`${agreement.monthlyCost} SEK`}</TableCell>
              {!props.coreSystemId && (
                <TableCell onClick={() => handleTableClick(agreement.id)}>
                  {agreement.isTerminated ? 'Uppsagt' : agreement.isActive ? 'Aktiv' : 'Inaktiv'}
                </TableCell>
              )}
            </TableRow>
          </>
        ))}
      </TableBody>
    </Table>
  );
};

export default AgreementList;
