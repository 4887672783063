import { makeStyles, Theme } from '@material-ui/core/styles';
import { InputFieldBaseStyles } from '../InputFields-styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& input': {
      ...InputFieldBaseStyles,
      paddingLeft: `3rem`,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '18rem',
    },
  },
  inline: {
    '& input': {
      border: 'none',
    },
  },
}));
