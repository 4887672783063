import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { FormControlLabel, Radio } from '@material-ui/core';
import Container from '../../../../../../../Shared/Container/Container';
import RadioField from '../../../../../../../Shared/InputFields/RadioField/RadioField';
import Select, { ISelectOption } from '../../../../../../../Shared/Select/Select';
import WizardFormActions from '../../../../../../../Shared/Form/WizardFormActions/WizardFormActions';
import { Fieldset } from '../../../../../../../Shared/Form/Fieldset/Fieldset';
import { InputField } from '../../../../../../../Shared/InputFields/InputField/InputField';
import { isResultError } from '../../../../../../../Shared/Api/response/ICreateResult';
import useInputState from '../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import useFormValidation from '../../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import OfferFormContext from '../OfferFormContext';
import { termsValidPeriodTexts } from '../../../../../Admin/Components/Preselection/Preselection.api';
import {
  getNextPage,
  getPreviousPage,
  IUpdateOfferTermsCommand,
  useGetOfferTerms,
  useUpdateOfferTerms,
} from '../../Offer.api';

const termsValidPeriodOptions: ISelectOption[] = termsValidPeriodTexts.map((x) => ({ label: x, value: x }));

const SetSaleTerms: React.FunctionComponent = () => {
  const { id: offerId } = useParams<{ id: string }>();
  const { offerStep, setOfferStep, offerType, identifier } = useContext(OfferFormContext);
  const { isFormValid, formRef } = useFormValidation();

  const { get: getOfferTerms, isLoading: isGetOfferTermsLoading } = useGetOfferTerms(offerId);
  const { put: updateOfferTerms, isLoading: isUpdateOfferTermsLoading } = useUpdateOfferTerms(offerId);

  const [offerDescription, changeOfferDescription, setOfferDescription] = useInputState('');
  const [termsIncluding, changeTermsIncluding, setTermsIncluding] = useInputState('');
  const [termsExcluding, changeTermsExcluding, setTermsExcluding] = useInputState('');
  const [termsRegulation, changeTermsRegulation, setTermsRegulation] = useInputState('');
  const [termsWarranty, changeTermsWarranty, setTermsWarranty] = useInputState('');
  const [termsDelivery, changeTermsDelivery, setTermsDelivery] = useInputState('');
  const [termsValidPeriod, setTermsValidPeriod] = useState('30 dagar'); //TODO: is valid period necessary??
  const [customerCanApprove, setCustomerCanApprove] = useState(false);

  useEffect(() => {
    async function loadOfferTermsData() {
      if (!!offerId) {
        const response = await getOfferTerms();

        if (response) {
          setOfferDescription(response.offerDescription ?? '');
          setCustomerCanApprove(response.customerCanApprove);
          setTermsIncluding(response.terms?.including ?? '');
          setTermsExcluding(response.terms?.excluding ?? '');
          setTermsRegulation(response.productRegulation || response.terms?.regulation || '');
          setTermsWarranty(response.terms?.warranty ?? '');
          setTermsDelivery(response.terms?.delivery ?? '');
          setTermsValidPeriod(response.terms.validPeriod && response.terms.validPeriod !== '' ? response.terms.validPeriod :  '30 dagar');
        }
      }
    }
    loadOfferTermsData();
  }, [
    offerId,
    getOfferTerms,
    setOfferDescription,
    setTermsDelivery,
    setTermsExcluding,
    setTermsIncluding,
    setTermsRegulation,
    setTermsWarranty,
  ]);

  /* Insert/update offer terms */
  const upsertOfferTerms = async () => {
    if (offerType === undefined) {
      return;
    }

    const updateOfferTermsCommand: IUpdateOfferTermsCommand = {
      offerDescription: offerDescription,
      terms: {
        including: termsIncluding,
        excluding: termsExcluding,
        regulation: termsRegulation,
        warranty: termsWarranty,
        delivery: termsDelivery,
        validPeriod: termsValidPeriod,
      },
      customerCanApprove: customerCanApprove,
    };

    const result = await updateOfferTerms(updateOfferTermsCommand);

    if (!isResultError(result)) {
      setOfferStep(getNextPage(offerType, offerStep));
    } else {
      //?? TODO: How to handle result error?
    }
  };

  return (
    <Container
      form
      ref={formRef}
      label={'Redigera ' + identifier}
      loading={isGetOfferTermsLoading}
      actions={
        <WizardFormActions
          goBack={() => setOfferStep(getPreviousPage(offerType!, offerStep))}
          goNext={upsertOfferTerms}
          isFormValid={isFormValid}
          isProcessing={isUpdateOfferTermsLoading}
        />
      }
      customSize={{ md: 12, lg: 6 }}
    >
      <Fieldset legend="Förklarande text" customSize={{ md: 12, lg: 12 }}>
        <InputField
          label="Förklarande text på offert"
          type="text"
          multiline
          fullwidth
          rows="10"
          value={offerDescription}
          onChange={changeOfferDescription}
          required
        />
      </Fieldset>

      <Fieldset legend="Villkor" customSize={{ md: 12, lg: 12 }}>
        <InputField
          label="Inklusive"
          type="text"
          multiline
          rows="5"
          value={termsIncluding}
          onChange={changeTermsIncluding}
          customWidth={6}
        />
        <InputField
          label="Enligt regelverk"
          multiline
          type="text"
          rows="5"
          value={termsRegulation}
          onChange={changeTermsRegulation}
          customWidth={6}
        />
        <InputField
          label="Exklusive"
          multiline
          type="text"
          rows="5"
          value={termsExcluding}
          onChange={changeTermsExcluding}
          customWidth={6}
        />
        <Select
          inputFieldProps={{ label: 'Giltighetstid', customWidth: 6 }}
          selectProps={{
            isClearable: true,
            options: termsValidPeriodOptions,
            value: termsValidPeriodOptions.find((x) => x.value === termsValidPeriod),
            onChange: (option) => {
              setTermsValidPeriod(option ? (option as ISelectOption).value : '');
            },
          }}
        />
        <InputField
          label="Leverans"
          multiline
          type="text"
          rows="5"
          value={termsDelivery}
          onChange={changeTermsDelivery}
          customWidth={6}
        />
        <InputField
          label="Garanti"
          multiline
          type="text"
          rows="5"
          value={termsWarranty}
          onChange={changeTermsWarranty}
          customWidth={6}
        />

        <RadioField label="Kan godkännas direkt av kund">
          <FormControlLabel
            label="Ja"
            control={<Radio color="primary" />}
            checked={customerCanApprove}
            onChange={() => {
              setCustomerCanApprove(true);
            }}
          />
          <FormControlLabel
            label="Nej"
            control={<Radio color="primary" />}
            checked={!customerCanApprove}
            onChange={() => {
              setCustomerCanApprove(false);
            }}
          />
        </RadioField>
      </Fieldset>
    </Container>
  );
};

export default SetSaleTerms;
