import React, { useEffect } from 'react';
import useInputState from '../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { InputField } from '../../../../../../../Shared/InputFields/InputField/InputField';
import { isResultError } from '../../../../../../../Shared/Api/response/ICreateResult';
import { IPreselectionProps } from '../../Preselection';
import { useGetPreselectedEmailContent, useAddPreselectedEmailContent } from '../../Preselection.api';
import useStyles from '../../Preselection.styles';

const EmailContent = (props: IPreselectionProps) => {
  const classes = useStyles();
  const { setIsLoading, saveCallback } = props;
  const { isLoading: isGetLoading, getPreselectedEmailContent } = useGetPreselectedEmailContent();
  const { isLoading, addPreselectedEmailContent } = useAddPreselectedEmailContent();

  const [footerText, changeFooterText, setFooterText] = useInputState('');

  useEffect(() => {
    setIsLoading(isGetLoading || isLoading);
  }, [isGetLoading, isLoading, setIsLoading]);

  useEffect(() => {
    const save: any = async () => {
      var result = await addPreselectedEmailContent({
        footerText: footerText,
      });
      if (isResultError(result)) {
        return false;
      }
      return true;
    };
    saveCallback(() => save);
  }, [footerText, saveCallback, addPreselectedEmailContent]);

  useEffect(() => {
    async function getValues() {
      const result = await getPreselectedEmailContent();
      if (result) {
        setFooterText(result.latestEmailContentData.footerText);
      } else {
        return false;
      }
    }
    getValues();
  }, [setFooterText, getPreselectedEmailContent]);

  return (
    <>
      <div className={`${classes.forPdf} ${classes.wysiwygFields}`}>
        <InputField
          label="Footer text i email"
          type="text"
          multiline
          fullwidth
          rows="5"
          value={footerText}
          onChange={changeFooterText}
          required
        />
      </div>
    </>
  );
};

export default EmailContent;
