import React, { useEffect, useState } from 'react';
import IconButton from '../../../../../../../Shared/IconButton/IconButton';
import EditIcon from '@material-ui/icons/EditRounded';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import { Grid } from '@material-ui/core';
import { AddCircleRounded } from '@material-ui/icons';
import Button from '../../../../../../../Shared/Button/Button';
import { Select } from '../../../../../../../Shared/Select';
import { Fieldset } from '../../../../../../../Shared/Form/Fieldset/Fieldset';
import { InputField } from '../../../../../../../Shared/InputFields/InputField/InputField';
import Datepicker from '../../../../../../../Shared/InputFields/DatePicker/Datepicker';
import useInputState from '../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import formatDate from '../../../../../../../Shared/Formatting/formatDate';
import {
  educationOptions,
  educationStatusOptions,
  EducationType,
  IEducationOptions,
  IUserEducation,
} from '../../Users.api';
import { useStyles } from './Education.styles';

interface IEducationRowProps {
  initdata?: IUserEducation;
  submit: (data: IUserEducation) => void;
  cancel?: () => void;
}

const EducationFormRow = (props: IEducationRowProps) => {
  const classes = useStyles();
  const { initdata, submit, cancel } = props;
  const [education, changeEducation, setEducation] = useInputState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [startDateError, setStartDateError] = useState<string>();
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [endDateError, setEndDateError] = useState<string>();
  const [educationStatus, setEducationStatus] = useState<EducationType | undefined>();
  const invalid = !education || (startDate !== null && endDate !== null && startDate >= endDate);

  useEffect(() => {
    if (initdata) {
      setEducation(initdata.education);
      if (initdata.startDate !== null) setStartDate(new Date(initdata.startDate));
      if (initdata.endDate !== null) setEndDate(new Date(initdata.endDate));
    }
  }, [initdata, setEducation]);

  const emptyForm = () => {
    setEducation('');
    setEducationStatus(undefined);
    setStartDate(null);
    setEndDate(null);
  };

  const onStartDateChange = (date: Date | null) => {
    let errorText = date && endDate && date >= endDate ? 'Startdatum måste vara före slutdatum' : undefined;
    setStartDateError(errorText);
    setEndDateError(undefined);
    setStartDate(date);
  };

  const onEndDateChange = (date: Date | null) => {
    let errorText = startDate && date && startDate >= date ? 'Slutdatum måste vara efter startdatum' : undefined;
    setStartDateError(undefined);
    setEndDateError(errorText);
    setEndDate(date);
  };

  const onSubmit = () => {
    if (invalid) return;
    let newRow: IUserEducation = {
      id: initdata ? initdata.id : null,
      education: education,
      educationStatus: educationStatus,
      startDate: startDate!,
      endDate: endDate!,
    };
    submit(newRow);
    emptyForm();
  };

  return (
    <>
      <InputField
        label="Utbildning"
        placeholder="Utbildning"
        fullwidth
        type="text"
        value={education}
        onChange={changeEducation}
      />

      <Select
        inputFieldProps={{ fullwidth: true, placeholder: 'Utbildningsstatus', label: 'Utbildningsstatus' }}
        selectProps={{
          options: educationStatusOptions,
          isClearable: true,
          onChange: (x) => (x ? setEducationStatus((x as IEducationOptions).type) : setEducationStatus(undefined)),
        }}
      />
      <Datepicker
        label="Start"
        placeholderText="Start"
        fullwidth
        errorText={startDateError}
        selected={startDate}
        onChange={onStartDateChange}
      />
      <Datepicker
        label="Slut"
        placeholderText="Slut"
        fullwidth
        errorText={endDateError}
        selected={endDate}
        onChange={onEndDateChange}
      />
      <div className={classes.button}>
        <Button
          color="primary"
          startIcon={!initdata && <AddCircleRounded />}
          disabled={invalid}
          onClick={onSubmit}
          variant="outlined"
          className={initdata ? classes.editButton : classes.saveButton}
        >
          {initdata ? 'Spara redigering' : 'Lägg till utbildning'}
        </Button>
        {cancel && (
          <Button className={classes.cancelButton} variant="outlined" onClick={cancel}>
            Avbryt
          </Button>
        )}
      </div>
    </>
  );
};

interface IEducationProps {
  rows: IUserEducation[];
  setRows: (rows: IUserEducation[]) => void;
}

const CompanyUserEducation = (props: IEducationProps) => {
  const classes = useStyles();
  const [selectedRow, setSelectedRow] = useState<number>();

  const onAdd = (addRow: IUserEducation) => {
    let newRows = [...props.rows, addRow];
    props.setRows(newRows);
  };

  const onEdit = (row: IUserEducation, newRow: IUserEducation) => {
    let index = props.rows.indexOf(row);
    if (index === -1) return;
    let newRows = [...props.rows];
    newRows[index] = newRow;
    props.setRows(newRows);
    setSelectedRow(undefined);
  };

  const onDelete = (row: IUserEducation) => {
    let index = props.rows.indexOf(row);
    if (index === -1) return;
    let newRows = [...props.rows];
    newRows.splice(index, 1);
    props.setRows(newRows);
  };

  return (
    <Fieldset legend="Utbildningar">
      {props.rows.length > 0 && (
        <Grid item container component="ul" className={classes.list}>
          {props.rows.map((row, index) => {
            if (selectedRow !== undefined && selectedRow === index) {
              return (
                <EducationFormRow
                  key={'edit_' + (row.id === null ? 'new_' + row.education : row.id)}
                  initdata={row}
                  submit={(newData: IUserEducation) => onEdit(row, newData)}
                  cancel={() => setSelectedRow(undefined)}
                />
              );
            }
            return (
              <Grid
                component="li"
                alignItems="center"
                container
                item
                xs={12}
                justify="space-between"
                className={classes.listItem}
                key={row.id === null ? 'new_' + row.education : row.id}
              >
                <div className={classes.texts}>
                  <span className={classes.educationTitle}>{row.education}</span>
                  {row.educationStatus !== undefined ? (
                    <span>{educationOptions[Number(row.educationStatus)].name}</span>
                  ) : undefined}
                  <div className={classes.dates}>
                    {formatDate(row.startDate)} - {formatDate(row.endDate)}
                  </div>
                </div>
                <div className={classes.actions}>
                  <IconButton
                    size="small"
                    aria-label="Redigera utbildning"
                    className={classes.edit}
                    onClick={() => setSelectedRow(index)}
                  >
                    <EditIcon />
                  </IconButton>
                  {selectedRow === undefined && (
                    <IconButton
                      color="error"
                      size="small"
                      aria-label="Ta bort utbildning"
                      className={classes.remove}
                      onClick={() => onDelete(row)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
              </Grid>
            );
          })}
        </Grid>
      )}
      {selectedRow === undefined && <EducationFormRow submit={onAdd} />}
    </Fieldset>
  );
};

export default CompanyUserEducation;
