import React, { FC } from 'react';
import useStyles from './ActionRow.styles';

interface IActionRow {
  reverse?: boolean;
}

const ActionRow: FC<IActionRow> = (props) => {
  const classes = useStyles();
  return (
    <div className={`${classes.root} ${!props.reverse ? classes.regular : classes.reverse}`}>{props.children}</div>
  );
};

export default ActionRow;
