import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './InputLabel.styles';

interface IInputLabelProps {
  label?: string;
  required?: boolean;
  id?: string;
}

const InputLabel: React.FunctionComponent<IInputLabelProps> = (props) => {
  const classes = useStyles();

  return (
    <Typography component="label" variant="h4" className={`${classes.root}`} id={props.id}>
      {props.label && props.label}
      {props.required && '*'}
    </Typography>
  );
};

export default InputLabel;
