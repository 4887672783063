import React, { useContext } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { ContainerContext } from '../../Container/Container';
import InputLabel from '../InputLabel/InputLabel';
import useStyles from './ViewAddress.styles';
import { Grid, GridSize } from '@material-ui/core';

export interface IAddressProps {
  customLabel?: string;
  address?: string;
  postalCode?: string;
  city?: string;
  customWidth?: GridSize;
}

export const ViewAddress: React.FunctionComponent<IAddressProps> = (props) => {
  const classes = useStyles();
  const containerContext = useContext(ContainerContext);

  return (
    <Grid item sm={props.customWidth ?? 6} xs={props.customWidth ?? 12} container direction="column">
      {containerContext?.loading ? (
        <>
          <Skeleton variant="text" animation="wave" className={classes.skeletonLabel} />
          <Skeleton variant="rect" animation="wave" className={classes.skeletonInput} />
        </>
      ) : (
        <>
          <InputLabel label={props.customLabel ?? "Adress"} />
          {props.address && <span className={classes.text}>{props.address as string | number}</span>}
          {(props.postalCode || props.city) && (
            <>
              <label className={'sr-only'}>Postnummer och ort</label>
              <div>
                <span className={classes.text}>{props.postalCode && (props.postalCode as string | number)}</span>{' '}
                <span className={classes.text}>{props.city && (props.city as string | number)}</span>
              </div>
            </>
          )}
        </>
      )}
    </Grid>
  );
};
