import React from 'react';
import { IconButtonProps } from '@material-ui/core/IconButton/IconButton';
import { CircularProgress, PropTypes, IconButton as MuiIconButton } from '@material-ui/core';
import useStyles from './IconButton.styles';

interface IButtonProps extends Omit<IconButtonProps, 'color'> {
  loading?: boolean;
  color?: PropTypes.Color | 'error';
}

const IconButton = (props: IButtonProps) => {
  const { loading, children, disabled, color, ...rest } = props;
  const classes = useStyles();
  const isError = color === 'error';

  return (
    <MuiIconButton
      color={isError ? undefined : (color as PropTypes.Color)}
      className={`${isError ? classes.errorColor : ''}`}
      {...rest}
      disabled={loading || disabled}
    >
      {loading ? (
        <CircularProgress aria-label="Laddar" disableShrink className={classes.loading} size="1rem" />
      ) : (
        children
      )}
    </MuiIconButton>
  );
};

export default IconButton;
