import { useApiPost } from '../../../../Core/Api/Api';
import { SaleStatus } from '../../Errands/Components/Sale.api';

export function useGetDashboard() {
  const { isLoading, response, post } = useApiPost<SalesDashboardDto, ISalesDashboardQuery>(
    `/api/statistics/dashboard`,
  );

  return {
    isLoading,
    dashboard: response,
    getDashboard: post,
  };
}

export function useGetSaleStats() {
  const { isLoading, response, post } = useApiPost<ISaleStatsDto, ISaleStatsQuery>(`/api/statistics/sales`);

  return {
    isLoading,
    saleStats: response,
    getSaleStats: post,
  };
}

export function useGetCompanyUserMonthlyStats(userId: string | undefined) {
  const { isLoading, response, post } = useApiPost<ICompanyUserMonthlyStatsDto, IGetCompanyUserMonthlyStatsQuery>(
    `/api/statistics/monthly/${userId}`,
  );

  return {
    isLoading,
    userMonthlyStats: response,
    getCompanyUserMonthlyStats: post,
  };
}

export interface IGetCompanyUserMonthlyStatsQuery {
  date: Date;
  companyUserId: number;
}

export interface ISaleStatsQuery {
  date: Date;
}

export interface ISalesDashboardQuery {
  date: string;
}

export interface SalesDashboardDto {
  orders: ICompanyUserSalesDto[];
  saleTotal: ISaleTotal;
  saleTotalCurrentYear: ISaleTotal;

  offers: ICompanyUserOfferDto[];
  offerTotal: IOfferTotalDto;
  offerTotalCurrentYear: IOfferTotalDto;

}

export interface ICompanyUserSalesDto {
  companyUserId: number;
  name: string;
  amount: number;
  invoiceAmount: number;
  orderCount: number;
  agreementCount: number;
  agreementAmount: number;
  orderIds: number[];
}

export interface ICompanyUserOfferDto {
  companyUserId: number;
  name: string;
  offerAmount: number;
  offerCount: number;
  orderCount: number;
  offerAgreementCount: number;
  offerAgreementSum: number;
  offerIds: number[];
}

export interface ISaleTotal {
  amount: number;
  invoiceAmount: number;
  orderCount: number;
  agreementCount: number;
  agreementAmount: number;
}

export interface IOfferTotalDto {
  offerAmount: number;
  offerCount: number;
  orderCount: number;  
  offerAgreementCount: number;
  offerAgreementSum: number;
}

export interface ISaleStatsDto {
  companyUserSales: ICompanyUserSaleStatsDto[];
  companyUserOffers: ICompanyUserOfferStatsDto[];
}
export interface ICompanyUserSaleStatsDto {
  companyUserId: number;
  companyUser: string;
  total: number;
  systemTotal: number;
  agreementTotal: number;
  newCustomers: number;
  agreementCount: number;
  orderCount: number;
}

export interface ICompanyUserOfferStatsDto {
  companyUserId: number;
  companyUserName: string;
  total: number;
  count: number;
}

export interface ICompanyUserMonthlyStatsDto {
  orderTotal: number;
  orderCount: number;
  workedHours: number;
  offerTotal: number;
  offerCount: number;
  vacationHours: number;
  vacationDays: number;
  sales: ISaleDto[];
  holidays: IHolidayDto[];
}

export interface ISaleDto {
  saleId: number;
  saleIdentifier: string;
  customerId: number;
  customerName: string;
  facilityName: string;
  amount: number;
  status: SaleStatus;
  created: Date;
}

export interface IHolidayDto {
  id: number;
  name: string;
  date: Date;
}
