import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  loading: {
    height: '1rem',
    width: '1rem',
    padding: '0.25rem 0',
  },
  errorColor: {
    color: theme.palette.error.main,
  },
}));

export default useStyles;
