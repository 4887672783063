import React, { useEffect, useState } from 'react';
import { IDocumentFormProps } from '../../IDocumentFormProps';
import { useUploadDocument } from '../../Documents.api';
import UploadInput, { IUploadFile } from '../../../../../../../../../Shared/InputFields/UploadInput/UploadInput';
import { useHistory } from 'react-router-dom';

const UploadDocumentForm = (props: IDocumentFormProps) => {
  const { customerId, facilityId, saveCallback, onLoadingChange, validateFormState } = props;
  const history = useHistory();
  const { isLoading, uploadDocument } = useUploadDocument(customerId);
  const [document, setDocument] = useState<IUploadFile>();

  useEffect(() => {
    onLoadingChange(isLoading);
  }, [isLoading, onLoadingChange]);

  useEffect(() => {
    validateFormState && validateFormState(!!document);
  }, [document, validateFormState]);

  useEffect(() => {
    async function save() {
      if (!document) return;
      await uploadDocument({
        facilityId: facilityId ? parseInt(facilityId) : undefined,
        document: document,
      });      
      history.goBack();
    }
    saveCallback(save);
  }, [history, customerId, facilityId, document, uploadDocument, saveCallback]);

  return (
    <UploadInput
      label="Ladda upp er fil"
      value={document?.name}
      type={'pdf'}
      disabled={isLoading}
      canEditFileName
      onFileChange={setDocument}
    />
  );
};

export default UploadDocumentForm;
