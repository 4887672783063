import { makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { useGetTodaysNameday } from '../../../../AuthApp/Modules/Home/Home.api';
import { formatDateWithDayName } from '../../../Formatting/formatDate';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textTransform: 'capitalize',
    padding: '0.5rem',
    fontSize: '1.125rem',
    lineHeight: 0,
    [theme.breakpoints.down('xs')]: {
      color: theme.palette.common.white,
    },
  },
}));

interface INameday {
  align?: 'left' | 'right';
}

export const Nameday = (props: INameday) => {
  const { nameday, isLoading } = useGetTodaysNameday();
  const nameDayDate = formatDateWithDayName(new Date());
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography align={props.align}>{nameDayDate}</Typography>
      <Typography align={props.align} style={{ opacity: isLoading ? 0 : 1 }}>
        {nameday ? nameday : 'Ingen har namnsdag i dag'}
      </Typography>
    </div>
  );
};
