import { useApiPost, useApiPut, useApiDelete, useApiGet } from '../../../Core/Api/Api';
import IPaginatedRequest from '../../../Shared/Api/request/IPaginatedRequest';
import IPaginatedResponse from '../../../Shared/Api/response/IPaginatedResponse';
import ValidationErrorResultType from '../../../Shared/Api/response/ValidationErrorResultType';
import { ICreateResult } from '../../../Shared/Api/response/ICreateResult';

export enum TodoRole {
  Assignor,
  Assignee,
}

export interface ITodoListItem {
  id: number;
  created: Date;
  createdByCompanyUserId: number;
  description: string;
  deadline: Date;
  assignor: string;
  assignee: string;
  canBeArchived: boolean;
  archiveDate?: Date;
  answer?: string;
  requiredAnswer?: boolean;
  sendDate?: Date;
  answerDate?: Date;
  role: TodoRole;
}

export interface IGetTodosRequest extends IPaginatedRequest {
  inArchive: boolean;
  getWithinWeek?: boolean;
}

export function useGetTodos() {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<ITodoListItem>, IGetTodosRequest>(
    '/api/todos/list',
  );

  return {
    isLoading,
    todos: response,
    getTodos: post,
  };
}

interface ITodo {
  id: number;
  created: Date;
  createdByCompanyUserId: number;
  createdByCompanyUserFullname: string;
  assignedPersonCompanyUserId: number | null;
  description: string;
  answer: string;
  deadline: Date;
  requiredAnswer: boolean;
  sendDate?: Date;
  answerDate?: Date;
  notificationDateTime: Date | null;
}
export function useGetTodo(id: string) {
  const { isLoading, response, get } = useApiGet<ITodo>('/api/todos/' + id, false);

  return {
    isLoading,
    todo: response,
    getTodo: get,
  };
}

export interface IUpsertTodoCommand {
  description: string;
  deadline: Date;
  assignedPersonCompanyUserId: number | null;
  requiredAnswer: boolean;
  notificationDateTime: Date | null;
}

export interface IUpsertTodoValidationErrors extends ValidationErrorResultType<IUpsertTodoCommand> {}

export function useUpsertTodo() {
  const { isLoading: isCreateLoading, post } = useApiPost<
    ICreateResult<IUpsertTodoValidationErrors>,
    IUpsertTodoCommand
  >(`/api/todos/`);

  const { isLoading: isUpdateLoading, put } = useApiPut<IUpsertTodoValidationErrors, IUpsertTodoCommand>(`/api/todos/`);

  async function upsert(command: IUpsertTodoCommand, id?: string) {
    if (id) {
      const result = await put(command, id);

      return result ? result : id;
    }

    return post(command);
  }

  return {
    isLoading: isCreateLoading || isUpdateLoading,
    upsert: upsert,
  };
}

export function useDeleteTodo() {
  const { isLoading, delete: deleteRequest } = useApiDelete('/api/todos/');

  async function deleteTodo(id: string) {
    return await deleteRequest(undefined, id);
  }

  return {
    isLoading,
    deleteTodo,
  };
}

export function useArchiveTodo() {
  const { isLoading, put: archiveRequest } = useApiPut('/api/todos/');

  async function archiveTodo(id: string) {
    return await archiveRequest(undefined, `${id}/archive`);
  }

  return {
    isLoading,
    archiveTodo,
  };
}

export interface IAnswerTodoValidationErrors extends ValidationErrorResultType<IAnswerTodoCommand> {}

interface IAnswerTodoCommand {
  answer: string;
}
export function useAnswerTodo() {
  const { isLoading, post } = useApiPost<ICreateResult<IAnswerTodoValidationErrors>, IAnswerTodoCommand>(
    '/api/todos/',
    undefined,
  );

  const answerTodo = async (id: string, data: IAnswerTodoCommand) => {
    return await post(data, `${id}/answer`);
  };

  return {
    isLoading,
    answerTodo,
  };
}
