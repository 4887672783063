import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './MobileTableRow.styles';
import { Link } from 'react-router-dom';

interface IMobileTableProps {
  label?: string | number | React.ReactNode;
  value?: string | number | React.ReactNode;
  column?: boolean;
  link?: string;
  onClick?: () => void;
}

export const MobileTableRow: React.FC<IMobileTableProps> = (props) => {
  const classes = useStyles();

  const GetContent = (content: string | number | React.ReactNode) => {
    if (typeof content === 'string' || typeof content === 'number') {
      return (
        <Typography component={content === props.value && props.link ? Link : 'span'} to={props.link}>
          {content}
        </Typography>
      );
    } else {
      return <div className={classes.content}>{content}</div>;
    }
  };

  return (
    <li className={`${classes.root} ${props.column ? classes.column : ''}`} onClick={props.onClick}>
      {props.label && GetContent(props.label)}
      {props.value && GetContent(props.value)}
      {props.children}
    </li>
  );
};
