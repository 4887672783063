import React, { forwardRef } from 'react';
import { Grid, makeStyles } from '@material-ui/core';

interface IDialogFormProps {
  actions: React.ReactNode;
  children: React.ReactNode;
  CustomWidth?: number;
}

const useStyles = (CustomWidth?: number) =>
  makeStyles(() => ({
    root: {
      maxWidth: CustomWidth ? `${CustomWidth}rem` : '30rem',
    },
    buttons: {
      '& > *': {
        marginLeft: '1rem',
      },
    },
  }));

const DialogForm: React.RefForwardingComponent<HTMLFormElement, IDialogFormProps> = (props, ref) => {
  const classes = useStyles(props.CustomWidth)();
  return (
    <Grid container component="form" ref={ref} xs={12} spacing={2} className={classes.root}>
      {props.children}
      <Grid container item xs={12} justify="flex-end" className={classes.buttons}>
        {props.actions}
      </Grid>
    </Grid>
  );
};

export default forwardRef(DialogForm);
