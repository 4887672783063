import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Container from '../../../../../../../Shared/Container/Container';
import Button from '../../../../../../../Shared/Button/Button';
import { Fieldset } from '../../../../../../../Shared/Form/Fieldset/Fieldset';
import { InputField } from '../../../../../../../Shared/InputFields/InputField/InputField';
import WizardFormActions from '../../../../../../../Shared/Form/WizardFormActions/WizardFormActions';
import useFormValidation from '../../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import { isResultError } from '../../../../../../../Shared/Api/response/ICreateResult';
import OfferFormContext from '../OfferFormContext';
import PriceChanges from './PriceChanges';
import {
  getNextPage,
  getPreviousPage,
  IOfferPriceProduct,
  IPriceDisplaySettings,
  IPriceItem,
  IPricesDto,
  IUpdateOfferPricesCommand,
  PriceLabels,
  useGetOfferPrices,
  useUpdateOfferPrices,
} from '../../Offer.api';
import {
  calculateInstallationCost,
  calculateInstallationTime,
  calculateMaterialCost,
  calculateRent,
  calculateTotalPrice,
} from './priceCalculations';
import { Checkbox, Grid } from '@material-ui/core';
import { SaleType } from '../../../../../../Shared/SaleType';

function transformNumericInputValue(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
  if (e.target.value === '') {
    return 0;
  }

  var value = Number(e.target.value);
  if (isNaN(value)) return 0;
  else return value;
}

const defaultDisplaySettings: IPriceDisplaySettings = {
  installationTime: false,
  installationRate: false,
  installationCost: false,
  materialCost: false,
  otherCost: false,
  totalPrice: true,
  firstInstallationCost: false,
  rentPrice: false,
  material: true,
  firstPage: true,
  agreement: true,
  deliveryAndWarrantyConditions: true,
};

export type IOfferShowExtra = 'firstPage' | 'material' | 'agreement' | 'deliveryAndWarrantyConditions';

export const OfferShowLabels: { [index in IOfferShowExtra]: string } = {
  firstPage: 'Förstasida',
  material: 'Material',
  agreement: 'Avtal & avtalsvillkor',
  deliveryAndWarrantyConditions: 'Leverans- & garantivillkor',
};

interface IPriceDisplaySettingsProps {
  prices: IPricesDto;
  displaySettings: IPriceDisplaySettings;
  setDisplaySettings: React.Dispatch<React.SetStateAction<IPriceDisplaySettings>>;
}

const SetPrices: React.FunctionComponent = () => {
  const { id: offerId } = useParams<{ id: string }>();
  const { offerStep, setOfferStep, offerType } = useContext(OfferFormContext);
  const { isFormValid, formRef } = useFormValidation();

  const { get: getOfferPrices, isLoading: isGetOfferPricesLoading } = useGetOfferPrices(offerId);
  const { put: updateOfferPrices, isLoading: isUpdateOfferPricesLoading } = useUpdateOfferPrices(offerId);

  const [otherCost, setOtherCost] = useState<number>(0);
  const [firstInstallationCost, setFirstInstallationCost] = useState<number>(0);

  const [customInstallationRate, setCustomInstallationRate] = useState<number>();
  const [customInstallationTime, setCustomInstallationTime] = useState<number>();
  const [customInstallationCost, setCustomInstallationCost] = useState<number>();
  const [customMaterialCost, setCustomMaterialCost] = useState<number>();
  const [customTotalCost, setCustomTotalCost] = useState<number>();
  const [customRent, setCustomRent] = useState<number>();

  const [defaultInstallationPrice, setDefaultInstallationPrice] = useState<number>(0);
  const [products, setProducts] = useState<IOfferPriceProduct[]>([]);
  const [useCustomPrices, setUseCustomPrices] = useState<boolean>(offerType === SaleType.custom ? true : false);
  const [confirmChanges, setConfirmChanges] = useState(false);
  const [priceDisplaySettings, setPriceDisplaySettings] = useState<IPriceDisplaySettings>(defaultDisplaySettings);

  const defaultInstallationTime = useMemo(() => calculateInstallationTime(products), [products]);
  const defaultMaterialCost = useMemo(() => calculateMaterialCost(products), [products]);

  const installationRate = customInstallationRate !== undefined ? customInstallationRate : defaultInstallationPrice;
  const installationTime =
    customInstallationTime !== undefined ? Math.round(customInstallationTime) : Math.round(defaultInstallationTime);
  const materialCost = customMaterialCost !== undefined ? customMaterialCost : defaultMaterialCost;

  const installationCost =
    customInstallationCost !== undefined
      ? customInstallationCost
      : calculateInstallationCost(installationTime!, installationRate!);

  const totalCost =
    customTotalCost !== undefined ? customTotalCost : calculateTotalPrice(materialCost, installationCost, otherCost);

  const totalRent = customRent !== undefined ? customRent : calculateRent(totalCost, firstInstallationCost);

  /* Set up offer prices data */
  useEffect(() => {
    async function loadOfferPrices() {
      const response = await getOfferPrices();

      if (response) {
        setOtherCost(response.otherCost);
        setFirstInstallationCost(response.firstInstallationCost);

        if (response.setPricesManually) {
          setCustomInstallationRate(response.installationRate);
          setCustomInstallationTime(response.installationTime);
          setCustomInstallationCost(response.installationCost);
          setCustomMaterialCost(response.materialCost);
          setCustomTotalCost(response.totalPrice);
          setCustomRent(response.rentPrice);
        }

        setDefaultInstallationPrice(response.defaultInstallationPrice);
        setProducts(response.products);
        setUseCustomPrices(offerType === SaleType.custom ? true : response.setPricesManually);
        if (offerType === SaleType.custom) setConfirmChanges(true);
        setPriceDisplaySettings(response.displaySettings);
      }
    }
    if (!!offerId) loadOfferPrices();
  }, [offerId, getOfferPrices, offerType]);

  const toggleUseCustomPrices = () => {
    if (useCustomPrices) {
      setCustomInstallationRate(undefined);
      setCustomInstallationTime(undefined);
      setCustomInstallationCost(undefined);
      setCustomMaterialCost(undefined);
      setCustomTotalCost(undefined);
      setCustomRent(undefined);
    }
    setUseCustomPrices((x) => !x);
  };

  const toggleSettings = (settingIdentifier: IPriceItem | IOfferShowExtra) => {
    setPriceDisplaySettings((x) => {
      let value = x[settingIdentifier];
      if (value === undefined) return x;
      return { ...x, [settingIdentifier]: !value };
    });
  };

  /* Insert/update offer prices */
  const upsertOfferPrices = async () => {
    if (offerType === undefined) {
      return;
    }

    const updateOfferPricesCommand: IUpdateOfferPricesCommand = {
      installationPrice: installationRate ?? 0,
      totalInstallationTime: installationTime ?? 0,
      totalInstallationCost: installationCost ?? 0,
      materialCost: materialCost ?? 0,
      otherCost: otherCost ?? 0,
      totalPrice: totalCost ?? 0,
      firstInstallationCost: firstInstallationCost ?? 0,
      rentPrice: totalRent ?? 0,
      displaySettings: priceDisplaySettings,
      setManually: useCustomPrices,
    };

    const result = await updateOfferPrices(updateOfferPricesCommand);

    if (!isResultError(result)) {
      setOfferStep(getNextPage(offerType, offerStep));
    } else {
      //?? TODO: How to handle result error?
    }
  };

  return (
    <>
      <Container
        form
        ref={formRef}
        label="Priser"
        loading={isGetOfferPricesLoading}
        actions={
          <>
            <Button color="default" variant="outlined" onClick={toggleUseCustomPrices}>
              {useCustomPrices ? 'Återställ priser' : 'Sätt egna priser'}
            </Button>
            <WizardFormActions
              goBack={() => setOfferStep(getPreviousPage(offerType!, offerStep))}
              goNext={upsertOfferPrices}
              isFormValid={isFormValid && (!useCustomPrices || confirmChanges) && offerType !== undefined}
              isProcessing={isUpdateOfferPricesLoading}
            />
          </>
        }
        customSize={{ md: 12, lg: 6 }}
      >
        <Fieldset customSize={{ md: 12 }}>
          <Grid container>
            <Fieldset>
              <Grid item sm={6} xs={12} container>
                <Grid item xs={1}>
                  <Checkbox
                    key={'installationRate'}
                    color="primary"
                    edge="start"
                    checked={priceDisplaySettings.installationRate}
                    onChange={() => toggleSettings('installationRate')}
                  />
                </Grid>
                <InputField
                  label={PriceLabels['installationRate']}
                  type="number"
                  inputProps={{ min: '0' }}
                  placeholder={installationRate.toString()}
                  value={useCustomPrices && !confirmChanges && installationRate === 0 ? '' : installationRate}
                  onChange={(e) => setCustomInstallationRate(transformNumericInputValue(e))}
                  viewOnly={!useCustomPrices}
                  endAdornment={'SEK'}
                  required
                />
              </Grid>
              <Grid item sm={6} xs={12} container>
                <Grid item xs={1}>
                  <Checkbox
                    key={'installationTime'}
                    color="primary"
                    edge="start"
                    checked={priceDisplaySettings.installationTime}
                    onChange={() => toggleSettings('installationTime')}
                  />
                </Grid>
                <InputField
                  label={`${PriceLabels['installationTime']} (Ber 2)`}
                  type="number"
                  inputProps={{ min: '0' }}
                  placeholder={installationTime.toString()}
                  value={useCustomPrices && !confirmChanges && installationTime === 0 ? '' : installationTime}
                  onChange={(e) => setCustomInstallationTime(transformNumericInputValue(e))}
                  viewOnly={!useCustomPrices}
                  endAdornment="h"
                  required
                />
              </Grid>
              <Grid item sm={6} xs={12} container>
                <Grid item xs={1}>
                  <Checkbox
                    key={'materialCost'}
                    color="primary"
                    edge="start"
                    checked={priceDisplaySettings.materialCost}
                    onChange={() => toggleSettings('materialCost')}
                  />
                </Grid>
                <InputField
                  label={PriceLabels['materialCost']}
                  type="number"
                  inputProps={{ min: '0' }}
                  placeholder={materialCost.toString()}
                  value={useCustomPrices && !confirmChanges && materialCost === 0 ? '' : materialCost}
                  onChange={(e) => setCustomMaterialCost(transformNumericInputValue(e))}
                  viewOnly={!useCustomPrices}
                  endAdornment={'SEK'}
                  required
                />
              </Grid>
              <Grid item sm={6} xs={12} container>
                <Grid item xs={1}>
                  <Checkbox
                    key={'otherCost'}
                    color="primary"
                    edge="start"
                    checked={priceDisplaySettings.otherCost}
                    onChange={() => toggleSettings('otherCost')}
                  />
                </Grid>
                <InputField
                  label={PriceLabels['otherCost']}
                  type="number"
                  inputProps={{ min: '0' }}
                  placeholder={otherCost.toString()}
                  value={!confirmChanges && otherCost === 0 ? '' : otherCost}
                  onChange={(e) => setOtherCost(transformNumericInputValue(e))}
                  endAdornment={'SEK'}
                />
              </Grid>
            </Fieldset>
            <Fieldset legend="Totalt">
              <Grid item sm={6} xs={12} container>
                <Grid item xs={1}>
                  <Checkbox
                    key={'installationCost'}
                    color="primary"
                    edge="start"
                    checked={priceDisplaySettings.installationCost}
                    onChange={() => toggleSettings('installationCost')}
                  />
                </Grid>
                <InputField
                  label={PriceLabels['installationCost']}
                  type="number"
                  inputProps={{ min: '0' }}
                  placeholder={installationCost.toString()}
                  value={useCustomPrices && !confirmChanges && installationCost === 0 ? '' : installationCost}
                  onChange={(e) => setCustomInstallationCost(transformNumericInputValue(e))}
                  viewOnly={!useCustomPrices}
                  endAdornment={'SEK'}
                  required
                />
              </Grid>
              <Grid item sm={6} xs={12} container>
                <Grid item xs={1}>
                  <Checkbox
                    key={'totalPrice'}
                    color="primary"
                    edge="start"
                    checked={priceDisplaySettings.totalPrice}
                    onChange={() => toggleSettings('totalPrice')}
                  />
                </Grid>
                <InputField
                  label={PriceLabels['totalPrice']}
                  type="number"
                  inputProps={{ min: '0' }}
                  placeholder={totalCost.toString()}
                  value={useCustomPrices && !confirmChanges && totalCost === 0 ? '' : totalCost}
                  onChange={(e) => setCustomTotalCost(transformNumericInputValue(e))}
                  viewOnly={!useCustomPrices}
                  endAdornment={'SEK'}
                  required
                />
              </Grid>
            </Fieldset>

            <Fieldset legend="Hyra">
              <Grid item sm={6} xs={12} container>
                <Grid item xs={1}>
                  <Checkbox
                    key={'firstInstallationCost'}
                    color="primary"
                    edge="start"
                    checked={priceDisplaySettings.firstInstallationCost}
                    onChange={() => toggleSettings('firstInstallationCost')}
                  />
                </Grid>
                <InputField
                  label={PriceLabels['firstInstallationCost']}
                  type="number"
                  inputProps={{ min: '0' }}
                  placeholder={firstInstallationCost.toString()}
                  value={!confirmChanges && firstInstallationCost === 0 ? '' : firstInstallationCost}
                  onChange={(e) => setFirstInstallationCost(transformNumericInputValue(e))}
                  endAdornment={'SEK'}
                />
              </Grid>
              <Grid item sm={6} xs={12} container>
                <Grid item xs={1}>
                  <Checkbox
                    key={'rentPrice'}
                    color="primary"
                    edge="start"
                    checked={priceDisplaySettings.rentPrice}
                    onChange={() => toggleSettings('rentPrice')}
                  />
                </Grid>
                <InputField
                  label={PriceLabels['rentPrice']}
                  type="number"
                  inputProps={{ min: '0' }}
                  placeholder={totalRent.toString()}
                  value={useCustomPrices && !confirmChanges && totalRent === 0 ? '' : totalRent}
                  onChange={(e) => setCustomRent(transformNumericInputValue(e))}
                  viewOnly={!useCustomPrices}
                  endAdornment={'SEK'}
                  required
                />
              </Grid>
            </Fieldset>
            <Fieldset legend="Visa/dölj avsnitt">
              <Grid item xs={12} container>
                <Grid item xs={3}>
                  Startsida{' '}
                  <Checkbox
                    key={'firstPage'}
                    color="primary"
                    edge="start"
                    checked={priceDisplaySettings.firstPage}
                    onChange={() => toggleSettings('firstPage')}
                  />
                </Grid>
                <Grid item xs={3}>
                  Material{' '}
                  <Checkbox
                    key={'material'}
                    color="primary"
                    edge="start"
                    checked={priceDisplaySettings.material}
                    onChange={() => toggleSettings('material')}
                  />
                </Grid>
                <Grid item xs={3}>
                  Avtal och avtalsvillkor{' '}
                  <Checkbox
                    key={'agreement'}
                    color="primary"
                    edge="start"
                    checked={priceDisplaySettings.agreement}
                    onChange={() => toggleSettings('agreement')}
                  />
                </Grid>
                <Grid item xs={3}>
                  Leverans- & garantivillkor{' '}
                  <Checkbox
                    key={'deliveryAndWarrantyConditions'}
                    color="primary"
                    edge="start"
                    checked={priceDisplaySettings.deliveryAndWarrantyConditions}
                    onChange={() => toggleSettings('deliveryAndWarrantyConditions')}
                  />
                </Grid>
              </Grid>
            </Fieldset>
          </Grid>
        </Fieldset>
        {useCustomPrices && (
          <PriceChanges
            originaInstallationRate={defaultInstallationPrice}
            originalInstallationTime={defaultInstallationTime}
            originalMaterialCost={defaultMaterialCost}
            prices={{
              installationRate: installationRate,
              installationTime: installationTime,
              installationCost: installationCost,
              materialCost: materialCost,
              otherCost: otherCost,
              totalPrice: totalCost,
              firstInstallationCost: firstInstallationCost,
              rentPrice: totalRent,
            }}
            confirmChanges={confirmChanges}
            setConfirmChanges={setConfirmChanges}
          />
        )}
      </Container>
    </>
  );
};

export default SetPrices;
