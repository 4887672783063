import React, { useState } from 'react';
import { MenuItem } from '@material-ui/core';
import PopperMenu from '../../../../../Shared/Popper/Popper';
import MoreVert from '@material-ui/icons/MoreVertRounded';
import { IGetCompanyUserMessagesResult } from '../../Forum.api';
import { useHistory } from 'react-router';
import { useStyles } from './MessageMenu.styles';
import AccessGuard from '../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { isResultError } from '../../../../../Shared/Api/response/ICreateResult';
import { AccessRights, ModuleIdentifiers } from '../../../../../Core/Authentication/ModuleAccess';
import { IUpsertMeetingNotesCommand, useUpsertMeetingNotes } from '../../../Todos/Components/MeetingNotes/MeetingNotes.api';

type IMessageMenuProps = {
  userMessage: IGetCompanyUserMessagesResult;
  handleEditCompanyUserMessage: () => void;
  handleCreateTodo: () => Promise<void>;
  onDelete: () => Promise<void>;
  isPostedByCurrentUser: boolean;
};

export default function MessageMenu(props: IMessageMenuProps) {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const { upsert } = useUpsertMeetingNotes();

  async function handleUpsert() {
    
    const upsertCommand: IUpsertMeetingNotesCommand = {
      responsiblePersonId: props.userMessage.senderCompanyUserId,
      date: new Date(),
      title: props.userMessage.description,
      description: props.userMessage.description,
    };

    const result = await upsert(upsertCommand);
    if (!isResultError(result)) {
      history.push({ pathname: `/todos/meetingnotes/${result}`});
    }
  }

  const handleDelete = async () => {
    await props.onDelete();
  };

  return (
    <PopperMenu
      color="default"
      variant="outlined"
      aria-label="Menyalernativ för meddelandet"
      buttonType="icon"
      setOpen={setOpen}
      open={open}
      content={
        <>
          {props.isPostedByCurrentUser &&
            <AccessGuard module={ModuleIdentifiers.Forum} accessRights={AccessRights.Write}>
              <MenuItem onClick={props.handleEditCompanyUserMessage}>Redigera</MenuItem>
            </AccessGuard>
          }
          <MenuItem autoFocus onClick={props.handleCreateTodo}>
            Till uppgifter
          </MenuItem>
          <MenuItem onClick={handleUpsert}>Skapa mötesfråga</MenuItem>
          {props.isPostedByCurrentUser && 
            <AccessGuard module={ModuleIdentifiers.Forum} accessRights={AccessRights.Full}>
              <MenuItem className={classes.remove} onClick={handleDelete}>
                Ta bort
              </MenuItem>
            </AccessGuard>
          } 
        </>
      }
    >
      <MoreVert />
    </PopperMenu>
  );
}
