import theme from '../../Styles/Theme';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  table: {
    border: `1px solid ${theme.palette.grey[300]}`,
    boxSizing: 'border-box',
    minWidth: '100%',
  },
  noBorderOnLastTr: {
    '& tbody > tr:last-child > td': {
      borderBottom: 0,
    },
  },
  noBorders: {
    border: 0,
  },
  roundCorners: {
    borderRadius: theme.shape.borderRadius,
  },
  roundBottomCorners: {
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    padding: '1rem 1rem ',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    height: '1.5rem',
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.contrastText,
      cursor: 'pointer',
    }
  },
  label: {
    color: theme.palette.primary.contrastText,
  },
  actionRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap-reverse',
  },
  filters: {
    flexGrow: 1,
    flexWrap: 'wrap',
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 1rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0.25rem 0.5rem',
    },
    '& > div': {
      marginRight: '1rem',
      minWidth: '13rem',
      marginTop: '0.25rem',
      marginBottom: '0.25rem',
      flexBasis: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        minWidth: 0,
        marginRight: 0,
        marginBottom: '0.5rem',
      },
    },
  },

  statisticFilters: {
    flexGrow: 1,
    flexWrap: 'wrap',
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 1rem',
    '& > div': {
      marginRight: '1rem',
      minWidth: '13rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      flexBasis: 'auto',
      [theme.breakpoints.down('xs')]: {
        minWidth: '94%',
      },
    },
  },

  filterInDesktop: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  filterAccordion: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: 'none',
    width: '100%',
    '&:last-child': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  filterTitle: {
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: '0.5rem',
    },
  },
  actions: {
    flexGrow: 1,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 1rem',
    '& > a': {
      marginLeft: '1rem',
    },
    '& > button': {
      marginLeft: '1rem',
    },
  },
  iconButtons: {
    '&:not(:empty)': {
      display: 'flex',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
      padding: '0.25rem 0 0.25rem 0.5rem',
    },
  },
  empty: {
    minHeight: '15rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableMessage: {
    marginBottom: '1rem',
    marginLeft: '1.25rem',
  },
});
