import React from 'react';
import Button from '../Button/Button';
import { useStyles } from './Stepper.styles';

interface IStepperProps {
  active: number;
  steps: IStep[];
}

interface IStep {
  id: number;
  label: string;
  disabled?: boolean;
  onClick?: () => void;
}

const Stepper = (props: IStepperProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.steps.map((step: IStep) => (
        <Button
          variant="outlined"
          className={`${classes.button} ${props.active === step.id ? classes.active : ''}`}
          disabled={step.disabled}
          aria-pressed={props.active === step.id}
          onClick={() => {
            step.onClick && step.onClick();
          }}
          key={step.id}
        >
          {step.label}
        </Button>
      ))}
    </div>
  );
};

export default Stepper;
