import { makeStyles, Theme } from '@material-ui/core/styles';
import { InputFieldBaseStyles } from '../../../../../../../../../Shared/InputFields/InputFields-styles';

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    paddingLeft: '1.25rem',
    paddingRight: '7rem',
  },
  inputContainer: {
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    right: 0,
  },
  input: {
    ...InputFieldBaseStyles,
    width: '3.5rem',
    margin: '0 1rem',
    fontSize: '1.1rem',
    padding: '0 0.5rem',
    '& > input': {
      textAlign: 'center',
    },
    '& input[type=number]': {
        '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        display: 'none'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        display: 'none'
    },
  },
  disabled: {
    backgroundColor: theme.palette.grey[100],
  },
  hidden: {
    opacity: 0,
  },
  productInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  // id: {
  //   fontWeight: 700,
  //   color: theme.palette.grey[600],
  // },
  skeleton: {
    height: 'calc(3.75rem - 1px)',
  },
  skeletonCheckbox: {
    height: '2rem',
    width: '2rem',
    marginRight: '1rem',
  },
  skeletonText: {
    flexGrow: 1,
    height: '1.5rem',
  },
  notInRegulation: {
    backgroundColor: "#fffbe8"
  },
}));

export default useStyles;
