export enum SaleType {
  system,
  deal,
  service,
  custom
}

export const SaleTypeLabel = {
  [SaleType.system]: 'System',
  [SaleType.deal]: 'Avtal',
  [SaleType.service]: 'Service',
  [SaleType.custom]: 'Egen',
};

export const SaleTypes = [
  { type: SaleType.system, name: SaleTypeLabel[SaleType.system] },
  { type: SaleType.deal, name: SaleTypeLabel[SaleType.deal] },
  { type: SaleType.service, name: SaleTypeLabel[SaleType.service] },
  { type: SaleType.custom, name: SaleTypeLabel[SaleType.custom] },
];
