import React, { useEffect } from 'react';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import AuthRoute from '../../../Core/Authentication/AuthRoute/AuthRoute';
import { ModuleIdentifiers, AccessRights } from '../../../Core/Authentication/ModuleAccess';
import { INavigationItem } from '../../../Shared/Navigation/Header/Header';
import useHeaderNavigationContext from '../../../Shared/Navigation/Header/HeaderNavigationContext/HeaderNavigationContext';
import FileSystem from './Components/FileSystem';

function FileSystemRoute() {
  const { path } = useRouteMatch();
  const { setHeaderNavigationItems, setHeaderNavigationText } = useHeaderNavigationContext();

  useEffect(() => {
    const navigationItems: INavigationItem[] = [
      {
        title: 'Filer',
        to: `${path}`,
        exact: false,
      },
    ];

    setHeaderNavigationText(navigationItems);
    setHeaderNavigationItems(navigationItems);

    return () => {
      setHeaderNavigationItems([]);
    };
  }, [path, setHeaderNavigationItems, setHeaderNavigationText]);

  return (
    <Switch>
      <AuthRoute exact path={path} module={ModuleIdentifiers.FileSystem} accessRights={AccessRights.Read}>
        <h1 className={'sr-only'}>Lista över filer</h1>
        <FileSystem />
      </AuthRoute>
      <Route path={`${path}/*`}>
        <Redirect to={`${path}`} />
      </Route>
    </Switch>
  );
}

export default FileSystemRoute;
