import { useRef, useState, useCallback, useEffect, useContext } from 'react';
import ConfigurationContext from '../Configurations/ConfigurationContext';
import useUserContext, { IUserContext } from '../Authentication/UserContext';
import useErrorMessage from '../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import useAuthContext from '../Authentication/AuthContextProvider';
import { IdTokenResponse, MsalAuthProvider } from 'react-aad-msal';
import { isErrorResponse } from '../../Shared/Api/response/IErrorRespose';
import { IFileResult } from '../../Shared/Api/response/IFileResult';
import { UserType } from '../../AuthApp/Modules/Admin/Components/Users/Users.api';

const noGlobalLoadingUrls: RegExp[] = [/\/notifyCustomer$/, /\d+\/sendtocustomer$/, /\d+\/sendtorecipients$/];

function useApi<TResponse, TData = Object>(
  url: string,
  config: RequestInit,
  initialFetch = false,
  useCachedToken = false,
) {
  const [isLoading, setIsLoadingApi] = useState(false);
  const { setErrorMessage } = useErrorMessage();
  const controller = useRef(new AbortController());
  const configurationContext = useContext(ConfigurationContext);
  const userContext = useUserContext();
  const authContext = useAuthContext();

  const [response, setResponse] = useState<TResponse>();

  const fetchFromApi: (data?: TData, urlPart?: string) => Promise<TResponse> = useCallback(
    async (data?: TData, urlPart?: string) => {
      if (controller.current.signal.aborted) {
        controller.current = new AbortController();
      }
      if (!urlPart || !noGlobalLoadingUrls.some((x) => x.test(urlPart))) {
        setIsLoadingApi(true);
      }

      try {
        const fetchResponse = await fetch(configurationContext?.apiBaseUrl + url + (urlPart ? urlPart : ''), {
          ...config,
          headers: await getHeaders(userContext, authContext, useCachedToken, config.headers),
          signal: controller.current.signal,
          body: data ? JSON.stringify(data) : undefined,
        });

        if (!fetchResponse.ok && (fetchResponse.status >= 500 || fetchResponse.status === 404)) {
          throw fetchResponse;
        } else if (fetchResponse.status === 401) {
          userContext.logout();
        }

        const contentType = fetchResponse.headers.get('content-type');
        let result;
        if (contentType) {
          if (contentType.indexOf('application/json') !== -1) {
            result = await fetchResponse.json();

            if (!isErrorResponse(result)) setResponse(result);
          } else if (
            contentType.indexOf('application/pdf') !== -1 ||
            contentType.indexOf('image/png') !== -1 ||
            contentType.indexOf('image/jpeg') !== -1 || 
            contentType.indexOf('text/html') !== -1 ||
            contentType.indexOf('application/zip') !== -1
          ) {
            result = {
              filename: (fetchResponse.headers.get('content-disposition') as string)
                .split('filename=')[1]
                .split(';')[0]
                .split('"')
                .join(''),
              blob: await fetchResponse.blob(),
            } as IFileResult;
          }
        }

        return result;
      } catch (error) {
        if (!controller.current.signal.aborted) {
          const message = error?.statusText ? error.statusText : 'An error occurred';
          setErrorMessage({
            message: message,
          });

          throw error;
        }
      } finally {
        setIsLoadingApi(false);
      }
    },
    [url, config, configurationContext, userContext, authContext, useCachedToken, setErrorMessage],
  );

  useEffect(() => {
    if (initialFetch) {
      fetchFromApi();
    }
  }, [initialFetch, fetchFromApi]);

  useEffect(() => {
    return () => {
      controller.current.abort();
    };
  }, []);

  return { isLoading, response, fetch: fetchFromApi };
}

let storedToken: IdTokenResponse | undefined;
async function getHeaders(
  userContext: IUserContext,
  authContext: MsalAuthProvider | null,
  useCachedToken: boolean,
  headers?: HeadersInit,
) {
  let newHeaders = new Headers({
    ...headers,

    // To avoid caching in internet explorer
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
  });

  if (authContext) {
    let token;
    if (useCachedToken && storedToken && authContext.authenticationState) {
      const expires = new Date(Number(storedToken.idToken.expiration) * 1000);
      if (expires > new Date(Date.now() - 1000 * 60)) {
        token = storedToken;
      } else {
        storedToken = undefined;
      }
    }
    if (!token) {
      try {
        token = await authContext.getIdToken();
        storedToken = token;
      } catch (error) {
        userContext.logout();
      }
    }

    newHeaders.append('Authorization', 'Bearer ' + token?.idToken.rawIdToken);
  }

  if (userContext.user && userContext.user.userType) {
    newHeaders.append('X-Skyl-BiznizPortal-Selected-User-Type', userContext.user.userType.toString());

    if (userContext.user.userType !== UserType.SuperAdmin) {
      newHeaders.append(
        'X-Skyl-BiznizPortal-Selected-Application-User-Id',
        userContext.user.selectedUserId?.toString() ?? '',
      );
    } else if (userContext.user.impersonatedCompany) {
      newHeaders.append(
        'X-Skyl-BiznizPortal-Selected-Application-User-Id',
        userContext.user.impersonatedCompany.companyUser.id.toString(),
      );
    }
  }

  return newHeaders;
}

export function useApiGet<T>(url: string, initialFetch = true) {
  const config = useRef<RequestInit>({
    method: 'GET',
  });

  const { isLoading, response, fetch } = useApi<T>(url, config.current, initialFetch);

  const get = useCallback(
    (urlParameter?: string) => {
      return fetch(undefined, urlParameter);
    },
    [fetch],
  );

  return {
    isLoading,
    response,
    get,
  };
}

export function useApiPost<T, TData = Object>(url: string, useCachedToken: boolean = false) {
  const config = useRef<RequestInit>({
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const { isLoading, response, fetch } = useApi<T, TData>(url, config.current, false, useCachedToken);

  return {
    isLoading,
    response,
    post: fetch,
  };
}

export function useApiPut<T, TData = Object>(url: string) {
  const config = useRef<RequestInit>({
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const { isLoading, fetch } = useApi<T, TData>(url, config.current);

  return {
    isLoading,
    put: fetch,
  };
}

export function useApiDelete<T>(url: string) {
  const config = useRef<RequestInit>({
    method: 'DELETE',
  });

  const { isLoading, fetch } = useApi<T>(url, config.current);

  return {
    isLoading,
    delete: fetch,
  };
}
