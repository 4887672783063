import React, { useCallback, useEffect, useState } from 'react';
import {
  ListItemIcon,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  MenuItem,
} from '@material-ui/core';
import { ValueType } from 'react-select';
import { useHistory } from 'react-router';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import EditIcon from '@material-ui/icons/EditRounded';
import moment from 'moment';
import IPaginatedResponse from '../../../../../../Shared/Api/response/IPaginatedResponse';
import SearchInput from '../../../../../../Shared/InputFields/SearchInput/SearchInput';
import { MobileTable } from '../../../../../../Shared/Table/MobileTable/MobileTable';
import { MobileTableRow } from '../../../../../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import SortButton from '../../../../../../Shared/Table/SortButton/SortButton';
import { Table } from '../../../../../../Shared/Table/Table';
import TablePaginationWithDatasource from '../../../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import useTable from '../../../../../../Shared/Table/useTable';
import { Select, ISelectOption } from '../../../../../../Shared/Select';
import { IAgreementListItems, useGetAgreementsList } from '../Agreements.api';
import { AgreementListCustomFilter, agreementListCustomFilterLabel } from '../ErrandAgreementList.api';
import theme from '../../../../../../Styles/Theme';
import formatDate from '../../../../../../Shared/Formatting/formatDate';
import { DropdownCell } from '../../../../../../Shared/Table/DropdownCell/DropdownCell';
import Datepicker from '../../../../../../Shared/InputFields/DatePicker/Datepicker';
import { SortDirection } from '../../../../../../Shared/Api/request/IPaginatedRequest';
import { useStyles } from './ErrandAgreementList.styles';
import RenewAgreementPopup from '../../../../Customers/Components/Facilities/CoreSystems/ViewCoreSystem/Components/RenewAgreementPopup/RenewAgreementPopup';
import TerminateAgreementForm from '../../../../Customers/Components/ViewCustomer/TerminateAgreementForm/TerminateAgreementForm';
import { AccessRights, ModuleIdentifiers } from '../../../../../../Core/Authentication/ModuleAccess';
import AccessGuard from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import SoftDeleteAgreementForm from '../../../../Customers/Components/ViewCustomer/SoftDeleteAgreementForm/SoftDeleteAgreementForm';
import CopyLink from '../../../../../../Shared/CopyLink/CopyLink';
import Container from '../../../../../../Shared/Container/Container';

const customFilteringOptions: ISelectOption[] = [
  {
    label: agreementListCustomFilterLabel[AgreementListCustomFilter.new],
    value: AgreementListCustomFilter.new.toString(),
  },
  {
    label: agreementListCustomFilterLabel[AgreementListCustomFilter.ending],
    value: AgreementListCustomFilter.ending.toString(),
  },
  {
    label: agreementListCustomFilterLabel[AgreementListCustomFilter.terminated],
    value: AgreementListCustomFilter.terminated.toString(),
  },
];

const ErrandAgreementList = () => {
  const { isLoading, agreementList, getAgreementsList } = useGetAgreementsList();

  const [agreements, setAgreements] = useState<IPaginatedResponse<IAgreementListItems>>();
  const [emptyMessage, setEmptyMessage] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isTerminated, setIsTerminated] = useState<boolean>(false);
  const [selectedAgreement, setSelectedAgreement] = useState<IAgreementListItems>();
  const [openRenewForm, setOpenRenewForm] = useState(false);
  const [openTerminateForm, setOpenTerminateForm] = useState(false);
  const [openSoftDeleteForm, setOpenSoftDeleteFrom] = useState(false);

  const history = useHistory();
  const customerParams = new URLSearchParams(window.location.search).get('query');
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();

  const {
    toggleSort,
    sortProperty,
    sortDirection,
    pageSize,
    setPageSize,
    setPageNumber,
    query,
    setQuery,
    paginatedRequest,
  } = useTable(100, '', SortDirection.Ascending, customerParams ?? '');

  const getAgreementList = useCallback(async () => {
    const response = await getAgreementsList({ ...paginatedRequest, startDate, endDate, isTerminated });
    setAgreements(response);
    if (response?.items?.length <= 0) {
      if (paginatedRequest?.query || startDate || endDate) {
        setEmptyMessage('Filtreringen gav inga resultat');
      } else {
        setEmptyMessage('Inga avtal tillagda');
      }
    }
  }, [startDate, endDate, isTerminated, paginatedRequest, getAgreementsList]);

  useEffect(() => {
    getAgreementList();
  }, [getAgreementList]);

  function getClasses(item: IAgreementListItems) {
    var diff = moment(item.endDate).diff(new Date(), 'month');
    if (diff < 0) {
      return classes.red;
    } else if (diff < 4) {
      return classes.yellow;
    } else {
      return classes.green;
    }
  }

  const checkEditable = (agreementEndDate: Date | undefined) => {
    if (agreementEndDate) return new Date(agreementEndDate) > new Date();
    else return true;
  };

  const handleTableClick = (agreement: IAgreementListItems) => {
    history.push({
      pathname: history.location.pathname,
      search: query ? `?query=${query}` : '',
    });

    history.push(`/customers/${agreement.customerId}/agreements/${agreement.id}`);
  };

  function handleCustomFiltering(option: ValueType<ISelectOption>): void {
    const selected = option ? Number((option as ISelectOption).value) : 0;

    switch (selected) {
      case AgreementListCustomFilter.all: {
        setIsTerminated(false);
        setStartDate(null);
        setEndDate(null);
        break;
      }
      case AgreementListCustomFilter.new: {
        setStartDate(moment().subtract(1, 'month').toDate());
        setEndDate(null);
        break;
      }
      case AgreementListCustomFilter.ending: {
        setStartDate(null);
        setEndDate(moment().add(3, 'month').toDate());
        break;
      }
      case AgreementListCustomFilter.terminated: {
        setStartDate(null);
        setEndDate(null);
        setIsTerminated(true);
        break;
      }
    }
  }

  return (
    <Container
      customSize={{ fullsize: true }}
      label={`Avtal ${agreementList?.totalCount ? `(${agreementList?.totalCount})` : ''}`}
      customIconHeader={
        <AccessGuard module={ModuleIdentifiers.Agreements} accessRights={AccessRights.Read}>
          <>
            {query && (
              <CopyLink
                color="white"
                root={history.location.pathname}
                parameters={[{ param: 'query', value: query }]}
              />
            )}
          </>
        </AccessGuard>
      }
    >
      <Table
        filters={
          <>
            <SearchInput
              defaultValue={query}
              type="search"
              placeholder="Sök"
              value={query}
              onChange={(search) => setQuery(search)}
            />
            <Select
              inputFieldProps={{ 'aria-label': 'Snabbval', placeholder: 'Snabbval' }}
              selectProps={{
                options: customFilteringOptions,
                isClearable: true,
                onChange: handleCustomFiltering,
              }}
            />
            <Datepicker isClearable inFilter placeholderText="Från" selected={startDate} onChange={setStartDate} />
            <Datepicker isClearable inFilter placeholderText="Till" selected={endDate} onChange={setEndDate} />
          </>
        }
        mobile={mobileView}
        customSize={{
          fullsize: true,
        }}
        pagination={
          agreements && (
            <TablePaginationWithDatasource
              datasource={agreements}
              onChangePage={setPageNumber}
              onChangeRowsPerPage={setPageSize}
            />
          )
        }
        empty={{
          empty: agreements && agreements.totalCount <= 0 ? true : false,
          message: emptyMessage,
        }}
        loading={{
          loading: !!isLoading,
          skeletonRows: pageSize,
        }}
        minWidth
      >
        {selectedAgreement && (
          <>
            <RenewAgreementPopup
              customerId={selectedAgreement.customerId.toString()}
              agreementId={selectedAgreement.id.toString()}
              startDate={selectedAgreement.endDate ?? new Date()}
              onRenew={getAgreementList}
              open={openRenewForm}
              isSaleAgreement={true}
              setOpen={setOpenRenewForm}
            />
            <TerminateAgreementForm
              customerId={selectedAgreement.customerId.toString()}
              agreementId={selectedAgreement.id.toString()}
              startDate={selectedAgreement.startDate}
              otherLoading={isLoading}
              onTerminate={getAgreementList}
              open={openTerminateForm}
              setOpen={setOpenTerminateForm}
            />
            <SoftDeleteAgreementForm
              agreementId={selectedAgreement.id.toString()}
              onTerminate={getAgreementList}
              open={openSoftDeleteForm}
              setOpen={setOpenSoftDeleteFrom}
            />
          </>
        )}
        {mobileView ? (
          <>
            {agreements?.items.map((agreement) => (
              <MobileTable key={agreement.id} label={`${agreement.customerNumber} - ${agreement.customerName}`}>
                <MobileTableRow>
                  <AccessGuard
                    module={ModuleIdentifiers.Agreements}
                    accessRights={AccessRights.Write}
                    fallback={<TableCell className={classes.narrowColumn}></TableCell>}
                  >
                    {checkEditable(agreement.endDate) && !agreement.isTerminated && (
                      <MenuItem
                        onClick={() =>
                          history.push(`/customers/${agreement.customerId}/agreements/${agreement.id}/update`)
                        }
                      >
                        <ListItemIcon>{<EditIcon />}</ListItemIcon>
                        <Typography variant="inherit">Ändra</Typography>
                      </MenuItem>
                    )}
                    {agreement.endDate && (
                      <MenuItem
                        onClick={() => {
                          setSelectedAgreement(agreement);
                          setOpenRenewForm(true);
                        }}
                      >
                        <ListItemIcon>{<AutorenewIcon />}</ListItemIcon>
                        <Typography variant="inherit">Förnya</Typography>
                      </MenuItem>
                    )}
                    {!agreement.isTerminated && (
                      <AccessGuard module={ModuleIdentifiers.Agreements} accessRights={AccessRights.Full}>
                        <MenuItem
                          onClick={() => {
                            setSelectedAgreement(agreement);
                            setOpenTerminateForm(true);
                          }}
                        >
                          <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                          <Typography variant="inherit">Säg upp</Typography>
                        </MenuItem>
                      </AccessGuard>
                    )}
                    <AccessGuard module={ModuleIdentifiers.Agreements} accessRights={AccessRights.Full}>
                      <MenuItem
                        onClick={() => {
                          setSelectedAgreement(agreement);
                          setOpenSoftDeleteFrom(true);
                        }}
                      >
                        <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                        <Typography variant="inherit">Ta Bort</Typography>
                      </MenuItem>
                    </AccessGuard>
                  </AccessGuard>
                </MobileTableRow>
                <MobileTableRow label="Avtal nr" value={agreement.agreementNumber} />
                <MobileTableRow label="Kundnummer" value={agreement.customerNumber} />
                <MobileTableRow label="Kundnamn" value={agreement.customerName} />
                <MobileTableRow label="Kundadress" value={agreement.customerAddress} />
                <MobileTableRow label="Startdatum" value={formatDate(agreement.startDate, '-')} />
                <MobileTableRow label="Utgångsdatum" value={formatDate(agreement.endDate, '-')} />
              </MobileTable>
            ))}
          </>
        ) : (
          <>
            <TableHead>
              <TableRow>
                <AccessGuard
                  module={ModuleIdentifiers.Agreements}
                  accessRights={AccessRights.Write}
                  fallback={<TableCell className={classes.narrowColumn}></TableCell>}
                >
                  <TableCell>
                    <span className={'sr-only'}>Knappar</span>
                  </TableCell>
                </AccessGuard>

                <TableCell>
                  Avtal nr
                  <SortButton
                    property="agreementNumber"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>
                <TableCell>
                  Kundnamn
                  <SortButton
                    property="customerName"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>

                <TableCell>
                  Kundadress
                  <SortButton
                    property="customerAddress"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>
                <TableCell>
                  Systemtyp
                  <SortButton
                    property="systemType"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>
                <TableCell>
                  Startdatum
                  <SortButton
                    property="startDate"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>
                <TableCell>
                  Utgångsdatum
                  <SortButton
                    property="endDate"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {agreements?.items.map((agreement) => (
                <TableRow className={classes.pointerCursor} hover tabIndex={-1} key={agreement.id}>
                  <AccessGuard
                    module={ModuleIdentifiers.Agreements}
                    accessRights={AccessRights.Write}
                    fallback={<TableCell className={classes.narrowColumn}></TableCell>}
                  >
                    <DropdownCell>
                      {checkEditable(agreement.endDate) && !agreement.isTerminated && (
                        <MenuItem
                          onClick={() =>
                            history.push(`/customers/${agreement.customerId}/agreements/${agreement.id}/update`)
                          }
                        >
                          <ListItemIcon>{<EditIcon />}</ListItemIcon>
                          <Typography variant="inherit">Ändra</Typography>
                        </MenuItem>
                      )}
                      {agreement.endDate && (
                        <MenuItem
                          onClick={() => {
                            setSelectedAgreement(agreement);
                            setOpenRenewForm(true);
                          }}
                        >
                          <ListItemIcon>{<AutorenewIcon />}</ListItemIcon>
                          <Typography variant="inherit">Förnya</Typography>
                        </MenuItem>
                      )}
                      {!agreement.isTerminated && (
                        <AccessGuard module={ModuleIdentifiers.Agreements} accessRights={AccessRights.Full}>
                          <MenuItem
                            onClick={() => {
                              setSelectedAgreement(agreement);
                              setOpenTerminateForm(true);
                            }}
                          >
                            <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                            <Typography variant="inherit">Säg upp</Typography>
                          </MenuItem>
                        </AccessGuard>
                      )}
                      <AccessGuard module={ModuleIdentifiers.Agreements} accessRights={AccessRights.Full}>
                        <MenuItem
                          onClick={() => {
                            setSelectedAgreement(agreement);
                            setOpenSoftDeleteFrom(true);
                          }}
                        >
                          <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                          <Typography variant="inherit">Ta Bort</Typography>
                        </MenuItem>
                      </AccessGuard>
                    </DropdownCell>
                  </AccessGuard>

                  <TableCell className={classes.font} onClick={() => handleTableClick(agreement)}>
                    {agreement.agreementNumber}
                  </TableCell>
                  <TableCell onClick={() => handleTableClick(agreement)}> {agreement.customerName}</TableCell>
                  <TableCell onClick={() => handleTableClick(agreement)}> {agreement.customerAddress}</TableCell>
                  <TableCell
                    className={classes.font}
                    onClick={() =>
                      history.push(
                        `/customers/${agreement.customerId}/facility/${agreement.facilityId}/system/${agreement.coreSystemId}`,
                      )
                    }
                  >
                    {agreement.systemType}
                  </TableCell>
                  <TableCell onClick={() => handleTableClick(agreement)}>{formatDate(agreement.startDate)}</TableCell>
                  <TableCell
                    onClick={() => handleTableClick(agreement)}
                    className={`${classes.endDate} ${getClasses(agreement)}`}
                  >
                    {agreement.endDate ? formatDate(agreement.endDate) : 'Tillsvidare'}
                  </TableCell>
                  <TableCell onClick={() => handleTableClick(agreement)}>
                    {agreement.isTerminated ? 'Uppsagt' : agreement.isActive ? 'Aktiv' : 'Inaktiv'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </>
        )}
      </Table>
    </Container>
  );
};

export default ErrandAgreementList;
