import React, { useState, useEffect, Fragment } from 'react';
import {
  Company,
  useGetSurvey,
  SurveyQuestionType,
  ISurveyQuestion,
  IQuestionAnswerDto,
  IChoiceQuestion,
  useRespondToSurvey,
} from '../Survey.api';
import { useHistory, useParams } from 'react-router-dom';
import { ErrorCode, isErrorResponse } from '../../../../Shared/Api/response/IErrorRespose';
import Button from '../../../../Shared/Button/Button';
import Container from '../../../../Shared/Container/Container';
import useFormValidation from '../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import Success from '../../Success/Success';
import TextQuestion from './TextQuestion/TextQuestion';
import SatisfactionQuestion from './SatisfactionQuestion/SatisfactionQuestion';
import ChoiceQuestion from './ChoiceQuestion/ChoiceQuestion';
import { isResultError } from '../../../../Shared/Api/response/ICreateResult';

const SurveyForm = () => {
  const history = useHistory();
  const { token } = useParams<{ token: string | undefined }>();
  const { isFormValid, formRef } = useFormValidation();
  const { isLoading, survey, getSurvey } = useGetSurvey();
  const { isRespondLoading, respond } = useRespondToSurvey(token);
  const [answers, setAnswers] = useState<IQuestionAnswerDto[]>([]);
  const [company, setCompany] = useState<Company>();
  const [success, setSuccess] = useState(false);

  const isValid = isFormValid && !answers.some((x) => !x.answer.trim()) && answers.length === survey?.questions.length;

  useEffect(() => {
    async function loadForm() {
      const result = await getSurvey(token);

      if (!result || (isErrorResponse(result) && result.errorCode === ErrorCode.AnonymousTokenNotFound)) {
        history.push(`${history.location.pathname}/404`);
      } else {
        setCompany(result.company);
        setAnswers(result.questions.map((x) => ({ questionId: x.id, answer: '' })));
      }
    }

    if (token) {
      loadForm();
    } else {
      history.push('');
    }
  }, [getSurvey, history, token]);

  async function save() {
    const result = await respond({
      answers: answers,
    });

    if (isErrorResponse(result) && result.errorCode === ErrorCode.AnonymousTokenNotFound) {
      history.push(`${history.location.pathname}/404`);
      return;
    }

    !isResultError(result) && setSuccess(true);
  }

  function Actions() {
    return (
      <Button color="primary" variant="contained" loading={isRespondLoading} disabled={!isValid} onClick={save}>
        Skicka
      </Button>
    );
  }

  function updateAnswer(questionId: number, answer: string) {
    setAnswers([...answers.filter((x) => x.questionId !== questionId), { questionId: questionId, answer: answer }]);
  }

  function getAnswer(questionId: number): string {
    var answerObj = answers.find((x) => x.questionId === questionId);
    return answerObj?.answer ?? '';
  }

  function QuestionComponent(question: ISurveyQuestion) {
    switch (question.type) {
      case SurveyQuestionType.Text:
        return (
          <TextQuestion
            question={question}
            answer={getAnswer(question.id)}
            onAnswerChange={(answer) => updateAnswer(question.id, answer)}
          />
        );
      case SurveyQuestionType.Satisfaction:
        return (
          <SatisfactionQuestion
            question={question}
            answer={getAnswer(question.id)}
            onAnswerChange={(answer) => updateAnswer(question.id, answer)}
          />
        );
      case SurveyQuestionType.Choice:
        return (
          <ChoiceQuestion
            question={question as IChoiceQuestion}
            answer={getAnswer(question.id)}
            onAnswerChange={(answer) => updateAnswer(question.id, answer)}
          />
        );
    }
  }

  if (success) return <Success title="Utskick besvarat." content="Tack för din medverkan" email={company?.emailCustomerService} phonenumber={company?.phoneNumber} companyLogoBase64={company?.companyLogoBase64} />;

  return (
    <Container label={'Utskick'} actions={<Actions />} form ref={formRef} loading={isLoading}>
      {survey?.questions
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map((question) => (
          <Fragment key={question.id}>{QuestionComponent(question)}</Fragment>
        ))}
    </Container>
  );
};

export default SurveyForm;
