import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreHorizRounded';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { Link } from 'react-router-dom';
import Button from '../../../Button/Button';
import { useStyles } from './ShowAll.styles';

interface IShowAllProps {
  items?: any;
}

function ShowAll(props: IShowAllProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button disableTouchRipple disableFocusRipple className={classes.root} onClick={handleOpen}>
        <div className={classes.content}>
          <div className={classes.icon} data-testid="icon">
            <MoreIcon />
          </div>
          <Typography variant="caption">Visa alla</Typography>
        </div>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        {props.items &&
          props.items.map((x: any) => (
            <MenuItem component={Link} key={x.innerText} tabIndex={0} to={x.childNodes[0].pathname}>
              {x.innerText}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}

export default ShowAll;
