import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ThemeProvider } from '@material-ui/core';
import theme from './Styles/Theme';
import * as serviceWorker from './serviceWorker';
import { ConfigurationProvider } from './Core/Configurations/ConfigurationContext';
import { ErrorMessageContextProvider } from './Shared/Hooks/UseErrorMessage/useErrorMessage';
import moment from 'moment';
import 'moment/locale/sv';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import AnonymousApp from './AnonymousApp/AnonymousApp';
import AuthApp from './AuthApp/AuthApp';
import ScrollToTop from './Shared/ScrollToTop/ScrollToTop';
import { AppContextProvider } from './Core/AppContext/AppContext';

moment.locale('sv');

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ErrorMessageContextProvider>
        <ConfigurationProvider>
          <BrowserRouter>
            <ScrollToTop />
            <AppContextProvider>
              <Switch>
                <Route path="/anonymous/:token">
                  <AnonymousApp />
                </Route>
                <Route>
                  <AuthApp />
                </Route>
              </Switch>
            </AppContextProvider>
          </BrowserRouter>
        </ConfigurationProvider>
      </ErrorMessageContextProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
