import React, { useEffect, useState } from 'react';
import { Tab, useMediaQuery, useTheme } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import DocumentList from '../../../../../AuthApp/Modules/Customers/Components/ViewCustomer/Documents/Components/DocumentList/DocumentList';
import { SaleList } from '../../../../../AuthApp/Modules/Customers/Components/ViewCustomer/SaleList/SaleList';
import { Fieldset } from '../../../../../Shared/Form/Fieldset/Fieldset';
import {
  ICustomerDraftDto,
  IUpdateCustomerDraftRequest,
  useGetCustomerDraft,
  useGetMyPagesCustomer,
  usePostCustomerDraft,
} from '../../MyPages.api';
import { ErrorCode, isErrorResponse } from '../../../../../Shared/Api/response/IErrorRespose';
import { InputField } from '../../../../../Shared/InputFields/InputField/InputField';
import ContactList from '../../../../../AuthApp/Modules/Customers/Components/Contacts/ContactList/ContactList';
import AgreementList from '../../../../../AuthApp/Modules/Customers/Components/ViewCustomer/AgreementList/AgreementList';
import SystemLogList from '../../../../../AuthApp/Modules/Admin/Components/SystemLog/SystemLogList/SystemLogList';
import FacilityList from '../../../../../AuthApp/Modules/Customers/Components/Facilities/FacilityList/FacilityList';
import Tabs from '../../../../../Shared/Tabs/Tabs';
import { CustomerType, getCustomerTypeName } from '../../../../../Shared/Misc/CustomerType';
import { SystemLogType } from '../../../../../AuthApp/Modules/Admin/Components/SystemLog/SystemLog.api';
// import { AutoCreditCheckInterval } from '../../../../../AuthApp/Modules/Customers/Customers.api';
import useInputState from '../../../../../Shared/Hooks/UseInputState/UseInputState';
import PhoneNumberField from '../../../../../Shared/InputFields/PhoneNumberField/PhoneNumberField';
import EmailField from '../../../../../Shared/InputFields/EmailField/EmailField';
import PostalCodeField from '../../../../../Shared/InputFields/PostalCodeField/PostalCodeField';
import Button from '../../../../../Shared/Button/Button';
import { useStyles } from './CustomerInformation.styles';
import moment from 'moment';
interface IProps {
  customerId: string;
  companyId: number;
}

const CustomerInformation = (props: IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [includeArchived, setincludeArchived] = useState<boolean>(false);
  const { token } = useParams<{ token: string }>();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { isLoading, customer, get: getOriginalCustomer } = useGetMyPagesCustomer(
    props.customerId,
    includeArchived,
    token,
  );
  const { response: draft, get: getCustomerDraft } = useGetCustomerDraft(
    token,
    props.companyId.toString(),
    props.customerId,
  );
  const { isLoading: postDraftIsLoading, post } = usePostCustomerDraft();

  const [draftView, setDraftView] = useState<boolean>(false);
  const [name, changeName, setName] = useInputState<string | undefined>('');
  const [customerEmail, changeCustomerEmail, setCustomerEmail] = useInputState<string | undefined>('');
  const [customerAddress, changeCustomerAddress, setCustomerAddress] = useInputState<string | undefined>('');
  const [customerPostalCode, changeCustomerPostalCode, setCustomerPostalCode] = useInputState<string | undefined>('');
  const [customerCity, changeCustomerCity, setCustomerCity] = useInputState<string | undefined>('');
  const [customerPhoneNumber, changeCustomerPhoneNumber, setCustomerPhoneNumber] = useInputState<string | undefined>(
    '',
  );
  const [invoiceEmail, changeInvoiceEmail, setInvoiceEmail] = useInputState<string | undefined>('');
  const [invoiceReference, changeInvoiceReference, setInvoiceReference] = useInputState<string | undefined>('');
  const [invoiceAddress, changeInvoiceAddress, setInvoiceAddress] = useInputState<string | undefined>('');
  const [invoicePostalCode, changeInvoicePostalCode, setInvoicePostalCode] = useInputState<string | undefined>('');
  const [invoiceCity, changeInvoiceCity, setInvoiceCity] = useInputState<string | undefined>('');

  // const [noContact, setNoContact] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<CustomerType | undefined>(CustomerType.Company);
  // const [selectedAutoCreditCheckIntervalOption, setSelectedAutoCreditCheckIntervalOption] = useState<
  //   AutoCreditCheckInterval | undefined
  // >();

  function convertDraftStatus(status: number): string {
    switch (status) {
      case 2:
        return 'Nekad';
      case 3:
        return 'Aktiv';

      default:
        return '';
    }
  }

  useEffect(() => {
    async function loadForm() {
      const result = await getOriginalCustomer();

      if (isErrorResponse(result) && result.errorCode === ErrorCode.AnonymousTokenNotFound) {
        history.push(`${history.location.pathname}/404`);
      }

      const draftResult = await getCustomerDraft();
      if (draftResult) {
        setName(draftResult?.customer.name);
        setCustomerPhoneNumber(draftResult?.customer.customerPhoneNumber);
        setCustomerEmail(draftResult?.customer.customerEmail);
        setCustomerAddress(draftResult?.customer.customerAddress);
        setCustomerPostalCode(draftResult?.customer.customerPostalCode);
        setCustomerCity(draftResult?.customer.customerCity);
        setInvoiceEmail(draftResult?.customer.invoiceEmail);
        setInvoiceAddress(draftResult?.customer.invoiceAddress);
        setInvoicePostalCode(draftResult?.customer.invoicePostalCode);
        setInvoiceCity(draftResult?.customer.invoiceCity);
        // setNoContact(result?.noContact);
        setInvoiceReference(draftResult?.customer.invoiceReference);
        setDraftView(true);
        // setSelectedAutoCreditCheckIntervalOption(result?.autoCreditCheckInterval);
        return;
      }

      setName(result?.name);
      setSelectedType(result?.type);
      setCustomerPhoneNumber(result?.customerPhoneNumber);
      setCustomerEmail(result?.customerEmail);
      setCustomerAddress(result?.customerAddress);
      setCustomerPostalCode(result?.customerPostalCode);
      setCustomerCity(result?.customerCity);
      setInvoiceEmail(result?.invoiceEmail);
      setInvoiceAddress(result?.invoiceAddress);
      setInvoicePostalCode(result?.invoicePostalCode);
      setInvoiceCity(result?.invoiceCity);
      // setNoContact(result?.noContact);
      setInvoiceReference(result?.invoiceReference);
      setDraftView(false);
      // setSelectedAutoCreditCheckIntervalOption(result?.autoCreditCheckInterval);
    }

    loadForm();

  }, [getOriginalCustomer, token, history, getCustomerDraft, setName, setCustomerPhoneNumber, setCustomerEmail, setCustomerAddress, setCustomerPostalCode, setCustomerCity, setInvoiceEmail, setInvoiceAddress, setInvoicePostalCode, setInvoiceCity, setInvoiceReference]);

  function resetToOriginalData(draftResult?: ICustomerDraftDto) {
    if (draftResult) {
      setName(draftResult?.customer.name);
      setCustomerPhoneNumber(draftResult?.customer.customerPhoneNumber);
      setCustomerEmail(draftResult?.customer.customerEmail);
      setCustomerAddress(draftResult?.customer.customerAddress);
      setCustomerPostalCode(draftResult?.customer.customerPostalCode);
      setCustomerCity(draftResult?.customer.customerCity);
      setInvoiceEmail(draftResult?.customer.invoiceEmail);
      setInvoiceAddress(draftResult?.customer.invoiceAddress);
      setInvoicePostalCode(draftResult?.customer.invoicePostalCode);
      setInvoiceCity(draftResult?.customer.invoiceCity);
      // setNoContact(result?.noContact);
      setInvoiceReference(draftResult?.customer.invoiceReference);
      // setSelectedAutoCreditCheckIntervalOption(result?.autoCreditCheckInterval);
      setDraftView(true);
      return;
    }
    setName(customer?.name);
    setSelectedType(customer?.type);
    setCustomerPhoneNumber(customer?.customerPhoneNumber);
    setCustomerEmail(customer?.customerEmail);
    setCustomerAddress(customer?.customerAddress);
    setCustomerPostalCode(customer?.customerPostalCode);
    setCustomerCity(customer?.customerCity);
    setInvoiceEmail(customer?.invoiceEmail);
    setInvoiceAddress(customer?.invoiceAddress);
    setInvoicePostalCode(customer?.invoicePostalCode);
    setInvoiceCity(customer?.invoiceCity);
    // setNoContact(result?.noContact);
    setInvoiceReference(customer?.invoiceReference);
    // setSelectedAutoCreditCheckIntervalOption(result?.autoCreditCheckInterval);
    setDraftView(false);
  }

  function detectChanges(): boolean {
    return false; // remove this when we want users to create drafts
    // eslint-disable-next-line
    if (
      (customer?.name !== name ||
        customer?.customerPhoneNumber !== customerPhoneNumber ||
        customer?.customerEmail !== customerEmail ||
        customer?.customerAddress !== customerAddress ||
        customer?.customerPostalCode !== customerPostalCode ||
        customer?.customerCity !== customerCity ||
        customer?.invoiceAddress !== invoiceAddress ||
        customer?.invoicePostalCode !== invoicePostalCode ||
        customer?.invoiceCity !== invoiceCity ||
        customer?.invoiceReference !== invoiceReference ||
        customer?.invoiceEmail !== invoiceEmail) &&
      !draftView
    ) {
      return true;
    }
    return false;
  }

  async function updateCustomer(): Promise<void> {
    const request: IUpdateCustomerDraftRequest = {
      customerId: customer?.id,
      companyId: props.companyId,
      name: name,
      customerNumber: customer?.customerNumber,
      customerEmail: customerEmail,
      customerAddress: customerAddress,
      customerPostalCode: customerPostalCode,
      customerCity: customerCity,
      customerPhoneNumber: customerPhoneNumber,
      invoiceEmail: invoiceEmail,
      invoiceAddress: invoiceAddress,
      invoicePostalCode: invoicePostalCode,
      invoiceCity: invoiceCity,
      invoiceReference: invoiceReference,
    };

    await post(token, request);
    const result = await getCustomerDraft();
    resetToOriginalData(result);
  }

  /* async function confirmSupplierCode() {
    const result = await confirmCode({ code: code });

    if (isErrorResponse(result) && result.errorCode === ErrorCode.AnonymousTokenNotFound) {
      history.push(`${history.location.pathname}/404`);
      return;
    }
  } */

  /** borde ligga här när det funkar med anontoken */
  return (
    <>
      <Fieldset
        legend="Kundens uppgifter"
        customSize={{ fullsize: true, sm: 'auto', lg: 'auto', md: 'auto', xs: 'auto', xl: 'auto' }}
      >
        {draft && (
          <div className={classes.draftBtn}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                resetToOriginalData();
                setDraftView(!draftView);
              }}
            >
              {draftView ? 'Se original' : 'Se ändringsförslag'}
            </Button>
          </div>
        )}
        <InputField
          fullwidth={mobileView}
          customWidth={4}
          label="Kundnummer"
          value={customer?.customerNumber.toString()}
          viewOnly
          required
        />
        <InputField
          fullwidth={mobileView}
          customWidth={4}
          label="Typ"
          value={getCustomerTypeName(customer?.type)}
          viewOnly
          required
        />
        <InputField
          fullwidth={mobileView}
          customWidth={4}
          label="Org-/Personnummer"
          required
          value={customer?.identificationNumber}
          disabled
        />
        <InputField
          fullwidth={mobileView}
          customWidth={4}
          label="Namn"
          value={!draftView ? name : draft?.customer.name}
          onChange={changeName}
          required
          success={customer?.name !== draft?.customer.name && draftView}
        />
        <PhoneNumberField
          label={selectedType === CustomerType.Company ? 'Telefonnummer till företaget' : 'Telefon'}
          fullwidth={mobileView}
          customWidth={4}
          value={!draftView ? customerPhoneNumber : draft?.customer.customerPhoneNumber}
          onChange={changeCustomerPhoneNumber}
          success={customer?.customerPhoneNumber !== draft?.customer.customerPhoneNumber && draftView}
        />

        <EmailField
          label={selectedType === CustomerType.Company ? 'E-post till företaget' : 'E-post '}
          fullwidth={mobileView}
          customWidth={4}
          value={!draftView ? customerEmail : draft?.customer.customerEmail}
          onChange={changeCustomerEmail}
          success={customer?.customerEmail !== draft?.customer.customerEmail && draftView}
        />
        <InputField
          fullwidth={mobileView}
          customWidth={4}
          label="Gatuadress"
          required
          value={!draftView ? customerAddress : draft?.customer.customerAddress}
          onChange={changeCustomerAddress}
          success={customer?.customerAddress !== draft?.customer.customerAddress && draftView}
        />
        <PostalCodeField
          fullwidth={mobileView}
          customWidth={4}
          label="Postnummer"
          required
          value={!draftView ? customerPostalCode : draft?.customer.customerPostalCode}
          onChange={changeCustomerPostalCode}
          success={customer?.customerPostalCode !== draft?.customer.customerPostalCode && draftView}
        />
        <InputField
          fullwidth={mobileView}
          customWidth={4}
          label="Ort"
          required
          type="text"
          value={!draftView ? customerCity : draft?.customer.customerCity}
          onChange={changeCustomerCity}
          success={customer?.customerCity !== draft?.customer.customerCity && draftView}
        />
      </Fieldset>
      <Fieldset legend="Fakturauppgifter" customSize={{ lg: 6, md: 6 }}>
        <EmailField
          fullwidth={mobileView}
          customWidth={4}
          label="E-post"
          value={!draftView ? invoiceEmail : draft?.customer.invoiceEmail}
          onChange={changeInvoiceEmail}
          success={customer?.invoiceEmail !== draft?.customer.invoiceEmail && draftView}
        />
        <InputField
          fullwidth={mobileView}
          customWidth={4}
          label="Fakturaadress"
          type="text"
          required
          value={!draftView ? invoiceAddress : draft?.customer.invoiceAddress}
          onChange={changeInvoiceAddress}
          success={customer?.invoiceAddress !== draft?.customer.invoiceAddress && draftView}
        />
        <PostalCodeField
          fullwidth={mobileView}
          customWidth={4}
          label="Postnummer"
          type="text"
          required
          value={!draftView ? invoicePostalCode : draft?.customer.invoicePostalCode}
          onChange={changeInvoicePostalCode}
          success={customer?.invoicePostalCode !== draft?.customer.invoicePostalCode && draftView}
        />
        <InputField
          fullwidth={mobileView}
          customWidth={4}
          label="Ort"
          type="text"
          required
          value={!draftView ? invoiceCity : draft?.customer.invoiceCity}
          onChange={changeInvoiceCity}
          success={customer?.invoiceCity !== draft?.customer.invoiceCity && draftView}
        />
        <InputField
          fullwidth={mobileView}
          customWidth={12}
          label="Faktureringsreferens"
          value={!draftView ? invoiceReference : draft?.customer.invoiceReference}
          onChange={changeInvoiceReference}
          success={customer?.invoiceReference !== draft?.customer.invoiceReference && draftView}
        />
      </Fieldset>
      {draft && draftView && (
        <>
          <Fieldset legend=" Ändringslogg" customSize={{ lg: 6, md: 6, sm: 6 }}>
            <InputField fullwidth={mobileView} customWidth={4} label="Skapare" value={draft.proposer} viewOnly />
            <InputField
              fullwidth={mobileView}
              customWidth={4}
              label="Status"
              value={convertDraftStatus(draft.status)}
              viewOnly
            />
            <InputField
              fullwidth={mobileView}
              customWidth={4}
              label="Senast ändrad"
              value={moment(draft.timeStamp).format('lll')}
              viewOnly
            />
          </Fieldset>
        </>
      )}
      {detectChanges() && (
        <div className={classes.saveButton}>
          <Button
            color="primary"
            variant="contained"
            onClick={async () => {
              updateCustomer();
            }}
            loading={postDraftIsLoading}
          >
            Spara
          </Button>
        </div>
      )}
      {/* <Fieldset legend="Platsuppgifter">
          <InputField fullwidth={mobileView} customWidth={4} label="Namn" value={customer?.facilities[0].name} />
          <InputField
            fullwidth={mobileView}
            customWidth={4}
            label="Fakturaadress"
            value={customer?.facilities[0].address}
          />
          <InputField
            fullwidth={mobileView}
            customWidth={4}
            label="Postnummer"
            value={customer?.facilities[0].postalCode}
          />
          <InputField fullwidth={mobileView} customWidth={4} label="Ort" value={customer?.facilities[0].city} />
        </Fieldset> */}
      {false && (
        <div className={classes.tabs}>
          <Tabs customSize={{ fullsize: true }}>
            <Tab label="Kontakter">
              <ContactList customerId={customer?.id.toString() ?? ''} showFacility mobileView={mobileView} />
            </Tab>
            <Tab label="Ordrar">
              <SaleList customerId={customer?.id.toString() ?? ''} mobileView={mobileView} />
            </Tab>
            <Tab label="Avtal">
              <AgreementList customerId={customer?.id.toString() ?? ''} />
            </Tab>
            <Tab label="Dokument">
              <DocumentList customerId={customer?.id.toString() ?? ''} />
            </Tab>
            <Tab label="Händelser">
              {customer && (
                <SystemLogList
                  customSize={{ fullsize: true }}
                  defaultEntityId={customer?.id}
                  lockCustomerId
                  defaultLogType={SystemLogType.Customer}
                  lockLogType
                  hideLabel
                  customPage={[10, 15, 20]}
                />
              )}
            </Tab>
          </Tabs>
        </div>
      )}
      {customer && customer.facilities.length > 1 && (
        <FacilityList
          includeArchived={includeArchived}
          setincludeArchived={setincludeArchived}
          facilities={customer?.facilities}
          customerId={customer?.id}
          loading={isLoading}
          reloadCustomer={() => {}}
        />
      )}
      {/* (customer?.facilities[0]) && (
            <SecuritySystemTable
              securitySystems={customer?.facilities[0]?.securitySystems}
              currentPath={`${history.location.pathname}/facility/${facility?.id}`}
            />
          ) */}
    </>
  );
};

export default CustomerInformation;
