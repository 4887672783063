import { IsMobileHelper } from '../../Misc/IsMobileHelper';

export interface IFileResult {
  filename: string;
  blob: Blob;
}

export function openFile(file: IFileResult, download: boolean = false) {
  const anchor = document.createElement('a');
  anchor.href = (window.webkitURL || window.URL).createObjectURL(file.blob);

  if (!download && !checkMobileDevice()) {
    anchor.target = '_blank';
  } else {
    anchor.download = file.filename;
    anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
  }
  document.body.appendChild(anchor);
  setTimeout(() => {
    anchor.click();
    document.body.removeChild(anchor);
  }, 70);  
}

function checkMobileDevice(): boolean {
  return IsMobileHelper();
}
