import React, { useState } from 'react';
import MoreIcon from '@material-ui/icons/MoreVertRounded';
import { TableCell } from '@material-ui/core';
import PopperMenu from '../../Popper/Popper';
import { useStyles } from './DropdownCell.styles';

interface IDropdownProps {
  mobile?: boolean;
  children: React.ReactNode;
}

export const DropdownCell = (props: IDropdownProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return props.mobile ? (
    <PopperMenu
      setOpen={setOpen}
      open={open}
      aria-label="Öppna meny med alternativ för tabellraden"
      size="small"
      customContent
      content={props.children}
      neverDialog
    >
      <MoreIcon />
    </PopperMenu>
  ) : (
    <TableCell className={classes.root}>
      <PopperMenu
        setOpen={setOpen}
        open={open}
        aria-label="Öppna meny med alternativ för tabellraden"
        size="small"
        content={props.children}
      >
        <MoreIcon />
      </PopperMenu>
    </TableCell>
  );
};
