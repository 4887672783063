import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '0.25rem',
    marginBottom: '1rem',
  },
  title: {
    fontWeight: 600,
  },
  message: {
    backgroundColor: theme.palette.grey[100],
    marginBottom: '0.5rem',
    borderRadius: '0.25rem',
    padding: '0.25rem 0.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dateandtime: {
    display: 'flex',
    alignItem: 'flex-start',
    flexWrap: 'wrap',
    '& > *:first-child': {
      marginRight: '1rem',
      minWidth: 'auto',
    },
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
  },
  by: {
    textAlign: 'right',
    width: '100%',
  },
  actions: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
}));
