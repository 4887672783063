import { makeStyles, Theme } from '@material-ui/core';
import { Loading } from '../Button/Button.styles';

const useStyles = makeStyles((theme: Theme) => ({
  loading: {
    ...Loading,
  },
  main: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    padding: '0.37rem 1rem 0.4rem 1.5rem',
  },
  showMore: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    minWidth: 0,
    paddingLeft: '0.25rem',
    paddingRight: '0.5rem',
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    '&:disabled': {
      borderLeft: `1px solid ${theme.palette.grey[400]}`,
    },
  },
  popoverWrapper: {
    border: '1px solid #f5f5f5',
    padding: '1rem',
    '& button': {
      marginLeft: '8px',
      marginRight: '8px'
    }
  }
}));

export default useStyles;
