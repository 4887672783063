import React, { useState, useEffect, useCallback } from 'react';
import { TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { Table } from '../../../../../../Shared/Table/Table';
import useTable from '../../../../../../Shared/Table/useTable';
import IPaginatedResponse from '../../../../../../Shared/Api/response/IPaginatedResponse';
import TablePaginationWithDatasource from '../../../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import { getEntryDescription, ISystemLogEntryDto, SystemLogType, useGetSystemLog } from '../SystemLog.api';
import Datepicker from '../../../../../../Shared/InputFields/DatePicker/Datepicker';
import moment from 'moment';
import { formatDateWithTime } from '../../../../../../Shared/Formatting/formatDate';
import Select, { ISelectOption } from '../../../../../../Shared/Select/Select';
import CustomerSelect from '../../../../Customers/Components/CustomerSelect/CustomerSelect';
import { useAddCustomLog } from '../../../../Errands/Components/Sale.api';
import useInputState from '../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { ICustomSize } from '../../../../../../Shared/Container/Container';
import SearchInput from '../../../../../../Shared/InputFields/SearchInput/SearchInput';
import IconButton from '../../../../../../Shared/IconButton/IconButton';

const defaultFromDate = moment().startOf('month').toDate();
const defaultToDate = moment().startOf('day').toDate();

export interface ILogRow {
  id: number;
  date: Date;
  user: string | undefined;
  customer?: string;
  saleNumber?: string;
  todoId?: string;
  description: string;
  impersonator?: string;
  oldValues?: string;
  newValues?: string;
}

export interface ILogTypeOption extends ISelectOption {
  type: SystemLogType;
}

const logTypeOptions: ILogTypeOption[] = [
  { label: 'Kund', value: SystemLogType.Customer.toString(), type: SystemLogType.Customer },
  { label: 'Offert/Order', value: SystemLogType.Sale.toString(), type: SystemLogType.Sale },
  { label: 'Uppgifter', value: SystemLogType.Todo.toString(), type: SystemLogType.Todo },
];

export interface ISystemLogListProps {
  defaultLogType?: SystemLogType;
  lockLogType?: boolean;
  defaultEntityId?: number;
  lockCustomerId?: boolean;
  hideLabel?: boolean;
  customSize?: ICustomSize;
  disableFromAndToDate?: boolean;
  customPage?: number[];
}

const SystemLogList = (props: ISystemLogListProps) => {
  const { defaultLogType, lockLogType, defaultEntityId, lockCustomerId, hideLabel, customPage } = props;
  const { isLoading, systemLog, getSystemLog } = useGetSystemLog();
  const { pageSize, setQuery, setPageSize, setPageNumber, paginatedRequest } = useTable(
    customPage ? customPage[0] : undefined,
  );
  const { isLoading: isAddingLog, addCustomLog } = useAddCustomLog();
  const [logRows, setLogRows] = useState<ILogRow[]>([]);
  const [fromDate, setFromDate] = useState<Date | undefined>(props.disableFromAndToDate ? undefined : defaultFromDate);
  const [toDate, setToDate] = useState<Date | undefined>(props.disableFromAndToDate ? undefined : defaultToDate);
  const [logType, setLogType] = useState<SystemLogType | undefined>(defaultLogType ?? SystemLogType.Customer);
  const [entityId, setCustomerId] = useState<number | undefined>(defaultEntityId);
  const [customLogContent, changeCustomLogContent, setCustomLogContent] = useInputState('');
  const [emptyMessage, setEmptyMessage] = useState('');

  const loadList = useCallback(async () => {
    const response: IPaginatedResponse<ISystemLogEntryDto> = await getSystemLog({
      ...paginatedRequest,
      fromDate,
      toDate,
      type: logType,
      entityId: entityId,
    });

    if (response?.totalCount === 0) {
      setEmptyMessage('Finns inga loggningar i valt intervall');
    }
  }, [paginatedRequest, getSystemLog, fromDate, toDate, logType, entityId]);

  useEffect(() => {
    if (systemLog) {
      setLogRows(
        systemLog.items.filter((l) => l !== null && l.eventData !== undefined && l.eventData !== null).map((x) => ({
          id: x.id,
          date: x.dateTime,
          user: x.user,
          customer: x.eventData?.entityDescription,
          saleNumber: x.eventData?.entityDescription,
          todoId: x.eventData?.entityDescription,
          description: getEntryDescription(x),
          impersonator: x.impersonator,
        })),
      );
    } else {
      setLogRows([]);
    }
  }, [systemLog]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  useEffect(() => {
    setPageNumber(1);
  }, [setPageNumber, entityId]);

  const handleAddCustomLog = async () => {
    if (customLogContent && defaultEntityId) {
      await addCustomLog({ customLogContent, logType: defaultLogType }, defaultEntityId.toString());
      setCustomLogContent('');
      loadList();
    }
  };

  return (
    <Table
      label={!hideLabel && `Loggbok (${systemLog?.totalCount ?? 0})`}
      customSize={props.customSize}
      pagination={
        systemLog && (
          <TablePaginationWithDatasource
            datasource={systemLog}
            onChangePage={setPageNumber}
            onChangeRowsPerPage={setPageSize}
            addPageSize={customPage}
          />
        )
      }
      empty={
        systemLog && {
          empty: systemLog?.totalCount === 0 ? true : false,
          message: emptyMessage,
        }
      }
      filters={
        <>
          {!lockLogType && (
            <Select
              inputFieldProps={{ label: 'Typ' }}
              selectProps={{
                options: logTypeOptions,
                value: logTypeOptions.find((x) => x.type === logType),
                onChange: (option) => {
                  setLogType((option as ILogTypeOption).type);
                  setQuery('');
                },
              }}
            />
          )}
          {logType === SystemLogType.Todo && (
            <SearchInput
              style={{ marginTop: '1.5rem' }}
              type="search"
              placeholder="Sök"
              onChange={(search) => setQuery(search)}
            />
          )}
          {!lockCustomerId && logType !== SystemLogType.Todo && (
            <CustomerSelect
              isClearable
              onChange={(customerId) => setCustomerId(customerId ? Number(customerId) : undefined)}
            />
          )}
          {!props.disableFromAndToDate && (
            <>
              <Datepicker
                required
                label="Visa från"
                inFilter
                selected={fromDate}
                onChange={(d: Date | null) => setFromDate(d ?? undefined)}
              />

              <Datepicker
                required
                label="Visa till"
                inFilter
                selected={toDate}
                onChange={(d: Date | null) => setToDate(d ?? undefined)}
              />
            </>
          )}
        </>
      }
      loading={{
        loading: isLoading,
        skeletonRows: pageSize,
      }}
      actions={
        defaultEntityId && (
          <>
            <InputField value={customLogContent} onChange={changeCustomLogContent} fullwidth/>
            <IconButton
                color="primary"
                onClick={handleAddCustomLog}
                disabled={isAddingLog}
                aria-label="Lägg till rad"
                >
              <AddIcon />
            </IconButton>
          </>
        )
      }
    >
      <>
        <TableHead>
          <TableRow>
            <TableCell>Tid</TableCell>
            <TableCell>Användare</TableCell>
            {!lockCustomerId && logType === SystemLogType.Customer && <TableCell>Kund</TableCell>}
            {logType === SystemLogType.Sale && !defaultEntityId && <TableCell>Offert/Order-nr</TableCell>}
            {logType === SystemLogType.Todo && !defaultEntityId && <TableCell>Uppgift id</TableCell>}
            <TableCell>Händelse</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logRows?.map((log) => (
            <TableRow key={log.id}>
              <TableCell>{formatDateWithTime(log.date)}</TableCell>
              {log.impersonator ? (
                <TableCell>{log.impersonator + ` (som ${log.user})`}</TableCell>
              ) : (
                <TableCell>{log.user}</TableCell>
              )}
              {!lockCustomerId && logType === SystemLogType.Customer && <TableCell>{log.customer}</TableCell>}
              {logType === SystemLogType.Sale && !defaultEntityId && <TableCell>{log.saleNumber}</TableCell>}
              {logType === SystemLogType.Todo && !defaultEntityId && <TableCell>{log.todoId}</TableCell>}
              <TableCell>{log.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </>
    </Table>
  );
};

export default SystemLogList;
