import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Tooltip, Typography } from '@material-ui/core';
import { MobileHeader } from './MobileHeader/MobileHeader';
import useHeaderNavigationContext from './HeaderNavigationContext/HeaderNavigationContext';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core';
import { useStyles } from './Header.styles';
import { Nameday } from './Nameday/Nameday';
import { Logotype } from './Logotype/Logotype';
import { InfoOutlined } from '@material-ui/icons';
import AccessGuard from '../../../Core/Authentication/AccessGuard/AccessGuard';
import SettingsIcon from '@material-ui/icons/SettingsRounded';
import { ModuleIdentifiers } from '../../../Core/Authentication/ModuleAccess';
import { UserType } from '../../../AuthApp/Modules/Admin/Components/Users/Users.api';
import { AddtionalHeaderImage } from './AdditionalHeaderImage/AdditionalHeaderImage';

interface IHeaderProps {
  userType: UserType | null;
  userDropDownMenu?: React.ReactNode;
  companyName?: string;
  displayAdditionalImage: boolean;
  bannerText: string | undefined;
}
export interface INavigationItem {
  to: string;
  title: string;
  exact?: boolean;
}

export const Header = (props: IHeaderProps) => {
  const classes = useStyles();
  const open = false;
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const { headerNavigationItems, headerNavigationText } = useHeaderNavigationContext();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const location = useLocation().pathname;

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  const isActivityWindow = !location.includes('activitywindow');
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const headerText = () =>
    headerNavigationText.length !== 0
      ? headerNavigationText.find((x) => new RegExp(x.to).test(location))
      : headerNavigationItems.find((x) => new RegExp(x.to).test(location));

  return (
    <>
      {isActivityWindow ? (
        <>
          {!mobile && (
          <header className={classes.root}>
            <nav aria-label="Modulspecifik navigering">
              <div className={`${classes.nav} ${mobile ? classes.mobile : ''}`}>
                <div className={classes.logotypeLinkNameDay}>
                  <Logotype />
                  <Nameday />
                </div>
                <div className={classes.textContainer}>
                  {location === '/' && props.userType ? (
                    props.displayAdditionalImage ? (
                      <AddtionalHeaderImage />
                    ) : (props.bannerText && props.bannerText !== "") ? <Typography variant="h1">{props.bannerText}</Typography> : (props.companyName ? (
                      <Typography variant="h1">{`Välkommen till ${props.companyName} affärssystem!`}</Typography>
                    ) : (
                      <Typography variant="h1">{`Admin`}</Typography>
                    ))
                  ) : (
                    <Typography variant="h1">
                      {headerText()?.title ?? 'MAXQ - Att arbeta rätt sparar tid och pengar'}
                    </Typography>
                  )}
                </div>

                <div className={classes.userDropDownMenu}>
                  {props.userType && (
                    <Tooltip className={classes.iIcon} title={'MAXQ - Att arbeta rätt sparar tid och pengar'}>
                      <InfoOutlined />
                    </Tooltip>
                  )}
                  <AccessGuard module={ModuleIdentifiers.Admin}>
                    <NavLink
                      exact={false}
                      to={'/admin'}
                      activeClassName={classes.active}
                      className={classes.navigationIcon}
                    >
                      <div className={classes.icon} data-testid="icon">
                        <SettingsIcon aria-hidden="true" />
                      </div>
                    </NavLink>
                  </AccessGuard>
                  {props.userDropDownMenu}
                </div>
              </div>
            </nav>
          </header>
          )}

          {location !== '/' && (
            <header className={classes.root}>
              <nav aria-label="Modulspecifik navigering">
                <div className={`${classes.nav} ${mobile ? classes.mobile : ''}`}>
                  {!mobile ? (
                    <ul className={classes.list}>
                      {headerNavigationItems?.map((link: INavigationItem) => (
                        <li key={link.title} className={classes.listItem}>
                          <NavLink
                            exact={link.exact}
                            to={link.to}
                            className={classes.link}
                            activeClassName={classes.active}
                          >
                            <Typography variant="h3" component="div">
                              {link.title}
                            </Typography>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <MobileHeader links={headerNavigationItems} />
                  )}
                </div>
              </nav>
            </header>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
