import React from 'react';
import { ButtonProps } from '@material-ui/core/Button/Button';
import {
  CircularProgress,
  Button as MuiButton,
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import useStyles from './MultiButton.styles';

export interface IButtonProps extends ButtonProps {
  loading?: boolean;
  menuItems?: React.ReactNode;
  onClick?: () => void;
}

const MultiButton = (props: IButtonProps) => {
  const { loading, children, disabled, menuItems, onClick, ...rest } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const classes = useStyles();
  return (
    <>
      <div>
        <MuiButton className={classes.main} {...rest} disabled={loading || disabled} onClick={(onClick) ? onClick : handleToggle}>
          {loading ? (
            <CircularProgress aria-label="Laddar" disableShrink className={classes.loading} size="1.25rem" />
          ) : (
            children
          )}
        </MuiButton>
        <MuiButton
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          aria-label="Visa fler alternativ"
          className={classes.showMore}
          color="primary"
          variant="contained"
          disabled={loading || disabled}
        >
          <KeyboardArrowDown />
        </MuiButton>
      </div>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 3}}>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList className={classes.popoverWrapper} autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {menuItems}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default MultiButton;
