import { useApiPost, useApiGet, useApiDelete, useApiPut } from '../../../../../Core/Api/Api';
import IPaginatedResponse from '../../../../../Shared/Api/response/IPaginatedResponse';
import IPaginatedRequest from '../../../../../Shared/Api/request/IPaginatedRequest';
import { useCallback } from 'react';
import { SaleProductStatus } from '../Sale.api';

export enum SupplierOrderStatus {
  Created,
  ConfirmedBySupplier,
  PartiallyReceived,
  Received
}

export const supplierOrderStatus = {
  [SupplierOrderStatus.Created]: 'Order lagd',
  [SupplierOrderStatus.ConfirmedBySupplier]: 'Bekräftad av leverantör',
  [SupplierOrderStatus.PartiallyReceived]: 'Delvis mottagen',
  [SupplierOrderStatus.Received]: 'Mottagen',
};

export interface IProduct {
  saleProductId: number;
  name: string;
  productNumber: number;
  description: string;
  status: SaleProductStatus;
  serialNumber: string;
  delivered: boolean;
}

export interface ICombinedSupplierProduct extends IProduct {
  supplierName: string;
  supplierMessage?: string;
  supplierOrderId?: number;
  messageToPurchaser?: string;
  receivedDate?: Date;
  confirmedDate?: Date;
  deliveryDate?: Date;
  supplierOrderNumber?: string;
  storagePlacement?: string;
}

export interface ISupplierOrder {
  id: number;
  saleId?: number;
  salesNumber?: number;
  saleIdentifier?: string;
  customerId?: number;
  supplierId: number;
  supplierName: string;
  customerName: string;
  deliveryMarking: string;
  created: Date;
  preferredDeliveryDate: Date;
  confirmedDate?: Date;
  deliveryDate?: Date;
  receivedDate?: Date;
  status?: SupplierOrderStatus;
  message: string;
  messageToPurchaser: string;
  storagePlacement?: string;
  products: IProduct[];
  contactPerson: IContactPerson | undefined;
  supplierOrderNumber?: string;
}

interface IContactPerson {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
}

export function useGetSupplierOrder() {
  const { isLoading, response, get } = useApiGet<ISupplierOrder>('/api/supplierorders/', false);

  return {
    isLoading,
    supplierOrder: response,
    getSupplierOrder: get,
  };
}

export function useGetCombinedSupplierOrderBySaleId() {
  const { isLoading, response, get } = useApiGet<Array<ISupplierOrder>>('/api/supplierorders/combinedorder/', false);

  return {
    isLoading,
    combinedSupplierOrder: response,
    getCombinedSupplierOrderBySaleId: get,
  };
}

export interface ISupplierListItem {
  id: number;
  salesNumber: number;
  customerName: string;
  supplierName: string;
  created: Date;
  status: SupplierOrderStatus;
  deliveryDate?: Date;
  expectedDeliveryDate?: Date;
  storagePlacement?: string;
  receivedDate?: Date;
  supplierOrder?: string;
}

export interface ICombinedSupplierListItem {
  id: number;
  salesNumber: number;
  saleId: number;
  customerName: string;
  supplierNames: Array<string>;
  created: Date;
  statuses: Array<SupplierOrderStatus>;
  deliveryDates: Array<Date>;
  expectedDeliveryDates: Array<Date>;
  storagePlacement?: string;
  receivedDates: Array<Date>;
  supplierOrder?: string;
}

export function useGetSupplierOrders() {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<ISupplierListItem>, IPaginatedRequest>(
    '/api/supplierorders/list',
  );

  return {
    isLoading,
    supplierOrders: response,
    getSupplierOrders: post,
  };
}

export function useGetCombinedSupplierOrders() {
  const { isLoading, response, get } = useApiGet<Array<ICombinedSupplierListItem>> (
    '/api/supplierorders/listcombined',
  );

  return {
    isLoading,
    supplierOrders: response,
    getCombinedSupplierOrders: get,
  };
}

export function useDeleteSupplierOrder() {
  const { isLoading, delete: deleteRequest } = useApiDelete(`/api/supplierorders/`);

  const deleteSupplierOrder = useCallback(
    async (id: string) => {
      return await deleteRequest(undefined, id);
    },
    [deleteRequest],
  );

  return {
    isLoading,
    deleteSupplierOrder,
  };
}

export interface IMarkSupplierOrderDeliveredRequest {
  storagePlacement?: string;
  markedProductIds: MarkedProductId[];
}

export interface MarkedProductId {
  saleProductId: number;
  serialNumber: string | undefined;
  delivered: boolean;
}

export function useMarkSupplierOrderDelivered(supplierOrderSaleId: string) {
  const { isLoading, put } = useApiPut<IMarkSupplierOrderDeliveredRequest>(
    `/api/supplierorders/${supplierOrderSaleId}/delivered`,
  );

  return {
    isLoading,
    put,
  };
}
