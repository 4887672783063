import { useApiPost, useApiGet, useApiPut, useApiDelete } from '../../../../../Core/Api/Api';
import ValidationErrorResultType from '../../../../../Shared/Api/response/ValidationErrorResultType';
import IPaginatedRequest from '../../../../../Shared/Api/request/IPaginatedRequest';
import IPaginatedResponse from '../../../../../Shared/Api/response/IPaginatedResponse';
import { useCallback } from 'react';

export interface IUpsertProductCategoryBody {
  name: string;
}

export function useUpsertProductCategory() {
  const { isLoading: isLoadingPost, post } = useApiPost<
    IUpsertProductCategoryValidationErrors,
    IUpsertProductCategoryBody
  >('/api/productcategories');
  const { isLoading: isLoadingPut, put } = useApiPut<
    IUpsertProductCategoryValidationErrors,
    IUpsertProductCategoryBody
  >('/api/productcategories/');

  async function upsert(body: IUpsertProductCategoryBody, id?: string) {
    if (id) {
      return await put(body, id);
    }

    return await post(body);
  }

  return {
    isLoading: isLoadingPost || isLoadingPut,
    upsertProductCategory: upsert,
  };
}

export interface IUpsertProductCategoryValidationErrors extends ValidationErrorResultType<IUpsertProductCategoryBody> {}

export interface IProductCategory {
  id: number;
  name: string;
  sortOrder?: number;
}

export function useGetProductCategories() {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<IProductCategory>, IPaginatedRequest>(
    '/api/productcategories/list',
  );

  return {
    isLoading,
    response,
    getProductCategories: post,
  };
}

export function useGetProductCategory() {
  const { isLoading, response, get } = useApiGet<IProductCategory>('/api/productcategories/', false);

  return {
    isLoading,
    response,
    getProductCategory: get,
  };
}

export function useDeleteProductCategory() {
  const { isLoading, delete: deleteRequest } = useApiDelete(`/api/productcategories/`);

  const deleteProductCategory = useCallback(
    async (productCategoryId: string) => {
      return await deleteRequest(undefined, productCategoryId);
    },
    [deleteRequest],
  );

  return {
    isLoading,
    deleteProductCategory,
  };
}

export function useMoveUp() {
  const { isLoading, post } = useApiPost('/api/productcategories/');

  const moveUp = useCallback(
    async (id: number) => {
      await post(undefined, id + '/up');
    },
    [post],
  );

  return {
    isLoading,
    moveUp,
  };
}

export function useMoveDown() {
  const { isLoading, post } = useApiPost('/api/productcategories/');

  const moveDown = useCallback(
    async (id: number) => {
      await post(undefined, id + '/down');
    },
    [post],
  );

  return {
    isLoading,
    moveDown,
  };
}
