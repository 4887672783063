import React from 'react';
import { useStyles } from './DataList.styles';

interface IDatalistProps {
  ariaLabelledBy?: string;
  removeBorder?: boolean;
}

const DataList: React.FC<IDatalistProps> = (props) => {
  const classes = useStyles();

  return (
    <dl
      className={`${classes.datalist} ${props.removeBorder ? '' : classes.border}`}
      aria-labelledby={props.ariaLabelledBy}
    >
      {props.children}
    </dl>
  );
};

export default DataList;
