import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  poppupText: {
    padding: '10px',
    margin: '10px',
  },

  message: {
    fontWeight: 'bold',
    marginBottom: '5px',
  },

  list: {
    marginLeft: '5px',
  },
  font: {
    fontWeight: 700,
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  pointerCursor: {
    cursor: 'pointer',
  },
  narrowColumn: {
    width: '15px',
  },
  installedRow: {
    backgroundColor: theme.palette.success.light,
  }
}));

export default useStyles;
