import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/styles';
import { pdfSize } from '../../Offer.api';

export const pdfInInput = `calc(${pdfSize}cm + 1.8rem)`;

export const forPdf = {
  '& textarea': {
    fontFamily: 'sans-serif',
    fontSize: '12px',
    lineHeight: '15px',
    overflowY: 'scroll',
    height: '37rem',
  },
} as CSSProperties;

export const fields = {
  maxWidth: pdfInInput,
  width: '100%',
  '@media (min-width: 855px)': {
    width: pdfInInput,
  },
} as CSSProperties;

const useStyles = makeStyles(() => ({
  forPdf: {
    ...forPdf,
  },
  fields: {
    ...fields,
  },
}));

export default useStyles;
