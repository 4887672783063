import { useApiPost, useApiGet, useApiPut, useApiDelete } from '../../../../../../Core/Api/Api';
import IPaginatedResponse from '../../../../../../Shared/Api/response/IPaginatedResponse';
import IPaginatedRequest from '../../../../../../Shared/Api/request/IPaginatedRequest';
import ValidationErrorResultType from '../../../../../../Shared/Api/response/ValidationErrorResultType';

export interface ICompanyBranch {
  id: number;
  name: string;
  hasCompanyUsers: boolean;
}

export function useGetCompanyBranches() {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<ICompanyBranch>, IPaginatedRequest>(
    '/api/companies/branch/list',
  );

  return {
    isLoading,
    response,
    getCompanyBranches: post,
  };
}

export function useGetCompanyBranch() {
  const { isLoading, response, get } = useApiGet<ICompanyBranch>('/api/companies/branch/', false);

  return {
    isLoading,
    response,
    getCompanyBranch: get,
  };
}

export interface IUpsertCompanyBranchCommand {
  name: string;
}
export interface IUpsertCompanyBranchValidationErrors extends ValidationErrorResultType<IUpsertCompanyBranchCommand> {}
export function useUpsertCompanyBranch() {
  const { isLoading: isCreateLoading, post } = useApiPost<
    IUpsertCompanyBranchValidationErrors,
    IUpsertCompanyBranchCommand
  >('/api/companies/branch');
  const { isLoading: isUpdateLoading, put } = useApiPut<
    IUpsertCompanyBranchValidationErrors,
    IUpsertCompanyBranchCommand
  >('/api/companies/branch/');

  async function upsert(command: IUpsertCompanyBranchCommand, id?: string) {
    if (id) {
      const result = await put(command, id);
      return result ? result : id;
    }

    return post(command);
  }

  return {
    isLoading: isCreateLoading || isUpdateLoading,
    upsert: upsert,
  };
}

export function useDeleteCompanyBranch() {
  const { isLoading, delete: deleteRequest } = useApiDelete('/api/companies/branch/');

  function deleteCompanyBranch(id: number) {
    return deleteRequest(undefined, id.toString());
  }

  return {
    isLoading,
    deleteCompanyBranch,
  };
}
