import React, { useCallback, useEffect, useState } from 'react';
import { InputField } from '../../../../../../../../../Shared/InputFields/InputField/InputField';
import { IDocumentFormProps } from '../../IDocumentFormProps';
import { useCreateLetterDocument } from '../../Documents.api';
import useInputState from '../../../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import moment from 'moment';
import Datepicker from '../../../../../../../../../Shared/InputFields/DatePicker/Datepicker';
import { isResultError } from '../../../../../../../../../Shared/Api/response/ICreateResult';

const Letter = (props: IDocumentFormProps) => {
  const { customerId, onLoadingChange, saveCallback, onDocumentCreated, customerInfo } = props;
  const { isLoading: isCreateLoading, createLetterDocument } = useCreateLetterDocument(customerId);
  const [date, setDate] = useState<Date>(moment().startOf('day').toDate());
  const [companyReference, changeCompanyReference] = useInputState('');
  const [customerReference, changeCustomerReference] = useInputState('');
  const [letterText, changLetterText] = useInputState('');

  //TODO get correct references let empty during init for now
  //const { getLetterDocumentForm, isLoading } = useGetLetterDocumentForm(customerId);

  // useEffect(() => {
  //   async function loadForm() {
  //     const form = await getLetterDocumentForm();

  //     //setCompanyReference(form.companyReference);
  //     //setCustomerReference(form.customerName);
  //   }
  //   loadForm();
  // }, [
  //   getLetterDocumentForm,
  //   setCompanyReference,
  //   setCustomerReference,
  // ]);

  useEffect(() => {
    onLoadingChange(isCreateLoading);
  }, [onLoadingChange, isCreateLoading]);

  const save = useCallback(() => {
    async function innerSave() {
      const result = await createLetterDocument({
        date: date,
        customerReference: customerReference,
        companyReference: companyReference,
        letterText: letterText,
        facilityId: props.facilityId
      });
      if (!isResultError(result)) onDocumentCreated(Number(result as string));
    }
    innerSave();
  }, [createLetterDocument, date, customerReference, companyReference, letterText, onDocumentCreated, props.facilityId]);

  useEffect(() => {
    saveCallback(save);
  }, [saveCallback, save]);

  return (
    <>
      <InputField label="Referensnummer" viewOnly type="text" value={customerInfo?.letterSerialNumber} />
      <Datepicker label="Datum" required selected={date} onChange={(d: Date | null) => setDate(d as Date)} />
      <InputField
        label="Vår referens"
        required
        type="text"
        value={companyReference}
        onChange={changeCompanyReference}
      />
      <InputField
        label="Er referens"
        required
        type="text"
        value={customerReference}
        onChange={changeCustomerReference}
      />
      <InputField
        label="Skriv brev nedan"
        fullwidth
        type="text"
        required
        multiline
        rows={6}
        value={letterText}
        onChange={changLetterText}
      />
    </>
  );
};

export default Letter;
