import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Datepicker from '../../../../../../../Shared/InputFields/DatePicker/Datepicker';
import Container from '../../../../../../../Shared/Container/Container';
import { InputField } from '../../../../../../../Shared/InputFields/InputField/InputField';
import Button from '../../../../../../../Shared/Button/Button';
import useFormValidation from '../../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import { isResultError } from '../../../../../../../Shared/Api/response/ICreateResult';
import { IAddCostCommandValidationErrors, IAddCostCommand, useAddSalesCost } from '../../Order.api';
import useInputState from '../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { SupplierSelect } from '../../../../../Admin/Components/Suppliers/SupplierSelect/SupplierSelect';
import { useGetSaleMisc } from '../../../Sale.api';

const AddCostForm = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { isLoading, getSaleMisc: getSaleOther } = useGetSaleMisc();
  const { isFormValid, formRef } = useFormValidation();
  const { isCreateLoading, create } = useAddSalesCost(id);
  const [date, setDate] = useState<Date | null>(new Date());
  const [costIn, setCostIn] = useInputState<number>(0);
  const [costOut, setCostOut] = useInputState<number>(0);
  const [description, setDescription] = useInputState<string>('');
  const [selectedSupplierId, setSelectedSupplierId] = useState<string>();
  const [errors, setErrors] = useState<IAddCostCommandValidationErrors>();

  useEffect(() => {
    const loadOrder = async () => {
      await getSaleOther(id);
    };

    loadOrder();
  }, [getSaleOther, id]);

  async function createCost() {
    const command: IAddCostCommand = {
      date: date as Date,
      costIn: costIn,
      costOut: costOut,
      description: description,
      supplierId: selectedSupplierId ?? '',
    };

    const result = await create(command);

    isResultError(result) ? setErrors(result) : history.push(`/errands/invoice/${id}`);
  }

  const valid = isFormValid && selectedSupplierId;

  return (
    <Container
      form
      ref={formRef}
      loading={isLoading}
      actions={
        <>
          <Button
            color="default"
            variant="outlined"
            disabled={isCreateLoading}
            onClick={() => history.push(`/errands/invoice/${id}`)}
          >
            Avbryt
          </Button>
          <Button
            color="primary"
            variant="contained"
            loading={isCreateLoading}
            disabled={!valid}
            onClick={createCost}
          >
            Lägg till
          </Button>
        </>
      }
      label="Kostnad"
    >
      <Datepicker label="Datum" selected={date} onChange={setDate} required />
      {!isLoading && (
        <SupplierSelect label={'Leverantör'} onChange={setSelectedSupplierId} selectedSupplierId={selectedSupplierId} />
      )}
      <InputField
        label="Summa in"
        type="number"
        required
        value={costIn}
        endAdornment="SEK"
        onChange={setCostIn}
        errorText={errors?.costIn}
      />
      <InputField
        label="Summa ut"
        type="number"
        required
        value={costOut}
        endAdornment="SEK"
        onChange={setCostOut}
        errorText={errors?.costOut}
      />
      <InputField
        label="Beskrivning"
        type="text"
        required
        value={description}
        onChange={setDescription}
        errorText={errors?.description}
      />
    </Container>
  );
};
export default AddCostForm;
