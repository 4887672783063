import React, { useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import useUserContext from '../../../../../Core/Authentication/UserContext';
import { ISelectOption } from '../../../../../Shared/Select/Select';
import { InputField } from '../../../../../Shared/InputFields/InputField/InputField';
import Datepicker from '../../../../../Shared/InputFields/DatePicker/Datepicker';
import Button from '../../../../../Shared/Button/Button';
import { DialogContentHeader } from '../../../../../Shared/DialogContentHeader/DialogContentHeader';
import DialogForm from '../../../../../Shared/DialogForm/DialogForm';
import useInputState from '../../../../../Shared/Hooks/UseInputState/UseInputState';
import CompanyUserSelect from '../../../../Modules/Admin/Components/Users/UserSelect/CompanyUserSelect';
import OrderSelect from '../../../../Modules/Errands/Components/Order/OrderSelect/OrderSelect';
import { IActivity } from '../ActivityForm/ActivityForm';
import { CalendarEntryType } from '../../../../Modules/Booking/Booking.api';
import { SaleStatus } from '../../../../Modules/Errands/Components/Sale.api';
import { addTimeToDate, validTime } from '../dateFunctions';
import { useStyles } from './BookOnOrder.styles';
import { Fieldset } from '../../../../../Shared/Form/Fieldset/Fieldset';

const defaultDate = new Date();
const filterStatus = [SaleStatus.approved, SaleStatus.installationBooked];

export interface IBookOnOrderProps {
  title: string;
  saleId?: number;
  customerId?: number;
  jobDescription?: string;
  selectUser?: boolean;
  useCurrentUser?: boolean;
  isLoading?: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSave?: (activity: IActivity) => void;
}

const BookOnOrder = (props: IBookOnOrderProps) => {
  const classes = useStyles();
  const { onSave, useCurrentUser, setOpen, open, title } = props;
  const [selectedInstaller, setSelectedInstaller] = useState<ISelectOption>();
  const [orderId, setOrderId] = useState<string | undefined>(props.saleId?.toString());
  const [jobDescription, changeJobDescription] = useInputState<string>(props.jobDescription ?? '');
  const [startDate, setStartDate] = useState<Date>(defaultDate);
  const [endDate, setEndDate] = useState<Date>(defaultDate);
  const [startTime, changeStartTime] = useInputState<string>('00:00');
  const [endTime, changeEndTime] = useInputState<string>('00:00');
  const { user } = useUserContext();
  const titleWithoutSpaces = title.replace(/\s/g, '');

  if (useCurrentUser && !selectedInstaller) {
    const currentUser = { value: user.selectedUserId?.toString() ?? '', label: user.username };
    setSelectedInstaller(currentUser);
  }

  function handleSave() {
    const newActivity = {
      companyUserIds: selectedInstaller ? [Number(selectedInstaller.value)] : undefined,
      startDate: addTimeToDate(startDate, startTime),
      endDate: addTimeToDate(endDate, endTime),
      type: CalendarEntryType.Sale,
      description: jobDescription,
      jobDescription,
      showInCompanyCalendar: false,
      customerId: props.customerId ? props.customerId : undefined,
      saleId: orderId ? Number(orderId) : undefined,
    };
    onSave && onSave(newActivity);
    setOpen(false);
  }

  /* function changeDate(d: Date | null) {
    d && setDate(d);
  } */

  function valid() {
    return selectedInstaller && jobDescription && bookTimeValid();
  }

  function bookTimeValid() {
    return (
      startDate &&
      endDate &&
      startDate &&
      validTime(startTime) &&
      validTime(endTime) &&
      addTimeToDate(endDate, endTime) >= addTimeToDate(startDate, startTime)
    );
  }

  const Actions = () => (
    <>
      <Button color="default" variant="outlined" onClick={() => setOpen(false)}>
        Avbryt
      </Button>
      <Button className={classes.save} color="primary" variant="contained" disabled={!valid()} onClick={handleSave}>
        Spara
      </Button>
    </>
  );

  return (
    <Dialog open={open} onClose={props.setOpen} aria-labelledby={titleWithoutSpaces}>
      <DialogContent>
        <DialogContentHeader onClose={() => setOpen(false)} title={title} titleId={titleWithoutSpaces} />

        <DialogForm actions={<Actions />}>
          <CompanyUserSelect
            label="Installatör"
            isClearable
            required
            placeholder="Installatör"
            selectedCompanyUser={selectedInstaller}
            onChange={(_1, _2, option) => setSelectedInstaller(option ? (option as ISelectOption) : undefined)}
          />
          {!props.saleId && (
            <OrderSelect
              fullwidth
              required
              label="Order"
              status={filterStatus}
              selectedOrderId={orderId}
              onChange={setOrderId}
            />
          )}          
          <Fieldset legend="Datum">
            <Datepicker
              label="Start"
              selected={startDate}
              onChange={(d: Date | null) => {
                setStartDate(d as Date);
                if (d && d > endDate) {
                  setEndDate(d as Date);
                }
              }}
            />
            <InputField
              label={"Starttid"}
              type="time"
              value={startTime}
              onChange={changeStartTime}
            />
            <Datepicker
              label="Slut"
              selected={endDate}
              minDate={startDate}
              onChange={(d: Date | null) => {
                setEndDate(d as Date);
              }}
            />
            <InputField
              label={"Sluttid"}
              type="time"
              value={endTime}
              onChange={changeEndTime}
            />
          </Fieldset>
          <InputField
            required
            label="Uppdragsbeskrivning"
            fullwidth
            type="text"
            multiline
            rows="5"
            value={jobDescription}
            onChange={changeJobDescription}
          />
        </DialogForm>
      </DialogContent>
    </Dialog>
  );
};

export default BookOnOrder;
