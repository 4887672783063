import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import CompanySelect from './Components/CompanySelect/CompanySelect';
import CustomerProfileRoutes from './Components/CustomerProfile/CustomerProfile.routes';

const CustomerPortalRoutes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <CompanySelect />
      </Route>
      <Route path={`/:companyId/:customerId`}>
        <CustomerProfileRoutes />
      </Route>
      <Route path="/*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default CustomerPortalRoutes;
