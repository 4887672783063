import React from 'react';
import { Fieldset } from '../../../../../../../Shared/Form/Fieldset/Fieldset';
import { InputField } from '../../../../../../../Shared/InputFields/InputField/InputField';
import {
  measures,
  services,
  auditIntervals,
  rentTypes,
  transferTypes,
  transfersIntervals,
  ISale,
} from '../../../Sale.api';
import { SaleTypeLabel, SaleType } from '../../../../../../Shared/SaleType';
import formatPrice from '../../../../../../../Shared/Formatting/formatPrice';
import formatDate from '../../../../../../../Shared/Formatting/formatDate';
import { getCustomerTypeName } from '../../../../../../../Shared/Misc/CustomerType';
import useStyles from './OfferSummary.styles';
import { ViewField } from '../../../../../../../Shared/InputFields/InputField/ViewField';
import { AccessRights, ModuleIdentifiers } from '../../../../../../../Core/Authentication/ModuleAccess';
import Link from '../../../../../../../Shared/Link/Link';
import Grid from '@material-ui/core/Grid';
import { creditCheckOutcomeText } from '../../../../../Customers/Customers.api';
import DownloadButton from '../../../../../../../Shared/DownloadButton/DownloadButton';
import { useGetDocument } from '../../../../../Customers/Components/ViewCustomer/Documents/Components/Documents.api';

export interface IOfferSummaryProps extends ISale {
  addToExistingSystem: boolean;
}

export default function OfferSummary(props: IOfferSummaryProps) {
  const { isLoading: isGettingDocument, getDocument } = useGetDocument(props.customer?.id.toString());

  const classes = useStyles();

  return (
    <>
      <Fieldset legend="Kund" customSize={{ md: 6, lg: 6 }}>
        {props.customer?.id && (
          <>
            <ViewField label="Kund nr">
              <Link
                to={`/customers/${props.customer?.id}`}
                module={ModuleIdentifiers.Customer}
                accessRights={AccessRights.Read}
              >
                {props.customer?.customerNumber}
              </Link>
            </ViewField>
            <ViewField label="Kund">
              <Link
                to={`/customers/${props.customer?.id}`}
                module={ModuleIdentifiers.Customer}
                accessRights={AccessRights.Read}
              >
                {props.customer?.name}
              </Link>
            </ViewField>
          </>
        )}
        <InputField label="Person/Org nr" type="text" value={props.customer?.identificationNumber} viewOnly />
        <InputField label="Kundtyp" type="text" value={getCustomerTypeName(props.customer?.type as number)} viewOnly />
        <InputField
          label="Senaste kontroll"
          value={
            props.customer?.creditCheck ? formatDate(props.customer?.creditCheck.date) : 'Ingen kontroll har utförts'
          }
          viewOnly
        />
        <InputField
          label="Bedömning"
          value={
            creditCheckOutcomeText.find((x) => x.value === props.customer?.creditCheck?.status)?.label ||
            'Ingen kontroll har utförts'
          }
          viewOnly
        />
        {!!props.customer?.creditCheck && (
          <DownloadButton
            customWidth={6}
            loading={isGettingDocument}
            onClick={() => getDocument(props.customer?.creditCheck?.documentId.toString() ?? undefined)}
          >
            Visa upplysning
          </DownloadButton>
        )}
      </Fieldset>
      <Fieldset legend="Allmänt" customSize={{ md: 6, lg: 6 }}>
        <InputField label="Offerttyp" type="text" value={SaleTypeLabel[props.type]} viewOnly />
        <InputField label="Projektledare" type="text" value={props.projectLeader?.name} viewOnly />
        <InputField fullwidth label="Offert rubrik" type="text" value={props.description} viewOnly />
      </Fieldset>
      <Fieldset legend="Beställare">
        <ViewField label={'Namn'}>
          <Link
            to={`/customers/${props?.customer.id}/contact/${props?.contactPerson?.id}`}
            module={ModuleIdentifiers.Customer}
            accessRights={AccessRights.Read}
          >
            {props?.contactPerson?.name ?? props?.customer.name}
          </Link>
        </ViewField>
        <ViewField label={'Email'} value={props?.contactPerson?.email ?? props?.customer.email} />
        <ViewField label={'Telefon'} value={props?.contactPerson?.phoneNumber ?? props?.customer.phoneNumber} />
      </Fieldset>
      <Grid xs item>
        <div className={classes.forPdf}>
          <InputField
            label="Förklarande text på offert"
            type="text"
            multiline
            fullWidth
            rows="5"
            value={props.saleDescription}
            viewOnly
          />
        </div>
      </Grid>
      <Fieldset alignment="flex-start">
        {props.type === SaleType.system && (
          <Fieldset legend="System"  customSize={{ md: 12, lg: 12 }}>
            {props.addToExistingSystem ? (
              <InputField label="Komplement till system" type="text" value={props.coreSystem?.description} viewOnly />
            ) : (
              <InputField label="System" type="text" value={props.coreSystem?.description ?? props.saleProducts.find((p) => p.isMainProduct)?.name} viewOnly />
            )}
            <InputField label="Systemtyp" type="text" value={props.coreSystem?.systemTypeName ?? props.systemTypeName} viewOnly />
            <InputField label="Regelverk" type="text" value={props.coreSystem?.regulationName ?? props.systemTypeRegulationName} viewOnly />
          </Fieldset>
        )}
        <InputField label="Projektör" type="text" value={props.projector?.name ?? ''} viewOnly />
        {props.saleAgreement && (
          <Fieldset legend="Avtal"  customSize={{ md: 12, lg: 12 }}>
            <InputField
              label="Månadsavgift"
              type="text"
              value={formatPrice(props.saleAgreement.monthlyCost)}
              viewOnly
            />
            <InputField label="Avtalsnummer" type="text" value={props.saleAgreement.agreementNumber} viewOnly />
            <InputField label="Datum från" type="text" value={formatDate(props.saleAgreement.startDate)} viewOnly />
            <InputField
              label="Datum till"
              type="text"
              value={props.saleAgreement.endDate ? formatDate(props.saleAgreement.endDate) : 'Tillsvidare'}
              viewOnly
            />
            <InputField
              label="Larmcentral"
              type="text"
              value={props.saleAgreement.emergencyCallCenterSupplierName}
              viewOnly
            />
            <InputField
              label="Vaktbolag"
              type="text"
              value={props.saleAgreement.securityCompanySupplierName}
              viewOnly
            />
            <InputField
              label="Åtgärd"
              type="text"
              value={measures.find((x) => x.type === props.saleAgreement?.emergencyCallCenterSupplierId)?.name}
              viewOnly
            />
            <InputField
              label="Service"
              type="text"
              value={services.find((x) => x.type === props.saleAgreement?.service)?.name}
              viewOnly
            />
            <InputField
              label="Revision"
              type="text"
              value={auditIntervals.find((x) => x.type === props.saleAgreement?.auditInterval)?.name}
              viewOnly
            />
            <InputField
              label="Hyra"
              type="text"
              value={rentTypes.find((x) => x.type === props.saleAgreement?.rentType)?.name}
              viewOnly
            />
            <InputField
              label="Överföring"
              type="text"
              value={transferTypes.find((x) => x.type === props.saleAgreement?.transferType)?.name}
              viewOnly
            />
            <InputField
              label="Överföringsperiod"
              type="text"
              value={transfersIntervals.find((x) => x.type === props.saleAgreement?.transferInterval)?.name}
              viewOnly
            />
            <InputField
              label="Nyckelförvaring: Inklusive inre kontroll"
              type="text"
              value={props.saleAgreement.includeInnerControl ? 'Ja' : 'Nej'}
              viewOnly
            />
          </Fieldset>
        )}

        <Fieldset legend="Villkor"  customSize={{ md: 12, lg: 12 }}>
          <InputField label="Inklusive" type="text" multiline rows="5" value={props.terms?.including} viewOnly />
          <InputField label="Exklusive" multiline rows="5" type="text" value={props.terms?.excluding} viewOnly />

          <InputField
            label="Enligt regelverk"
            multiline
            rows="5"
            type="text"
            value={props.terms?.regulation}
            viewOnly
          />
          <InputField label="Leverans" multiline rows="5" type="text" value={props.terms?.delivery} viewOnly />
          <InputField label="Garanti" multiline rows="5" type="text" value={props.terms?.warranty} viewOnly />
          <InputField label="Giltighetstid" type="text" value={props.terms?.validPeriod} viewOnly />

          <InputField
            label="Kan godkännas direkt av kund"
            fullwidth
            type="text"
            value={props.canBeApprovedByCustomer ? 'Ja' : 'Nej'}
            viewOnly
          />
        </Fieldset>
      </Fieldset>
    </>
  );
}
