import React, { useState, Dispatch, SetStateAction } from 'react';

type ReturnType = [
  number | null,
  (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => any,
  Dispatch<SetStateAction<number | null>>,
];

export default function useNullableNumericInputState(initialValue: number | null = null): ReturnType {
  const [value, setValue] = useState<number | null>(initialValue);

  function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    let value = null;
    if (e.target?.value) {
      value = parseInt(e.target.value, 10);
    }

    if (value !== null && isNaN(value)) value = null;

    setValue(value);
    return value;
  }

  return [value, handleChange, setValue];
}
