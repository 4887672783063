import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    margin: 10,
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      flex: 1,
    },
  },
  btn: {
    width: '100%',
    overflow: 'hidden',
    background: 'white',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',

    borderRadius: theme.shape.borderRadius,
    opacity: 1,
    transition: 'opacity 300ms ease',

    '& .MuiButton-label': {
      flexDirection: 'column',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '10rem',
    },

    [theme.breakpoints.down('xs')]: {
      height: '5rem',
      justifyContent: 'space-between',
      '& .MuiButton-label': {
        flexDirection: 'row',
      },
    },
  },
  logo: {
    width: '70px',
    [theme.breakpoints.down('xs')]: {
      width: '2rem',
      marginRight: '3px',
    },
  },
  mobileTitle: {
    color: theme.palette.primary.main,
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '5px',
  },
}));
