import { makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/styles';
import theme from '../../../../../../../Styles/Theme';

const buttonBase = {
  borderRadius: '12rem',
  backgroundColor: theme.palette.grey[100],
} as CSSProperties;

export const useStyles = makeStyles((theme: Theme) => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  list: {
    padding: 0,
    listStyleType: 'none',
  },
  remove: {
    ...buttonBase,
    color: theme.palette.error.main,
    marginLeft: '0.5rem',
  },
  edit: {
    ...buttonBase,
    color: theme.palette.primary.main,
  },

  listItem: {
    marginBottom: '1rem',
  },
  texts: {
    flexGrow: 1,
  },
  educationTitle: {
    fontSize: '1.125rem',
    display: 'block',
    paddingBottom: '0.25rem',
  },
  dates: {
    color: theme.palette.grey[800],
  },
  saveButton: {
    marginRight: '0.5rem',
  },
  editButton: {
    marginTop: '0.5rem',
    marginRight: '0.5rem',
  },
  cancelButton: {
    marginTop: '0.5rem',
  },
  actions: {
    display: 'flex',
    marginLeft: '0.5rem',
  },
}));
