import React, { useState, useEffect } from 'react';
import Button from '../../../../../../Shared/Button/Button';
import { Fieldset } from '../../../../../../Shared/Form/Fieldset/Fieldset';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import Container from '../../../../../../Shared/Container/Container';
import { useHistory, useParams } from 'react-router-dom';
import useInputState from '../../../../../../Shared/Hooks/UseInputState/UseInputState';
import {
  useUpsertCustomerContact,
  IUpsertCustomerContactCommand,
  IUpsertCustomerContactValidationErrors,
  useGetContact,
  getErrorMessage,
} from '../../../Customers.api';
import useFormValidation from '../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import { isResultError } from '../../../../../../Shared/Api/response/ICreateResult';
import FacilitySelect from '../../Facilities/FacilitySelect/FacilitySelect';
import useErrorMessage from '../../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import EmailField from '../../../../../../Shared/InputFields/EmailField/EmailField';
import { ISelectOption } from '../../../../../../Shared/Select';
import PhoneNumberField from '../../../../../../Shared/InputFields/PhoneNumberField/PhoneNumberField';

const EditContactForm: React.FunctionComponent<{ viewOnly?: boolean }> = (props) => {
  const { viewOnly } = props;
  const history = useHistory();
  const { customerId, id, facilityId } = useParams<{
    customerId: string;
    id: string | undefined;
    facilityId: string | undefined;
  }>();
  const { isFormValid, formRef } = useFormValidation();
  const { isLoading: isUpsertLoading, upsert } = useUpsertCustomerContact(customerId);
  const { isLoading: isGetContactLoading, getContact } = useGetContact(customerId);
  const [originalName, setOriginalName] = useState('');
  const [firstname, changeFirstname, setFirstname] = useInputState('');
  const [lastname, changeLastname, setLastname] = useInputState('');
  const [phone, changePhone, setPhone] = useInputState('');
  const [mobilePhone, changeMobilePhone, setMobilePhone] = useInputState('');
  const [position, changePosition, setPosition] = useInputState('');
  const [email, changeEmail, setEmail] = useInputState('');
  const [errors, setErrors] = useState<IUpsertCustomerContactValidationErrors>();
  const [facilityIds, setFacilityIds] = useState<number[]>(facilityId ? [Number(facilityId)] : []);
  const { setErrorMessage } = useErrorMessage();

  useEffect(() => {
    async function get() {
      const response = await getContact(id);

      if (response) {
        setOriginalName(response.firstname + ' ' + response.lastname);
        setFirstname(response.firstname);
        setLastname(response.lastname);
        setPhone(response.phoneNumber);
        setEmail(response.email);
        setMobilePhone(response.mobilePhoneNumber ?? '');
        setPosition(response.position ?? '');
        setFacilityIds(response.facilityIds);
      } else {
        history.push('/customers');
      }
    }
    if (id) {
      get();
    }
  }, [getContact, history, id, setFirstname, setLastname, setPhone, setEmail, setMobilePhone, setPosition]);

  async function upsertContact() {
    const upsertCommand: IUpsertCustomerContactCommand = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      phoneNumber: phone,
      mobilePhoneNumber: mobilePhone,
      position: position,
      facilityIds: facilityIds,
    };

    const result = await upsert(upsertCommand, id);
    if (isResultError(result)) {
      setErrors(result);
      setErrorMessage({ message: getErrorMessage(result) });
    } else {
      history.goBack();
      // history.push(facilityId ? `/customers/${customerId}/facility/${facilityId}` : `/customers/${customerId}`);
    }
  }
  const cancel = () => history.goBack();
    //history.push(facilityId ? `/customers/${customerId}/facility/${facilityId}` : `/customers/${customerId}`);

  function Actions() {
    return (
      <>
        <Button color="default" variant="outlined" onClick={cancel}>
          Avbryt
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={upsertContact}
          loading={isUpsertLoading}
          disabled={!isFormValid || facilityIds.length < 1 || isGetContactLoading}
        >
          {id ? 'Spara' : 'Skapa'}
        </Button>
      </>
    );
  }

  return (
    <Container
      form
      ref={formRef}
      label={id ? `Redigera ${originalName}` : 'Skapa kontakt'}
      loading={isGetContactLoading}
      actions={!viewOnly && <Actions />}
    >
      <Fieldset legend="Kontaktuppgifter">
        <InputField
          viewOnly={viewOnly}
          label="Förnamn"
          type="text"
          required
          value={firstname}
          onChange={changeFirstname}
          errorText={errors?.firstname}
        />
        <InputField
          viewOnly={viewOnly}
          label="Efternamn"
          type="text"
          required
          value={lastname}
          onChange={changeLastname}
          errorText={errors?.lastname}
        />
        <EmailField
          viewOnly={viewOnly}
          label="E-post"
          required
          value={email}
          onChange={changeEmail}
          errorText={errors?.email}
        />
        <PhoneNumberField
          viewOnly={viewOnly}
          label="Telefon"
          required
          value={phone}
          onChange={changePhone}
          errorText={errors?.phoneNumber}
        />
        <PhoneNumberField
          viewOnly={viewOnly}
          label="Mobil"
          value={mobilePhone}
          onChange={changeMobilePhone}
          errorText={errors?.mobilePhoneNumber}
        />
        <InputField
          viewOnly={viewOnly}
          label="Befattning/Avdelning"
          type="text"
          value={position}
          onChange={changePosition}
          errorText={errors?.position}
        />
        <FacilitySelect
          viewOnly={viewOnly}
          label="Platser"
          isMulti
          isDisabled={!!facilityId}
          customerId={customerId}
          selectedFacilityId={facilityIds.map((x) => x.toString())}
          onChange={(_1, _2, option) => {
            option ? setFacilityIds((option as ISelectOption[]).map((x) => Number(x.value))) : setFacilityIds([]);
          }}
        />
      </Fieldset>
    </Container>
  );
};

export default EditContactForm;
