import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '30rem',
    marginTop: '8rem',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '1rem',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
    paddingTop: '1rem',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
  },
  contact: {
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    marginRight: '1rem',
    maxWidth: '4rem',
    maxHeight: '4rem',
  },
}));
