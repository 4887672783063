import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ICoreSystem, useDeleteCoreSystem } from '../../Facilities.api';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useMediaQuery,
  MenuItem,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import Button from '../../../../../../../Shared/Button/Button';
import AccessGuard from '../../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { ModuleIdentifiers, AccessRights } from '../../../../../../../Core/Authentication/ModuleAccess';
import TableWithSearch from '../../../../../../../Shared/Table/TableWithSearch/TableWithSearch';
import formatDate from '../../../../../../../Shared/Formatting/formatDate';
import theme from '../../../../../../../Styles/Theme';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import { MobileTable } from '../../../../../../../Shared/Table/MobileTable/MobileTable';
import { MobileTableRow } from '../../../../../../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import { DropdownCell } from '../../../../../../../Shared/Table/DropdownCell/DropdownCell';
import RemoveSystemModal from './RemoveSystemModal/RemoveSystemModal';
import { useStyles } from '../../../../../../../Shared/Table/TableRowHeader/TableRowHeader.styles';
import { ConfirmDeleteOrArchiveRow } from '../../../../../../../Shared/Table/ConfirmDeleteOrArchiveRow/ConfirmDeleteOrArchiveRow';

interface ICoreSystemTable {
  currentPath?: string;
  coreSystems: ICoreSystem[] | undefined;
  loading?: boolean;
  coreSystemsList?: () => void;
  redirectToCustomer?: boolean;
}

const SecurICoreSystemTable = (props: ICoreSystemTable) => {
  const { isLoading, deleteCoreSystem } = useDeleteCoreSystem();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const [confirmRemoveSystemId, setConfirmRemoveSystemId] = useState<number | undefined>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [coreSystem, setCoreSystem] = useState<ICoreSystem[] | undefined>();

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (props.coreSystems) setCoreSystem(props.coreSystems);
  }, [props.coreSystems, setCoreSystem]);

  const handleDeleteCoreSystem = async () => {
    await deleteCoreSystem(confirmRemoveSystemId!);
    setOpenModal(false);
    setConfirmRemoveSystemId(undefined);
    props.coreSystemsList && (await props.coreSystemsList());
  };

  const HandleRemove = (id: number, agreement: boolean) => {
    if (agreement) {
      setOpenModal(true);
    }

    setConfirmRemoveSystemId(id);
  };

  if (!coreSystem) return null;

  const handleTableClick = (system: ICoreSystem) => {
    history.push(`${props.currentPath ?? history.location.pathname}/system/${system.id}`);
  };

  const formatAgreement = (system: ICoreSystem) => {
    const agremeent =
      system.activeAgreement ??
      (system.agreements.length > 1
        ? system.agreements.sort((a, b) => b.agreementId - a.agreementId)[0]
        : system.agreements[0]);
    return `${formatDate(agremeent.startDate)} - ${formatDate(agremeent.endDate)} ${
      agremeent.monthlyCost && `(${agremeent.monthlyCost} kr/månad)`
    }`;
  };

  return (
    <>
      <TableWithSearch<ICoreSystem>
        filterLabel="Sök"
        loading={props.loading}
        label="System"
        items={coreSystem}
        actions={
          <AccessGuard module={ModuleIdentifiers.Customer} accessRights={AccessRights.Write}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(
                  `${props.currentPath ?? history.location.pathname}/system/create${
                    props.redirectToCustomer ? '?redirect-to-customer=1' : ''
                  }`,
                );
              }}
            >
              Lägg till system
            </Button>
          </AccessGuard>
        }
        filter={(query: string, item: ICoreSystem) =>
          item.mainProduct.toLowerCase().includes(query) || item.systemType.toLowerCase().includes(query)
        }
        mobile={mobileView}
        customSize={{
          fullsize: true,
        }}
      >
        {(items: ICoreSystem[]) => (
          <>
            {mobileView ? (
              <>
                {items.map((system) => (
                  <MobileTable
                    key={system.id}
                    link={`${props.currentPath ?? history.location.pathname}/system/${system.id}`}
                    label={`${system.coreSystemIdentifier} ${system.systemPlacement && ' - ' + system.systemPlacement}`}
                  >
                    <MobileTableRow label="Huvudprodukt" value={system.mainProduct} />
                    <MobileTableRow label="SID" value={`${system.coreSystemIdentifier}${system.systemPlacement && ` - ${system.systemPlacement}`}`} />
                    <MobileTableRow label="Regelverk" value={system.regulation} />
                    <MobileTableRow label="SändarID" value={system.communication?.transmitter} />
                    <MobileTableRow label="Larmcentral" value={system.communication?.securityCompanyName} />
                    <MobileTableRow label="Avtal" value={system.activeAgreement && formatAgreement(system)} />
                  </MobileTable>
                ))}
              </>
            ) : (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <span className={'sr-only'}>Knappar</span>
                    </TableCell>
                    <TableCell>Huvudprodukt</TableCell>
                    <TableCell>SID</TableCell>
                    <TableCell>Regelverk</TableCell>
                    <TableCell>SändarID</TableCell>
                    <TableCell>Larmcentral</TableCell>
                    <TableCell>Avtal</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((system) => (
                    <TableRow className={classes.pointerCursor} hover tabIndex={-1} key={system.id}>
                      {confirmRemoveSystemId !== system.id ? (
                        <>
                          <DropdownCell>
                            <MenuItem onClick={() => HandleRemove(system.id, !!system.activeAgreement)}>
                              <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                              <Typography variant="inherit">Ta bort</Typography>
                            </MenuItem>
                          </DropdownCell>
                          <TableCell onClick={() => handleTableClick(system)}>{system.mainProduct}</TableCell>
                          <TableCell className={classes.linkFont} onClick={() => handleTableClick(system)}>
                            {system.coreSystemIdentifier}
                            {system.systemPlacement && ` - ${system.systemPlacement}`}
                          </TableCell>
                          <TableCell onClick={() => handleTableClick(system)}>{system.regulation}</TableCell>
                          <TableCell onClick={() => handleTableClick(system)}>
                            {system.communication?.transmitter}
                          </TableCell>
                          <TableCell onClick={() => handleTableClick(system)}>
                            {system.communication?.securityCompanyName}
                          </TableCell>
                          <TableCell onClick={() => handleTableClick(system)}>
                            {system.agreements?.length > 0 && formatAgreement(system)}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <ConfirmDeleteOrArchiveRow
                            onConfirm={handleDeleteCoreSystem}
                            onCancel={() => {
                              setConfirmRemoveSystemId(undefined);
                            }}
                            colspan={8}
                            label={`system med SID ${system?.coreSystemIdentifier}`}
                            loading={isLoading}
                          />
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </>
        )}
      </TableWithSearch>
      {confirmRemoveSystemId && (
        <RemoveSystemModal
          systemId={confirmRemoveSystemId}
          open={openModal}
          setOpen={setOpenModal}
          isLoading={isLoading}
          handleDelete={handleDeleteCoreSystem}
        />
      )}
    </>
  );
};

export default SecurICoreSystemTable;
