import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMediaQuery, useTheme, FormControlLabel, Checkbox, Grid } from '@material-ui/core';
import { AccessRights, ModuleIdentifiers } from '../../../../../../Core/Authentication/ModuleAccess';
import AccessGuard from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import useUserContext, { userHasAccessThroughRole } from '../../../../../../Core/Authentication/UserContext';
import Button from '../../../../../../Shared/Button/Button';
import PostalCodeField from '../../../../../../Shared/InputFields/PostalCodeField/PostalCodeField';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import { Fieldset } from '../../../../../../Shared/Form/Fieldset/Fieldset';
import { SaleTypeLabel, SaleType } from '../../../../../Shared/SaleType';
import formatPrice from '../../../../../../Shared/Formatting/formatPrice';
import formatDate from '../../../../../../Shared/Formatting/formatDate';
import { isErrorResponse } from '../../../../../../Shared/Api/response/IErrorRespose';
import BookOnOrder from '../../../../../Shared/Calendars/Components/BookOnOrder/BookOnOrder';
import useErrorMessage from '../../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import useInputState from '../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { getCustomerTypeName } from '../../../../../../Shared/Misc/CustomerType';
import Link from '../../../../../../Shared/Link/Link';
import { isResultError } from '../../../../../../Shared/Api/response/ICreateResult';
import useFormValidation from '../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import { ViewField } from '../../../../../../Shared/InputFields/InputField/ViewField';
import Container from '../../../../../../Shared/Container/Container';
import PopperMenu from '../../../../../../Shared/Popper/Popper';
import DialogForm from '../../../../../../Shared/DialogForm/DialogForm';
import CheckboxField from '../../../../../../Shared/InputFields/CheckboxField/CheckboxField';
import { SingleRowInputField } from '../../../../../../Shared/InputFields/InputField/SingleRowInputField';
import { IActivity } from '../../../../../Shared/Calendars/Components/ActivityForm/ActivityForm';
import { openFile } from '../../../../../../Shared/Api/response/IFileResult';
import ErrandsWarningPopUp from '../../../ErrandsWaringPopUp/ErrandsWarningPopUp';
import SystemLogList from '../../../../Admin/Components/SystemLog/SystemLogList/SystemLogList';
import { Action, SystemLogType } from '../../../../Admin/Components/SystemLog/SystemLog.api';
import OrderTab from './OrderTab/OrderTab';
import { useOpenDocument } from '../../../../Customers/Components/ViewCustomer/Documents/Components/Documents.api';
import {
  useUpdateCustomerBillingAddress,
  IUpdateCustomerBillingAddressCommand,
  IUpdateCustomerBillingAddressValidationErrors,
  creditCheckOutcomeText,
} from '../../../../Customers/Customers.api';
import { getErrorMessage, useUpsertCalendarEntry } from '../../../../Booking/Booking.api';
import {
  SaleStatus,
  SaleStatusLabel,
  measures,
  services,
  auditIntervals,
  rentTypes,
  transferTypes,
  transfersIntervals,
  useCancelSale,
  WarningStatus,
  useGetPreviewSalePdf,
  useGetWorkOrderPdf,
  SaleProductStatus,
} from '../../Sale.api';
import {
  useGetOrder,
  useCompleteInstall,
  useMarkForInvoice,
  useCompleteOrder,
  useUpdateDescriptions,
  useUpdateOrderInvoice,
  IUpdateInvoiceCommand,
  useRoleBackOrder as rollBackOrder,
  useUpdateOrderTotalPrice,
} from '../Order.api';
import { ViewAddress } from '../../../../../../Shared/InputFields/Address/ViewAddress';
import MultiButton from '../../../../../../Shared/MultiButton/MultiButton';
import MaterialList from './MaterialList/MaterialList';
import PostCalculation from './PostCalculation/PostCalculation';
import { Role, useGetCurrentUser } from '../../../../Admin/Components/Users/Users.api';
import { hasUserAccess } from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { useNotifyCustomer } from '../../Offer/Offer.api';
import Snackbar from '../../../../../../Shared/Hooks/UseErrorMessage/Snackbar/Snackbar';
import CompanyUserSelect from '../../../../Admin/Components/Users/UserSelect/CompanyUserSelect';
import { ISelectOption } from '../../../../../../Shared/Select';

const ViewOrder = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { user } = useUserContext();
  const { isLoading, order, getOrder } = useGetOrder();
  const { isLoading: isCompleteInstallLoading, completeInstall } = useCompleteInstall();
  const { getCurrentUser, response: currrentUser } = useGetCurrentUser();
  const { rollbackOrder } = rollBackOrder();
  const { isLoading: isInvoiceOrderLoading, markForInvoice } = useMarkForInvoice();
  const { isLoading: isUpdateInvoiceLoading, updateOrderInvoice } = useUpdateOrderInvoice(id);
  const { isLoading: isCompleteOrderLoading, completeOrder } = useCompleteOrder(id);
  const { notifyCustomer } = useNotifyCustomer();
  const { isLoading: isUpsertLoading, upsert } = useUpsertCalendarEntry();
  const { isLoading: isCancelLoading, cancelSale } = useCancelSale();
  const { setErrorMessage } = useErrorMessage();
  const { isLoading: isUpdateLoading, updateBillingAddress } = useUpdateCustomerBillingAddress(
    order?.customer.id.toString(),
  );
  const { updateTotalPrice } = useUpdateOrderTotalPrice(id);
  const { isFormValid, formRef } = useFormValidation();
  const [errors, setErrors] = useState<IUpdateCustomerBillingAddressValidationErrors>();
  const { isUpdatingDescriptionsLoading, updateDescriptions } = useUpdateDescriptions(id);
  const { isLoading: isOpeningDocument, openDocument } = useOpenDocument(order?.customer.id.toString());
  const { isLoading: isGetPreviewOfferPdfLoading, getPreviewSalePdf } = useGetPreviewSalePdf(id);
  const { getWorkOrderPdf } = useGetWorkOrderPdf(id);

  const anyLoading =
    isLoading ||
    isCompleteInstallLoading ||
    isInvoiceOrderLoading ||
    isCompleteOrderLoading ||
    isUpsertLoading ||
    isUpdatingDescriptionsLoading ||
    isUpdateLoading ||
    isUpdateInvoiceLoading;

  const [snackError, setSnackError] = useState<string | undefined>();

  const [jobDescription, updateJobDescription, setJobDescription] = useInputState('');
  const [workDescription, updateWorkDescription, setWorkDescription] = useInputState('');
  const [materialDescription, updateMaterialDescription, setMaterialDescription] = useInputState('');
  const [extraMaterial, updateExtraMaterial, setExtraMaterial] = useInputState('');
  const [invoiceAddress, changeInvoiceAddress, setInvoiceAddress] = useInputState('');
  const [invoicePostalCode, changeInvoicePostalCode, setInvoicePostalCode] = useInputState('');
  const [invoiceCity, changeInvoiceCity, setInvoiceCity] = useInputState('');
  const [invoiceReference, changeInvoiceReference, setInvoiceReference] = useInputState('');
  const [invoiceMessage, changeinvoiceMessage, setinvoiceMessage] = useInputState('');
  const [projectLeaderCompanyUser, setProjectLeaderCompanyUser] = useState<ISelectOption>();

  const [openWorkingDescription, setOpenWorkingDescription] = useState(false);
  const [openJobDescription, setOpenJobDescription] = useState(false);
  const [openMaterialDescription, setOpenMaterialDescription] = useState(false);
  const [openExtraMaterial, setOpenExtraMaterial] = useState(false);
  const [openInvoiceAddress, setOpenInvoiceAddress] = useState(false);
  const [openInvoiceCity, setOpenInvoiceCity] = useState(false);
  const [openInvoicePostalCode, setOpenInvoicePostalCode] = useState(false);
  const [openInvoiceReference, setOpenInvoiceReference] = useState(false);
  const [openOrderPrice, setOpenOrderPrice] = useState(false);
  const [invoiceTotalPrice, changeInvoiceTotalPrice, setInvoiceTotalPrice] = useInputState(0);
  const [newTotalPrice, changeNewTotalPrice] = useInputState(undefined);
  const [externalInvoiceNumber, updateExternalInvoiceNumber, setExternalInvoiceNumber] = useInputState<string>('');
  const [
    externalAgreementInvoiceNumber,
    updateExternalAgreementInvoiceNumber,
    setExternalAgreementInvoiceNumber,
  ] = useInputState<string>('');
  const [sendInvoiceSurvey, setSendInvoiceSurvey] = useState(false);

  const isInputOpen =
    openWorkingDescription ||
    openJobDescription ||
    openMaterialDescription ||
    openMaterialDescription ||
    openExtraMaterial ||
    openInvoiceAddress ||
    openInvoiceCity ||
    openInvoicePostalCode ||
    openInvoiceReference ||
    openOrderPrice || 
    order?.status === SaleStatus.invoicing;
  const [key, setKey] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);

  const showProducts =
    (order?.type === SaleType.system || order?.type === SaleType.service) && order?.coreSystem?.id !== undefined;

  const [openBookOrderDialog, setOpenBookOrderDialog] = useState(false);

  const expandedTerms =
    order && [SaleStatus.installed, SaleStatus.invoicing, SaleStatus.completed].includes(order.status);
  const expandedTotal = order && [SaleStatus.invoicing, SaleStatus.completed].includes(order.status);

  const isUserInstaller = user.roles?.some((x) => x === Role.Installer);

  const loadOrder = useCallback(async (first?: boolean) => {
    const response = await getOrder(id);
    if (!response || response.status === SaleStatus.draft) {
      history.push('errands/orders');
    } else {
      setJobDescription(response.jobDescription);
      setWorkDescription(response.workDescription);
      setMaterialDescription(response.materialDescription);
      setExtraMaterial(response.extraMaterial);
      setInvoiceAddress(response.customer.invoiceAddress);
      setInvoiceCity(response.customer.invoiceCity);
      setInvoicePostalCode(response.customer.invoicePostalCode);
      setInvoiceReference(response.customer.invoiceReference);
      setinvoiceMessage(response.invoiceMessage);
      setExternalInvoiceNumber(response.externalInvoiceNumber);
      setExternalAgreementInvoiceNumber(response.saleAgreement?.externalAgreementInvoiceNumber ?? '');
      setProjectLeaderCompanyUser({ label: response.projectLeader.name, value: response.projectLeader.id.toString() });

      if (first && (response?.status === SaleStatus.approved ||
        response?.status === SaleStatus.installationBooked ||
        response?.status === SaleStatus.installed ||
        response?.status === SaleStatus.invoicing)) {
        if (isUserInstaller || response.userAccess?.installer || response.userAccess?.admin) {
          setOpenWorkingDescription(true);
          setOpenExtraMaterial(true);
        } 
        if (response.userAccess?.projectLeader || response.userAccess?.admin) {
          setOpenInvoiceAddress(true);
          setOpenInvoiceCity(true);
          setOpenInvoicePostalCode(true);
          setOpenJobDescription(true);
          setOpenMaterialDescription(true);
          setOpenInvoiceReference(true);
          setOpenOrderPrice(true);
        }
      }
    }
  }, [getOrder, id, history, setJobDescription, setWorkDescription, setMaterialDescription, setExtraMaterial, setInvoiceAddress, setInvoiceCity, setInvoicePostalCode, setInvoiceReference, setinvoiceMessage, setExternalInvoiceNumber, setExternalAgreementInvoiceNumber, isUserInstaller]);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  useEffect(() => {
    loadOrder(true);
  }, [loadOrder]);

  async function updateCustomerBillingAddress() {
    if (order) {
      const updateCommand: IUpdateCustomerBillingAddressCommand = {
        invoiceAddress: invoiceAddress,
        invoicePostalCode: invoicePostalCode,
        invoiceCity: invoiceCity,
        invoiceReference: invoiceReference,
      };
      return await updateBillingAddress(updateCommand);
    }
  }

  async function handleCompleteInstall() {
    if (id) {
      const result = await completeInstall(id, invoiceMessage);
      await getOrder(id);
      isResultError(result)
        ? setSnackError('Något gick fel, försök igen.')
        : await notifyCustomer({ saleId: parseInt(id), currentAction: Action.SaleInstallationComplete });
      setKey((x) => x + 1);
    }
  }

  function handleOpenPopup(open: boolean) {
    setOpenPopup(open);
  }

  const navigateCallBack = useCallback(
    async (path: string, id: string) => {
      await getOrder(id).then(() => {
        history.push(`/errands/${path}/${id}`);
      });
    },
    [history, getOrder],
  );

  useEffect(() => {
    if (order) {
      setInvoiceTotalPrice(
        order.prices?.invoicedTotalPrice ? order.prices.invoicedTotalPrice : order.prices?.totalPrice ?? 0,
      );
    }
  }, [order, setInvoiceTotalPrice]);

  async function handleMarkForInvoice() {
    if (id) {
      await markForInvoice(id, invoiceMessage);
      await navigateCallBack('invoice', id);

      setOpenPopup(false);
    }
  }
  async function handleCompleteOrder() {
    if (id && order) {
      // If it's a system or deal type the externalInvoiceNumber is required to complete. If it's an agreement the externalAgreementInvoiceNumber is required to complete.
      if (order?.type === SaleType.system && !externalInvoiceNumber) {
        return;
      }
      if (order?.saleAgreement && !externalAgreementInvoiceNumber) {
        return;
      }
      if (order?.type === SaleType.deal && !externalAgreementInvoiceNumber) {
        return;
      }

      await completeOrder({
        invoiceAmount: invoiceTotalPrice,
        sendInvoiceSurvey,
        externalInvoiceNumber,
        externalAgreementInvoiceNumber,
      });
      await navigateCallBack('invoice', id);

      setKey((x) => x + 1);
    }
  }
  async function handleUpdateInvoice() {
    if (id) {
      const updateCommand: IUpdateInvoiceCommand = {
        externalInvoiceNumber: externalInvoiceNumber ? externalInvoiceNumber : '',
        externalAgreementInvoiceNumber: externalAgreementInvoiceNumber,
        invoiceTotalPrice: invoiceTotalPrice !== 0 ? invoiceTotalPrice : 0.0,
      };
      await updateOrderInvoice(updateCommand);
    }
  }

  async function handleBookInstallation(activity: IActivity) {
    if (activity.companyUserIds) {
      const result = await upsert({ ...activity, companyUserIds: activity.companyUserIds }, activity.id?.toString());
      if (isErrorResponse(result)) {
        setErrorMessage({ message: getErrorMessage(result, !activity.id) });
      } else {
        loadOrder();
        setKey((x) => x + 1);
      }
    }
  }

  function cancelEditDescriptions() {
    setOpenWorkingDescription(false);
    setOpenJobDescription(false);
    setOpenMaterialDescription(false);
    setOpenInvoiceAddress(false);
    setOpenInvoicePostalCode(false);
    setOpenInvoiceReference(false);
    setOpenInvoiceCity(false);
    setOpenExtraMaterial(false);
    setJobDescription(order?.jobDescription ?? '');
    setWorkDescription(order?.workDescription ?? '');
    setOpenOrderPrice(false);
    id ? history.push(`/errands/orders/${id}`) : history.push('/errands/orders');
  }

  function editDescription() {
    if (isUserInstaller || order?.userAccess?.installer || order?.userAccess?.admin) {
      setOpenWorkingDescription(true);
      setOpenExtraMaterial(true);
    } 
    if (order?.userAccess?.projectLeader || order?.userAccess?.admin) {
      setOpenInvoiceAddress(true);
      setOpenInvoiceCity(true);
      setOpenInvoicePostalCode(true);
      setOpenJobDescription(true);
      setOpenMaterialDescription(true);
      setOpenInvoiceReference(true);
      setOpenOrderPrice(true);
    }
  }

  const rollbackToOrder = useCallback(async () => {
    await rollbackOrder(id);
    await navigateCallBack('orders', id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateCallBack, rollbackOrder]);

  async function saveDescriptions() {
    const result = await updateDescriptions(
      jobDescription,
      workDescription,
      materialDescription,
      extraMaterial,
      Number(projectLeaderCompanyUser?.value),
    );

    if (isErrorResponse(result)) {
      setErrorMessage({ message: result });
    } else {
      //setOpenWorkingDescription(false);
      //setOpenJobDescription(false);
      //setOpenMaterialDescription(false);
      //setOpenExtraMaterial(false);
      if (order !== undefined) {
        order.jobDescription = jobDescription;
        order.workDescription = workDescription;
        order.extraMaterial = extraMaterial;
      }
    }

    const result2 = await updateCustomerBillingAddress();

    if (isResultError(result2)) {
      setErrors(result2);
    } else {
      /*setOpenInvoiceAddress(false);
      setOpenInvoicePostalCode(false);
      setOpenInvoiceCity(false);
      setOpenInvoiceReference(false);
      setOpenOrderPrice(false);*/
      loadOrder();
      setKey((x) => x + 1);
    }
    if (newTotalPrice !== undefined && newTotalPrice !== null) {
      const resultTotalPrice = await updateTotalPrice({ totalPrice: newTotalPrice });
      if (isResultError(resultTotalPrice)) {
        // setErrors(resultTotalPrice);
      } else {
        setKey((x) => x + 1);
      }
    }
  }

  async function handleCancelOrder() {
    if (!order?.id) {
      return;
    }

    await cancelSale(order?.id.toString());
    history.push('/errands/orders');
  }

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const getLabel = (saleStatus: string, approveDate: Date | null, approvedBy: string | null) => {
    if (saleStatus === SaleStatusLabel[4]) {
      return `${saleStatus} av ${approvedBy ? approvedBy : 'Kund via länk'} ${
        approveDate ? formatDate(new Date(approveDate)) : ''
      }`;
    }
    return saleStatus;
  };

  const flowButtons = () => {
    const newButtons = [];

    if (
      (order?.status === SaleStatus.approved || order?.status === SaleStatus.installationBooked) &&
      order?.type !== SaleType.deal &&
      order.saleProducts.some((x) => x.status === SaleProductStatus.added)
    ) {
      newButtons.push(
        <Button
          color="default"
          variant="outlined"
          onClick={() => history.push(`${history.location.pathname}/supplierorders`)}
        >
          Skapa beställning
        </Button>,
      );
    }

    if (
      order?.type !== SaleType.deal &&
      (order?.status === SaleStatus.approved || order?.status === SaleStatus.installationBooked) &&
      hasUserAccess(user, ModuleIdentifiers.Booking, AccessRights.Admin)
    ) {
      newButtons.push(
        <>
          <Button
            loading={isUpsertLoading}
            onClick={() => setOpenBookOrderDialog(true)}
            variant="contained"
            color="primary"
          >
            Boka
          </Button>
          <BookOnOrder
            title="Boka"
            saleId={order.id}
            customerId={order.customer.id}
            open={openBookOrderDialog}
            setOpen={setOpenBookOrderDialog}
            onSave={handleBookInstallation}
            jobDescription={order.jobDescription}
          />
        </>,
      );
    }

    if (order?.status === SaleStatus.invoicing) {
      newButtons.push(
        <Button
          disabled={
            anyLoading ||
            (order?.type === SaleType.system && !externalInvoiceNumber) ||
            (order.saleAgreement && !externalAgreementInvoiceNumber)
          }
          loading={isCompleteOrderLoading}
          color="default"
          variant="outlined"
          onClick={handleCompleteOrder}
        >
          Arkivera order
        </Button>,
      );
    }

    if (
      ((order?.status === SaleStatus.approved && order?.type === SaleType.deal) ||
        order?.status === SaleStatus.installed) &&
      (order?.projectLeader?.id === user.selectedUserId ||
        hasUserAccess(user, ModuleIdentifiers.Orders, AccessRights.Admin))
    ) {
      newButtons.push(
        <PopperMenu
          color="primary"
          variant="outlined"
          disabled={anyLoading}
          alwaysDialog
          customContent
          open={openPopup}
          setOpen={handleOpenPopup}
          title={`Text till faktura`}
          data-testid="open"
          content={
            <>
              <DialogForm
                actions={
                  <Grid container>
                    <Button color="default" variant="outlined" onClick={() => handleOpenPopup(false)}>
                      Avbryt
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      loading={isInvoiceOrderLoading}
                      onClick={handleMarkForInvoice}
                      data-testid="submit"
                    >
                      Skicka till faktura
                    </Button>
                  </Grid>
                }
                children={
                  <Grid xs={12} item>
                    <InputField
                      placeholder="Lägg till fakturatext"
                      type="text"
                      multiline
                      rows={3}
                      fullwidth
                      value={invoiceMessage}
                      onChange={changeinvoiceMessage}
                      onEnter={handleMarkForInvoice}
                    />
                  </Grid>
                }
              />
            </>
          }
        >
          Fakturera
        </PopperMenu>,
      );
    }
    return newButtons.map((b) => b);
  };

  return (
    <>
      <>
        <Container
          form
          ref={formRef}
          loading={isLoading}
          label={
            order &&
            'Order ' +
              order.saleIdentifier +
              ' - ' +
              getLabel(SaleStatusLabel[order.status], order.approveDate, order.approvedBy)
          }
          actions={
            <>
              {order && order.documentId && (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    loading={isOpeningDocument}
                    onClick={() => order?.documentId && openDocument(order.documentId)}
                  >
                    Visa offert
                  </Button>
                </>
              )}
              {order && (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    loading={isGetPreviewOfferPdfLoading}
                    onClick={async () => openFile(await getWorkOrderPdf(), mobileView)}
                  >
                    Visa arbetsorder
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    loading={isGetPreviewOfferPdfLoading}
                    onClick={async () => openFile(await getPreviewSalePdf(), mobileView)}
                  >
                    Visa order
                  </Button>
                </>
              )}
              <AccessGuard module={ModuleIdentifiers.Orders} accessRights={AccessRights.Write}>
                <>
                  <MultiButton
                    color="primary"
                    variant="contained"
                    loading={isLoading}
                    menuItems={
                      <>
                        {order && order.status !== SaleStatus.invoicing && (
                          <AccessGuard module={ModuleIdentifiers.Orders} accessRights={AccessRights.Full}>
                            <ErrandsWarningPopUp
                              id={order.id}
                              title={'Ta bort order'}
                              warningStatus={WarningStatus.order}
                              action={handleCancelOrder}
                              isLoading={isCancelLoading}
                            />
                          </AccessGuard>
                        )}
                        {/*(order?.status === SaleStatus.approved ||
                          order?.status === SaleStatus.installationBooked ||
                          order?.status === SaleStatus.installed ||
                          order?.status === SaleStatus.invoicing) && (
                          <Button color="default" variant="outlined" onClick={() => editDescription()}>
                            Redigera
                          </Button>
                        )*/}
                        {(order?.status === SaleStatus.invoicing || order?.status === SaleStatus.installed) && (
                          <Button color="default" variant="outlined" onClick={rollbackToOrder}>
                            {order?.status === SaleStatus.invoicing ? 'Återställa order' : 'Återställa installation'}
                          </Button>
                        )}
                        {order?.status !== SaleStatus.new && order?.status !== SaleStatus.pending_approval && (
                          <Button
                            color="default"
                            variant="outlined"
                            onClick={() =>
                              history.push({
                                pathname: '/errands/reports/create',
                                state: {
                                  customerId: order?.customer.id,
                                  saleId: order?.id,
                                },
                              })
                            }
                          >
                            Skapa felrapport
                          </Button>
                        )}
                      </>
                    }
                  >
                    Administrera
                  </MultiButton>
                  {order &&
                    order.status !== SaleStatus.archived &&
                    order.status !== SaleStatus.canceled &&
                    order.status !== SaleStatus.completed && (
                      <>
                        {flowButtons().length <= 1 ? (
                          flowButtons()
                        ) : (
                          <MultiButton
                            color="primary"
                            variant="contained"
                            loading={isLoading}
                            menuItems={<>{flowButtons()}</>}
                          >
                            Flöde
                          </MultiButton>
                        )}

                        {order?.status === SaleStatus.installationBooked &&
                          (order?.mainInstaller?.id === user.selectedUserId ||
                            userHasAccessThroughRole(user, Role.Installer) ||
                            hasUserAccess(user, ModuleIdentifiers.Orders, AccessRights.Admin)) && (
                            <Button
                              disabled={anyLoading}
                              loading={isCompleteInstallLoading}
                              color="default"
                              variant="outlined"
                              onClick={handleCompleteInstall}
                            >
                              Slutför installation
                            </Button>
                          )}
                      </>
                    )}
                </>
                {isInputOpen && (
                  <>
                    <Button color="default" variant="outlined" disabled={anyLoading} onClick={cancelEditDescriptions}>
                      Avbryt
                    </Button>
                    <Button
                      disabled={!isFormValid || anyLoading}
                      color="primary"
                      variant="contained"
                      loading={isUpdatingDescriptionsLoading || isCompleteOrderLoading}
                      onClick={() => {
                        saveDescriptions();
                        if(order?.status === SaleStatus.invoicing) handleUpdateInvoice();
                      }}
                    >
                      Spara
                    </Button>
                  </>
                )}
              </AccessGuard>
            </>
          }
        >
          <Fieldset legend="Kund" useAsExpandPanel={!isInputOpen}>
            <ViewField label="Kund nr">
              <Link
                to={`/customers/${order?.customer.id}`}
                module={ModuleIdentifiers.Customer}
                accessRights={AccessRights.Read}
              >
                {order?.customer.customerNumber}
              </Link>
            </ViewField>
            <ViewField label="Kund">
              <Link
                to={`/customers/${order?.customer.id}`}
                module={ModuleIdentifiers.Customer}
                accessRights={AccessRights.Read}
              >
                {order?.customer?.name}
              </Link>
            </ViewField>
            <InputField label="Person/Org nr" type="text" value={order?.customer?.identificationNumber} viewOnly />
            <InputField
              label="Kundtyp"
              type="text"
              value={getCustomerTypeName(order?.customer?.type as number)}
              viewOnly
            />
            <InputField
              label="Senaste kontroll"
              value={
                order?.customer?.creditCheck
                  ? formatDate(order?.customer?.creditCheck.date)
                  : 'Ingen kontroll har utförts'
              }
              viewOnly
            />
            <InputField
              label="Bedömning"
              value={
                creditCheckOutcomeText.find((x) => x.value === order?.customer?.creditCheck?.status)?.label ||
                'Ingen kontroll har utförts'
              }
              viewOnly
            />

            {!!order?.customer?.creditCheck && (
              <Button
                variant="text"
                color="primary"
                loading={isOpeningDocument}
                onClick={() => order?.documentId && openDocument(order?.customer.creditCheck.documentId)}
              >
                Visa upplysning
              </Button>
            )}
            <AccessGuard module={ModuleIdentifiers.Customer} accessRights={AccessRights.Write}>
              <Fieldset legend="Fakturaadress">
                <InputField
                  label="Adress"
                  type="text"
                  required
                  value={invoiceAddress}
                  errorText={errors?.invoiceAddress}
                  inputProps={{
                    onFocus: () => setOpenInvoiceAddress(true),
                  }}
                  onChange={changeInvoiceAddress}
                  viewOnly={!openInvoiceAddress}
                />
                <InputField
                  label="Faktureringsreferens"
                  value={invoiceReference}
                  viewOnly={!openInvoiceReference}
                  onChange={changeInvoiceReference}
                  inputProps={{
                    onFocus: () => setOpenInvoiceReference(true),
                  }}
                />
                <PostalCodeField
                  label="Postnummer"
                  type="text"
                  required
                  value={invoicePostalCode}
                  errorText={errors?.invoicePostalCode}
                  inputProps={{
                    onFocus: () => setOpenInvoicePostalCode(true),
                  }}
                  onChange={changeInvoicePostalCode}
                  viewOnly={!openInvoicePostalCode}
                />
                <InputField
                  label="Ort"
                  type="text"
                  required
                  value={invoiceCity}
                  errorText={errors?.invoiceCity}
                  inputProps={{
                    onFocus: () => setOpenInvoiceAddress(true),
                  }}
                  onChange={changeInvoiceCity}
                  viewOnly={!openInvoiceCity}
                />
              </Fieldset>
            </AccessGuard>
          </Fieldset>
          {order?.facility &&
            <Fieldset legend="Plats">
              <ViewField label={'Platsnamn'} value={order?.facility?.name} />
              <ViewField label={'Kundnummer'}>
                <Link
                  to={`/customers/${order?.customer.id}/facility/${order?.facility.id}`}
                  module={ModuleIdentifiers.Customer}
                  accessRights={AccessRights.Read}
                >
                  {order?.facility.facilityNumber}
                </Link>
              </ViewField>
              <ViewAddress
                customLabel="Platsadress"
                address={order?.facility?.address}
                postalCode={order?.facility?.postalCode}
                city={order?.facility?.city}
              />
              <ViewField label={'Anteckning'} value={order?.facility?.internalNote} />
            </Fieldset>
          }
          <Fieldset legend="Beställare">
            <ViewField label={'Namn'}>
              <Link
                to={`/customers/${order?.customer.id}/contact/${order?.contactPerson?.id}`}
                module={ModuleIdentifiers.Customer}
                accessRights={AccessRights.Read}
              >
                {order?.contactPerson?.name}
              </Link>
            </ViewField>
            <ViewField label={'Email'} value={order?.contactPerson?.email} />
            <ViewField label={'Telefon'} value={order?.contactPerson?.phoneNumber} />
          </Fieldset>
          {order && (
            <Fieldset useAsExpandPanel={!isInputOpen} legend="Allmänt">
              {order && order.description && <InputField label="Rubrik" viewOnly value={order.description} />}
              <InputField label="Ordertyp" type="text" value={SaleTypeLabel[order.type]} viewOnly />
              {!openJobDescription && (
                <InputField label="Projektledare" type="text" value={order.projectLeader?.name} viewOnly />
              )}
              {openJobDescription && (
                <CompanyUserSelect
                  required
                  placeholder="Välj Företagsanvändare"
                  label="Projektledare"
                  selectedCompanyUser={projectLeaderCompanyUser}
                  selectedCompanyUserId={order.projectLeader.id.toString()}
                  onChange={(_1, _2, option) =>
                    setProjectLeaderCompanyUser(option ? (option as ISelectOption) : undefined)
                  }
                />
              )}
            </Fieldset>
          )}
          <InputField label="Säljare" type="text" value={order && order.salesPerson?.name} viewOnly />
          {order?.type !== SaleType.deal && (
            <Fieldset legend="Beskrivningar">
              <InputField
                label="Uppdragsbeskrivning"
                type="text"
                multiline
                rows="5"
                value={jobDescription}
                inputProps={{
                  onFocus: () => setOpenJobDescription(true),
                }}
                onChange={updateJobDescription}
                viewOnly={!openJobDescription}
              />
              <InputField
                label="Materialbeskrivning fritext"
                type="text"
                multiline
                rows="5"
                value={materialDescription === "" && !openMaterialDescription ? "Material enligt offert." : materialDescription}
                inputProps={{
                  onFocus: () => setOpenMaterialDescription(true),
                }}
                onChange={updateMaterialDescription}
                viewOnly={!openMaterialDescription}
              />
              {/*<MaterialList products={order?.saleProducts} /> */}            
              <InputField
                label="Arbetsbeskrivning"
                type="text"
                multiline
                rows="5"
                value={workDescription}
                inputProps={{
                  onFocus: () => setOpenWorkingDescription(true),
                }}
                onChange={updateWorkDescription}
                viewOnly={!openWorkingDescription}
              />
              <InputField
                label="Extra-material"
                type="text"
                multiline
                rows="5"
                value={extraMaterial}
                inputProps={{
                  onFocus: () => setOpenExtraMaterial(true),
                }}
                onChange={updateExtraMaterial}
                viewOnly={!openExtraMaterial}
              />
            </Fieldset>
          )}
          {!!order?.coreSystem && (
            <Fieldset legend="System">
              <InputField label="Plats" type="text" value={order?.facility?.name ?? 'Ny plats'} viewOnly />
              {!order?.saleProducts?.some((x) => x.productId === order.coreSystem?.mainProductId) ? (
                <InputField
                  label="Komplement till system"
                  type="text"
                  value={`${order?.coreSystem.description}${
                    order.coreSystem.transmitter != null ? `(${order?.coreSystem.transmitter})` : ''
                  }`}
                  viewOnly
                />
              ) : (
                <InputField label="System" type="text" value={order.coreSystem.description} viewOnly />
              )}
              <InputField label="Systemtyp" type="text" value={order.coreSystem.systemTypeName} viewOnly />
              <InputField label="Regelverk" type="text" value={order.coreSystem.regulationName} viewOnly />
            </Fieldset>
          )}
          <InputField label="Projektör" type="text" value={order && order.projector?.name} viewOnly />
          {order?.mainInstaller && (
            <Fieldset legend="Installation" useAsExpandPanel>
              <InputField
                label="Installerad av"
                type="text"
                value={order?.installDate ? order.mainInstaller?.name : ''}
                viewOnly
              />
              <InputField
                label="Datum"
                type="text"
                value={order?.installDate ? formatDate(order.installDate) : ''}
                viewOnly
              />
            </Fieldset>
          )}
          {order && order.type !== SaleType.deal && (
            <>
              <Fieldset legend="Priser" useAsExpandPanel>
                <InputField
                  label="Timpris"
                  type="text"
                  value={!!order?.prices?.installationRate ? formatPrice(order?.prices?.installationRate) : 'Ej visas'}
                  viewOnly
                />
                <InputField
                  label="Total tid (ber 2)"
                  type="text"
                  value={!!order?.prices?.installationTime ? order?.prices?.installationTime + ' h' : 'Ej visas'}
                  viewOnly
                />
                <InputField
                  label="Materialpris"
                  type="text"
                  value={!!order?.prices?.materialCost ? formatPrice(order?.prices?.materialCost) : 'Ej visas'}
                  viewOnly
                />
                <InputField
                  label="Övrig kostnad"
                  type="text"
                  value={!!order?.prices?.otherCost ? formatPrice(order?.prices?.otherCost) : 'Ej visas'}
                  viewOnly
                />
              </Fieldset>

              <Fieldset legend="Hyra" useAsExpandPanel>
                <InputField
                  label="Första betalning"
                  type="text"
                  value={
                    !!order?.prices?.firstInstallationCost
                      ? formatPrice(order?.prices?.firstInstallationCost)
                      : 'Ej visas'
                  }
                  viewOnly
                />
                <InputField
                  label="Månadsavgift"
                  type="text"
                  value={!!order?.prices?.rentPrice ? formatPrice(order?.prices?.rentPrice) : 'Ej visas'}
                  viewOnly
                />
              </Fieldset>
            </>
          )}
          {order?.saleAgreement && (
            <Fieldset legend="Avtal" useAsExpandPanel>
              <InputField
                label="Månadsavgift"
                type="text"
                value={!!order.saleAgreement.monthlyCost ? formatPrice(order.saleAgreement.monthlyCost) : 'Ej visas'}
                viewOnly
              />
              <InputField label="Avtalsnummer" type="text" value={order.saleAgreement.agreementNumber} viewOnly />
              <InputField
                label="Externt avtalsfakturanummer"
                type="text"
                value={order.saleAgreement.externalAgreementInvoiceNumber ?? ''}
                viewOnly
              />
              <InputField label="Datum från" type="text" value={formatDate(order.saleAgreement.startDate)} viewOnly />
              <InputField
                label="Datum till"
                type="text"
                value={order.saleAgreement.endDate ? formatDate(order.saleAgreement.endDate) : 'Tillsvidare'}
                viewOnly
              />
              <InputField
                label="Larmcentral"
                type="text"
                value={order.saleAgreement.emergencyCallCenterSupplierName}
                viewOnly
              />
              <InputField
                label="Vaktbolag"
                type="text"
                value={order.saleAgreement.securityCompanySupplierName}
                viewOnly
              />
              <InputField
                label="Åtgärd"
                type="text"
                value={measures.find((x) => x.type === order.saleAgreement?.measure)?.name}
                viewOnly
              />
              <InputField
                label="Service"
                type="text"
                value={services.find((x) => x.type === order.saleAgreement?.service)?.name}
                viewOnly
              />
              <InputField
                label="Revision"
                type="text"
                value={auditIntervals.find((x) => x.type === order.saleAgreement?.auditInterval)?.name}
                viewOnly
              />
              <InputField
                label="Hyra"
                type="text"
                value={rentTypes.find((x) => x.type === order.saleAgreement?.rentType)?.name}
                viewOnly
              />
              <InputField
                label="Överföring"
                type="text"
                value={transferTypes.find((x) => x.type === order.saleAgreement?.transferType)?.name}
                viewOnly
              />
              <InputField
                label="Överföringsperiod"
                type="text"
                value={transfersIntervals.find((x) => x.type === order.saleAgreement?.transferInterval)?.name}
                viewOnly
              />
              <InputField
                label="Nyckelförvaring: Inklusive inre kontroll"
                type="text"
                value={order.saleAgreement.includeInnerControl ? 'Ja' : 'Nej'}
                viewOnly
              />
            </Fieldset>
          )}
          <Fieldset legend="Villkor" useAsExpandPanel expandedAsDefault={expandedTerms}>
            <InputField label="Inklusive" type="text" multiline rows="5" value={order?.terms?.including} viewOnly />
            <InputField
              label="Enligt regelverk"
              multiline
              rows="5"
              type="text"
              value={order?.terms?.regulation}
              viewOnly
            />
            <InputField label="Exklusive" multiline rows="5" type="text" value={order?.terms?.excluding} viewOnly />
            <InputField label="Garanti" multiline rows="5" type="text" value={order?.terms?.warranty} viewOnly />
            <InputField label="Leverans" multiline rows="5" type="text" value={order?.terms?.delivery} viewOnly />
          </Fieldset>
          {order?.type !== SaleType.deal && (
            <Fieldset legend="Totalt" useAsExpandPanel expandedAsDefault={expandedTotal}>
              <InputField
                label="Total installation"
                type="text"
                value={!!order?.prices?.installationCost ? formatPrice(order?.prices?.installationCost) : 'Ej visas'}
                viewOnly
              />
              <InputField
                label="Totalt orderpris"
                type="text"
                viewOnly={openOrderPrice && !hasUserAccess(user, ModuleIdentifiers.Orders, AccessRights.Admin)}
                value={newTotalPrice || order?.prices?.totalPrice}
                onChange={changeNewTotalPrice}
              />
            </Fieldset>
          )}
          {(order?.status === SaleStatus.invoicing || order?.status === SaleStatus.completed) && (
            <Fieldset legend="Fakturering" useAsExpandPanel expandedAsDefault={expandedTotal}>
              <InputField
                viewOnly={order?.status === SaleStatus.completed}
                label="Totalt till fakturering"
                type="text"
                value={invoiceTotalPrice ?? 0}
                endAdornment={'SEK'}
                onChange={changeInvoiceTotalPrice}
              />
              {(order?.type === SaleType.system || order?.type === SaleType.service) && (
                <InputField
                  viewOnly={order?.status === SaleStatus.completed}
                  label="Externt fakturanummer"
                  type="text"
                  value={externalInvoiceNumber}
                  onChange={updateExternalInvoiceNumber}
                  required
                />
              )}
              {order?.saleAgreement && (
                <InputField
                  viewOnly={order?.status === SaleStatus.completed}
                  label="Externt avtalsfakturanummer"
                  type="text"
                  value={externalAgreementInvoiceNumber}
                  onChange={updateExternalAgreementInvoiceNumber}
                  required
                />
              )}
              {order?.status === SaleStatus.invoicing && (
                <CheckboxField label="Enkät">
                  <FormControlLabel
                    onClick={() => setSendInvoiceSurvey(!sendInvoiceSurvey)}
                    checked={sendInvoiceSurvey}
                    control={<Checkbox color="primary" />}
                    label="Skicka enkät efter fakturering"
                  />
                </CheckboxField>
              )}
              <SingleRowInputField viewOnly label="Faktura-meddelande" type="text" value={order?.invoiceMessage} />
              {currrentUser?.roles.filter((x) => x === Role.Installer).length === 0 && (
                <PostCalculation key={`cal_${key}`} saleId={id} />
              )}
            </Fieldset>
          )}
        </Container>
      </>
      {order?.type !== SaleType.deal && (
        <OrderTab
          key={`tab_${key}`}
          id={id}
          mobileView={mobileView}
          parentLoading={anyLoading}
          reloadOrder={() => setKey((x) => x + 1)}
          showProducts={showProducts}
        />
      )}
      {!isUserInstaller && (
        <SystemLogList
          key={`log_${key}`}
          customSize={{ lg: 5, md: 6, sm: 12, xs: 12 }}
          defaultEntityId={id ? parseInt(id) : undefined}
          defaultLogType={SystemLogType.Sale}
          lockCustomerId
          lockLogType
          disableFromAndToDate
        />
      )}
      {snackError && (
        <Snackbar message={snackError} open={!!snackError} severity="error" onClose={() => setSnackError(undefined)} />
      )}
    </>
  );
};

export default ViewOrder;
