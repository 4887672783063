import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Fieldset } from '../../../../../../../../Shared/Form/Fieldset/Fieldset';
import IPaginatedResponse from '../../../../../../../../Shared/Api/response/IPaginatedResponse';
import { getListOf } from '../../../../../../../Shared/utils';
import ProductSelect from '../../../../../../Admin/Components/Suppliers/ViewSupplier/Products/ProductSelect/ProductSelect';
import SelectedProducts from './SelectedProducts';
import SelectProductsList from './SelectProductsList/SelectProductsList';
import { ISelectProductItem } from './SelectProductsList/SelectProductListRow';
import {
  IProductExtended,
  useGetProducts,
} from '../../../../../../Admin/Components/Suppliers/ViewSupplier/Products/Products.api';
import { IOfferProduct } from '../../../Offer.api';
import { IRegulation } from '../../../../../../Admin/Components/SystemTypes/SystemTypes.api';

function mapProductToItem(product: IProductExtended, quantity?: number): ISelectProductItem {
  return {
    id: product.id,
    label: product.name,
    value: quantity ?? 0,
    description: product.description,
    regulation: product.regulationName ?? '',
    regulationPriority: product.regulationPriority ?? 0,
    certificate: product.certifiedTo ?? undefined,
    productCategoryName: product.productCategoryName,
    productCategorySortOrder: product.productCategorySortOrder,
  };
}

function mapItemToOfferProduct(item: ISelectProductItem, quantity: number): IOfferProduct {
  return {
    productId: item.id,
    name: item.label,
    isMain: false,
    description: item.description,
    regulation: item.regulation,
    certificate: item.certificate,
    quantity: quantity,
    placements: getListOf('', quantity),
    productCategoryName: item.productCategoryName,
    productCategorySortOrder: item.productCategorySortOrder,
  };
}

function getProductsInCategory(
  category: string,
  products: IProductExtended[],
  selectedProducts: IOfferProduct[],
): ISelectProductItem[] {
  return products
    .filter((x) => x.productCategoryName === category)
    .map((x) => {
      const selection = selectedProducts.find((y) => y.productId === x.id);
      return mapProductToItem(x, selection?.quantity);
    });
}

function transformOfferProducts(
  selectedProducts: IOfferProduct[],
  item: ISelectProductItem,
  newQuantity: number,
): IOfferProduct[] {
  let newList = [...selectedProducts];

  // Check if product already exists in selection list
  const index = selectedProducts.findIndex((x) => x.productId === item.id);

  if (index > -1) {
    //Remove from list and get item
    var oldItem = newList.splice(index, 1)[0];

    if (newQuantity > 0) {
      //Remove unnecessary old placements
      var placements = oldItem.placements.slice(0, newQuantity);

      //If more products, append empty placements
      var oldLength = oldItem.placements.length;
      if (oldLength < newQuantity) placements = [...placements, ...getListOf('', newQuantity - oldLength)];

      //Added it again with new placements
      newList.push({
        ...oldItem,
        quantity: newQuantity,
        placements: placements,
      });
    }
  } else if (newQuantity > 0) {
    newList.push(mapItemToOfferProduct(item, newQuantity));
  }

  return [...newList];
}

interface ISelectProductProps {
  isTypeSystem: boolean;
  coreSystemId?: string;
  systemTypeId?: string;
  regulationId?: string;
  regulations?: IRegulation[];
  mainProductId?: string;
  offerProducts: IOfferProduct[];
  setMainProductId: (mainProductId: string | undefined) => void;
  setOfferProducts: React.Dispatch<React.SetStateAction<IOfferProduct[]>>;
}

const SelectProducts = (props: ISelectProductProps) => {
  const {
    isTypeSystem,
    coreSystemId,
    systemTypeId,
    regulationId,
    mainProductId,
    offerProducts,
    setMainProductId,
    setOfferProducts,
  } = props;

  const { products, getProducts } = useGetProducts();
  const [mainProducts, setMainProducts] = useState<IPaginatedResponse<IProductExtended>>();
  const [expandedPanel, setExpandedPanel] = useState<number | undefined>(undefined);

  const categories = useMemo(() => {
    if (!products) return undefined;
    return Array.from(
      products.items
        .map((x) => ({
          name: x.productCategoryName,
          sortOrder: x.productCategorySortOrder,
        }))
        .reduce((map, obj) => map.set(obj.name, obj), new Map())
        .values(),
    ).sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));
  }, [products]);

  /* Get product list every time a main product is selected, and reset list when no main product is selected. */
  useEffect(() => {
    if (mainProductId) {
      getProducts({ mainProductId: parseInt(mainProductId), pageSize: 1000, pageNumber: 1 });
    } // else setOfferProducts([]);
    //TODO: check how to handle service products
  }, [mainProductId, getProducts]);

  /* Every time main product changes in a new system, add it to product selection  */
  useEffect(() => {
    if (coreSystemId === '0' && mainProductId && mainProducts) {
      var product = mainProducts.items.find((x) => x.id.toString() === mainProductId);
      if (product) {
        let offerProduct = mapItemToOfferProduct(mapProductToItem(product), 1);
        offerProduct.isMain = true;
        setOfferProducts((x) => [
          ...x.filter((offerProduct) => products?.items.find((y) => y.id === offerProduct.productId)),
          offerProduct,
        ]);
      }
    }
  }, [coreSystemId, mainProductId, mainProducts, products, setOfferProducts]);
  return (
    <>
      <Fieldset legend="Välj produkter" customSize={{ md: 6, lg: 6 }}>
        {isTypeSystem && (
          <ProductSelect
            key={mainProductId ?? 'notSelected'}
            label={'Huvudprodukt'}
            onlyMainProducts
            withMoreInformation
            required
            isDisabled={coreSystemId !== '0' || !systemTypeId || !regulationId}
            systemTypeId={systemTypeId ? Number(systemTypeId) : undefined}
            regulationId={regulationId ? Number(regulationId) : undefined}
            selectedProductId={mainProductId}
            setProductsList={setMainProducts}
            onChange={(selected) => setMainProductId(selected as string)}
          />
        )}
        {(!isTypeSystem || !!mainProductId) && categories && products && (
          <Grid item xs={12}>
            {categories.map((category, index) => (
              <SelectProductsList
                chosenRegulation={props.regulations?.filter((r) => r.id?.toString() === regulationId)[0]}
                key={category.name}
                expanded={expandedPanel === index}
                onExpand={() => setExpandedPanel(expandedPanel === index ? undefined : index)}
                label={`${category.name} (${
                  getProductsInCategory(category.name, products.items, offerProducts).length
                })`}
                list={getProductsInCategory(category.name, products.items, offerProducts)}
                onChange={(item, newQuantity) => setOfferProducts((x) => transformOfferProducts(x, item, newQuantity))}
              />
            ))}
          </Grid>
        )}
      </Fieldset>

      <Fieldset style={{paddingTop: '8.2rem', overflowY: 'auto'}} customSize={{ md: 6, lg: 6 }}>
        {offerProducts.length > 0 && <SelectedProducts products={offerProducts} setOfferProducts={setOfferProducts} />}
      </Fieldset>
    </>
  );
};

export default SelectProducts;
