import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import RichTextEditor from '../../../../../../../Shared/RichTextEditor/RichTextEditor';
import {
  useUpsertPreselectedDeliveryAndWarrantyTerms,
  useGetPreselectedDeliveryAndWarrantyTerms,
  useGetPreselectionFileContent,
  IPreselectionFileInformationDto,
  useGetPreselectedDocumentFormat,
} from '../../Preselection.api';
import { Fieldset } from '../../../../../../../Shared/Form/Fieldset/Fieldset';
import { IPreselectionProps } from '../../Preselection';
import { isResultError } from '../../../../../../../Shared/Api/response/ICreateResult';
import { Table } from '../../../../../../../Shared/Table/Table';
import formatDate from '../../../../../../../Shared/Formatting/formatDate';
import Button from '../../../../../../../Shared/Button/Button';
import useStyles from '../../Preselection.styles';

const DeliveryAndWarrantyTerms = (props: IPreselectionProps) => {
  const classes = useStyles();
  const {
    preselectionDefaultContent,
    saveCallback,
    setPreselectionId,
    setIsLoading,
    setPreselectionDefaultContent,
    setChildrenComponent,
  } = props;
  const {
    isLoading: isGetLoading,
    getPreselectedDeliveryAndWarrantyTerms,
  } = useGetPreselectedDeliveryAndWarrantyTerms();
  const { isLoading: isUpdateLoading, upsert } = useUpsertPreselectedDeliveryAndWarrantyTerms();
  const { isLoading: isGettingFileLoading, getPreselectionFileContent } = useGetPreselectionFileContent();

  const { getPreselectedDocumentFormat } = useGetPreselectedDocumentFormat();

  const [termsAndConditions, setTermsAndConditions] = useState<string | undefined>();
  const [agreementFiles, setAgreementFiles] = useState<IPreselectionFileInformationDto[]>([]);
  const [header, setHeader] = useState<string>();
  const [footer, setFooter] = useState<string>();

  useEffect(() => {
    setIsLoading(isGetLoading || isUpdateLoading || isGettingFileLoading);
  }, [isGetLoading, isUpdateLoading, isGettingFileLoading, setIsLoading]);

  useEffect(() => {
    if (preselectionDefaultContent) {
      setTermsAndConditions(preselectionDefaultContent);
      setPreselectionDefaultContent(undefined);
    }
  }, [preselectionDefaultContent, setPreselectionDefaultContent]);

  useEffect(() => {
    const save: any = async (id: string) => {
      var result = await upsert(
        {
          termsAndConditions: termsAndConditions,
        },
        id,
      );
      if (isResultError(result)) {
        return false;
      }
      return true;
    };
    saveCallback(() => save);
  }, [termsAndConditions, saveCallback, upsert]);

  useEffect(() => {
    async function getValues() {
      const result = await getPreselectedDeliveryAndWarrantyTerms();
      if (result) {
        setPreselectionId(result.id?.toString());
        setTermsAndConditions(result.termsAndConditions);
        setAgreementFiles(result.termsAndConditionsFileNames);
      } else {
        return false;
      }
    }
    getValues();
  }, [getPreselectedDeliveryAndWarrantyTerms, setPreselectionId]);

  useEffect(() => {
    agreementFiles && setChildrenComponent(<AgreementFileTable />);
  }, [agreementFiles, setChildrenComponent]);

  const setHeaderFooter = useCallback(async () => {
    const results = await getPreselectedDocumentFormat();
    if (results) {
      setFooter(results.customFooter ?? '');
      setHeader(results.customHeader ?? '');
    }
  }, [getPreselectedDocumentFormat]);

  useEffect(() => {
    setHeaderFooter();
  }, [setHeaderFooter]);

  const textEditor = useMemo(() => {
    if (header && footer) {
      return (
        <RichTextEditor
          menuBar={false}
          header={header}
          footer={footer}
          termsAndCondtions={termsAndConditions}
          value={termsAndConditions ?? ''}
          onChange={setTermsAndConditions}
          onFocus={() => true}
          onBlur={() => false}
          type={'Agreement'}
        />
      );
    }
  }, [header, footer, termsAndConditions]);

  const AgreementFileTable = () => (
    <Table customSize={{ md: 5, lg: 5 }}>
      <TableHead>
        <TableRow>
          <TableCell>Version</TableCell>
          <TableCell>Skapad</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {agreementFiles.map((file) => (
          <TableRow>
            <TableCell>
              <Button
                color="primary"
                variant="text"
                className={classes.button}
                disableTouchRipple
                onClick={() => handleGetPreselectionFile(file.id.toString())}
              >
                {file.name}
              </Button>
            </TableCell>
            <TableCell>{formatDate(new Date(file.created))}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  const handleGetPreselectionFile = async (id: string) => {
    setTermsAndConditions((await getPreselectionFileContent(id.toString())).content);
  };

  return (
    <>
      <Fieldset legend="Leverans- och garantivillkor">
        <div className={classes.wysiwygFields}>{textEditor}</div>
      </Fieldset>
    </>
  );
};

export default DeliveryAndWarrantyTerms;
