import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Stepper from '../../../../../../../Shared/Stepper/Stepper';
import { ErrorReportState, IErrorReport, useDeleteErrorReport, useGetErrorReport } from '../../Reports.api';
import CreateErrorReport from './CreateErrorReport/CreateErrorReport';
import AddAnalyze from './AddAnalyze/AddAnalyze';
import PostMeasureAnalysis from './PostMeasureAnalysis/PostMeasureAnalysis';
import AddInvestigationResult from './AddInvestigationResult/AddInvestigationResult';
import ServiceOrderPopup from './ServiceOrderPopup/ServiceOrderPopup';
import Button from '../../../../../../../Shared/Button/Button';
import { WarningStatus } from '../../../Sale.api';
import ErrandsWarningPopUp from '../../../../ErrandsWaringPopUp/ErrandsWarningPopUp';
import AccessGuard from '../../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { AccessRights, ModuleIdentifiers } from '../../../../../../../Core/Authentication/ModuleAccess';

export interface IErrorReportStepProps {
  errorReport: IErrorReport | undefined;
  onSave: (goToNextStep: boolean, errorReportId?: number) => void;
  onCancel: () => void;
  children?: JSX.Element;
  errorReportIsLoading?: boolean;
  actions?: JSX.Element;
}

const ReportForm: React.FunctionComponent = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { isLoading: isLoadingDeleteReport, deleteErrorReport } = useDeleteErrorReport();
  const { getErrorReport, errorReport, isLoading: getIsLoading } = useGetErrorReport();
  const [activeStep, setActiveStep] = useState(1);

  const steps = [
    { id: 1, label: 'Steg 1', onClick: () => setActiveStep(1), disabled: false },
    { id: 2, label: 'Steg 2', onClick: () => setActiveStep(2), disabled: !errorReport },
    {
      id: 3,
      label: 'Steg 3',
      onClick: () => setActiveStep(3),
      disabled:
        !errorReport || !errorReport.errorReportState || errorReport.errorReportState < ErrorReportState.Investigated,
    },
    {
      id: 4,
      label: 'Steg 4',
      onClick: () => setActiveStep(4),
      disabled:
        !errorReport || !errorReport.errorReportState || errorReport.errorReportState < ErrorReportState.Analysed,
    },
  ];

  const goToList = useCallback(() => {
    history.goBack();
    // history.push('/errands/reports');
  }, [history]);

  const loadErrorReport = useCallback(async () => {
    const result = await getErrorReport(id);
    if (!result) {
      goToList();
    } else {
      switch (result.errorReportState) {
        case ErrorReportState.Created:
          setActiveStep(2);
          break;
        case ErrorReportState.Investigated:
          setActiveStep(3);
          break;
        case ErrorReportState.Analysed:
        case ErrorReportState.Completed:
          setActiveStep(4);
          break;
        default:
          break;
      }
    }
  }, [getErrorReport, goToList, id]);

  useEffect(() => {
    if (id) {
      loadErrorReport();
    }
  }, [id, loadErrorReport]);

  function handleErrorReportUpdated(goToNextStep: boolean, errorReportId?: number) {
    if (goToNextStep) {
      if (!errorReport) {
        history.push(`/errands/reports/${errorReportId}/update`);
      } else {
        loadErrorReport();
      }
    } else {
      goToList();
    }
  }

  function handleCreateServiceSale() {
    loadErrorReport();
  }

  const FormTop = () => {
    return <Stepper active={activeStep} steps={steps} />;
  };

  async function handleDeleteReport() {
    if (!errorReport?.id) return;

    await deleteErrorReport(errorReport?.id.toString());
    history.push('/errands/reports/');
  }
  const Actions = () => {
    return (
      <>
        {activeStep !== 1 && (
          <>
            {errorReport && (
              <AccessGuard module={ModuleIdentifiers.ErrorReport} accessRights={AccessRights.Full}>
                <ErrandsWarningPopUp
                  id={errorReport.id}
                  title={'Ta bort felrapport'}
                  warningStatus={WarningStatus.errorReport}
                  action={handleDeleteReport}
                  isLoading={isLoadingDeleteReport}
                />
              </AccessGuard>
            )}
          </>
        )}
        <Button color="default" variant="outlined" onClick={goToList}>
          Avbryt
        </Button>
        {errorReport?.id && !errorReport.serviceSaleId && activeStep !== 1 && (
          <ServiceOrderPopup errorReportId={errorReport.id} onCreate={handleCreateServiceSale} onCancel={() => {}} />
        )}
        {errorReport?.serviceSaleId && (
          <Button
            color="default"
            variant="outlined"
            onClick={() => history.push(`/errands/orders/${errorReport.serviceSaleId}`)}
          >
            Gå till serviceorder {errorReport?.serviceSaleIdentifier}
          </Button>
        )}
      </>
    );
  };

  const common = {
    errorReport,
    onSave: handleErrorReportUpdated,
    onCancel: goToList,
    errorReportIsLoading: getIsLoading,
    actions: <Actions />,
    children: <FormTop />,
  };

  return (
    <>
      {activeStep === 1 && <CreateErrorReport {...common} />}
      {activeStep === 2 && <AddInvestigationResult {...common} />}
      {activeStep === 3 && <AddAnalyze {...common} />}
      {activeStep === 4 && <PostMeasureAnalysis {...common} />}
    </>
  );
};

export default ReportForm;
