export const Messages = {
  date: 'Datum',
  time: 'Tid',
  event: 'Event',
  allDay: 'Hela dagen',
  week: 'Vecka',
  work_week: 'Arbetsvecka',
  day: 'Dag',
  month: 'Månad',
  previous: 'Föregående',
  next: 'Nästa',
  yesterday: 'Igår',
  tomorrow: 'Imorgon',
  today: 'Idag',
  agenda: 'Agenda',
  showMore: (total: number) => `Visa alla (${total})`,
};
