import { makeStyles, Theme } from '@material-ui/core/styles';

const AppBarHeight = '4.5rem';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '100vh',
      position: 'fixed',
      width: '6rem',
      backgroundColor: theme.palette.grey[50],
      borderRight: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  toolbar: {    
    background: theme.palette.grey[800],
    justifyContent: 'space-between',
    minHeight: AppBarHeight,
    '& span': {
      fontSize: '1.5rem'
    },
  },
  list: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    [theme.breakpoints.up('sm')]: {
      maxHeight: 'calc(100vh - 7.5rem - 1rem)',
      flexWrap: 'wrap',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  collapse: {
    backgroundColor: theme.palette.primary.main,
    position: 'fixed',
    top: AppBarHeight,
    zIndex: 3,
    width: '100%',
  },
  appBar: {
    minHeight: AppBarHeight,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5rem 1rem',
    borderBottom: `1px solid rgba(255, 255, 255, 0.1)`,
  },
}));
