export default interface IPaginatedRequest {
  pageNumber: number;
  pageSize: number;
  query?: string;
  sortDirection?: SortDirection;
  sortProperty?: string;
  usedAsSelectList?: boolean;
}

export enum SortDirection {
  Ascending = 0,
  Descending = 1,
}
