import React from 'react';
import { IChoiceQuestion } from '../../Survey.api';
import RadioField from '../../../../../Shared/InputFields/RadioField/RadioField';
import { FormControlLabel, Radio } from '@material-ui/core';

export interface ITextQuestionProps {
  question: IChoiceQuestion;
  answer: string;
  preview?: boolean;
  onAnswerChange: (answer: string) => void;
}

const ChoiceQuestion = (props: ITextQuestionProps) => {
  const { question, answer, onAnswerChange } = props;

  return (
    <RadioField required label={question.question}>
      {question.choices && question.choices
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map((x) => (
          <FormControlLabel
            key={x.id}
            label={x.choiceOption}
            control={<Radio color="primary" />}
            checked={!props.preview ? answer === x.id.toString() : false}
            onChange={(_, _2) => !props.preview && onAnswerChange(x.id.toString())}
          />
        ))}
    </RadioField>
  );
};

export default ChoiceQuestion;
