import React, { useState } from 'react';
import CompanyUserMessage from '../Forum/CompanyUserMessage/CompanyUserMessage';
import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import BookingCompanyCalendar from '../Booking/BookingCompanyCalendar/BookingCompanyCalendar';
import ToDoListWidget from '../Errands/Components/ToDoList/ToDoListWidget';
import Service from '../Forum/Service/Service';
import useBreadcrumbNavigationContext from '../../../Shared/Navigation/Breadcrumbs/HeaderNavigationContext/BreadcrumbNavigationContext';
import AccessGuard from '../../../Core/Authentication/AccessGuard/AccessGuard';
import { ModuleIdentifiers } from '../../../Core/Authentication/ModuleAccess';
import { useStyles } from './Home.styles';
import useUserContext from '../../../Core/Authentication/UserContext';
import { HomeComponent } from '../Admin/Components/Users/Users.api';

const Home = () => {
  const classes = useStyles();
  const { setBreadcrumbNavigationItems } = useBreadcrumbNavigationContext();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useUserContext();
  const [key, setKey] = useState(0);

  React.useEffect(() => {
    setBreadcrumbNavigationItems([]);
  }, [setBreadcrumbNavigationItems]);

  const getComponents = (name: HomeComponent) => {
    switch (name) {
      case HomeComponent.Calendar:
        return (
          <Grid container item className={classes.calendar}>
            {!mobile && <Grid item container justify="space-between" className={classes.date}>
              <Typography component="p" className={classes.dateAndName}>
                <span className={'sr-only'}>Dagens datum:</span>
              </Typography>
            </Grid>}
            <Grid item xs md={12}>
              <AccessGuard module={ModuleIdentifiers.Booking}>
                <BookingCompanyCalendar key={key} />
              </AccessGuard>
            </Grid>
          </Grid>
        );
      case HomeComponent.Todo:
        return (
          <AccessGuard module={ModuleIdentifiers.Todo}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <ToDoListWidget key={key} />
            </Grid>
          </AccessGuard>
        );
      case HomeComponent.UserMessage:
        return (
          <AccessGuard module={ModuleIdentifiers.Forum}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <CompanyUserMessage key={key} setKey={setKey} />
            </Grid>
          </AccessGuard>
        );
      case HomeComponent.Service:
        return (
          <AccessGuard module={ModuleIdentifiers.Service}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Service key={key} setKey={setKey} />
            </Grid>
          </AccessGuard>
        );
      default:
        return <></>;
    }
  };

  return mobile ? (
    <>{user.homeComponents?.sort((x) => x.priority).map((x) => (x.isHidden ? <></> : getComponents(x.component)))}</>
  ) : (
    <>
      {user.homeComponents?.find((x) => x.component === HomeComponent.Calendar)?.isHidden ? (
        <></>
      ) : (
        getComponents(HomeComponent.Calendar)
      )}
      <Grid container item spacing={2}>
        {user.homeComponents?.find((x) => x.component === HomeComponent.Todo)?.isHidden ? (
          <></>
        ) : (
          getComponents(HomeComponent.Todo)
        )}
        {user.homeComponents?.find((x) => x.component === HomeComponent.UserMessage)?.isHidden ? (
          <></>
        ) : (
          getComponents(HomeComponent.UserMessage)
        )}
        {user.homeComponents?.find((x) => x.component === HomeComponent.Service)?.isHidden ? (
          <></>
        ) : (
          getComponents(HomeComponent.Service)
        )}
      </Grid>
    </>
  );
};

export default Home;
