import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { IErrorReportStepProps } from '../ReportForm';
import {
  IUpsertErrorReportValidationErrors,
  useUpsertErrorReport,
  IUpsertErrorReportCommand,
  ErrorReportState,
} from '../../../Reports.api';
import useInputState from '../../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { isResultError } from '../../../../../../../../Shared/Api/response/ICreateResult';
import Container from '../../../../../../../../Shared/Container/Container';
import { Fieldset } from '../../../../../../../../Shared/Form/Fieldset/Fieldset';
import Datepicker from '../../../../../../../../Shared/InputFields/DatePicker/Datepicker';
import CustomerSelect from '../../../../../../Customers/Components/CustomerSelect/CustomerSelect';
import OrderSelect from '../../../../../../Errands/Components/Order/OrderSelect/OrderSelect';
import { InputField } from '../../../../../../../../Shared/InputFields/InputField/InputField';
import useFormValidation from '../../../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import MultiButton from '../../../../../../../../Shared/MultiButton/MultiButton';
import MultiButtonItem from '../../../../../../../../Shared/MultiButton/MultiButtonItem/MultiButtonItem';
import { SaleStatus } from '../../../../../../Errands/Components/Sale.api';
import { useLocation } from 'react-router-dom';

const saleStatusList = [
  SaleStatus.approved,
  SaleStatus.installationBooked,
  SaleStatus.installed,
  SaleStatus.invoicing,
  SaleStatus.completed,
  SaleStatus.approved,
];

export interface IErrorReportStateProp {
  customerId: number;
  saleId: number;
}

const CreateErrorReport: React.FunctionComponent<IErrorReportStepProps> = (props: IErrorReportStepProps) => {
  const { errorReport, onSave, errorReportIsLoading, children } = props;
  const location = useLocation<IErrorReportStateProp | undefined>();
  const { isFormValid, formRef } = useFormValidation();
  const { isLoading: isUpsertLoading, upsert } = useUpsertErrorReport();
  const [date, setDate] = useState(new Date());
  const [customerId, setCustomerId] = useState<number | undefined>(location.state?.customerId);
  const [saleId, setSaleId] = useState<number | undefined>(location.state?.saleId);
  const [customerRef, changeCustomerRef, setCustomerRef] = useInputState('');
  const [description, changeDescription, setDescription] = useInputState('');
  const [savingWithGoNextStep, setSavingWithGoNextStep] = useState<boolean>();
  const [errors, setErrors] = useState<IUpsertErrorReportValidationErrors>();

  const isValid = !!(isFormValid && customerId && saleId && date);

  useEffect(() => {
    if (errorReport) {
      setDate(new Date(errorReport.date));
      setCustomerId(errorReport.customerId);
      setSaleId(errorReport.saleId);
      setCustomerRef(errorReport.customerReferences);
      setDescription(errorReport.description);
    }
  }, [errorReport, setCustomerRef, setDescription]);

  async function handleUpsert(goToNextStep: boolean) {
    if (saleId) {
      setSavingWithGoNextStep(goToNextStep);
      const upsertCommand: IUpsertErrorReportCommand = {
        saleId: saleId,
        date: moment(date).startOf('day').toDate(),
        customerReferences: customerRef,
        description: description,
      };

      const result = await upsert(upsertCommand, errorReport?.id.toString());

      if (isResultError(result)) {
        setErrors(result);
      } else {
        onSave(goToNextStep, Number(result));
      }
    }
  }

  function Actions() {
    return (
      <>
        {props.actions}
        {errorReport?.errorReportState !== ErrorReportState.Completed && (
          <MultiButton
            color="primary"
            variant="contained"
            disabled={!isValid || isUpsertLoading}
            loading={isUpsertLoading && savingWithGoNextStep}
            onClick={() => handleUpsert(true)}
            menuItems={
              <>
                <MultiButtonItem
                  disabled={!isValid || isUpsertLoading}
                  loading={isUpsertLoading && !savingWithGoNextStep}
                  onClick={() => handleUpsert(false)}
                >
                  {errorReport ? 'Spara och stäng' : 'Skapa och stäng'}
                </MultiButtonItem>
              </>
            }
          >
            {errorReport ? 'Spara' : 'Skapa'} och gå till nästa steg
          </MultiButton>
        )}
      </>
    );
  }

  return (
    <Container form ref={formRef} loading={errorReportIsLoading} label="Skapa felrapport" actions={<Actions />}>
      {children}
      <Fieldset legend="Steg 1 - Anmälan">
        <Datepicker
          label="Datum"
          required
          onChange={(d: Date | null) => setDate(d as Date)}
          selected={date}
          errorText={errors?.date}
        />
        <CustomerSelect
          data-testid="CustomerSelect"
          required
          selectedCustomerId={customerId?.toString()}
          onChange={(id) => {
            setCustomerId(id ? Number(id) : undefined);
            setSaleId(undefined);
          }}
        />
        <OrderSelect
          fullwidth
          required
          isDisabled={!customerId}
          selectedOrderId={saleId?.toString()}
          customerId={customerId}
          status={saleStatusList}
          onChange={(saleId) => setSaleId(Number(saleId))}
          errorText={errors?.saleId}
        />
        <InputField
          label="Anmälare"
          required
          type="text"
          value={customerRef}
          onChange={changeCustomerRef}
          errorText={errors?.customerReferences}
        />
        <InputField
          label="Felbeskrivning"
          required
          multiline
          fullWidth
          rows={5}
          type="text"
          value={description}
          onChange={changeDescription}
          errorText={errors?.description}
        />
      </Fieldset>
    </Container>
  );
};

export default CreateErrorReport;
