import { useApiPost, useApiGet, useApiPut, useApiDelete } from '../../../../../Core/Api/Api';
import ValidationErrorResultType from '../../../../../Shared/Api/response/ValidationErrorResultType';
import IPaginatedResponse from '../../../../../Shared/Api/response/IPaginatedResponse';
import IPaginatedRequest from '../../../../../Shared/Api/request/IPaginatedRequest';
import { ICreateResult } from '../../../../../Shared/Api/response/ICreateResult';
import { useCallback } from 'react';

export interface ISupplier {
  id: number;
  name: string;
  customerNumber: string;
  supplierType: SupplierType;
}

export enum SupplierType {
  Hardware = 1,
  EmergencyCallCenter = 2,
  SecurityCompany = 3,
  Telematic = 4
}

export interface ISupplierType {
  type: SupplierType;
  name: string;
}

export const supplierTypes: ISupplierType[] = [
  { type: SupplierType.Hardware, name: 'Produkter' },
  { type: SupplierType.SecurityCompany, name: 'Vaktbolag' },
  { type: SupplierType.EmergencyCallCenter, name: 'Larmcentral' },
  { type: SupplierType.Telematic, name: 'Telematik' },
];

export interface ISupplierPaginatedRequest extends IPaginatedRequest {
  supplierType: SupplierType | null;
}

export function useGetSuppliers() {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<ISupplier>, ISupplierPaginatedRequest>(
    '/api/suppliers/list',
  );

  return {
    isLoading,
    response,
    getSuppliers: post,
  };
}

export interface ISupplierDto {
  id: number;

  name: string;
  customerNumber: string | undefined;
  identificationNumber: string;
  orderEmail: string;
  orderContact: string | undefined;
  orderPhone: string | undefined;
  supportEmail: string | undefined;
  supportContact: string | undefined;
  supportPhone: string | undefined;

  address: string | undefined;
  postalCode: string | undefined;
  city: string | undefined;
  code: string | undefined;
  supplierType: SupplierType;

  lastSurveySentDate: Date | undefined;
}

export function useGetSupplier() {
  const { isLoading, response, get } = useApiGet<ISupplierDto>('/api/suppliers/', false);

  return {
    isLoading,
    response,
    getSupplier: get,
  };
}

export interface IUpsertSupplierCommand {
  name: string;

  customerNumber: string | undefined;
  identificationNumber: string;
  orderEmail: string;
  orderContact: string | undefined;
  orderPhone: string | undefined;
  supportEmail: string | undefined;
  supportContact: string | undefined;
  supportPhone: string | undefined;

  address: string | undefined;
  postalCode: string | undefined;
  city: string | undefined;
  code: string | undefined;
  supplierType: SupplierType;
}

export interface IUpsertSupplierValidationErrors extends ValidationErrorResultType<IUpsertSupplierCommand> {}

export function useUpsertSupplier() {
  const { isLoading: isCreateLoading, post } = useApiPost<
    ICreateResult<IUpsertSupplierValidationErrors>,
    IUpsertSupplierCommand
  >('/api/suppliers');

  const { isLoading: isUpdateLoading, put } = useApiPut<IUpsertSupplierValidationErrors, IUpsertSupplierCommand>(
    '/api/suppliers/',
  );

  async function upsert(command: IUpsertSupplierCommand, id?: string) {
    if (id) {
      const result = await put(command, id);

      return result ? result : id;
    }

    return post(command);
  }

  return {
    isLoading: isCreateLoading || isUpdateLoading,
    upsert: upsert,
  };
}

export function useDeleteSupplier() {
  const { isLoading, delete: deleteRequest } = useApiDelete(`/api/suppliers/`);

  const deleteSupplier = useCallback(
    async (supplierId: string) => {
      return await deleteRequest(undefined, supplierId);
    },
    [deleteRequest],
  );

  return {
    isLoading,
    deleteSupplier,
  };
}

export function useSendSurveySupplier() {
  const { isLoading, response, post } = useApiPost(`/api/suppliers/`);

  async function sendSurvey(supplierId: string) {
    return post(undefined, `${supplierId}/send-survey`);
  }

  return {
    isLoading,
    response,
    sendSurvey,
  };
}
