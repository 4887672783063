import React from 'react';
import { IInputFieldProps, InputField } from './InputField';
import { useStyles } from './InputField.styles';

export const SingleRowInputField: React.FunctionComponent<IInputFieldProps> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.oneRow}>
      <InputField {...props} />
    </div>
  );
};
