import React, { useCallback, useEffect, useState } from 'react';
import Container from '../../../../../Shared/Container/Container';
import MyCustomers from '../My-Customers/MyCustomers';
import { ISelectOption, Select } from '../../../../../Shared/Select';
import { useHistory, useParams } from 'react-router-dom';
import { isErrorResponse, ErrorCode } from '../../../../../Shared/Api/response/IErrorRespose';
import { useGetCompanies } from '../../MyPages.api';
import { useStyles } from './MyCompanies.styles';

export default function MyCompanies() {
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>();
  const { token } = useParams<{ token: string | undefined }>();
  const [companyOptions, setCompanyOptions] = useState<ISelectOption[]>([]);
  const history = useHistory();
  const { getCompanies, isLoading } = useGetCompanies(token);
  const classes = useStyles();

  const loadCompanies = useCallback(async () => {
    if (token === undefined) {
      history.push(`${history.location.pathname}/404`);
    }

    const result = await getCompanies();

    if (!result || (isErrorResponse(result) && result.errorCode === ErrorCode.AnonymousTokenNotFound)) {
      history.push(`${history.location.pathname}/404`);
    } else {
      if (result.length === 1) {
        setSelectedCompanyId(result[0].id);
      }
      const companyOptions = result?.map((x) => ({
        value: x.id.toString(),
        label: x.name,
      }));

      setCompanyOptions(companyOptions);

      if (companyOptions.length === 1) {
        setCompanyId(Number(companyOptions[0].value));
      }
    }
  }, [getCompanies, history, token]);

  useEffect(() => {
    loadCompanies();
  }, [loadCompanies]);

  function setCompanyId(id: number) {
    setSelectedCompanyId(id);
  }

  const dom = (
    <>
      <Container customSize={{ fullsize: true }}>
        {companyOptions.length > 1 && (
          <div className={classes.companySelector}>
            <Select
              inputFieldProps={{
                fullwidth: true,
                label: 'Platform',
                placeholder: 'Välj företag',
              }}
              selectProps={{
                isDisabled: isLoading,
                value: companyOptions.find((x) => x.value === selectedCompanyId?.toString()),
                options: companyOptions,
                onChange: (selectedValue) => {
                  const selectedCompany = selectedValue as ISelectOption;
                  setCompanyId(Number(selectedCompany.value));
                },
              }}
            />
          </div>
        )}
        {selectedCompanyId && <MyCustomers companyId={selectedCompanyId} />}
      </Container>
    </>
  );

  return dom;
}
