import React, { useEffect, useState } from 'react';
import Button from '../../../../../../../Shared/Button/Button';
import Checklist from '../Checklist/Checklist';
import formatDate, { formatTime } from '../../../../../../../Shared/Formatting/formatDate';
import formatPrice from '../../../../../../../Shared/Formatting/formatPrice';
import Link from '../../../../../../../Shared/Link/Link';
import Tabs from '../../../../../../../Shared/Tabs/Tabs';
import EditIcon from '@material-ui/icons/Edit';
import useUserContext from '../../../../../../../Core/Authentication/UserContext';
import { AccessRights, ModuleIdentifiers } from '../../../../../../../Core/Authentication/ModuleAccess';
import { MobileTable } from '../../../../../../../Shared/Table/MobileTable/MobileTable';
import { MobileTableRow } from '../../../../../../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import {
  SaleStatus,
  ISaleChecklistEntryDto,
  SaleProductStatus,
  saleProductStatusLabel,
  timeReportTypes,
  useGetSaleMisc,
  useDeleteTimeReport,
  useDeleteSaleProduct,
  ISaleCostDto,
} from '../../../Sale.api';
import { TabActions } from '../../../../../../../Shared/Tabs/TabActions/TabActions';
import { Table } from '../../../../../../../Shared/Table/Table';
import { TableRow, TableCell, TableBody, TableHead, Tab, ListItemIcon, Typography, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDeleteSalesCost, useUpdateSaleChecklist } from '../../Order.api';
import useStyles from './OrderTab.styles';
import { DropdownCell } from '../../../../../../../Shared/Table/DropdownCell/DropdownCell';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import moment from 'moment';
import TimeReportForm from '../TimeReportForm/TimeReportForm';
import { ConfirmDeleteOrArchiveRow } from '../../../../../../../Shared/Table/ConfirmDeleteOrArchiveRow/ConfirmDeleteOrArchiveRow';
import { Role } from '../../../../../Admin/Components/Users/Users.api';
export interface IOrderTab {
  showProducts: boolean;
  mobileView: boolean;
  id: string;
  parentLoading: boolean;
  reloadOrder: () => void;
}

const OrderTab = (props: IOrderTab) => {
  const classes = useStyles();
  const history = useHistory();
  const { hasAccess } = useUserContext();
  const { showProducts, mobileView, id, parentLoading } = props;
  const { isLoading: getSaleMiscLoading, getSaleMisc, saleMisc } = useGetSaleMisc();
  const { isLoading: isUpdateSaleChecklistLoading, updateSaleChecklist } = useUpdateSaleChecklist(id);
  const { deleteSaleCost } = useDeleteSalesCost();

  const [checklistEntries, setChecklistEntries] = useState<ISaleChecklistEntryDto[]>([]);
  const [saleCosts, setSaleCosts] = useState<ISaleCostDto[]>([]);
  const [checklistChanged, setChecklistChanged] = useState<boolean>(false);
  const [confirmDeleteTimeReportId, setConfirmDeleteTimeReportId] = useState<number>(-1);
  const { deleteTimeReport } = useDeleteTimeReport();
  const [confirmDeleteSaleProductId, setConfirmDeleteSaleProductId] = useState<number>(-1);
  const { deleteSaleProduct } = useDeleteSaleProduct();

  const isLoading = parentLoading || getSaleMiscLoading;
  const userCanEdit = hasAccess(ModuleIdentifiers.Orders, AccessRights.Write);

  useEffect(() => {
    async function loadSaleOtherInfo() {
      const result = await getSaleMisc(id);
      setChecklistEntries(result?.checklistEntries ?? []);
      setSaleCosts(result?.saleCosts);
      console.log(result);
    }
    loadSaleOtherInfo();
  }, [id, getSaleMisc]);

  async function updateChecklist() {
    if (id) {
      await updateSaleChecklist({ entries: checklistEntries });
      setChecklistChanged(false);
    }
  }
  async function handleDeleteTimeReport() {
    await deleteTimeReport(id, confirmDeleteTimeReportId);
    delete saleMisc?.timeReports[saleMisc.timeReports.findIndex((x) => x.id === confirmDeleteTimeReportId)];
    setConfirmDeleteTimeReportId(-1);
  }

  async function handleDeleteSaleProduct() {
    await deleteSaleProduct(id, confirmDeleteSaleProductId);
    delete saleMisc?.saleProducts[saleMisc.saleProducts.findIndex((x) => x.id === confirmDeleteSaleProductId)];
    setConfirmDeleteSaleProductId(-1);
  }

  async function handleDeleteCost(costId: number) {
    await deleteSaleCost(id, costId.toString());
    setSaleCosts(saleCosts.filter((x) => x.id !== costId));
  }

  return (
    <>
      <Tabs customSize={{ md: 6 }}>
        {showProducts && (
          <Tab label="Produkter">
            <TabActions>
              {saleMisc && [SaleStatus.approved, SaleStatus.installationBooked].includes(saleMisc.status) && (
                <Button
                  disabled={isLoading}
                  color="primary"
                  variant="outlined"
                  onClick={() => history.push(`${history.location.pathname}/product/add`)}
                >
                  Lägg till
                </Button>
              )}
            </TabActions>
            <>
              <Table mobile={mobileView} customSize={{ fullsize: true }}>
                {saleMisc && (
                  <>
                    {mobileView ? (
                      <>
                        {saleMisc.saleProducts.map((product) => (
                          <MobileTable key={product.id} label={product.description}>
                            {confirmDeleteSaleProductId !== product.id ? (
                              <MobileTableRow>
                                {saleMisc.status !== SaleStatus.canceled &&
                                  saleMisc.status !== SaleStatus.invoicing &&
                                  saleMisc.status !== SaleStatus.completed &&
                                  saleMisc.status !== SaleStatus.archived && (
                                    <MenuItem
                                      onClick={() => {
                                        setConfirmDeleteSaleProductId(product.id);
                                      }}
                                    >
                                      <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                                      <Typography variant="inherit">Ta bort</Typography>
                                    </MenuItem>
                                  )}
                              </MobileTableRow>
                            ) : (
                              <MobileTableRow>
                                <ConfirmDeleteOrArchiveRow
                                  onConfirm={handleDeleteSaleProduct}
                                  onCancel={() => {
                                    setConfirmDeleteSaleProductId(-1);
                                  }}
                                  colspan={3}
                                  label={product.name}
                                />
                              </MobileTableRow>
                            )}
                            <MobileTableRow label="Namn" value={product.name} />
                            <MobileTableRow label="Produktnummer" value={product.productId} />
                            <MobileTableRow label="Placering" value={product.facilityPlacement} />
                            <MobileTableRow
                              label="Kommentar"
                              value={product.comment ?? (product.additionOnOrder && 'Tillägg')}
                            />
                            {!!product.supplierOrder && (
                              <MobileTableRow
                                label="Beställning"
                                value={
                                  <Link
                                    to={`/errands/supplierorders/${product.supplierOrder.id}`}
                                    module={ModuleIdentifiers.SupplierOrders}
                                    accessRights={AccessRights.Read}
                                  >
                                    {product.supplierOrder.id}
                                  </Link>
                                }
                              />
                            )}
                            <MobileTableRow label="Leverantör" value={product.supplierOrder?.supplierName} />
                            <MobileTableRow
                              label="Status"
                              value={saleProductStatusLabel[product.status] + 
                                (product.status === SaleProductStatus.delivered && product.supplierOrder?.deliveryDate)
                                  && `${saleProductStatusLabel[product.status]} ${formatDate(
                                      product.supplierOrder?.deliveryDate,
                                    )}` + 
                                    (product.status === SaleProductStatus.orderConfirmed && product.supplierOrder?.expectedDeliveryDate) && `${saleProductStatusLabel[product.status]} ${formatDate(
                                    product.supplierOrder?.expectedDeliveryDate,
                                  )}`
                              }
                            />
                          </MobileTable>
                        ))}
                      </>
                    ) : (
                      <>
                        <TableHead>
                          <TableRow>
                            {saleMisc.status !== SaleStatus.canceled &&
                              saleMisc.status !== SaleStatus.invoicing &&
                              saleMisc.status !== SaleStatus.completed &&
                              saleMisc.status !== SaleStatus.archived && (
                                <TableCell>
                                  <span className={'sr-only'}>Knappar</span>
                                </TableCell>
                              )}
                            <TableCell>Namn</TableCell>
                            <TableCell>Produktnr.</TableCell>
                            <TableCell>Placering</TableCell>
                            <TableCell>Kommentar</TableCell>
                            <TableCell>Beställning</TableCell>
                            <TableCell>Leverantör</TableCell>
                            <TableCell>Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {saleMisc?.saleProducts.map((product) => (
                            <React.Fragment key={product.id}>
                              <TableRow
                                className={
                                  product.status === SaleProductStatus.delivered ? classes.statusDelivered : ''
                                }
                              >
                                {confirmDeleteSaleProductId !== product.id ? (
                                  <>
                                    {saleMisc.status !== SaleStatus.canceled &&
                                      saleMisc.status !== SaleStatus.invoicing &&
                                      saleMisc.status !== SaleStatus.completed &&
                                      saleMisc.status !== SaleStatus.archived && (
                                        <DropdownCell>
                                          <MenuItem
                                            onClick={() => {
                                              setConfirmDeleteSaleProductId(product.id);
                                            }}
                                          >
                                            <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                                            <Typography variant="inherit">Ta bort</Typography>
                                          </MenuItem>
                                        </DropdownCell>
                                      )}
                                    <TableCell>{product.name}</TableCell>
                                    <TableCell>{product.productId}</TableCell>
                                    <TableCell>{product.facilityPlacement}</TableCell>
                                    <TableCell>{product.comment ?? (product.additionOnOrder && 'Tillägg')}</TableCell>
                                    <TableCell>
                                      {!!product.supplierOrder && (
                                        <Link
                                          to={`/errands/supplierorders/${product.supplierOrder.id}`}
                                          module={ModuleIdentifiers.SupplierOrders}
                                          accessRights={AccessRights.Read}
                                        >
                                          {product.supplierOrder.id}
                                        </Link>
                                      )}
                                    </TableCell>
                                    <TableCell>{product.supplierOrder?.supplierName}</TableCell>
                                    <TableCell>
                                      {saleProductStatusLabel[product.status]}
                                      {(product.status === SaleProductStatus.delivered && product.supplierOrder?.deliveryDate) && ` ${formatDate(
                                        product.supplierOrder?.deliveryDate,
                                      )}`}
                                      {(product.status === SaleProductStatus.orderConfirmed && product.supplierOrder?.expectedDeliveryDate) && `. Leverans: ${formatDate(
                                        product.supplierOrder?.expectedDeliveryDate,
                                      )}`}
                                    </TableCell>
                                  </>
                                ) : (
                                  <ConfirmDeleteOrArchiveRow
                                    onConfirm={handleDeleteSaleProduct}
                                    onCancel={() => {
                                      setConfirmDeleteSaleProductId(-1);
                                    }}
                                    colspan={3}
                                    label={product.name}
                                  />
                                )}
                              </TableRow>
                            </React.Fragment>
                          ))}
                        </TableBody>
                      </>
                    )}
                  </>
                )}
              </Table>
            </>
          </Tab>
        )}
        {saleMisc &&
          (saleMisc.status === SaleStatus.installationBooked ||
            saleMisc.status === SaleStatus.installed ||
            saleMisc.status === SaleStatus.archived ||
            saleMisc.status === SaleStatus.invoicing ||
            saleMisc.status === SaleStatus.completed) && (
            <Tab label="Tidsrapportering">
              {(saleMisc.status === SaleStatus.installationBooked ||
                saleMisc.status === SaleStatus.installed ||
                saleMisc.status === SaleStatus.invoicing) && <TimeReportForm onSave={() => getSaleMisc(id)} id={id} />}
              <Table mobile={mobileView} customSize={{ fullsize: true }}>
                <>
                  {mobileView ? (
                    <>
                      {saleMisc?.timeReports.map((timeReport) => (
                        <MobileTable
                          key={timeReport.id}
                          label={`${timeReport.companyUser.name} -  ${formatDate(timeReport.date)}`}
                        >
                          {confirmDeleteTimeReportId !== timeReport.id && (
                            <MenuItem
                              onClick={() => {
                                setConfirmDeleteTimeReportId(timeReport.id);
                              }}
                            >
                              <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                              <Typography variant="inherit">Ta bort</Typography>
                            </MenuItem>
                          )}
                          {confirmDeleteTimeReportId === timeReport.id && (
                            <ConfirmDeleteOrArchiveRow
                              onConfirm={handleDeleteTimeReport}
                              onCancel={() => {
                                setConfirmDeleteTimeReportId(-1);
                              }}
                              colspan={3}
                              label={moment(timeReport.date).format('YYYY MM DD')}
                            />
                          )}
                          <MobileTableRow label="Datum" value={formatDate(timeReport.date)} />
                          <MobileTableRow label="Montör" value={`${timeReport.companyUser.name}${(timeReport.companyUser.roles.find((r) => r.role === Role.Contractor) ? ' (UE)' : '')}`} />
                          <MobileTableRow label="Typ" value={timeReportTypes.find((x) => x.type === timeReport.type)?.name} />
                          <MobileTableRow label="Antal h" value={timeReport.hours} />
                          <MobileTableRow label="Antal resor" value={timeReport.trips} />
                          <MobileTableRow label="Parkering" value={formatPrice(timeReport.parking)} />
                        </MobileTable>
                      ))}
                    </>
                  ) : (
                    <>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <span className={'sr-only'}>Knappar</span>
                          </TableCell>
                          <TableCell>Datum</TableCell>
                          <TableCell>Montör</TableCell>
                          <TableCell>Typ</TableCell>
                          <TableCell>Antal h</TableCell>
                          <TableCell>Antal resor</TableCell>
                          <TableCell>Parkering</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {saleMisc?.timeReports.map((timeReport) => (
                          <React.Fragment key={timeReport.id}>
                            <TableRow>
                              {confirmDeleteTimeReportId !== timeReport.id ? (
                                <>
                                  <DropdownCell>
                                    <MenuItem
                                      onClick={() => {
                                        setConfirmDeleteTimeReportId(timeReport.id);
                                      }}
                                    >
                                      <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                                      <Typography variant="inherit">Ta bort</Typography>
                                    </MenuItem>
                                  </DropdownCell>
                                  <TableCell>{formatDate(timeReport.date)}</TableCell>
                                  <TableCell>{`${timeReport.companyUser.name}${(timeReport.companyUser.roles.find((r) => r.role === Role.Contractor) ? ' (UE)' : '')}`}</TableCell>
                                  <TableCell>{timeReportTypes.find((x) => x.type === timeReport.type)?.name}</TableCell>
                                  <TableCell>{timeReport.hours}</TableCell>
                                  <TableCell>{timeReport.trips}</TableCell>
                                  <TableCell>{formatPrice(timeReport.parking)}</TableCell>
                                </>
                              ) : (
                                <ConfirmDeleteOrArchiveRow
                                  onConfirm={handleDeleteTimeReport}
                                  onCancel={() => {
                                    setConfirmDeleteTimeReportId(-1);
                                  }}
                                  colspan={3}
                                  label={moment(timeReport.date).format('YYYY MM DD')}
                                />
                              )}
                            </TableRow>
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </>
                  )}
                </>
              </Table>
            </Tab>
          )}
        <Tab label="Montörer">
          <Table customSize={{ fullsize: true }}>
            <TableHead>
              <TableRow>
                <TableCell>Montör</TableCell>
                <TableCell>Datum</TableCell>
                <TableCell>Tid</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {saleMisc?.bookedUsers?.map((user) =>
                user.dateDetails.map((details, index) => (
                  <TableRow key={user.id.toString() + index.toString()}>
                    <TableCell>{index > 0 ? '' : user.name}</TableCell>
                    <TableCell>{formatDate(details.startDate)}</TableCell>
                    <TableCell>
                      {formatTime(details.startDate)} - {formatTime(details.endDate)}
                    </TableCell>
                  </TableRow>
                )),
              )}
            </TableBody>
          </Table>
        </Tab>
        <Tab label="Checklista">
          <TabActions>
            <Button
              disabled={!checklistChanged}
              loading={isUpdateSaleChecklistLoading}
              onClick={updateChecklist}
              color="primary"
              variant="outlined"
            >
              Spara
            </Button>
          </TabActions>
          <Checklist
            disabled={saleMisc?.status !== SaleStatus.installationBooked || !userCanEdit}
            checklistEntries={checklistEntries}
            products={saleMisc?.saleProducts}
            onChange={(entries) => {
              setChecklistEntries(entries);
              setChecklistChanged(true);
            }}
          />
        </Tab>
        <Tab label="Kostnader">
          <TabActions>
            <Button
              disabled={
                isLoading ||
                saleMisc?.status === SaleStatus.archived ||
                saleMisc?.status === SaleStatus.canceled ||
                saleMisc?.status === SaleStatus.draft ||
                saleMisc?.status === SaleStatus.new ||
                saleMisc?.status === SaleStatus.pending_approval
              }
              color="default"
              variant="outlined"
              onClick={() => history.push(`${history.location.pathname}/cost/create`)}
            >
              Lägg till
            </Button>
          </TabActions>
          <Table mobile={mobileView} customSize={{ fullsize: true }}>
            <>
              {mobileView ? (
                <>
                  {saleCosts?.map((cost) => (
                    <MobileTable key={cost.id} label={`${formatDate(cost.date)}`}>
                      <MobileTableRow>
                        <MenuItem onClick={() => history.push(`${history.location.pathname}/cost/${cost.id}/update`)}>
                          <ListItemIcon>{<EditIcon />}</ListItemIcon>
                          <Typography variant="inherit">Uppdatera</Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={async () => {
                            await handleDeleteCost(cost.id);
                          }}
                        >
                          <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                          <Typography variant="inherit">Ta bort</Typography>
                        </MenuItem>
                      </MobileTableRow>
                      <MobileTableRow label="Id" value={cost.id} />
                      <MobileTableRow label="Datum" value={formatDate(cost.date)} />
                      <MobileTableRow label="Leverantör" value={cost.supplierName} />
                      <MobileTableRow label="Beskrivning" value={cost.description} />
                      <MobileTableRow label="Summa in" value={formatPrice(cost.costIn)} />
                      <MobileTableRow label="Summa ut" value={formatPrice(cost.costOut)} />
                    </MobileTable>
                  ))}
                </>
              ) : (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <span className={'sr-only'}>Knappar</span>
                      </TableCell>
                      <TableCell>Id</TableCell>
                      <TableCell>Datum</TableCell>
                      <TableCell>Leverantör</TableCell>
                      <TableCell>Beskrivning</TableCell>
                      <TableCell>Summa in</TableCell>
                      <TableCell>Summa ut</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {saleMisc?.saleCosts.map((cost) => (
                      <React.Fragment key={cost.id}>
                        <TableRow>
                          <DropdownCell>
                            <MenuItem
                              onClick={() => history.push(`${history.location.pathname}/cost/${cost.id}/update`)}
                            >
                              <ListItemIcon>{<EditIcon />}</ListItemIcon>
                              <Typography variant="inherit">Uppdatera</Typography>
                            </MenuItem>
                            <MenuItem
                              onClick={async () => {
                                await handleDeleteCost(cost.id);
                              }}
                            >
                              <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                              <Typography variant="inherit">Ta bort</Typography>
                            </MenuItem>
                          </DropdownCell>
                          <TableCell>{cost.id}</TableCell>
                          <TableCell>{formatDate(cost.date)}</TableCell>
                          <TableCell>{cost.supplierName}</TableCell>
                          <TableCell>{cost.description}</TableCell>
                          <TableCell>{formatPrice(cost.costIn)}</TableCell>
                          <TableCell>{formatPrice(cost.costOut)}</TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </>
              )}
            </>
          </Table>
        </Tab>
      </Tabs>
    </>
  );
};

export default OrderTab;
