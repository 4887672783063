import React, { useContext, useEffect, useState } from 'react';
import { IInputFieldProps, InputField } from '../InputField/InputField';
import { CustomerType } from '../../Misc/CustomerType';
import { useCheckIdentificationExisting } from '../../../AuthApp/Modules/Customers/Customers.api';
import InputMask from 'react-input-mask';
import { ContainerContext } from '../../Container/Container';

export interface IIdentificationNumberFieldProps extends IInputFieldProps {
  currentCustomerId?: number;
  customerType: CustomerType;
  withoutLastFourDigits?: boolean;
  setExistingIdentificationCustomerId?: (customerId: string) => void;
  setIsIdentificationExisting?: (exist: boolean) => void;
}

const IdentificationNumberField = (props: IIdentificationNumberFieldProps) => {
  const {
    currentCustomerId,
    customerType,
    withoutLastFourDigits,
    disabled,
    inputProps,
    setIsIdentificationExisting,
    setExistingIdentificationCustomerId,
    onChange,
    ...rest
  } = props;
  const { isLoading: isCheckLoading, check } = useCheckIdentificationExisting();
  const containerContext = useContext(ContainerContext);
  const [errorText, setErrorText] = useState<string>();
  const [lastCheckCustomerId, setLastCheckCustomerId] = useState<number>();
  let pattern = '';
  let label = props.label;
  let helperText = props.helperText;
  let mask = '';

  switch (customerType) {
    case CustomerType.Company:
      pattern = '^(?:[0-9]{6})[-+](?:[0-9]{4})$';
      label = label ?? 'Organisationsnummer';
      helperText = helperText ?? 'XXXXXX-XXXX';
      mask = '999999-9999';
      break;
    case CustomerType.Private:
      pattern = withoutLastFourDigits
        ? '^(?:19|[2-9][0-9]){0,1}(?:[0-9]{2})(?!0230|0231|0431|0631|0931|1131)(?:(?:0[1-9])|(?:1[0-2]))(?:(?:0[1-9])|(?:1[0-9])|(?:2[0-9])|(?:3[01]))$'
        : '^(?:19|[2-9][0-9]){0,1}(?:[0-9]{2})(?!0230|0231|0431|0631|0931|1131)(?:(?:0[1-9])|(?:1[0-2]))(?:(?:0[1-9])|(?:1[0-9])|(?:2[0-9])|(?:3[01]))[-+](?!0000)(?:[0-9]{4})$';
      label = label ?? 'Personnummer';
      if (!helperText) helperText = withoutLastFourDigits ? 'ÅÅMMDD' : 'ÅÅMMDD-XXXX';
      mask = withoutLastFourDigits ? '999999' : '999999-9999';
      break;
    default:
      break;
  }

  useEffect(() => {
    async function checkIdentificationExisting() {
      if (
        props.value &&
        (setExistingIdentificationCustomerId || setIsIdentificationExisting) &&
        RegExp(pattern as string).test(props.value as string)
      ) {
        const customerId = await check({
          currentCustomerId: currentCustomerId,
          type: customerType,
          identificationNumber: props.value as string,
        });
        if (customerId && customerId !== lastCheckCustomerId) {
          setLastCheckCustomerId(customerId);
          setExistingIdentificationCustomerId && setExistingIdentificationCustomerId(customerId.toString());
        }
        setIsIdentificationExisting && setIsIdentificationExisting(customerId ? true : false);
        setErrorText(customerId ? 'Finns redan en befinnlig kund' : undefined);
      } else setErrorText(undefined);
    }
    checkIdentificationExisting();
  }, [
    props.value,
    currentCustomerId,
    customerType,
    lastCheckCustomerId,
    pattern,
    setIsIdentificationExisting,
    setExistingIdentificationCustomerId,
    check,
  ]);

  if (props.viewOnly || containerContext?.loading) {
    return (
      <InputField
        type="text"
        {...rest}
        inputProps={{ ...inputProps, isLoading: isCheckLoading, pattern }}
        label={label}
        helperText={helperText}
      />
    );
  } else {
    return (
      <InputMask
        onChange={onChange}
        alwaysShowMask={false}
        mask={mask}
        value={props.value as any}
        maskChar={null}
        disabled={disabled}
      >
        {(innerProps: any) => {
          let inputProps = { ...innerProps.inputProps, isLoading: isCheckLoading, pattern };
          return (
            <InputField
              type="id"
              inputProps={inputProps}
              {...rest}
              label={label}
              helperText={helperText}
              disabled={disabled}
              errorText={props.viewOnly || props.disabled ? undefined : errorText}
            />
          );
        }}
      </InputMask>
    );
  }
};

export default IdentificationNumberField;
