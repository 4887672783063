import React, { useState, useEffect, useCallback } from 'react';
import { TableHead, TableRow, TableCell, TableBody, Tab } from '@material-ui/core';
import { Table } from '../../../../../../Shared/Table/Table';
import useTable from '../../../../../../Shared/Table/useTable';
import IPaginatedResponse from '../../../../../../Shared/Api/response/IPaginatedResponse';
import TablePaginationWithDatasource from '../../../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import SearchInput from '../../../../../../Shared/InputFields/SearchInput/SearchInput';
import {
  IProductExtended,
  useGetProducts,
  useGetProductsCount,
} from '../../Suppliers/ViewSupplier/Products/Products.api';
import { useHistory, useParams } from 'react-router-dom';
import { useStyles } from './ProductList.style';
import formatDate from '../../../../../../Shared/Formatting/formatDate';
import SortButton from '../../../../../../Shared/Table/SortButton/SortButton';
import Tabs from '../../../../../../Shared/Tabs/Tabs';

const tabUrls = ['', 'archive'];

const ProductsList = () => {
  const { tabId } = useParams<{ tabId: string }>();
  const {
    toggleSort,
    sortProperty,
    sortDirection,
    pageSize,
    setPageSize,
    query,
    setQuery,
    setPageNumber,
    paginatedRequest,
  } = useTable();
  const [emptyMessage, setEmptyMessage] = useState<string>('');
  const history = useHistory();
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState<number>(tabId ? tabUrls.indexOf(tabId) : 0);
  const { isLoading, products, getProducts } = useGetProducts(tabIndex === 1);
  const { productsCount } = useGetProductsCount();

  useEffect(() => {
    if (tabId) {
      setTabIndex(tabUrls.indexOf(tabId));
    }
  }, [tabId]);

  const loadList = useCallback(async () => {
    const productsResponse: IPaginatedResponse<IProductExtended> = await getProducts({
      ...paginatedRequest,
    });

    if (productsResponse?.totalCount === 0) {
      if (query) {
        setEmptyMessage('Filtreringen gav inga resultat');
      } else {
        setEmptyMessage('Inga produktkategorier tillagda');
      }
    }
  }, [paginatedRequest, query, getProducts]);

  const handleTableClick = (product: IProductExtended) => {
    history.push(`/admin/supplier/${product.supplierId}/product/${product.id}`);
  };

  const ProductTable = (
    <Table
      label=""
      minWidth
      pagination={
        products && (
          <TablePaginationWithDatasource
            datasource={products}
            onChangePage={setPageNumber}
            onChangeRowsPerPage={setPageSize}
          />
        )
      }
      empty={
        products && {
          empty: products?.totalCount === 0 ? true : false,
          message: emptyMessage,
        }
      }
      filters={<SearchInput type="search" placeholder="Sök produkter" onChange={setQuery} />}
      loading={{
        loading: isLoading,
        skeletonRows: pageSize,
      }}
    >
      <>
        <TableHead>
          <TableRow>
            <TableCell>
              Namn
              <SortButton
                property="Name"
                sortProperty={sortProperty}
                sortDirection={sortDirection}
                onSort={toggleSort}
              />
            </TableCell>
            <TableCell>
              Kategori
              <SortButton
                property="ProductCategoryName"
                sortProperty={sortProperty}
                sortDirection={sortDirection}
                onSort={toggleSort}
              />
            </TableCell>
            <TableCell>
              Leverantör
              <SortButton
                property="SupplierName"
                sortProperty={sortProperty}
                sortDirection={sortDirection}
                onSort={toggleSort}
              />
            </TableCell>
            <TableCell>
              Certifiering
              <SortButton
                property="CertifiedTo"
                sortProperty={sortProperty}
                sortDirection={sortDirection}
                onSort={toggleSort}
              />
            </TableCell>
            <TableCell>
              Regelverk
              <SortButton
                property="RegulationName"
                sortProperty={sortProperty}
                sortDirection={sortDirection}
                onSort={toggleSort}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.productTableBody}>
          {products?.items.map((product) => (
            <TableRow hover tabIndex={-1} key={product.id}>
              <div style={{ display: 'contents' }} onClick={() => handleTableClick(product)}>
                <TableCell>{`${product.name} ${!product.visible ? '(arkiverad)' : ''}`}</TableCell>
              </div>
              <TableCell onClick={() => handleTableClick(product)}>{product.productCategoryName}</TableCell>
              <TableCell onClick={() => handleTableClick(product)}>{product.supplierName}</TableCell>
              <TableCell onClick={() => handleTableClick(product)}>
                {product.certifiedTo && formatDate(product.certifiedTo)}
              </TableCell>
              <TableCell onClick={() => handleTableClick(product)}>{product.regulationName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </>
    </Table>
  );

  useEffect(() => {
    loadList();
  }, [loadList]);

  return (
    <Tabs
      customSize={{ fullsize: true }}
      onChange={(newIndex) => {
        history.push(`/admin/products/${tabUrls[newIndex]}`);
        setQuery('');
        setTabIndex(newIndex);
      }}
      startIndex={tabIndex}
    >
      <Tab
        label={`Produkter ${productsCount?.visibleProductsCount ? '(' + productsCount.visibleProductsCount + ')' : ''}`}
      >
        {ProductTable}
      </Tab>
      <Tab label={`Arkiv ${productsCount?.hiddenProductCount ? '(' + productsCount.hiddenProductCount + ')' : ''}`}>
        {ProductTable}
      </Tab>
    </Tabs>
  );
};

export default ProductsList;
