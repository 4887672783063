import React, { useEffect, useState } from 'react';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Fieldset } from '../../../../../../../Shared/Form/Fieldset/Fieldset';
import useInputState from '../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { InputField } from '../../../../../../../Shared/InputFields/InputField/InputField';
import Select, { ISelectOption } from '../../../../../../../Shared/Select/Select';
import { IPreselectionProps } from '../../Preselection';
import {
  IPreselectionFileInformationDto,
  IPreselectedOfferTermsData,
  useGetPreselectedOfferTerms,
  useAddPreselectedOfferTerms,
  useGetPreselectionFileContent,
  termsValidPeriodTexts,
} from '../../Preselection.api';
import { Table } from '../../../../../../../Shared/Table/Table';
import Button from '../../../../../../../Shared/Button/Button';
import formatDate from '../../../../../../../Shared/Formatting/formatDate';
import { isResultError } from '../../../../../../../Shared/Api/response/ICreateResult';
import useStyles from '../../Preselection.styles';

const termsValidPeriodOptions: ISelectOption[] = termsValidPeriodTexts.map((x) => ({ label: x, value: x }));

const OfferTerms = (props: IPreselectionProps) => {
  const classes = useStyles();
  const { setIsLoading, saveCallback, setChildrenComponent } = props;
  const { isLoading, addPreselectedOfferTerms } = useAddPreselectedOfferTerms();
  const { isLoading: isGetLoading, getPreselectedOfferTerms } = useGetPreselectedOfferTerms();
  const { isLoading: isGettingFileLoading, getPreselectionFileContent } = useGetPreselectionFileContent();

  const [saleDescription, changeSaleDescription, setSaleDescription] = useInputState('');
  const [termsIncluding, changeTermsIncluding, setTermsIncluding] = useInputState('');
  const [termsRegulation, changeTermsRegulation, setTermsRegulation] = useInputState('');
  const [termsExcluding, changeTermsExcluding, setTermsExcluding] = useInputState('');
  const [termsWarranty, changeTermsWarranty, setTermsWarranty] = useInputState('');
  const [termsDelivery, changeTermsDelivery, setTermsDelivery] = useInputState('');
  const [termsValidPeriod, setTermsValidPeriod] = useState('30 dagar');
  const [offerTermsFiles, setOfferTermsFiles] = useState<IPreselectionFileInformationDto[]>([]);

  useEffect(() => {
    setIsLoading(isGetLoading || isLoading || isGettingFileLoading);
  }, [isGettingFileLoading, isGetLoading, isLoading, setIsLoading]);

  useEffect(() => {
    const save: any = async () => {
      var result = await addPreselectedOfferTerms({
        saleDescription: saleDescription,
        inclusive: termsIncluding,
        regulation: termsRegulation,
        exclusive: termsExcluding,
        warranty: termsWarranty,
        delivery: termsDelivery,
        validPeriod: termsValidPeriod,
      });
      if (isResultError(result)) {
        return false;
      }
      return true;
    };
    saveCallback(() => save);
  }, [
    saleDescription,
    termsIncluding,
    termsRegulation,
    termsExcluding,
    termsWarranty,
    termsDelivery,
    termsValidPeriod,
    saveCallback,
    addPreselectedOfferTerms,
  ]);

  useEffect(() => {
    async function getValues() {
      const result = await getPreselectedOfferTerms();
      if (result) {
        setSaleDescription(result.latestOfferTermsData.saleDescription);
        setTermsIncluding(result.latestOfferTermsData.inclusive);
        setTermsRegulation(result.latestOfferTermsData.regulation);
        setTermsExcluding(result.latestOfferTermsData.exclusive);
        setTermsWarranty(result.latestOfferTermsData.warranty);
        setTermsDelivery(result.latestOfferTermsData.delivery);
        setTermsValidPeriod(result.latestOfferTermsData.validPeriod);
        setOfferTermsFiles(result.offerTermsFileInformation);
      } else {
        return false;
      }
    }
    getValues();
  }, [
    setSaleDescription,
    setTermsIncluding,
    setTermsRegulation,
    setTermsExcluding,
    setTermsWarranty,
    setTermsDelivery,
    setTermsValidPeriod,
    getPreselectedOfferTerms,
  ]);

  useEffect(() => {
    offerTermsFiles && setChildrenComponent(<OfferTermFileTable />);
  }, [offerTermsFiles, setChildrenComponent]);

  const handleGetPreselectionFile = async (id: string) => {
    let data: IPreselectedOfferTermsData = JSON.parse((await getPreselectionFileContent(id.toString())).content);
    setSaleDescription(data.saleDescription);
    setTermsIncluding(data.inclusive);
    setTermsRegulation(data.regulation);
    setTermsExcluding(data.exclusive);
    setTermsWarranty(data.warranty);
    setTermsDelivery(data.delivery);
    setTermsValidPeriod(data.validPeriod);
  };

  const OfferTermFileTable = () => (
    <Table customSize={{ md: 5, lg: 5 }}>
      <TableHead>
        <TableRow>
          <TableCell>Version</TableCell>
          <TableCell>Skapad</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {offerTermsFiles.map((file) => (
          <TableRow>
            <TableCell>
              <Button
                color="primary"
                variant="text"
                disableTouchRipple
                onClick={() => handleGetPreselectionFile(file.id.toString())}
              >
                {file.name}
              </Button>
            </TableCell>
            <TableCell>{formatDate(new Date(file.created))}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <>
      <div className={`${classes.forPdf} ${classes.wysiwygFields}`}>
        <InputField
          label="Förklarande text på offert"
          type="text"
          multiline
          fullwidth
          rows="5"
          value={saleDescription}
          onChange={changeSaleDescription}
          required
        />
      </div>
      <Fieldset legend="Villkor">
        <div className={classes.containedWysiwygFields}>
          <InputField
            label="Inklusive"
            type="text"
            multiline
            rows="5"
            fullwidth
            value={termsIncluding}
            onChange={changeTermsIncluding}
          />
          <InputField
            label="Enligt regelverk"
            multiline
            fullwidth
            type="text"
            rows="5"
            value={termsRegulation}
            onChange={changeTermsRegulation}
          />
          <InputField
            label="Exklusive"
            multiline
            fullwidth
            type="text"
            rows="5"
            value={termsExcluding}
            onChange={changeTermsExcluding}
          />
          <Select
            inputFieldProps={{ label: 'Giltighetstid', fullwidth: true }}
            selectProps={{
              isClearable: true,
              options: termsValidPeriodOptions,
              value: termsValidPeriodOptions.find((x) => x.value === termsValidPeriod),
              onChange: (option) => {
                setTermsValidPeriod(option ? (option as ISelectOption).value : '');
              },
            }}
          />
          <InputField
            label="Leverans"
            fullwidth
            multiline
            type="text"
            rows="5"
            value={termsDelivery}
            onChange={changeTermsDelivery}
          />
          <InputField
            label="Garanti"
            multiline
            type="text"
            fullwidth
            rows="5"
            value={termsWarranty}
            onChange={changeTermsWarranty}
          />
        </div>
      </Fieldset>
    </>
  );
};

export default OfferTerms;
