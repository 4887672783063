import React, { createContext, useState, useEffect, useContext } from 'react';
import { INavigationItem } from '../Header';
import useUserContext from '../../../../Core/Authentication/UserContext';

interface IHeaderNavigationContext {
  setHeaderNavigationItems: (links: INavigationItem[]) => void;
  setHeaderNavigationText: (links: INavigationItem[]) => void;
  headerNavigationItems: INavigationItem[];
  headerNavigationText: INavigationItem[];
}

const HeaderNavigationContext = createContext<IHeaderNavigationContext>({} as IHeaderNavigationContext);
const useHeaderNavigationContext = () => useContext(HeaderNavigationContext);

interface IProps {
  children: JSX.Element;
}

export function HeaderNavigationContextProvider(props: IProps) {
  const [headerNavigationItems, setHeaderNavigationItems] = useState<INavigationItem[]>([]);
  const [headerNavigationText, setHeaderNavigationText] = useState<INavigationItem[]>([]);
  const { hasAccess } = useUserContext();

  const [headerNavigationContext, setHeaderNavigationContext] = useState<IHeaderNavigationContext>({
    headerNavigationItems: headerNavigationItems,
    headerNavigationText: headerNavigationText,
    setHeaderNavigationItems: setHeaderNavigationItems,
    setHeaderNavigationText: setHeaderNavigationText,
  });

  useEffect(() => {
    setHeaderNavigationContext((prev) => ({
      ...prev,
      headerNavigationItems: headerNavigationItems,
      headerNavigationText: headerNavigationText,
    }));
  }, [headerNavigationItems, headerNavigationText, hasAccess]);
  return (
    <HeaderNavigationContext.Provider value={headerNavigationContext}>
      {props.children}
    </HeaderNavigationContext.Provider>
  );
}

export default useHeaderNavigationContext;
