import React, { useEffect, useState } from 'react';
import BMSNavigationMenu from './NavigationMenu/NavigationMenu';
import ViewBMSNode from './ViewNode/ViewNode';
import EditBMSNode from './EditNode/EditNode';
import { useStyles } from './BMS.styles';
import { useHistory } from 'react-router-dom';

const BMS = (props: { mode?: 'view' | 'edit' }) => {
  const classes = useStyles();
  const history = useHistory();
  const [rootNodeId, setRootNodeId] = useState<number | null>();

  useEffect(() => {
    if (!props.mode && !!rootNodeId) history.push(`${history.location.pathname}/${rootNodeId}`);
  }, [props.mode, rootNodeId, history]);

  return (
    <>
      <div className={classes.container}>
        <span className={classes.bmslList}>
          <div className={classes.navigation}>
            <BMSNavigationMenu rootNodeId={rootNodeId} setRootNodeId={setRootNodeId} />
          </div>
        </span>
        <span className={classes.bmsNodes}>
          {props.mode === 'view' && <ViewBMSNode />}
          {props.mode === 'edit' && <EditBMSNode triggerRefetch={() => setRootNodeId(undefined)} />}
        </span>
      </div>
    </>
  );
};

export default BMS;
