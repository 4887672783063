import React from 'react';
import theme from '../Theme';

const EmptyListIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="97" height="108" viewBox="0 0 97 108" aria-hidden="true">
    <g id="Group_166" data-name="Group 166" transform="translate(-276 -343)">
      <g id="Group_165" data-name="Group 165" transform="translate(-322 11)">
        <g id="Path_65" data-name="Path 65" transform="translate(598 349)" fill={theme.palette.primary.light}>
          <path
            d="M 65 89 L 13 89 C 10.06178665161133 89 7.299453258514404 87.85579681396484 5.221826553344727 85.77817535400391 C 3.144200086593628 83.70054626464844 2 80.93820953369141 2 78 L 2 13 C 2 10.06178665161133 3.144200086593628 7.299453258514404 5.221826553344727 5.221826553344727 C 7.299453258514404 3.144200086593628 10.06178665161133 2 13 2 L 65 2 C 67.93820953369141 2 70.70054626464844 3.144200086593628 72.77817535400391 5.221826553344727 C 74.85579681396484 7.299453258514404 76 10.06178665161133 76 13 L 76 78 C 76 80.93820953369141 74.85579681396484 83.70054626464844 72.77817535400391 85.77817535400391 C 70.70054626464844 87.85579681396484 67.93820953369141 89 65 89 Z"
            stroke="none"
          />
          <path
            d="M 13 4 C 8.037384033203125 4 4 8.037384033203125 4 13 L 4 78 C 4 82.96261596679688 8.037384033203125 87 13 87 L 65 87 C 69.96261596679688 87 74 82.96261596679688 74 78 L 74 13 C 74 8.037384033203125 69.96261596679688 4 65 4 L 13 4 M 13 0 L 65 0 C 72.17971038818359 0 78 5.820289611816406 78 13 L 78 78 C 78 85.17971038818359 72.17971038818359 91 65 91 L 13 91 C 5.820289611816406 91 0 85.17971038818359 0 78 L 0 13 C 0 5.820289611816406 5.820289611816406 0 13 0 Z"
            stroke="none"
            fill={theme.palette.primary.main}
          />
        </g>
        <line
          id="Line_352"
          data-name="Line 352"
          x2="17"
          transform="translate(631.5 417)"
          fill="none"
          stroke={theme.palette.primary.main}
          strokeLinecap="round"
          strokeWidth="4"
        />
        <line
          id="Line_355"
          data-name="Line 355"
          x2="17"
          transform="translate(631.5 391)"
          fill="none"
          stroke={theme.palette.primary.main}
          strokeLinecap="round"
          strokeWidth="4"
        />
        <line
          id="Line_357"
          data-name="Line 357"
          x2="17"
          transform="translate(631.5 365)"
          fill="none"
          stroke={theme.palette.primary.main}
          strokeLinecap="round"
          strokeWidth="4"
        />
        <line
          id="Line_353"
          data-name="Line 353"
          x2="30"
          transform="translate(631.5 425)"
          fill="none"
          stroke={theme.palette.primary.main}
          strokeLinecap="round"
          strokeWidth="4"
        />
        <line
          id="Line_354"
          data-name="Line 354"
          x2="30"
          transform="translate(631.5 399)"
          fill="none"
          stroke={theme.palette.primary.main}
          strokeLinecap="round"
          strokeWidth="4"
        />
        <line
          id="Line_356"
          data-name="Line 356"
          x2="30"
          transform="translate(631.5 373)"
          fill="none"
          stroke={theme.palette.primary.main}
          strokeLinecap="round"
          strokeWidth="4"
        />
        <g id="Path_64" data-name="Path 64" transform="translate(609 413)" fill={theme.palette.common.white}>
          <path
            d="M 13 14 L 3 14 C 2.448600053787231 14 2 13.55140018463135 2 13 L 2 3 C 2 2.448600053787231 2.448600053787231 2 3 2 L 13 2 C 13.55140018463135 2 14 2.448600053787231 14 3 L 14 13 C 14 13.55140018463135 13.55140018463135 14 13 14 Z"
            stroke="none"
          />
          <path
            d="M 4 4 L 4 12 L 12 12 L 12 4 L 4 4 M 3 0 L 13 0 C 14.65684986114502 0 16 1.34315013885498 16 3 L 16 13 C 16 14.65684986114502 14.65684986114502 16 13 16 L 3 16 C 1.34315013885498 16 0 14.65684986114502 0 13 L 0 3 C 0 1.34315013885498 1.34315013885498 0 3 0 Z"
            stroke="none"
            fill={theme.palette.primary.main}
          />
        </g>
        <g
          id="Rectangle_1348"
          data-name="Rectangle 1348"
          transform="translate(609 387)"
          fill={theme.palette.common.white}
          stroke={theme.palette.primary.main}
          strokeWidth="4"
        >
          <rect width="16" height="16" rx="3" stroke="none" />
          <rect x="2" y="2" width="12" height="12" rx="1" fill="none" />
        </g>
        <g
          id="Rectangle_1351"
          data-name="Rectangle 1351"
          transform="translate(609 361)"
          fill={theme.palette.common.white}
          stroke={theme.palette.primary.main}
          strokeWidth="4"
        >
          <rect width="16" height="16" rx="3" stroke="none" />
          <rect x="2" y="2" width="12" height="12" rx="1" fill="none" />
        </g>
      </g>
      <g
        id="Ellipse_15"
        data-name="Ellipse 15"
        transform="translate(331 343)"
        fill={theme.palette.common.white}
        stroke={theme.palette.primary.main}
        strokeWidth="4"
      >
        <circle cx="21" cy="21" r="21" stroke="none" />
        <circle cx="21" cy="21" r="19" fill="none" />
      </g>
      <text
        id="_0"
        data-name="0"
        transform="translate(346 372)"
        fill={theme.palette.primary.main}
        fontSize="24"
        fontFamily={theme.typography.fontFamily}
        fontWeight="700"
      >
        <tspan x="0" y="0">
          0
        </tspan>
      </text>
    </g>
  </svg>
);

export default EmptyListIcon;
