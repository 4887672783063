import React, { useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useGetSupplier, SupplierType, supplierTypes, useSendSurveySupplier } from '../Suppliers.api';
import Container from '../../../../../../Shared/Container/Container';
import { Fieldset } from '../../../../../../Shared/Form/Fieldset/Fieldset';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import Button from '../../../../../../Shared/Button/Button';
import ProductList from './Products/ProductList/ProductList';
import AccessGuard from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { ModuleIdentifiers, AccessRights } from '../../../../../../Core/Authentication/ModuleAccess';
import { ViewAddress } from '../../../../../../Shared/InputFields/Address/ViewAddress';
import { formatDateWithTime } from '../../../../../../Shared/Formatting/formatDate';

const ViewSupplier = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { isLoading, response, getSupplier } = useGetSupplier();
  const { isLoading: isSendSurveyLoading, sendSurvey } = useSendSurveySupplier();

  const get = useCallback(async () => {
    const response = await getSupplier(id);

    if (!response) {
      history.push('admin/supplier');
    }
  }, [getSupplier, history, id]);

  useEffect(() => {
    get();
  }, [get]);

  async function sendSurveyToSupplier() {
    await sendSurvey(id);
    await get();
  }

  return (
    <>
      <Container
        loading={isLoading}
        label={response?.name}
        actions={
          <AccessGuard module={ModuleIdentifiers.Admin} accessRights={AccessRights.Write}>
            <Button color="default" variant="outlined" loading={isSendSurveyLoading} onClick={sendSurveyToSupplier}>
              Skicka ny enkät
            </Button>
            <Button
              color="default"
              variant="outlined"
              onClick={() => history.push(`${history.location.pathname}/update`)}
            >
              Redigera
            </Button>
          </AccessGuard>
        }
      >
        <Fieldset legend="Leverantörens uppgifter">
          <InputField label="Vårt Kundnummer" value={response?.customerNumber} viewOnly />
          <InputField label="Organisationsnummer" value={response?.identificationNumber} viewOnly />
          <ViewAddress address={response?.address} postalCode={response?.postalCode} city={response?.city} />
          <InputField label="Typ" value={supplierTypes.find((x) => x.type === response?.supplierType)?.name} viewOnly />
          <InputField label="Leverantörsnamn" value={response?.name} viewOnly />
          <InputField label="Kod" value={response?.code ? '****' : ''} viewOnly />
          <Fieldset legend="Kontakt order">
            <InputField label="E-post" value={response?.orderEmail} viewOnly />
            <InputField label="Namn" value={response?.orderContact} viewOnly />
            <InputField label="Telefon" value={response?.orderPhone} viewOnly />
          </Fieldset>
          <Fieldset legend="Kontakt support">
            <InputField label="E-post" value={response?.supportEmail} viewOnly />
            <InputField label="Namn" value={response?.supportContact} viewOnly />
            <InputField label="Telefon" value={response?.supportPhone} viewOnly />
          </Fieldset>
          <Fieldset legend="Leverantörsenkät">
            <InputField label="Senast skickat" value={formatDateWithTime(response?.lastSurveySentDate)} viewOnly />
          </Fieldset>
        </Fieldset>
      </Container>
      {response?.supplierType === SupplierType.Hardware && <ProductList />}
    </>
  );
};

export default ViewSupplier;
