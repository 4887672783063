import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Datepicker from '../../../../../../../../../Shared/InputFields/DatePicker/Datepicker';
import { InputField } from '../../../../../../../../../Shared/InputFields/InputField/InputField';
import { ICheckboxItem, useCheckboxField } from './Components/useCheckboxField';
import { IDocumentFormProps } from '../../IDocumentFormProps';
import {
  SecurityChoice,
  useCreateFacilityCertificateDocument,
  useGetFacilityCertificateDocumentForm,
} from '../../Documents.api';
import { ICoreSystem, useGetFacility } from '../../../../../Facilities/Facilities.api';
import { isResultError } from '../../../../../../../../../Shared/Api/response/ICreateResult';
import FacilitySelect from '../../../../../Facilities/FacilitySelect/FacilitySelect';
import useInputState from '../../../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { Buildings } from './Components/Buildings/Buildings';

export const securityChoiceLabels: ICheckboxItem<SecurityChoice>[] = [
  { type: SecurityChoice.ShellProtection, name: 'Indraget skalskydd' },
  { type: SecurityChoice.CompleteProtection, name: 'Komplett skalskydd' },
  { type: SecurityChoice.IntentionalProtection, name: 'Försåtsskydd' },
  { type: SecurityChoice.VolumeProtection, name: 'Volymskydd' },
  { type: SecurityChoice.PartialProtection, name: 'Delskydd' },
  { type: SecurityChoice.PointProtection, name: 'Punktskydd' },
  { type: SecurityChoice.OutdoorDetection, name: 'Utomhusdetekering' },
  { type: SecurityChoice.Dimgenerator, name: 'Dimgenerator' },
  { type: SecurityChoice.SSF1042, name: 'Enligt SSF 1042' },
  { type: SecurityChoice.MaintenanceAgreement, name: 'Underhållsavtal finns' },
  { type: SecurityChoice.SSF1307, name: 'SSF 130:7' },
];

const FacilityCertificate = (props: IDocumentFormProps) => {
  const { customerId, onLoadingChange, saveCallback, documentName, onDocumentCreated, validateFormState } = props;

  const { getFacilityCertificateDocumentForm, isLoading } = useGetFacilityCertificateDocumentForm(
    customerId,
    props.facilityId,
  );
  const { getFacility } = useGetFacility(customerId);
  const { isLoading: isCreateLoading, createFacilityCertificateDocument } = useCreateFacilityCertificateDocument(
    customerId,
  );

  const [receiver, changeReceiver, setReceiver] = useInputState('');
  const [certificateDate, setCertificateDate] = useState<Date>(moment().startOf('day').toDate());
  const [facilityId, setFacilityId] = useState<number | undefined>(
    props.facilityId ? parseInt(props.facilityId) : undefined,
  );
  const [coreSystems, setCoreSystems] = useState<ICoreSystem[]>([]);
  const [coreSystemId, setCoreSystemId] = useState<string>();
  const [buildings, setBuildings] = useState<string[]>([]);
  const [note, changeNote] = useInputState('');
  const [SecurityChoicesComponent, securityChoices] = useCheckboxField('Val', securityChoiceLabels);

  useEffect(() => {
    validateFormState && validateFormState(!!facilityId);
  }, [validateFormState, facilityId]);

  useEffect(() => {
    async function loadForm() {
      const form = await getFacilityCertificateDocumentForm();
      if (form) {
        setReceiver(form.receiver);
        setFacilityId(form.facilityId);
        setCoreSystemId(form.coreSystemId.toString());
      }
    }
    loadForm();
  }, [getFacilityCertificateDocumentForm, setReceiver]);

  useEffect(() => {
    onLoadingChange(isLoading || isCreateLoading);
  }, [onLoadingChange, isLoading, isCreateLoading]);

  useEffect(() => {
    async function loadFacility() {
      var response = await getFacility(facilityId?.toString());
      setCoreSystems(response.coreSystems);

      if (response.coreSystems.length === 1) {
        setCoreSystemId(response.coreSystems[0].id.toString());
      } else {
        setCoreSystemId(undefined);
      }
    }
    if (facilityId !== undefined) {
      loadFacility();
    }
  }, [facilityId, getFacility]);

  const alarmClass = useMemo(() => {
    return (
      coreSystems.find((coreSystem: ICoreSystem) => coreSystem.id === Number(coreSystemId))?.mainProductRegulation ?? ''
    );
  }, [coreSystems, coreSystemId]);

  const save = useCallback(() => {
    async function innerSave() {
      const result = await createFacilityCertificateDocument({
        documentName: documentName,
        receiver: receiver ? receiver : null,
        certificateDate: certificateDate,
        facilityId: facilityId!,
        buildings: buildings,
        note: note ? note : null,
        securityChoices: securityChoices,
        alarmClass: alarmClass ? alarmClass : null,
      });
      if (!isResultError(result)) onDocumentCreated(Number(result as string));
    }
    innerSave();
  }, [
    createFacilityCertificateDocument,
    documentName,
    receiver,
    certificateDate,
    facilityId,
    buildings,
    note,
    securityChoices,
    alarmClass,
    onDocumentCreated,
  ]);

  useEffect(() => {
    saveCallback(save);
  }, [saveCallback, save]);

  return (
    <>
      <InputField label="Mottagare" type="text" value={receiver} onChange={changeReceiver} />
      <Datepicker
        label="Datum"
        required
        selected={certificateDate}
        onChange={(d: Date | null) => setCertificateDate(d as Date)}
      />
      <FacilitySelect
        customerId={customerId}
        label="Välj plats"
        required
        selectedFacilityId={facilityId?.toString()}
        onChange={(selected) => {
          setFacilityId(selected ? Number(selected) : undefined);
          setCoreSystemId(undefined);
        }}
      />
      <Buildings rows={buildings} setRows={setBuildings} />
      <InputField
        label="Notering/avvikelse"
        fullwidth
        multiline
        rows={5}
        type="text"
        value={note}
        onChange={changeNote}
      />
      <Grid item md={12}>
        {SecurityChoicesComponent}
      </Grid>
    </>
  );
};

export default FacilityCertificate;
