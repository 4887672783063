import React, { useState } from 'react';
import { MenuItem } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVertRounded';
import PopperMenu from '../../../../../Shared/Popper/Popper';
import { isErrorResponse } from '../../../../../Shared/Api/response/IErrorRespose';
import useErrorMessage from '../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import ServiceForm from '../ServiceForm/ServiceForm';
import BookOnService from '../BookOnOrder/BookOnService';
import { ServiceDto, useDeleteCompanyMessage } from '../../Forum.api';
import { useStyles } from '../Service.styles';

type IServiceMenuProps = {
  service: ServiceDto;
  onChange: () => void;
};

export default function ServiceMenu(props: IServiceMenuProps) {
  const classes = useStyles();
  const { service, onChange } = props;

  const { deleteCompanyMessage } = useDeleteCompanyMessage();
  const { setErrorMessage } = useErrorMessage();

  const [openMenu, setOpenMenu] = useState(false);
  const [openBooking, setOpenBooking] = useState(false);
  const [openEditService, setOpenEditService] = useState(false);

  async function handleCancelService(id: string) {
    const result = await deleteCompanyMessage(id);
    if (isErrorResponse(result)) {
      setErrorMessage({ message: result.message });
    } else {
      onChange();
    }
  }

  return (
    <>
      <PopperMenu
        buttonType="icon"
        open={openMenu}
        setOpen={setOpenMenu}
        content={
          <>
            <MenuItem autoFocus onClick={() => setOpenBooking(true)}>
              Boka
            </MenuItem>
            <MenuItem autoFocus onClick={() => setOpenEditService(true)}>
              Redigera
            </MenuItem>
            <MenuItem className={classes.remove} autoFocus onClick={() => handleCancelService(service.id.toString())}>
              Ta bort
            </MenuItem>
          </>
        }
      >
        <MoreVert />
      </PopperMenu>
      <BookOnService
        title="Boka"
        serviceId={service.id}
        customerId={service.customerId}
        facilityId={service.facilityId}
        description={service.description}
        open={openBooking}
        setOpen={setOpenBooking}
        onSave={onChange}
      />
      <ServiceForm
        title={'Redigera Service'}
        service={service}
        open={openEditService}
        setOpen={setOpenEditService}
        onUpsert={() => onChange()}
      />
    </>
  );
}
