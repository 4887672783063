import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Container from '../../../../../../Shared/Container/Container';
import Datepicker from '../../../../../../Shared/InputFields/DatePicker/Datepicker';
import Button from '../../../../../../Shared/Button/Button';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import { isResultError } from '../../../../../../Shared/Api/response/ICreateResult';
import useFormValidation from '../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import useInputState from '../../../../../../Shared/Hooks/UseInputState/UseInputState';
import {
  IUpsertMeetingNotesCommentCommand,
  IUpsertMeetingNotesCommentValidationErrors,
  useGetMeetingNotesComment,
  useUpsertMeetingNotesComment,
} from '../MeetingNotes.api';

const MeetingNotesCommentForm = () => {
  const history = useHistory();
  const { id, commentId } = useParams<{ id: string; commentId: string }>();
  const { isFormValid, formRef } = useFormValidation();
  const { isLoading: isGetLoading, getComment } = useGetMeetingNotesComment(id, commentId);
  const { isLoading: isUpsertLoading, upsert } = useUpsertMeetingNotesComment(id);
  const [commentDate, setCommentDate] = useState(new Date());
  const [topic, changeTopic, setTopic] = useInputState('');
  const [content, changeContent, setContent] = useInputState('');
  const [errors, setErrors] = useState<IUpsertMeetingNotesCommentValidationErrors>();
  const isValid = isFormValid && !!commentDate;

  useEffect(() => {
    async function loadComment() {
      const response = await getComment();
      if (!response) history.push(`/todos/meetingnotes/${id}`);
      setCommentDate(new Date(response.date));
      setTopic(response.topic);
      setContent(response.content);
    }

    if (commentId) {
      loadComment();
    }
  }, [id, commentId, history, getComment, setTopic, setContent]);

  const goBackToView = () => history.push(`/todos/meetingnotes/${id}`);

  async function handleUpsert() {
    if (!commentDate) return;
    const upsertCommand: IUpsertMeetingNotesCommentCommand = {
      date: commentDate,
      topic: topic,
      content: content,
    };

    const result = await upsert(upsertCommand, commentId);

    isResultError(result) ? setErrors(result) : goBackToView();
  }

  function Actions() {
    return (
      <>
        <Button color="default" variant="outlined" onClick={goBackToView}>
          Avbryt
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!isValid}
          loading={isUpsertLoading}
          onClick={handleUpsert}
        >
          {id ? 'Spara' : 'Skapa'}
        </Button>
      </>
    );
  }

  return (
    <Container
      form
      ref={formRef}
      label={id ? 'Redigera kommentar' : 'Skapa kommentar'}
      actions={<Actions />}
      loading={isGetLoading}
    >
      <Datepicker
        label="Datum"
        required
        onChange={(d: Date | null) => setCommentDate(d as Date)}
        selected={commentDate}
        errorText={errors?.date}
      />
      <InputField
        label="Rubrik"
        fullwidth
        required
        type="text"
        value={topic}
        onChange={changeTopic}
        errorText={errors?.topic}
      />
      <InputField
        label="Text"
        required
        type="text"
        fullwidth
        multiline
        rows={3}
        value={content}
        onChange={changeContent}
        errorText={errors?.content}
      />
    </Container>
  );
};

export default MeetingNotesCommentForm;
