import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  updateDiv: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  yesColor: {
    color: theme.palette.success.main,
    cursor: 'pointer',
  },
  noColor: {
    color: theme.palette.error.main,
    cursor: 'pointer',
  },
  iconDiv: {
    alignSelf: 'center',
  },
}));

export default useStyles;
