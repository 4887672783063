import { GridSize } from '@material-ui/core';
import React from 'react';
import { CustomerType, customerTypes } from '../../../../../Shared/Misc/CustomerType';
import Select, { ISelectOption } from '../../../../../Shared/Select/Select';

interface ICustomerTypeOption extends ISelectOption {
  type: CustomerType;
}

export interface ICustomerTypeSelectProps {
  selected: CustomerType;
  onChange: (type: CustomerType) => void;
  fullwidth?: boolean;
  customerWidth?: GridSize;
  required?: boolean;
  disabled?: boolean;
}

const CustomerTypeSelect = (props: ICustomerTypeSelectProps) => {
  return (
    <Select
      inputFieldProps={{
        label: 'Kundtyp',
        required: props.required,
        customWidth: props.customerWidth,
        fullwidth: props.fullwidth,
      }}
      selectProps={{
        isDisabled: props.disabled,
        value: customerTypes.find((x) => x.type === props.selected),
        onChange: (selectedType) => {
          props.onChange((selectedType as ICustomerTypeOption).type);
        },
        options: customerTypes,
      }}
    ></Select>
  );
};

export default CustomerTypeSelect;
