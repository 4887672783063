import React, { useState, useEffect, useCallback } from 'react';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import useInputState from '../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { useHistory, useParams } from 'react-router-dom';
import {
  IUpsertProductCategoryBody,
  useUpsertProductCategory,
  IUpsertProductCategoryValidationErrors,
  useGetProductCategory,
} from '../ProductCategories.api';
import Button from '../../../../../../Shared/Button/Button';
import Container from '../../../../../../Shared/Container/Container';
import useFormValidation from '../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';

export const ProductCategoryForm = () => {
  const history = useHistory();
  const [name, changeName, setName] = useInputState('');
  const { id } = useParams<{ id: string }>();
  const { isLoading: isLoadingGetProductCategory, getProductCategory } = useGetProductCategory();
  const { isLoading, upsertProductCategory } = useUpsertProductCategory();
  const { isFormValid, formRef } = useFormValidation();
  const [errors, setErrors] = useState<IUpsertProductCategoryValidationErrors>();

  const goToProductCategoryList = useCallback(() => {
    history.push('/admin/productcategory');
  }, [history]);

  useEffect(() => {
    async function getProductCategoryAsync() {
      const retreivedProductCategory = await getProductCategory(id);
      if (retreivedProductCategory) {
        setName(retreivedProductCategory.name);
      } else {
        goToProductCategoryList();
      }
    }

    if (id) {
      getProductCategoryAsync();
    }
  }, [id, getProductCategory, setName, goToProductCategoryList]);

  async function upsert() {
    const body: IUpsertProductCategoryBody = {
      name: name,
    };

    const result = await upsertProductCategory(body, id);

    if (result && result.name) {
      setErrors(result);
    } else {
      goToProductCategoryList();
    }
  }

  const Actions = () => {
    return (
      <>
        <Button variant="outlined" onClick={goToProductCategoryList}>
          Avbryt
        </Button>
        <Button loading={isLoading} variant="contained" color="primary" disabled={!isFormValid} onClick={upsert}>
          Spara
        </Button>
      </>
    );
  };

  return (
    <Container
      form
      label={id ? 'Redigera produktkategori' : 'Skapa produktkategori'}
      actions={<Actions />}
      ref={formRef}
      loading={isLoadingGetProductCategory}
    >
      <InputField
        fullwidth
        label="Namn"
        type="text"
        value={name}
        onChange={changeName}
        errorText={errors?.name}
        required
      />
    </Container>
  );
};
