import React, { useEffect, useCallback, useState } from 'react';
import { useRouteMatch, Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import useHeaderNavigationContext from '../../../Shared/Navigation/Header/HeaderNavigationContext/HeaderNavigationContext';
import { INavigationItem } from '../../../Shared/Navigation/Header/Header';
import { EditUser } from './Components/Users/EditUser/EditUser';
import UserList from './Components/Users/UserList/UserList';
import ProductCategoryList from './Components/ProductCategories/ProductCategoryList/ProductCategoryList';
import Preselection from './Components/Preselection/Preselection';
import SupplierList from './Components/Suppliers/SupplierList/SupplierList';
import ViewSupplier from './Components/Suppliers/ViewSupplier/ViewSupplier';
import SupplierForm from './Components/Suppliers/SupplierForm/SupplierForm';
import SystemTypeList from './Components/SystemTypes/SystemTypeList/SystemTypeList';
import { ProductCategoryForm } from './Components/ProductCategories/ProductCategoryForm/ProductCategoryForm';
import SystemTypeForm from './Components/SystemTypes/SystemTypeForm/SystemTypeForm';
import ProductForm from './Components/Suppliers/ViewSupplier/Products/ProductForm/ProductForm';
import AuthRoute from '../../../Core/Authentication/AuthRoute/AuthRoute';
import { ModuleIdentifiers, AccessRights } from '../../../Core/Authentication/ModuleAccess';
import Settings from './Components/Settings/Settings';
import SurveyList from './Components/Surveys/SurveyList/SurveyList';
import EditSurvey from './Components/Surveys/EditSurvey/EditSurvey';
import Statistics from './Components/Statistics/Statistics';
import ProductionUserDetails from './Components/Statistics/Production/ProductionUserDetails/ProductionUserDetails';
import SystemLogList from './Components/SystemLog/SystemLogList/SystemLogList';
import CompanyBranchForm from './Components/Settings/CompanyBranch/CompanyBranchForm/CompanyBranchForm';
import FormatList from './Components/Format/FormatList/FormatList';
import { EntityType, useGetBreadCrumb } from '../../../Shared/Navigation/Breadcrumbs/Breadcrumbs.api';
import ProductsList from './Components/Products/ProductsList/ProductsList';
import SurveyResponseList from './Components/Surveys/SurveyResponseList/SurveyResponseList';

function AdminRoutes() {
  const id = '[\\d]+';
  const { path } = useRouteMatch();
  const location = useLocation();
  const { setHeaderNavigationItems, setHeaderNavigationText } = useHeaderNavigationContext();
  const history = useHistory();
  const { getBreadCrumb } = useGetBreadCrumb();
  const [supplierName, setSupplierName] = useState<string>();

  useEffect(() => {
    async function handleBreadCrumb() {
      const supplierName = location.pathname.match(new RegExp(id, 'g'));

      supplierName &&
        setSupplierName(
          await (await getBreadCrumb({ entityId: Number(supplierName[0]), type: EntityType.Supplier }))?.breadCrumbName,
        );
    }
    handleBreadCrumb();
  }, [location.pathname, getBreadCrumb]);

  useEffect(() => {
    const navigationItems: INavigationItem[] = [
      {
        title: 'Användare',
        to: `${path}/user`,
        exact: false,
      },
      {
        title: 'Leverantörer',
        to: `${path}/supplier`,
        exact: false,
      },
      {
        title: 'Produktkategorier',
        to: `${path}/productcategory`,
        exact: false,
      },

      {
        title: 'Produkter',
        to: `${path}/products`,
        exact: false,
      },
      {
        title: 'Systemtyper',
        to: `${path}/systemtype`,
        exact: false,
      },
      {
        title: 'Inställningar',
        to: `${path}/settings`,
        exact: false,
      },
      {
        title: 'Texter och förval',
        to: `${path}/preselection`,
        exact: false,
      },
      {
        title: 'Format',
        to: `${path}/Format`,
        exact: false,
      },
      {
        title: 'Utskick',
        to: `${path}/surveys`,
        exact: false,
      },
      {
        title: 'Statistik',
        to: `${path}/stats`,
        exact: false,
      },
      {
        title: 'Loggbok',
        to: `${path}/logs`,
        exact: false,
      },
    ];

    const HeaderNavigation: INavigationItem[] = [
      {
        title: 'Redigera användare',
        to: `${path}/user/${id}`,
        exact: false,
      },
      {
        title: 'Redigera leverantör',
        to: `${path}/supplier/${id}/update`,
        exact: false,
      },
      {
        title: 'Redigera produkt',
        to: `${path}/supplier/${id}/product/${id}`,
        exact: false,
      },
      {
        title: 'Skapa produkt',
        to: `${path}/supplier/${id}/product`,
        exact: false,
      },
      {
        title: 'Leverantör',
        to: `${path}/supplier/`,
        exact: false,
      },
      {
        title: 'Statistik försäljning',
        to: `${path}/stats/sales`,
        exact: false,
      },
      {
        title: 'Statistik produktion',
        to: `${path}/stats/production`,
        exact: false,
      },
      {
        title: 'Statistik månadsrapport',
        to: `${path}/stats/month`,
        exact: false,
      },
      {
        title: 'Statistik felrapporter',
        to: `${path}/stats/error-reports`,
        exact: false,
      },
      {
        title: 'Redigera systemtyp',
        to: `${path}/systemtype/${id}`,
        exact: false,
      },
      {
        title: 'Skapa utskick',
        to: `${path}/surveys/create`,
        exact: false,
      },
      {
        title: 'Redigera utskick',
        to: `${path}/surveys/${id}/update`,
        exact: false,
      },
      {
        title: 'Utskickssvar',
        to: `${path}/surveys/${id}/answers`,
        exact: false,
      },
      {
        title: 'Redigera filial',
        to: `${path}/settings/branch/${id}`,
        exact: false,
      },
      {
        title: 'Skapa filial',
        to: `${path}/settings/branch/create`,
        exact: false,
      },
      {
        title: 'Inställningar filial',
        to: `${path}/settings/branch`,
        exact: false,
      },
      {
        title: 'Inställningar Företag',
        to: `${path}/settings`,
        exact: false,
      },
      {
        title: 'Format',
        to: `${path}/Format`,
        exact: false,
      },
      {
        title: 'Skapa format',
        to: `${path}/Format/create`,
        exact: false,
      },
      {
        title: 'Redigera produkt',
        to: `${path}/productcategory/${id}/edit`,
        exact: false,
      },
    ];

    setHeaderNavigationText(HeaderNavigation.concat(navigationItems));
    setHeaderNavigationItems(navigationItems);

    return () => {
      setHeaderNavigationItems([]);
    };
  }, [path, setHeaderNavigationText, setHeaderNavigationItems]);

  const handleCancelEditUser = useCallback(() => history.push(`${path}/user`), [history, path]);

  return (
    <Switch>
      <AuthRoute exact path={path} module={ModuleIdentifiers.Admin}>
        <Redirect to={`${path}/user`} />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/user`}
        module={ModuleIdentifiers.Admin}
        breadcrumbs={[{ title: 'Admin' }, { title: 'Användare' }]}
      >
        <h1 className={'sr-only'}>Lista över användare</h1>
        <UserList onUserClick={(user) => history.push(`${path}/user/${user.id}`)} />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/user/create`}
        module={ModuleIdentifiers.Admin}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Admin' },
          {
            title: 'Användare',
            to: `${path}/user`,
          },
          {
            title: 'Lägg till ny användare',
          },
        ]}
      >
        <h1 className={'sr-only'}>Skapa användare</h1>
        <EditUser onUserSaved={() => history.push(`${path}/user`)} onCancel={handleCancelEditUser} />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/user/:id`}
        module={ModuleIdentifiers.Admin}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Admin' },
          {
            title: 'Användare',
            to: `${path}/user`,
          },
          {
            title: 'Redigera',
          },
        ]}
      >
        <h1 className={'sr-only'}>Redigera användare</h1>
        <EditUser onUserSaved={() => history.push(`${path}/user`)} onCancel={handleCancelEditUser} />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/supplier`}
        module={ModuleIdentifiers.Admin}
        breadcrumbs={[{ title: 'Admin' }, { title: 'Leverantörer' }]}
      >
        <h1 className={'sr-only'}>Lista över leverantörer</h1>
        <SupplierList />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/supplier/create`}
        module={ModuleIdentifiers.Admin}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Admin' },
          {
            title: 'Leverantörer',
            to: `${path}/supplier`,
          },
          {
            title: 'Skapa leverantör',
          },
        ]}
      >
        <h1 className={'sr-only'}>Skapa leverantör</h1>
        <SupplierForm />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/supplier/:id`}
        module={ModuleIdentifiers.Admin}
        breadcrumbs={[
          { title: 'Admin' },
          {
            title: 'Leverantörer',
            to: `${path}/supplier`,
          },
          {
            title: supplierName || 'Leverantör',
          },
        ]}
      >
        <h1 className={'sr-only'}>Information om leverantör</h1>
        <ViewSupplier />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/supplier/:id/update`}
        module={ModuleIdentifiers.Admin}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Admin' },
          {
            title: 'Leverantörer',
            to: `${path}/supplier`,
          },
          {
            title: supplierName || 'Leverantör',
            to: `${path}/supplier/:id`,
          },
          {
            title: 'Redigera',
          },
        ]}
      >
        <h1 className={'sr-only'}>Uppdatera leverantör</h1>
        <SupplierForm />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/supplier/:supplierId/product/:id`}
        module={ModuleIdentifiers.Admin}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Admin' },
          {
            title: 'Leverantörer',
            to: `${path}/supplier`,
          },
          {
            title: supplierName || 'Leverantör',
            to: `${path}/supplier/:supplierId`,
          },
          {
            title: 'Redigera produkt',
          },
        ]}
      >
        <h1 className={'sr-only'}>Redigera produkt</h1>
        <ProductForm />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/supplier/:supplierId/product`}
        module={ModuleIdentifiers.Admin}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Admin' },
          {
            title: 'Leverantörer',
            to: `${path}/supplier`,
          },
          {
            title: supplierName || 'Leverantör',
            to: `${path}/supplier/:supplierId`,
          },
          {
            title: 'Skapa produkt',
          },
        ]}
      >
        <h1 className={'sr-only'}>Redigera produkt</h1>
        <ProductForm />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/preselection`}
        module={ModuleIdentifiers.Admin}
        accessRights={AccessRights.Write}
        breadcrumbs={[{ title: 'Admin' }, { title: 'Texter och förval' }]}
      >
        <h1 className={'sr-only'}>Texter och förval</h1>
        <Preselection />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/productcategory`}
        module={ModuleIdentifiers.Admin}
        breadcrumbs={[{ title: 'Admin' }, { title: 'Produktkategorier' }]}
      >
        <h1 className={'sr-only'}>Lista över produktkategorier</h1>
        <ProductCategoryList />
      </AuthRoute>

      <AuthRoute
        exact
        path={[`${path}/products/:tabId`, `${path}/products`]}
        module={ModuleIdentifiers.Admin}
        breadcrumbs={[{ title: 'Admin' }, { title: 'Produkter' }]}
      >
        <h1 className={'sr-only'}>Lista över produkter</h1>
        <ProductsList />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/productcategory/create`}
        module={ModuleIdentifiers.Admin}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Admin' },
          {
            title: 'Produktkategorier',
            to: `${path}/productcategory`,
          },
          {
            title: 'Skapa produktkategori',
          },
        ]}
      >
        <h1 className={'sr-only'}>Skapa ny produktkategori</h1>
        <ProductCategoryForm />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/productcategory/:id/edit`}
        module={ModuleIdentifiers.Admin}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Admin' },
          {
            title: 'Produktkategorier',
            to: `${path}/productcategory`,
          },
          {
            title: 'Redigera produktkategori',
          },
        ]}
      >
        <h1 className={'sr-only'}>Redigera produktkategori</h1>
        <ProductCategoryForm />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/systemtype`}
        module={ModuleIdentifiers.Admin}
        breadcrumbs={[{ title: 'Admin' }, { title: 'Systemtyper' }]}
      >
        <h1 className={'sr-only'}>Lista över systemtyper</h1>
        <SystemTypeList />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/systemtype/create`}
        module={ModuleIdentifiers.Admin}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Admin' },
          {
            title: 'Systemtyper',
            to: `${path}/systemtype`,
          },
          {
            title: 'Skapa systemtyp',
          },
        ]}
      >
        <h1 className={'sr-only'}>Skapa systemtyp</h1>
        <SystemTypeForm />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/systemtype/:id`}
        module={ModuleIdentifiers.Admin}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Admin' },
          {
            title: 'Systemtyper',
            to: `${path}/systemtype`,
          },
          {
            title: 'Redigera systemtyp',
          },
        ]}
      >
        <h1 className={'sr-only'}>Redigera systemtyp</h1>
        <SystemTypeForm />
      </AuthRoute>

      <AuthRoute
        exact
        path={[`${path}/settings/:tabId`, `${path}/settings`]}
        module={ModuleIdentifiers.Admin}
        breadcrumbs={[{ title: 'Admin' }, { title: 'Inställningar' }]}
      >
        <h1 className={'sr-only'}>Inställningar för applikationen</h1>
        <Settings />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/format`}
        module={ModuleIdentifiers.Admin}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Admin' },
          {
            title: 'Format',
          },
        ]}
      >
        <FormatList />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/settings/branch/create`}
        module={ModuleIdentifiers.Admin}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Inställningar',
            to: `${path}/settings/branch`,
          },
          {
            title: 'Skapa filial',
          },
        ]}
      >
        <h1 className={'sr-only'}>Skapa filial</h1>
        <CompanyBranchForm />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/settings/branch/:id`}
        module={ModuleIdentifiers.Admin}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          {
            title: 'Inställningar',
            to: `${path}/settings/branch`,
          },
          {
            title: 'Redigera filial',
          },
        ]}
      >
        <h1 className={'sr-only'}>Redigera filial</h1>
        <CompanyBranchForm />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/surveys`}
        module={ModuleIdentifiers.Admin}
        breadcrumbs={[{ title: 'Admin' }, { title: 'Utskick' }]}
      >
        <h1 className={'sr-only'}>Lista över utskick</h1>
        <SurveyList />
      </AuthRoute>
      <AuthRoute
        exact
        path={[`${path}/surveys/create`]}
        module={ModuleIdentifiers.Admin}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Admin' },
          {
            title: 'Utskick',
            to: `${path}/surveys`,
          },
          {
            title: 'Skapa utskick',
          },
        ]}
      >
        <h1 className={'sr-only'}>Skapa utskick</h1>
        <EditSurvey />
      </AuthRoute>
      <AuthRoute
        exact
        path={[`${path}/surveys/:id/update`]}
        module={ModuleIdentifiers.Admin}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Admin' },
          {
            title: 'Utskick',
            to: `${path}/surveys`,
          },
          {
            title: 'Redigera utskick',
          },
        ]}
      >
        <h1 className={'sr-only'}>Redigera utskick</h1>
        <EditSurvey />
      </AuthRoute>
      <AuthRoute
        exact
        path={[`${path}/surveys/:id/answers`]}
        module={ModuleIdentifiers.Admin}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Admin' },
          {
            title: 'Utskick',
            to: `${path}/surveys`,
          },
          {
            title: 'Utskickssvar',
          },
        ]}
      >
        <h1 className={'sr-only'}>Utskickssvar</h1>
        <SurveyResponseList />
      </AuthRoute>
      <AuthRoute
        exact
        path={[`${path}/stats/production/details/:userId`]}
        module={ModuleIdentifiers.Admin}
        accessRights={AccessRights.Write}
        breadcrumbs={[
          { title: 'Admin' },
          {
            title: 'Statistik',
          },
          {
            title: 'Produktionsdetailjer',
          },
        ]}
      >
        <h1 className={'sr-only'}>Detailjer för produktion</h1>
        <ProductionUserDetails />
      </AuthRoute>
      <AuthRoute
        path={[`${path}/stats/:tabId`, `${path}/stats`]}
        module={ModuleIdentifiers.Admin}
        accessRights={AccessRights.Read}
        breadcrumbs={[{ title: 'Admin' }, { title: 'Statistik' }]}
      >
        <h1 className={'sr-only'}>Statistik</h1>
        <Statistics />
      </AuthRoute>

      <AuthRoute
        exact
        path={[`${path}/logs`]}
        module={ModuleIdentifiers.Admin}
        accessRights={AccessRights.Read}
        breadcrumbs={[
          { title: 'Admin' },
          {
            title: 'Loggbok',
          },
        ]}
      >
        <SystemLogList customSize={{ fullsize: true }} />
      </AuthRoute>

      <Route path={`${path}/*`}>
        <Redirect to={`${path}/user`} />
      </Route>
    </Switch>
  );
}

export default AdminRoutes;
