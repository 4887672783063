import React, { useEffect, useState } from 'react';
import { Grid, Tab } from '@material-ui/core';
import Tabs from '../../../../../Shared/Tabs/Tabs';
import ProductionList from './Production/ProductionList/ProductionList';
import MonthlyReport from './MonthlyReport/MonthlyReport';
import ErrorReport from '../ErrorReport/ErrorReport';
import Overview from './Overview/Overview';
import Sales from './Sales/Sales';
import { useHistory, useParams } from 'react-router-dom';

const tabUrls = ['', 'sales', 'production', 'month', 'survey', 'error-reports'];
const Statistics = () => {
  const { tabId } = useParams<{ tabId: string }>();
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState<number>();

  useEffect(() => {
    if (tabId) {
      setTabIndex(tabUrls.indexOf(tabId));
    }
  }, [tabId]);

  return (
    <Grid container item md={12} xs={12} direction="column">
      <Tabs
        customSize={{ lg: 12, md: 12 }}
        onChange={(newIndex) => history.push(`/admin/stats/${tabUrls[newIndex]}`)}
        startIndex={tabIndex}
      >
        <Tab label="Översikt">
          <Overview />
        </Tab>
        <Tab label="Försäljning">
          <Sales />
        </Tab>
        <Tab label="Produktion">
          <ProductionList />
        </Tab>
        <Tab label="Månadsrapport">
          <MonthlyReport />
        </Tab>
        <Tab label="Felrapporter">
          <ErrorReport />
        </Tab>
      </Tabs>
    </Grid>
  );
};

export default Statistics;
