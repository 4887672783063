import React, { useEffect, useState } from 'react';
import { IErrorReportStepProps } from '../ReportForm';
import Button from '../../../../../../../../Shared/Button/Button';
import Container from '../../../../../../../../Shared/Container/Container';
import { Fieldset } from '../../../../../../../../Shared/Form/Fieldset/Fieldset';
import { InputField } from '../../../../../../../../Shared/InputFields/InputField/InputField';
import useFormValidation from '../../../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import RadioField from '../../../../../../../../Shared/InputFields/RadioField/RadioField';
import { FormControlLabel, Radio } from '@material-ui/core';
import {
  useSetPostMeasureAnalysis,
  ISetPostMeasureAnalysisCommand,
  MeasureStatus,
  ISetPostMeasureAnalysisCommandValidationErrors,
  useGetErrorReportPdf,
  ErrorReportState,
} from '../../../Reports.api';
import { isResultError } from '../../../../../../../../Shared/Api/response/ICreateResult';
import useInputState from '../../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import DownloadButton from '../../../../../../../../Shared/DownloadButton/DownloadButton';

const PostMeasureAnalysis: React.FunctionComponent<IErrorReportStepProps> = (props: IErrorReportStepProps) => {
  const { errorReport, onSave, errorReportIsLoading, children } = props;
  const { isLoading, setPostMeasureAnalysis } = useSetPostMeasureAnalysis(errorReport?.id.toString());
  const { getPdf, isLoading: isPdfLoading } = useGetErrorReportPdf(errorReport?.id.toString());
  const { isFormValid, formRef } = useFormValidation();

  const [measureEffectAnalysis, changeMeasureEffectAnalysis, setMeasureEffectAnalysis] = useInputState('');
  const [processNumber, changeProcessNumber, setProcessNumber] = useInputState('');
  const [measureStatus, setMeasureStatus] = useState<MeasureStatus>();

  const [errors, setErrors] = useState<ISetPostMeasureAnalysisCommandValidationErrors>();

  const isValid = isFormValid && measureStatus;

  useEffect(() => {
    if (errorReport) {
      errorReport.measureEffectAnalysis && setMeasureEffectAnalysis(errorReport.measureEffectAnalysis);
      errorReport.processNumber && setProcessNumber(errorReport.processNumber);
      errorReport.measureStatus && setMeasureStatus(errorReport.measureStatus);
    }
  }, [errorReport, setMeasureEffectAnalysis, setProcessNumber]);

  async function handleSave() {
    const command: ISetPostMeasureAnalysisCommand = {
      measureEffectAnalysis: measureEffectAnalysis,
      processNumber: processNumber,
      measureStatus: measureStatus as MeasureStatus,
    };

    const result = await setPostMeasureAnalysis(command);

    if (isResultError(result)) {
      setErrors(result);
    } else {
      onSave(false);
    }
  }

  function Actions() {
    return (
      <>
        {props.actions}
        {errorReport?.errorReportState === ErrorReportState.Analysed && (
          <Button
            color="primary"
            variant="contained"
            disabled={!isValid}
            loading={isLoading}
            onClick={() => handleSave()}
          >
            Spara och arkivera
          </Button>
        )}
      </>
    );
  }

  return (
    <Container form ref={formRef} loading={errorReportIsLoading} label="Redigera felrapport" actions={<Actions />}>
      {children}
      <Fieldset legend="Steg 4 - Efteranalys">
        <InputField
          required
          label="Efteranalys av åtgärdens effekt"
          multiline
          rows={5}
          type="text"
          value={measureEffectAnalysis}
          onChange={changeMeasureEffectAnalysis}
          errorText={errors?.measureEffectAnalysis}
        />
        <InputField
          label="Processnummer"
          type="text"
          value={processNumber}
          onChange={changeProcessNumber}
          errorText={errors?.processNumber}
        />
        <RadioField label="Åtgärd">
          <FormControlLabel
            label="Korrigerande åtgärd införd och avslutad"
            control={
              <Radio
                color="primary"
                checked={measureStatus === MeasureStatus.Implemented}
                onChange={(_, checked) => checked && setMeasureStatus(MeasureStatus.Implemented)}
              />
            }
          />
          <FormControlLabel
            label="Åtgärd ej nödvändig"
            control={
              <Radio
                color="primary"
                checked={measureStatus === MeasureStatus.ImplementationNotNecessary}
                onChange={(_, checked) => checked && setMeasureStatus(MeasureStatus.ImplementationNotNecessary)}
              />
            }
          />
        </RadioField>
        <DownloadButton loading={isPdfLoading} onClick={getPdf}>
          Ladda ner som pdf
        </DownloadButton>
      </Fieldset>
    </Container>
  );
};

export default PostMeasureAnalysis;
