import React from 'react';
import { Typography } from '@material-ui/core';
import { ContainerContext } from '../../../../../../../Shared/Container/Container';
import { useStyles } from './ResponseContainer.styles';

interface IResponseContainerProps {
  label: string;
  date?: string;
  loading?: boolean;
  children?: React.ReactNode;
}

const ResponseContainer = (props: IResponseContainerProps) => {
  const classes = useStyles();

  const labelAsId = props.label.replace(/\s/g, '');

  return (
    <ContainerContext.Provider value={{ loading: props.loading }}>
      <section className={classes.root} aria-labelledby={labelAsId}>
        <div className={classes.labelContainer}>
          <Typography className={classes.label} variant="h4" component="h3" id={labelAsId}>
            {props.label}
          </Typography>
          <span>{props.date}</span>
        </div>
        <div>{props.children}</div>
      </section>
    </ContainerContext.Provider>
  );
};
export default ResponseContainer;
