import React from 'react';
import { red } from '../../../../Styles/Theme';

export const HeaderCalendarContent: React.FC<any> = (props: any) => {
  const { date } = props;
  const dayOfWeek = date.getDay();

  const styleName = dayOfWeek === 0 || dayOfWeek === 6 ? { color: red.main } : undefined;
  return <span style={styleName}>{props.label}</span>;
};
