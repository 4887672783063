import { isEqual } from 'lodash';
import { ICompanyUserModuleAccess, Role } from '../../AuthApp/Modules/Admin/Components/Users/Users.api';

export enum ModuleIdentifiers {
  Admin = 'urn:skyl:bizniz-portal:module:admin',
  Forum = 'urn:skyl:bizniz-portal:module:forum',
  Service = 'urn:skyl:bizniz-portal:module:service',
  Customer = 'urn:skyl:bizniz-portal:module:customer',
  Offers = 'urn:skyl:bizniz-portal:module:offers',
  Orders = 'urn:skyl:bizniz-portal:module:orders',
  Agreements = 'urn:skyl:bizniz-portal:module:agreements',
  SupplierOrders = 'urn:skyl:bizniz-portal:module:supplierorders',
  Booking = 'urn:skyl:bizniz-portal:module:booking',
  ErrorReport = 'urn:skyl:bizniz-portal:module:error-report',
  Todo = 'urn:skyl:bizniz-portal:module:todo',
  BusinessManagementSystem = 'urn:skyl:bizniz-portal:module:business-management-system',
  FileSystem = 'urn:skyl:bizniz-portal:module:file-system',
}

export const modules = [
  { name: 'Forum', identifier: ModuleIdentifiers.Forum },
  { name: 'Service', identifier: ModuleIdentifiers.Service },
  { name: 'Uppgifter', identifier: ModuleIdentifiers.Todo },
  { name: 'Bokningar', identifier: ModuleIdentifiers.Booking },
  { name: 'Kunder', identifier: ModuleIdentifiers.Customer },
  { name: 'Offerter', identifier: ModuleIdentifiers.Offers },
  { name: 'Ordrar', identifier: ModuleIdentifiers.Orders },
  { name: 'Avtal', identifier: ModuleIdentifiers.Agreements },
  { name: 'Beställningar', identifier: ModuleIdentifiers.SupplierOrders },
  { name: 'Felrapporter', identifier: ModuleIdentifiers.ErrorReport },
  { name: 'Admin', identifier: ModuleIdentifiers.Admin },
  { name: 'VLS', identifier: ModuleIdentifiers.BusinessManagementSystem },
  { name: 'Filer', identifier: ModuleIdentifiers.FileSystem },
];

const modulesWithAdmin: ModuleIdentifiers[] = [
  ModuleIdentifiers.Booking,
  ModuleIdentifiers.Offers,
  ModuleIdentifiers.Orders,
  ModuleIdentifiers.SupplierOrders,
];

export const moduleHasAdminRight = (module: ModuleIdentifiers) => modulesWithAdmin.some((x) => x === module);

export enum AccessRights {
  Denied = 0,
  Read = 1,
  Write = 2,
  ReadWrite = 3,
  Delete = 4,
  Full = 7,
  Admin = 15,
}

interface IAccessRightsOption {
  accessRight: AccessRights;
  name: string;
}

export const accessRights: IAccessRightsOption[] = [
  { accessRight: AccessRights.Denied, name: 'Åtkomst nekad' },
  { accessRight: AccessRights.Read, name: 'Läsa' },
  { accessRight: AccessRights.ReadWrite, name: 'Läsa/skriva' },
  { accessRight: AccessRights.Full, name: 'Läsa/skriva/radera' },
  { accessRight: AccessRights.Admin, name: 'Admin' },
];

export enum AccessRightsGroup {
  Custom = 0,
  Installer = 1,
  Admin = 2,
}

interface IAccessRightsGroupOption {
  type: AccessRightsGroup;
  name: string;
}

export const accessRightsGroups: IAccessRightsGroupOption[] = [
  { type: AccessRightsGroup.Installer, name: 'Montör' },
  { type: AccessRightsGroup.Admin, name: 'Admin' },
  { type: AccessRightsGroup.Custom, name: 'Enligt nedan' },
];

export const getGroupAccessRights = (type: AccessRightsGroup): [Role[],ICompanyUserModuleAccess[]] => {
  switch (type) {
    case AccessRightsGroup.Admin:
      return [[], modules
        .sort((a, b) => a.identifier.localeCompare(b.identifier))
        .map((module) => ({
          moduleIdentifier: module.identifier,
          accessRights: moduleHasAdminRight(module.identifier) ? AccessRights.Admin : AccessRights.Full,
        }))];
    //TODO: find out about other user types
    case AccessRightsGroup.Installer:
      return [[Role.Installer],[
        { moduleIdentifier: ModuleIdentifiers.Admin, accessRights : AccessRights.Denied},
        { moduleIdentifier: ModuleIdentifiers.Agreements, accessRights : AccessRights.ReadWrite},
        { moduleIdentifier: ModuleIdentifiers.Booking, accessRights : AccessRights.Full},
        { moduleIdentifier: ModuleIdentifiers.BusinessManagementSystem, accessRights : AccessRights.Denied},
        { moduleIdentifier: ModuleIdentifiers.Customer, accessRights : AccessRights.ReadWrite},
        { moduleIdentifier: ModuleIdentifiers.ErrorReport, accessRights : AccessRights.Denied},
        { moduleIdentifier: ModuleIdentifiers.FileSystem, accessRights : AccessRights.Denied},
        { moduleIdentifier: ModuleIdentifiers.Forum, accessRights : AccessRights.Full},
        { moduleIdentifier: ModuleIdentifiers.Service, accessRights : AccessRights.Full},
        { moduleIdentifier: ModuleIdentifiers.Offers, accessRights : AccessRights.Denied},
        { moduleIdentifier: ModuleIdentifiers.Orders, accessRights : AccessRights.ReadWrite},
        { moduleIdentifier: ModuleIdentifiers.SupplierOrders, accessRights : AccessRights.ReadWrite},
        { moduleIdentifier: ModuleIdentifiers.Todo, accessRights : AccessRights.Full},
      ]];
    default:
      return [[],modules
      .sort((a, b) => a.identifier.localeCompare(b.identifier))
      .map((module) => ({
        moduleIdentifier: module.identifier,
        accessRights: AccessRights.Denied,
      }))];
  }
};

export const getAccessRightsGroup = (accessRights: ICompanyUserModuleAccess[]) => {
  var activeGroup = AccessRightsGroup.Custom;

  const allGroups: AccessRightsGroup[] = Object.values(AccessRightsGroup)
    .filter((v) => !isNaN(Number(v)))
    .map((v) => Number(v) as AccessRightsGroup);

  if (accessRights.length > 0) {
    allGroups.forEach((group) => {
      if (isEqual(accessRights, getGroupAccessRights(group))) {
        activeGroup = group;
      }
    });
  }

  return activeGroup;
};
