import { useRouteMatch, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import useHeaderNavigationContext from '../../../Shared/Navigation/Header/HeaderNavigationContext/HeaderNavigationContext';
import { useEffect, useCallback } from 'react';
import { INavigationItem } from '../../../Shared/Navigation/Header/Header';
import React from 'react';
import CompanyForm from './Components/CompanyForm/CompanyForm';
import CompanyList from './Components/CompanyList/CompanyList';
import ViewCompany from './Components/ViewCompany/ViewCompany';
import { EditUser } from '../Admin/Components/Users/EditUser/EditUser';
import AuthRoute from '../../../Core/Authentication/AuthRoute/AuthRoute';
import { UserType } from '../Admin/Components/Users/Users.api';

function CompanyRoutes() {
  const { path } = useRouteMatch();
  const { setHeaderNavigationItems, setHeaderNavigationText } = useHeaderNavigationContext();
  const history = useHistory();

  useEffect(() => {
    const navigationItems: INavigationItem[] = [
      {
        title: 'Företag',
        to: `${path}`,
        exact: false,
      },
    ];
    setHeaderNavigationText(navigationItems);
    setHeaderNavigationItems(navigationItems);

    return () => {
      setHeaderNavigationItems([]);
    };
  }, [path, setHeaderNavigationText, setHeaderNavigationItems]);

  const handleCancelEditUser = useCallback((companyId?: string) => history.push(`${path}/${companyId}`), [
    history,
    path,
  ]);

  return (
    <Switch>
      <AuthRoute exact path={`${path}`} userType={UserType.SuperAdmin}>
        <h1 className={'sr-only'}>Lista över företag</h1>
        <CompanyList />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/create`}
        userType={UserType.SuperAdmin}
        breadcrumbs={[{ title: 'Företagslista', to: path }, { title: 'Skapa företag' }]}
      >
        <h1 className={'sr-only'}>Lägg till företag</h1>
        <CompanyForm />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/:companyId`}
        userType={UserType.SuperAdmin}
        breadcrumbs={[{ title: 'Företagslista', to: path }, { title: 'Företag' }]}
      >
        <h1 className={'sr-only'}>Information om företaget</h1>
        <ViewCompany />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/:companyId/update`}
        userType={UserType.SuperAdmin}
        breadcrumbs={[
          { title: 'Företagslista', to: path },
          { title: 'Företag', to: `${path}/:companyId` },
          { title: 'Redigera' },
        ]}
      >
        <h1 className={'sr-only'}>Redigera företaget</h1>
        <CompanyForm />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/:companyId/user/create`}
        userType={UserType.SuperAdmin}
        breadcrumbs={[
          { title: 'Företagslista', to: path },
          { title: 'Företag', to: `${path}/:companyId` },
          { title: 'Skapa användare' },
        ]}
      >
        <h1 className={'sr-only'}>Skapa användare</h1>
        <EditUser
          onUserSaved={(_, companyId) => history.push(`${path}/${companyId}`)}
          onCancel={handleCancelEditUser}
        />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/:companyId/user/:id`}
        userType={UserType.SuperAdmin}
        breadcrumbs={[
          { title: 'Företagslista', to: path },
          { title: 'Företag', to: `${path}/:companyId` },
          { title: 'Redigera användare' },
        ]}
      >
        <h1 className={'sr-only'}>Redigera användare</h1>
        <EditUser
          onUserSaved={(_, companyId) => history.push(`${path}/${companyId}`)}
          onCancel={handleCancelEditUser}
        />
      </AuthRoute>

      <Route path={`${path}/*`}>
        <Redirect to={`${path}`} />
      </Route>
    </Switch>
  );
}

export default CompanyRoutes;
