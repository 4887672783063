import ValidationErrorResultType from '../../../../../../Shared/Api/response/ValidationErrorResultType';
import { useApiPost, useApiPut, useApiGet, useApiDelete } from '../../../../../../Core/Api/Api';
import { ICreateResult } from '../../../../../../Shared/Api/response/ICreateResult';
import { Measure } from '../../../../Errands/Components/Sale.api';

export interface ICoreSystem {
  id: number;
  coreSystemIdentifier: string;
  mainProductId: number;
  systemTypeId: number;
  systemTypeName: string;
  regulationId: number;
  regulationName: string;

  projectLeaderId: number;
  projectLeaderName: string;
  mainInstallerId?: number;
  mainInstallerName?: string;

  client?: string;
  clientPhoneNumber?: string;

  approvedBy?: string;

  systemPlacement: string;
  installDate?: Date;
  alarmClass: number;
  alarmCentralVersion: string;
  previousOwner: string;
  customerDocuments: boolean;

  latestAgreement: IAgreement | undefined;
  currentAgreement: IAgreement | undefined;
  products: ICoreSystemProduct[];
  agreements: IAgreement[];
  eventActions: ICoreSystemEventAction[];
  systemUsers: ICoreSystemUser[];
  onPendingConfirm: boolean;
}

export interface ICoreSystemProduct {
  id: number;
  productId: number;
  productName: string;
  productNumber: string;
  productAddress: string | null;
  facilityPlacement: string | null;
  installDate: Date;
  productCategoryName: string;
  productCategorySortOrder: number;
}

export interface IAgreement {
  id: number;
  agreementIdentifier: string;
  isActive: boolean;
  startDate: Date;
  endDate: Date;
  monthlyCost: number;
  emergencyCallCentreSupplier: string;
  securityCompanySupplier: string;
  measure: Measure;
  renewedAgreementId?: number;
}

export interface IFormat {
  id: number;
  name: string;
}

export function useGetFormats() {
  const { isLoading, response, get } = useApiGet<IFormat[]>('/api/formats/coreSystem/list/');

  return {
    isLoading,
    response,
    getFormats: get,
  };
}

export interface ICoreSystemCommunication {
  id?: number;
  alarmCenterId: number;
  alarmCenterName: string;
  format: IFormat;
  transmitter: string;
  gsMorSNR: string | null;
  multicomNumber: string | null;
  tcPorIP: string | null;
  securityCompanyId: number;
  securityCompanyName: string;
  keyNumber: string | null;
  otherInformation: string | null;
  alarmCenterInformation: string | null;
  securityCompanyInformation: string | null;
  telematicSupplierId: number;
  telematicSupplierName: string;
  telematicInformation: string;
}

export interface ICoreSystemEventAction {
  id?: number;
  eventName: string;
  timespan: string;
  action: string;
}

export enum CoreSystemRegistrationStatus {
  upToDate,
  new,
  updated,
  deleted,
  unchanged,
}

export const CoreSystemRegistrationStatusName: { status: CoreSystemRegistrationStatus; name: string }[] = [
  { status: CoreSystemRegistrationStatus.upToDate, name: 'Synkad' },
  { status: CoreSystemRegistrationStatus.new, name: 'Ny' },
  { status: CoreSystemRegistrationStatus.updated, name: 'Uppdaterad' },
  { status: CoreSystemRegistrationStatus.deleted, name: 'Borttagen' },
  { status: CoreSystemRegistrationStatus.unchanged, name: 'Oförändrat' },
];

export interface ICoreSystemUser {
  id?: number;
  name: string;
  email?: string;
  phoneNumber?: string;
  mobilePhoneNumber?: string;
  registrationStatus: CoreSystemRegistrationStatus;
  code?: string;
  IsResponsible?: boolean;
  hasCode: boolean;
}
export interface IUpsertCoreSystemCommand {
  systemTypeId: number;
  regulationId: number;
  projectLeaderId: number;
  installerId?: number;
  mainProductId?: number | null;
  systemPlacement?: string;
  installDate?: Date;
  alarmClass: number;
  alarmCentralVersion: string;
  previousOwner: string;
  customerDocuments?: boolean;
  products: any[];
  isCustom: boolean;
  CustomSystemTypeName?: string;
}

export interface IUpdateCoreSystemCommunicationCommand {
  alarmCenterId: number;
  formatId: Number;
  transmitter: string;
  gsMorSNR: string | null;
  multicomNumber: string | null;
  tcPorIP: string | null;
  securityCompanyId: number;
  keyNumber: string | null;
  otherInformation: string | null;
  alarmCenterInformation: string | null;
  securityCompanyInformation: string | null;
  telematicSupplierId: number;
  telematicInformation: string | null;
}

export function useGetCoreSystem(customerId: string | undefined, facilityId: string | undefined) {
  const { isLoading, response, get } = useApiGet<ICoreSystem>(
    `/api/customers/${customerId}/facility/${facilityId}/coresystem/`,
    false,
  );

  return {
    isLoading,
    coreSystem: response,
    getCoreSystem: get,
  };
}

export interface IUpsertCoreSystemValidationErrors extends ValidationErrorResultType<IUpsertCoreSystemCommand> {}

export function useUpsertCoreSystem(customerId: string | undefined, facilityId: string | undefined) {
  const { isLoading: isCreateLoading, post } = useApiPost<
    ICreateResult<IUpsertCoreSystemValidationErrors>,
    IUpsertCoreSystemCommand
  >(`/api/customers/${customerId}/facility/${facilityId}/coresystem`);

  const { isLoading: isUpdateLoading, put } = useApiPut<IUpsertCoreSystemValidationErrors, IUpsertCoreSystemCommand>(
    `/api/customers/${customerId}/facility/${facilityId}/coresystem/`,
  );

  async function upsert(command: IUpsertCoreSystemCommand, id?: string) {
    if (id) {
      const result = await put(command, id);

      return result ? result : id;
    }

    return post(command);
  }

  return {
    isLoading: isCreateLoading || isUpdateLoading,
    upsert: upsert,
  };
}

export function useGetCoreSystemCommunication(coreSystemId: string | undefined) {
  const { isLoading, response, get } = useApiGet<ICoreSystemCommunication>(
    `/api/coresystem/${coreSystemId}/communication/`,
    false,
  );

  return {
    isLoading,
    communication: response,
    getCommunication: get,
  };
}

export interface IUpdateCoreSystemCommunicationValidationErrors
  extends ValidationErrorResultType<IUpdateCoreSystemCommunicationCommand> {}

export function useUpdateCoreSystemCommunication(coreSystemId: string) {
  const { isLoading: isUpdateLoading, put } = useApiPut<
    IUpdateCoreSystemCommunicationValidationErrors,
    IUpdateCoreSystemCommunicationCommand
  >(`/api/coresystem/${coreSystemId}/communication/`);

  return {
    isLoading: isUpdateLoading,
    updateCommunication: put,
  };
}

interface IUpdateCoreSystemProductsCommand {
  products: ICoreSystemProduct[];
}
export interface IUpdateCoreSystemProductsValidationErrors
  extends ValidationErrorResultType<IUpdateCoreSystemProductsCommand> {}

export function useUpdateCoreSystemProducts(coreSystemId: string) {
  const { isLoading, put } = useApiPut<IUpdateCoreSystemProductsValidationErrors, IUpdateCoreSystemProductsCommand>(
    `/api/coresystem/${coreSystemId}/products`,
  );

  function updateSystemProducts(products: ICoreSystemProduct[]) {
    const command: IUpdateCoreSystemProductsCommand = {
      products: products,
    };
    return put(command);
  }

  return {
    isUpdatingSystemProductsLoading: isLoading,
    updateSystemProducts: updateSystemProducts,
  };
}

interface IUpdateCoreSystemEventActionsCommand {
  eventActions: ICoreSystemEventAction[];
}
export interface IUpdateCoreSystemEventActionsValidationErrors
  extends ValidationErrorResultType<IUpdateCoreSystemEventActionsCommand> {}

export function useUpdateCoreSystemEventActions(coreSystemId: string) {
  const { isLoading, put } = useApiPut<
    IUpdateCoreSystemEventActionsValidationErrors,
    IUpdateCoreSystemEventActionsCommand
  >(`/api/coresystem/${coreSystemId}/eventactions`);

  function updateEventActions(eventActions: ICoreSystemEventAction[]) {
    const command: IUpdateCoreSystemEventActionsCommand = {
      eventActions: eventActions,
    };
    return put(command);
  }

  return {
    isUpdatingEventActionsLoading: isLoading,
    updateEventActions: updateEventActions,
  };
}

interface IUpdateCoreSystemUsersCommand {
  systemUsers: ICoreSystemUser[];
}
export interface IUpdateCoreSystemUsersValidationErrors
  extends ValidationErrorResultType<IUpdateCoreSystemUsersCommand> {}

export function useUpdateCoreSystemUsers(coreSystemId: string) {
  const { isLoading, put } = useApiPut<IUpdateCoreSystemUsersValidationErrors, IUpdateCoreSystemUsersCommand>(
    `/api/coresystem/${coreSystemId}/users`,
  );

  function updateSystemUsers(users: ICoreSystemUser[]) {
    const command: IUpdateCoreSystemUsersCommand = {
      systemUsers: users,
    };
    return put(command);
  }

  return {
    isUpdatingSystemUsersLoading: isLoading,
    updateSystemUsers: updateSystemUsers,
  };
}

export interface IRenewAgreementCommandValidationErrors extends ValidationErrorResultType<IRenewAgreementCommand> {}
export function useRenewAgreement(customerId: string | undefined) {
  const { isLoading, post } = useApiPost<ICreateResult<IRenewAgreementCommandValidationErrors>, IRenewAgreementCommand>(
    `/api/customers/${customerId}`,
  );

  const renewAgreement = (command: IRenewAgreementCommand, agreementId: string) => {
    return post(command, `/agreements/${agreementId}/renewAgreement`);
  };

  return {
    isLoading,
    renewAgreement,
  };
}

export interface IRenewAgreementCommand {
  renewStartDate: Date;
  renewEndDate: Date | null;
  isSaleAgreement?: boolean | null;
}

export interface IAddProductToCoreSystemDto {
  mainProductId: number;
}

export function useGetAddProductToCoreSystemForm(
  customerId: string | undefined,
  facilityId: string | undefined,
  coreSystemId: string | undefined,
) {
  return useApiGet<IAddProductToCoreSystemDto>(
    `/api/customers/${customerId}/facility/${facilityId}/coresystem/${coreSystemId}/addproduct`,
  );
}

export interface IAddProductToCoreSystemCommand {
  productId: number;
  productAddress: string;
  facilityPlacement: string;
  amount: number;
}

export interface IAddProductToCoreSystemCommandValidationErrors
  extends ValidationErrorResultType<IAddProductToCoreSystemCommand> {}

export function useAddProductToCoreSystem(
  customerId: string | undefined,
  facilityId: string | undefined,
  coreSystemId: string | undefined,
) {
  const { isLoading, post } = useApiPost<
    ICreateResult<IAddProductToCoreSystemCommandValidationErrors>,
    IAddProductToCoreSystemCommand
  >(`/api/customers/${customerId}/facility/${facilityId}/coresystem/${coreSystemId}/addproduct`);

  return {
    isLoading,
    post,
  };
}

export function useRemoveProductFromCoreSystem(
  customerId: string | undefined,
  facilityId: string | undefined,
  coreSystemId: string | undefined,
) {
  const { isLoading, delete: deleteRequest } = useApiDelete(
    `/api/customers/${customerId}/facility/${facilityId}/coresystem/${coreSystemId}/products/`,
  );

  const remove = async (coreSystemProductId: string) => {
    return await deleteRequest(undefined, coreSystemProductId);
  };

  return {
    isLoading,
    remove,
  };
}
