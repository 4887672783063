import {
  useGetSales,
  ISaleListRequest,
  useGetSale,
  ISaleChecklistEntryDto,
  TimeReportType,
  WorkflowStatus,
} from '../Sale.api';
import { useCallback } from 'react';
import { useApiPost, useApiGet, useApiPut, useApiDelete } from '../../../../../Core/Api/Api';
import ValidationErrorResultType from '../../../../../Shared/Api/response/ValidationErrorResultType';
import { ICreateResult } from '../../../../../Shared/Api/response/ICreateResult';

export function useGetOrder() {
  const { isLoading, sale, getSale } = useGetSale();

  return {
    isLoading,
    order: sale,
    getOrder: getSale,
  };
}

export interface IOrderListRequest extends ISaleListRequest {
  customerId?: number;
  facilityId?: number;
  bookedTo?: Date;
  onlyMine?: boolean;
}

export function useGetOrders() {
  const { isLoading, sales, getSales } = useGetSales();

  const getOrders = useCallback(
    (request: IOrderListRequest) => {
      return getSales({ ...request, workflowStatus: request.workflowStatus ?? WorkflowStatus.Order });
    },
    [getSales],
  );

  return {
    isLoading,
    orders: sales,
    getOrders,
  };
}

export function useGetOrdersFromSearch() {
  const { isLoading, sales, getSales } = useGetSales();

  const getOrders = useCallback(
    (request: IOrderListRequest) => {
      return getSales({ ...request, workflowStatus: request.workflowStatus ?? WorkflowStatus.Order });
    },
    [getSales],
  );

  return {
    isLoading,
    orders: sales,
    getOrders,
  };
}
export interface ICreateServiceSaleCommand {
  customerId: number;
  customerContactId?: number;
  facilityId: number;
  coreSystemId?: number;
  totalPrice: number;
  jobDescription: string;
  description?: string;
}

export interface ICreateServiceSaleCommandValidationErrors
  extends ValidationErrorResultType<ICreateServiceSaleCommand> {}

export function useCreateServiceSale() {
  const { isLoading, post } = useApiPost<
    ICreateResult<ICreateServiceSaleCommandValidationErrors>,
    ICreateServiceSaleCommand
  >(`/api/sales/service`);

  return {
    isCreateLoading: isLoading,
    create: post,
  };
}

export function useCompleteInstall() {
  const { isLoading, response, post } = useApiPost('/api/sales/');

  const completeInstall = useCallback(
    (saleId: string, invoiceMessage?: string) => {
      return post({ invoiceMessage }, saleId + '/installed');
    },
    [post],
  );

  return {
    isLoading,
    response,
    completeInstall,
  };
}

export function useRoleBackOrder() {
  const { isLoading, response, post } = useApiPost('/api/sales/');

  const rollbackOrder = useCallback(
    (saleId: string) => {
      return post({}, saleId + '/rollbackToOrder');
    },
    [post],
  );

  return {
    isLoading,
    response,
    rollbackOrder,
  };
}

export function useMarkForInvoice() {
  const { isLoading, response, post } = useApiPost('/api/sales/');

  const markForInvoice = useCallback(
    (saleId: string, invoiceMessage?: string) => {
      return post({ invoiceMessage }, saleId + '/invoice');
    },
    [post],
  );

  return {
    isLoading,
    response,
    markForInvoice,
  };
}

export interface IUpdateInvoiceCommand {
  externalInvoiceNumber?: string;
  externalAgreementInvoiceNumber?: string;
  invoiceTotalPrice?: number;
}

export function useUpdateOrderInvoice(saleId: string | undefined) {
  const { isLoading, put } = useApiPut<any, IUpdateInvoiceCommand>(`/api/sales/${saleId}/updateInvoice`);

  return {
    isLoading,
    updateOrderInvoice: put,
  };
}

export interface ICompleteOrderRequest {
  invoiceAmount: number;
  sendInvoiceSurvey: boolean;
  externalInvoiceNumber: string;
  externalAgreementInvoiceNumber?: string;
}

export function useCompleteOrder(saleId: string | undefined) {
  const { isLoading, response, post } = useApiPost<any, ICompleteOrderRequest>(`/api/sales/${saleId}/complete`);

  return {
    isLoading,
    response,
    completeOrder: post,
  };
}

interface IProductDto {
  id: number;
  name: string;
  quantity: number;
}

interface ISupplierProductsDto {
  id: number;
  name: string;
  products: IProductDto[];
}

export interface ISupplierOrderFormDto {
  saleIdentifier: string;
  customerName: string;
  suppliers: ISupplierProductsDto[];
}

export function useGetSupplierOrderForm(saleId: string | undefined) {
  const { isLoading, response, get } = useApiGet<ISupplierOrderFormDto>(`/api/sales/${saleId}/supplierorders`);

  return {
    isLoading,
    response,
    get,
  };
}

export interface IProduct {
  id: number;
  name: string;
}

export function useGetProduct() {
  const { isLoading, get } = useApiGet<IProduct>(`/api/products/`, false);
  const getProduct = (productId: string | undefined) => {
    return get(`${productId}`);
  };

  return {
    isLoading: isLoading,
    getProduct: getProduct,
  };
}

export interface ISupplierMessage {
  supplierId: number;
  message: string;
}

export interface ICreateSupplierOrdersCommand {
  preferredDeliveryDate: string;
  suppliers: ISupplierMessage[];
}

export interface ICreateSupplierOrdersValidationErrors
  extends ValidationErrorResultType<ICreateSupplierOrdersCommand> {}

export function useCreateSupplierOrders(saleId: string | undefined) {
  const { isLoading, post } = useApiPost<ICreateSupplierOrdersValidationErrors, ICreateSupplierOrdersCommand>(
    `/api/sales/${saleId}/supplierorders`,
  );

  return {
    isCreateLoading: isLoading,
    create: post,
  };
}

export interface IAddProductCommand {
  productId: number;
  facilityPlacement?: string;
  comment?: string;
}

export interface IAddProductOrdersValidationErrors extends ValidationErrorResultType<IAddProductCommand> {}

export function useAddProduct(saleId: string) {
  const { isLoading, post } = useApiPost<IAddProductOrdersValidationErrors, IAddProductCommand>(
    `/api/sales/${saleId}/products`,
  );

  return {
    isAddProductLoading: isLoading,
    addProduct: post,
  };
}

export interface IProductInformation {
  productId: number;
  placement?: string;
  comment?: string;
  quantity: number;
}

export interface IAddProductsCommand {
  products: IProductInformation[];
}

export interface IAddProductsOrdersValidationErrors extends ValidationErrorResultType<IAddProductsCommand> {}

export function useAddProducts(saleId: string) {
  const { isLoading, post } = useApiPost<IAddProductOrdersValidationErrors, IAddProductsCommand>(
    `/api/sales/${saleId}/addproducts`,
  );

  return {
    isAddProductsLoading: isLoading,
    addProducts: post,
  };
}

export interface ICreateTimeReportCommand {
  date: string;
  type: TimeReportType;
  hours: number;
  trips: number;
  parking: number;
  companyUserId: number;
}

export interface ICreateTimeReportCommandValidationErrors extends ValidationErrorResultType<ICreateTimeReportCommand> {}

export function useCreateTimeReport(saleId: string | undefined) {
  const { isLoading, post } = useApiPost<ICreateTimeReportCommandValidationErrors, ICreateTimeReportCommand>(
    `/api/sales/${saleId}/timereports`,
  );

  return {
    isCreateLoading: isLoading,
    create: post,
  };
}

export interface IAddCostCommand {
  date: Date;
  costIn: number;
  costOut: number;
  description: string;
  supplierId: string;
}

export interface IAddCostCommandValidationErrors extends ValidationErrorResultType<IAddCostCommand> {}

export function useAddSalesCost(saleId: string | undefined) {
  const { isLoading, post } = useApiPost<IAddCostCommandValidationErrors, IAddCostCommand>(
    `/api/sales/${saleId}/costs`,
  );

  return {
    isCreateLoading: isLoading,
    create: post,
  };
}

export function useDeleteSalesCost() {
  const { isLoading, delete: deleteRequest } = useApiDelete(`/api/sales/`);

  const deleteSaleCost = useCallback(
    async (saleId: string | undefined, saleCostId: string) => {
      return await deleteRequest(undefined, `${saleId}/costs/${saleCostId}`);
    },
    [deleteRequest],
  );

  return {
    isLoading,
    deleteSaleCost,
  };
}

export interface IUpdateCostCommand {
  date: Date;
  costIn: number;
  costOut: number;
  description: string;
  supplierId: string;
}

export interface IUpdateCostCommandValidationErrors extends ValidationErrorResultType<IUpdateCostCommand> {}

export function useUpdateSalesCost(saleId: string | undefined, saleCostId: string) {
  const { isLoading, put } = useApiPut<IUpdateCostCommandValidationErrors, IUpdateCostCommand>(
    `/api/sales/${saleId}/costs/${saleCostId}`,
  );

  return {
    isUpdateLoading: isLoading,
    update: put,
  };
}

interface IUpdateDescriptionsCommand {
  projectLeadId: number | undefined;
  jobDescription: string;
  workDescription: string;
  materialDescription: string;
  extraMaterial: string;
}

export interface IUpdateDescriptionsCommandValidationErrors
  extends ValidationErrorResultType<IUpdateDescriptionsCommand> {}
export function useUpdateDescriptions(saleId: string | undefined) {
  const { isLoading, put } = useApiPut<IUpdateDescriptionsCommandValidationErrors, IUpdateDescriptionsCommand>(
    `/api/sales/${saleId}/descriptions`,
  );

  function updateDescriptions(
    jobDescription: string,
    workDescription: string,
    materialDescription: string,
    extraMaterial: string,
    projectLeaderId: number | undefined,
  ) {
    return put({
      jobDescription: jobDescription,
      workDescription: workDescription,
      materialDescription: materialDescription,
      extraMaterial: extraMaterial,
      projectLeadId: projectLeaderId,
    });
  }

  return {
    isUpdatingDescriptionsLoading: isLoading,
    updateDescriptions: updateDescriptions,
  };
}

export interface IUpdateChecklistRequest {
  entries: ISaleChecklistEntryDto[];
}

export function useUpdateSaleChecklist(saleId: string) {
  const { isLoading, put } = useApiPut<any, IUpdateChecklistRequest>(`/api/sales/${saleId}/checklist`);

  return {
    isLoading,
    updateSaleChecklist: put,
  };
}
export interface IUpdateOrderTotalPriceCommand {
  totalPrice?: number;
}

export function useUpdateOrderTotalPrice(saleId: string | undefined) {
  const { isLoading: isUpdatePriceLoading, put } = useApiPut<any, IUpdateOrderTotalPriceCommand>(
    `/api/sales/${saleId}/totalprice`,
  );

  return {
    isLoading: isUpdatePriceLoading,
    updateTotalPrice: put,
  };
}
