import React, { useCallback } from 'react';
import { AsyncSelect } from '../../../../../Shared/Select';
import { IBMSNode, useSearchBMSNodes } from '../../BMS.api';

function getBMSNodeSelectLabel(node: IBMSNode) {
  return `${'-'.repeat(node.treeLevel - 1)} ${node.sectionNumber} ${node.name}`;
}

export interface IBMSNodeSelectProps {
  label?: string;
  required?: boolean;
  disabled?: boolean;
  fullwidth?: boolean;
  nodeId?: number;
  selectedNodeId?: number;
  onChange: (nodeId: number | undefined) => void;
}

const BMSNodeSelect = (props: IBMSNodeSelectProps) => {
  const { isLoading, searchNodes } = useSearchBMSNodes();

  const loadNodes = useCallback(
    async (query?: string) => {
      const response = await searchNodes({ query: query, selectedNode: props.nodeId });
      var options = response?.map((x) => ({ value: x.id.toString(), label: getBMSNodeSelectLabel(x) }));
      if (props.required) return [{ value: '0', label: '(Ingen)' }, ...options];
      return options;
    },
    [props.nodeId, props.required, searchNodes],
  );

  return (
    <AsyncSelect
      label={props.label}
      required={props.required}
      isDisabled={props.disabled}
      isClearable={!props.required}
      fullwidth={props.fullwidth}
      isLoading={isLoading}
      selectedOptionId={props.selectedNodeId?.toString()}
      loadOptions={loadNodes}
      onChange={(value) => props.onChange(value ? Number(value.value) : undefined)}
    />
  );
};

export default BMSNodeSelect;
