import React, { useCallback, useEffect, useState } from 'react';
import Container from '../../../../../../Shared/Container/Container';
import Button from '../../../../../../Shared/Button/Button';
import UploadInput, { IUploadFile } from '../../../../../../Shared/InputFields/UploadInput/UploadInput';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import PostalCodeField from '../../../../../../Shared/InputFields/PostalCodeField/PostalCodeField';
import {
  IUpdateCompanySettingsCommand,
  IUpdateCompanySettingsCommandValidationErrors,
  useGetCompanySettings,
  useUpdateCompanySettings,
} from '../CompanySettings.api';
import useInputState from '../../../../../../Shared/Hooks/UseInputState/UseInputState';
import useNullableNumericInputState from '../../../../../../Shared/Hooks/UseNullableNumericInputState/UseNullableNumericInputState';
import useFormValidation from '../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import { isResultError } from '../../../../../../Shared/Api/response/ICreateResult';
import EmailField from '../../../../../../Shared/InputFields/EmailField/EmailField';
import PhoneNumberField from '../../../../../../Shared/InputFields/PhoneNumberField/PhoneNumberField';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tab,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutlineRounded';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import IconButton from '../../../../../../Shared/IconButton/IconButton';
import Tabs from '../../../../../../Shared/Tabs/Tabs';
import CompanyBranchList from '../CompanyBranch/CompanyBranchList/CompanyBranchList';

function CompanySettings() {
  const { isLoading, settings, getCompanySettings } = useGetCompanySettings();
  const { isLoading: isUpdateLoading, updateCompanySettings } = useUpdateCompanySettings();
  const { isFormValid, formRef } = useFormValidation();
  const [administrationName, changeAdministrationName, setAdministrationName] = useInputState('');
  const [customerServiceName, changeCustomerServiceName, setCustomerServiceName] = useInputState('');
  const [name, changeName, setName] = useInputState('');
  const [shortName, changeShortName, setShortName] = useInputState('');
  const [email, changeEmail, setEmail] = useInputState('');
  const [emailCustomerService, changeEmailCustomerService, setEmailCustomerService] = useInputState('');
  const [web, changeWeb, setWeb] = useInputState('');
  const [phoneNumber, changePhoneNumber, setPhoneNumber] = useInputState('');
  const [address, changeAddress, setAddress] = useInputState('');
  const [postalCode, changePostalCode, setPostalCode] = useInputState('');
  const [city, changeCity, setCity] = useInputState('');
  const [bannerText, changeBannerText, setBannerText] = useInputState('');
  const [companyDescription, changeCompanyDescription, setCompanyDescription] = useInputState('');
  const [
    defaultInstallationPrice,
    changeDefaultInstallationPrice,
    setDefaultInstallationPrice,
  ] = useNullableNumericInputState();
  const [nextCustomerNumber, changeNextCustomerNumber, setNextCustomerNumber] = useInputState(0);
  const [nextAgreementNumber, changeNextAgreementNumber, setNextAgreementNumber] = useInputState(0);
  const [nextSalesNumber, changeNextSalesNumber, setNextSalesNumber] = useInputState(0);
  const [nextFacilityNumber, changeNextFacilityNumber, setNextFacilityNumber] = useInputState(0);
  const [nextCoreSystemNumber, changeNextCoreSystemNumber, setNextCoreSystemNumber] = useInputState(0);
  const [companyLogo, setCompanyLogo] = useState<IUploadFile>();
  const [companyAddtionalImage, setCompanyAdditionalImage] = useState<IUploadFile>();
  const [companyPdfBackground, setCompanyPdfBackground] = useState<IUploadFile>();
  const [allowOutgoingEmail, setAllowOutgoingEmail] = useState<boolean>(false);
  const [emailWhiteList, setEmailWhiteList] = useState<string[]>([]);
  const [errors, setErrors] = useState<IUpdateCompanySettingsCommandValidationErrors>();

  const [newEmail, changeNewEmail, setNewEmail] = useInputState('');

  const load = useCallback(async () => {
    await getCompanySettings();
  }, [getCompanySettings]);

  const setFields = useCallback(() => {
    if (settings) {
      setName(settings.name);
      setEmail(settings.email);
      setPhoneNumber(settings.phoneNumber);
      setAddress(settings.address);
      setPostalCode(settings.postalCode);
      setCity(settings.city);
      setCompanyDescription(settings.companyDescription);
      setDefaultInstallationPrice(settings.defaultInstallationPrice);
      setAdministrationName(settings.administrationName);
      setCustomerServiceName(settings.customerServiceName);
      setEmailCustomerService(settings.emailCustomerService);
      setShortName(settings.shortName);
      setWeb(settings.web);
      setNextCustomerNumber(settings.nextCustomerNumber);
      setNextSalesNumber(settings.nextSalesNumber);
      setNextFacilityNumber(settings.nextFacilityNumber);
      setNextCoreSystemNumber(settings.nextCoreSystemNumber);
      setCompanyLogo(settings.companyLogo);
      setCompanyAdditionalImage(settings.companyAdditionalImage);
      setCompanyPdfBackground(settings.companyPdfBackground);
      setAllowOutgoingEmail(settings.allowOutgoingEmail);
      setEmailWhiteList(settings.emailWhiteList);
      setNextAgreementNumber(settings.nextAgreementNumber);
      setBannerText(settings.bannerText)
    }
  }, [
    settings,
    setName,
    setEmail,
    setPhoneNumber,
    setAddress,
    setPostalCode,
    setCity,
    setCompanyDescription,
    setDefaultInstallationPrice,
    setAdministrationName,
    setCustomerServiceName,
    setEmailCustomerService,
    setShortName,
    setWeb,
    setNextCustomerNumber,
    setNextSalesNumber,
    setNextAgreementNumber,
    setNextFacilityNumber,
    setNextCoreSystemNumber,
    setBannerText
  ]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    setFields();
  }, [setFields, settings]);

  async function save() {
    const command: IUpdateCompanySettingsCommand = {
      name: name,
      email: email,
      phoneNumber: phoneNumber,
      address: address,
      postalCode: postalCode,
      city: city,
      companyDescription: companyDescription,
      defaultInstallationPrice: defaultInstallationPrice as number,
      administrationName: administrationName,
      customerServiceName: customerServiceName,
      shortName: shortName,
      emailCustomerService: emailCustomerService,
      web: web,
      nextCustomerNumber: nextCustomerNumber,
      nextSalesNumber: nextSalesNumber,
      companyLogo: companyLogo,
      companyAdditionalImage: companyAddtionalImage,
      companyPdfBackground: companyPdfBackground,
      nextAgreementNumber: nextAgreementNumber,
      nextFacilityNumber: nextFacilityNumber,
      nextCoreSystemNumber: nextCoreSystemNumber,
      allowOutgoingEmail: allowOutgoingEmail,
      emailWhiteList: emailWhiteList,
      bannerText: bannerText
    };

    const result = await updateCompanySettings(command);
    if (isResultError(result)) {
      setErrors(result);
    } else {
      setErrors(undefined);
      load();
    }
  }

  const handleAddEmail = () => {
    if (RegExp(`^\\S+@\\S+\\.\\S+$`).test(newEmail as string)) {
      setEmailWhiteList((x) => [...x, newEmail]);
      setNewEmail('');
    }
  };

  function handleRemoveEmail(email: string) {
    setEmailWhiteList((x) => x.filter((y) => y !== email));
  }

  return (
    <Container
      form
      loading={isLoading}
      ref={formRef}
      customSize={{
        fullsize: true,
      }}
      actions={
        <>
          <Button variant="outlined" onClick={setFields}>
            Avbryt
          </Button>
          <Button variant="contained" color="primary" loading={isUpdateLoading} disabled={!isFormValid} onClick={save}>
            Spara
          </Button>
        </>
      }
    >
      <Tabs customSize={{fullsize: true }} padding={1} columns> 
        <Tab label="Information">
          <InputField
            label="Företagsnamn"
            type="text"
            required
            value={name}
            onChange={changeName}
            errorText={errors?.name}
            extraMargin={1}
          />
          <InputField
            label="Förkortning"
            type="text"
            value={shortName}
            onChange={changeShortName}
            errorText={errors?.shortName}            
            extraMargin={1}
          />
          <InputField
            label="Administration"
            type="text"
            value={administrationName}
            onChange={changeAdministrationName}
            errorText={errors?.administrationName}            
            extraMargin={1}
          />
          <InputField
            label="Kundtjänst"
            type="text"
            value={customerServiceName}
            onChange={changeCustomerServiceName}
            errorText={errors?.customerServiceName}            
            extraMargin={1}
          />
          <InputField 
            label="Organisationsnummer" 
            type="text" 
            viewOnly 
            value={settings?.identificationNumber} 
            extraMargin={1}
          />
          <PhoneNumberField
            label="Telefon"
            value={phoneNumber}
            onChange={changePhoneNumber}
            errorText={errors?.phoneNumber}            
            extraMargin={1}
          />
          <EmailField label="E-post" required value={email} onChange={changeEmail} errorText={errors?.email} extraMargin={1} />
          <EmailField
            label="E-post kundtjänst"
            value={emailCustomerService}
            onChange={changeEmailCustomerService}
            errorText={errors?.emailCustomerService}            
            extraMargin={1}
          />
          <InputField label="Gatuadress" type="text" value={address} onChange={changeAddress} errorText={errors?.address} extraMargin={1}/>
          <InputField label="Web" type="url" value={web} onChange={changeWeb} errorText={errors?.web} extraMargin={1}/>
          <PostalCodeField
            label="Postnummer"
            type="text"
            value={postalCode}
            onChange={changePostalCode}
            errorText={errors?.postalCode}          
            extraMargin={1}
          />
          <InputField label="Ort" type="text" value={city} onChange={changeCity} errorText={errors?.city} extraMargin={1}/>
          <InputField
            fullwidth
            multiline
            label="Verksamhetsbeskrivning"
            type="text"
            value={companyDescription}
            onChange={changeCompanyDescription}
            rows={6}
            extraMargin={1}
          />
          <InputField label="Banner-text" type="text" fullwidth multiline value={bannerText} onChange={changeBannerText} errorText={errors?.bannerText}  rows={6} extraMargin={1}/>
        </Tab>
        <Tab label="Startnummer">
          <InputField
            label="Startnummer för offert/order"
            type="number"
            value={nextSalesNumber}
            onChange={changeNextSalesNumber}
            errorText={errors?.nextSalesNumber}
            extraMargin={1}
          />
          <InputField
            label="Startnummer för kund"
            type="number"
            value={nextCustomerNumber}
            onChange={changeNextCustomerNumber}
            errorText={errors?.nextCustomerNumber}
            extraMargin={1}
          />
          <InputField
            label="Startnummer för avtal"
            type="number"
            value={nextAgreementNumber}
            onChange={changeNextAgreementNumber}
            errorText={errors?.nextAgreementNumber}
            extraMargin={1}
          />
          <InputField
            label="Startnummer för plats"
            type="number"
            value={nextFacilityNumber}
            onChange={changeNextFacilityNumber}
            errorText={errors?.nextFacilityNumber}
            extraMargin={1}
          />
          <InputField
            label="Startnummer för system"
            type="number"
            value={nextCoreSystemNumber}
            onChange={changeNextCoreSystemNumber}
            errorText={errors?.nextCoreSystemNumber}
            extraMargin={1}
          />          
        </Tab>
        <Tab label="Standardpriser">
          <InputField
            label="Timpris för installation"
            type="text"
            endAdornment={'SEK'}
            value={defaultInstallationPrice}
            onChange={changeDefaultInstallationPrice}
            errorText={errors?.defaultInstallationPrice}
            extraMargin={1}
          />
        </Tab>
        <Tab label="Bilder/logotyp">
          <div style={{padding: '1rem'}}>
            <UploadInput
              label="Ladda upp logotyp"
              value={companyLogo?.name}
              type="image"
              onFileChange={(selectedFile) => {
                setCompanyLogo(selectedFile ? selectedFile : undefined);
              }}
            />
            <br />
            <UploadInput
              label="Ladda upp bakgrund PDF"
              value={companyPdfBackground?.name}
              type="image"
              onFileChange={(selectedFile) => {
                setCompanyPdfBackground(selectedFile ? selectedFile : undefined);
              }}
            />
          </div>
        </Tab>
        <Tab label="E-post">
          <div style={{padding: '1rem', width: '100%'}}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    name="allowOutgoingEmailCheckbox"
                    color="primary"
                    checked={allowOutgoingEmail}
                    onChange={(_, checked) => setAllowOutgoingEmail(checked)}
                  />
                }
                label="Tillåt alla utgående mejl."
              />
            </Grid>
            <br />
            <InputField
              placeholder="Lägg till email"
              type="text"
              fullwidth
              value={newEmail}
              onChange={changeNewEmail}
              onEnter={(e) => {
                e.preventDefault();
                handleAddEmail();
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Lägg till e-post"
                    disabled={!RegExp(`^\\S+@\\S+\\.\\S+$`).test(newEmail as string)}
                    onClick={handleAddEmail}
                    color="primary"
                  >
                    <AddIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
            {emailWhiteList.length > 0 && (
              <Grid sm={12} xs={12} md={12} lg={12} item>
                <List>
                  {emailWhiteList.map((email) => (
                    <ListItem key={email} disableGutters>
                      <ListItemText primary={email} />
                      <ListItemSecondaryAction>
                        <IconButton aria-label="Ta bort e-post" onClick={() => handleRemoveEmail(email)} color="error">
                          <RemoveIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}
          </div>
        </Tab>
        <Tab label="Filial">
          <CompanyBranchList />
        </Tab>
      </Tabs>
      {/* <Fieldset legend="Extra bild till startsida"> THIS IS NOT IMPLEMENTED HERE OR IN THE BACKEND.
        <UploadInput
          label="Ladda upp bild"
          value={companyAddtionalImage?.name}
          type="image"
          onFileChange={(selectedFile) => {
            setCompanyAdditionalImage(selectedFile ? selectedFile : undefined);
          }}
        />
      </Fieldset> */}
    </Container>
  );
}

export default CompanySettings;
