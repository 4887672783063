import React, { forwardRef, useMemo } from 'react';
import { InputBaseProps, InputBase, InputAdornment, Checkbox, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import DateIcon from '@material-ui/icons/DateRangeRounded';
import ClearIcon from '@material-ui/icons/ClearRounded';
import IconButton from '../../../IconButton/IconButton';
import { InputField } from '../../InputField/InputField';
import InputLabel from '../../InputLabel/InputLabel';
import { useStyles } from './DateInput.styles';

interface IProps extends InputBaseProps {
  label?: string;
  hideLabel?: boolean;
  clear?: () => void;
  errorText?: string | string[];
  checkBoxProps?: ICheckBoxProps;
  radioBoxProps?: IRadioBoxProps;
  isLoading?: boolean;
}
export interface ICheckBoxProps {
  checkBoxLabel: string;
  checkBoxValue: boolean;
  onCheckBoxChange: (isChecked: boolean) => void;
}

export interface IRadioBoxProps {
  radioBoxLabel: string;
  radioBoxCheckedValue?: number | null;
  alternatives: Array<{ 
    radioBoxAlternativeLabel: string;
    radioBoxAlternativeValue: number; 
  }>
  onRadioBoxChange: (value: number) => void;
}

const DateInput = (props: IProps, ref: any) => {
  const classes = useStyles();
  const { label, clear, errorText, hideLabel, checkBoxProps, radioBoxProps, ...rest } = props;
  const labelWithoutSpaces = label?.replace(/\s/g, '');
  const checkedBox = useMemo(() => checkBoxProps?.checkBoxValue, [checkBoxProps]);
  const radioValue = useMemo(() => radioBoxProps?.radioBoxCheckedValue, [radioBoxProps]);

  return (
    <InputField
      fullwidth
      errorText={errorText}
      helperText={
        <>
          {checkBoxProps && (
            <FormControlLabel
              className={classes.checkBox}
              control={
                <Checkbox
                  name={checkBoxProps.checkBoxLabel}
                  color="primary"
                  edge="start"
                  checked={checkedBox}
                  onChange={(_, checked) => {
                    checkBoxProps.onCheckBoxChange(checked);
                  }}
                />
              }
              label={checkBoxProps.checkBoxLabel}
            />
          )}
          {radioBoxProps && 
            <RadioGroup defaultChecked defaultValue={3}>
            {radioBoxProps.alternatives.map((rb) => 
                <FormControlLabel
                  className={classes.checkBox}
                  label={rb.radioBoxAlternativeLabel}
                  control={
                    <Radio
                      name={radioBoxProps.radioBoxLabel}
                      color="primary"
                      edge="start"                      
                      value={rb.radioBoxAlternativeValue}
                      checked={rb.radioBoxAlternativeValue === radioValue}
                      onChange={(_, value) => {
                        radioBoxProps.onRadioBoxChange(rb.radioBoxAlternativeValue);
                      }}
                    />
                    }
                />
              )}
            </RadioGroup>
          }
        </>
      }
    >
      {label && <InputLabel label={label} id={labelWithoutSpaces} />}
      <InputBase
        ref={ref}
        {...rest}
        className={classes.input}
        startAdornment={
          <InputAdornment position="start">
            <DateIcon />
          </InputAdornment>
        }
        inputProps={{
          'auto-complete': 'off',
          'data-lpignore': 'true',
          'aria-labelledby': labelWithoutSpaces,
          'aria-label': !label ? rest.placeholder : '',
        }}
        endAdornment={
          props.clear && (
            <InputAdornment position="end">
              <IconButton size="small" aria-label="Rensa fält">
                <ClearIcon fontSize="small" onClick={clear} />
              </IconButton>
            </InputAdornment>
          )
        }
      />
    </InputField>
  );
};

export default forwardRef(DateInput);
