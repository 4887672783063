import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import IconButton from '../IconButton/IconButton';

interface IDialogContentHeader {
  title: string | React.ReactNode;
  titleId: string;
  isActivityWindow?: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
  button: {
    padding: 0,
  },
}));

export const DialogContentHeader = (props: IDialogContentHeader) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography id={props.titleId} variant="h2" component="label">
        {props.title}
      </Typography>
      {!props.isActivityWindow && (
        <IconButton aria-label="Close dialog" onClick={props.onClose} className={classes.button}>
          <Close />
        </IconButton>
      )}
    </div>
  );
};
