import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isErrorResponse } from '../../../../Shared/Api/response/IErrorRespose';
import useErrorMessage from '../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import { IActivity } from '../../../Shared/Calendars/Components/ActivityForm/ActivityForm';
import EditActivity from '../../../Shared/Calendars/Components/EditActivity/EditActivity';
import { calendarEntryTypes, getErrorMessage, useUpsertCalendarEntry } from '../Booking.api';

const ActivityWindow = () => {
  const { id, activityId } = useParams<{
    id: string;
    activityId: string;
    isCompanyCalendar: string;
  }>();
  const { setErrorMessage } = useErrorMessage();
  const [editActivity, setEditActivity] = useState<IActivity>();
  const { upsert } = useUpsertCalendarEntry();

  const onRemove = () => {};

  const handleClose = useCallback(() => {
    setEditActivity(undefined);
    setTimeout(() => {
      window.close();
    }, 500);
  }, [setEditActivity]);

  async function handleSave(activity: IActivity) {
    if (activity.companyUserIds) {
      const result = await upsert({ ...activity, companyUserIds: activity.companyUserIds }, activity.id?.toString());
      if (isErrorResponse(result)) {
        setErrorMessage({ message: getErrorMessage(result, !activity.id) });
      }
      handleClose();
    }
  }

  return (
    <EditActivity
      onSave={handleSave}
      onRemove={onRemove}
      onClose={handleClose}
      editActivityId={editActivity?.id}
      saleId={editActivity?.saleId}
      open={true}
      showInCompanyCalendarByDefault={false}
      inCalendar
      activityLabel={(calendarEntryTypes.find((c) => c.type === editActivity?.type)?.name ?? 'Aktivitet') + (editActivity?.facilityName ? ': ' + editActivity?.facilityName : editActivity?.otherActivity ? (': ' + editActivity?.otherActivity) : '')}
      isActivityWindow
      activityType={Number(activityId)}
      companyUserId={Number(id)}
    />
  );
};

export default ActivityWindow;
