import { useApiGet, useApiPost } from '../../../Core/Api/Api';
import { IFileResult } from '../../../Shared/Api/response/IFileResult';

export interface IContactInfoDto {
  emailCustomerService?: string;
  phoneNumber?: string;
  companyLogoBase64?: string;
}

export function useConfirmCoreSystemUpdate(token: string | undefined) {
  const { isLoading, response, post } = useApiPost<IContactInfoDto>(`/api/anonymous/securitycompany/${token}`);

  return {
    isConfirmLoading: isLoading,
    response,
    confirm: post,
  };
}

export function useGetSystemPdf(token: string | undefined) {
  const { isLoading, response, get } = useApiGet<IFileResult>(`/api/anonymous/securitycompany/${token}/pdf`, false);

  return {
    isLoading,
    pdf: response,
    getPdf: get,
  };
}
