import { makeStyles } from '@material-ui/core';
import theme from '../../../../Styles/Theme';

export const useSharedCalendarStyles = makeStyles(() => ({
  calendarContainer: {
    margin: '-1rem',
  },
  innerContainer: {
    position: 'relative',
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    zIndex: 2,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    [theme.breakpoints.down('xs')]: {
      top: '3.5rem',
    },
    '& + div': {
      borderTop: 0,
    },
  },
}));

export const sharedCalendarStyling = {
  '& .rbc-timeslot-group': {
    borderBottom: 0,
  },
  '& .rbc-label': {
    padding: 0,
  },
  '& .rbc-time-slot': {
    borderTop: 0,
    minHeight: '1rem'
  },
  '& .rbc-today': {
    backgroundColor: theme.palette.common.white,
    '& span': {
      fontWeight: 600,
    },
  },
  '& .rbc-show-more': {
    display: 'flex',
    justifyContent: 'center',
    padding: '0.3rem',
    margin: '0 0.4rem',
    borderRadius: '4px',
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 400,
  },
  '& .rbc-time-header-content': {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    borderLeft: 0,
  },
  '& .rbc-slot-selection': {
    backgroundColor: 'transparent',
    cursor: 'default',
  },
  '& .rbc-header': {
    borderBottom: 0,
    height: '3rem',
    fontWeight: 300,
    zIndex: 1,
    fontSize: theme.typography.body1.fontSize,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  '& .rbc-time-view': {
    border: 0,
  },
  '& .rbc-current-time-indicator': {
    display: 'none',
  },
  '& .rbc-event': {
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    alignItems: 'flex-start',
    minHeight: '25px',
    justifyContent: 'space-between',
    flexFlow: 'row-reverse',
    position: 'static',
    minWidth: '100%',
  },
  '& .rbc-addons-dnd-resizable': {
    width: '100%',
  },
  '& .rbc-event-continues-earlier': {
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
  '& .rbc-events-container': {
    marginLeft: '0.75rem',
    paddingRight: '0.25rem',
    borderLeft: 'none',
    paddingBottom: '0.5rem',
    marginTop: '0.5rem',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#fff',
    },
    '&::-webkit-scrollbar-thumb': {
      background: `${theme.palette.grey[300]}`,
    }
  },
  '& .rbc-time-content': {
    borderTop: 0,
  },
  '& .rbc-allday-cell': {
    display: 'none'
  }
};

export const companyCalStyling = {
  '& .rbc-time-gutter': {
    display: 'none',
    '& + div > *': {
      borderLeft: 0,
    },
  },
  '& .rbc-day-slot': {
    height: '100%',
  }
};

export const bookingCalStyling = {
  '& .rbc-time-gutter': {
    width: '10.5rem',
    '& > *': {
      display: 'none',
    },
  },
  '& .rbc-day-slot': {
    height: '100%',
  }
};
