import React, { useEffect, useState } from 'react';
import Button from '../../../../../../Shared/Button/Button';
import Container from '../../../../../../Shared/Container/Container';
import ErrandsWarningPopUp from '../../../ErrandsWaringPopUp/ErrandsWarningPopUp';
import formatDate from '../../../../../../Shared/Formatting/formatDate';
import Link from '../../../../../../Shared/Link/Link';
import TableBody from '@material-ui/core/TableBody';
import { Fieldset } from '../../../../../../Shared/Form/Fieldset/Fieldset';
import { SaleProductStatus, saleProductStatusLabel, WarningStatus } from '../../Sale.api';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import { TableRow, TableCell, TableHead, Checkbox } from '@material-ui/core';
import { Table } from '../../../../../../Shared/Table/Table';
import { useHistory, useParams } from 'react-router-dom';
import {
  IProduct,
  MarkedProductId,
  useDeleteSupplierOrder,
  useGetSupplierOrder,
  useGetCombinedSupplierOrderBySaleId,
  useMarkSupplierOrderDelivered,
  ICombinedSupplierProduct,
  ISupplierOrder,
} from '../SupplierOrder.api';
import { ViewField } from '../../../../../../Shared/InputFields/InputField/ViewField';
import AccessGuard from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { AccessRights, ModuleIdentifiers } from '../../../../../../Core/Authentication/ModuleAccess';

const ViewSupplierOrder = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  // const { isLoading, supplierOrder, getSupplierOrder } = useGetSupplierOrder();
  const [supplierOrder, setSupplierOrder] = useState<ISupplierOrder>();
  const { isLoading, getCombinedSupplierOrderBySaleId } = useGetCombinedSupplierOrderBySaleId();
  const { isLoading: deleteSupplierOrderIsLoading, deleteSupplierOrder } = useDeleteSupplierOrder();
  const { isLoading: markSupplierOrderDeliveredIsLoading, put } = useMarkSupplierOrderDelivered(id);
  const [supplierProducts, setsupplierProducts] = useState<ICombinedSupplierProduct[]>([]);
  const [storagePlacement, setStoragePlacement] = useState<string | undefined>('');
  const [changedProducts, setChangedProducts] = useState<MarkedProductId[]>([]);

  useEffect(() => {
    async function get() {      
      const response = await getCombinedSupplierOrderBySaleId(id);
      if (response) {
        const combinedProducts: Array<ICombinedSupplierProduct> = [];
        response.forEach(x => {
          combinedProducts.push(...x.products.map((product) => {
            return {
              supplierName: x.supplierName,
              supplierMessage: x.message,
              supplierOrderId: x.id,
              receivedDate: x.deliveryDate,
              confirmedDate: x.confirmedDate,
              supplierOrderNumber: x.supplierOrderNumber,
              storagePlacement: x.storagePlacement,
              ...product
            }
          }));
        });
        setsupplierProducts(combinedProducts);
        setSupplierOrder(response[0]);
        setStoragePlacement(response[0].storagePlacement);
      } else {
        history.push('/errands/supplierorders');
      }
    }

    get();
  }, [getCombinedSupplierOrderBySaleId, history, id]);

  async function handleCancelOrder() {
    if (!supplierOrder?.saleId) return;

    const response = await deleteSupplierOrder(supplierOrder.saleId.toString());
    if (response !== null) {
      await history.push('/errands/supplierorders');
    }
  }

  async function handleUpdateOrder() {
    await put({
      storagePlacement: storagePlacement,
      markedProducts: changedProducts,
    });

    // const response = await getSupplierOrder(id);

    /*if (response !== null) {
      await history.push('/errands/supplierorders');
    }*/
  }

  function markAllProducts() {
    const filteredProduct = supplierProducts
      .filter((x) => x.status === SaleProductStatus.orderConfirmed)
      .map((x) => {
        return { saleProductId: x.saleProductId, serialNumber: x.serialNumber, storagePlacement: x.storagePlacement, delivered: true };
      });
    if (filteredProduct) {
      setChangedProducts(filteredProduct);
    }
  }

  return (
    <>
      <Container
        actions={
          <>
            {supplierOrder && (
              <>
                <AccessGuard module={ModuleIdentifiers.SupplierOrders} accessRights={AccessRights.Full}>
                  <ErrandsWarningPopUp
                    id={supplierOrder.id}
                    title={'Ta bort beställning'}
                    warningStatus={WarningStatus.supplierOrder}
                    action={handleCancelOrder}
                    isLoading={deleteSupplierOrderIsLoading}
                  />
                </AccessGuard>
                <AccessGuard module={ModuleIdentifiers.SupplierOrders} accessRights={AccessRights.Write}>
                  <Button
                    color="default"
                    variant="outlined"
                    onClick={handleUpdateOrder}
                    loading={markSupplierOrderDeliveredIsLoading}
                  >
                    Uppdatera beställning
                  </Button>
                </AccessGuard>
              </>
            )}
          </>
        }
        form
        customSize={{ sm: 12, xs: 12, md: 12, lg: 12 }}
        loading={isLoading}
        label="Beställning"
      >
        <Fieldset legend="Information">
          <ViewField label="Kund">
            <Link
              to={`/customers/${supplierOrder?.customerId}`}
              module={ModuleIdentifiers.Customer}
              accessRights={AccessRights.Read}
            >
              {supplierOrder?.customerName}
            </Link>
          </ViewField>
          <ViewField label="Order">
            <Link
              to={`/errands/orders/${supplierOrder?.saleId}`}
              module={ModuleIdentifiers.Orders}
              accessRights={AccessRights.Read}
            >
              {supplierOrder?.saleIdentifier}
            </Link>
          </ViewField>
          <InputField label="Skapad" type="text" value={formatDate(supplierOrder?.created)} viewOnly />
          <InputField
            label="Önskad leverans"
            type="text"
            value={formatDate(supplierOrder?.preferredDeliveryDate)}
            viewOnly
          />
          <InputField label="Leverans märks med" type="text" value={`[${supplierOrder?.deliveryMarking}]`} viewOnly />
          <InputField label="Meddelande" type="text" value={supplierOrder?.message} viewOnly />
          <ViewField label="Leverantör">
            <Link
              to={`/admin/supplier/${supplierOrder?.supplierId}`}
              module={ModuleIdentifiers.Admin}
              accessRights={AccessRights.Read}
            >
              {supplierOrder?.supplierName}
            </Link>
          </ViewField>
          {supplierOrder?.contactPerson && (
            <Fieldset legend="Beställare">
              <ViewField label={'Namn'}>
                <Link
                  to={`/customers/${supplierOrder?.customerId}/contact/${supplierOrder?.contactPerson.id}`}
                  module={ModuleIdentifiers.Customer}
                  accessRights={AccessRights.Read}
                >
                  {supplierOrder?.contactPerson.name}
                </Link>
              </ViewField>
              <ViewField label={'Email'} value={supplierOrder?.contactPerson.email} />
              <ViewField label={'Telefon'} value={supplierOrder?.contactPerson.phoneNumber} />
            </Fieldset>
          )}
        </Fieldset>

        <Fieldset legend="Produkter">
          <InputField
            label="Lagerplats"
            type="text"
            customWidth={3}
            value={storagePlacement}
            onChange={(e) => setStoragePlacement(e.target.value)}
          />
          <Table customSize={{ md: 12 }}>
            <TableHead>
              <TableRow>
                <TableCell>Namn</TableCell>
                <TableCell>Produktnummer</TableCell>
                <TableCell>Beskrivning</TableCell>
                <TableCell>Leverantör</TableCell>
                <TableCell>Meddelande</TableCell>
                <TableCell>Ord.nr. lev</TableCell>
                <TableCell>Bekräftad</TableCell>
                <TableCell>Lev.datum</TableCell>
                <TableCell>Levererat</TableCell>
                {false && <TableCell>Status</TableCell>}
                <TableCell color="blue" onClick={markAllProducts}>
                  Mottaget
                </TableCell>
                <TableCell>Serienummer</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {supplierProducts.map((product, index) => (
                <TableRow key={index}>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>{product.productNumber}</TableCell>
                  <TableCell>{product.description}</TableCell>
                  <TableCell>{product.supplierName}</TableCell>
                  <TableCell>{product.messageToPurchaser}</TableCell>
                  <TableCell>{product.supplierOrderNumber}</TableCell>
                  <TableCell>{formatDate(product.confirmedDate)}</TableCell>
                  <TableCell>{formatDate(product.deliveryDate)}</TableCell>
                  <TableCell>{formatDate(product.receivedDate)}</TableCell>
                  {false && (
                    <TableCell>{product.status !== null ? saleProductStatusLabel[product.status] : ''}</TableCell>
                  )}
                  <TableCell>
                    <Checkbox
                      role="checkbox"
                      color="primary"
                      checked={product.delivered === true}
                      onClick={() => {
                        product.delivered = !product.delivered;
                        setChangedProducts((x) => {
                          const existingProduct = x.find((c) => c.saleProductId === product.saleProductId);
                          if (x.length > 0 && existingProduct !== undefined) {
                            existingProduct.serialNumber = product.serialNumber;
                            existingProduct.delivered = product.delivered;
                          } else {
                            if (x.length === 0 || !existingProduct) {
                              x.push({
                                saleProductId: product.saleProductId,
                                serialNumber: product.serialNumber,
                                delivered: product.delivered,
                              });
                            }
                          }
                          return x;
                        });
                        setsupplierProducts([...supplierProducts]);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <InputField
                      fullwidth
                      value={product.serialNumber}
                      onChange={(e) =>
                        setChangedProducts((x) => {
                          product.serialNumber = e.target.value;
                          let newState = x.map((obj) => {
                            if (obj.saleProductId === product.saleProductId) {
                              return {
                                saleProductId: product.saleProductId,
                                serialNumber: product.serialNumber,
                                delivered: product.delivered,
                              };
                            }
                            return obj;
                          });

                          const existingProduct = x.find((c) => c.saleProductId === product.saleProductId);
                          if (newState.length === 0 || !existingProduct) {
                            newState.push({
                              saleProductId: product.saleProductId,
                              serialNumber: product.serialNumber,
                              delivered: product.delivered,
                            });
                          }
                          return newState;
                        })
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Fieldset>
      </Container>
    </>
  );
};

export default ViewSupplierOrder;
