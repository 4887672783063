import React, { createContext, useState, useEffect, useContext } from 'react';
import useUserContext from '../../../../Core/Authentication/UserContext';

interface IBreadcrumbNavigationContext {
  setBreadcrumbNavigationItems: (links: IBreadbrumbItem[]) => void;
  breadcrumbNavigationItems: IBreadbrumbItem[];
}

export interface IBreadbrumbItem {
  title: string;
  to?: string;
}

const BreadcrumbNavigationContext = createContext<IBreadcrumbNavigationContext>({} as IBreadcrumbNavigationContext);
const useBreadcrumbNavigationContext = () => useContext(BreadcrumbNavigationContext);

interface IProps {
  children: React.ReactNode;
}

export function BreadcrumbNavigationContextProvider(props: IProps) {
  const [breadcrumbNavigationItems, setBreadcrumbNavigationItems] = useState<IBreadbrumbItem[]>([]);
  const { hasAccess } = useUserContext();

  const [breadcrumbNavigationContext, setBreadcrumbNavigationContext] = useState<IBreadcrumbNavigationContext>({
    breadcrumbNavigationItems: breadcrumbNavigationItems,
    setBreadcrumbNavigationItems: setBreadcrumbNavigationItems,
  });

  useEffect(() => {
    setBreadcrumbNavigationContext((prev) => ({
      ...prev,
      breadcrumbNavigationItems: breadcrumbNavigationItems,
    }));
  }, [breadcrumbNavigationItems, hasAccess]);

  return (
    <BreadcrumbNavigationContext.Provider value={breadcrumbNavigationContext}>
      {props.children}
    </BreadcrumbNavigationContext.Provider>
  );
}

export default useBreadcrumbNavigationContext;
