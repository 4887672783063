import React, { useState, useCallback } from 'react';
import { AsyncSelect, ISelectOption } from '../../../../../Shared/Select';
import { useSearchFacilityCustomers, ICustomerFacilityListItem } from '../../Customers.api';

interface ICustomerSelectOption extends ISelectOption {
  customerFacility: ICustomerFacilityListItem;
}

export function customerDisplayName(customerFacility: ICustomerFacilityListItem): string {
  return (
    customerFacility.facilityNumber +
    ': ' +
    customerFacility.name + 
    (customerFacility.name !== customerFacility.facilityName ? (': ' +
    customerFacility.facilityName) : '') + 
    ' - ' +
    customerFacility.facilityAddress
  );
}
export interface IProps {
  customerId?: number;
  facilityId?: number;
  disabled?: boolean;
  isClearable?: boolean;
  required?: boolean;
  requireSystem?: boolean;
  fullWidth?: boolean;
  onChange?: (customerFacility: ICustomerFacilityListItem | undefined) => void;
}

const CustomerFacilitySelect = (props: IProps) => {
  const { requireSystem, customerId, facilityId, onChange } = props;
  const { searchCustomerFacilities: searchCustomers, isLoading } = useSearchFacilityCustomers();
  const [selected, setSelected] = useState<ICustomerSelectOption>();

  const loadCustomers = useCallback(
    async (query?: string) => {
      const response = await searchCustomers({ query: query, hasSystem: requireSystem, facilityId: facilityId });
      var options = response?.map((x) => ({
        value: x.id + '-' + x.facilityId,
        label: customerDisplayName(x),
        customerFacility: x,
      }));

      if (customerId && facilityId && options) {
        setSelected(options.find((x) => x.value === customerId + '-' + facilityId));
      }

      return options;
    },
    [requireSystem, customerId, facilityId, searchCustomers],
  );

  return (
    <AsyncSelect
      isClearable={props.isClearable}
      label="Välj kund"
      required={props.required}
      isLoading={isLoading}
      fullwidth={props.fullWidth}
      isDisabled={props.disabled}
      selectedOption={selected}
      loadOptions={loadCustomers}
      onChange={(value) => {
        onChange && onChange((value as ICustomerSelectOption) ? (value as ICustomerSelectOption).customerFacility : undefined);
        setSelected(value as ICustomerSelectOption);
      }}
    />
  );
};
export default CustomerFacilitySelect;
