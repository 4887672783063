import { makeStyles, Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    helpLabel: {
      marginTop: '0.25rem',
      fontSize: '1rem',
    },
    errorLabel: {
      color: theme.palette.error.dark,
      fontSize: '1rem',
    },
  }),
);
