import React from 'react';
import TableBody from '@material-ui/core/TableBody/TableBody';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import { AddCircleRounded } from '@material-ui/icons';
import IconButton from '../../../../../../../../../../../Shared/IconButton/IconButton';
import { Table } from '../../../../../../../../../../../Shared/Table/Table';
import { InputField } from '../../../../../../../../../../../Shared/InputFields/InputField/InputField';
import useInputState from '../../../../../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import useStyles from './Buildings.styles';

interface IBuildingsProps {
  rows: string[];
  setRows: (rows: string[]) => void;
}

export const Buildings = (props: IBuildingsProps) => {
  const classes = useStyles();
  const [building, changeBuilding, setBuilding] = useInputState('');

  const onAdd = () => {
    if (!building) return;
    let newRows = [...props.rows, building];
    props.setRows(newRows);
    setBuilding('');
  };

  const onDelete = (index: number) => {
    let newRows = [...props.rows];
    newRows.splice(index, 1);
    props.setRows(newRows);
  };

  return (
    <Table customSize={{ lg: 12, md: 12 }}>
      <TableHead>
        <TableRow>
          <TableCell>Byggnader som omfattas</TableCell>
          <TableCell>
            <span className="sr-only">Lägg till och ta bort</span>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.rows.map((row, index) => (
          <TableRow key={row + '_' + index}>
            <TableCell>{row}</TableCell>
            <TableCell>
              <IconButton
                size="small"
                aria-label="Ta bort utbildning"
                className={`${classes.IconBtn} ${classes.remove}`}
                onClick={() => onDelete(index)}
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell>
            <InputField placeholder="Byggnad" type="text" value={building} onChange={changeBuilding} fullWidth />
          </TableCell>
          <TableCell className={classes.actionTc}>
            <IconButton
              color="primary"
              size="small"
              aria-label="Lägg till byggnad"
              className={classes.IconBtn}
              onClick={onAdd}
            >
              <AddCircleRounded />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
