import React, { useState, useEffect } from 'react';
import { IErrorReportStepProps } from '../ReportForm';
import {
  IAddInvestigationtResultValidationErrors,
  IAddInvestigationtResult,
  useAddInvestigationtResult,
  useGetErrorReportCategories,
  ErrorReportState,
} from '../../../Reports.api';
import useInputState from '../../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { isResultError } from '../../../../../../../../Shared/Api/response/ICreateResult';
import Container from '../../../../../../../../Shared/Container/Container';
import { Fieldset } from '../../../../../../../../Shared/Form/Fieldset/Fieldset';
import { InputField } from '../../../../../../../../Shared/InputFields/InputField/InputField';
import useFormValidation from '../../../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import RadioField from '../../../../../../../../Shared/InputFields/RadioField/RadioField';
import { FormControlLabel, Radio } from '@material-ui/core';
import Select, { ISelectOption } from '../../../../../../../../Shared/Select/Select';
import MultiButton from '../../../../../../../../Shared/MultiButton/MultiButton';
import MultiButtonItem from '../../../../../../../../Shared/MultiButton/MultiButtonItem/MultiButtonItem';

const AddInvestigationResult: React.FunctionComponent<IErrorReportStepProps> = (props: IErrorReportStepProps) => {
  const { errorReport, onSave, errorReportIsLoading, children } = props;
  const { isFormValid, formRef } = useFormValidation();
  const { isLoading, addInvestigationtResult } = useAddInvestigationtResult();
  const { getErrorReportCategories, isLoading: getErrorReportCategoriesIsLoading } = useGetErrorReportCategories();
  const [errorCategoryOptions, setErrorCategoryOptions] = useState<ISelectOption[]>([]);
  const [investigationResult, changeInvestigationResult, setInvestigationResult] = useInputState('');
  const [errorMeasure, changeErrorMeasure, setErrorMeasure] = useInputState('');
  const [errorCategory, setErrorCategory] = useState<number>();
  const [invoiceCustomer, setInvoiceCustomer] = useState<boolean>(true);
  const [savingWithGoNextStep, setSavingWithGoNextStep] = useState<boolean>();
  const [errors, setErrors] = useState<IAddInvestigationtResultValidationErrors>();

  const isValid = isFormValid && investigationResult && errorMeasure && errorCategory;

  useEffect(() => {
    if (errorReport) {
      errorReport.investigationResult && setInvestigationResult(errorReport.investigationResult);
      errorReport.errorMeasure && setErrorMeasure(errorReport.errorMeasure);
      setErrorCategory(errorReport.errorReportCategoryId);
      if (errorReport.invoiceCustomer !== undefined && errorReport.invoiceCustomer !== null)
        setInvoiceCustomer(errorReport.invoiceCustomer);
    }
  }, [errorReport, setErrorMeasure, setInvestigationResult]);

  useEffect(() => {
    async function loadErrorCategories() {
      const errorCategories = await getErrorReportCategories();
      if (errorCategories)
        setErrorCategoryOptions(errorCategories.map((x) => ({ label: x.description, value: x.id.toString() })));
    }
    loadErrorCategories();
  }, [getErrorReportCategories]);

  async function handleAddInvestigationResult(goToNextStep: boolean) {
    setSavingWithGoNextStep(goToNextStep);
    const command: IAddInvestigationtResult = {
      investigationResult: investigationResult,
      errorMeasure: errorMeasure,
      errorReportCategoryId: errorCategory as number,
      invoiceCustomer: invoiceCustomer,
    };

    const result = await addInvestigationtResult(command, (errorReport?.id as number).toString());

    if (isResultError(result)) {
      setErrors(result);
    } else {
      onSave(goToNextStep);
    }
  }

  function Actions() {
    return (
      <>
        {props.actions}
        {errorReport?.errorReportState !== ErrorReportState.Completed && (
          <>
            <MultiButton
              color="primary"
              variant="contained"
              disabled={!isValid || isLoading}
              loading={isLoading && savingWithGoNextStep}
              onClick={() => handleAddInvestigationResult(true)}
              menuItems={
                <MultiButtonItem
                  disabled={!isValid || isLoading}
                  loading={isLoading && !savingWithGoNextStep}
                  onClick={() => handleAddInvestigationResult(false)}
                >
                  Spara och stäng
                </MultiButtonItem>
              }
            >
              Spara och gå till nästa steg
            </MultiButton>
          </>
        )}
      </>
    );
  }

  return (
    <Container form ref={formRef} loading={errorReportIsLoading} label="Redigera felrapport" actions={<Actions />}>
      {children}
      <Fieldset legend="Steg 2 - Undersökning">
        <InputField
          label="Undersökningsresultat"
          required
          multiline
          rows={5}
          type="text"
          value={investigationResult}
          onChange={changeInvestigationResult}
          errorText={errors?.investigationResult}
        />
        <InputField
          label="Åtgärd för att lösa problem"
          required
          multiline
          rows={5}
          type="text"
          value={errorMeasure}
          onChange={changeErrorMeasure}
          errorText={errors?.errorMeasure}
        />
        <Select
          inputFieldProps={{ label: 'Felkategori', required: true, errorText: errors?.errorReportCategoryId }}
          selectProps={{
            value: errorCategoryOptions.find((x) => x.value === errorCategory?.toString()),
            onChange: (selectedValue) => {
              setErrorCategory(Number((selectedValue as ISelectOption).value));
            },
            options: errorCategoryOptions,
            isClearable: false,
            isLoading: getErrorReportCategoriesIsLoading,
          }}
        />
        <RadioField required label="Debiteras kund">
          <FormControlLabel
            label="Debiteras"
            control={
              <Radio color="primary" checked={invoiceCustomer} onChange={(_, checked) => setInvoiceCustomer(checked)} />
            }
          />
          <FormControlLabel
            label="Debiteras ej"
            control={
              <Radio
                color="primary"
                checked={!invoiceCustomer}
                onChange={(_, checked) => setInvoiceCustomer(!checked)}
              />
            }
          />
        </RadioField>
      </Fieldset>
    </Container>
  );
};

export default AddInvestigationResult;
