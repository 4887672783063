import React, { useEffect, useCallback, useMemo } from 'react';
import { AsyncSelect, ISelectOption } from '../../../../../../Shared/Select';
import { ICustomerContact, useGetContactList } from '../../../Customers.api';
import { GridSize } from '@material-ui/core';

export interface IProps {
  onChange?: (
    companyUserId: string | undefined,
    companyUserName: string | undefined,
    option: ISelectOption | ISelectOption[] | undefined,
    allOptionsSelected: boolean | undefined,
  ) => void;
  customerId: string;
  chosenContacts?: ICustomerContact[];
  required?: boolean;
  selectedCompanyUser?: ISelectOption | ISelectOption[];
  selectedCompanyUserId?: string | string[];
  fullwidth?: boolean;
  companyUserIds?: number[];
  autoFocus?: boolean;
  customWidth?: GridSize;
  label?: string;
}

const CustomerContactSelector = (props: IProps) => {
  const { customerId, chosenContacts, selectedCompanyUser, selectedCompanyUserId } = props;
  const { isLoading, getContactList } = useGetContactList(customerId);

  const selectedOption = useMemo(() => {
    if (!selectedCompanyUser) return undefined;

    return Array.isArray(selectedCompanyUser) ? selectedCompanyUser[0] : selectedCompanyUser;
  }, [selectedCompanyUser]);

  const selectedOptionId = useMemo(() => {
    if (!selectedCompanyUserId) return undefined;
    return Array.isArray(selectedCompanyUserId) ? selectedCompanyUserId[0] : selectedCompanyUserId;
  }, [selectedCompanyUserId]);

  const loadUsers = useCallback(async () => {
    const response = await getContactList();
    var newOptions =
      response?.filter((x) => {
        return(!chosenContacts?.find((y) => y.id === x.id))
      }).map((x) => ({
        value: x.id.toString(),
        label: x.firstname + ' ' + x.lastname,
      })) ?? [];
    return newOptions;
  }, [getContactList, chosenContacts]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers, customerId]);

  return (
    <AsyncSelect
      {...props}
      customWidth={props.customWidth}
      placeholder={'Välj kundkontakt'}
      autoFocus={props.autoFocus ?? true}
      isLoading={isLoading}
      selectedOption={selectedOption as ISelectOption | undefined}
      selectedOptionId={selectedOptionId as string | undefined}
      loadOptions={loadUsers}
      label={props.label}
      onChange={(value) => props.onChange && props.onChange(value?.value, value?.label, value ?? undefined, false)}
    />
  );
};

export default CustomerContactSelector;
