import React, { useContext, useEffect, useState } from 'react';
import InputBase, { InputBaseProps } from '@material-ui/core/InputBase';
import { InputAdornment, FormControl, Grid, GridSize } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/SearchRounded';
import { ContainerContext } from '../../Container/Container';
import InputLabel from '../InputLabel/InputLabel';
import { ViewField } from './ViewField';
import { InputFieldLoading } from './InputFieldLoading/InputFieldLoading';
import { HelperText } from './HelperText/HelperText';
import { useStyles } from './InputField.styles';

export interface IInputFieldProps extends InputBaseProps {
  label?: string;
  helperText?: string | React.ReactNode;
  errorText?: string | string[];
  viewOnly?: boolean;
  pattern?: string;
  onEnter?: (event: React.KeyboardEvent<HTMLElement>) => void;
  fullwidth?: boolean;
  customWidth?: GridSize;
  disableErrorText?: boolean;
  hideArrows?: boolean;
  success?: boolean;
  extraMargin?: number;
}

export const InputField: React.FunctionComponent<IInputFieldProps> = (props) => {
  const classes = useStyles();
  const containerContext = useContext(ContainerContext);
  const [errorValidation, setErrorValidation] = useState<string>();
  const {
    label,
    helperText,
    errorText,
    children,
    value,
    fullwidth,
    customWidth,
    endAdornment,
    viewOnly,
    pattern,
    onEnter,
    disableErrorText,
    success,
    extraMargin,
    ...rest
  } = props;
  const endAdornmentIsString = typeof endAdornment === 'string';
  let inputProps = props.inputProps;

  useEffect(() => {
    if (!RegExp(inputProps?.pattern as string).test(value as string) && value) {
      setErrorValidation(`${label} har inte ett korrekt format.`);
    } else {
      setErrorValidation(undefined);
    }
  }, [value, inputProps, label]);

  function getFormControlClasses() {
    let classNames: string[] = [classes.formControl];

    if (errorText) classNames.push(classes.error);
    if (!errorText && success) classNames.push(classes.success);
    if (endAdornment) classNames.push(classes.withEndAdornment);
    if (rest.startAdornment || rest.type === 'search') classNames.push(classes.withStartAdornment);
    if (!inputProps?.pattern && props.required) {
      if (!props.type || props.type === 'text') {
        inputProps = { ...inputProps, pattern: '.*\\S+.*' };
      }
    }
    if (props.type && props.type === 'tel') inputProps = { ...inputProps, pattern: '^[0-9]+$' };
    return classNames.join(' ');
  }

  function getErrorText(error: string | string[]) {
    if (typeof error === 'string') return error;

    return error.length > 0 ? error[0] : '';
  }

  const labelWithoutSpaces = label?.replace(/ /g, '');

  return (
    <Grid item md={fullwidth ? 12 : customWidth ?? 6} sm={fullwidth ? 12 : customWidth ?? 6} xs={12} style={{padding: `${extraMargin}rem` ?? 'inherit'}}>
      {containerContext?.loading ? (
        <InputFieldLoading />
      ) : (
        <>
          {!viewOnly ? (
            <FormControl error={props.error} className={`${getFormControlClasses()} formcontrol`}>
              <>
                {children ? (
                  children
                ) : (
                  <>
                    {label && <InputLabel label={label} required={props.required} id={labelWithoutSpaces} />}
                    <InputBase
                      {...rest}
                      value={value === undefined || value === null ? '' : value}
                      onKeyPress={(e) => {
                        if (onEnter && e.key === 'Enter') {
                          onEnter(e);
                        }
                      }}
                      className={`${errorValidation ? classes.error : ''} `}
                      inputProps={{
                        ...inputProps,
                        'data-lpignore': 'true',
                        'aria-labelledby': labelWithoutSpaces,
                        'aria-label': !label ? rest.placeholder : undefined,
                      }}
                      endAdornment={
                        endAdornmentIsString ? (
                          <InputAdornment position="end">{endAdornment}</InputAdornment>
                        ) : (
                          endAdornment
                        )
                      }
                      startAdornment={
                        rest.type === 'search' ? (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ) : (
                          rest.startAdornment
                        )
                      }
                      placeholder={rest.placeholder ?? (typeof helperText === 'string' ? helperText : undefined)}
                    />
                  </>
                )}
                {(typeof helperText !== 'string' || errorText) && (
                  <HelperText
                    helperText={helperText}
                    errorText={disableErrorText ? undefined : errorValidation ?? getErrorText(errorText ?? '')}
                  />
                )}
              </>
            </FormControl>
          ) : (
            <div className={`${getFormControlClasses()} ${classes.viewOnly}`}>
              <ViewField
                fullwidth
                label={label}
                endAdornment={endAdornmentIsString && ' ' + endAdornment}
                multiline={rest.multiline}
                value={value as string | number}
              />
            </div>
          )}
        </>
      )}
    </Grid>
  );
};
