import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SaleType } from '../../../../../Shared/SaleType';
import SelectCustomerAndSaleType from './SelectCustomerAndSaleType/SelectCustomerAndSaleType';
import SelectFacilityAndSystem from './SelectFacilityAndSystem/SelectFacilityAndSystem';
import SetAgreement from './SetAgreement/SetAgreement';
import SetPrices from './SetPrices/SetPrices';
import SetSaleTerms from './SetSaleTerms/SetSaleTerms';
import Summary from './Summary/Summary';
import OfferFormContext, { IOfferFormContext } from './OfferFormContext';
import { getActivePage, getNextPage, OfferSteps, saveActivePage, useGetOfferContext } from '../Offer.api';

const OfferForm: React.FunctionComponent = () => {
  const { id: offerId } = useParams<{ id: string }>();
  const { get: getOffer } = useGetOfferContext();

  const [activePage, setActivePage] = useState(offerId ? OfferSteps.notSet : OfferSteps.setCustomer);
  const [offerType, setOfferType] = useState(SaleType.system);
  const [offerCustomerId, setOfferCustomerId] = useState(0);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [identifier, setIdentifier] = useState('');

  const handleSetOfferStep = useCallback(
    (offerStep: OfferSteps) => {
      setActivePage(offerStep);
      saveActivePage(offerId, offerStep);
    },
    [offerId],
  );

  const offerFormContext: IOfferFormContext = {
    offerStep: activePage,
    setOfferStep: handleSetOfferStep,
    offerType: offerType,
    setOfferType: setOfferType,
    customerId: offerCustomerId,
    setCustomerId: setOfferCustomerId,
    referenceNumber: referenceNumber,
    setReferenceNumber: setReferenceNumber,
    identifier: identifier,
    setIdentifier: setIdentifier,
    customSize: { lg: 12, md: 12 },
  };

  /* Set initial offer data and active page for existing offer */
  useEffect(() => {
    async function loadOffer() {
      // Load offer context
      if (!!offerId) {
        let offerStepInContext: OfferSteps | undefined = getActivePage(offerId);
        const response = await getOffer(offerId);

        if (response) {
          let offerStepInDatabase = getNextPage(response.saleType, response.offerStep);

          if (offerStepInContext && offerStepInContext <= offerStepInDatabase) {
            setActivePage(offerStepInContext);
          } else if (response.offerStep || response.offerStep === OfferSteps.notSet) {
            saveActivePage(offerId, offerStepInDatabase);
            setActivePage(offerStepInDatabase);
          }
          setOfferType(response.saleType);
          setOfferCustomerId(response.customerId);
          setReferenceNumber(response.referenceNumber);
          setIdentifier(response.saleIdentifier);
        }
      }
      // Or reset context
      else {
        setActivePage(OfferSteps.setCustomer);
        setOfferType(SaleType.system);
        setOfferCustomerId(0);
        setReferenceNumber('');
        setIdentifier('');
      }
    }
    loadOffer();
  }, [offerId, getOffer]);

  return (
    <OfferFormContext.Provider value={offerFormContext}>
      {activePage === OfferSteps.setCustomer && <SelectCustomerAndSaleType />}
      {activePage === OfferSteps.setFacilityAndSystem && <SelectFacilityAndSystem />}
      {activePage === OfferSteps.setAgreement && <SetAgreement />}
      {activePage === OfferSteps.setPrices && <SetPrices />}
      {activePage === OfferSteps.setSaleTerms && <SetSaleTerms />}
      {activePage === OfferSteps.summary && <Summary />}
    </OfferFormContext.Provider>
  );
};

export default OfferForm;
