import React, { useEffect, useState } from 'react';
import { TableHead, TableRow, TableCell, TableBody, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { Table } from '../../../../../../Shared/Table/Table';
import moment from 'moment';
import { useGetSaleStats } from '../../SaleStats.api';
import DatePicker from '../../../../../../Shared/InputFields/DatePicker/Datepicker';
import formatPrice from '../../../../../../Shared/Formatting/formatPrice';
import { MobileTableRow } from '../../../../../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import { MobileTable } from '../../../../../../Shared/Table/MobileTable/MobileTable';
import useStyles from '../Overview/Overview.styles';

const monthStart = moment().startOf('month').toDate();
const Sales = () => {
  const classes = useStyles();
  const { isLoading, saleStats, getSaleStats } = useGetSaleStats();
  const [date, setDate] = useState(monthStart);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    getSaleStats({ date: date });
  }, [getSaleStats, date]);

  return (
    <div className={classes.root}>
      <div className={classes.filter}>
        <DatePicker
          label="År/månad"
          required
          selected={date}
          onChange={(d: Date | null) => setDate(d as Date)}
          dateFormat="MM/yyyy"
          showMonthYearPicker
        />
      </div>
      <div className={classes.container}>
        <Typography id="forsaljning" component="h2" variant="h4" className={classes.label}>
          Försäljning
        </Typography>
        <Table
          customSize={{ fullsize: true }}
          loading={{ loading: isLoading }}
          aria-labelledby="forsaljning"
          mobile={mobileView}
        >
          {mobileView ? (
            <>
              {saleStats?.companyUserSales.map((x) => (
                <MobileTable key={x.companyUserId} label={x.companyUser}>
                  <MobileTableRow label="Anställd" value={x.companyUser} />
                  <MobileTableRow label="Totalt" value={formatPrice(x.total)} />
                  <MobileTableRow label="System" value={formatPrice(x.systemTotal)} />
                  <MobileTableRow label="Avtal" value={formatPrice(x.agreementTotal)} />
                  <MobileTableRow label="Nya kunder" value={x.newCustomers} />
                  <MobileTableRow label="Antal avtal" value={x.agreementCount} />
                  <MobileTableRow label="Antal ordrar" value={x.orderCount} />
                </MobileTable>
              ))}
            </>
          ) : (
            <>
              <TableHead>
                <TableRow>
                  <TableCell>Anställd</TableCell>
                  <TableCell>Totalt</TableCell>
                  <TableCell>System</TableCell>
                  <TableCell>Avtal</TableCell>
                  <TableCell>Nya kunder</TableCell>
                  <TableCell>Antal avtal</TableCell>
                  <TableCell>Antal ordrar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {saleStats?.companyUserSales.map((x) => (
                  <TableRow key={x.companyUserId}>
                    <TableCell>{x.companyUser}</TableCell>
                    <TableCell>{formatPrice(x.total)}</TableCell>
                    <TableCell>{formatPrice(x.systemTotal)}</TableCell>
                    <TableCell>{formatPrice(x.agreementTotal)}</TableCell>
                    <TableCell>{x.newCustomers}</TableCell>
                    <TableCell>{x.agreementCount}</TableCell>
                    <TableCell>{x.orderCount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          )}
        </Table>
        <Typography id="offertstock" component="h2" variant="h4" className={`${classes.label} ${classes.qoutation}`}>
          Offertstock
        </Typography>
        <Table customSize={{ fullsize: true }} aria-labelledby="offertstock">
          {mobileView ? (
            <>
              {saleStats?.companyUserOffers.map((x) => (
                <MobileTable key={x.companyUserId} label={x.companyUserName}>
                  <MobileTableRow label="Anställd" value={x.companyUserName} />
                  <MobileTableRow label="Summa" value={formatPrice(x.total)} />
                  <MobileTableRow label="Antal offerter" value={formatPrice(x.count)} />
                </MobileTable>
              ))}
            </>
          ) : (
            <>
              <TableHead>
                <TableRow>
                  <TableCell>Anställd</TableCell>
                  <TableCell>Summa</TableCell>
                  <TableCell>Antal offerter</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {saleStats?.companyUserOffers.map((x) => (
                  <TableRow key={x.companyUserId}>
                    <TableCell>{x.companyUserName}</TableCell>
                    <TableCell>{formatPrice(x.total)}</TableCell>
                    <TableCell>{x.count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          )}
        </Table>
      </div>
    </div>
  );
};

export default Sales;
