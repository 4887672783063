import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../Styles/Theme';

const useStyles = makeStyles({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  content: {
    padding: '0.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
  },
  circle: {
    height: '1.5rem',
    width: '1.5rem',
  },
  line: {
    flexGrow: 1,
    height: '1.5rem',
    margin: '0.25rem 0 0.25rem 1rem',
  },
});

export default useStyles;
