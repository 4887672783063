import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  systeminformation: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  information: {
    marginBottom: '1rem',
    marginRight: '1rem',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginRight: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  products: {
    width: '100%',
    maxWidth: 'calc(100vw - 10rem)',
    overflowX: 'hidden',
  },
  arrowBtn: {
    fontWeight: 200,
    padding: '1rem 1rem 0.75rem',
    float: 'right',
  },
  wrapper: {
    width: "100%", 
    maxWidth: "1240px", 
    paddingTop: '1rem'
  }
}));
