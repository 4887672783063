import { useCallback } from 'react';
import { useApiGet, useApiPost, useApiPut } from '../../../../../Core/Api/Api';
import { ICreateResult } from '../../../../../Shared/Api/response/ICreateResult';
import ValidationErrorResultType from '../../../../../Shared/Api/response/ValidationErrorResultType';

export const termsValidPeriodTexts = ['30 dagar', '60 dagar'];

export enum IPreselectionType {
  AgreementTerms,
  DocumentFormat,
  MeetingNotes,
  OfferTerms,
  EmailContent,
  OfferOptional,
  DeliveryAndWarrantyTerms
}

export const IPreselectionTypes = [
  { type: IPreselectionType.AgreementTerms, name: 'Avtal' },
  { type: IPreselectionType.DocumentFormat, name: 'Dokument' },
  { type: IPreselectionType.MeetingNotes, name: 'Mötesfrågor' },
  { type: IPreselectionType.OfferTerms, name: 'Offerts villkor' },
  { type: IPreselectionType.EmailContent, name: 'Email' },
  { type: IPreselectionType.OfferOptional, name: 'Offerts tillval' },
  { type: IPreselectionType.DeliveryAndWarrantyTerms, name: 'Leverans- & garantivillkor' },
];

export function getPreselectionTypeName(type: IPreselectionType) {
  return IPreselectionTypes.find((x) => x.type === type)?.name ?? '';
}

export enum IPreselectionDocumentType {
  Agreement,
  DocumentHeader,
  DocumentFooter,
  MeetingNotes,
  OfferTerms,
  EmailContent,
  OfferOptional,
  DeliveryAndWarrantyTerms
}

interface IPreselectionDefault {
  type: IPreselectionDocumentType;
}
export interface IPreselectionContent {
  content: string;
}

export function useGetPreselectionDefault() {
  const { isLoading, response, post } = useApiPost<IPreselectionContent, IPreselectionDefault>(
    '/api/companies/preselection/default',
    false,
  );

  return {
    isLoading,
    preselectionDefault: response,
    getPreselectionDefault: post,
  };
}

export interface IPreselectionFileInformationDto {
  id: number;
  name: string;
  created: Date;
}

export interface IPreselectedAgreementTerms {
  id?: number;
  auditInterval?: number;
  invoiceInterval?: number;
  includeInnerControl: boolean;
  termsAndConditions?: string;
}

export interface IPreselectedAgreementTermsDto extends IPreselectedAgreementTerms {
  termsAndConditionsFileNames: IPreselectionFileInformationDto[];
}

export function useGetPreselectedAgreementTerms() {
  const { isLoading, response, get } = useApiGet<IPreselectedAgreementTermsDto>(
    '/api/companies/preselection/agreementterms',
    false,
  );

  return {
    isLoading,
    preselectedTerms: response,
    getPreselectedAgreementTerms: get,
  };
}

export interface IUpsertPreselectedAgreementTermsCommandValidationErrors
  extends ValidationErrorResultType<IPreselectedAgreementTerms> {}

export function useUpsertPreselectedAgreementTerms() {
  const { isLoading: isCreateLoading, post } = useApiPost<
    ICreateResult<IUpsertPreselectedAgreementTermsCommandValidationErrors>,
    IPreselectedAgreementTerms
  >(`/api/companies/preselection/agreementterms/`);

  const { isLoading: isUpdateLoading, put } = useApiPut<
    IUpsertPreselectedAgreementTermsCommandValidationErrors,
    IPreselectedAgreementTerms
  >(`/api/companies/preselection/agreementterms/`);

  const upsert = useCallback(
    async (command: IPreselectedAgreementTerms, id?: string) => {
      if (id && id !== '0') {
        const result = await put(command, id);
        return result ? result : id;
      }

      return post(command);
    },
    [put, post],
  );

  return {
    isLoading: isCreateLoading || isUpdateLoading,
    upsert: upsert,
  };
}

export enum IDocumentFormatType {
  Header,
  Footer,
}

export function getDocumentFormatTypeName(type: IDocumentFormatType) {
  return IPreselectedDocumentFormatSection.find((x) => x.type === type)?.name ?? '';
}

export const IPreselectedDocumentFormatSection = [
  { type: IDocumentFormatType.Header, name: 'Sidhuvud' },
  { type: IDocumentFormatType.Footer, name: 'Sidfot' },
];

export interface IPreselectedDocumentFormatDto {
  id?: number;
  customHeader?: string | null;
  customFooter?: string | null;
  customHeaderFileNames: IPreselectionFileInformationDto[];
  customFooterFileNames: IPreselectionFileInformationDto[];
}

export function useGetPreselectedDocumentFormat() {
  const { isLoading, response, get } = useApiGet<IPreselectedDocumentFormatDto>(
    '/api/companies/preselection/documentformat',
    false,
  );

  return {
    isLoading,
    preselectedFormats: response,
    getPreselectedDocumentFormat: get,
  };
}

export interface IUpsertPreselectedDocumentFormat {
  id?: number;
  customContent?: string | null;
  documentType: IDocumentFormatType;
}

export interface IUpsertPreselectedDocumentFormatCommandValidationErrors
  extends ValidationErrorResultType<IUpsertPreselectedDocumentFormat> {}

export function useUpsertPreselectedDocumentFormat() {
  const { isLoading: isCreateLoading, post } = useApiPost<
    ICreateResult<IUpsertPreselectedDocumentFormatCommandValidationErrors>,
    IUpsertPreselectedDocumentFormat
  >(`/api/companies/preselection/documentformat`);

  const { isLoading: isUpdateLoading, put } = useApiPut<
    IUpsertPreselectedDocumentFormatCommandValidationErrors,
    IUpsertPreselectedDocumentFormat
  >(`/api/companies/preselection/documentformat/`);

  const upsert = useCallback(
    async (command: IUpsertPreselectedDocumentFormat, id?: string) => {
      if (id && id !== '0') {
        const result = await put(command, id);
        return result ? result : id;
      }

      return post(command);
    },
    [put, post],
  );

  return {
    isLoading: isCreateLoading || isUpdateLoading,
    upsert: upsert,
  };
}

export interface IUpsertMeetingNotesTemplateCommand {
  name: string;
  content: string;
}

export interface IUpsertMeetingNotesTemplateCommandValidationErrors
  extends ValidationErrorResultType<IUpsertMeetingNotesTemplateCommand> {}

export function useUpsertMeetingNotesTemplate() {
  const { isLoading: isCreateLoading, post } = useApiPost<
    ICreateResult<IUpsertMeetingNotesTemplateCommandValidationErrors>,
    IUpsertMeetingNotesTemplateCommand
  >(`/api/companies/preselection/meetingnotes/`);

  const { isLoading: isUpdateLoading, put } = useApiPut<
    IUpsertMeetingNotesTemplateCommandValidationErrors,
    IUpsertMeetingNotesTemplateCommand
  >(`/api/companies/preselection/meetingnotes/`);

  const upsert = useCallback(
    async (command: IUpsertMeetingNotesTemplateCommand, id?: string) => {
      if (id) {
        const result = await put(command, id);
        return result ? result : id;
      }

      return post(command);
    },
    [put, post],
  );

  return {
    isLoading: isCreateLoading || isUpdateLoading,
    upsert: upsert,
  };
}

export interface IPreselectedOfferTermsData {
  saleDescription: string;
  inclusive: string;
  regulation: string;
  exclusive: string;
  validPeriod: string;
  delivery: string;
  warranty: string;
}

export interface IPreselectedOfferTermsDto {
  latestOfferTermsData: IPreselectedOfferTermsData;
  offerTermsFileInformation: IPreselectionFileInformationDto[];
}

export function useGetPreselectedOfferTerms() {
  const { isLoading, response, get } = useApiGet<IPreselectedOfferTermsDto>(
    '/api/companies/preselection/offerterms',
    undefined,
  );

  return {
    isLoading,
    response,
    getPreselectedOfferTerms: get,
  };
}

export function useGetLatestPreselectedOfferTerms() {
  const { isLoading, response, get } = useApiGet<IPreselectedOfferTermsData>(
    '/api/companies/preselection/offerterms/latest',
    undefined,
  );

  return {
    isLoading,
    response,
    getLatestOfferTerms: get,
  };
}

export interface IUpsertPreselectedOfferTermsCommandValidationErrors
  extends ValidationErrorResultType<IPreselectedOfferTermsData> {}

export function useAddPreselectedOfferTerms() {
  const { isLoading, post } = useApiPost<
    ICreateResult<IUpsertPreselectedOfferTermsCommandValidationErrors>,
    IPreselectedOfferTermsData
  >(`/api/companies/preselection/offerterms/`);

  return {
    isLoading,
    addPreselectedOfferTerms: post,
  };
}

export function useGetPreselectionFileContent() {
  const { isLoading, response, get } = useApiGet<IPreselectionContent>('/api/companies/preselection/files/', false);

  return {
    isLoading,
    response,
    getPreselectionFileContent: get,
  };
}

export interface INewEmailContent {
  footerText: string;
}

export interface IPreselectedEmailContentDto {
  latestEmailContentData: INewEmailContent;
}

export function useGetPreselectedEmailContent() {
  const { isLoading, response, get } = useApiGet<IPreselectedEmailContentDto>(
    '/api/companies/preselection/emailcontent',
    undefined,
  );

  return {
    isLoading,
    response,
    getPreselectedEmailContent: get,
  };
}

export interface IUpsertPreselectedEmailContentCommandValidationErrors
  extends ValidationErrorResultType<INewEmailContent> {}

export function useAddPreselectedEmailContent() {
  const { isLoading, post } = useApiPost<
    ICreateResult<IUpsertPreselectedEmailContentCommandValidationErrors>,
    INewEmailContent
  >(`/api/companies/preselection/emailcontent/`);

  return {
    isLoading,
    addPreselectedEmailContent: post,
  };
}


export interface IPreselectedOfferOptionalData {
  services: string;
  installations: string;
}

export interface IPreselectedOfferOptionalDto {
  latestOfferOptionalData: IPreselectedOfferOptionalData;
  OfferOptionalFileInformation: IPreselectionFileInformationDto[];
}

export function useGetPreselectedOfferOptional() {
  const { isLoading, response, get } = useApiGet<IPreselectedOfferOptionalDto>(
    '/api/companies/preselection/OfferOptional',
    undefined,
  );

  return {
    isLoading,
    response,
    getPreselectedOfferOptional: get,
  };
}

export function useGetLatestPreselectedOfferOptional() {
  const { isLoading, response, get } = useApiGet<IPreselectedOfferOptionalData>(
    '/api/companies/preselection/OfferOptional/latest',
    undefined,
  );

  return {
    isLoading,
    response,
    getLatestOfferOptional: get,
  };
}

export interface IUpsertPreselectedOfferOptionalCommandValidationErrors
  extends ValidationErrorResultType<IPreselectedOfferOptionalData> {}

export function useAddPreselectedOfferOptional() {
  const { isLoading, post } = useApiPost<
    ICreateResult<IUpsertPreselectedOfferOptionalCommandValidationErrors>,
    IPreselectedOfferOptionalData
  >(`/api/companies/preselection/OfferOptional/`);

  return {
    isLoading,
    addPreselectedOfferOptional: post,
  };
}


export interface IPreselectedDeliveryAndWarrantyTerms {
  id?: number;
  termsAndConditions?: string;
}
export interface IPreselectedDeliveryAndWarrantyTermsDto extends IPreselectedDeliveryAndWarrantyTerms {
  termsAndConditionsFileNames: IPreselectionFileInformationDto[];
}

export function useGetPreselectedDeliveryAndWarrantyTerms() {
  const { isLoading, response, get } = useApiGet<IPreselectedDeliveryAndWarrantyTermsDto>(
    '/api/companies/preselection/deliveryandwarrantyterms',
    false,
  );

  return {
    isLoading,
    preselectedTerms: response,
    getPreselectedDeliveryAndWarrantyTerms: get,
  };
}

export interface IUpsertPreselectedDeliveryAndWarrantyTermsCommandValidationErrors
  extends ValidationErrorResultType<IPreselectedDeliveryAndWarrantyTerms> {}

export function useUpsertPreselectedDeliveryAndWarrantyTerms() {
  const { isLoading: isCreateLoading, post } = useApiPost<
    ICreateResult<IUpsertPreselectedDeliveryAndWarrantyTermsCommandValidationErrors>,
    IPreselectedDeliveryAndWarrantyTerms
  >(`/api/companies/preselection/deliveryandwarrantyterms/`);

  const { isLoading: isUpdateLoading, put } = useApiPut<
    IUpsertPreselectedDeliveryAndWarrantyTermsCommandValidationErrors,
    IPreselectedDeliveryAndWarrantyTerms
  >(`/api/companies/preselection/deliveryandwarrantyterms/`);

  const upsert = useCallback(
    async (command: IPreselectedDeliveryAndWarrantyTerms, id?: string) => {
      if (id && id !== '0') {
        const result = await put(command, id);
        return result ? result : id;
      }

      return post(command);
    },
    [put, post],
  );

  return {
    isLoading: isCreateLoading || isUpdateLoading,
    upsert: upsert,
  };
}
