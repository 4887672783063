import React from 'react';
import { useStyles } from '../DataList.styles';

const Dd: React.FC = (props) => {
  const classes = useStyles();

  return <dd className={`${classes.item} ${classes.dd}`}>{props.children}</dd>;
};

export default Dd;
