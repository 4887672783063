import React, { useEffect } from 'react';
import AppRoutes from './App.routes';
import { Sidebar } from '../Shared/Navigation/Sidebar/Sidebar';
import { HeaderNavigationContextProvider } from '../Shared/Navigation/Header/HeaderNavigationContext/HeaderNavigationContext';
import { Header } from '../Shared/Navigation/Header/Header';
import Breadcrumbs from '../Shared/Navigation/Breadcrumbs/Breadcrumbs';
import ImpersonateBar from '../Shared/Navigation/ImpersonateBar/ImpersonateBar';
import { UserDropdownMenu } from '../Shared/Navigation/UserDropdownMenu/UserDropdownMenu';
import useUserContext from '../Core/Authentication/UserContext';
import { BreadcrumbNavigationContextProvider } from '../Shared/Navigation/Breadcrumbs/HeaderNavigationContext/BreadcrumbNavigationContext';
import { UserType } from './Modules/Admin/Components/Users/Users.api';
import { useStyles } from './App.styles';
import useAppContext from '../Core/AppContext/AppContext';
import { useHistory } from 'react-router-dom';
import MultiTypeLogin from './MultiTypeLogin/MultiTypeLogin';

function App() {
  const classes = useStyles();
  const history = useHistory();
  const { isLoading } = useAppContext();
  const { logout, user, cancelImpersonation } = useUserContext();

  interface IUser {
    white?: boolean;
    alwaysMobile?: boolean;
  }

  const User = (props: IUser) => (
    <UserDropdownMenu user={user} white={props.white} alwaysMobile={props.alwaysMobile} logout={logout} />
  );

  useEffect(() => {
    if (user.userType === UserType.SuperAdmin && !user.impersonatedCompany) {
      history.push('/company');
    }
  }, [user, history]);
  const isActivityWindow = !history.location.pathname.includes('activitywindow');
  return (
    <div className={isLoading ? classes.isLoading : classes.container}>
      <>
        {user.userType && isActivityWindow && (
          <Sidebar isImpersonated={user.impersonatedCompany?.name} userDropDownMenu={<User white alwaysMobile />} />
        )}
        <main className={user.userType ? classes.content : classes.multiUserContent}>
          {user && user.userType === UserType.SuperAdmin && user.impersonatedCompany && (
            <ImpersonateBar
              user={user.impersonatedCompany?.companyUser.fullName ?? 'company'}
              company={user.impersonatedCompany?.name ?? 'logo'}
              leave={cancelImpersonation}
            />
          )}
          <HeaderNavigationContextProvider>
            <BreadcrumbNavigationContextProvider>
              <>
                <Header
                  userType={user.userType}
                  userDropDownMenu={<User />}
                  companyName={user.impersonatedCompany ? user.impersonatedCompany?.name : user.companyName}
                  displayAdditionalImage={!!user.companyAdditionalImageBase64}
                  bannerText={user.bannerText ? user.bannerText : undefined}
                />
                {!user.userType ? (
                  <>
                    <MultiTypeLogin />
                  </>
                ) : (
                  <>
                    <Breadcrumbs />
                    <AppRoutes />
                  </>
                )}
              </>
            </BreadcrumbNavigationContextProvider>
          </HeaderNavigationContextProvider>
        </main>
      </>
    </div>
  );
}

export default App;
