import { makeStyles } from '@material-ui/core/styles';

export const skeletonLabel = {
  height: `${19 / 16}rem`,
  width: '100%',
  marginBottom: '0.25rem',
};
export const skeletonInput = {
  height: `${25 / 16}rem`,
  width: '100%',
};

export const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '0.5rem',
  },
  skeletonLabel: {
    ...skeletonLabel,
  },
  skeletonInput: {
    ...skeletonInput,
  },
  skeletonHelperText: {
    height: `${20 / 16}rem`,
    marginTop: '0.5rem',
    marginBottom: '0.75rem',
  },
}));
