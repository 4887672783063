import React, { useEffect, useState } from 'react';
import { TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { Table } from '../../../../../../../Shared/Table/Table';
import { useParams } from 'react-router-dom';
import { useGetUserProductionStats } from '../ProductionStats.api';
import moment from 'moment';
import formatDate from '../../../../../../../Shared/Formatting/formatDate';
import formatPrice from '../../../../../../../Shared/Formatting/formatPrice';
import DatePicker from '../../../../../../../Shared/InputFields/DatePicker/Datepicker';

const defaultFromDate = moment().startOf('month').toDate();

const ProductionUserDetails = () => {
  var { userId } = useParams<{ userId: string }>();
  const { isLoading, productionStats, getProductionStats } = useGetUserProductionStats(userId);
  const [fromDate, setFromDate] = useState(defaultFromDate);

  useEffect(() => {
    getProductionStats({ fromDate, toDate: moment(fromDate).endOf('month').startOf('day').toDate() });
  }, [getProductionStats, fromDate]);

  return (
    <Table
      filters={
        <DatePicker
          label="År/månad"
          required
          selected={fromDate}
          onChange={(d: Date | null) => setFromDate(d as Date)}
          dateFormat="MM/yyyy"
          showMonthYearPicker
        />
      }
      loading={{ loading: isLoading }}
      label={productionStats?.name}
    >
      <TableHead>
        <TableRow>
          <TableCell>Företag</TableCell>
          <TableCell>Datum</TableCell>
          <TableCell>Timmar</TableCell>
          <TableCell>Antal resor</TableCell>
          <TableCell>Parkering</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {productionStats?.entries.map((x) => (
          <TableRow key={x.id}>
            <TableCell>{x.company}</TableCell>
            <TableCell>{formatDate(x.date)}</TableCell>
            <TableCell>{x.hours}</TableCell>
            <TableCell>{x.trips}</TableCell>
            <TableCell>{formatPrice(x.parking)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ProductionUserDetails;
