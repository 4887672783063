import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@material-ui/core';
import AccessGuard from '../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { AccessRights, ModuleIdentifiers } from '../../../../../Core/Authentication/ModuleAccess';
import Container from '../../../../../Shared/Container/Container';
import { Fieldset } from '../../../../../Shared/Form/Fieldset/Fieldset';
import formatDate from '../../../../../Shared/Formatting/formatDate';
import { InputField } from '../../../../../Shared/InputFields/InputField/InputField';
import HtmlView from '../../../../../Shared/RichTextEditor/HtmlView';
import { useGetBMSNode, useOpenDocument } from '../../BMS.api';

const ViewBMSNode = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { isLoading, node, getNode } = useGetBMSNode();
  const { isLoading: isOpenLoading, openDocument } = useOpenDocument();

  useEffect(() => {
    getNode(id);
  }, [id, getNode]);

  return (
    <Container
      form
      customSize={{ fullsize: true }}
      loading={isLoading}
      label="Ledningssystem"
      actions={
        <AccessGuard module={ModuleIdentifiers.BusinessManagementSystem} accessRights={AccessRights.Read}>
          <AccessGuard module={ModuleIdentifiers.BusinessManagementSystem} accessRights={AccessRights.Write}>
            <Button
              color="default"
              variant="outlined"
              onClick={() => history.push(`${history.location.pathname}/update`)}
            >
              Redigera
            </Button>
          </AccessGuard>
          <Button color="default" variant="outlined" disabled={isOpenLoading} onClick={() => openDocument(id)}>
            Visa PDF
          </Button>
        </AccessGuard>
      }
    >
      <Fieldset legend={`${node?.sectionNumber} ${node?.name}`}>
        <InputField label="Skapad" type="text" value={formatDate(node?.created)} viewOnly />
        <InputField label="Ändrad" type="text" value={formatDate(node?.lastModified)} viewOnly />
        <InputField label="Avsnitt" type="text" value={node?.parentNodeName} viewOnly />
        <InputField label="Utfärdad av" type="text" value={node?.issuedByCompanyUserName} viewOnly />
        <InputField label="Fastställd av" type="text" value={node?.determinedByCompanyUserName} viewOnly />
        <InputField label="Processägare" type="text" value={node?.processOwnerCompanyUserName} viewOnly />
        <span
          style={{
            marginLeft: '10px',
          }}
        >
          <HtmlView value={node?.content || ''} />
        </span>
      </Fieldset>
    </Container>
  );
};

export default ViewBMSNode;
