import { platform } from 'os';

export function IsMobileHelper(): boolean {
  var x = platform();
  var mobileOs = ['darwin', 'android']; // darwin is apparently ios, source https://nodejs.org/api/os.html#osplatform
  if (mobileOs.includes(x)) {
    return true;
  }

  return false;
}
