import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  loginContainer: {
    marginTop: `5rem`,
    padding: `4rem`,
    background: `#fafafa`,
    border: `1px solid ${theme.palette.grey[200]}`,
  },

}));
