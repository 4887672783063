import React, { useState, useEffect, createContext } from 'react';

interface IProps {
  children: React.ReactNode;
}

export function ConfigurationProvider(props: IProps) {
  const [config, setConfig] = useState<IConfigurationContext | null>(null);

  function getConfigurationFile() {
    fetch('/configuration.json')
      .then((response) => response.json())
      .then((configurationContext: IConfigurationContext) => {
        setConfig(configurationContext);
      });
  }

  useEffect(() => {
    getConfigurationFile();
  }, []);

  if (!config) {
    return null;
  }

  return <ConfigurationContext.Provider value={config}>{props.children}</ConfigurationContext.Provider>;
}

export interface IConfigurationContext {
  apiBaseUrl: string;
  azureAdAuthority: string;
  azureAdClientId: string;
  policy_signin: string;
  policy_invitation: string;
  policy_passwordReset: string;
}

const ConfigurationContext = createContext<IConfigurationContext>({} as IConfigurationContext);

export default ConfigurationContext;
