import { Dialog, DialogContent } from '@material-ui/core';
import Button from '../../../../../../Shared/Button/Button';
import { isResultError } from '../../../../../../Shared/Api/response/ICreateResult';
import useStyles from './SoftDeleteAgreementForm.style';
import DialogForm from '../../../../../../Shared/DialogForm/DialogForm';
import { useSoftDeleteAgreement } from '../../../../Errands/Components/ErrandAgreementList/Agreements.api';
import React from 'react';

export interface ISoftDeleteAgreementFormProps {
  agreementId: string;
  onTerminate?: () => void;
  setOpen: (open: boolean) => void;
  open: boolean;
}

const SoftDeleteAgreementForm = (props: ISoftDeleteAgreementFormProps) => {
  const classes = useStyles();
  const { agreementId, onTerminate, open, setOpen } = props;
  const { isLoading: softDeleteIsLoading, softDeleteAgreement } = useSoftDeleteAgreement();

  async function handleTerminate() {
    const result = await softDeleteAgreement(agreementId);
    if (!isResultError(result)) {
      onTerminate && onTerminate();
      setOpen(false);
    }
  }

  const Action = () => (
    <>
      <Button color="default" variant="outlined" onClick={() => setOpen(false)}>
        Avbryt
      </Button>
      <Button
        variant="contained"
        onClick={handleTerminate}
        className={classes.terminateBtn}
        loading={softDeleteIsLoading}
      >
        Ta bort
      </Button>
    </>
  );

  return (
    <Dialog open={open} onClose={setOpen}>
      <DialogContent>
        <DialogForm actions={<Action />}>
          <h3>Vill du ta bort avtalet?</h3>
        </DialogForm>
      </DialogContent>
    </Dialog>
  );
};

export default SoftDeleteAgreementForm;
