import { useCallback } from 'react';
import { useApiGet } from '../../../Core/Api/Api';

export const holidayCache = 'HOLIDAY_CACHE';

export default interface IHoliday {
  name: string;
  date: Date;
}

export function useGetHoliday() {
  const { isLoading, get } = useApiGet<IHoliday[]>('/api/holiday', false);

  const getCheck = useCallback(async () => {
    const localData = localStorage.getItem(holidayCache);
    if (!localData || localData === 'undefined' || localData === 'null') {
      const result = await get();
      localStorage.setItem(holidayCache, JSON.stringify(result));
      return result;
    }
    return JSON.parse(localData!) as IHoliday[];
  }, [get]);

  return {
    isLoading,
    getCheck,
  };
}
