import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

export interface IAppContext {
  updateAppLoading: (isLoading: boolean) => void;
  isLoading: boolean;
}

export const AppContext = createContext<IAppContext>({} as IAppContext);
const useAppContext = () => useContext(AppContext);

interface IProps {
  children: JSX.Element;
}

interface ILoading {
  id: number;
}

export function AppContextProvider(props: IProps) {
  const [loadingList, setLoadingList] = useState(0);
  const [isAppLoading, setIsAppLoading] = useState<IAppContext | null>(null);

  const updateAppLoading = useCallback((isLoading: boolean) => {
    if (isLoading) {
      setLoadingList((prev) => {
        return prev + 1;
      });
    } else {
      setLoadingList((prev) => {
        return prev - 1;
      });
    }
  }, []);

  useEffect(() => {
    setIsAppLoading(() => ({
      isLoading: loadingList > 0,
      updateAppLoading: updateAppLoading,
    }));
  }, [loadingList, updateAppLoading]);

  return <>{isAppLoading && <AppContext.Provider value={isAppLoading}>{props.children}</AppContext.Provider>}</>;
}

export default useAppContext;
