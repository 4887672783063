import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useGetCompanies, ICompanyListItem } from '../../Companies.api';
import { TableHead, TableRow, TableCell, TableBody, MenuItem, ListItemIcon, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import TablePaginationWithDatasource from '../../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import { Table } from '../../../../../Shared/Table/Table';
import { DropdownCell } from '../../../../../Shared/Table/DropdownCell/DropdownCell';
import SortButton from '../../../../../Shared/Table/SortButton/SortButton';
import useTable from '../../../../../Shared/Table/useTable';
import SearchInput from '../../../../../Shared/InputFields/SearchInput/SearchInput';
import ImpersonateIcon from '@material-ui/icons/PeopleAltRounded';
import CompanyUserSelectModal from '../CompanyUserSelectModal/CompanyUserSelectModal';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import IconButton from '../../../../../Shared/IconButton/IconButton';

const CompanyList = () => {
  const [company, setCompany] = useState<ICompanyListItem>();
  const history = useHistory();
  const { isLoading, response: companies, getCompanies } = useGetCompanies();
  const {
    toggleSort,
    sortProperty,
    sortDirection,
    pageSize,
    setPageSize,
    setPageNumber,
    setQuery,
    paginatedRequest,
  } = useTable();
  const [emptyMessage, setEmptyMessage] = useState('');
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    async function getList() {
      const response = await getCompanies(paginatedRequest);

      if (response?.totalCount <= 0) {
        if (paginatedRequest.query) {
          setEmptyMessage('Filtreringen gav inga resultat');
        } else {
          setEmptyMessage('Inga företag tillagda');
        }
      }
    }

    getList();
  }, [paginatedRequest, getCompanies]);

  const close = useCallback(() => {
    setOpenModal(false);
    setCompany(undefined);
  }, [setOpenModal, setCompany]);

  const handleSelectedCompany = (company: ICompanyListItem) => {
    setOpenModal(true);
    setCompany(company);
  };

  return (
    <>
      <Table
        label={`Företag (${companies?.totalCount ?? 0})`}
        minWidth
        filters={<SearchInput type="search" placeholder="Sök" onChange={setQuery} />}
        iconButtons={
          <IconButton
            size="small"
            aria-label="Lägg till företag"
            onClick={() =>{ history.push(`${history.location.pathname}/create`); }}
          >
            <AddIcon />
          </IconButton>       
        }
        pagination={
          companies && (
            <TablePaginationWithDatasource
              datasource={companies}
              onChangePage={setPageNumber}
              onChangeRowsPerPage={setPageSize}
            />
          )
        }
        empty={{
          empty: companies && companies.totalCount <= 0 ? true : false,
          message: emptyMessage,
        }}
        loading={{
          loading: isLoading,
          skeletonRows: pageSize,
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <span className={'sr-only'}>Knappar</span>
            </TableCell>
            <TableCell>
              Namn
              <SortButton
                property="name"
                sortProperty={sortProperty}
                sortDirection={sortDirection}
                onSort={toggleSort}
              />
            </TableCell>
            <TableCell>
              Organisationsnummer
              <SortButton
                property="identificationNumber"
                sortProperty={sortProperty}
                sortDirection={sortDirection}
                onSort={toggleSort}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {companies?.items.map((company) => (
            <TableRow key={company.id}>
              <>
                <DropdownCell>
                  <MenuItem onClick={() => handleSelectedCompany(company)}>
                    <ListItemIcon>{<ImpersonateIcon />}</ListItemIcon>
                    <Typography variant="inherit">Impersonera företag</Typography>
                  </MenuItem>
                  <MenuItem disabled>
                    <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                    <Typography variant="inherit">Ta bort</Typography>
                  </MenuItem>
                </DropdownCell>
                <TableCell>
                  <Link to={`${history.location.pathname}/${company.id}`}>{company.name}</Link>
                </TableCell>
                <TableCell>{company.identificationNumber}</TableCell>
              </>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div>
        {company && (
          <CompanyUserSelectModal company={company} onClose={close} open={openModal} setOpen={setOpenModal} />
        )}
      </div>
    </>
  );
};

export default CompanyList;
