import { fade, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  save: {
    marginLeft: '1rem',
  },
  listItem: {
    marginBottom: '1rem',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: '1rem',
    borderRadius: theme.shape.borderRadius,
  },
  messageContainerPersonal: {
    display: 'flex',
    alignItems: 'flex-start',
    border: `1px solid ${theme.palette.primary.main}`,
    background: fade(theme.palette.primary.main, 0.05),
    padding: '1rem',
    borderRadius: theme.shape.borderRadius,
  },
  message: {
    wordBreak: 'break-word',
    flexGrow: 1,
    cursor: 'pointer',
  },
  dropdown: {
    marginLeft: '1rem',
  },
  dateTime: {
    float: 'left',
    marginTop: '0.5rem',
  },
  author: {
    float: 'right',
    marginTop: '0.5rem',
  },
  list: {
    padding: 0,
    margin: 0,
    listStyleType: 'none',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
