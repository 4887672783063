import { Dialog, DialogContent } from '@material-ui/core';
import React from 'react';
import Button from '../Button/Button';
import { DialogContentHeader } from '../DialogContentHeader/DialogContentHeader';
import DialogForm from '../DialogForm/DialogForm';
import { useStyles } from './WarningModal.styles';

export interface IWarningModal {
  id?: number;
  title: string;
  open: boolean;
  isLoading?: boolean;
  children: React.ReactNode;
  setOpen: (open: boolean) => void;
  action: () => void;
}

const WarningModal = (props: IWarningModal) => {
  const classes = useStyles();
  const { id, title, open, isLoading, children, setOpen, action } = props;

  const Action = () => (
    <>
      <Button color="default" variant="outlined" onClick={() => setOpen(false)}>
        Nej
      </Button>
      <Button loading={isLoading} className={classes.cautionButton} variant="outlined" onClick={action}>
        Ja
      </Button>
    </>
  );

  return (
    <Dialog open={open} onClose={setOpen}>
      <DialogContent>
        <DialogContentHeader onClose={() => setOpen(false)} title={title} titleId={id?.toString() ?? ''} />
        <DialogForm actions={<Action />}>{children}</DialogForm>
      </DialogContent>
    </Dialog>
  );
};

export default WarningModal;
