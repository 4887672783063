import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { TableHead, TableRow, TableCell, TableBody, useMediaQuery, Tooltip } from '@material-ui/core';
import { Table } from '../../../../../../Shared/Table/Table';
import useTable from '../../../../../../Shared/Table/useTable';
import TablePaginationWithDatasource from '../../../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import SortButton from '../../../../../../Shared/Table/SortButton/SortButton';
import { ISelectOption } from '../../../../../../Shared/Select/Select';
import { SaleType, SaleTypeLabel } from '../../../../../Shared/SaleType';
import { MobileTable } from '../../../../../../Shared/Table/MobileTable/MobileTable';
import { MobileTableRow } from '../../../../../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import formatPrice from '../../../../../../Shared/Formatting/formatPrice';
import { SortDirection } from '../../../../../../Shared/Api/request/IPaginatedRequest';
import { SaleStatus, SaleStatusLabel, ISaleListItem } from '../../Sale.api';
import { useGetOrders } from '../Order.api';
import theme from '../../../../../../Styles/Theme';
import useStyles from './TodoOrderList.styles';
import formatDate from '../../../../../../Shared/Formatting/formatDate';
import useUserContext from '../../../../../../Core/Authentication/UserContext';
import { Role } from '../../../../Admin/Components/Users/Users.api';

export interface ISaleTypeOption extends ISelectOption {
  type: SaleType;
}

const TodoOrderList = () => {
  const history = useHistory();
  const customerParams = new URLSearchParams(window.location.search).get('query');
  const { isLoading, orders, getOrders } = useGetOrders();
  const { user } = useUserContext();
  const {
    toggleSort,
    sortProperty,
    sortDirection,
    pageSize,
    setPageSize,
    setPageNumber,
    query,
    paginatedRequest,
  } = useTable(100, '', SortDirection.Ascending, customerParams ?? '');
  const [emptyMessage, setEmptyMessage] = useState('');
  const classes = useStyles();

  const mobileView = useMediaQuery(theme.breakpoints.down(1080 / 16));

  const getList = useCallback(async () => {
    const statuses = user.roles?.some((r) => r === Role.Installer)
      ? [SaleStatus.installationBooked]
      : [SaleStatus.completed, SaleStatus.installationBooked, SaleStatus.installed];
    const response = await getOrders({
      ...paginatedRequest,
      bookedTo: new Date(),
      status: statuses,
      sortProperty: sortProperty,
      onlyMine: true,
    });

    if (response?.totalCount <= 0) {
      setEmptyMessage('Filtreringen gav inga resultat');
    } else {
      setEmptyMessage('Inga order tillagda');
    }
    
  }, [user.roles, getOrders, paginatedRequest, sortProperty]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleTableClick = (order: ISaleListItem) => {
    history.push({
      pathname: history.location.pathname,
      search: query ? `?query=${query}` : '',
    });
    history.push(`${history.location.pathname}/${order.id}`);
  };

  return (
    <>
      <Table
        label={`Ordrar ${orders?.totalCount ?? 0}`}
        minWidth
        customSize={{
          fullsize: true,
        }}
        pagination={
          orders && (
            <TablePaginationWithDatasource
              datasource={orders}
              onChangePage={setPageNumber}
              onChangeRowsPerPage={setPageSize}
            />
          )
        }
        empty={{
          empty: orders && orders.totalCount <= 0 ? true : false,
          message: emptyMessage,
        }}
        loading={{
          loading: isLoading,
          skeletonRows: pageSize,
        }}
        mobile={mobileView}
      >
        {mobileView ? (
          <>
            {orders?.items.map((order) => (
              <MobileTable
                key={order.id}
                link={`${history.location.pathname}/${order.id}`}
                label={`${order.saleIdentifier} - ${order.customerName}`}
              >
                <MobileTableRow label="Order nr" value={order.saleIdentifier} />
                <MobileTableRow label="Kund" value={order.customerName} />
                <MobileTableRow label="Projektledare" value={order.projectLeader} />
                <MobileTableRow label="Typ" value={SaleTypeLabel[order.type]} />
                <MobileTableRow label="Kommentar" value={order.description} />
                <MobileTableRow label="Status" value={SaleStatusLabel[order.status]} />
                <MobileTableRow label="Summa" value={formatPrice(order.totalPrice)} />
              </MobileTable>
            ))}
          </>
        ) : (
          <>
            <TableHead>
              <TableRow>
                <TableCell>
                  Order nr
                  <SortButton
                    property="saleIdentifier"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>
                <TableCell>
                  Kund
                  <SortButton
                    property="customerName"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>
                <TableCell>
                  Typ
                  <SortButton
                    property="type"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>
                <TableCell>Rubrik</TableCell>
                <TableCell>Beskrivning</TableCell>
                <TableCell>
                  Status
                  <SortButton
                    property="status"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>
                <TableCell>
                  Bokningsdatum
                  <SortButton
                    property="bookingDate"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>
                <TableCell>Bokad på</TableCell>
                <TableCell>
                  Summa
                  <SortButton
                    property="totalPrice"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders?.items.map((order: ISaleListItem) => (
                <TableRow
                  className={`${classes.pointerCursor} ${
                    order.status === SaleStatus.installed ? classes.installedRow : ''
                  }`}
                  hover
                  tabIndex={-1}
                  key={order.id}
                >
                  <TableCell onClick={() => handleTableClick(order)} className={classes.font}>
                    {order.saleIdentifier}
                  </TableCell>
                  <TableCell onClick={() => handleTableClick(order)}> {order.customerName}</TableCell>
                  <TableCell onClick={() => handleTableClick(order)}>{SaleTypeLabel[order.type]}</TableCell>
                  <TableCell onClick={() => handleTableClick(order)}>{order.description}</TableCell>
                  <Tooltip title={order.jobDescription ?? ''}>
                    <TableCell onClick={() => handleTableClick(order)}>
                      {order.jobDescription?.substring(0, 15) +
                        (order.jobDescription && order.jobDescription?.length > 14 ? '..' : '')}
                    </TableCell>
                  </Tooltip>
                  <TableCell onClick={() => handleTableClick(order)}>{SaleStatusLabel[order.status]}</TableCell>
                  <TableCell onClick={() => handleTableClick(order)}>{formatDate(order.bookingDate)}</TableCell>
                  <TableCell onClick={() => handleTableClick(order)}>{order.booked ?? '-'}</TableCell>
                  <TableCell onClick={() => handleTableClick(order)}>{formatPrice(order.totalPrice)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </>
        )}
      </Table>
    </>
  );
};

export default TodoOrderList;
