import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  useUpsertTodo,
  IUpsertTodoCommand,
  IUpsertTodoValidationErrors,
  useGetTodo,
  useAnswerTodo,
  IAnswerTodoValidationErrors,
  useArchiveTodo,
} from '../../Todos.api';
import useInputState from '../../../../../Shared/Hooks/UseInputState/UseInputState';
import { isResultError } from '../../../../../Shared/Api/response/ICreateResult';
import Button from '../../../../../Shared/Button/Button';
import Container from '../../../../../Shared/Container/Container';
import { InputField } from '../../../../../Shared/InputFields/InputField/InputField';
import Datepicker from '../../../../../Shared/InputFields/DatePicker/Datepicker';
import useFormValidation from '../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import { IGetCompanyUserMessagesResult, useDeleteCompanyMessage } from '../../../Forum/Forum.api';
import moment from 'moment';
import DialogForm from '../../../../../Shared/DialogForm/DialogForm';
import CompanyUserSelect from '../../../Admin/Components/Users/UserSelect/CompanyUserSelect';
import CheckboxField from '../../../../../Shared/InputFields/CheckboxField/CheckboxField';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import useUserContext from '../../../../../Core/Authentication/UserContext';
import Snackbar from '../../../../../Shared/Hooks/UseErrorMessage/Snackbar/Snackbar';
import { formatDateWithTime } from '../../../../../Shared/Formatting/formatDate';
import { Fieldset } from '../../../../../Shared/Form/Fieldset/Fieldset';
export interface ITodoFormProps {
  onAction?: () => void;
  isModal?: boolean;
  isArchive?: boolean;
  id?: string;
}

const TodoForm = (props: ITodoFormProps) => {
  const history = useHistory();
  const { user } = useUserContext();
  const location = useLocation<IGetCompanyUserMessagesResult | undefined>();
  const { id } = useParams<{ id: string }>();
  const [todoId] = useState<string>(id ? id : props.id ? props.id : '');
  const { isFormValid, formRef } = useFormValidation();

  const { isLoading, todo, getTodo } = useGetTodo(todoId);
  const { isLoading: isUpsertLoading, upsert } = useUpsertTodo();
  const { isLoading: isAnswerLoading, answerTodo } = useAnswerTodo();
  const { isLoading: isArchiveLoading, archiveTodo } = useArchiveTodo();

  const [description, changeDescription, setDescription] = useInputState('');
  const [deadline, setDeadline] = useState(new Date());
  const [notificationDateTime, setNotificationDateTime] = useState<Date | null>(new Date());
  const [companyUserId, setCompanyUserId] = useState<number | undefined>();
  const [assignedPersonCompanyUserId, setAssignedUserCompanyUserId] = useState<number | null>(null);
  const [requiredAnswer, setRequiredAnswer] = useState(false);
  const [notificationActivated, setNotificationActivated] = useState(true);
  const [answer, changeAnswer, setAnswer] = useInputState('');
  const { deleteCompanyMessage: deleteCompanyUserMessage } = useDeleteCompanyMessage();

  const [openConfirmText, setOpenConfirmText] = useState(false);
  const [errors, setErrors] = useState<IUpsertTodoValidationErrors>();
  const [answerErrors, setAnswerErrors] = useState<IAnswerTodoValidationErrors>();

  function goBackToList() {
    if (props.onAction) {
      props.onAction();
    } else history.push('/todos/list');
  }

  useEffect(() => {
    async function loadTodo() {
      const response = await getTodo();
      if (!response) {
        // history.push('/todos/list');
      } else {
        if (response.notificationDateTime === null) {
          setNotificationActivated(false);
        } else {
          setNotificationActivated(true);
        }
        setCompanyUserId(response?.createdByCompanyUserId);
        setAssignedUserCompanyUserId(response?.assignedPersonCompanyUserId);
        setDescription(response?.description);
        setAnswer(response?.answer);
        setDeadline(moment(response?.deadline).toDate());
        setRequiredAnswer(response?.requiredAnswer);
        if (response?.notificationDateTime) {
          setNotificationDateTime(moment(response?.notificationDateTime).toDate());
        }
      }
    }

    if (todoId) {
      loadTodo();
    }

    if (location.state?.description) {
      setDescription(location.state.description);
    }
  }, [history, location.state, getTodo, setDescription, setAnswer, todoId]);

  async function handleUpsert() {
    const upsertCommand: IUpsertTodoCommand = {
      description: description,
      deadline: deadline,
      assignedPersonCompanyUserId: assignedPersonCompanyUserId,
      requiredAnswer: requiredAnswer,
      notificationDateTime: notificationActivated ? notificationDateTime : null,
    };

    const result = await upsert(upsertCommand, todoId);
    if (isResultError(result)) {
      setErrors(result);
    } else {
      if (location.state?.description && location.state?.id) {
        await deleteCompanyUserMessage(location.state.id.toString());
        location.state = undefined;
      }
      goBackToList();
    }
  }

  const handleAnswerTodo = async () => {
    if (todoId) {
      const result = await answerTodo(todoId, { answer: answer });
      if (isResultError(result)) {
        setAnswerErrors(result);
      } else {
        setOpenConfirmText(true);
        history.goBack();
      }
    }
  };

  const handleArchiveTodo = async () => {
    if (todoId) {
      todo && todo.answer !== answer && (await handleAnswerTodo());
      const result = await archiveTodo(todoId);

      if (isResultError(result)) {
        setAnswerErrors(result as IAnswerTodoValidationErrors);
      } else {
        setOpenConfirmText(true);
        history.push('/todos/archivelist');
      }
    }
  };

  const handleSetAssignedPerson = (id: string | undefined) => {
    if (id) {
      setAssignedUserCompanyUserId(Number(id));
      setRequiredAnswer(true);
    } else {
      setAssignedUserCompanyUserId(null);
      setRequiredAnswer(false);
    }
  };

  const handleSetRequiredAnswer = () => {
    if (assignedPersonCompanyUserId && (user.selectedUserId === companyUserId || !companyUserId))
      setRequiredAnswer(!requiredAnswer);
  };

  const handleSetNotification = () => {
    setNotificationActivated(!notificationActivated);
    if (!notificationActivated) {
      setNotificationDateTime(null);
    }
  };

  function Actions() {
    return (
      <>
        <Button color="default" variant="outlined" onClick={() => goBackToList()}>
          Avbryt
        </Button>
        {todoId && (assignedPersonCompanyUserId === user.selectedUserId || companyUserId === user.selectedUserId) && (
          <Button
            color="primary"
            variant="outlined"
            disabled={requiredAnswer && (!answer || answer?.length < 1)}
            loading={isArchiveLoading}
            onClick={handleArchiveTodo}
          >
            {'Arkivera'}
          </Button>
        )}
        {todoId && requiredAnswer && assignedPersonCompanyUserId === user.selectedUserId ? (
          <Button
            color="primary"
            variant="contained"
            disabled={!answer || answer?.length < 1}
            loading={isAnswerLoading}
            onClick={handleAnswerTodo}
          >
            {'Svara'}
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            disabled={!isFormValid && (!companyUserId || companyUserId !== user.selectedUserId)}
            loading={isUpsertLoading}
            onClick={handleUpsert}
          >
            {todoId ? 'Spara' : 'Skapa'}
          </Button>
        )}
      </>
    );
  }

  return (
    <>
      {props.isModal ? (
        <DialogForm actions={<Actions />} ref={formRef}>
          <Datepicker
            label="Ska vara klart"
            required
            fullwidth
            onChange={(d: Date | null) => setDeadline(d as Date)}
            selected={deadline}
            errorText={errors?.deadline}
          />
          <InputField
            label="Beskrivning"
            rows={5}
            fullwidth
            required
            type="text"
            multiline
            value={description}
            onChange={changeDescription}
            errorText={errors?.description}
          />
          <CompanyUserSelect
            label="Assignera till"
            selectedCompanyUserId={assignedPersonCompanyUserId?.toString()}
            isClearable
            onChange={(id) => handleSetAssignedPerson(id)}
            omitSelf
          />
          <Fieldset>
            <CheckboxField label="Kräv svar" customWidth={6}>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                checked={requiredAnswer}
                label=""
                disabled={!assignedPersonCompanyUserId}
                onClick={handleSetRequiredAnswer}
              />
            </CheckboxField>
            <CheckboxField label={`Notifikation`} customWidth={6}>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                checked={notificationActivated}
                label=""
                onClick={handleSetNotification}
              />
            </CheckboxField>
          </Fieldset>
          {notificationActivated && (
            <Datepicker
              label="Påminnelse"
              fullwidth
              onChange={(d: Date | null) => {
                setNotificationDateTime(d as Date);
              }}
              selected={notificationDateTime}
              errorText={errors?.notificationDateTime}
            />
          )}
        </DialogForm>
      ) : (
        <Container
          form
          ref={formRef}
          label={props.isArchive ? 'Mitt arkiv' : todoId ? 'Redigera uppgift  - #todo-' + todo?.id : 'Skapa uppgift'}
          actions={props.isArchive ? undefined : <Actions />}
          loading={isLoading}
        >
          <Datepicker
            label="Ska vara klart"
            required
            fullwidth
            onChange={(d: Date | null) => setDeadline(d as Date)}
            selected={deadline}
            errorText={errors?.deadline}
            disabled={(todoId && companyUserId !== user.selectedUserId) || props.isArchive}
          />
          <InputField label="Skapad av" fullwidth type="text" value={todo?.createdByCompanyUserFullname} viewOnly />
          <InputField
            label="Beskrivning"
            rows={5}
            fullwidth
            required
            type="text"
            multiline
            value={description}
            onChange={changeDescription}
            errorText={errors?.description}
            disabled={(todoId && companyUserId !== user.selectedUserId) || props.isArchive}
          />
          {!(props.isArchive && !assignedPersonCompanyUserId) && (
            <>
              <CompanyUserSelect
                label="Assignera till"
                selectedCompanyUserId={assignedPersonCompanyUserId?.toString()}
                isClearable
                onChange={(id) => handleSetAssignedPerson(id)}
                isDisabled={(todoId && companyUserId !== user.selectedUserId) || props.isArchive}
                omitSelf
              />
              <CheckboxField
                label={`Kräv svar ${todo?.sendDate ? '(' + formatDateWithTime(todo?.sendDate) + ')' : ''}`}
              >
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  checked={requiredAnswer}
                  label=""
                  disabled={
                    (todoId && companyUserId !== user.selectedUserId) || !assignedPersonCompanyUserId || props.isArchive
                  }
                  onClick={handleSetRequiredAnswer}
                />
              </CheckboxField>
              {todoId && requiredAnswer && (
                <InputField
                  label={`Svar ${todo?.answerDate ? '(' + formatDateWithTime(todo?.answerDate) + ')' : ''}`}
                  rows={5}
                  fullwidth
                  required={assignedPersonCompanyUserId === user.selectedUserId}
                  type="text"
                  multiline
                  value={answer}
                  onChange={changeAnswer}
                  errorText={answerErrors?.answer}
                  disabled={assignedPersonCompanyUserId !== user.selectedUserId || props.isArchive}
                />
              )}
              <CheckboxField label={`Notifikation`}>
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  checked={notificationActivated}
                  label=""
                  onClick={handleSetNotification}
                />
              </CheckboxField>
              {notificationActivated && (
                <Datepicker
                  label="Påminnelse"
                  fullwidth
                  onChange={(d: Date | null) => {
                    setNotificationDateTime(d as Date);
                  }}
                  selected={notificationDateTime}
                  errorText={errors?.notificationDateTime}
                />
              )}
            </>
          )}
        </Container>
      )}
      <Snackbar
        message={'Uppgift besvarad.'}
        open={openConfirmText}
        severity={'success'}
        onClose={() => setOpenConfirmText(false)}
      />
    </>
  );
};

export default TodoForm;
