import { makeStyles, Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';
import { selectBorderRadius } from '../../../Styles/Theme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      height: 0,
      width: 0,
      opacity: 0,
      margin: 0,
      padding: 0,
    },
    label: {
      marginBottom: '0.25rem',
    },
    container: {
      border: `1px solid ${theme.palette.grey[300]}`,
      height: `calc(${90 / 16}rem - 1px)`,
      borderRadius: selectBorderRadius,
      display: 'flex',
      alignItems: 'center',
    },
    focused: {
      borderColor: theme.palette.primary.main,
    },
    fileName: {
      flexGrow: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    editableFileName: {
      width: '100%',
      '& div': {
        width: '100%',
        maxWidth: '100%'
      },
      '& input': {
        marginLeft: '0.25rem'
      }
    },
    icon: {
      margin: '0.5rem',
    },
    button: {
      marginRight: '0.25rem',
    },
    removeFile: {
      color: theme.palette.error.main,
    },
  }),
);
