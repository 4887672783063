import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ArrowDropdown from '@material-ui/icons/ArrowDropDownRounded';
import { INavigationItem } from '../Header';
import { useHistory, useLocation } from 'react-router-dom';
import { useStyles } from './MobileHeader.styles';

interface IMobileHeaderProps {
  links?: INavigationItem[];
}

export const MobileHeader = (props: IMobileHeaderProps) => {
  const [activeLink, setActiveLink] = useState('');
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const history = useHistory();
  const location = useLocation();
  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef?.current && anchorRef?.current.contains(event?.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    const link = props.links?.find((x) => x.to === location.pathname)?.title;
    if (link) {
      setActiveLink(link);
    }
  }, [location.pathname, props.links]);

  const linkTo = (navigationItem: INavigationItem) => {
    history.push(navigationItem.to);
    setActiveLink(navigationItem.title);
    setOpen(false);
  };

  return (
    <>
      {props.links && props.links?.length > 2 && (
        <div className={classes.selectContainer}>
          <Button ref={anchorRef} aria-haspopup="true" onClick={handleToggle} className={classes.selectMenu}>
            <Typography>{activeLink}</Typography>
            <ArrowDropdown aria-hidden="true" />
          </Button>

          <Popper open={open} anchorEl={anchorRef.current} role="nav" disablePortal transition>
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList className={classes.menuList} autoFocusItem={open} onKeyDown={handleListKeyDown}>
                      {props.links?.map((link: INavigationItem) => (
                        <MenuItem button={true} onClick={() => linkTo(link)}>
                          {link.title}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      )}
    </>
  );
};
