import { useCallback } from 'react';
import { useApiDelete, useApiGet, useApiPost, useApiPut } from '../../../Core/Api/Api';
import ISearchRequest from '../../../Shared/Api/request/ISearchRequest';
import { ICreateResult } from '../../../Shared/Api/response/ICreateResult';
import { IFileResult, openFile } from '../../../Shared/Api/response/IFileResult';
import ValidationErrorResultType from '../../../Shared/Api/response/ValidationErrorResultType';
import { IUploadFile } from '../../../Shared/InputFields/UploadInput/UploadInput';

export interface IFileNode {
  id: number;
  parentNodeId: number | null;
  nodeType: 'folder' | 'pdf' | 'image';
  hasChildren: boolean;
  name: string;
  treeLevel: number;
  fileInformationId: number;
}

export type IFileFormType = 'uploadFile' | 'createFolder' | 'editNode';

export interface IGetFileNodeListRequest {
  openNodes?: number[];
  selectedNode?: number;
}

export function useGetFileSystemNodes() {
  const { isLoading, response, post } = useApiPost<IFileNode[], IGetFileNodeListRequest>('/api/filesystem/list');

  return {
    isLoading,
    nodes: response,
    getNodes: post,
  };
}

export interface IFolderSearchRequest extends ISearchRequest {
  selectedNode?: number;
}

export function useSearchFileSystemFolders() {
  const { isLoading, response, post } = useApiPost<IFileNode[], IFolderSearchRequest>('/api/filesystem/search');

  return {
    isLoading,
    nodes: response,
    searchFolders: post,
  };
}

export function useDownloadFile() {
  const { isLoading, get } = useApiGet<IFileResult>('/api/filesystem/download/', false);
  const downloadFile = useCallback(
    (nodeId: number) => {
      async function loadFile() {
        let result = await get(nodeId.toString());
        openFile(result, false);
      }
      loadFile();
    },
    [get],
  );
  return { isLoading, downloadFile };
}

export function useGetFile() {
  const { isLoading, get } = useApiGet<IFileResult>('/api/filesystem/download/', false);
  const getFile = async function (nodeId: number) {
    let result = await get(nodeId.toString());
    return result;
  };
  return { isLoading, getFile };
}

export interface IUploadFileCommand {
  parentNodeId: number;
  file: IUploadFile;
}

export interface IUploadFileCommandValidationErrors extends ValidationErrorResultType<IUploadFileCommand> {}
export function useUploadFile() {
  const { isLoading, post } = useApiPost<IUploadFileCommandValidationErrors, IUploadFileCommand>(
    '/api/filesystem/upload',
  );

  return {
    isLoading,
    uploadFile: post,
  };
}

export interface IUpsertFileNodeCommand {
  parentNodeId?: number;
  nodeName: string;
}

export interface IUpsertFileNodeCommandValidationErrors extends ValidationErrorResultType<IUpsertFileNodeCommand> {}
export function useUpsertFileSystemNode() {
  const { isLoading: isCreateLoading, post } = useApiPost<
    ICreateResult<IUpsertFileNodeCommandValidationErrors>,
    IUpsertFileNodeCommand
  >('/api/filesystem');

  const { isLoading: isUpdateLoading, put } = useApiPut<IUpsertFileNodeCommandValidationErrors, IUpsertFileNodeCommand>(
    '/api/filesystem/',
  );

  const upsert = useCallback(
    async (command: IUpsertFileNodeCommand, id?: string) => {
      if (id) {
        const result = await put(command, id);
        return result ? result : id;
      }

      return post(command);
    },
    [put, post],
  );

  return {
    isLoading: isCreateLoading || isUpdateLoading,
    upsertNode: upsert,
  };
}

export function useDeleteFileSystemNode() {
  const { isLoading, delete: deleteRequest } = useApiDelete('/api/filesystem/');

  const deleteNode = useCallback(
    async (id: string) => {
      return await deleteRequest(undefined, id);
    },
    [deleteRequest],
  );

  return {
    isLoading,
    deleteNode,
  };
}
