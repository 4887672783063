import React from 'react';
import { useParams } from 'react-router-dom';
import MeetingNotesList from './MeetingNotesList/MeetingNotesList';
import MeetingNotesView from './MeetingNotesView/MeetingNotesView';

const MeetingsArchive = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <MeetingNotesList label="Mötesarkiv" mode="archive" />
      {id && <MeetingNotesView noEdit />}
    </>
  );
};

export default MeetingsArchive;
