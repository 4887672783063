import { makeStyles, Theme } from '@material-ui/core';
import { skeletonInput, skeletonLabel } from '../InputField/InputFieldLoading/InputFieldLoading.styles';

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    color: theme.palette.grey[700],
    marginBottom: '0.25rem',
  },
  skeletonLabel: {
    ...skeletonLabel,
  },
  skeletonInput: {
    ...skeletonInput,
  },
}));

export default useStyles;
