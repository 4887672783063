import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Datepicker from '../../../../../../../Shared/InputFields/DatePicker/Datepicker';
import Container from '../../../../../../../Shared/Container/Container';
import { InputField } from '../../../../../../../Shared/InputFields/InputField/InputField';
import Button from '../../../../../../../Shared/Button/Button';
import useFormValidation from '../../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import { isResultError } from '../../../../../../../Shared/Api/response/ICreateResult';
import { IUpdateCostCommand, IUpdateCostCommandValidationErrors, useUpdateSalesCost } from '../../Order.api';
import useInputState from '../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { SupplierSelect } from '../../../../../Admin/Components/Suppliers/SupplierSelect/SupplierSelect';
import { useGetSaleCost, useGetSaleMisc } from '../../../Sale.api';

const UpdateCostForm = () => {
  const history = useHistory();
  const { id, costId } = useParams<{ id: string; costId: string; }>();
  const { isLoading, getSaleMisc: getSaleOther } = useGetSaleMisc();
  const { getSaleCost } = useGetSaleCost();
  const { isFormValid, formRef } = useFormValidation();
  const { isUpdateLoading, update } = useUpdateSalesCost(id, costId);
  const [date, setDate] = useState<Date | null>(new Date());
  const [costIn, changeCostIn , setCostIn] = useInputState<number>(0);
  const [costOut, changeCostOut , setCostOut] = useInputState<number>(0);
  const [description, changeDescription , setDescription] = useInputState<string>('');
  const [selectedSupplierId, setSelectedSupplierId] = useState<string>();
  const [errors, setErrors] = useState<IUpdateCostCommandValidationErrors>();

  useEffect(() => {
    const loadOrder = async () => {
      await getSaleOther(id);
      const saleCost = await getSaleCost(id, costId);
      if (saleCost) {
        setDate(new Date(saleCost.date));
        setCostIn(saleCost.costIn);
        setCostOut(saleCost.costOut);
        setDescription(saleCost.description);
        setSelectedSupplierId(saleCost.supplierId.toString());
      }
    };

    loadOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSaleOther, id]);

  async function updateCost() {
    const command: IUpdateCostCommand = {
      date: date as Date,
      costIn: costIn,
      costOut: costOut,
      description: description,
      supplierId: selectedSupplierId ?? '',
    };

    const result = await update(command);

    isResultError(result) ? setErrors(result) : history.push(`/errands/invoice/${id}`);
  }

  const valid = isFormValid && selectedSupplierId;

  return (
    <Container
      form
      ref={formRef}
      loading={isLoading}
      actions={
        <>
          <Button
            color="default"
            variant="outlined"
            disabled={isUpdateLoading}
            onClick={() => history.push(`/errands/invoice/${id}`)}
          >
            Avbryt
          </Button>
          <Button
            color="primary"
            variant="contained"
            loading={isUpdateLoading}
            disabled={!valid}
            onClick={updateCost}
          >
            Spara
          </Button>
        </>
      }
      label="Kostnad"
    >
      <Datepicker label="Datum" selected={date} onChange={setDate} required />
      {!isLoading && (
        <SupplierSelect label={'Leverantör'} onChange={setSelectedSupplierId} selectedSupplierId={selectedSupplierId} />
      )}
      <InputField
        label="Summa in"
        type="number"
        required
        value={costIn}
        endAdornment="SEK"
        onChange={changeCostIn}
        errorText={errors?.costIn}
      />
      <InputField
        label="Summa ut"
        type="number"
        required
        value={costOut}
        endAdornment="SEK"
        onChange={changeCostOut}
        errorText={errors?.costOut}
      />
      <InputField
        label="Beskrivning"
        type="text"
        required
        value={description}
        onChange={changeDescription}
        errorText={errors?.description}
      />
    </Container>
  );
};
export default UpdateCostForm;
