import React, { useEffect } from 'react';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import { INavigationItem } from '../../../Shared/Navigation/Header/Header';
import AuditList from './Components/AuditList/AuditList';
import Contractless from './Components/ContractlessList/ContractlessList';
import NoContact from './Components/NoContact/NoContact';
import AuditForm from './Components/AuditForm/AuditForm';
import useHeaderNavigationContext from '../../../Shared/Navigation/Header/HeaderNavigationContext/HeaderNavigationContext';
import AuthRoute from '../../../Core/Authentication/AuthRoute/AuthRoute';
import { ModuleIdentifiers } from '../../../Core/Authentication/ModuleAccess';

function AuditRoute() {
  const { path } = useRouteMatch();
  const { setHeaderNavigationItems, setHeaderNavigationText } = useHeaderNavigationContext();

  useEffect(() => {
    const navigationItems: INavigationItem[] = [
      {
        title: 'Revisioner',
        to: `${path}/list`,
        exact: false,
      },
      {
        title: 'Avtalslösa',
        to: `${path}/contractless`,
        exact: false,
      },
      {
        title: 'Ej kontakt',
        to: `${path}/nocontact`,
        exact: false,
      },
    ];
    setHeaderNavigationText(navigationItems);
    setHeaderNavigationItems(navigationItems);

    return () => {
      setHeaderNavigationItems([]);
    };
  }, [path, setHeaderNavigationText, setHeaderNavigationItems]);

  return (
    <Switch>
      <AuthRoute exact path={`${path}/list`} module={ModuleIdentifiers.Customer}>
        <h1 className={'sr-only'}>Lista över revisioner</h1>
        <AuditList />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/list/:customerId/:id`}
        module={ModuleIdentifiers.Customer}
        breadcrumbs={[{ title: 'Revisioner', to: `${path}/list` }, { title: 'Revision' }]}
      >
        <h1 className={'sr-only'}>Information om revision</h1>
        <AuditForm cancelPath={`${path}/list`} />
      </AuthRoute>

      <AuthRoute exact path={`${path}/contractless`} module={ModuleIdentifiers.Customer}>
        <h1 className={'sr-only'}>Lista över avtalslösa kunder</h1>
        <Contractless />
      </AuthRoute>
      <AuthRoute exact path={`${path}/nocontact`} module={ModuleIdentifiers.Customer}>
        <h1 className={'sr-only'}>Lista över kunder som ej önskar bli kontaktade</h1>
        <NoContact />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/contractless/:customerId/:id`}
        module={ModuleIdentifiers.Customer}
        breadcrumbs={[{ title: 'Avtalslösa', to: `${path}/contractless` }, { title: 'Avtal' }]}
      >
        <h1 className={'sr-only'}>Information om revision</h1>
        <AuditForm cancelPath={`${path}/contractless`} />
      </AuthRoute>

      <AuthRoute
        exact
        path={`${path}/nocontact/:customerId/:id`}
        module={ModuleIdentifiers.Customer}
        breadcrumbs={[{ title: 'Ej kontakt', to: `${path}/nocontact` }, { title: 'Avtal' }]}
      >
        <h1 className={'sr-only'}>Information om revision</h1>
        <AuditForm cancelPath={`${path}/nocontact`} />
      </AuthRoute>

      <Route path={[path, `${path}/*`]}>
        <Redirect to={`${path}/list`} />
      </Route>
    </Switch>
  );
}

export default AuditRoute;
