import React, { useMemo, useState } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import CheckboxField from '../../../../../../../../../../Shared/InputFields/CheckboxField/CheckboxField';

export interface ICheckboxItem<T> {
  type: T;
  name: string;
  //initiallyIncluded?: boolean;
}

export function useCheckboxField<T>(
  label: string,
  items: ICheckboxItem<T>[],
): [JSX.Element, T[], (items: T[]) => void] {
  //const [choices, setChoices] = useState<T[]>(items.filter(x=>x.initiallyIncluded).map(x=>x.type));
  const [choices, setChoices] = useState<T[]>([]);

  const checkboxComp = useMemo(() => {
    const onToggle = (checked: boolean, choice: T) => {
      const onAdd = (newChoice: T) => {
        let newChoices = [...choices, newChoice];
        setChoices(newChoices);
      };
      const onDelete = (choice: T) => {
        let index = choices.indexOf(choice);
        let newChoices = [...choices];
        newChoices.splice(index, 1);
        setChoices(newChoices);
      };
      if (checked) onAdd(choice);
      else onDelete(choice);
    };

    return (
      <Grid item md={12}>
        <CheckboxField label={label} fullwidth>
          {items.map((row, index) => (
            <FormControlLabel
              control={<Checkbox color="primary" />}
              key={index}
              label={row.name}
              checked={choices.includes(row.type)}
              onChange={(_, checked) => onToggle(checked, row.type)}
            />
          ))}
        </CheckboxField>
      </Grid>
    );
  }, [label, items, choices]);

  return [checkboxComp, choices, setChoices];
}
