import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  postCalculationWrapper: {
    '& tr > td:nth-last-of-type(1)': {
      textAlign: 'center',
    },
  },
}));

export default useStyles;