import { useApiPost } from '../../../Core/Api/Api';

export enum EntityType {
  Sale = 1,
  Customer,
  Facility,
  System,
  Supplier,
  SaleCost
}

interface IBreadCrumbRequest {
  entityId: number;
  type: EntityType;
}

interface IBreadCrumbResponse {
  breadCrumbName: string;
}

export function useGetBreadCrumb() {
  const { isLoading, response, post } = useApiPost<IBreadCrumbResponse, IBreadCrumbRequest>('/api/utils/breadcrumb');

  return {
    isLoading,
    name: response,
    getBreadCrumb: post,
  };
}
