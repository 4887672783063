import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    minWidth: `${theme.breakpoints.values.sm}rem`,
    maxWidth: '50rem',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  labelContainer: {
    padding: '0.75rem 1rem',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    height: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    fontWeight: 700,
  },
}));
