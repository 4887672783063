import React, { forwardRef, createContext } from 'react';
import ActionRow from '../ActionRow/ActionRow';
import Label from './Label/Label';
import { useStyles } from './Container.styles';
import { Grid, GridItemsAlignment, GridSize } from '@material-ui/core';

interface IContainerProps {
  children: React.ReactNode;
  label?: string | React.ReactNode;
  actions?: React.ReactNode;
  form?: boolean;
  loading?: boolean;
  filters?: React.ReactNode;
  customSize?: ICustomSize;
  customHeader?: React.ReactNode;
  customActionHeader?: React.ReactNode;
  customIconHeader?: React.ReactNode;
  mobileView?: boolean;
  customStatisticFilter?: boolean;
  alignment?: GridItemsAlignment;
  noPadding?: boolean;
}

export interface ICustomSize {
  fullsize?: boolean;
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  xl?: GridSize;
}
interface IContainerContext {
  loading?: boolean;
}

export const ContainerContext = createContext<IContainerContext | null | undefined>(null);

const Container: React.RefForwardingComponent<HTMLFormElement, IContainerProps> = (props, ref) => {
  const classes = useStyles();

  function sizeLg() {
    if (props.customSize?.fullsize) {
      return 12;
    }

    if (props.customSize?.md) {
      return props.customSize.md;
    }

    if (props.form) {
      return 5;
    } else {
      return 6;
    }
  }

  return (
    <ContainerContext.Provider value={{ loading: props.loading }}>
      <Grid
        item
        lg={props.customSize?.lg ?? sizeLg()}
        md={props.customSize?.fullsize ? 12 : props.customSize?.md ?? 6}
        sm={props.customSize?.fullsize ? 12 : props.customSize?.sm ?? 12}
        xs={12}
        alignItems={props.alignment}
      >
        <div className={`${classes.root} ${props.noPadding ? classes.noPadding : ''} ${props.label || props.customHeader ? classes.allRoundCorners : ''} `}>
          {props.filters && props.customActionHeader ? (
            <>
              {props.label && <Label label={props.label} />}
              <div className={classes.CustomContainer}>
                <span className={props.customStatisticFilter ? classes.statisticFilters : classes.customFilters}>
                  {props.filters}
                </span>
                <span className={classes.customActionHeader}>{props.customActionHeader}</span>
              </div>
            </>
          ) : (
            <>
              {props.customHeader && <>{props.customHeader}</>}
              {props.customActionHeader && <>{props.customActionHeader}</>}
              {props.customIconHeader && <span className={classes.customIconHeader}>{props.customIconHeader}</span>}
              {props.label && <Label label={props.label} />}
              {props.filters && (
                <ActionRow reverse>
                  <div className={props.customStatisticFilter ? classes.statisticFilters : classes.filters}>
                    {props.filters}
                  </div>
                </ActionRow>
              )}
            </>
          )}
          {props.form ? (
            <form ref={ref}>
              <Grid container item spacing={2} className={classes.children} alignItems={props.alignment} style={{padding: props.noPadding ? '0' : ''}}>
                {props.children}
              </Grid>
              {props.actions && (
                <div className={props.mobileView ? classes.mobileActions : classes.actions}>{props.actions}</div>
              )}
            </form>
          ) : (
            <div>
              <div className={classes.children}>{props.children}</div>
              {props.actions && (
                <div className={props.mobileView ? classes.mobileActions : classes.actions}>{props.actions}</div>
              )}
            </div>
          )}
        </div>
      </Grid>
    </ContainerContext.Provider>
  );
};

export default forwardRef(Container);
