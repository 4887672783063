import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    color: theme.palette.error.dark,
    borderColor: theme.palette.error.dark,
  },
  save: {
    marginLeft: '1rem',
  },
  actions: {
    marginTop: '1rem',
  },

  activityWindow: {
    display: 'flex',
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    justifyContent: 'end',
    marginTop: '14px',
    marginLeft: '6.5em',
  },
}));
