import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import useUserContext from '../../../../Core/Authentication/UserContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '15rem',
    height: '3.5rem',
    objectFit: 'contain',
    backgroundColor: theme.palette.common.white,
  },
}));

export const AddtionalHeaderImage = () => {
  const { user } = useUserContext();
  const classes = useStyles();
  return user && (user.companyAdditionalImageBase64 || user.impersonatedCompany?.additionalImage) ? (
    <img
      className={classes.root}
      src={user.companyAdditionalImageBase64 ?? user.impersonatedCompany?.additionalImage}
      alt="additional"
    />
  ) : null;
};
