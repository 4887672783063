import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useGetFacilities } from './Facilities.api';
import { Table } from '../../../../../../Shared/Table/Table';

interface IFacilityListProps {
  customerId: string | undefined;
}

const FacilityList = (props: IFacilityListProps) => {
  const history = useHistory();
  const { getFacilities, isLoading, facilities } = useGetFacilities(props.customerId);

  useEffect(() => {
    if (props.customerId) {
      getFacilities();
    }
  }, [getFacilities, props.customerId]);

  return (
    <Table label="Mina objekt" loading={{ loading: isLoading, skeletonRows: 5 }} minWidth>
      <TableHead>
        <TableRow>
          <TableCell>Namn</TableCell>
          <TableCell>Adress</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {facilities?.map((facility) => (
          <TableRow key={facility.id}>
            <TableCell>
              <Link to={`${history.location.pathname}/facility/${facility.id}`}>{facility.name}</Link>
            </TableCell>
            <TableCell>
              {facility.address}, {facility.postalCode} {facility.city}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default FacilityList;
