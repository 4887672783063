import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TableHead, TableRow, TableCell, TableBody, ListItemIcon, Typography, MenuItem } from '@material-ui/core';
import { Table } from '../../../../../../Shared/Table/Table';
import { ISurveyListItemDto, SurveyType, useDeactiveSurvey, useDeleteSurvey, useGetSurveyList } from '../Surveys.api';
import AccessGuard from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { AccessRights, ModuleIdentifiers } from '../../../../../../Core/Authentication/ModuleAccess';
import { DropdownCell } from '../../../../../../Shared/Table/DropdownCell/DropdownCell';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/EditOutlined';
import { useStyles } from '../../../../../../Shared/Table/TableRowHeader/TableRowHeader.styles';
import Snackbar from '../../../../../../Shared/Hooks/UseErrorMessage/Snackbar/Snackbar';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import IconButton from '../../../../../../Shared/IconButton/IconButton';
import { ConfirmDeleteOrArchiveRow } from '../../../../../../Shared/Table/ConfirmDeleteOrArchiveRow/ConfirmDeleteOrArchiveRow';

const SurveyList = () => {
  const { isLoading, surveys, getSurveys } = useGetSurveyList();
  const { deleteSurvey } = useDeleteSurvey();
  const { deactiveSurvey } = useDeactiveSurvey();

  const [confirmDeleteSurveyId, setConfirmDeleteSurveyId] = useState<number | undefined>();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    getSurveys({ query: '' });
  }, [getSurveys]);

  async function handleDelete() {
    if (confirmDeleteSurveyId) {
      await deleteSurvey(confirmDeleteSurveyId.toString());
      await getSurveys({ query: '' });
      setConfirmDeleteSurveyId(undefined);
    }
  }

  const handleTableClick = (survey: ISurveyListItemDto) => {
    history.push(`${history.location.pathname}/${survey.id}/answers`);
  };

  const handleDeactiveSurvey = useCallback(
    async (id: number) => {
      await deactiveSurvey(id.toString());
      setOpenSnackbar(true);
      await getSurveys({ query: '' });
    },
    [deactiveSurvey, getSurveys],
  );

  return (
    <>
      <Table
        loading={{
          loading: isLoading,
          skeletonRows: surveys?.items.length,
        }}
        label="Utskick"
        minWidth
        iconButtons={
          <AccessGuard module={ModuleIdentifiers.Admin} accessRights={AccessRights.Write}>
            <IconButton
              size="small"
              aria-label="Lägg till utskick"
              onClick={() => {
                history.push(`${history.location.pathname}/create`);
              }}
            >
              <AddIcon />
            </IconButton>
          </AccessGuard>
        }
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <span className={'sr-only'}>Knappar</span>
            </TableCell>
            <TableCell>Namn</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {surveys &&
            surveys.items.map((survey) => (
              <>
                {confirmDeleteSurveyId !== survey.id ? (
                  <TableRow className={classes.pointerCursor} hover tabIndex={-1} key={survey.type}>
                    <AccessGuard
                      module={ModuleIdentifiers.Admin}
                      accessRights={AccessRights.Full}
                      fallback={<TableCell className={classes.narrowColumn}></TableCell>}
                    >
                      <DropdownCell>
                        {survey.type === SurveyType.Custom && (
                          <MenuItem onClick={() => setConfirmDeleteSurveyId(survey.id)}>
                            <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                            <Typography variant="inherit">Ta bort</Typography>
                          </MenuItem>
                        )}

                        {survey.isActive && (
                          <MenuItem onClick={() => handleDeactiveSurvey(survey.id)}>
                            <ListItemIcon>{<BlockIcon />}</ListItemIcon>
                            <Typography variant="inherit">Avaktivera</Typography>
                          </MenuItem>
                        )}

                        <MenuItem onClick={() => history.push(`${history.location.pathname}/${survey.id}/update`)}>
                          <ListItemIcon>{<EditIcon />}</ListItemIcon>
                          <Typography variant="inherit">Redigera</Typography>
                        </MenuItem>
                      </DropdownCell>
                    </AccessGuard>
                    <TableCell onClick={() => handleTableClick(survey)} className={classes.linkFont}>
                      {survey.name}
                    </TableCell>
                    <TableCell onClick={() => handleTableClick(survey)} className={classes.linkFont}>
                      {survey.isActive ? 'Aktiv' : !survey.interval ? 'Manuell' : 'Inaktiv'}
                    </TableCell>
                  </TableRow>
                ) : (
                  <ConfirmDeleteOrArchiveRow
                    onConfirm={handleDelete}
                    onCancel={() => setConfirmDeleteSurveyId(undefined)}
                    colspan={2}
                    label={`somethingdelete`}
                    loading={false}
                  />
                )}
              </>
            ))}
        </TableBody>
      </Table>

      <Snackbar
        message={'Utskick avaktiveras.'}
        open={openSnackbar}
        severity={'success'}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
};

export default SurveyList;
