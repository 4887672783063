import { useApiDelete, useApiPost, useApiPut } from '../../../Core/Api/Api';
import IPaginatedRequest from '../../../Shared/Api/request/IPaginatedRequest';
import { ICreateResult } from '../../../Shared/Api/response/ICreateResult';
import IPaginatedResponse from '../../../Shared/Api/response/IPaginatedResponse';
import ValidationErrorResultType from '../../../Shared/Api/response/ValidationErrorResultType';

export interface IAdministrator extends ICreateAdministrator {
  id: number;
  fullName: string;
  isActive: boolean;
}

export interface ICreateAdministrator {
  firstName: string;
  lastName: string;
  email: string;
}

export interface IUpdateAdministrator {
  firstName: string;
  lastName: string;
}

export interface IAdministratorValidationError extends ValidationErrorResultType<ICreateAdministrator> {}

export function useGetAdministrators() {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<IAdministrator>, IPaginatedRequest>(
    '/api/users/administrators/list',
  );
  return {
    isLoading,
    response,
    getAdministrators: post,
  };
}

export function useCreateAdministrator() {
  const { isLoading, post } = useApiPost<ICreateResult<IAdministratorValidationError>, ICreateAdministrator>(
    `/api/users/administrator`,
  );

  return {
    isLoading,
    createAdministrator: post,
  };
}

export function useUpdateAdministrator() {
  const { isLoading, put } = useApiPut<IAdministratorValidationError, IUpdateAdministrator>(
    `/api/users/administrator/`,
  );

  return {
    isLoading,
    updateAdministrator: put,
  };
}

export function useDeleteAdministrator() {
  const { isLoading, delete: deleteRequest } = useApiDelete('/api/users/administrator/');

  function deleteAdministrator(id: number) {
    return deleteRequest(undefined, id.toString());
  }

  return {
    isLoading,
    deleteAdministrator,
  };
}

export function useSendAdministratorInvitation() {
  const { isLoading, post } = useApiPost(`/api/users/administrator/`);

  const sendAdminInvite = (id: string) => {
    return post(undefined, `${id}/invite`);
  };

  return {
    isLoading,
    sendAdminInvite,
  };
}
