import React from 'react';
import MuiTable, { TableProps } from '@material-ui/core/Table';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import EmptyListIcon from '../../Styles/Icons/EmptyListIcon';
import SkeletonRow from './SkeletonRow/SkeletonRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from './Table.styles';
import { ICustomSize } from '../Container/Container';

interface IEmptyTable {
  empty: boolean;
  message?: string;
}

export interface ILoadingTable {
  loading: boolean;
  skeletonRows?: number;
}

export interface ITableProps extends TableProps {
  label?: string | React.ReactNode;
  filters?: React.ReactElement;
  actions?: React.ReactNode;
  pagination?: React.ReactNode;
  mobile?: boolean;
  children: React.ReactNode;
  empty?: IEmptyTable;
  loading?: ILoadingTable;
  h2?: boolean;
  minWidth?: boolean;
  customSize?: ICustomSize;
  customStatisticFilter?: boolean;
  iconButtons?: React.ReactNode;
  noBorders?: boolean;
}

export const Table = (props: ITableProps) => {
  const classes = useStyles();
  const {
    label,
    filters,
    actions,
    pagination,
    children,
    mobile,
    empty,
    loading,
    h2,
    minWidth,
    customSize,
    iconButtons,
    ...rest
  } = props;
  const theme = useTheme();
  const mobileFilter = useMediaQuery(theme.breakpoints.down('sm'));
  const filterRef = React.useRef<HTMLDivElement>(null);
  const mobileFilterItems: React.ReactChild[] = filters?.props?.children;

  const checkIfMoreThanOneFilter = () => {
    return (mobileFilterItems && mobileFilterItems.length > 1) ?? false;
  };

  let skeletonRows = [];

  for (var i = 0; i < (loading?.skeletonRows ? loading?.skeletonRows : 15); i++) {
    skeletonRows.push(<SkeletonRow key={`skeletion-${i}`} />);
  }

  return (
    <Grid
      item
      lg={customSize?.fullsize ? 12 : customSize?.lg ?? customSize?.md ?? 6}
      md={customSize?.fullsize ? 12 : customSize?.md ?? 6}
      sm={customSize?.sm ?? 12}
      xs={12}
    >
      <div
        className={`${classes.table} ${label ? classes.roundCorners : classes.roundBottomCorners} ${
          !pagination ? classes.noBorderOnLastTr : ''
        } ${props.noBorders ? classes.noBorders : ''
        }`}
      >
        {(label || iconButtons) && 
          <header className={classes.header} style={{borderLeft: props.noBorders ? '1px solid #e0e0e0' : '0', borderRight: props.noBorders ? '1px solid #e0e0e0' : '0'}}>
            <Typography id={label as string} className={classes.label} variant="h3" component="h2">
              {props.label}
            </Typography>
            {iconButtons && <div className={classes.iconButtons}>{iconButtons}</div>}
          </header>
        }
        {(filters || actions) && (
          <div className={classes.actionRow}>
            {filters && (
              <>
                {mobileFilter && checkIfMoreThanOneFilter() ? (
                  <Accordion className={classes.filterAccordion}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-labelledby="filter">
                      {mobileFilterItems[0]}
                    </AccordionSummary>
                    <AccordionDetails>
                      <section
                        className={props.customStatisticFilter ? classes.statisticFilters : classes.filters}
                        ref={filterRef}
                        aria-label="Filter"
                      >
                        {mobileFilterItems.map(
                          (f: React.ReactChild, index) => index > 0 ? (f) : null)                         
                        }
                      </section>
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <section
                    className={
                      props.customStatisticFilter
                        ? classes.statisticFilters
                        : `${classes.filters} ${classes.filterInDesktop} `
                    }
                    aria-label="Filter"
                  >
                    {filters}
                  </section>
                )}
              </>
            )}
            {actions && <div className={classes.actions}>{actions}</div>}
          </div>
        )}
        {empty?.empty ? (
          <div className={classes.empty}>
            <span className={classes.tableMessage}>
              <EmptyListIcon />
            </span>
            <Typography>{empty?.message ? empty.message : 'Tabellen är tom'}</Typography>
          </div>
        ) : (
          <>
            {!loading || !loading.loading ? (
              <>
                {!mobile ? (
                  <MuiTable {...rest} aria-labelledby={props.label as string}>
                    {children}
                  </MuiTable>
                ) : (
                  <div>{children}</div>
                )}
              </>
            ) : (
              <>{skeletonRows}</>
            )}
            {pagination && <div>{pagination}</div>}
          </>
        )}
      </div>
    </Grid>
  );
};
