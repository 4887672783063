import React from 'react';
import CloseIcon from '@material-ui/icons/CloseRounded';
import CheckIcon from '@material-ui/icons/CheckRounded';
import IconButton from '../../../Shared/IconButton/IconButton';
import { TableCell } from '@material-ui/core';
import { useStyles } from './EditRows.Styles';

interface IEditRow {
  label?: string | number;
  colspan?: number;
  onConfirm: () => void;
  onCancel: () => void;
  loading?: boolean;
  mobile?: boolean;
  children?: JSX.Element | JSX.Element[];
}

export const EditRows = (props: IEditRow) => {
  const classes = useStyles();

  return !props.mobile ? (
    <>
      <TableCell>
        <div className={classes.root}>
          <IconButton
            role={'AcceptButton'}
            size="small"
            autoFocus
            className={classes.confirm}
            onClick={props.onConfirm}
            loading={props.loading}
          >
            <CheckIcon />
          </IconButton>
          <IconButton size="small" onClick={props.onCancel} aria-label="Avbryt" disabled={props.loading}>
            <CloseIcon />
          </IconButton>
        </div>
      </TableCell>
      <TableCell colSpan={props.colspan}>
        <div className={classes.root}>{props.children}</div>
      </TableCell>
    </>
  ) : (
    <div className={classes.mobile}>
      <div className={classes.root}>{props.children}</div>
    </div>
  );
};
