import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import ConfirmSupplierOrderForm from './Features/ConfirmSupplierOrder/ConfirmSupplierOrderForm';
import ApproveOfferForm from './Features/ApproveOffer/ApproveOfferForm';
import NotFound from './Shared/NotFound/NotFound';
import SurveyForm from './Features/Survey/SurveyForm/SurveyForm';
import SecurityCompanyConfirmForm from './Features/SecurityCompanyConfirm/SecurityCompanyConfirmForm';
import MyPagesLogin from './Features/MyPages/Login/MyPagesLogin';
import MyCompanies from './Features/MyPages/View/My-Companies/MyCompanies';

const AnonymousAppRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/supplierorder`}>
        <ConfirmSupplierOrderForm />
      </Route>
      <Route exact path={`${path}/offer`}>
        <ApproveOfferForm />
      </Route>
      <Route exact path={`${path}/survey`}>
        <SurveyForm />
      </Route>
      <Route exact path={`/anonymous/my-pages`}>
        <MyPagesLogin />
      </Route>
      <Route exact path={`${path}/my-pages/companies`}>
        <MyCompanies />
      </Route>
      <Route exact path={`${path}/securitycompany`}>
        <SecurityCompanyConfirmForm />
      </Route>
      <Route path={[`${path}*/404`, `${path}*`]}>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default AnonymousAppRoutes;
