import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  label: {
    textTransform: 'capitalize',
    padding: '1rem 1rem 0.75rem',
    height: '1.5rem',
    color: theme.palette.primary.contrastText,
    fontWeight: 700,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '0.25rem 0.5rem',
  },
  root: {
    width: '100%',
    display: 'flex',
    // justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    background: theme.palette.primary.main,
  },
  navigateButton: {
    padding: 'calc(0.75rem - 4px)',
    border: '2px solid',
    marginRight: '1rem',
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('md')]: {
      marginRight: '0.5rem',
      marginTop: '0.25rem',
      marginBottom: '0.25rem',
    },
  },
  todayButtonWrapper: {
    marginRight: '15px',
    cursor: 'pointer',
    color: theme.palette.primary.contrastText,
    border: '2px solid white',
    padding: '10px 10px 8px',
    borderRadius: '5px',
  },                                
  chooseDatePicker: {
    cursor: 'pointer',
    paddingRight: '0.5rem',
    '& .react-datepicker-popper': {
      zIndex: 999,
    }
  },
  mobileDateWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '1rem',
    '& > div': {
      display: 'flex'
    }
  },
  mobileButtonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '1rem'
  }
}));
