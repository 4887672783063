import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    '& > *': {
      margin: '0.5rem 0.5rem',
    },
  },
}));
