import React, { useEffect, useState } from 'react';
import { Table } from '../Table';
import SearchInput from '../../InputFields/SearchInput/SearchInput';
import { ICustomSize } from '../../Container/Container';

interface IProps<T> {
  label?: string;
  filterLabel?: string;
  items: T[];
  filter: (query: string, x: T) => boolean;
  children: (items: T[]) => React.ReactNode;
  actions?: React.ReactNode;
  loading?: boolean;
  mobile?: boolean;
  customSize?: ICustomSize;
}

const TableWithSearch = <T extends object>(props: IProps<T>) => {
  const [items, setItems] = useState<T[]>(props.items);
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (!query) setItems(props.items);
    else {
      const filtreredItems = props.items.filter((x) => props.filter(query.toLowerCase(), x));
      setItems(filtreredItems);
    }
  }, [props, props.filter, props.items, query]);

  return (
    <Table
      label={props.label}
      actions={props.actions}
      loading={props.loading ? { loading: props.loading, skeletonRows: 5 } : undefined}
      mobile={props.mobile}
      customSize={props.customSize}
      filters={
        props.filter && <SearchInput type="search" placeholder={props.filterLabel ?? 'Sök'} onChange={setQuery} />
      }
    >
      {props.children(items)}
    </Table>
  );
};

export default TableWithSearch;
