import React, { useEffect, useMemo, useState } from 'react';
import { Fieldset } from '../../../../../../../Shared/Form/Fieldset/Fieldset';
import {
  IDocumentFormatType,
  IPreselectedDocumentFormatSection,
  useGetPreselectedDocumentFormat,
  useUpsertPreselectedDocumentFormat,
  IPreselectionFileInformationDto,
  IPreselectionDocumentType,
  useGetPreselectionFileContent,
} from '../../Preselection.api';
import RichTextEditor from '../../../../../../../Shared/RichTextEditor/RichTextEditor';
import { ISelectOption, Select } from '../../../../../../../Shared/Select';
import { IPreselectionProps } from '../../Preselection';
import { isErrorResponse } from '../../../../../../../Shared/Api/response/IErrorRespose';
import { Table } from '../../../../../../../Shared/Table/Table';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import formatDate from '../../../../../../../Shared/Formatting/formatDate';
import Button from '../../../../../../../Shared/Button/Button';
import useStyles from '../../Preselection.styles';
interface ITypeSelectOption extends ISelectOption {
  type: IDocumentFormatType;
}
interface IPreselectedDocumentFormatProps extends IPreselectionProps {
  setPreselectionDocumentType: (type: IPreselectionDocumentType) => void;
}

const options: ISelectOption[] = IPreselectedDocumentFormatSection.map((x) => ({
  label: x.name,
  value: x.type.toString(),
}));

const DocumentFormat = (props: IPreselectedDocumentFormatProps) => {
  const classes = useStyles();
  const {
    preselectionDefaultContent,
    saveCallback,
    setIsLoading,
    setPreselectionId,
    setPreselectionDocumentType,
    setPreselectionDefaultContent,
    setChildrenComponent,
  } = props;
  const { upsert } = useUpsertPreselectedDocumentFormat();
  const { isLoading: isHeaderFooterLoading, getPreselectedDocumentFormat } = useGetPreselectedDocumentFormat();
  const { isLoading: isGettingFileLoading, getPreselectionFileContent } = useGetPreselectionFileContent();

  const [selectedDocumentType, setSelectedDocumentType] = useState<IDocumentFormatType>(IDocumentFormatType.Header);
  const [header, setHeader] = useState<string>('');
  const [footer, setFooter] = useState<string>('');
  const [headerFiles, setHeaderFiles] = useState<IPreselectionFileInformationDto[]>([]);
  const [footerFiles, setFooterFiles] = useState<IPreselectionFileInformationDto[]>([]);
  const isHeader = useMemo(() => selectedDocumentType === IDocumentFormatType.Header, [selectedDocumentType]);

  useEffect(() => {
    setIsLoading(isHeaderFooterLoading || isGettingFileLoading);
  }, [isHeaderFooterLoading, isGettingFileLoading, setIsLoading]);

  useEffect(() => {
    if (preselectionDefaultContent) {
      switch (selectedDocumentType) {
        case IDocumentFormatType.Header:
          setHeader(preselectionDefaultContent);
          break;
        case IDocumentFormatType.Footer:
          setFooter(preselectionDefaultContent);
          break;
        default:
          break;
      }
      setPreselectionDefaultContent(undefined);
    }
  }, [preselectionDefaultContent, selectedDocumentType, setPreselectionDefaultContent]);

  useEffect(() => {
    setPreselectionDocumentType(
      selectedDocumentType === IDocumentFormatType.Header
        ? IPreselectionDocumentType.DocumentHeader
        : IPreselectionDocumentType.DocumentFooter,
    );
  }, [selectedDocumentType, setPreselectionDocumentType]);

  useEffect(() => {
    const save: any = async (id?: string) => {
      const result = await upsert(
        {
          customContent: isHeader ? header : footer,
          documentType: selectedDocumentType,
        },
        id,
      );
      return !isErrorResponse(result);
    };
    saveCallback(() => save);
  }, [header, footer, selectedDocumentType, isHeader, saveCallback, upsert]);

  useEffect(() => {
    const getValues = async () => {
      const results = await getPreselectedDocumentFormat();
      if (results) {
        setPreselectionId(results.id?.toString());
        setFooter(results.customFooter!);
        setHeader(results.customHeader!);
        setHeaderFiles(results.customHeaderFileNames);
        setFooterFiles(results.customFooterFileNames);
      } else {
        //TODO: display error message
      }
    };
    getValues();
  }, [getPreselectedDocumentFormat, setPreselectionId]);

  const handleGetPreselectionFile = async (id: string) => {
    const file = await getPreselectionFileContent(id.toString());
    isHeader ? setHeader(file.content) : setFooter(file.content);
  };

  useEffect(() => {
    headerFiles && footerFiles && setChildrenComponent(<HeaderFooterFileTable />);
  }, [isHeader, headerFiles, footerFiles, setChildrenComponent]);

  const textEditor = useMemo(() => {
    if (header && footer) {
      return (
        <RichTextEditor
          key={Number(isHeader)}
          menuBar={false}
          header={header}
          footer={footer}
          value={isHeader ? header : footer}
          onChange={isHeader ? setHeader : setFooter}
          onFocus={() => true}
          onBlur={() => false}
          height={400}
          type={isHeader ? 'Header' : 'Footer'}
        />
      );
    }
  }, [header, footer, isHeader]);

  const HeaderFooterFileTable = () => (
    <Table customSize={{ md: 5, lg: 5 }}>
      <TableHead>
        <TableRow>
          <TableCell>Version</TableCell>
          <TableCell>Skapad</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isHeader
          ? headerFiles &&
            headerFiles.map((file) => (
              <TableRow>
                <TableCell>
                  <Button
                    color="primary"
                    variant="text"
                    className={classes.button}
                    disableTouchRipple
                    onClick={() => handleGetPreselectionFile(file.id.toString())}
                  >
                    {file.name}
                  </Button>
                </TableCell>
                <TableCell>{formatDate(new Date(file.created))}</TableCell>
              </TableRow>
            ))
          : footerFiles &&
            footerFiles.map((file) => (
              <TableRow>
                <TableCell>
                  <Button
                    color="primary"
                    variant="text"
                    className={classes.button}
                    disableTouchRipple
                    onClick={() => handleGetPreselectionFile(file.id.toString())}
                  >
                    {file.name}
                  </Button>
                </TableCell>
                <TableCell>{formatDate(new Date(file.created))}</TableCell>
              </TableRow>
            ))}
      </TableBody>
    </Table>
  );

  return (
    <>
      <Fieldset legend="Dokumentmall">
        <div className={classes.selectContainerUnpadded}>
          <Select
            inputFieldProps={{ fullwidth: true, label: 'Välj sidhuvud eller sidfot' }}
            selectProps={{
              value: options.find((x) => x.value === selectedDocumentType.toString()),
              options: options,
              onChange: (selectedValue) => {
                const selected = selectedValue as ITypeSelectOption;
                setSelectedDocumentType(parseInt(selected.value));
                setPreselectionDefaultContent(undefined);
              },
            }}
          />
        </div>

        <Fieldset legend={options[selectedDocumentType].label}>
          <div className={classes.wysiwygFields}>{textEditor}</div>
        </Fieldset>
      </Fieldset>
    </>
  );
};

export default DocumentFormat;
