import { useApiGet } from '../../../Core/Api/Api';

export interface INamedayDto {
  names: string;
}

export function useGetTodaysNameday() {
  const { isLoading, response, get } = useApiGet<INamedayDto>('/api/nameday/today');

  return {
    isLoading,
    nameday: response?.names,
    get,
  };
}
