import React, { useCallback, useEffect, useMemo, useState } from 'react';
import RichTextEditor from '../../../../../../../../../Shared/RichTextEditor/RichTextEditor';
import { useGetPreselectedDocumentFormat } from '../../../../../../../Admin/Components/Preselection/Preselection.api';
import { IDocumentFormProps } from '../../IDocumentFormProps';
import { useCreateCustomDocument, useOpenDocument } from '../../Documents.api';
import { isResultError } from '../../../../../../../../../Shared/Api/response/ICreateResult';
import { useParams } from 'react-router-dom';

const CustomDocument = (props: IDocumentFormProps) => {
  const { customerId, documentName, saveCallback, onLoadingChange, validateFormState } = props;
  const { facilityId } = useParams<{ facilityId: string }>();
  const {
    isLoading: isPreselectionLoading,
    preselectedFormats,
    getPreselectedDocumentFormat,
  } = useGetPreselectedDocumentFormat();
  const { isLoading: isOpenLoading, openDocument } = useOpenDocument(customerId);
  const { isLoading, createCustomDocument } = useCreateCustomDocument(customerId);
  const [customPdfData, setCustomPdfData] = useState<string>('');
  const [header, setHeader] = useState<string>(preselectedFormats?.customHeader ?? '');
  const [footer, setFooter] = useState<string>(preselectedFormats?.customFooter ?? '');

  useEffect(() => {
    validateFormState && validateFormState(!!customPdfData);
  }, [validateFormState, customPdfData]);

  useEffect(() => {
    onLoadingChange(isPreselectionLoading || isLoading || isOpenLoading);
  }, [isPreselectionLoading, isLoading, isOpenLoading, onLoadingChange]);

  const setHeaderFooter = useCallback(async () => {
    const results = await getPreselectedDocumentFormat();
    if (results) {
      setFooter(results.customFooter ?? '');
      setHeader(results.customHeader ?? '');
    }
  }, [getPreselectedDocumentFormat]);

  useEffect(() => {
    setHeaderFooter();
  }, [setHeaderFooter]);

  const save = useCallback(async () => {
    async function innerSave() {
      const result = await createCustomDocument({
        facilityId: Number(facilityId),
        documentName: documentName,
        customPdfData: customPdfData,
      });

      if (!isResultError(result)) openDocument(Number(result as string));
    }
    innerSave();
  }, [documentName, customPdfData, facilityId, createCustomDocument, openDocument]);

  useEffect(() => {
    saveCallback(save);
  }, [saveCallback, save]);

  const textEditor = useMemo(() => {
    if (header && footer) {
      return (
        <RichTextEditor
          height={945}
          menuBar
          header={header}
          footer={footer}
          type="customDocument"
          value={customPdfData}
          onChange={setCustomPdfData}
        />
      );
    }
  }, [header, footer, customPdfData]);

  return <>{textEditor}</>;
};

export default CustomDocument;
