import { useCallback } from 'react';
import { useApiPost, useApiGet, useApiPut, useApiDelete } from '../../../../../../../Core/Api/Api';
import IPaginatedResponse from '../../../../../../../Shared/Api/response/IPaginatedResponse';
import IPaginatedRequest from '../../../../../../../Shared/Api/request/IPaginatedRequest';
import ValidationErrorResultType from '../../../../../../../Shared/Api/response/ValidationErrorResultType';
import { ICreateResult } from '../../../../../../../Shared/Api/response/ICreateResult';
import { IUploadFile } from '../../../../../../../Shared/InputFields/UploadInput/UploadInput';

export interface IProduct {
  id: number;
  name: string;
  productCategoryName: string;
  productCategorySortOrder: number;
  certifiedTo?: Date | null;
  regulationName?: string;
  regulationPriority?: number;
  visible: boolean;
}

export interface IProductExtended extends IProduct {
  description: string;
  price: number;
  supplierName: string;
  supplierId: number;
  installationTime?: number;
}

export interface IProductListBySupplierRequest extends IPaginatedRequest {
  productCategoryIds: number[];
}

export function useGetProductsBySupplier() {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<IProduct>, IProductListBySupplierRequest>(
    '/api/suppliers',
  );

  const getProducts = useCallback(
    (supplierId: string, data: IProductListBySupplierRequest) => {
      return post(data, `/${supplierId}/products`);
    },
    [post],
  );

  return {
    isLoading,
    products: response,
    getProducts: getProducts,
  };
}

export function useGetAllProductsBySupplier() {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<IProduct>, IProductListBySupplierRequest>(
    '/api/suppliers',
  );

  const getProducts = useCallback(
    (supplierId: string, data: IProductListBySupplierRequest) => {
      return post(data, `/${supplierId}/products/all`);
    },
    [post],
  );

  return {
    isLoading,
    products: response,
    getProducts: getProducts,
  };
}

export interface IProductListRequest extends IPaginatedRequest {
  onlyMainProducts?: boolean;
  onlyProductsInRegulation?: boolean;
  systemTypeId?: number;
  regulationId?: number;
  mainProductId?: number;
}

export function useGetProducts(onlyHidden: boolean = false) {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<IProductExtended>, IProductListRequest>(
    `/api/products/list${onlyHidden ? '/hidden' : ''}`,
  );

  const getProducts = useCallback(
    (request: IProductListRequest) => {
      return post(request);
    },
    [post],
  );

  return {
    isLoading,
    products: response,
    getProducts: getProducts,
  };
}

export function useGetProductsCount() {
  const { response } = useApiGet<{ visibleProductsCount: number; hiddenProductCount: number }>(`/api/products/count`);

  return {
    productsCount: response,
  };
}

export function useGetAllProducts() {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<IProductExtended>, IProductListRequest>(
    '/api/products/list/all',
  );

  const getProducts = useCallback(
    (request: IProductListRequest) => {
      return post(request);
    },
    [post],
  );

  return {
    isLoading,
    products: response,
    getProducts: getProducts,
  };
}

export interface ISelect {
  id: number;
  name: string;
}

export interface ISystemTypeSelect extends ISelect {
  regulations: ISelect[];
}

export interface IGetProductForm {
  systemTypes: ISystemTypeSelect[];
  productCategories: ISelect[];
  mainProducts: ISelect[];
  childProducts: ISelect[];
}

export function useGetProductForm(supplierId: string | undefined) {
  const { isLoading, get } = useApiGet<IGetProductForm>(`/api/suppliers/${supplierId}/product/`, false);

  const getProductForm = useCallback(() => {
    return get();
  }, [get]);

  return {
    isGetProductFormLoading: isLoading,
    getProductForm,
  };
}

export interface IGetProductFilters {
  productCategories: ISelect[];
}

export function useGetProductFilters(supplierId: string | undefined) {
  const { isLoading, get, response } = useApiGet<IGetProductFilters>(
    `/api/suppliers/${supplierId}/products/filters/`,
    false,
  );

  return {
    isGetProductFiltersLoading: isLoading,
    getProductFilters: get,
    productFilters: response,
  };
}

export interface IProductDto {
  supplierName: string;
  systemTypeId: number;
  regulationId: number;
  productCategoryId: number;
  isMainProduct: boolean;
  mainProductIds?: number[];
  childProductIds?: number[];

  name: string;
  description: string;
  productNumber: string;

  purchasePrice: number;
  netPrice: number;
  price: number;
  resellerPrice: number;

  numberOfSections?: number;
  numberOfBuses?: number;
  numberOfInputs?: number;
  numberOfOutputs?: number;

  certifiedTo?: Date | null;

  idlePowerConsumption?: number;
  activePowerConsumption?: number;

  installationTime?: number;
  installationCost?: number;
  installationPrice?: number;

  productImage?: IUploadFile;
  productPdf?: IUploadFile;
  visible: boolean;
}

export function useGetProductBySupplier(supplierId: string | undefined) {
  const { isLoading, get } = useApiGet<IProductDto>(`/api/suppliers/${supplierId}/product/`, false);

  return {
    isGetProductLoading: isLoading,
    getProduct: get,
  };
}

export function useDeleteProductBySupplier(supplierId: string | undefined) {
  const { isLoading, delete: deleteRequest } = useApiDelete(`/api/suppliers/${supplierId}/product/`);

  const deleteProduct = useCallback(
    async (productId: string) => {
      return await deleteRequest(undefined, productId);
    },
    [deleteRequest],
  );

  return {
    isLoading,
    deleteProduct,
  };
}

export function useGetProduct() {
  const { isLoading, get } = useApiGet<IProductDto>(`/api/products/`, false);

  return {
    isGetProductLoading: isLoading,
    getProduct: get,
  };
}

export interface IUpsertProductCommand {
  systemTypeId: number;
  regulationId: number;
  productCategoryId: number;
  isMainProduct: boolean;
  isVisible: boolean;
  mainProductIds?: number[];
  childProductIds?: number[];

  name: string;
  description: string;
  productNumber: string;

  purchasePrice: number;
  netPrice: number;
  price: number;
  resellerPrice: number;

  numberOfSections?: number;
  numberOfBuses?: number;
  numberOfInputs?: number;
  numberOfOutputs?: number;

  certifiedTo?: Date | null;

  idlePowerConsumption?: number;
  activePowerConsumption?: number;

  installationTime?: number;
  installationCost?: number;
  installationPrice?: number;

  productImage?: IUploadFile;
  productPdf?: IUploadFile;
}

export interface IUpsertProductValidationErrors extends ValidationErrorResultType<IUpsertProductCommand> {}

export function useUpsertProduct(supplierId: string | undefined) {
  const { isLoading: isCreateLoading, post } = useApiPost<
    ICreateResult<IUpsertProductValidationErrors>,
    IUpsertProductCommand
  >(`/api/suppliers/${supplierId}/product/`);

  const { isLoading: isUpdateLoading, put } = useApiPut<IUpsertProductValidationErrors, IUpsertProductCommand>(
    `/api/suppliers/${supplierId}/product/`,
  );

  async function upsert(command: IUpsertProductCommand, id?: string) {
    if (id) {
      const result = await put(command, id);

      return result ?? id;
    }

    return post(command);
  }

  return {
    isUpsertLoading: isCreateLoading || isUpdateLoading,
    upsert: upsert,
  };
}

export interface IUpsertProductVisibility {
  visible: boolean;
}

export interface IUpsertProductVisibilityValidationErrors extends ValidationErrorResultType<IUpsertProductVisibility> {}

export function useUpdateProductVisibility() {
  const { isLoading, put } = useApiPut<IUpsertProductVisibilityValidationErrors, IUpsertProductVisibility>(
    `/api/products/visibility/`,
  );

  const update = useCallback(
    async (id: number, visible: boolean) => {
      const command: IUpsertProductVisibility = {
        visible: visible,
      };
      return put(command, id.toString());
    },
    [put],
  );

  return {
    IsUpsertProductLoading: isLoading,
    updateProduct: update,
  };
}
