import moment from 'moment';
import 'moment-timezone';

function getTzDate(d: Date | undefined) {
  var date = moment(d);
  var zone = 'Europe/Paris';
  return moment.tz(date, zone);
}

export function newTzDate(d: Date) {
  d.setTime(d.getTime() + d.getTimezoneOffset()*60*1000);
  return d;
}

export default function formatDate(d: Date | undefined, defaultText?: string): string {
  if (!d) return defaultText ? defaultText : '';

  return getTzDate(d).format('YYYY-MM-DD');
}

export function formatDateMonthName(d: Date | undefined, defaultText?: string): string {
  if (!d) return defaultText ? defaultText : '';

  return getTzDate(d).format('MMM DD');
}

export function formatDateWithDayName(d: Date | undefined, defaultText?: string): string {
  if (!d) return defaultText ? defaultText : '';
  return getTzDate(d).format('dddd DD MMMM YYYY');

}

export function formatDateWithTime(d: Date | undefined, defaultText?: string): string {
  if (!d) return defaultText ? defaultText : '';

  return getTzDate(d).format('YYYY-MM-DD HH:mm');
}

export function formatTime(d: Date | undefined, defaultText?: string): string {
  if (!d) return defaultText ? defaultText : '';

  return getTzDate(d).format('HH:mm');
}
