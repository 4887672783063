import { useApiGet, useApiPut } from '../../../../../../../Core/Api/Api';
import ValidationErrorResultType from '../../../../../../../Shared/Api/response/ValidationErrorResultType';
import { CoreSystemRegistrationStatus } from '../../../../../Customers/Components/Facilities/CoreSystems/CoreSystem.api';

export function useGetCoreSystem(customerId: string | undefined, facilityId: string | undefined) {
  const { isLoading, response, get } = useApiGet<ICoreSystem>(
    `/api/customer-portal/${customerId}/facilities/${facilityId}/coresystems/`,
    false,
  );

  return {
    isLoading,
    coreSystem: response,
    getCoreSystem: get,
  };
}

export interface ICoreSystem {
  eventActions: ICoreSystemEventAction[];
  users: ICoreSystemUser[];
  onPendingConfirm: boolean;
}

export interface ICoreSystemEventAction {
  id?: number;
  eventName: string;
  timespan: string;
  action: string;
}

export interface ICoreSystemUser {
  id?: number;
  name: string;
  email?: string;
  phoneNumber?: string;
  mobilePhoneNumber?: string;
  registrationStatus: CoreSystemRegistrationStatus;
  code?: string;
  IsResponsible?: boolean;
  hasCode: boolean;
}

interface IUpdateCoreSystemEventActionsCommand {
  eventActions: ICoreSystemEventAction[];
}
export interface IUpdateCoreSystemEventActionsValidationErrors
  extends ValidationErrorResultType<IUpdateCoreSystemEventActionsCommand> {}

export function useUpdateCoreSystemEventActions(customerId: string, facilityId: string, coreSystemId: string) {
  const { isLoading, put } = useApiPut<
    IUpdateCoreSystemEventActionsValidationErrors,
    IUpdateCoreSystemEventActionsCommand
  >(`/api/customer-portal/${customerId}/facilities/${facilityId}/coresystems/${coreSystemId}/eventactions`);

  function updateEventActions(eventActions: ICoreSystemEventAction[]) {
    const command: IUpdateCoreSystemEventActionsCommand = {
      eventActions: eventActions,
    };
    return put(command);
  }

  return {
    isUpdatingEventActionsLoading: isLoading,
    updateEventActions: updateEventActions,
  };
}

interface IUpdateCoreSystemUsersCommand {
  users: ICoreSystemUser[];
}
export interface IUpdateCoreSystemUsersValidationErrors
  extends ValidationErrorResultType<IUpdateCoreSystemUsersCommand> {}

export function useUpdateCoreSystemUsers(customerId: string, facilityId: string, coreSystemId: string) {
  const { isLoading, put } = useApiPut<IUpdateCoreSystemUsersValidationErrors, IUpdateCoreSystemUsersCommand>(
    `/api/customer-portal/${customerId}/facilities/${facilityId}/coresystems/${coreSystemId}/users`,
  );

  function updateSystemUsers(users: ICoreSystemUser[]) {
    const command: IUpdateCoreSystemUsersCommand = {
      users: users,
    };
    return put(command);
  }

  return {
    isUpdatingSystemUsersLoading: isLoading,
    updateSystemUsers: updateSystemUsers,
  };
}
