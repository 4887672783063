import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  option: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      flexGrow: 1,
      minWidth: 0,
    },
  },
  addOptionBtn: {
    alignSelf: 'flex-start',
    marginBottom: '1.5rem',
  },
  removeOption: {
    color: theme.palette.error.main,
    display: 'flex',
    borderRadius: '12rem',
    backgroundColor: theme.palette.grey[100],
    marginLeft: '1rem',
    marginTop: '0.25rem',
  },
}));
