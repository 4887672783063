import React, { useState } from 'react';
import {
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  ListItemSecondaryAction,
  InputBase,
  Typography,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import formatDate from '../../../../../../../../../Shared/Formatting/formatDate';
import useStyles from './SelectProductListRow.styles';

export interface ISelectProductItem {
  id: number;
  label: string;
  value: number;
  description: string;
  regulation: string;
  regulationPriority: number;
  certificate?: Date;
  productCategoryName: string;
  productCategorySortOrder: number;
}

export interface ISelectProductProps {
  item: ISelectProductItem;
  loading?: boolean;
  shadowed?: boolean;
  onChange?: (value: number) => void;
}

const SelectProductsListRow = (props: ISelectProductProps) => {
  const classes = useStyles();
  const { item, onChange, loading } = props;
  const [value, setValue] = useState(item.value.toString());
  const checked = item.value > 0;

  return loading ? (
    <ListItem className={`${classes.listItem} ${classes.skeleton}`}>
      <Skeleton variant="circle" animation="wave" className={classes.skeletonCheckbox} />
      <Skeleton variant="text" animation="wave" className={classes.skeletonText} />
    </ListItem>
  ) : (
    <ListItem
      className={`${classes.listItem} ${props.shadowed ? classes.notInRegulation : ""}`}
      button
      aria-label={item.label}
      aria-pressed={checked}
      onClick={() => {
        setValue(checked ? '0' : '1');
        onChange && onChange(checked ? 0 : 1);
      }}
    >
      <ListItemIcon>
        <Checkbox edge="start" color="primary" disableRipple checked={checked} tabIndex={-1} />
      </ListItemIcon>
      <ListItemText
        primary={
          <div className={classes.productInfo}>
            <Typography>{item.label}</Typography>
            <Typography>{item.regulation}</Typography>
          </div>
        }
        primaryTypographyProps={{ component: 'div' }}
        secondary={
          <div className={classes.productInfo}>
            <Typography>{item.description}</Typography>
            <Typography>{item.certificate ? formatDate(item.certificate) : ''}</Typography>
          </div>
        }
        secondaryTypographyProps={{ component: 'div' }}
      />
      <ListItemSecondaryAction className={`${classes.inputContainer} ${!checked ? classes.disabled : ''}`}>
        <InputBase
          disabled={!checked}
          type="number"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            if (e.target.value !== "") {
              onChange && onChange(parseInt(e.target.value));
            }            
          }}
          className={` ${classes.input} ${!checked ? classes.hidden : ''} `}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default SelectProductsListRow;
