import { useApiPost, useApiGet, useApiPut } from '../../../../../Core/Api/Api';
import ValidationErrorResultType from '../../../../../Shared/Api/response/ValidationErrorResultType';
import { ICreateResult } from '../../../../../Shared/Api/response/ICreateResult';
import { useCallback } from 'react';
import {
  ISaleListRequest,
  useGetSales,
  useGetSale,
  SaleStatus,
  WorkflowStatus,
  Measure,
  Service,
  AuditInterval,
  RentType,
  TransferType,
  TransferInterval,
  InvoiceInterval,
  ISaleTerms,
} from '../Sale.api';
import { SaleType } from '../../../../Shared/SaleType';
import { CustomerType } from '../../../../../Shared/Misc/CustomerType';
import { IFileResult } from '../../../../../Shared/Api/response/IFileResult';
import { ICreditCheckDto } from '../../../Customers/Customers.api';
import { Action } from '../../../Admin/Components/SystemLog/SystemLog.api';

/******OFFER LIST*******/
export interface IOfferListItem {
  id: number;
  salesNumber: number;
  customerName: string;
  projectLeader: string;
  type: SaleType;
  status: SaleStatus;
  totalPrice: number;
  description: string;
  createdDate: Date;
  sentDate?: Date;
}

export interface IOfferListRequest extends ISaleListRequest {}

export function useGetOffers() {
  const { isLoading, sales, getSales } = useGetSales();

  const getOffers = useCallback(
    (request: IOfferListRequest) => {
      return getSales({ ...request, workflowStatus: WorkflowStatus.Offer });
    },
    [getSales],
  );

  return {
    isLoading,
    offers: sales,
    getOffers,
  };
}

/******OFFER FORM*******/
export enum OfferSteps {
  notSet = 0,
  setCustomer = 1,
  setFacilityAndSystem,
  setPrices,
  setAgreement,
  setSaleTerms,
  summary,
}

const OfferTypeSteps = {
  [SaleType.system]: [
    OfferSteps.setCustomer,
    OfferSteps.setFacilityAndSystem,
    OfferSteps.setPrices,
    OfferSteps.setAgreement,
    OfferSteps.setSaleTerms,
    OfferSteps.summary,
  ],
  [SaleType.deal]: [
    OfferSteps.setCustomer,
    OfferSteps.setFacilityAndSystem,
    OfferSteps.setAgreement,
    OfferSteps.setSaleTerms,
    OfferSteps.summary,
  ],
  // Added steps here temporary to make next button work...
  [SaleType.service]: [
    OfferSteps.setCustomer,
    OfferSteps.setFacilityAndSystem,
    OfferSteps.setPrices,
    OfferSteps.setAgreement,
    OfferSteps.setSaleTerms,
    OfferSteps.summary,
  ],
  [SaleType.custom]: [
    OfferSteps.setCustomer,
    OfferSteps.setPrices,
    OfferSteps.setSaleTerms,
    OfferSteps.summary,
  ],
};

export function getNextPage(offerType: SaleType, currentStep: OfferSteps): OfferSteps {
  if (currentStep === OfferSteps.summary) {
    return OfferSteps.summary;
  }

  const nextStep = (Number(currentStep) + 1) as OfferSteps;

  if (!OfferTypeSteps[offerType].some((x) => x === nextStep)) {
    return getNextPage(offerType, nextStep);
  }

  return nextStep;
}

export function getPreviousPage(offerType: SaleType, currentStep: OfferSteps): OfferSteps {
  if (currentStep === OfferSteps.setCustomer) {
    return OfferSteps.setCustomer;
  }

  const previousStep = (Number(currentStep) - 1) as OfferSteps;

  if (!OfferTypeSteps[offerType].some((x) => x === previousStep)) {
    return getPreviousPage(offerType, previousStep);
  }

  return previousStep;
}

export interface IStoredActivePage {
  id: string;
  page: OfferSteps;
}

export function getActivePage(offerId: string) {
  let storedActivePages = localStorage.getItem('ACTIVE_PAGE');
  let activePages: IStoredActivePage[] = storedActivePages ? JSON.parse(storedActivePages) : undefined;

  if (!activePages) return undefined;

  let activePage = activePages.find((x) => x.id === offerId)?.page;

  return activePage;
}

export function saveActivePage(offerId: string, offerStep: OfferSteps, maxItems: number = 5) {
  let storedActivePages = localStorage.getItem('ACTIVE_PAGE');
  let activePages: IStoredActivePage[] = storedActivePages ? JSON.parse(storedActivePages) : [];
  activePages = activePages.filter((x: IStoredActivePage) => x.id !== offerId);

  const currentActivePage: IStoredActivePage = {
    id: offerId,
    page: offerStep,
  };

  activePages.unshift(currentActivePage);

  if (activePages.length > maxItems) {
    activePages.length = maxItems;
  }

  localStorage.setItem('ACTIVE_PAGE', JSON.stringify(activePages));
}

export interface IOfferDto {
  offerStep: OfferSteps;
  saleType: SaleType;
  customerId: number;
  referenceNumber: string;
  saleIdentifier: string;
}

export function useGetOfferContext() {
  const { isLoading, response, get } = useApiGet<IOfferDto>(`/api/sales/offer/`, false);

  return {
    isLoading,
    response,
    get,
  };
}

//SetCustomerStep
export interface IOfferCustomerData {
  type: CustomerType;
  name: string;
  identificationNumber: string;
  email: string;
  phoneNumber: string;
  address: string;
  postalCode: string;
  city: string;
  isProspect: boolean;
}

export interface IOfferCustomerDto extends IOfferCustomerData {
  creditCheck?: ICreditCheckDto;
  contacts?: ICustomerContactDto[];
}

export interface IOfferCustomerContactData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  position: string;
}

export interface ICustomerContactDto extends IOfferCustomerContactData {
  id: number;
  isProspect: boolean;
  fullName?: string;
}

export function useGetOfferCustomer() {
  const { isLoading, response, get } = useApiGet<IOfferCustomerDto>(`/api/sales/offer/customer/`, false);

  return {
    isLoading,
    response,
    get,
  };
}

export interface IOfferDraftDto {
  description: string;
  customerContactId?: number;
  salesPersonId?: number;
}

export function useGetOfferDraft(offerId: string | undefined) {
  const { isLoading, response, get } = useApiGet<IOfferDraftDto>(`/api/sales/offer/${offerId}/draft`, false);

  return {
    isLoading,
    response,
    get,
  };
}

export interface IUpdateOfferDraftCommand {
  description: string;
  customer: IOfferCustomerData;
  customerContactId?: number;
  contactPerson?: IOfferCustomerContactData;
  salesPersonId?: number;
}

export interface IUpdateOfferDraftCommandValidationErrors extends ValidationErrorResultType<IUpdateOfferDraftCommand> {}

export function useUpdateOfferDraft(offerId: string) {
  const { isLoading, put } = useApiPut<
    ICreateResult<IUpdateOfferDraftCommandValidationErrors>,
    IUpdateOfferDraftCommand
  >(`/api/sales/offer/${offerId}/draft`);

  return {
    isLoading,
    put,
  };
}

export interface ICreateOfferDraftCommand extends IUpdateOfferDraftCommand {
  saleType: SaleType;
  customerId?: number;
  performCreditCheck: boolean;
}

export interface ICreateOfferDraftCommandValidationErrors extends ValidationErrorResultType<ICreateOfferDraftCommand> {}

export function useCreateOfferDraft() {
  const { isLoading, post } = useApiPost<
    ICreateResult<ICreateOfferDraftCommandValidationErrors>,
    ICreateOfferDraftCommand
  >(`/api/sales/offer`);

  return {
    isLoading,
    post,
  };
}

//SetFacilityAndSystemStep

export interface IOfferFacilityData {
  name: string;
  address: string;
  postalCode: string;
  city: string;
}

export interface IOfferProduct {
  productId: number;
  quantity: number;
  name: string;
  isMain: boolean;
  description: string;
  regulation: string;
  certificate?: Date;
  placements: string[];
  productCategoryName: string;
  productCategorySortOrder: number;
}

export interface ISetOfferFacilityAndSystemDto extends IUpdateOfferFacilityAndSystemCommand {}

export function useGetOfferFacilityAndSystem(offerId: string | undefined) {
  const { isLoading, response, get } = useApiGet<ISetOfferFacilityAndSystemDto>(
    `/api/sales/offer/${offerId}/facilityandsystem/`,
    false,
  );

  return {
    isLoading,
    response,
    get,
  };
}

export interface IUpdateOfferFacilityAndSystemCommand {
  facilityId?: number;
  facilityInformation?: IOfferFacilityData;
  coreSystemId?: number;
  systemTypeId?: number;
  regulationId?: number;
  mainProductId?: number;
  products?: IOfferProduct[];
  projectorId?: number;
}

export interface IUpdateOfferFacilityAndSystemCommandValidationErrors
  extends ValidationErrorResultType<IUpdateOfferFacilityAndSystemCommand> {}

export function useUpdateOfferFacilityAndSystem(offerId: string) {
  const { isLoading, put } = useApiPut<
    ICreateResult<IUpdateOfferFacilityAndSystemCommandValidationErrors>,
    IUpdateOfferFacilityAndSystemCommand
  >(`/api/sales/offer/${offerId}/facilityandsystem/`);

  return {
    isLoading,
    put,
  };
}

//SetPricesStep

export type IPriceItem =
  | 'installationRate'
  | 'installationTime'
  | 'installationCost'
  | 'materialCost'
  | 'otherCost'
  | 'totalPrice'
  | 'firstInstallationCost'
  | 'rentPrice';

export const PriceLabels: { [index in IPriceItem]: string } = {
  installationTime: 'Total tid',
  installationRate: 'Timpris',
  installationCost: 'Total installation',
  materialCost: 'Materialpris',
  otherCost: 'Övrig kostnad',
  totalPrice: 'Totalt offertpris',
  firstInstallationCost: 'Första betalning',
  rentPrice: 'Månadsavgift',
};

export type IPricesDto = {
  [index in IPriceItem]: number;
};

export type IPriceDisplaySettings = {
  [index in IPriceItem]: boolean;
} & {
  material: boolean;
  firstPage: boolean;
  agreement: boolean;
  deliveryAndWarrantyConditions: boolean;
};

export interface IOfferPriceProduct {
  id: number;
  quantity: number;
  price: number;
  installationTime: number;
}

export interface ISetOfferPricesDto extends IPricesDto {
  products: IOfferPriceProduct[];
  defaultInstallationPrice: number;
  setPricesManually: boolean;
  displaySettings: IPriceDisplaySettings;
}

export function useGetOfferPrices(offerId: string) {
  const { isLoading, response, get } = useApiGet<ISetOfferPricesDto>(`/api/sales/offer/${offerId}/prices/`, false);

  return {
    isLoading,
    response,
    get,
  };
}

export interface IUpdateOfferPricesCommand {
  installationPrice?: number;
  totalInstallationTime?: number;
  totalInstallationCost?: number;
  materialCost?: number;
  otherCost: number;
  totalPrice?: number;
  firstInstallationCost: number;
  rentPrice?: number;
  displaySettings: IPriceDisplaySettings;
  setManually: boolean; //// in back-end command validator, check that prices are not null if true.
}

export interface IUpdateOfferPricesCommandValidationErrors
  extends ValidationErrorResultType<IUpdateOfferPricesCommand> {}

export function useUpdateOfferPrices(offerId: string) {
  const { isLoading, put } = useApiPut<
    ICreateResult<IUpdateOfferPricesCommandValidationErrors>,
    IUpdateOfferPricesCommand
  >(`/api/sales/offer/${offerId}/prices/`);

  return {
    isLoading,
    put,
  };
}

//SetAgreement
export interface IOfferAgreement {
  monthlyCost: number | null;
  startDate: Date;
  endDate: Date | null;
  emergencyCallCenterSupplierId: number | undefined;
  securityCompanySupplierId: number | undefined;
  measure: Measure | undefined;
  service: Service | undefined;
  auditInterval: AuditInterval | undefined;
  invoiceInterval?: InvoiceInterval | undefined;
  rentType: RentType | undefined;
  transferType: TransferType | undefined;
  transferInterval: TransferInterval | undefined;
  includeInnerControl: boolean;
}

export interface ISetOfferAgreementDto {
  offerAgreement?: IOfferAgreement;
}

export function useGetOfferAgreement(offerId: string | undefined) {
  const { isLoading, response, get } = useApiGet<ISetOfferAgreementDto>(
    `/api/sales/offer/${offerId}/agreement/`,
    false,
  );

  return {
    isLoading,
    response,
    get,
  };
}

export interface IUpdateOfferAgreementCommand {
  addAgreement: boolean;
  agreement?: IOfferAgreement;
}

export interface IUpdateOfferAgreementCommandValidationErrors
  extends ValidationErrorResultType<IUpdateOfferAgreementCommand> {}

export function useUpdateOfferAgreement(offerId: string) {
  const { isLoading, put } = useApiPut<
    ICreateResult<IUpdateOfferAgreementCommandValidationErrors>,
    IUpdateOfferAgreementCommand
  >(`/api/sales/offer/${offerId}/agreement/`);

  return {
    isLoading,
    put,
  };
}

//SetSaleTerms Step
export interface ISetOfferTermsDto extends IUpdateOfferTermsCommand {
  productRegulation: string;
}

export function useGetOfferTerms(offerId: string | undefined) {
  const { isLoading, response, get } = useApiGet<ISetOfferTermsDto>(`/api/sales/offer/${offerId}/terms/`, false);

  return {
    isLoading,
    response,
    get,
  };
}

export interface IUpdateOfferTermsCommand {
  offerDescription: string;
  terms: ISaleTerms;
  customerCanApprove: boolean;
}

export interface IUpdateOfferTermsCommandValidationErrors extends ValidationErrorResultType<IUpdateOfferTermsCommand> {}

export function useUpdateOfferTerms(offerId: string) {
  const { isLoading, put } = useApiPut<
    ICreateResult<IUpdateOfferTermsCommandValidationErrors>,
    IUpdateOfferTermsCommand
  >(`/api/sales/offer/${offerId}/terms/`);

  return {
    isLoading,
    put,
  };
}

//Summary Step
export interface IOfferSummary {
  customer: {
    id: string;
    name: string;
    identificationNumber: string;
    type: CustomerType;
    creditCheck?: ICreditCheckDto;
    contactPerson?: {
      name: string;
      email: string;
      phoneNumber: string;
      position: string;
    };
  };
  projectLeader: string;
  salesPerson: string;
  projector: string;
  description: string;
  offerDescription: string;
  system?: {
    name?: string;
    systemType: string;
    regulation: string;
  };
  agreement?: {
    referencesNumber: string;
    monthlyCost: number;
    startDate: Date;
    endDate?: Date;
    emergencyCallCenter?: string;
    securityCompany?: string;
    measure?: Measure;
    service?: Service;
    auditInterval?: AuditInterval;
    invoiceInterval?: InvoiceInterval;
    rentType?: RentType;
    transferType?: TransferType;
    transferInterval?: TransferInterval;
    includeInnerControl: boolean;
  };
  terms?: {
    including: string;
    excluding: string;
    regulation: string;
    warranty: string;
    delivery: string;
    validPeriod: string;
  };
  canBeApprovedByCustomer: boolean;
}

export function useGetOfferSummary(offerId: string) {
  const { isLoading, response, get } = useApiGet<IOfferSummary>(`/api/sales/offer/${offerId}/summary/`, false);

  return {
    isLoading,
    response,
    get,
  };
}

export interface IPublishOfferDraftCommand {
  sendToCustomer: boolean;
}

export function usePublishOfferDraft(offerId: string) {
  const { isLoading, put } = useApiPut<ICreateResult<number>, IPublishOfferDraftCommand>(
    `/api/sales/offer/${offerId}/publish`,
  ); //Check response type

  return {
    isLoading,
    put,
  };
}

/******VIEW OFFER*******/

export const pdfSize = 21 - 3.4;

export function useGetOffer() {
  const { isLoading, sale, getSale } = useGetSale();

  return {
    isLoading,
    offer: sale,
    getOffer: getSale,
  };
}

export function useGetOfferPdf() {
  const { isLoading, response, get } = useApiGet<IFileResult>('/api/sales/offer/', false);

  const getOfferPdf = useCallback(
    (saleId: string) => {
      return get(`${saleId}/pdf`);
    },
    [get],
  );

  return {
    isLoading,
    offerPdf: response,
    getOfferPdf,
  };
}

export function useRevertOfferConfirmation() {
  const { isLoading, put } = useApiPut<IApproveOfferErrors>('/api/sales/offer/');

  const editOfferConfirmation = useCallback(
    (offerId: string) => {
      return put(undefined, offerId + '/revertconfirmation');
    },
    [put],
  );

  return {
    isLoading,
    editOfferConfirmation,
  };
}

export interface IApproveOfferCommand {
  invoiceReference?: string;
}

export interface IApproveOfferErrors extends ValidationErrorResultType<IApproveOfferCommand> {}

export function useApproveOffer() {
  const { isLoading, response, post } = useApiPost<IApproveOfferErrors, IApproveOfferCommand>('/api/sales/offer/');

  const approveOffer = useCallback(
    (offerId: string, command: IApproveOfferCommand) => {
      return post(command, offerId + '/approve');
    },
    [post],
  );

  return {
    isLoading,
    response,
    approveOffer,
  };
}

export interface INotifyCustomerCommand {
  saleId: number;
  currentAction: Action;
}

export interface INotifyCustomerErrors extends ValidationErrorResultType<INotifyCustomerCommand> {}

export function useNotifyCustomer(isAnonymous: boolean = false) {
  const { post } = useApiPost<INotifyCustomerErrors, INotifyCustomerCommand>(
    `/api/${isAnonymous ? 'anonymous/offers' : 'sales'}`,
  );

  const notifyCustomer = useCallback(
    (command: INotifyCustomerCommand) => {
      return post(command, '/notifyCustomer');
    },
    [post],
  );

  return {
    notifyCustomer,
  };
}

export function useSendOfferToCustomer() {
  const { isLoading, post } = useApiPost('/api/sales/offer/');

  const sendOfferToCustomer = useCallback(
    (offerId: string) => {
      return post(undefined, offerId + '/sendtocustomer');
    },
    [post],
  );

  return {
    isLoading,
    sendOfferToCustomer,
  };
}

export interface ISendSaleToRecipientsCommand {
  recipients: string[];
  message?: string;
}
export interface ISendSaleToRecipientsCommandValidationErrors
  extends ValidationErrorResultType<ISendSaleToRecipientsCommand> {}

export function useSendOfferToRecipients() {
  const { isLoading, post } = useApiPost<ISendSaleToRecipientsCommandValidationErrors, ISendSaleToRecipientsCommand>(
    '/api/sales/offer/',
  );

  const sendOfferToRecipients = useCallback(
    (offerId: string, data: ISendSaleToRecipientsCommand) => {
      return post(data, offerId + '/sendtorecipients');
    },
    [post],
  );

  return {
    isLoading,
    sendOfferToRecipients,
  };
}
