import React, { useState } from 'react';
import { IAuditListItem } from '../../Audit.api';
import { useHistory } from 'react-router';
import { MenuItem, Typography } from '@material-ui/core';
import SetNextAuditCalendar from './SetNextAuditCalendar/SetNextAuditCalendar';
import { DropdownCell } from '../../../../../Shared/Table/DropdownCell/DropdownCell';
import { SaleType } from '../../../../Shared/SaleType';

type ISetNextAuditMenuProps = {
  auditInfo: IAuditListItem;
  mobileView: boolean;
  loadList?: () => void;
};

export default function ContractlessMenu(props: ISetNextAuditMenuProps) {
  const { auditInfo, mobileView, loadList } = props;
  const history = useHistory();
  const [open, setOpen] = useState(false);

  return (
    <>
      <DropdownCell mobile={mobileView}>
        <MenuItem
          autoFocus
          onClick={() =>
            history.push({
              pathname: '/errands/offers/create',
              state: {
                customerId: auditInfo.customerId,
                offer: {
                  facilityId: Number(auditInfo.facilityId),
                  coreSystemId: auditInfo.coreSystemId,
                },
                type: SaleType.deal,
              },
            })
          }
        >
          <Typography variant="inherit">Skapa offert</Typography>
        </MenuItem>
        <MenuItem onClick={() => setOpen(true)}>
          <Typography variant="inherit">Sätt uppföljningsdatum</Typography>
        </MenuItem>
      </DropdownCell>
      <SetNextAuditCalendar auditInfo={auditInfo} open={open} setOpen={setOpen} loadList={loadList} />
    </>
  );
}
