import { useApiPost, useApiGet, useApiPut } from '../../../Core/Api/Api';
import { ICreateResult } from '../../../Shared/Api/response/ICreateResult';
import ValidationErrorResultType from '../../../Shared/Api/response/ValidationErrorResultType';
import IPaginatedResponse from '../../../Shared/Api/response/IPaginatedResponse';
import IPaginatedRequest from '../../../Shared/Api/request/IPaginatedRequest';

export interface ICompanyDto {
  id: number;

  name: string;
  identificationNumber: string;
  email: string;
  phoneNumber: string;

  address: string;
  postalCode: string;
  city: string;
}

export function useGetCompany() {
  const { isLoading, response, get } = useApiGet<ICompanyDto>('/api/companies/', false);

  return {
    isLoading,
    response,
    getCompany: get,
  };
}

export interface IUpsertCompanyCommand {
  name: string;
  identificationNumber: string;
  email: string;
  phoneNumber: string;

  address: string;
  postalCode: string;
  city: string;
}

export interface IUpsertCompanyValidationErrors extends ValidationErrorResultType<IUpsertCompanyCommand> {}
export function useUpsertCompany() {
  const { isLoading: isCreateLoading, post } = useApiPost<
    ICreateResult<IUpsertCompanyValidationErrors>,
    IUpsertCompanyCommand
  >('/api/companies');

  const { isLoading: isUpdateLoading, put } = useApiPut<IUpsertCompanyValidationErrors, IUpsertCompanyCommand>(
    '/api/companies/',
  );

  async function upsert(command: IUpsertCompanyCommand, id?: string) {
    if (id) {
      const result = await put(command, id);
      return result ? result : id;
    }

    return post(command);
  }

  return {
    isLoading: isCreateLoading || isUpdateLoading,
    upsert: upsert,
  };
}

export interface ICompanyListItem {
  id: number;
  name: string;
  logo: string;
  identificationNumber: string;
}

export function useGetCompanies() {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<ICompanyListItem>, IPaginatedRequest>(
    '/api/companies/list',
  );

  return {
    isLoading,
    response,
    getCompanies: post,
  };
}

export function useGetAllowedEmail() {
  const { isLoading, response, get } = useApiGet<boolean>(`/api/companies/allowedEmail/`, false);

  return {
    isLoading,
    response,
    getIsEmailAllowed: get,
  };
}
