import React, { useState } from 'react';
import Container from '../../../../../../../Shared/Container/Container';
import Button from '../../../../../../../Shared/Button/Button';
import { useHistory, useParams } from 'react-router-dom';
import {
  useGetSupplierOrderForm,
  ICreateSupplierOrdersValidationErrors,
  useCreateSupplierOrders,
  ISupplierMessage,
  ICreateSupplierOrdersCommand,
} from '../../Order.api';
import useFormValidation from '../../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import { Fieldset } from '../../../../../../../Shared/Form/Fieldset/Fieldset';
import { InputField } from '../../../../../../../Shared/InputFields/InputField/InputField';
import Datepicker from '../../../../../../../Shared/InputFields/DatePicker/Datepicker';
import { Table } from '../../../../../../../Shared/Table/Table';
import { TableBody, TableRow, TableCell, Typography, TableHead } from '@material-ui/core';
import { isResultError } from '../../../../../../../Shared/Api/response/ICreateResult';
import { newTzDate } from '../../../../../../../Shared/Formatting/formatDate';

const minDate = newTzDate(new Date());

const CreateSupplierOrdersForm = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { isLoading, response } = useGetSupplierOrderForm(id);
  const { isCreateLoading, create } = useCreateSupplierOrders(id);

  const { isFormValid, formRef } = useFormValidation();

  const [preferredDeliveryDate, setPreferredDeliveryDate] = useState<Date | null>(minDate);
  const [supplierMessages, setSupplierMessages] = useState<{ [key: string]: ISupplierMessage }>({});

  const [errors, setErrors] = useState<ICreateSupplierOrdersValidationErrors>();

  async function createSupplierOrders() {
    if (!preferredDeliveryDate) return;
    let suppliers: ISupplierMessage[] = [];

    Object.keys(supplierMessages).forEach((key: string) => {
      suppliers.push(supplierMessages[key]);
    });

    const command: ICreateSupplierOrdersCommand = {
      preferredDeliveryDate: preferredDeliveryDate.toLocaleDateString(),
      suppliers: suppliers,
    };

    const result = await create(command);

    isResultError(result) ? setErrors(result) : history.push(`/errands/orders/${id}`);
  }

  function changeSupplierMessages(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    supplierId: number,
  ) {
    let messages: { [key: string]: ISupplierMessage } = {};
    messages[supplierId] = {
      supplierId: supplierId,
      message: event.target.value,
    };

    setSupplierMessages((prev) => ({ ...prev, ...messages }));
  }

  function Actions() {
    const cancel = () => history.push(`/errands/orders/${id}`);

    return (
      <>
        <Button color="default" variant="outlined" onClick={cancel}>
          Avbryt
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={createSupplierOrders}
          loading={isCreateLoading}
          disabled={!isFormValid}
        >
          Skapa
        </Button>
      </>
    );
  }

  return (
    <Container form label="Skapa beställning" actions={<Actions />} ref={formRef} loading={isLoading}>
      <Fieldset legend="Information">
        <InputField label="Order Nr" value={response?.saleIdentifier} viewOnly />
        <InputField label="Kund" value={response?.customerName} viewOnly />
        <Datepicker
          label="Önskat leveransdatum"
          selected={preferredDeliveryDate}
          onChange={setPreferredDeliveryDate}
          minDate={minDate}
          required
          errorText={errors?.preferredDeliveryDate}
        />
      </Fieldset>
      <Fieldset legend="Produkter">
        {response?.suppliers.map((x) => (
          <Container key={x.id} customSize={{ fullsize: true }}>
            <h3>{x.name}</h3>
            <InputField
              label="Meddelande"
              value={supplierMessages[x.id]?.message}
              onChange={(event) => changeSupplierMessages(event, x.id)}
              style={{ marginBottom: '10px' }}
            />
            <Table customSize={{ fullsize: true }}>
              <TableHead>
                <TableRow>
                  <TableCell>Namn</TableCell>
                  <TableCell>Antal</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {x.products.map((product) => (
                  <TableRow key={product.id}>
                    <TableCell>
                      <Typography>{product.name}</Typography>
                    </TableCell>
                    <TableCell align="right">{product.quantity}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Container>
        ))}
      </Fieldset>
    </Container>
  );
};

export default CreateSupplierOrdersForm;
