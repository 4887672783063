import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

  focusNode: {
    backgroundColor: theme.palette.grey[200],
  },
  notFocusNode: {
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    marginBottom: '0.75rem',
    '&:last-child': {
      marginBottom: 0,
    },
    listStyleType: 'none',
  },
  empty: {
    minHeight: '15rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyMessage: {
    marginBottom: '1rem',
    marginLeft: '1.25rem',
  },
  nodeName: {
    '& > *': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginRight: '5px',
    },
  },

  nodeIcon: {
    padding: 0,
  },
}));

export default useStyles;
