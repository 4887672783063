import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isResultError } from '../../../../../../Shared/Api/response/ICreateResult';
import Button from '../../../../../../Shared/Button/Button';
import Container from '../../../../../../Shared/Container/Container';
import useFormValidation from '../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import useInputState from '../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import { ISelectOption, Select } from '../../../../../../Shared/Select';
import CustomerFacilitySelect from '../../../../Customers/Components/CustomerFacilitySelect/CustomerFacilitySelect';
import { ICoreSystem, useGetFacility } from '../../../../Customers/Components/Facilities/Facilities.api';
import CustomerInfoDisplay from '../../../../Customers/Components/ViewCustomer/CustomerInfo/CustomerInfoDisplay';
import { useGetContactList } from '../../../../Customers/Customers.api';
import { ICreateServiceSaleCommandValidationErrors, useCreateServiceSale } from '../Order.api';

interface IOrderForm {
  location: string;
}
const OrderForm = (props: IOrderForm) => {
  const history = useHistory();
  const { location } = props;
  const { isFormValid, formRef } = useFormValidation();
  const { create, isCreateLoading } = useCreateServiceSale();

  const [selectedCustomerId, setSelectedCustomerId] = useState<string>();
  const [selectedContactId, setSelectedContactId] = useState<string>();
  const [contactOptions, setContactOptions] = useState<ISelectOption[]>();
  const [selectedFacilityId, setSelectedFacilityId] = useState<string>();
  const [selectedCoreSystemId, setSelectedCoreSystemId] = useState<string>();
  const [coreSystems, setCoreSystems] = useState<ICoreSystem[]>();
  const [jobDescription, changeJobDescription] = useInputState('');
  const [description, changeDescription] = useInputState('');
  const [totalPrice, changeTotalPrice] = useInputState(0);
  const [errors, setErrors] = useState<ICreateServiceSaleCommandValidationErrors>();

  const { getFacility, isLoading: isGetFacilityLoading } = useGetFacility(selectedCustomerId);
  const { getContactList, isLoading: contactsLoading } = useGetContactList(selectedCustomerId);

  const isValid = isFormValid && selectedCustomerId && selectedFacilityId;

  const coreSystemOptions = useMemo(() => {
    let options: ISelectOption[] = [];
    if (coreSystems) options = [...coreSystems.map((x) => ({ value: x.id.toString(), label: x.description + " - " + x.communication?.transmitter }))];
    return options;
  }, [coreSystems]);

  useEffect(() => {
    async function loadCoreSystem() {
      if (selectedFacilityId) {
        const response = await getFacility(selectedFacilityId.toString());
        if (response) setCoreSystems(response.coreSystems);
      } else {
        setCoreSystems(undefined);
      }
    }
    loadCoreSystem();
  }, [selectedFacilityId, getFacility]);

  useEffect(() => {
    async function loadContactList() {
      if (selectedCustomerId) {
        var response = await getContactList();
        setContactOptions([
          ...response.map((x) => ({ value: x.id.toString(), label: x.firstname + ' ' + x.lastname + ' ('+ x.position +')' })),
        ]);
      } else setContactOptions([]);
    }
    loadContactList();
  }, [selectedCustomerId, getContactList]);

  async function handleCreate() {
    const result = await create({
      customerId: Number(selectedCustomerId),
      customerContactId: selectedContactId ? Number(selectedContactId) : undefined,
      facilityId: Number(selectedFacilityId),
      coreSystemId: selectedCoreSystemId ? Number(selectedCoreSystemId) : undefined,
      totalPrice,
      jobDescription,
      description
    });

    if (isResultError(result)) {
      setErrors(result);
    }
    history.push(`${location}/${result}`);
  }

  return (
    <>
      <Container
        form
        ref={formRef}
        label={'Ny order'}
        customSize={{xs: 12, md: 6}}
        actions={
          <>
            <Button color="default" variant="outlined" onClick={() => history.push(location)}>
              Avbryt
            </Button>
            <Button
              loading={isCreateLoading}
              disabled={!isValid}
              color="primary"
              variant="contained"
              onClick={handleCreate}
            >
              Skapa
            </Button>
          </>
        }
      >
        <CustomerFacilitySelect
          required
          requireSystem
          fullWidth
          onChange={(customer) => {
            setSelectedCustomerId(customer ? customer.id.toString() : undefined);
            setSelectedFacilityId(customer ? customer.facilityId.toString() : undefined);
          }}
        />

        <Select
          inputFieldProps={{ label: 'System', required: false }}
          selectProps={{
            isClearable: true,
            value: coreSystemOptions.find((x) => x.value === selectedCoreSystemId?.toString()) || null,
            isLoading: isGetFacilityLoading,
            isDisabled: !coreSystemOptions.length,
            options: coreSystemOptions,
            onChange: (selected) => setSelectedCoreSystemId(selected! ? (selected as ISelectOption).value : undefined),
          }}
        />
        <InputField
          label="Totalt pris"
          type="number"
          value={totalPrice}
          onChange={changeTotalPrice}
          required
          errorText={errors?.totalPrice}
        />
        <Select
          inputFieldProps={{ label: 'Välj kontakt' }}
          selectProps={{
            value: (selectedContactId && contactOptions?.find((x) => x.value === selectedContactId)) || null,
            isDisabled: !!(contactOptions?.length === 0),
            isLoading: contactsLoading,
            onChange: (selected) => {
              setSelectedContactId(selected ? (selected as ISelectOption).value : undefined);
            },
            options: contactOptions,
          }}
        />

        <InputField
          label="Uppdragsbeskrivning"
          type="text"
          value={jobDescription}
          onChange={changeJobDescription}
          multiline
          rows={5}
          required
          fullwidth
          errorText={errors?.jobDescription}
        />
        <InputField
          label="Rubrik"
          type="text"
          value={description}
          onChange={changeDescription}
          fullwidth
        />
      </Container>
      {selectedCustomerId && <CustomerInfoDisplay customerId={selectedCustomerId} />}
    </>
  );
};

export default OrderForm;
