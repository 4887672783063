import React, { useMemo, useState } from 'react';
import { TableBody, TableCell, TableRow, useMediaQuery } from '@material-ui/core';
import theme from '../../Styles/Theme';
import { Table } from '../../Shared/Table/Table';
import { UserType } from '../Modules/Admin/Components/Users/Users.api';
import useUserContext from '../../Core/Authentication/UserContext';
import { MobileTableRow } from '../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import UserTypeContainer from './UserTypeContainer/UserTypeContainer';
import { useStyles } from './MultiTypeLogin.styles';
import { useHistory } from 'react-router-dom';
import { ISelectItem } from '../../Shared/Table/SelectTable/SelectTable';
import SelectCollapseCell from '../../Shared/Table/SelectCellList/SelectCollapseCell';
import { groupBy } from '../Shared/utils';

interface ISelectImpersonatedUser extends ISelectItem {
  userType: UserType;
}

const MultiTypeLogin = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user, setSession } = useUserContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedUserType, setSelectedUserType] = useState<UserType>();

  const availableLoginOption = useMemo(() => {
    const loginOptions: UserType[] = [];
    //NOTE: order used here is order used in getAllSessions function in UserContext
    if (user.sessions.find((x) => x.userType === UserType.CompanyUser)) loginOptions.push(UserType.CompanyUser);

    //TODO: implement everything need to be able to login as a customer
    //if (user.sessions.find((x) => x.userType === UserType.Customer)) loginOptions.push(UserType.Customer);
    if (user.sessions.find((x) => x.userType === UserType.SuperAdmin)) loginOptions.push(UserType.SuperAdmin);

    if (loginOptions.length > 0) {
      setSelectedUserType(loginOptions[0]);
    }

    return loginOptions;
  }, [user.sessions]);

  const impersonateList = groupBy(
    user.sessions?.filter((x) => x.userType === selectedUserType && x.userId),
    (x) => x.companyName ?? '',
  );

  const handleSelectUserType = (userType: UserType) => {
    if (user.sessions?.filter((x) => x.userType === userType && x.userId).length === 0) setSession({ userType });
    else setSelectedUserType(userType !== selectedUserType ? userType : undefined);
  };

  const handleSession = (userType: UserType, userId?: number) => {
    localStorage.setItem('USER_TYPE', JSON.stringify(userType));
    localStorage.setItem('USER_ID', userId?.toString() ?? '');
    setSession({ userType, userId });
    history.push('');
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {availableLoginOption.map((userType) => (
          <UserTypeContainer
            key={userType}
            isMobile={mobileView}
            userType={userType}
            handleSession={handleSelectUserType}
          />
        ))}
      </div>
      {selectedUserType && (
        <div className={classes.table}>
          <Table
            key={selectedUserType}
            customSize={{ fullsize: true }}
            label={`Logga in som ${selectedUserType === UserType.CompanyUser ? 'företagsanvändare' : 'kund'}`}
          >
            {mobileView ? (
              <Table>
                {user.sessions
                  ?.filter((x) => x.userType === selectedUserType)
                  .sort((a, b) => {
                    if (a.companyName && b.companyName) {
                      return a.companyName > b.companyName ? 1 : -1;
                    }
                    return 0;
                  })
                  .map((session) => (
                    <MobileTableRow
                      onClick={() => handleSession(selectedUserType, session.userId)}
                      label={`${
                        session.userType === UserType.SuperAdmin
                          ? session.userName
                            ? session.userName + ' (Impersonifiera)'
                            : 'Gå till administratörsidan'
                          : session.companyName
                      }`}
                      value={
                        session.userType === UserType.SuperAdmin
                          ? session.companyName && session.companyName
                          : session.userType === UserType.CompanyUser
                          ? 'Företagsanvändare'
                          : 'kund'
                      }
                    />
                  ))}
              </Table>
            ) : (
              <TableBody>
                {selectedUserType === UserType.SuperAdmin ? (
                  <>
                    <TableRow className={classes.pointerCursor} hover tabIndex={-1}>
                      <>
                        <TableCell
                          onClick={() => {
                            handleSession(selectedUserType);
                          }}
                        >
                          Gå till administratörsidan
                        </TableCell>
                      </>
                    </TableRow>
                    {Object.entries(impersonateList).map((grouped) => (
                      <SelectCollapseCell<ISelectImpersonatedUser>
                        items={grouped[1].map((session) => {
                          return {
                            label: session.userName ?? '',
                            value: session.userId?.toString() ?? '',
                            userType: session.userType,
                          };
                        })}
                        onItemSelect={(x) => handleSession(x.userType, Number(x.value))}
                        header={grouped[0] + ' (Impersonering)'}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {user.sessions
                      ?.filter((x) => x.userType === selectedUserType)
                      .map((session) => (
                        <TableRow className={classes.pointerCursor} hover tabIndex={-1}>
                          <>
                            <TableCell
                              onClick={() => {
                                handleSession(selectedUserType, session.userId);
                              }}
                            >
                              {session.companyName} (
                              {
                                user.sessions.filter(
                                  (y) => y.companyName === session.companyName && y.userType === UserType.SuperAdmin,
                                ).length
                              }
                              )
                            </TableCell>
                          </>
                        </TableRow>
                      ))}
                  </>
                )}
              </TableBody>
            )}
          </Table>
        </div>
      )}
    </div>
  );
};

export default MultiTypeLogin;
