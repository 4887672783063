import React from 'react';
import { Grid, GridJustification, GridSize } from '@material-ui/core';
import Button, { IButtonProps } from './Button';
import useStyles from './Button.styles';

interface IInlineButtonProps extends IButtonProps {
  fullwidth?: boolean;
  customWidth?: GridSize;
  justify?: GridJustification;
  slim?: boolean;
}

const InlineButton: React.FunctionComponent<IInlineButtonProps> = (props) => {
  const classes = useStyles();
  const { fullwidth, customWidth, justify, slim, ...rest } = props;

  return (
    <Grid
      className={classes.buttonRow}
      item
      lg={fullwidth ? 12 : customWidth ?? 6}
      md={fullwidth ? 12 : customWidth ?? 6}
      sm={fullwidth ? 12 : customWidth ?? 6}
      xs={12}
      justify={justify ?? 'center'}
      style={{
        marginTop: slim ? '0' : '1.4rem',
        height: 'fit-content'
      }}
    >
      <Button {...rest} />
    </Grid>
  );
};

export default InlineButton;
