import React, { useEffect, useCallback, useState } from 'react';
import { TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core';
import { Table } from '../../../../../../Shared/Table/Table';
import SummaryRow from '../../../../../../Shared/Table/SummaryRow/SummaryRow';
import { useGetDashboard } from '../../SaleStats.api';
import DatePicker from '../../../../../../Shared/InputFields/DatePicker/Datepicker';
import formatPrice from '../../../../../../Shared/Formatting/formatPrice';
import moment from 'moment';
import useStyles from './Overview.styles';
import { ISaleListItem, SaleStatusLabel, useGetSalesFromQuery } from '../../../../Errands/Components/Sale.api';
import { formatDateWithTime } from '../../../../../../Shared/Formatting/formatDate';

const monthStart = moment().startOf('month').toDate();
const Overview = () => {
  const classes = useStyles();
  const { isLoading, dashboard, getDashboard } = useGetDashboard();
  const { getSalesFromSearch } = useGetSalesFromQuery();
  const [ userOrders, setUserOrders] = useState<ISaleListItem[]>([]);
  const [ userOffers, setUserOffers] = useState<ISaleListItem[]>([]);
  const [chosenUserOfferIds, setChosenUserOfferIds] = useState<number[]>([]);
  const [chosenUserOrderIds, setChosenUserOrderIds] = useState<number[]>([]);
  const [date, setDate] = useState(monthStart);

  useEffect(() => {
    getDashboard({ date: date.toLocaleDateString() });
  }, [getDashboard, date]);

  const getList = useCallback(async (type: string, ids: number[]) => { 
    if (type === 'offers' && userOffers.length === 0) {
      const response = await getSalesFromSearch({ saleIds: ids})
      if (response !== undefined) {
        setUserOffers(response);
      }
    }
    if (type === 'orders' &&  userOrders.length === 0) {
      const response = await getSalesFromSearch({ saleIds: ids})
      if (response !== undefined) {
        setUserOrders(response);
      }
    }
  }, [getSalesFromSearch]);
  
  useEffect(() => {
    if (chosenUserOfferIds.length > 0){
      getList('offers', chosenUserOfferIds);
    } else setUserOffers([]);
    if (chosenUserOrderIds.length > 0){
      getList('orders', chosenUserOrderIds);
    } else setUserOrders([]);
  }, [chosenUserOfferIds, chosenUserOrderIds, getList]);

  return (
    <div className={classes.root}>
      <div className={classes.filter}>
        <DatePicker
          label="År/månad"
          required
          selected={date}
          onChange={(d: Date | null) => setDate(d as Date)}
          dateFormat="MM/yyyy"
          showMonthYearPicker
        />
      </div>
      <div className={classes.container}>
        <Typography id="orders-summary" component="h2" variant="h4" className={`${classes.label} ${classes.qoutation}`}>
          Ordrar
        </Typography>
        <Table customSize={{ fullsize: true }} loading={{ loading: isLoading }} aria-labelledby="orders-summary" className={classes.statTable}>
          <TableHead>
            <TableRow>
              <TableCell>Namn</TableCell>
              <TableCell>Totala ordrar</TableCell>
              <TableCell>Ordervärde</TableCell>
              <TableCell>Totala avtal</TableCell>
              <TableCell>Avtalsvärde</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dashboard?.orders.map((x) => (
              <>
                <TableRow key={x.companyUserId} style={{cursor: 'pointer'}} onClick={() => setChosenUserOrderIds(chosenUserOrderIds.length > 0 ? [] : x.orderIds)}>
                  <TableCell>{x.name}</TableCell>
                  <TableCell>{x.orderCount}</TableCell>
                  <TableCell>{formatPrice(x.amount)}</TableCell>
                  <TableCell>{x.agreementCount}</TableCell>
                  <TableCell>{formatPrice(x.agreementAmount)}</TableCell>
                </TableRow>              
                {userOrders.length > 0 && chosenUserOrderIds === x.orderIds &&  (
                  <>
                    <TableRow className={`${classes.userOfferOrderRows} ${classes.header}`}>
                      <TableCell>--</TableCell>
                      <TableCell>OrderId</TableCell>
                      <TableCell>Kund</TableCell>
                      <TableCell>Skapat</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                    {userOrders?.map((y) => (
                    <TableRow key={y.id}  className={classes.userOfferOrderRows}>
                      <TableCell>--</TableCell>
                      <TableCell>{y.id}</TableCell>
                      <TableCell>{y.customerName}</TableCell>
                      <TableCell>{formatDateWithTime(y.createdDate)}</TableCell>
                      <TableCell>{SaleStatusLabel[y.status]}</TableCell>
                    </TableRow>
                    ))}
                  </>
                )}
              </>
            ))}
            <SummaryRow>
              <TableCell>Summa {date.toLocaleString('default', { month: 'long'})}</TableCell>
              <TableCell>{dashboard?.saleTotal.orderCount}</TableCell>
              <TableCell>{formatPrice(dashboard?.saleTotal.amount)}</TableCell>
              <TableCell>{dashboard?.saleTotal.agreementCount}</TableCell>
              <TableCell>{formatPrice(dashboard?.saleTotal.agreementAmount)}</TableCell>
            </SummaryRow>
            <SummaryRow>
              <TableCell>Summa {date.getFullYear()}</TableCell>
              <TableCell>{dashboard?.saleTotalCurrentYear.orderCount}</TableCell>
              <TableCell>{formatPrice(dashboard?.saleTotalCurrentYear.amount)}</TableCell>
              <TableCell>{dashboard?.saleTotalCurrentYear.agreementCount}</TableCell>
              <TableCell>{formatPrice(dashboard?.saleTotalCurrentYear.agreementAmount)}</TableCell>
            </SummaryRow>
          </TableBody>
        </Table>
        <br />
        <Typography id="offer-summary" component="h2" variant="h4" className={`${classes.label} ${classes.qoutation}`}>
          Offerter
        </Typography>
        <Table aria-labelledby="offer-summary" loading={{ loading: isLoading }}  customSize={{ fullsize: true }}  className={classes.statTable}>
          <TableHead>
            <TableRow>
              <TableCell>Namn</TableCell>              
              <TableCell>Totala offerter</TableCell>
              <TableCell>Offertvärde</TableCell>
              <TableCell>Totala avtal</TableCell>
              <TableCell>Avtalsvärde</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dashboard?.offers.map((x) => (
              <>
                <TableRow key={x.companyUserId} style={{cursor: 'pointer'}} onClick={() => setChosenUserOfferIds(chosenUserOfferIds.length > 0 ? [] : x.offerIds)}>
                  <TableCell>{x.name}</TableCell>
                  <TableCell>{x.offerCount}</TableCell>
                  <TableCell>{formatPrice(x.offerAmount)}</TableCell>
                  <TableCell>{x.offerAgreementCount}</TableCell>
                  <TableCell>{formatPrice(x.offerAgreementSum)}</TableCell>
                </TableRow>
                {userOffers.length > 0 && chosenUserOfferIds === x.offerIds &&  (
                  <>
                    <TableRow className={`${classes.userOfferOrderRows} ${classes.header}`}>
                      <TableCell>--</TableCell>
                      <TableCell>OffertId</TableCell>
                      <TableCell>Kund</TableCell>
                      <TableCell>Skapat</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                    {userOffers?.map((y) => (
                    <TableRow key={y.id}  className={classes.userOfferOrderRows}>
                      <TableCell>--</TableCell>
                      <TableCell>{y.id}</TableCell>
                      <TableCell>{y.customerName}</TableCell>
                      <TableCell>{formatDateWithTime(y.createdDate)}</TableCell>
                      <TableCell>{SaleStatusLabel[y.status]}</TableCell>
                    </TableRow>
                    ))}
                  </>
                )}
              </>
            ))}
            <SummaryRow>
              <TableCell>Summa  {date.toLocaleString('default', { month: 'long'})}</TableCell>              
              <TableCell>{dashboard?.offerTotal.offerCount}</TableCell>
              <TableCell>{formatPrice(dashboard?.offerTotal.offerAmount)}</TableCell>
              <TableCell>{dashboard?.offerTotal.offerAgreementCount}</TableCell>
              <TableCell>{formatPrice(dashboard?.offerTotal.offerAgreementSum)}</TableCell>
            </SummaryRow>
            <SummaryRow>
              <TableCell>Summa {date.getFullYear()}</TableCell>                            
              <TableCell>{dashboard?.offerTotalCurrentYear.offerCount}</TableCell>
              <TableCell>{formatPrice(dashboard?.offerTotalCurrentYear.offerAmount)}</TableCell>
              <TableCell>{dashboard?.offerTotalCurrentYear.offerAgreementCount}</TableCell>
              <TableCell>{formatPrice(dashboard?.offerTotalCurrentYear.offerAgreementSum)}</TableCell>
            </SummaryRow>
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default Overview;
