import { useApiPost, useApiGet, useApiPut, useApiDelete } from '../../../Core/Api/Api';
import ValidationErrorResultType from '../../../Shared/Api/response/ValidationErrorResultType';
import { ICreateResult } from '../../../Shared/Api/response/ICreateResult';
import IPaginatedResponse from '../../../Shared/Api/response/IPaginatedResponse';
import IPaginatedRequest from '../../../Shared/Api/request/IPaginatedRequest';
import ISearchRequest from '../../../Shared/Api/request/ISearchRequest';
import { SaleType } from '../../Shared/SaleType';
import { CustomerType } from '../../../Shared/Misc/CustomerType';
import { ErrorCode } from '../../../Shared/Api/response/IErrorRespose';
import { useCallback } from 'react';
import {
  AuditInterval,
  InvoiceInterval,
  Measure,
  RentType,
  SaleStatus,
  Service,
  TransferInterval,
  TransferType,
} from '../Errands/Components/Sale.api';

export enum AutoCreditCheckInterval {
  OneMonth = 1,
  ThreeMonth = 3,
  SixMonth = 6,
  OneYear = 12,
}

export const autoCreditCheckIntervals = [
  { option: AutoCreditCheckInterval.OneMonth, text: 'Varje månad' },
  { option: AutoCreditCheckInterval.ThreeMonth, text: 'Var tredje månad' },
  { option: AutoCreditCheckInterval.SixMonth, text: 'Var sjätte månad' },
  { option: AutoCreditCheckInterval.OneYear, text: 'Varje år' },
];
export interface ICustomerDto {
  id: number;
  name: string;
  customerNumber: number;
  identificationNumber: string;
  agreementInvoiceNumber: string;
  customerPhoneNumber: string;
  customerEmail: string;
  customerAddress: string;
  customerPostalCode: string;
  customerCity: string;
  invoiceReference: string;
  invoiceEmail: string;
  invoiceAddress: string;
  invoicePostalCode: string;
  invoiceCity: string;
  type: CustomerType;
  noContact: boolean;
  letterSerialNumber: number;
  facilities: IFacilityListItemDto[];
  creditCheck?: ICreditCheckDto;
  autoCreditCheckInterval?: AutoCreditCheckInterval;
}
export interface IFacilityListItemDto {
  id: number;
  name: string;
  address: string;
  postalCode: string;
  city: string;
  facilityNumber: number;
  agreementCount: number;
  hasCommunication: boolean;
  isArchived: boolean;
}

export interface ICustomerContact {
  id: number;
  invoiceReference: string;
  firstname: string;
  lastname: string;
  email: string;
  phoneNumber: string;
  mobilePhoneNumber: string | undefined;
  position: string | undefined;
  facilityIds: number[];
  facilityNames: string[];
  inviteAccepted: boolean;
}

export interface ISaleDto {
  id: number;
  type: SaleType;
  salesNumber: number;
  status: SaleStatus;
  created: Date;
  installDate: Date | null;
  description?: string;
  jobDescription?: string;
}

export enum CreditCheckOutcome {
  CreditGranted,
  CreditRejected,
}

export const creditCheckOutcomeText = [
  { value: CreditCheckOutcome.CreditGranted, label: 'Kredit beviljas' },
  { value: CreditCheckOutcome.CreditRejected, label: 'Endast förskottsbetalning' },
];
export interface ICreditCheckDto {
  status: CreditCheckOutcome;
  date: Date;
  documentId: number;
}

export function useGetCustomer() {
  const { isLoading, response, get } = useApiGet<ICustomerDto>('/api/customers/', false);

  return {
    isLoading,
    customer: response,
    getCustomer: get,
  };
}
export interface IGetProspectLegalDataRequest {
  identificationNumber: string;
  type: CustomerType;
}

export interface IProspectLegalDataDto {
  name: string;
  identificationNumber: string;
  invoiceEmail: string;
  invoiceAddress: string;
  invoicePostalCode: string;
  invoiceCity: string;
}

export function useGetProspectLegalData() {
  const { isLoading, response, post } = useApiPost<IProspectLegalDataDto, IGetProspectLegalDataRequest>(
    '/api/customers/prospect/data',
    false,
  );

  return {
    isLoading,
    prospectLegalData: response,
    getProspectLegalData: post,
  };
}
export interface IUpsertCustomerCommand {
  name: string;
  identificationNumber: string;
  customerPhoneNumber: string;
  customerEmail: string;
  customerAddress: string;
  customerPostalCode: string;
  customerCity: string;
  invoiceReference: string;
  invoiceEmail: string;
  invoiceAddress: string;
  invoicePostalCode: string;
  invoiceCity: string;
  type: CustomerType;
  noContact: boolean;
  doCreditCheck: boolean;
  autoCreditCheckInterval?: AutoCreditCheckInterval;
}

export function useGetContact(customerId: string | undefined) {
  const { isLoading, response, get } = useApiGet<ICustomerContact>(`/api/customers/${customerId}/contacts/`, false);

  return {
    isLoading,
    customer: response,
    getContact: get,
  };
}

interface IContactListRequest {
  facilityId?: string;
}

export function useGetContactList(customerId: string | undefined, facilityId?: string) {
  const { isLoading, response, post } = useApiPost<ICustomerContact[], IContactListRequest>(
    `/api/customers/${customerId}/contacts/list`,
    false,
  );

  const getContactList = useCallback(
    async (request?: IContactListRequest) => {
      return await post({ ...request, facilityId: facilityId });
    },
    [facilityId, post],
  );

  return {
    isLoading,
    contacts: response,
    getContactList,
  };
}

export function useDeleteContact(customerId: string | undefined) {
  const { isLoading, delete: deleteRequest } = useApiDelete(`/api/customers/${customerId}/contacts/`);

  function deleteContact(id: number) {
    return deleteRequest(undefined, id.toString());
  }

  return {
    isLoading,
    deleteContact,
  };
}

export function useDeleteFacilityContact(customerId: string) {
  const { isLoading, delete: deleteRequest } = useApiDelete(`/api/customers/${customerId}/contacts/`);

  function deleteFacilityContact(id: number, facilityId: string) {
    return deleteRequest(undefined, `${id.toString()}/${facilityId}`);
  }

  return {
    isLoading,
    deleteFacilityContact,
  };
}

export interface IUpsertCustomerValidationErrors extends ValidationErrorResultType<IUpsertCustomerCommand> {}

export function useUpsertCustomer() {
  const { isLoading: isCreateLoading, post } = useApiPost<
    ICreateResult<IUpsertCustomerValidationErrors>,
    IUpsertCustomerCommand
  >('/api/customers/');

  const { isLoading: isUpdateLoading, put } = useApiPut<IUpsertCustomerValidationErrors, IUpsertCustomerCommand>(
    '/api/customers/',
  );

  async function upsert(command: IUpsertCustomerCommand, id?: string) {
    if (id) {
      const result = await put(command, id);

      return result ? result : id;
    }

    return post(command);
  }

  return {
    isLoading: isCreateLoading || isUpdateLoading,
    upsert: upsert,
  };
}

export function useDeleteCustomer() {
  const { isLoading, delete: deleteRequest } = useApiDelete('/api/customers/');

  const deleteCustomer = useCallback(
    async (id: number) => {
      return await deleteRequest(undefined, id.toString());
    },
    [deleteRequest],
  );

  return {
    isDeleteLoading: isLoading,
    deleteCustomer,
  };
}

export interface IUpdateCustomerCreditCheckCommand {
  identificationNumber: string;
  type: CustomerType;
}

export interface IUpdateCustomerCreditCheckValidationErrors
  extends ValidationErrorResultType<IUpdateCustomerCreditCheckCommand> {}

export function useUpdateCustomerCreditCheck(customerId: string | undefined) {
  const { isLoading, post } = useApiPost<IUpdateCustomerCreditCheckValidationErrors, IUpdateCustomerCreditCheckCommand>(
    `/api/customers/${customerId}/creditcheck/`,
  );

  function makeCreditCheck(command: IUpdateCustomerCreditCheckCommand) {
    return post(command);
  }

  return {
    isLoading,
    makeCreditCheck,
  };
}
export interface ICustomerSearchRequest extends ISearchRequest {
  includeProspect?: boolean;
  customerIds?: number[];
}

export interface ICustomerWithAddress extends ICustomerListItem {
  address: string;
  city: string;
}
export interface ICustomerListItem {
  id: number;
  name: string;
  customerNumber: number;
  customerActivities: ICustomerActivities;
  customerFacilities: ICustomerFacility[];
  facilityCount: number;
}

export interface ICustomerActivities {
  numberOfActiveAgreements: number;
  numberOfFacilities?: number;
  numberOfCoreSystems?: number;
  numberOfUpComingAgreements: number;
}

export interface ICustomerFacility {
  facilityId: number;
  facilityName: string;
  facilityAddress: string;
  facilityCity: string;
  facilityNumber: string;
  internalNote?: string;
  coreSystems: ICoreSystemFacility[];
}

export interface ICoreSystemFacility {
  coreSystemId: number;
  coreSystemIdentifier: string;
  mainProductName: string;
  systemTypeName: string;
}

export function useGetCustomers() {
  const { isLoading, response, post } = useApiPost<
    IPaginatedResponse<ICustomerWithAddress>,
    ICustomerListSearchRequest
  >('/api/customers/list');

  return {
    isLoading,
    customers: response,
    getCustomers: post,
  };
}

export interface ICustomerWithFacility extends ICustomerListItem {
  facilityId: number | undefined;
  facilityName: string | undefined;
  address: string;
}

export function useSearchCustomers() {
  const { isLoading, response, post } = useApiPost<ICustomerListItem[], ICustomerSearchRequest>(
    '/api/customers/search',
    false,
  );

  return {
    isLoading,
    customers: response,
    searchCustomers: post,
  };
}

export interface IUpsertCustomerContactCommand {
  firstname: string;
  lastname: string;
  email: string;
  phoneNumber: string;
  mobilePhoneNumber: string | undefined;
  position: string | undefined;
  facilityIds: number[];
}

export interface IUpdateExistingContactCommand {
  position: string | undefined;
  facilityId: number;
}

export interface IUpsertCustomerContactValidationErrors
  extends ValidationErrorResultType<IUpsertCustomerContactCommand> {}

export function useUpsertCustomerContact(customerId: string | undefined) {
  const { isLoading: isCreateLoading, post } = useApiPost<
    ICreateResult<IUpsertCustomerContactValidationErrors>,
    IUpsertCustomerContactCommand
  >(`/api/customers/${customerId}/contacts/`);

  const { isLoading: isUpdateLoading, put } = useApiPut<
    IUpsertCustomerContactValidationErrors,
    IUpsertCustomerContactCommand
  >(`/api/customers/${customerId}/contacts/`);

  async function upsert(command: IUpsertCustomerContactCommand, id?: string) {
    if (id) {
      const result = await put(command, id);

      return result ? result : id;
    }

    return post(command);
  }

  return {
    isLoading: isCreateLoading || isUpdateLoading,
    upsert: upsert,
  };
}

export function useUpdateExistingContact(customerId: string) {
  const { isLoading: isUpdateLoading, put } = useApiPut<IUpdateExistingContactCommand>(
    `/api/customers/${customerId}/existingcontact/`,
  );

  async function update(command: IUpdateExistingContactCommand, id?: string) {
    const result = await put(command, id);

    return result ? result : id;
  }

  return {
    isLoading: isUpdateLoading,
    updateContact: update,
  };
}

export interface ICustomerFacilityListItem {
  id: number;
  name: string;
  customerNumber: string;
  facilityId: number;
  facilityName: string;
  facilityAddress: string;
  facilityNumber: string;
}

export interface ISearchFacilityRequest extends ISearchRequest {
  facilityId?: number;
  hasSystem?: boolean;
}

export function useSearchFacilityCustomers() {
  const { isLoading, response, post } = useApiPost<ICustomerFacilityListItem[], ISearchFacilityRequest>(
    '/api/customers/searchfacility',
  );

  return {
    isLoading,
    customers: response,
    searchCustomerFacilities: post,
  };
}

export function getErrorMessage(error: any): string {
  let errorMessage;
  if (error.errorCode === ErrorCode.EmailExists) {
    errorMessage = 'Den valda epost-adressen är redan kopplad till ett konto.';
  } else {
    errorMessage = `: ${error.message}`;
  }
  return errorMessage;
}

export interface IUpdateCustomerBillingAddressCommand {
  invoiceAddress: string;
  invoicePostalCode: string;
  invoiceCity: string;
  invoiceReference: string;
}

export interface IUpdateCustomerBillingAddressValidationErrors
  extends ValidationErrorResultType<IUpdateCustomerBillingAddressCommand> {}

export function useUpdateCustomerBillingAddress(customerId: string | undefined) {
  const { isLoading: isUpdateLoading, put } = useApiPut<
    IUpdateCustomerBillingAddressValidationErrors,
    IUpdateCustomerBillingAddressCommand
  >(`/api/customers/${customerId}/billingaddress`);

  return {
    isLoading: isUpdateLoading,
    updateBillingAddress: put,
  };
}

export function useSendContactInvite(customerId: string | undefined) {
  const { isLoading, post } = useApiPost(`/api/customers/${customerId}/contacts/`);

  const sendContactInvite = (contactId: string) => {
    return post(undefined, `${contactId}/invite`);
  };

  return {
    isLoading,
    sendContactInvite,
  };
}

export enum SearchCustomerOption {
  customerContact = 1,
  systemProducts,
  systemsCommunication,
  user,
  withOutFacility,
  alarmCentral,
  securityCompany,
}

export const searchCustomerOptionList = [
  { option: SearchCustomerOption.customerContact, name: 'Kontakter' },
  { option: SearchCustomerOption.systemProducts, name: 'Produkt' },
  { option: SearchCustomerOption.systemsCommunication, name: 'Kommunikation' },
  { option: SearchCustomerOption.user, name: 'Användare' },
  { option: SearchCustomerOption.withOutFacility, name: 'Utan plats' },
  { option: SearchCustomerOption.alarmCentral, name: 'Larmcentral' },
  { option: SearchCustomerOption.securityCompany, name: 'Vaktbolag' },
];

export interface ICustomerListSearchRequest extends IPaginatedRequest {
  option?: SearchCustomerOption;
  includeProspect?: boolean;
}

export interface IAgreementListItemDto {
  id: number;
  agreementIdentifier: string;
  externalAgreementInvoiceNumber: string;
  facilityId: number;
  facilityName: string;
  coreSystemId: number;
  coreSystemIdentifier: string;
  startDate: Date;
  endDate?: Date;
  monthlyCost: number;
  isActive: boolean;
  isTerminated: boolean;
  isSaleAgreement: boolean;
  editMode: boolean;
}

export interface ICustomerAgreementSearchRequest extends IPaginatedRequest {
  facilityId?: string;
  coreSystemId?: string;
}

export function useGetCustomerAgreements(customerId: string | undefined, facilityId?: string) {
  const { isLoading, response, post } = useApiPost<
    IPaginatedResponse<IAgreementListItemDto>,
    ICustomerAgreementSearchRequest
  >(`/api/customers/${customerId}/agreements/list`);

  const getCustomerAgreements = useCallback(
    async (request: ICustomerAgreementSearchRequest) => {
      return await post({ ...request, facilityId: facilityId });
    },
    [facilityId, post],
  );

  return {
    isLoading,
    agreements: response,
    getCustomerAgreements,
  };
}

export interface IAgreementDto {
  facilityId?: number;
  coreSystemId?: number;
  agreementNumber: number;
  externalAgreementInvoiceNumber: string | undefined;
  contactPersonId?: number;
  monthlyCost: number;
  startDate: Date;
  endDate?: Date | null;
  emergencyCallCenterId: number;
  securityCompanyId: number;
  measure: Measure;
  service: Service;
  auditInterval: AuditInterval;
  invoiceInterval: InvoiceInterval;
  rentType: RentType;
  transferType: TransferType;
  transferInterval: TransferInterval;
  includeInnerControl: boolean;
}

export function useGetAgreement(customerId: string) {
  const { isLoading, response, get } = useApiGet<IAgreementDto>(`/api/customers/${customerId}/agreements/`, false);

  return {
    isLoading,
    response,
    get,
  };
}

export interface IUpsertAgreementCommand {
  facilityId?: number;
  coreSystemId?: number;
  customerContactId?: number;
  externalAgreementInvoiceNumber: string;
  customerContactFirstName: string;
  customerContactLastName: string;
  customerContactPosition: string;
  customerContactPhoneNumber: string;
  customerContactEmail: string;
  monthlyCost?: number | null;
  startDate?: Date;
  endDate?: Date | null;
  emergencyCallCenterId: number | undefined;
  securityCompanyId: number | undefined;
  measure: Measure | undefined;
  service: Service | undefined;
  auditInterval: AuditInterval | undefined;
  invoiceInterval?: InvoiceInterval | undefined;
  rentType: RentType | undefined;
  transferType: TransferType | undefined;
  transferInterval: TransferInterval | undefined;
  includeInnerControl: boolean;
  createAndApprove?: boolean | null;
  isSaleAgreement?: boolean | null;
}

export interface IUpsertAgreementValidationErrors extends ValidationErrorResultType<IUpsertAgreementCommand> {}

export function useUpsertAgreement(customerId: string) {
  const { isLoading: isCreateLoading, post } = useApiPost<
    ICreateResult<IUpsertAgreementValidationErrors>,
    IUpsertAgreementCommand
  >(`/api/customers/${customerId}/agreements/`);
  const { isLoading: isUpdateLoading, put } = useApiPut<
    ICreateResult<IUpsertAgreementValidationErrors>,
    IUpsertAgreementCommand
  >(`/api/customers/${customerId}/agreements/`);

  async function upsert(command: IUpsertAgreementCommand, id?: string) {
    if (id) {
      const result = await put(command, id);

      return result ? result : id;
    }

    return post(command);
  }

  return {
    isLoading: isCreateLoading || isUpdateLoading,
    upsert: upsert,
  };
}

export interface IUpdateAgreementExternalNumberCommand {
  externalAgreementInvoiceNumber: string;
}

export interface IUpdateAgreementExternalNumberCommandValidationErrors
  extends ValidationErrorResultType<IUpdateAgreementExternalNumberCommand> {}

export function useUpdateAgreementExternalNumber() {
  const { isLoading: isUpdateLoading, put } = useApiPut<
    ICreateResult<IUpsertAgreementValidationErrors>,
    IUpdateAgreementExternalNumberCommand
  >(`/api/customers/agreements/`);

  async function updateFunction(command: IUpdateAgreementExternalNumberCommand, id: string) {
    const result = await put(command, id);

    return result;
  }

  return {
    isLoading: isUpdateLoading,
    updateExternalNumber: updateFunction,
  };
}

export interface ITerminateAgreementCommand {
  terminationDate: Date;
  comment: string;
  sendToCustomer: boolean;
}
export interface ITerminateAgreementCommandValidationErrors
  extends ValidationErrorResultType<ITerminateAgreementCommand> {}

export function useTerminateAgreement(customerId: string) {
  const { isLoading, put } = useApiPut<
    ICreateResult<ITerminateAgreementCommandValidationErrors>,
    ITerminateAgreementCommand
  >(`/api/customers/${customerId}/agreements`);

  const terminateAgreement = useCallback(
    async (command: ITerminateAgreementCommand, agreementId: string) => {
      return await put(command, `/${agreementId}/terminate`);
    },
    [put],
  );

  return {
    isLoading,
    terminateAgreement,
  };
}
export interface ICheckCustomerIdentificationExisting {
  currentCustomerId?: number;
  type?: CustomerType;
  identificationNumber: string;
}

export function useCheckIdentificationExisting() {
  const { isLoading, post } = useApiPost<number | undefined, ICheckCustomerIdentificationExisting>(
    `/api/customers/checkIdentification/`,
    false,
  );

  return {
    isLoading,
    check: post,
  };
}

export interface ICustomerSaleListRequest extends IPaginatedRequest {
  facilityId?: string;
}

export function useGetCustomerSaleList(customerId: string | undefined, facilityId?: string) {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<ISaleDto>, ICustomerSaleListRequest>(
    `/api/customers/${customerId}/sales/`,
  );

  const getCustomerSaleList = useCallback(
    async (request: ICustomerSaleListRequest) => {
      return await post({ ...request, facilityId: facilityId });
    },
    [facilityId, post],
  );

  return {
    isLoading,
    sales: response,
    getCustomerSaleList,
  };
}

export interface IEmailDocument {
  recipient: string;
  message: string;
  subject: string;
}

export interface IEmailDocumentCommand extends IEmailDocument {
  documentId: number;
  customerId?: number;
  fileInformationId?: number;
}

export interface ISendDocumentEmailCommandValidationErrors extends ValidationErrorResultType<IEmailDocumentCommand> {}

export function useEmailDocument() {
  const { isLoading, post } = useApiPost<
    ICreateResult<ISendDocumentEmailCommandValidationErrors>,
    IEmailDocumentCommand
  >(`/api/customers/email/document`, false);

  return {
    isLoadingEmailDocument: isLoading,
    emailDocument: post,
  };
}
