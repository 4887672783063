import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  placement: {
    color: theme.palette.grey[700],
  },
  description: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '6rem',
    },
  },
}));

export default useStyles;
