import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  versionContainer: {
    flex: 1,
    display: 'flex',
    marginLeft: '10px',
    marginTop: "10px",
  },

}));
