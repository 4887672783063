import React, { forwardRef, createContext } from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './WidgetContainer.styles';
import { useHistory } from 'react-router-dom';

interface IContainerProps {
  children: React.ReactNode;
  label: string;
  actions?: React.ReactNode;
  subheader?: string;
  loading?: boolean;
  headerLink?: string;
}

interface IContainerContext {
  loading?: boolean;
}

export const ContainerContext = createContext<IContainerContext | null | undefined>(null);

const Container: React.RefForwardingComponent<HTMLFormElement, IContainerProps> = (props, ref) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <ContainerContext.Provider value={{ loading: props.loading }}>
      <section className={classes.root} aria-labelledby={props.label}>
        <header className={classes.header}>
          <Typography id={props.label} className={classes.label} variant="h3" component="h2" onClick={() => props.headerLink && history.push(props.headerLink)} style={{cursor: props.headerLink ? 'pointer' : 'default'}}>
            {props.label}
          </Typography>
          {props.actions && <div className={classes.actions}>{props.actions}</div>}
          {props.subheader && (
            <Typography variant="body1" component="h3" className={classes.subheader}>
              {props.subheader}
            </Typography>
          )}
        </header>
        <div>
          <div className={classes.children}>{props.children}</div>
        </div>
      </section>
    </ContainerContext.Provider>
  );
};

export default forwardRef(Container);
