import React, { createContext, useState, useEffect, useContext } from 'react';
import { MsalAuthProvider, LoginType, IMsalAuthProviderConfig } from 'react-aad-msal';
import { Configuration, AuthenticationParameters } from 'msal';
import { IConfigurationContext } from '../Configurations/ConfigurationContext';

const forgotPasswordErrorCode: string = 'AADB2C90118';

function getIdHint() {
  const idHintIndex = window.location.search.indexOf('id_token_hint');
  const idTokenHint = window.location.search.substr(idHintIndex);
  const token = idTokenHint.substr('id_token_hint='.length);
  return token;
}

function getAuthConfig(config: IConfigurationContext, policy: string): Configuration {
  return {
    auth: {
      authority: config.azureAdAuthority + policy,
      clientId: config.azureAdClientId,
      validateAuthority: false,
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
    },
  };
}

function getConfigurations(config: IConfigurationContext) {
  const isInviteLink = window.location.search.indexOf('id_token_hint') > -1;
  const policy = isInviteLink ? config.policy_invitation : config.policy_signin;

  const authConfig = getAuthConfig(config, policy);

  const parameters: AuthenticationParameters = {
    extraQueryParameters: isInviteLink ? { id_token_hint: getIdHint() } : undefined,
  };

  const options: IMsalAuthProviderConfig = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin + '/auth.html',
  };

  return { authConfig, parameters, options };
}

interface IProps {
  config: IConfigurationContext;
  children: (msalAuthProvider: MsalAuthProvider | null) => JSX.Element | null;
}

export const AuthContext = createContext<MsalAuthProvider | null>(null);

export function AuthContextProvider(props: IProps) {
  const [state, setState] = useState<MsalAuthProvider | null>(null);
  useEffect(() => {
    const configuration = getConfigurations(props.config);
    const msalAuthProvider = new MsalAuthProvider(
      configuration.authConfig,
      configuration.parameters,
      configuration.options,
    );

    const authRedirectCallback = (error: any, response: any) => {
      if (error) {
        if (error.errorMessage.indexOf(forgotPasswordErrorCode) > -1) {
          msalAuthProvider.loginRedirect({
            authority: props.config.azureAdAuthority + props.config.policy_passwordReset,
          });
        } else {
          msalAuthProvider.loginRedirect({
            authority: props.config.azureAdAuthority + props.config.policy_signin,
          });
        }
      }
    };
    msalAuthProvider.handleRedirectCallback(authRedirectCallback);

    setState(msalAuthProvider);
  }, [props.config]);

  return (
    <>
      {state && (
        <AuthContext.Provider value={state as MsalAuthProvider}> {props.children(state)} </AuthContext.Provider>
      )}
    </>
  );
}

const useAuthContext = () => useContext(AuthContext);

export default useAuthContext;
