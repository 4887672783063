import React, { useCallback, useState } from 'react';
import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import {
  CreateNewFolder,
  CloudDownload,
  CloudUpload,
  Edit,
  ExpandLess,
  ExpandMore,
  Folder,
  FolderOpen,
  Image,
  MoreVert,
  PictureAsPdf,
  MailRounded
} from '@material-ui/icons';
import useUserContext from '../../../../Core/Authentication/UserContext';
import { hasUserAccess } from '../../../../Core/Authentication/AccessGuard/AccessGuard';
import { IFileFormType, IFileNode, useDownloadFile, useGetFile } from '../FileSystem.api';
import { AccessRights, ModuleIdentifiers } from '../../../../Core/Authentication/ModuleAccess';
import { useStyles } from './FileSystem.styles';
import { IEmailDocument, IEmailDocumentCommand, useEmailDocument } from '../../Customers/Customers.api';
import EmailDocumentModal from '../../../../Shared/Modal/EmailModal/EmailDocumentModal';

const initialEmailData = { recipient: '', message: '', subject: '' };

const FileNode: React.FC<{
  node: IFileNode;
  allNodes: IFileNode[];
  openNodeIds: number[];
  onToggleNode: (nodeId: number, close?: boolean) => void;
  onOpenForm: (node: IFileNode, formType: IFileFormType) => void;
  focusNode: number;
  onViewFile: (file: string, nodeId: number) => void;
}> = (props) => {
  const classes = useStyles();
  const { node, focusNode, onOpenForm } = props;
  const { user } = useUserContext();
  const { isLoading, downloadFile } = useDownloadFile();
  const { isLoadingEmailDocument, emailDocument } = useEmailDocument();
  const { getFile } = useGetFile();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openMenu, setOpenMenu] = useState<number | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<IFileNode>();
  const [documentEmailData, setDocumentEmailData] = useState<IEmailDocument>(initialEmailData);
  var canEdit = hasUserAccess(user, ModuleIdentifiers.FileSystem, AccessRights.Write);
  var isNodeOpen = props.openNodeIds.includes(props.node.id);
  var isFolder = node.nodeType === 'folder';

  const onOpenMenu = (nodeId: number, anchor: HTMLElement) => {
    setOpenMenu(nodeId);
    setAnchorEl(anchor);
  };

  const onCloseMenu = () => {
    setOpenMenu(null);
    setAnchorEl(null);
  };

  const onDownloadFile = (node: IFileNode) => {
    if (node.nodeType === 'folder') return;
    downloadFile(node.id);
    onCloseMenu();
  };

  const onViewFile = useCallback(
    async (node: IFileNode) => {
      if (node.nodeType === 'folder') return;
      const document = await getFile(node.id);
      var file = new Blob([document.blob], { type: document.blob.type });
      var fileURL = URL.createObjectURL(file);
      props.onViewFile(fileURL, node.id);
      onCloseMenu();
    },
    [getFile, props],
  );

  function onOpenFileForm(node: IFileNode, formType: IFileFormType) {
    onOpenForm(node, formType);
    onCloseMenu();
  }
  
  
  const ToggleModalCallBack = useCallback(() => {
    setOpenModal(!openModal);
    setDocumentEmailData(initialEmailData);
  }, [openModal, setOpenModal]);

  const handleSelectDocument = (document: IFileNode) => {
    setSelectedDocument(document);
    ToggleModalCallBack();
  };

  const handleSendDocument = useCallback(async () => {
    if (selectedDocument && documentEmailData.recipient && documentEmailData.subject && documentEmailData.message) {
      const data: IEmailDocumentCommand = {
        ...documentEmailData,
        documentId: selectedDocument?.id,
        fileInformationId: selectedDocument.fileInformationId,
      };

      emailDocument(data);
    }
    ToggleModalCallBack();
  }, [selectedDocument, documentEmailData, ToggleModalCallBack, emailDocument]);

  return (
    <div>
      <div
        className={focusNode === node.id || focusNode === node.parentNodeId ? classes.focusNode : classes.notFocusNode}
      >
        <ListItem
          button={node ? (true as false) : false}
          data-testid={`expand_node_${node.id}`}
          onClick={
            node.hasChildren
              ? () => {
                  props.onToggleNode(node.id, isNodeOpen);
                }
              : undefined
          }
          style={
            node.treeLevel < 2
              ? {
                  paddingLeft: `${!node.hasChildren ? node.treeLevel + 2 : node.treeLevel}rem`,
                }
              : node.treeLevel <= 7
              ? {
                  paddingLeft: `${!node.hasChildren ? (node.treeLevel - 1) * 2 + 3 : (node.treeLevel - 1) * 2 + 1}rem`,
                  paddingTop: '3px',
                  paddingBottom: '3px',
                }
              : { paddingLeft: `15rem` }
          }
        >
          {node.hasChildren && <ListItemIcon>{isNodeOpen ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>}
          <ListItemIcon>
            {node.nodeType === 'pdf' ? (
              <PictureAsPdf />
            ) : node.nodeType === 'image' ? (
              <Image />
            ) : isNodeOpen || !node.hasChildren ? (
              <FolderOpen />
            ) : (
              <Folder />
            )}
          </ListItemIcon>
          <ListItemText className={classes.listItemText} primary={node.name} onClick={() => onViewFile(node)} />
          {(canEdit || !isFolder) && (
            <ListItemSecondaryAction>
              <IconButton
                className={classes.iconButton}
                data-testid={`menu_node_${node.id}`}
                onClick={(e) => onOpenMenu(node.id, e.currentTarget)}
              >
                <MoreVert />
              </IconButton>
              <Menu key={node.name} anchorEl={anchorEl} open={openMenu === node.id} onClose={onCloseMenu}>
                {node.nodeType !== 'folder' && (
                  <MenuItem disabled={isLoading} onClick={() => onDownloadFile(node)}>
                    <ListItemIcon>{<CloudDownload />}</ListItemIcon>
                    <Typography variant="inherit">Ladda ner fil</Typography>
                  </MenuItem>
                )}
                {node.nodeType === 'folder' && (
                  <MenuItem onClick={() => onOpenFileForm(node, 'uploadFile')}>
                    <ListItemIcon>{<CloudUpload />}</ListItemIcon>
                    <Typography variant="inherit">Ladda upp fil</Typography>
                  </MenuItem>
                )}
                {node.nodeType === 'folder' && (
                  <MenuItem onClick={() => onOpenFileForm(node, 'createFolder')}>
                    <ListItemIcon>{<CreateNewFolder />}</ListItemIcon>
                    <Typography variant="inherit">Skapa mapp</Typography>
                  </MenuItem>
                )}
                {canEdit && (
                  <MenuItem onClick={() => handleSelectDocument(node)}>
                    <ListItemIcon>{<MailRounded />}</ListItemIcon>
                    <Typography variant="inherit">Skicka</Typography>
                  </MenuItem>
                )}
                {canEdit && (
                  <MenuItem onClick={() => onOpenFileForm(node, 'editNode')}>
                    <ListItemIcon>{<Edit />}</ListItemIcon>
                    <Typography variant="inherit">{`Redigera ${isFolder ? 'mapp' : 'fil'}`}</Typography>
                  </MenuItem>
                )}
              </Menu>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </div>
      {node.hasChildren && (
        <Collapse in={isNodeOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {props
              .allNodes!.filter((x) => x.parentNodeId === node.id)
              .map((childNode) => (
                <FileNode key={childNode.id} {...props} node={childNode} />
              ))}
          </List>
        </Collapse>
      )}
      {selectedDocument && (
        <EmailDocumentModal
          key={selectedDocument?.id}
          title={`Skicka ${selectedDocument.name}`}
          value={documentEmailData}
          open={openModal}
          ToggleModal={ToggleModalCallBack}
          onChange={setDocumentEmailData}
          type={'Dokument'}
          onSend={handleSendDocument}
          isLoading={isLoadingEmailDocument}
        />
      )}
    </div>
  );
};

export default FileNode;
