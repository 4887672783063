import React, { useState, useEffect } from 'react';
import Container from '../../../../../Shared/Container/Container';
import Button from '../../../../../Shared/Button/Button';
import { useHistory, useParams } from 'react-router-dom';
import {
  useUpsertCompany,
  useGetCompany,
  IUpsertCompanyCommand,
  IUpsertCompanyValidationErrors,
} from '../../Companies.api';
import useFormValidation from '../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import { isResultError } from '../../../../../Shared/Api/response/ICreateResult';
import useInputState from '../../../../../Shared/Hooks/UseInputState/UseInputState';
import { Fieldset } from '../../../../../Shared/Form/Fieldset/Fieldset';
import { InputField } from '../../../../../Shared/InputFields/InputField/InputField';
import PostalCodeField from '../../../../../Shared/InputFields/PostalCodeField/PostalCodeField';
import EmailField from '../../../../../Shared/InputFields/EmailField/EmailField';
import IdentificationNumberField from '../../../../../Shared/InputFields/IdentificationNumberField/IdentificationNumberField';
import { CustomerType } from '../../../../../Shared/Misc/CustomerType';
import PhoneNumberField from '../../../../../Shared/InputFields/PhoneNumberField/PhoneNumberField';

const CompanyForm = () => {
  const history = useHistory();
  const { companyId } = useParams<{ companyId: string }>();
  const { isLoading: isGetLoading, getCompany } = useGetCompany();
  const { isLoading: isUpsertLoading, upsert } = useUpsertCompany();
  const { isFormValid, formRef } = useFormValidation();

  const [originalName, setOriginalName] = useState('');
  const [name, changeName, setName] = useInputState('');
  const [identificationNumber, changeIdentificationNumber, setIdentificationNumber] = useInputState('');
  const [email, changeEmail, setEmail] = useInputState('');
  const [phoneNumber, changePhoneNumber, setPhoneNumber] = useInputState('');
  const [address, changeAddress, setAddress] = useInputState('');
  const [postalCode, changePostalCode, setPostalCode] = useInputState('');
  const [city, changeCity, setCity] = useInputState('');

  const [errors, setErrors] = useState<IUpsertCompanyValidationErrors>();

  useEffect(() => {
    async function loadCompany() {
      const company = await getCompany(companyId);

      setOriginalName(company.name);

      setName(company.name);
      setIdentificationNumber(company.identificationNumber);
      setEmail(company.email);
      setPhoneNumber(company.phoneNumber);
      setAddress(company.address);
      setPostalCode(company.postalCode);
      setCity(company.city);
    }

    if (companyId) {
      loadCompany();
    }
  }, [
    getCompany,
    companyId,
    setAddress,
    setCity,
    setEmail,
    setIdentificationNumber,
    setName,
    setPhoneNumber,
    setPostalCode,
  ]);

  async function upsertCompany() {
    const upsertCommand: IUpsertCompanyCommand = {
      name: name,
      identificationNumber: identificationNumber,
      email: email,
      phoneNumber: phoneNumber,
      address: address,
      postalCode: postalCode,
      city: city,
    };

    const result = await upsert(upsertCommand, companyId);

    isResultError(result) ? setErrors(result) : history.push('/company/' + result);
  }

  function Actions() {
    return (
      <>
        <Button
          color="default"
          variant="outlined"
          onClick={() => (companyId ? history.push(`/company/${companyId}`) : history.push('/company/'))}
        >
          Avbryt
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={upsertCompany}
          loading={isUpsertLoading}
          disabled={!isFormValid}
        >
          {companyId ? 'Spara' : 'Skapa'}
        </Button>
      </>
    );
  }

  return (
    <Container
      form
      label={companyId ? `Redigera ${originalName}` : 'Skapa företag'}
      actions={<Actions />}
      ref={formRef}
      loading={isGetLoading}
    >
      <Fieldset legend="Allmänt">
        <IdentificationNumberField
          required
          fullwidth
          customerType={CustomerType.Company}
          value={identificationNumber}
          onChange={changeIdentificationNumber}
          errorText={errors?.identificationNumber}
        />
        <InputField
          label="Företagsnamn"
          type="text"
          required
          value={name}
          onChange={changeName}
          errorText={errors?.name}
        />
        <EmailField label="E-post" required value={email} onChange={changeEmail} errorText={errors?.email} />
        <InputField
          label="Gatuadress"
          type="text"
          value={address}
          onChange={changeAddress}
          errorText={errors?.address}
        />
        <PhoneNumberField
          label="Telefon"
          value={phoneNumber}
          onChange={changePhoneNumber}
          errorText={errors?.phoneNumber}
        />
        <PostalCodeField
          label="Postnummer"
          type="text"
          value={postalCode}
          onChange={changePostalCode}
          errorText={errors?.postalCode}
        />
        <InputField label="Ort" type="text" value={city} onChange={changeCity} errorText={errors?.city} />
      </Fieldset>
    </Container>
  );
};

export default CompanyForm;
