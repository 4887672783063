import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Tab } from '@material-ui/core';
import Tabs from '../../../../../Shared/Tabs/Tabs';
import CompanySettings from './Company/CompanySettings';
import CompanyBranchList from './CompanyBranch/CompanyBranchList/CompanyBranchList';
import Container from '../../../../../Shared/Container/Container';

const tabUrls = ['', 'branch'];
function Settings() {
  const { tabId } = useParams<{ tabId: string }>();
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState<number>(tabId ? tabUrls.indexOf(tabId) : 0);

  useEffect(() => {
    if (tabId) {
      setTabIndex(tabUrls.indexOf(tabId));
    }
  }, [tabId]);

  return (
    <CompanySettings />
  );
}

export default Settings;
