import { makeStyles } from '@material-ui/core';
import theme from '../../Styles/Theme';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    display: 'flex',
    borderRadius: '12rem',
    margin: '0 1rem',
    maxWidth: 'max-content',
  },
}));

export default useStyles;
