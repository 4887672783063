import { makeStyles, Theme } from '@material-ui/core';
import { sharedCalendarStyling } from '../Components/SharedCalendar.styles';

export const useStyles = makeStyles((theme: Theme) => ({
  outerContainer: {
    width: '100%;',
  },
  innerContainer: {
    display: 'flex'
  },
  calendar: {
    width: '100%',
    height: 'calc(100vh - 15rem)',
    minHeight: '42rem',
    paddingBottom: '1rem',
    '& .rbc-time-gutter': {
      display: 'none'
    },
    '& .rbc-event-label': {
      display: "none"
    },
    '& .rbc-off-range-bg': {
      backgroundColor: theme.palette.grey[100],
    },

    '& .rbc-month-view': {
      border: 0,
    },
    '& .rbc-month-header': {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    '& .rbc-allday-cell': {
      padding: '0.5rem',
      boxSizing: 'border-box',
      border: `1px solid ${theme.palette.grey[300]}`
    },
    '& .rbc-row-content': {
      overflowY: 'auto',
      scrollbarWidth: 'none', // firefox
      overFlowStyle: 'none', // ie & edge
      '&::-webkit-scrollbar': {
        display: 'none' // chrome & safari
      },
      '&::-moz-webkit-scrollbar': {
        display: 'none' // firefox
      },
      '&::-ms-scrollbar': {
        display: 'none' // ie & edge
      },
      '&::-o-scrollbar': {
        display: 'none' // safari & opera
      },
    },
    ...sharedCalendarStyling,
  },
  calendarContainer: {
    margin: '-1rem',
    flex: '8',
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    '& ul': {
      width: '100%',
      textAlign: 'center',
      paddingInlineStart: '0',
      '& table': {
        width: '100%',
        '& tr': {
          height: '30px'
        },
        '& tr > td:nth-of-type(1)': {
          width: '90px'
        },
        '& tr > td:nth-of-type(2)': {
          textAlign: 'left',
          width: '120px',
          overflow: 'hidden',
        },
      }
    }
  },
  agenda: {
    flex: '4',
    height: 'calc(100vh - 15rem)',
    minHeight: '42rem',
    marginLeft: '2rem',
    overflowY: 'auto',
    '& .rbc-off-range-bg': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  popper: {
    zIndex: 4,
  },
  list: {
    padding: 0,
    margin: 0,
  },
  listItem: {
    marginBottom: '0.75rem',
    '&:last-child': {
      marginBottom: 0,
    },
    listStyleType: 'none',
  },
  event: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
  eventListItem: {
    marginBottom: '0.75rem',
    listStyleType: 'none',
  },
  dash: {
    margin: '0 0.25rem',
  },
  messageListContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: '1rem',
    borderRadius: theme.shape.borderRadius,
  },
  messageListContainerError: {
    display: 'flex',
    alignItems: 'flex-start',
    border: `1px solid ${theme.palette.error.main}`,
    padding: '1rem',
    borderRadius: theme.shape.borderRadius,
  },
  greyContainer: {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  blueContainer: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  redContainer: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  greenContainer: {
    border: `1px solid ${theme.palette.success.main}`,
  },
  warningColor: {
    color: theme.palette.error.main,
  },
  normalColor: {
    color: 'black',
  },
  message: {
    flexGrow: 1,
    cursor: 'pointer',
  },
  bold: {
    marginTop: '0.5rem',
    fontWeight: 600,
  },
}));
