import React, { useState, useEffect, useCallback } from 'react';
import { useGetCompanyUsers, IUser, useSendInvite, useSoftDeleteCompanyUser } from '../Users.api';
import {
  Typography,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ListItemIcon,
  MenuItem,
  useMediaQuery,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { DropdownCell } from '../../../../../../Shared/Table/DropdownCell/DropdownCell';
import EditIcon from '@material-ui/icons/EditRounded';
import TablePaginationWithDatasource from '../../../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import useTable from '../../../../../../Shared/Table/useTable';
import { useHistory, useParams } from 'react-router-dom';
import SearchInput from '../../../../../../Shared/InputFields/SearchInput/SearchInput';
import { Table } from '../../../../../../Shared/Table/Table';
import IPaginatedResponse from '../../../../../../Shared/Api/response/IPaginatedResponse';
import SortButton from '../../../../../../Shared/Table/SortButton/SortButton';
import LinkButton from '../../../../../../Shared/Table/LinkButton/LinkButton';
import theme from '../../../../../../Styles/Theme';
import { MobileTable } from '../../../../../../Shared/Table/MobileTable/MobileTable';
import { MobileTableRow } from '../../../../../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import AccessGuard from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { ModuleIdentifiers, AccessRights } from '../../../../../../Core/Authentication/ModuleAccess';
import { useStyles } from '../../../../../../Shared/Table/TableRowHeader/TableRowHeader.styles';
import ImpersonateIcon from '@material-ui/icons/PeopleAltRounded';
import useUserContext from '../../../../../../Core/Authentication/UserContext';
import { Mail } from '@material-ui/icons';
import IconButton from '../../../../../../Shared/IconButton/IconButton';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
export interface IUserListProps {
  onUserClick: (user: IUser) => void;
  onCreateNew?: () => void;
  isSuperAdmin?: boolean;
}

function UserList(props: IUserListProps) {
  const { isSuperAdmin, onUserClick, onCreateNew } = props;
  const history = useHistory();
  const classes = useStyles();
  const userContext = useUserContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { isLoading: isSendingInviteLoading, sendInvite } = useSendInvite();
  const [includeDeleted, SetIncludeDeleted] = useState<boolean>(false);
  const { companyId } = useParams<{ companyId: string }>();
  const { isLoading, response: users, getCompanyUsers } = useGetCompanyUsers();
  const { softDeleteCompanyUser } = useSoftDeleteCompanyUser();
  const {
    toggleSort,
    sortProperty,
    sortDirection,
    pageSize,
    setPageSize,
    setPageNumber,
    setQuery,
    paginatedRequest,
  } = useTable();

  const [emptyMessage, setEmptyMessage] = useState('');

  const getUsersCallback = useCallback(async () => {
    const usersResponse: IPaginatedResponse<IUser> = await getCompanyUsers({
      ...paginatedRequest,
      companyId: companyId,
      includeDeleted: includeDeleted,
    });

    if (usersResponse?.totalCount <= 0) {
      if (paginatedRequest.query) {
        setEmptyMessage('Filtreringen gav inga resultat');
      } else {
        setEmptyMessage('Inga användare tillagda');
      }
    }
  }, [getCompanyUsers, paginatedRequest, companyId, includeDeleted]);

  useEffect(() => {
    getUsersCallback();
  }, [getUsersCallback]);

  const softDelete = useCallback(
    async (id) => {
      await softDeleteCompanyUser(id);
      await getUsersCallback();
    },
    [getUsersCallback, softDeleteCompanyUser],
  );

  function displayName(user: IUser) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', color: !user.inviteAccepted ? 'red' : 'inherit' }}>
        {user.firstName + ' ' + user.lastName}
      </div>
    );
  }

  return (
    <Table
      label={`Användare (${users?.totalCount ?? 0})`}
      filters={
        <>
          <SearchInput type="search" placeholder="Sök" onChange={setQuery} />
          <FormControlLabel
            control={
              <Checkbox
                name="showDeleted"
                color="primary"
                checked={includeDeleted}
                onChange={(_, checked) => {
                  SetIncludeDeleted(checked);
                }}
              />
            }
            label="Visa inaktiva användare"
          />
        </>
      }
      mobile={mobileView}
      customSize={{
        md: 8,
      }}
      iconButtons={
        <AccessGuard module={ModuleIdentifiers.Admin} accessRights={AccessRights.Write} superAdminAccess>
          <IconButton
            size="small"
            aria-label="Lägg till användare"
            onClick={() => {
              onCreateNew ? onCreateNew() : history.push(`${history.location.pathname}/create`);
            }}
          >
            <AddIcon />
          </IconButton>
        </AccessGuard>
      }
      pagination={
        users && (
          <TablePaginationWithDatasource
            datasource={users}
            onChangePage={setPageNumber}
            onChangeRowsPerPage={setPageSize}
          />
        )
      }
      empty={
        users && {
          empty: users.items.length <= 0 ? true : false,
          message: emptyMessage,
        }
      }
      loading={{
        loading: isLoading,
        skeletonRows: pageSize,
      }}
      minWidth
    >
      {mobileView ? (
        <>
          {users &&
            users.items.map((user) => (
              <MobileTable
                key={user.id}
                label={`${user.firstName} ${user.lastName}`}
                customLink={
                  <AccessGuard
                    module={ModuleIdentifiers.Admin}
                    accessRights={AccessRights.Write}
                    fallback={displayName(user)}
                  >
                    <LinkButton onClick={() => onUserClick(user)} color="primary">
                      {displayName(user)}
                    </LinkButton>
                  </AccessGuard>
                }
                action={
                  <DropdownCell mobile={mobileView}>
                    {isSuperAdmin && (
                      <MenuItem disabled={!user.inviteAccepted} onClick={() => userContext.impersonateCompany(user.id)}>
                        <ListItemIcon>{<ImpersonateIcon />}</ListItemIcon>
                        <Typography variant="inherit">Impersonera företag</Typography>
                      </MenuItem>
                    )}
                    {!isSendingInviteLoading && (
                      <MenuItem onClick={() => softDelete(user.id)}>
                        <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                        <Typography variant="inherit">Inaktivera</Typography>
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() => {
                        onUserClick(user);
                      }}
                    >
                      <ListItemIcon>{<EditIcon />}</ListItemIcon>
                      <Typography variant="inherit">Redigera</Typography>
                    </MenuItem>
                  </DropdownCell>
                }
              >
                <MobileTableRow label="Name" value={`${user.firstName} ${user.lastName}`}></MobileTableRow>
                <MobileTableRow label="Filial" value={user.companyBranchName}></MobileTableRow>
                <MobileTableRow label="E-post" value={user.email}></MobileTableRow>
                <MobileTableRow label="Telefon" value={user.phoneNumber}></MobileTableRow>
              </MobileTable>
            ))}
        </>
      ) : (
        <>
          <TableHead>
            <TableRow>
              <TableCell>
                <span className={'sr-only'}>Knappar</span>
              </TableCell>
              <TableCell>
                Namn
                <SortButton
                  property="firstName"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Filial
                <SortButton
                  property="companyBranchName"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                E-post
                <SortButton
                  property="email"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Telefon
                <SortButton
                  property="phoneNumber"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.items.map((user) => (
              <TableRow className={classes.pointerCursor} hover tabIndex={-1} key={user.id}>
                <>
                  <DropdownCell>
                    {isSuperAdmin && (
                      <MenuItem disabled={!user.inviteAccepted} onClick={() => userContext.impersonateCompany(user.id)}>
                        <ListItemIcon>{<ImpersonateIcon />}</ListItemIcon>
                        <Typography variant="inherit">Impersonera företag</Typography>
                      </MenuItem>
                    )}
                    {!isSendingInviteLoading && (
                      <MenuItem onClick={() => softDelete(user.id)}>
                        <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                        <Typography variant="inherit">Inaktivera</Typography>
                      </MenuItem>
                    )}
                    {!user.inviteAccepted && (
                      <MenuItem disabled={isSendingInviteLoading} onClick={() => sendInvite(user.id.toString())}>
                        <ListItemIcon>{<Mail />}</ListItemIcon>
                        <Typography variant="inherit">Skicka ny inbjudan</Typography>
                      </MenuItem>
                    )}
                  </DropdownCell>
                  <TableCell className={classes.linkFont} onClick={() => onUserClick(user)}>
                    <AccessGuard
                      module={ModuleIdentifiers.Admin}
                      accessRights={AccessRights.Write}
                      fallback={displayName(user)}
                    >
                      {displayName(user)}
                    </AccessGuard>
                  </TableCell>
                  <TableCell onClick={() => onUserClick(user)}>{user.companyBranchName}</TableCell>
                  <TableCell onClick={() => onUserClick(user)}>{user.email}</TableCell>
                  <TableCell onClick={() => onUserClick(user)}>{user.phoneNumber}</TableCell>
                </>
              </TableRow>
            ))}
          </TableBody>
        </>
      )}
    </Table>
  );
}

export default UserList;
