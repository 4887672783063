import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '0.25rem 0.5rem',
    border: 0,
  },
  legend: {
    padding: 0,
  },
  legendContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  line: {
    height: '1px',
    flexGrow: 1,
    backgroundColor: theme.palette.grey[200],
    marginLeft: '1rem',
  },
  expandButton: {
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: '0.25rem',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
      '& legend': {
        color: theme.palette.primary.main,
      },
    },
  },
  arrow: {
    marginRight: '-0.5rem',
    marginLeft: '-0.25rem',
  },
}));
