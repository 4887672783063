import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  inputWrapper: {
    display: 'flex',
    margin: '10px',
    alignItems: 'center',
    flex: 'auto',
  },

  input: {
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[400]}`,
    width: '40%',
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
    fontSize: '1.1rem',
    '& > input': {
      textAlign: 'center',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      display: 'none',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      display: 'none',
    },
  },
}));

export default useStyles;
