import React, { useState, useEffect, useCallback } from 'react';
import { Table } from '../../../../../../../../Shared/Table/Table';
import { useHistory, useParams } from 'react-router-dom';
import SearchInput from '../../../../../../../../Shared/InputFields/SearchInput/SearchInput';
import useTable from '../../../../../../../../Shared/Table/useTable';
import { TableHead, TableRow, TableCell, TableBody, Checkbox, FormControlLabel, IconButton } from '@material-ui/core';
import SortButton from '../../../../../../../../Shared/Table/SortButton/SortButton';
import TablePaginationWithDatasource from '../../../../../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import {
  useGetAllProductsBySupplier,
  useGetProductFilters,
  IProduct,
  useUpdateProductVisibility,
} from '../Products.api';
import Select, { ISelectOption } from '../../../../../../../../Shared/Select/Select';
import AccessGuard from '../../../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { ModuleIdentifiers, AccessRights } from '../../../../../../../../Core/Authentication/ModuleAccess';
import { useStyles } from '../../../../../../../../Shared/Table/TableRowHeader/TableRowHeader.styles';
import formatDate from '../../../../../../../../Shared/Formatting/formatDate';
import moment from 'moment';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';

const ProductList = () => {
  const { id } = useParams<{ id: string }>();
  const { isLoading, products, getProducts } = useGetAllProductsBySupplier();
  const { getProductFilters, productFilters } = useGetProductFilters(id);
  const { IsUpsertProductLoading } = useUpdateProductVisibility();
  const {
    toggleSort,
    sortProperty,
    sortDirection,
    pageSize,
    setPageSize,
    setPageNumber,
    setQuery,
    paginatedRequest,
  } = useTable(10);
  const [emptyMessage, setEmptyMessage] = useState('');
  const [productCategories, setProductCategories] = useState<ISelectOption[]>([]);
  const [showHidden, toggleShowHidden] = useState<boolean>(false);
  const [selectedProductCategories, setSelectedProductCategories] = useState<ISelectOption[]>([]);

  const history = useHistory();
  const classes = useStyles();

  function getClasses(date: Date | null | undefined) {
    var diff = moment(date).diff(new Date(), 'month');
    if (diff < 0) {
      return classes.red;
    } else if (diff < 4) {
      return classes.yellow;
    } else {
      return classes.green;
    }
  }

  const getList = useCallback(async () => {
    if (!id) {
      history.push('/admin/supplier/');
      return;
    }

    const response = await getProducts(id, {
      ...paginatedRequest,
      productCategoryIds: selectedProductCategories ? selectedProductCategories.map((x) => parseInt(x.value)) : [],
    });

    if (response?.totalCount <= 0) {
      paginatedRequest.query
        ? setEmptyMessage('Filtreringen gav inga resultat')
        : setEmptyMessage('Inga produkter tillagda');
    }
  }, [getProducts, history, paginatedRequest, id, selectedProductCategories]);

  useEffect(() => {
    getList();
  }, [getList]);

  useEffect(() => {
    getProductFilters();
  }, [getProductFilters, id]);

  useEffect(() => {
    if (productFilters) {
      const mappedProductCategories = productFilters?.productCategories.map((x) => ({
        value: x.id.toString(),
        label: x.name,
      }));
      setProductCategories(mappedProductCategories);
    }
  }, [productFilters]);

  const handleTableClick = (product: IProduct) => {
    history.push(`${history.location.pathname}/product/${product.id}`);
  };

  return (
    <Table
      label={`Produkter (${
        products?.items?.filter((x) => (showHidden ? !x.visible || x.visible : x.visible)).length ?? 0
      })`}
      iconButtons={
        <AccessGuard module={ModuleIdentifiers.Admin} accessRights={AccessRights.Write}>
          <IconButton
            size="small"
            aria-label="Lägg till produkt"
            onClick={() => { history.push(`${history.location.pathname}/product`); }}
          >
            <AddIcon />
          </IconButton>
        </AccessGuard>
      }
      pagination={
        products && (
          <TablePaginationWithDatasource
            datasource={products}
            onChangePage={setPageNumber}
            onChangeRowsPerPage={setPageSize}
          />
        )
      }
      empty={
        products && {
          empty: products?.totalCount === 0 ? true : false,
          message: emptyMessage,
        }
      }
      filters={
        <>
          <SearchInput type="search" placeholder="Sök" onChange={setQuery} />
          <Select
            inputFieldProps={{ 'aria-label': 'Produktkategori' }}
            selectProps={{
              isClearable: true,
              isMulti: true,
              value: selectedProductCategories,
              onChange: (selectedValues) => {
                setSelectedProductCategories(selectedValues as ISelectOption[]);
              },
              options: productCategories,
            }}
          />
          <FormControlLabel
            control={<Checkbox value={showHidden} onChange={(_, checked) => toggleShowHidden(checked)} />}
            label="Visa arkiverade"
          />
        </>
      }
      loading={{
        loading: isLoading || IsUpsertProductLoading,
        skeletonRows: pageSize,
      }}
    >
      <>
        <TableHead>
          <TableRow>
            <TableCell>
              Benämning
              <SortButton
                property="name"
                sortProperty={sortProperty}
                sortDirection={sortDirection}
                onSort={toggleSort}
              />
            </TableCell>
            <TableCell>Produktkategori</TableCell>
            <TableCell>Certifiering</TableCell>
            <TableCell>Regelverk</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products?.items
            ?.filter((x) => (showHidden ? !x.visible || x.visible : x.visible))
            .map((product) => (
              <TableRow className={classes.pointerCursor} hover tabIndex={-1} key={product.id}>
                <TableCell onClick={() => handleTableClick(product)} className={classes.linkFont}>
                  <AccessGuard
                    module={ModuleIdentifiers.Admin}
                    accessRights={AccessRights.Write}
                    fallback={product.name}
                  >
                    {`${product.name} ${!product.visible ? '(Arkiverad)' : ''}`}
                  </AccessGuard>
                </TableCell>
                <TableCell onClick={() => handleTableClick(product)}>{product.productCategoryName}</TableCell>
                <TableCell
                  onClick={() => handleTableClick(product)}
                  className={`${classes.linkFont} ${getClasses(product.certifiedTo)}`}
                >
                  {formatDate(product.certifiedTo ?? undefined)}
                </TableCell>
                <TableCell>{product.regulationName}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </>
    </Table>
  );
};

export default ProductList;
