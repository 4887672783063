import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    width: '2rem',
    justifyContent: 'center',
  },
  iconButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  popper: {
    zIndex: 2,
  },
});
