import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import AdminRoutes from './Modules/Admin/Admin.routes';
import AdministrationRoutes from './Modules/Administration/Administration.routes';
import AuditRoutes from './Modules/Audit/Audit.routes';
import AuthRoutes from '../Core/Authentication/AuthRoute/AuthRoute';
import BookingRoutes from './Modules/Booking/Booking.routes';
import BusinessManagementSystemRoutes from './Modules/BMS/BMS.routes';
import CompanyRoutes from './Modules/Company/Company.routes';
import CustomerPortalRoutes from './Modules/CustomerPortal/CustomerPortal.routes';
import CustomersRoutes from './Modules/Customers/Customers.routes';
import ErrandsRoutes from './Modules/Errands/Errands.routes';
import FileSystemRoutes from './Modules/FileSystem/FileSystem.routes';
import Home from './Modules/Home/Home';
import TodosRoutes from './Modules/Todos/Todos.routes';
import { UserType } from './Modules/Admin/Components/Users/Users.api';
import { ModuleIdentifiers } from '../Core/Authentication/ModuleAccess';
import useUserContext from '../Core/Authentication/UserContext';
import CompanyUserRoute from './Modules/CompanyUser/CompanyUser.routes';

const ModuleContainer: React.FunctionComponent = (props) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div>
      <Grid container spacing={mobile ? 1 : 4}>
        {props.children}
      </Grid>
    </div>
  );
};

const CompanyUserRoutes = () => {
  const { key } = useLocation();

  return (
    <Switch>
      <Route exact path="/" key={key}>
        <Home />
      </Route>
      <AuthRoutes path="/todos" module={ModuleIdentifiers.Todo}>
        <TodosRoutes />
      </AuthRoutes>
      <AuthRoutes path="/booking" module={ModuleIdentifiers.Booking}>
        <BookingRoutes />
      </AuthRoutes>
      {/* //TODO: add orders, agreement or supplier order too */}
      <AuthRoutes
        path="/errands"
        module={[ModuleIdentifiers.Offers, ModuleIdentifiers.Orders, ModuleIdentifiers.Agreements]}
      >
        <ErrandsRoutes />
      </AuthRoutes>
      <AuthRoutes path="/customers" module={ModuleIdentifiers.Customer}>
        <CustomersRoutes />
      </AuthRoutes>
      <AuthRoutes path="/audit" module={ModuleIdentifiers.Customer}>
        <AuditRoutes />
      </AuthRoutes>
      <AuthRoutes path="/bms" module={ModuleIdentifiers.BusinessManagementSystem}>
        <BusinessManagementSystemRoutes />
      </AuthRoutes>
      <AuthRoutes path="/admin" module={ModuleIdentifiers.Admin}>
        <AdminRoutes />
      </AuthRoutes>
      <AuthRoutes path="/files" module={ModuleIdentifiers.FileSystem}>
        <FileSystemRoutes />
      </AuthRoutes>
      <AuthRoutes path="/company" userType={UserType.SuperAdmin}>
        <CompanyRoutes />
      </AuthRoutes>
      <AuthRoutes path="/administration" userType={UserType.SuperAdmin}>
        <AdministrationRoutes />
      </AuthRoutes>
      <AuthRoutes path="/user" anyModule>
        <CompanyUserRoute />
      </AuthRoutes>
      <Route path="/*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default function AppRoutes() {
  const { user } = useUserContext();

  return (
    <ModuleContainer>
      {user.userType === UserType.Customer ? <CustomerPortalRoutes /> : <CompanyUserRoutes />}
    </ModuleContainer>
  );
}
