import React, { useState, useEffect, useCallback } from 'react';
import Container from '../../../../../../../Shared/Container/Container';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../../../../../../Shared/Button/Button';
import useFormValidation from '../../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import { useGetOrder, useGetProduct, useAddProducts, IProductInformation } from '../../Order.api';
import ProductSelect from '../../../../../Admin/Components/Suppliers/ViewSupplier/Products/ProductSelect/ProductSelect';
import ProductTable, { IProductList } from './ProductTable/ProductTable';
import { useTheme, useMediaQuery, Dialog, DialogContent, InputBase } from '@material-ui/core';
import { DialogContentHeader } from '../../../../../../../Shared/DialogContentHeader/DialogContentHeader';
import DialogForm from '../../../../../../../Shared/DialogForm/DialogForm';
import { InputField } from '../../../../../../../Shared/InputFields/InputField/InputField';
import useStyles from './ProductForm.styles';
import { ViewField } from '../../../../../../../Shared/InputFields/InputField/ViewField';

const ProductForm = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { formRef } = useFormValidation();
  const { isAddProductsLoading, addProducts } = useAddProducts(id as string);
  const [productsList, setProductList] = useState<IProductList[]>([]);
  const { isLoading, order, getOrder } = useGetOrder();
  const { isLoading: productIsLoading, getProduct } = useGetProduct();
  const [selectedProductId, setSelectedProductId] = useState<string | undefined>(undefined);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    async function get() {
      const response = await getOrder(id);

      if (!response) {
        history.push('errands');
      }
    }

    get();
  }, [getOrder, history, id]);

  const addListProducts = useCallback(
    async (id: string, placement?: string, comment?: string, quantity?: number) => {
      var results = await getProduct(id);
      if (results) {
        const product: IProductList = {
          id: results.id,
          name: results.name,
          placement: placement,
          comment: comment,
          quantity: quantity,
        };
        setProductList([...productsList, product]);
      }
    },
    [productsList, getProduct],
  );

  async function handleAddProduct() {
    const command = productsList.map((x) => {
      const product: IProductInformation = {
        productId: x.id,
        placement: x.placement,
        comment: x.comment,
        quantity: x.quantity ?? 1,
      };
      return product;
    });

    await addProducts({ products: command });
    history.push(`/errands/orders/${id}`);
  }

  const ProductModal = () => {
    const [comment, setComment] = useState<string>();
    const [placement, setPlacement] = useState<string>();
    const [quantity, setQuantity] = useState<number>(1);
    const classes = useStyles();

    return (
      <Dialog open={openModal} onClose={setOpenModal}>
        <DialogContent>
          <DialogContentHeader onClose={() => setOpenModal(false)} title={'Produkt information'} titleId={''} />
          <DialogForm
            actions={
              <>
                <Button color="default" variant="outlined" onClick={() => setOpenModal(!openModal)}>
                  Avbryt
                </Button>
                <Button
                  loading={isLoading}
                  variant="outlined"
                  onClick={() => {
                    setOpenModal(false);
                    if (selectedProductId) {
                      addListProducts(selectedProductId, placement, comment, quantity);
                    }
                    setComment('');
                    setPlacement('');
                    setQuantity(1);
                  }}
                >
                  Lägg till
                </Button>
              </>
            }
          >
            <InputField
              label="Placering"
              type="text"
              value={placement}
              onChange={(event) => setPlacement(event.target.value)}
            />
            <InputField
              label="Kommentar"
              type="text"
              fullwidth
              value={comment}
              onChange={(event) => setComment(event.target.value)}
            />
            <div className={classes.inputWrapper}>
              <ViewField label="Antal:" />
              <InputBase
                className={classes.input}
                type="number"
                value={quantity}
                onChange={(event) => setQuantity(parseInt(event.target.value))}
              />
            </div>
          </DialogForm>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <>
      {openModal && <ProductModal />}
      <Container
        form
        ref={formRef}
        loading={isLoading || isAddProductsLoading}
        actions={
          <>
            <Button color="default" variant="outlined" onClick={() => history.push(`/errands/orders/${id}`)}>
              Avbryt
            </Button>
            <Button
              color="primary"
              variant="contained"
              loading={productIsLoading}
              disabled={productsList.length < 1}
              onClick={handleAddProduct}
            >
              Spara
            </Button>
          </>
        }
        label="Lägg till produkt"
      >
        <ProductSelect
          //TODO: find a better way to fetch mainProductId instead of getting a whole order
          mainProductId={order?.coreSystem?.mainProductId}
          selectedProductId={selectedProductId}
          onChange={(productId) => {
            if (mobileView) {
              setSelectedProductId(productId as string);
              setOpenModal(!openModal);
            } else {
              addListProducts(productId as string);
            }
          }}
          required
        />
      </Container>
      {productsList.length > 0 && (
        <ProductTable onChange={setProductList} products={productsList} isLoading={productIsLoading} />
      )}
    </>
  );
};

export default ProductForm;
