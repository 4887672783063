import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  red: {
    color: theme.palette.error.main,
  },
  blue: {
    color: theme.palette.primary.main,
  },
  green: {
    color: theme.palette.success.main,
  },
  yellow: {
    color: theme.palette.warning.main,
  },
  linkFont: {
    fontWeight: 700,
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  pointerCursor: {
    cursor: 'pointer',
  },
  tableCellSmall: {
    minWidth: '40px',
    width: '100px',
  },
  tableCellMedium: {
    minWidth: '100px',
  },
  tableCellEmpty: {
    border: '0',
    display: 'table-column',
    width: '6px',
  },
}));

export default useStyles;
