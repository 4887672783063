import React, { useState } from 'react';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { CircularProgress, Button as MuiButton } from '@material-ui/core';
import useStyles from './Button.styles';

export interface IButtonProps extends ButtonProps {
  loading?: boolean;
  delay?: number;
}

const Button = (props: IButtonProps) => {
  const { loading, children, disabled, delay, onClick, ...rest } = props;
  const [delayDisable, setDelayDisable] = useState(false);

  const handleOnClick = (e: any) => {
    onClick && onClick(e);

    if (delay) {
      setDelayDisable(true);
      setTimeout(() => {
        setDelayDisable(false);
      }, delay);
    }
  };

  const classes = useStyles();
  return (
    <MuiButton {...rest} onClick={handleOnClick} disabled={loading || disabled || delayDisable}>
      {loading ? (
        <CircularProgress aria-label="Laddar" disableShrink className={classes.loading} size="1.25rem" />
      ) : (
        children
      )}
    </MuiButton>
  );
};

export default Button;
