import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import useTable from '../../../../../../Shared/Table/useTable';
import { Table } from '../../../../../../Shared/Table/Table';
import SearchInput from '../../../../../../Shared/InputFields/SearchInput/SearchInput';
import {
  useGetSupplierOrders,
  useDeleteSupplierOrder,
  ISupplierListItem,
  supplierOrderStatus,
  useGetCombinedSupplierOrders,
  ICombinedSupplierListItem,
} from '../SupplierOrder.api';
import TablePaginationWithDatasource from '../../../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import {
  useMediaQuery,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import theme from '../../../../../../Styles/Theme';
import { MobileTable } from '../../../../../../Shared/Table/MobileTable/MobileTable';
import { MobileTableRow } from '../../../../../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import formatDate from '../../../../../../Shared/Formatting/formatDate';
import SortButton from '../../../../../../Shared/Table/SortButton/SortButton';
import { DropdownCell } from '../../../../../../Shared/Table/DropdownCell/DropdownCell';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import useUserContext from '../../../../../../Core/Authentication/UserContext';
import { useStyles } from '../../../../../../Shared/Table/TableRowHeader/TableRowHeader.styles';
import { SortDirection } from '../../../../../../Shared/Api/request/IPaginatedRequest';
import AccessGuard from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { AccessRights, ModuleIdentifiers } from '../../../../../../Core/Authentication/ModuleAccess';
import { ConfirmDeleteOrArchiveRow } from '../../../../../../Shared/Table/ConfirmDeleteOrArchiveRow/ConfirmDeleteOrArchiveRow';

const SupplierOrderList = () => {
  const { hasAccess } = useUserContext();

  const { isLoading: deleteIsLoading, deleteSupplierOrder } = useDeleteSupplierOrder();
  const { isLoading, supplierOrders, getCombinedSupplierOrders } = useGetCombinedSupplierOrders();

  const {
    toggleSort,
    sortProperty,
    sortDirection,
    pageSize,
    setPageSize,
    setPageNumber,
    query,
    setQuery,
    paginatedRequest,
  } = useTable(100, 'created', SortDirection.Descending);
  const [emptyMessage, setEmptyMessage] = useState('');
  const [confirmDeleteId, setConfirmDeleteId] = useState<number | undefined>();

  const mobileView = useMediaQuery(theme.breakpoints.down(1080 / 16));
  const history = useHistory();
  const classes = useStyles();

  const getSupplierOrderList = useCallback(async () => {
    await getCombinedSupplierOrders();
  }, [getCombinedSupplierOrders]);

  useEffect(() => {
    getSupplierOrderList();
  }, [getSupplierOrderList]);

  async function handleCancelOrder() {
    if (!confirmDeleteId) return;

    await deleteSupplierOrder(confirmDeleteId.toString());
    setConfirmDeleteId(undefined);
    getSupplierOrderList();
  }

  const handleTableClick = (supplierOrder: ICombinedSupplierListItem) => {
    history.push(`${history.location.pathname}/${supplierOrder.saleId}`);
  };

  return (
    <Table
      label={`Beställningar (${supplierOrders?.length ?? 0})`}
      customSize={{
        fullsize: true,
      }}
      filters={<SearchInput type="search" placeholder="Sök" onChange={setQuery} />}
      empty={{
        empty: supplierOrders && supplierOrders.length <= 0 ? true : false,
        message: emptyMessage,
      }}
      loading={{
        loading: isLoading,
        skeletonRows: pageSize,
      }}
      mobile={mobileView}
    >
      {mobileView ? (
        <>
          {supplierOrders?.map((supplierOrder) => (
            <MobileTable
              key={supplierOrder.id}
              label={`${supplierOrder.id} - ${supplierOrder.customerName} - ${formatDate(supplierOrder.created)}`}
              link={`${history.location.pathname}/${supplierOrder.id}`}
            >
              {supplierOrder.id}
              <MobileTableRow label="Beställningsnr" value={supplierOrder.id} />
              <MobileTableRow label="Order" value={supplierOrder.salesNumber} />
              <MobileTableRow label="Leverantör" value={supplierOrder.supplierNames.join(', ')} />
              <MobileTableRow label="Kund" value={supplierOrder.customerName} />
              <MobileTableRow label="Skapad" value={formatDate(supplierOrder.created)} />
              <MobileTableRow label="Status" value={supplierOrder.statuses.map(s => supplierOrderStatus[s]).join(', ')} />
              <MobileTableRow label="Leverans" value={supplierOrder.receivedDates.map(s => formatDate(s)).join(', ')} />
            </MobileTable>
          ))}
        </>
      ) : (
        <>
          <TableHead>
            <TableRow>
              {hasAccess(ModuleIdentifiers.Admin, AccessRights.Write) && (
                <TableCell>
                  <span className={'sr-only'}>Knappar</span>
                </TableCell>
              )}
              <TableCell>Beställningsnr</TableCell>
              <TableCell>
                Order
                <SortButton
                  property="salesNumber"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Leverantör
                <SortButton
                  property="supplierName"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Kund
                <SortButton
                  property="customerName"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Skapad
                <SortButton
                  property="created"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Leverans</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {supplierOrders?.map((supplierOrder) => (
              <TableRow className={classes.pointerCursor} hover tabIndex={-1} key={supplierOrder.id}>
                {confirmDeleteId !== supplierOrder.id ? (
                  <>
                    <AccessGuard
                      module={ModuleIdentifiers.SupplierOrders}
                      accessRights={AccessRights.Full}
                      fallback={<TableCell></TableCell>}
                    >
                      <DropdownCell>
                        <MenuItem onClick={() => setConfirmDeleteId(supplierOrder.id)}>
                          <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                          <Typography variant="inherit">Ta bort</Typography>
                        </MenuItem>
                      </DropdownCell>
                    </AccessGuard>
                    <TableCell onClick={() => handleTableClick(supplierOrder)} className={classes.linkFont}>
                      {supplierOrder.id}
                    </TableCell>
                    <TableCell onClick={() => handleTableClick(supplierOrder)}>{supplierOrder.salesNumber}</TableCell>
                    <TableCell onClick={() => handleTableClick(supplierOrder)}>{supplierOrder.supplierNames.join(', ')}</TableCell>
                    <TableCell onClick={() => handleTableClick(supplierOrder)}>{supplierOrder.customerName}</TableCell>
                    <TableCell onClick={() => handleTableClick(supplierOrder)}>
                      {formatDate(supplierOrder.created)}
                    </TableCell>
                    <TableCell onClick={() => handleTableClick(supplierOrder)}>
                      {supplierOrder.statuses.map(s => supplierOrderStatus[s]).join(', ')} {supplierOrder.storagePlacement ? '(' + supplierOrder.storagePlacement + ')' : '' }
                    </TableCell>
                    <TableCell onClick={() => handleTableClick(supplierOrder)}>
                      {supplierOrder.receivedDates.map(s => formatDate(s)).join(', ')}
                    </TableCell>
                  </>
                ) : (
                  <ConfirmDeleteOrArchiveRow
                    onConfirm={handleCancelOrder}
                    onCancel={() => {
                      setConfirmDeleteId(undefined);
                    }}
                    colspan={8}
                    label={`beställning med beställning nr ${supplierOrder.id.toString()}`}
                    loading={deleteIsLoading}
                  />
                )}
              </TableRow>
            ))}
          </TableBody>
        </>
      )}
    </Table>
  );
};

export default SupplierOrderList;
