import React, { useState, useEffect, useCallback } from 'react';
import { TableHead, TableRow, TableCell, TableBody, MenuItem, ListItemIcon, Typography } from '@material-ui/core';
import { Table } from '../../../../../../Shared/Table/Table';
import { useHistory } from 'react-router-dom';
import useTable from '../../../../../../Shared/Table/useTable';
import {
  useGetProductCategories,
  IProductCategory,
  useMoveUp,
  useMoveDown,
  useDeleteProductCategory,
} from '../ProductCategories.api';
import IPaginatedResponse from '../../../../../../Shared/Api/response/IPaginatedResponse';
import TablePaginationWithDatasource from '../../../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import SearchInput from '../../../../../../Shared/InputFields/SearchInput/SearchInput';
import { DropdownCell } from '../../../../../../Shared/Table/DropdownCell/DropdownCell';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import Link from '../../../../../../Shared/Link/Link';
import AccessGuard from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { ModuleIdentifiers, AccessRights } from '../../../../../../Core/Authentication/ModuleAccess';
import ChangeLevel from '../../../../../../Shared/ChangeLevel/ChangeLevel';
import { isErrorResponse, ErrorCode } from '../../../../../../Shared/Api/response/IErrorRespose';
import useErrorMessage from '../../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import { useStyles } from '../../../../../../Shared/Table/TableRowHeader/TableRowHeader.styles';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import IconButton from '../../../../../../Shared/IconButton/IconButton';
import { ConfirmDeleteOrArchiveRow } from '../../../../../../Shared/Table/ConfirmDeleteOrArchiveRow/ConfirmDeleteOrArchiveRow';

const ProductCategoryList = () => {
  const history = useHistory();
  const { isLoading, response: productCategories, getProductCategories } = useGetProductCategories();
  const { isLoading: isDeleteLoading, deleteProductCategory } = useDeleteProductCategory();
  const { pageSize, setPageSize, pageNumber, setPageNumber, paginatedRequest } = useTable();
  const { moveUp } = useMoveUp();
  const { moveDown } = useMoveDown();
  const [emptyMessage, setEmptyMessage] = useState('');
  const [confirmDeleteId, setConfirmDeleteId] = useState<number>();
  const [query, setQuery] = useState('');
  const { setErrorMessage } = useErrorMessage();
  const classes = useStyles();

  const loadList = useCallback(async () => {
    setConfirmDeleteId(undefined);
    const productCategoriesResponse: IPaginatedResponse<IProductCategory> = await getProductCategories(
      paginatedRequest,
    );

    if (productCategoriesResponse?.totalCount === 0) {
      if (query) {
        setEmptyMessage('Filtreringen gav inga resultat');
      } else {
        setEmptyMessage('Inga produktkategorier tillagda');
      }
    }
  }, [paginatedRequest, query, getProductCategories]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  async function handleMoveUp(productCategory: IProductCategory) {
    await moveUp(productCategory.id);
    loadList();
  }

  async function handleMoveDown(productCategory: IProductCategory) {
    await moveDown(productCategory.id);
    loadList();
  }

  async function handleDeleteCategory() {
    if (confirmDeleteId) {
      const result = await deleteProductCategory(confirmDeleteId.toString());
      if (isErrorResponse(result) && result.errorCode === ErrorCode.EntityInUse) {
        setErrorMessage({
          message: 'Gick inte att ta bort produktkategorin då det finns produkter kopplade till den.',
        });
        setConfirmDeleteId(undefined);
      } else {
        loadList();
      }
    }
  }

  function isUpDisabled(index: number): boolean {
    if (query) {
      return true;
    }

    return pageNumber === 1 && index === 0;
  }

  function isDownDisabled(index: number): boolean {
    if (query) {
      return true;
    }

    return productCategories?.totalPages === pageNumber && index + 1 === productCategories?.items.length;
  }

  return (
    <Table
      label={`Produktkategorier (${productCategories?.totalCount ?? 0})`}
      minWidth
      iconButtons={
        <AccessGuard module={ModuleIdentifiers.Admin} accessRights={AccessRights.Write}>
          <IconButton
            size="small"
            aria-label="Lägg till kategori"
            onClick={() => {
              history.push(`${history.location.pathname}/create`);
            }}
          >
            <AddIcon />
          </IconButton>
        </AccessGuard>
      }
      pagination={
        productCategories && (
          <TablePaginationWithDatasource
            datasource={productCategories}
            onChangePage={setPageNumber}
            onChangeRowsPerPage={setPageSize}
          />
        )
      }
      empty={
        productCategories && {
          empty: productCategories?.totalCount === 0 ? true : false,
          message: emptyMessage,
        }
      }
      filters={<SearchInput type="search" placeholder="Sök produktkategorier" onChange={setQuery} />}
      loading={{
        loading: isLoading,
        skeletonRows: pageSize,
      }}
    >
      <>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Namn</TableCell>
            <AccessGuard module={ModuleIdentifiers.Admin} accessRights={AccessRights.Write}>
              <TableCell></TableCell>
            </AccessGuard>
          </TableRow>
        </TableHead>
        <TableBody>
          {productCategories?.items?.map((productCategory, index) => (
            <TableRow hover tabIndex={-1} key={productCategory.id}>
              {confirmDeleteId !== productCategory.id ? (
                <>
                  <AccessGuard
                    module={ModuleIdentifiers.Admin}
                    accessRights={AccessRights.Full}
                    fallback={<TableCell className={classes.narrowColumn}></TableCell>}
                  >
                    <DropdownCell>
                      <MenuItem
                        onClick={() => {
                          setConfirmDeleteId(productCategory.id);
                        }}
                      >
                        <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                        <Typography variant="inherit">Ta bort</Typography>
                      </MenuItem>
                    </DropdownCell>
                  </AccessGuard>
                  <TableCell>
                    <Link
                      to={`${history.location.pathname}/${productCategory.id}/edit`}
                      module={ModuleIdentifiers.Admin}
                      accessRights={AccessRights.Write}
                    >
                      {productCategory.name}
                    </Link>
                  </TableCell>
                  <AccessGuard module={ModuleIdentifiers.Admin} accessRights={AccessRights.Write}>
                    <TableCell>
                      <ChangeLevel
                        up={{
                          onClick: () => {
                            handleMoveUp(productCategory);
                          },
                          disabled: isUpDisabled(index),
                        }}
                        down={{
                          onClick: () => {
                            handleMoveDown(productCategory);
                          },
                          disabled: isDownDisabled(index),
                        }}
                      />
                    </TableCell>
                  </AccessGuard>
                </>
              ) : (
                <ConfirmDeleteOrArchiveRow
                  onConfirm={() => handleDeleteCategory()}
                  onCancel={() => setConfirmDeleteId(undefined)}
                  colspan={3}
                  label={productCategory.name}
                  loading={isDeleteLoading}
                />
              )}
            </TableRow>
          ))}
        </TableBody>
      </>
    </Table>
  );
};

export default ProductCategoryList;
