import { makeStyles, Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginBottom: '1.25rem',
      minWidth: '100%',
      [theme.breakpoints.up('md')]: {
        minWidth: '18rem',
      },
    },
    skeletonLabel: {
      height: `${19 / 16}rem`,
      marginBottom: '0.25rem',
      marginRight: '2rem',
    },
    skeletonInput: {
      height: `${19 / 16}rem`,
      flexGrow: 1,
      marginRight: '2rem',
    },
    skeletonRow: {
      display: 'flex',
      alignItems: 'center',
    },
    skeletonRadioButton: {
      width: '1.25rem',
      height: '1.25rem',
      marginRight: '0.5rem',
      marginTop: '0.25rem',
      marginBottom: '0.25rem',
    },
  }),
);
