// this is a dummy Data used for preview in the RichTextEditor
export const PreselectionPreviewDummyData = (
  header: string | undefined,
  footer: string | undefined,
  body: string | undefined,
  termsAndConditions: string | undefined,
) => {
  return `
  <!doctype html>
  <html style="font: small-caption;">
  <body style="font: small-caption">
  <div style="margin: 10%;font: -webkit-control;">
      <div
          style=" ${
            body ? 'height: 24cm;' : ''
          } padding: 2%; box-shadow: 2px 2px 2px grey, -2px -2px 2px grey, 2px -2px 2px grey, -2px 2px 2px grey; margin-top: -50px !important;">

          ${
            header
              ? `<div class="headerStyle" style="margin: 10px;">${header} </div>`
              : ` Låt oss skydda dina värden, Företaget erbjuder försäkringsgodkända lösningar inom inbrottslarm, brandlarm,
          utrymningslarm och kamerasystem. Vi utgår från Göteborg och Stockholm.`
          }
          <br>
          ${
            body
              ? `<div style="margin-left: 3%;">${body}</div>`
              : `<div style="position: relative;background-color: white;margin-top: -30px;">
              <h1>Avtal</h1>
              <section class="information"
                  style=" margin-top: 0.25cm;display: flex;justify-content: space-between;padding-bottom: 1em;border-bottom: 1px solid #e0e0e0;">
                  <table class="ref" style="display: flex; color: #626262; border-collapse: collapse;">
                      <tbody style="height: 10%;">
                          <tr>
                              <td>Skapad datum:</td>
                              <td>2021-10-04</td>
                          </tr>
                          <tr>
                              <td>OBS VIKTIG HANDLING</td>
                          </tr>
                          <tr>
                              <td>Sparas i systempärm</td>
                          </tr>
                      </tbody>
                  </table>

                  <table class="adress" style="border-collapse: collapse; text-align: left; margin-right: 10%;">
                      <tbody>
                          <tr>
                              <td>&#xD6;rn Haraldsson</td>
                          </tr>
                          <tr>
                              <td>Att: &#xD6;rn Haraldsson</td>
                          </tr>
                          <tr>
                              <td>Tungusida</td>
                          </tr>
                          <tr>
                              <td>60300 Akureyri</td>
                          </tr>
                      </tbody>
                  </table>
              </section>
              <section>
                  <h2>Välkommen som kund hos Spinit Ab</h2>
                  <p>
                      Vi hälsar dig välkommen som kund och skickar här en bekräftelse på
                      avtalet du tecknat med oss. Om uppgifterna är felaktiga skall du
                      kontakta vår kundtjänst på 0728344894 inom 14 dagar.
                  </p>
              </section>

              <section>
                  <h2>Avtal 28-16 Inbrottslarm Central</h2>
                  <table class="table">
                      <tbody style="text-align: justify;">
                          <tr>
                              <th>Kund:</th>
                              <td>&#xD6;rn Haraldsson</td>
                          </tr>
                          <tr>
                              <th>Adress:</th>
                              <td>Tungusida</td>
                          </tr>
                          <tr>
                              <th></th>
                              <td>60300 Akureyri</td>
                          </tr>
                          <tr>
                              <th>Org / Person nr:</th>
                              <td>126543-1423</td>
                          </tr>
                          <tr>
                              <th>Beställare:</th>
                              <td>&#xD6;rn Haraldsson</td>
                          </tr>
                          <tr>
                              <th>Telefon:</th>
                              <td></td>
                          </tr>
                          <tr>
                              <th></th>
                              <td></td>
                          </tr>
                          <tr>
                              <th>Avtalstid:</th>
                              <td>2021-04-01 till 2022-04-01</td>
                          </tr>
                          <tr>
                              <th>Fakturaintervall:</th>
                              <td>M&#xE5;nad</td>
                          </tr>
                          <tr>
                              <th>Månadsavgift:</th>
                              <td>88885 kr/månad</td>
                          </tr>
                          <tr>
                              <th></th>
                              <td></td>
                          </tr>
                          <tr>
                              <th>Avtalsvillkor:</th>
                              <td>Se sidan 2</td>
                          </tr>
                          <tr style="vertical-align: top;">
                              <th>Avtalet inkluderar:</th>
                              <td>
                                  <ul class="included-list"
                                      style="list-style: none; margin-block-start: 0; margin-block-end: 0; margin-inline-start: 0px; margin-inline-end: 0px; padding-inline-start: 0;">
                                      <li> Larmcentral</li>
                                      <li>Vaktbolag</li>
                                      <li>Fri larmutryckningar</li>
                                      <li>Grundservice</li>
                                      <li>Revisionsavtal 1 &#xE5;r</li>
                                      <li>Hyra av system</li>
                                      <li>S&#xE4;ker &#xF6;verf&#xF6;ring via dualtech</li>
                                      <li>Nyckelf&#xF6;rvaring och inre kontroll</li>
                                  </ul>
                              </td>
                          </tr>

                          <tr>
                              <th></th>
                              <td></td>
                          </tr>

                          <tr>
                              <th>Noteringar:</th>
                              <td></td>
                          </tr>
                      </tbody>
                  </table>
                  <section class="from">
                      <p>Med vänliga hälsningar</p>
                      <p>Spinit Ab</p>
                      <p>Kundservice</p>
                  </section>
              </section>
              ${
                footer
                  ? body
                    ? ` <div style="position: relative;">
                  <div
                      style="width: 100% ;position: absolute;top: 18.25cm; padding-top: 0.25cm; border-top: 1px solid #e0e0e0;">
                      ${footer}`
                    : `<div style="padding-top: 0.25cm; border-top: 1px solid #e0e0e0; width: 100%;">${footer}</div>
                      `
                  : `
                      <footer id="footer-template"
                          style="padding-top: 0.25cm; border-top: 1px solid #e0e0e0; width: 100%;">
                          <div class="footer-inner"
                              style="display: flex; justify-content: space-between; width: 100%;">

                              <div>
                                  <span style="margin: 0; color: #484848; font-weight: 300; line-height: 1.5;">TEST
                                      Ab</span>
                                  <br>
                                  <span
                                      style="margin: 0; color: #484848; font-weight: 300; line-height: 1.5;">GBG</span>
                                  <br>
                                  <span style="margin: 0; color: #484848; font-weight: 300; line-height: 1.5;">73894
                                      Torslanda</span>
                              </div>
                              <div style="text-align: center;">
                                  <span
                                      style="margin: 0; color: #484848; font-weight: 300; line-height: 1.5;">Telefon:
                                      0728344894</span>
                                  <br>
                                  <span style="margin: 0; color: #484848; font-weight: 300; line-height: 1.5;">E-post:
                                      ornharalds@gmail.com</span>
                              </div>
                              <div style="text-align: right;">
                                  <span style="margin: 0;color: #484848; font-weight: 300; line-height: 1.5;">Org Nr:
                                      910112-1025</span>
                                  <br>
                                  <span style="margin: 0; color: #484848; font-weight: 300; line-height: 1.5;">Innehar
                                      F-skattebvis </span>
                                  <br>
                                  <span
                                      style="margin: 0; color: #484848; font-weight: 300; line-height: 1.5;">Certifikat
                                      C 019234</span>
                              </div>
                          </div>
                      </footer>`
              }
                  </div>
              </div>
              <!-- New page -->
              <!-- my page break -->


              <p style="page-break-before: always; ">&nbsp;</p>
              <div
                  style="height: 29cm; margin-top: 2%; padding: 2%; box-shadow: 2px 2px 2px grey, -2px -2px 2px grey, 2px -2px 2px grey, -2px 2px 2px grey; position: relative;">
                  ${
                    header
                      ? `<div class="headerStyle">${header} </div>`
                      : ` Låt oss skydda dina värden, Företaget erbjuder försäkringsgodkända lösningar inom inbrottslarm,
                  brandlarm, utrymningslarm och kamerasystem. Vi utgår från Göteborg och Stockholm.`
                  }
                  <br>
                  <section style="page-break-before: always;" &nbsp;>
                      ${
                        termsAndConditions ??
                        `<section class="page -break-before">
                          <section>
                              <h2>Avtalsvillkor</h2>
                              <p>Avtalsvillkor</p>
                          </section>
                          <section>
                              <h3>Allm&auml;nt</h3>
                              <p>Enligt distansavtalslagen har du 14 dagars &aring;ngerr&auml;tt fr&aring;n och med
                                  den dag du mottagit denna bekr&auml;ftelse.Avtalet g&auml;ller enligt avtalad period
                                  och f&ouml;rl&auml;ngs automatiskt med 12 m&aring;nader i taget.Upps&auml;gning av
                                  detta avtal skall ske skriftligen senast 3 m&aring;nader f&ouml;re avtalsperiodens
                                  slut. &Ouml;verl&aring;telse av avtal skall anm&auml;las till Företaget skriftligen senast
                                  30 dagar innan &ouml;verl&aring;tandet p&aring; s&auml;rskild blankett som skickas
                                  ut fr&aring;n kundservice.Vid utebliven betalning kopplas larmtj&auml;nsten ur tills
                                  betalningen &auml;r till fullo samt att larmintyg till f&ouml;rs&auml;kringsbolag
                                  &aring;terkallas..</p>
                          </section>
                          <section>
                              <h3>Priser</h3>
                              <p>Prisjustering sker enligt avtal med 4 % per kalender&aring;r eller enligt
                                  h&ouml;jning av ber&ouml;rd samarbetspartner vid underavtal.</p>
                          </section>
                          <section>
                              <h3>Larmcentral</h3>
                              <p>Uppkoppling till av Företagets godk&auml;nd larmcentral samt kontaktlista med upp till
                                  10 kontaktpersoner.F&ouml;r&auml;ndring av kontaktlista eller kontaktpersoner eller
                                  rutiner skall ske via mail eller via Företagets kundservice. Meddelande p&aring;
                                  utf&ouml;rd f&ouml;r&auml;ndring sker skriftligen f&ouml;rst efter
                                  f&ouml;r&auml;ndringen &auml;r inf&ouml;rd och i drift. F&ouml;r&auml;ndringar
                                  debiteras enligt g&auml;llande prislista.</p>
                          </section>
                          <section>
                              <h3>Nyckelf&ouml;rvaring</h3>
                              <p>Samtliga nycklar hanteras direkt av kund &amp; ber&ouml;rt bevakningsbolag Det
                                  &auml;r kundens ansvar att hantera alla nycklar.Inl&auml;mnade nycklar kan
                                  h&auml;mtas mot uppvisande av godk&auml;nd legitimation mot avgift enligt
                                  g&auml;llande prislista. Nycklar som inte h&auml;mtas inom 30 dagar fr&aring;n och
                                  med att avtalet &auml;r avslutat, g&aring;r automatiskt till destruktion och kan
                                  inte &aring;terf&aring;s.</p>
                          </section>
                          <section>
                              <h3>Larmutryckning</h3>
                              <p>Utryckningar debiteras fr&aring;n Företaget om ej annat anges.Fria utryckningar
                                  inneb&auml;r att sj&auml;lva kostnaden f&ouml;r larmutryckning inte debiteras. Vid
                                  skada debiteras alltid material f&ouml;r att laga, s&auml;kra objekt samt
                                  ev.vakth&aring;llning. Vid uppenbart slarv eller f&ouml;rsummelse, utebliven
                                  motringning debiteras larmutryckning.</p>
                          </section>
                          <section>
                              <h3>&Aring;rlig kontroll / revision</h3>
                              <p>Normalt intervall f&ouml;r besiktning &auml;r 12 m&aring;nader, f&ouml;r f&ouml;retag
                                  och 36 m&aring;nader f&ouml;r privatpersoner.Ovanst&aring;ende larminnehavare
                                  f&ouml;rbinder sig att enligt detta avtal f&ouml;lja de r&aring;d och anvisningar
                                  f&ouml;r anl&auml;ggningens handhavande som l&auml;mnas av installat&ouml;ren,
                                  larmcentral eller vaktbolag. Revision inneb&auml;r att kontroll av hela eller delar
                                  av anl&auml;ggningen enligt g&auml;llande regler eller larmklass,
                                  intyg.Larminnehavaren &auml;r skyldig att till Företaget anm&auml;la
                                  f&ouml;r&auml;ndringar av lokaler eller andra omst&auml;ndigheter som kan
                                  p&aring;verka anl&auml;ggningens s&auml;kerhet, funktion eller larmklass hos
                                  ber&ouml;rt f&ouml;rs&auml;kringsbolag. Larminnehavaren f&ouml;rbinder sig att
                                  l&auml;mna tilltr&auml;de till erforderliga utrymmen utan dr&ouml;jsm&aring;l vid
                                  service / revision.</p>
                          </section>
                          <section>
                              <h3>Hyra</h3>
                              <p>Hyrd utrustning &auml;r Företagets egendom och f&aring;r ej s&auml;ljas
                                  pants&auml;ttas. Uppkommer skador skall dessa ers&auml;ttas enligt g&auml;llande
                                  prislista. Larminnehavaren ger Företaget r&auml;tt att demontera utrustningen. Företaget
                                  svarar inte f&ouml;r eventuella skador/ borrh&aring;l,skruvh&aring;l eller
                                  m&aring;lningsbehov som kan uppkomma i samband med demontering av utrustningen.</p>
                          </section>
                      </section>`
                      }
                  </section>`
          }


                  <div style="position: static;">
                      ${
                        footer
                          ? body
                            ? ` <div
                          style="padding-top: 0.25cm; width: 75%;position: absolute;top: 25.25cm;  border-top: 1px solid #e0e0e0;">
                          ${footer}</div>`
                            : `<div style="padding-top: 0.25cm; border-top: 1px solid #e0e0e0; width: 100%;">${footer}</div>
                      `
                          : `
                      <footer id="footer-template"
                          style="bottom: 10px;padding-top: 0.25cm;border-top: 1px solid #e0e0e0;width: 95%;position: absolute;">
                          <div class="footer-inner"
                              style=" display: flex; justify-content: space-between; width: 100%;">

                              <div>
                                  <span style="margin: 0; color: #484848; font-weight: 300;line-height: 1.5;">TEST
                                      Ab</span>
                                  <br>
                                  <span
                                      style="margin: 0; color: #484848; font-weight: 300; line-height: 1.5;">GBG</span>
                                  <br>
                                  <span style="margin: 0; color: #484848; font-weight: 300; line-height: 1.5;">73894
                                      Torslanda</span>
                              </div>
                              <div style="text-align: center;">
                                  <span
                                      style="margin: 0; color: #484848; font-weight: 300; line-height: 1.5;">Telefon:
                                      0728344894</span>
                                  <br>
                                  <span style="margin: 0; color: #484848; font-weight: 300; line-height: 1.5;">E-post:
                                      example@gmail.com</span>
                              </div>
                              <div style="text-align: right;">
                                  <span style="margin: 0; color: #484848; font-weight: 300; line-height: 1.5;">Org Nr:
                                  XXXXXX-XXXX</span>
                                  <br>
                                  <span style="margin: 0; color: #484848; font-weight: 300; line-height: 1.5;">Innehar
                                      F-skattebvis </span>
                                  <br>
                                  <span
                                      style="margin: 0; color: #484848; font-weight: 300; line-height: 1.5;">Certifikat
                                      C 019234</span>
                              </div>
                          </div>
                      </footer>`
                      }
                  </div>
              </div>
          </div>
      </div>

</body>
  </html>`;
};
