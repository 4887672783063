import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    marginBottom: '0.5rem',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: '1rem',
    borderRadius: theme.shape.borderRadius,
  },
  message: {
    flexGrow: 1,
    cursor: 'pointer',
  },
  dropdown: {
    marginLeft: '1rem',
  },
  customer: {
    marginBottom: '0.5rem',
  },
  list: {
    padding: 0,
    margin: 0,
    listStyleType: 'none',
  },
  remove: {
    color: theme.palette.error.dark,
  },
}));
