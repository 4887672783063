import { Button, Dialog, DialogContent, Typography } from '@material-ui/core';
import React from 'react';
import { DialogContentHeader } from '../../../../../../Shared/DialogContentHeader/DialogContentHeader';
import DialogForm from '../../../../../../Shared/DialogForm/DialogForm';
import { Fieldset } from '../../../../../../Shared/Form/Fieldset/Fieldset';
import formatDate from '../../../../../../Shared/Formatting/formatDate';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import RichTextEditor from '../../../../../../Shared/RichTextEditor/RichTextEditor';
import { IBMSSnapshotDto } from '../../../BMS.api';
import { useStyles } from './ViewOldVersionModal.styles';

export interface IViewOldVersionModal {
  snapshot?: IBMSSnapshotDto;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const ViewOldVersionModal = (props: IViewOldVersionModal) => {
  const { open, snapshot, setOpen } = props;
  const classes = useStyles();
  const Action = () => (
    <>
      <Button color="default" variant="outlined" onClick={() => setOpen(false)}>
        Stänga
      </Button>
    </>
  );

  return (
    <Dialog fullWidth className={classes.dialog} open={open} onClose={setOpen}>
      <DialogContent>
        <DialogContentHeader onClose={() => setOpen(false)} title={'Visa gamla avsnitt version'} titleId="" />
        <DialogForm CustomWidth={100} actions={<Action />}>
          {snapshot && (
            <>
              <Fieldset legend={`${snapshot.sectionNumber ?? ''} ${snapshot.name}`}>
                <InputField label="Skapad" type="text" value={formatDate(snapshot.created)} viewOnly />
              </Fieldset>
              <Typography component="label" variant="h4" className={`${classes.textLabel}`}>
                Plats och Innehåll
              </Typography>
              <div className={classes.oldVersionContainer}>
                <RichTextEditor viewOnly value={snapshot.content} />
              </div>
            </>
          )}
        </DialogForm>
      </DialogContent>
    </Dialog>
  );
};

export default ViewOldVersionModal;
