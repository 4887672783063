import React, { useState } from 'react';
import useInputState from '../../../../../Shared/Hooks/UseInputState/UseInputState';
import { InputField } from '../../../../../Shared/InputFields/InputField/InputField';
import CompanyUserSelect from '../../../Admin/Components/Users/UserSelect/CompanyUserSelect';
import { ISelectOption } from '../../../../../Shared/Select/Select';
import { IUpsertCompanyUserMessageRequestBody, IGetCompanyUserMessagesResult } from '../../Forum.api';
import Button from '../../../../../Shared/Button/Button';
import { useStyles } from '../CompanyUserMessage.styles';
import DialogForm from '../../../../../Shared/DialogForm/DialogForm';
import useFormValidation from '../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import AccessGuard from '../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { AccessRights, ModuleIdentifiers } from '../../../../../Core/Authentication/ModuleAccess';

export interface ICompanyUserMessageFormProps {
  onCancel: () => void;
  onSave: (body: IUpsertCompanyUserMessageRequestBody, id?: string) => void;
  message?: IGetCompanyUserMessagesResult;
}

export default function CompanyUserMessageForm(props: ICompanyUserMessageFormProps) {
  const classes = useStyles();
  const { isFormValid, formRef } = useFormValidation();
  const [description, changeDescription] = useInputState(props.message ? props.message.description : '');
  const [selectedUsers, setSelectedUsers] = useState<string[]>(
    props.message?.recipientCompanyUserIds ? props.message.recipientCompanyUserIds.map((x) => x.toString()) : [],
  );
  const [allUsersSelected, setAllUsersSelected] = useState<boolean>(
    props.message ? props.message.allCompanyUsersSelected : false,
  );

  function valid() {
    return (selectedUsers.length > 0 || allUsersSelected) && isFormValid;
  }

  function saveMessage() {
    const body: IUpsertCompanyUserMessageRequestBody = {
      allCompanyUsersSelected: allUsersSelected,
      description: description,
      companyUserIds: selectedUsers,
    };

    props.onSave(body, props.message?.id?.toString());
  }

  function updateSelectedUsers(option?: ISelectOption | ISelectOption[], allOptionsSelected?: boolean) {
    if (option && Array.isArray(option)) {
      setAllUsersSelected(allOptionsSelected ?? false);
      setSelectedUsers(option.map((x) => x.value));
    }
  }

  const Actions = () => (
    <>
      <Button variant="outlined" onClick={props.onCancel}>
        Avbryt
      </Button>
      <AccessGuard module={ModuleIdentifiers.Forum} accessRights={AccessRights.Write}>
        <Button
          color="primary"
          variant="contained"
          className={classes.save}
          disabled={!valid()}
          onClick={() => saveMessage()}
        >
          Spara
        </Button>
      </AccessGuard>
    </>
  );

  return (
    <>
      <DialogForm actions={<Actions />} ref={formRef}>
        <AccessGuard
          module={ModuleIdentifiers.Forum}
          accessRights={AccessRights.Write}
          fallback={
            props.message?.allCompanyUsersSelected ? (
              <h4 style={{ padding: '0.5rem' }}>Till: Alla</h4>
            ) : props.message?.recipientNames && props.message?.recipientNames?.length < 4 ? (
              <h4 style={{ padding: '0.5rem' }}>Till: {props.message?.recipientNames.join(', ')}</h4>
            ) : (
              <h4 style={{ padding: '0.5rem' }}>Till: Fyra eller fler mottagare</h4>
            )
          }
        >
          <CompanyUserSelect
            isMulti={true}
            label="Välj mottagare"
            fullwidth
            required
            placeholder="Användare"
            defaultAll={!props.message}
            companyUserIds={props.message?.recipientCompanyUserIds}
            selectedCompanyUserId={selectedUsers}
            onChange={(_1, _2, options, allOptionsSelected) => {
              updateSelectedUsers(options, allOptionsSelected);
            }}
            addSelectAllOption={true}
            pageSize={100}
          />
        </AccessGuard>
        <AccessGuard
          module={ModuleIdentifiers.Forum}
          accessRights={AccessRights.Write}
          fallback={
            <InputField readOnly label="Beskrivning" type="text" fullwidth multiline rows="5" value={description} />
          }
        >
          <InputField
            label="Beskrivning"
            type="text"
            fullwidth
            multiline
            rows="5"
            value={description}
            onChange={changeDescription}
            required
          />
        </AccessGuard>
      </DialogForm>
    </>
  );
}
