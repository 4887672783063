import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetSuppliers, useDeleteSupplier, supplierTypes, useSendSurveySupplier, ISupplier } from '../Suppliers.api';
import { Table } from '../../../../../../Shared/Table/Table';
import TablePaginationWithDatasource from '../../../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ListItemIcon,
  Typography,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import SortButton from '../../../../../../Shared/Table/SortButton/SortButton';
import useTable from '../../../../../../Shared/Table/useTable';
import SearchInput from '../../../../../../Shared/InputFields/SearchInput/SearchInput';
import AccessGuard from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { ModuleIdentifiers, AccessRights } from '../../../../../../Core/Authentication/ModuleAccess';
import useUserContext from '../../../../../../Core/Authentication/UserContext';
import { DropdownCell } from '../../../../../../Shared/Table/DropdownCell/DropdownCell';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import { isErrorResponse, ErrorCode } from '../../../../../../Shared/Api/response/IErrorRespose';
import useErrorMessage from '../../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import { MobileTable } from '../../../../../../Shared/Table/MobileTable/MobileTable';
import { MobileTableRow } from '../../../../../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import { EmailRounded } from '@material-ui/icons';
import Snackbar from '../../../../../../Shared/Hooks/UseErrorMessage/Snackbar/Snackbar';
import { useStyles } from '../../../../../../Shared/Table/TableRowHeader/TableRowHeader.styles';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import IconButton from '../../../../../../Shared/IconButton/IconButton';
import { ConfirmDeleteOrArchiveRow } from '../../../../../../Shared/Table/ConfirmDeleteOrArchiveRow/ConfirmDeleteOrArchiveRow';

const SupplierList = () => {
  const theme = useTheme();
  const { isLoading, response: suppliers, getSuppliers } = useGetSuppliers();
  const { isLoading: isDeleteLoading, deleteSupplier } = useDeleteSupplier();
  const { sendSurvey } = useSendSurveySupplier();
  const {
    toggleSort,
    sortProperty,
    sortDirection,
    pageSize,
    setPageSize,
    setPageNumber,
    setQuery,
    paginatedRequest,
  } = useTable();
  const { hasAccess } = useUserContext();
  const { setErrorMessage } = useErrorMessage();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const [emptyMessage, setEmptyMessage] = useState('');
  const [confirmDeleteId, setConfirmDeleteId] = useState<number>();
  const [openConfirmText, setOpenConfirmText] = useState(false);
  const canEdit = hasAccess(ModuleIdentifiers.Admin, AccessRights.Write);

  const history = useHistory();
  const classes = useStyles();

  const getList = useCallback(
    async function () {
      setConfirmDeleteId(undefined);
      const response = await getSuppliers({
        ...paginatedRequest,
        sortProperty: sortProperty === '' ? 'name' : sortProperty,
        supplierType: null,
      });

      if (response?.totalCount <= 0) {
        if (paginatedRequest.query) {
          setEmptyMessage('Filtreringen gav inga resultat');
        } else {
          setEmptyMessage('Inga leverantörer tillagda');
        }
      }
    },
    [paginatedRequest, getSuppliers, sortProperty],
  );

  useEffect(() => {
    getList();
  }, [getList]);

  async function handleDelete() {
    if (confirmDeleteId) {
      const result = await deleteSupplier(confirmDeleteId.toString());
      if (isErrorResponse(result) && result.errorCode === ErrorCode.EntityInUse) {
        setErrorMessage({ message: 'Gick inte att ta bort leverantören då den används' });
        setConfirmDeleteId(undefined);
      } else {
        getList();
      }
    }
  }

  const handleSendSurvey = async (supplierId: string) => {
    await sendSurvey(supplierId);
    setOpenConfirmText(true);
  };

  const handleTableClick = (supplier: ISupplier) => {
    history.push(`${history.location.pathname}/${supplier.id}`);
  };

  return (
    <>
      <Table
        label={`Leverantörer (${suppliers?.totalCount ?? 0})`}
        minWidth
        mobile={mobileView}
        filters={<SearchInput type="search" placeholder="Sök" onChange={setQuery} />}
        iconButtons={
          <AccessGuard module={ModuleIdentifiers.Admin} accessRights={AccessRights.Write}>
            <IconButton
              size="small"
              aria-label="Lägg till leverantör"
              onClick={() => {
                history.push(`${history.location.pathname}/create`);
              }}
            >
              <AddIcon />
            </IconButton>
          </AccessGuard>
        }
        pagination={
          suppliers && (
            <TablePaginationWithDatasource
              datasource={suppliers}
              onChangePage={setPageNumber}
              onChangeRowsPerPage={setPageSize}
            />
          )
        }
        empty={{
          empty: suppliers && suppliers.totalCount <= 0 ? true : false,
          message: emptyMessage,
        }}
        loading={{
          loading: isLoading,
          skeletonRows: pageSize,
        }}
      >
        {mobileView ? (
          <>
            {suppliers?.items.map((supplier) => (
              <MobileTable
                key={supplier.id}
                label={supplier.name}
                link={`${history.location.pathname}/${supplier.id}`}
                confirmDeleteRow={{
                  show: confirmDeleteId === supplier.id,
                  onConfirm: handleDelete,
                  onCancel: () => setConfirmDeleteId(-1),
                  loading: isDeleteLoading,
                }}
                action={
                  <DropdownCell mobile={mobileView}>
                    <AccessGuard module={ModuleIdentifiers.Admin} accessRights={AccessRights.Full}>
                      <MenuItem
                        onClick={() => {
                          setConfirmDeleteId(supplier.id);
                        }}
                      >
                        <ListItemIcon>
                          <DeleteIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">Ta bort</Typography>
                      </MenuItem>
                    </AccessGuard>
                    <AccessGuard module={ModuleIdentifiers.Admin} accessRights={AccessRights.Write}>
                      <MenuItem onClick={() => handleSendSurvey(supplier.id.toString())}>
                        <ListItemIcon>{<EmailRounded />}</ListItemIcon>
                        <Typography variant="inherit">Skicka enkät</Typography>
                      </MenuItem>
                    </AccessGuard>
                  </DropdownCell>
                }
              >
                <MobileTableRow label="Namn" value={supplier.name} />
                <MobileTableRow label="Vårt kundnummer" value={supplier.customerNumber} />
                <MobileTableRow label="Typ" value={supplierTypes.find((x) => x.type === supplier.supplierType)?.name} />
              </MobileTable>
            ))}
          </>
        ) : (
          <>
            <TableHead>
              <TableRow>
                {canEdit && <TableCell></TableCell>}
                <TableCell>
                  Namn
                  <SortButton
                    property="name"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>
                <TableCell>
                  Vårt Kundnummer
                  <SortButton
                    property="customerNumber"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>
                <TableCell>
                  Typ
                  <SortButton
                    property="supplierType"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {suppliers?.items.map((supplier) => (
                <TableRow className={classes.pointerCursor} hover tabIndex={-1} key={supplier.id}>
                  {confirmDeleteId !== supplier.id ? (
                    <>
                      <DropdownCell>
                        <AccessGuard module={ModuleIdentifiers.Admin} accessRights={AccessRights.Full}>
                          <MenuItem
                            onClick={() => {
                              setConfirmDeleteId(supplier.id);
                            }}
                          >
                            <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                            <Typography variant="inherit">Ta bort</Typography>
                          </MenuItem>
                        </AccessGuard>
                        <AccessGuard module={ModuleIdentifiers.Admin} accessRights={AccessRights.Write}>
                          <MenuItem onClick={() => handleSendSurvey(supplier.id.toString())}>
                            <ListItemIcon>{<EmailRounded />}</ListItemIcon>
                            <Typography variant="inherit">Skicka enkät</Typography>
                          </MenuItem>
                        </AccessGuard>
                      </DropdownCell>

                      <TableCell onClick={() => handleTableClick(supplier)} className={classes.linkFont}>
                        {supplier.name}
                      </TableCell>
                      <TableCell onClick={() => handleTableClick(supplier)}>{supplier.customerNumber}</TableCell>
                      <TableCell onClick={() => handleTableClick(supplier)}>
                        {supplierTypes.find((x) => x.type === supplier.supplierType)?.name}
                      </TableCell>
                    </>
                  ) : (
                    <ConfirmDeleteOrArchiveRow
                      onConfirm={handleDelete}
                      onCancel={() => setConfirmDeleteId(-1)}
                      colspan={4}
                      label={supplier.name}
                      loading={isDeleteLoading}
                    />
                  )}
                </TableRow>
              ))}
            </TableBody>
          </>
        )}
      </Table>
      <Snackbar
        message={'Enkät skickat.'}
        open={openConfirmText}
        severity={'success'}
        onClose={() => setOpenConfirmText(false)}
      />
    </>
  );
};

export default SupplierList;
