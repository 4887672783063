import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Container from '../../../Shared/Container/Container';
import Button from '../../../Shared/Button/Button';
import Success from '../Success/Success';
import { isErrorResponse, ErrorCode } from '../../../Shared/Api/response/IErrorRespose';
import { useConfirmCoreSystemUpdate, useGetSystemPdf, IContactInfoDto } from './SecurityCompany.api';
import { useCheckSupplierHasCodeAnonymous, useConfirmSupplierCodeAnonymous } from '../Supplier/Supplier.api';
import DownloadButton from '../../../Shared/DownloadButton/DownloadButton';
import { InputField } from '../../../Shared/InputFields/InputField/InputField';
import useInputState from '../../../Shared/Hooks/UseInputState/UseInputState';

const SecurityCompanyConfirmForm = () => {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const { isLoading: isCheckingHasCodeLoading, response: supplierHasCode, get } = useCheckSupplierHasCodeAnonymous(
    token,
  );
  const { isLoading: isConfirmCodeLoading, response: isCodeCorrect, confirmCode } = useConfirmSupplierCodeAnonymous(
    token,
  );
  const { isConfirmLoading, confirm } = useConfirmCoreSystemUpdate(token);
  const { isLoading: isPdfLoading, getPdf } = useGetSystemPdf(token);
  const [success, setSuccess] = useState(false);
  const [contactInfo, setContactInfo] = useState<IContactInfoDto>();
  const [code, changeCode] = useInputState('');

  useEffect(() => {
    if (!token) {
      history.push('');
    }
  }, [token, history]);

  useEffect(() => {
    async function checkSupplierCode() {
      const result = await get();
      if (isErrorResponse(result) && result.errorCode === ErrorCode.AnonymousTokenNotFound) {
        history.push(`${history.location.pathname}/404`);
        return;
      }
    }
    checkSupplierCode();
  }, [history, get]);

  async function confirmSupplierCode() {
    const result = await confirmCode({ code: code });

    if (isErrorResponse(result) && result.errorCode === ErrorCode.AnonymousTokenNotFound) {
      history.push(`${history.location.pathname}/404`);
      return;
    }
  }

  async function confirmChanges() {
    const result = await confirm();

    if (isErrorResponse(result) && result.errorCode === ErrorCode.AnonymousTokenNotFound) {
      history.push(`${history.location.pathname}/404`);
      return;
    }

    //Temporary solution for now. Need to handle error if it occurs.
    setContactInfo(result);
    setSuccess(true);
  }

  function ConfirmActions() {
    return (
      <Button
        color="primary"
        variant="contained"
        onClick={confirmSupplierCode}
        loading={isCheckingHasCodeLoading || isConfirmCodeLoading}
        disabled={!code}
      >
        Bekräfta koden
      </Button>
    );
  }

  function Actions() {
    return (
      <Button color="primary" variant="contained" onClick={confirmChanges} loading={isConfirmLoading}>
        Bekräfta uppdaterade uppgifterna
      </Button>
    );
  }

  if (success)
    return (
      <Success
        title="Uppdatering bekräftad."
        content="Uppdateringen är nu bekräftad. Har du några funderingar så tveka inte att ta kontakt med oss."
        email={contactInfo?.emailCustomerService}
        phonenumber={contactInfo?.phoneNumber}
        companyLogoBase64={contactInfo?.companyLogoBase64}
      />
    );

  return (
    <>
      {supplierHasCode && !isCodeCorrect ? (
        <Container label="Skriv in er företagskod" actions={<ConfirmActions />}>
          <InputField
            label="Kod"
            type="text"
            placeholder="Ange koden"
            required
            value={code}
            onChange={changeCode}
            errorText={isCodeCorrect !== undefined && !!isCodeCorrect === false ? 'Felaktig kod' : undefined}
          />
        </Container>
      ) : (
        <>
          {supplierHasCode !== undefined && (
            <Container label="Uppdatering av system" actions={<Actions />}>
              <DownloadButton onClick={getPdf} loading={isPdfLoading}>
                Hämta användarlista
              </DownloadButton>
            </Container>
          )}
        </>
      )}
    </>
  );
};

export default SecurityCompanyConfirmForm;
