import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../../../Styles/Theme';

const useStyles = makeStyles(() => ({
  root: {
    margin: '10px',
    display: 'flex',
    width: '100%',
    '& > div': {
      maxWidth: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  userList: {
    marginTop: '1rem',
    display: 'contents',
    [theme.breakpoints.up('sm')]: {
      '& > div': {
        padding: '0 0.5rem',
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& > div': {
        padding: '0.5rem 0',
      },
    },
  },
  companyContainer: {
    marginBottom: '5px',
    [theme.breakpoints.up('sm')]: {
      '& > div': {
        maxWidth: '80%',
      },
    },
  },
}));

export default useStyles;
