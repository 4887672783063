import React, { useEffect, useState } from 'react';
import Container from '../../../../../../Shared/Container/Container';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import { ViewAddress } from '../../../../../../Shared/InputFields/Address/ViewAddress';
import { IFacility, useGetFacility } from '../../Facilities/Facilities.api';

interface IFacilityDisplay {
  facilityId: string;
  customerId: string;
}

const FacilityInfoDisplay = (props: IFacilityDisplay) => {
  const { isLoading, getFacility } = useGetFacility(props.customerId);
  const [facility, setFacility] = useState<IFacility>();

  useEffect(() => {
    async function loadFacility() {
      const result = await getFacility(props.facilityId);
      setFacility(result);
    }
    loadFacility();
  }, [props.facilityId, getFacility]);

  return (
    <Container form label={'Platsinfo'} loading={isLoading} customSize={{fullsize: true}}>
      <InputField label="Kundnamn" type="text" value={facility?.name} viewOnly />
      <ViewAddress
        address={facility?.address ?? ''}
        postalCode={facility?.postalCode ?? ''}
        city={facility?.city}
      />
    </Container>
  );
};

export default FacilityInfoDisplay;
