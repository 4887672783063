import { useCallback } from 'react';
import { useApiPost, useApiPut, useApiGet, useApiDelete } from '../../../../../Core/Api/Api';
import ValidationErrorResultType from '../../../../../Shared/Api/response/ValidationErrorResultType';
import { ICreateResult } from '../../../../../Shared/Api/response/ICreateResult';
import IPaginatedResponse from '../../../../../Shared/Api/response/IPaginatedResponse';
import IPaginatedRequest from '../../../../../Shared/Api/request/IPaginatedRequest';

export interface IFacility {
  id: number;
  facilityNumber: number;
  agreementInvoiceNumber: string;
  phoneNumber: string;
  name: string;
  address: string;
  postalCode: string;
  city: string;
  invoiceAddress: string;
  invoicePostalCode: string;
  invoiceCity: string;
  invoiceEmail: string;
  internalNote?: string;
  coreSystems: ICoreSystem[];
  invoiceNote: string;
}

export interface ISystemAgreement {
  id: number;
  agreementId: number;
  description: string | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
  monthlyCost: number | undefined;
  externalAgreementInvoiceNumber: string | undefined;
  isActive: boolean | undefined;
}

export interface ICoreSystem {
  id: number;
  description: string;
  coreSystemIdentifier: string;
  systemTypeId: number;
  systemType: string;
  mainProductId: number;
  mainProduct: string;
  mainProductRegulation: string;
  regulationId: number;
  regulation: string;
  installedBy: string | undefined;
  installDate: Date | undefined;
  systemPlacement: string;
  customerDocuments: boolean;
  activeAgreement: ISystemAgreement;
  agreements: ISystemAgreement[];
  communication: {
    transmitter: string;
    securityCompanyName: string;
  };
}

export function useGetFacility(customerId: string | undefined) {
  const { isLoading, response, get } = useApiGet<IFacility>(`/api/customers/${customerId}/facility/`, false);

  return {
    isLoading,
    facility: response,
    getFacility: get,
  };
}

export interface IUpsertFacilityCommand {
  name?: string;
  address: string;
  postalCode: string;
  city: string;
  invoiceAddress: string;
  invoicePostalCode: string;
  invoiceCity: string;
  invoiceEmail: string;
  invoiceNote: string;
  internalNote?: string;
}

export interface IUpsertFacilityValidationErrors extends ValidationErrorResultType<IUpsertFacilityCommand> {}

export function useUpsertFacility(customerId: string | undefined) {
  const { isLoading: isCreateLoading, post } = useApiPost<
    ICreateResult<IUpsertFacilityValidationErrors>,
    IUpsertFacilityCommand
  >(`/api/customers/${customerId}/facility`);

  const { isLoading: isUpdateLoading, put } = useApiPut<IUpsertFacilityValidationErrors, IUpsertFacilityCommand>(
    `/api/customers/${customerId}/facility/`,
  );

  async function upsert(command: IUpsertFacilityCommand, id?: string) {
    if (id) {
      const result = await put(command, id);

      return result ? result : id;
    }

    return post(command);
  }

  return {
    isLoading: isCreateLoading || isUpdateLoading,
    upsert: upsert,
  };
}

export enum HandleAgreementChoice {
  MoveAgreement,
  TerminateAgreement,
  KeepAgreement,
}
export interface IUpdateFacilityCustomerCommand {
  updatedCustomerId: number;
  handleSystemAgreement: HandleAgreementChoice;
}

export interface IUpdateFacilityCustomerValidationErrors
  extends ValidationErrorResultType<IUpdateFacilityCustomerCommand> {}

export function useUpdateFacilityCustomer(customerId: string | undefined) {
  const { isLoading: isUpdateLoading, put } = useApiPut<
    IUpdateFacilityCustomerValidationErrors,
    IUpdateFacilityCustomerCommand
  >(`/api/customers/${customerId}/facility/`);

  async function handleUpdateFaclityCustomer(command: IUpdateFacilityCustomerCommand, id: string) {
    return await put(command, `${id}/customer`);
  }

  return {
    isUpdateLoading,
    handleUpdateFaclityCustomer,
  };
}

export interface IGetFacilityRequest extends IPaginatedRequest {
  hasSystem?: boolean;
}

export function useGetFacilities(customerId: string | undefined) {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<IFacilityListItemDto>, IGetFacilityRequest>(
    `/api/customers/${customerId}/facility/list`,
  );

  return {
    isLoading,
    facilities: response,
    getFacilities: post,
  };
}

export interface IFacilityListItemDto {
  id: number;
  name: string;
  address: string;
  postalCode: string;
  city: string;
}

export function useDeleteFacility(customerId: number) {
  const { isLoading, delete: deletePrior } = useApiDelete(`/api/customers/${customerId}/facility/`);

  const deleteFacility = async (facilityId: number) => {
    await deletePrior(undefined, facilityId.toString());
  };

  return {
    isLoading,
    deleteFacility: deleteFacility,
  };
}
export function useArchiveFacility(customerId: number) {
  const { isLoading, put: archivePrior } = useApiPut(`/api/customers/${customerId}/facility/archive/`);

  const archiveFacility = async (facilityId: number) => {
    await archivePrior(undefined, facilityId.toString());
  };

  return {
    isLoading,
    archiveFacility: archiveFacility,
  };
}

export function useDeleteCoreSystem() {
  const { isLoading, delete: deleteSystem } = useApiDelete(`/api/customers/coresystem/`);

  const deleteCoreSystem = async (coreSystemId: number) => {
    await deleteSystem(undefined, coreSystemId.toString());
  };

  return {
    isLoading,
    deleteCoreSystem,
  };
}

export interface IFacilityContactDto {
  name: string;
  email: string;
  phoneNumber: string;
  mobilePhoneNumber?: string;
}

export function useGetFacilityContacts() {
  const { isLoading, response, get } = useApiGet<IFacilityContactDto[]>(`/api/facility/`, false);

  const getFacilityContacts = useCallback(
    async (facilityId: string | undefined) => {
      if (facilityId) return await get(`${facilityId.toString()}/contacts`);
    },
    [get],
  );

  return {
    isLoading,
    facilityContacts: response,
    getFacilityContacts,
  };
}
