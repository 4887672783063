import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import { Dialog, DialogContent, useMediaQuery, useTheme, IconButton } from '@material-ui/core';
import { DialogContentHeader } from '../DialogContentHeader/DialogContentHeader';
import { useStyles } from './Popper.styles';

interface IPopperMenuProps extends ButtonProps {
  content: React.ReactNode;
  setOpen: (open: boolean) => void;
  open: boolean;
  buttonType?: 'icon' | 'unstyled';
  onClose?: () => void;
  isActivityWindow?: boolean;

  // If not true, the component will use dialog in small screens and popper in big
  alwaysDialog?: boolean;

  // If not true, the component will use dialog in small screens and popper in big
  neverDialog?: boolean;

  // If not using the popper/dialog as a menu
  customContent?: boolean;

  // Title in dialog & for aria-label if using iconButton
  title?: string;
}

const PopperMenu = (props: IPopperMenuProps) => {
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    content,
    open,
    onClose,
    isActivityWindow,
    customContent,
    alwaysDialog,
    neverDialog,
    buttonType,
    title,
    setOpen,
    size,
    ...rest
  } = props;

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (!smDown && event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
      anchorRef.current?.focus();
    }
  }

  function handleEsc(event: React.KeyboardEvent) {
    if (event.key === 'Escape') {
      setOpen(false);
      anchorRef.current?.focus();
    }
  }

  return (
    <>
      {buttonType === 'icon' && (
        <IconButton
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          aria-label={title}
          {...rest}
        />
      )}
      {buttonType === 'unstyled' && (
        <button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={classes.unstyledBtn}
        >
          {rest.children}
        </button>
      )}
      {!buttonType && (
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          size={size}
          {...rest}
        />
      )}
      {(!smDown && !alwaysDialog) || !customContent || neverDialog ? (
        <Popper open={open} className={classes.popper} anchorEl={anchorRef.current} role={undefined} transition>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper onKeyDown={handleEsc}>
                <ClickAwayListener onClickAway={handleClose}>
                  {!customContent ? (
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onClick={handleClose}
                      onKeyDown={handleListKeyDown}
                    >
                      {content}
                    </MenuList>
                  ) : (
                    <div className={classes.popperContent}>{content}</div>
                  )}
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      ) : (
        <Dialog open={open} onClose={props.setOpen} aria-labelledby={rest.children as string}>
          <DialogContent>
            {!customContent ? (
              <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                {content}
              </MenuList>
            ) : (
              <div>
                <DialogContentHeader
                  title={title ? title : rest.children}
                  titleId={rest.children as string}
                  isActivityWindow={props.isActivityWindow}
                  onClose={onClose ? onClose : () => setOpen(false)}
                />
                {content}
              </div>
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default PopperMenu;
