export enum CustomerType {
  Company = 1,
  Private = 2,
}

export function getCustomerTypeName(type: CustomerType | undefined): string {
  return customerTypes.find((x) => x.type === type)?.label ?? '';
}

export const customerTypes = [
  {
    value: '1',
    label: 'Företag',
    type: CustomerType.Company,
  },
  {
    value: '2',
    label: 'Privat',
    type: CustomerType.Private,
  },
];
