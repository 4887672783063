import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Button,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid
} from '@material-ui/core';
import { ModuleIdentifiers, AccessRights } from '../../../../../Core/Authentication/ModuleAccess';
import useUserContext, { userHasAccessThroughRole } from '../../../../../Core/Authentication/UserContext';
import { ViewField } from '../../../../../Shared/InputFields/InputField/ViewField';
import Select, { ISelectOption } from '../../../../../Shared/Select/Select';
import { InputField } from '../../../../../Shared/InputFields/InputField/InputField';
import Datepicker from '../../../../../Shared/InputFields/DatePicker/Datepicker';
import DialogForm from '../../../../../Shared/DialogForm/DialogForm';
import useInputState from '../../../../../Shared/Hooks/UseInputState/UseInputState';
import CheckboxField from '../../../../../Shared/InputFields/CheckboxField/CheckboxField';
import { ViewAddress } from '../../../../../Shared/InputFields/Address/ViewAddress';
import { Fieldset } from '../../../../../Shared/Form/Fieldset/Fieldset';
import AccessGuard, { hasUserAccess } from '../../../../../Core/Authentication/AccessGuard/AccessGuard';
import Link from '../../../../../Shared/Link/Link';
import CustomerFacilitySelect from '../../../../Modules/Customers/Components/CustomerFacilitySelect/CustomerFacilitySelect';
import CustomerSelect from '../../../../Modules/Customers/Components/CustomerSelect/CustomerSelect';
import OrderSelect from '../../../../Modules/Errands/Components/Order/OrderSelect/OrderSelect';
import CompanyUserSelect from '../../../../Modules/Admin/Components/Users/UserSelect/CompanyUserSelect';
import { useGetFacility } from '../../../../Modules/Customers/Components/Facilities/Facilities.api';
import { useGetContactList } from '../../../../Modules/Customers/Customers.api';
import {
  calendarEntryTypes,
  CalendarEntryType,
  ICustomerInformation,
  useGetActivity,
} from '../../../../Modules/Booking/Booking.api';
import {
  IFacility,
  SaleStatus,
  UserAccessType,
  timeReportTypes,
  useGetSale,
  useGetSaleMisc,
} from '../../../../Modules/Errands/Components/Sale.api';
import { timeFromDate, addTimeToDate, validTime } from '../dateFunctions';
import { getCurrentDay } from '../../../utils';
import { OpenInBrowserRounded } from '@material-ui/icons';
import { useStyles } from './ActivityForm.styles';
import moment from 'moment';
import { ICustomerContactDto, useNotifyCustomer } from '../../../../Modules/Errands/Components/Offer/Offer.api';
import { Table } from '../../../../../Shared/Table/Table';
import formatDate from '../../../../../Shared/Formatting/formatDate';
import formatPrice from '../../../../../Shared/Formatting/formatPrice';
import theme from '../../../../../Styles/Theme';
import { Role } from '../../../../Modules/Admin/Components/Users/Users.api';
import { useCompleteInstall, useMarkForInvoice } from '../../../../Modules/Errands/Components/Order/Order.api';
import { isResultError } from '../../../../../Shared/Api/response/ICreateResult';
import { Action } from '../../../../Modules/Admin/Components/SystemLog/SystemLog.api';
import { SaleType } from '../../../SaleType';
import TimeReportForm from '../../../../Modules/Errands/Components/Order/ViewOrder/TimeReportForm/TimeReportForm';
import { CircularProgress } from '@material-ui/core';

export interface IActivity {
  id?: number;
  startDate: Date;
  endDate: Date;
  description: string;
  type: CalendarEntryType;
  companyUserIds?: number[];
  customerId?: number;
  facilityId?: number;
  facilityName?: string;
  facility?: IFacility;
  coreSystemId?: number;
  customerContactId?: number;
  totalPrice?: number;
  saleId?: number;
  saleStatus?: SaleStatus;
  showInCompanyCalendar: boolean;
  otherActivity?: string;
  materialDescription?: string;
}

export interface IActivityFormProps {
  onClose?: (reload?: boolean) => void;
  onRemove?: (id: number) => void;
  onSave?: (activity: IActivity) => void;
  editActivityId?: number;
  saleId?: number;
  defaultDate?: Date;
  companyUserId?: number;
  start?: Date;
  end?: Date;
  showInCompanyCalendarByDefault?: boolean;
  isCompanyCalendar?: boolean;
  open?: boolean;
  isActivityWindow?: boolean;
  activityType?: CalendarEntryType;
  installerCalendar?: boolean;
  customerId?: string;
  facilityId?: string;
  label?: string;
  description?: string;
  revisionId?: string;
  firstContact?: boolean;
}

export interface IActivityUser extends IActivity {
  userName?: string;
  companyUserNames?: string[];
  saleId?: number;
  saleIdentifier?: string;
  saleType?: SaleType;
  saleProjectLeaderId?: number;
  companyUserName?: string | null;
  customerInformation?: ICustomerInformation;
  customerContactPerson?: ICustomerContactDto;
  coreSystem?: {
    mainProduct: string;
    transmitter: string;
    systemPlacement: string;
  };
}

const filterStatus = [SaleStatus.approved, SaleStatus.installationBooked];
const activityOptions: ISelectOption[] = calendarEntryTypes
  .map((x) => ({
    value: (x.type as number).toString(),
    label: x.name,
  }))
  .sort((a, b) => (a.label > b.label ? 1 : -1));

const ActivityForm = (props: IActivityFormProps) => {
  const classes = useStyles();
  const userContext = useUserContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    onSave,
    onClose,
    onRemove,
    editActivityId,
    defaultDate,
    showInCompanyCalendarByDefault,
    activityType,
  } = props;
  const { getSale, isLoading: getSaleLoading } = useGetSale();
  const { getActivity } = useGetActivity();
  const [editMode, setEditMode] = useState<boolean>(true); // editActivity === undefined
  const [editActivity, setEditActivity] = useState<IActivityUser>();
  const [activity, setActivity] = useState<CalendarEntryType | undefined>(activityType);
  const [description, changeDescription, setDescription] = useInputState<string>(props.label ?? '');
  const [jobDescription, changeJobDescription, setJobDescription] = useInputState<string>(description ?? '');
  const [materialDescription, changeMaterialDescription, setMaterialDescription] = useInputState<string>('');
  const [extraMaterial, changeExtraMaterial, setExtraMaterial] = useInputState<string>('');
  const [workDescription, changeWorkDescription, setWorkDescription] = useInputState<string>('');
  const [startDate, setStartDate] = useState<Date>(defaultDate ?? getCurrentDay());
  const [endDate, setEndDate] = useState<Date>(defaultDate ?? getCurrentDay());
  const [startTime, changeStartTime, setStartTime] = useInputState<string>('00:00');
  const [endTime, changeEndTime, setEndTime] = useInputState<string>('00:00');
  const selectedActivityOption = activity && activityOptions.find((x) => x.value === (activity as number).toString());
  const [showInCompanyCalendar, setShowInCompanyCalendar] = useState<boolean>(showInCompanyCalendarByDefault ?? false);
  const [otherActivity, changeOtherActivity, setOtherActivity] = useInputState<string | undefined>(undefined);

  const [saleId, setSaleId] = useState<string | undefined>(props.saleId?.toString() ?? '0');
  const [customerId, setCustomerId] = useState<string | undefined>(props.customerId ?? undefined);
  const [contactId, setContactId] = useState<string>();
  const [facilityId, setFacilityId] = useState<string | undefined>(props.facilityId);
  const [coreSystemId, setCoreSystemId] = useState<string | undefined>(props.revisionId ?? undefined);
  const [totalPrice, changeTotalPrice, setTotalPrice] = useInputState(0);

  const [contactOptions, setContactOptions] = useState<ISelectOption[]>([]);
  const [coreSystemOptions, setCoreSystemOptions] = useState<ISelectOption[]>([]);

  const [fullday, setFullday] = useState<boolean>(false);

  const { getFacility, isLoading: isGetFacilityLoading } = useGetFacility(customerId);
  const { getContactList, isLoading: contactsLoading } = useGetContactList(customerId, facilityId);
  const { completeInstall } = useCompleteInstall();
  const { markForInvoice } = useMarkForInvoice();
  const { notifyCustomer } = useNotifyCustomer();
  const { getSaleMisc, saleMisc } = useGetSaleMisc();
  const [invoiceMessage, changeinvoiceMessage] = useInputState('');
  const { user } = useUserContext();
  const [userAcess, setUserAccess] = useState<UserAccessType | undefined>();

  const [openPopup, setOpenPopup] = useState(false);

  function handleOpenPopup(open: boolean) {
    setOpenPopup(open);
  }

  const isHolidayEvent = useMemo(() => {
    return editActivity && editActivity.type === CalendarEntryType.Holiday;
  }, [editActivity]);
  const [selectedCompanyUserIds, setSelectedCompanyUserIds] = useState<string | string[]>(
    editActivity?.companyUserIds
      ? editActivity.companyUserIds.map((x) => x.toString())
      : props.companyUserId
      ? props.companyUserId.toString()
      : [],
  );
  const customerIds = useMemo(() => (customerId ? [parseInt(customerId)] : undefined), [customerId]);
  const isMultiBooking = (activity: CalendarEntryType | undefined) => {
    return (
      activity === CalendarEntryType.CustomerVisit ||
      //activity === CalendarEntryType.Meeting ||
      activity === CalendarEntryType.Education ||
      activity === CalendarEntryType.Misc
    );
  };

  async function handleCompleteInstall() {
    if (editActivity?.saleId) {
      const result = await completeInstall(editActivity?.saleId.toString());
      if (!isResultError(result)) {
        await notifyCustomer({
          saleId: parseInt(editActivity?.saleId.toString()),
          currentAction: Action.SaleInstallationComplete,
        });
        if (onClose) onClose(true);
      }
    }
  }

  async function handleMarkForInvoice() {
    if (editActivity?.saleId) {
      await markForInvoice(editActivity?.saleId.toString(), invoiceMessage);
      setOpenPopup(false);
      if (onClose) onClose(true);
    }
  }

  useEffect(() => {
    async function loadSaleOtherInfo() {
      if (saleId && saleId !== '0') await getSaleMisc(saleId);
    }
    loadSaleOtherInfo();
  }, [saleId, getSaleMisc]);

  useEffect(() => {
    const getExistingSale = async () => {
      var results = await getSale(saleId);
      if (results) {
        setTotalPrice(results.prices?.totalPrice ?? 0);
        setWorkDescription(results.workDescription ?? '');
        setUserAccess(results.userAccess);
        if (editActivity) {
          setJobDescription(results.jobDescription ?? '');
          setDescription(results.description ?? '');
          setMaterialDescription(results.materialDescription ?? '');
          setExtraMaterial(results.extraMaterial ?? '');
          setCustomerId(results.customer?.id.toString());
        }
      }
    };
    if (saleId && saleId !== "0") {
      getExistingSale();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleId, getSale, setDescription, setMaterialDescription, setTotalPrice]);

  useEffect(() => {
    const getEditActivity = async () => {
      const result = await getActivity(editActivityId?.toString());

      if (result) {
        setEditActivity(result);

        const start = new Date(result.startDate);
        setStartDate(start);
        setStartTime(timeFromDate(start));
        const end = new Date(result.endDate);
        setEndDate(end);
        setEndTime(timeFromDate(end));

        setDescription(result.description);
        setJobDescription(result.jobDescription ?? '');
        setMaterialDescription(result.materialDescription ?? '');
        setExtraMaterial(result.extraMaterial ?? '');
        setWorkDescription(result.workDescription ?? '');
        setOtherActivity(result.otherActivity ?? undefined);
        setActivity(result.type);
        setShowInCompanyCalendar(result.showInCompanyCalendar);
        setCustomerId(result.customerInformation?.customerId.toString() ?? undefined);
        setFacilityId(result.facilityId?.toString() ?? undefined);
        setSaleId(result.saleId?.toString());

        if (result.companyUserIds) {
          if (isMultiBooking(result.type)) setSelectedCompanyUserIds(result.companyUserIds?.map((x) => x.toString()));
          else setSelectedCompanyUserIds(result.companyUserIds[0]?.toString());
        }
      }
    };
    if (editActivityId) {
      getEditActivity();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editActivityId, getActivity]);

  useEffect(() => {
    if (props.start) {
      setStartDate(props.start);
      setStartTime(timeFromDate(props.start));
    }
    if (props.end) {
      setEndDate(props.end);
      setEndTime(timeFromDate(props.end));
    }
  }, [props.start, props.end, setStartTime, setEndTime]);

  useEffect(() => {
    async function loadCoreSystems() {
      if (facilityId) {
        const response = await getFacility(facilityId);
        if (response) {
          var options = response.coreSystems.map((x) => ({
            value: x.id.toString(),
            label: x.description + ' - ' + x.communication?.transmitter,
          }));
          setCoreSystemOptions(options);
        }
      } else setCoreSystemOptions([]);
    }
    facilityId && saleId === '0' && loadCoreSystems();
  }, [facilityId, saleId, setMaterialDescription, getFacility]);

  useEffect(() => {
    async function loadContactList() {
      if (customerId) {
        var response = await getContactList();
        setContactOptions(response.map((x) => ({ value: x.id.toString(), label: x.firstname + ' ' + x.lastname })));
        setContactId(response[0]?.id.toString());
      } else {
        setContactOptions([]);
      }
    }
    customerId && saleId === '0' && loadContactList();
  }, [customerId, saleId, getContactList, setContactId]);

  function handleOnSave() {
    if (onSave) {
      const newActivity = {
        ...(editActivity ?? {}),
        companyUserIds: !selectedCompanyUserIds
          ? []
          : typeof selectedCompanyUserIds === 'string'
          ? [parseInt(selectedCompanyUserIds)]
          : selectedCompanyUserIds.map((x) => parseInt(x)),
        startDate: addTimeToDate(startDate, startTime),
        endDate: addTimeToDate(endDate, endTime),
        type: activity as CalendarEntryType,
        description,
        jobDescription,
        materialDescription,
        extraMaterial,
        workDescription,
        showInCompanyCalendar,
        customerId: customerId ? parseInt(customerId) : undefined,
        facilityId: facilityId ? parseInt(facilityId) : undefined,
        coreSystemId: coreSystemId ? parseInt(coreSystemId) : undefined,
        customerContactId: contactId ? parseInt(contactId) : undefined,
        totalPrice: totalPrice,
        saleId: saleId && saleId !== '0' ? parseInt(saleId) : undefined,
        otherActivity,
      };

      onSave(newActivity);
    }
  }

  function valid() {
    return (
      activity &&
      startDate &&
      endDate &&
      validTime(startTime) &&
      validTime(endTime) &&
      validEndDate() &&
      (activity !== CalendarEntryType.Sale || (customerId && saleId)) &&
      (activity !== CalendarEntryType.CustomerVisit || customerId)
    );
  }

  function validEndDate() {
    return addTimeToDate(endDate, endTime) >= addTimeToDate(startDate, startTime);
  }

  function updateSelectedUsers(option?: ISelectOption | ISelectOption[]) {
    if (option) {
      if (Array.isArray(option)) {
        setSelectedCompanyUserIds(option.map((x) => x.value));
      } else {
        setSelectedCompanyUserIds(option.value);
      }
    }
  }

  const handleOpenWindow = useCallback(() => {
    onClose && onClose();
    window.open(
      `${window.location.origin}/booking/activitywindow/${user.selectedUserId}/${activity}`,
      '_blank',
      'width=500, height=8000',
    );
  }, [activity, user.selectedUserId, onClose]);

  const Actions = () => (
    <>
      {editMode ? (
        <>
          {editActivity?.saleStatus === SaleStatus.installationBooked &&
            (userHasAccessThroughRole(user, Role.Installer) ||
              hasUserAccess(user, ModuleIdentifiers.Orders, AccessRights.Admin)) && (
              <Button color="default" variant="outlined" onClick={handleCompleteInstall}>
                Slutför installation
              </Button>
            )}
          {onRemove &&
            editActivity &&
            editActivity.id &&
            (editActivity.type !== CalendarEntryType.Sale ||
              userContext.hasAccess(ModuleIdentifiers.Booking, AccessRights.Admin)) && (
              <Button variant="outlined" className={classes.delete} onClick={() => onRemove(editActivity.id as number)}>
                Ta bort
              </Button>
            )}
          <div>
            <Button
              variant="outlined"
              onClick={() => {
                editActivity ? setEditMode(false) : onClose && onClose(true);
              }}
            >
              Avbryt
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={classes.save}
              disabled={!valid() || !onSave}
              onClick={handleOnSave}
            >
              Spara
            </Button>
          </div>
        </>
      ) : (
        <>
          {editActivity?.saleStatus === SaleStatus.installationBooked &&
            (userHasAccessThroughRole(user, Role.Installer) ||
              hasUserAccess(user, ModuleIdentifiers.Orders, AccessRights.Admin)) && (
              <Button color="default" variant="outlined" onClick={handleCompleteInstall}>
                Slutför installation
              </Button>
            )}
          {((editActivity?.saleStatus === SaleStatus.approved && editActivity?.saleType === SaleType.deal) ||
            editActivity?.saleStatus === SaleStatus.installed) &&
            (editActivity?.saleProjectLeaderId === user.selectedUserId ||
              hasUserAccess(user, ModuleIdentifiers.Orders, AccessRights.Admin)) && (
              <Button color="primary" variant="outlined" onClick={() => handleOpenPopup(true)}>
                Skicka till faktura
              </Button>
            )}
          <Button variant="outlined" onClick={() => onClose && onClose(true)}>
            Stäng
          </Button>
          <AccessGuard module={ModuleIdentifiers.Booking} accessRights={AccessRights.Write}>
            {!isHolidayEvent && (
              <Button variant="outlined" className={classes.save} onClick={() => setEditMode(true)}>
                Redigera
              </Button>
            )}
          </AccessGuard>
        </>
      )}
    </>
  );

  const calendarEventDateFormat = (startDate: Date, endDate: Date) => {
    if (moment(startDate).format('YYYY-MM-DD') === moment(endDate).format('YYYY-MM-DD')) {
      return (
        <ViewField
          customWidth={4}
          label="Bokad tid"
          value={moment(startDate).format('YYYY-MM-DD HH:mm') + ' - ' + moment(endDate).format('HH:mm')}
        />
      );
    } else {
      return (
        <>
          <ViewField customWidth={4} label="Från" value={startTime} />
          <ViewField customWidth={4} label="Till" value={endTime} />
        </>
      );
    }
  };

  const ViewForm = () => {
    return (
      <>
        <Fieldset>
          {editActivity?.customerInformation && (
            <>
              <ViewField customWidth={4}>
                <Link
                  to={`/customers/${editActivity.customerInformation.customerId}${
                    editActivity.facility ? `/facility/${editActivity.facilityId}` : ''
                  }`}
                  module={ModuleIdentifiers.Customer}
                  accessRights={AccessRights.Read}
                >
                  {editActivity.facility?.facilityNumber
                    ? editActivity.facility.facilityNumber
                    : editActivity.customerInformation.customerId}
                  :{' '}
                  {editActivity.customerInformation.customerName !== editActivity.facility?.name &&
                    editActivity.customerInformation.customerName + ' - '}
                  {editActivity.facility?.name}
                </Link>
                <ViewAddress
                  customLabel=""
                  address={editActivity.facility?.address ?? editActivity.customerInformation?.customerAddress}
                  postalCode={editActivity.facility?.postalCode ?? editActivity.customerInformation?.customerPostalCode}
                  city={editActivity.facility?.city ?? editActivity.customerInformation?.customerCity}
                />
              </ViewField>
            </>
          )}
          {editActivity?.customerContactPerson && (
            <ViewField customWidth={4} label="Beställare">
              <ViewField value={editActivity?.customerContactPerson?.fullName} />
              <ViewField value={editActivity?.customerContactPerson?.phoneNumber} />
            </ViewField>
          )}
          {calendarEventDateFormat(startDate, endDate)}
          {/* <InputField customWidth={4} label="Aktivitet" type="text" viewOnly value={isHolidayEvent ? 'Helgdagar' : (activity === CalendarEntryType.Misc ? "Egen: " + otherActivity : selectedActivityOption?.label)} /> */}
          {editActivity?.description && <ViewField label="Beskrivning" value={editActivity.description} />}
        </Fieldset>
        {editActivity?.type === CalendarEntryType.Sale && (
          <Fieldset legend="Order">
            {editActivity?.saleIdentifier && (
              <ViewField customWidth={4} label="Order">
                <Link
                  to={`/errands/orders/${editActivity?.saleId}`}
                  module={ModuleIdentifiers.Orders}
                  accessRights={AccessRights.Read}
                >
                  {editActivity?.saleIdentifier}
                </Link>
              </ViewField>
            )}
            {!isHolidayEvent && editActivity?.companyUserNames && (
              <InputField
                label="Bokad på"
                type="text"
                viewOnly
                multiline
                customWidth={4}
                rows="5"
                value={editActivity?.companyUserNames?.join(', \n')}
              />
            )}
            {!isHolidayEvent && editActivity?.companyUserName && (
              <ViewField label="Skapad av" customWidth={4} value={editActivity?.companyUserName} />
            )}
            <ViewField label="Rubrik" customWidth={12} value={description} />
            {activity === CalendarEntryType.Sale && (
              <>
                <ViewField label="Uppdragsbeskrivning" customWidth={3} value={jobDescription} />
                <ViewField label="Materialbeskrivning" customWidth={3} value={materialDescription !== "" ? materialDescription : 'Material enligt offert.'} />
                <ViewField label="Extra-material" customWidth={3} value={extraMaterial} />
                <ViewField label="Arbetsbeskrivning" customWidth={3} value={workDescription} />
              </>
            )}
          </Fieldset>
        )}
        <Fieldset>
          {!isHolidayEvent && (
            <ViewField customWidth={4}>
              {showInCompanyCalendar ? 'Finns i företagskalendern.' : 'Finns inte i någon annan kalender.'}
            </ViewField>
          )}
        </Fieldset>
        {editActivity?.customerInformation && (
          <Fieldset legend="Kontaktperson" customSize={{ lg: 6 }}>
            <ViewField customWidth={4} value={editActivity.customerContactPerson?.fullName} />
            <ViewField customWidth={4}>
              <a href={'tel:' + editActivity.customerContactPerson?.phoneNumber}>
                {editActivity.customerContactPerson?.phoneNumber}
              </a>
            </ViewField>
          </Fieldset>
        )}
        {editActivity?.coreSystem && (
          <Fieldset legend="System" customSize={{ lg: 6 }}>
            <ViewField
              customWidth={4}
              value={`${editActivity.coreSystem?.mainProduct} ${
                editActivity.coreSystem?.transmitter ? '(' + editActivity.coreSystem?.transmitter + ')' : ''
              }`}
            />
            <ViewField customWidth={4} value={editActivity.coreSystem?.systemPlacement} />
          </Fieldset>
        )}
      </>
    );
  };

  const EditorForm = () => {
    return (
      <>
        <Fieldset>
          {editActivity?.customerInformation && (
            <>
              <ViewField customWidth={6}>
                <Link
                  to={`/customers/${editActivity.customerInformation.customerId}${
                    editActivity.facility ? `/facility/${editActivity.facilityId}` : ''
                  }`}
                  module={ModuleIdentifiers.Customer}
                  accessRights={AccessRights.Read}
                >
                  {editActivity.facility?.facilityNumber
                    ? editActivity.facility.facilityNumber
                    : editActivity.customerInformation.customerId}
                  :{' '}
                  {editActivity.customerInformation.customerName !== editActivity.facility?.name &&
                    editActivity.customerInformation.customerName + ' - '}
                  {editActivity.facility?.name}
                </Link>
                <ViewAddress
                  customLabel=""
                  address={editActivity.facility?.address ?? editActivity.customerInformation?.customerAddress}
                  postalCode={editActivity.facility?.postalCode ?? editActivity.customerInformation?.customerPostalCode}
                  city={editActivity.facility?.city ?? editActivity.customerInformation?.customerCity}
                />
              </ViewField>
            </>
          )}
          {editActivity?.customerContactPerson && (
            <ViewField customWidth={6} label="Beställare">
              <ViewField value={editActivity?.customerContactPerson?.fullName} />
              <ViewField value={editActivity?.customerContactPerson?.phoneNumber} />
            </ViewField>
          )}
        </Fieldset>
        <Fieldset>
          {!isHolidayEvent && (
            <>
              <Select
                inputFieldProps={{
                  label: 'Aktivitet',
                  required: true,
                  viewOnly: editActivity?.type === CalendarEntryType.Sale,
                  customWidth: 4
                }}
                selectProps={{
                  options: activityOptions,
                  value: selectedActivityOption,
                  onChange: (selected) => {
                    selected && setActivity(parseInt((selected as ISelectOption).value));
                    setCustomerId(undefined);
                    setSaleId(undefined);
                  },
                }}
                viewOnlyValue={
                  activity === CalendarEntryType.Misc && otherActivity ? otherActivity : selectedActivityOption?.label
                }
              />
              <ViewField label="Ordernummer" customWidth={4}>
                <Link
                  to={`/errands/orders/${editActivity?.saleId}`}
                  module={ModuleIdentifiers.Orders}
                  accessRights={AccessRights.Read}
                >
                  {editActivity?.saleIdentifier}
                </Link>
              </ViewField>
            </>
          )}
          {activity === CalendarEntryType.Misc && (
            <>
              <InputField
                customWidth={4}
                label="Egen"
                type="text"
                value={otherActivity}
                onChange={changeOtherActivity}
              />
              <InputField
                label="Beskrivning"
                type="text"
                customWidth={12}
                value={description}
                onChange={changeDescription}
              />
            </>
          )}
          {isHolidayEvent && <InputField customWidth={4} label="Aktivitet" type="text" viewOnly value={'Helgdagar'} />}
          <Grid item md={4}>
            <CompanyUserSelect
              isMulti={isMultiBooking(activity)}
              inline={false}
              label={activity !== CalendarEntryType.Sale ? 'Användare' : 'Installatör'}
              fullwidth={true}
              placeholder="Användare"
              selectedCompanyUserId={selectedCompanyUserIds}
              onChange={(_1, _2, option) => updateSelectedUsers(option)}
            />
          </Grid>
        </Fieldset>
        {!isHolidayEvent && (
          <>
            <Fieldset legend="Datum">
              <CheckboxField label="" fullwidth={true} inline={true}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="checkedFullDay"
                      color="primary"
                      checked={fullday}
                      onChange={(event) => {
                        setFullday(event.target.checked);
                        if (event.target.checked) {
                          setStartTime('09:00');
                          setEndTime('17:00');
                        }
                      }}
                    />
                  }
                  label="Heldag"
                />
              </CheckboxField>
              <Datepicker
                label="Start"
                customWidth={mobileView ? 6 : 3}
                selected={startDate}
                onChange={(d: Date | null) => {
                  setStartDate(d as Date);
                  if ((d as Date) > endDate) {
                    setEndDate(d as Date);
                  }
                }}
              />
              <InputField
                label={mobileView ? 'Starttid' : 'Tid'}
                customWidth={mobileView ? 6 : 3}
                type="time"
                value={startTime}
                disabled={fullday}
                onChange={changeStartTime}
              />
              <Datepicker
                label="Slut"
                customWidth={mobileView ? 6 : 3}
                selected={endDate}
                minDate={startDate}
                onChange={(d: Date | null) => {
                  setEndDate(d as Date);
                }}
              />
              <InputField
                label={mobileView ? 'Sluttid' : 'Tid'}
                customWidth={mobileView ? 6 : 3}
                type="time"
                value={endTime}
                disabled={fullday}
                onChange={changeEndTime}
              />
            </Fieldset>
          </>
        )}
        <Fieldset>
          {activity === CalendarEntryType.CustomerVisit && (
            <CustomerSelect
              required
              selectedCustomerId={customerId}
              customerIds={customerIds}
              onChange={(customerId) => {
                setCustomerId(customerId);
                setSaleId(undefined);
              }}
            />
          )}
          {activity === CalendarEntryType.Sale && (
            <>
              <CustomerFacilitySelect
                required
                isClearable
                customerId={customerId ? parseInt(customerId) : undefined}
                facilityId={facilityId ? parseInt(facilityId) : undefined}
                onChange={(customerFacility) => {
                  setCustomerId(customerFacility ? customerFacility.id.toString() : undefined);
                  setFacilityId(customerFacility ? customerFacility.facilityId.toString() : undefined);
                  setSaleId(undefined);
                }}
                disabled={saleId !== undefined}
              />
              {customerId !== undefined ? (
                <OrderSelect
                  isClearable
                  addNewOrderOption
                  label="Order"
                  status={filterStatus}
                  customerId={customerId ? parseInt(customerId) : undefined}
                  facilityId={facilityId ? parseInt(facilityId) : undefined}
                  selectedOrderId={saleId}
                  notSearchable
                  onChange={(saleId, jobDescription, materialDescription) => {
                    setSaleId(saleId);
                    setJobDescription(jobDescription ?? '');
                    setMaterialDescription(materialDescription ?? '');
                  }}
                />
              ) : (
                <OrderSelect
                  isClearable
                  label="Sök order"
                  onChange={(saleId, jobDescription, materialDescription, customerId, facilityId) => {
                    setSaleId(saleId);
                    setJobDescription(jobDescription ?? '');
                    setMaterialDescription(materialDescription ?? '');
                    setCustomerId(customerId?.toString());
                    setFacilityId(facilityId?.toString());
                  }}
                />
              )}
              {saleId === '0' && customerId && (
                <>
                  <Select
                    inputFieldProps={{ label: 'Välj kontakt' }}
                    selectProps={{
                      value: (contactId && contactOptions?.find((x) => x.value === contactId)) || null,
                      isDisabled: !!(contactOptions?.length === 0),
                      isLoading: contactsLoading,
                      onChange: (selected) => {
                        var value = selected ? (selected as ISelectOption).value : undefined;
                        setContactId(value);
                      },
                      options: contactOptions,
                    }}
                  />
                  <Select
                    inputFieldProps={{ label: 'System', required: false }}
                    selectProps={{
                      isClearable: true,
                      value: coreSystemOptions.find((x) => x.value === coreSystemId?.toString()) || null,
                      isLoading: isGetFacilityLoading,
                      isDisabled: !coreSystemOptions.length,
                      options: coreSystemOptions,
                      onChange: (selected) => setCoreSystemId(selected ? (selected as ISelectOption).value : undefined),
                    }}
                  />
                </>
              )}
            </>
          )}
        </Fieldset>
        <Fieldset>
          {activity === CalendarEntryType.Sale && (
            <>
              <InputField
                label="Rubrik"
                type="text"
                customWidth={12}
                value={description}
                onChange={changeDescription}
              />
              <InputField
                label="Uppdragsbeskrivning"
                type="text"
                customWidth={mobileView ? 12 : 3}
                multiline
                rows="5"
                disabled={editActivityId !== undefined && userAcess?.projectLeader !== true && userAcess?.admin !== true}
                value={jobDescription}
                required
                onChange={changeJobDescription}
              />            
              <InputField
                label="Materialbeskrivning"
                type="text"
                customWidth={mobileView ? 12 : 3}
                multiline
                rows="5"
                disabled={editActivityId !== undefined && userAcess?.projectLeader !== true && userAcess?.admin !== true}
                value={materialDescription === "" && (!userAcess?.projectLeader && !userAcess?.admin) ? "Material enligt offert." : materialDescription}
                onChange={changeMaterialDescription}
              />           
              <InputField
                label="Arbetsbeskrivning"
                type="text"
                customWidth={mobileView ? 12 : 3}
                multiline
                rows="5"
                disabled={editActivityId === undefined || (userAcess?.installer !== true && userAcess?.admin !== true)}
                value={workDescription}
                onChange={changeWorkDescription}
              />
              <InputField
                label="Extra-material"
                type="text"
                customWidth={mobileView ? 12 : 3}
                multiline
                rows="5"
                disabled={editActivityId === undefined || (userAcess?.installer !== true && userAcess?.admin !== true)}
                value={extraMaterial}
                onChange={changeExtraMaterial}
              />
              {/*<InputField label="Totalt pris" type="number" value={totalPrice} onChange={changeTotalPrice} required />*/}
            </>
          )}
        </Fieldset>
        <Fieldset>
          {!isHolidayEvent && activity !== CalendarEntryType.Sale && (
            <CheckboxField label="">
              <FormControlLabel
                control={
                  <Checkbox
                    name="checkedShowInCompanyCalendar"
                    color="primary"
                    checked={showInCompanyCalendar}
                    onChange={(event) => setShowInCompanyCalendar(event.target.checked)}
                  />
                }
                label="Lägg till i företagskalender"
              />
            </CheckboxField>
          )}
          {!isHolidayEvent && editActivity?.companyUserName && (
            <ViewField label="Skapad av" customWidth={4} value={editActivity?.companyUserName} />
          )}
        </Fieldset>
      </>
    );
  };

  return (
    <>
      {(editActivityId === undefined ||
        editActivity?.saleId !== null ||
        (editActivityId !== undefined && !editActivity?.saleId)) && (
        <>
          {!openPopup && (
            getSaleLoading ? (
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <CircularProgress aria-label="Laddar" disableShrink size="3rem" />
              </div>
            ) : 
            <DialogForm CustomWidth={60} actions={<Actions />}>
              {!props.isActivityWindow && !editActivity && (
                <div className={classes.activityWindow}>
                  <Button onClick={handleOpenWindow}>
                    <OpenInBrowserRounded />
                  </Button>
                </div>
              )}
              {editMode ? EditorForm() : ViewForm()}
              {editActivity?.type === CalendarEntryType.Sale && (
                <Fieldset legend="Tidrapporter">
                  <AccessGuard module={ModuleIdentifiers.Orders} accessRights={AccessRights.Write}>
                    {editActivity?.type === CalendarEntryType.Sale && editActivity.saleId && (
                      <TimeReportForm
                        id={editActivity.saleId.toString()}
                        onSave={() => editActivity.saleId && getSaleMisc(editActivity.saleId?.toString())}
                      />
                    )}
                  </AccessGuard>
                  <Table customSize={{ fullsize: true }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Datum</TableCell>
                        <TableCell>Montör</TableCell>
                        <TableCell>Typ</TableCell>
                        <TableCell>Antal h</TableCell>
                        <TableCell>Antal resor</TableCell>
                        <TableCell>Parkering</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {saleMisc?.timeReports.map((timeReport) => (
                        <React.Fragment key={timeReport.id}>
                          <TableRow>
                            <TableCell>{formatDate(timeReport.date)}</TableCell>
                            <TableCell>{timeReport.companyUser?.name}</TableCell>
                            <TableCell>{timeReportTypes.find((x) => x.type === timeReport.type)?.name}</TableCell>
                            <TableCell>{timeReport.hours}</TableCell>
                            <TableCell>{timeReport.trips}</TableCell>
                            <TableCell>{formatPrice(timeReport.parking)}</TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </Fieldset>
                )
              }
            </DialogForm>
          )}
          {openPopup && (
            <DialogForm
              actions={
                <>
                  <Button color="default" variant="outlined" onClick={() => handleOpenPopup(false)}>
                    Avbryt
                  </Button>
                  <Button color="primary" variant="outlined" onClick={handleMarkForInvoice} data-testid="submit">
                    Skicka till faktura
                  </Button>
                </>
              }
              children={
                <>
                  <InputField
                    placeholder="Lägg till fakturatext"
                    type="text"
                    multiline
                    rows={3}
                    fullwidth
                    value={invoiceMessage}
                    onChange={changeinvoiceMessage}
                    onEnter={handleMarkForInvoice}
                  />
                </>
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default ActivityForm;
