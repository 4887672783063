import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  cautionButton: {
    borderColor: theme.palette.error.dark,
    color: theme.palette.error.light,
  },
  padding: {
    padding: '0.5rem',
  },
}));
