import { useApiGet, useApiPut } from '../../../../../Core/Api/Api';
import ValidationErrorResultType from '../../../../../Shared/Api/response/ValidationErrorResultType';
import { IUploadFile } from '../../../../../Shared/InputFields/UploadInput/UploadInput';

export function useGetCompanySettings() {
  const { isLoading, response, get } = useApiGet<ICompanySettings>('/api/companies/current', false);

  return {
    isLoading,
    settings: response,
    getCompanySettings: get,
  };
}

export interface ICompanySettings {
  id: number;
  name: string;
  identificationNumber: string;
  administrationName: string;
  customerServiceName: string;
  shortName: string;
  email: string;
  emailCustomerService: string;
  web: string;
  phoneNumber: string;

  address: string;
  postalCode: string;
  city: string;
  companyDescription: string;
  defaultInstallationPrice: number;

  nextCustomerNumber: number;
  nextSalesNumber: number;
  nextAgreementNumber: number;
  nextFacilityNumber: number;
  nextCoreSystemNumber: number;

  companyLogo?: IUploadFile;
  companyAdditionalImage?: IUploadFile;
  companyPdfBackground?: IUploadFile;
  allowOutgoingEmail: boolean;
  emailWhiteList: string[];

  bannerText: string;
}

export interface IUpdateCompanySettingsCommand {
  name: string;
  administrationName: string;
  customerServiceName: string;
  shortName: string;
  email: string;
  emailCustomerService: string;
  web: string;
  phoneNumber: string;

  address: string;
  postalCode: string;
  city: string;
  companyDescription: string;
  defaultInstallationPrice: number;

  nextCustomerNumber: number;
  nextSalesNumber: number;
  nextAgreementNumber: number;
  nextFacilityNumber: number;
  nextCoreSystemNumber: number;

  companyLogo?: IUploadFile;
  companyAdditionalImage?: IUploadFile;
  companyPdfBackground?: IUploadFile;
  allowOutgoingEmail: boolean;
  emailWhiteList: string[];

  bannerText: string;
}

export interface IUpdateCompanySettingsCommandValidationErrors
  extends ValidationErrorResultType<IUpdateCompanySettingsCommand> {}
export function useUpdateCompanySettings() {
  const { isLoading: isUpdateLoading, put } = useApiPut<
    IUpdateCompanySettingsCommandValidationErrors,
    IUpdateCompanySettingsCommand
  >('/api/companies/current');

  return {
    isLoading: isUpdateLoading,
    updateCompanySettings: put,
  };
}
