import { useEffect } from 'react';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';

const ScrollToTop = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo({ top: 0 });
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
};

export default withRouter(ScrollToTop);
