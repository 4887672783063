import React, { ReactNode, useEffect, useState } from 'react';
import {
  Calendar as BigCalendar,
  EventProps,
  EventWrapperProps,
  momentLocalizer,
  Event as CalendarEvent,
} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Typography, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useUserContext from '../../../../Core/Authentication/UserContext';
import Container from '../../../../Shared/Container/Container';
import CompanyBranchSelect from '../../../Modules/Admin/Components/Users/EditUser/CompanyBranchSelect/CompanyBranchSelect';
import Toolbar, { Stepping } from '../Components/Toolbar/Toolbar';
import EventWrapper from '../Components/EventWrapper/EventWrapper';
import Event from '../Components/Event/Event';
import EditActivity from '../Components/EditActivity/EditActivity';
import { IActivity } from '../Components/ActivityForm/ActivityForm';
import { Messages } from '../Messages';
import { ICalendarEvent } from '../UserCalendar/UserCalendar';
import LoadingCalendar from './LoadingCalendar/LoadingCalendar';
import { HeaderCalendarContent } from './HeaderCalendarContent';
import { useGetHoliday } from '../../../Modules/Holiday/Holiday.api';
import { useSharedCalendarStyles } from '../Components/SharedCalendar.styles';
import { useStyles } from './Calendar.styles';
import './Calendar.css';
import moment from 'moment';
import { Role } from '../../../Modules/Admin/Components/Users/Users.api';
import { calendarEntryTypes } from '../../../Modules/Booking/Booking.api';

export interface IUserCalendar<TResource extends IActivity = IActivity> {
  companyBranchName?: string;
  companyBranchId?: Number;
  userCalendar: IUserBookingCalendar<TResource>[];
}
export interface IUserBookingCalendar<TResource> {
  companyUserId?: number;
  companyUserName?: string;
  companyUserPhoneNumber?: string;
  companyUserEmail?: string;
  events?: ICalendarEvent<TResource>[];
}

export interface ICalendarProps<TResource extends IActivity = IActivity> {
  calendars?: IUserCalendar<TResource>[];
  viewDate: Date;
  onDateChanged: (date: Date) => void;
  onSave?: (activity: IActivity) => void;
  onRemove?: (id: number) => void;
  onReload?: () => void;
  toolbarActions?: ReactNode;
  loading?: boolean;
  query: (companyBranchId: number | number[] | undefined) => void;
}

const Calendar = <TResource extends IActivity = IActivity>(props: ICalendarProps<TResource>) => {
  const classes = useStyles();
  const sharedClasses = useSharedCalendarStyles();
  const {
    onDateChanged,
    viewDate,
    onSave,
    onRemove,
    onReload,
    toolbarActions,
    calendars: bookingCalendar,
    loading,
    query,
    ...rest
  } = props;
  const { getCheck } = useGetHoliday();
  const localizer = momentLocalizer(moment);
  const [addActivity, setAddActivity] = useState<{ start: Date; end: Date; companyUserId?: number }>();
  const [editActivity, setEditActivity] = useState<IActivity>();
  const [holidayEvent, setHolidayEvent] = useState<CalendarEvent[]>([]);
  const userContext = useUserContext();

  useEffect(() => {
    async function getList() {
      const result = await getCheck();
      setHolidayEvent(
        result
          ? result.map((x) => ({
              start: new Date(x.date),
              end: new Date(x.date),
              allDay: true,
              title: x.name,
            }))
          : [],
      );
    }
    getList();
  }, [getCheck]);

  // Custom component for Event (adding custom color based on resource number, maybe there's a better way)
  const CustomEventWrapper: React.FunctionComponent<EventWrapperProps<ICalendarEvent<TResource>>> = (props) => {
    return (
      <div className={classes.event}>
        <EventWrapper
          onSave={onSave}
          onRemove={onRemove}
          activity={props.event.resource}
          type={props.event.type}
          readonly
        >
          {props.children}
        </EventWrapper>
      </div>
    );
  };

  // Custom component for event
  function CustomEvent(event: EventProps) {
    const customTitle = event.event.resource?.otherActivity ?? (event.event.resource?.customerName ? event.event.resource?.customerName : event.event.resource?.facilityName);
    return (
      <Event
        isCompanyCalendar={false}
        label={event.event?.resource.type}
        start={event.event?.start}
        end={event.event?.end}
        customTitle={customTitle}
        productStatuses={event.event?.resource.saleProductsStatus}
      />
    );
  }

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const dailyEventsCount = (dates: ICalendarEvent<TResource>[] | undefined) => {
    let dailyEvents: number = 1;
    if (dates) {
      dates.forEach((x) => {
        const count = dates.filter(
          (y) => moment.utc(y.start).format('LLL').toString() === moment.utc(x.start).format('LLL').toString(),
        ).length;
        dailyEvents = count > dailyEvents ? count : dailyEvents;
      });
    }
    return dailyEvents;
  };

  return (
    <>
      <Container
        customSize={{
          md: 12,
        }}
      >
        <div className={sharedClasses.calendarContainer}>
          <div className={sharedClasses.stickyHeader}>
            <Toolbar
              editProps={{
                onSave: onSave,
                onRemove: onRemove,
                open: !!addActivity,
                start: addActivity?.start,
                end: addActivity?.end,
                isCompanyCalendar: false,
                onClose: () => setAddActivity(undefined),
                companyUserId: addActivity?.companyUserId,
              }}
              stepping={mobile ? Stepping.Day : Stepping.Week}
              date={viewDate}
              onDateChanged={onDateChanged}
              actions={toolbarActions}
              leftActions={
                <div className={classes.companyBranchSelect}>
                  <CompanyBranchSelect
                    placeholder="Filtrera"
                    isMulti
                    fullWidth
                    hasCompanyUsers
                    onChange={(search) => {
                      query(search);
                    }}
                  />
                </div>
              }
            />
            <span className={classes.weekDesktop}>
              <Typography component="span">{`V. ${moment(viewDate).isoWeek()}`}</Typography>
            </span>
            <BigCalendar
              tooltipAccessor={(e) => ''}
              localizer={localizer}
              events={holidayEvent}
              date={viewDate}
              onNavigate={() => {}}
              startAccessor="start"
              endAccessor="end"
              view={mobile ? 'day' : 'week'}
              defaultView={mobile ? 'day' : 'week'}
              views={['day', 'week']}
              onView={() => {}}
              messages={Messages}
              min={new Date(0, 0, 0, 6, 0, 0)}
              max={new Date(0, 0, 0, 20, 0, 0)}
              className={`${classes.main} ${classes.bookingCal}`}
              toolbar={false}
              components={{
                week: {
                  header: HeaderCalendarContent,
                },
              }}
            />
          </div>
          {/* Loop one per user, every user has one calendar each without header, didn't found a better way */}
          {bookingCalendar &&
            !loading &&
            bookingCalendar
              ?.sort((a) => (a.userCalendar.some((x) => x.companyUserId === userContext.user.selectedUserId ) ? -1 : 1))
              .map((calendar) => (
                <>
                  {calendar.userCalendar.some((x) => x.companyUserId) && (
                    <>
                      <div className={classes.companyBranchContainer}>
                        <div className={classes.companyBranch}>
                          {calendar.companyBranchName ?? userContext.user.companyName}
                        </div>
                      </div>
                      {calendar.userCalendar
                        .sort((b) => b.companyUserId === userContext.user.selectedUserId  ? -1 : 1)
                        .map((calendar) => (
                          calendar.companyUserName &&
                          <>
                            <div className={classes.calendar} key={calendar.companyUserId}>
                              <div
                                className={
                                  (calendar.companyUserName && calendar.companyUserName.length < 15) || mobile
                                    ? classes.user
                                    : classes.userMaxWidth
                                }
                              >
                                <Typography
                                  variant="body1"
                                  className={
                                    (calendar.companyUserName && calendar.companyUserName.length < 15) || mobile
                                      ? classes.userName
                                      : classes.longUserName
                                  }
                                >
                                  <span style={{ fontWeight: 'bold' }}>{calendar.companyUserName}</span> <br />
                                  <a href={`tel:${calendar.companyUserPhoneNumber}`}>
                                    {calendar.companyUserPhoneNumber}
                                  </a>
                                  <br />
                                  <a href={`mailto:${calendar.companyUserEmail}`}>{calendar.companyUserEmail}</a>
                                </Typography>
                              </div>
                              <BigCalendar<ICalendarEvent<TResource>, TResource>
                                {...rest}
                                tooltipAccessor={(e) => ''}
                                showMultiDayTimes
                                localizer={localizer}
                                messages={Messages}
                                events={calendar.events}
                                view={mobile ? 'day' : 'week'}
                                defaultView={mobile ? 'day' : 'week'}
                                views={['day', 'week']}
                                onView={() => {}}
                                timeslots={2}
                                step={30}
                                date={viewDate}
                                onNavigate={(date) => {
                                  if (date !== viewDate) {
                                    onDateChanged(date);
                                  }
                                }}
                                components={{
                                  eventWrapper: CustomEventWrapper,
                                  event: CustomEvent as (event: CalendarEvent) => JSX.Element,
                                }}
                                className={classes.companyCalendarChild}
                                selectable
                                onSelectEvent={(x) => {
                                  setEditActivity(x.resource);
                                }}
                                onSelectSlot={(info) => {
                                  var startDate = info.start as Date;
                                  var endDate = info.end as Date;
                                  startDate.setHours(0, 0);
                                  endDate.setHours(0, 0);
                                  setAddActivity({
                                    start: startDate,
                                    end: endDate,
                                    companyUserId: calendar.companyUserId,
                                  });
                                }}
                                dayLayoutAlgorithm="no-overlap"
                                style={{ height: `${(dailyEventsCount(calendar.events) * 52) + (mobile ? 52 : 30)}px` }}
                              />
                            </div>
                          </>
                        ))}
                    </>
                  )}
                </>
              ))}
          {loading && <LoadingCalendar numberOfItems={bookingCalendar?.length} />}
        </div>
      </Container>
      <EditActivity
        onSave={onSave}
        onRemove={onRemove}
        onClose={(reload?: boolean) => {
          setEditActivity(undefined);
          if (reload && props.onReload) props.onReload()
        }}
        editActivityId={editActivity?.id}
        saleId={editActivity?.saleId}
        open={!!editActivity}
        showInCompanyCalendarByDefault={false}
        activityLabel={(calendarEntryTypes.find((c) => c.type === editActivity?.type)?.name ?? 'Aktivitet') + (editActivity?.facilityName ? ': ' + editActivity?.facilityName : editActivity?.otherActivity ? (': ' + editActivity?.otherActivity) : '')}
        inCalendar
        installerCalendar={userContext.user.roles?.some((x) => x === Role.Installer)}
      />
    </>
  );
};

export default Calendar;
