import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: '1rem',
  },
  emptyList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '2rem 0 2rem',
  },
  emptyListText: {
    marginTop: '1rem',
  },
  label: {
    fontWeight: 700,
  },
  expansionPanel: {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[400]}`,
    '&:before': {
      height: 0,
    },
  },
  panelSummaryContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  numberOfSelectedProducts: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  content: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
}));

export default useStyles;
