import { useEffect, useRef, useState } from 'react';
import IPaginatedRequest, { SortDirection } from '../Api/request/IPaginatedRequest';
import { useMediaQuery } from '@material-ui/core';
import theme from '../../Styles/Theme';

export default function useTable(
  initialPageSize: number = 100,
  defaultSortProperty: string = '',
  defaultSortDirection: SortDirection = SortDirection.Ascending,
  initialQuery: string = '',
) {
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [sortProperty, setSortProperty] = useState(defaultSortProperty);
  const [query, setQuery] = useState(initialQuery);
  const [sortDirection, setSortDirection] = useState(defaultSortDirection);
  const [pageSize, setPageSize] = useState(mobileView ? 50 : initialPageSize);
  const [pageNumber, setPageNumber] = useState(1);
  const [paginatedRequest, setPaginatedRequest] = useState<IPaginatedRequest>({
    pageNumber,
    pageSize,
    sortProperty,
    sortDirection,
    query,
  });

  useEffect(() => {
    setPageNumber(1);
  }, [query]);

  function toggleSort(property: string) {
    setSortProperty(property);
    if (sortDirection === SortDirection.Ascending) {
      setSortDirection(SortDirection.Descending);
    } else {
      setSortDirection(SortDirection.Ascending);
    }
  }
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    setPaginatedRequest({
      pageNumber,
      pageSize,
      sortProperty,
      sortDirection,
      query,
    });
  }, [pageNumber, pageSize, sortProperty, sortDirection, query]);

  return {
    toggleSort,
    sortProperty,
    sortDirection,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    query,
    setQuery,
    paginatedRequest,
  };
}
