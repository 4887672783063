import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: `${theme.palette.primary.main}`,
    color: `${theme.palette.primary.contrastText}`,
    padding: '1rem 1rem 0.75rem',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    height: '1.5rem',
  },
  actionRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    padding: '1rem 1rem 0.75rem',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    height: '2rem',
  },
  invert: {
    background: `${theme.palette.primary.main}`,
    color: '#fff',
    '& .MuiSvgIcon-root': {
      color: '#fff'
    }
  },
  h1: {
    color: theme.palette.primary.contrastText,
  },
  h2: {
    fontWeight: 700,
    color: theme.palette.grey[800],
  },
  action: {
    marginBottom: '3px',
  },
}));

export default useStyles;
