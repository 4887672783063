import React from 'react';
import { Box } from '@material-ui/core';

interface IEmptyGridSpace {
  width?: 'full' | 'half';
}

const EmptyGridSpace = (props: IEmptyGridSpace) => {
  const { width, ...rest } = props;

  const boxWidth = width === 'full' ? 1 : width === 'half' ? 1 / 2 : 1;
  return <Box width={boxWidth} {...rest} />;
};

export default EmptyGridSpace;
