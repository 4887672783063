import React from 'react';
import { Route, RouteProps, Redirect, matchPath, useLocation, generatePath } from 'react-router-dom';
import { AccessRights } from '../ModuleAccess';
import { hasUserAccess } from '../AccessGuard/AccessGuard';
import useUserContext from '../UserContext';
import useBreadcrumbNavigationContext, {
  IBreadbrumbItem,
} from '../../../Shared/Navigation/Breadcrumbs/HeaderNavigationContext/BreadcrumbNavigationContext';
import { UserType } from '../../../AuthApp/Modules/Admin/Components/Users/Users.api';

interface IProps extends RouteProps {
  module?: string | string[];
  accessRights?: AccessRights;
  breadcrumbs?: IBreadbrumbItem[];
  userType?: UserType;
  anyModule?: boolean;
}

const AuthRoute: React.FunctionComponent<IProps> = (props) => {
  const { children, module, accessRights, userType, anyModule, ...routeProps } = props;
  const { user } = useUserContext();
  const { pathname } = useLocation();
  const { setBreadcrumbNavigationItems, breadcrumbNavigationItems } = useBreadcrumbNavigationContext();
  const hasAccess = hasUserAccess(user, module, accessRights, userType, false, anyModule);

  React.useEffect(() => {
    const match = matchPath(pathname, { path: props.path, exact: true, strict: false });
    const isMatch = !!match;
    if (isMatch && hasAccess) {
      let newBreadCrumbs: IBreadbrumbItem[] = [];
      if (props.breadcrumbs) {
        newBreadCrumbs = props.breadcrumbs.map((x) => ({
          ...x,
          to: x.to ? generatePath(x.to, match?.params) : undefined,
        }));
      }

      if (JSON.stringify(newBreadCrumbs) !== JSON.stringify(breadcrumbNavigationItems)) {
        setBreadcrumbNavigationItems(newBreadCrumbs);
      }
    }
  }, [pathname, hasAccess, props.path, props.breadcrumbs, breadcrumbNavigationItems, setBreadcrumbNavigationItems]);

  return <Route {...routeProps}>{hasAccess ? children : <Redirect to="/" />}</Route>;
};

export default AuthRoute;
