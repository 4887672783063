import React from 'react';
import { ISurveyQuestion } from '../../Survey.api';
import RadioField from '../../../../../Shared/InputFields/RadioField/RadioField';
import { FormControlLabel, Radio } from '@material-ui/core';

export interface ITextQuestionProps {
  question: ISurveyQuestion;
  answer: string;
  onAnswerChange: (answer: string) => void;
}

const choices = [
  { value: '1', text: 'Inte nöjd' },
  { value: '3', text: 'Nöjd' },
  { value: '5', text: 'Mycket nöjd' },
];
const SatisfactionQuestion = (props: ITextQuestionProps) => {
  const { question, answer, onAnswerChange } = props;

  return (
    <RadioField required label={question.question}>
      {choices.map((x) => (
        <FormControlLabel
          key={x.value}
          label={x.text}
          control={<Radio color="primary" />}
          checked={answer === x.value}
          onChange={(_, checked) => onAnswerChange(x.value)}
        />
      ))}
    </RadioField>
  );
};

export default SatisfactionQuestion;
