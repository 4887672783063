import React, { useContext } from 'react';
import InputMask from 'react-input-mask';
import { IInputFieldProps, InputField } from '../InputField/InputField';
import { ContainerContext } from '../../Container/Container';

export interface IPostalCodeFieldProps extends IInputFieldProps {}

const PostalCodeField = (props: IPostalCodeFieldProps) => {
  let { label, helperText, inputProps, onChange, ...otherProps } = props;
  let pattern = '(?:[0-9]{3})[- ]?(?:[0-9]{2})';
  label = label ?? 'Postnummer';
  const containerContext = useContext(ContainerContext);

  if (props.viewOnly || containerContext?.loading) {
    return (
      <InputField
        type="text"
        {...otherProps}
        inputProps={{ ...inputProps, pattern }}
        label={label}
        helperText={helperText}
      />
    );
  }

  const onChangeEvent = onChange
    ? (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        var newEvent = event;
        if (event.target) {
          newEvent = { ...event, target: { ...event.target, value: event.target.value.replace(/[-\s]/g, '') } };
        }
        onChange!(newEvent);
      }
    : undefined;

  return (
    <InputMask
      onChange={onChangeEvent}
      alwaysShowMask={false}
      mask="999 99"
      value={props.value as string}
      maskChar={null}
      disabled={otherProps.disabled}
    >
      {(innerProps: any) => {
        let inputProps = { ...innerProps.inputProps, pattern };
        return <InputField type="text" inputProps={inputProps} {...otherProps} label={label} helperText={helperText} />;
      }}
    </InputMask>
  );
};

export default PostalCodeField;
