import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Container from '../../../Shared/Container/Container';
import { Fieldset } from '../../../Shared/Form/Fieldset/Fieldset';
import Button from '../../../Shared/Button/Button';
import useFormValidation from '../../../Shared/Hooks/UseFormValidation/useFormValidation';
import { InputField } from '../../../Shared/InputFields/InputField/InputField';
import Datepicker from '../../../Shared/InputFields/DatePicker/Datepicker';
import useInputState from '../../../Shared/Hooks/UseInputState/UseInputState';
import {
  useGetSupplierOrder,
  useConfirmSupplierOrder,
  IConfirmSupplierOrderCommand,
  IContactInfoDto,
  IProduct,
  IProductRequest,
} from './SupplierOrder.api';
import { isResultError } from '../../../Shared/Api/response/ICreateResult';
import { Table } from '../../../Shared/Table/Table';
import { TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import formatDate from '../../../Shared/Formatting/formatDate';
import Success from '../Success/Success';
import { isErrorResponse, ErrorCode } from '../../../Shared/Api/response/IErrorRespose';
import { useCheckSupplierHasCodeAnonymous, useConfirmSupplierCodeAnonymous } from '../Supplier/Supplier.api';
import { SingleRowInputField } from '../../../Shared/InputFields/InputField/SingleRowInputField';

const minDate = new Date();

const ConfirmSupplierOrderForm = () => {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const { isFormValid, formRef } = useFormValidation();
  const [products, setProducts] = useState<IProduct[]>([]);

  const {
    isLoading: isCheckingHasCodeLoading,
    response: supplierHasCode,
    get: checkCode,
  } = useCheckSupplierHasCodeAnonymous(token);
  const { isLoading: isConfirmCodeLoading, response: isCodeCorrect, confirmCode } = useConfirmSupplierCodeAnonymous(
    token,
  );

  const { isLoading, supplierOrder, get } = useGetSupplierOrder();
  const { isConfirmLoading, confirm } = useConfirmSupplierOrder(token);

  const [code, changeCode] = useInputState('');
  const [deliveryDate, setdeliveryDate] = useState<Date | null>(minDate);
  const [supplierOrderNumber, changeSupplierOrderNumber] = useInputState('');
  const [messageToPurchaser, changeMessageToPurchaser] = useInputState('');
  const [contactInfo, setContactInfo] = useState<IContactInfoDto>();
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState<any>();

  useEffect(() => {
    async function checkSupplierCode() {
      const result = await checkCode();
      if (isErrorResponse(result) && result.errorCode === ErrorCode.AnonymousTokenNotFound) {
        history.push(`${history.location.pathname}/404`);
        return;
      }
    }
    checkSupplierCode();
  }, [history, checkCode]);

  async function confirmSupplierCode() {
    const result = await confirmCode({ code: code });

    if (isErrorResponse(result) && result.errorCode === ErrorCode.AnonymousTokenNotFound) {
      history.push(`${history.location.pathname}/404`);
      return;
    }
  }

  useEffect(() => {
    async function loadForm() {
      const result = await get(token);

      if (isErrorResponse(result) && result.errorCode === ErrorCode.AnonymousTokenNotFound) {
        history.push(`${history.location.pathname}/404`);
      }
      setProducts(result?.products);
      setContactInfo(result.contactInfo);
      setdeliveryDate(new Date(result.preferredDeliveryDate));
    }

    if (token) {
      if ((supplierHasCode !== undefined && !supplierHasCode) || isCodeCorrect) loadForm();
    } else {
      history.push('');
    }
  }, [get, history, token, supplierHasCode, isCodeCorrect]);

  async function confirmSupplierOrder() {
    if (!deliveryDate) {
      return;
    }

    const productRequest = supplierOrder?.products.map((x) => {
      const container: IProductRequest = {
        quantity: x.quantity,
        id: x.id
      };
      return container;
    });

    const command: IConfirmSupplierOrderCommand = {
      deliveryDate: deliveryDate,
      supplierOrderNumber: supplierOrderNumber,
      messageToPurchaser: messageToPurchaser,
      products: productRequest,
    };

    const result = await confirm(command);

    if (isErrorResponse(result) && result.errorCode === ErrorCode.AnonymousTokenNotFound) {
      history.push(`${history.location.pathname}/404`);
      return;
    }

    isResultError(result) ? setErrors(result) : setSuccess(true);
  }

  function ConfirmActions() {
    return (
      <Button
        color="primary"
        variant="contained"
        onClick={confirmSupplierCode}
        loading={isCheckingHasCodeLoading || isConfirmCodeLoading}
        disabled={!code}
      >
        Bekräfta koden
      </Button>
    );
  }

  function Actions() {
    return (
      <Button
        color="primary"
        variant="contained"
        onClick={confirmSupplierOrder}
        loading={isConfirmLoading}
        disabled={!isFormValid}
      >
        Spara
      </Button>
    );
  }

  if (success)
    return (
      <Success
        title="Beställningen är bekräftad."
        content="Beställningen och dess leverans är nu bekräftad. Tack för ert samarbete. Vid eventuella synpunkter eller problem tveka inte att ta kontakt med oss."
        email={contactInfo?.emailCustomerService}
        phonenumber={contactInfo?.phoneNumber}
        companyLogoBase64={contactInfo?.companyLogoBase64}
      />
    );

  return (
    <>
      {supplierHasCode && !isCodeCorrect ? (
        <Container label="Skriv in er företagskod" actions={<ConfirmActions />}>
          <InputField
            label="Kod"
            type="text"
            placeholder="Ange koden"
            required
            value={code}
            onChange={changeCode}
            errorText={isCodeCorrect !== undefined && !!isCodeCorrect === false ? 'Felaktig kod' : undefined}
          />
        </Container>
      ) : (
        <>
          {supplierHasCode !== undefined && (
            <Container label={supplierOrder?.supplierName} actions={<Actions />} form ref={formRef} loading={isLoading}>
              <Fieldset legend="Information">
                <InputField
                  label="Önskat leveransdatum"
                  type="text"
                  value={formatDate(supplierOrder?.preferredDeliveryDate)}
                  viewOnly
                />
                <InputField label="Meddelande" type="text" value={supplierOrder?.message} viewOnly />
                <SingleRowInputField
                  label="Märk beställningen med:"
                  type="text"
                  value={`[${supplierOrder?.deliveryMarking}]`}
                  viewOnly
                />
              </Fieldset>
              <Fieldset legend="Order">
                <Datepicker
                  label="Leveransdatum"
                  selected={deliveryDate}
                  onChange={setdeliveryDate}
                  minDate={minDate}
                  required
                  errorText={errors?.preferredDeliveryDate}
                />
                <InputField
                  label="Ert ordernummer"
                  type="text"
                  required
                  value={supplierOrderNumber}
                  onChange={changeSupplierOrderNumber}
                  errorText={errors?.supplierOrderNumber}
                />
                <InputField
                  label="Meddelande till beställare"
                  type="text"
                  value={messageToPurchaser}
                  onChange={changeMessageToPurchaser}
                  errorText={errors?.messageToPurchaser}
                />
              </Fieldset>
              <Fieldset legend="Produkter">
                <Table customSize={{ fullsize: true }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Namn</TableCell>
                      <TableCell>Produktnummer</TableCell>
                      <TableCell>Antal</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products?.map((product, index) => (
                      <TableRow key={index}>
                        <TableCell>{product.name}</TableCell>
                        <TableCell>{product.productNumber}</TableCell>
                        <TableCell align="right">
                          <InputField
                            fullwidth
                            type="number"
                            value={product.quantity}
                            onChange={(e) => {
                              product.quantity = Number(e.target.value);
                              setProducts([...products]);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Fieldset>
            </Container>
          )}
        </>
      )}
    </>
  );
};

export default ConfirmSupplierOrderForm;
