import IPaginatedRequest from '../../../../../Shared/Api/request/IPaginatedRequest';
import { useApiPost, useApiGet, useApiPut, useApiDelete } from '../../../../../Core/Api/Api';
import IPaginatedResponse from '../../../../../Shared/Api/response/IPaginatedResponse';
import ValidationErrorResultType from '../../../../../Shared/Api/response/ValidationErrorResultType';
import { ICreateResult } from '../../../../../Shared/Api/response/ICreateResult';
import ISearchRequest from '../../../../../Shared/Api/request/ISearchRequest';

export interface IMeetingListItem {
  id: number;
  title: string;
  date: Date;
  responsiblePerson: string;
}

export type IMeetingNotesListType = 'search' | 'archive' | 'BMS';
export interface IGetMeetingsRequest extends IPaginatedRequest {}

export function useGetMeetingNotesList(mode: IMeetingNotesListType = 'search') {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<IMeetingListItem>, IGetMeetingsRequest>(
    '/api/todos/meetingnotes' + (mode === 'archive' ? '/archive' : mode === 'BMS' ? '/bms' : '/list'),
  );
  return {
    isLoading,
    meetingNotes: response,
    getMeetingNotes: post,
  };
}

export interface IMeetingNotes {
  id: number;
  responsiblePersonId?: number;
  date: Date;
  title: string;
  description: string;
  documentId?: number;
  inArchive: boolean;
  createdBy: string;
  comments: IMeetingNotesComment[];
}

export function useGetMeetingNotes(id: string) {
  const { isLoading, response, get } = useApiGet<IMeetingNotes>('/api/todos/meetingnotes/' + id, false);

  return {
    isLoading,
    meetingNotes: response,
    getMeetingNotes: get,
  };
}

export interface IUpsertMeetingNotesCommand {
  responsiblePersonId?: number;
  date: Date;
  title: string;
  description: string;
  document?: number;
}

export interface IUpsertMeetingNotesValidationErrors extends ValidationErrorResultType<IUpsertMeetingNotesCommand> {}

export function useUpsertMeetingNotes() {
  const { isLoading: isCreateLoading, post } = useApiPost<
    ICreateResult<IUpsertMeetingNotesValidationErrors>,
    IUpsertMeetingNotesCommand
  >(`/api/todos/meetingnotes/`);

  const { isLoading: isUpdateLoading, put } = useApiPut<
    IUpsertMeetingNotesValidationErrors,
    IUpsertMeetingNotesCommand
  >(`/api/todos/meetingnotes/`);

  async function upsert(command: IUpsertMeetingNotesCommand, id?: string) {
    if (id) {
      const result = await put(command, id);

      return result ? result : id;
    }

    return post(command);
  }

  return {
    isLoading: isCreateLoading || isUpdateLoading,
    upsert: upsert,
  };
}

export function useDeleteMeetingNotes() {
  const { isLoading, delete: deleteRequest } = useApiDelete('/api/todos/meetingnotes/');

  async function deleteMeetingNotes(id: string) {
    return await deleteRequest(undefined, id);
  }

  return {
    isLoading,
    deleteMeetingNotes,
  };
}

export function useArchiveMeetingNotes() {
  const { isLoading, put } = useApiPut('/api/todos/meetingnotes/');

  async function archiveMeetingNotes(id: string) {
    return await put(undefined, id + '/archive');
  }

  return {
    isLoading,
    archiveMeetingNotes,
  };
}

export interface IMeetingNotesTemplate {
  id: number;
  name: string;
  content: string;
}

export function useSearchMeetingNotesTemplates() {
  const { isLoading, response, post } = useApiPost<IMeetingNotesTemplate[], ISearchRequest>(
    '/api/todos/meetingnotes/templates',
  );

  return {
    isLoading,
    templates: response,
    searchTemplates: post,
  };
}

export interface IMeetingNotesComment {
  id: number;
  meetingNotesId: number;
  date: Date;
  topic: string;
  content: string;
  createdBy: string;
}

export function useGetMeetingNotesComment(id: string, commentId: string) {
  const { isLoading, response, get } = useApiGet<IMeetingNotesComment>(
    `/api/todos/meetingnotes/${id}/comment/${commentId}`,
    false,
  );

  return {
    isLoading,
    commentId: response,
    getComment: get,
  };
}

export interface IUpsertMeetingNotesCommentCommand {
  date: Date;
  topic: string;
  content: string;
}

export interface IUpsertMeetingNotesCommentValidationErrors
  extends ValidationErrorResultType<IUpsertMeetingNotesCommentCommand> {}

export function useUpsertMeetingNotesComment(id: string) {
  const { isLoading: isCreateLoading, post } = useApiPost<
    ICreateResult<IUpsertMeetingNotesCommentValidationErrors>,
    IUpsertMeetingNotesCommentCommand
  >(`/api/todos/meetingnotes/${id}/comment/`);

  const { isLoading: isUpdateLoading, put } = useApiPut<
    IUpsertMeetingNotesCommentValidationErrors,
    IUpsertMeetingNotesCommentCommand
  >(`/api/todos/meetingnotes/${id}/comment/`);

  async function upsert(command: IUpsertMeetingNotesCommentCommand, commentId?: string) {
    if (commentId) {
      const result = await put(command, commentId);

      return result ? result : commentId;
    }

    return post(command);
  }

  return {
    isLoading: isCreateLoading || isUpdateLoading,
    upsert: upsert,
  };
}

export function useDeleteMeetingNotesComment(id: number) {
  const { isLoading, delete: deleteRequest } = useApiDelete(`/api/todos/meetingnotes/${id}/comment/`);

  async function deleteMeetingNotesComment(commentId: number) {
    return await deleteRequest(undefined, commentId.toString());
  }

  return {
    isLoading,
    deleteComment: deleteMeetingNotesComment,
  };
}
