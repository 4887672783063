import React, { useEffect, useState } from 'react';
import { Checkbox, Dialog, DialogContent, FormControlLabel } from '@material-ui/core';
import Datepicker from '../../../../../../Shared/InputFields/DatePicker/Datepicker';
import Button from '../../../../../../Shared/Button/Button';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import useInputState from '../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { useOpenDocument } from '../Documents/Components/Documents.api';
import { isResultError } from '../../../../../../Shared/Api/response/ICreateResult';
import { useTerminateAgreement } from '../../../Customers.api';
import useStyles from './TerminateAgreementForm.style';
import DialogForm from '../../../../../../Shared/DialogForm/DialogForm';

export interface ITerminateAgreementProps {
  customerId: string;
  agreementId: string;
  otherLoading?: boolean;
  onTerminate?: () => void;
  startDate: Date;
  setOpen: (open: boolean) => void;
  open: boolean;
}

const TerminateAgreementForm = (props: ITerminateAgreementProps) => {
  const classes = useStyles();
  const { customerId, agreementId, startDate, otherLoading, onTerminate, open, setOpen } = props;
  const { isLoading: isOpenLoading, openDocument } = useOpenDocument(customerId);
  const { isLoading: isTerminateAgreementLoading, terminateAgreement } = useTerminateAgreement(customerId);

  const [sendToCustomer, setSendToCustomer] = useState(true);
  const [terminationDate, setTerminationDate] = useState<Date>(new Date(startDate));
  const [comment, changeComment] = useInputState('');

  useEffect(() => {
    if (startDate && agreementId) {
      setSendToCustomer(true);
      setTerminationDate(new Date(startDate));
    }
  }, [agreementId, startDate]);

  async function handleTerminate() {
    const result = await terminateAgreement(
      { terminationDate: terminationDate as Date, comment, sendToCustomer },
      agreementId,
    );
    if (!isResultError(result)) {
      await openDocument(Number(result));
      onTerminate && onTerminate();
      setOpen(false);
    }
  }

  const Action = () => (
    <>
      <Button color="default" variant="outlined" onClick={() => setOpen(false)}>
        Avbryt
      </Button>
      <Button
        variant="contained"
        onClick={handleTerminate}
        className={classes.terminateBtn}
        loading={isOpenLoading || isTerminateAgreementLoading || otherLoading}
        disabled={!terminationDate}
      >
        Säg upp avtal
      </Button>
    </>
  );

  return (
    <Dialog open={open} onClose={setOpen}>
      <DialogContent>
        <DialogForm actions={<Action />}>
          <Datepicker
            fullwidth
            minDate={new Date(startDate)}
            label="Datum när uppsägning träder i kraft"
            selected={terminationDate}
            onChange={(d: Date | null) => setTerminationDate(d as Date)}
          />
          <InputField
            fullwidth
            label="Slutkommentar"
            rows={5}
            multiline
            type="text"
            value={comment}
            onChange={changeComment}
          />
          <FormControlLabel
            className={classes.checkbox}
            control={
              <Checkbox
                name="sendToCustomer"
                checked={sendToCustomer}
                onChange={(_, checked) => setSendToCustomer(checked)}
                color="primary"
              />
            }
            label="Skicka till kund"
          />
        </DialogForm>
      </DialogContent>
    </Dialog>
  );
};

export default TerminateAgreementForm;
