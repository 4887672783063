import React, { useState } from 'react';
import { Button, Dialog, DialogContent, Grid } from '@material-ui/core';
import { IAuditListItem, useMoveAudit } from '../../../Audit.api';
import Datepicker from '../../../../../../Shared/InputFields/DatePicker/Datepicker';
import { isErrorResponse } from '../../../../../../Shared/Api/response/IErrorRespose';
import useErrorMessage from '../../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import useStyles from './SetNextAuditCalendar.styles';
import { getCurrentDay } from '../../../../../Shared/utils';

type ISetNextAuditCalendarMenuProps = {
  auditInfo: IAuditListItem;
  setOpen: (open: boolean) => void;
  open: boolean;
  loadList?: () => void;
};

export default function SetNextAuditCalendar(props: ISetNextAuditCalendarMenuProps) {
  const { auditInfo, setOpen, open, loadList } = props;
  const [nextAuditDate, setNextAuditDate] = useState(new Date());
  const { setErrorMessage } = useErrorMessage();
  const { moveAudit } = useMoveAudit(auditInfo.customerId.toString(), auditInfo.coreSystemId.toString());
  const classes = useStyles();

  async function handleSetNextAuditDate() {
    const result = await moveAudit(nextAuditDate);
    if (isErrorResponse(result)) {
      setErrorMessage({ message: 'Kan inte sätta datum' });
    } else {
      setOpen(false);
      loadList && loadList();
    }
  }

  const valid = nextAuditDate && nextAuditDate >= getCurrentDay();

  return (
    <>
      <Dialog open={open} onClose={setOpen}>
        <DialogContent className={classes.root}>
          <div>
            <Grid item>
              <Datepicker
                required
                label="Nästa revision datum"
                selected={nextAuditDate}
                minDate={getCurrentDay()}
                onChange={(d: Date | null) => setNextAuditDate(d as Date)}
                inline
              />
            </Grid>
          </div>
          <Grid container justify="space-between" style={{ paddingTop: '0.5rem' }}>
            <Button color="default" variant="outlined" onClick={() => setOpen(false)}>
              Avbryt
            </Button>
            <Button color="primary" variant="contained" disabled={!valid} onClick={handleSetNextAuditDate}>
              Spara
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
