import React from 'react';
import { IAuditListItem, useSetNoContact } from '../../Audit.api';
import { MenuItem, Typography } from '@material-ui/core';
import { DropdownCell } from '../../../../../Shared/Table/DropdownCell/DropdownCell';

type ISetNoContactMenuProps = {
  auditInfo: IAuditListItem;
  mobileView: boolean;
  loadList?: () => void;
};

const SetNoContactMenu = (props: ISetNoContactMenuProps) => {
  const { auditInfo, mobileView, loadList } = props;
  const { put } = useSetNoContact(auditInfo.customerId.toString(), auditInfo.coreSystemId.toString());

  const handleSetContact = async () => {
    await put({ noContact: false });
    loadList && loadList();
  };

  return (
    <DropdownCell mobile={mobileView}>
      <MenuItem onClick={handleSetContact}>
        <Typography variant="inherit">Återuppta kontakt</Typography>
      </MenuItem>
    </DropdownCell>
  );
};

export default SetNoContactMenu;
