import { makeStyles, Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';
import { InputFieldBaseStyles } from '../InputFields-styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    viewOnly: {
      minHeight: '2.7rem',
      '& > span': {
        color: theme.palette.grey[700],
      },
    },
    formControl: {
      '& input': {
        ...InputFieldBaseStyles,
        '&[type=number]': {
          appearance: 'textfield',
        },        
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        display: 'none',
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        display: 'none',
      },
      '& textarea': {
        ...InputFieldBaseStyles,
      },
      '& pre': {
        margin: 0,
        padding: 0,
        fontFamily: theme.typography.body1.fontFamily,
        whiteSpace: 'pre-wrap',
        marginBottom: '.25rem',
        color: theme.palette.grey[700],
      },
    },
    withEndAdornment: {
      '& input': {
        paddingRight: '3rem',
      },
    },
    withStartAdornment: {
      '& input': {
        paddingLeft: '3rem',
      },
    },
    error: {
      '& input': {
        borderColor: theme.palette.error.dark,
        '&:focus': {
          borderColor: theme.palette.error.dark,
        },
      },
    },

    success: {
      '& input': {
        borderColor: theme.palette.success.main,
        '&:focus': {
          borderColor: theme.palette.success.main,
        },
      },
    },
    oneRow: {
      width: '100%',
      '& > div': {
        padding: '0.5rem',
      },
    },
  }),
);
