import React, { useEffect } from 'react';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import AuthRoute from '../../../Core/Authentication/AuthRoute/AuthRoute';
import { ModuleIdentifiers, AccessRights } from '../../../Core/Authentication/ModuleAccess';
import { INavigationItem } from '../../../Shared/Navigation/Header/Header';
import useHeaderNavigationContext from '../../../Shared/Navigation/Header/HeaderNavigationContext/HeaderNavigationContext';
import BMS from './Components/BMS';
import Documents from './Components/Documents';
import BMSNodeList from './Components/NodeList/NodeList';

function BMSRoute() {
  const { path } = useRouteMatch();
  const { setHeaderNavigationItems, setHeaderNavigationText } = useHeaderNavigationContext();

  useEffect(() => {
    const navigationItems: INavigationItem[] = [
      {
        title: 'Verksamhetsledningssystem',
        to: `${path}`,
        exact: false,
      },
      {
        title: 'Dokumentation',
        to: `${path}/documents`,
        exact: false,
      },
      {
        title: 'Senaste ändringarna',
        to: `${path}/latest`,
        exact: false,
      },
      // {
      //   title: 'Leverantörsenkät',
      //   to: `${path}/survey`, //TODO: ask?
      //   exact: false,
      // },
    ];

    setHeaderNavigationText(navigationItems);
    setHeaderNavigationItems(navigationItems);

    return () => {
      setHeaderNavigationItems([]);
    };
  }, [path, setHeaderNavigationItems, setHeaderNavigationText]);

  return (
    <Switch>
      <AuthRoute
        exact
        path={`${path}`}
        module={ModuleIdentifiers.BusinessManagementSystem}
        accessRights={AccessRights.Read}
      >
        <h1 className={'sr-only'}>Lista över verksamhetsledningssystem</h1>
        <BMS />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/documents`}
        module={ModuleIdentifiers.BusinessManagementSystem}
        accessRights={AccessRights.Read}
      >
        <h1 className={'sr-only'}>Lista över mötesfrågor med verksamhetsledningssystem</h1>
        <Documents />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/documents/:id`}
        module={ModuleIdentifiers.BusinessManagementSystem}
        accessRights={AccessRights.Read}
      >
        <h1 className={'sr-only'}>Visualisera dokument</h1>
        <Documents />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/latest`}
        module={ModuleIdentifiers.BusinessManagementSystem}
        accessRights={AccessRights.Read}
      >
        <h1 className={'sr-only'}>Senaste ändringarna</h1>
        <BMSNodeList />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/create`}
        module={ModuleIdentifiers.BusinessManagementSystem}
        accessRights={AccessRights.Write}
      >
        <h1 className={'sr-only'}>Skapa ny</h1>
        <BMS mode="edit" />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/:id`}
        module={ModuleIdentifiers.BusinessManagementSystem}
        accessRights={AccessRights.Read}
      >
        <h1 className={'sr-only'}>Visa system</h1>
        <BMS mode="view" />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/:id/update`}
        module={ModuleIdentifiers.BusinessManagementSystem}
        accessRights={AccessRights.Write}
      >
        <h1 className={'sr-only'}>Redigera system</h1>
        <BMS mode="edit" />
      </AuthRoute>
      <Route path={`${path}/*`}>
        <Redirect to={`${path}`} />
      </Route>
    </Switch>
  );
}

export default BMSRoute;
