import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  label: {
    marginBottom: '0.5rem',
    fontWeight: 600,
  },
  qoutation: {
    marginTop: '1rem',
  },
  container: {
    padding: '1rem',
  },
  statTable: {
    '& tbody tr:nth-last-of-type(2)': {
      borderTop: '3px solid #9e9e9e',
      borderLeft: '3px solid #9e9e9e',
      borderRight: '3px solid #9e9e9e',
    },
    '& tbody tr:nth-last-of-type(1)': {
      borderBottom: '3px solid #9e9e9e',
      borderLeft: '3px solid #9e9e9e',
      borderRight: '3px solid #9e9e9e',
    },
    '& td, & th': {
      width: '20%',
    },
  },
  filter: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '1rem',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    '& > *': {
      [theme.breakpoints.up('sm')]: {
        minWidth: '13rem',
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: '94%',
      },
      flexBasis: 'auto',
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  header: {
    '& > td': {
      fontWeight: 'bold',
    }
  },
  userOfferOrderRows: {
    background: '#eff6ff',
  }
}));

export default useStyles;
