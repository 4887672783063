import React, { useEffect, useState, useCallback } from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import UserCalendar, { ICalendarEvent } from '../../../Shared/Calendars/UserCalendar/UserCalendar';
import useErrorMessage from '../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import { isErrorResponse } from '../../../../Shared/Api/response/IErrorRespose';
import { IActivity } from '../../../Shared/Calendars/Components/ActivityForm/ActivityForm';
import {
  useGetMyCalendar,
  ICalendarEntry,
  getErrorMessage,
  getCalendarEntryTypeName,
  useDeleteCalendarEntry,
  useUpsertCalendarEntry,
} from '../Booking.api';
import moment from 'moment';

const now = new Date();

const MyCalendar = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const [date, setDate] = useState(now);
  const { entries, getMyCalendar } = useGetMyCalendar();
  const [events, setEvents] = useState<ICalendarEvent<ICalendarEntry>[]>();
  const { upsert } = useUpsertCalendarEntry();
  const { deleteCalendarEntry } = useDeleteCalendarEntry();
  const { setErrorMessage } = useErrorMessage();

  const getCalendar = useCallback(async () => {
    const startDate = mobile
      ? moment(date).startOf('day').toDate()
      : moment(date).startOf('month').startOf('week').toDate();
    const endDate = mobile ? moment(date).endOf('week').toDate() : moment(date).endOf('month').endOf('week').toDate();

    getMyCalendar({ startDate, endDate });
  }, [getMyCalendar, date, mobile]);

  useEffect(() => {
    getCalendar();
  }, [getCalendar]);

  const handleSave = async (activity: IActivity) => {
    if (activity.companyUserIds) {
      const result = await upsert({ ...activity, companyUserIds: activity.companyUserIds }, activity.id?.toString());
      if (isErrorResponse(result)) {
        setErrorMessage({ message: getErrorMessage(result, !activity.id) });
      } else {
        getCalendar();
      }
    }
  };

  const handleRemove = async (id: number) => {
    const result = await deleteCalendarEntry(id.toString());
    if (isErrorResponse(result)) {
      setErrorMessage({ message: getErrorMessage(result, false) });
    } else {
      getCalendar();
    }
  };

  useEffect(() => {
    if (entries) {
      setEvents(
        entries.map((x) => ({
          start: new Date(x.startDate),
          end: new Date(x.endDate),
          title: x.description ? x.description : getCalendarEntryTypeName(x.type),
          resource: x,
          type: x.type,
        })),
      );
    } else {
      setEvents([]);
    }
  }, [entries]);

  useEffect(() => {
    if (mobile) setDate(now);
  }, [mobile]);

  return (
    <Grid item container xs={12}>
      <UserCalendar<ICalendarEntry>
        viewDate={date}
        events={events}
        onDateChanged={(d) => setDate(d)}
        onSave={handleSave}
        onRemove={handleRemove}
      />
    </Grid>
  );
};

export default MyCalendar;
