import React, { useCallback, useEffect, useState } from 'react';
import { Table } from '../../../../../../Shared/Table/Table';
import { TableHead, TableBody, TableRow, TableCell, Checkbox } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import AccessGuard from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { AccessRights, ModuleIdentifiers } from '../../../../../../Core/Authentication/ModuleAccess';
import Button from '../../../../../../Shared/Button/Button';
import { useGetFormats, IFormat, useUpdateFormats } from '../Format.api';
import useInputState from '../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import IconButton from '../../../../../../Shared/IconButton/IconButton';
import EditFormatRow from './EditFormatRow';

const FormatList = () => {
  const { isLoading, response: formats, getFormats } = useGetFormats();
  const { updateFormat } = useUpdateFormats();
  const [isEditing, setIsEditing] = useState(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [list, setList] = useState<IFormat[]>([]);
  const [name, updateName, setName] = useInputState<string | undefined>(undefined);
  const [description, updateDescription, setDescription] = useInputState<string | undefined>(undefined);
  const [emptyMessage, setEmptyMessage] = useState('');
  const isAddValid = name && description;
  const isEmpty = !isEditing && list?.length < 1;

  const loadList = useCallback(async () => {
    const formats = await getFormats();

    if (!formats || formats.length === 0) {
      setEmptyMessage('Inga forms tillagda');
    }

    setList(formats);
  }, [getFormats]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  function clearState() {
    setName('');
    setDescription('');
    setIsVisible(false);
  }

  function updateRow(index: number, name: string, description: string, isVisible: boolean) {
    const newList = [...list];
    newList[index] = {
      ...list[index],
      name: name,
      description: description,
      isVisible: isVisible,
    };
    setList(newList);
  }

  async function saveFormats() {
    if (isAddValid) {
      await updateFormat([
        ...list,
        {
          name: name,
          description: description,
          isVisible: isVisible,
        } as IFormat,
      ]);
    } else {
      await updateFormat(list);
    }

    setIsEditing(!isEditing);
    clearState();
    await loadList();
  }

  const addRow = () => {
    setList([
      ...list,
      {
        name: name,
        description: description,
        isVisible: isVisible,
      } as IFormat,
    ]);
    clearState();
  };

  function remove(index: number) {
    setList([...list.filter((_, i) => i !== index)]);
  }
  return (
    <Table
      label={'Format'}
      actions={
        <>
          <AccessGuard module={ModuleIdentifiers.Admin} accessRights={AccessRights.Write}>
            {isEditing && (
              <Button variant="outlined" onClick={() => setIsEditing(!isEditing)}>
                Avbryt
              </Button>
            )}
            {isEditing ? (
              <Button variant="contained" color="primary" onClick={saveFormats}>
                Spara
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsEditing(!isEditing);
                }}
              >
                {isEmpty ? 'Skapa ny' : 'Redigera format'}
              </Button>
            )}
          </AccessGuard>
        </>
      }
      empty={
        formats && {
          empty: isEmpty,
          message: emptyMessage,
        }
      }
      loading={{
        loading: isLoading,
        skeletonRows: formats?.length,
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>Format</TableCell>
          <TableCell>Beskrivning</TableCell>
          <TableCell>Synlig</TableCell>
          {isEditing && (
            <TableCell>
              <span className={'sr-only'}>Knappar</span>
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {list &&
          list.map((x, index) => (
            <EditFormatRow
              isEditing={isEditing}
              format={x}
              key={index}
              delete={() => remove(index)}
              update={(name: string, description: string, isVisible: boolean) => {
                updateRow(index, name, description, isVisible);
              }}
            />
          ))}
        {isEditing && (
          <TableRow>
            <TableCell>
              <InputField fullwidth placeholder="Format" value={name} onChange={updateName} />
            </TableCell>
            <TableCell>
              <InputField fullwidth placeholder="Beskrivning" value={description} onChange={updateDescription} />
            </TableCell>
            <TableCell>
              <Checkbox
                role="checkbox"
                color="primary"
                checked={isVisible}
                value={isVisible}
                onClick={() => setIsVisible(!isVisible)}
              />
            </TableCell>
            {isEditing && (
              <TableCell>
                <IconButton
                  color="primary"
                  onClick={addRow}
                  disabled={!name || !description}
                  aria-label="Lägg till rad"
                >
                  <AddIcon />
                </IconButton>
              </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default FormatList;
