import { useApiPost, useApiPut, useApiDelete, useApiGet } from '../../../Core/Api/Api';
import ValidationErrorResultType from '../../../Shared/Api/response/ValidationErrorResultType';
import { ICreateResult } from '../../../Shared/Api/response/ICreateResult';
import IErrorResponse, { ErrorCode } from '../../../Shared/Api/response/IErrorRespose';
import { IFacility, SaleStatus } from '../Errands/Components/Sale.api';
import { ICustomerContactDto } from '../Errands/Components/Offer/Offer.api';

export enum CalendarEntryType {
  OffDuty = 1,
  Sick = 2,
  SickChild = 3,
  OnCall = 4,
  Education = 5,
  Vacation = 6,
  CustomerVisit = 7,
  Misc = 8,
  Sale = 9,
  Holiday = 11,
}

export interface ICalendarEntryType {
  type: CalendarEntryType;
  name: string;
}

export const calendarEntryTypes: ICalendarEntryType[] = [
  { type: CalendarEntryType.OffDuty, name: 'Ledig' },
  { type: CalendarEntryType.Sick, name: 'Sjuk' },
  { type: CalendarEntryType.SickChild, name: 'VAB' },
  { type: CalendarEntryType.OnCall, name: 'Jour' },
  { type: CalendarEntryType.Education, name: 'Utbildning' },
  { type: CalendarEntryType.Vacation, name: 'Semester' },
  { type: CalendarEntryType.CustomerVisit, name: 'Kundbesök' },
  { type: CalendarEntryType.Misc, name: 'Egen' },
  { type: CalendarEntryType.Sale, name: 'Order' },
];

export function getCalendarEntryTypeName(type: CalendarEntryType) {
  return calendarEntryTypes.find((x) => x.type === type)?.name;
}

export interface IGetCalendarRequest {
  startDate: Date;
  endDate: Date;
}

export interface IGetMyCalendarRequest extends IGetCalendarRequest {
  query?: number | number[] | undefined;
  isCompanyCalendar?: boolean;
}

export interface ICalendarEntry {
  id: number;
  saleId?: number;
  saleStatus?: SaleStatus;
  facilityId?: number;
  startDate: Date;
  endDate: Date;
  description: string;
  type: CalendarEntryType;
  showInCompanyCalendar: boolean;
  companyUserNames: string[];
  companyUserIds: number[];
  customerName?: string;
  otherActivity?: string;
  saleProductsStatus?: number[];
}

export interface ICalendarFullEntry extends ICalendarEntry {
  customerInformation?: ICustomerInformation;
  materialDescription?: string;
  extraMaterial?: string;
  workDescription?: string;
  jobDescription?: string;
  facility?: IFacility;
  companyContactperson?: ICustomerContactDto;
  coreSystem?: {
    mainProduct: string;
    transmitter: string;
    systemPlacement: string;
  }
}

export interface ICustomerInformation {
  customerId: number;
  customerName: string;
  customerAddress: string;
  customerPostalCode: string;
  customerCity: string;
  customerPhoneNumber: string;
}

export interface IBookingCalendar {
  companyUserId: number;
  companyUserName: string;
  companyUserPhoneNumber: string;
  companyUserEmail: string;
  entries: ICalendarEntry[];
}

export interface ICalendar {
  companyBranchName?: string;
  companyBranchId?: Number;
  companyUsers: IBookingCalendar[];
}

export function useGetCalendars() {
  const { isLoading, response, post } = useApiPost<ICalendar[], IGetMyCalendarRequest>('/api/calendar/list');

  return {
    isLoading,
    calendars: response,
    getCalendars: post,
  };
}

export function useGetCompanyCalendars() {
  const { isLoading, response, post } = useApiPost<ICalendarEntry[], IGetCalendarRequest>('/api/calendar/company/list');

  return {
    isLoading,
    calendars: response,
    getCompanyCalendars: post,
  };
}

export function useGetMyCalendar() {
  const { isLoading, response, post } = useApiPost<ICalendarEntry[], IGetCalendarRequest>('/api/calendar/me/list');

  return {
    isLoading,
    entries: response,
    getMyCalendar: post,
  };
}

export function useGetActivity() {
  const { isLoading, response, get } = useApiGet<ICalendarFullEntry>('/api/calendar/', false);

  return {
    isLoading,
    entry: response,
    getActivity: get,
  };
}

export interface IUpsertCalendarEntryCommand {
  companyUserIds: number[];
  startDate: Date;
  endDate: Date;
  description: string;
  jobDescription?: string;
  materialDescription?: string;
  extraMaterial?: string;
  workDescription?: string;
  type: CalendarEntryType;
  showInCompanyCalendar: boolean;
  customerId?: number;
  facilityId?: number;
  coreSystemId?: number;
  customerContactId?: number;
  totalPrice?: number;
  saleId?: number;
}

export interface IUpsertCalendarEntryValidationErrors extends ValidationErrorResultType<IUpsertCalendarEntryCommand> {}

export function useUpsertCalendarEntry() {
  const { isLoading: isCreateLoading, post } = useApiPost<
    ICreateResult<IUpsertCalendarEntryValidationErrors>,
    IUpsertCalendarEntryCommand
  >('/api/calendar/');

  const { isLoading: isUpdateLoading, put } = useApiPut<
    IUpsertCalendarEntryValidationErrors,
    IUpsertCalendarEntryCommand
  >('/api/calendar/');

  async function upsert(command: IUpsertCalendarEntryCommand, id?: string) {
    if (id) {
      const result = await put(command, id);

      return result ? result : id;
    }

    return post(command);
  }

  return {
    isLoading: isCreateLoading || isUpdateLoading,
    upsert: upsert,
  };
}

export function useDeleteCalendarEntry() {
  const { isLoading, delete: deleteRequest } = useApiDelete('/api/calendar/');

  function deleteCalendarEntry(id: string, myCalendar: boolean = false) {
    return deleteRequest(undefined, id);
  }

  return {
    isLoading,
    deleteCalendarEntry,
  };
}

export function getErrorMessage(error: IErrorResponse, newActivity: Boolean = false): string {
  let errorMessage = error.message;
  if (!newActivity) {
    errorMessage = 'Gick inte uppdatera aktivitet';
  } else {
    errorMessage = 'Gick inte skapa aktivitet';
  }
  if (error.errorCode === ErrorCode.OverlappingCalendarEntry) {
    errorMessage += ', överlappar med existerande';
  } else {
    errorMessage += `: ${error.message}`;
  }
  return errorMessage;
}
