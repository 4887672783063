import { makeStyles } from '@material-ui/core/styles';
import theme, { red } from '../../../Styles/Theme';

export const useStyles = makeStyles(() => ({
  datepicker: {
    '& > div': {
      width: '100%',
    },
    '& > div p': {
      marginTop: '0',
    },
    '& .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range)': {
      backgroundColor: 'rgba(33, 107, 163, 0.5) !important',
    },
    '& .react-datepicker__close-icon': {
      '&:after': {
        content: `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-6q0nyr-Svg" fill="%23757575"%3E%3Cpath d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"%3E%3C/path%3E%3C/svg%3E')`,
        backgroundColor: '#f5f5f5',
        color: '#757575',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.5rem',
        lineHeight: '16px',
        padding: '1px',
      },
    },
    '& .react-datepicker__input-container > div': {
      width: '100%',
    },
  },
  calendar: {
    border: `1px solid ${theme.palette.grey[300]}`,
    fontFamily: theme.typography.fontFamily,
    '&.react-datepicker': {
      '& .react-datepicker__day': {
        '&:hover': {
          borderRadius: '50%',
        },
      },
      '& .react-datepicker__day--in-range': {
        borderRadius: '30% !important',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main + '!important',
      },
      '& .react-datepicker__day--highlighted': {
        backgroundColor: 'transparent',
        color: red.main + '!important',
        '&:hover': {
          backgroundColor: '#f0f0f0',
          color: theme.palette.background.paper,
        },
      },
      '& .react-datepicker__day--selected': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        color: theme.palette.common.white,
        // '& > *': {
        //   color: theme.palette.common.white,
        // },
      },
      '& .react-datepicker__header': {
        backgroundColor: theme.palette.common.white,
        borderBottom: 0,
        paddingTop: '0.5rem',
      },
      '& .react-datepicker__day-names': {
        backgroundColor: theme.palette.grey[100],
        fontWeight: 600,
      },
      '& .react-datepicker__week-number': {
        color: theme.palette.grey[500],
      },
      // '& .react-datepicker__day--keyboard-selected': {
      //   backgroundColor: theme.palette.primary.light,
      //   color: theme.palette.common.white,
      //   borderRadius: '50%',
      // },
      '& .react-datepicker__triangle': {
        borderBottomColor: theme.palette.common.white,
        '&:before': {
          borderBottomColor: theme.palette.grey[300],
        },
      },
    },
  },
  yearPicker: {
    '& .react-datepicker__day-names': {
      display: 'none',
    },
    '& .react-datepicker__month-text': {
      padding: '0.5rem 0.25rem',
      borderRadius: '20px',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.paper,
      },
    },
    '& .react-datepicker__month--selected': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  popper: {
    zIndex: 2,
    '&[data-placement^="top"]': {
      marginBottom: 10,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 0.5rem 0.5rem',
  },
  viewOnly: {
    minHeight: '3.5rem',
  },
  weekend: {
    color: red.main,
  },
}));
