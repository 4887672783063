import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import Container from '../../../Shared/Container/Container';
import { Fieldset } from '../../../Shared/Form/Fieldset/Fieldset';
import Button from '../../../Shared/Button/Button';
import { Select, ISelectOption } from '../../../Shared/Select';
import { InputField } from '../../../Shared/InputFields/InputField/InputField';
import PostalCodeField from '../../../Shared/InputFields/PostalCodeField/PostalCodeField';
import { isResultError } from '../../../Shared/Api/response/ICreateResult';
import { isErrorResponse, ErrorCode } from '../../../Shared/Api/response/IErrorRespose';
import useFormValidation from '../../../Shared/Hooks/UseFormValidation/useFormValidation';
import useInputState from '../../../Shared/Hooks/UseInputState/UseInputState';
import { getSelectedOption, getSelectedValue } from '../../../AuthApp/Shared/utils';
import { SaleType } from '../../../AuthApp/Shared/SaleType';
import Success from '../Success/Success';
import { mapAgreementOptions } from '../../../AuthApp/Modules/Errands/Components/Sale.api';
import {
  useGetOffer,
  useApproveOffer,
  IApproveOfferCommand,
  useDeclineOffer,
  declineReasons,
  IDeclineOfferCommand,
  IApproveOfferValidationErrors,
  IDeclineOfferValidationErrors,
  IContactInfoDto,
  DeclineReason,
} from './Offer.api';
import formatPrice from '../../../Shared/Formatting/formatPrice';
import CheckboxField from '../../../Shared/InputFields/CheckboxField/CheckboxField';
import { useNotifyCustomer } from '../../../AuthApp/Modules/Errands/Components/Offer/Offer.api';
import { Action } from '../../../AuthApp/Modules/Admin/Components/SystemLog/SystemLog.api';
import { ViewField } from '../../../Shared/InputFields/InputField/ViewField';

const declineReasonOptions: ISelectOption[] = mapAgreementOptions(declineReasons);

const ApproveOfferForm = () => {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const { isFormValid, formRef } = useFormValidation();

  const { isLoading, offer, get } = useGetOffer();
  const { isApproveLoading, approve } = useApproveOffer(token);
  const { isDeclineLoading, decline } = useDeclineOffer(token);
  const { notifyCustomer } = useNotifyCustomer(true);

  const [isDeclining, setIsDeclining] = useState(false);
  const [customerName, changeCustomerName, setCustomerName] = useInputState('');
  const [customerAddress, changeCustomerAddress, setCustomerAddress] = useInputState('');
  const [customerPostalCode, changeCustomerPostalCode, setCustomerPostalCode] = useInputState('');
  const [customerCity, changeCustomerCity, setCustomerCity] = useInputState('');
  const [facilityName, changeFacilityName, setFacilityName] = useInputState('');
  const [facilityAddress, changeFacilityAddress, setFacilityAddress] = useInputState('');
  const [facilityPostalCode, changeFacilityPostalCode, setFacilityPostalCode] = useInputState('');
  const [facilityCity, changeFacilityCity, setFacilityCity] = useInputState('');
  const [invoiceAddress, changeInvoiceAddress, setInvoiceAddress] = useInputState('');
  const [invoicePostalCode, changeInvoicePostalCode, setInvoicePostalCode] = useInputState('');
  const [invoiceCity, changeInvoiceCity, setInvoiceCity] = useInputState('');
  const [message, setDeclineMessage] = useState('');
  const [contactInfo, setContactInfo] = useState<IContactInfoDto>();
  const [termsAgreedCheckbox, setTermsAgreeCheckbox] = useState(false);

  const [declineReason, setDeclineReason] = useState<DeclineReason | undefined>(DeclineReason.Other);
  const selectedDeclineOption = useMemo(() => getSelectedOption(declineReasonOptions, declineReason), [declineReason]);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState<IApproveOfferValidationErrors>();
  const [declineErrors, setDeclineErrors] = useState<IDeclineOfferValidationErrors>();
  const [divergentInvoiceData, setDivergentInvoiceData] = useState<boolean>(false);
  const [editFacilityData, setEditFacilityData] = useState<boolean>(false);

  const notifyCallback = useCallback(async () => {
    if (offer) {
      setTimeout(async () => {
        await notifyCustomer({ saleId: offer.id, currentAction: Action.CustomerApproveOffer });
      }, 200);
    }
  }, [offer, notifyCustomer]);

  useEffect(() => {
    async function loadForm() {
      const result = await get(token);

      if (!isErrorResponse(result)) {
        setContactInfo(result.companyContactInfo);
        setCustomerName(result.customerData?.name || result.customerData?.address);
        setCustomerAddress(result.customerData?.address);
        setCustomerPostalCode(result.customerData?.postalCode);
        setCustomerCity(result.customerData?.city);
        if (result.type !== SaleType.deal || result.facilityId) {
          setFacilityName(result.facilityInformation?.name || result.facilityInformation?.address);
          setFacilityAddress(result.facilityInformation?.address);
          setFacilityPostalCode(result.facilityInformation?.postalCode);
          setFacilityCity(result.facilityInformation?.city);
        }
        setInvoiceAddress(result.customerInvoiceInfo.invoiceAddress ?? result.facilityInformation.address);
        setInvoiceCity(result.customerInvoiceInfo.invoiceCity ?? result.facilityInformation.city);
        setInvoicePostalCode(result.customerInvoiceInfo.invoicePostalCode ?? result.facilityInformation.postalCode);
        setDivergentInvoiceData(result.divergentInvoiceData ?? false);
      } else if (result.errorCode === ErrorCode.AnonymousTokenNotFound) {
        history.push(`${history.location.pathname}/404`);
      }
    }
    if (token) loadForm();
    else history.push('');
  }, [history, token, get, setFacilityName, setFacilityAddress, setFacilityPostalCode, setFacilityCity, setInvoiceAddress, setInvoiceCity, setInvoicePostalCode, setCustomerName, setCustomerAddress, setCustomerPostalCode, setCustomerCity]);

  async function approveOffer() {
    const command: IApproveOfferCommand = {
      facilityName: facilityName,
      facilityAddress: facilityAddress,
      facilityPostalCode: facilityPostalCode,
      facilityCity: facilityCity,
      invoiceAddress: divergentInvoiceData ? invoiceAddress : offer?.type === SaleType.custom ? customerAddress : facilityAddress,
      invoiceCity: divergentInvoiceData ? invoiceCity : offer?.type === SaleType.custom ? customerCity : facilityCity,
      invoicePostalCode: divergentInvoiceData ? invoicePostalCode : offer?.type === SaleType.custom ? customerPostalCode : facilityPostalCode,
    };

    const result = await approve(command);

    if (isErrorResponse(result) && result.errorCode === ErrorCode.AnonymousTokenNotFound) {
      history.push(`${history.location.pathname}/404`);
      return;
    }

    if (isResultError(result)) {
      setErrors(result)
    } else {
      notifyCallback();
      setSuccess(true);
    }
  }

  async function declineOffer() {
    const command: IDeclineOfferCommand = {
      message,
    };

    const result = await decline(command);

    if (isErrorResponse(result) && result.errorCode === ErrorCode.AnonymousTokenNotFound) {
      history.push(`${history.location.pathname}/404`);
      return;
    }

    isResultError(result) ? setDeclineErrors(result) : setSuccess(true);
  }

  function Actions() {
    return offer?.canBeApprovedByCustomer ? (
      <>
        {!isDeclining && (
          <Grid container item md={12} xs={12} direction="row" justify="center" style={{ padding: '1.5rem' }}>
            <Grid item md={8} xs={8}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="termsAgreedCheckbox"
                    color="primary"
                    checked={termsAgreedCheckbox}
                    onChange={(_, checked) => setTermsAgreeCheckbox(checked)}
                    required
                  />
                }
                label="Jag har läst och godkänner villkoren i avtal och offert. Jag godkänner också att bolaget sparar mina uppgifter så länge jag är kund enligt GDPR. Jag godkänner även att kreditupplysning kan komma att tas på mig vid behov."
              />
            </Grid>
            <Grid item md={4} xs={4}>
              <Grid container md={12} xs={12} alignContent="center" alignItems="center" justify="center" style={{height: "100%"}}>
                <Grid item md={6}>
                  <Button color="default" variant="outlined" onClick={() => setIsDeclining(true)}>
                    Neka
                  </Button>
                </Grid>
                <Grid item md={6}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={approveOffer}
                    loading={isApproveLoading}
                    disabled={!isFormValid}
                  >
                    Godkänn
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {isDeclining && (
          <>
            <Button color="default" variant="outlined" onClick={() => setIsDeclining(false)}>
              Ångra
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={declineOffer}
              loading={isDeclineLoading}
              disabled={!isFormValid}
            >
              Bekräfta och skicka
            </Button>
          </>
        )}
      </>
    ) : null;
  }

  if (success) {
    return (
      <Success
        title={isDeclining ? 'Offert nekad.' : 'Offerten är bekräftad.'}
        content={
          isDeclining
            ? 'Din säljare är meddelad. Har du några funderingar så tveka inte att ta kontakt med oss.'
            : 'Vi kommer inom kort återkomma för leverans av order. Har du några funderingar så tveka inte att ta kontakt med oss.'
        }
        email={contactInfo?.emailCustomerService}
        phonenumber={contactInfo?.phoneNumber}
        companyLogoBase64={contactInfo?.companyLogoBase64}
      />
    );
  }

  return (
    <Container
      label={offer && `Offert ${offer.saleIdentifier}`}
      actions={<Actions />}
      form
      ref={formRef}
      loading={isLoading}
    >
      <Fieldset legend={offer?.type === SaleType.custom ? offer.description : offer && `${offer.coreSystem?.systemType} - ${offer.facilityInformation.name}`}>
        {!isDeclining && (
          <>
            {(offer?.facilityId || offer?.type === SaleType.system) && (
              <>
                <InputField
                  label="Objektsnamn"
                  type="text"
                  value={facilityName}
                  onChange={changeFacilityName}
                  errorText={errors?.facilityName}
                  customWidth={3}
                  viewOnly={!editFacilityData}
                  required
                />
                <InputField
                  label="Gatuadress"
                  type="text"
                  value={facilityAddress}
                  onChange={changeFacilityAddress}
                  errorText={errors?.facilityAddress}
                  customWidth={3}
                  viewOnly={!editFacilityData}
                  required
                />
                <InputField
                  label="Ort"
                  type="text"
                  value={facilityCity}
                  onChange={changeFacilityCity}
                  errorText={errors?.facilityCity}
                  customWidth={3}
                  viewOnly={!editFacilityData}
                  required
                />
                <PostalCodeField
                  label="Postnummer"
                  type="text"
                  value={facilityPostalCode}
                  onChange={changeFacilityPostalCode}
                  errorText={errors?.facilityPostalCode}
                  customWidth={3}
                  viewOnly={!editFacilityData}
                  required
                />
                {offer?.canBeApprovedByCustomer && (
                  <CheckboxField label="" customWidth={6} inline>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="editFacilityData"
                          color="primary"
                          checked={editFacilityData}
                          onChange={(_, checked) => setEditFacilityData(checked)}
                        />
                      }
                      label="Ändra adress-uppgifter"
                    />
                  </CheckboxField>
                )}
              </>
            )}
            {(offer?.type === SaleType.custom) && (
              <>
                <InputField
                  label="Namn"
                  type="text"
                  value={customerName}
                  onChange={changeCustomerName}
                  customWidth={3}
                  viewOnly
                  required
                />
                <InputField
                  label="Gatuadress"
                  type="text"
                  value={customerAddress}
                  onChange={changeCustomerAddress}
                  customWidth={3}
                  viewOnly
                  required
                />
                <InputField
                  label="Ort"
                  type="text"
                  value={customerCity}
                  onChange={changeCustomerCity}
                  customWidth={3}
                  viewOnly
                  required
                />
                <PostalCodeField
                  label="Postnummer"
                  type="text"
                  value={customerPostalCode}
                  onChange={changeCustomerPostalCode}
                  customWidth={3}
                  viewOnly
                  required
                />
              </>
            )}
            <CheckboxField label="" customWidth={6} inline>
              <FormControlLabel
                control={
                  <Checkbox
                    name="divergentInvoiceData"
                    color="primary"
                    checked={divergentInvoiceData}
                    onChange={(_, checked) => setDivergentInvoiceData(checked)}
                  />
                }
                label="Annan faktureringsadress"
              />
            </CheckboxField>
            {offer?.canBeApprovedByCustomer && divergentInvoiceData && (
              <Fieldset datatest-id={'Fakturauppgifter'} legend="Fakturauppgifter">
                <InputField
                  required
                  label="Fakturaadress"
                  type="text"
                  value={invoiceAddress}
                  onChange={changeInvoiceAddress}
                  errorText={errors?.invoiceAddress}
                  customWidth={4}
                />
                <InputField
                  required
                  label="Ort"
                  type="text"
                  value={invoiceCity}
                  onChange={changeInvoiceCity}
                  errorText={errors?.invoiceCity}
                  customWidth={4}
                />
                <PostalCodeField
                  required
                  label="Postnummer"
                  type="text"
                  value={invoicePostalCode}
                  onChange={changeInvoicePostalCode}
                  errorText={errors?.invoicePostalCode}
                  customWidth={4}
                />
              </Fieldset>
            )}
            <Fieldset legend="Offertbeskrivning">
              <InputField type="text" value={offer?.offerDescription} viewOnly />
            </Fieldset>
            {(offer?.terms.including || offer?.terms.excluding || offer?.terms.delivery || offer?.terms.warranty || offer?.terms.validPeriod)  && 
              <Fieldset legend="Avtal">
                {offer?.terms.including && (
                  <InputField label="Inkluderat" type="text" value={offer?.terms.including} viewOnly />
                )}
                {offer?.terms.excluding && (
                  <InputField label="Exkluderat" type="text" value={offer?.terms.excluding} viewOnly />
                )}
                {offer?.terms.delivery && (
                  <InputField label="Leveransvillkor" type="text" value={offer?.terms.delivery} viewOnly />
                )}
                {offer?.terms.warranty && (
                  <InputField label="Garanti" type="text" value={offer?.terms.warranty} viewOnly />
                )}
                {offer?.terms.validPeriod && (
                  <InputField label="Giltighetstid" type="text" value={offer?.terms.validPeriod} viewOnly />
                )}
              </Fieldset>
            }
            <ViewField>Vi sparar dina uppgifter under offertens giltighetstid varefter de raderas enligt GDPR.</ViewField>
          </>
        )}
      </Fieldset>
      {!isDeclining && offer?.type === SaleType.system && !offer?.facilityId && (
        <Fieldset legend="Sammanfattning">
          {offer?.prices?.totalPrice && (
            <InputField
              label="Pris inklusive installation"
              type="text"
              value={`${formatPrice(offer?.prices.totalPrice)}, exkl moms`}
              viewOnly
            />
          )}
          {offer?.saleAgreement?.monthlyCost && (
            <InputField
              label="Avtal"
              type="text"
              value={`${formatPrice(offer?.saleAgreement?.monthlyCost)} per månad, faktureras kvartalsvis`}
              viewOnly
            />
          )}
          {offer?.prices.installationTime && (
            <InputField
              label="Installationstid"
              type="text"
              value={offer?.prices.installationTime + ' timmar'}
              viewOnly
            />
          )}
        </Fieldset>
      )}

      {isDeclining && (
        <>
          <Select
            viewOnlyValue={selectedDeclineOption?.label}
            inputFieldProps={{ label: 'Anledning' }}
            selectProps={{
              options: declineReasonOptions,
              value: selectedDeclineOption,
              onChange: (option) => {
                let value = getSelectedValue(option);
                setDeclineMessage(
                  value !== 0 ? getSelectedOption(declineReasonOptions, getSelectedValue(option))?.label ?? '' : '',
                );
                setDeclineReason(value);
              },
            }}
          />
          <InputField
            required
            fullWidth
            label="Meddelande till säljare"
            type="text"
            multiline
            rows={5}
            value={message}
            onChange={(value) => {
              setDeclineMessage(value.target.value);
            }}
            errorText={declineErrors?.message}
            disabled={declineReason !== DeclineReason.Other}
          />
        </>
      )}
    </Container>
  );
};

export default ApproveOfferForm;
