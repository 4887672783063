import { TableRow, TableCell, TableBody } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Fieldset } from '../../../../../../../Shared/Form/Fieldset/Fieldset';
import { Table } from '../../../../../../../Shared/Table/Table';
import { useGetSalePostCalculation } from '../../../Sale.api';
import useStyles from './PostCalculation.styles';

interface IPostCalculationProps {
  saleId: string;
}

const PostCalculation = (props: IPostCalculationProps) => {
  const classes = useStyles();
  const { salePostCalculation, getSalePostCalculation } = useGetSalePostCalculation();

  useEffect(() => {
    async function load() {
      await getSalePostCalculation(props.saleId);
    }
    load();
  }, [props.saleId, getSalePostCalculation]);

  return (
    <>
      <Fieldset legend="Efterkalkyl" useAsExpandPanel expandedAsDefault>
        <Table customSize={{ fullsize: true }} className={classes.postCalculationWrapper}>
          <TableBody>
            <TableRow>
              <TableCell>Inköp:</TableCell>
              <TableCell>{(salePostCalculation?.materialCost ?? 0) + (salePostCalculation?.saleCost ?? 0)} SEK</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Installation {salePostCalculation?.installationHours}tim {salePostCalculation?.installationHoursContractor ? `(${salePostCalculation?.installationHoursContractor})` : ''}:</TableCell>
              <TableCell>
                {salePostCalculation?.installationCost ?? 0} SEK
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Vinst:</TableCell>
              <TableCell>{(salePostCalculation?.profit ?? 0)} SEK</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Fieldset>
    </>
  );
};

export default PostCalculation;
