import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  lists: {
    '& > *': {
      marginBottom: '1rem',
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
}));

export default useStyles;
