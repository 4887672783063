import { useApiGet, useApiPost } from '../../../Core/Api/Api';
import { ICreateResult } from '../../../Shared/Api/response/ICreateResult';

export function useCheckSupplierHasCodeAnonymous(token: string) {
  const { isLoading, response, get } = useApiGet<boolean>(`/api/anonymous/supplier/${token}`, false);

  return {
    isLoading,
    response,
    get,
  };
}

export interface ISupplierCode {
  code: string;
}

export function useConfirmSupplierCodeAnonymous(token: string) {
  const { isLoading, response, post } = useApiPost<ICreateResult<boolean>, ISupplierCode>(
    `/api/anonymous/supplier/${token}/code`,
    true,
  );

  async function confirmCode(command: ISupplierCode) {
    return post(command);
  }

  return {
    isLoading,
    response,
    confirmCode,
  };
}
