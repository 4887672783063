import React from 'react';
import { SaleType } from '../../../../../Shared/SaleType';
import { OfferSteps } from '../Offer.api';

export interface IOfferFormContext {
  offerStep: OfferSteps;
  setOfferStep: (offerStep: OfferSteps) => void;
  customerId?: number;
  setCustomerId: React.Dispatch<React.SetStateAction<number>>;
  offerType?: SaleType;
  setOfferType: React.Dispatch<React.SetStateAction<SaleType>>;
  referenceNumber?: string;
  setReferenceNumber: React.Dispatch<React.SetStateAction<string>>;
  identifier?: string;
  setIdentifier: React.Dispatch<React.SetStateAction<string>>;
  customSize: { lg: number; md: number };
}

const initialContextState = {
  offerStep: OfferSteps.setCustomer,
  setOfferStep: () => {},
  customerId: undefined,
  setCustomerId: () => {},
  offerType: SaleType.system,
  setOfferType: () => {},
  referenceNumber: '',
  setReferenceNumber: () => {},
  identifier: '',
  setIdentifier: () => {},
  customSize: { lg: 12, md: 12 },
};

const OfferFormContext = React.createContext<IOfferFormContext>(initialContextState);

export default OfferFormContext;
