import React, { useCallback } from 'react';
import { Table } from '../../../../../Shared/Table/Table';
import {
  TableBody,
  TableRow,
  TableCell,
  useMediaQuery,
  useTheme,
  ListItemIcon,
  Typography,
  MenuItem,
  TableHead,
} from '@material-ui/core';
import SortButton from '../../../../../Shared/Table/SortButton/SortButton';
import { MobileTable } from '../../../../../Shared/Table/MobileTable/MobileTable';
import { useHistory } from 'react-router-dom';
import { useGetTodos, useDeleteTodo, ITodoListItem, useArchiveTodo, TodoRole } from '../../Todos.api';
import { useState, useEffect } from 'react';
import useTable from '../../../../../Shared/Table/useTable';
import { DropdownCell } from '../../../../../Shared/Table/DropdownCell/DropdownCell';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import ArchiveIcon from '@material-ui/icons/Archive';
import formatDate, { formatDateWithTime } from '../../../../../Shared/Formatting/formatDate';
import { MobileTableRow } from '../../../../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import TablePaginationWithDatasource from '../../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import AccessGuard from '../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { ModuleIdentifiers, AccessRights } from '../../../../../Core/Authentication/ModuleAccess';
import SearchInput from '../../../../../Shared/InputFields/SearchInput/SearchInput';
import { isErrorResponse } from '../../../../../Shared/Api/response/IErrorRespose';
import useErrorMessage from '../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import moment from 'moment';
import useStyles from './ToDoList.styles';
import IconButton from '../../../../../Shared/IconButton/IconButton';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { ConfirmDeleteOrArchiveRow } from '../../../../../Shared/Table/ConfirmDeleteOrArchiveRow/ConfirmDeleteOrArchiveRow';

interface ITodoListProps {
  inArchive?: boolean;
}

const TodoList = (props: ITodoListProps) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { isLoading, todos, getTodos } = useGetTodos();
  const { archiveTodo } = useArchiveTodo();
  const { isLoading: isDeleteLoading, deleteTodo } = useDeleteTodo();

  const {
    toggleSort,
    sortProperty,
    sortDirection,
    pageSize,
    setPageSize,
    setPageNumber,
    setQuery,
    paginatedRequest,
  } = useTable();
  const [emptyMessage, setEmptyMessage] = useState('');
  const [confirmDeleteId, setConfirmDeleteId] = useState<number | null>(null);
  const { setErrorMessage } = useErrorMessage();

  const history = useHistory();
  const classes = useStyles();

  const getList = useCallback(
    async function () {
      const response = await getTodos({ ...paginatedRequest, inArchive: props.inArchive ?? false });

      if (response?.totalCount <= 0) {
        if (paginatedRequest.query) {
          setEmptyMessage('Filtreringen gav inga resultat');
        } else {
          setEmptyMessage('Allt är klart, bra jobbat!');
        }
      }
    },
    [paginatedRequest, props.inArchive, getTodos],
  );

  useEffect(() => {
    getList();
  }, [getList]);

  async function handleArchive(todoId: string) {
    if (todoId) {
      const result = await archiveTodo(todoId);

      if (isErrorResponse(result)) {
        setErrorMessage({ message: 'Gick inte arkivera.' });
      } else {
        getList();
      }
    }
  }

  async function handleDelete() {
    if (confirmDeleteId) {
      const result = await deleteTodo(confirmDeleteId.toString());

      if (isErrorResponse(result)) {
        setErrorMessage({ message: 'Gick inte att ta bort då den används' });
        setConfirmDeleteId(null);
      } else {
        getList();
      }
    }
  }

  function getClasses(todo: ITodoListItem) {
    if (moment(todo.deadline).diff(new Date(), 'day') < 0) {
      return classes.red;
    }
    if (!todo.answer) {
      return classes.yellow;
    }
    if (todo.role === TodoRole.Assignor) {
      return classes.green;
    }
    if (todo.role === TodoRole.Assignee) {
      return classes.blue;
    }
  }

  const handleTableClick = (todo: ITodoListItem) => {
    history.push(props.inArchive ? `archivelist/${todo.id}` : `list/${todo.id}/update`);
  };

  return (
    <Table
      label={`${props.inArchive ? 'Mitt arkiv' : 'Uppgift'} (${todos?.totalCount ?? 0})`}
      filters={<SearchInput type="search" placeholder="Sök" onChange={setQuery} />}
      iconButtons={
        !props.inArchive && (
          <AccessGuard module={ModuleIdentifiers.Todo} accessRights={AccessRights.Write}>
            <IconButton
              size="small"
              aria-label="Lägg till uppgift"
              onClick={() => {
                history.push(`${history.location.pathname}/create`);
              }}
            >
              <AddIcon />
            </IconButton>
          </AccessGuard>
        )
      }
      pagination={
        todos && (
          <TablePaginationWithDatasource
            datasource={todos}
            onChangePage={setPageNumber}
            onChangeRowsPerPage={setPageSize}
          />
        )
      }
      empty={{
        empty: todos && todos.totalCount <= 0 ? true : false,
        message: emptyMessage,
      }}
      loading={{
        loading: isLoading,
        skeletonRows: pageSize,
      }}
      mobile={mobileView}
      customSize={{ md: 12, lg: 12 }}
    >
      {mobileView ? (
        <>
          {todos?.items.map((todo) => (
            <MobileTable
              label={todo.description}
              key={todo.id}
              confirmDeleteRow={{
                show: confirmDeleteId === todo.id,
                onConfirm: handleDelete,
                onCancel: () => setConfirmDeleteId(null),
                loading: isDeleteLoading,
              }}
              action={
                <AccessGuard
                  module={ModuleIdentifiers.Todo}
                  accessRights={AccessRights.Full}
                  fallback={<TableCell className={classes.tableCellEmpty}></TableCell>}
                >
                  <DropdownCell mobile={mobileView}>
                    {props.inArchive ? (
                      <MenuItem onClick={() => setConfirmDeleteId(todo.id)}>
                        <ListItemIcon>
                          <DeleteIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">Ta bort</Typography>
                      </MenuItem>
                    ) : (
                      <MenuItem disabled={!todo.canBeArchived} onClick={() => handleArchive(todo.id.toString())}>
                        <ListItemIcon>
                          <ArchiveIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">Arkivera</Typography>
                      </MenuItem>
                    )}
                  </DropdownCell>
                </AccessGuard>
              }
            >
              <MobileTableRow label="Id" value={todo.id} />
              <MobileTableRow label="Ska vara klart" value={formatDate(todo.deadline)} />
              <MobileTableRow label="Beskrivning" value={todo.description} />
              <MobileTableRow label="Från" value={todo.assignor} />
              <MobileTableRow label="Till" value={todo.assignee} />
              {props.inArchive && <MobileTableRow label="Arkiverad datum" value={formatDate(todo.archiveDate)} />}
            </MobileTable>
          ))}
        </>
      ) : (
        <>
          <TableHead>
            <TableRow>
              <TableCell>
                <span className={'sr-only'}>Knappar</span>
              </TableCell>
              <TableCell className={classes.tableCellSmall}>
                Id
                <SortButton
                  property="id"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell className={classes.tableCellMedium}>
                Ska vara klart
                <SortButton
                  property="deadline"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Beskrivning
                <SortButton
                  property="description"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Från
                <SortButton
                  property="assigner"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Till
                <SortButton
                  property="assignee"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              {props.inArchive && (
                <TableCell>
                  Arkiverad datum
                  <SortButton
                    property="archivedate"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {todos?.items
              .sort((a, b) => b.id - a.id)
              .map((todo) => (
                <TableRow className={classes.pointerCursor} hover tabIndex={-1} key={todo.id}>
                  {confirmDeleteId !== todo.id ? (
                    <>
                      <AccessGuard
                        module={ModuleIdentifiers.Todo}
                        accessRights={AccessRights.Full}
                        fallback={<TableCell></TableCell>}
                      >
                        <DropdownCell>
                          {props.inArchive ? (
                            <MenuItem onClick={() => setConfirmDeleteId(todo.id)}>
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <Typography variant="inherit">Ta bort</Typography>
                            </MenuItem>
                          ) : (
                            <MenuItem disabled={!todo.canBeArchived} onClick={() => handleArchive(todo.id.toString())}>
                              <ListItemIcon>
                                <ArchiveIcon />
                              </ListItemIcon>
                              <Typography variant="inherit">Arkivera</Typography>
                            </MenuItem>
                          )}
                        </DropdownCell>
                      </AccessGuard>

                      <TableCell onClick={() => handleTableClick(todo)} className={classes.linkFont}>
                        {todo.id}
                      </TableCell>
                      <TableCell className={getClasses(todo)} onClick={() => handleTableClick(todo)}>
                        {formatDate(todo.deadline)}
                      </TableCell>
                      <TableCell onClick={() => handleTableClick(todo)}>{todo.description}</TableCell>
                      <TableCell onClick={() => handleTableClick(todo)} className={classes.linkFont}>
                        {todo.sendDate && formatDateWithTime(todo.sendDate) + ' - '} {todo.assignor}
                      </TableCell>
                      <TableCell onClick={() => handleTableClick(todo)} className={classes.linkFont}>
                        {todo.answerDate && formatDateWithTime(todo.answerDate) + ' - '} {todo.assignee}
                      </TableCell>
                      {props.inArchive && (
                        <TableCell onClick={() => handleTableClick(todo)} className={classes.linkFont}>
                          {formatDate(todo.archiveDate)}
                        </TableCell>
                      )}
                    </>
                  ) : (
                    <ConfirmDeleteOrArchiveRow
                      onConfirm={handleDelete}
                      onCancel={() => setConfirmDeleteId(null)}
                      colspan={4}
                      loading={isDeleteLoading}
                    />
                  )}
                </TableRow>
              ))}
          </TableBody>
        </>
      )}
    </Table>
  );
};

export default TodoList;
