import React from 'react';
import MuiButton, { ButtonProps } from '@material-ui/core/Button/Button';
import useStyles from './LinkButton.styles';

interface ILinkButtonProps extends ButtonProps {
  loading?: boolean;
}

const LinkButton = (props: ILinkButtonProps) => {
  const { children, ...rest } = props;

  const classes = useStyles();
  return (
    <MuiButton {...rest} className={classes.root} disableRipple>
      {children}
    </MuiButton>
  );
};

export default LinkButton;
