import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  useMediaQuery,
  InputAdornment,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import { Fieldset } from '../../../../../../Shared/Form/Fieldset/Fieldset';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import PostalCodeField from '../../../../../../Shared/InputFields/PostalCodeField/PostalCodeField';
import useInputState from '../../../../../../Shared/Hooks/UseInputState/UseInputState';
import useNullableNumericInputState from '../../../../../../Shared/Hooks/UseNullableNumericInputState/UseNullableNumericInputState';
import Button from '../../../../../../Shared/Button/Button';
import Container from '../../../../../../Shared/Container/Container';
import { isResultError } from '../../../../../../Shared/Api/response/ICreateResult';
import Datepicker from '../../../../../../Shared/InputFields/DatePicker/Datepicker';
import { Table } from '../../../../../../Shared/Table/Table';
import useFormValidation from '../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import { MobileTable } from '../../../../../../Shared/Table/MobileTable/MobileTable';
import IdentificationNumberField from '../../../../../../Shared/InputFields/IdentificationNumberField/IdentificationNumberField';
import { CustomerType } from '../../../../../../Shared/Misc/CustomerType';
import EmailField from '../../../../../../Shared/InputFields/EmailField/EmailField';
import { SingleRowInputField } from '../../../../../../Shared/InputFields/InputField/SingleRowInputField';
import { MobileTableRow } from '../../../../../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import PhoneNumberField from '../../../../../../Shared/InputFields/PhoneNumberField/PhoneNumberField';
import { DialogContentHeader } from '../../../../../../Shared/DialogContentHeader/DialogContentHeader';
import DialogForm from '../../../../../../Shared/DialogForm/DialogForm';
import useUserContext from '../../../../../../Core/Authentication/UserContext';
import {
  AccessRights,
  AccessRightsGroup,
  getAccessRightsGroup,
  ModuleIdentifiers,
} from '../../../../../../Core/Authentication/ModuleAccess';
import CompanyBranchSelect from './CompanyBranchSelect/CompanyBranchSelect';
import CompanyUserEducation from './Education/Education';
import { CompanyUserAccessRights as CompanyUserAccessRightsAndRoles } from './AccessRights/AccessRights';
import {
  useCreateCompanyUser,
  useGetCompanyUser,
  IEditCompanyUserBody,
  IEditCompanyUserResult,
  IUser,
  useUpdateCompanyUser,
  ICompanyUserModuleAccess,
  IUserEducation,
  useSendInvite,
  useDeleteCompanyUser,
  Role,
  IProcessOwnership,
} from '../Users.api';
import theme from '../../../../../../Styles/Theme';
import AccessGuard from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import SearchInput from '../../../../../../Shared/InputFields/SearchInput/SearchInput';

export interface IUserListProps {
  onUserSaved?: (userId: string, companyId?: string) => void;
  onCancel?: (companyId?: string) => void;
}

export const EditUser = (props: IUserListProps) => {
  const { onUserSaved, onCancel } = props;
  const history = useHistory();
  const { id, companyId } = useParams<{ id?: string; companyId?: string }>();
  const { user: currentUser, refetch } = useUserContext();
  const { isFormValid, formRef } = useFormValidation();

  const { isLoading: getIsLoading, response: user, getCompanyUser } = useGetCompanyUser();
  const { isLoading: createIsLoading, createCompanyUser } = useCreateCompanyUser();
  const { isLoading: updateIsLoading, updateCompanyUser } = useUpdateCompanyUser();
  const { isLoading: sendIsLoading, sendInvite } = useSendInvite();
  const { isLoading: isDeleteLoading, deleteCompanyUser } = useDeleteCompanyUser();

  const [processOwnership, SetProcessOwnership] = useState<IProcessOwnership[] | undefined>(user?.processOwnership);
  const [originalName, setOriginalName] = useState('');
  const [identificationNumber, changeIdentificationNumber, setIdentificationNumber] = useInputState('');
  const [firstname, changeFirstname, setFirstname] = useInputState('');
  const [lastname, changeLastname, setLastname] = useInputState('');
  const [address, changeAddress, setAddress] = useInputState('');
  const [postalCode, changePostalCode, setPostalCode] = useInputState('');
  const [city, changeCity, setCity] = useInputState('');
  const [phoneNumber, changePhoneNumber, setPhoneNumber] = useInputState('');
  const [email, changeEmail, setEmail] = useInputState('');
  const [employmentDate, setEmploymentDate] = useState<Date | null>(null);
  const [controlDate1, setControlDate1] = useState<Date | null>(null);
  const [controlDate2, setControlDate2] = useState<Date | null>(null);
  const [controlDate3, setControlDate3] = useState<Date | null>(null);
  const [salesBudget, changeSalesBudget, setSalesBudget] = useNullableNumericInputState(null);
  const [offerBudget, changeOfferBudget, setOfferBudget] = useNullableNumericInputState(null);
  const [purchaseLimit, changePurchaseLimit, setPurchaseLimit] = useNullableNumericInputState(null);
  const [numOfOffers, changeNumOfOffers, setNumOfOffers] = useInputState(0);
  const [organizationDepartment, changeOrganizationDepartment, setOrganizationDepartment] = useInputState('');
  const [organizationJobDescription, changeOrganizationJobDescription, setOrganizationJobDescription] = useInputState(
    '',
  );
  const [privateEmail, changePrivateEmail, setPrivateEmail] = useInputState('');
  const [privatePhoneNumber, changePrivatePhoneNumber, setPrivatePhoneNumber] = useInputState('');
  const [companyBranchId, setCompanyBranchId] = useState<number | undefined>();
  const [userEducation, setUserEducation] = useState<IUserEducation[]>([]);
  const [accessRightsGroup, setAccessRightsGroup] = useState<AccessRightsGroup>(AccessRightsGroup.Custom);
  const [userModuleAccess, setUserModuleAccess] = useState<ICompanyUserModuleAccess[]>([]);
  const [userRoles, setUserRoles] = useState<Role[]>([]);

  const [confirmDeleteUser, setConfirmDeleteUser] = useState<boolean>(false);

  const [identificationNumberErrorString, setIdentificationNumberErrorString] = useState<string>();
  const [firstnameErrorString, setFirstnameErrorString] = useState<string>();
  const [lastnameErrorString, setLastnameErrorString] = useState<string>();
  const [phoneNumberErrorString, setPhoneNumberErrorString] = useState<string>();
  const [postalCodeErrorString, setPostalCodeErrorString] = useState<string>();
  const [addressErrorString, setAddressErrorString] = useState<string>();
  const [cityErrorString, setCityErrorString] = useState<string>();
  const [emailErrorString, setEmailErrorString] = useState<string>();
  const [privateEmailErrorString, setPrivateEmailErrorString] = useState<string>();
  const [privatePhoneNumberErrorString, setPrivatePhoneNumberErrorString] = useState<string>();
  const [query, setQuery] = useState<string>('');

  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const isLoading = createIsLoading || updateIsLoading || getIsLoading;

  const setFields = useCallback(
    (user: IUser) => {
      setOriginalName(user.firstName + ' ' + user.lastName);
      setIdentificationNumber(user.identificationNumber);
      setFirstname(user.firstName);
      setLastname(user.lastName);
      setAddress(user.address);
      setPostalCode(user.postalCode);
      setCity(user.city);
      setPhoneNumber(user.phoneNumber);
      setEmail(user.email);
      setPrivateEmail(user.privateEmail);
      setPrivatePhoneNumber(user.privatePhoneNumber);

      setEmploymentDate(user.employmentDate ? new Date(user.employmentDate) : null);
      setControlDate1(user.controlDate1 ? new Date(user.controlDate1) : null);
      setControlDate2(user.controlDate2 ? new Date(user.controlDate2) : null);
      setControlDate3(user.controlDate3 ? new Date(user.controlDate3) : null);

      setSalesBudget(user.salesBudget);
      setOfferBudget(user.offerBudget);
      setNumOfOffers(user.offerAmount);
      setPurchaseLimit(user.purchaseLimit);

      setCompanyBranchId(user.companyBranchId);
      setOrganizationDepartment(user.organizationDepartment);
      setOrganizationJobDescription(user.organizationJobDescription);

      setUserEducation(user.education);
      setUserModuleAccess(user.moduleAccessRights);
      setUserRoles(user.roles);
    },
    [
      setAddress,
      setCity,
      setControlDate1,
      setControlDate2,
      setControlDate3,
      setEmail,
      setEmploymentDate,
      setFirstname,
      setIdentificationNumber,
      setLastname,
      setNumOfOffers,
      setOfferBudget,
      setOrganizationDepartment,
      setOrganizationJobDescription,
      setPhoneNumber,
      setPostalCode,
      setPrivateEmail,
      setPrivatePhoneNumber,
      setPurchaseLimit,
      setSalesBudget,
      setUserEducation,
      setUserModuleAccess,
    ],
  );

  useEffect(() => {
    const loadUser = async () => {
      if (id) {
        var user = await getCompanyUser(id);
        if (user) {
          setFields(user);
          if (user.moduleAccessRights) {
            setAccessRightsGroup(getAccessRightsGroup(user.moduleAccessRights));
          }
        } else {
          onCancel && onCancel();
        }
      }
    };
    loadUser();
  }, [id, getCompanyUser, setFields, onCancel]);

  async function edit() {
    const body: IEditCompanyUserBody = {
      identificationNumber: identificationNumber,
      firstname: firstname,
      lastname: lastname,
      address: address,
      city: city,
      postalCode: postalCode,
      phoneNumber: phoneNumber,
      email: email,
      privateEmail: privateEmail ? privateEmail : null,
      privatePhoneNumber: privatePhoneNumber ? privatePhoneNumber : null,
      employmentDate: !employmentDate ? null : new Date(employmentDate),
      controlDate1: controlDate1,
      controlDate2: controlDate2,
      controlDate3: controlDate3,
      salesBudget: salesBudget,
      offerBudget: offerBudget,
      purchaseLimit: purchaseLimit,
      offerAmount: numOfOffers,
      companyId: companyId,
      companyBranchId: companyBranchId,
      organizationDepartment: organizationDepartment ? organizationDepartment : null,
      organizationJobDescription: organizationJobDescription ? organizationJobDescription : null,
      educationList: userEducation,
      moduleAccessRights: userModuleAccess
        .filter((x) => x.accessRights !== null)
        .map((x) => ({
          moduleIdentifier: x.moduleIdentifier,
          accessRights: x.accessRights as AccessRights,
        })),
      roles: userRoles,
    };

    let result;
    if (id) {
      result = await updateCompanyUser(id, body);
    } else {
      result = await createCompanyUser(body);
    }

    //reload user
    if (id === currentUser.selectedUserId?.toString()) refetch();
    isResultError(result) ? setErrors(result) : onUserSaved && onUserSaved(result, companyId);
  }

  async function sendInviteToUser() {
    if (!user?.id || user.inviteAccepted) return;
    await sendInvite(user.id.toString());
  }

  async function handleDeleteUser() {
    if (user) {
      await deleteCompanyUser(user.id);
      history.goBack();
    }
  }

  function setErrors(result: IEditCompanyUserResult) {
    setIdentificationNumberErrorString(result.identificationNumber?.join(' '));
    setFirstnameErrorString(result.firstname?.join(' '));
    setLastnameErrorString(result.lastname?.join(' '));
    setPhoneNumberErrorString(result.phoneNumber?.join(' '));
    setPostalCodeErrorString(result.postalCode?.join(' '));
    setAddressErrorString(result.address?.join(' '));
    setCityErrorString(result.city?.join(' '));
    setEmailErrorString(result.email?.join(' '));
    setPrivateEmailErrorString(result.privateEmail?.join(' '));
    setPrivatePhoneNumberErrorString(result.privatePhoneNumber?.join(' '));
  }

  useEffect(() => {
    if (query) {
      SetProcessOwnership((x) =>
        x?.filter(
          (y) =>
            y.name.toLocaleLowerCase().includes(query.trim().toLowerCase()) || y.sectionNumber.includes(query.trim()),
        ),
      );
    } else if (user?.processOwnership) {
      SetProcessOwnership(user.processOwnership);
    }
  }, [query, user]);

  return (
    <Container
      form
      label={id ? `Redigera ${originalName}` : 'Lägg till ny användare'}
      actions={
        <>
          {id && (
            <AccessGuard module={ModuleIdentifiers.Admin} accessRights={AccessRights.Full}>
              <Button
                color="default"
                variant="outlined"
                onClick={() => {
                  setConfirmDeleteUser(true);
                }}
              >
                Ta bort
              </Button>
            </AccessGuard>
          )}
          <Button
            color="default"
            variant="outlined"
            disabled={!onCancel}
            onClick={() => onCancel && onCancel(companyId)}
          >
            Avbryt
          </Button>
          <Button color="primary" variant="contained" onClick={edit} loading={isLoading} disabled={!isFormValid}>
            Spara
          </Button>
          {user?.id && !user.inviteAccepted && (
            <Button
              delay={30000}
              color="primary"
              variant="contained"
              loading={sendIsLoading}
              onClick={sendInviteToUser}
            >
              Skicka ny inbjudningslänk
            </Button>
          )}
        </>
      }
      loading={getIsLoading}
      ref={formRef}
      customSize={{
        md: 12,
        lg: 12,
      }}
    >
      <Fieldset legend="Personuppgifter">
        <IdentificationNumberField
          withoutLastFourDigits
          customerType={CustomerType.Private}
          onChange={changeIdentificationNumber}
          required
          errorText={identificationNumberErrorString}
          value={identificationNumber}
          fullwidth
        />
        <InputField
          label="Förnamn"
          type="text"
          errorText={firstnameErrorString}
          onChange={changeFirstname}
          required
          value={firstname}
        />
        <InputField
          label="Efternamn"
          type="text"
          onChange={changeLastname}
          required
          errorText={lastnameErrorString}
          value={lastname}
        />
        <SingleRowInputField
          label="Gatuadress"
          type="text"
          onChange={changeAddress}
          required
          errorText={addressErrorString}
          value={address}
        />
        <PostalCodeField
          label="Postnummer"
          type="text"
          required
          value={postalCode}
          onChange={changePostalCode}
          errorText={postalCodeErrorString}
        />
        <InputField label="Ort" type="text" onChange={changeCity} required errorText={cityErrorString} value={city} />
        <PhoneNumberField
          label="Telefon"
          onChange={changePhoneNumber}
          required
          errorText={phoneNumberErrorString}
          value={phoneNumber}
        />
        <EmailField label="E-post" onChange={changeEmail} value={email} required errorText={emailErrorString} />
        <PhoneNumberField
          label="Privat telefon"
          onChange={changePrivatePhoneNumber}
          errorText={privatePhoneNumberErrorString}
          value={privatePhoneNumber}
        />
        <EmailField
          label="Privat e-post"
          onChange={changePrivateEmail}
          value={privateEmail}
          errorText={privateEmailErrorString}
        />
      </Fieldset>

      <Fieldset legend="Datum">
        <Datepicker label="Datum anställd" selected={employmentDate} onChange={setEmploymentDate} />
        <Datepicker label="Senaste kontroll 1" selected={controlDate1} onChange={setControlDate1} />
        <Datepicker label="Senaste kontroll 2" selected={controlDate2} onChange={setControlDate2} />
        <Datepicker label="Senaste kontroll 3" selected={controlDate3} onChange={setControlDate3} />
      </Fieldset>

      <Fieldset legend="Kriterier">
        <InputField
          label="Försäljningsbudget"
          endAdornment={<InputAdornment position="end">SEK</InputAdornment>}
          value={salesBudget}
          type="number"
          onChange={changeSalesBudget}
        />
        <InputField label="Inköpsgräns" value={purchaseLimit} type="number" onChange={changePurchaseLimit} />
        <InputField label="Offert antal" value={numOfOffers} type="number" onChange={changeNumOfOffers} />
        <InputField
          label="Offertgräns"
          value={offerBudget}
          endAdornment={<InputAdornment position="end">SEK</InputAdornment>}
          type="number"
          onChange={changeOfferBudget}
        />
      </Fieldset>

      <Fieldset legend="Organisation">
        <InputField
          label="Avdelning"
          type="text"
          value={organizationDepartment}
          onChange={changeOrganizationDepartment}
        />
        <CompanyBranchSelect
          label="Välj filial"
          onChange={(companyBranchId) => setCompanyBranchId(companyBranchId as number | undefined)}
          selectedCompanyBranchId={companyBranchId}
        />
        <InputField
          label="Arbetsbeskrivning"
          type="text"
          multiline
          fullwidth
          rows="5"
          value={organizationJobDescription}
          onChange={changeOrganizationJobDescription}
        />

        {processOwnership && (
          <Fieldset useAsExpandPanel legend="Processägare">
            <Table
              mobile={mobileView}
              customSize={{ fullsize: true }}
              filters={
                <SearchInput
                  defaultValue={query}
                  type="search"
                  placeholder="Sök"
                  onChange={(search) => setQuery(search)}
                />
              }
            >
              {mobileView ? (
                <>
                  {processOwnership.map((item) => (
                    <MobileTable label={item.name}>
                      <MobileTableRow label="Avsnitt nr" value={item.sectionNumber} />
                      <MobileTableRow label="Dokumentnamn" value={item.name} />
                    </MobileTable>
                  ))}
                </>
              ) : (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell>Avsnitt nr</TableCell>
                      <TableCell>Dokumentnamn</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {processOwnership.map((item) => (
                      <TableRow>
                        <TableCell>{item.sectionNumber}</TableCell>
                        <TableCell>{item.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </>
              )}
            </Table>
          </Fieldset>
        )}
      </Fieldset>
      <CompanyUserEducation rows={userEducation} setRows={setUserEducation} />
      <CompanyUserAccessRightsAndRoles
        userModuleAccess={userModuleAccess}
        accessRightsGroup={accessRightsGroup}
        userRoles={userRoles}
        setUserModuleAccess={setUserModuleAccess}
        setAccessRightsGroup={setAccessRightsGroup}
        setUserRole={setUserRoles}
      />
      <Dialog open={confirmDeleteUser}>
        <DialogContent>
          <DialogContentHeader
            onClose={() => setConfirmDeleteUser(false)}
            title={'Är du säker på att du vill ta bort denna användare?'}
            titleId=""
          />
          <DialogForm
            CustomWidth={100}
            actions={
              <>
                <Button variant="outlined" onClick={() => setConfirmDeleteUser(false)}>
                  Avbryt
                </Button>
                <Button color="primary" variant="contained" onClick={handleDeleteUser} loading={isDeleteLoading}>
                  Radera
                </Button>
              </>
            }
          >
            {}
          </DialogForm>
        </DialogContent>
      </Dialog>
    </Container>
  );
};
