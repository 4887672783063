import React from 'react';
import { Typography, TableCell } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';
import CheckIcon from '@material-ui/icons/CheckRounded';
import IconButton from '../../IconButton/IconButton';
import { useStyles } from './ConfirmDeleteOrArchiveRow.styles';

interface IConfirmDeleteOrArchiveRowProps {
  label?: string | number;
  colspan?: number;
  onConfirm: () => void;
  onCancel: () => void;
  loading?: boolean;
  mobile?: boolean;
  archiveText?: boolean;
  isArchive?: boolean;
}

export const ConfirmDeleteOrArchiveRow = (props: IConfirmDeleteOrArchiveRowProps) => {
  const classes = useStyles();

  const Content = () => (
    <div className={classes.root}>
      <IconButton
        size="small"
        autoFocus
        className={classes.confirm}
        onClick={props.onConfirm}
        aria-label={
          props.isArchive
            ? `Vill du ta bort platsen från arkivet?`
            : `Är du säker på att du vill ${props.archiveText ? 'arkivera' : 'ta bort'} ${props.label}?`
        }
        loading={props.loading}
      >
        <CheckIcon />
      </IconButton>
      <IconButton size="small" onClick={props.onCancel} aria-label="Avbryt" disabled={props.loading}>
        <CloseIcon />
      </IconButton>
      <Typography className={classes.text} aria-hidden="true">
        {props.isArchive ? (
          <>Vill du ta bort platsen från arkivet?</>
        ) : (
          <>
            Är du säker på att du vill {props.archiveText ? 'arkivera' : 'ta bort'}
            <span className={classes.label}> {props.label}</span>?
          </>
        )}
      </Typography>
    </div>
  );

  return !props.mobile ? (
    <TableCell colSpan={props.colspan}>
      <Content />
    </TableCell>
  ) : (
    <div className={classes.mobile}>
      <Content />
    </div>
  );
};
