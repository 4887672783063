import React from 'react';
import ServiceOrderForm, { IServiceOrderFormProps } from './ServiceOrderForm/ServiceOrderForm';
import PopperMenu from '../../../../../../../../Shared/Popper/Popper';
export interface IServiceOrderPopupProps extends IServiceOrderFormProps {}

const ServiceOrderPopup = (props: IServiceOrderPopupProps) => {
  const { onCancel, onCreate } = props;
  const [open, setOpen] = React.useState(false);

  function setClose() {
    setOpen(false);
  }

  function handleCreate(id: number) {
    setClose();
    onCreate(id);
  }

  function handleCancel() {
    setClose();
    onCancel && onCancel();
  }

  return (
    <div>
      <PopperMenu
        color="primary"
        variant="outlined"
        setOpen={setOpen}
        open={open}
        alwaysDialog
        customContent
        title={'Lägg till Service'}
        content={
          <ServiceOrderForm errorReportId={props.errorReportId} onCreate={handleCreate} onCancel={handleCancel} />
        }
      >
        Lägg till
      </PopperMenu>
    </div>
  );
};

export default ServiceOrderPopup;
