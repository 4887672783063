import React, { ReactNode } from 'react';
import PopperMenu from '../../../../../Shared/Popper/Popper';
import ActivityForm, { IActivity, IActivityFormProps } from '../ActivityForm/ActivityForm';
import { useStyles } from './EditActivity.styles';

export interface IEditActivityProps extends IActivityFormProps {
  children?: ReactNode;
  inCalendar?: boolean;
  icon?: boolean;
  activityLabel?: string;
}

const EditActivity = (props: IEditActivityProps) => {
  const classes = useStyles();
  const { onSave, onRemove, onClose, inCalendar, children, ...rest } = props;
  const [open, setOpen] = React.useState(false);

  const handleSave = (activity: IActivity) => {
    setClose(true);
    onSave && onSave(activity);
  };

  const handleRemove = (id: number) => {
    setClose(true);
    onRemove && onRemove(id);
  };

  function setClose(reload?: boolean) {
    setOpen(false);
    onClose && onClose(reload);
  }

  return (
    <div className={classes.root}>
      <PopperMenu
        open={open || (rest.open ? true : false)}
        setOpen={setOpen}
        buttonType={props.icon ? 'icon' : inCalendar ? 'unstyled' : undefined}
        onClose={() => setClose()}
        alwaysDialog
        customContent
        isActivityWindow={rest.isActivityWindow}
        title={props.activityLabel ?? 'Aktivitet'}
        content={<ActivityForm {...rest} onClose={(reload?: boolean) => setClose(reload)} onRemove={handleRemove} onSave={handleSave} />}
      >
        {props.children}
      </PopperMenu>
    </div>
  );
};

export default EditActivity;
