import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  bmslList: {
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      gap: '1px',
      marginTop: '10px',
    },
    width: '30%',
  },

  navigation: {
    marginBottom: '10px',
  },

  bmsNodes: {
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      marginLeft: '0px',
      marginTop: '10px',
      gap: '0px',
    },
    marginLeft: '10px',
    display: 'flex',
    width: '60%',
  },

  container: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
    },
    flex: '1 0 auto',
    width: '100%',
    margin: '13px',
    display: 'flex',
  },
}));
