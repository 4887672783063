import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  datalist: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 0,
    borderRadius: theme.shape.borderRadius,
    '& > *': {
      width: 'calc(50% - 1px - 0.5rem - 1.5rem)',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      padding: '0.5rem 1rem',
    },
  },
  border: {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  dt: {
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    fontWeight: 600,
  },
  dd: {
    marginLeft: 0,
  },
  item: {
    '&:last-child': {
      borderBottom: 0,
    },
    '&:nth-last-child(2)': {
      borderBottom: 0,
    },
  },
}));
