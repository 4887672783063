import React, { useState } from 'react';
import ErrandWarningContainer from './ErrandWarningContainer/ErrandWarningContainer';
import { IWarningContainer } from './ErrandWarningContainer/ErrandWarningContainer';
import PopperMenu from '../../../../Shared/Popper/Popper';
import Button from '../../../../Shared/Button/Button';
import useStyles from './ErrandWarningContainer/ErrandWarningContainer.styles';
import { DialogContent } from '@material-ui/core';
import DialogForm from '../../../../Shared/DialogForm/DialogForm';
import { WarningLabel } from '../Components/Sale.api';

interface IWarningPopUp extends IWarningContainer {
  title: string;
  isLoading: boolean;
  action: () => void;
}

const ErrandsWarningPopUp = (props: IWarningPopUp) => {
  const classes = useStyles();
  const { id, warningStatus, isLoading, action } = props;

  const [open, setOpen] = useState<boolean>(false);

  const Action = () => (
    <>
      <Button color="default" variant="outlined" onClick={() => setOpen(false)}>
        Nej
      </Button>
      <Button loading={isLoading} className={classes.cautionButton} variant="outlined" onClick={action}>
        Ja
      </Button>
    </>
  );

  return (
    <PopperMenu
      color="secondary"
      variant="outlined"
      alwaysDialog
      customContent
      open={open}
      setOpen={setOpen}
      content={
        <DialogContent>
          <DialogForm actions={<Action />}>
            <ErrandWarningContainer id={id} warningStatus={warningStatus} />
          </DialogForm>
        </DialogContent>
      }
    >
      Ta bort {`${WarningLabel[warningStatus]}`}
    </PopperMenu>
  );
};

export default ErrandsWarningPopUp;
