import React from 'react';
import { ISurveyQuestion } from '../../Survey.api';
import { InputField } from '../../../../../Shared/InputFields/InputField/InputField';

export interface ITextQuestionProps {
  question: ISurveyQuestion;
  answer: string;
  onAnswerChange: (answer: string) => void;
}

const TextQuestion = (props: ITextQuestionProps) => {
  const { question, answer, onAnswerChange } = props;

  return (
    <InputField
      label={question.question}
      type="text"
      multiline
      required
      rows={2}
      value={answer}
      onChange={(e) => onAnswerChange(e.target.value)}
    />
  );
};

export default TextQuestion;
