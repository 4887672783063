import React, { useCallback, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import EditActivity from '../EditActivity/EditActivity';
import { IActivity } from '../ActivityForm/ActivityForm';
import { CalendarEntryType, calendarEntryTypes } from '../../../../Modules/Booking/Booking.api';
import { useStyles } from './EventWrapper.styles';
import { SaleStatus } from '../../../../Modules/Errands/Components/Sale.api';

interface IEventWrapperProps {
  type: CalendarEntryType;
  activity?: IActivity | undefined;
  onSave?: (activity: IActivity) => void;
  onRemove?: (id: number) => void;
  showInCompanyCalendarByDefault?: boolean;
  isCompanyCalendar?: boolean;
  readonly?: boolean;
}

const EventWrapper: React.FC<IEventWrapperProps> = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [mouseCapture, setMouseCapture] = useState(true);

  const getBackgroundColor = () => {
    switch (props.type) {
      case CalendarEntryType.OffDuty:
      case CalendarEntryType.Sick:
      case CalendarEntryType.SickChild:
      case CalendarEntryType.Holiday:
        return classes.gray;
      case CalendarEntryType.Misc:
        return classes.blue;
      default:
        if (props.activity?.saleStatus) {
          if (props.activity?.saleStatus === SaleStatus.invoicing || props.activity?.saleStatus === SaleStatus.completed || props.activity?.saleStatus === SaleStatus.archived) {
            return classes.green;
          }
          if (props.activity?.saleStatus === SaleStatus.installed) {
            return classes.yellow;
          }
          let today = new Date();
          today.setDate(today.getDate() - 1);
          if (props.activity?.endDate && new Date(props.activity?.endDate) < today) {
            return classes.red;
          }
        }
        return classes.gray;
    }
  };
  const onMouseOverCapture = useCallback(() => setOpen(mouseCapture), [mouseCapture]);

  const toggleToolTip = () => {
    if (open) {
      setMouseCapture(false);
    }
  };

  const onClosed = () => {
    setMouseCapture(!mouseCapture);
  };

  return (
    <Tooltip
      title={props.activity ? props.activity.description : ''}
      onClick={() => toggleToolTip()}
      onMouseOverCapture={onMouseOverCapture}
      onMouseOutCapture={() => setOpen(false)}
      open={open}
      style={{ textTransform: 'capitalize' }}
    >
      <div className={getBackgroundColor()}>
        {props.readonly && props.children}
        {!props.readonly && (
          <EditActivity
            onSave={props.onSave}
            onRemove={props.onRemove}
            editActivityId={props.activity?.id}
            saleId={props.activity?.saleId}
            showInCompanyCalendarByDefault={props.showInCompanyCalendarByDefault}
            isCompanyCalendar={props.isCompanyCalendar}
            activityLabel={(calendarEntryTypes.find((c) => c.type === props.activity?.type)?.name ?? 'Aktivitet') + (props.activity?.facilityName ? ': ' + props.activity?.facilityName : props.activity?.otherActivity ? (': ' + props.activity?.otherActivity) : '')}
            inCalendar
            onClose={() => onClosed()}
          >
            {props.children}
          </EditActivity>
        )}
      </div>
    </Tooltip>
  );
};

export default EventWrapper;
