import React, { useCallback, useMemo, useState } from 'react';
import Container from '../../../../../Shared/Container/Container';
import PopperMenu from '../../../../../Shared/Popper/Popper';
import Button from '../../../../../Shared/Button/Button';
import DialogForm from '../../../../../Shared/DialogForm/DialogForm';
import { Fieldset } from '../../../../../Shared/Form/Fieldset/Fieldset';
import { AsyncSelect } from '../../../../../Shared/Select';
import { InputField } from '../../../../../Shared/InputFields/InputField/InputField';
import { isResultError } from '../../../../../Shared/Api/response/ICreateResult';
import { ErrorCode, isErrorResponse } from '../../../../../Shared/Api/response/IErrorRespose';
import useInputState from '../../../../../Shared/Hooks/UseInputState/UseInputState';
import useErrorMessage from '../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import {
  IFileNode,
  IUpsertFileNodeCommand,
  useDeleteFileSystemNode,
  useSearchFileSystemFolders,
  useUpsertFileSystemNode,
} from '../../FileSystem.api';
import AccessGuard from '../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { AccessRights, ModuleIdentifiers } from '../../../../../Core/Authentication/ModuleAccess';

//Note: node overrides parentNodeId, component should not take both props at the same time
const EditNodeForm: React.FC<{
  node?: IFileNode;
  parentNode?: IFileNode;
  closeForm: (refetch?: boolean) => void;
  closeFile: () => void;
}> = (props) => {
  const { node, parentNode } = props;
  const { isLoading: isSearchLoading, searchFolders } = useSearchFileSystemFolders();
  const { isLoading: isUpsertLoading, upsertNode } = useUpsertFileSystemNode();
  const { isLoading: isDeleteLoading, deleteNode } = useDeleteFileSystemNode();
  const { setValidationErrorMessage, setErrorMessage } = useErrorMessage();
  const [parentNodeId, setParentNodeId] = useState<number | null>(node ? node.parentNodeId : parentNode?.id ?? null);
  const [nodeName, changeNodeName] = useInputState(node?.name);
  const [openPopup, setOpenPopup] = useState(false);
  const isLoading = isUpsertLoading || isDeleteLoading;
  const isValid = !!nodeName;
  const isFile = node && node.nodeType !== 'folder';

  const loadNodes = useCallback(
    async (query?: string) => {
      const response = await searchFolders({ query: query, selectedNode: node?.id });
      return response?.map((x) => ({ value: x.id.toString(), label: x.name }));
    },
    [node, searchFolders],
  );
  const parentNodeOption = useMemo(() => {
    if (!parentNode) return undefined;
    return { value: parentNode.id.toString(), label: parentNode.name };
  }, [parentNode]);

  async function save() {
    if (!isValid) return;
    const command: IUpsertFileNodeCommand = {
      parentNodeId: parentNodeId ?? undefined,
      nodeName: nodeName!,
    };
    const result = await upsertNode(command, node?.id.toString());
    if (isResultError(result)) {
      setValidationErrorMessage(result);
    } else {
      props.closeForm(true);
    }
  }

  async function handleDelete() {
    if (!node) return;
    const result = await deleteNode(node.id.toString());
    setOpenPopup(false);
    if (isErrorResponse(result) && result.errorCode === ErrorCode.EntityInUse) {
      setErrorMessage({
        message: 'Det Gick inte att ta bort mappen då det inte är möjligt att radera mappar som innehåller filer',
      });
    } else {
      props.closeForm(true);
      props.closeFile();
    }
  }

  function handleKeydown(event: React.KeyboardEvent) {
    if (event.key === '.') {
      event.preventDefault();
    }
  }

  return (
    <Container
      customSize={{ sm: 12, md: 4, lg: 3 }}
      label={`${node ? 'Redigera' : 'Skapa'} ${isFile ? 'fil' : 'mapp'}`}
      actions={
        <>
          <Button variant="outlined" disabled={isLoading} onClick={() => props.closeForm()}>
            Avbryt
          </Button>
          {node && (
            <AccessGuard module={ModuleIdentifiers.FileSystem} accessRights={AccessRights.Full}>
              <PopperMenu
                color="secondary"
                variant="outlined"
                alwaysDialog
                customContent
                disabled={isLoading}
                open={openPopup}
                setOpen={setOpenPopup}
                title={`Är du säker att du vill radera ${isFile ? 'fil' : 'mapp'} ${node.name}?`}
                content={
                  <>
                    <DialogForm
                      actions={
                        <>
                          <Button color="default" variant="outlined" onClick={() => setOpenPopup(false)}>
                            Nej
                          </Button>
                          <Button variant="outlined" onClick={handleDelete}>
                            Ja
                          </Button>
                        </>
                      }
                      children={null}
                    />
                  </>
                }
              >
                Radera
              </PopperMenu>
            </AccessGuard>
          )}
          <Button color="primary" variant="contained" disabled={isLoading || !isValid} onClick={save}>
            Spara
          </Button>
        </>
      }
    >
      <Fieldset legend="Flytta">
        <AsyncSelect
          label="Huvudmapp"
          isDisabled={isLoading}
          isClearable={true}
          fullwidth
          isLoading={isSearchLoading}
          selectedOption={parentNode?.id === parentNodeId ? parentNodeOption : undefined}
          selectedOptionId={parentNodeId?.toString()}
          loadOptions={loadNodes}
          onChange={(option) => setParentNodeId(option ? Number(option.value) : null)}
        />
      </Fieldset>
      <Fieldset legend={node ? 'Ändra' : 'Nytt'}>
        <InputField
          label={`${isFile ? 'Fils' : 'Mapps'} namn`}
          type="text"
          required
          value={nodeName}
          onKeyDown={isFile ? handleKeydown : undefined}
          onChange={changeNodeName}
        />
      </Fieldset>
    </Container>
  );
};

export default EditNodeForm;
