import React from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './Label.styles';

interface ILabel {
  label?: string | React.ReactNode;
  action?: React.ReactNode;
  id?: string;
  invert?: boolean;
}

const Label = (props: ILabel) => {
  const classes = useStyles();
  return (
    <div className={(props.action ? classes.actionRoot : classes.root) + ' ' + (props.invert && classes.invert)}>
      <Typography variant="h3" component="h2" className={classes.h1} id={props.id}>
        {props.label}
      </Typography>

      {props.action && <>{props.action}</>}
    </div>
  );
};

export default Label;
