import { useApiPost, useApiGet, useApiPut, useApiDelete } from '../../../Core/Api/Api';
import ValidationErrorResultType from '../../../Shared/Api/response/ValidationErrorResultType';
import { ICreateResult } from '../../../Shared/Api/response/ICreateResult';
import IPaginatedResponse from '../../../Shared/Api/response/IPaginatedResponse';
import IPaginatedRequest from '../../../Shared/Api/request/IPaginatedRequest';
import { useCallback } from 'react';
import ISearchRequest from '../../../Shared/Api/request/ISearchRequest';
import { IFileResult, openFile } from '../../../Shared/Api/response/IFileResult';
export interface IBMSDto {
  id: number;
  parentNodeId: number | null;
  parentNodeName: string;
  hasChildren: boolean;
  sectionNumber: string;
  name: string;
  topic: string;
  isoVersion: string;
  issuedByCompanyUserId: number;
  issuedByCompanyUserName: string;
  determinedByCompanyUserId: number;
  determinedByCompanyUserName: string;
  processOwnerCompanyUserId: number;
  processOwnerCompanyUserName: string;
  version: number;
  olderVersions: number[];
  content: string;
  created: Date;
  lastModified: Date;
}

export function useGetBMSNode() {
  const { isLoading, response, get } = useApiGet<IBMSDto>('/api/businessmanagementsystem/', false);

  return {
    isLoading,
    node: response,
    getNode: get,
  };
}

export interface IUpsertBMSNodeCommand {
  parentNodeId: number | null;
  sectionNumber: string;
  name: string;
  topic: string;
  isoVersion: string;
  issuedByCompanyUserId: number;
  determinedByCompanyUserId: number;
  processOwnerCompanyUserId: number;
  content: string;
  isNewVersion: boolean;
}
export interface IUpsertBMSNodeValidationErrors extends ValidationErrorResultType<IUpsertBMSNodeCommand> {}

export function useUpsertBMSNode() {
  const { isLoading: isCreateLoading, post } = useApiPost<
    ICreateResult<IUpsertBMSNodeValidationErrors>,
    IUpsertBMSNodeCommand
  >('/api/businessmanagementsystem/');

  const { isLoading: isUpdateLoading, put } = useApiPut<IUpsertBMSNodeValidationErrors, IUpsertBMSNodeCommand>(
    '/api/businessmanagementsystem/',
  );

  async function upsert(command: IUpsertBMSNodeCommand, id?: string) {
    if (id) {
      const result = await put(command, id);
      return result ? result : id;
    }

    return post(command);
  }

  return {
    isLoading: isCreateLoading || isUpdateLoading,
    upsert: upsert,
  };
}

export function useDeleteBMSNode() {
  const { isLoading, delete: deleteRequest } = useApiDelete('/api/businessmanagementsystem/');

  const deleteNode = useCallback(
    async (id: string) => {
      return await deleteRequest(undefined, id);
    },
    [deleteRequest],
  );

  return {
    isLoading,
    deleteNode,
  };
}

export interface IBMSNode {
  id: number;
  parentNodeId: number | null;
  treeLevel: number;
  hasChildren: boolean;
  sectionNumber: string;
  name: string;
}
export interface IBMSNodeSearchRequest extends ISearchRequest {
  selectedNode?: number;
}

export function useSearchBMSNodes() {
  const { isLoading, response, post } = useApiPost<IBMSNode[], IBMSNodeSearchRequest>(
    '/api/businessmanagementsystem/search',
  );

  return {
    isLoading,
    nodes: response,
    searchNodes: post,
  };
}
export interface IBMSTreeNodeSearchRequest extends ISearchRequest {
  openNodes?: number[];
  selectedNode?: number;
}

export function useSearchBMSTreeNodes() {
  const { isLoading, response, post } = useApiPost<IBMSNode[], IBMSTreeNodeSearchRequest>(
    '/api/businessmanagementsystem/search/tree',
  );

  return {
    isLoading,
    nodes: response,
    searchNodes: post,
  };
}

export interface IBMSNodeListItem {
  id: number;
  sectionNumber: string;
  name: string;
  topic: string;
  processOwnerCompanyUserName: string;
  lastModified?: Date;
  lastModifiedByCompanyUserName?: string;
}

export function useGetBMSNodeList() {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<IBMSNodeListItem>, IPaginatedRequest>(
    '/api/businessmanagementsystem/list',
  );

  return {
    isLoading,
    nodes: response,
    getNodes: post,
  };
}

export interface IBMSSnapshotDto {
  id: number;
  nodeId: number;
  version: number;
  sectionNumber: string;
  name: string;
  content: string;
  created: Date;
}

export function useGetBMSNodeOldVersion(nodeId: string) {
  const { isLoading, response, get } = useApiGet<IBMSSnapshotDto>(
    `/api/businessmanagementsystem/${nodeId}/oldversion/`,
    false,
  );

  return {
    isLoading,
    snapshot: response,
    getSnapshot: get,
  };
}

export function useOpenDocument() {
  const { isLoading, get } = useApiGet<IFileResult>('/api/businessmanagementsystem/', false);
  const openDocument = useCallback(
    (nodeId: string) => {
      async function loadDocument() {
        openFile(await get(`${nodeId}/pdf`), false);
      }
      loadDocument();
    },
    [get],
  );
  return { isLoading, openDocument };
}
