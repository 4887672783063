import React, { useMemo } from 'react';
import { TableHead, TableRow, TableCell, TableBody, useMediaQuery } from '@material-ui/core';
import { Table } from '../../../../../../../../Shared/Table/Table';
import { MobileTable } from '../../../../../../../../Shared/Table/MobileTable/MobileTable';
import { MobileTableRow } from '../../../../../../../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import { InputField } from '../../../../../../../../Shared/InputFields/InputField/InputField';
import { IOfferProduct } from '../../../Offer.api';
import theme from '../../../../../../../../Styles/Theme';
import useStyles from './SelectedProducts.styles';
import formatDate from '../../../../../../../../Shared/Formatting/formatDate';

function transformProductsWithPlacements(
  selectedProducts: IOfferProduct[],
  p: IOfferProduct,
  index: number,
  placement: string,
): IOfferProduct[] {
  let newList = [...selectedProducts];
  //Find element and edit its placement
  const productIndex = selectedProducts.findIndex((x) => x.productId === p.productId);
  let newPlacement = [...selectedProducts[productIndex].placements];
  newPlacement[index] = placement;
  newList[productIndex].placements = newPlacement;
  return newList;
}

function sortProducts(products: IOfferProduct[]): IOfferProduct[] {
  const mainProduct = products.find((x) => x.isMain);
  const productList: IOfferProduct[] = [];

  if (mainProduct) {
    productList.push(mainProduct);

    const categories = [...new Set(products.map((p) => p.productCategoryName))]
      .map((categoryName) => ({
        name: categoryName,
        sortOrder: products.find((p) => p.productCategoryName === categoryName)?.productCategorySortOrder ?? 0,
      }))
      .sort((a, b) => a.sortOrder - b.sortOrder || a.name.localeCompare(b.name));

    for (const { name: category } of categories) {
      const productsInCategory = products
        .filter((product) => product.productCategoryName === category)
        .sort((a, b) => a.name.localeCompare(b.name));

      productList.push(...productsInCategory.filter((product) => product.productId !== mainProduct?.productId));
    }
  } else {
    productList.push(...products.sort((a, b) => a.name.localeCompare(b.name)));
  }

  return productList;
}

export interface ISelectedProductsProps {
  products: IOfferProduct[];
  setOfferProducts: React.Dispatch<React.SetStateAction<IOfferProduct[]>>;
}

const SelectedProducts: React.FunctionComponent<ISelectedProductsProps> = ({ products, setOfferProducts }) => {
  const classes = useStyles();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const sortedProducts = useMemo(() => sortProducts(products), [products]);

  return products.length > 0 ? (
    <Table customSize={{ fullsize: true }} label="Valda produkter" mobile={mobileView}>
      {mobileView ? (
        <>
          {sortedProducts.map((p) => {
            return (
              <>
                {p.placements.map((placement, index) => (
                  <MobileTable key={p.productId} label={p.name + ' ' + (p.placements.length === 1 ? '' : index + 1)}>
                    <MobileTableRow label="Beskrivning" value={p.description}></MobileTableRow>
                    <MobileTableRow label="Regelverk" value={p.regulation}></MobileTableRow>
                    <MobileTableRow label="Certifierad till" value={p.certificate}></MobileTableRow>
                    <MobileTableRow
                      label="Placering"
                      key={p.productId.toString() + '-' + index.toString()}
                      column={mobileView}
                    >
                      <InputField
                        placeholder="Ange placering"
                        fullwidth
                        value={placement}
                        onChange={(e) =>
                          setOfferProducts((x) => transformProductsWithPlacements(x, p, index, e.target.value))
                        }
                      />
                    </MobileTableRow>
                  </MobileTable>
                ))}
              </>
            );
          })}
        </>
      ) : (
        <>
          <TableHead>
            <TableRow>
              <TableCell>Namn</TableCell>
              <TableCell>Beskrivning</TableCell>
              <TableCell>Regelverk</TableCell>
              <TableCell>Certifierad till</TableCell>
              <TableCell>Placering</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedProducts.map((p) => {
              return (
                <React.Fragment key={p.productId}>
                  {p.placements.map((placement, index) => (
                    <TableRow key={p.productId.toString() + '-' + index.toString()}>
                      <TableCell>
                        {p.name} {p.placements.length === 1 ? '' : index + 1}
                      </TableCell>
                      <TableCell className={classes.description}>{p.description}</TableCell>
                      <TableCell>{p.regulation}</TableCell>
                      <TableCell>{formatDate(p.certificate)}</TableCell>
                      <TableCell>
                        <InputField
                          placeholder="Ange placering"
                          fullwidth
                          value={placement}
                          onChange={(e) =>
                            setOfferProducts((x) => transformProductsWithPlacements(x, p, index, e.target.value.slice(0,1).toLocaleUpperCase() + e.target.value.slice(1)))
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              );
            })}
          </TableBody>
        </>
      )}
    </Table>
  ) : (
    <></>
  );
};

export default SelectedProducts;
