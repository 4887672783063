import { useApiPost } from '../../../../../Core/Api/Api';
import IPaginatedResponse from '../../../../../Shared/Api/response/IPaginatedResponse';

export enum AgreementListCustomFilter {
  all = 0,
  new = 1,
  ending = 2,
  terminated,
}

export const agreementListCustomFilterLabel = {
  [AgreementListCustomFilter.new]: 'Nya avtal',
  [AgreementListCustomFilter.ending]: 'Utgående avtal',
  [AgreementListCustomFilter.terminated]: 'Uppsagda avtal',
};

export interface IAgreementListItems {
  id: number;
  agreementNumber: number;
  customerId: number;
  customerNumber: number;
  customerName: string;
  customerAddress: string;
  facilityId: number;
  coreSystemId: number;
  systemType: string;
  startDate: Date;
  endDate?: Date;
  isActive: boolean;
  isTerminated: boolean;
}

export function useGetAgreementsList() {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<IAgreementListItems>>('/api/agreement/list');

  return {
    isLoading,
    agreementList: response,
    getAgreementsList: post,
  };
}
