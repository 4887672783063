import { ICustomerDto } from '../../../AuthApp/Modules/Customers/Customers.api';
import { useApiGet, useApiPost } from '../../../Core/Api/Api';

export function useGetMyPagesCustomer(customerId: string, includeArchived: boolean, token: string) {
  const { isLoading, response, get } = useApiGet<ICustomerDto>(
    `/api/anonymous/mypages/customer/${customerId}/${includeArchived}/${token}`,
    true,
  );

  return {
    isLoading,
    customer: response,
    get,
  };
}

export interface ICustomerCheckCodeResult {
  accessToken: string;
}

export function useCreateCustomerCode() {
  const { isLoading, response, post } = useApiPost<ICustomerCodeCommand>(`/api/anonymous/mypages/createCode`);

  return {
    isLoading,
    response,
    createCode: post,
  };
}

export interface ICustomerCodeCommand {
  email: string;
}

export function usePostCustomerDraft() {
  const { isLoading, post } = useApiPost('/api/anonymous/mypages/customer/draft/');

  const postCustomerDraft = (token: string, updateCustomerRequest: IUpdateCustomerDraftRequest) => {
    return post(updateCustomerRequest, token);
  };

  return {
    isLoading,
    post: postCustomerDraft,
  };
}

export interface IUpdateCustomerDraftRequest {
  customerId: number | undefined;
  companyId: number | undefined;
  name: string | undefined;
  customerNumber: number | undefined;
  customerEmail: string | undefined;
  customerAddress: string | undefined;
  customerPostalCode: string | undefined;
  customerCity: string | undefined;
  customerPhoneNumber: string | undefined;
  invoiceReference: string | undefined;
  invoiceEmail: string | undefined;
  invoiceAddress: string | undefined;
  invoicePostalCode: string | undefined;
  invoiceCity: string | undefined;
}

export function useGetCustomerDraft(token: string, companyId: string, targetId: string) {
  const { isLoading, response, get } = useApiGet<ICustomerDraftDto>(
    `/api/anonymous/mypages/customer/draft/${token}/${companyId}/${targetId}`,
    true,
  );

  return {
    isLoading,
    response,
    get,
  };
}

export interface ICustomerDraftDto {
  customer: draftCustomerDto;
  proposer: string;
  status: number;
  timeStamp: Date;
}

export interface draftCustomerDto {
  id: number;
  name: string;
  customerNumber: number;
  identificationNumber: string;
  agreementInvoiceNumber: string;
  customerPhoneNumber: string;
  customerEmail: string;
  customerAddress: string;
  customerPostalCode: string;
  customerCity: string;
  invoiceReference: string;
  invoiceEmail: string;
  invoiceAddress: string;
  invoicePostalCode: string;
  invoiceCity: string;
}

export function useGetCustomers(token: string | undefined, companyId: number) {
  const { isLoading, response, get } = useApiGet<ICustomersDto[]>(
    `/api/anonymous/mypages/customers/${token}/${companyId}`,
    true,
  );

  return {
    isLoading,
    customers: response,
    getCustomers: get,
  };
}

export interface ICustomersDto {
  id: number;
  name: string;
}

export function useGetCompanies(token: string | undefined) {
  const { isLoading, response, get } = useApiGet<ICompanyDto[]>(`/api/anonymous/mypages/companies/${token}`, true);

  return {
    isLoading,
    companies: response,
    getCompanies: get,
  };
}

export interface ICompanyDto {
  id: number;
  name: string;
}
