import { useApiGet, useApiPost } from '../../../Core/Api/Api';
import ValidationErrorResultType from '../../../Shared/Api/response/ValidationErrorResultType';

export interface Company {
  emailCustomerService?: string;
  phoneNumber?: string;
  companyLogoBase64?: string;
}

export function useGetSurvey() {
  const { isLoading, response, get } = useApiGet<ISurvey>('/api/anonymous/surveys/', false);

  return {
    isLoading,
    survey: response,
    getSurvey: get,
  };
}

export interface ISurveyAnswer {
  questionId: number;
  answer: string;
}

export interface ISurvey {
  company: Company;
  questions: ISurveyQuestion[];
}

export interface ISurveyQuestion {
  id: number;
  sortOrder: number;
  question: string;
  type: SurveyQuestionType;
}

export interface IChoiceQuestion extends ISurveyQuestion {
  choices: IChoiceQuestionAnswer[];
}

export interface IChoiceQuestionAnswer {
  id: number;
  choiceOptionId: number;
  choiceOption: string;
  sortOrder: number;
}

export enum SurveyQuestionType {
  Satisfaction = 1,
  Text = 2,
  Choice = 3,
}

export interface IRespondToSurveyCommandValidationErrors extends ValidationErrorResultType<IRespondToSurveyCommand> {}

export function useRespondToSurvey(token: string | undefined) {
  const { isLoading, post } = useApiPost<IRespondToSurveyCommandValidationErrors, IRespondToSurveyCommand>(
    `/api/anonymous/surveys/${token}/respond`,
  );

  return {
    isRespondLoading: isLoading,
    respond: post,
  };
}

export interface IRespondToSurveyCommand {
  answers: IQuestionAnswerDto[];
}

export interface IQuestionAnswerDto {
  questionId: number;
  answer: string;
}
