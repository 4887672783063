import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../../../../../../../../Shared/Button/Button';
import Container from '../../../../../../../../../Shared/Container/Container';
import { InputField } from '../../../../../../../../../Shared/InputFields/InputField/InputField';
import ProductSelect from '../../../../../../../Admin/Components/Suppliers/ViewSupplier/Products/ProductSelect/ProductSelect';
import {
  useGetAddProductToCoreSystemForm,
  IAddProductToCoreSystemCommandValidationErrors,
  useAddProductToCoreSystem,
} from '../../../CoreSystem.api';
import useFormValidation from '../../../../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import useInputState from '../../../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { isResultError } from '../../../../../../../../../Shared/Api/response/ICreateResult';

const ProductForm = () => {
  const history = useHistory();
  const { customerId, facilityId, id } = useParams();

  const { isLoading, response } = useGetAddProductToCoreSystemForm(customerId, facilityId, id);
  const { isLoading: isAddProductLoading, post } = useAddProductToCoreSystem(customerId, facilityId, id);

  const { isFormValid, formRef } = useFormValidation();
  const [selectedProductId, setSelectedProductId] = useState<string | undefined>(undefined);
  const [productAddress, changeProductAddress] = useInputState('');
  const [productAmount, setProductAmount] = useInputState(1);
  const [facilityPlacement, changeFacilityPlacement] = useInputState('');

  const [errors, setErrors] = useState<IAddProductToCoreSystemCommandValidationErrors>();

  async function handleAddProduct() {
    const result = await post({
      productId: Number(selectedProductId),
      productAddress: productAddress,
      facilityPlacement: facilityPlacement,
      amount: productAmount,
    });

    isResultError(result) ? setErrors(result) : goBack();
  }

  const goBack = () =>
    id
      ? history.push(`/customers/${customerId}/facility/${facilityId}/system/${id}#systemet`)
      : history.push(`/customers/${customerId}`);

  return (
    <Container
      form
      ref={formRef}
      loading={isLoading}
      actions={
        <>
          <Button color="default" variant="outlined" onClick={goBack}>
            Avbryt
          </Button>
          <Button
            color="primary"
            variant="contained"
            loading={isAddProductLoading}
            disabled={!isFormValid || selectedProductId === undefined}
            onClick={handleAddProduct}
          >
            Lägg till
          </Button>
        </>
      }
      label="Lägg till produkt"
    >
      <ProductSelect
        mainProductId={response?.mainProductId}
        selectedProductId={selectedProductId}
        onChange={(productId) => setSelectedProductId(productId as string)}
      />
      <InputField
        label="Adress (SEKT.)"
        type="text"
        value={productAddress}
        onChange={changeProductAddress}
        errorText={errors?.productAddress}
      />
      <InputField
        label="Placering"
        type="text"
        value={facilityPlacement}
        onChange={changeFacilityPlacement}
        errorText={errors?.facilityPlacement}
      />
      <InputField
        label="Antal"
        type="number"
        value={productAmount}
        onChange={setProductAmount}
        errorText={errors?.amount}
      />
    </Container>
  );
};

export default ProductForm;
