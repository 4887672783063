import React, { useCallback, useState } from 'react';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import AccessGuard from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { Table } from '../../../../../../Shared/Table/Table';
import { SaleTypeLabel } from '../../../../../Shared/SaleType';
import { useGetCustomerSaleList } from '../../../Customers.api';
import { useStyles } from '../../../../../../Shared/Table/TableRowHeader/TableRowHeader.styles';
import { useEffect } from 'react';
import { SaleStatusLabel } from '../../../../Errands/Components/Sale.api';
import { MobileTable } from '../../../../../../Shared/Table/MobileTable/MobileTable';
import { MobileTableRow } from '../../../../../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import { AccessRights, ModuleIdentifiers } from '../../../../../../Core/Authentication/ModuleAccess';
import TablePaginationWithDatasource from '../../../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import useTable from '../../../../../../Shared/Table/useTable';
import SortButton from '../../../../../../Shared/Table/SortButton/SortButton';
import formatDate from '../../../../../../Shared/Formatting/formatDate';
import { SortDirection } from '../../../../../../Shared/Api/request/IPaginatedRequest';

interface ISaleListProps {
  customerId: string;
  facilityId?: string;
  mobileView?: boolean;
}

export const SaleList = (props: ISaleListProps) => {
  const history = useHistory();
  const classes = useStyles();
  const { customerId, facilityId, mobileView } = props;
  const { toggleSort, sortProperty, sortDirection, pageSize, setPageSize, setPageNumber, paginatedRequest } = useTable(
    15, 'salesNumber', SortDirection.Descending
  );
  const { getCustomerSaleList, isLoading, sales } = useGetCustomerSaleList(customerId, facilityId);
  const [emptyMessage, setEmptyMessage] = useState('');

  const getList = useCallback(async () => {
    const response = await getCustomerSaleList(paginatedRequest);
    if (response?.totalCount <= 0) {
      setEmptyMessage('Inga order tillagda');
    }
  }, [paginatedRequest, getCustomerSaleList]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleTableClick = (saleId: number) => {
    history.push(`/errands/orders/${saleId}`);
  };

  return (
    <Table
      customSize={{ fullsize: true }}
      loading={{
        loading: !!isLoading,
        skeletonRows: pageSize,
      }}
      empty={{
        empty: sales && sales.totalCount <= 0 ? true : false,
        message: emptyMessage,
      }}
      mobile={mobileView}
      pagination={
        sales && (
          <TablePaginationWithDatasource
            datasource={sales}
            onChangePage={setPageNumber}
            onChangeRowsPerPage={setPageSize}
            addPageSize={[15, 20]}
            fixed
          />
        )
      }
    >
      {mobileView ? (
        <>
          {sales?.items.map((sale) => (
            <MobileTable
              key={sale.id}
              label={sale.salesNumber + ' ' + SaleStatusLabel[sale.status]}
              customLink={
                <AccessGuard
                  module={ModuleIdentifiers.Customer}
                  accessRights={AccessRights.Write}
                  fallback={sale.salesNumber + ' ' + SaleStatusLabel[sale.status]}
                >
                  <Link to={`/errands/orders/${sale.id}`}>{sale.salesNumber + ' ' + SaleStatusLabel[sale.status]}</Link>
                </AccessGuard>
              }
            >
              <MobileTableRow
                label="Namn"
                value={
                  <Link to={`/errands/orders/${sale.id}`}>{sale.salesNumber + ' ' + SaleStatusLabel[sale.status]}</Link>
                }
              />
              <MobileTableRow label="Order nr" value={sale.salesNumber} />
              <MobileTableRow label="Typ" value={SaleTypeLabel[sale.type]} />
              <MobileTableRow label="Status" value={SaleStatusLabel[sale.status]} />
              {facilityId && (
                <>
                  <MobileTableRow label="Bokad" value={sale.installDate ? formatDate(sale.installDate) : ''} />
                  <MobileTableRow label="Skapad" value={formatDate(sale.created)} />
                </>
              )}
            </MobileTable>
          ))}
        </>
      ) : (
        <>
          <TableHead>
            <TableRow>
              <TableCell>
                Order nr
                <SortButton
                  property="salesNumber"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Typ
                <SortButton
                  property="type"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>              
              <TableCell>
                Rubrik
                <SortButton
                  property="description"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Beskrivning
              </TableCell>
              <TableCell>
                Status
                <SortButton
                  property="status"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>

              {facilityId && (
                <>
                  <TableCell>
                    Bokad
                    <SortButton
                      property="installDate"
                      sortProperty={sortProperty}
                      sortDirection={sortDirection}
                      onSort={toggleSort}
                    />
                  </TableCell>
                  <TableCell>
                    Skapad
                    <SortButton
                      property="created"
                      sortProperty={sortProperty}
                      sortDirection={sortDirection}
                      onSort={toggleSort}
                    />
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {sales?.items.map((sale) => (
              <TableRow
                onClick={() => handleTableClick(sale.id)}
                className={classes.pointerCursor}
                hover
                tabIndex={-1}
                key={sale.id}
              >
                <TableCell className={classes.linkFont}>{sale.salesNumber}</TableCell>
                <TableCell>{SaleTypeLabel[sale.type]}</TableCell>
                <TableCell>{sale.description}</TableCell>
                <TableCell>{(sale.jobDescription?.substring(0, 15) + (sale.jobDescription && sale.jobDescription?.length > 14 ? '..' : ''))}</TableCell>
                <TableCell>{SaleStatusLabel[sale.status]}</TableCell>
                {facilityId && (
                  <>
                    <TableCell>{sale.installDate ? formatDate(sale.installDate) : ''}</TableCell>
                    <TableCell>{formatDate(sale.created)}</TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </>
      )}
    </Table>
  );
};
