import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  contacts: {
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  font: {
    fontWeight: 700,
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  pointerCursor: {
    cursor: 'pointer',
  },
  roundedHighlightColumn: {
    cursor: 'pointer',
    background: '#f7f7f7',
    borderRadius: '0 0 10px 10px',
    textAlign: 'center',
    padding: '12px 0',
  },
  mobileTableEndRow: {
    cursor: 'pointer',
    background: '#f7f7f7',
    borderRadius: '0 0 10px 10px',
    textAlign: 'center',
    padding: '18px 0',
  },
  addContacts: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  }
}));

export default useStyles;
