import React, { useState } from 'react';
import { ValueType } from 'react-select';
import Datepicker from '../../../../../../../Shared/InputFields/DatePicker/Datepicker';
import Container from '../../../../../../../Shared/Container/Container';
import Select, { ISelectOption } from '../../../../../../../Shared/Select/Select';
import { InputField } from '../../../../../../../Shared/InputFields/InputField/InputField';
import useNullableNumericInputState from '../../../../../../../Shared/Hooks/UseNullableNumericInputState/UseNullableNumericInputState';
import Button from '../../../../../../../Shared/Button/Button';
import useFormValidation from '../../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import CompanyUserSelect from '../../../../../Admin/Components/Users/UserSelect/CompanyUserSelect';
import { TimeReportType, timeReportTypes } from '../../../Sale.api';
import {
  useCreateTimeReport,
  ICreateTimeReportCommandValidationErrors,
  ICreateTimeReportCommand,
} from '../../Order.api';
import useUserContext from '../../../../../../../Core/Authentication/UserContext';
import { AccessRights, ModuleIdentifiers } from '../../../../../../../Core/Authentication/ModuleAccess';
import AccessGuard from '../../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { Grid, useMediaQuery } from '@material-ui/core';
import theme from '../../../../../../../Styles/Theme';

const timeReportTypeOptions: ISelectOption[] = timeReportTypes.map((x) => ({
  label: x.name,
  value: (x.type as number).toString(),
}));

function getSelectedOption(options: ISelectOption[], value: any): ISelectOption | undefined {
  return options.find((x) => Number(x.value) === (value as number | undefined));
}

function getSelectedValue(value: ValueType<ISelectOption>): number | undefined {
  return value ? Number((value as ISelectOption).value) : undefined;
}
export interface ITimeReportProps {
  id: string;
  onSave: () => void;
}

const TimeReportForm = (props: ITimeReportProps) => {
  const { id, onSave } = props;
  const { user } = useUserContext();
  const { isFormValid, formRef } = useFormValidation();
  const { isCreateLoading, create } = useCreateTimeReport(id);
  const [date, setDate] = useState<Date | null>(new Date());
  const [hours, changeHours, setHours] = useNullableNumericInputState();
  const [parking, changeParking, setParking] = useNullableNumericInputState();
  const [trips, changeTrips, setTrips] = useNullableNumericInputState();
  const [timeReportType, setTimeReportType] = useState<TimeReportType | undefined>();
  const [companyUserId, setCompanyUserId] = useState<string | undefined>(user.selectedUserId?.toString() ?? undefined);
  const [errors] = useState<ICreateTimeReportCommandValidationErrors>();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));


  async function createTimeReport() {
    if (date) {
      const command: ICreateTimeReportCommand = {
        date: date.toLocaleDateString(),
        hours: hours ?? 0,
        parking: parking ?? 0,
        trips: trips ?? 0,
        type: timeReportType as TimeReportType,
        companyUserId: Number(companyUserId),
      };
  
      const result = await create(command);
      if (result) {
        onSave();
        setHours(0);
        setParking(0);
        setTrips(0);
      }
    }    
  }

  const valid = isFormValid && timeReportType && companyUserId;

  return (
    <>
      <Container
        customSize={{ fullsize: true }}
        form
        ref={formRef}
        actions={
          <>
            <Button
              color="primary"
              variant="contained"
              loading={isCreateLoading}
              disabled={!valid}
              onClick={createTimeReport}
            >
              Lägg till
            </Button>
          </>
        }
      >
        <Datepicker label="Datum" selected={date} onChange={setDate} required customWidth={mobileView ? 12 : 3} />
        <Select
          inputFieldProps={{ label: 'Typ', required: true, errorText: errors?.date, customWidth: mobileView ? 12 : 3 }}          
          selectProps={{
            options: timeReportTypeOptions,
            value: getSelectedOption(timeReportTypeOptions, timeReportType),
            onChange: (option) => {
              setTimeReportType(getSelectedValue(option));
            },
          }}
        />
        <AccessGuard module={ModuleIdentifiers.Orders} accessRights={AccessRights.Admin}>
          <Grid item lg={2} md={12} sm={12} xs={12}>
            <CompanyUserSelect
              label="Användare"
              selectedCompanyUserId={companyUserId}
              inline={false}
              onChange={(id) => setCompanyUserId(id)}
              fullwidth
            />
          </Grid>
        </AccessGuard>
        <InputField
          label="Timmar"
          type="number"
          hideArrows
          required
          value={hours}
          onChange={changeHours}
          errorText={errors?.hours}
          customWidth={mobileView ? 12 : 1}
        />
        <InputField label="Resor" type="number" value={trips} onChange={changeTrips} errorText={errors?.trips} customWidth={mobileView ? 12 : 1} />
        <InputField
          label="Parkering"
          type="number"
          hideArrows
          value={parking}
          onChange={changeParking}
          endAdornment="SEK"
          errorText={errors?.parking}
          customWidth={mobileView ? 12 : 2}
        />
      </Container>
    </>
  );
};
export default TimeReportForm;
