import React from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import AccessGuard from '../../Core/Authentication/AccessGuard/AccessGuard';
import { AccessRights, ModuleIdentifiers } from '../../Core/Authentication/ModuleAccess';
import useStyles from './Link.styles';

interface ILinkProps extends LinkProps {
  module: ModuleIdentifiers;
  accessRights: AccessRights;
}

const Link = (props: ILinkProps) => {
  const classes = useStyles();

  return (
    <AccessGuard module={props.module} accessRights={props.accessRights} fallback={props.children}>
      <RouterLink className={classes.root} {...props} />
    </AccessGuard>
  );
};

export default Link;
