import { Dialog, DialogContent, Grid } from '@material-ui/core';
import React from 'react';
import DialogForm from '../../../../../../../Shared/DialogForm/DialogForm';
import { DialogContentHeader } from '../../../../../../../Shared/DialogContentHeader/DialogContentHeader';
import Button from '../../../../../../../Shared/Button/Button';
import { useStyles } from '../../../Facilities/RemoveFacilityModal/RemoveOrArchiveFacilityModal.style';

export interface IRemoveDocumentModalProps {
  documentId: number;
  open: boolean;
  setOpen: (open: boolean) => void;
  handleDelete: () => void;
  isLoading?: boolean;
}

const RemoveDocumentModal = (props: IRemoveDocumentModalProps) => {
  const classes = useStyles();
  const { documentId, open, isLoading, setOpen, handleDelete } = props;

  const Action = () => (
    <>
      <Button color="default" variant="outlined" onClick={() => setOpen(false)}>
        Nej
      </Button>
      <Button loading={isLoading} className={classes.cautionButton} variant="outlined" onClick={handleDelete}>
        Ja
      </Button>
    </>
  );

  return (
    <Dialog open={open} onClose={setOpen}>
      <DialogContent>
        <DialogContentHeader
          onClose={() => setOpen(false)}
          title={`Ta bort dokument `}
          titleId={documentId?.toString() ?? ''}
        />
        <DialogForm actions={<Action />}>
          <Grid container item>
            Är du säker på att du vill ta bort det här dokumentet?
          </Grid>
        </DialogForm>
      </DialogContent>
    </Dialog>
  );
};

export default RemoveDocumentModal;
