import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  selectMenu: {
    border: `2px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    justifyContent: 'space-between',
    minHeight: '3rem',
  },
  menuList: {
    padding: 0,
  },
  selectContainer: {
    position: 'relative',
    flexGrow: 1,
    zIndex: 2,
    '& > div': {
      right: 0,
      // To override Material UI
      transform: 'translate3d(0, 3rem, 0) !important',
    },
  },
}));
