import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  remove: {
    color: theme.palette.error.main,
  },
  IconBtn: {
    borderRadius: '12rem',
    backgroundColor: theme.palette.grey[100],
    marginBottom: '0.25rem',
  },
  actionTc: {
    width: '1rem',
  },
}));

export default useStyles;
