import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    marginBottom: '1rem',
    overflowX: 'auto',
    width: '100%',
  },
  button: {
    borderRight: 0,
    borderColor: theme.palette.grey[500],
    fontWeight: 600,
    borderRadius: 0,
    flexGrow: 1,
    whiteSpace: 'nowrap',
    '&:first-child': {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },
    '&:last-child': {
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      borderRight: `2px solid ${theme.palette.grey[500]}`,
      '&:disabled': {
        borderRight: `2px solid ${theme.palette.grey[500]}`,
      },
    },
    '&:disabled': {
      borderColor: theme.palette.grey[500],
      borderRight: 0,
    },
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      '& + *': {
        borderLeftColor: theme.palette.primary.main,
      },
    },
  },
  active: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    pointerEvents: 'none',
    '& + *': {
      borderLeftColor: theme.palette.primary.main,
      '&:disabled': {
        borderLeftColor: theme.palette.primary.main,
      },
    },
    '&:last-child': {
      borderRightColor: theme.palette.primary.main,
    },
  },
}));
