import PopperMenu from '../../../../../../Shared/Popper/Popper';
import FacilityChangeCustomer from '../FacilityChangeCustomer/FacilityChangeCustomer';
import { useState } from 'react';
import React from 'react';
import { useStyles } from '../FacilityChangeCustomer/FacilityChangeCustomer.styles';

interface IFacilityChangeCustomerButtonProps {
  onUpdateCustomer: () => void;
  facilityId: string;
  currentCustomerId: string;
}

export const FacilityChangeCustomerButton = (props: IFacilityChangeCustomerButtonProps) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  function onUpdate() {
    setOpen(false);
    props.onUpdateCustomer();
  }

  return (
    <PopperMenu
      setOpen={setOpen}
      open={open}
      className={classes.cautionButton}
      variant="outlined"
      customContent
      alwaysDialog
      title={'Flytta plats'}
      content={
        <FacilityChangeCustomer
          currentCustomerId={props.currentCustomerId}
          facilityId={props.facilityId}
          onUpdateCustomer={onUpdate}
          onCancel={() => setOpen(false)}
        />
      }
    >
      Flytta plats
    </PopperMenu>
  );
};
