import { makeStyles } from '@material-ui/core';
import theme from '../../Styles/Theme';

const useStyles = makeStyles(() => ({
  root: {
    fontWeight: 700,
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
