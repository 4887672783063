import React, { useEffect, useState } from 'react';
import { ISurveyQuestionAnswer, SurveyQuestionType, useGetSurveyResponses } from '../../Surveys/Surveys.api';
import Container from '../../../../../../Shared/Container/Container';
import moment from 'moment';
import Datepicker from '../../../../../../Shared/InputFields/DatePicker/Datepicker';
import ResponseContainer from './ResponseContainer/ResponseContainer';
import formatDate from '../../../../../../Shared/Formatting/formatDate';
import DataList from '../../../../../../Shared/DataList/DataList';
import Dt from '../../../../../../Shared/DataList/Dt/Dt';
import Dd from '../../../../../../Shared/DataList/Dd/Dd';
import SurveySelect from './SurveySelect/SurveySelect';
import { useParams } from 'react-router-dom';

const defaultFromDate = moment().startOf('month').toDate();
const defaultToDate = moment().startOf('day').toDate();

const SurveyResponseList = () => {
  const { id } = useParams<{ id: string }>();
  const [selectedSurveyId, setSelectedSurveyId] = useState<number>(parseInt(id));
  const { isLoading, responses, getSurveyResponses } = useGetSurveyResponses();
  const [fromDate, setFromDate] = useState<Date>(defaultFromDate);
  const [toDate, setToDate] = useState<Date>(defaultToDate);

  // const surveyTypeOptions: ISelectOption[] = surveys.map((x) => ({
  //   value: (x.type as number).toString(),
  //   label: x.name,
  // }));

  useEffect(() => {
    if (selectedSurveyId !== undefined)
      getSurveyResponses(selectedSurveyId.toString(), {
        fromDate: fromDate,
        toDate: toDate,
      });
  }, [selectedSurveyId, fromDate, toDate, getSurveyResponses]);

  function getAnswerText(answer: ISurveyQuestionAnswer) {
    if (answer.questionType === SurveyQuestionType.Satisfaction) {
      switch (answer.answer) {
        case '1':
          return 'Inte nöjd';
        case '3':
          return 'Nöjd';
        case '5':
          return 'Mycket nöjd';
      }
    }
    return answer.answer;
  }

  return (
    <Container
      label="Utskickssvar"
      loading={isLoading}
      customSize={{ fullsize: true }}
      customStatisticFilter
      filters={
        <>
          <Datepicker
            required
            label="Visa från"
            inFilter
            selected={fromDate}
            onChange={(d: Date | null) => setFromDate(d as Date)}
          />

          <Datepicker
            required
            label="Visa till"
            inFilter
            selected={toDate}
            onChange={(d: Date | null) => setToDate(d as Date)}
          />

          <SurveySelect
            selectedSurveyId={selectedSurveyId}
            onChange={(id) => id && setSelectedSurveyId(parseInt(id))}
          />
        </>
      }
    >
      {selectedSurveyId &&
        responses?.map((response) => (
          <React.Fragment key={`${response.id}`}>
            <ResponseContainer
              label={`${response.responderNumber} - ${response.responderName}`}
              date={formatDate(response.created)}
            >
              <DataList removeBorder>
                {response.answers.map((answer) => (
                  <React.Fragment key={answer.question}>
                    <Dt>{answer.question}</Dt>
                    <Dd>{getAnswerText(answer)}</Dd>
                  </React.Fragment>
                ))}
              </DataList>
            </ResponseContainer>
          </React.Fragment>
        ))}
    </Container>
  );
};
export default SurveyResponseList;
