import React, { useEffect } from 'react';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import useInputState from '../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { InputField } from '../../../../../../../Shared/InputFields/InputField/InputField';
import { IPreselectionProps } from '../../Preselection';
import {
  IPreselectedOfferOptionalData,
  IPreselectionFileInformationDto,
  useGetPreselectionFileContent,
  useAddPreselectedOfferOptional,
  useGetPreselectedOfferOptional,
} from '../../Preselection.api';
import { Table } from '../../../../../../../Shared/Table/Table';
import Button from '../../../../../../../Shared/Button/Button';
import formatDate from '../../../../../../../Shared/Formatting/formatDate';
import { isResultError } from '../../../../../../../Shared/Api/response/ICreateResult';
import useStyles from '../../Preselection.styles';

const OfferOptional = (props: IPreselectionProps) => {
  const classes = useStyles();
  const { setIsLoading, saveCallback, setChildrenComponent } = props;
  const { isLoading: isGetLoading, getPreselectedOfferOptional } = useGetPreselectedOfferOptional();
  const { isLoading: isGettingFileLoading, getPreselectionFileContent } = useGetPreselectionFileContent();
  const { isLoading: isAddLoading, addPreselectedOfferOptional } = useAddPreselectedOfferOptional();

  const [optionalServices, changeOptionalServices, setOptionalServices] = useInputState('');
  const [optionalInstallations, changeOptionalInstallations, setOptionalInstallations] = useInputState('');
  const offerOptionalFiles: IPreselectionFileInformationDto[] = [];

  useEffect(() => {
    setIsLoading(isGetLoading || isAddLoading || isGettingFileLoading);
  }, [isGettingFileLoading, isGetLoading, isAddLoading, setIsLoading]);

  useEffect(() => {
    const save: any = async () => {
      var result = await addPreselectedOfferOptional({
        services: optionalServices,
        installations: optionalInstallations,
      });
      if (isResultError(result)) {
        return false;
      }
      return true;
    };
    saveCallback(() => save);
  }, [optionalServices, optionalInstallations, saveCallback, addPreselectedOfferOptional]);

  useEffect(() => {
    async function getValues() {
      const result = await getPreselectedOfferOptional();
      if (result) {
        setOptionalServices(result.latestOfferOptionalData.services);
        setOptionalInstallations(result.latestOfferOptionalData.installations);
        //TODO: get optional files?
      } else {
        return false;
      }
    }
    getValues();
  }, [getPreselectedOfferOptional, setOptionalServices, setOptionalInstallations]);

  useEffect(() => {
    offerOptionalFiles && setChildrenComponent(<OfferTermFileTable />);
  }, [offerOptionalFiles, setChildrenComponent]);

  const handleGetPreselectionFile = async (id: string) => {
    let data: IPreselectedOfferOptionalData = JSON.parse((await getPreselectionFileContent(id.toString())).content);
    setOptionalServices(data.services);
    setOptionalInstallations(data.installations);
  };

  const OfferTermFileTable = () => (
    <Table customSize={{ md: 5, lg: 5 }}>
      <TableHead>
        <TableRow>
          <TableCell>Version</TableCell>
          <TableCell>Skapad</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {offerOptionalFiles.map((file) => (
          <TableRow>
            <TableCell>
              <Button
                color="primary"
                variant="text"
                disableTouchRipple
                onClick={() => handleGetPreselectionFile(file.id.toString())}
              >
                {file.name}
              </Button>
            </TableCell>
            <TableCell>{formatDate(new Date(file.created))}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <>
      <div className={`${classes.forPdf} ${classes.wysiwygFields}`}>
        <InputField
          label="Tillval tjänster"
          type="text"
          multiline
          fullwidth
          rows="5"
          value={optionalServices}
          onChange={changeOptionalServices}
          required
        />
      </div>
      <div className={`${classes.forPdf} ${classes.wysiwygFields}`}>
        <InputField
          label="Tillval installation"
          type="text"
          multiline
          fullwidth
          rows="5"
          value={optionalInstallations}
          onChange={changeOptionalInstallations}
          required
        />
      </div>
    </>
  );
};

export default OfferOptional;
