import React from 'react';
import { UserType } from '../../../AuthApp/Modules/Admin/Components/Users/Users.api';
import { AccessRights } from '../ModuleAccess';
import useUserContext, { IUser, hasUserAccessToModule, hasUserAccessToAnyModule } from '../UserContext';

export function hasUserAccess(
  user: IUser,
  module?: string | string[],
  accessRights?: AccessRights,
  userType?: UserType,
  superAdminAccess?: boolean,
  anyModule?: boolean,
) {
  if (superAdminAccess && user && user.userType === UserType.SuperAdmin) return true;
  let access = false;
  if (userType && userType === UserType.SuperAdmin)
    access = user.userType === UserType.SuperAdmin && !user.impersonatedCompany;
  if (module) access = hasUserAccessToModule(user, module, accessRights ?? AccessRights.Read);
  if (userType && userType !== UserType.SuperAdmin) access = userType === user.userType;
  if (anyModule) access = hasUserAccessToAnyModule(user);

  return access;
}

interface IProps {
  module?: string | string[];
  accessRights?: AccessRights;
  userType?: UserType;
  fallback?: React.ReactNode;
  superAdminAccess?: boolean;
}

const AccessGuard: React.FunctionComponent<IProps> = (props) => {
  const { user } = useUserContext();
  const { children, module, accessRights, userType, fallback, superAdminAccess } = props;

  if (module !== undefined && userType !== undefined && userType === UserType.SuperAdmin)
    console.warn(
      'When providing both module and portalAdmin to AccessGuard component, module will have higher priority.',
    );
  let access = hasUserAccess(user, module, accessRights, userType, superAdminAccess);
  if (access === true) return <>{children}</>;

  return fallback ? <>{fallback}</> : null;
};

export default AccessGuard;
