import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useParams, useRouteMatch } from 'react-router-dom';
import { INavigationItem } from '../../../../../Shared/Navigation/Header/Header';
import useHeaderNavigationContext from '../../../../../Shared/Navigation/Header/HeaderNavigationContext/HeaderNavigationContext';
import CustomerInfo from './CustomerInfo/CustomerInfo';
import CustomerProfile from './CustomerProfile';
import ViewFacility from './FacilityList/ViewFacility/ViewFacility';
import CustomerInfoForm from './CustomerInfoForm/CustomerInfoForm';
import ViewCoreSystem from './FacilityList/ViewFacility/ViewCoreSystem/ViewCoreSystem';

const CustomerProfileRoutes = () => {
  const { companyId, customerId } = useParams<{ companyId: string; customerId: string }>();
  const { setHeaderNavigationItems, setHeaderNavigationText } = useHeaderNavigationContext();
  const { path } = useRouteMatch();

  useEffect(() => {
    let navigationItems: INavigationItem[] = [];
    if (companyId && customerId) {
      navigationItems = [
        {
          title: 'Mina objekt',
          to: `/${companyId}/${customerId}/facilities`,
          exact: false,
        },
        {
          title: 'Kunduppgifter',
          to: `/${companyId}/${customerId}/profile`,
          exact: false,
        },
      ];
    }

    setHeaderNavigationText(navigationItems);
    setHeaderNavigationItems(navigationItems);

    return () => {
      setHeaderNavigationItems([]);
    };
  }, [setHeaderNavigationItems, setHeaderNavigationText, companyId, customerId]);

  return (
    <Switch>
      <Route path={`${path}/facilities/facility/:facilityId/system/:coreSystemId`}>
        <ViewCoreSystem />
      </Route>
      <Route path={`${path}/facilities/facility/:facilityId`}>
        <ViewFacility />
      </Route>
      <Route path={`${path}/profile/update`}>
        <CustomerInfoForm />
      </Route>
      <Route path={`${path}/profile`}>
        <CustomerInfo />
      </Route>
      <Route path={`${path}/facilities`}>
        <CustomerProfile />
      </Route>
      <Route path="/*">
        <Redirect to={`/${companyId}/${customerId}/facilities`} />
      </Route>
    </Switch>
  );
};

export default CustomerProfileRoutes;
