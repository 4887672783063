import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IDocumentFormProps } from '../../IDocumentFormProps';
import Select, { ISelectOption } from '../../../../../../../../../Shared/Select/Select';
import { Fieldset } from '../../../../../../../../../Shared/Form/Fieldset/Fieldset';
import useInputState from '../../../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { InputField } from '../../../../../../../../../Shared/InputFields/InputField/InputField';
import Datepicker from '../../../../../../../../../Shared/InputFields/DatePicker/Datepicker';
import moment from 'moment';
import FacilitySelect from '../../../../../Facilities/FacilitySelect/FacilitySelect';
import { ICoreSystem, useGetFacility } from '../../../../../Facilities/Facilities.api';
import useNullableNumericInputState from '../../../../../../../../../Shared/Hooks/UseNullableNumericInputState/UseNullableNumericInputState';
import { Grid } from '@material-ui/core';
import { ICheckboxItem, useCheckboxField } from '../FacilityCertificate/Components/useCheckboxField';
import {
  FacilityTypeSBSC,
  IPoliceDeviation,
  PoliceSideType,
  SecurityChoiceSBSC,
  useCreateFacilitySafetyCertificateDocument,
  useGetFacilityCertificateDocumentForm,
} from '../../Documents.api';
import CompanyUserSelect from '../../../../../../../Admin/Components/Users/UserSelect/CompanyUserSelect';
import { PoliceDeviations } from './Components/PoliceDeviations/PoliceDeviations';
import { Buildings } from '../FacilityCertificate/Components/Buildings/Buildings';
import { isResultError } from '../../../../../../../../../Shared/Api/response/ICreateResult';

//TODO: Ask Patric if the alarm types should be hardcoded
const facilityTypeLabels: ICheckboxItem<FacilityTypeSBSC>[] = [
  { type: FacilityTypeSBSC.BurglarAlarm, name: 'Inbrottslarm' },
  { type: FacilityTypeSBSC.NoiseAlarm, name: 'Bråklarm' },
  { type: FacilityTypeSBSC.PortableAssaultAlarm, name: 'Bärbart överfallslarm' },
  { type: FacilityTypeSBSC.AssaultAlarm, name: 'Överfallslarm' },
  { type: FacilityTypeSBSC.FireIndication, name: 'Brandindikering' },
];
const policeSideLabels: ICheckboxItem<PoliceSideType>[] = [
  {
    type: PoliceSideType.AlarmClass3,
    name: 'Plats uppfyller Larmklass 3 enlight SSF-klass angiven, samt Rikspolisstyelsen tilläggskrav.',
  },
  {
    type: PoliceSideType.IncompleteFacility,
    name: "Ofullständig anläggning, redovisas under 'Avvikelser polismyndighet'.",
  },
  { type: PoliceSideType.EquipmentFullyCertified, name: 'Anläggningens matriel helt certifierat.' },
  { type: PoliceSideType.EquipmentPartiallyCertified, name: 'Anläggningens matriel delvis certifierat.' },
  {
    type: PoliceSideType.ChecksOnlyOnRenovations,
    name:
      'Kontroll har endast utförts på ändrings- och utökningsarbeten varvid inga störande inverkningar på befintlig anläggning konstaterats.',
  },
  { type: PoliceSideType.DocumentationSubmitted, name: 'Dokumentation överlämnad.' },
  { type: PoliceSideType.FacilityPlansOnAppendix, name: 'Ritning över objektet enligt seperat bilaga.' },
];

export const securityChoiceLabels: ICheckboxItem<SecurityChoiceSBSC>[] = [
  { type: SecurityChoiceSBSC.ShellProtection, name: 'Skalskydd' },
  { type: SecurityChoiceSBSC.PointProtection, name: 'Punktskydd' },
  { type: SecurityChoiceSBSC.InternalAlarm, name: 'Inre larmdon' },
  { type: SecurityChoiceSBSC.ExternalAlarm, name: 'Yttre larmdon' },
  { type: SecurityChoiceSBSC.IntentionalProtection, name: 'Försåtsskydd' },
  { type: SecurityChoiceSBSC.OutdoorDetection, name: 'Utomhusdetekering' },
  { type: SecurityChoiceSBSC.VolumeProtection, name: 'Volymskydd' },
  { type: SecurityChoiceSBSC.Dimgenerator, name: 'Dimgenerator  installerad' },
  { type: SecurityChoiceSBSC.PartialProtection, name: 'Delskydd' },
  { type: SecurityChoiceSBSC.SSF1042, name: 'Enligt SSF 1042' },
];

const FacilitySafetyCertificate = (props: IDocumentFormProps) => {
  const { customerId, onLoadingChange, saveCallback, documentName, onDocumentCreated, validateFormState } = props;
  const { isLoading: isFacilityLoading, getFacility } = useGetFacility(customerId);
  const { isLoading: isDocumentLoading, getFacilityCertificateDocumentForm } = useGetFacilityCertificateDocumentForm(
    customerId,
    props.facilityId,
  );
  const {
    isLoading: isCreateLoading,
    createFacilitySafetyCertificateDocument,
  } = useCreateFacilitySafetyCertificateDocument(customerId);

  const [facilityId, setFacilityId] = useState<number | undefined>(
    props.facilityId ? parseInt(props.facilityId) : undefined,
  );
  const [coreSystemId, setCoreSystemId] = useState<string>();
  const [coreSystems, setCoreSystems] = useState<ICoreSystem[]>([]);
  const [receiver, changeReceiver, setReceiver] = useInputState('');
  const [certificateNr, changeCertificateNr] = useNullableNumericInputState(null);
  const [otherTransmission, changeOtherTransmission] = useInputState('');
  const [FacilityTypesComponent, facilityTypes] = useCheckboxField('Anläggningstyp', facilityTypeLabels);
  const [SecurityChoicesComponent, securityChoices] = useCheckboxField('Val', securityChoiceLabels);
  const [externalAlarm, changeExternalAlarm] = useInputState('');
  const [internalAlarm, changeInternalAlarm] = useInputState('');
  const [testingDate, setTestingDate] = useState<Date | null>(null);
  const [facilityProjector, setFacilityProjector] = useState<string>();
  const [responsibleTechnician, setResponsibleTechnician] = useState<string>();
  const [note, changeNote] = useInputState('');
  const [testInterval, changeTestInterval] = useInputState('');
  const [ssf130, changeSSF130] = useInputState('');
  const [nameClarification, changeNameClarification] = useInputState('');
  const [maintenanceContractNr, changeMaintenanceContractNr] = useNullableNumericInputState(null);
  const [PoliceSideComponent, policeSideOptions] = useCheckboxField('Polissidan', policeSideLabels);
  const [electricitySubcontractor, changeElectricitySubcontractor] = useInputState('');
  const [telecommunicationsSubcontractor, changeTelecommunicationsSubcontractor] = useInputState('');
  const [trainingCompleted, changeTrainingCompleted] = useNullableNumericInputState(null);
  const [policeDeviations, setPoliceDeviations] = useState<IPoliceDeviation[]>([]);
  const [buildings, setBuildings] = useState<string[]>([]);
  const [certificateDate, setCertificateDate] = useState<Date>(moment().startOf('day').toDate());

  useEffect(() => {
    onLoadingChange(isFacilityLoading || isCreateLoading || isDocumentLoading);
  }, [isFacilityLoading, isCreateLoading, isDocumentLoading, onLoadingChange]);

  useEffect(() => {
    validateFormState && validateFormState(!!facilityId && !!coreSystemId);
  }, [validateFormState, facilityId, coreSystemId]);

  useEffect(() => {
    async function loadForm() {
      const form = await getFacilityCertificateDocumentForm();
      if (form) {
        setReceiver(form.receiver);
        setFacilityId(form.facilityId);
        setCoreSystemId(form.coreSystemId.toString());
      }
    }
    loadForm();
  }, [getFacilityCertificateDocumentForm, setReceiver]);

  useEffect(() => {
    async function loadFacility() {
      var response = await getFacility(facilityId?.toString());
      setCoreSystems(response.coreSystems);

      if (response.coreSystems.length === 1) {
        setCoreSystemId(response.coreSystems[0].id.toString());
      } else {
        setCoreSystemId(undefined);
      }
    }
    if (facilityId !== undefined) {
      loadFacility();
    }
  }, [facilityId, getFacility]);

  const alarmClass = useMemo(() => {
    return coreSystems.find((x) => x.id === Number(coreSystemId))?.mainProductRegulation ?? '';
  }, [coreSystems, coreSystemId]);

  const save = useCallback(() => {
    async function innerSave() {
      const result = await createFacilitySafetyCertificateDocument({
        documentName: documentName,
        receiver: receiver ? receiver : null,
        certificateDate: certificateDate,
        facilityId: facilityId!,
        coreSystemId: Number(coreSystemId!),
        buildings: buildings,
        note: note ? note : null,
        securityChoices: securityChoices,
        facilityTypes: facilityTypes,
        otherTransmission: otherTransmission ? otherTransmission : null,
        externalAlarm: externalAlarm ? externalAlarm : null,
        internalAlarm: internalAlarm ? internalAlarm : null,
        testingDate: testingDate,
        facilityProjector: facilityProjector ? facilityProjector : null,
        responsibleTechnician: responsibleTechnician ? responsibleTechnician : null,
        testInterval: testInterval ? testInterval : null,
        ssf130: ssf130 ? ssf130 : null,
        alarmClass: alarmClass ? alarmClass : null,
        nameClarification: nameClarification ? nameClarification : null,
        maintenanceContractNumber: maintenanceContractNr,
        certificateNumber: certificateNr,
        policeSideOptions: policeSideOptions,
        electricitySubcontractor: electricitySubcontractor ? electricitySubcontractor : null,
        telecommunicationsSubcontractor: telecommunicationsSubcontractor ? telecommunicationsSubcontractor : null,
        trainingCompleted: trainingCompleted,
        policeDeviations: policeDeviations,
      });
      if (!isResultError(result)) onDocumentCreated(Number(result as string));
    }
    innerSave();
  }, [
    documentName,
    receiver,
    certificateDate,
    facilityId,
    coreSystemId,
    buildings,
    note,
    securityChoices,
    facilityTypes,
    otherTransmission,
    externalAlarm,
    internalAlarm,
    testingDate,
    facilityProjector,
    responsibleTechnician,
    testInterval,
    ssf130,
    alarmClass,
    nameClarification,
    maintenanceContractNr,
    certificateNr,
    policeSideOptions,
    electricitySubcontractor,
    telecommunicationsSubcontractor,
    trainingCompleted,
    policeDeviations,
    onDocumentCreated,
    createFacilitySafetyCertificateDocument,
  ]);

  useEffect(() => {
    saveCallback(save);
  }, [saveCallback, save]);

  return (
    <>
      <InputField label="Mottagare" type="text" value={receiver} onChange={changeReceiver} />{' '}
      <Datepicker
        label="Datum"
        required
        selected={certificateDate}
        onChange={(d: Date | null) => setCertificateDate(d as Date)}
      />
      <FacilitySelect
        customerId={customerId}
        isDisabled={!!props.facilityId}
        label="Välj plats"
        required
        selectedFacilityId={facilityId?.toString()}
        onChange={(selected) => {
          setFacilityId(selected ? Number(selected) : undefined);
          setCoreSystemId(undefined);
        }}
      />
      <Select
        inputFieldProps={{ label: 'Välj system', required: true }}
        selectProps={{
          placeholder: 'Välj system',
          value: coreSystemId
            ? ({
                label: coreSystems.find((x) => x.id === Number(coreSystemId))?.mainProduct,
                value: coreSystemId,
              } as ISelectOption)
            : null,
          isDisabled: !!(coreSystems.length === 0),
          onChange: (selected) => {
            setCoreSystemId(selected ? (selected as ISelectOption).value : undefined);
            // setAlarmClass(
            //   coreSystems.find((x) => x.id === Number((selected as ISelectOption).value))?.mainProductRegulation ??
            //     '',
            // );
          },
          options: coreSystems.map((x) => ({ label: x.mainProduct, value: x.id.toString() })),
          isLoading: isFacilityLoading,
        }}
      ></Select>
      <InputField label="Certifikatnummer" type="number" value={certificateNr} onChange={changeCertificateNr} />
      <Buildings rows={buildings} setRows={setBuildings} />
      <InputField
        label="Notering/avvikelse"
        fullwidth
        multiline
        rows={5}
        type="text"
        value={note}
        onChange={changeNote}
      />
      <Grid item md={12}>
        {SecurityChoicesComponent}
      </Grid>
      <Grid item md={12}>
        {FacilityTypesComponent}
      </Grid>
      <InputField
        label="Annan överföringstyp"
        fullwidth
        type="text"
        value={otherTransmission}
        onChange={changeOtherTransmission}
      />
      <Fieldset legend="Larmdon">
        <InputField label="Yttre larmdon" type="text" value={externalAlarm} onChange={changeExternalAlarm} />
        <InputField label="Inre larmdon" type="text" value={internalAlarm} onChange={changeInternalAlarm} />
      </Fieldset>
      <Datepicker label="Datum larmet blev testat" selected={testingDate} onChange={setTestingDate} />
      <CompanyUserSelect
        autoFocus={false}
        isClearable
        placeholder="Välj projektör"
        label="Projektör av platsen"
        onChange={(_1, userName) => setFacilityProjector(userName ?? '')}
      />
      <CompanyUserSelect
        autoFocus={false}
        isClearable
        placeholder="Välj tekniker"
        label="Ansvarig tekniker"
        onChange={(_1, userName) => setResponsibleTechnician(userName ?? '')}
      />
      <InputField label="Intervall för test" type="text" value={testInterval} onChange={changeTestInterval} />
      <InputField label="SSF 130" type="text" value={ssf130} onChange={changeSSF130} />
      <InputField viewOnly label="Larmklass" type="text" value={alarmClass} />
      <InputField label="Namnförtydligande" type="text" value={nameClarification} onChange={changeNameClarification} />
      <InputField
        label="Avtalsnummer"
        type="number"
        value={maintenanceContractNr}
        onChange={changeMaintenanceContractNr}
      />
      <InputField label="Certifikatnummer" type="number" value={certificateNr} onChange={changeCertificateNr} />
      {PoliceSideComponent}
      <Fieldset legend="Underleverantör">
        <InputField label="El" type="text" value={electricitySubcontractor} onChange={changeElectricitySubcontractor} />
        <InputField
          label="Tele"
          type="text"
          value={telecommunicationsSubcontractor}
          onChange={changeTelecommunicationsSubcontractor}
        />
      </Fieldset>
      <Fieldset legend="Utbildning">
        <InputField
          label="Utbilning utförd (år?)"
          type="number"
          value={trainingCompleted}
          onChange={changeTrainingCompleted}
        />
      </Fieldset>
      <PoliceDeviations rows={policeDeviations} setRows={setPoliceDeviations} />
    </>
  );
};

export default FacilitySafetyCertificate;
