import { ValueType } from 'react-select';
import { ISelectOption } from '../../Shared/Select';

export function uniqueValues<T>(list: T[]) {
  return list.filter((x, i, a) => a.indexOf(x) === i);
}

export function getCurrentDay(plusDays?: number) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  if (plusDays) today.setDate(today.getDate() + plusDays);
  return today;
}

export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) previous[group] = [];
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);

export function getSelectedOption(options: ISelectOption[], value: any): ISelectOption | undefined {
  if(!value) return undefined;
  return options.find((x) => Number(x.value) === (value as number));
}

export function getSelectedValue(value: ValueType<ISelectOption>): number | undefined {
  return value ? Number((value as ISelectOption).value) : undefined;
}

export function getListOf<T>(value: T, quantity: number) : T[]{
  let list : T[] = [];
  for (let index = 0; index < quantity; index++) {
    list.push(value);
  }
  return list;
}
