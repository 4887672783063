import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Container from '../../../../../../Shared/Container/Container';
import { useGetProfile } from '../Customer.api';
import { ViewAddress } from '../../../../../../Shared/InputFields/Address/ViewAddress';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import Button from '../../../../../../Shared/Button/Button';

const CustomerInfo = () => {
  const history = useHistory();
  const { customerId } = useParams<{ customerId: string }>();
  const { isLoading, profile } = useGetProfile(customerId);

  return (
    <Container
      loading={isLoading}
      actions={
        <Button color="default" variant="outlined" onClick={() => history.push(`${history.location.pathname}/update`)}>
          Redigera
        </Button>
      }
      label="Kunduppgifter"
    >
      <InputField label="Namn" value={profile?.name} viewOnly />
      <InputField label="Org-/Personnummer" value={profile?.identificationNumber} viewOnly />
      <ViewAddress
        address={profile?.invoiceAddress}
        postalCode={profile?.invoicePostalCode}
        city={profile?.invoiceCity}
      />
      <InputField label="E-post" value={profile?.invoiceEmail} viewOnly />
    </Container>
  );
};

export default CustomerInfo;
