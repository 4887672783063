import React, { useCallback, useState } from 'react';
import { List, Typography, Accordion, AccordionSummary } from '@material-ui/core';
import SelectProductsListRow, { ISelectProductItem } from './SelectProductListRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import SearchInput from '../../../../../../../../../Shared/InputFields/SearchInput/SearchInput';
import useStyles from './SelectProductsList.styles';
import { IRegulation } from '../../../../../../../Admin/Components/SystemTypes/SystemTypes.api';

interface ISelectProductsList {
  label: string;
  list: ISelectProductItem[];
  chosenRegulation?: IRegulation;
  onChange: (productItem: ISelectProductItem, newQuantity: number) => void;
  onExpand: () => void;
  expanded: boolean;
}

const SelectProductsList = (props: ISelectProductsList) => {
  const classes = useStyles();
  const [query, setQuery] = useState('');

  const numberOfSelectedProducts = props.list.filter((x) => x.value > 0).length;

  const filterFunction = useCallback((x: ISelectProductItem, y: ISelectProductItem) => {

    if (props.chosenRegulation?.priority && props.chosenRegulation?.priority <= x.regulationPriority) {
      if (props.chosenRegulation?.priority && props.chosenRegulation?.priority <= y.regulationPriority) {
        return x.productCategorySortOrder - y.productCategorySortOrder
      }
      return -1;
    } else if (props.chosenRegulation?.priority && props.chosenRegulation?.priority <= y.regulationPriority) {
      return 1;
    }
    
    return x.productCategorySortOrder - y.productCategorySortOrder
  }, [props.chosenRegulation])

  return (
    <div className={classes.root}>
      <Accordion
        elevation={0}
        expanded={props.expanded}
        onChange={(_, expanded) => { props.onExpand(); }}
        className={classes.expansionPanel}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.panelSummaryContent}>
            <Typography className={classes.label}>{props.label}</Typography>
            <Typography className={classes.numberOfSelectedProducts}>
              {numberOfSelectedProducts} {numberOfSelectedProducts === 1 ? 'produkt vald' : 'produkter valda'}
            </Typography>
          </div>
        </AccordionSummary>

        <div className={classes.content}>
          <SearchInput value={query} onChange={setQuery} inline />
          <List disablePadding>
            {props.list
              .filter((x) => !query || x.label.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) > -1)
              .sort(filterFunction)
              .map((listItem: ISelectProductItem) => (
                <SelectProductsListRow
                  key={listItem.id}
                  item={listItem}
                  onChange={(value) => props.onChange(listItem, value)}
                  shadowed={props.chosenRegulation?.priority ? (props.chosenRegulation?.priority > listItem.regulationPriority) : false}
                />
              ))}
          </List>
        </div>
      </Accordion>
    </div>
  );
};

export default SelectProductsList;
