import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  user: {
    minWidth: '10.5rem',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
      paddingBottom: '1rem',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  skeletonUserName: {
    flexGrow: 1,
    marginLeft: '1rem',
    marginRight: '1rem',
    height: '1.25rem',
  },
  skeletonUserNameContainer: {
    height: '10rem',
    borderRight: `1px solid ${theme.palette.grey[300]}`,
  },
  skeletonRestContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
  skeletonRest: {
    display: 'flex',
    flexGrow: 1,
    '& > div': {
      flexGrow: 1,
      borderRight: `1px solid ${theme.palette.grey[300]}`,
      '&:last-child': {
        borderRight: 0,
      },
    },
  },
  skeletonOuterContainer: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}));
