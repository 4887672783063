import React, { useEffect, useState } from 'react';
import { Hash } from 'history';
import { useLocation } from 'react-router-dom';
import {
  Calendar as BigCalendar,
  EventProps,
  Event as CalendarEvent,
  EventWrapperProps,
  momentLocalizer,
} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';
import useUserContext from '../../../../Core/Authentication/UserContext';
import Container from '../../../../Shared/Container/Container';
import Toolbar, { Stepping } from '../Components/Toolbar/Toolbar';
import Event from '../Components/Event/Event';
import EventWrapper from '../Components/EventWrapper/EventWrapper';
import EditActivity from '../Components/EditActivity/EditActivity';
import { IActivity } from '../Components/ActivityForm/ActivityForm';
import { Messages } from '../Messages';
import { ICalendarEntry, CalendarEntryType, calendarEntryTypes } from '../../../Modules/Booking/Booking.api';
import { useGetHoliday } from '../../../Modules/Holiday/Holiday.api';
import { useStyles } from './UserCalendar.styles';
import moment from 'moment';
import { Role } from '../../../Modules/Admin/Components/Users/Users.api';
import MobileEvent from '../Components/MobileEvent/MobileEvent';
import { SaleStatus } from '../../../Modules/Errands/Components/Sale.api';

export interface ICalendarEvent<EntryType> extends CalendarEvent {
  type: CalendarEntryType;
  resource: EntryType;
}

export interface IUserCalendarProps {
  events?: ICalendarEvent<ICalendarEntry>[];
  viewDate: Date;
  onDateChanged: (date: Date) => void;
  onSave?: (activity: IActivity) => void;
  onRemove?: (id: number) => void;
}
interface EventWraperProps {
  onSave?: (activity: IActivity) => void;
  onRemove?: (id: number) => void;
}

const getBackgroundColor = (classes: ClassNameMap, event: ICalendarEvent<ICalendarEntry>) => {
  switch (event.type) {
    case CalendarEntryType.OffDuty:
    case CalendarEntryType.Sick:
    case CalendarEntryType.SickChild:
    case CalendarEntryType.Holiday:
      return classes.greyContainer;
    case CalendarEntryType.Misc:
      return classes.blueContainer;
    default:
      if (event.resource?.saleStatus) {
        if (event.resource?.saleStatus === SaleStatus.invoicing || event.resource?.saleStatus === SaleStatus.completed || event.resource?.saleStatus === SaleStatus.archived) {
          return classes.green;
        }
        if (event.resource?.saleStatus === SaleStatus.installed) {
          return classes.yellow;
        }
        let today = new Date();
        today.setDate(today.getDate() - 1);
        if (event.resource?.endDate && new Date(event.resource?.endDate) < today) {
          return classes.red;
        }
      }
        return classes.gray;
  }
};

const UserCalendar = <TResource extends object = object>(props: IUserCalendarProps) => {
  const classes = useStyles();
  const  { hash }  = useLocation<Hash | undefined>();
  const { user } = useUserContext();
  const { onDateChanged, viewDate, onSave, onRemove, ...rest } = props;
  const { getCheck } = useGetHoliday();
  const events: ICalendarEvent<ICalendarEntry>[] = props.events ?? [];
  const localizer = momentLocalizer(moment);
  const [addActivity, setAddActivity] = useState<{ start: Date; end: Date }>();
  const [editActivity, setEditActivity] = useState<IActivity>();
  const [holidayEvent, setHolidayEvent] = useState<ICalendarEvent<ICalendarEntry>[]>([]);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    async function getList() {
      const result = await getCheck();
      setHolidayEvent(
        result
          ? result.map((x) => ({
              start: new Date(x.date),
              end: new Date(x.date),
              allDay: true,
              title: x.name,
              type: CalendarEntryType.Holiday,
              resource: {
                companyUserIds: [],
                companyUserNames: [],
                startDate: new Date(x.date),
                endDate: new Date(x.date),
                description: x.name,
                showInCompanyCalendar: false,
                type: CalendarEntryType.Holiday,
                id: 0,
              },
            }))
          : [],
      );
    }
    getList();
  }, [getCheck]);

  useEffect(() => {
    if (hash.length > 0 && hash.slice(1) !== editActivity?.id?.toString()) {
      const eventId = hash.slice(1);
      setEditActivity(events.find(e => e.resource.id.toString() === eventId)?.resource);
    }
  }, [hash, events, editActivity]);
  
  // Custom component for Event (adding custom color based on resource number, maybe there's a better way)
  const CustomEventWrapper: React.FunctionComponent<EventWrapperProps<ICalendarEvent<ICalendarEntry>>> = (props) => {
    return (
      <div className={classes.event}>
        <EventWrapper
          onSave={onSave}
          onRemove={onRemove}
          activity={props.event.resource}
          type={props.event.type}
          readonly
        >
          {props.children}
        </EventWrapper>
      </div>
    );
  };

  function CustomEvent(event: EventProps) {
    const customTitle = event.event.resource.type === CalendarEntryType.Holiday ? event.event.resource.description : event.event.resource?.otherActivity ?? event.event.resource?.customerName;
    return (
      <Event
        isCompanyCalendar={false}
        label={event.event.resource.type}
        start={event.event?.start}
        end={event.event?.end}
        customTitle={customTitle}
      />
    );
  }

  return (
    <>
      <div className={classes.outerContainer}>
        <Container
          customSize={{
            md: 12,
          }}
        >
          <div className={classes.innerContainer}>
            <div className={classes.calendarContainer}>
              <Toolbar
                editProps={{
                  companyUserId: user ? user.selectedUserId ?? undefined : undefined,
                  onSave: onSave,
                  onRemove: onRemove,
                  open: !!addActivity,
                  start: addActivity?.start,
                  end: addActivity?.end,
                  onClose: () => setAddActivity(undefined),
                }}
                stepping={mobile ? Stepping.Day : Stepping.Month}
                date={viewDate}
                onDateChanged={onDateChanged}
              />
              {mobile ? 
                <>
                  <ul>
                    {events.filter((e) => moment(viewDate).startOf('date').diff(moment(e.start).startOf('date'), 'm') === 0).map((event) => {
                      const customTitle = event.resource?.otherActivity ?? event.resource?.customerName;
                      return <EventWrapper
                        onSave={onSave}
                        onRemove={onRemove}
                        activity={event.resource}
                        type={event.type}
                        isCompanyCalendar={true}
                        showInCompanyCalendarByDefault={true}
                      >
                        <MobileEvent
                          activity={event.resource}
                          type={event.type}
                          isCompanyCalendar
                          users={event.resource.companyUserNames}
                          label={event.resource.type}
                          start={event.start}
                          end={event.end}
                          isBlueBooking={
                            moment(event.start).format('HH:mm') === '00:00' && moment(event.end).format('HH:mm') === '00:00'
                          }
                          customTitle={customTitle}
                        />
                      </EventWrapper>
                    })}
                    {events.filter((e) => moment(viewDate).startOf('date').diff(moment(e.start).startOf('date'), 'm') === 0).length === 0 && <span>Inga bokningar idag</span>}
                  </ul>
                  <hr />
                  <ul>
                    <span>Senare i veckan</span>
                    {events.sort((a, b) => moment(a.start).diff(b.start, 'milliseconds')).filter((e) => moment(viewDate).startOf('date').diff(moment(e.start).startOf('date'), 'm') < 0 && moment(viewDate).endOf('week').diff(moment(e.start).endOf('date'), 'm') > 0).map((event) => {
                      const customTitle = event.resource?.otherActivity ?? event.resource?.customerName;
                      return <EventWrapper
                        onSave={onSave}
                        onRemove={onRemove}
                        activity={event.resource}
                        type={event.type}
                        isCompanyCalendar={true}
                        showInCompanyCalendarByDefault={true}
                      >
                        <MobileEvent
                          activity={event.resource}
                          type={event.type}
                          isCompanyCalendar
                          users={event.resource.companyUserNames}
                          label={event.resource.type}
                          start={event.start}
                          end={event.end}
                          showDate
                          isBlueBooking={
                            moment(event.start).format('HH:mm') === '00:00' && moment(event.end).format('HH:mm') === '00:00'
                          }
                          customTitle={customTitle}
                        />
                      </EventWrapper>
                    })}
                  </ul>
                </>
                :
                <BigCalendar<ICalendarEvent<ICalendarEntry>, TResource>
                  {...rest}
                  tooltipAccessor={(e) => ''}
                  popup
                  events={[...holidayEvent, ...events]}
                  localizer={localizer}
                  defaultView={mobile ? 'day' : 'month'}
                  views={['day', 'month']}
                  view={mobile ? 'day' : 'month'}
                  date={viewDate}
                  timeslots={12}
                  step={100}
                  toolbar={false}
                  onNavigate={(date) => {
                    onDateChanged && onDateChanged(date);
                  }}
                  components={{
                    eventWrapper: CustomEventWrapper,
                    event: CustomEvent as (event: CalendarEvent) => JSX.Element,
                  }}
                  className={classes.calendar}
                  messages={Messages}
                  selectable
                  onSelectEvent={(x) => {
                    setEditActivity(x.resource);
                    if (window !== undefined) window.location.hash = x.resource.id.toString();
                  }}
                  onSelectSlot={(info) => {
                    var startDate = info.start as Date;
                    var endDate = info.end as Date;
                    startDate.setHours(0, 0);
                    endDate.setHours(0, 0);
                    setAddActivity({ start: startDate, end: endDate });
                  }}
                />}
            </div>
            {!mobile && (
              <div className={classes.agenda}>
                <ul className={classes.list}>
                  {events
                    ?.filter((e) => e.start && e.start > new Date())
                    .sort((a,b) => a > b ? -1 : 1)
                    .map((event: any) => (
                      <li
                        key={event.id}
                        className={classes.eventListItem}
                        onClick={() => { setEditActivity(event.resource); if (window !== undefined) window.location.hash = event.resource.id.toString();}}
                      >
                        <div className={`${classes.messageListContainer} ${getBackgroundColor(classes, event)}`}>
                          <div className={classes.message}>
                            <Typography>
                              <span className={classes.bold}>
                                <span>
                                  {event.resource?.otherActivity ?? event.resource?.customerInformation?.customerName}
                                </span>
                              </span>
                            </Typography>
                            <Typography>
                              <span>{calendarEntryTypes.find((x) => x.type === event.type)?.name}</span>
                            </Typography>
                            <Typography>
                              <span className={classes.bold}>{'Datum: '}</span>
                              <span>{event.start ? moment(event.start).format('DD MMMM YYYY') : ''}</span>
                            </Typography>
                            <Typography>
                              <span className={classes.bold}>{'Tid: '}</span>
                              <span>
                                {event.start ? moment(event.start).format('HH:mm') : ''}
                                <span className={classes.dash}>–</span>
                                {event.end ? moment(event.end).format('HH:mm') : ''}
                              </span>
                            </Typography>
                            <Typography>{event.description}</Typography>
                            <Typography>
                              {event.customerInformation && (
                                <>
                                  <span className={classes.bold}>{'Kundnamn:'}</span>{' '}
                                  {event.customerInformation.customerName}{' '}
                                </>
                              )}
                            </Typography>
                            <Typography>
                              {event.customerInformation && (
                                <>
                                  <span className={classes.bold}>{'Adress:'}</span>{' '}
                                  {event.customerInformation.customerName}{' '}
                                  {event.customerInformation.customerPostalCode}{' '}
                                  {event.customerInformation.customerCity}{' '}
                                </>
                              )}
                            </Typography>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>
        </Container>
      </div>
      <EditActivity
        onSave={onSave}
        onRemove={onRemove}
        onClose={() => { setEditActivity(undefined); if (window !== undefined) window.location.hash = ''; }}
        editActivityId={editActivity?.id}
        saleId={editActivity?.saleId}
        open={!!editActivity}
        showInCompanyCalendarByDefault={false}
        isCompanyCalendar={false}
        activityLabel={(calendarEntryTypes.find((c) => c.type === editActivity?.type)?.name ?? 'Aktivitet') + (editActivity?.facilityName ? ': ' + editActivity?.facilityName : editActivity?.otherActivity ? (': ' + editActivity?.otherActivity) : '')}
        inCalendar
        installerCalendar={user?.roles?.some((x) => x === Role.Installer)}
      />
    </>
  );
};

export default UserCalendar;
