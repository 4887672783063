import React, { useState } from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';
import Container from '../../../../../Shared/Container/Container';
import Button from '../../../../../Shared/Button/Button';
import RadioField from '../../../../../Shared/InputFields/RadioField/RadioField';
import { InputField } from '../../../../../Shared/InputFields/InputField/InputField';
import UploadInput, { IUploadFile, UploadFileType } from '../../../../../Shared/InputFields/UploadInput/UploadInput';
import { isResultError } from '../../../../../Shared/Api/response/ICreateResult';
import { IFileNode, IUploadFileCommand, IUploadFileCommandValidationErrors, useUploadFile } from '../../FileSystem.api';

const UploadFileForm: React.FC<{
  parentNode: IFileNode;
  toggleNode: (nodeId: number, close?: boolean) => void;
  onViewFile: (file: string, nodeId: number) => void;
  closeForm: (refetch?: boolean) => void;
}> = (props) => {
  const { isLoading, uploadFile } = useUploadFile();
  const [file, setFile] = useState<IUploadFile>();
  const [fileType, setFileType] = useState<UploadFileType>('pdf');
  const [errors, setErrors] = useState<IUploadFileCommandValidationErrors>();

  async function save() {
    if (!file) return;
    const command: IUploadFileCommand = {
      parentNodeId: props.parentNode.id,
      file: file,
    };
    const result = await uploadFile(command);
    if (isResultError(result)) {
      setErrors(result);
    } else {
      setErrors(undefined);
      props.closeForm(true);
      props.toggleNode(props.parentNode.id);

      const base64 = await fetch(file.content);
      var fileBlob = base64.blob();
      var fileURL = URL.createObjectURL(await fileBlob);

      props.onViewFile(fileURL, result);
    }
  }

  return (
    <Container
      customSize={{ sm: 12, md: 4, lg: 3 }}
      form
      loading={isLoading}
      label="Ladda upp fil"
      actions={
        <>
          <Button variant="outlined" disabled={isLoading} onClick={() => props.closeForm()}>
            Avbryt
          </Button>
          <Button color="primary" variant="contained" disabled={isLoading || !file} onClick={save}>
            Spara
          </Button>
        </>
      }
    >
      <InputField label="Huvudmapp" type="text" viewOnly value={props.parentNode.name} />
      <RadioField label="Filtyp">
        <FormControlLabel
          label="PDF"
          disabled={isLoading}
          control={<Radio color="primary" />}
          checked={fileType === 'pdf'}
          onChange={() => setFileType('pdf')}
        />
        <FormControlLabel
          label="Image"
          disabled={isLoading}
          control={<Radio color="primary" />}
          checked={fileType === 'image'}
          onChange={() => setFileType('image')}
        />
      </RadioField>
      <UploadInput
        label="Ladda upp er fil"
        value={file?.name}
        type={fileType}
        disabled={isLoading}
        canEditFileName
        onFileChange={setFile}
        error={errors?.file !== undefined}
      />
    </Container>
  );
};

export default UploadFileForm;
