import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  mobilePermissions: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0 1rem 1rem 1rem',
    '& > *': {
      minWidth: '50%',
    },
  },
  invitationButton: {
    padding: 0,
    top: '-0.25rem',
    border: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  inputLabel: {
    marginLeft: '10px',
  },
}));
