import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Button from '../../../../../../Shared/Button/Button';
import Container from '../../../../../../Shared/Container/Container';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import useInputState from '../../../../../../Shared/Hooks/UseInputState/UseInputState';
import useFormValidation from '../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import { isResultError } from '../../../../../../Shared/Api/response/ICreateResult';
import {
  IUpsertFacilityValidationErrors,
  IUpsertFacilityCommand,
  useUpsertFacility,
  useGetFacility,
} from '../Facilities.api';
import PostalCodeField from '../../../../../../Shared/InputFields/PostalCodeField/PostalCodeField';
import { SingleRowInputField } from '../../../../../../Shared/InputFields/InputField/SingleRowInputField';
import { useGetCustomer } from '../../../Customers.api';
import EmailField from '../../../../../../Shared/InputFields/EmailField/EmailField';

const FacilityForm: React.FunctionComponent = () => {
  const history = useHistory();
  const { customerId, id } = useParams<{ customerId: string; id: string }>();
  const location = useLocation();

  const { isLoading, getFacility } = useGetFacility(customerId);
  const { isLoading: isUpsertLoading, upsert } = useUpsertFacility(customerId);
  const { getCustomer } = useGetCustomer();

  const { isFormValid, formRef } = useFormValidation();
  const [originalName, setOriginalName] = useState('');
  const [name, changeName, setName] = useInputState('');

  const [address, changeAddress, setAddress] = useInputState('');
  const [postalCode, changePostalCode, setPostalCode] = useInputState('');
  const [city, changeCity, setCity] = useInputState('');

  const [invoiceAddress, changeInvoiceAddress, setInvoiceAddress] = useInputState('');
  const [invoicePostalCode, changeInvoicePostalCode, setInvoicePostalCode] = useInputState('');
  const [invoiceCity, changeInvoiceCity, setInvoiceCity] = useInputState('');
  const [invoiceEmail, changeInvoiceEmail, setInvoiceEmail] = useInputState('');
  const [invoiceNote, changeInvoiceNote, setInvoiceNote] = useInputState('');

  const [internalNote, changeInternalNote, setInternalNote] = useInputState('');

  const [errors, setErrors] = useState<IUpsertFacilityValidationErrors>();

  const urlParams = new URLSearchParams(location.search);
  const redirectToCustomer = urlParams.get('redirect-to-customer') === '1';

  const handleGetCustomer = useCallback(async () => {
    const response = await getCustomer(`${customerId}/${false}`);

    if (!response) {
      history.push('/customers/' + customerId);
    }
  }, [customerId, history, getCustomer]);

  useEffect(() => {
    async function loadFacility() {
      const facility = await getFacility(id);
      setOriginalName(facility.name);
      setName(facility.name);
      setAddress(facility.address);
      setPostalCode(facility.postalCode);
      setCity(facility.city);
      setInvoiceAddress(facility.invoiceAddress);
      setInvoicePostalCode(facility.invoicePostalCode);
      setInvoiceCity(facility.invoiceCity);
      setInvoiceEmail(facility.invoiceEmail);
      setInvoiceNote(facility.invoiceNote ?? '');
      setInternalNote(facility.internalNote ?? '');
    }

    if (id) {
      loadFacility();
    } else {
      handleGetCustomer();
    }
  }, [
    id,
    customerId,
    getFacility,
    setName,
    setAddress,
    setCity,
    setPostalCode,
    setInternalNote,
    handleGetCustomer,
    setInvoiceAddress,
    setInvoiceCity,
    setInvoiceEmail,
    setInvoiceNote,
    setInvoicePostalCode,
  ]);

  async function upsertFacility() {
    const upsertCommand: IUpsertFacilityCommand = {
      name: name,
      address: address,
      postalCode: postalCode,
      city: city,
      invoiceAddress: invoiceAddress,
      invoicePostalCode: invoicePostalCode,
      invoiceCity: invoiceCity,
      invoiceEmail: invoiceEmail,
      invoiceNote: invoiceNote,
      internalNote: internalNote,
    };

    const result = await upsert(upsertCommand, id);

    isResultError(result)
      ? setErrors(result)
      : redirectToCustomer
      ? history.push(`/customers/${customerId}`)
      : history.push(`/customers/${customerId}/facility/${result}`);
  }

  function quickCreate() {
    setAddress(!!address ? address : 'KOMPLETTERA');
    setPostalCode(!!postalCode ? postalCode : '00000');
    setCity(!!city ? city : 'KOMPLETTERA');
    upsertFacility();
  }

  function Actions() {
    const cancel = () =>
      redirectToCustomer
        ? history.push(`/customers/${customerId}`)
        : id
        ? history.push(`/customers/${customerId}/facility/${id}`)
        : history.push(`/customers/${customerId}`);

    return (
      <>
        <Button color="default" variant="outlined" onClick={cancel}>
          Avbryt
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={upsertFacility}
          loading={isUpsertLoading}
          disabled={!isFormValid}
        >
          {id ? 'Spara' : 'Skapa'}
        </Button>
        {!id && (
          <Button color="primary" variant="contained" onClick={quickCreate} loading={isUpsertLoading} disabled={!name}>
            {'Snabbskapa'}
          </Button>
        )}
      </>
    );
  }

  return (
    <Container
      form
      label={id ? `Redigera ${originalName}` : 'Skapa plats'}
      actions={<Actions />}
      ref={formRef}
      loading={isLoading}
    >
      <InputField label="Namn" type="text" fullWidth value={name} onChange={changeName} errorText={errors?.name} />
      <SingleRowInputField
        label="Gatuadress"
        type="text"
        required
        value={address}
        onChange={changeAddress}
        errorText={errors?.address}
      />
      <PostalCodeField
        label="Postnummer"
        type="text"
        required
        value={postalCode}
        onChange={changePostalCode}
        errorText={errors?.postalCode}
      />
      <InputField
        label="Ort"
        type="text"
        fullWidth
        required
        value={city}
        onChange={changeCity}
        errorText={errors?.city}
      />
      <SingleRowInputField
        label="Fakturaadress"
        type="text"
        value={invoiceAddress}
        onChange={changeInvoiceAddress}
        errorText={errors?.invoiceAddress}
      />
      <PostalCodeField
        label="Faktura postnummer"
        type="text"
        value={invoicePostalCode}
        onChange={changeInvoicePostalCode}
        errorText={errors?.invoicePostalCode}
      />
      <InputField
        label="Faktura ort"
        type="text"
        fullWidth
        value={invoiceCity}
        onChange={changeInvoiceCity}
        errorText={errors?.invoiceCity}
      />
      <EmailField
        label="Faktura e-post"
        type="text"
        fullWidth
        value={invoiceEmail}
        onChange={changeInvoiceEmail}
        errorText={errors?.invoiceEmail}
      />
      <InputField
        label="E-faktura anteckning"
        type="text"
        fullWidth
        multiline
        value={invoiceNote}
        onChange={changeInvoiceNote}
        errorText={errors?.internalNote}
      />
      <InputField
        label="Anteckningar"
        type="text"
        fullWidth
        multiline
        customWidth={12}
        rows={6}
        value={internalNote}
        onChange={changeInternalNote}
        errorText={errors?.internalNote}
      />
    </Container>
  );
};

export default FacilityForm;
