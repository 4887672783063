import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import MeetingNotesList from './MeetingNotesList/MeetingNotesList';
import MeetingNotesView from './MeetingNotesView/MeetingNotesView';

const MeetingNotes = () => {
  const { id } = useParams<{ id: string }>();
  const [refetchNo, setRefetchNo] = useState(0);

  return (
    <>
      <MeetingNotesList label="Mötesfrågor" mode="search" refetchNo={refetchNo} />
      {id && <MeetingNotesView triggerRefetch={() => setRefetchNo((x) => x + 1)} />}
    </>
  );
};

export default MeetingNotes;
