import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { useStyles } from './InputFieldLoading.styles';

interface InputFieldLoading {
  helperText?: string;
}

export const InputFieldLoading = (props: InputFieldLoading) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Skeleton variant="text" animation="wave" className={classes.skeletonLabel} />
      <Skeleton variant="rect" animation="wave" className={classes.skeletonInput} />
      {props.helperText && <Skeleton variant="text" animation="wave" className={classes.skeletonHelperText} />}
    </div>
  );
};
