import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import IPaginatedResponse from '../../../../Shared/Api/response/IPaginatedResponse';
import useTable from '../../../../Shared/Table/useTable';
import { Table } from '../../../../Shared/Table/Table';
import TablePaginationWithDatasource from '../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import {
  getEntryDescription,
  ISystemLogEntryDto,
  useGetCurrentCompanyUserLog,
} from '../../Admin/Components/SystemLog/SystemLog.api';
import React from 'react';
import Datepicker from '../../../../Shared/InputFields/DatePicker/Datepicker';
import { formatDateWithTime } from '../../../../Shared/Formatting/formatDate';
import { ILogRow } from '../../Admin/Components/SystemLog/SystemLogList/SystemLogList';

const defaultFromDate = moment().startOf('month').toDate();
const defaultToDate = moment().startOf('day').toDate();

const CompanyUserLog = () => {
  const { isLoading, currentCompanyUserLog, getCurrentCompanyUserLog } = useGetCurrentCompanyUserLog();
  const { pageSize, setPageSize, setPageNumber, paginatedRequest } = useTable();
  const [logRows, setLogRows] = useState<ILogRow[]>([]);
  const [fromDate, setFromDate] = useState<Date | undefined>(defaultFromDate);
  const [toDate, setToDate] = useState<Date | undefined>(defaultToDate);
  const [emptyMessage, setEmptyMessage] = useState('');

  const loadList = useCallback(async () => {
    const response: IPaginatedResponse<ISystemLogEntryDto> = await getCurrentCompanyUserLog({
      ...paginatedRequest,
      fromDate,
      toDate,
    });

    if (response?.totalCount === 0) {
      setEmptyMessage('Finns inga loggningar i valt intervall');
    }
  }, [paginatedRequest, getCurrentCompanyUserLog, fromDate, toDate]);

  useEffect(() => {
    if (currentCompanyUserLog) {
      setLogRows(
        currentCompanyUserLog.items.map((x) => ({
          id: x.id,
          date: x.dateTime,
          user: x.user,
          customer: x.eventData?.entityDescription,
          saleNumber: x.eventData?.entityDescription,
          todoId: x.eventData?.entityDescription,
          description: getEntryDescription(x),
          impersonator: x.impersonator,
        })),
      );
    } else {
      setLogRows([]);
    }
  }, [currentCompanyUserLog]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  useEffect(() => {
    setPageNumber(1);
  }, [setPageNumber]);

  return (
    <Table
      label={`Loggbok (${currentCompanyUserLog?.totalCount ?? 0})`}
      pagination={
        currentCompanyUserLog && (
          <TablePaginationWithDatasource
            datasource={currentCompanyUserLog}
            onChangePage={setPageNumber}
            onChangeRowsPerPage={setPageSize}
          />
        )
      }
      empty={
        currentCompanyUserLog && {
          empty: currentCompanyUserLog?.totalCount === 0 ? true : false,
          message: emptyMessage,
        }
      }
      filters={
        <>
          <>
            <Datepicker
              required
              label="Visa från"
              inFilter
              selected={fromDate}
              onChange={(d: Date | null) => setFromDate(d ?? undefined)}
            />

            <Datepicker
              required
              label="Visa till"
              inFilter
              selected={toDate}
              onChange={(d: Date | null) => setToDate(d ?? undefined)}
            />
          </>
        </>
      }
      loading={{
        loading: isLoading,
        skeletonRows: pageSize,
      }}
    >
      <>
        <TableHead>
          <TableRow>
            <TableCell>Tid</TableCell>
            <TableCell>Användare</TableCell>
            <TableCell>Händelse</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logRows?.map((log) => (
            <TableRow key={log.id}>
              <TableCell>{formatDateWithTime(log.date)}</TableCell>
              {log.impersonator ? (
                <TableCell>{log.impersonator + ` (som ${log.user})`}</TableCell>
              ) : (
                <TableCell>{log.user}</TableCell>
              )}
              <TableCell>{log.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </>
    </Table>
  );
};

export default CompanyUserLog;
