import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cautionButton: {
    borderColor: theme.palette.error.dark,
    color: theme.palette.error.light,
  },

  warningsGrid: {
    marginTop: -15,
    display: 'none',
  },
  ConfirmText: {
    fontWeight: 'bold',
  },
  parentGrid: {
    display: 'inline-block',
  },
}));

export default useStyles;
