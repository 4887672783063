import React, { useState, useContext } from 'react';
import {
  Typography,
  Button,
  Grid,
  GridSize,
  GridJustification,
  GridItemsAlignment,
  GridContentAlignment,
} from '@material-ui/core';
import ArrowRight from '@material-ui/icons/ArrowRight';
import { ContainerContext } from '../../Container/Container';
import { useStyles } from './Fieldset.styles';

interface IFieldsetProps {
  legend?: string;
  legendAction?: React.ReactNode;
  children: React.ReactNode;
  actions?: React.ReactNode;
  useAsExpandPanel?: boolean;
  expandedAsDefault?: boolean;
  customSize?: ICustomSize;
  justification?: GridJustification;
  alignment?: GridItemsAlignment;
  contentAlignment?: GridContentAlignment;
  style?: React.CSSProperties;
}

export interface ICustomSize {
  fullsize?: boolean;
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  xl?: GridSize;
}

export const Fieldset = (props: IFieldsetProps) => {
  const classes = useStyles();
  const containerContext = useContext(ContainerContext);
  const [expanded, setExpanded] = useState<boolean | undefined>(containerContext ? props.expandedAsDefault : undefined);
  const { ...rest } = props;

  const Legend = () => (
    <>
      <Typography component="legend" variant="h5" className={classes.legend}>
        {props.legend}
      </Typography>
      <span className={classes.line}></span>
      {props.legendAction}
    </>
  );

  return (
    <Grid
      component="fieldset"
      spacing={2}
      container
      justify={props.justification}
      alignItems={props.alignment}
      alignContent={props.contentAlignment}
      className={classes.root}
      lg={props.customSize?.fullsize ? 12 : props.customSize?.lg ?? 12}
      md={props.customSize?.fullsize ? 12 : props.customSize?.md ?? 12}
      sm={props.customSize?.fullsize ? 12 : props.customSize?.sm ?? 12}
      xs={12}
      {...rest}
    >
      {props.legend && (
        <Grid item xs={12} className={classes.legendContainer}>
          {props.useAsExpandPanel ? (
            <Button
              onClick={() => setExpanded(!expanded)}
              className={classes.expandButton}
              variant="text"
              color="default"
              aria-expanded={expanded}
              aria-label={`Visa alla ${props.legend && `fält gällande ${props.legend.toLowerCase()}`}`}
              disableTouchRipple
              startIcon={
                <ArrowRight className={classes.arrow} style={{ transform: expanded ? 'rotate(90deg)' : 'initial' }} />
              }
            >
              {props.legend && <Legend aria-hidden={true} />}
            </Button>
          ) : (
            props.legend && <Legend />
          )}
          {props.actions}
        </Grid>
      )}
      {!props.useAsExpandPanel ? props.children : expanded && props.children}
    </Grid>
  );
};
