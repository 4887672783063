import React, { useEffect } from 'react';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import AuthRoute from '../../../Core/Authentication/AuthRoute/AuthRoute';
import { INavigationItem } from '../../../Shared/Navigation/Header/Header';
import useHeaderNavigationContext from '../../../Shared/Navigation/Header/HeaderNavigationContext/HeaderNavigationContext';
import CompanyUserLog from './Component/CompanyUserLog';
import HomeComponentSetting from './Component/HomeComponetSetting';

function CompanyUserRoute() {
  const { path } = useRouteMatch();
  const { setHeaderNavigationItems, setHeaderNavigationText } = useHeaderNavigationContext();

  useEffect(() => {
    const navigationItems: INavigationItem[] = [
      {
        title: 'Användarloggbok',
        to: `${path}/log`,
        exact: false,
      },
      {
        title: 'Startsidan inställning',
        to: `${path}/homepage`,
        exact: false,
      },
    ];

    setHeaderNavigationText(navigationItems);
    setHeaderNavigationItems(navigationItems);

    return () => {
      setHeaderNavigationItems([]);
    };
  }, [path, setHeaderNavigationItems, setHeaderNavigationText]);

  return (
    <Switch>
      <AuthRoute exact path={path} anyModule>
        <Redirect to={`${path}/log`} />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/log`}
        breadcrumbs={[
          { title: 'Användare' },
          {
            title: 'Loggbok',
          },
        ]}
        anyModule
      >
        <h1 className={'sr-only'}>Användarloggbok</h1>
        <CompanyUserLog />
      </AuthRoute>
      <AuthRoute
        exact
        path={`${path}/homepage`}
        breadcrumbs={[
          { title: 'Användare' },
          {
            title: 'Startsidan inställning',
          },
        ]}
        anyModule
      >
        <h1 className={'sr-only'}>Startsidan inställning</h1>
        <HomeComponentSetting />
      </AuthRoute>
      <Route path={`${path}/*`}>
        <Redirect to={`${path}/log`} />
      </Route>
    </Switch>
  );
}

export default CompanyUserRoute;
