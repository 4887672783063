import { useApiGet, useApiPost } from '../../../Core/Api/Api';
import ValidationErrorResultType from '../../../Shared/Api/response/ValidationErrorResultType';
import { SaleType } from '../../../AuthApp/Shared/SaleType';
import {
  AuditInterval,
  IAgreementOption,
  IFacility,
  Measure,
  RentType,
  Service,
  TransferInterval,
  TransferType,
} from '../../../AuthApp/Modules/Errands/Components/Sale.api';

export interface IOffer {
  id: number;
  saleIdentifier: string;
  agreementNumber: number;
  type: SaleType;
  canBeApprovedByCustomer: boolean;
  description: string;
  offerDescription: string;
  companyContactInfo: IContactInfoDto;
  customerInvoiceInfo: IInvoiceInformation;
  customerData: {
    name: string;
    address: string;
    postalCode: string;
    city: string;
  };
  divergentInvoiceData?: boolean;
  facilityId?: number;
  facilityInformation: IFacility;
  coreSystemId?: number;
  coreSystem?: {
    systemType: string;
    systemTypeRegulation: string;
    mainProduct: string;
  };
  saleProducts: {
    name: string;
    facilityPlacement: string;
    price?: number;
    installationTime: number;
  }[];
  prices: {
    materialCost?: number;
    installationTime?: number;
    installationRate?: number;
    installationCost?: number;
    otherCost?: number;
    totalPrice?: number;
    firstInstallationCost?: number;
    rentPrice?: number;
  };
  saleAgreement?: {
    monthlyCost: number;
    startDate: Date;
    endDate: Date | null;
    emergencyCallCenterSupplierName: string;
    securityCompanySupplierName: string;
    measure?: Measure;
    service?: Service;
    auditInterval?: AuditInterval;
    transferType?: TransferType;
    transferInterval?: TransferInterval;
    rentType?: RentType;
    includeInnerControl: boolean;
  };
  terms: {
    including: string;
    excluding: string;
    regulation: string;
    warranty: string;
    delivery: string;
    validPeriod: string;
  };
}

interface IInvoiceInformation {
  invoiceAddress?: string;
  invoiceCity?: string;
  invoicePostalCode?: string;
}

export interface IContactInfoDto {
  emailCustomerService?: string;
  phoneNumber?: string;
  companyLogoBase64?: string;
}

export function useGetOffer() {
  const { isLoading, response, get } = useApiGet<IOffer>('/api/anonymous/offers/', false);

  return {
    isLoading,
    offer: response,
    get: get,
  };
}

export interface IApproveOfferCommand {
  facilityName: string | undefined;
  facilityAddress: string;
  facilityPostalCode: string;
  facilityCity: string;
  invoiceAddress: string | undefined;
  invoiceCity: string | undefined;
  invoicePostalCode: string | undefined;
}

export enum DeclineReason {
  Other = 0,
  PriceTooHigh = 1,
  NotAsAgreed = 2,
  ChangedMyMind = 3,
}

export const declineReasons: IAgreementOption<DeclineReason>[] = [
  { type: DeclineReason.Other, name: 'Annan anledning. Skriv nedan!' },
  { type: DeclineReason.PriceTooHigh, name: 'Priset är för högt.' },
  { type: DeclineReason.NotAsAgreed, name: 'Detta var inte vad vi kom överrens om.' },
  { type: DeclineReason.ChangedMyMind, name: 'Jag har ändrat mig.' },
];

export interface IApproveOfferValidationErrors extends ValidationErrorResultType<IApproveOfferCommand> {}

export function useApproveOffer(token: string | undefined) {
  const { isLoading, post } = useApiPost<IApproveOfferValidationErrors, IApproveOfferCommand>(
    `/api/anonymous/offers/${token}/approve`,
  );

  return {
    isApproveLoading: isLoading,
    approve: post,
  };
}

export interface IDeclineOfferCommand {
  message: string;
}

export interface IDeclineOfferValidationErrors extends ValidationErrorResultType<IDeclineOfferCommand> {}

export function useDeclineOffer(token: string | undefined) {
  const { isLoading, post } = useApiPost<IDeclineOfferValidationErrors, IDeclineOfferCommand>(
    `/api/anonymous/offers/${token}/decline`,
  );

  return {
    isDeclineLoading: isLoading,
    decline: post,
  };
}
