import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  note: {
    marginBottom: '1rem',
  },
  commentTitle: {
    marginBottom: '1rem',
    marginTop: '2rem',
  },
  list: {
    padding: 0,
  },
}));
