import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import useStyles from './SkeletonRow.styles';

export const SkeletonRow = () => {
  const classes = useStyles();
  const animation = 'wave';

  return (
    <div className={classes.root} data-testid="skeleton">
      <div className={classes.content}>
        <Skeleton variant="circle" animation={animation} className={classes.circle} />
        <Skeleton animation={animation} className={classes.line} />
      </div>
    </div>
  );
};

export default SkeletonRow;
