import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  isLoading: {
    display: 'flex',
    pointerEvents: 'none',
    opacity: 0.7,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      padding: '.5rem',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '1rem 2rem 6rem',
      marginLeft: '6rem',
      maxWidth: 'calc(100% - 10rem)',
    },
  },
  multiUserContent: {
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      padding: '1rem',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '1rem 2rem 6rem',
      maxWidth: 'calc(100% - 10rem)',
    },
  },
  date: {
    marginBottom: '0.25rem',
  },
}));
