import React from 'react';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import moment from 'moment';
import { useStyles } from './Event.styles';
import { calendarEntryTypes, CalendarEntryType } from '../../../../Modules/Booking/Booking.api';
import { LocalShipping } from '@material-ui/icons';
import theme, { calendarColors } from '../../../../../Styles/Theme';

interface IEventProps {
  label: CalendarEntryType;
  start: Date | undefined;
  end: Date | undefined;
  isCompanyCalendar: boolean;
  users?: string[];
  isBlueBooking?: boolean;
  customTitle?: string;
  productStatuses?: number[];
}

const productStatusColors = [
  theme.palette.grey[700],
  calendarColors.yellow.background,
  calendarColors.yellow.background,
  theme.palette.success.main,
  theme.palette.success.main,
]
const productStatusTooltips = [
  'Ingen beställning lagd',
  'Beställning lagd',
  'Beställning bekräftad av leverantör',
  'Beställning levererad',
  'Produkter installerade'
]

const Event: React.FC<IEventProps> = (props) => {
  const classes = useStyles();
  const dateStart = props.start ? moment(props.start).format('HH:mm') : '';
  const dateEnd = props.end ? moment(props.end).format('HH:mm') : '';
  const productsStatus = props.productStatuses ? Math.min(...props.productStatuses) : null;

  return (
    <>
      <Grid role="title" container>
        <Grid
          item
          role="user"
          className={classes.eventTitleContainer}
        >
          <Typography className={classes.eventTitle} variant="subtitle1">
            <span>{props.customTitle ?? (calendarEntryTypes.find((x) => x.type === props.label)?.name)}</span>
            {props.users && (
              <span>: {props?.users?.length < 2 ? props.users[0] : props.users.length + ' användare'}</span>
            )}
          </Typography>
        </Grid>
        {!props.isBlueBooking && (
          <Grid item container alignItems="center" className={classes.eventTimeCompanyContainer}>
            <Typography variant="subtitle1"><span  className="continuesDateStart">{dateStart}</span> - <span className="continuesDateEnd">{dateEnd}</span></Typography>
            {productsStatus != null && <Tooltip title={productStatusTooltips[productsStatus]}><LocalShipping style={{ color: productStatusColors[productsStatus]}} /></Tooltip>}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Event;
