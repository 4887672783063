import { makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import theme from '../../../../../Styles/Theme';

export const dialogContent = {
  [theme.breakpoints.up('sm')]: {
    minWidth: '20rem',
  },
} as CSSProperties;

export const useStyles = makeStyles((theme: Theme) => ({
  popper: {
    zIndex: 4,
  },
  button: {
    marginRight: '0.5rem',
  },
  content: {
    ...dialogContent,
  },
  time: {
    width: 'calc(50% - 0.5rem)',
  },
  datepicker: {
    '& > *': {
      width: '100%',
    },
  },
  save: {
    marginLeft: '1rem',
  },
  title: {
    marginBottom: '0.5rem',
  },
}));
