import { makeStyles, Theme } from '@material-ui/core/styles';
import { InputFieldBaseStyles } from '../../InputFields-styles';

export const useStyles = makeStyles((theme: Theme) => ({
  input: {
    '& input': {
      ...InputFieldBaseStyles,
      paddingLeft: `3rem`,
      fontWeight: 400,
    },
  },
  checkBox: {
    marginLeft: '1rem',
    '& .MuiFormControlLabel-label': {
      paddingLeft: '5px',
    }
  },
}));
