import React, { useEffect, useState } from 'react';
import Container from '../../../../../../Shared/Container/Container';
import DataList from '../../../../../../Shared/DataList/DataList';
import Dd from '../../../../../../Shared/DataList/Dd/Dd';
import Dt from '../../../../../../Shared/DataList/Dt/Dt';
import { Table } from '../../../../../../Shared/Table/Table';
import { TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core';
import CompanyUserSelect from '../../Users/UserSelect/CompanyUserSelect';
import DatePicker from '../../../../../../Shared/InputFields/DatePicker/Datepicker';
import { ISaleDto, useGetCompanyUserMonthlyStats } from '../../SaleStats.api';
import formatPrice from '../../../../../../Shared/Formatting/formatPrice';
import moment from 'moment';
import formatDate from '../../../../../../Shared/Formatting/formatDate';
import { SaleStatusLabel } from '../../../../Errands/Components/Sale.api';
import useStyles from './MonthlyReport.styles';

interface IReportDate {
  date: Date;
  red: boolean;
  holidayName: string | undefined;
  sales: ISaleDto[];
}

interface IReportWeek {
  number: number;
  dates: IReportDate[];
}

const monthStart = moment().startOf('month').toDate();
const MonthlyReport = () => {
  const classes = useStyles();
  const [companyUserId, setCompanyUserId] = useState<string>();
  const [weeks, setWeeks] = useState<IReportWeek[]>();
  const { isLoading, userMonthlyStats: stats, getCompanyUserMonthlyStats } = useGetCompanyUserMonthlyStats(
    companyUserId,
  );
  const [date, setDate] = useState(monthStart);

  useEffect(() => {
    if (companyUserId) {
      getCompanyUserMonthlyStats({ date, companyUserId: Number(companyUserId) });
    }
  }, [getCompanyUserMonthlyStats, date, companyUserId]);

  useEffect(() => {
    if (stats) {
      let newWeeks: IReportWeek[] = [];
      let dates: IReportDate[] = [];
      let daysInMonth = moment(date).daysInMonth();
      let weekNumber;
      for (let index = 1; index <= daysInMonth; index++) {
        let momentInMonth = moment(date).date(index).startOf('day');
        let dateInMonth = momentInMonth.toDate();
        let dayInWeek = momentInMonth.isoWeekday();
        let currentWeekNumber = momentInMonth.isoWeek();
        if (currentWeekNumber !== weekNumber) {
          weekNumber = currentWeekNumber;
          dates = [];
          newWeeks.push({ number: weekNumber, dates: dates });
        }

        let weekEnd = dayInWeek === 6 || dayInWeek === 7;
        let holiday = stats.holidays.find((x) => momentInMonth.isSame(x.date, 'date'));
        let sales = stats.sales.filter((x) => momentInMonth.isSame(moment(x.created), 'date'));
        if (holiday || weekEnd || sales.length > 0) {
          dates.push({
            date: dateInMonth,
            red: !!(weekEnd || holiday),
            holidayName: holiday?.name,
            sales: sales,
          });
        }
      }
      if (dates.length > 0 && weekNumber) {
        newWeeks.push({ number: weekNumber, dates: dates });
      }

      setWeeks(newWeeks);
    } else {
      setWeeks(undefined);
    }
  }, [stats, date]);

  return (
    <Container
      loading={isLoading}
      customSize={{ fullsize: true }}
      customStatisticFilter
      filters={
        <>
          <DatePicker
            label="År/månad"
            required
            selected={date}
            onChange={(d: Date | null) => setDate(d as Date)}
            dateFormat="MM/yyyy"
            showMonthYearPicker
          />
          <CompanyUserSelect
            isClearable
            label="Välj användare"
            placeholder="Användare"
            inline
            selectedCompanyUserId={companyUserId}
            onChange={setCompanyUserId}
          />
        </>
      }
    >
      {companyUserId ? (
        <>
          <div className={classes.reportList}>
            <Typography id="sammanstallning" component="h2" variant="h4" className={classes.label}>
              Sammanställning
            </Typography>
            <DataList ariaLabelledBy="sammanstallning">
              <Dt>Försäljning</Dt>
              <Dd>{formatPrice(stats?.orderTotal)}</Dd>
              <Dt>Antal ordrar</Dt>
              <Dd>{stats?.orderCount}</Dd>
              <Dt>Arbetade timmar</Dt>
              <Dd>{stats?.workedHours}</Dd>
              <Dt>Offertstock</Dt>
              <Dd>{formatPrice(stats?.offerTotal)}</Dd>
              <Dt>Antal offerter</Dt>
              <Dd>{stats?.offerCount}</Dd>
              <Dt>Ledighet (fristående timmar)</Dt>
              <Dd>{stats?.vacationHours}</Dd>
              <Dt>Ledighet dagar</Dt>
              <Dd>{stats?.vacationDays}</Dd>
            </DataList>
          </div>
          <Typography id="datumspecifikt" component="h2" variant="h4" className={classes.label}>
            Datumspecifikt
          </Typography>
          <Table aria-labelledby="datumspecifikt">
            <TableHead>
              <TableRow>
                <TableCell>Vecka/Datum</TableCell>
                <TableCell>Referens</TableCell>
                <TableCell>Kund</TableCell>
                <TableCell>Belopp</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {weeks?.map((week) => (
                <React.Fragment key={week.number}>
                  <TableRow>
                    <TableCell className={classes.week} colSpan={5}>
                      Vecka {week.number}
                    </TableCell>
                  </TableRow>
                  {week.dates.map((day) => (
                    <React.Fragment key={day.date.toString()}>
                      {day.red && (
                        <TableRow>
                          <TableCell style={{ color: day.red ? 'red' : undefined }}>{formatDate(day.date)}</TableCell>
                          <TableCell></TableCell>
                          <TableCell style={{ color: day.red ? 'red' : undefined }}>{day.holidayName}</TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      )}
                      {day.sales.map((sale, i) => (
                        <TableRow key={sale.saleId}>
                          <TableCell>{!day.red && i === 0 && formatDate(day.date)}</TableCell>
                          <TableCell>{sale.saleIdentifier}</TableCell>
                          <TableCell>
                            {sale.customerName} {sale.facilityName}
                          </TableCell>
                          <TableCell>{formatPrice(sale.amount)}</TableCell>
                          <TableCell>{SaleStatusLabel[sale.status]}</TableCell>
                        </TableRow>
                      ))}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </>
      ) : (
        <Typography align="center">Filtrera på användare, år och månad.</Typography>
      )}
    </Container>
  );
};

export default MonthlyReport;
