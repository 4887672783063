import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useGetTopSales } from '../../../../Modules/Errands/Components/Sale.api';

export const ScrollingText = () => {
  const { getTopSales, topSales } = useGetTopSales();
  const [width, setWidth] = useState(0);
  const anchorRef = useRef<HTMLUListElement>(null);

  const useStyles = makeStyles((theme: Theme) => ({
    '@global': {
      '@keyframes rightToLeft': {
        '0%': { transform: `translateX(100%)` },
        '100%': { transform: `translateX(-${width}px)` },
      },
    },
    scrollingTextAnimation: {
      '& > div': {
        animation: `$rightToLeft 20s linear infinite`,
        '&:hover': {
          animationPlayState: 'paused',
        },
      },
    },
    scrollingText: {
      overflow: 'hidden',
      position: 'relative',
      whiteSpace: 'nowrap',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      '& > div': {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      },
    },
    list: {
      listStyleType: 'none',
      display: 'flex',
      padding: 0,
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    getTopSales();
  }, [getTopSales]);

  const topSaleList = useMemo(
    () =>
      topSales?.map((x) => {
        const [firstName, lastName] = x.user.split(' ');
        return (
          <li style={{ padding: `0 0.5rem` }}>
            {`${firstName} ${lastName?.charAt(0) ?? ''} ${x.saleAmount
              .toString()
              .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')}.-`}
          </li>
        );
      }),
    [topSales],
  );

  useEffect(() => {
    if (anchorRef.current) {
      setWidth(anchorRef.current.clientWidth);
    }
  }, [topSales]);

  return (
    <div className={`${classes.scrollingText} ${classes.scrollingTextAnimation}`}>
      <div>
        <ul ref={anchorRef} className={classes.list}>
          {topSaleList}
        </ul>
      </div>
    </div>
  );
};
