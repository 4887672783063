import React, { useContext } from 'react';
import Button, { IButtonProps } from '../Button/Button';
import { IFileResult, openFile } from '../Api/response/IFileResult';
import InputLabel from '../InputFields/InputLabel/InputLabel';
import Skeleton from '@material-ui/lab/Skeleton';
import { ContainerContext } from '../Container/Container';
import useStyles from './DownloadButton.styles';
import Grid, { GridSize } from '@material-ui/core/Grid';
import { useMediaQuery, useTheme } from '@material-ui/core';

interface DownloadButton extends IButtonProps {
  onClick?: () => Promise<IFileResult>;
  label?: string;
  customWidth?: GridSize;
}

const DownloadButton = (props: DownloadButton) => {  
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));
  const { onClick, ...rest } = props;
  const containerContext = useContext(ContainerContext);

  const classes = useStyles();

  async function download() {
    if (onClick) {
      const file = await onClick();
      if (file) openFile(file, mobileView);
    }
  }

  return (
    <Grid lg={props.customWidth} md={props.customWidth} container item direction="column" alignItems="flex-start">
      {containerContext?.loading ? (
        <div className={classes.skeleton}>
          <Skeleton variant="text" animation="wave" className={classes.skeletonLabel} />
          <Skeleton variant="rect" animation="wave" className={classes.skeletonInput} />
        </div>
      ) : (
        <>
          <InputLabel label={props.label ? props.label : 'PDF'} />
          <Button
            color="primary"
            variant="text"
            {...rest}
            disableTouchRipple
            className={classes.button}
            onClick={download}
          ></Button>
        </>
      )}
    </Grid>
  );
};

export default DownloadButton;
