import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import {
  SaleStatusLabel,
  WarningStatus,
  WarningLabel,
  useGetSaleDeleteInfo,
  ISaleDeleteInfo,
} from '../../Components/Sale.api';
import useStyles from './ErrandWarningContainer.styles';

export interface IWarningContainer {
  id: number;
  warningStatus: WarningStatus;
}

const ErrandWarningContainer = (props: IWarningContainer) => {
  const { id, warningStatus } = props;
  const { getSaleDeleteInfo } = useGetSaleDeleteInfo();
  const [saleDeleteInfo, setSaleDeleteInfo] = useState<ISaleDeleteInfo | undefined>();
  const ref = useRef<boolean>(false);
  const classes = useStyles();

  var getSaleInfo = useCallback(async () => {
    if (id && (warningStatus === WarningStatus.offer || warningStatus === WarningStatus.order)) {
      var results = await getSaleDeleteInfo(id.toString());
      if (results && results !== saleDeleteInfo) {
        setSaleDeleteInfo(saleDeleteInfo);
      }
    }
  }, [id, saleDeleteInfo, warningStatus, getSaleDeleteInfo]);

  useEffect(() => {
    if (ref.current === false) {
      getSaleInfo();
      ref.current = true;
    }
  }, [getSaleInfo]);

  return (
    <>
      <Grid container item className={classes.parentGrid}>
        <Typography className={classes.ConfirmText}>{`Är du säker att du vill radera ${WarningLabel[warningStatus]} ${
          saleDeleteInfo?.salesNumber ?? id
        }?`}</Typography>
        <br />
        {!!(saleDeleteInfo?.status || saleDeleteInfo?.supplierOrderCount || saleDeleteInfo?.errorReportCount) && (
          <div>
            <Typography>{`Order ${saleDeleteInfo.salesNumber} har följande kopplingar:`}</Typography>
            <ul>
              {!!saleDeleteInfo.status && <li>{SaleStatusLabel[saleDeleteInfo.status]}</li>}
              {!!saleDeleteInfo.supplierOrderCount && saleDeleteInfo.supplierOrderCount > 0 && (
                <li>{`${saleDeleteInfo.supplierOrderCount} pågående beställning.`}</li>
              )}
              {!!saleDeleteInfo.errorReportCount && saleDeleteInfo.errorReportCount > 0 && (
                <li>{`${saleDeleteInfo.errorReportCount} pågående felrapport.`}</li>
              )}
            </ul>
          </div>
        )}
      </Grid>

      <Grid container item>
        Observera! Detta går inte att ändra senare.
      </Grid>
    </>
  );
};

export default ErrandWarningContainer;
