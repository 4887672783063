import React, { useCallback, useEffect, useState } from 'react';
import { InputField } from '../../../../../../../../../Shared/InputFields/InputField/InputField';
import { IDocumentFormProps } from '../../IDocumentFormProps';
import useInputState from '../../../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { isResultError } from '../../../../../../../../../Shared/Api/response/ICreateResult';
import { useCreateInspectionProtocolDocument, useGetInspectionProtocolDocumentForm } from '../../Documents.api';

const InspectionProtocol = (props: IDocumentFormProps) => {
  const { customerId, onLoadingChange, saveCallback, documentName, onDocumentCreated } = props;
  const { getInspectionProtocolDocumentForm, isLoading } = useGetInspectionProtocolDocumentForm(customerId);
  const { isLoading: isCreateLoading, createInspectionProtocolDocument } = useCreateInspectionProtocolDocument(
    customerId,
  );
  const [receiver, changeReceiver, setReceiver] = useInputState('');
  const [serialNumber, setSerialNumber] = useState<Number>();

  useEffect(() => {
    async function loadForm() {
      const form = await getInspectionProtocolDocumentForm();
      setSerialNumber(form.serialNumber);
      setReceiver(form.receiver);
    }
    loadForm();
  }, [getInspectionProtocolDocumentForm, setSerialNumber, setReceiver]);

  useEffect(() => {
    onLoadingChange(isLoading || isCreateLoading);
  }, [onLoadingChange, isLoading, isCreateLoading]);

  const save = useCallback(() => {
    async function innerSave() {
      const result = await createInspectionProtocolDocument({
        documentName: documentName,
        receiver: receiver ?? null,
      });
      if (!isResultError(result)) onDocumentCreated(Number(result as string));
    }
    innerSave();
  }, [createInspectionProtocolDocument, documentName, receiver, onDocumentCreated]);

  useEffect(() => {
    saveCallback(save);
  }, [saveCallback, save]);

  return (
    <>
      <InputField label="Mottagare" type="text" value={receiver} onChange={changeReceiver} />
      <InputField label="Löppnummer" viewOnly type="text" value={serialNumber} />
    </>
  );
};

export default InspectionProtocol;
