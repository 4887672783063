import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Container from '../../../../../../Shared/Container/Container';
import Datepicker from '../../../../../../Shared/InputFields/DatePicker/Datepicker';
import Button from '../../../../../../Shared/Button/Button';
import { AsyncSelect } from '../../../../../../Shared/Select';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import { isResultError } from '../../../../../../Shared/Api/response/ICreateResult';
import useFormValidation from '../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import useInputState from '../../../../../../Shared/Hooks/UseInputState/UseInputState';
import BMSNodeSelect from '../../../../BMS/Components/EditNode/BMSNodeSelect';
import {
  IMeetingNotesTemplate,
  IUpsertMeetingNotesValidationErrors,
  IUpsertMeetingNotesCommand,
  useSearchMeetingNotesTemplates,
  useGetMeetingNotes,
  useUpsertMeetingNotes,
} from '../MeetingNotes.api';
import { IGetCompanyUserMessagesResult, useDeleteCompanyMessage } from '../../../../Forum/Forum.api';
import RichTextEditor from '../../../../../../Shared/RichTextEditor/RichTextEditor';
import { Fieldset } from '../../../../../../Shared/Form/Fieldset/Fieldset';
import CompanyUserSelect from '../../../../Admin/Components/Users/UserSelect/CompanyUserSelect';
import useUserContext from '../../../../../../Core/Authentication/UserContext';

const MeetingNotesForm = () => {
  const history = useHistory();
  const { user } = useUserContext();
  const { id } = useParams<{ id: string }>();
  const { isFormValid, formRef } = useFormValidation();
  const { isLoading: isSearchLoading, searchTemplates } = useSearchMeetingNotesTemplates();
  const { isLoading: isGetLoading, meetingNotes, getMeetingNotes } = useGetMeetingNotes(id);
  const { isLoading: isUpsertLoading, upsert } = useUpsertMeetingNotes();
  const { deleteCompanyMessage: deleteCompanyUserMessage } = useDeleteCompanyMessage();
  const [responsiblePersonId, setResponsiblePersonId] = useState<number | undefined>(user.id);
  const [meetingDate, setMeetingDate] = useState(new Date());
  const [title, changeTitle, setTitle] = useInputState('');
  const [description, setDescription] = useState('');
  const [documentId, setDocumentId] = useState<number>();
  const [templates, setTemplates] = useState<IMeetingNotesTemplate[]>([]);
  const [errors, setErrors] = useState<IUpsertMeetingNotesValidationErrors>();
  const location = useLocation<IGetCompanyUserMessagesResult | undefined>();
  const userIds = useMemo(() => (responsiblePersonId ? [responsiblePersonId] : undefined), [responsiblePersonId]);

  const loadTemplates = useCallback(
    async (query?: string) => {
      const response = await searchTemplates({ query: query });
      setTemplates(response);
      return response.map((x) => ({ value: x.id.toString(), label: x.name }));
    },
    [searchTemplates],
  );

  useEffect(() => {
    async function loadMeeting() {
      const response = await getMeetingNotes();
      if (!response) history.push('/todos/meetingnotes');
      setResponsiblePersonId(response.responsiblePersonId);
      setMeetingDate(new Date(response.date));
      setTitle(response.title);
      setDescription(response.description);
      setDocumentId(response.documentId);
    }

    if (id) {
      loadMeeting();
    }

    if (location.state?.description) {
      setTitle(location.state.description);
      setDescription(location.state.description);
    }
  }, [id, history, getMeetingNotes, setTitle, setDescription, location.state]);

  const goBackToView = (toArchive?: boolean) => {
    if (toArchive) history.push(`/todos/meetings/archive/${id}`);
    else if (id) history.push(`/todos/meetingnotes/${id}`);
    else history.push('/todos/meetingnotes');
  };

  async function handleUpsert() {
    if (!meetingDate) return;
    const upsertCommand: IUpsertMeetingNotesCommand = {
      responsiblePersonId: responsiblePersonId,
      date: meetingDate,
      title: title,
      description: description,
      document: documentId!,
    };

    const result = await upsert(upsertCommand, id);

    if (isResultError(result)) {
      setErrors(result);
    } else {
      if (location.state?.description && location.state?.id) {
        await deleteCompanyUserMessage(location.state.id.toString());
        location.state = undefined;
      }
      goBackToView();
    }
  }

  function Actions() {
    return (
      <>
        <Button color="default" variant="outlined" onClick={() => goBackToView(meetingNotes?.inArchive)}>
          Avbryt
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!isFormValid || !description}
          loading={isUpsertLoading}
          onClick={handleUpsert}
        >
          {id ? 'Spara' : 'Skapa'}
        </Button>
      </>
    );
  }

  return (
    <Container
      form
      ref={formRef}
      label={id ? 'Redigera mötesfråga' : 'Skapa mötesfråga'}
      actions={<Actions />}
      loading={isGetLoading}
    >
      <Datepicker
        label="Datum"
        required
        onChange={(d: Date | null) => setMeetingDate(d as Date)}
        selected={meetingDate}
        errorText={errors?.date}
      />
      <CompanyUserSelect
        label={'Ansvarig'}
        isClearable={true}
        onChange={(id) => setResponsiblePersonId(id ? parseInt(id) : undefined)}
        selectedCompanyUserId={responsiblePersonId?.toString()}
        companyUserIds={userIds}
      />
      <BMSNodeSelect
        label="VLS-referens"
        selectedNodeId={documentId ?? undefined}
        onChange={(id) => setDocumentId(id ? Number(id) : undefined)}
      />
      {!id && (
        <AsyncSelect
          label="Hämta mall"
          isClearable
          isLoading={isSearchLoading}
          loadOptions={loadTemplates}
          onChange={(value) => {
            if (value) {
              var template = templates.find((x) => x.id.toString() === value.value);
              if (template) {
                setDescription(template.content);
                setTitle(template.name);
              }
            } else {
              setDescription('');
              setTitle('');
            }
          }}
        />
      )}
      <InputField
        label="Rubrik"
        fullwidth
        required
        type="text"
        value={title}
        onChange={changeTitle}
        errorText={errors?.title}
      />
      <Fieldset legend="Text*">
        <RichTextEditor menuBar={false} value={description} onChange={setDescription} />
      </Fieldset>
    </Container>
  );
};

export default MeetingNotesForm;
