import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.grey[300]}`,
    minWidth: `${theme.breakpoints.values.xs}rem`,
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    },
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  allRoundCorners: {
    position: 'relative',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  noPadding: {
    border: 0,
    '& > div > div': {
      padding: 0,
    }
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: '0.5rem 0.5rem',
      flexBasis: 'auto',
      [theme.breakpoints.up('sm')]: {
        minWidth: '18rem',
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: '94%',
      },
    },
  },
  statisticFilters: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: '0.5rem 0.5rem !important',
      flexBasis: 'auto !important',
      [theme.breakpoints.up('sm')]: {
        minWidth: '13rem !important',
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: '94%',
      },
    },
  },
  customFilters: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: '0.5rem 0.5rem',
      flexBasis: 'auto !important',
      [theme.breakpoints.up('sm')]: {
        minWidth: '14rem !important',
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: '10rem !important',
      },
    },
  },
  children: {
    padding: '1rem',
    '& div[id^=__lpform_]': {
      display: 'none',
    },
  },
  actions: {
    '&:not(:empty)': {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      display: 'flex',
      justifyContent: 'left',
      flexWrap: 'wrap',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      '& > *': {
        margin: '0.5rem 0.5rem',
      },
    },
  },
  mobileActions: {
    '&:not(:empty)': {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        margin: '0.5rem 0.5rem',
      },
    },
  },
  customActionHeader: {
    '&:not(:empty)': {
      marginLeft: 'auto',
      display: 'flex',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',

      paddingRight: '0.5rem',
      '& > *': {
        margin: '0.5rem 0.5rem',
      },
    },
  },
  customIconHeader: {
    position: 'absolute',
    left: '8.5rem',
    top: '.25rem',
    '& .MuiIconButton-root': {
      color: `${theme.palette.primary.contrastText}`,
      padding: '7px'
    }
  },
  CustomContainer: {
    marginLeft: '10px',
    flexWrap: 'wrap',
    display: 'flex',
  },
}));
