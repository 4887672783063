import ValidationErrorResultType from '../../../../../Shared/Api/response/ValidationErrorResultType';
import { useApiPost, useApiPut, useApiGet, useApiDelete } from '../../../../../Core/Api/Api';
import { ICreateResult } from '../../../../../Shared/Api/response/ICreateResult';
import IPaginatedResponse from '../../../../../Shared/Api/response/IPaginatedResponse';
import IPaginatedRequest from '../../../../../Shared/Api/request/IPaginatedRequest';
import { IFileResult } from '../../../../../Shared/Api/response/IFileResult';
import { useCallback } from 'react';

export interface IUpsertErrorReportCommand {
  saleId: number;
  date: Date;
  customerReferences: string;
  description: string;
}

export interface IUpsertErrorReportValidationErrors extends ValidationErrorResultType<IUpsertErrorReportCommand> {}

export function useUpsertErrorReport() {
  const { isLoading: isCreateLoading, post } = useApiPost<
    ICreateResult<IUpsertErrorReportValidationErrors>,
    IUpsertErrorReportCommand
  >(`/api/error-reports/`);

  const { isLoading: isUpdateLoading, put } = useApiPut<IUpsertErrorReportValidationErrors, IUpsertErrorReportCommand>(
    `/api/error-reports/`,
  );

  async function upsert(command: IUpsertErrorReportCommand, id?: string) {
    if (id) {
      const result = await put(command, id);

      return result ? result : id;
    }

    return post(command);
  }

  return {
    isLoading: isCreateLoading || isUpdateLoading,
    upsert: upsert,
  };
}

export function useAddInvestigationtResult() {
  const { isLoading, put } = useApiPut<IAddInvestigationtResultValidationErrors, IAddInvestigationtResult>(
    `/api/error-reports/`,
  );

  async function addInvestigationtResult(command: IAddInvestigationtResult, id: string) {
    return await put(command, id + '/result');
  }

  return {
    isLoading,
    addInvestigationtResult,
  };
}

export function useSetMeasureAnalysisPersonFault(id: string | undefined) {
  const { isLoading, put } = useApiPut<
    ISetMeasureAnalysisPersonFaultCommandValidationErrors,
    ISetMeasureAnalysisPersonFaultCommand
  >(`/api/error-reports/${id}/AnalysisPersonFault`);

  return {
    isLoading,
    setMeasureAnalysisPersonFault: put,
  };
}

export function useSetMeasureAnalysisOther(id: string | undefined) {
  const { isLoading, put } = useApiPut<
    ISetMeasureAnalysisOtherCommandValidationErrors,
    ISetMeasureAnalysisOtherCommand
  >(`/api/error-reports/${id}/AnalysisOther`);

  return {
    isLoading,
    setMeasureAnalysisOther: put,
  };
}

export function useSetMeasureAnalysisProductFault(id: string | undefined) {
  const { isLoading, put } = useApiPut<
    ISetMeasureAnalysisProductFaultCommandValidationErrors,
    ISetMeasureAnalysisProductFaultCommand
  >(`/api/error-reports/${id}/AnalysisProductFault`);

  return {
    isLoading,
    setMeasureAnalysisProductFault: put,
  };
}

export function useSetPostMeasureAnalysis(id: string | undefined) {
  const { isLoading, put } = useApiPut<ISetPostMeasureAnalysisCommandValidationErrors, ISetPostMeasureAnalysisCommand>(
    `/api/error-reports/${id}/PostMeasureAnalysis`,
  );

  return {
    isLoading,
    setPostMeasureAnalysis: put,
  };
}

export function useGetProductFaultForm(id: string | undefined) {
  const { isLoading, response, get } = useApiGet<IProductFaultFormDto>(
    `/api/error-reports/${id}/ProductFaultForm`,
    false,
  );

  return {
    isLoading,
    productFaultForm: response,
    getProductFaultForm: get,
  };
}

export function useGetErrorReport() {
  const { isLoading, response, get } = useApiGet<IErrorReport>('/api/error-reports/', false);

  return {
    isLoading,
    errorReport: response,
    getErrorReport: get,
  };
}

export interface ISetMeasureAnalysisProductFaultCommandValidationErrors
  extends ValidationErrorResultType<ISetMeasureAnalysisProductFaultCommand> {}

export interface ISetMeasureAnalysisProductFaultCommand {
  products: IAddFaultyProductDto[];
  complaintMessage: string;
  complaintAction: ComplaintAction;
}

export interface ISetPostMeasureAnalysisCommandValidationErrors
  extends ValidationErrorResultType<ISetPostMeasureAnalysisCommand> {}

export interface ISetPostMeasureAnalysisCommand {
  measureEffectAnalysis: string;
  processNumber: string | undefined;
  measureStatus: MeasureStatus;
}

export enum MeasureStatus {
  Implemented = 1,
  ImplementationNotNecessary = 2,
}

export interface IAddFaultyProductDto {
  productId: number;
  quantity: number;
}

export enum ComplaintAction {
  NewProducts = 1,
  Credit = 2,
}

export interface IErrorReport {
  id: number;
  saleId: number;
  customerId: number;
  customerName: string;
  customerReferences: string;
  date: Date;
  description: string;
  responsiblePerson: string | undefined;

  errorReportState: ErrorReportState | undefined;
  investigationResult: string | undefined;
  errorMeasure: string | undefined;
  invoiceCustomer: boolean | undefined;
  errorReportCategoryId: number | undefined;
  errorReportCategoryDescription: string | undefined;
  errorCategoryType: ErrorCategoryType | null;

  causeAnalysis: string | undefined;
  correctiveAction: string | undefined;

  analysisStatus: string | undefined;

  complaintMessage: string | undefined;
  complaintAction: ComplaintAction | undefined;
  faultyProducts: IFaultyProductDto[];

  measureEffectAnalysis: string | undefined;
  processNumber: string | undefined;
  measureStatus: MeasureStatus | undefined;

  serviceSaleId: number | undefined;
  serviceSaleIdentifier: string | undefined;
}

export interface IFaultyProductDto {
  productId: number;
  quantity: number;
}

export interface IAddInvestigationtResultValidationErrors extends ValidationErrorResultType<IAddInvestigationtResult> {}

export interface IAddInvestigationtResult {
  investigationResult: string;
  errorMeasure: string;
  errorReportCategoryId: number;
  invoiceCustomer: boolean;
}

export interface ISetMeasureAnalysisOtherCommandValidationErrors
  extends ValidationErrorResultType<ISetMeasureAnalysisOtherCommand> {}

export interface ISetMeasureAnalysisOtherCommand {
  causeAnalysis: string;
  correctiveAction: string;
}

export interface ISetMeasureAnalysisPersonFaultCommandValidationErrors
  extends ValidationErrorResultType<ISetMeasureAnalysisPersonFaultCommand> {}

export interface ISetMeasureAnalysisPersonFaultCommand {
  analysisStatus: string;
}

export interface IProductFaultFormDto {
  products: ISaleProductDto[];
}
export interface ISaleProductDto {
  productId: number;
  productNumber: string;
  quantity: number;
  productName: string;
  supplierName: string;
}

export enum ErrorCategory {
  ExternalSource = 1,
  ComponentFault = 2,
  Sales = 3,
  Installation = 4,
  Suggestion = 5,
  Process = 6,
  Environment = 7,
}

export interface IErrorReportListItem {
  id: number;
  saleIdentifier: string;
  customerId: number;
  customerName: string;
  responsiblePerson: string;
  registeredBy: string;
  errorReportState: ErrorReportState;
  created: Date;
  categoryType: ErrorCategoryType;
  categoryDescription: string;
}

export interface IGetErrorReportsRequest extends IPaginatedRequest {
  registeredBy?: number;
  errorReportState?: ErrorReportState | null;
  createdFrom?: Date | null;
  createdTo?: Date | null;
  onlyMine?: boolean;
  categoryType?: ErrorCategoryType;
}

export function useGetErrorReports() {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<IErrorReportListItem>, IGetErrorReportsRequest>(
    '/api/error-reports/list',
  );

  return {
    isLoading,
    errorReports: response,
    getErrorReports: post,
  };
}

export function useGetErrorReportCategories() {
  const { isLoading, response, get } = useApiGet<IErrorReportCategoryDto[]>('/api/error-reports/categories');

  return {
    isLoading,
    errorReportCategories: response,
    getErrorReportCategories: get,
  };
}

export function useGetErrorReportPdf(id: string | undefined) {
  const { isLoading, response, get } = useApiGet<IFileResult>(`/api/error-reports/${id}/pdf`, false);

  return {
    isLoading,
    pdf: response,
    getPdf: get,
  };
}

export interface ICreateServiceSaleCommand {
  jobDescription: string;
  saleDescription: string;
  contactName: string;
  contactPhone: string;
}

export interface ICreateServiceSaleCommandValidationErrors
  extends ValidationErrorResultType<ICreateServiceSaleCommand> {}

export function useCreateServiceSale(id: string | undefined, useCachedToken: boolean) {
  const { isLoading, post } = useApiPost<ICreateServiceSaleCommandValidationErrors, ICreateServiceSaleCommand>(
    `/api/error-reports/${id}/service`,
    useCachedToken,
  );

  return {
    isLoading,
    createServiceSale: post,
  };
}

export interface IErrorReportCategoryDto {
  id: number;
  description: string;
  errorCategoryType: ErrorCategoryType;
}

export enum ErrorCategoryType {
  ProductFault = 1,
  Sales = 2,
  Installation = 3,
  Other = 4,
}

export const ErrorReportCategories = [
  { type: ErrorCategoryType.ProductFault, name: 'Produktfel' },
  { type: ErrorCategoryType.Sales, name: 'Försäljning' },
  { type: ErrorCategoryType.Installation, name: 'Installation' },
  { type: ErrorCategoryType.Other, name: 'Övrigt' },
];

export enum ErrorReportState {
  Created = 1,
  Investigated = 2,
  Analysed = 3,
  Completed = 4,
}

export const ErrorReportStates = [
  { type: ErrorReportState.Created, name: 'Skapad' },
  { type: ErrorReportState.Investigated, name: 'Undersökt' },
  { type: ErrorReportState.Analysed, name: 'Analyserad' },
  { type: ErrorReportState.Completed, name: 'Arkiverad' },
];

export function useDeleteErrorReport() {
  const { isLoading, delete: deleteRequest } = useApiDelete(`/api/error-reports/`);

  const deleteErrorReport = useCallback(
    async (errorReportId: string) => {
      return await deleteRequest(undefined, errorReportId);
    },
    [deleteRequest],
  );

  return {
    isLoading,
    deleteErrorReport,
  };
}
