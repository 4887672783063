import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  red: {
    color: theme.palette.error.main,
  },
  yellow: {
    color: theme.palette.warning.dark,
  },
  green: {
    color: theme.palette.success.dark,
  },
  endDate: {
    fontWeight: 700,
  },
  font: {
    fontWeight: 700,
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  pointerCursor: {
    cursor: 'pointer',
  },
  narrowColumn: {
    width: '15px',
  },
}));
