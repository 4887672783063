import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../../Styles/Theme';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.5rem 1rem',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  content: {
    flexGrow: 1,
    maxWidth: 'calc(50% - 0.5rem)',
    paddingBottom: '0.5rem',
    textAlign: 'right',
    '& > a': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  column: {
    flexDirection: 'column',
  },
});
