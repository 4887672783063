import React, { useState, useEffect, useCallback } from 'react';
import { Table } from '../../../../../../../Shared/Table/Table';
import SearchInput from '../../../../../../../Shared/InputFields/SearchInput/SearchInput';
import {
  MenuItem,
  ListItemIcon,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Select, { ISelectOption } from '../../../../../../../Shared/Select/Select';
import Datepicker from '../../../../../../../Shared/InputFields/DatePicker/Datepicker';
import AccessGuard from '../../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { ModuleIdentifiers, AccessRights } from '../../../../../../../Core/Authentication/ModuleAccess';
import { DropdownCell } from '../../../../../../../Shared/Table/DropdownCell/DropdownCell';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import { useHistory } from 'react-router-dom';
import { MobileTableRow } from '../../../../../../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import { MobileTable } from '../../../../../../../Shared/Table/MobileTable/MobileTable';
import CompanyUserSelect from '../../../../../Admin/Components/Users/UserSelect/CompanyUserSelect';
import {
  useGetErrorReports,
  ErrorReportStates,
  ErrorReportState,
  useDeleteErrorReport,
  IErrorReportListItem,
  ErrorReportCategories,
  ErrorCategoryType,
} from '../../Reports.api';
import useTable from '../../../../../../../Shared/Table/useTable';
import TablePaginationWithDatasource from '../../../../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import formatDate from '../../../../../../../Shared/Formatting/formatDate';
import SortButton from '../../../../../../../Shared/Table/SortButton/SortButton';
import { useStyles } from '../../../../../../../Shared/Table/TableRowHeader/TableRowHeader.styles';
import IconButton from '../../../../../../../Shared/IconButton/IconButton';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { ConfirmDeleteOrArchiveRow } from '../../../../../../../Shared/Table/ConfirmDeleteOrArchiveRow/ConfirmDeleteOrArchiveRow';

interface IProps {
  onlyMine?: boolean;
}

const statusOptions: ISelectOption[] = ErrorReportStates.map((x) => ({
  value: (x.type as number).toString(),
  label: x.name,
})).sort((a, b) => (a.label > b.label ? 1 : -1));

const categoryOptions: ISelectOption[] = ErrorReportCategories.map((x) => ({
  value: (x.type as number).toString(),
  label: x.name,
})).sort((a, b) => (a.label > b.label ? 1 : -1));

const ReportList = (props: IProps) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { isLoading, errorReports, getErrorReports } = useGetErrorReports();
  const { isLoading: isLoadingDeleteReport, deleteErrorReport } = useDeleteErrorReport();
  const {
    toggleSort,
    sortProperty,
    sortDirection,
    pageSize,
    setPageSize,
    setPageNumber,
    setQuery,
    paginatedRequest,
  } = useTable();
  const [emptyMessage, setEmptyMessage] = useState('');
  const [registeredBy, setRegisteredBy] = useState<string>();
  const [createdFrom, setCreatedFrom] = useState<Date | null>(null);
  const [createdTo, setCreatedTo] = useState<Date | null>(null);
  const [errorReportState, setErrorReportState] = useState<ErrorReportState | null>();
  const [errorReportCategory, setErrorReportCategory] = useState<ErrorCategoryType | null>();
  const [confirmCancelErrorReportId, setConfirmCancelErrorReportId] = useState<number | undefined>();

  const history = useHistory();
  const classes = useStyles();

  const getList = useCallback(async () => {
    const response = await getErrorReports({
      ...paginatedRequest,
      registeredBy: registeredBy ? Number(registeredBy) : undefined,
      createdFrom,
      createdTo,
      errorReportState,
      onlyMine: props.onlyMine,
      categoryType: errorReportCategory ? errorReportCategory : undefined,
    });

    if (response?.totalCount <= 0) {
      if (paginatedRequest.query) {
        setEmptyMessage('Filtreringen gav inga resultat');
      } else {
        setEmptyMessage('Inga felrapporter tillagda');
      }
    }
  }, [paginatedRequest, getErrorReports, registeredBy, createdFrom, createdTo, errorReportState, props.onlyMine, errorReportCategory]);

  useEffect(() => {
    getList();
  }, [getList]);

  async function handleDeleteReport() {
    if (!confirmCancelErrorReportId) return;

    await deleteErrorReport(confirmCancelErrorReportId.toString());
    setConfirmCancelErrorReportId(undefined);
    await getList();
  }

  const handleTableClick = (errorReport: IErrorReportListItem) => {
    history.push(`${history.location.pathname}/${errorReport.id}/update`);
  };

  return (
    <Table
      label={`Felrapporter (${errorReports?.totalCount ?? 0})`}
      customSize={{
        md: 12,
        lg: 8,
      }}
      filters={
        <>
          <SearchInput type="search" placeholder="Sök" onChange={setQuery} />
          <Select
            inputFieldProps={{ 'aria-label': 'Status', placeholder: 'Status' }}
            selectProps={{
              options: statusOptions,
              isClearable: true,
              onChange: (x) => setErrorReportState(x && (Number((x as ISelectOption).value) as ErrorReportState)),
            }}
          />
          <Select
            inputFieldProps={{ 'aria-label': 'Category', placeholder: 'Kategori' }}
            selectProps={{
              options: categoryOptions,
              isClearable: true,
              onChange: (x) => setErrorReportCategory(x && (Number((x as ISelectOption).value) as ErrorCategoryType)),
            }}
          />
          {!props.onlyMine && (
            <CompanyUserSelect
              isClearable
              placeholder="Registrerad av"
              selectedCompanyUserId={registeredBy}
              onChange={setRegisteredBy}
            />
          )}
          <Datepicker
            isClearable
            inFilter
            placeholderText="Skapad fr.o.m"
            selected={createdFrom}
            onChange={setCreatedFrom}
          />
          <Datepicker
            isClearable
            inFilter
            placeholderText="Skapad t.o.m"
            selected={createdTo}
            onChange={setCreatedTo}
          />
        </>
      }
      iconButtons={
        !props.onlyMine && (
          <AccessGuard module={ModuleIdentifiers.ErrorReport} accessRights={AccessRights.Write}>
            <IconButton
              size="small"
              aria-label="Lägg till rapport"
              onClick={() => {
                history.push(`/errands/reports/create`);
              }}
            >
              <AddIcon />
            </IconButton>
          </AccessGuard>
        )
      }
      pagination={
        errorReports && (
          <TablePaginationWithDatasource
            datasource={errorReports}
            onChangePage={setPageNumber}
            onChangeRowsPerPage={setPageSize}
          />
        )
      }
      empty={{
        empty: errorReports && errorReports.totalCount <= 0 ? true : false,
        message: emptyMessage,
      }}
      loading={{
        loading: isLoading,
        skeletonRows: pageSize,
      }}
      mobile={mobileView}
    >
      {mobileView ? (
        <>
          {errorReports?.items.map((errorReport) => (
            <MobileTable
              label={`${formatDate(errorReport.created)} - ${errorReport.customerName}`}
              key={errorReport.id}
              link={`${history.location.pathname}/${errorReport.id}/update`}
              action={
                <AccessGuard
                  module={ModuleIdentifiers.ErrorReport}
                  accessRights={AccessRights.Full}
                  fallback={<TableCell className={classes.emptyColumn}></TableCell>}
                >
                  <DropdownCell mobile={mobileView}>
                    <MenuItem disabled>
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">Ta bort</Typography>
                    </MenuItem>
                  </DropdownCell>
                </AccessGuard>
              }
            >
              <MobileTableRow label="Id" value={errorReport.id} />
              <MobileTableRow label="Order" value={errorReport.saleIdentifier} />
              <MobileTableRow label="Reg. datum" value={formatDate(errorReport.created)} />
              <MobileTableRow label="Registrerad av" value={errorReport.registeredBy} />
              <MobileTableRow label="Ansvarig" value={errorReport.responsiblePerson} />
              <MobileTableRow label="Kategori" value={errorReport.categoryType} />
              <MobileTableRow label="Beskrivning" value={errorReport.categoryDescription} />
              <MobileTableRow
                label="Status"
                value={ErrorReportStates.find((x) => x.type === errorReport.errorReportState)?.name}
              />
            </MobileTable>
          ))}
        </>
      ) : (
        <>
          <TableHead>
            <TableRow>
              <TableCell>
                <span className={'sr-only'}>Knappar</span>
              </TableCell>
              <TableCell>
                Id
                <SortButton
                  property="id"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Kund
                <SortButton
                  property="customerName"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Order
                <SortButton
                  property="customerName"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Reg. datum
                <SortButton
                  property="registeredBy"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Registrerad av
                <SortButton
                  property="created"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Ansvarig
                <SortButton
                  property="responsiblePerson"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Kategori
                <SortButton
                  property="categoryType"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Status
                <SortButton
                  property="errorReportState"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {errorReports?.items.map((errorReport) => (
              <TableRow className={classes.pointerCursor} hover tabIndex={-1} key={errorReport.id}>
                {confirmCancelErrorReportId !== errorReport.id ? (
                  <>
                    <AccessGuard
                      module={ModuleIdentifiers.ErrorReport}
                      accessRights={AccessRights.Full}
                      fallback={<TableCell></TableCell>}
                    >
                      <DropdownCell>
                        <MenuItem onClick={() => setConfirmCancelErrorReportId(errorReport.id)}>
                          <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                          <Typography variant="inherit">Ta bort</Typography>
                        </MenuItem>
                      </DropdownCell>
                    </AccessGuard>
                    <TableCell className={classes.linkFont} onClick={() => handleTableClick(errorReport)}>
                      {errorReport.id}
                    </TableCell>
                    <TableCell onClick={() => handleTableClick(errorReport)}>{errorReport.customerName}</TableCell>
                    <TableCell onClick={() => handleTableClick(errorReport)}>{errorReport.saleIdentifier}</TableCell>
                    <TableCell onClick={() => handleTableClick(errorReport)}>
                      {formatDate(errorReport.created)}
                    </TableCell>
                    <TableCell onClick={() => handleTableClick(errorReport)}>{errorReport.registeredBy}</TableCell>
                    <TableCell onClick={() => handleTableClick(errorReport)}>{errorReport.responsiblePerson}</TableCell>
                    <TableCell onClick={() => handleTableClick(errorReport)}>{errorReport.categoryDescription}</TableCell>
                    <TableCell onClick={() => handleTableClick(errorReport)}>
                      {ErrorReportStates.find((x) => x.type === errorReport.errorReportState)?.name}
                    </TableCell>
                  </>
                ) : (
                  <ConfirmDeleteOrArchiveRow
                    onConfirm={handleDeleteReport}
                    onCancel={() => {
                      setConfirmCancelErrorReportId(undefined);
                    }}
                    colspan={8}
                    label={`felrapport med felrapport nr ${errorReport.saleIdentifier}`}
                    loading={isLoadingDeleteReport}
                  />
                )}
              </TableRow>
            ))}
          </TableBody>
        </>
      )}
    </Table>
  );
};

export default ReportList;
