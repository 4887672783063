import { ISelectOption } from './Select';

export const selectAllOption = { label: 'Alla', value: '0' };
export const selectAllList = [selectAllOption];
export const emptyList: ISelectOption[] = [];

export function areAllSelected(nrOfOptions?: number, options?: ISelectOption[], optionIds?: string[]) {
  if (!nrOfOptions) return false;
  if (options) {
    if (options.some((x) => x === selectAllOption)) return true;
    return nrOfOptions === options.length + 1;
  }
  if (optionIds) {
    if (optionIds.some((x) => x === '0')) return true;
    return nrOfOptions === optionIds.length + 1;
  }
  return false;
}
