import { useApiDelete, useApiGet } from '../../../../../../Core/Api/Api';

export function useGetFacilities(customerId: string | undefined) {
  const { isLoading, response, get } = useApiGet<IFacilityListItemDto[]>(
    `/api/customer-portal/${customerId}/facilities/list`,
  );

  return {
    isLoading,
    facilities: response,
    getFacilities: get,
  };
}

export interface IFacilityListItemDto {
  id: number;
  name: string;
  address: string;
  postalCode: string;
  city: string;
}

export function useGetFacility(customerId: string | undefined) {
  const { isLoading, response, get } = useApiGet<IFacilityDto>(`/api/customer-portal/${customerId}/facilities/`, false);

  return {
    isLoading,
    facility: response,
    getFacility: get,
  };
}

export interface IFacilityDto {
  id: number;
  name: string;
  address: string;
  postalCode: string;
  city: string;
  coreSystems: ICoreSystemListItemDto[];
}

export interface ICoreSystemListItemDto {
  id: number;
  systemType: string;
  description: string;
  emergencyCallCentre: string;
}

export function useDeleteFacility(customerId: number) {
  const { isLoading, delete: deletePrior } = useApiDelete<IFacilityDto>(
    `/api/customer-portal/${customerId}/facilities/`,
  );

  const deleteFacility = async (facilityId: number) => {
    await deletePrior(undefined, facilityId.toString());
  };

  return {
    isLoading,
    deleteFacility: deleteFacility,
  };
}
