import { makeStyles, Theme } from '@material-ui/core';
import { calendarColors } from '../../../../../Styles/Theme';

export const useStyles = makeStyles((theme: Theme) => ({
  eventTitleContainer: {
    width: '100%',
  },
  eventTimeCompanyContainer: {
    width: '100%',
    textAlign: 'left',
  },
  eventTitle: {
    marginRight: '0.25rem',
    fontWeight: 600,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    justifyContent: 'center',
    fontSize: theme.typography.body1.fontSize
  },
  dash: {
    margin: '0 0.25rem',
  },
  '& td > span': {
  },
  blue: {
    backgroundColor: calendarColors.blue.background,
    borderColor: calendarColors.blue.background,
    color: calendarColors.blue.text,
    padding: '2px 6px',
    borderRadius: '6px'
  },
  green: {
    backgroundColor: calendarColors.green.background,
    borderColor: calendarColors.green.background,
    color: calendarColors.green.text,
    padding: '2px 6px',
    borderRadius: '6px'
  },
  purple: {
    backgroundColor: calendarColors.purple.background,
    borderColor: calendarColors.purple.background,
    color: calendarColors.purple.text,
    padding: '2px 6px',
    borderRadius: '6px'
  },
  yellow: {
    backgroundColor: calendarColors.yellow.background,
    borderColor: calendarColors.yellow.background,
    color: calendarColors.yellow.text,
    padding: '2px 6px',
    borderRadius: '6px'
  },
  red: {
    backgroundColor: calendarColors.red.background,
    borderColor: calendarColors.red.background,
    color: calendarColors.red.text,
    padding: '2px 6px',
    borderRadius: '6px'
  }, 
  gray: {
    backgroundColor: calendarColors.gray.background,
    borderColor: calendarColors.gray.background,
    color: calendarColors.gray.text,
    padding: '2px 6px',
    borderRadius: '6px'
  },
}));
