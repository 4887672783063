import React, { useState, useEffect, useMemo, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import { Fieldset } from '../../../../../../../Shared/Form/Fieldset/Fieldset';
import Container from '../../../../../../../Shared/Container/Container';
import Select, { ISelectOption } from '../../../../../../../Shared/Select/Select';
import WizardFormActions from '../../../../../../../Shared/Form/WizardFormActions/WizardFormActions';
import { InputField } from '../../../../../../../Shared/InputFields/InputField/InputField';
import InlineButton from '../../../../../../../Shared/Button/InlineButton';
import EmptyGridSpace from '../../../../../../../Shared/EmptyGridSpace/EmptyGridSpace';
import useInputState from '../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import PostalCodeField from '../../../../../../../Shared/InputFields/PostalCodeField/PostalCodeField';
import useFormValidation from '../../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import { isResultError } from '../../../../../../../Shared/Api/response/ICreateResult';
import { SaleType } from '../../../../../../Shared/SaleType';
import SystemTypeSelect from '../../../../../Admin/Components/SystemTypes/SystemTypeSelect/SystemTypeSelect';
import { IRegulation, useGetSystemType } from '../../../../../Admin/Components/SystemTypes/SystemTypes.api';
import { ICoreSystem, useGetFacility } from '../../../../../Customers/Components/Facilities/Facilities.api';
import FacilitySelect from '../../../../../Customers/Components/Facilities/FacilitySelect/FacilitySelect';
import OfferFormContext from '../OfferFormContext';
import SelectProducts from './SelectProducts/SelectProducts';
import {
  getPreviousPage,
  getNextPage,
  IOfferProduct,
  IUpdateOfferFacilityAndSystemCommand,
  useGetOfferFacilityAndSystem,
  useUpdateOfferFacilityAndSystem,
  useGetOfferCustomer,
} from '../../Offer.api';
import CompanyUserSelect from '../../../../../Admin/Components/Users/UserSelect/CompanyUserSelect';
import useUserContext from '../../../../../../../Core/Authentication/UserContext';

const SelectFacilityAndSystem: React.FunctionComponent = () => {
  const { user } = useUserContext();
  const { id: offerId } = useParams<{ id: string }>();
  const { offerStep, setOfferStep, customerId, offerType, identifier } = useContext(OfferFormContext);
  const { isFormValid, formRef } = useFormValidation();

  const { get: getFacilityAndSystem, isLoading: isFacilityAndSystemLoading } = useGetOfferFacilityAndSystem(offerId);
  const { get: getOfferCustomer } = useGetOfferCustomer();
  const { getFacility, isLoading: isGetFacilityLoading } = useGetFacility(customerId?.toString());
  const { getSystemType, isLoading: isSystemTypeLoading } = useGetSystemType();
  const {
    put: updateOfferFacilityAndSystem,
    isLoading: isUpdateOfferFacilityAndSystemLoading,
  } = useUpdateOfferFacilityAndSystem(offerId);

  const [facilityId, setFacilityId] = useState<string | undefined>();
  const [facilityName, changeFacilityName, setFacilityName] = useInputState<string | undefined>('');
  const [facilityAddress, changeFacilityAdress, setFacilityAddress] = useInputState<string | undefined>('');
  const [facilityPostalCode, changeFacilityPostalCode, setFacilityPostalCode] = useInputState<string | undefined>('');
  const [facilityCity, changeFacilityCity, setFacilityCity] = useInputState<string | undefined>('');

  const [projectorId, changeProjectorId, setProjectorId] = useInputState<string>(user.selectedUserId?.toString() ?? '0');

  const [coreSystems, setCoreSystems] = useState<ICoreSystem[]>();
  const [coreSystemId, setCoreSystemId] = useState<string | undefined>();
  const [systemTypeId, setSystemTypeId] = useState<string | undefined>();
  const [regulationOptions, setRegulationOptions] = useState<ISelectOption[]>();
  const [regulationId, setRegulationId] = useState<string | undefined>();
  const [regulations, setRegulations] = useState<IRegulation[]>();

  const [mainProductId, setMainProductId] = useState<string | undefined>();
  const [products, setProducts] = useState<IOfferProduct[]>([]);

  const isTypeSystem = offerType === SaleType.system;
  const hasSystem =
    (coreSystemId && coreSystemId !== '0') ||
    (coreSystemId === '0' && !!systemTypeId && !!regulationId && !!mainProductId);

  const coreSystemOptions = useMemo(() => {
    let newSystemOptions: ISelectOption[] = isTypeSystem ? [{ value: '0', label: 'Nytt system' }] : [];
    if (coreSystems)
      newSystemOptions = [
        ...newSystemOptions,
        ...coreSystems.map((x) => ({
          value: x.id.toString(),
          label: `SID[${x.coreSystemIdentifier}]-${x.description}`,
        })),
      ];
    return newSystemOptions;
  }, [isTypeSystem, coreSystems]);

  const getCustomerData = useCallback(async () => {
    if (!!customerId) {
      const response = await getOfferCustomer(customerId.toString());

      if (response) {
        setFacilityName(response.name);
        setFacilityAddress(response.address);
        setFacilityPostalCode(response.postalCode);
        setFacilityCity(response.city);
      }
    }
  }, [customerId, getOfferCustomer, setFacilityName, setFacilityAddress, setFacilityPostalCode, setFacilityCity]);

  /* Set up previously saved facility and system info */
  useEffect(() => {
    async function loadFacilityAndSystem() {
      const response = await getFacilityAndSystem();
      if (response) {
        if (response.facilityId) {
          setFacilityId(response.facilityId.toString());
          setCoreSystemId(response.coreSystemId?.toString());
        } else {
          if (isTypeSystem || !!response.facilityInformation) {
            setFacilityId('0');
            setCoreSystemId('0');
            getCustomerData();
          }
          setFacilityName(response.facilityInformation?.name);
          setFacilityAddress(response.facilityInformation?.address);
          setFacilityPostalCode(response.facilityInformation?.postalCode);
          setFacilityCity(response.facilityInformation?.city);
        }
        setSystemTypeId(response.systemTypeId?.toString());
        setRegulationId(response.regulationId?.toString());
        setMainProductId(response.mainProductId?.toString());
        setProducts(response.products ?? []);
        setProjectorId(response.projectorId?.toString() ?? user.selectedUserId?.toString() ?? '0');
      }
    }
    if (!!offerId) loadFacilityAndSystem();
  }, [offerId, isTypeSystem, getFacilityAndSystem, setFacilityName, setFacilityAddress, setFacilityCity, setFacilityPostalCode, getCustomerData, setProjectorId, user.selectedUserId]);

  /* Set up details for selected facility and populate system list */
  useEffect(() => {
    async function loadFacility() {
      const response = await getFacility(facilityId);
      if (response) {
        setFacilityName(response.name);
        setFacilityAddress(response.address);
        setFacilityPostalCode(response.postalCode);
        setFacilityCity(response.city);
        setCoreSystems(response.coreSystems);
        setCoreSystemId((x) => {
          if (x && response.coreSystems.find((s) => s.id.toString() === x)) return x;
          return isTypeSystem ? '0' : undefined;
        });
      }
    }
    if (!!facilityId && facilityId !== '0') loadFacility();
  }, [
    facilityId,
    isTypeSystem,
    getFacility,
    setFacilityName,
    setFacilityAddress,
    setFacilityCity,
    setFacilityPostalCode,
  ]);

  /* Set system dependencies if a core system has previously been selected */
  useEffect(() => {
    let selectedCoreSystem = undefined;
    if (!!coreSystemId && coreSystemId !== '0' && !!coreSystems) {
      selectedCoreSystem = coreSystems.find((x) => x.id.toString() === coreSystemId);
    }
    setSystemTypeId(selectedCoreSystem?.systemTypeId.toString());
    setRegulationId(selectedCoreSystem?.regulationId.toString());
    setMainProductId(selectedCoreSystem?.mainProductId.toString());
  }, [coreSystemId, coreSystems]);

  /* Set up regulation select options after a system type has been set*/
  useEffect(() => {
    async function loadSystemType() {
      if (!!systemTypeId) {
        const result = await getSystemType(systemTypeId);
        if (result) {
          setRegulations(result.regulations);
          var options = result.regulations.map((x) => ({
            value: (x.id as number).toString(),
            label: x.name,
          }));
          setRegulationOptions(options);
          setRegulationId((x) => {
            if (!!options.find((r) => r.value === x)) return x;
            return options[0].value;
          });
        } else {
          setRegulationOptions(undefined);
          setRegulations(undefined);
        }
      }
    }
    loadSystemType();
  }, [systemTypeId, getSystemType]);

  /* Insert/update facility and system data */
  const upsertOfferFacilityAndSystem = async () => {
    if (offerType === undefined || (isTypeSystem && !hasSystem)) {
      return;
    }

    const updateOfferFacilityAndSystemCommand: IUpdateOfferFacilityAndSystemCommand = {
      facilityId: facilityId && facilityId !== '0' ? Number(facilityId) : undefined,
      facilityInformation:
        facilityId === '0'
          ? {
              name: facilityName ?? '',
              address: facilityAddress ?? '',
              postalCode: facilityPostalCode ?? '',
              city: facilityCity ?? '',
            }
          : undefined,
      coreSystemId: coreSystemId && coreSystemId !== '0' ? Number(coreSystemId) : undefined,
      systemTypeId: systemTypeId ? Number(systemTypeId) : undefined,
      regulationId: regulationId ? Number(regulationId) : undefined,
      mainProductId: mainProductId ? Number(mainProductId) : undefined,
      products: products,
      projectorId: projectorId ? Number(projectorId) : undefined,
    };

    const result = await updateOfferFacilityAndSystem(updateOfferFacilityAndSystemCommand);

    if (!isResultError(result)) {
      setOfferStep(getNextPage(offerType, offerStep));
    } else {
      //TODO: what should happen if upsert fails?
    }
  };

  const resetFacilityDependencies = () => {
    setFacilityName('');
    setFacilityAddress('');
    setFacilityPostalCode('');
    setFacilityCity('');

    setCoreSystemId(isTypeSystem ? '0' : undefined);
    setSystemTypeId(undefined);
    setRegulationId(undefined);
    setMainProductId(undefined);
  };

  return (
    <>
      <Container
        form
        ref={formRef}
        loading={isFacilityAndSystemLoading || isGetFacilityLoading}
        label={'Redigera ' + identifier + (offerType === SaleType.deal ? ' (Avtal)' : '')}
        actions={
          <WizardFormActions
            goBack={() => setOfferStep(getPreviousPage(offerType!, offerStep))}
            goNext={upsertOfferFacilityAndSystem}
            isFormValid={isFormValid && (!isTypeSystem || hasSystem)}
            isProcessing={isUpdateOfferFacilityAndSystemLoading}
          />
        }
        customSize={{ md: 12 }}
      >
        <Fieldset alignment="flex-start" justification="flex-start">
          <Fieldset legend="Plats/objektadress" customSize={{ fullsize: true }}>
            <FacilitySelect
              customerId={customerId?.toString() ?? ''}
              label="Välj plats"
              placeholder={!isTypeSystem ? 'Välj eller lämna tomt' : undefined}
              required={isTypeSystem}
              isClearable={!isTypeSystem}
              hasNewOption
              selectedFacilityId={facilityId}
              onChange={(selected) => {
                setFacilityId(selected);
                if (!selected || selected === '0') resetFacilityDependencies();
              }}
              customWidth={3}
            />
            <InputField
              customWidth={3}
              label="Namn"
              type="text"
              disabled={facilityId !== '0'}
              value={facilityName}
              onChange={changeFacilityName}
            />
            <InputField
              customWidth={2}
              label="Gatuadress"
              type="text"
              disabled={facilityId !== '0'}
              value={facilityAddress}
              onChange={changeFacilityAdress}
              required={isTypeSystem}
            />
            <PostalCodeField
              customWidth={2}
              label="Postnummer"
              type="text"
              disabled={facilityId !== '0'}
              value={facilityPostalCode}
              onChange={changeFacilityPostalCode}
              required={isTypeSystem}
            />
            <InputField
              customWidth={2}
              label="Ort"
              type="text"
              disabled={facilityId !== '0'}
              value={facilityCity}
              onChange={changeFacilityCity}
              required={isTypeSystem}
            />

            {facilityId && facilityId !== '0' && customerId && (
              <>
                <EmptyGridSpace width="half" />
                <InlineButton
                  color="primary"
                  variant="text"
                  startIcon={<EditIcon />}
                  href={customerId ? `/customers/${customerId}/facility/${facilityId}/update` : '#'}
                  disabled={!customerId}
                  justify="flex-end"
                  slim
                >
                  Redigera plats
                </InlineButton>
              </>
            )}
          </Fieldset>
          <Fieldset
            legend={`${coreSystemOptions.length === 1 && isTypeSystem ? 'Nytt ' : ''}System`}
            customSize={{ fullsize: true }}
          >
            <Select
              inputFieldProps={{ label: 'Välj system om du vill komplettera', required: isTypeSystem, customWidth: 4 }}
              selectProps={{
                isClearable: !isTypeSystem,
                placeholder: isTypeSystem ? undefined : 'Välj eller lämna tomt',
                value: coreSystemOptions.find((x) => x.value === coreSystemId) || null,
                isLoading: isGetFacilityLoading,
                isDisabled: !coreSystemOptions.length,
                options: coreSystemOptions,
                onChange: (selected) => setCoreSystemId(selected! ? (selected as ISelectOption).value : undefined),
              }}
            />
            {isTypeSystem && (
              <>
                <SystemTypeSelect
                  required
                  selectedSystemTypeId={systemTypeId}
                  isDisabled={coreSystemId !== '0'}
                  onChange={(selected) => {
                    setSystemTypeId(selected);
                    setRegulationId(undefined);
                    setMainProductId(undefined);
                  }}
                  customWidth={4}
                />
                <Select
                  inputFieldProps={{ label: 'Regelverk', required: true, customWidth: 4 }}
                  tooltip={'Produkter med gul bakgrund i listan för att välja produkter uppfyller ej valt regelverk.'}
                  selectProps={{
                    isLoading: isSystemTypeLoading,
                    isDisabled: !regulationOptions || !regulationOptions.length || coreSystemId !== '0',
                    options: regulationOptions,
                    value: regulationOptions?.find((x) => x.value === regulationId) ?? null,
                    onChange: (selected) => {
                      setRegulationId((selected as ISelectOption)?.value ?? undefined);
                      setMainProductId(undefined);
                    },
                  }}
                />
              </>
            )}
            <CompanyUserSelect
              isClearable
              label={'Projektör'}
              selectedCompanyUserId={projectorId}
              onChange={(_1, _2, option) => {
                setProjectorId((option as ISelectOption).value)}
              }
              // customWidth={3}
            />
          </Fieldset>
        </Fieldset>
        {offerType !== SaleType.deal && (
          <SelectProducts
            isTypeSystem={isTypeSystem}
            coreSystemId={coreSystemId}
            systemTypeId={systemTypeId}
            regulationId={regulationId}
            regulations={regulations}
            mainProductId={mainProductId}
            offerProducts={products}
            setMainProductId={setMainProductId}
            setOfferProducts={setProducts}
          />
        )}
      </Container>
    </>
  );
};

export default SelectFacilityAndSystem;
