import React from 'react';
import { Typography } from '@material-ui/core';
import Button from '../../Button/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToAppRounded';
import { useStyles } from './ImpersonateBar.styles';

interface IImpersonateBarProps {
  leave: () => void;
  company: string;
  user: string;
}

function ImpersonateBar(props: IImpersonateBarProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography className={classes.message}>
          Du agerar som <span className={classes.company}>{props.user}</span> hos{' '}
          <span className={classes.company}>{props.company}</span>
        </Typography>
        <Button
          onClick={props.leave}
          startIcon={<ExitToAppIcon />}
          variant="contained"
          size="small"
          className={classes.button}
        >
          Gå ur
        </Button>
      </div>
    </div>
  );
}

export default ImpersonateBar;
