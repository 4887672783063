import React, { useRef, useState } from 'react';
import InputBase from '@material-ui/core/InputBase';
import { Typography, Button } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFileRounded';
import IconButton from '../../IconButton/IconButton';
import DeleteIcon from '@material-ui/icons/ClearRounded';
import { InputField, IInputFieldProps } from '../InputField/InputField';
import { useStyles } from './UploadInput.styles';

export interface IUploadFile {
  name: string;
  content: string;
  contentType: string;
}

export type UploadFileType = 'image' | 'pdf';
interface IUploadInputProps extends IInputFieldProps {
  onFileChange: React.Dispatch<React.SetStateAction<IUploadFile | undefined>>;
  type: UploadFileType;
  canEditFileName?: boolean;
}

const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as any);
    reader.onerror = (error) => reject(error);
  });

const fileTypes = (type: UploadFileType) => {
  switch (type) {
    case 'image':
      return 'image/png, image/jpeg';
    case 'pdf':
      return 'application/pdf';
    default:
      return;
  }
};

const UploadInput = (props: IUploadInputProps) => {
  const classes = useStyles();
  const [file, setFile] = useState<File | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const { label, required, onFileChange, disabled, ...rest } = props;
  const labelWithoutSpaces = props.label?.replace(/ /g, '');
  const accept = fileTypes(props.type);
  const fileRef = useRef<HTMLInputElement>();
  var fileName = props.value as string | undefined;
  const onChange = async (e: any) => {
    const file = e.target.files[0];
    if (file?.type.includes(props.type)) {
      var fileName = file.name.split('.')[0];
      setFile(new File([file], fileName));
      onFileChange({ name: fileName, content: await toBase64(file), contentType: file.type });
    }
  };

  const reset = () => {
    setFile(null);
    onFileChange(undefined);
    if (fileRef.current) fileRef.current.value = '';
    fileName = undefined;
  };

  const handleChangeFileName = (fileName: string) => {
    setFile((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        name: fileName,
      };
    });
    onFileChange((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        name: fileName,
      };
    });
  };

  function handleKeydown(event: React.KeyboardEvent) {
    if (event.key === '.') {
      event.preventDefault();
    }
  }

  return (
    <InputField customWidth={7} {...rest} label={label} disabled={!file} value={file ? file.name : fileName}>
      <>
        <Typography id={labelWithoutSpaces} component="span" variant="h4" className={classes.label}>
          {label}
          {required && '*'}
        </Typography>
        <InputBase
          inputProps={{ accept: accept }}
          aria-labelledby={labelWithoutSpaces}
          id={`${labelWithoutSpaces}-id`}
          className={classes.input}
          type="file"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={onChange}
          disabled={disabled}
          inputRef={fileRef}
        />
        <div className={`${classes.container} ${isFocused ? classes.focused : ''}`}>
          <AttachFileIcon className={classes.icon} />
          {props.canEditFileName && file ? (
            <div className={classes.editableFileName}>
              <InputField
                disabled={!file}
                type="text"
                required
                value={file ? file.name : fileName ?? ''}
                onKeyDown={handleKeydown}
                onChange={(e) => handleChangeFileName(e.target.value.toString())}
              />
            </div>
          ) : (
            <Typography className={classes.fileName}>{file ? file.name : fileName}</Typography>
          )}
          {!file && !fileName ? (
            <Button
              htmlFor={`${labelWithoutSpaces}-id`}
              variant="outlined"
              size="small"
              color="primary"
              tabIndex={-1}
              component="label"
              disabled={disabled}
              className={classes.button}
            >
              Välj fil
            </Button>
          ) : (
            <>
              <IconButton
                onClick={reset}
                size="small"
                aria-label="Ta bort fil"
                className={`${classes.button} ${classes.removeFile}`}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </div>
      </>
    </InputField>
  );
};

export default UploadInput;
