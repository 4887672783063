import React, { useState, useEffect } from 'react';
import Container from '../../../../../../../Shared/Container/Container';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import useFormValidation from '../../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import { useUpsertCoreSystem, IUpsertCoreSystemCommand, useGetCoreSystem, ICoreSystemProduct } from '../CoreSystem.api';
import Button from '../../../../../../../Shared/Button/Button';
import { isResultError } from '../../../../../../../Shared/Api/response/ICreateResult';
import SystemTypeSelect from '../../../../../Admin/Components/SystemTypes/SystemTypeSelect/SystemTypeSelect';
import Select, { ISelectOption } from '../../../../../../../Shared/Select/Select';
import { useGetSystemType } from '../../../../../Admin/Components/SystemTypes/SystemTypes.api';
import { useGetProducts } from '../../../../../Admin/Components/Suppliers/ViewSupplier/Products/Products.api';
import useErrorMessage from '../../../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import CompanyUserSelect from '../../../../../Admin/Components/Users/UserSelect/CompanyUserSelect';
import { InputField } from '../../../../../../../Shared/InputFields/InputField/InputField';
import useInputState from '../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { Checkbox, FormControlLabel, Radio } from '@material-ui/core';
import Datepicker from '../../../../../../../Shared/InputFields/DatePicker/Datepicker';
import RadioField from '../../../../../../../Shared/InputFields/RadioField/RadioField';

const CoreSystemForm = () => {
  const history = useHistory();
  const { customerId, facilityId, id } = useParams<{ customerId: string; facilityId: string; id: string }>();
  const location = useLocation();
  const { getSystemType } = useGetSystemType();
  const { isLoading, getCoreSystem } = useGetCoreSystem(customerId, facilityId);
  const [originalName, setOriginalName] = useState('');
  const [selectedSystemType, setSelectedSystemType] = useState<ISelectOption | null>(null);
  const [selectedRegulation, setSelectedRegulation] = useState<ISelectOption | null>(null);
  const [selectedMainProduct, setSelectedMainProduct] = useState<ISelectOption | null>(null);
  const [regulationOptions, setRegulationOptions] = useState<ISelectOption[]>();
  const [mainProductOptions, setMainProductOptions] = useState<ISelectOption[]>();
  const [selectedProjectLeader, setSelectedProjectLeader] = useState<ISelectOption>();
  const [selectedInstaller, setSelectedInstaller] = useState<ISelectOption>();
  const [systemPlacement, changeSystemPlacement, setSystemPlacement] = useInputState('');
  const [installDate, setInstallDate] = useState<Date>();
  const [alarmCentralVersion, changeAlarmCentralVersion, setAlarmCentralVersion] = useInputState('');
  const [previousOwner, changePreviousOwner, setPreviousOwner] = useInputState('');
  const [alarmClass, setAlarmClass] = useState<number>(0);
  const [customerDocuments, changeCustomerDocuments] = useState<boolean>(false);
  const [isCustomSystemType, setIsCustomSystemType] = useState<boolean>(false);
  const [customSystemTypeName, setCustomSystemTypeName] = useInputState<string>('');

  const { getProducts: getMainProducts, isLoading: mainProductsIsLoading } = useGetProducts();

  const urlParams = new URLSearchParams(location.search);
  const redirectToCustomer = urlParams.get('redirect-to-customer') === '1';

  const { isFormValid, formRef } = useFormValidation();

  const { setValidationErrorMessage } = useErrorMessage();
  const { isLoading: isUpsertLoading, upsert } = useUpsertCoreSystem(customerId, facilityId);

  useEffect(() => {
    async function loadOptions() {
      const type = await getSystemType(selectedSystemType?.value);
      setIsCustomSystemType(type.isCustom);
      if (type)
        setRegulationOptions(type.regulations.map((x) => ({ value: (x.id as number).toString(), label: x.name })));
    }
    if (selectedSystemType) {
      loadOptions();
    }
  }, [selectedSystemType, getSystemType]);

  useEffect(() => {
    async function loadMainProducts() {
      const mainProducts = await getMainProducts({
        systemTypeId: selectedSystemType ? parseInt(selectedSystemType.value) : undefined,
        regulationId: selectedRegulation ? parseInt(selectedRegulation.value) : undefined,
        onlyMainProducts: true,
        pageSize: 25,
        pageNumber: 1,
        usedAsSelectList: true,
      });

      if (mainProducts)
        setMainProductOptions(mainProducts.items.map((x) => ({ value: x.id.toString(), label: x.name })));
    }
    if (selectedSystemType && selectedRegulation) {
      loadMainProducts();
    }
  }, [selectedSystemType, selectedRegulation, getMainProducts]);

  useEffect(() => {
    if (regulationOptions && regulationOptions.length > 0 && !selectedRegulation) {
      setSelectedRegulation(regulationOptions[0]);
    }
  }, [regulationOptions, selectedRegulation]);

  async function upsertCoreSystem() {
    if (
      !selectedSystemType ||
      !selectedRegulation ||
      (!selectedMainProduct && !customSystemTypeName) ||
      !selectedProjectLeader
    ) {
      return;
    }

    const upsertCommand: IUpsertCoreSystemCommand = {
      systemTypeId: parseInt(selectedSystemType.value, 10),
      regulationId: parseInt(selectedRegulation.value, 10),
      projectLeaderId: parseInt(selectedProjectLeader.value, 10),
      installerId: selectedInstaller ? parseInt(selectedInstaller.value, 10) : undefined,
      mainProductId: selectedMainProduct ? parseInt(selectedMainProduct.value, 10) : null,
      systemPlacement: systemPlacement,
      installDate,
      alarmClass,
      alarmCentralVersion,
      previousOwner,
      customerDocuments,
      products: selectedMainProduct
        ? [{ productId: selectedMainProduct.value, productAddress: '', facilityPlacement: '' }]
        : [],
      isCustom: isCustomSystemType,
      CustomSystemTypeName: isCustomSystemType ? customSystemTypeName : undefined,
    };

    const result = await upsert(upsertCommand, id);

    isResultError(result)
      ? setValidationErrorMessage(result)
      : history.push(`/customers/${customerId}/facility/${facilityId}/system/${result}`);
  }

  useEffect(() => {
    async function loadCoreSystem() {
      const coreSystem = await getCoreSystem(id);

      const mainProduct = coreSystem.products.find(
        (x: ICoreSystemProduct) => x.productId === coreSystem.mainProductId,
      ) as ICoreSystemProduct;

      setOriginalName(mainProduct.productName);
      setSelectedSystemType({ value: coreSystem.systemTypeId.toString(), label: coreSystem.systemTypeName });
      setSelectedRegulation({ value: coreSystem.regulationId.toString(), label: coreSystem.regulationName });
      setSelectedProjectLeader({
        value: coreSystem.projectLeaderId.toString(),
        label: coreSystem.projectLeaderName,
      });
      setSelectedInstaller(
        coreSystem.mainInstallerId && coreSystem.mainInstallerName
          ? {
              value: coreSystem.mainInstallerId.toString(),
              label: coreSystem.mainInstallerName,
            }
          : undefined,
      );
      setSelectedMainProduct({ value: mainProduct.productId.toString(), label: mainProduct.productName });
      setSystemPlacement(coreSystem.systemPlacement);
      setInstallDate(coreSystem.installDate ? new Date(coreSystem.installDate) : undefined);
      setAlarmClass(coreSystem.alarmClass);
      setAlarmCentralVersion(coreSystem.alarmCentralVersion);
      setPreviousOwner(coreSystem.previousOwner);
      changeCustomerDocuments(coreSystem.customerDocuments);
    }
    if (id) loadCoreSystem();
  }, [id, getCoreSystem, setSystemPlacement, setAlarmCentralVersion, setPreviousOwner]);

  function Actions() {
    const cancel = () =>
      id
        ? history.push(`/customers/${customerId}/facility/${facilityId}/system/${id}`)
        : redirectToCustomer
        ? history.push(`/customers/${customerId}`)
        : history.push(`/customers/${customerId}/facility/${facilityId}`);

    const isValid =
      isFormValid &&
      selectedSystemType &&
      selectedRegulation &&
      (selectedMainProduct || customSystemTypeName) &&
      selectedProjectLeader;
    return (
      <>
        <Button color="default" variant="outlined" onClick={cancel}>
          Avbryt
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={upsertCoreSystem}
          loading={isUpsertLoading}
          disabled={!isValid}
        >
          {id ? 'Spara' : 'Skapa'}
        </Button>
      </>
    );
  }

  return (
    <Container
      form
      loading={isLoading}
      label={id ? `Redigera ${originalName}` : 'Skapa system'}
      actions={<Actions />}
      ref={formRef}
    >
      <CompanyUserSelect
        required
        label="Säljare"
        inline={false}
        selectedCompanyUser={selectedProjectLeader}
        onChange={(_1, _2, option) => setSelectedProjectLeader(option as ISelectOption)}
      />
      <CompanyUserSelect
        label="Montör"
        inline={false}
        selectedCompanyUser={selectedInstaller}
        onChange={(_1, _2, option) => setSelectedInstaller(option as ISelectOption)}
      />
      <SystemTypeSelect
        selectedSystemType={selectedSystemType as ISelectOption}
        onChange={(value, label) => {
          setSelectedSystemType(value && label ? { value, label } : null);
          setSelectedRegulation(null);
          setRegulationOptions(undefined);
          setSelectedMainProduct(null);
          setMainProductOptions(undefined);
        }}
      />
      <Select
        inputFieldProps={{ label: 'Regelverk', required: true }}
        selectProps={{
          value: selectedRegulation,
          isDisabled: !regulationOptions || regulationOptions.length === 0,
          onChange: (selected) => {
            setSelectedRegulation(selected as ISelectOption);
            setSelectedMainProduct(null);
          },
          options: regulationOptions,
        }}
      />
      {isCustomSystemType ? (
        <InputField
          required
          label="Övrigt"
          type="text"
          value={customSystemTypeName}
          onChange={setCustomSystemTypeName}
        />
      ) : (
        <Select
          inputFieldProps={{ label: 'Huvudprodukt', required: true }}
          selectProps={{
            isLoading: mainProductsIsLoading,
            isDisabled: !mainProductOptions || mainProductOptions.length === 0,
            value: selectedMainProduct,
            onChange: (selected) => {
              setSelectedMainProduct(selected as ISelectOption);
            },
            options: mainProductOptions,
          }}
        />
      )}
      <InputField label="Placering" type="text" value={systemPlacement} onChange={changeSystemPlacement} />
      <Datepicker
        label="Installationsdatum"
        fullwidth
        selected={installDate}
        inFilter={true}
        onChange={(d: Date | null) => setInstallDate(d ?? undefined)}
      />
      <RadioField label="Larmklass">
        <FormControlLabel
          label="Ingen"
          disabled={isLoading}
          control={<Radio color="primary" />}
          checked={alarmClass === 0}
          onChange={() => setAlarmClass(0)}
        />
        <FormControlLabel
          label="1"
          disabled={isLoading}
          control={<Radio color="primary" />}
          checked={alarmClass === 1}
          onChange={() => setAlarmClass(1)}
        />
        <FormControlLabel
          label="2"
          disabled={isLoading}
          control={<Radio color="primary" />}
          checked={alarmClass === 2}
          onChange={() => setAlarmClass(2)}
        />
        <FormControlLabel
          label="3"
          disabled={isLoading}
          control={<Radio color="primary" />}
          checked={alarmClass === 3}
          onChange={() => setAlarmClass(3)}
        />
        <FormControlLabel
          label="4"
          disabled={isLoading}
          control={<Radio color="primary" />}
          checked={alarmClass === 4}
          onChange={() => setAlarmClass(4)}
        />
      </RadioField>
      <InputField
        label="Version, centralapparat"
        type="text"
        value={alarmCentralVersion}
        onChange={changeAlarmCentralVersion}
      />
      <InputField label="Tidigare installatör" type="text" value={previousOwner} onChange={changePreviousOwner} />
      <FormControlLabel
        control={
          <Checkbox
            name="customerDocsCheckbox"
            color="primary"
            checked={customerDocuments}
            onChange={(_, checked) => changeCustomerDocuments(checked)}
          />
        }
        label="Kundpärm finns"
      />
    </Container>
  );
};

export default CoreSystemForm;
