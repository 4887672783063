import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import AccessGuard from '../../../Core/Authentication/AccessGuard/AccessGuard';
import { ModuleIdentifiers, AccessRights } from '../../../Core/Authentication/ModuleAccess';
import { DialogContentHeader } from '../../DialogContentHeader/DialogContentHeader';
import DialogForm from '../../DialogForm/DialogForm';
import EmailField from '../../InputFields/EmailField/EmailField';
import { InputField } from '../../InputFields/InputField/InputField';
import { IEmailDocument } from '../../../AuthApp/Modules/Customers/Customers.api';
import Button from '../../Button/Button';
import { useGetAllowedEmail } from '../../../AuthApp/Modules/Company/Companies.api';

interface IEmailDocumentModal {
  title: string;
  open: boolean;
  type: string;
  value: IEmailDocument;
  isLoading?: boolean;
  onChange: Dispatch<SetStateAction<IEmailDocument>>;
  ToggleModal: () => void;
  onSend: () => void;
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[a-z]{2,}$/i;

const EmailDocumentModal = (props: IEmailDocumentModal) => {
  const { title, open, type: DocumentType, value, isLoading, onChange, ToggleModal, onSend } = props;
  const { getIsEmailAllowed } = useGetAllowedEmail();
  const [isValidEmail, setIsValidEmail] = useState<boolean | undefined>();

  const isValid = value && value.recipient && value.subject && value.message;

  function handleChangeEvent(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    event.persist();
    const { name, value } = event.target;

    onChange((prevEmailObject) => ({
      ...prevEmailObject,
      [name]: value,
    }));
  }

  useEffect(() => {
    async function fetchIsEmailAllowed() {
      const results = await getIsEmailAllowed(value.recipient);
      setIsValidEmail(results);
    }
    if (emailRegex.test(value.recipient)) {
      fetchIsEmailAllowed();
    } else {
      setIsValidEmail(value.recipient ? false : undefined);
    }
  }, [value.recipient, getIsEmailAllowed]);

  return (
    <Dialog open={open} onClose={ToggleModal}>
      <DialogContent>
        <DialogContentHeader onClose={ToggleModal} title={title} titleId="" />
        <DialogForm
          actions={
            <>
              <AccessGuard module={ModuleIdentifiers.Admin} accessRights={AccessRights.Write}>
                <Button disabled={isLoading} variant="outlined" onClick={ToggleModal}>
                  Avbryt
                </Button>

                <Button
                  loading={isLoading}
                  disabled={!isValid || !isValidEmail}
                  variant="contained"
                  color="primary"
                  onClick={onSend}
                >
                  Skicka
                </Button>
              </AccessGuard>
            </>
          }
          children={
            <>
              <InputField fullwidth viewOnly label="Dokumentets namn" value={DocumentType} disabled={isLoading} />
              <InputField
                name="subject"
                fullwidth
                label="Ämne"
                value={value.subject}
                disabled={isLoading}
                onChange={(event) => handleChangeEvent(event)}
              />
              <EmailField
                name="recipient"
                fullwidth
                label="Skicka till"
                success={isValidEmail}
                onChange={(event) => handleChangeEvent(event)}
                value={value.recipient}
                removeHelperText
                errorText={isValidEmail === false ? 'Denna e-post är inte tillåten.' : ''}
                disabled={isLoading}
              />
              <InputField
                name="message"
                placeholder="Meddelande..."
                fullwidth
                type="text"
                multiline
                rows={10}
                value={value.message}
                disabled={isLoading}
                onChange={(event) => handleChangeEvent(event)}
              />
            </>
          }
        />
      </DialogContent>
    </Dialog>
  );
};

export default EmailDocumentModal;
