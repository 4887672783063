import React from 'react';
import TableBody from '@material-ui/core/TableBody/TableBody';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import { AddCircleRounded } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { Table } from '../../../../../../../../../../../Shared/Table/Table';
import { InputField } from '../../../../../../../../../../../Shared/InputFields/InputField/InputField';
import { Fieldset } from '../../../../../../../../../../../Shared/Form/Fieldset/Fieldset';
import { IPoliceDeviation } from '../../../../Documents.api';
import useInputState from '../../../../../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import useStyles from './PoliceDeviations.styles';

interface IPoliceDeviationsProps {
  rows: IPoliceDeviation[];
  setRows: (rows: IPoliceDeviation[]) => void;
}

export const PoliceDeviations = (props: IPoliceDeviationsProps) => {
  const classes = useStyles();
  const [pos, changePos, setPos] = useInputState('');
  const [note, changeNote, setNote] = useInputState('');

  const onAdd = () => {
    if (!pos || !note) return;
    let newRow: IPoliceDeviation = {
      id: null,
      pos: pos,
      note: note,
    };
    props.setRows([...props.rows, newRow]);
    setPos('');
    setNote('');
  };

  const onDelete = (index: number) => {
    let newRows = [...props.rows];
    newRows.splice(index, 1);
    props.setRows(newRows);
  };

  return (
    <Fieldset legend="Avvikelser, polismyndighetssidan">
      <Table customSize={{ md: 12, lg: 12 }}>
        <TableHead>
          <TableRow>
            <TableCell>Pos</TableCell>
            <TableCell>Notering</TableCell>
            <TableCell>
              <span className="sr-only">Lägg till och ta bort</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, index) => (
            <TableRow key={row.id ?? 'new_' + index}>
              <TableCell>{row.pos}</TableCell>
              <TableCell>{row.note}</TableCell>
              <TableCell className={classes.actionTc}>
                <IconButton
                  size="small"
                  aria-label="Ta bort utbildning"
                  className={`${classes.IconBtn} ${classes.remove}`}
                  onClick={() => onDelete(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>
              <InputField placeholder="Pos" type="text" value={pos} onChange={changePos} />
            </TableCell>
            <TableCell>
              <InputField placeholder="Notering" type="text" fullWidth value={note} onChange={changeNote} />
            </TableCell>
            <TableCell className={classes.actionTc}>
              <IconButton
                color="primary"
                size="small"
                aria-label="Lägg till avvikelser"
                className={classes.IconBtn}
                onClick={onAdd}
              >
                <AddCircleRounded />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Fieldset>
  );
};
