import React, { useState, useEffect } from 'react';
import { IChoiceQuestion, IChoiceQuestionAnswer } from '../../Surveys.api';
import Button from '../../../../../../../Shared/Button/Button';
import { InputField } from '../../../../../../../Shared/InputFields/InputField/InputField';
import IconButton from '../../../../../../../Shared/IconButton/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';
import { useStyles } from './ChoiceQuestion.styles';

export interface IChoiceQuestionProps {
  question: IChoiceQuestion;
  onChange: (question: IChoiceQuestion) => void;
}

const ChoiceQuestion = (props: IChoiceQuestionProps) => {
  const { question, onChange } = props;
  const [sortedChoices, setSortedChoices] = useState<IChoiceQuestionAnswer[]>([]);
  const classes = useStyles();

  useEffect(() => {
    const sortedList = question.choices ? [...question.choices] : [];
    sortedList.sort((a, b) => a.sortOrder - b.sortOrder);
    setSortedChoices(sortedList);
  }, [question.choices]);

  function addChoice() {
    const newList = [...sortedChoices];
    newList.push({ choiceOption: '', sortOrder: nextSortOrder() });
    onChange({ ...question, choices: newList });
  }

  function updateChoice(index: number, update: any) {
    const newList = [...sortedChoices];

    newList[index] = { ...newList[index], ...update };

    onChange({ ...question, choices: newList });
  }

  function removeChoice(choice: IChoiceQuestionAnswer) {
    const newList = [...sortedChoices];

    const index = newList.indexOf(choice);
    newList.splice(index, 1);

    onChange({ ...question, choices: newList });
  }

  function nextSortOrder() {
    return question.choices ? Math.max(...question.choices.map((x) => x.sortOrder), 0) + 1 : 1;
  }

  return (
    <>
      {sortedChoices.map((x, index) => (
        <div key={x.id + '-' + x.sortOrder} className={classes.option}>
          <InputField
            label={'Alternativ ' + (index + 1)}
            multiline
            value={x.choiceOption}
            onChange={(e) => updateChoice(index, { choiceOption: e.target.value })}
          />
          <IconButton
            color="error"
            size="small"
            aria-label="Ta bort alternativ"
            className={classes.removeOption}
            onClick={() => removeChoice(x)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ))}

      <Button variant="outlined" onClick={addChoice} className={classes.addOptionBtn}>
        Lägg till alternativ
      </Button>
    </>
  );
};

export default ChoiceQuestion;
