import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Datepicker from '../../../../../../../../../Shared/InputFields/DatePicker/Datepicker';
import { InputField } from '../../../../../../../../../Shared/InputFields/InputField/InputField';
import Select, { ISelectOption } from '../../../../../../../../../Shared/Select/Select';
import { IDocumentFormProps } from '../../IDocumentFormProps';
import { useGetFacility } from '../../../../../Facilities/Facilities.api';
import { useGetCoreSystem } from '../../../../../Facilities/CoreSystems/CoreSystem.api';
import moment from 'moment';
import { isResultError } from '../../../../../../../../../Shared/Api/response/ICreateResult';
import FacilitySelect from '../../../../../Facilities/FacilitySelect/FacilitySelect';
import useInputState from '../../../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { useCreateOrderEmergencyCenterDocument, useGetOrderEmergencyCenterDocumentForm } from '../../Documents.api';
import FormatSelect from '../../../../../../../Admin/Components/Format/FormatSelect/FormatSelect';

const OrderEmergencyCenter = (props: IDocumentFormProps) => {
  const { customerId, onLoadingChange, saveCallback, documentName, onDocumentCreated, validateFormState } = props;
  const { getOrderEmergencyCenterDocumentForm, isLoading } = useGetOrderEmergencyCenterDocumentForm(customerId);
  const { isLoading: isFacilityLoading, getFacility } = useGetFacility(customerId);
  const { isLoading: isCreateLoading, createOrderEmergencyCenterDocument } = useCreateOrderEmergencyCenterDocument(
    customerId,
  );
  const [facilityId, setFacilityId] = useState<number | undefined>(
    props.facilityId ? parseInt(props.facilityId) : undefined,
  );
  const { isLoading: isCoreSystemLoading, getCoreSystem } = useGetCoreSystem(customerId, facilityId?.toString());

  const [coreSystemOptions, setCoreSystemOptions] = useState<ISelectOption[]>([]);
  const [coreSystemId, setCoreSystemId] = useState<string>();
  const [formatId, setFormatId] = useState<string | undefined>();
  const [startDate, setStartDate] = useState<Date>(moment().startOf('day').add(1, 'day').toDate());
  const [takenFrom, changeTakenFrom, setTakenFrom] = useInputState('');
  const [agreementOptions, setAgreementOptions] = useState<ISelectOption[]>([]);
  const [agreementId, setAgreementId] = useState<string>();
  const [withoutAgreement, setWithoutAgreement] = useState<boolean>(true);

  useEffect(() => {
    validateFormState &&
      validateFormState(!!facilityId && !!coreSystemId && (withoutAgreement || !!agreementId) && !!formatId);
  }, [validateFormState, facilityId, coreSystemId, withoutAgreement, agreementId, formatId]);

  useEffect(() => {
    async function loadForm() {
      const form = await getOrderEmergencyCenterDocumentForm();
      setFacilityId(form.facilityId);
      setCoreSystemId(form.coreSystemId.toString());
      setFormatId(form.formatId ? form.formatId.toString() : undefined);
      setTakenFrom(form.takenFrom ?? '');
      setAgreementId(form.agreementId ? form.agreementId.toString() : undefined);
      setWithoutAgreement(form.withoutAgreement);
    }
    loadForm();
  }, [getOrderEmergencyCenterDocumentForm, setTakenFrom]);

  useEffect(() => {
    onLoadingChange(isLoading || isCreateLoading);
  }, [onLoadingChange, isLoading, isCreateLoading]);

  useEffect(() => {
    async function loadFacility() {
      var response = await getFacility(facilityId?.toString());
      setCoreSystemOptions(response.coreSystems.map((x) => ({ label: x.mainProduct, value: x.id.toString() })));
      if (response.coreSystems.length === 1) {
        setCoreSystemId(response.coreSystems[0].id.toString());
      }
    }
    if (facilityId !== undefined) {
      loadFacility();
    }
  }, [facilityId, getFacility]);

  useEffect(() => {
    async function loadCoreSystem() {
      var response = await getCoreSystem(coreSystemId);
      var agreements = response.agreements.filter((x) => x.isActive);
      setAgreementOptions(agreements.map((x) => ({ label: x.agreementIdentifier, value: x.id.toString() })));
      if (agreements.length === 0) {
        setAgreementId(undefined);
        setWithoutAgreement(true);
      }
    }
    if (coreSystemId !== undefined) {
      loadCoreSystem();
    }
  }, [coreSystemId, getCoreSystem]);

  const save = useCallback(() => {
    async function innerSave() {
      const result = await createOrderEmergencyCenterDocument({
        documentName: documentName,
        facilityId: facilityId!,
        coreSystemId: Number(coreSystemId!),
        formatId: Number(formatId),
        startDate: startDate,
        takenFrom: takenFrom,
        agreementId: agreementId ? Number(agreementId) : null,
        withoutAgreement: withoutAgreement,
      });
      if (!isResultError(result)) onDocumentCreated(Number(result as string));
    }
    innerSave();
  }, [
    createOrderEmergencyCenterDocument,
    documentName,
    facilityId,
    coreSystemId,
    formatId,
    startDate,
    takenFrom,
    agreementId,
    withoutAgreement,
    onDocumentCreated,
  ]);

  useEffect(() => {
    saveCallback(save);
  }, [saveCallback, save]);

  return (
    <>
      <FacilitySelect
        customerId={customerId}
        isDisabled={!!props.facilityId}
        label="Välj plats"
        required
        selectedFacilityId={facilityId?.toString()}
        onChange={(selected) => {
          setFacilityId(selected ? Number(selected) : undefined);
          setCoreSystemId(undefined);
        }}
      />
      <Select
        inputFieldProps={{ label: 'Välj system', required: true }}
        selectProps={{
          value: coreSystemOptions.find((x) => x.value === coreSystemId) || null,
          isDisabled: !!(coreSystemOptions.length === 0),
          onChange: (selected) => {
            setCoreSystemId(selected ? (selected as ISelectOption).value : undefined);
          },
          options: coreSystemOptions,
          isLoading: isFacilityLoading,
        }}
      ></Select>
      <FormatSelect
        required
        selectedFormatId={formatId ? Number(formatId) : undefined}
        onChange={(formatId) => {
          setFormatId(formatId ? formatId.toString() : undefined);
        }}
      />
      <Datepicker
        label="Önskat leveransdatum"
        selected={startDate}
        onChange={(d: Date | null) => setStartDate(d as Date)}
      />
      <InputField label="Övertagen från" type="text" value={takenFrom} onChange={changeTakenFrom} />
      <Select
        inputFieldProps={{ label: 'Avtal' }}
        selectProps={{
          value: agreementOptions.find((x) => x.value === agreementId) || null,
          isDisabled: !!(agreementOptions.length === 0),
          onChange: (selected) => {
            setAgreementId(selected ? (selected as ISelectOption).value : undefined);
            setWithoutAgreement(!selected);
          },
          options: agreementOptions,
          isLoading: isCoreSystemLoading,
        }}
      ></Select>
      <FormControlLabel
        control={<Checkbox color="primary" />}
        label="Lokalt larm, ej avtal"
        checked={withoutAgreement}
        onChange={(_, checked) => {
          setWithoutAgreement(checked);
          if (checked) setAgreementId(undefined);
        }}
      />
    </>
  );
};

export default OrderEmergencyCenter;
