import React, { useState, useEffect, useCallback } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { ICalendarEvent } from '../../../Shared/Calendars/UserCalendar/UserCalendar';
import CompanyCalendar from '../../../Shared/Calendars/CompanyCalendar/CompanyCalendar';
import useErrorMessage from '../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import { isErrorResponse } from '../../../../Shared/Api/response/IErrorRespose';
import { IActivity } from '../../../Shared/Calendars/Components/ActivityForm/ActivityForm';
import {
  ICalendarEntry,
  useUpsertCalendarEntry,
  getErrorMessage,
  useDeleteCalendarEntry,
  getCalendarEntryTypeName,
  useGetCompanyCalendars,
} from '../Booking.api';
import moment from 'moment';

const now = new Date();

export default function BookingCompanyCalendar() {
  const [date, setDate] = useState(new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())));
  const { calendars: bookingCalendar, getCompanyCalendars } = useGetCompanyCalendars();
  const [events, setEvents] = useState<ICalendarEvent<ICalendarEntry>[]>();
  const { upsert } = useUpsertCalendarEntry();
  const { setErrorMessage } = useErrorMessage();
  const { deleteCalendarEntry } = useDeleteCalendarEntry();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const loadData = useCallback(async () => {
    const startDate = moment(date)
      .startOf(mobile ? 'day' : 'isoWeek')
      .toDate();
    const endDate = moment(date)
      .endOf(mobile ? 'day' : 'isoWeek')
      .toDate();

    getCompanyCalendars({ startDate, endDate });
  }, [getCompanyCalendars, date, mobile]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const addSecondToDate = (date: Date) => {
    // A hack to prevent no duration event to be counted as allDay event
    var newDate = new Date(date);
    newDate.setSeconds(1);
    return newDate;
  };

  useEffect(() => {
    if (bookingCalendar) {
      setEvents(
        bookingCalendar.map((x) => ({
          start: new Date(x.startDate),
          end: addSecondToDate(x.endDate),
          title: x.description ? x.description : getCalendarEntryTypeName(x.type),
          resource: x,
          type: x.type,
        })),
      );
    } else {
      setEvents([]);
    }
  }, [bookingCalendar]);

  async function handleSave(activity: IActivity) {
    if (activity.companyUserIds) {
      const result = await upsert({ ...activity, companyUserIds: activity.companyUserIds }, activity.id?.toString());
      if (isErrorResponse(result)) {
        setErrorMessage({ message: getErrorMessage(result, !activity.id) });
      } else {
        loadData();
      }
    }
  }

  const handleRemove = async (id: number) => {
    const result = await deleteCalendarEntry(id.toString());
    if (isErrorResponse(result)) {
      setErrorMessage({ message: getErrorMessage(result, false) });
    } else {
      loadData();
    }
  };

  return (
    <>
      <CompanyCalendar<ICalendarEntry>
        viewDate={date}
        events={events}
        onDateChanged={(d) => setDate(d)}
        onSave={handleSave}
        onRemove={handleRemove}
      />
    </>
  );
}
