import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  eventTitleContainer: {
    width: '100%',
  },
  eventTimeCompanyContainer: {
    width: '100%',
    textAlign: 'left',
    position: 'relative',
    '& > svg': {
      height: '15px',
      position: 'absolute',
      right: 0
    }
  },
  eventTitle: {
    marginRight: '0.25rem',
    fontWeight: 600,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    justifyContent: 'center',
    fontSize: theme.typography.body1.fontSize
  },
  dash: {
    margin: '0 0.25rem',
  },
}));
