import React, { useState, Dispatch, SetStateAction } from 'react';

type ReturnType<T> = [
  T,
  (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => any,
  Dispatch<SetStateAction<T>>,
];

export default function useInputState<T>(initialValue: T, valueTransform?: (oldVal: T, newVal: T) => T): ReturnType<T> {
  const [value, setValue] = useState<T>(initialValue);

  function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (e.target && typeof initialValue === 'number' && typeof e.target.value === 'string') {
      const value = parseInt(e.target.value, 10);
      if (valueTransform) setValue((x) => valueTransform(x, value as any));
      else setValue(value as any);
      return value;
    }

    if (valueTransform) setValue((x) => valueTransform(x, e.target.value as any));
    else setValue(e.target.value as any);
    return e.target.value;
  }

  return [value, handleChange, setValue];
}
