import { createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

export const blue = {
  light: '#c3dbfd',
  main: '#3f6a96',
  dark: '#2a3789',
};

export const purple = {
  light: '#C565EF',
  main: '#8429AC',
  dark: '#4F1E7E',
};

export const turquoise = {
  light: '#A9EEEB',
  main: '#1E8177',
  dark: '#1C655E',
};

export const green = {
  light: '#86E0AB',
  main: '#23834B',
  dark: '#0B572A',
};
export const red = {
  light: '#FF9FA4',
  main: '#C90610',
  dark: '#A3050D',
};

const colorPalette = {
  grey: {
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
  },
  primary: {
    ...blue,
    contrastText: '#fff',
  },
  common: {
    white: '#fff',
    black: '#000',
  },
  error: {
    main: '#DC3030',
  },
  warning: {
    main: '#F4CA64',
    dark: '#D97923',
  },
  success: {
    main: '#39C171',
  },
};

export const calendarColors = {
  blue: {
    background: '#E8F0FE',
    text: '#1066DC',
  },
  green: {
    background: '#E3FCEC',
    text: '#167741',
  },
  yellow: {
    background: '#FEF3D7',
    text: '#5C4813',
  },
  purple: {
    background: '#F0EDFF',
    text: '#482DF4',
  },
  red: {
    background: '#FFEDED',
    text: '#F42D2D',
  },
  gray: {
    background: 'rgba(234,234,234,1)',
    text: '#5C4813',
  },
};

export const selectBorderRadius: string = '5px';
const baseFontSize: number = 14;
const breakpoints = createBreakpoints({});

const theme = createMuiTheme({
  palette: colorPalette,
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 100,
        fontWeight: 'bold',
        textTransform: 'initial',
        fontSize: '1rem',
        border: `2px solid transparent`,
        minWidth: '6rem',
      },
      outlined: {
        padding: '0.4rem 1.5rem',
        border: `2px solid ${colorPalette.grey[700]}`,
        color: colorPalette.grey && colorPalette.grey[700],
        '&:disabled': {
          border: `2px solid ${colorPalette.grey[200]}`,
        },
      },
      outlinedPrimary: {
        border: `2px solid ${colorPalette.primary.dark}`,
        '&:hover': {
          border: `2px solid ${colorPalette.primary.dark}`,
        },
      },
      contained: {
        border: `2px solid ${colorPalette.primary.dark}`,
        '&:hover': {
          border: `2px solid ${colorPalette.primary.dark}`,
          boxShadow: 'none',
        },
        backgroundColor: colorPalette.primary.contrastText,
        color: colorPalette.primary.dark,
        padding: '0.4rem 1.5rem',
        boxShadow: 'none',
      },
      text: {
        minWidth: 0,
      },
    },
    MuiMenuItem: {
      root: {
        paddingTop: '1rem',
        paddingBottom: '1rem',
        fontSize: '1rem',
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none',
        zIndex: 3,
      },
    },
    MuiInputBase: {
      root: {
        width: '100%',
        '&.Mui-disabled': {
          color: 'rgba(0,0,0,0.5)',
        }
      },
      input: {
        padding: '0.5rem 0 6px',
        '&.Mui-disabled': {
          cursor: 'not-allowed'
        },
      },
      multiline: {
        padding: 0,
        paddingBottom: '0.5rem',
      },
    },
    MuiInputLabel: {
      root: {
        color: '#000',
        lineHeight: 1.6,
        '& span': {
          lineHeight: '1.25rem',
        },
      },
      formControl: {
        position: 'static',
        transform: 'none',
      },
      shrink: {
        transform: 'inheirit',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginBottom: '0.25rem',
      },
    },
    MuiTableHead: {
      root: {
        wordBreak: 'break-all',
        '& > tr:first-child': {
          backgroundColor: colorPalette.grey[100],
        },
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 700,
        padding: '0.5rem 0.75rem',
      },
      body: {
        padding: '0.5rem 0.75rem',
        height: '2rem',
        '& > a': {
          display: 'flex',
        },
      },
      root: {
        fontSize: '0.9rem',
        borderRight: `1px solid ${colorPalette.grey[300]}`,
        '&:last-child': {
          borderRight: 'none',
        },
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        right: '1rem',
        position: 'absolute',
        '& > *': {
          fontWeight: 'bold',
          color: colorPalette.common.black,
        },
      },
      positionStart: {
        left: '1rem',
        position: 'absolute',
        zIndex: 1,
        '& > *': {
          fontWeight: 'bold',
          color: colorPalette.common.black,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1rem',
      },
    },
    MuiTablePagination: {
      root: {
        overflow: 'hidden',
      },
      toolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
        [breakpoints.down('xs')]: {
          justifyContent: 'space-between',
        },
      },
      input: {
        maxWidth: '4rem',
      },
      spacer: {
        [breakpoints.down('xs')]: {
          display: 'none',
          '& + p': {
            display: 'none',
          },
        },
      },
      selectRoot: {
        [breakpoints.down('xs')]: {
          display: 'none',
        },
      },
    },
    MuiFormControl: {
      root: {
        minWidth: 'inherit',
        display: 'flex',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '2rem',
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: '0 1rem',
        '&.Mui-expanded': {
          minHeight: 0,
        },
      },
      content: {
        display: 'flex',
        flexGrow: 1,
        maxWidth: 'calc(100% - 2rem)',
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: '0',
      },
    },
    MuiAccordion: {
      root: {
        '&:before': {
          height: 0,
        },
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },
    MuiDialog: {
      paper: {
        margin: '0.5rem',
      },
      paperWidthSm: {
        maxWidth: '100%',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
      },
    },
    MuiTabs: {
      root: {
        border: `1px solid ${colorPalette.grey[300]}`,
        borderBottom: 0,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        boxSizing: 'border-box',
        maxWidth: '100%',
        color: colorPalette.grey[600],
        '& .Mui-selected': {
          color: colorPalette.primary.dark,
        },
      },
    },
    MuiTab: {
      root: {
        textTransform: 'initial',
        minHeight: '3.25rem',
        '&$selected': {
          color: colorPalette.primary.dark,
        },
      },
      textColorInherit: {
        opacity: 0.8,
      },
      wrapper: {
        fontSize: '1rem',
        fontWeight: 700,
      },
    },
    MuiRadio: {
      root: {
        paddingTop: '0.25rem ',
        paddingBottom: '0.25rem ',
      },
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontFamily: 'niveau-grotesk, sans-serif',
    htmlFontSize: baseFontSize,
    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '1.6rem',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '1rem',
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: colorPalette.common.black,
      textTransform: 'uppercase',
    },
    h6: {
      fontSize: '0.9rem',
      fontWeight: 'bold',
    },
    body1: {
      fontSize: '1rem',
    },
    caption: {
      fontSize: '1rem',
    },
    subtitle1: {
      fontSize: '0.75rem',
    }
  },
  breakpoints: {
    unit: 'rem',
    values: {
      xs: 0,
      sm: 434 / baseFontSize,
      md: 960 / baseFontSize,
      lg: 1280 / baseFontSize,
      xl: 1920 / baseFontSize,
    },
  },
  spacing: (factor) => `${0.5 * factor}rem`,
});

export default theme;
