import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ISupplier, SupplierType, useGetSuppliers } from '../Suppliers.api';
import { ISelectOption } from '../../../../../../Shared/Select/Select';
import {
  areAllSelected,
  selectAllOption,
  selectAllList,
  emptyList,
} from '../../../../../../Shared/Select/SelectMultiChecker';
import IPaginatedResponse from '../../../../../../Shared/Api/response/IPaginatedResponse';
import useTable from '../../../../../../Shared/Table/useTable';
import { AsyncSelect, MultiAsyncSelect } from '../../../../../../Shared/Select';

export interface ISupplierSelectProps {
  isMulti?: boolean;
  addSelectAllOption?: boolean;
  placeholder?: string;
  required?: boolean;
  fullwidth?: boolean;
  isDisabled?: boolean;
  isClearable?: boolean;
  selectedSupplier?: ISelectOption | ISelectOption[];
  selectedSupplierId?: string | string[];
  supplierType?: SupplierType;
  viewOnly?: boolean;
  label?: string;
  onChange?: (
    supplierId: string | undefined,
    supplierName: string | undefined,
    option: ISelectOption | ISelectOption[] | undefined,
    allOptionsSelected: boolean | undefined,
  ) => void;
}

export const SupplierSelect = (props: ISupplierSelectProps) => {
  const { supplierType, isMulti, addSelectAllOption, selectedSupplier, selectedSupplierId } = props;
  const { isLoading, getSuppliers } = useGetSuppliers();
  const { paginatedRequest } = useTable();
  const [defaultOptions, setDefaultOptions] = useState<ISelectOption[]>();
  const label = props.label ?? 'Välj leverantör';
  const placeholder = props.placeholder ?? 'Välj leverantör';

  const selectedOption = useMemo(() => {
    if (!selectedSupplier) return undefined;
    if (isMulti) {
      return Array.isArray(selectedSupplier) ? selectedSupplier : [selectedSupplier];
    } else {
      return Array.isArray(selectedSupplier) ? selectedSupplier[0] : selectedSupplier;
    }
  }, [isMulti, selectedSupplier]);

  const selectedOptionId = useMemo(() => {
    if (!selectedSupplierId) return undefined;
    if (isMulti) {
      return Array.isArray(selectedSupplierId) ? selectedSupplierId : [selectedSupplierId];
    } else {
      return Array.isArray(selectedSupplierId) ? selectedSupplierId[0] : selectedSupplierId;
    }
  }, [isMulti, selectedSupplierId]);

  const isAllSelected =
    isMulti &&
    addSelectAllOption &&
    areAllSelected(defaultOptions?.length, selectedSupplier as ISelectOption[], selectedSupplierId as string[]);

  const loadSupplier = useCallback(
    async (query?: string) => {
      const response: IPaginatedResponse<ISupplier> = await getSuppliers({
        ...paginatedRequest,
        supplierType: supplierType ?? null,
        query,
      });
      const list = response?.items.map((x) => ({ value: x.id.toString(), label: x.name })) ?? [];
      if (addSelectAllOption && list.length > 0) list.unshift(selectAllOption);
      return list;
    },
    [paginatedRequest, supplierType, addSelectAllOption, getSuppliers],
  );

  useEffect(() => {
    //Load with no query
    loadSupplier().then((x) => {
      setDefaultOptions(x);
    });
  }, [loadSupplier]);

  if (isMulti) {
    return (
      <MultiAsyncSelect
        {...props}
        label={label}
        placeholder={placeholder}
        isLoading={isLoading}
        selectedOptions={isAllSelected ? [selectAllOption] : (selectedOption as ISelectOption[] | undefined)}
        selectedOptionIds={isAllSelected ? ['0'] : (selectedOptionId as string[] | undefined)}
        defaultOptions={isAllSelected ? emptyList : defaultOptions}
        loadOptions={loadSupplier}
        onChange={(value) => {
          if (props.onChange && value) {
            var selectedOptions = value as ISelectOption[];
            let areAll = defaultOptions && areAllSelected(defaultOptions?.length, selectedOptions);
            props.onChange(undefined, undefined, areAll ? selectAllList : selectedOptions, areAll);
          }
        }}
      />
    );
  }

  return (
    <AsyncSelect
      label={label}
      placeholder={placeholder}
      isLoading={isLoading}
      selectedOption={selectedOption as ISelectOption | undefined}
      selectedOptionId={selectedOptionId as string | undefined}
      defaultOptions={defaultOptions}
      loadOptions={loadSupplier}
      onChange={(value) => props.onChange && props.onChange(value?.value, value?.label, value ?? undefined, false)}
    />
  );
};
