import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './HelperText.styles';

export interface IHelperText {
  helperText?: React.ReactNode;
  errorText?: string;
}

export const HelperText: React.FunctionComponent<IHelperText> = (props) => {
  const classes = useStyles();

  return (
    <>
      {props.helperText && !props.errorText && (
        <Typography className={classes.helpLabel}>{props.helperText}</Typography>
      )}
      {props.errorText && (
        <Typography component="span" className={classes.errorLabel}>
          {props.errorText} {props.helperText && `(${props.helperText})`}
        </Typography>
      )}
    </>
  );
};
