import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { Table } from '../../../../../../../Shared/Table/Table';
import Link from '../../../../../../../Shared/Link/Link';
import DatePicker from '../../../../../../../Shared/InputFields/DatePicker/Datepicker';
import { timeReportTypes } from '../../../../../Errands/Components/Sale.api';
import { useGetProductionStatsList } from '../ProductionStats.api';
import moment from 'moment';
import { AccessRights, ModuleIdentifiers } from '../../../../../../../Core/Authentication/ModuleAccess';

const defaultFromDate = moment().startOf('month').toDate();

const ProductionList = () => {
  const { pathname } = useLocation();
  const { isLoading, productionStats, getProductionStatsList } = useGetProductionStatsList();
  const [fromDate, setFromDate] = useState(defaultFromDate);

  useEffect(() => {
    getProductionStatsList({ fromDate, toDate: moment(fromDate).endOf('month').startOf('day').toDate() });
  }, [getProductionStatsList, fromDate]);

  return (
    <Table
      customStatisticFilter
      filters={
        <DatePicker
          label="År/månad"
          required
          selected={fromDate}
          onChange={(d: Date | null) => setFromDate(d as Date)}
          dateFormat="MM/yyyy"
          showMonthYearPicker
        />
      }
      customSize={{ fullsize: true }}
      loading={{ loading: isLoading }}
    >
      <TableHead>
        <TableRow>
          <TableCell>Namn</TableCell>
          {timeReportTypes.map((x) => (
            <TableCell key={x.type}>{x.name}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {productionStats?.map((prod) => (
          <TableRow key={prod.companyUserId}>
            <TableCell>
              <Link
                to={`${pathname}/details/${prod.companyUserId}`}
                module={ModuleIdentifiers.Admin}
                accessRights={AccessRights.Read}
              >
                {prod.name}
              </Link>
            </TableCell>
            {timeReportTypes.map((type) => (
              <TableCell key={type.type}>{prod.entries.find((x) => x.type === type.type)?.hours}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ProductionList;
