import React, { useMemo, useState } from 'react';
import Container from '../../../../../Shared/Container/Container';
import Select, { ISelectOption } from '../../../../../Shared/Select/Select';
import Button from '../../../../../Shared/Button/Button';
import PopperMenu from '../../../../../Shared/Popper/Popper';
import DialogForm from '../../../../../Shared/DialogForm/DialogForm';
import DocumentFormat from './PreselectionType/DocumentFormat/DocumentFormat';
import useFormValidation from '../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import AgreementTerms from './PreselectionType/AgreementTerms/AgreementTerms';
import MeetingNotesTemplate from './PreselectionType/MeetingNotes/MeetingNotesTemplate';
import {
  getPreselectionTypeName,
  IPreselectionType,
  IPreselectionTypes,
  IPreselectionDocumentType,
  useGetPreselectionDefault,
} from './Preselection.api';
import OfferTerms from './PreselectionType/OfferTerms/OfferTerms';
import EmailContent from './PreselectionType/EmailContent/EmailContent';
import OfferOptional from './PreselectionType/OfferOptional/OfferOptional';
import useStyles from './Preselection.styles';
import DeliveryAndWarrantyTerms from './PreselectionType/DeliveryAndWarrantyTerms/DeliveryAndWarrantyTerms';

export interface IPreselectionProps {
  preselectionDefaultContent?: string;
  setPreselectionId: (id: string | undefined) => void;
  setIsLoading: (isLoading: boolean) => void;
  saveCallback: (callback: (id?: string) => Promise<boolean>) => void;
  setPreselectionDefaultContent: (content?: string) => void;
  setChildrenComponent: (childrenComponent: JSX.Element) => void;
}

interface ITypeSelectOption extends ISelectOption {
  type: IPreselectionType;
}

const options: ITypeSelectOption[] = IPreselectionTypes.map((x) => ({ label: x.name, value: x.name, type: x.type }));

const Preselection: React.FunctionComponent = () => {
  const classes = useStyles();
  const { isFormValid, formRef } = useFormValidation();
  const { isLoading: isGettingPreselectionDefault, getPreselectionDefault } = useGetPreselectionDefault();

  const [selectedType, setSelectedType] = useState<IPreselectionType>();
  const [preselectionId, setPreselectionId] = useState<string>();
  const [isSaveLoading, setIsLoading] = useState<boolean>(false);
  const [save, setSave] = useState<(id?: string) => Promise<boolean>>();
  const [openPopup, setOpenPopup] = useState(false);
  const [preselectionDocumentType, setPreselectionDocumentType] = useState<IPreselectionDocumentType>();
  const [preselectionDefaultContent, setPreselectionDefaultContent] = useState<string>();
  const [childrenComponent, setChildrenComponent] = useState<JSX.Element>();

  const isLoading = isSaveLoading || isGettingPreselectionDefault;

  const form = useMemo(() => {
    const props: IPreselectionProps = {
      preselectionDefaultContent,
      setPreselectionId,
      setIsLoading,
      saveCallback: setSave,
      setPreselectionDefaultContent: setPreselectionDefaultContent,
      setChildrenComponent,
    };

    switch (selectedType) {
      case IPreselectionType.AgreementTerms:
        return <AgreementTerms {...props} />;
      case IPreselectionType.DocumentFormat:
        return <DocumentFormat setPreselectionDocumentType={setPreselectionDocumentType} {...props} />;
      case IPreselectionType.MeetingNotes:
        return <MeetingNotesTemplate {...props} />;
      case IPreselectionType.OfferTerms:
        return <OfferTerms {...props} />;
      case IPreselectionType.EmailContent:
        return <EmailContent {...props} />;
      case IPreselectionType.OfferOptional:
        return <OfferOptional {...props} />;
      case IPreselectionType.DeliveryAndWarrantyTerms:
        return <DeliveryAndWarrantyTerms {...props} />;  
      default:
        setPreselectionId(undefined);
        setSave(undefined);
        setSelectedType(undefined);
        setPreselectionDefaultContent(undefined);
        return undefined;
    }
  }, [selectedType, preselectionDefaultContent, setPreselectionDocumentType]);

  async function handleRestore() {
    if (preselectionDocumentType !== undefined) {
      setOpenPopup(false);
      setPreselectionDefaultContent((await getPreselectionDefault({ type: preselectionDocumentType })).content);
    }
  }

  function cancel() {
    setSelectedType(undefined);
    setPreselectionDefaultContent(undefined);
    setChildrenComponent(undefined);
  }

  async function handleSave() {
    if (!save) return;
    var success = await save(preselectionId);
    //TODO: handle general error?
    if (success) cancel();
  }

  const handleSelectType = (type: IPreselectionType) => {
    setSelectedType(type);
    setChildrenComponent(undefined);
    switch (type) {
      case IPreselectionType.AgreementTerms:
        setPreselectionDocumentType(IPreselectionDocumentType.Agreement);
        return;
      case IPreselectionType.DeliveryAndWarrantyTerms:
        setPreselectionDocumentType(IPreselectionDocumentType.DeliveryAndWarrantyTerms);
        return;  
      case IPreselectionType.MeetingNotes:
        setPreselectionDocumentType(IPreselectionDocumentType.MeetingNotes);
        return;
      default:
        setPreselectionDocumentType(undefined);
        return;
    }
  };

  function Actions() {
    return (
      <>
        <PopperMenu
          color="secondary"
          variant="outlined"
          alwaysDialog
          customContent
          disabled={selectedType === undefined || isLoading}
          open={openPopup}
          setOpen={setOpenPopup}
          title={`Är du säker att du vill återställa ${getPreselectionTypeName(selectedType!).toLowerCase()}?`}
          content={
            <>
              <DialogForm
                actions={
                  <>
                    <Button color="default" variant="outlined" onClick={() => setOpenPopup(false)}>
                      Nej
                    </Button>
                    <Button variant="outlined" onClick={handleRestore}>
                      Ja
                    </Button>
                  </>
                }
                children={null}
              />
            </>
          }
        >
          Återställ
        </PopperMenu>

        <Button color="default" variant="outlined" onClick={cancel}>
          Avbryt
        </Button>
        <Button disabled={!save || !isFormValid || isLoading} color="primary" variant="contained" onClick={handleSave}>
          Bekräfta
        </Button>
      </>
    );
  }

  return (
    <>
      <Container
        customSize={{ md: 7, lg: 7 }}
        form
        ref={formRef}
        label="Texter och förval"
        actions={selectedType !== undefined ? <Actions /> : undefined}
      >
        <div className={classes.selectContainer}>
          <Select
            inputFieldProps={{
              disabled: isLoading,
              fullwidth: true,
              label: 'Välj område',
              placeholder: 'Välj område',
            }}
            selectProps={{
              isDisabled: isLoading,
              value: options.find((x) => x.type === selectedType),
              options: options,
              onChange: (selectedValue) => {
                var preselectionType = (selectedValue as ITypeSelectOption).type;
                handleSelectType(preselectionType);
              },
            }}
          />
        </div>
        {selectedType !== undefined && form}
      </Container>
      {childrenComponent && childrenComponent}
    </>
  );
};

export default Preselection;
