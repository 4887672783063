export default interface IErrorResponse {
  errorCode: ErrorCode;
  message: string;
}

export function isErrorResponse(result: any): result is IErrorResponse {
  return result && result.hasOwnProperty('errorCode') && result.hasOwnProperty('message');
}

export enum ErrorCode {
  OverlappingCalendarEntry = 1,
  AnonymousTokenNotFound = 2,
  EntityInUse = 3,
  InvalidInvalidIdentificationNumber = 4,
  EmailExists = 5,
}
