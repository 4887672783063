import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import Datepicker from '../../../../../../../../../Shared/InputFields/DatePicker/Datepicker';
import Button from '../../../../../../../../../Shared/Button/Button';
import DialogForm from '../../../../../../../../../Shared/DialogForm/DialogForm';
import { useRenewAgreement } from '../../../CoreSystem.api';
import { isResultError } from '../../../../../../../../../Shared/Api/response/ICreateResult';
import moment from 'moment';
import { useHistory } from 'react-router';

export interface IRenewAgreementPopup {
  startDate: Date;
  agreementId: string;
  customerId: string;
  open: boolean;
  facilityId?: number | null;
  isSaleAgreement: boolean;
  setOpen: (open: boolean) => void;
  onRenew?: () => void;
}

const RenewAgreementPopup = (props: IRenewAgreementPopup) => {
  const { startDate, agreementId, customerId, open, facilityId, isSaleAgreement, setOpen } = props;
  const history = useHistory();
  const { isLoading: isRenewAgreementLoading, renewAgreement } = useRenewAgreement(customerId);
  const [renewStartDate, setRenewStartDate] = useState<Date>(new Date(startDate));
  const [renewEndDate, setRenewEndDate] = useState<Date | null>(null);

  useEffect(() => {
    if (agreementId && startDate) {
      setRenewStartDate(new Date(startDate));
    }
  }, [agreementId, startDate]);

  const handleRenewAgreement = async () => {
    const result = await renewAgreement(
      {
        renewEndDate,
        renewStartDate,
        isSaleAgreement: isSaleAgreement,
      },
      agreementId,
    );
    if (!isResultError(result)) {
      setOpen(false);

      if (isSaleAgreement) history.push(`/errands/offers/${result}/draft`);
      else history.push(`/customers/${customerId}/${facilityId ? 'facility/' + facilityId + '/' : ''}agreements/${result}`);
    }
  };
  const Actions = () => (
    <>
      <Button color="default" variant="outlined" onClick={() => setOpen(false)}>
        Avbryt
      </Button>
      <Button variant="contained" onClick={handleRenewAgreement} loading={isRenewAgreementLoading}>
        Förnya avtal
      </Button>
    </>
  );

  return (
    <Dialog open={open} onClose={setOpen}>
      <DialogContent>
        <DialogForm actions={<Actions />}>
          <Datepicker
            required
            minDate={moment(startDate).add(1, 'days').toDate() ?? new Date()}
            label="Från"
            selected={renewStartDate}
            onChange={(d: Date | null) => setRenewStartDate(d as Date)}
          />
          <Datepicker
            required
            minDate={moment(startDate).add(1, 'days').toDate()}
            label="Till"
            selected={renewEndDate}
            onChange={setRenewEndDate}
          />
        </DialogForm>
      </DialogContent>
    </Dialog>
  );
};

export default RenewAgreementPopup;
