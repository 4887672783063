import { Dialog, DialogContent, Grid } from '@material-ui/core';
import React from 'react';
import Button from '../../../../../../../../Shared/Button/Button';
import { DialogContentHeader } from '../../../../../../../../Shared/DialogContentHeader/DialogContentHeader';
import DialogForm from '../../../../../../../../Shared/DialogForm/DialogForm';
import { useStyles } from '../../../RemoveFacilityModal/RemoveOrArchiveFacilityModal.style';

export interface IRemoveSystemModalProps {
  systemId: number;
  open: boolean;
  setOpen: (open: boolean) => void;
  handleDelete: () => void;
  isLoading?: boolean;
}

const RemoveSystemModal = (props: IRemoveSystemModalProps) => {
  const classes = useStyles();
  const { systemId, open, isLoading, setOpen, handleDelete } = props;

  const Action = () => (
    <>
      <Button color="default" variant="outlined" onClick={() => setOpen(false)}>
        Nej
      </Button>
      <Button loading={isLoading} className={classes.cautionButton} variant="outlined" onClick={handleDelete}>
        Ja
      </Button>
    </>
  );

  return (
    <Dialog open={open} onClose={setOpen}>
      <DialogContent>
        <DialogContentHeader
          onClose={() => setOpen(false)}
          title={`Ta bort system `}
          titleId={systemId?.toString() ?? ''}
        />
        <DialogForm actions={<Action />}>
          <Grid container item>
            Det finns avtal kopplade till systemet. Vill du avsluta det? Avtalet kommer att sägas upp med omedelbar
            verkan och detta går ej att ångra senare
          </Grid>
        </DialogForm>
      </DialogContent>
    </Dialog>
  );
};

export default RemoveSystemModal;
