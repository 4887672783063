import React from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { useStyles } from './MobileEvent.styles';
import { calendarEntryTypes, CalendarEntryType } from '../../../../Modules/Booking/Booking.api';
import { IActivity } from '../ActivityForm/ActivityForm';
import { SaleStatus } from '../../../../Modules/Errands/Components/Sale.api';

interface IEventProps {
  label: CalendarEntryType;
  start: Date | undefined;
  end: Date | undefined;
  isCompanyCalendar: boolean;
  users?: string[];
  isBlueBooking?: boolean;
  customTitle?: string;
  type?: CalendarEntryType;
  activity?: IActivity | undefined;
  showDate?: boolean;
}

const MobileEvent: React.FC<IEventProps> = (props) => {
  const classes = useStyles();
  const getBackgroundColor = () => {
    switch (props.type) {
      case CalendarEntryType.OffDuty:
      case CalendarEntryType.Sick:
      case CalendarEntryType.SickChild:
      case CalendarEntryType.Holiday:
        return classes.gray;
      case CalendarEntryType.Misc:
        return classes.blue;
      default:
        if (props.activity?.saleStatus) {
          if (props.activity?.saleStatus === SaleStatus.invoicing || props.activity?.saleStatus === SaleStatus.completed || props.activity?.saleStatus === SaleStatus.archived) {
            return classes.green;
          }
          if (props.activity?.saleStatus === SaleStatus.installed) {
            return classes.yellow;
          }
          let today = new Date();
          today.setDate(today.getDate() - 1);
          if (props.activity?.endDate && new Date(props.activity?.endDate) < today) {
            return classes.red;
          }
        }
        return classes.gray;
    }
  };

  return (
    <>
      <Grid role="title" container>
        <table>
          <tr>
            {props.showDate ? <td>{props.start ? moment(props.start).format('dddd DD/MM') : ''}</td> : <td>{props.isBlueBooking ?? "Heldag"} {props.start ? moment(props.start).format('HH:mm') : ''}–{props.end ? moment(props.end).format('HH:mm') : ''}</td>}
            <td>
              {props.users && (
                <span>{props?.users?.length < 2 ? props.users[0] : props.users.length + ' användare'}</span>
              )}
            </td>
            <td><span className={getBackgroundColor()}>{props.customTitle ?? calendarEntryTypes.find((x) => x.type === props.label)?.name}</span></td>
          </tr>
        </table>        
      </Grid>
    </>
  );
};

export default MobileEvent;
