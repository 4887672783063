import React, { useState, ReactNode, useEffect } from 'react';
import { Tab, Tabs as MuiTabs, TabProps, Grid } from '@material-ui/core';
import { ICustomSize } from '../Container/Container';
import { useStyles } from './Tabs.styles';
interface ITabsProps {
  startIndex?: number;
  children: (React.ReactElement<TabProps> | ReactNode)[];
  onChange?: (index: number) => void;
  customSize?: ICustomSize;
  padding?: number;
  columns?: boolean;
}

const Tabs = (props: ITabsProps) => {
  const classes = useStyles();
  const [currentIndex, setCurrentValue] = useState(props.startIndex ?? 0);

  const handleCurrentIndexChange = (event: React.ChangeEvent<{}>, newIndex: number) => {
    setCurrentValue(newIndex);
    props.onChange && props.onChange(newIndex);
  };

  useEffect(() => {
    props.startIndex && setCurrentValue(props.startIndex);
  }, [props.startIndex]);

  const tabs: React.ReactElement<TabProps>[] = props.children
    .filter(
      (x) => x && (x as React.ReactElement<TabProps>).props.label && (x as React.ReactElement<TabProps>).props.children,
    )
    .map((x) => x as React.ReactElement<TabProps>);

  return (
    <Grid
      item
      container
      direction="column"
      lg={props.customSize?.fullsize ? 12 : props.customSize?.lg ?? 6}
      md={props.customSize?.fullsize ? 12 : props.customSize?.md ?? 6}
      sm={props.customSize?.fullsize ? 12 : props.customSize?.sm ?? 12}
      xs={12}
      className={classes.root}
    >
      <MuiTabs
        scrollButtons="auto"
        variant="scrollable"
        value={currentIndex}
        onChange={handleCurrentIndexChange}
        indicatorColor="primary"
      >
        {tabs.map((x, index) => (
          <Tab label={x.props.label} value={index} key={index} />
        ))}
      </MuiTabs>
      <Grid item xs style={{display: props.columns ? 'inline-flex' : 'block', flexWrap: 'wrap'}}>
        {tabs.map(
          (x, index) => currentIndex === index && <React.Fragment key={index}>{x.props.children}</React.Fragment>,
        )}
      </Grid>
    </Grid>
  );
};

export default Tabs;
