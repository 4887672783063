import { Grid, GridSize } from '@material-ui/core';
import React from 'react';
import InputLabel from '../InputLabel/InputLabel';

export interface IViewFieldProps {
  label?: string;
  value?: string | number;
  multiline?: boolean;
  endAdornment?: React.ReactNode;
  fullwidth?: boolean;
  customWidth?: GridSize;
}

export const ViewField: React.FunctionComponent<IViewFieldProps> = (props) => {
  const Content = () => (
    <>
      {props.value}
      {props.children}
      {props.endAdornment}
    </>
  );

  return (
    <Grid
      container
      item
      direction="column"
      style={{ marginBottom: '0', overflow: 'hidden' }}
      md={props.fullwidth ? 12 : props.customWidth ?? 6}
      sm={props.fullwidth ? 12 : props.customWidth ?? 6}
      xs={props.customWidth ?? 12}
    >
      <InputLabel label={props.label} />
      {props.multiline ? (
        <pre>
          <Content />
        </pre>
      ) : (
        <span>
          <Content />
        </span>
      )}
    </Grid>
  );
};
