import React, { useState, useEffect } from 'react';
import Button from '../../../../../../Shared/Button/Button';
import { useHistory, useParams } from 'react-router-dom';
import { Fieldset } from '../../../../../../Shared/Form/Fieldset/Fieldset';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import useInputState from '../../../../../../Shared/Hooks/UseInputState/UseInputState';
import {
  IUpsertSupplierCommand,
  IUpsertSupplierValidationErrors,
  useGetSupplier,
  useUpsertSupplier,
  SupplierType,
  supplierTypes,
} from '../Suppliers.api';
import useFormValidation from '../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import Container from '../../../../../../Shared/Container/Container';
import { isResultError } from '../../../../../../Shared/Api/response/ICreateResult';
import Select, { ISelectOption } from '../../../../../../Shared/Select/Select';
import IdentificationNumberField from '../../../../../../Shared/InputFields/IdentificationNumberField/IdentificationNumberField';
import { CustomerType } from '../../../../../../Shared/Misc/CustomerType';
import PostalCodeField from '../../../../../../Shared/InputFields/PostalCodeField/PostalCodeField';
import EmailField from '../../../../../../Shared/InputFields/EmailField/EmailField';
import PhoneNumberField from '../../../../../../Shared/InputFields/PhoneNumberField/PhoneNumberField';

const supplierTypeOptions: ISelectOption[] = supplierTypes.map((x) => ({
  label: x.name,
  value: (x.type as number).toString(),
}));

const SupplierForm: React.FunctionComponent = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { isLoading: isGetLoading, getSupplier } = useGetSupplier();
  const { isLoading: isUpsertLoading, upsert } = useUpsertSupplier();

  const { isFormValid, formRef } = useFormValidation();

  const [originalName, setOriginalName] = useState('');
  const [supplierType, setSupplierType] = useState<SupplierType>(SupplierType.Hardware);
  const [customerNumber, changeCustomerNumber, setCustomerNumber] = useInputState('');
  const [identificationNumber, changeIdentificationNumber, setIdentificationNumber] = useInputState('');
  const [name, changeName, setName] = useInputState('');
  const [orderEmail, changeOrderEmail, setOrderEmail] = useInputState('');
  const [orderPhone, changeOrderPhone, setOrderPhone] = useInputState('');
  const [orderContact, changeOrderContact, setOrderContact] = useInputState('');
  const [address, changeAddress, setAddress] = useInputState('');
  const [code, changeCode, setCode] = useInputState('');
  const [postalCode, changePostalCode, setPostalCode] = useInputState('');
  const [city, changeCity, setCity] = useInputState('');
  const [supportEmail, changeSupportEmail, setSupportEmail] = useInputState('');
  const [supportPhone, changeSupportPhone, setSupportPhone] = useInputState('');
  const [supportContact, changeSupportContact, setSupportContact] = useInputState('');

  const [errors, setErrors] = useState<IUpsertSupplierValidationErrors>();

  useEffect(() => {
    async function loadSupplier() {
      const supplier = await getSupplier(id);

      setOriginalName(supplier.name);
      setSupplierType(supplier.supplierType);
      setCustomerNumber(supplier.customerNumber ?? '');
      setIdentificationNumber(supplier.identificationNumber);
      setName(supplier.name);
      setOrderEmail(supplier.orderEmail);
      setOrderPhone(supplier.orderPhone ?? '');
      setOrderContact(supplier.orderContact ?? '');
      setAddress(supplier.address ?? '');
      setPostalCode(supplier.postalCode ?? '');
      setCity(supplier.city ?? '');
      setSupportEmail(supplier.supportEmail ?? '');
      setSupportContact(supplier.supportContact ?? '');
      setSupportPhone(supplier.supportPhone ?? '');
      setCode(supplier.code ?? '');
    }

    if (id) {
      loadSupplier();
    }
  }, [
    getSupplier,
    id,
    setAddress,
    setCity,
    setCustomerNumber,
    setCode,
    setIdentificationNumber,
    setName,
    setOrderEmail,
    setPostalCode,
    setOrderContact,
    setOrderPhone,
    setSupportContact,
    setSupportEmail,
    setSupportPhone,
  ]);

  async function upsertSupplier() {
    const upsertCommand: IUpsertSupplierCommand = {
      name: name,
      customerNumber: customerNumber,
      identificationNumber: identificationNumber,
      orderEmail: orderEmail,
      orderContact: orderContact,
      orderPhone: orderPhone,
      address: address,
      postalCode: postalCode,
      city: city,
      code: code,
      supplierType: supplierType as SupplierType,
      supportContact: supportContact,
      supportEmail: supportEmail,
      supportPhone: supportPhone,
    };

    const result = await upsert(upsertCommand, id);

    isResultError(result) ? setErrors(result) : history.push('/admin/supplier/' + result);
  }

  function Actions() {
    const cancel = () => (id ? history.push(`/admin/supplier/${id}`) : history.push('/admin/supplier'));

    return (
      <>
        <Button color="default" variant="outlined" disabled={isUpsertLoading} onClick={cancel}>
          Avbryt
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={upsertSupplier}
          loading={isUpsertLoading}
          disabled={!isFormValid}
        >
          {id ? 'Spara' : 'Skapa'}
        </Button>
      </>
    );
  }

  return (
    <Container
      form
      label={id ? `Redigera ${originalName}` : 'Skapa leverantör'}
      actions={<Actions />}
      ref={formRef}
      loading={isGetLoading}
    >
      <Fieldset legend="Leverantörens uppgifter">
        <InputField
          label="Vårt Kundnummer"
          type="text"
          helperText="Ert kundnummer hos leverantören"
          value={customerNumber}
          onChange={changeCustomerNumber}
          errorText={errors?.customerNumber}
        />
        <IdentificationNumberField
          customerType={CustomerType.Company}
          required
          value={identificationNumber}
          onChange={changeIdentificationNumber}
          errorText={errors?.identificationNumber}
        />
        <Select
          inputFieldProps={{
            label: 'Typ',
            required: true,
            errorText: errors?.supplierType,
            viewOnly: id !== undefined,
          }}
          viewOnlyValue={supplierTypeOptions.find((x) => Number(x.value) === supplierType)?.label}
          selectProps={{
            value: supplierTypeOptions.filter((x) => Number(x.value) === supplierType),
            onChange: (selectedValue) => {
              setSupplierType(Number((selectedValue as ISelectOption).value));
            },
            options: supplierTypeOptions,
            isClearable: false,
          }}
        />
        <InputField
          label="Leverantörsnamn"
          type="text"
          required
          value={name}
          onChange={changeName}
          errorText={errors?.name}
        />
        <InputField
          label="Gatuadress"
          type="text"
          value={address}
          onChange={changeAddress}
          errorText={errors?.address}
        />
        <InputField label="Kod" type="text" value={code} onChange={changeCode} errorText={errors?.code} />
        <PostalCodeField
          label="Postnummer"
          type="text"
          value={postalCode}
          onChange={changePostalCode}
          errorText={errors?.postalCode}
        />
        <InputField label="Ort" type="text" value={city} onChange={changeCity} errorText={errors?.city} />
        <Fieldset legend="Kontakt order">
          <EmailField
            label="E-post"
            type="email"
            required
            fullwidth
            value={orderEmail}
            onChange={changeOrderEmail}
            errorText={errors?.orderEmail}
          />
          <InputField
            label="Namn"
            type="text"
            value={orderContact}
            onChange={changeOrderContact}
            errorText={errors?.orderContact}
          />
          <PhoneNumberField
            label={'Telefon'}
            value={orderPhone}
            onChange={changeOrderPhone}
            errorText={errors?.orderPhone}
          />
        </Fieldset>

        <Fieldset legend="Kontakt support">
          <EmailField
            label="E-post"
            fullwidth
            value={supportEmail}
            onChange={changeSupportEmail}
            errorText={errors?.supportEmail}
          />
          <InputField
            label="Namn"
            type="text"
            value={supportContact}
            onChange={changeSupportContact}
            errorText={errors?.supportContact}
          />
          <PhoneNumberField
            label={'Telefon'}
            value={supportPhone}
            onChange={changeSupportPhone}
            errorText={errors?.supportPhone}
          />
        </Fieldset>
      </Fieldset>
    </Container>
  );
};

export default SupplierForm;
