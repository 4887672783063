import { Dialog, DialogContent } from '@material-ui/core';
import React, { useState } from 'react';
import useUserContext from '../../../../../Core/Authentication/UserContext';
import Button from '../../../../../Shared/Button/Button';
import CompanyUserSelect from '../../../Admin/Components/Users/UserSelect/CompanyUserSelect';
import { ICompanyListItem } from '../../Companies.api';
import { DialogContentHeader } from '../../../../../Shared/DialogContentHeader/DialogContentHeader';
import DialogForm from '../../../../../Shared/DialogForm/DialogForm';

export interface ICompanyUserSelectModalProps {
  open: boolean;
  isLoading?: boolean;
  setOpen: (open: boolean) => void;
  onClose: () => void;
  company: ICompanyListItem;
}
const CompanyUserSelectModal = (props: ICompanyUserSelectModalProps) => {
  const { open, company, onClose, setOpen } = props;
  const userContext = useUserContext();
  const [userId, setUserId] = useState<string>();

  const Actions = () => {
    return (
      <>
        <Button color="default" variant="outlined" onClick={onClose}>
          Avbryt
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => (userId ? userContext.impersonateCompany(parseInt(userId)) : undefined)}
        >
          Impersonera
        </Button>
      </>
    );
  };
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <DialogContentHeader onClose={() => setOpen(false)} title={company.name} titleId={company.id.toString()} />
          <DialogForm actions={<Actions />}>
            <CompanyUserSelect
              isUserActived
              fullwidth
              isClearable
              label="Impersonering"
              selectedCompanyUserId={userId}
              companyId={company.id.toString()}
              onChange={setUserId}
            />
          </DialogForm>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CompanyUserSelectModal;
