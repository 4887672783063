import React, { ReactNode } from 'react';
import NavigateNext from '@material-ui/icons/NavigateNextRounded';
import NavigateBack from '@material-ui/icons/NavigateBeforeRounded';
import IconButton from '../../../../../Shared/IconButton/IconButton';
import EditActivity from '../EditActivity/EditActivity';
import { useStyles } from './Toolbar.styles';
import { IActivityFormProps } from '../ActivityForm/ActivityForm';
import moment from 'moment';
import { formatDateMonthName } from '../../../../../Shared/Formatting/formatDate';
import AccessGuard from '../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { AccessRights, ModuleIdentifiers } from '../../../../../Core/Authentication/ModuleAccess';
import Datepicker from '../../../../../Shared/InputFields/DatePicker/Datepicker';
import { useMediaQuery, useTheme } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined';
import AddIcon from '@material-ui/icons/AddCircleOutlineRounded';

export enum Stepping {
  Month,
  Week,
  Day,
}

interface IToolbarProps {
  date: Date;
  onDateChanged: (date: Date) => void;
  actions?: ReactNode;
  leftActions?: ReactNode;
  stepping?: Stepping;
  editProps?: IActivityFormProps;
}

const Toolbar = (props: IToolbarProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { actions, leftActions, editProps } = props;
  const stepping: Stepping = props.stepping ?? Stepping.Month;

  function nextMonth() {
    const newDate = new Date(props.date);
    newDate.setMonth(props.date.getMonth() + 1);
    props.onDateChanged(newDate);
  }

  function prevMonth() {
    const newDate = new Date(props.date);
    newDate.setMonth(props.date.getMonth() - 1);
    props.onDateChanged(newDate);
  }

  function nextWeek() {
    const newDate = new Date(props.date);
    newDate.setDate(props.date.getDate() + 7);
    props.onDateChanged(newDate);
  }

  function prevWeek() {
    const newDate = new Date(props.date);
    newDate.setDate(props.date.getDate() - 7);
    props.onDateChanged(newDate);
  }

  function nextDay() {
    const newDate = new Date(props.date);
    newDate.setDate(props.date.getDate() + 1);
    props.onDateChanged(newDate);
  }

  function prevDay() {
    const newDate = new Date(props.date);
    newDate.setDate(props.date.getDate() - 1);
    props.onDateChanged(newDate);
  }

  function next() {
    if (stepping === Stepping.Month) {
      return nextMonth();
    }
    if (stepping === Stepping.Week) {
      nextWeek();
    } else {
      nextDay();
    }
  }
  function prev() {
    if (stepping === Stepping.Month) {
      return prevMonth();
    }
    if (stepping === Stepping.Week) {
      prevWeek();
    } else {
      prevDay();
    }
  }

  const mobileToolbar = 
  (<div className={classes.root}>
    <div className={classes.container}>
      <span className={classes.label}>
        V.{moment(props.date as Date).isoWeek()} - {moment(props.date as Date).format("YYYY-MM-DD")} 
      </span>
      <div className={classes.chooseDatePicker}>
        <Datepicker
          onChange={(x: Date | null) => {
            if (x) props.onDateChanged(x);
          }}
          selected={props.date}
          customInput={<CalendarIcon style={ {color: '#fff'} } />}
        />
      </div>
      {leftActions}
    </div>
    <div className={classes.container}>
      {actions}
      <AccessGuard module={ModuleIdentifiers.Booking} accessRights={AccessRights.Write}>
        <EditActivity defaultDate={props.date} icon={true} {...editProps}>
          <AddIcon />
        </EditActivity>
      </AccessGuard>
    </div>
  </div>);

  return mobile ? mobileToolbar : (
    <div className={classes.root}>
      <div className={classes.container}>
        <span className={classes.label}>
          {stepping === Stepping.Week
            ? `V.${moment(props.date as Date).isoWeek()} `
            : `${formatDateMonthName(props.date as Date)} `}{moment(props.date as Date).format('YYYY')}
        </span>
        <div>
          <IconButton className={classes.navigateButton} onClick={prev} color="primary">
            <NavigateBack />
          </IconButton>
          <IconButton className={classes.navigateButton} onClick={next} color="primary">
            <NavigateNext />
          </IconButton>
        </div>
        <div className={classes.todayButtonWrapper}>
          <span onClick={() => props.onDateChanged(new Date())}>Idag</span>
        </div>
        <div className={classes.chooseDatePicker}>
          <Datepicker
            onChange={(x: Date | null) => {
              if (x) props.onDateChanged(x);
            }}
            selected={props.date}
            fullwidth
          />
        </div>
        {leftActions}
      </div>
      <div className={classes.container}>
        {actions}
        <AccessGuard module={ModuleIdentifiers.Booking} accessRights={AccessRights.Write}>
          <EditActivity defaultDate={props.date} icon={true} {...editProps}>
            <AddIcon />
          </EditActivity>
        </AccessGuard>
      </div>
    </div>);
};

export default Toolbar;
