import React, { createContext, useContext, useState } from 'react';
import Snackbar from './Snackbar/Snackbar';
import ValidationErrorResultType from '../../Api/response/ValidationErrorResultType';

interface IErrorMessage {
  message: React.ReactNode;
  action?: React.ReactNode;
}

interface IErrorMessageContext {
  setErrorMessage: (errorMessage: IErrorMessage) => void;
  setValidationErrorMessage: (errors: ValidationErrorResultType<object>) => void;
}

const ErrorMessageContext = createContext<IErrorMessageContext>({} as IErrorMessageContext);

export const ErrorMessageContextProvider: React.FunctionComponent = (props) => {
  const [errorMessage, setErrorMessage] = useState<IErrorMessage | null>(null);
  const [context] = useState<IErrorMessageContext>({
    setErrorMessage: setErrorMessage,
    setValidationErrorMessage: setValidationErrorMessage,
  });

  function onClose() {
    setErrorMessage(null);
  }

  function setValidationErrorMessage(errors: ValidationErrorResultType<object>) {
    const message = Object.entries(errors).map(([key, value]: [string, string[]]) => <li>{value.join(', ')}</li>);
    setErrorMessage({ message: <ul>{message}</ul> });
  }

  return (
    <ErrorMessageContext.Provider value={context}>
      <>
        {props.children}
        <Snackbar
          message={errorMessage?.message}
          action={errorMessage?.action}
          open={errorMessage !== null}
          onClose={onClose}
          severity={'error'}
        />
      </>
    </ErrorMessageContext.Provider>
  );
};

const useErrorMessage = () => useContext(ErrorMessageContext);

export default useErrorMessage;
