import React, { useContext } from 'react';
import { FormControl, Grid, RadioGroup } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { ContainerContext } from '../../Container/Container';
import InputLabel from '../InputLabel/InputLabel';
import { useStyles } from './RadioField.styles';

interface IRadioFieldProps {
  label: string;
  required?: boolean;
  fullwidth?: boolean;
}

const RadioField: React.FunctionComponent<IRadioFieldProps> = (props) => {
  const classes = useStyles();
  const containerContext = useContext(ContainerContext);

  let skeletonRows = [];

  for (var i = 0; i < React.Children.count(props.children); i++) {
    skeletonRows.push(
      <div className={classes.skeletonRow} key={`radio-skeleton-${i}`}>
        <Skeleton variant="circle" animation="wave" className={classes.skeletonRadioButton} />
        <Skeleton variant="text" animation="wave" className={classes.skeletonInput} />
      </div>,
    );
  }
  return (
    <Grid item md={props.fullwidth ? 12 : 6} sm={props.fullwidth ? 12 : 6} xs={12}>
      <FormControl className={classes.formControl}>
        {containerContext?.loading ? (
          <>
            <Skeleton variant="text" animation="wave" className={classes.skeletonLabel} />
            {skeletonRows}
          </>
        ) : (
          <>
            <InputLabel label={props.label} required={props.required} />
            <RadioGroup name={props.label}>{props.children}</RadioGroup>
          </>
        )}
      </FormControl>
    </Grid>
  );
};

export default RadioField;
