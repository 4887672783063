import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Container from '../../../../../../../Shared/Container/Container';
import { ViewAddress } from '../../../../../../../Shared/InputFields/Address/ViewAddress';
import { useGetFacility } from '../Facilities.api';
import SystemList from './SystemList/SystemList';

const ViewFacility = () => {
  const { customerId, facilityId } = useParams<{ facilityId: string; customerId: string }>();
  const { getFacility, isLoading, facility } = useGetFacility(customerId);

  useEffect(() => {
    if (facilityId) {
      getFacility(facilityId);
    }
  }, [getFacility, facilityId]);

  return (
    <Grid container spacing={2} wrap="wrap-reverse">
      <Grid item xs={12} sm={12} md={8}>
        <SystemList coreSystems={facility?.coreSystems} isLoading={isLoading} />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Container label={facility?.name} loading={isLoading}>
          <ViewAddress address={facility?.address} postalCode={facility?.postalCode} city={facility?.city} />
        </Container>
      </Grid>
    </Grid>
  );
};

export default ViewFacility;
