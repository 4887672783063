import React, { useEffect } from 'react';
import IPaginatedResponse from '../../Api/response/IPaginatedResponse';
import { TablePagination } from '@material-ui/core';

export interface ITablePaginationProps {
  datasource: IPaginatedResponse<any>;
  onChangePage: (pageNumber: number) => void;
  onChangeRowsPerPage: (pageSize: number) => void;
  addPageSize?: number[];
  fixed?: boolean;
}

function TablePaginationWithDatasource(props: ITablePaginationProps) {
  const { datasource, addPageSize: customPageSize, onChangePage, onChangeRowsPerPage, fixed } = props;

  let rowsPerPageOptions = [25, 50, 100, 250];

  if (customPageSize) {
    rowsPerPageOptions = customPageSize.concat(rowsPerPageOptions).sort(function (a, b) {
      return a - b;
    });
  }

  useEffect(() => {
    if (datasource.hasPreviousPage && datasource.items.length === 0) onChangePage(datasource.pageIndex - 1);
  }, [datasource, onChangePage]);

  return (
    <TablePagination
      component="div"
      count={datasource.totalCount}
      rowsPerPage={datasource.pageSize}
      rowsPerPageOptions={rowsPerPageOptions}
      page={datasource.pageIndex - 1}
      onChangePage={(_, page) => {
        onChangePage(page + 1);
        !fixed && window.scrollTo({ top: 0 });
      }}
      onChangeRowsPerPage={(e) => {
        onChangeRowsPerPage(parseInt(e.target.value));
      }}
    />
  );
}
export default TablePaginationWithDatasource;
