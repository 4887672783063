import React, { useEffect, useState, useCallback } from 'react';
import { Table } from '../../../../../../../Shared/Table/Table';
import { TableHead, TableRow, TableCell, TableBody, MenuItem, ListItemIcon, Typography } from '@material-ui/core';
import SortButton from '../../../../../../../Shared/Table/SortButton/SortButton';
import { useHistory } from 'react-router-dom';
import useTable from '../../../../../../../Shared/Table/useTable';
import { useGetCompanyBranches, useDeleteCompanyBranch } from '../CompanyBranch.api';
import SearchInput from '../../../../../../../Shared/InputFields/SearchInput/SearchInput';
import AccessGuard from '../../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { ModuleIdentifiers, AccessRights } from '../../../../../../../Core/Authentication/ModuleAccess';
import TablePaginationWithDatasource from '../../../../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import { DropdownCell } from '../../../../../../../Shared/Table/DropdownCell/DropdownCell';
import Link from '../../../../../../../Shared/Link/Link';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import IconButton from '../../../../../../../Shared/IconButton/IconButton';
import { ConfirmDeleteOrArchiveRow } from '../../../../../../../Shared/Table/ConfirmDeleteOrArchiveRow/ConfirmDeleteOrArchiveRow';

const CompanyBranchList = () => {
  const history = useHistory();
  const { isLoading, response: companyBranches, getCompanyBranches } = useGetCompanyBranches();
  const { isLoading: isDeleteLoading, deleteCompanyBranch } = useDeleteCompanyBranch();

  const {
    toggleSort,
    sortProperty,
    sortDirection,
    pageSize,
    setPageSize,
    setPageNumber,
    query,
    setQuery,
    paginatedRequest,
  } = useTable();
  const [emptyMessage, setEmptyMessage] = useState('');
  const [confirmDeleteId, setConfirmDeleteId] = useState(-1);

  const getList = useCallback(async () => {
    const response = await getCompanyBranches(paginatedRequest);

    if (response?.totalCount <= 0) {
      if (query) {
        setEmptyMessage('Filtreringen gav inga resultat');
      } else {
        setEmptyMessage('Inga filialer tillagda');
      }
    }
  }, [paginatedRequest, query, getCompanyBranches]);

  useEffect(() => {
    getList();
  }, [getList]);

  async function handleDelete() {
    await deleteCompanyBranch(confirmDeleteId);
    getList();
    setConfirmDeleteId(-1);
  }

  return (
    <Table
      minWidth
      noBorders
      label={`Företagsfiliallista (${companyBranches?.totalCount ?? 0})`}
      filters={<SearchInput type="search" placeholder="Sök" onChange={setQuery} />}
      iconButtons={
        <AccessGuard module={ModuleIdentifiers.Admin} accessRights={AccessRights.Write}>
          <IconButton
            size="small"
            aria-label="Lägg till branch"
            onClick={() => {
              history.push(`${history.location.pathname}/create`);
            }}
          >
            <AddIcon />
          </IconButton>
        </AccessGuard>
      }
      customSize={{
        fullsize: true,
      }}
      pagination={
        companyBranches && (
          <TablePaginationWithDatasource
            datasource={companyBranches}
            onChangePage={setPageNumber}
            onChangeRowsPerPage={setPageSize}
          />
        )
      }
      empty={{
        empty: companyBranches && companyBranches.totalCount <= 0 ? true : false,
        message: emptyMessage,
      }}
      loading={{
        loading: isLoading,
        skeletonRows: pageSize,
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>
            <span className={'sr-only'}>Knappar</span>
          </TableCell>
          <TableCell>
            Namn
            <SortButton property="name" sortProperty={sortProperty} sortDirection={sortDirection} onSort={toggleSort} />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {companyBranches?.items.map((companyBranch) => (
          <TableRow key={companyBranch.id}>
            {confirmDeleteId !== companyBranch.id ? (
              <>
                <DropdownCell>
                  <MenuItem
                    onClick={() => {
                      setConfirmDeleteId(companyBranch.id);
                    }}
                  >
                    <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                    <Typography variant="inherit">Ta bort</Typography>
                  </MenuItem>
                </DropdownCell>
                <TableCell>
                  <Link
                    to={`${history.location.pathname}/${companyBranch.id}/edit`}
                    module={ModuleIdentifiers.Admin}
                    accessRights={AccessRights.Write}
                  >
                    {companyBranch.name}
                  </Link>
                </TableCell>
              </>
            ) : (
              <ConfirmDeleteOrArchiveRow
                onConfirm={handleDelete}
                onCancel={() => {
                  setConfirmDeleteId(-1);
                }}
                colspan={3}
                label={companyBranch.name}
                loading={isDeleteLoading}
              />
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CompanyBranchList;
