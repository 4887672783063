import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { useStyles } from './LoadingCalendar.styles';

interface IProps {
  numberOfItems?: number;
}

const LoadingCalendar = (props: IProps) => {
  const classes = useStyles();

  const SkeletonRow = () => (
    <div className={classes.skeletonOuterContainer}>
      <div className={`${classes.user} ${classes.skeletonUserNameContainer} `}>
        <Skeleton className={classes.skeletonUserName} variant="text" />
      </div>
      <div className={classes.skeletonRest}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );

  let skeletonRows = [];

  const numberOfRows = props.numberOfItems && props.numberOfItems > 0 ? props.numberOfItems : 5;
  for (var i = 0; i < numberOfRows; i++) {
    skeletonRows.push(<SkeletonRow key={`skeletonRow-calendar-${i}`} />);
  }

  return <div className={classes.skeletonRestContainer}>{skeletonRows}</div>;
};

export default LoadingCalendar;
