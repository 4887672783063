import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  white: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
  },
  popper: {
    zIndex: 4,
  },
  popperContent: {
    display: 'flex',
    zIndex: 2,
    flexDirection: 'column',
    padding: '1rem',
    '& > *': {
      marginBottom: '1rem',
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  unstyledBtn: {
    padding: '0.1rem',
    border: 'none',
    backgroundColor: 'transparent',
    width: '100%',
  },
}));
