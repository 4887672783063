import React, { useState, useEffect, useCallback } from 'react';
import { AsyncSelect, ISelectOption, Select } from '../../../../../../Shared/Select';
import { useGetOrders, IOrderListRequest } from '../Order.api';
import { SaleStatus } from '../../Sale.api';
import { SaleTypeLabel } from '../../../../../Shared/SaleType';
import formatDate from '../../../../../../Shared/Formatting/formatDate';

export interface IProps {
  onChange?: (orderId: string | undefined, jobDescription: string | undefined, materialDescription: string | undefined, customerId?: number | undefined, facilityId?: number | undefined) => void;
  required?: boolean;
  selectedOrderId?: string;
  label?: string;
  placeholder?: string;
  noLabel?: boolean;
  isClearable?: boolean;
  status?: SaleStatus[];
  isDisabled?: boolean;
  customerId?: number;
  facilityId?: number;
  errorText?: string | string[];
  addNewOrderOption?: boolean;
  fullwidth?: boolean;
  notSearchable?: boolean;
}
interface IOrderSelectOption extends ISelectOption {
  jobDescription?: string;
  materialDescription?: string;
  customerId?: number;
  facilityId?: number;
}

const selectNewOrderOption = { label: 'Ny order', value: '0' };

function getSelectedOption(options: IOrderSelectOption[] | undefined, value: any): IOrderSelectOption | undefined {
  if(value === undefined) return undefined;
  return options ? options.find((x) => Number(x.value) === (value as number)) : { label: 'Ny order', value: '0' };
}

const OrderSelect = (props: IProps) => {
  const { status, customerId, facilityId, addNewOrderOption, onChange } = props;
  const { getOrders, isLoading } = useGetOrders();
  const [options, setOptions] = useState<IOrderSelectOption[]>();

  useEffect(() => {
    setOptions(undefined);
    loadOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, facilityId]);

  const loadOrders = useCallback(
    async (query?: string) => {
      var request: IOrderListRequest = {
        pageNumber: 1,
        pageSize: 1000,
        query: query,
        status: status,
        customerId: customerId,
        facilityId: facilityId,
        usedAsSelectList: true,
      };

      if (customerId || query) {
        const response = await getOrders(request);
        var newOptions = response
        ? response.items.map((x) => ({
            jobDescription: x.jobDescription,
            materialDescription: x.materialDescription,
            customerId: x.customerId,
            facilityId: x.facilityId,
            value: x.id.toString(),
            label: `${formatDate(x.approvedDate)} ${x.saleIdentifier} - ${SaleTypeLabel[x.type]}${
              x.description !== null && x.description ? ` - ${x.description}` : ''
            }`,
          }))
        : [];
        setOptions([selectNewOrderOption, ...newOptions]);
        return [selectNewOrderOption, ...newOptions];
      }
      return [selectNewOrderOption]      
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status, customerId, facilityId, addNewOrderOption, getOrders],
  );

  return (
    props.notSearchable ? 
    <Select
      inputFieldProps={{ label: 'Välj order' }}
      selectProps={{
        isClearable: true,
        isLoading: isLoading,
        options: options,
        value: getSelectedOption(options, parseInt(props.selectedOrderId ?? '')),
        onChange: (option) => {
          let chosenOption = options?.find((x) => x.value === (option as ISelectOption)?.value);
          return onChange && onChange((option as ISelectOption)?.value, chosenOption?.jobDescription, chosenOption?.materialDescription, chosenOption?.customerId, chosenOption?.facilityId)
        },
      }}
    />
    : <AsyncSelect
      {...props}
      isDisabled={props.isDisabled || isLoading}
      label={props.noLabel ? undefined : props.label ?? 'Sök order'}
      isLoading={isLoading}
      selectedOptionId={props.selectedOrderId}
      cacheOptions={customerId}
      loadOptions={loadOrders}
      onChange={(value) => {
        let chosenOption = options?.find((x) => x.value === value?.value);
        return onChange && onChange(value?.value, chosenOption?.jobDescription, chosenOption?.materialDescription, chosenOption?.customerId, chosenOption?.facilityId)
        }
      }
    />
  );
};
export default OrderSelect;
