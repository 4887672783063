import React, { useState, useEffect } from 'react';
import { IFacilityListItemDto } from '../../../Customers.api';
import ArchiveIcon from '@material-ui/icons/Archive';
import { Table } from '../../../../../../Shared/Table/Table';
import AccessGuard from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { ModuleIdentifiers, AccessRights } from '../../../../../../Core/Authentication/ModuleAccess';
import { useHistory } from 'react-router-dom';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
  ListItemIcon,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import SearchInput from '../../../../../../Shared/InputFields/SearchInput/SearchInput';
import { DropdownCell } from '../../../../../../Shared/Table/DropdownCell/DropdownCell';
import RemoveOrArchiveFacilityModal from '../RemoveFacilityModal/RemoveOrArchiveFacilityModal';
import WarningModal from '../../../../../../Shared/WarningModal/WarningModal';
import { useArchiveFacility, useDeleteFacility } from '../Facilities.api';
import useErrorMessage from '../../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import { isErrorResponse } from '../../../../../../Shared/Api/response/IErrorRespose';
import { useStyles } from '../../../../../../Shared/Table/TableRowHeader/TableRowHeader.styles';
import IconButton from '../../../../../../Shared/IconButton/IconButton';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CheckboxField from '../../../../../../Shared/InputFields/CheckboxField/CheckboxField';
import { ConfirmDeleteOrArchiveRow } from '../../../../../../Shared/Table/ConfirmDeleteOrArchiveRow/ConfirmDeleteOrArchiveRow';
interface IProps {
  facilities: IFacilityListItemDto[] | undefined;
  customerId: number;
  loading?: boolean;
  reloadCustomer: () => void;
  includeArchived: boolean;
  setincludeArchived: any;
}

const defaultVisibleItems = 5;

const FacilityList = (props: IProps) => {
  const history = useHistory();
  const [facilities, setFacilities] = useState(props.facilities);
  const [query, setQuery] = useState('');
  const [selectedDeleteFacilityId, setSelectedDeleteFacilityId] = useState<number | undefined>();
  const [selectedArchiveFacilityId, setSelectedArchiveFacilityId] = useState<number | undefined>();
  const [selectedFacilityAgreementCount, setSelectedFacilityAgreementCount] = useState<number | undefined>();
  const [openModal, setOpenModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openArchiveModal, setOpenArchiveModal] = useState(false);
  const [isToggleOpen, setIsToggleOpen] = useState(false);
  const { isLoading, deleteFacility } = useDeleteFacility(props.customerId);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoading: archiveIsLoading, archiveFacility } = useArchiveFacility(props.customerId);
  const { setErrorMessage } = useErrorMessage();
  const classes = useStyles();

  const isTogglable = facilities && facilities.length > defaultVisibleItems;
  const maxDisplay = facilities && isToggleOpen ? facilities.length : defaultVisibleItems;

  useEffect(() => {
    if (!query) {
      setFacilities(props.facilities);
    } else {
      const filteredFacilities = props.facilities?.filter((x) => x.name.toLowerCase().includes(query.toLowerCase()));
      setFacilities(filteredFacilities);
    }
  }, [props.facilities, query]);

  const handleDeleteFacility = async () => {
    if (selectedDeleteFacilityId) {
      const result = await deleteFacility(selectedDeleteFacilityId);
      if (!isErrorResponse(result)) {
        setOpenModal(false);
        setSelectedDeleteFacilityId(0);
        setSelectedFacilityAgreementCount(0);
        props.reloadCustomer();
      } else {
        setErrorMessage({ message: 'Kunde inte avsluta avtal kopplade till systemet/platsen.' });
      }
    }
  };

  const handleArchiveFacility = async () => {
    if (selectedArchiveFacilityId && !(selectedFacilityAgreementCount ?? 0 < 1)) {
      const result = await archiveFacility(selectedArchiveFacilityId);
      if (!isErrorResponse(result)) {
        setOpenArchiveModal(false);
        setSelectedArchiveFacilityId(0);
        setSelectedFacilityAgreementCount(0);
        props.reloadCustomer();
      } else {
        setErrorMessage({ message: 'Kunde inte avsluta avtal kopplade till systemet/platsen.' });
      }
    } else {
      setErrorMessage({ message: 'Det går inte arkivera platsen med aktiva avtal.' });
    }
  };

  const handleConfirmDelete = (id: number, agreementCount: number) => {
    setSelectedDeleteFacilityId(id);
    setSelectedFacilityAgreementCount(agreementCount);
    agreementCount > 0 && setOpenModal(true);
  };

  const handleConfirmArchive = (id: number, agreementCount: number) => {
    setSelectedArchiveFacilityId(id);
    setSelectedFacilityAgreementCount(agreementCount);
    if (agreementCount > 0) {
      setSelectedArchiveFacilityId(0);
      setSelectedFacilityAgreementCount(0);
      setErrorMessage({ message: 'Det går inte arkivera platsen med aktiva avtal.' });
    }
  };

  const handleTableClick = (facility: IFacilityListItemDto) => {
    history.push(`${history.location.pathname}/facility/${facility.id}`);
  };

  const FacilityModal = (
    open: boolean,
    setOpen: any,
    title: string,
    isLoading: boolean,
    facilityId: number,
    handleFacilityAction: any,
    message: string,
  ) => (
    <WarningModal
      open={open}
      action={() => handleFacilityAction(facilityId)}
      setOpen={setOpen}
      title={title}
      isLoading={isLoading}
    >
      <Grid container item>
        {message}
      </Grid>
    </WarningModal>
  );

  return (
    <>
      <Table
        label="Platser"
        loading={props.loading ? { loading: props.loading, skeletonRows: 5 } : undefined}
        customSize={{
          fullsize: true,
        }}
        iconButtons={
          <AccessGuard module={ModuleIdentifiers.Customer} accessRights={AccessRights.Write}>
            <IconButton
              size="small"
              aria-label="Lägg till plats"
              onClick={() => {
                history.push(`${history.location.pathname}/facility/create`);
              }}
            >
              <AddIcon />
            </IconButton>
          </AccessGuard>
        }
        filters={
          <>
            <SearchInput type="search" placeholder="Sök" onChange={setQuery} />{' '}
            <div>
              <CheckboxField label="Inkludera arkiverade">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="editFacilityData"
                      color="primary"
                      checked={props.includeArchived}
                      onChange={(_, checked) => props.setincludeArchived(checked)}
                    />
                  }
                  label=""
                />
              </CheckboxField>
            </div>
          </>
        }
      >
        <RemoveOrArchiveFacilityModal
          customerId={props.customerId}
          facilityId={selectedDeleteFacilityId}
          agreementCount={selectedFacilityAgreementCount ?? 0}
          open={openModal}
          setOpen={selectedArchiveFacilityId ? setOpenArchiveModal : setOpenModal}
          loadList={props.reloadCustomer}
          isArchive={selectedArchiveFacilityId ? true : false}
        />
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Platsnummer</TableCell>
            <TableCell>Namn</TableCell>
            <TableCell>Adress</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {facilities?.map(
            (facility, index) =>
              index < maxDisplay && (
                <TableRow className={classes.pointerCursor} hover tabIndex={-1} key={facility.id}>
                  {!(
                    (selectedDeleteFacilityId === facility.id || selectedArchiveFacilityId === facility.id) &&
                    selectedFacilityAgreementCount
                  ) ? (
                    <>
                      <DropdownCell>
                        <MenuItem onClick={() => handleConfirmDelete(facility.id, facility.agreementCount)}>
                          <ListItemIcon>{<DeleteIcon />}</ListItemIcon>
                          <Typography variant="inherit">Ta bort</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => handleConfirmArchive(facility.id, facility.agreementCount)}>
                          <ListItemIcon>{<ArchiveIcon />}</ListItemIcon>
                          <Typography variant="inherit">Arkivera</Typography>
                        </MenuItem>
                      </DropdownCell>
                      <TableCell onClick={() => handleTableClick(facility)} className={classes.linkFont}>
                        {facility.facilityNumber}
                      </TableCell>
                      <TableCell onClick={() => handleTableClick(facility)} className={classes.linkFont}>
                        {facility.name}
                      </TableCell>
                      <TableCell onClick={() => handleTableClick(facility)}>
                        {facility.address}, {facility.postalCode} {facility.city}
                      </TableCell>
                    </>
                  ) : (
                    <>
                      {selectedDeleteFacilityId && !selectedArchiveFacilityId && (
                        <ConfirmDeleteOrArchiveRow
                          onConfirm={() => {
                            handleDeleteFacility();
                            setSelectedDeleteFacilityId(undefined);
                            setSelectedFacilityAgreementCount(0);
                          }}
                          onCancel={() => {
                            setSelectedDeleteFacilityId(undefined);
                            setSelectedFacilityAgreementCount(0);
                          }}
                          colspan={4}
                          label={`Plats ${facility.name}`}
                          loading={isLoading}
                        />
                      )}
                      {selectedArchiveFacilityId && (
                        <ConfirmDeleteOrArchiveRow
                          onConfirm={() => {
                            handleArchiveFacility();
                            setSelectedArchiveFacilityId(undefined);
                            setSelectedFacilityAgreementCount(0);
                          }}
                          onCancel={() => {
                            setSelectedArchiveFacilityId(undefined);
                            setSelectedFacilityAgreementCount(0);
                          }}
                          colspan={4}
                          label={`Plats ${facility.name}`}
                          loading={isLoading}
                          archiveText
                          isArchive={facility.isArchived}
                        />
                      )}
                    </>
                  )}
                </TableRow>
              ),
          )}

          {isTogglable && facilities && (
            <TableRow>
              <TableCell
                onClick={() => setIsToggleOpen((x) => !x)}
                className={classes.roundedHighlightColumn}
                colSpan={5}
              >
                {facilities.length - maxDisplay ? `+ ${facilities.length - maxDisplay} till` : 'Visa färre'}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {selectedDeleteFacilityId &&
        FacilityModal(
          !!openModal,
          setOpenModal,
          'Ta bort anlägging',
          isLoading,
          selectedDeleteFacilityId,
          handleDeleteFacility,
          `Det finns ${selectedFacilityAgreementCount}st avtal kopplade till systemet/platsen. Vill du avsluta dessa?
          Avtalen kommer att sägas upp med omedelbar verkan.`,
        )}
      {/* {selectedArchiveFacilityId &&
        FacilityModal(
          !!openArchiveModal,
          setOpenArchiveModal,
          'Arkivera anlägging',
          archiveIsLoading,
          selectedArchiveFacilityId,
          handleArchiveFacility,
          `Vill du verkligen arkivera platsen?`,
        )} */}
    </>
  );
};

export default FacilityList;
