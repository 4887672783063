import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  oldVersionContainer: {
    margin: '3px',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    padding: '10px',
  },
  textLabel: {
    marginBottom: '0.25rem',
    fontWeight: 600,
    marginLeft: '5px',
  },
  dialog: {
    '& .MuiDialog-paperWidthSm ': {
      maxWidth: '57%',
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-paperWidthSm ': {
        maxWidth: '100%',
      },
    },
  },
}));
