import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  cursorPointer: {
    cursor: 'pointer',
  },
  headerCell: {
    display: 'flex',
    alignItems: 'center',
    '& div': {
      flexBasis: '50%',
    },
    borderTop: '1px solid',
  },
  innerTableWrap: {
    '& div > div': {
      display: 'table',
      '& tr:last-child > td': {
        borderBottom: 0,
      },
    },
  },
});
