import React, { useCallback, useEffect, useState } from 'react';
import RichTextEditor from '../../../../../../../Shared/RichTextEditor/RichTextEditor';
import { Fieldset } from '../../../../../../../Shared/Form/Fieldset/Fieldset';
import { AsyncSelect } from '../../../../../../../Shared/Select';
import { InputField } from '../../../../../../../Shared/InputFields/InputField/InputField';
import useInputState from '../../../../../../../Shared/Hooks/UseInputState/UseInputState';
import { isResultError } from '../../../../../../../Shared/Api/response/ICreateResult';
import { IPreselectionProps } from '../../Preselection';
import {
  IUpsertMeetingNotesTemplateCommandValidationErrors,
  useUpsertMeetingNotesTemplate,
} from '../../Preselection.api';
import {
  IMeetingNotesTemplate,
  useSearchMeetingNotesTemplates,
} from '../../../../../Todos/Components/MeetingNotes/MeetingNotes.api';

const MeetingNotesTemplate = (props: IPreselectionProps) => {
  const { preselectionDefaultContent, saveCallback, setIsLoading, setPreselectionDefaultContent } = props;
  const { isLoading: isSearchLoading, searchTemplates } = useSearchMeetingNotesTemplates();
  const { isLoading: isUpsertLoading, upsert } = useUpsertMeetingNotesTemplate();
  const [templates, setTemplates] = useState<IMeetingNotesTemplate[]>([]);
  const [name, changeName, setName] = useInputState('');
  const [content, setContent] = useState('');
  const [errors, setErrors] = useState<IUpsertMeetingNotesTemplateCommandValidationErrors>();

  const loadTemplates = useCallback(
    async (query?: string) => {
      const response = await searchTemplates({ query: query });
      setTemplates(response);
      var options = response.map((x) => ({ value: x.id.toString(), label: x.name }));
      if (!query || !query?.length) {
        return [...options, { value: '0', label: 'Skapa en ny mall' }];
      }
      return options;
    },
    [searchTemplates],
  );

  useEffect(() => {
    setIsLoading(isSearchLoading || isUpsertLoading);
  }, [isSearchLoading, isUpsertLoading, setIsLoading]);

  useEffect(() => {
    if (preselectionDefaultContent) {
      setContent(preselectionDefaultContent);
      setPreselectionDefaultContent(undefined);
    }
  }, [preselectionDefaultContent, setPreselectionDefaultContent]);

  useEffect(() => {
    const save: any = async (id: string) => {
      var result = await upsert(
        {
          name: name,
          content: content,
        },
        id,
      );
      if (isResultError(result)) {
        setErrors(result);
        return false;
      }
      return true;
    };
    saveCallback(() => save);
  }, [name, content, saveCallback, upsert]);

  return (
    <>
      <Fieldset>
        <AsyncSelect
          label="Hämta mall"
          isLoading={isSearchLoading}
          loadOptions={loadTemplates}
          onChange={(value) => {
            var template = value ? templates.find((x) => x.id.toString() === value.value) : undefined;
            if (template) {
              props.setPreselectionId(template.id.toString());
              setName(template.name);
              setContent(template.content);
            } else {
              props.setPreselectionId(undefined);
              setName('');
              setContent('');
            }
          }}
        />
        <InputField label="Mall namn" type="text" value={name} onChange={changeName} errorText={errors?.name} />
      </Fieldset>
      <Fieldset legend="Text*">
        <RichTextEditor menuBar={false} value={content} onChange={setContent} />
      </Fieldset>
    </>
  );
};

export default MeetingNotesTemplate;
